import { PayloadAction } from '@reduxjs/toolkit';
import { AppSettings } from 'types/AppSettings';
import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { Entity } from 'types/common';
import { KnownModules } from 'types/KnownModules';
import { Notification } from 'types/Notification';
import { SiteMapProvider, SiteMapSettings } from 'types/SiteMapSettings';
import { UserProfileSettings } from 'types/UserProfileSettings';
import { SettingModel } from 'utils/globalSettings/SettingModel';
/* --- STATE --- */

/**
 * AppSettings state interface
 * TODO: auto generate based on bkt_webApp.Api.V4.Models.SettingsModel
 */
export interface AppSettingsState {
  error: string | null;
  loadingAppSettings: boolean;
  appSettings: AppSettings | null;
  loadingAuthenticatedUser: boolean;
  loadingSiteMapSettings: boolean;
  authenticatedUser?: IAuthenticatedUser;
  currentLanguage?: string;
  notifications: Notification[];
  siteMapSettings: SiteMapSettings | null;
  siteMapProvider: SiteMapProvider | null;
  eventsCount: number;
  loadingEventsCount: boolean;
  userProfileSettings: UserProfileSettings[];
  loadingUserProfileSettings: boolean;
  updatedUserProfileSettings: boolean;
  isExpanded: boolean;
  systemSettings: SettingModel[];
  loadingSystemSettings: boolean;
  isAuthenticated?: boolean;
  /**
   * List of Service Groups selected by the user in the global service group filter aka Top Sg filter
   */
  globalServiceGroupFilter: Entity<number>[] | undefined;
  showGlobalServiceGroupFilter: boolean;
  showGlobalServiceGroupsAsList: boolean;
  isFullScreen: boolean;
  barcodeScanTarget?: ScanTarget;
  showAllCores?: boolean;
}

export type ContainerState = AppSettingsState;

/**
 * User roles enum - local/Bookit.Data/Enums.cs
 */

export enum Roles {
  Administrators = 1,
  GroupAdministrators = 2,
  InstituteCoord = 3,
  HeadOfLab = 4,
  CagesAdministrators = 5,
  LabTech = 6,
  FinAdmin = 7,
  RepAdmin = 8,
  InventoryAdmin = 9,
  UserGroupCoord = 10,
  InstrumentEventsAdmin = 11,
  GroupBudgetsAdmin = 12,
  CalendarOnly = 13,
  Readonly = 14,
  LoanDeskManager = 15,
  EquipmentAdministrator = 16,
  UserInquiryAdmin = 17,
  ModelAdmin = 18,
  RoomOnly = 19,
  InvoicesReader = 20,
  KioskAdmin = 21,
  CustomRole = 22,
  BudgetAdmin = 23,
  GroupAdminAffiliated = 24,
  ServiceGroupBudgetsAdmin,
  AnimalAdmin = 25,
  /// <summary>
  /// Users in this role will unlock the large animals operating room pos
  /// </summary>
  LargeAnimalsOperatingRoomUser = 26,
  LargeAnimalsOperatingRoomTechnician = 27,
  LargeAnimalsOperatingRoomVeterinarian = 28,
  CoreBudgetsAdmin = 29,
  ConsumableKiosk = 30,
  CreateNewAssetsAdmin = 31,
}

export enum LoginTypes {
  Password,
  InternalId,
}
export type ISaveUserProfileSettingPayload = Pick<
  UserProfileSettings,
  'Key' | 'Value' | 'UserName' | 'Enabled'
>;
export type SaveUserProfileSettingAction = PayloadAction<
  ISaveUserProfileSettingPayload
>;
export interface NoAuthSettings {
  defaultUserGroup?: Entity<string>;
  registrationEnabled?: boolean;
  requiredUserProps: string[];
  hiddenUserProps: string[];
}
export const anonimusUrls = ['registeraccount', 'assets/public'];
export enum ScanTarget {
  All,
  Consumables = 1,
  Asset = 2,
}
export interface BarcodeData {
  data: string;
  key: ScanTarget;
}
export interface LoginSettingsModel {
  Modules?: KnownModules[];
  settings?: SettingModel[];
  LoginScreenCompanyLogoLink?: string;
  LoginScreenCompanyLogo?: string;
  LoginScreenCompanyLogoHeight?: string;
  ShowForgotPassword?: boolean;
  Help_Login?: string;
  WebVersion?: string;
  LastCodeUpdate?: Date;
}
