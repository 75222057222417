import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { IconProp } from 'app/AllIcons';
import { Entity } from 'types/common';
import * as React from 'react';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Box, LinearProgress, ListItem, ListItemText } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { HoverLinkedItem } from './HoverLinkItem';
import { translations } from 'locales/translations';
import { Body } from 'app/components/Typography';
import { useTranslation } from 'react-i18next';
import cliTruncate from 'cli-truncate';

export interface LinkedList extends Entity<number> {
  Id: number;
  Name: string;
  ParentId: number;
  Linked: LinkedList[];
}
export interface WorkOrderLinksCollapsedProps {
  links: LinkedList[];
  hoverIcon?: IconProp;
  onHoverClick?: (linked: LinkedList) => void;
  onItemClcik?: (linked: Entity<number>) => void;
}

export function WorkOrderLinksCollapsed(props: WorkOrderLinksCollapsedProps) {
  const { links, hoverIcon, onHoverClick, onItemClcik } = props;
  const { t } = useTranslation();
  return (
    <List
      component="div"
      dense
      disablePadding
      style={{ width: '100%' }}
      subheader={<Body bold={true}>{t(translations.AllLinkedWorkOrders)}</Body>}
    >
      {links.map(link => (
        <HoverLinkedCollapsedItems
          linked={link}
          key={link.Id}
          hoverIcon={hoverIcon}
          onHoverClick={onHoverClick}
          onItemClcik={onItemClcik}
        />
      ))}
    </List>
  );
}
export interface HoverLinkedItemsProps {
  linked: LinkedList;
  onHoverClick?: (linked: LinkedList) => void;
  onItemClcik?: (linked: Entity<number>) => void;
  hoverIcon?: IconProp;
}

export function HoverLinkedCollapsedItems(props: HoverLinkedItemsProps) {
  const { linked, onHoverClick, onItemClcik, hoverIcon } = props;

  const [open, setOpen] = React.useState(false);
  return linked.Linked.length > 0 ? (
    <React.Fragment>
      <ListItem
        dense
        onClick={() => setOpen(!open)}
        style={{ cursor: 'pointer' }}
        disableGutters
      >
        <ListItemIcon>
          {<Icon icon="square" fontSize="8px" transform={{ rotate: 45 }} />}
        </ListItemIcon>
        <ListItemText>
          {cliTruncate(linked.Name, 32, { position: 'middle' })}
        </ListItemText>
        <ListItemIcon>
          {<Icon icon={open ? 'chevron-up' : 'chevron-down'} />}
        </ListItemIcon>
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        style={{ marginLeft: 8 }}
      >
        {/* <Divider /> */}
        <List component="div" className="childRoot" dense disablePadding>
          {linked.Linked.map((item, index) => (
            <HoverLinkedItems
              key={index}
              linked={item}
              onHoverClick={onHoverClick}
              onItemClcik={onItemClcik}
              hoverIcon={hoverIcon}
            />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  ) : (
    <ListItem disableGutters dense>
      <ListItemIcon style={{ justifyContent: 'end' }}>
        {<Icon icon="circle" fontSize="8px" color="primary" />}
      </ListItemIcon>
      <HoverLinkedItem
        linked={linked}
        hoverIcon={hoverIcon}
        useHoverButton={true}
        onHoverClick={item => !!onHoverClick && onHoverClick(linked)}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => !!onItemClcik && onItemClcik(linked)}
        >
          {cliTruncate(linked.Name, 32, { position: 'middle' })}
        </Button>
      </HoverLinkedItem>
    </ListItem>
  );
}

export function HoverLinkedItems(props: HoverLinkedItemsProps) {
  const { linked, onHoverClick, onItemClcik, hoverIcon } = props;

  return linked.Linked.length > 0 ? (
    <React.Fragment>
      <ListItem disableGutters dense>
        <ListItemIcon style={{ justifyContent: 'end' }}>
          {<Icon icon="square" fontSize="8px" transform={{ rotate: 45 }} />}
        </ListItemIcon>
        <HoverLinkedItem
          linked={linked}
          hoverIcon={hoverIcon}
          useHoverButton={true}
          onHoverClick={item => !!onHoverClick && onHoverClick(linked)}
        >
          <Button
            variant="text"
            size="small"
            onClick={() => !!onItemClcik && onItemClcik(linked)}
          >
            {cliTruncate(linked.Name, 32, { position: 'middle' })}
          </Button>
        </HoverLinkedItem>
      </ListItem>
      <List
        component="div"
        className="childRoot"
        dense
        disablePadding
        style={{ marginLeft: 8 }}
      >
        {linked.Linked.map((item, index) => (
          <HoverLinkedItems
            key={index}
            linked={item}
            onHoverClick={onHoverClick}
            onItemClcik={onItemClcik}
            hoverIcon={hoverIcon}
          />
        ))}
      </List>
    </React.Fragment>
  ) : (
    <ListItem disableGutters dense>
      <ListItemIcon style={{ justifyContent: 'end' }}>
        {<Icon icon="circle" fontSize="8px" color="primary" />}
      </ListItemIcon>
      <HoverLinkedItem
        linked={linked}
        hoverIcon={hoverIcon}
        useHoverButton={true}
        onHoverClick={item => !!onHoverClick && onHoverClick(linked)}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => !!onItemClcik && onItemClcik(linked)}
        >
          {cliTruncate(linked.Name, 32, { position: 'middle' })}
        </Button>
      </HoverLinkedItem>
    </ListItem>
  );
}
export interface WorkOrderLinksProps {
  links: LinkedList[];
  hoverIcon?: IconProp;
  onHoverClick?: (linked: LinkedList) => void;
  onItemClcik?: (linked: Entity<number>) => void;
  showProcessing?: boolean;
}
const getLength = (link: LinkedList) => {
  let count = link.Linked.length;
  if (link.Linked.length > 0) {
    link.Linked.forEach(item => {
      count += getLength(item);
    });
  }
  return count;
};
export function WorkOrderLinks(props: WorkOrderLinksProps) {
  const { links, hoverIcon, onHoverClick, onItemClcik, showProcessing } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const countLinks = React.useMemo(() => {
    let count = links.length;
    if (links.length > 0) {
      links.forEach(item => {
        count += getLength(item);
      });
    }
    return count;
  }, [links]);
  return (
    <List component="div" dense disablePadding style={{ width: '100%' }}>
      <React.Fragment>
        <ListItem
          onClick={() => setOpen(!open)}
          style={{ cursor: 'pointer' }}
          disableGutters
        >
          <ListItemText>
            {
              <Body bold={true}>
                {t(translations.LinkedWorkOrders).replace(
                  '{0}',
                  countLinks.toString(),
                )}
              </Body>
            }
          </ListItemText>
          <ListItemIcon>
            {<Icon icon={open ? 'chevron-up' : 'chevron-down'} />}
          </ListItemIcon>
        </ListItem>
        {showProcessing && (
          <Box style={{ width: '100%' }}>
            <LinearProgress variant="query" color="primary" />
          </Box>
        )}
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          style={{ marginLeft: 8 }}
        >
          {/* <Divider /> */}
          <List component="div" className="childRoot" dense disablePadding>
            {links.map((item, index) => (
              <HoverLinkedItems
                key={index}
                linked={item}
                onHoverClick={onHoverClick}
                onItemClcik={onItemClcik}
                hoverIcon={hoverIcon}
              />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    </List>
  );
}
