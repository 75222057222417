/**
 *
 * CustomFormField
 *
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

import { FormRadioGroup } from 'app/components/Forms/FromRadioGroup';
import { CustomFormFieldProps } from 'app/components/CustomForm/CustomFormFields';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { Entity } from 'types/common';

export function CustomFormAnswerQuestions({
  formField,
  readonly,
  disabled,
  ...props
}: CustomFormFieldProps) {
  const { t } = useTranslation();
  const options = getOptions(formField);
  const title = `${formField.QuizScore} ${t(translations.Points) as string}`;
  return (
    <FormRadioGroup
      options={options}
      disabled={readonly || disabled}
      label={formField.Label}
      helperText={formField.Description ?? undefined}
      title={title}
      {...props}
    />
  );
}
const indexToAlpha = (index: number): string => {
  return (index + 10).toString(36);
};

const getOptions = (formField: IFormFieldDto): Entity<string>[] => {
  switch (formField.OptionsSourceType) {
    case 'SimpleList':
      return (
        formField.Options?.split(';').map((s, index) => ({
          Id: s,
          Name: `${indexToAlpha(index)}. ${s}`,
        })) ?? []
      );
    default:
      console.error(
        `OptionsSourceType ${formField.OptionsSourceType} is not implemented`,
      );
      return [];
  }
};
