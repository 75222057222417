import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { bookitColors } from 'styles/colors/bookitColors';
import { CheckColor } from '.';

export const useStyles = makeStyles<
  Theme,
  {
    size?: 'medium' | 'small';
    color?: CheckColor;
    withPadding?: boolean;
    danger?: boolean;
  }
>((theme: Theme) =>
  createStyles({
    root: {
      '&[class*="PrivateSwitchBase-root"]': {
        padding: props => (props.withPadding ? 9 : 0),
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: 4,
      width: props => (props.size === 'medium' ? 20 : 16),
      height: props => (props.size === 'medium' ? 20 : 16),
      boxShadow: props =>
        props.danger
          ? `inset 0 0 0 1px ${bookitColors.danger.borders}, inset 0 -1px 0 ${bookitColors.danger.borders}`
          : `inset 0 0 0 1px ${bookitColors.grayscale.grayBorders}, inset 0 -1px 0 ${bookitColors.grayscale.grayBorders}`,
      //border: `1px solid ${bookitColors.grayscale.grayBorders}`,
      backgroundColor: bookitColors.base.white,
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        // outline: `1px auto ${blackGray.colorGrayComponentsLight}`,
        // outlineOffset: 1,
        boxShadow: props =>
          props.danger
            ? `inset 0 0 0 2px ${bookitColors.danger.componentsLight}, inset 0 -1px 0 ${bookitColors.danger.componentsLight}`
            : `inset 0 0 0 2px ${bookitColors.grayscale.grayComponentsLight}, inset 0 -1px 0 ${bookitColors.grayscale.grayComponentsLight}`,
      },
      'input:hover ~ &': {
        // backgroundColor: '#ebf1f5',
        //border: `1px solid ${bookitColors.grayscale.grayBorders}`,
        boxShadow: props =>
          props.danger
            ? `inset 0 0 0 1px ${bookitColors.danger.borders}, inset 0 -1px 0 ${bookitColors.danger.borders}`
            : `inset 0 0 0 1px ${bookitColors.grayscale.grayBorders}, inset 0 -1px 0 ${bookitColors.grayscale.grayBorders}`,
      },
      'input:disabled ~ &': {
        boxShadow: props =>
          props.danger
            ? `inset 0 0 0 1px ${bookitColors.danger.disabled}, inset 0 -1px 0 ${bookitColors.danger.disabled}`
            : `inset 0 0 0 1px ${bookitColors.grayscale.graySelection}, inset 0 -1px 0 ${bookitColors.grayscale.graySelection}`,
        background: props =>
          props.danger
            ? bookitColors.danger.background
            : bookitColors.grayscale.grayBackground,
      },
    },
    checkedIcon: {
      boxShadow: 'unset',
      backgroundColor: props =>
        props.danger
          ? bookitColors.danger.regular
          : // : props.color === 'secondary'
            // ? bookitColors.success.regular
            bookitColors.primary.regular,
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: props => (props.size === 'medium' ? 20 : 16),
        height: props => (props.size === 'medium' ? 20 : 16),
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: props =>
          props.danger
            ? bookitColors.danger.hover
            : // : props.color === 'secondary'
              // ? bookitColors.success.hover
              bookitColors.primary.hover,
        boxShadow: 'unset',
      },
      'input:disabled ~ &': {
        boxShadow: 'unset', //`inset 0 0 0 1px ${blackGray.colorDividers}, inset 0 -1px 0 ${blackGray.colorDividers}`,
        background: props =>
          props.danger
            ? bookitColors.danger.disabled
            : // : props.color === 'secondary'
              // ? bookitColors.success.disabled
              bookitColors.primary.disabled,
      },
    },
    indeterminateIcon: {
      boxShadow: 'unset',
      backgroundColor: props =>
        props.danger
          ? bookitColors.danger.regular
          : // : props.color === 'secondary'
            // ? bookitColors.success.regular
            bookitColors.primary.regular,
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: props => (props.size === 'medium' ? 20 : 16),
        height: props => (props.size === 'medium' ? 20 : 16),
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-54 -24 516 560'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M400 288h-352c-17.69 " +
          "0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: props =>
          props.danger
            ? bookitColors.danger.hover
            : // : props.color === 'secondary'
              // ? bookitColors.success.hover
              bookitColors.primary.hover,
        boxShadow: 'unset',
      },
      'input:disabled ~ &': {
        boxShadow: 'unset', //`inset 0 0 0 1px ${blackGray.colorDividers}, inset 0 -1px 0 ${blackGray.colorDividers}`,
        background: props =>
          props.danger
            ? bookitColors.danger.disabled
            : // : props.color === 'secondary'
              // ? bookitColors.success.disabled
              bookitColors.primary.disabled,
      },
    },
  }),
);
