/**
 *
 * DialogConfirm
 *
 */
import { DialogProps } from '@material-ui/core';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../BasicButtons/ActionButton';
import { BasicDialog } from '../BasicDialog';
import { DialogActionsRenderer } from '../BasicDialog/DialogActionsRender';

export interface DialogConfirmProps
  extends Omit<DialogProps, 'open' | 'title'> {
  title?: string | React.ReactNode;
  subtitle?: React.ReactNode;
  body: React.ReactNode;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: () => void;
  onClose?: () => void;
  processing?: boolean;
  disabled?: boolean;
  isOpen: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  actions?: DialogActionsRenderer[];
}

export function DialogConfirm({
  title,
  body,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
  processing,
  isOpen,
  maxWidth,
  subtitle,
  disabled,
  actions,
  onClose,
  ...props
}: DialogConfirmProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(isOpen);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    onCancel?.();
    onClose?.();
    setOpen(false);
  };
  const handleConfirm = event => {
    if (!!onConfirm) {
      onConfirm(event);
    }
  };
  const btnActions: DialogActionsRenderer[] = [];
  if (actions && actions.length > 0) {
    actions.forEach(a => btnActions.push(a));
  }
  if (!!onConfirm) {
    btnActions.push(() => (
      <ActionButton
        autoFocus
        text={confirmButtonLabel ?? t(translations.Confirm)}
        variant="main"
        processing={processing}
        disabled={disabled || processing}
        onClick={handleConfirm}
      />
    ));
  }
  if (!!onCancel) {
    btnActions.push(() => (
      <ActionButton
        text={cancelButtonLabel ?? t(translations.Cancel)}
        variant="ghost"
        disabled={processing}
        onClick={handleClose}
      />
    ));
  }

  return (
    <BasicDialog
      maxWidth={maxWidth}
      isopen={open}
      id="confirmation-dialog-title"
      title={title}
      subtitle={subtitle}
      body={body}
      leftActions={btnActions}
      rightActions={[]}
      processing={processing}
      disabled={disabled}
      onClose={handleClose}
      PaperProps={
        !!props.className ? { classes: { root: props.className } } : undefined
      }
    />
  );
}
