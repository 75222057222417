import * as React from 'react';
import * as DHTMLX from 'dhtmlx-scheduler';

export function deleteTempReservations(
  schedulerRef: React.MutableRefObject<DHTMLX.SchedulerStatic | undefined>,
  id?: string | number | undefined,
) {
  try {
    schedulerRef?.current
      ?.getEvents()
      ?.filter(f => f.type === undefined)
      // new events generally have int id and id parameter here can be either int or string
      ?.filter(f => String(f.id) !== String(id))
      ?.forEach(f => schedulerRef?.current?.deleteEvent(f.id));
  } catch (error) {
    //noop
  }
}
