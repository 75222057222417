import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { pricePeriodSaga } from './saga';
import {
  ConvertDtoToModel,
  IPriceSheetPeriodDto,
  PricePeriodQueryParams,
  PricePeriodsDetailsState,
  PricePeriodState,
} from './types';

export const initialState: PricePeriodState = {
  detailsState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
    error: undefined,
  },
  processing: false,
};

const slice = createSlice({
  name: 'pricePeriod',
  initialState,
  reducers: {
    initDetails(
      state,
      action: PayloadAction<{
        query: PricePeriodQueryParams;
        model?: IPriceSheetPeriodDto;
      }>,
    ) {
      state.processing = true;
      state.detailsState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        data: IPriceSheetPeriodDto;
      }>,
    ) {
      state.processing = false;
      state.detailsState.data = ConvertDtoToModel(action.payload.data);
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
    },
    initDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    resetDetailsState(state, action: PayloadAction) {
      state.detailsState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.processing = false;
    },
    createPeriod(state, action: PayloadAction<PricePeriodsDetailsState>) {
      state.detailsState.processing = true;
      state.detailsState.completed = undefined;
      state.detailsState.hasErrors = undefined;
    },
    createPeriod_Success(state, action: PayloadAction<{ hasErrors: boolean }>) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.detailsState.completed = true;
    },
    createPeriod_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
    },
    updatePeriod(
      state,
      action: PayloadAction<{
        current: PricePeriodsDetailsState;
        original: PricePeriodsDetailsState;
      }>,
    ) {
      state.detailsState.processing = true;
      state.detailsState.completed = undefined;
      state.detailsState.hasErrors = undefined;
    },
    updatePeriod_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.detailsState.completed = true;
    },
    updatePeriod_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
    },
  },
});

export const { actions: pricePeriodActions } = slice;

export const usePricePeriodSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: pricePeriodSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = usePricePeriodSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
