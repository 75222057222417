/**
 *
 * AssetCatGroupPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IAssetCatGroupDto } from 'api/odata/generated/entities/IAssetCatGroupDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import {
  selectGlobalServiceGroupFilter,
  selectIsAuthenticated,
} from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity, Identifiable } from 'types/common';

export type CatGroupUrl = 'base' | 'assetBase' | 'public';
export interface AssetCatGroupPickerProps
  extends Omit<AutocompletePickerProps<IAssetCatGroupDto>, 'loadData'> {
  noGlobalServiceGroupFilter?: boolean;
  urlType?: CatGroupUrl;
}

const url = '/api/odata/v4/AssetCatGroups';
const assetBaseUrl = '/api/odata/v4/AssetCatGroups/AssetCatGroupsFilter';
const publicUrl = '/api/odata/v4/AssetCatGroups/PublicAssetCatGroupsFilter';
export const initAssetCatGroupData = (
  initval: string | undefined,
  sourceUrl?: CatGroupUrl,
) => {
  if (initval === undefined) {
    return new Promise<IAssetCatGroupDto[]>((resolve, reject) => {
      resolve([] as IAssetCatGroupDto[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name,ServiceGroups',
      $skip: 0,
      $top: 1,
    };
    let initUrl =
      sourceUrl === 'public'
        ? publicUrl
        : sourceUrl === 'assetBase'
        ? assetBaseUrl
        : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as IAssetCatGroupDto[]);
  }
};
const loadData = (
  predicates: (string | Condition<IAssetCatGroupDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  isAuthenticated?: boolean,
  urlType?: CatGroupUrl,
) => {
  const sourceUrl =
    urlType === 'public' || !isAuthenticated
      ? publicUrl
      : urlType === 'assetBase'
      ? assetBaseUrl
      : url;
  return getAutoCompleteLoadDataFn<IAssetCatGroupDto>({
    url: sourceUrl,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: ['Id', 'Name', 'ServiceGroups'],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<IAssetCatGroupDto>(
              'ServiceGroups',
              ODataOperators.AnyIn,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });
};

export function AssetCatGroupPicker(props: AssetCatGroupPickerProps) {
  const { predicates, noGlobalServiceGroupFilter, urlType, ...other } = props;
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const isAuth = useSelector(selectIsAuthenticated);
  const innerLoadData = loadData(
    predicates,
    isAuth
      ? !noGlobalServiceGroupFilter
        ? globalServiceGroupFilter || []
        : []
      : [],
    isAuth,
    urlType,
  );
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'AssertCatGroupId'}
      loadData={innerLoadData}
      {...other}
    />
  );
}
export const AssetCatGroupByServiceGroupFilter = (value: FilterValueType) => {
  if (value !== undefined && value !== null) {
    return [
      `${new Condition<IAssetCatGroupDto>(
        'ServiceGroups',
        ODataOperators.In,
        (value as Identifiable<number>).Id,
      ).toString()}`,
    ];
  }
  return [];
};
