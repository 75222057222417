/**
 *
 * auto generated interface for Bookit.Data.RequiredTypes
 *
 */

export enum RequiredTypes {
  Optional = 0,
  User = 1,
  Admin = 2,
  Conditional = 3,
}
export type RequiredTypesUnion = keyof typeof RequiredTypes;
export const RequiredTypesUnionArray: Array<RequiredTypesUnion> = [
  'Optional',
  'User',
  'Admin',
  'Conditional',
];
