/**
 *
 * ServerBarcodeRoute
 *
 */
import React from 'react';
import { AppSettings } from 'types/AppSettings';
import { ShortURLRoute } from '../ShortURLRoute';

export interface ServerBarcodeRouteProps {
  appSettings: AppSettings | null;
}

/**
 * Forces url reload for external pages after navigation from react router (push/Redirect/etc.) and the page needs server side reload
 * @param props route props
 * @returns redirect
 */
export function ServerBarcodeRoute({
  appSettings,
}: ServerBarcodeRouteProps): React.ReactElement {
  // collect short url templates from system settings
  const templates: Array<string> = [
    appSettings?.AssetBarcodeRelativeUrlTemplate,
    appSettings?.RoomBarcodeRelativeUrlTemplate,
    appSettings?.LocationBarcodeRelativeUrlTemplate,
    appSettings?.SubLocationBarcodeRelativeUrlTemplate,
  ]
    .map(p => p ?? '')
    .filter(p => p !== '');

  // replace handlebars based templates to react format
  // e.g. /location/{{RoomId}}/{{LocationId}} => /location/:param1?/:param2?
  const replaceHandlebarTokens = function (s) {
    let index = 0;
    const result = s.replace(/({{[^}]+}})/g, function () {
      return `:param${index++}?`;
    });
    return result;
  };
  if (appSettings == null) {
    return <></>;
  }

  const paths = templates
    .map(p =>
      // react router path should start with /
      replaceHandlebarTokens(p),
    )
    .map(p => (p.startsWith('/') ? p : '/' + p));

  // use the paths matching the shorturltemplates and use the dedicated route for all
  return <ShortURLRoute exact path={paths} />;
}
