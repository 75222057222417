import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { UserAttributesDetailsState } from '../slice/types';
import * as Yup from 'yup';
import { translations } from 'locales/translations';
import i18next from 'i18next';
import { Formik } from 'formik';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRightSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormSwitch } from 'app/components/Forms/Switch';
import { FormNumberField } from 'app/components/Forms/FormNumberField';
import { FormLabelText } from 'app/components/Forms/FormLabelText';

export interface UserAttributesFormProps {
  initialValues: UserAttributesDetailsState;
  onSubmit: (item: UserAttributesDetailsState) => void;
  isEdit: boolean;
  isAdmin: boolean;
  bindSubmitForm: any;
}

export const UserAttributesForm = React.memo(function UserAttributesForm({
  initialValues,
  onSubmit,
  isEdit,
  isAdmin,
  bindSubmitForm,
}: UserAttributesFormProps): JSX.Element {
  //SETTINGS
  const { t } = useTranslation();

  //STATES
  //const [ssoChanged, setSsoChanged] = useState<boolean>(false);
  /* const [ssoAttributeChanged, setSsoAttributeChanged] = useState<boolean>(
      false,
    ); */

  //FUNCTIONS
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };

  //check if the field's props (Disabled, Mandatory) can't be editted
  const isNotEditableField = () => {
    if (initialValues.Attribute === 'UserName') {
      return true;
    }
    if (initialValues.Attribute === 'Password') {
      return true;
    }
    if (initialValues.Attribute === 'Email') {
      return true;
    }
    if (initialValues.Attribute === 'UserGroup') {
      return true;
    }
    return false;
  };

  /*     const handleFormChange = useCallback(
      (values, isValid, dirty, setValue) => {
        if (
          initialValues.Attribute === 'UserName' ||
          initialValues.Attribute === 'Password' ||
          initialValues.Attribute === 'Email' ||
          initialValues.Attribute === 'UserGroup'
        ) {
          setValue('Disabled', false, false);
          setValue('Mandatory', true, false);
        }
        /*         if (ssoChanged) {
          if (values.SSO === true) {
            setValue('Mandatory', false, false);
            setValue('RegistrationForm', false, false);
            setValue('UserDetails', false, false);
            setValue('UserSidePanel', false, false);
            setValue('UserProfile', false, false);
            setValue('Order', 0, false);
          } else {
            setValue('SSOAttribute', '', false);
            setValue('Mandatory', false, false);
          }
          setSsoChanged(false);
        }
        if (ssoAttributeChanged) {
          if (values.SSOAttribute !== '') {
            setValue('Mandatory', true, false);
          } else {
            setValue('Mandatory', false, false);
          }
          setSsoAttributeChanged(false);
        } */
  //  },
  //  [initialValues.Attribute],
  //);

  //SCHEMA
  const userAttributesDetailsSchema: Yup.SchemaOf<UserAttributesDetailsState> = Yup.object(
    {
      Id: Yup.number().notRequired().default(-1),
      Attribute: Yup.string().default('').notRequired().nullable(),
      AttributeLocalized: Yup.string()
        .default('')
        .nullable()
        .required()
        .label(i18next.t(translations.Attribute)),
      Disabled: Yup.boolean()
        .notRequired()
        .nullable()
        .default(false)
        .label(i18next.t(translations.Disabled)),
      SSO: Yup.boolean()
        .notRequired()
        .default(false)
        .nullable()
        .label(i18next.t(translations.SSO)),
      SSOAttribute: Yup.string()
        .label(i18next.t(translations.SSOAttribute))
        .when('SSO', {
          is: sso => sso === true,
          then: Yup.string().nullable().required(),
          otherwise: Yup.string().notRequired().nullable().default(''),
        }),
      Mandatory: Yup.boolean()
        .notRequired()
        .nullable()
        .default(false)
        .label(i18next.t(translations.Mandatory)),
      RegistrationForm: Yup.boolean()
        .notRequired()
        .nullable()
        .default(false)
        .label(i18next.t(translations.RegistrationForm)),
      UserDetails: Yup.boolean()
        .notRequired()
        .nullable()
        .default(false)
        .label(i18next.t(translations.UserDetails)),
      UserSidePanel: Yup.boolean()
        .notRequired()
        .nullable()
        .default(false)
        .label(i18next.t(translations.UserSidePanel)),
      UserProfile: Yup.boolean()
        .notRequired()
        .nullable()
        .default(false)
        .label(i18next.t(translations.UserProfile)),
      Order: Yup.number()
        .notRequired()
        .nullable()
        .default(0)
        .label(i18next.t(translations.Order)),
    },
  );

  //RENDER
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={userAttributesDetailsSchema}
        validateOnMount={true}
        validateOnBlur={false}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          console.debug('Submitting: ', values);
          formikHelpers.setSubmitting(true);
          handleSubmit(values);
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            //console.log('User Attributes Details Errors: ', formik.errors);
          }
          return (
            <>
              <StyledForm onSubmit={formik.handleSubmit}>
                {/* <FormListener
                    onFormChange={handleFormChange}
                    fields={['Disabled', 'Mandatory']}
                  /> */}
                <FormLeftSection>
                  <FormFieldsSection
                    titleSection={
                      t(translations.UserAttributeDetails) as string
                    }
                  >
                    <FormRow>
                      <FormLabelText
                        id="AttributeLocalizedID"
                        name="AttributeLocalized"
                        label={t(translations.Attribute)}
                        text={initialValues.AttributeLocalized}
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormLeftSection>
                <FormRightSection>
                  <FormFieldsSection>
                    <FormRow fullRow>
                      <FormSwitch
                        id="DisabledID"
                        boldLebel
                        name="Disabled"
                        label={t(translations.Disabled)}
                        disabled={formik.isSubmitting || isNotEditableField()}
                      />
                    </FormRow>
                    {/*                       <FormRow fullRow>
                        <FormSwitch
                          id="ssoID"
                          boldLebel
                          name="SSO"
                          label={t(translations.SSO)}
                          disabled={formik.isSubmitting}
                          //onChange={() => setSsoChanged(true)}
                        />
                      </FormRow>
                      <FormRow>
                        <FormTextField
                          id="SSOAttributeID"
                          name="SSOAttribute"
                          label={t(translations.SSOAttribute)}
                          placeholder={t(translations.SSOAttribute)}
                          disabled={!formik.values.SSO || formik.isSubmitting}
                          onChange={() => setSsoAttributeChanged(true)}
                          fullWidth
                        />
                      </FormRow> */}
                    <FormRow fullRow>
                      <FormSwitch
                        id="MandatoryID"
                        boldLebel
                        name="Mandatory"
                        label={t(translations.Mandatory)}
                        disabled={
                          formik.values.SSO ||
                          formik.isSubmitting ||
                          isNotEditableField()
                        }
                      />
                    </FormRow>
                    <FormRow fullRow>
                      <FormSwitch
                        id="RegistrationFormID"
                        boldLebel
                        name="RegistrationForm"
                        label={t(translations.RegistrationForm)}
                        disabled={formik.values.SSO || formik.isSubmitting}
                        info={t(
                          translations.MandatoryFieldsArePartOfTheRegistrationForm,
                        )}
                      />
                    </FormRow>
                    <FormRow fullRow>
                      <FormSwitch
                        id="UserDetailsID"
                        boldLebel
                        name="UserDetails"
                        label={t(translations.UserDetails)}
                        disabled={formik.values.SSO || formik.isSubmitting}
                        info={t(
                          translations.MandatoryFieldsArePartOfTheRegistrationForm,
                        )}
                      />
                    </FormRow>
                    {/* <FormRow fullRow>
                        <FormSwitch
                          id="UserSidePanelID"
                          boldLebel
                          name="UserSidePanel"
                          label={t(translations.UserSidePanel)}
                          disabled={formik.values.SSO || formik.isSubmitting}
                        />
                      </FormRow> */}
                    {/* <FormRow fullRow>
                        <FormSwitch
                          id="UserProfileID"
                          boldLebel
                          name="UserProfile"
                          label={t(translations.UserProfile)}
                          disabled={formik.values.SSO || formik.isSubmitting}
                        />
                      </FormRow> */}
                    <FormRow fullRow>
                      <FormNumberField
                        fullWidth
                        id="OrderID"
                        name="Order"
                        label={t(translations.Order)}
                        placeholder={t(translations.Order)}
                        disabled={formik.values.SSO || formik.isSubmitting}
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormRightSection>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    </>
  );
});
