import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.samplePlates || initialState;

export const selectSamplePlates = createSelector([selectSlice], state => state);

export const selectSamplePlatesData = createSelector(
  [selectSamplePlates],
  state => state.data,
);
export const selectSamplePlatesTableState = createSelector(
  [selectSamplePlates],
  state => state.tableState,
);
export const selectSamplePlatesCreate = createSelector(
  [selectSamplePlates],
  state => state.create,
);

export const selectBulkSamplePlatesCreate = createSelector(
  [selectSamplePlates],
  state => state.bulckCreate,
);

export const selectSamplePlatesUpdate = createSelector(
  [selectSamplePlates],
  state => state.update,
);
