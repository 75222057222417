/**
 *
 * BookitlabLogo
 *
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { messages } from './messages';

export interface BookitlabShortLogoProps {}

export const BookitlabShortLogo = React.memo(function BookitlabShortLogo(
  props: BookitlabShortLogoProps,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="34px"
      height="34px"
      viewBox="0 0 75 60"
      enableBackground="new 0 0 75 60"
      xmlSpace="preserve"
    >
      <title>{t(messages.bookitlab())}</title>
      <image
        id="image0"
        width="75"
        height="60"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAAA8CAYAAAAuaUeTAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAB+HAAAfhwEb/jQiAAALvUlEQVR42u2cfXDV1ZnHP8+59+a+JBchBBBUpFVEaa3iVqQk
Ny9bkRdJonZCnaG2W63ttLP1DVK7dbobnZ2igluL66xOdVqdKa1gSUgwqfbl0iSwYqGuqNNalFUr
geXFEJLc3Nffs3+Ei0kIyblJbtJp+/0rv5vnOc/5fXLu+Z3nOecX+LusJRPdgaFUFda8mCdyKZh5
gs4UlTxVycWgoD3iaLcD7xvR/TFPYH/TIjn5NwOrolWDxol8VkWWgHwWmJeBuwJvIhp2HNevO1M5
L+4ok+hfHayK5kihiNwGrAJyx6ZVPaHIzwX94bZQYPdYtDihsCqbo0sRfQBYmOVQYRVZV1/k++Vo
GpkQWBXNsU8hzuMCReMaWGlUt9xZv9j39kjcxxVW1Zuak/gw+h2FfwFyxjN2H8VUuP+qQt9DNSJO
Jo7jBmvlrsh57qTUqnD1+PMZVL9KJFO3NJblHbZ1GBdY5a2RRUZlKzBzwtAMroPGmGW1hd43bIyz
DquyJXotaD3gn2gyZ0HQjmjltiJ/y7CW2ezGja09RY7SBORNNJJh1KOqS+qLAzuHMhoVrA3Ny+c7
UCIiVyDMRXWyIpMFIoe6VPd9OKvA7Z8fMYFFKXdgwcdExDOCMA7IPoEDqnpEhGMOJEECgs4GmQt6
BeAeFS7luFETqi3x/mHMYH2/eenMpLi+hspqRC8ezKYnCQ37HSKJjz7ze0QnBy865Mv/UsQV+OTF
w4TpUdhk1NmekMRvXwhNbh/KuCqseQlPLKSqNwM3McKRrHDAG/VdtWWJdIwK1oZweYG6U/cDtwHe
swZU+MUBh//rHvz3AhQEp3T4Z649muOb3w+awlGBHzjJxJMNZZOOjeSGbwi3T1aX7xsIa4D8TP0F
2VIX8q0aMawNLStuUXjUJvgbR5U9h9SiUzB9yty2vAu+ly+4fSg/Md7EnbXXTDo+EkgDVR4+WWDc
noeBL2cMTPhKXZH/6YxgbWxc7o1PkidU+SebIJ1xqPuTQyqDpd7UXH/PnI995+5nS65+ciwgDVRF
S6RKkKeASdZOynHjTcwb+IczZ7N/PFyaFwvKdltQAK+0ZQYq4IbFs6L+y82/PbCh5fp/yAas+lBg
i5hUCWC9+ESYmop7vnfmx4Oo5s2qnNzj3Q0I19m2fzwC2992GP4L2Cu/R1jxcSGYnv2EDqOmZE1o
+2vZgFbRGp2HaovANEuXlBiZV1foeyf9waAjK7e9++lMQAG8ekStQbkMXDuHj0ABKOc4OA0bwuUF
2YBVX+R7y6iuBGxrXC51tLrvB2fAWt96/W0oX8ikIx0xOHjSFhVcM0uY6h90UF+gLucZ1ewsluuK
A6+ArM3A5UtVzZ2nR2I/WI++XDED9JFMO3HghP2oOn8SXJI/BAvRFY+0rsjoj5WJtoV8jwNhS3Nf
As/N6Yt+q95kMvkgyjmZBHdUaOsOdJ2bP7fd5Zsbxz1d0C4nFXvXxCOv5Xd0t09OnSJpBBbOGn7Q
KDy8/sXrtlYvfal7WOMRyBHzTaPOa4Br2L6IrgYegz4T/Lrwsjlut9lPRmmDtL7N1356mCWPn80i
lTx2LNL2UPzIibdnXZQPRecby6b17uqipkezAQugsqXnp8DNNrYpl56/fXHg4Omeu9yuf84IlOqG
Cw8FSg/LkvOGMnO5CwqCs9fPOu/Cr+//xHRXp23zgtyZrbkLQMWxnm5MUsrg1JxVozVGUCvKvTfC
g9XFTdWrVm1JqVJs4+M757rDU90sA2JWN6PMeWTnssJswaovyt0D/MHKWCg9DSu4c/dC4DxLx990
FS28D6BG1Qh8yspNdOuakhd2IWr9NFJHKrMFC0CUOhs7gyw4DctRU2LZvmNSurZGahyA/9kRnY1l
GpFytBmgO9HzBPBHu7uR0mzCcoxYPRUVnYuqnJqznCvtmtfmNSVNr56+8nCBZb8SnanAPoCash1J
QR6z9Lt88+aqYZ9YI5Um4q9amgZvaomcewqWzLXzkYZ+wRy1+uoKvLujTJLp66SY7Zad9L43Izo7
W7BOlYE+tLFN4p6Wfhpa5kuyrz8EsSqyKRzpe/3toob3ETpsfMWdyEr600dWdTPRVF4aVsCqWU31
u2k14rMKBJEz27KrAmhSglkEhSBddoYmmIaVsHJwuQeeQ0jauOmg6ze1gqBit9QYqRT12tiJaCwN
y26xqM6ACV177Lok/Z6YGxuXe0GmW3mi1gvZEcrqae6I09ULS3nXqlmVawe0YFcnV53T9zKeZ0qx
zBa8OWLXtxFoeaN6gVk2ti51nTS9DCxXsmhlzZ7yPvOb/NnKTZha8XLXjNOtiH7eMl7bHYuasnZA
LSc3fgkWyTSQinZ630svHXZaOABMz+1xvpm+8KZyDgB2heSkKQR4qHXZPOAWGxdRGXaXeFQyapWq
KbzXtEJ65yyTNGEsJ2vQ7z7SsvIKgC1l0iXwjp2bLK0Jl/qMmmewT9h/lVVY6lhVg+XUN88ArC1r
OJZBx3IdnG3/sWvpxQAKv7MK6CQrA67cZ4FrLOPEjTu1NVucrm85MQWRpXZ9703VTpdoVHkqg1gX
plKu3etblpdjATmROHTo6DtfDL7XoVW2AUSpvWfxi1ar65HIQ86tDLFZPEA7+sGKhBbWYpvg9iof
pP7TfPUr0RPb96vGBiw8nVQ88vsDne+vafvgj9+Y2dHTE9h31Hr3Rx0XD2YLVGlYfYrcY2l+xHPY
vxcGbIVtaF5+g4rUZhr8pf91ONwJ/hxPwu3yJBwn6Y6m4p5E8sziXfFs4eOTh63pbaoONa7OFqzK
5p5/Rbjfylj0sW1FgTtgwIbF2uKmOoQXMg1+8RTBAbrjCU9HTyTQGYvnDAYK4JWDSnToR8kJR9zV
ZEk3tkQvQbjX1t6BTemfzyyIS/JW4FAmHbhwkuCzLKREU7DrA0XP9n1U+fq9RfVt2QBVGlafA89h
mwvDnoaiwMtnhVVd+NIRVa0CLFOZ3k3T+dPsy+Xvn1T2Hh6ElrKuuviFn2UDVI2qmeyOPQt6pb2X
9tvCH3Sr5VvFTTtBP49lvRzgsgLB67YH9sZR5fUjHwFT4am1ocb7sgGqarO6Xm2NPqXYP40RXl9Q
5N82LCyA6lBTA46uAKzSDY+BK61S44+097Cyu01xxLu+urDxqyLWe7XWqmjVYPzcnq1kePRIlDsG
Hv0echOvuqTpNwauRrAqv146VZiWaz+6PG70pG/1u7t0U/lNuyKLxhpU+c7uq41G9yJSkaHrz+pC
/h1nALTx3Ni43BvNY42I3Mcwk2N7tPeIpDPEGBEgPzijPXj+v6vbU5A+IOeoykaXK7GutjB4hFGo
4uWuGRJ31SDcjl2i3FdtOZq8cktx8OiIYKX1/ealM5OYezBy+1Db/G8dV/774Jm0BMjPm3YiMPOu
iNc//2ylkajAJsG1sTaUk9Hxo4rW7k+DuVV6z5SN5Ch5SuDawUZVxrDSqgmX+gLu3HJBVwJlcOYu
z84PlP0fKl63aMA3pdMXXNTun/K5qcadn8nh2D8DYVHCjnLAhTnqlvixiKSSXuOdpOgsx5FLgc8A
/whcNJL7SUtVv1VfHFh/tt+Pyfb4upbrpxiVuSJOPkjQJcQ6E0T2Ju6/3fg/sWr0EbIvQX9QFwrc
NbRNFlW1WV3xc6PPIGQtdRkTCT9eUOi7bbgXn7L+OkrVZnXFZkaflN4j4X9xUuTh+iLvtxEZdtky
bm+FVbZE7zh1UG50b0KMnWKicnddse+/bB3G9X3DG1p6SlX4EcqccUfT/6b3p9S5uaE49/eZ+Fme
LBsb1YX8O3ISvstF9T+xrd2PreKKPOxJ+q7KFBRM4DvSFb/tXoCRBwRZOQ7hFNUGx+26t2GxN5MC
Zz9N+Nv35a2RRcaRuxBuwL7Ma6sYyvOoeWhbiff10TY24bDSunH3yalOPGc1aAW9L5qPFFwUaEV5
TlLR5+vKppwYqz7+xcDqq/I9GjCRWAijC1AuE+UyNUxD5RzQU2mWdIB2ohzE8JbCn1wOu9pTvt1j
/c8v0vp/Ci1IyG6NV4UAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDctMTlUMTM6NDg6MzIrMDA6
MDCo9EmgAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA3LTE5VDEzOjQ4OjMyKzAwOjAw2anxHAAA
ABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII="
      />
    </svg>
  );
});
