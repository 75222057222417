import { FieldHookConfig, useField } from 'formik';
import {
  CheckBoxGroup,
  CheckBoxGroupProps,
  CheckEntity,
} from '../CheckBoxGroup';

//export type FormCheckBoxGroupProps = FieldHookConfig<CheckEntity[]> & CheckBoxGroupProps;

export const FormCheckBoxGroup = ({
  label,
  ...props
}: FieldHookConfig<CheckEntity[]> & CheckBoxGroupProps) => {
  const [field, meta, helpers] = useField<CheckEntity[]>(props);
  const handleChange = (value, event) => {
    const val = value as CheckEntity;
    helpers.setValue(
      field.value.map(f => {
        if (f.Id === val.Id) {
          f.checked = val.checked;
        }
        return f;
      }),
      true,
    );
    if (props.onChange !== undefined) {
      props.onChange(value, event);
    }
    field.onChange(event);
  };
  return (
    <CheckBoxGroup
      label={label}
      value={field.value}
      error={meta?.error !== undefined}
      helperText={meta.error}
      info={props.info}
      onChange={handleChange}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      required={props.required}
      isRow={true}
      margin="none"
      size="medium"
      {...props}
    />
  );
};
