import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export function highlightSearchTerm(text, highlight) {
  // Split on highlight term and include term into parts, ignore case
  const matches = match(text, highlight);
  const parts = parse(text, matches);

  return (
    <span>
      {parts.map((part, index) =>
        part.highlight ? (
          <strong key={index}>{part.text}</strong>
        ) : (
          <span key={index}>{part.text}</span>
        ),
      )}
    </span>
  );
}
