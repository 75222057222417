import { RepetitiveOptions } from 'enums/RepetitiveOptions';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { every } from 'lodash';
import { IRepetitiveDto } from 'types/IRepetitiveDto';
import { dateUtils } from 'utils/date-utils';
import * as Yup from 'yup';
import { CheckEntityShema } from '.';
import { TransformDate } from './SchemaMethods';

export const RepetitiveSchema: Yup.SchemaOf<IRepetitiveDto> = Yup.object({
  StartDate: Yup.date()
    .transform(TransformDate)
    .default(dateUtils.dateOrStringToDate(new Date())),
  RecurringEndDate: Yup.date()
    .label(i18next.t(translations.Until))
    .when('RecurringEvents', {
      is: val =>
        val !== null && val.Id !== (RepetitiveOptions.DoNotRepeat as number),
      then: Yup.date()
        .min(
          Yup.ref('StartDate'),
          i18next.t(translations.err_RepeatUntilDateLessThanStart) as string,
        )
        .required(i18next.t(translations.err_AlertInvalidRecurringUntilDate)),
    }),
  RecurringEvents: Yup.mixed(),
  RepeatsEveryDay: Yup.mixed(),
  RepeatsEveryPeriod: Yup.mixed(),
  RepeatsMonthlyBy: Yup.mixed(),
  RepeatsOnDays: Yup.array()
    .of(CheckEntityShema)
    .test({
      name: i18next.t(translations.RepeatsBy),
      message: i18next.t(translations.err_PepeatsOnDaysRequired),
      test: val => !every(val, ['checked', false]),
    }),
});
