import { Avatar, makeStyles } from '@material-ui/core';
import { Link } from 'app/components/ExternalLink';
import { selectAppSettings, selectLoading } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppSettings } from 'types/AppSettings';
import { toRootedURL } from 'utils/url-utils';

export interface CustomerLogoProps {
  height?: string | number;
}
const useStyles = makeStyles(theme => ({
  root: {
    '&>img': {
      objectFit: 'contain',
    },
  },
}));
export const CustomerLogo = React.memo(function CustomerLogo(
  props: CustomerLogoProps,
) {
  const { t } = useTranslation();
  const appSettings: AppSettings | null = useSelector(selectAppSettings);
  const isLoading = useSelector(selectLoading);
  const classes = useStyles();
  const [customerLink, setCustomerLink] = React.useState<string | undefined>(
    undefined,
  );
  const [customerImg, setCustomerImg] = React.useState<string | undefined>(
    undefined,
  );
  React.useEffect(() => {
    let active = !isLoading;
    if (active) {
      let link = appSettings?.TopCustomerLogoLink;
      let img = appSettings?.TopCustomerLogo;
      if (link !== undefined && link !== '') {
        setCustomerLink(link);
      }
      if (img !== undefined && img !== '') {
        setCustomerImg(img);
        console.log(toRootedURL(img));
      }
    } else {
      setCustomerLink(undefined);
      setCustomerImg(undefined);
    }
    return () => {
      active = false;
    };
  }, [appSettings, isLoading]);
  return (
    <Link
      href={customerLink || 'https://bookit-lab.com'}
      aria-label="customer logo"
    >
      <Avatar
        className={classes.root}
        variant="square"
        alt={t(translations.CompanyLogo)}
        src={toRootedURL(customerImg || '/ctl/DS/Default/logo.png')}
        style={{ height: props.height || 36, width: 'auto' }}
      />
    </Link>
  );
});
