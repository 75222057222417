import { BaseTextFieldProps, capitalize } from '@material-ui/core';
import { BaseTimePickerProps, useUtils } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FormControl from 'app/components/Forms/FormControls/FormControl';
import FormHelperText from 'app/components/Forms/FormControls/FormHelperText';
import { InputLabel } from '@material-ui/core';
import * as React from 'react';

import clsx from 'clsx';
import { Entity } from 'types/common';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { dateUtils } from 'utils/date-utils';
import { validateError } from 'app/components/DatePicker/utils/pickerDateValidate';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { DateDropDownValidationProps } from '../DateDropDown';
import {
  defaultHourValue12,
  defaultHourValue24,
  getHoursEntityFromDate,
  HoursPicker,
} from 'app/components/pickers/StaticOptionsPickers/HoursPicker';
import {
  defaultMinutes,
  getMinutesEntityFromDate,
  MinutesPicker,
} from 'app/components/pickers/StaticOptionsPickers/MinutesPicker';
import {
  AmPmPicker,
  defaultAmPm,
  getAmPmEntityFromDate,
} from 'app/components/pickers/StaticOptionsPickers/AmPmPicker';

export interface TimeDropDownProps
  extends DateDropDownValidationProps,
    BaseTimePickerProps,
    Pick<
      BaseTextFieldProps,
      | 'fullWidth'
      | 'helperText'
      | 'required'
      | 'disabled'
      | 'name'
      | 'id'
      | 'label'
      | 'error'
      | 'size'
    > {
  value?: Date | string | null;
  onChange: (
    date: MaterialUiPickersDate | null,
    withCompletion?: boolean,
  ) => void;
  inputVariant?: 'filled' | 'standard' | 'outlined';
  info?: string;
  className?: string;
  hoursDisabled?: boolean;
  minutesDisabled?: boolean;
  onError?: (
    error: React.ReactNode,
    value: MaterialUiPickersDate | ParsableDate,
  ) => void;
}

export function TimeDropDown(props: TimeDropDownProps) {
  const {
    value,
    onChange,
    inputVariant,
    id,
    name,
    fullWidth,
    helperText,
    disabled,
    label,
    error,
    size,
    hoursDisabled,
    minutesDisabled,
    ampm,
    minutesStep,
    onError,
  } = props;
  const { newDate } = useSystemDate();
  const utils = useUtils();
  const inputLabelId = label && id ? `${id}-label` : undefined;
  const helperTextId = id ? `${id}-helper-text` : undefined;
  const sizeClass = `inputSize${capitalize(size ?? 'large')}`;
  const dateTimeValue = React.useMemo(() => {
    return value;
  }, [value]);
  const [dateSelected, setDateSelected] = React.useState<
    Date | string | null | undefined
  >(value);
  const [hoursSelected, setHoursSelected] = React.useState(
    getHoursEntityFromDate(ampm ?? false, dateTimeValue),
  );
  const [minutesSelected, setMinutesSelected] = React.useState(
    getMinutesEntityFromDate(dateTimeValue),
  );
  const [ampmSelected, setAmpmSelected] = React.useState(
    getAmPmEntityFromDate(dateTimeValue),
  );
  const [isHourOpen, setIsHourOpen] = React.useState<boolean>(false);
  const [isMinuteOpen, setIsMinuteOpen] = React.useState<boolean>(false);
  const [isAmPmOpen, setIsAmPmOpen] = React.useState<boolean>(false);
  const someOpen = React.useMemo(() => {
    return isHourOpen || isMinuteOpen || isAmPmOpen;
  }, [isHourOpen, isMinuteOpen, isAmPmOpen]);
  const [innerError, setInnerError] = React.useState<
    React.ReactNode | undefined
  >(undefined);

  React.useEffect(() => {
    setHoursSelected(() =>
      getHoursEntityFromDate(ampm ?? false, dateTimeValue),
    );
    setMinutesSelected(() => getMinutesEntityFromDate(dateTimeValue));
    setAmpmSelected(() => getAmPmEntityFromDate(dateTimeValue));
  }, [ampm, dateTimeValue]);

  React.useEffect(() => {
    if (dateSelected !== null && dateSelected !== undefined) {
      let time = dateUtils.set(dateUtils.dateOrStringToDate(dateSelected), {
        hours: hoursSelected.Id,
        minutes: minutesSelected.Id,
      });
      let validateDate = dateUtils.convertToMeridiem(
        time,
        ampmSelected.Id === 'am' ? 'am' : 'pm',
        ampm ?? false,
      );
      let error = validateError(
        validateDate,
        {
          maxDate: props.maxDate,
          minDate: props.minDate,
          disablePast: props.disablePast,
          disableFuture: props.disableFuture,
          maxDateMessage: props.maxDateMessage,
          minDateMessage: props.minDateMessage,
          invalidDateMessage: props.invalidDateMessage,
          strictCompareDates: props.strictCompareDates,
        },
        utils,
      );
      if (!!error) {
        setInnerError(error);
        if (onError) {
          onError(error, validateDate);
        }
      } else {
        setInnerError('');
        if (onError) {
          onError('', validateDate);
        }
      }
    } else {
      setInnerError('');
      if (onError) {
        onError('', null);
      }
    }
  }, [
    ampm,
    ampmSelected.Id,
    dateSelected,
    hoursSelected.Id,
    minutesSelected.Id,
    onError,
    props.disableFuture,
    props.disablePast,
    props.invalidDateMessage,
    props.maxDate,
    props.maxDateMessage,
    props.minDate,
    props.minDateMessage,
    props.strictCompareDates,
    utils,
  ]);
  const handleChange = React.useCallback(
    (
      hour: number,
      minute: number,
      ampmValue: 'am' | 'pm',
      withCompletion?: boolean,
    ) => {
      if (dateSelected !== null && dateSelected !== undefined) {
        let time = dateUtils.set(dateUtils.dateOrStringToDate(dateSelected), {
          hours: hour,
          minutes: minute,
        });
        let finalDate = dateUtils.convertToMeridiem(
          time,
          ampmValue,
          ampm ?? false,
        );
        let hasError = validateError(
          finalDate,
          {
            maxDate: props.maxDate,
            minDate: props.minDate,
            disablePast: props.disablePast,
            disableFuture: props.disableFuture,
            maxDateMessage: props.maxDateMessage,
            minDateMessage: props.minDateMessage,
            invalidDateMessage: props.invalidDateMessage,
            strictCompareDates: props.strictCompareDates,
          },
          utils,
        );
        if (!hasError || hasError === '') {
          onChange(finalDate, withCompletion);
        }
      } else {
        let time = dateUtils.set(newDate(), { hours: hour, minutes: minute });
        let finalDate = dateUtils.convertToMeridiem(
          time,
          ampmValue,
          ampm ?? false,
        );
        setDateSelected(finalDate);
        let hasError = validateError(
          finalDate,
          {
            maxDate: props.maxDate,
            minDate: props.minDate,
            disablePast: props.disablePast,
            disableFuture: props.disableFuture,
            maxDateMessage: props.maxDateMessage,
            minDateMessage: props.minDateMessage,
            invalidDateMessage: props.invalidDateMessage,
            strictCompareDates: props.strictCompareDates,
          },
          utils,
        );
        if (!hasError || hasError === '') {
          onChange(finalDate, withCompletion);
        }
      }
    },
    [
      ampm,
      dateSelected,
      newDate,
      onChange,
      props.disableFuture,
      props.disablePast,
      props.invalidDateMessage,
      props.maxDate,
      props.maxDateMessage,
      props.minDate,
      props.minDateMessage,
      props.strictCompareDates,
      utils,
    ],
  );
  const handleHoursChange = React.useCallback(
    (val: Entity<number>) => {
      setHoursSelected(val);
      handleChange(
        val.Id,
        minutesSelected.Id,
        ampmSelected.Id === 'am' ? 'am' : 'pm',
      );
    },
    [ampmSelected.Id, handleChange, minutesSelected.Id],
  );
  const handleMinutesChange = React.useCallback(
    (val: Entity<number>) => {
      setMinutesSelected(val);
      handleChange(
        hoursSelected.Id,
        val.Id,
        ampmSelected.Id === 'am' ? 'am' : 'pm',
      );
    },
    [ampmSelected.Id, handleChange, hoursSelected.Id],
  );
  const handleAmPmChange = React.useCallback(
    (val: Entity<string>) => {
      setAmpmSelected(val);
      handleChange(
        hoursSelected.Id,
        minutesSelected.Id,
        val.Id === 'am' ? 'am' : 'pm',
      );
    },
    [handleChange, hoursSelected.Id, minutesSelected.Id],
  );
  return (
    <React.Fragment>
      <FormControl
        className={clsx('date-dropdown-picker', sizeClass, {
          withLabel: !!label,
        })}
        disabled={disabled}
        error={error || Boolean(innerError)}
        fullWidth={fullWidth}
        required={props.required}
        variant={inputVariant || 'standard'}
        size={'medium'}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          const target = event.target as any; // as HTMLInputElement;

          if (event.key === 'Enter') {
            if (!!target && target.nodeName === 'INPUT') {
              if (!someOpen && !innerError) {
                handleChange(
                  hoursSelected.Id,
                  minutesSelected.Id,
                  ampmSelected.Id === 'am' ? 'am' : 'pm',
                  true,
                );
              }
            }
          }
        }}
      >
        {label && (
          <InputLabel
            htmlFor={id}
            id={inputLabelId}
            className={clsx('date-dropdown-picker', sizeClass)}
            required={props.required}
            error={error || Boolean(innerError)}
            disabled={props.disabled}
          >
            {label}
          </InputLabel>
        )}
        <div className={'date-dropdown-picker-inputs'}>
          <HoursPicker
            useAmPm={ampm}
            defaultValue={ampm ? defaultHourValue12 : defaultHourValue24}
            value={hoursSelected}
            onChange={hour =>
              handleHoursChange(
                hour ?? (ampm ? defaultHourValue12 : defaultHourValue24),
              )
            }
            id={`${id ?? 'hourId'}_hour`}
            name={`${name ?? 'hourName'}_hour`}
            variant={inputVariant ?? 'standard'}
            disabled={disabled || hoursDisabled}
            ariaLabel={`${label ?? 'hour'} select`}
            error={error}
            size={size || 'small'}
            disableClearable
            autoFocus={true}
            placeholder={'HH'}
            setSomeOpen={setIsHourOpen}
            // disableListWrap
            // disableCloseOnSelect
            // ListboxProps={{
            //   style: { maxHeight: '20vh' },
            // }}
            // isOpen={true}
          />
          <MinutesPicker
            defaultValue={defaultMinutes}
            value={minutesSelected}
            onChange={minute => handleMinutesChange(minute ?? defaultMinutes)}
            id={`${id ?? 'minuteId'}_minute`}
            name={`${name ?? 'minuteName'}_minute`}
            variant={inputVariant ?? 'standard'}
            disabled={disabled || minutesDisabled}
            ariaLabel={`${label ?? 'minute'} select`}
            error={error}
            size={size || 'small'}
            minutesStep={minutesStep}
            disableClearable
            placeholder={'mm'}
            setSomeOpen={setIsMinuteOpen}
            // disableCloseOnSelect
            // ListboxProps={{
            //   style: { maxHeight: '20vh' },
            // }}
            // isOpen={true}
          />
          {ampm && (
            <AmPmPicker
              defaultValue={ampmSelected}
              value={ampmSelected}
              onChange={ampmVal => handleAmPmChange(ampmVal ?? defaultAmPm)}
              id={`${id ?? 'ampmId'}_ampm`}
              name={`${name ?? 'ampmName'}_ampm`}
              variant={inputVariant ?? 'standard'}
              disabled={disabled}
              ariaLabel={`${label ?? 'ampm'} select`}
              error={error}
              size={size || 'small'}
              placeholder={'AM/PM'}
              disableClearable
              setSomeOpen={setIsAmPmOpen}
              // disableCloseOnSelect
              // ListboxProps={{
              //   style: { maxHeight: '20vh' },
              // }}
              // isOpen={true}
            />
          )}
        </div>
        {(!!helperText || Boolean(innerError)) && (
          <FormHelperText
            id={helperTextId}
            className={clsx('date-dropdown-picker', sizeClass)}
            disabled={disabled}
            error={error || Boolean(innerError)}
            required={props.required}
          >
            {helperText || innerError}
          </FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
}
