import * as React from 'react';
import {
  IconButton as MatIconButton,
  IconButtonProps as MatIconButtonProps,
  Link,
} from '@material-ui/core';
import clsx from 'clsx';
import { IPathAndQuery, toRootedURL } from 'utils/url-utils';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { IconProp } from 'app/AllIcons';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { capitalize } from 'lodash';
import { ButtonsVariant } from '../Button';

export interface IconButtonPropsBase
  extends Omit<MatIconButtonProps, 'size' | 'color'> {
  disabled?: boolean;
  children?: React.ReactNode;
  href?: string | IPathAndQuery;
  to?: string;
  icon?: IconProp;
}
export interface IPrimaryIconStyleThemeProps {
  size?: 'large' | 'medium' | 'small' | 'xs';
  variant?: ButtonsVariant;
  shape?: 'circle' | 'square';
}
export type IconButtonProps = IconButtonPropsBase & IPrimaryIconStyleThemeProps;

export function IconButton(props: IconButtonProps) {
  const {
    children,
    disabled,
    size = 'small',
    variant = 'main',
    shape = 'square',
    href,
    to,
    title,
    icon,
    ...other
  } = props;
  const history = useHistory();
  const handleClick = (e: any) => {
    if (to) {
      history.push(to);
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };
  const absolutePath =
    href === undefined
      ? undefined
      : typeof href === 'string'
      ? toRootedURL(href)
      : toRootedURL(
          (href as IPathAndQuery).path,
          (href as IPathAndQuery).search,
        );

  return href ? (
    <WithTitle title={title}>
      <Link href={absolutePath}>
        <MatIconButton
          className={clsx(
            props.className,
            `variant${capitalize(variant)} iconSize${capitalize(
              size,
            )} shape${capitalize(shape)}`,
          )}
          disabled={disabled}
          disableRipple
          onClick={event => {
            event.stopPropagation();
            handleClick(event);
          }}
          {...other}
        >
          {!!children ? children : <Icon icon={icon ?? 'question-circle'} />}
        </MatIconButton>
      </Link>
    </WithTitle>
  ) : (
    <WithTitle title={title}>
      <MatIconButton
        className={clsx(
          props.className,
          `variant${capitalize(variant)} iconSize${capitalize(
            size,
          )} shape${capitalize(shape)}`,
        )}
        onClick={event => {
          event.stopPropagation();
          handleClick(event);
        }}
        disabled={disabled}
        disableRipple
        {...other}
      >
        {!!children ? children : <Icon icon={icon ?? 'question-circle'} />}
      </MatIconButton>
    </WithTitle>
  );
}

function WithTitle({
  children,
  title,
}: React.PropsWithChildren<{
  title?: string;
}>) {
  if (title) {
    return (
      <Tooltip title={title}>
        <span>{children}</span>
      </Tooltip>
    );
  } else {
    return <>{children}</>;
  }
}
