import { isEmptyOrWhitespace } from 'utils/typeUtils';

export interface TooltipLineProps {
  label: string;
  value: string | number | JSX.Element | JSX.Element[] | null | undefined;
}
export function TooltipLine({ label: lablel, value }: TooltipLineProps) {
  if (value === undefined) {
    return null;
  }
  if (value === null) {
    return null;
  }
  if (typeof value === 'string' && isEmptyOrWhitespace(value)) {
    return null;
  }
  return (
    <div>
      <b>{lablel}</b>:&nbsp;
      <span>{value}</span>
    </div>
  );
}
