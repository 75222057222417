import {
  bindMenu,
  usePopupState,
  bindTrigger,
  anchorRef,
} from 'material-ui-popup-state/hooks';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import * as React from 'react';
import { Menu, useTheme } from '@material-ui/core';
import { useStyles } from './useStyles';
import { StyledMenuItem } from './StyledMenuItem';
import { ReactActionsProps } from './index';

export const ClickableActions = React.forwardRef<any, ReactActionsProps>(
  function ClickableActions(props, ref) {
    const { id, items, size, iconVariant, icon } = props;
    const classes = useStyles();
    const theme = useTheme();
    const popupState = usePopupState({
      variant: 'popper',
      popupId: id,
    });
    const buttons = React.useMemo(() => {
      return items.filter(
        f =>
          f !== null &&
          f !== false &&
          f !== undefined &&
          f !== true &&
          f !== '',
      );
    }, [items]);
    return (
      <div ref={anchorRef(popupState)} className={classes.root}>
        <IconButton
          variant={iconVariant || 'ghost'}
          shape="square"
          aria-label="Open Menu"
          size={size}
          {...bindTrigger(popupState)}
        >
          <Icon icon={!!icon ? icon : 'ellipsis-v'} />
        </IconButton>

        <Menu
          {...bindMenu(popupState)}
          ref={ref}
          container={document.fullscreenElement ?? document.body}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          closeAfterTransition={false}
          onClose={() => popupState.close()}
        >
          {buttons.map((item, index) => {
            return !!item ? (
              <StyledMenuItem
                key={index}
                theme={theme}
                button={false}
                ref={ref}
                className={'hidden-if-empty'}
                /** Ripple is disabled for non button PageActions, e.g. Timeline grouping drop down list. Otherwise the ripple over drop down is pretty confusing */
                //disableRipple={props.disableRipple || true}
                onClick={() => {
                  if (!props.keepOpen) {
                    popupState.close();
                  }
                }}
              >
                {item}
              </StyledMenuItem>
            ) : (
              <></>
            );
          })}
        </Menu>
      </div>
    );
  },
);
