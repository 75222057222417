/**
 *
 * Asynchronously loads the component for Charges Page
 *
 */
import { lazyLoad } from 'utils/loadable';

export const Charges = lazyLoad(
  () => import('./index'),
  module => module.ChargesPage,
);
