/**
 *
 * Asynchronously loads the component for BudgetsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const BudgetsPage = lazyLoad(
  () => import('./index'),
  module => module.BudgetsPage,
);
