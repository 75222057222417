/**
 *
 * Asynchronously loads the component for LabelTypesPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const LabelTypesPage = lazyLoad(
  () => import('./index'),
  module => module.LabelTypesPage,
);
