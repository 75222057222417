import { httpClient } from 'api/HttpClient';
import { IFileDto } from 'api/odata/generated/entities/IFileDto';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { FormInputFile } from 'app/components/Forms/FormInputFile';
import React from 'react';
import { Identifiable } from 'types/common';
import { CustomFormFieldProps } from '../CustomFormFields';

export function CustomFormFileUpload({
  formField,
  readonly,
  disabled,
  ...props
}: CustomFormFieldProps) {
  return (
    <FormInputFile
      {...props}
      disabled={readonly || disabled}
      label={formField.Label}
      key={formField.Id}
      referenceId={formField.Id}
      multiple={formField.IsMultiple}
      helperText={formField.Description ?? undefined}
      showPreview={true}
      fullWidth
    />
  );
}
const url = '/api/odata/v4/Files';
export const InitFormFiles = (
  id: string,
  formField: IFormFieldDto | undefined,
) => {
  const initids = id
    .split(',')
    .filter(f => f !== '')
    .map(item => {
      return { Id: item };
    }) as Identifiable<string>[];
  if (initids.length > 0) {
    let filter: string | undefined = '';
    if (formField) {
      if (formField.IsMultiple) {
        filter = new Condition(
          'Id',
          ODataOperators.Includes,
          initids,
        ).toString();
      } else {
        filter = `Id eq ${initids[0].Id}`;
      }
    }
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: filter,
      $select: 'Id,Name,Size,TypeId',
      $skip: 0,
      $top: initids.length,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as IFileDto[]);
  } else {
    return new Promise<IFileDto[]>((resolve, reject) => {
      resolve([] as IFileDto[]);
    });
  }
};
