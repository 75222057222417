import { selectAppTimeZone } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import { dateUtils } from 'utils/date-utils';
import React from 'react';

export interface ISystemDate {
  newDate: () => Date;
  getOffsetDate: (date: Date | string | undefined | null) => Date;
  getOffset: (date: Date | undefined) => Date;
  systemOffset: number;
  currentTimezoneOffser: number;
  withoutOffset: (date: Date | string | undefined | null) => Date;
  getOffsetDateOrNull: (
    date: Date | string | number | object | undefined | null,
  ) => Date | null;
}

export const useSystemDate = (): ISystemDate => {
  const appTimeZone = useSelector(selectAppTimeZone);
  const systemOffset = appTimeZone?.TimezoneOffsetMinutes ?? 0;
  const currentTimezoneOffser = new Date().getTimezoneOffset();
  const getOffset = React.useCallback(
    (date: Date | undefined): Date => {
      const currentDateOffset = (date || new Date()).getTimezoneOffset();
      const appNow = dateUtils.addMinutes(
        date || new Date(),
        systemOffset + currentDateOffset,
      );
      return appNow;
    },
    [systemOffset],
  );
  const newDate = React.useCallback(() => {
    return getOffset(undefined);
  }, [getOffset]);
  const getOffsetDate = React.useCallback(
    (date: Date | string | undefined | null) => {
      if (date === null || date === undefined) {
        return newDate();
      } else {
        let parsedDate = dateUtils.dateOrStringToDate(date);
        return getOffset(parsedDate);
      }
    },
    [getOffset, newDate],
  );
  const getOffsetDateOrNull = React.useCallback(
    (date: Date | string | number | object | undefined | null) => {
      if (date === null || date === undefined) {
        return null;
      } else {
        let localDate = dateUtils.dateFnsUtils.date(date);
        if (localDate === null) return null;
        let parsedDate = dateUtils.dateOrStringToDate(localDate);
        return getOffset(parsedDate);
      }
    },
    [getOffset],
  );
  const withoutOffset = React.useCallback(
    (date: Date | string | undefined | null) => {
      const withOffset =
        date === null || date === undefined
          ? newDate()
          : dateUtils.dateOrStringToDate(date);
      return dateUtils.addMinutes(
        withOffset,
        (withOffset.getTimezoneOffset() + systemOffset) * -1,
      );
    },
    [newDate, systemOffset],
  );
  return {
    newDate,
    getOffsetDate,
    getOffset,
    systemOffset,
    currentTimezoneOffser,
    withoutOffset,
    getOffsetDateOrNull,
  };
};
