/**
 *
 * Asynchronously loads the component for KioskProjectsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PrivateKioskProjectsRoute = lazyLoad(
  () => import('./index'),
  module => module.PrivateKioskProjectsRoute,
);
