/**
 *
 * DatesRangeFilter
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IFilterComponentProps } from 'app/components/BasicTable/Filters/IFilterComponentProps';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { PresetDatesRange } from 'types/PresetDatesRange';
import {
  getPeriodTypesEntity,
  PeriodTypes,
  PeriodTypesEntity,
} from 'app/components/DatePeriods';
import { DatesRange } from 'types/DatesRange';
import { DatePeriodsPicker } from 'app/components/pickers/DatePeriodsPicker';
import { BookitDateTimePicker } from 'app/components/BookitDatePicker/BookitDateTimePicker';

/**
 * Date picker to be used as a BasicTable filter component
 */
const allPeriods: Array<PeriodTypes> = [
  'CustomDate',
  'Today',
  'TodayTomorrow',
  'ThisWeek',
  'NextWeek',
  'ThisMonth',
  'NextMonth',
  'ThisQuarter',
  'NextQuarter',
  'ThisYear',
  'NextYear',
  'LastWeek',
  'LastMonth',
  'LastQuarter',
  'LastYear',
];
const noFuturePeriods: Array<PeriodTypes> = [
  'CustomDate',
  'ThisWeek',
  'ThisMonth',
  'ThisQuarter',
  'ThisYear',
  'LastWeek',
  'LastMonth',
  'LastQuarter',
  'LastYear',
];

export interface DatesRangeFilterProps extends IFilterComponentProps {
  noFuturePeriods?: boolean;
}
export const DatesRangeFilter = (props: DatesRangeFilterProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<PresetDatesRange>(
    props.value !== undefined ? props.value : new PresetDatesRange(),
  );
  const handleStartOnChange = (date: MaterialUiPickersDate) => {
    const changedValue = Object.assign(new PresetDatesRange(), value, {
      start: date,
    });
    setValue(changedValue);
    if (props.onChange !== undefined) {
      props.onChange(changedValue);
    }
  };
  const handleEndOnChange = (date: MaterialUiPickersDate) => {
    const changedValue = Object.assign(new PresetDatesRange(), value, {
      end: date,
    });
    setValue(changedValue);
    if (props.onChange !== undefined) {
      props.onChange(changedValue);
    }
  };

  const handleTypeChange = (periodValue: PeriodTypesEntity | null) => {
    let changedValue = Object.assign(
      new PresetDatesRange(),
      {
        type:
          (periodValue as PeriodTypesEntity) ??
          (getPeriodTypesEntity('CustomDate') as PeriodTypesEntity),
      },
      DatesRange.fromPreset(
        (periodValue as PeriodTypesEntity) ??
          (getPeriodTypesEntity('CustomDate') as PeriodTypesEntity),
        null,
        null,
      ),
    );
    setValue(state => changedValue);
    props.onChange(changedValue);
  };
  return (
    <>
      {value !== undefined && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 8,
            width: 400,
          }}
        >
          <DatePeriodsPicker
            isOpen={props.open !== undefined ? props.open : true}
            onChange={handleTypeChange}
            value={
              props.value instanceof PresetDatesRange
                ? (props.value.type as PeriodTypesEntity | undefined)
                : (props.value as PeriodTypesEntity | undefined)
            }
            periods={
              props.noFuturePeriods !== undefined && props.noFuturePeriods
                ? noFuturePeriods
                : allPeriods
            }
            disableClearable={true}
            placeholder={t(translations.Forever)}
            id="datesrangeid"
            size="small"
            aria-label="Date Periods select"
          />
          {/* <DatePeriods
            value={value?.type}
            size="small"
            onChange={handleTypeChange}
          /> */}
          {value !== undefined &&
            value !== null &&
            value?.type !== undefined &&
            value.type.Id === 'CustomDate' && (
              <>
                <BookitDateTimePicker
                  value={props.value === null ? null : value.start}
                  maxDate={value.end ?? undefined}
                  onChange={handleStartOnChange}
                  label={t(translations.From)}
                  size="small"
                  labelFunc={props.labelFunc}
                  inputVariant={'standard'}
                  minutesStep={15}
                  inputProps={{ 'aria-label': 'Start Period' }}
                  id="datesrangestartId"
                  saveOnSelect
                  maskIfEmpty={false}
                  clearable={
                    props.dateTimeClear === undefined ||
                    props.dateTimeClear === null
                      ? true
                      : props.dateTimeClear
                  }
                />

                {/* <Icon
                  icon="arrow-from-left"
                  color="primary"
                  style={{ marginLeft: '4px', marginRight: '4px' }}
                /> */}

                <BookitDateTimePicker
                  value={props.value === null ? null : value.end}
                  minDate={value.start ?? undefined}
                  onChange={handleEndOnChange}
                  labelFunc={props.labelFunc}
                  label={t(translations.To)}
                  inputVariant={'standard'}
                  minutesStep={15}
                  size="small"
                  inputProps={{ 'aria-label': 'End Period' }}
                  id="datesrangeendId"
                  saveOnSelect
                  maskIfEmpty={false}
                  clearable={
                    props.dateTimeClear === undefined ||
                    props.dateTimeClear === null
                      ? true
                      : props.dateTimeClear
                  }
                />
              </>
            )}
        </div>
      )}
    </>
  );
};
export default DatesRangeFilter;
