/**
 *
 * ServiceRequestRowsTable
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useDispatch } from 'react-redux';
import { useRequestSamplesSlice } from 'app/pages/Samples/RequestSamplesPage/slice';

import {
  Column,
  Row,
  useExpanded,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
} from 'react-table';
import { AddServiceRequestRowButton } from './components/AddServiceRequestRowButton';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import {
  FieldArray,
  FormikProps,
  ArrayHelpers,
  FastFieldProps,
  FastField,
} from 'formik';
import {
  IServiceRequestDetailsFormModel,
  IServiceRequestTableRowModel,
  IServiceRequestTableRowValueModel,
} from '../../RequestSamplesPage/slice/types';
import { ColumnTypeExtension, getTableColumns } from './getTableColumns';
import { TableToolbar } from 'app/components/BasicTable/BasicFilter/TableToolbar';
import { UpdateServiceRequestRowValue } from '../ServiceRequestRowEditableCell';
import { serializeCustomFormRowValue } from 'app/components/CustomForm/CustomFormUtils';
import styled from 'styled-components';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Table,
} from '@material-ui/core/index';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import TablePagination from '@material-ui/core/TablePagination';
import { usePagination, PluginHook } from 'react-table';
import { useStyles } from 'app/components/BasicTable/styles';
import TablePaginationActions from 'app/components/BasicTable/TablePaginationActions';
import { tableSectionStyles } from '../../RequestSamplesPage';
import clsx from 'clsx';
import { ServiceRequestRowComments } from './components/ServiceRequestComments';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { ServiceRequestTableColumnsCollection } from '../../RequestSamplesPage/slice/utils/ServiceRequestTableColumn';
import {
  ExportRows,
  ServiceRequestRowsImport,
} from './components/ServiceRequestRowsImport';
import { BulkUpdate } from './components/BulkUpdate';
import { addRowSelect } from 'app/components/BasicTable/ControlledTable/addRowSelect';
import {
  ColumnsSelect,
  getHiddenColumns,
  setHiddenColumns,
} from './components/ColumnsSelect';
import { bookitColors } from 'styles/colors/bookitColors';
import { Body } from 'app/components/Typography';
import { isNullOrUndefined } from 'utils/typeUtils';
import { httpClient } from 'api/HttpClient';
import { AlertContentType } from 'app/components/BasicAlert';
import { Printing } from 'app/components/PrintToPDF';

export interface IServiceRequestRelated {
  serviceRequestId: number;
}
export interface IServiceRequestTableFormComponent {
  arrayHelpers: ArrayHelpers;
  updateMyData: UpdateServiceRequestRowValue<IServiceRequestTableRowValueModel>;
}

export interface IServiceRequestSelectedRows {
  selectedFlatRows: Array<Row<IServiceRequestTableRowModel>>;
}
export interface ServiceRequestRowsTableProps
  extends IServiceRequestRelated,
    FormikProps<IServiceRequestDetailsFormModel> {
  processing: boolean;
  isEditable: boolean;
  isAdmin: boolean;
  pageSize?: number;
  formFields: ServiceRequestTableColumnsCollection;
  serviceRequestTypeId: number;
  hasErrors?: boolean;
  printing?: Printing;
}
/**
 * "Custom Form" based table section
 */
export const ServiceRequestRowsTable = React.memo(
  function ServiceRequestRowsTable({
    serviceRequestId,
    isAdmin,
    isEditable,
    formFields: customFormColumns,
    hasErrors,
    //pageSize,
    ...props
  }: ServiceRequestRowsTableProps) {
    const { actions } = useRequestSamplesSlice();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    // TODO: server side pagination
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleChangeState = React.useCallback(
      state => {
        dispatch(actions.loadServiceRequestRows({ ...state }));
      },
      [actions, dispatch],
    );

    // TODO: server side pagination
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleSelectedRowsChange = React.useCallback(
      rows => {
        dispatch(actions.setSelected(rows));
      },
      [actions, dispatch],
    );

    useEffectOnMount(() => {
      dispatch(
        actions.loadServiceRequestRows({
          pageIndex: 0,
          pageSize: 10,
        }),
      );
    });

    const tableColumns = React.useMemo<
      Array<Column<IServiceRequestTableRowModel>>
    >(() => {
      return getTableColumns(
        customFormColumns,
        isEditable,
        t,
        serviceRequestId,
      );
    }, [customFormColumns, isEditable, serviceRequestId, t]);

    const updateMyData = React.useCallback<
      UpdateServiceRequestRowValue<IServiceRequestTableRowValueModel>
    >(
      args => {
        const column = customFormColumns.get(args.columnId);

        // dispatch(
        //   actions.updateServiceRequestRowValue({
        //     serviceRequestId: serviceRequestId,
        //     serviceRequestValue: {
        //       RowId: args.serviceRequestRowId,
        //       ColumnId: column.Id,
        //       ...serializeCustomFormRowValue(args.value),
        //     },
        //   }),
        // );

        const value = serializeCustomFormRowValue(args.value);

        // TODO: migrate value updates to the new api
        // const url = `/api/odata/v4/ServiceRequests(${action.payload.serviceRequestId})/Rows(${action.payload.serviceRequestValue.RowId})/Values`;
        const url = `/api/requests/${serviceRequestId}/tabledata/${args.serviceRequestRowId}/${column.Id}`;
        const payload =
          value.DisplayValue === null
            ? value.Value
            : {
                id: value.Value,
                text: value.DisplayValue,
              };

        try {
          httpClient.post(url, JSON.stringify(payload), {
            headers: { 'Content-Type': 'application/json' },
          });
        } catch (error) {}
      },
      [customFormColumns, serviceRequestId],
    );

    const print = React.useMemo(() => {
      return props.printing !== undefined && props.printing.printing === true;
    }, [props.printing]);
    React.useEffect(() => {
      if (
        props.printing !== undefined &&
        print &&
        props.printing.onLoading !== undefined
      ) {
        props.printing.onLoading(
          props.printing.id,
          props.values.Rows === undefined || props.values.Rows === null,
        );
      }
    }, [print, props.printing, props.values.Rows]);

    if (props.values.Rows === undefined || props.values.Rows === null) {
      return <></>;
    }
    return (
      <>
        <>
          <TableContainer key="table-container" style={tableSectionStyles}>
            <SRFormContent
              {...props}
              tableColumns={tableColumns}
              isEditable={isEditable}
              isAdmin={isAdmin}
              updateMyData={updateMyData}
              serviceRequestId={serviceRequestId}
              serviceRequestTypeId={props.serviceRequestTypeId}
              hasError={hasErrors}
            />
          </TableContainer>
        </>
      </>
    );
  },
);

interface SRFormContentProps
  extends FormikProps<IServiceRequestDetailsFormModel>,
    IServiceRequestRelated {
  tableColumns: Array<Column<IServiceRequestTableRowModel>>;
  updateMyData: UpdateServiceRequestRowValue<IServiceRequestTableRowValueModel>;
  isEditable: boolean;
  isAdmin: boolean;
  serviceRequestTypeId: number;
  hasError?: boolean;
}
const SRFormContent = React.memo(function SRFormContent({
  tableColumns,
  updateMyData,
  ...props
}: SRFormContentProps) {
  const { t } = useTranslation();
  const tablePlugins = React.useMemo(() => {
    const plugins: PluginHook<IServiceRequestTableRowModel>[] = [
      // useSortBy,
      useExpanded,
      usePagination,
    ];
    if (props.isEditable) {
      addRowSelect<IServiceRequestTableRowModel>(plugins);
    }
    return plugins;
  }, [props.isEditable]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of using 'rows', we'll use page,
    prepareRow,
    //pagination
    gotoPage,
    setPageSize,
    allColumns,
    toggleHideColumn,
    state: { pageIndex, pageSize, hiddenColumns },
    selectedFlatRows,
  } = useTable(
    {
      columns: tableColumns,
      data: props.values.Rows ?? [],
      updateMyData: updateMyData,
      autoResetPage: false,
      autoResetExpanded: false,
      initialState: {
        // sortBy: [{ id: 'Id' }],
        hiddenColumns: getHiddenColumns(
          props.serviceRequestTypeId,
          tableColumns,
        ),
      },
    },
    ...tablePlugins,
  );
  React.useEffect(() => {
    setHiddenColumns(props.serviceRequestTypeId, hiddenColumns);
  }, [hiddenColumns, props.serviceRequestTypeId]);

  const classes = useStyles();

  return (
    <FieldArray name="Rows" validateOnChange={true}>
      {arrayHelpers => {
        return (
          <>
            <TableToolbar
              screenName={t(translations.ServiceRequestTable)}
              screenNameVariant={'section'}
              titleAsColumn={true}
              titleIcon={
                <Body bold size="small">
                  {props.values.Rows !== undefined && (
                    <>
                      {props.values.Rows.length} {t(translations.Records)}
                    </>
                  )}
                </Body>
              }
              alertContent={
                props.hasError
                  ? ({
                      type: 'error',
                      message: t(translations.RequestRows_error) as string,
                      closable: true,
                    } as AlertContentType)
                  : undefined
              }
              showAlert={props.hasError}
            >
              {props.isEditable && selectedFlatRows.length > 0 && (
                <BulkUpdate
                  serviceRequestId={props.serviceRequestId}
                  selectedFlatRows={selectedFlatRows}
                />
              )}
              {props.isEditable && (
                <ServiceRequestRowsImport
                  serviceRequestId={props.serviceRequestId}
                  arrayHelpers={arrayHelpers}
                  updateMyData={updateMyData}
                />
              )}
              {props.isEditable && (
                <ExportRows
                  url={`/api/requests/${props.serviceRequestId}/exportTable`}
                >
                  {t(translations.Export)}
                </ExportRows>
              )}
              {props.isAdmin && (
                <ColumnsSelect
                  allColumns={allColumns}
                  hiddenColumns={hiddenColumns}
                  toggleHideColumn={toggleHideColumn}
                />
              )}
              {props.isEditable && (
                <AddServiceRequestRowButton
                  serviceRequestId={props.serviceRequestId}
                  disabled={!props.isEditable}
                  arrayHelpers={arrayHelpers}
                  updateMyData={updateMyData}
                />
              )}
            </TableToolbar>
            <div className={classes.tableContent}>
              <div className={classes.tableScrolling}>
                <Table
                  {...getTableProps()}
                  className={clsx(classes.table, { withFooter: true })}
                  size="small"
                >
                  <TableHead>
                    {
                      // Loop over the header rows
                      headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                          {
                            // Loop over the headers in each row
                            headerGroup.headers.map(column => (
                              // Apply the header cell props

                              <TableCell {...column.getHeaderProps()}>
                                {
                                  // Render the header
                                  <TableHeaderCellLabel>
                                    {column.render('Header')}
                                  </TableHeaderCellLabel>
                                }
                              </TableCell>
                            ))
                          }
                        </TableRow>
                      ))
                    }
                  </TableHead>
                  <TableBody {...getTableBodyProps()}>
                    <SRTableBody
                      page={page}
                      prepareRow={prepareRow}
                      arrayHelpers={arrayHelpers}
                      updateMyData={updateMyData}
                      serviceRequestId={props.serviceRequestId}
                    />
                  </TableBody>
                </Table>
              </div>

              {props.values.Rows !== undefined && (
                <div
                  id={`serviceRequest_rows_footer`}
                  className={classes.tableFooterWithBorder}
                >
                  <SRTablePagination
                    isEditable={props.isEditable}
                    dataLength={props.values.Rows.length}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    setPageSize={setPageSize}
                  />
                </div>
              )}
            </div>
          </>
        );
      }}
    </FieldArray>
  );
});

interface SRTableBodyProps
  extends IServiceRequestRelated,
    IServiceRequestTableFormComponent {
  page: Array<Row<IServiceRequestTableRowModel>>;
  prepareRow: (row: Row<IServiceRequestTableRowModel>) => void;
}
const SRTableBody = React.memo(function SRTableBody({
  page,
  prepareRow,
  arrayHelpers,

  updateMyData,
}: SRTableBodyProps) {
  return (
    <>
      {page.map(row => {
        prepareRow(row);

        const rowProps = row.getRowProps();
        return (
          <React.Fragment key={`srbody_row_${row.original.Id}`}>
            <TableRow {...rowProps} key={`editable-row-${row.id}`}>
              {row.cells.map((cell, index) => (
                <EditableCellField
                  key={`editable-cell-${row.original.Id}-${index}`}
                  name={(cell.column as ColumnTypeExtension).name?.(cell.row)}
                >
                  {(fieldProps?: FastFieldProps) => {
                    const hasError = !isNullOrUndefined(
                      fieldProps?.meta?.error,
                    );
                    return (
                      <StyledTableCell
                        className={
                          (cell.column as ColumnTypeExtension).name !==
                          undefined
                            ? 'editcell'
                            : undefined
                        }
                        {...cell.getCellProps()}
                        title={fieldProps?.meta?.error}
                        error={hasError}
                        key={`cell-${row.original.Id}-${index}`}
                      >
                        {cell.render('Cell', {
                          arrayHelpers,
                          updateMyData,
                          fieldProps,
                        })}
                      </StyledTableCell>
                    );
                  }}
                </EditableCellField>
              ))}
            </TableRow>
            {row.isExpanded && (
              <TableRow key={`expand_${rowProps.key}`}>
                <TableCell colSpan={row.cells.length}>
                  <ServiceRequestRowComments
                    commentType={CommentReferenceTypes.ServiceRequestRow}
                    referenceId={row.original.Id}
                    serviceRequestId={row.original.ServiceRequestId}
                    row={row}
                  />
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
});

interface SRTableCellProps {
  error?: boolean;
  title?: string;
  key?: string;
  className?: string;
  children: React.ReactNode;
}
function SRTableCell({
  error,
  children,
  title,
  key,
  className,
  ...other
}: React.PropsWithChildren<SRTableCellProps>) {
  return (
    <Tooltip title={title ?? ''} key={`title_${key}`}>
      <TableCell key={key} className={className} {...other}>
        {children}
      </TableCell>
    </Tooltip>
  );
}
const StyledTableCell = styled(SRTableCell)`
  background: ${props =>
    props.error ? bookitColors.danger.background : 'transparent'};

  border-bottom: ${props => (props.error ? '1px solid #F78F8B' : 'unset')};
  &.editcell:hover {
    background: ${props =>
      props.error
        ? bookitColors.danger.bgHover
        : bookitColors.grayscale.grayBackground};
    cursor: pointer;
    & > * {
      cursor: pointer;
      & > * {
        cursor: pointer;
        & > * {
          cursor: pointer;
          & > * {
            cursor: pointer;
          }
        }
      }
    }
  }
  > .Mui-focused & {
    border: 1px solid ${bookitColors.primary.regular};
    background: ${bookitColors.grayscale.grayBackground};
  }
  &:focus-within {
    border: 1px solid ${bookitColors.primary.regular};
    background: ${bookitColors.grayscale.grayBackground};
  }
`;
// box-shadow: ${props =>
//   props.error
//     ? '-1px 0px 0px #e0e5ec, 0px 1px 0px #C42E52'
//     : '-1px 1px 0px #e0e5ec'};
const TableHeaderCellLabel = styled('span')`
  white-space: break-spaces;
  word-break: normal;
`;
interface SRTablePaginationProps
  extends Pick<
      UsePaginationState<IServiceRequestTableRowModel>,
      'pageSize' | 'pageIndex'
    >,
    Pick<
      UsePaginationInstanceProps<IServiceRequestTableRowModel>,
      'gotoPage' | 'setPageSize'
    > {
  isEditable: boolean;
  dataLength: number;
}
const SRTablePagination = React.memo(function SRTablePagination(
  props: SRTablePaginationProps,
) {
  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={[5, 10, 25]}
      /*colSpan={tableColumns.length + (props.isEditable ? 1 : 0)}*/
      count={props.dataLength}
      rowsPerPage={props.pageSize}
      page={props.pageIndex}
      SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
      }}
      onChangePage={(event, newPage) => props.gotoPage(newPage)}
      onChangeRowsPerPage={event =>
        props.setPageSize(Number(event.target.value))
      }
      ActionsComponent={TablePaginationActions}
    />
  );
});

interface EditableCellProps {
  name?: string;
  children: (fieldProps?: FastFieldProps) => React.ReactElement;
}
function EditableCellField({
  name,
  ...props
}: EditableCellProps): React.ReactElement {
  if (name === undefined) {
    return props.children(undefined);
  } else {
    return <FastField name={name} key={name} {...props} />;
  }
}
