import styled from 'styled-components';

export const CopyrightSection = styled('div')(({ theme }) => ({
  '&.copyrightSection': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 8,
    alignSelf: 'stretch',
    width: 'calc(100% - 16px)',
    padding: '0px 16px 0px 32px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '0px 0px',
      width: '100%',
      gap: 4,
    },
  },
}));
