import { Chip, ChipProps, Theme, useTheme } from '@material-ui/core';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { BasicTypography } from 'app/components/Typography/BasicTypography';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';
import { Entity } from 'types/common';
import { DetectIsMobile } from 'utils/mobileDetect';
import { FilterChip, FilterDisplayProps } from '..';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { FilterValueType } from '../../FilterValueType';

const InstrumentFilterChip = styled(
  ({
    color,
    theme,
    ...other
  }: {
    color?: string;
    theme: Theme;
  } & ChipProps) => <Chip {...other} />,
)`
  &.instrument-display-root {
    gap: ${props => props.theme.spacing(1)}px;
    max-width: 28ch;
    border-radius: 8px;
    color: white;
    background-color: ${props => (!!props.color ? props.color : '#ccc')};
    height: 28px;
    &::first-child {
      margin: 0;
    }
  }
`;
function IsNotEmptyArray(
  entity: FilterValueType | undefined,
): entity is Extract<FilterValueType, any[]> {
  return Array.isArray(entity) && !!entity.length;
}
export function InstrumentsFilterDisplay<TRow>(
  props: FilterDisplayProps<TRow>,
) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = DetectIsMobile();
  const value = props.filter.value;
  const items = IsNotEmptyArray(value) ? (value as Array<Entity<number>>) : [];

  const handleDelete = event => {
    props.onDelete(props.filter);
  };
  return items.length > 0 ? (
    <div
      style={{
        gap: '6px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        flexDirection: 'row',
        border: `1px solid ${bookitColors.grayscale.grayBorders}`,
        padding: `${items.length > 7 ? '6px 12px' : '0px 12px'}`,
        borderRadius: '8px',
      }}
    >
      <BasicTypography
        component="span"
        noWrap
        variant="boldM"
        style={
          isMobile
            ? {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '10ch',
              }
            : undefined
        }
      >
        {props.filter.displayName}
        {':'}
        {props.filter.isInversed ? ' Not' : ''}
      </BasicTypography>

      {items
        .filter(chip => chip.Name !== undefined)
        .map(chip => (
          <InstrumentFilterChip
            theme={theme}
            key={chip.Id}
            className={'instrument-display-root'}
            onClick={props.openFilter}
            color={(chip as any)?.Color}
            label={
              <>
                {chip.Name !== undefined && chip.Name.length > 20 ? (
                  <Tooltip title={chip.Name} placement="bottom-start" arrow>
                    <span>
                      <BasicTypography
                        component="span"
                        noWrap
                        variant="bodyS"
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '20ch',
                          color: 'inherit',
                        }}
                      >
                        {chip.Name}
                      </BasicTypography>
                    </span>
                  </Tooltip>
                ) : (
                  <BasicTypography
                    component="span"
                    variant="bodyS"
                    noWrap
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '20ch',
                      color: 'inherit',
                    }}
                  >
                    {chip.Name}
                  </BasicTypography>
                )}
              </>
            }
            //   onDelete={handleDelete}
          />
        ))}
      <IconButton variant="ghost" size="small" onClick={handleDelete}>
        <Icon icon="times" />
      </IconButton>
    </div>
  ) : (
    <FilterChip
      theme={theme}
      className={'filter-display-root'}
      onClick={props.openFilter}
      label={
        <>
          <BasicTypography
            component="span"
            noWrap
            style={
              isMobile
                ? {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '10ch',
                  }
                : undefined
            }
          >
            {props.filter.displayName}
          </BasicTypography>
          :&ensp;
          <BasicTypography
            component="span"
            noWrap
            style={
              isMobile
                ? {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '30ch',
                  }
                : undefined
            }
          >
            {t(translations.NA)}
          </BasicTypography>
        </>
      }
      onDelete={handleDelete}
    />
  );
}
