/**
 *
 * CustomPicker
 *
 */
import * as React from 'react';
import { Entity } from 'types/common';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';

export interface CustomPickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'> {
  disabled?: boolean;
}
export const initCustomPicker = (initval: string | undefined) => {
  return new Promise<Entity<number>[]>((resolve, reject) => {
    resolve([] as Entity<number>[]);
  });
};
export function CustomPicker(props: CustomPickerProps) {
  const loadData = () => {
    const options =
      props.customData !== undefined
        ? ((props.customData as unknown) as Entity<number>[])
        : ([] as Entity<number>[]);
    return Promise.resolve<Entity<number>[]>(options);
  };
  return (
    <>
      <AutocompletePicker
        loadData={loadData}
        mini={props.mini ? true : undefined}
        id={props.id || 'custompickerId'}
        fullWidth={props.fullWidth}
        {...props}
      />
    </>
  );
}
