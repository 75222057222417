import { Input, MenuItem } from '@material-ui/core';
import { useMemo, useState, useRef } from 'react';
import { dateUtils } from 'utils/date-utils';
import { IComment, NewComment } from './IComment';
import { parse } from './commentParser';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Caption } from '../Typography';
import { Box } from '../basic/Wrappers/Box';
import * as Yup from 'yup';
import { Entity } from 'types/common';
import { Form, Formik } from 'formik';
import { Button } from '../BasicButtons/Button';
import { Icon } from '../BasicIcons/FontAwesome';
import { FormRichTextField } from '../Forms/FormRichTextField';
import { TextField } from '../TextField';
import clsx from 'clsx';
import { useCommentStyles, useContainerStyles, useStyles } from './styles';
import { DropDown } from '../DropDown';
import { IEmail } from 'api/EmailApi';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { useSendMail } from 'app/hooks/useSendMail';
import { renderToStaticMarkup } from 'react-dom/server';
import { FormListener } from '../Forms/FormRender/FormRenderer';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { Avatar } from '../Avatar';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { EmailTypeUnion } from 'api/odata/generated/enums/EmailType';
import { Alert } from '@material-ui/lab';
import { Body } from '../Typography';
import * as React from 'react';
import BasicTypography from '../Typography/BasicTypography';
import { FileButton } from './FileButton';
import { Tooltip } from '../BasicTooltips/Tooltip';

export interface CommentsContainerProps extends EmailRelated {
  Comments: IComment[];
  onSubmit: (comment: NewComment) => void;
  withMentions?: boolean;
  isAdmin?: boolean;
  onEditComment?: (comment: IComment, newComment: NewComment) => void;
  onDeleteComment?: (comment: IComment) => void;
  shouldSendEmail?: boolean;
  disabled?: boolean;
  bindSubmitComment?: any;
  defaultComment?: Partial<IComment>;
  cannotComment?: boolean;
  sectionTitle?: React.ReactNode;
}

export interface EmailRelated {
  emailBody?: string;
  emailSubject?: string;
  emailType?: EmailTypeUnion;
  pageName?: string;
  referenceName?: string;
  linkUrl?: string;
}

const AddComment = ({
  onSubmit,
  onClose,
  withMentions,
  isAdmin,
  defaultComment,
  disabled,
  bindSubmitComment,
}: {
  onSubmit: (comment: NewComment) => void;
  onClose: () => void;
  withMentions: boolean;
  isAdmin?: boolean;
  defaultComment?: Partial<IComment>;
  disabled?: boolean;
  bindSubmitComment?: any;
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileList | undefined>();
  const formRef = useRef<HTMLFormElement>(null);
  const [commentFilesChanged, setCommentFilesChanged] = useState<
    boolean | undefined
  >(undefined);
  const classes = useStyles();

  useEffectOnMount(() => {
    formRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  });
  const userSchema: Yup.SchemaOf<Entity<string>> = Yup.object({
    Id: Yup.string().ensure(),
    Name: Yup.string().ensure(),
  });

  const schema: Yup.SchemaOf<NewComment> = Yup.object({
    Users: Yup.array().of(userSchema),
    Files: Yup.mixed().notRequired(),
    Text: Yup.string()
      .notRequired()
      .test(val => (val ?? '').length > 0 || !!commentFilesChanged),
    IsInternal: Yup.boolean().nullable(),
  });

  const initial: NewComment = {
    Text: defaultComment?.Text,
    Users: [],
    IsInternal: defaultComment?.IsInternal,
  };
  const handleSubmit = ({ Files, ...values }: NewComment) => {
    const comment: NewComment = {
      Files: files,
      ...values,
    };
    onSubmit(comment);
    onClose();
  };
  const onFileChange = e => {
    setFiles(e.target.files);
    setCommentFilesChanged((e.target.files as FileList).length > 0);
  };
  return (
    <Formik
      onSubmit={(values, action) => {
        handleSubmit(values);
        action.resetForm();
      }}
      initialValues={initial}
      validationSchema={schema}
    >
      {formik => {
        if (bindSubmitComment && (formik.isValid || commentFilesChanged)) {
          bindSubmitComment(formik.submitForm);
        }
        const additionalButtons = [
          (className, key) => {
            return isAdmin ? (
              <DropDown
                variant="ghost"
                menuProps={{ closeAfterTransition: true }}
                className={className}
                disabled={disabled}
                key={key}
                menuChildren={close => [
                  <MenuItem>
                    <Button
                      color={formik.values.IsInternal ? 'default' : 'primary'}
                      variant={formik.values.IsInternal ? 'ghost' : 'main'}
                      fullWidth
                      startIcon={<Icon icon="globe" />}
                      onClick={() => {
                        formik.getFieldHelpers('IsInternal').setValue(false);
                        close();
                      }}
                    >
                      {t(translations.VisibleToUsers)}
                    </Button>
                  </MenuItem>,
                  <MenuItem>
                    <Button
                      color={formik.values.IsInternal ? 'primary' : 'default'}
                      variant={formik.values.IsInternal ? 'main' : 'ghost'}
                      fullWidth
                      startIcon={<Icon icon="lock" />}
                      onClick={() => {
                        formik.getFieldHelpers('IsInternal').setValue(true);
                        close();
                      }}
                    >
                      {t(translations.StaffOnly)}
                    </Button>
                  </MenuItem>,
                ]}
              >
                <Icon
                  icon={formik.values.IsInternal ? 'lock' : 'lock-open'}
                  fixedWidth
                />
              </DropDown>
            ) : (
              <></>
            );
          },
        ];
        return (
          <Form
            ref={formRef}
            onSubmit={formik.handleSubmit}
            className={classes.section}
          >
            <FormListener onFormChange={() => {}} fields={[]} />
            <Box className={classes.form}>
              <Box className={clsx(classes.fields)}>
                <Box className={classes.row}>
                  <FormRichTextField
                    autoFocus
                    name="Text"
                    placeholder={t(translations.WriteAComment)}
                    hasMentions={withMentions}
                    disabled={disabled}
                    onChangeMentions={v =>
                      formik.getFieldHelpers('Users').setValue(v, true)
                    }
                    additionalButtons={additionalButtons}
                  />
                </Box>
                <Box className={classes.row}>
                  <Input
                    type="file"
                    inputProps={{ multiple: true }}
                    onChange={onFileChange}
                    disabled={disabled}
                    fullWidth
                  />
                </Box>
                <Box className={classes.row}>
                  <Button
                    size="small"
                    type="submit"
                    disabled={disabled || formik.isSubmitting}
                  >
                    {t(translations.Save)}
                  </Button>
                  <Button
                    size="small"
                    onClick={onClose}
                    color="primary"
                    variant="white"
                  >
                    {t(translations.Close)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

const CommentCell = ({
  comment,
  onSubmit,
  withMentions,
  isAdmin,
  onEditComment,
  onDeleteComment,
  disabled,
  bindSubmitComment,
}: {
  comment: IComment;
  onSubmit: (comment: NewComment) => void;
  withMentions: boolean;
  isAdmin?: boolean;
  onEditComment?: (comment: IComment, newComment: NewComment) => void;
  onDeleteComment?: (comment: IComment) => void;
  disabled?: boolean;
  bindSubmitComment?: any;
}) => {
  const [hover, setHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const { newDate, withoutOffset } = useSystemDate();
  const classes = useCommentStyles();
  const [files, setFiles] = useState(comment.Files);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const currentUserComment = authenticatedUser?.Id === comment.CreatedBy.Id;
  return (
    <React.Fragment>
      {onEditComment && edit ? (
        <>
          <AddComment
            withMentions={withMentions}
            isAdmin={isAdmin}
            defaultComment={comment}
            disabled={disabled}
            onSubmit={newComment => {
              onEditComment(comment, newComment);
              setEdit(false);
            }}
            onClose={() => setEdit(false)}
            bindSubmitComment={bindSubmitComment}
          />
        </>
      ) : (
        <Box className={classes.commentSection}>
          <Box>
            <Avatar user={comment.CreatedBy} />
          </Box>
          <Box
            className={classes.commentTextFiles}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Box className={classes.commentText}>
              <Box className={classes.nameAndTime}>
                <Box className={classes.nameTitleButtons}>
                  <Box className={classes.nameTitle}>
                    <BasicTypography variant="boldS">
                      {comment.CreatedBy.Name}
                    </BasicTypography>
                    {isAdmin && comment.IsInternal && <Icon icon="lock" />}
                  </Box>
                  {withMentions && hover && (
                    <>
                      {isAdmin && (
                        <>
                          {onEditComment && (
                            <Button
                              variant="text"
                              startIcon={<Icon icon="edit" />}
                              size="small"
                              onClick={() => setEdit(true)}
                            >
                              {t(translations.Edit)}
                            </Button>
                          )}
                          {onDeleteComment && (
                            <Button
                              variant="text"
                              startIcon={<Icon icon="trash" />}
                              size="small"
                              onClick={() => onDeleteComment(comment)}
                            >
                              {t(translations.Delete)}
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>
                <Caption color="secondary">
                  <Tooltip
                    title={
                      dateUtils.format(
                        comment.CreatedAt,
                        dateUtils.DateIOFormats.keyboardDateTime24h,
                      ) ?? ''
                    }
                    arrow
                  >
                    <span>
                      {Math.abs(
                        dateUtils.differenceInDays(
                          dateUtils.dateOrStringToDate(comment.CreatedAt),
                          newDate(),
                        ),
                      ) > 30
                        ? dateUtils.format(
                            comment.CreatedAt,
                            dateUtils.DateIOFormats.keyboardDateTime24h, //'PP hh:mm',
                          )
                        : dateUtils.formatDistanceToNow(
                            withoutOffset(comment.CreatedAt),
                          )}
                    </span>
                  </Tooltip>
                </Caption>
              </Box>
              <BasicTypography variant="bodyS">
                {comment.Text.trim() !== '' ? parse(comment.Text) : ''}
              </BasicTypography>
            </Box>

            {files.map(f => {
              return (
                <>
                  <FileButton
                    canRemove={isAdmin || currentUserComment}
                    onDeleteFile={id => {
                      setFiles(prev => prev.filter(file => file.Id !== id));
                    }}
                    key={f.Id}
                    file={f}
                  />
                </>
              );
            })}
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export const CommentsContainer = ({
  Comments,
  onSubmit,
  withMentions,
  isAdmin,
  onEditComment,
  onDeleteComment,
  emailBody,
  emailSubject,
  pageName,
  referenceName,
  emailType,
  linkUrl,
  disabled,
  shouldSendEmail = true,
  bindSubmitComment,
  defaultComment,
  cannotComment,
  sectionTitle,
}: CommentsContainerProps) => {
  const [count, setCount] = useState<number>(5);
  const sortedComments = useMemo(() => {
    Comments.sort(
      (a, b) =>
        -1 *
        (new Date(a.CreatedAt).getTime() - new Date(b.CreatedAt).getDate()),
    );
    return Comments.filter(c => !c.IsInternal || isAdmin);
  }, [Comments, isAdmin]);
  const [writeCommentOpen, setWriteCommentOpen] = useState(false);
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const sendMail = useSendMail();
  const handleSubmit = (comment: NewComment) => {
    if (shouldSendEmail && withMentions) {
      const email = createEmail({
        emailBody: emailBody ?? t(translations.DefaultEmailTemplateBody),
        emailSubject:
          emailSubject ?? t(translations.DefaultEmailTemplateSubject),
        pageName: pageName ?? t(translations.DefaultPageName),
        referenceName: referenceName ?? t(translations.DefaultReferenceName),
        emailType: emailType,
        linkUrl: linkUrl ?? '',
        userName: authenticatedUser?.Name ?? '',
        comment: comment,
      });
      if (email) {
        sendMail(email);
      }
    }
    onSubmit(comment);
  };
  const classes = useContainerStyles();
  return (
    <Box className={classes.commentsContainer}>
      <Box className={classes.sectionTitle}>
        <Body bold={true}>{sectionTitle || t(translations.CommentsCount)}</Body>
      </Box>
      {sortedComments
        .slice(0, count < sortedComments.length ? count : undefined)
        .map((c, i) => (
          <CommentCell
            key={`${i}-${c.Id}`}
            comment={c}
            onSubmit={handleSubmit}
            withMentions={!!withMentions}
            isAdmin={isAdmin}
            onEditComment={onEditComment}
            onDeleteComment={onDeleteComment}
            bindSubmitComment={bindSubmitComment}
          />
        ))}
      <Box className={classes.controlBox}>
        {count < sortedComments.length && (
          <Button
            color="default"
            fullWidth
            onClick={() => setCount(prev => prev + 5)}
          >
            {(('Load more ' + t(translations.CommentsCount)) as string) +
              ' (' +
              (sortedComments.length - count) +
              ')'}
          </Button>
        )}
        {!cannotComment && (
          <Box className={classes.addCommentBox}>
            {writeCommentOpen ? (
              <AddComment
                defaultComment={defaultComment}
                onSubmit={handleSubmit}
                disabled={disabled}
                onClose={() => setWriteCommentOpen(false)}
                withMentions={!!withMentions}
                isAdmin={isAdmin}
                bindSubmitComment={bindSubmitComment}
              />
            ) : (
              <TextField
                fullWidth
                id="commentId"
                inputProps={{ 'aria-label': 'comments input' }}
                disabled={disabled}
                placeholder={t(translations.WriteAComment)}
                onClick={() => setWriteCommentOpen(true)}
              />
            )}
          </Box>
        )}
        {cannotComment && sortedComments.length === 0 && (
          <Alert color="info">{t(translations.Comments_EmptyMessage)}</Alert>
        )}
      </Box>
    </Box>
  );
};

const createEmail = ({
  comment,
  emailBody,
  emailSubject,
  pageName,
  referenceName,
  emailType,
  userName,
  linkUrl,
}: {
  emailBody: string;
  emailSubject: string;
  emailType?: EmailTypeUnion;
  pageName: string;
  referenceName: string;
  comment: NewComment;
  userName: string;
  linkUrl: string;
}) => {
  if (emailType === undefined || comment.Users.length === 0) return;
  const subject = emailSubject
    .replaceAll('{@UserName}', userName)
    .replaceAll('{@PageName}', pageName)
    .replaceAll('{@Reference}', referenceName);
  const body = emailBody
    .replaceAll('{@UserName}', userName)
    .replaceAll('{@PageName}', pageName)
    .replaceAll('{@Reference}', referenceName)
    .replaceAll('{@Link}', linkUrl)
    .replaceAll(
      '{@Body}',
      renderToStaticMarkup(<>{parse(comment.Text ?? '')}</>),
    );
  return {
    Subject: subject,
    Body: body,
    Files: comment.Files,
    SaveToLog: true,
    To: [],
    Cc: [],
    Bcc: comment.Users,
    Type: emailType,
  } as IEmail;
};
