import axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { uniqueId } from 'lodash';
import { toRootedURL } from 'utils/url-utils';

axios.defaults.baseURL = process.env.PUBLIC_URL;
axios.defaults.timeout = 600000;

/**
 * Check if HTTP status is a success - anything in 2xx range
 * e.g. 200, 201, etc.
 * @param status HTTP_STATUS
 * @returns true for all success statuses
 */
const isStatusOK = (status: number) => status >= 200 && status < 300;

export type BatchRequest = {
  id?: string;
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  pathName: string;
  search?: URLSearchParams;
  body?: any;
};
interface BatchResponse {
  id: string;
  atomicityGroup: string;
  status: StatusCodes;
  headers: Record<string, string>;
  body?: unknown;
}
export interface BatchResponses {
  responses: BatchResponse[];
}
// const y = {
//   responses: [
//     {
//       id: 'g1_0',
//       atomicityGroup: 'f27f07a2-e8f4-4c62-8216-4611e3ba07c6',
//       status: 204,
//       headers: { 'odata-version': '4.0' },
//     },
//     {
//       id: 'g1_1',
//       atomicityGroup: 'f27f07a2-e8f4-4c62-8216-4611e3ba07c6',
//       status: 204,
//       headers: { 'odata-version': '4.0' },
//     },
//   ],
// };
// const x = {
//   requests: [
//     {
//       method: 'PATCH',
//       atomicityGroup: 'g1',
//       url:
//         'http://localhost:3194/bkt_webApp/api/odata/v4/ServiceRequestTickets(650)',
//       headers: {
//         'content-type':
//           'application/json; odata.metadata=minimal; odata.streaming=true',
//         'odata-version': '4.0',
//       },
//       id: 'g1_0',
//       body: { Index: 0 },
//     },
//     {
//       method: 'PATCH',
//       atomicityGroup: 'g1',
//       url:
//         'http://localhost:3194/bkt_webApp/api/odata/v4/ServiceRequestTickets(651)',
//       headers: {
//         'content-type':
//           'application/json; odata.metadata=minimal; odata.streaming=true',
//         'odata-version': '4.0',
//       },
//       id: 'g1_1',
//       body: { Index: 1 },
//     },
//   ],
// };

class Client {
  public cancelToken = axios.CancelToken;
  public async get<T = any>(
    url: string,
    params?: any,
    cancelToken?: CancelToken,
  ): Promise<T> {
    try {
      const response = await axios.get<T>(url, {
        params: params,
        cancelToken: cancelToken,
      });
      if (isStatusOK(response.status)) {
        return response.data;
      } else {
        throw response;
      }
    } catch (err) {
      throw err;
    }
  }
  public async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    const response = await axios.put(url, data, config);
    if (isStatusOK(response.status)) {
      return response.data;
    } else {
      throw response;
    }
  }
  public async post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    try {
      const response = await axios.post<T>(url, data, config);
      if (isStatusOK(response.status)) {
        return response.data;
      } else {
        throw response;
      }
    } catch (error) {
      throw error;
    }
  }
  public async patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    const response = await axios.patch<T>(url, data, config);
    if (isStatusOK(response.status)) {
      return response.data;
    } else {
      throw response;
    }
  }
  public async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    const response = await axios.delete(url);
    if (isStatusOK(response.status)) {
      return response.data;
    } else {
      throw response;
    }
  }
  public async deleteCustom<T = any>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    const response = await axios.delete(url);
    if (isStatusOK(response.status)) {
      return response.data;
    } else {
      throw response;
    }
  }
  public async batch(b: Array<BatchRequest>): Promise<BatchResponses> {
    const atomicityGroup = 'g1';
    const payload = {
      requests: b
        // each request needs to be fully qualified e.g. https://foo/api/odata/v4/entity otherwise odata throws NullRef exception regarding virtualpath
        .map((r, index) => {
          const rootedPathWithSearch = toRootedURL(
            r.pathName + (r.search === undefined ? '' : `?${r.search}`),
          );
          return {
            method: r.method,
            atomicityGroup: atomicityGroup,
            url: new URL(rootedPathWithSearch, new URL(window.location.origin)),

            headers: {
              'content-type':
                'application/json; odata.metadata=minimal; odata.streaming=true',
              'odata-version': '4.0',
            },
            id: r.id ?? uniqueId(`${atomicityGroup}_`),
            body: r.body,
          };
        }),
    };
    const response: AxiosResponse<BatchResponses> = await axios.post(
      '/api/odata/v4/$batch',
      payload,
    );

    if (
      response.data.responses.every(response => isStatusOK(response.status))
    ) {
      return response.data;
    } else {
      throw response;
    }
  }
  public async batchDelete(urls: string[]) {
    return this.batch(
      urls.map(f => ({
        pathName: f,
        method: 'DELETE',
      })),
    );
  }
  /**
   * Response type is blob by default due to historical reasons. If json is expected, then
   * uploadFile('url', data, {resposneType:'json'})
   * @param url
   * @param file
   * @param config
   */
  public async uploadFile(url, file, config?: AxiosRequestConfig) {
    const formData = new FormData();
    formData.append('File', file);
    const response = await axios.post(url, formData, {
      responseType: 'blob',
      timeout: undefined,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
      ...config,
    });
    return response;
  }
}
export const httpClient = new Client();
