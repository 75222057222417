import * as React from 'react';
import { IBasePageProps } from '../../pages/IBasePageProps';
import { useGlobalServiceGroupsFilter } from 'app/hooks/useGlobalServiceGroupsFilter';

/**
 * Connects the saved selected global service groups filter with the <IBasePageProps> element:
 * 1. Sets the state to show/hide the top service groups filter UI element
 * 2. Connects the saved selection to the prop of the wrapped element
 * example: export const FooPage = withGlobalServiceGroupFilter(FooPageComponent, true)
 * @param Component Page component that extends the IBasePageProps params interface
 * @param applyGlobalServiceGroupsFilter enable/disable the global service groups filter on a page
 * @returns component that can be passed to the router
 */
export function withGlobalServiceGroupFilter<P extends object>(
  Component: React.ComponentType<P>,
  applyGlobalServiceGroupsFilter: boolean,
): React.FC<P & IBasePageProps> {
  return ({ ...props }: IBasePageProps) => {
    const { serviceGroups } = useGlobalServiceGroupsFilter(
      applyGlobalServiceGroupsFilter,
    );

    if (serviceGroups === undefined && applyGlobalServiceGroupsFilter) {
      return <></>;
    }
    return <Component serviceGroups={serviceGroups} {...(props as P)} />;
  };
}
