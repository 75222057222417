import * as React from 'react';
import { Redirect } from 'react-router';
import { useProfileSetting } from 'app/components/BasicTable/useProfileSetting/index';
import {
  parseProfileUrl,
  undefinedIfIsEmpty,
} from 'app/components/BasicTable/useProfileSetting/parseProfileUrl';
import { CalendarPageProps, calendarPathUserProfileKey } from './index';
import { DetectIsMobile } from 'utils/mobileDetect';
import { ViewTypes } from './Scheduler/slice/types';
import { trimEnd } from 'lodash';

/**
 * Redirects to the last visited calendar view.
 * e.g. /schedule -> /schedule/timeline?eid=12150&un=&BookedById=
 * @param param0
 * @returns
 */
export function CalendarPageDefaultRedir({
  match,
  ...props
}: CalendarPageProps) {
  const isMobile = DetectIsMobile();
  const { savedValue } = useProfileSetting({
    profileSettingKey: calendarPathUserProfileKey,
  });
  const defaultViewType: ViewTypes = isMobile ? 'calendar' : 'timeline';
  const defaultPathName = `/schedule/${defaultViewType}`;
  const { pathName: savedPathName, search: savedSearch } = parseProfileUrl(
    savedValue,
  );
  // mobile clients should always land on calendar, regardless last visited mode
  const pathName = trimEnd(
    isMobile ? defaultPathName : savedPathName ?? defaultPathName,
    '?',
  );
  // the saved search part will be applied anyway
  // setting it here will save one redir/replace that will happen in the useFilters componenent on the scheduler page
  const search = savedSearch;

  return (
    <Redirect
      to={{
        pathname: undefinedIfIsEmpty(pathName),
        search: search,
      }}
    />
  );
}
