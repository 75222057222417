import * as Yup from 'yup';
import { ServiceRequestTableColumnsCollection } from '../slice/utils/ServiceRequestTableColumn';
import { getSchema } from 'app/pages/Samples/components/ServiceRequestRowsTable/getServiceRequestRowSchema';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { getFieldSchema } from 'app/components/CustomForm/CustomFormUtils';
import { chain } from 'lodash';

export const getRequestDetailsSchema = (props: {
  columns: ServiceRequestTableColumnsCollection | null;
  fields: IFormFieldDto[];
  isAdmin: boolean;
  SampleManagementTablesEnabled: boolean;
  ForbiddenFileExtensions: string[];
  UploadFileSizeLimit: number;
}) => {
  const customFormSchema = Yup.object(
    chain(props.fields.filter(f => f.Active))
      .keyBy(f => f.Id)
      .mapValues(field =>
        getFieldSchema(
          field,
          props.isAdmin,
          props.UploadFileSizeLimit,
          props.ForbiddenFileExtensions,
        ),
      )
      .value(),
  );
  let result = Yup.object({
    Rows:
      props.columns === null
        ? Yup.mixed()
        : getSchema({
            columns: props.columns,
            isAdmin: props.isAdmin,
          }).nullable(),
    FormValues: customFormSchema,
  });

  return result;
};
