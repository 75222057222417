import * as React from 'react';
import { SidebarMenuItemProps } from './MenuItem';
import { bindHover, bindMenu } from 'material-ui-popup-state';
import {
  Fade,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuItemComponent from './MenuItemComponent';
import {
  anchorRef,
  bindFocus,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { LinkBehavior } from 'app/components/ExternalLink';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { setTimeout } from 'timers';

export interface HoverSideMenuProps {
  items: SidebarMenuItemProps[];
  handleChildClick: (event, index) => void;
  handleClick: (event, index) => void;
  selected: boolean;
  id: string;
  name: string;
  Icon?: PropTypes.ReactElementLike | null;
  link?: string | null;
  external: boolean;
  index: number;
}
const styles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.primary.bgHover,
    borderRadius: 0,
  },
  list: {
    padding: 0,
  },
  childRoot: {
    '& > .menuItem': {
      minWidth: '200px',
      boxShadow: '0px 1px 0px 0px #e0e5ec',
      color: theme.palette.common.blackRegular,
      '&.active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      '&:focus': {
        textDecoration: 'underline',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      '& > .menuItemTextInset': {
        paddingLeft: theme.spacing(2),
        marginTop: 0,
        marginBottom: 0,
        '& > *': {
          fontSize: '13px',
          fontWeight: 700,
        },
      },
    },
  },
  focusVisible: {
    backgroundColor: theme.palette.common.grayDividers,
    '&::focus': {
      textDecoration: 'underline',
    },
  },
  menuItem: {
    '&::focus': {
      textDecoration: 'underline',
    },
  },
}));
export const createMenuItemRefs = (items, rowInd) => {
  // obj
  let menuItemRefs = {};
  // loop each
  for (let i = 0; i < Object.keys(items).length; i++) {
    // When assign createRef, no current
    menuItemRefs[rowInd + i] = React.createRef();
  }
  return menuItemRefs;
};
export const HoverSideMenu = React.forwardRef<any, HoverSideMenuProps>(
  function HoverSideMenu(props, ref) {
    const {
      items,
      selected,
      handleChildClick,
      id,
      name,
      Icon,
      link,
      handleClick,
      external,
      index,
    } = props;
    const popupState = usePopupState({
      variant: 'popover',
      popupId: id,
    });
    const classes = styles();
    const [childActiveIndex, setChildActiveIndex] = React.useState(-1);
    const menuItemRefs = React.useRef(createMenuItemRefs(items, index));
    React.useEffect(() => {
      if (selected && childActiveIndex >= 0) {
        setTimeout(() => {
          menuItemRefs.current[index + childActiveIndex]?.focus();
        }, 0);
      }
    }, [childActiveIndex, selected, index]);
    const buttonIconKeyDown = (event, parentRowIndex) => {
      if (event.keyCode === 13) {
        // Enter pressed
        console.log('enter is pressed');
        setChildActiveIndex(0);
      } else if (event.keyCode === 9) {
        // tab away
        console.log('tab away');

        //handleClick(event, parentRowIndex + 1);
        setChildActiveIndex(-1);
        //setCurrRowInd('');
      } else if (event.keyCode === 40) {
        //test
        console.log('down arrow');

        // 38 is up arrow
        if (selected) {
          // No scrolling
          event.preventDefault();

          // set to 1st item in 0 index
          setChildActiveIndex(prev => {
            return prev !== items.length - 1 ? prev + 1 : 0;
          });
        }
      } else if (event.keyCode === 38) {
        //test
        console.log('up arrow');

        // 38 is up arrow
        if (selected) {
          // No scrolling
          event.preventDefault();

          // set to 1st item in 0 index
          setChildActiveIndex(prev => {
            return prev > 0 ? prev - 1 : items.length - 1;
          });
        }
      }
    };
    return (
      <React.Fragment>
        <div ref={anchorRef(popupState)} className="hoverItem">
          <MenuItemComponent
            className="menuItem"
            link={link}
            onClick={handleClick}
            onKeyDown={buttonIconKeyDown}
            external={external}
            selected={selected}
            currentIndex={index}
            {...bindFocus(popupState)}
            {...bindHover(popupState)}
          >
            {/* Display an icon if any */}
            <div className="menuItemHoverContent">
              {!!Icon && (
                <ListItemIcon className="menuItemIcon">{Icon}</ListItemIcon>
              )}
              <ListItemText
                className={!Icon ? 'menuItemTextInset' : 'menuItemText'}
                primary={name}
                inset={!Icon}
              />
            </div>
          </MenuItemComponent>
        </div>
        {items && items.length > 0 && (
          <HoverMenu
            ref={ref}
            autoFocus
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            anchorPosition={{ top: 0, left: 200 }}
            getContentAnchorEl={null}
            TransitionComponent={Fade}
            classes={{ paper: classes.paper, list: classes.childRoot }}
          >
            {/* <List component="ul" className={classes.childRoot} disablePadding> */}
            {items.map(
              (item, cindex) => {
                if (!item.link || typeof item.link !== 'string') {
                  return (
                    <MenuItem
                      className="menuItem"
                      tabIndex={0}
                      key={`child-${cindex}`}
                      onClick={event => {
                        console.log('child-div-click', event);
                        handleChildClick(event, cindex);
                      }}
                      ref={element =>
                        (menuItemRefs.current[index + cindex] = element)
                      }
                    >
                      <ListItemText
                        className={'menuItemTextInset'}
                        primary={item.name}
                        inset={true}
                      />
                    </MenuItem>
                  );
                } else {
                  if (item.external) {
                    return (
                      <MenuItem
                        className="menuItem"
                        tabIndex={0}
                        key={`child-external-${cindex}`}
                        onClick={event => {
                          console.log('child-external-click', event);
                          handleChildClick(event, cindex);
                        }}
                        selected={selected && item.selected}
                        component={LinkBehavior}
                        href={item.link}
                        ref={element =>
                          (menuItemRefs.current[index + cindex] = element)
                        }
                      >
                        <ListItemText
                          className={'menuItemTextInset'}
                          primary={item.name}
                          inset={true}
                        />
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem
                        className="menuItem"
                        tabIndex={0}
                        key={`child-react-${cindex}`}
                        onClick={event => {
                          console.log('child-react-click', event);
                          handleChildClick(event, cindex);
                        }}
                        component={React.forwardRef(
                          (props: NavLinkProps, ref: any) => (
                            <NavLink
                              tabIndex={0}
                              exact
                              {...props}
                              innerRef={element =>
                                (menuItemRefs.current[index + cindex] = element)
                              }
                            />
                          ),
                        )}
                        focusVisibleClassName={classes.focusVisible}
                        to={item.link}
                        ref={element =>
                          (menuItemRefs.current[index + cindex] = element)
                        }
                      >
                        <ListItemText
                          className={'menuItemTextInset'}
                          primary={item.name}
                          inset={true}
                        />
                      </MenuItem>
                    );
                  }
                }
              },
              // <SidbarMenuItem
              //   ref={ref}
              //   {...item}
              //   key={cindex}
              //   indexChanged={handleChildClick}
              //   selected={selected && item.selected} //childIndex === index
              // />
            )}
            {/* </List> */}
          </HoverMenu>
        )}
      </React.Fragment>
    );
  },
);
