import * as React from 'react';
import { Grid, Divider, Card } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Box } from 'app/components/basic/Wrappers/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { TableCardGrid } from '../TableCardGrid';
import clsx from 'clsx';

/* 
xs (for phones - screens less than 768px wide)
sm (for tablets - screens equal to or greater than 768px wide)
md (for small laptops - screens equal to or greater than 992px wide)
lg (for laptops and desktops - screens equal to or greater than 1200px wide) 
*/

const headerHeight = 48;
const toolbarHeight = 30;
const searchBarHeight = 30;
//const tableHeaderHeight = 30;
//const tableRowHeight = 30;
//const tableColumnHeight = 438;
//const tableFooterHeight = 30;

const numOfHeaderItems = 7;
const numOfColumns = 7;
const numOfRows = 11;
const numberOfCards = 12;

const useStyles = makeStyles({
  tableContainer: {
    boxShadow: '0 3px 5px 2px #D7D7D7',
  },
  tableHeaderCell: {
    position: 'relative',
    height: 48,
    backgroundColor: '#F5F5F5',
    borderRight: '1px solid #D7D7D7',
    borderBottom: '1px solid #D7D7D7',
    boxShadow: '0 3px 5px 2px #D7D7D7',
    color: 'white',
  },
  tableHeader: {
    position: 'absolute',
    top: '35%',
    left: '20%',
    height: 15,
    width: '60%',
    background: '#D7D7D7',
    color: 'white',
  },
  tableRowContainer: {
    height: 48,
    color: 'white',
  },
  tableRowCell: {
    position: 'relative',
    borderBottom: '1px solid #D7D7D7',
  },
  tableRow: {
    position: 'absolute',
    top: '37%',
    left: '20%',
    height: 15,
    width: '60%',
    color: 'white',
  },
  tableFooterContainer: {
    //display: 'block',
    //position: 'relative',
    height: 48,
    backgroundColor: 'green',
    justifyContent: 'center',
    //borderBottom: '1px solid black',
    //boxShadow: '0 3px 5px 2px #D7D7D7',
    color: 'white',
  },
  tableFooter: {
    //position: 'absolute',
    //top: '37%',
    //left: '20%',
    height: 15,
    //width: 'auto',
    backgroundColor: 'black', //'#F5F5F5',
    boxShadow: '0 3px 5px 2px #D7D7D7',
    color: 'white',
  },
  shadow: {
    boxShadow: '0 3px 5px 2px #D7D7D7',
  },
});
const useTableCardsStyle = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 0,
  },
  cardVertical: {
    maxHeight: 308,
    height: 220,
  },
  cardHorizontal: {
    maxHeight: 183,
    height: 110,
  },
  cardSkelet: {
    backgroundColor: '#e0e5ec',
  },
});
const RenderTableRowsItems = classes => {
  return [...Array(numOfRows).keys()].map(key => (
    //Row item
    <Grid
      key={key}
      container
      spacing={1}
      className={classes.tableRowContainer}
      role="alert"
      aria-live="polite"
      aria-busy="true"
    >
      <Grid item xs md className={classes.tableRowCell}>
        <Skeleton variant="rect" className={classes.tableRow} />
      </Grid>
    </Grid>
  ));
};

const RenderTableHeaderCells = classes => {
  return [...Array(numOfHeaderItems).keys()].map(key => (
    <Grid key={key} item xs md className={classes.tableHeaderCell}>
      <Grid container spacing={0}>
        <Grid item xs md>
          <Skeleton variant="rect" className={classes.tableHeader} />
        </Grid>
      </Grid>
    </Grid>
  ));
};

const RenderTableRows = classes => {
  return [...Array(numOfColumns).keys()].map(key => (
    //TableRow
    <Grid key={key} item xs md>
      <Grid container spacing={0}>
        <Grid item xs md>
          {RenderTableRowsItems(classes)}
        </Grid>
      </Grid>
    </Grid>
  ));
};

/* const RenderFooter = classes => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4} className={classes.tableFooterContainer}>
        <Grid container spacing={2}>
          <Grid item xs md>
            <Skeleton variant="rect" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="rect" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="rect" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="circle" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="circle" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="circle" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="circle" className={classes.tableFooter} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}; */
export function TableCardsSkeleton({ isMobile }: { isMobile: boolean }) {
  const classes = useTableCardsStyle();
  return (
    <TableCardGrid>
      {[...Array(numberOfCards).keys()].map(key => (
        <Card
          key={key}
          className={clsx(classes.card, {
            [classes.cardVertical]: !isMobile,
            [classes.cardHorizontal]: isMobile,
          })}
        >
          <Skeleton
            variant="rect"
            width="100%"
            height="100%"
            className={classes.cardSkelet}
          />
        </Card>
      ))}
    </TableCardGrid>
  );
}
export interface TableRowsSkeletonProps {
  id?: string;
  isMobile: boolean;
  isCards: boolean;
  style?: React.CSSProperties;
}
export function TableRowsSkeleton(props: TableRowsSkeletonProps) {
  const { isMobile, isCards, style } = props;
  const classes = useStyles();
  //RENDER
  return (
    <Box component="div" flexGrow={1} id={props.id} style={style}>
      {/* Table */}
      {/* TableHeaders */}

      {isCards ? (
        <TableCardsSkeleton isMobile={isMobile} />
      ) : (
        <Box component="div" marginTop={1} className={classes.tableContainer}>
          <Grid container spacing={1}>
            {RenderTableHeaderCells(classes)}
          </Grid>
          {/* TableBody */}
          <Grid container spacing={1}>
            {RenderTableRows(classes)}
          </Grid>
        </Box>
      )}

      {/* TableFooter */}
      {/* {RenderFooter(classes)} */}
    </Box>
  );
}

export function TableSkeleton(): JSX.Element {
  const classes = useStyles();
  //RENDER
  return (
    <Box
      component="div"
      flexGrow={1}
      role="alert"
      aria-live="polite"
      aria-busy="true"
    >
      {/* Header */}
      <Box component="div">
        <Grid container spacing={2}>
          <Grid item xs={4} md={2}>
            <Skeleton
              variant="text"
              height={headerHeight}
              className={classes.shadow}
            />
          </Grid>
        </Grid>
      </Box>
      {/* Toolbar */}
      <Box component="div" marginTop={1}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Skeleton
              variant="rect"
              height={toolbarHeight}
              className={classes.shadow}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <Skeleton
              variant="rect"
              height={toolbarHeight}
              className={classes.shadow}
            />
          </Grid>
        </Grid>
      </Box>
      <Box component="div" marginTop={3} marginBottom={3}>
        <Divider />
      </Box>
      {/* Search bar */}
      <Box component="div">
        <Grid container spacing={2}>
          <Grid item xs={2} sm={2} md={2}>
            <Skeleton
              variant="rect"
              height={searchBarHeight}
              className={classes.shadow}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <Skeleton
              variant="rect"
              height={searchBarHeight}
              className={classes.shadow}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <Skeleton
              variant="rect"
              height={searchBarHeight}
              className={classes.shadow}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <Skeleton
              variant="rect"
              height={searchBarHeight}
              className={classes.shadow}
            />
          </Grid>
        </Grid>
      </Box>
      {/* Table */}
      {/* TableHeaders */}
      <Box component="div" marginTop={3} className={classes.tableContainer}>
        <Grid container spacing={1}>
          {RenderTableHeaderCells(classes)}
        </Grid>
        {/* TableBody */}
        <Grid container spacing={1}>
          {RenderTableRows(classes)}
        </Grid>
        {/* TableFooter */}
        {/* <Grid container spacing={1}>
          <Grid item xs md>
            <Skeleton variant="rect" height={tableFooterHeight} />
          </Grid>
        </Grid> */}
      </Box>
    </Box>
  );
}
