/**
 *
 * DescriptionCell
 *
 */
import * as React from 'react';
import { Description, DescriptionProps } from 'app/components/Description';

export interface DescriptonCellProps
  extends Pick<
    DescriptionProps,
    'width' | 'interactive' | 'leaveDelay' | 'leaveTouchDelay'
  > {}
/**
 * Renders a long text Cell - truncates long text with a popup
 * @param c react-table renderprop params - see https://react-table.tanstack.com/docs/api/useTable
 * @returns
 */
export const DescriptionCell = (p?: DescriptonCellProps) => (c: {
  value: string | null;
}) => {
  if (p?.width === 'auto') {
    return String(c.value);
  }
  return (
    <Description
      value={c.value}
      width={p?.width}
      interactive={p?.interactive}
      leaveDelay={p?.leaveDelay}
      leaveTouchDelay={p?.leaveTouchDelay}
    />
  );
};
