import { filterHandler } from 'app/components/BasicTable/UnconfigurableTable/FilterHandler';
import { translations } from 'locales/translations';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UnconfigurableTable } from '../../../../components/BasicTable/UnconfigurableTable/UnconfigurableTable';
import { getColumns } from './Columns';
import { GetFilters } from './Filter';
import { IInvoiceHistory } from './IInvoiceHistory';

export interface InvoiceHistoryProps {
  invoiceId: number;
}
export const InvoiceHistory = ({ invoiceId }: InvoiceHistoryProps) => {
  const { t } = useTranslation();
  const columns = useMemo(() => getColumns(t), [t]);
  const url = '/api/odata/v4/InvoiceHistory';
  return (
    <TTable
      api={{ path: url, search: { invoiceId } }}
      screenName={t(translations.History)}
      columns={columns}
      serviceGroups={[]}
      additionalColumns={['Id']}
      searchColumns={[
        'FieldName',
        'OldValue',
        'cast(InvoiceId,Edm.String)',
        'NewValue',
        'DisplayName',
      ]}
    />
  );
};

const TTable = filterHandler<IInvoiceHistory, {}>(
  UnconfigurableTable,
  GetFilters,
  {
    isSidePanel: true,
  },
);
