import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarKey } from 'notistack';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { messagesSaga } from './saga';
import { Message, MessagesState } from './type';
import { MessageProps } from '..';

export const initialState: MessagesState = {
  messages: [],
  processing: undefined,
};

const slice = createSlice({
  name: 'messagesState',
  initialState,
  reducers: {
    init(state, action: PayloadAction<MessageProps>) {
      state.processing = true;
    },
    init_success(state, action: PayloadAction<Message[]>) {
      state.messages.push(...action.payload);
      state.processing = false;
    },
    remove(state, action: PayloadAction<SnackbarKey>) {
      state.messages = [
        ...state.messages.filter(m => m.key !== action.payload),
      ];
    },
    gotIt(state, action: PayloadAction<string>) {},
    clear(state, action: PayloadAction) {
      state.messages = [];
    },
    refreshUsages(state, action: PayloadAction) {},
  },
});
export const { actions: messagesActions } = slice;

export const useMessagesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: messagesSaga });
  return { actions: slice.actions };
};
