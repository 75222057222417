import { PayloadAction } from '@reduxjs/toolkit';
import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { layoutActions as actions } from '.';
import { PageTypeProps, RenderPageType } from './type';
import { selectHasNotSavedChanges, selectSidePanelOpen } from './selectors';

// export function* doInitSidePanel() {
//   yield put(actions.initLayoutState());
// }
export function* doOpenSidePanel(
  action: PayloadAction<{
    type: RenderPageType | undefined;
    props: PageTypeProps | undefined;
    expanded: boolean | undefined;
  }>,
) {
  const isChangesNotSaved: boolean = yield select(selectHasNotSavedChanges);
  const sidePanelOpen: boolean = yield select(selectSidePanelOpen);
  if (isChangesNotSaved) {
    yield put(
      actions.setContinueState({
        type: action.payload.type,
        props: action.payload.props,
        expanded: action.payload.expanded,
        continue: true,
      }),
    );
    yield put(actions.setConfirmOpen(true));
  } else {
    if (sidePanelOpen) {
      yield put(actions.resetSidePanel(true));
      yield delay(200);
      yield put(
        actions.setTuggleOpenState({
          expanded: !!action.payload.expanded,
          type: action.payload.type,
          props: action.payload.props,
          open: true,
        }),
      );
    } else {
      yield put(
        actions.setTuggleOpenState({
          expanded: !!action.payload.expanded,
          type: action.payload.type,
          props: action.payload.props,
          open: true,
        }),
      );
    }
  }
}
export function* layoutSaga() {
  yield takeLatest(actions.openSidePanel.type, doOpenSidePanel);
}
