import { Entity } from 'types/common';
import * as React from 'react';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { IUserGroupDto } from 'api/odata/generated/entities/IUserGroupDto';

export interface BudgetListenerProps<T> {
  budget: Entity<number> | null;
  ReservationUserGroupGroupByBudgetUserGroup: boolean;
  values?: T;
  setUserGroup: (value: Entity<string> | null) => void;
}

export function BudgetListener<T>(props: BudgetListenerProps<T>) {
  const {
    budget,
    ReservationUserGroupGroupByBudgetUserGroup,
    setUserGroup,
  } = props;
  const [initilized, setInitilized] = React.useState(true);
  React.useEffect(() => {
    if (!initilized) {
      if (!!budget?.Id) {
        if (ReservationUserGroupGroupByBudgetUserGroup) {
          if (!!(budget as IBudgetFilterDto).UserGroupId) {
            setUserGroup({
              Id: (budget as IBudgetFilterDto).UserGroupId,
              Name: (budget as IBudgetFilterDto).UserGroupName,
            } as IUserGroupDto);
          }
        }
      } else {
        if (ReservationUserGroupGroupByBudgetUserGroup) {
          setUserGroup(null);
        }
      }
    }
    setInitilized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget]);

  return null;
}
