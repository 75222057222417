/**
 *
 * BuildingPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IBuildingDto } from 'api/odata/generated/entities/IBuildingDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import {
  selectGlobalServiceGroupFilter,
  selectIsAuthenticated,
} from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity, Identifiable } from 'types/common';

export type BuildingsUrl = 'base' | 'assetBase' | 'public' | 'locations';
export interface BuildingPickerProps
  extends Omit<AutocompletePickerProps<IBuildingDto>, 'loadData'> {
  urlType?: BuildingsUrl;
  withoutServiceGroups?: boolean;
}

const url = '/api/odata/v4/Buildings';
const assetBuildingUrl = '/api/odata/v4/Buildings/BuildingsFilter';
const publicBuildingUrl = '/api/odata/v4/Buildings/PublicBuildingsFilter';
const LocationsUrl = '/api/odata/v4/Locations';
export const initBuildingData = (
  initval: string | undefined,
  sourceUrl?: BuildingsUrl,
  withoutServiceGroups?: boolean,
) => {
  if (initval === undefined) {
    return new Promise<IBuildingDto[]>((resolve, reject) => {
      resolve([] as IBuildingDto[]);
    });
  } else {
    if (sourceUrl === 'locations') {
      withoutServiceGroups = true;
    }
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select:
        withoutServiceGroups === undefined || withoutServiceGroups === false
          ? 'Id,Name,ServiceGroups'
          : 'Id,Name',
      $skip: 0,
      $top: 1,
    };
    let initUrl =
      sourceUrl === 'public'
        ? publicBuildingUrl
        : sourceUrl === 'assetBase'
        ? assetBuildingUrl
        : sourceUrl === 'locations'
        ? LocationsUrl
        : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as IBuildingDto[]);
  }
};

const loadData = (
  predicates: (string | Condition<IBuildingDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  isAuthenticated?: boolean,
  urlType?: BuildingsUrl,
  withoutServiceGroups?: boolean,
) => {
  if (urlType === 'locations') {
    withoutServiceGroups = true;
  }
  const sourceUrl =
    urlType === 'public' || !isAuthenticated
      ? publicBuildingUrl
      : urlType === 'assetBase'
      ? assetBuildingUrl
      : urlType === 'locations'
      ? LocationsUrl
      : url;
  return getAutoCompleteLoadDataFn<IBuildingDto>({
    url: sourceUrl,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select:
      withoutServiceGroups === undefined || withoutServiceGroups === false
        ? ['Id', 'Name', 'ServiceGroups']
        : ['Id', 'Name'],
    globalServiceGroupFilter:
      (withoutServiceGroups === undefined || withoutServiceGroups === false) &&
      !!globalServiceGroupFilter &&
      globalServiceGroupFilter.length > 0
        ? [
            new Condition<IBuildingDto>(
              'ServiceGroups',
              ODataOperators.AnyIn,
              globalServiceGroupFilter.map(f => f.Id),
              'Id',
            ),
          ]
        : [],
  });
};

export function BuildingPicker(props: BuildingPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const isAuth = useSelector(selectIsAuthenticated);
  const innerLoadData = loadData(
    props.predicates,
    isAuth ? globalServiceGroupFilter || [] : [],
    isAuth,
    props.urlType,
    props.withoutServiceGroups,
  );
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={innerLoadData}
      id={props.id || 'BuildingId'}
      {...props}
    />
  );
}
export const BuildingByServiceGroupFilter = (
  value: FilterValueType,
  fieldName: any,
) => {
  if (value !== undefined && value !== null) {
    if (fieldName === 'ServiceGroupId') {
      return [
        `${new Condition<IBuildingDto>(
          'ServiceGroups',
          ODataOperators.AnyIn,
          [(value as Identifiable<number>).Id],
          'Id',
        ).toString()}`,
      ];
    }
  }
  return [];
};
