import {
  selectAuthenticatedUser,
  selectglobalSettings,
} from 'app/slice/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AllowedSettings } from 'utils/globalSettings';
import * as React from 'react';
import { DropDown } from 'app/components/DropDown';
import { MenuItem } from '@material-ui/core';
import { isEmptyOrWhitespace } from 'utils/typeUtils';
import { translations } from 'locales/translations';
import { toRootedURL } from 'utils/url-utils';
import { IconProp } from 'app/AllIcons';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { bookitColors } from 'styles/colors/bookitColors';

export interface SwitchSitesProps {}

export function SwitchSites(props: SwitchSitesProps) {
  const { t } = useTranslation();
  const settings = useSelector(selectglobalSettings);
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const allowToSwitchToOtherSites = settings.GetBooleanByKey(
    AllowedSettings.AllowToSwitchToOtherSites,
  );
  const externalTitleOne = settings.GetNullableByKey(
    AllowedSettings.ExternalSwitchTitle1,
  );
  const externalUrlOne = settings.GetNullableByKey(
    AllowedSettings.ExternalSwitchUrl1,
  );
  const externalTitleTwo = settings.GetNullableByKey(
    AllowedSettings.ExternalSwitchTitle2,
  );
  const externalUrlTwo = settings.GetNullableByKey(
    AllowedSettings.ExternalSwitchUrl2,
  );
  const externalSitesIcon = settings.GetNullableByKey(
    AllowedSettings.ExternalSitesIcon,
  );
  const buttonIcon = React.useMemo(() => {
    if (externalSitesIcon !== null) {
      if (externalSitesIcon.indexOf('globe-europe') !== -1) {
        return 'earth-europe' as IconProp;
      } else if (externalSitesIcon.indexOf(' fa-') !== -1) {
        let tmpIcon = externalSitesIcon.substring(
          externalSitesIcon.indexOf(' fa-') + 4,
          externalSitesIcon.length,
        );
        return tmpIcon as IconProp;
      } else {
        return 'earth-europe' as IconProp;
      }
    } else {
      return 'earth-europe' as IconProp;
    }
  }, [externalSitesIcon]);
  const hasExternalOne = React.useMemo(() => {
    return !(
      isEmptyOrWhitespace(externalTitleOne) ||
      isEmptyOrWhitespace(externalUrlOne)
    );
  }, [externalTitleOne, externalUrlOne]);
  const hasExternalTwo = React.useMemo(() => {
    return !(
      isEmptyOrWhitespace(externalTitleTwo) ||
      isEmptyOrWhitespace(externalUrlTwo)
    );
  }, [externalTitleTwo, externalUrlTwo]);
  const useExternalLinks = React.useMemo(() => {
    return (
      allowToSwitchToOtherSites &&
      (hasExternalOne || hasExternalTwo) &&
      authenticatedUser?.IsActiveDirectory
    );
  }, [
    allowToSwitchToOtherSites,
    authenticatedUser?.IsActiveDirectory,
    hasExternalOne,
    hasExternalTwo,
  ]);
  const items: React.ReactNode[] = React.useMemo(() => {
    let actions: React.ReactNode[] = [];
    if (hasExternalOne && externalUrlOne !== null) {
      actions.push(
        <MenuItem
          component={'a'}
          key={'firstExternal'}
          href={toRootedURL(externalUrlOne)}
        >
          {externalTitleOne}
        </MenuItem>,
      );
    }
    if (hasExternalTwo && externalUrlTwo !== null) {
      actions.push(
        <MenuItem
          component={'a'}
          key={'secondExternal'}
          href={toRootedURL(externalUrlTwo)}
        >
          {externalTitleTwo}
        </MenuItem>,
      );
    }
    return actions;
  }, [
    externalTitleOne,
    externalTitleTwo,
    externalUrlOne,
    externalUrlTwo,
    hasExternalOne,
    hasExternalTwo,
  ]);
  return useExternalLinks ? (
    <DropDown
      size="small"
      color="primary"
      variant="ghost"
      style={{ padding: '4px 4px' }}
      title={t(translations.SwitchToOtherSites)}
      menuChildren={() => items}
      menuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      <Icon
        icon={buttonIcon}
        style={{
          width: 20,
          height: 20,
          fontSize: 20,
          color: bookitColors.grayscale.grayComponents,
        }}
      />
    </DropDown>
  ) : (
    <React.Fragment></React.Fragment>
  );
}
