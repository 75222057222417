import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { Entity } from 'types/common';
import { IsEmptyArray, IsEntity } from '../BasicFilter/AppliedFilterComponent';

import { FilterValueType } from '../BasicFilter/FilterValueType';

const extractValuePart = function (
  value: FilterValueType | undefined,
): FilterValueType | undefined {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return undefined;
  }
  if (IsEntity(value)) {
    return (value as Entity<number | string>).Id;
  }

  return value;
};

export function TableFilterBuilder<TRow extends Record<string, any>>(
  appliedFilters?: Array<IFilterSettings<TRow>>,
  globalFilterValue?: string | undefined,
  serviceGroups?: Entity<number>[],
  searchColumns?: Array<keyof TRow>,
  additionalFilters?: (string | Condition<TRow>)[],
  multipleServiceGroups?: boolean,
) {
  return new ODataFilterBuilder<TRow>({
    predicates: ((appliedFilters || [])
      .map(filterSettingToCondition)
      .filter(f => f !== null)
      .filter(f => f !== undefined) as (string | Condition<TRow>)[]).concat(
      additionalFilters ?? [],
    ),
    globalServiceGroupFilter:
      serviceGroups === undefined || serviceGroups.length === 0
        ? undefined
        : [
            new Condition<any>(
              multipleServiceGroups ? 'ServiceGroups' : 'ServiceGroupId',
              multipleServiceGroups
                ? ODataOperators.AnyIn
                : ODataOperators.Includes,
              serviceGroups,
              multipleServiceGroups ? 'Id' : undefined,
            ),
          ],
    stringColumns: searchColumns || [],
    stringSearch: globalFilterValue,
  }).toString();
}

export function filterSettingToCondition<TRow extends Record<string, any>>(
  filter: IFilterSettings<TRow>,
) {
  if (typeof filter === 'string') {
    return filter;
  }
  if (
    filter.value === undefined ||
    filter.value === null ||
    IsEmptyArray(filter.value)
  ) {
    return undefined;
  }
  if (filter.customFilter) {
    return filter.customFilter(filter.value, filter);
  }

  var value = extractValuePart(filter.value);
  if (value === undefined) {
    return undefined;
  }

  return new Condition<TRow>(
    filter.fieldName,
    filter.operator,
    value,
    filter.fieldName1,
    filter.isInversed,
  );
}
