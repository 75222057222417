import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';

export interface UpdateFilesModel {
  files: IFormFileValue[];
  typeId: number;
  referenceId: number;
  formType: number;
  operation: string;
  updateOption: number;
  reccurentAssetId: number | null;
  recurrentGroupId: number | null;
  alertTypeId: number | null;
}
export const getFilesModel = (
  original: IFormFileValue[],
  current: IFormFileValue[],
  type: number,
  referenceId: number,
  formType: number,
  updateOption: number,
  reccurentAssetId: number | null,
  recurrentGroupId: number | null,
  alertTypeId: number | null,
) => {
  const filesData: UpdateFilesModel[] = [];
  if (current.length > 0 || original.length > 0) {
    let added =
      current.length > 0
        ? current.filter(f => f.Value === null && f.PostedFile !== null)
        : [];
    let removed =
      original.length > 0 && current.length > 0
        ? original.filter(
            f => f.Value !== null && !current.some(s => s.Value === f.Value),
          )
        : [];
    if (added.length > 0) {
      filesData.push({
        files: added,
        typeId: type,
        referenceId: referenceId,
        formType: formType,
        operation: 'added',
        updateOption: updateOption,
        reccurentAssetId: reccurentAssetId,
        recurrentGroupId: recurrentGroupId,
        alertTypeId: alertTypeId,
      });
    }
    if (removed.length > 0) {
      filesData.push({
        files: removed,
        typeId: type,
        referenceId: referenceId,
        formType: formType,
        operation: 'removed',
        updateOption: updateOption,
        reccurentAssetId: reccurentAssetId,
        recurrentGroupId: recurrentGroupId,
        alertTypeId: alertTypeId,
      });
    }
  }
  return filesData;
};
