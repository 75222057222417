import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectServiceRequestStatusesByInternalId } from '../../slice/selectors';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useServiceRequestStatusUpdate } from 'app/pages/Samples/components/Utils';
import { IRequestDetailsModel } from '../../slice/types';
import { Button } from 'app/components/BasicButtons/Button';

export function canSendQuote(
  isAdmin: boolean,
  serviceRequest?: IRequestDetailsModel,
) {
  return (
    serviceRequest?.Service.SendQuoteToUser === true &&
    isAdmin === true &&
    ['InProcess', 'Draft'].includes(serviceRequest?.Status.InternalStatusId)
  );
}
export interface ISendQuoteButtonButtonProps {
  serviceRequest: IRequestDetailsModel;
}
export function SendQuoteButtonButton(props: ISendQuoteButtonButtonProps) {
  const { t } = useTranslation();
  const statuses = useSelector(state =>
    selectServiceRequestStatusesByInternalId(state, 'QuoteSendToUser'),
  );

  const { changeStatus } = useServiceRequestStatusUpdate({
    serviceRequestStatusId: props.serviceRequest?.Id,
  });

  // const submitEnabled = React.useMemo(() => canSendQuote(props), [props]);

  const handleClick = () => {
    const status = statuses?.[0];
    if (status !== undefined) {
      changeStatus({
        status: status,
      });
    }
  };

  return (
    <Button
      size="small"
      startIcon={<Icon icon={'save'} />}
      variant="ghost"
      aria-label={t(translations.SendQuote)}
      disabled={statuses === undefined || statuses.length === 0}
      onClick={handleClick}
      title={t(translations.SendQuote)}
    >
      {t(translations.SendQuote)}
    </Button>
  );
}
