import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.messagesState || initialState;

export const selectMessages = createSelector([selectSlice], state =>
  state.messages.filter(
    (val, ind, arr) => arr.findIndex(f => f.key === val.key) === ind,
  ),
);
export const selectMsgInitProcessing = createSelector(
  [selectSlice],
  state => state.processing,
);
