import { Entity } from 'types/common';

/**
 * Combines Id & Name into an object that represents an entity
 * @param id Entity Id
 * @param name Entity Name
 * @returns Entity with Id & Name populated
 */
export function toEntity<T>(
  id: T | null | undefined,
  name?: string | null,
): Entity<T> | null {
  if (id == null || id === undefined || name == null || name === undefined) {
    return null;
  } else {
    return { Id: id, Name: name ?? id };
  }
}
