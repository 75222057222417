import { IModificationsDto } from 'api/odata/generated/entities/IModificationsDto';
import DateRenderer from 'app/components/BasicTable/CellRenderers/DateRenderer';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';

export const getColumns = (t: TFunction) => {
  const res: Column<IModificationsDto>[] = [
    // {
    //   accessor: 'Id',
    //   Header: t(translations.HashSymbol) as string,
    // },
    {
      accessor: 'Field',
      Header: t(translations.Field) as string,
    },
    {
      accessor: 'OldValue',
      Header: t(translations.OldValue) as string,
    },
    {
      accessor: 'NewValue',
      Header: t(translations.NewValue) as string,
    },
    {
      accessor: 'UserDisplayName',
      Header: t(translations.User) as string,
    },
    {
      accessor: 'Date',
      Header: t(translations.Date) as string,
      Cell: DateRenderer,
    },
  ];
  return res;
};
