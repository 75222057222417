/**
 *
 * AsyncPicker
 *
 */
import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { httpClient } from 'api/HttpClient';
import { Identifiable } from 'types/common';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IServiceGroupDto } from 'api/odata/generated/entities/IServiceGroupDto';

export type GroupUrl =
  | 'base'
  | 'admin'
  | 'regpublic'
  | 'public'
  | 'billableAdminUrl';

export interface ServiceGroupPickerProps
  extends Omit<AutocompletePickerProps<IServiceGroupDto>, 'loadData'> {
  urlType: GroupUrl;
}

const url = '/api/odata/v4/servicegroups';
const adminUrl = '/api/odata/v4/servicegroups/GetAdminServiceGroups';
const publicUrl = '/api/odata/v4/servicegroups/GetPublicServiceGroups';
const regPublicUrl = '/api/odata/v4/servicegroups/GetRegistrationServiceGroups';
const billableAdminUrl =
  '/api/odata/v4/servicegroups/GetBillableAdminServiceGroups';

export const initServiceGroupsData = (
  initval: string | undefined,
  urlType: GroupUrl | undefined,
) => {
  if (initval === undefined) {
    return new Promise<IServiceGroupDto[]>((resolve, reject) => {
      resolve([] as IServiceGroupDto[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name,DivisionId,DivisionName',
      $skip: 0,
      $top: 1,
    };
    let initUrl =
      urlType === undefined
        ? url
        : urlType === 'public'
        ? publicUrl
        : urlType === 'regpublic'
        ? regPublicUrl
        : urlType === 'admin'
        ? adminUrl
        : urlType === 'billableAdminUrl'
        ? billableAdminUrl
        : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as IServiceGroupDto[]);
  }
};

export function ServiceGroupPicker(props: ServiceGroupPickerProps) {
  const loadData = getAutoCompleteLoadDataFn<IServiceGroupDto>({
    url:
      props.urlType === 'public'
        ? publicUrl
        : props.urlType === 'regpublic'
        ? regPublicUrl
        : props.urlType === 'admin'
        ? adminUrl
        : props.urlType === 'billableAdminUrl'
        ? billableAdminUrl
        : url,
    select: ['Id', 'Name', 'DivisionId', 'DivisionName'],
    predicates: props.predicates,
  });
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      id={props.id || 'serviceGroupId'}
      size={props.size}
      loadData={loadData}
      {...props}
    />
  );
}

export const ServiceGroupByDivisionFilter = (value: FilterValueType) => {
  if (value !== undefined && value !== null) {
    return [
      `${new Condition<IServiceGroupDto>(
        'DivisionId',
        ODataOperators.Equals,
        (value as Identifiable<number>).Id,
      ).toString()}`,
    ];
  }
  return [];
};

export const serviceGroupFilterUrl = url;
