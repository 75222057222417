import { httpClient } from 'api/HttpClient';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { ConfigurableTypes } from 'enums/ConfigurableTypes';
import { Column } from 'react-table';
import { IRow } from './IRow';

/**
 * Simplest in memory cache of the configurable columns
 * TODO: replace this one with something a little bit capable (e.g. expiration, cache-control, etc.)
 */
const configurableColumnsCache: Record<
  string,
  Promise<Array<ConfigurableColumns>>
> = {};

export interface ConfigurableColumns {
  Id: string;
  DisplayName: string;
  CustomName: string;
  hasCustomName: boolean;
}

/**
 * Returns cached configurable columns data. Can be also used for pre fetching screens that are likely will be visited.
 * Server request can be already resolved one which will return the data immediately.
 * If the request is pending, it will resolve for all callers on completion.
 * In case no calls were made yet with exact params, a new request will be issued and a pending promis will be returned.
 * @param listId listId aka screenId
 * @param configurableType screen/export
 * @param force forces reload of configurable column and ignores cache
 * @returns A promise of the configurable columns data.
 */
export async function GetCachedConfigurableColumnsData(
  listId: number,
  configurableType: number,
  isAuthenticated?: boolean,
  force?: boolean,
) {
  const privateUrl = '/api/odata/v4/ConfigurableColumns';
  const publicUrl = '/api/odata/v4/ConfigurableColumns/PublicColumns';
  const url = isAuthenticated ? privateUrl : publicUrl;
  const param = { ListID: listId, ConfigurableType: configurableType };
  const key = JSON.stringify(param);

  // drops stored value so it will be reloaded later
  if (force === true) {
    delete configurableColumnsCache[key];
  }

  // load configurable columns data if it's not present yet
  if (configurableColumnsCache[key] === undefined) {
    configurableColumnsCache[key] = loadData(url, param);
  }
  return await configurableColumnsCache[key];
}
/**
 * Loads configurable columns from the server. Throws an error if there's no columns configured
 * @param url
 * @param param
 * @returns
 */
async function loadData(url, param) {
  try {
    const response = await httpClient.get<
      IODataQueryResponse<ConfigurableColumns>
    >(url, param);
    if (!response || !response.value || response.value.length === 0) {
      throw new Error('Unexpected configurable columns response');
    }
    return response.value;
  } catch (error) {
    throw error;
  }
}

export function getTableColumns<TRow extends IRow>({
  response,
  ConfigurableColumnsSetUp,
}: {
  response: ConfigurableColumns[];
  ConfigurableColumnsSetUp: Column<TRow>[];
}) {
  var res: Column<TRow>[] = [];

  if (!response || response.length === 0) {
    return ConfigurableColumnsSetUp;
  } else {
    for (var i = 0; i < response.length; i++) {
      for (var j = 0; j < ConfigurableColumnsSetUp.length; j++) {
        if (
          ConfigurableColumnsSetUp[j].accessor === response[i].Id ||
          ConfigurableColumnsSetUp[j].accessor === response[i].DisplayName ||
          // This is for dummy columns when no accessor is defined.
          ConfigurableColumnsSetUp[j].Header === response[i].DisplayName
        ) {
          if (response[i].hasCustomName) {
            ConfigurableColumnsSetUp[j].Header = response[i].CustomName;
          }
          res.push(ConfigurableColumnsSetUp[j]);
          break;
        }
      }
    }
    return res;
  }
}

export async function TryGetExportColumns<TRow extends IRow>(
  listId: number | undefined,
  columns: Array<Column<TRow>>,
): Promise<Array<keyof TRow> | undefined> {
  try {
    if (listId === undefined) {
      return getColumnAccessors(columns);
    } else {
      const columnsData = await GetCachedConfigurableColumnsData(
        listId,
        ConfigurableTypes.ColumnExportConfig,
      );
      return columnsData.map(item => item.Id);
    }
  } catch (error) {
    return getColumnAccessors(columns);
  }
}

export function getColumnAccessors<TRow extends IRow>(
  columns: Column<TRow>[],
): Array<keyof TRow> {
  const result: Array<keyof TRow> = columns
    .filter(c => typeof c.accessor === 'string')
    .filter(c => c.accessor !== undefined)
    .map(c => c.accessor as keyof TRow);
  return result;
}
