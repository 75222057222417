/**
 *
 * FundingTypeIdPicker
 *
 */

import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import { InvoiceFundingType } from 'enums/InvoiceFundingType';
import { Entity } from 'types/common';
import { enumToEntityArray } from 'utils/enumKeys';

export interface FundingTypePickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {}

export const getSingleInvoiceFundingType = (val: number): Entity<number> => {
  return enumToEntityArray(InvoiceFundingType).filter(item => {
    return item.Id === val;
  })[0];
};

export const initFundingTypeData = (
  initval: string | undefined,
): Promise<Entity<number>[]> => {
  const val = parseInt(initval ?? '');
  if (initval === undefined || isNaN(val)) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([getSingleInvoiceFundingType(val)]);
    });
  }
};

const getOptions = (searchTerm: string | null) => {
  let items = enumToEntityArray(InvoiceFundingType);
  if (searchTerm !== null && searchTerm !== '') {
    return items.filter(
      f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
    );
  }
  return items;
};

export function FundingTypeIdPicker(props: FundingTypePickerProps) {
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      value={props.value}
      style={{ marginRight: '4px' }}
      {...props}
    />
  );
}
