/**
 *
 * CustomFormRadioGroup
 *
 */
import React from 'react';

import { FormRadioGroup } from 'app/components/Forms/FromRadioGroup';
import { CustomFormFieldProps } from 'app/components/CustomForm/CustomFormFields';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { Entity } from 'types/common';

export function CustomFormRadioGroup({
  formField,
  readonly,
  disabled,
  ...props
}: CustomFormFieldProps) {
  const options = getOptions(formField);

  const title = formField.Description ?? undefined;
  return (
    <FormRadioGroup
      {...props}
      key={formField.Id}
      options={options}
      disabled={readonly || disabled}
      label={formField.Label}
      helperText={formField.Description ?? undefined}
      title={title}
    />
  );
}

const getOptions = (formField: IFormFieldDto): Entity<string>[] => {
  if (formField) {
    return createOptions(formField);
  } else {
    return [] as Entity<string>[];
  }
};
const createOptions = (field: IFormFieldDto): Entity<string>[] => {
  if (field.Options && field.Options !== null) {
    switch (field.OptionsSourceType) {
      case 'Internal':
        return deserializeOptions(field.Options);

      case 'SimpleList':
        return deserializeOptions(field.Options.split(','));
      case 'External':
        return [] as Entity<string>[];
    }
  } else {
    return [] as Entity<string>[];
  }
};
const deserializeOptions = (options: any): Entity<string>[] => {
  let optsArray = [] as Entity<string>[];
  if (Array.isArray(options)) {
    optsArray = options.map(opt => {
      return { Id: opt, Name: opt } as Entity<string>;
    });
  } else {
    try {
      let items = JSON.parse(options);
      if (Array.isArray(items)) {
        let arr = items.map(o => {
          if (typeof o == 'string') {
            return { Id: o, Name: o, Parent: null } as Entity<string>;
          } else if (o.hasOwnProperty('id') && o.hasOwnProperty('text')) {
            return {
              Id: o.id,
              Name: o.text,
            } as Entity<string>;
          } else {
            return undefined;
          }
        });
        optsArray =
          (arr.filter(o => o !== undefined) as Entity<string>[]) ||
          ([] as Entity<string>[]);
      }
      return optsArray;
    } catch {
      return optsArray;
    }
  }

  return optsArray;
};
