import * as React from 'react';
import { Button } from 'app/components/BasicButtons/Button';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOnlineBudgetsStatusSlice } from '../slice';
import { selectGenerateInvoicesState } from '../slice/selectors';
import { IInvoiceBudgetBalanceDto } from 'api/odata/generated/entities/IInvoiceBudgetBalanceDto';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';

export interface GenerateInvoicesButtonProps {
  endDate: Date;
  selected: IInvoiceBudgetBalanceDto[];
  disabled?: boolean;
  disabledTitle?: string;
}
export const GenerateInvoicesButton = ({
  endDate,
  selected,
  disabled,
  disabledTitle,
}: GenerateInvoicesButtonProps) => {
  const { t } = useTranslation();
  const generateInvoicesState = useSelector(selectGenerateInvoicesState);
  const dispatch = useDispatch();
  const { actions } = useOnlineBudgetsStatusSlice();
  return (
    <>
      <Tooltip title={disabledTitle}>
        <span>
          <Button
            onClick={() =>
              dispatch(
                selected.length > 0
                  ? actions.GenerateInvoicesAndChargeOnlineBudgets({
                      endDate: endDate,
                      selected: selected,
                      chargeOnlineBudgets: false,
                    })
                  : actions.generateInvoices({ endDate: endDate }),
              )
            }
            processing={generateInvoicesState.processing}
            disabled={disabled === true || selected.length === 0}
          >
            {t(translations.GenerateInvoiceForEstimatedCharges)}
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
