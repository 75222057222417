import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PrintPDFItem } from './types';
import { printPDFActions as actions } from '.';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { appSettingsActions } from 'app/slice';
import { selectPrintPDFSections } from './selectors';

function* doUpdateSections(action: PayloadAction<PrintPDFItem[]>) {}
function* doSaveSections(action: PayloadAction) {
  const sections: PrintPDFItem[] = yield select(selectPrintPDFSections);
  const value = sections.filter(s => s.checked === true).map(f => f.id);
  const user = yield select(selectAuthenticatedUser);
  yield put(
    appSettingsActions.updateUserProfileSettings({
      key: '/RequestSamples.pdf',
      model: {
        UserName: user.Id,
        Key: '/RequestSamples.pdf',
        Value: value.join(','),
        Id: 0,
        AdGroup: null,
        DefaultGroups: null,
        Enabled: true,
        IsMultiple: null,
        Name: null,
        Selected: false,
        StaffEnabled: false,
      },
    }),
  );
}
export function* printPDFSaga() {
  yield takeLatest(actions.updateSections.type, doUpdateSections);
  yield takeLatest(actions.saveSections.type, doSaveSections);
}
