/**
 *
 * BasicDialogContent
 *
 */
import {
  DialogContent,
  DialogContentProps as MuiDialogContentProps,
  styled,
} from '@material-ui/core';
export interface BasicDialogContentProps extends MuiDialogContentProps {
  children: React.ReactNode;
}
export function BasicDialogContent(props: BasicDialogContentProps) {
  const { children } = props;
  return <StyledDialogContent>{children}</StyledDialogContent>;
}
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderTop: 'unset',
}));
