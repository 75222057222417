import { PayloadAction } from '@reduxjs/toolkit';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { CustomDate } from 'types/CustomDate';
import {
  ConnectedFiltersInit,
  IConnectedFiltersDto,
} from 'types/IConnectedFiltersDto';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { connectedFiltersSaga } from './saga';
import { ConnectedFiltersSettings, ConnectedFiltersState } from './types';

export const initialState: ConnectedFiltersState = {
  processing: false,
  data: {
    StartTime: new CustomDate(new Date()).toISOString(),
    EndTime: undefined,
    User: null,
    UserGroup: null,
    Budget: null,
    BudgetExperiment: null,
    InstituteProject: null,
    ExternalCustomer: null,
    FundingType: getSingleInvoiceFundingType(0),
    PurchaseOrder: undefined,
    Reference: undefined,
  },
  connectedValueHandler: undefined,
  connectedSettings: {
    budgetExpirementVisible: undefined,
    budgetLimitedByUsers: undefined,
    budgetVisible: undefined,
    endTimeVisible: undefined,
    extCustomerVisible: undefined,
    fundingTypeVisible: undefined,
    hideProject: undefined,
    instProjVisible: undefined,
    isBudgetsWithoutUserGroup: undefined,
    isServiceAdmin: undefined,
    onlyOwnUserGroupsAllowed: undefined,
    purchaseOrderVisible: undefined,
    referenceVisible: undefined,
    ReservationUserGroupGroupByBudgetUserGroup: undefined,
    startTimeVisible: undefined,
    userGroupVisible: undefined,
    userVisible: undefined,
    isUserGroupCoordinator: undefined,
    chargesViewAllowed: undefined,
  },
};

const slice = createSlice({
  name: 'connectedFilters',
  initialState,
  reducers: {
    resetState(state, action: PayloadAction<Date | null>) {
      state.data = ConnectedFiltersInit();
    },
    saveState(state, action: PayloadAction<IConnectedFiltersDto>) {
      state.data = {
        StartTime: action.payload.StartTime,
        EndTime: action.payload.EndTime,
        User: action.payload.User,
        UserGroup: action.payload.UserGroup,
        Budget: action.payload.Budget,
        BudgetExperiment: action.payload.BudgetExperiment,
        InstituteProject: action.payload.InstituteProject,
        ExternalCustomer: action.payload.ExternalCustomer,
        FundingType: action.payload.FundingType,
        PurchaseOrder: action.payload.PurchaseOrder,
        Reference: action.payload.PurchaseOrder,
      };
    },
    setSubmit(state, action: PayloadAction<boolean>) {
      state.processing = action.payload;
    },
    resetConnectedState(state, action: PayloadAction) {},
    resetConnectedState_success(state, action: PayloadAction) {
      state.connectedSettings = {
        budgetExpirementVisible: undefined,
        budgetLimitedByUsers: undefined,
        budgetVisible: undefined,
        endTimeVisible: undefined,
        extCustomerVisible: undefined,
        fundingTypeVisible: undefined,
        hideProject: undefined,
        instProjVisible: undefined,
        isBudgetsWithoutUserGroup: undefined,
        isServiceAdmin: undefined,
        onlyOwnUserGroupsAllowed: undefined,
        purchaseOrderVisible: undefined,
        referenceVisible: undefined,
        ReservationUserGroupGroupByBudgetUserGroup: undefined,
        startTimeVisible: undefined,
        userGroupVisible: undefined,
        userVisible: undefined,
        isUserGroupCoordinator: undefined,
        chargesViewAllowed: undefined,
      };
      state.data = {
        StartTime: new CustomDate(new Date()).toISOString(),
        EndTime: undefined,
        User: null,
        UserGroup: null,
        Budget: null,
        BudgetExperiment: null,
        InstituteProject: null,
        ExternalCustomer: null,
        FundingType: getSingleInvoiceFundingType(0),
        PurchaseOrder: undefined,
        Reference: undefined,
      };
      state.connectedValueHandler = undefined;
      state.completed = undefined;
      state.processing = undefined;
      state.hasErrors = undefined;
    },
    setConnectedValue(
      state,
      action: PayloadAction<{
        fieldKey: keyof IConnectedFiltersDto;
        fieldValue: any;
      }>,
    ) {},
    setConnectedValueSuccess(
      state,
      action: PayloadAction<{
        fieldKey: keyof IConnectedFiltersDto;
        fieldValue: any;
      }>,
    ) {
      state.connectedValueHandler = {
        run: true,
        fieldKey: action.payload.fieldKey,
        fieldValue: action.payload.fieldValue,
        shouldValidate: true,
      };
    },
    resetConnectedHandler(state, action: PayloadAction) {
      state.connectedValueHandler = undefined;
    },
    extendSettingsState(
      state,
      action: PayloadAction<{
        services: IServiceTypeFilterDto[];
        globalSettings: GlobalSettingsType;
        settings: ConnectedFiltersSettings;
        isEdit: boolean;
        data?: IConnectedFiltersDto;
      }>,
    ) {},
    updateSettingsState(
      state,
      action: PayloadAction<ConnectedFiltersSettings>,
    ) {
      state.connectedSettings = action.payload;
    },
  },
});

export const { actions: connectedFiltersActions } = slice;

export const useConnectedFiltersSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: connectedFiltersSaga });
  return { actions: slice.actions };
};
