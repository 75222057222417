/**
 *
 * ChipLabelGroup
 *
 */
import {
  FormControl,
  FormHelperText,
  FormLabel,
  styled,
} from '@material-ui/core';
import * as React from 'react';
import { inputLabelXS } from 'styles/typography/bookitTypography';
import { BookitChipWithInfo } from '.';
import { CheckEntity, ChipEntity } from '../CheckBoxGroup';

export interface ChipLabelGroupProps {
  options: ChipEntity[];
  value?: ChipEntity[];
  className?: string;
  onChipClick?: (chip: ChipEntity) => void;
  onChange?: (
    value: CheckEntity,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  id: string;
  //formik
  name?: string;
  error?: boolean;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  info?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  margin?: 'none' | 'dense' | 'normal';
  isRow?: boolean;
  style?: React.CSSProperties;
}

export function ChipLabelGroup({
  name,
  id,
  value,
  options,
  onChange,
  label,
  onChipClick,
  required,
  helperText,
  disabled,
  fullWidth,
  margin,
  error,
  size,
  isRow,
  style,
  ...props
}: ChipLabelGroupProps) {
  //   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const value = {
  //       Id: parseInt(event.target.id),
  //       Name: event.target.name,
  //       checked: event.target.checked,
  //     } as CheckEntity;
  //     if (onChange !== undefined) {
  //       onChange(value, event);
  //     }
  //   };
  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      name={name}
      margin={margin}
      id={id}
      variant="filled"
      fullWidth={fullWidth}
      style={{
        border: '1px solid #CDD5DF',
        borderRadius: '6px',
        minHeight: '48px',
        backgroundColor: '#ffffffcc',
        paddingBlock: '8px',
        paddingInline: '6px',
        ...style,
      }}
    >
      <StyledFormLabel>{label}</StyledFormLabel>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          gap: '6px',
        }}
      >
        {options.map((item, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px',
              width: '260px',
            }}
            key={`wrap-${item.Id}`}
          >
            <BookitChipWithInfo
              onClick={() => !!onChipClick && onChipClick(item)}
              //fullwidth={fullWidth}
              variant="default"
              size={size || 'small'}
              label={item.Name}
              key={`chip-${item.Id}`}
              customwidth={260}
              info={item.info}
            />
          </div>
        ))}
      </div>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.common.graySubtitle,
  ...inputLabelXS,
  '.Mui-focused &': {
    color: theme.palette.common.graySubtitle,
  },
}));
