import { Theme } from '@material-ui/core';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import * as React from 'react';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export interface SidePanelRootProps {
  children: React.ReactNode;
  className?: string;
}
export const SidePanelRoot = React.forwardRef(
  ({ className, children }: SidePanelRootProps, ref: any) => (
    <div ref={ref} className={className}>
      {children}
    </div>
  ),
);
export const SidePanelPopper = styled(
  ({
    sidePanelWidth,
    theme,
    children,
    ...other
  }: {
    sidePanelWidth: number;
    theme: Theme;
    children: React.ReactNode;
  } & PopperProps) => <Popper {...other}>{children}</Popper>,
)`
  z-index: ${props => props.theme.zIndex.modal};
  height: 100vh;
  border-left: 1px solid ${bookitColors.grayscale.grayBorders};
  &.rootopen {
    width: 100%;
    transform: unset;
    &.mobileroot {
      padding-top: 0px;
    }
  }
  &.rootclose {
    width: 100vw;
    &.mobileroot {
      inset-block: 50px auto;
      height: calc(100% - 50px);
      padding-top: 0px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    &.rootclose {
      width: 100%;
      min-width: 600px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    &.rootclose {
      width: 100%;
      min-width: 370px;
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.values.md}px) {
    &.rootclose {
      width: ${props => props.sidePanelWidth}px;
    }
  }
  & .paper {
    background: ${props => props.theme.palette.common.background};
    border-radius: 0;
    max-height: 100vh;
    height: auto;
    margin: 0;
    box-shadow: unset;
  }
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    & .paper {
      max-width: 100%;
    }
  }
`;
