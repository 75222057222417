import { AxiosError } from 'axios';
import { IModelState } from 'types/Modelstate';

export function handleBadRequest(err: any): string[] | undefined {
  if (
    err.isAxiosError === true &&
    err.response &&
    err.response.status === 400 &&
    err.response.data &&
    err.response.data.ModelState
  ) {
    const ms = err.response.data.ModelState as IModelState;
    const messages = Object.keys(ms)
      .map(key => ms[key])
      .flat();
    return messages;
  }
}

/**
 * Extracts ModelState errors from odata responses
 * @param error http error
 * @returns
 */
export const getModelStateErrors = (
  error: unknown,
): Record<string, Array<string>> | undefined => {
  const axiosError = error as AxiosError;
  const isAxiosError = axiosError.isAxiosError === true;
  if (!isAxiosError) {
    return undefined;
  }
  const message = axiosError.response?.data?.error?.innererror
    ?.message as string;
  if (message === undefined) {
    return axiosError.response?.data?.error?.message;
  }
  return message?.split('\r\n').reduce((accumulator, currentValue) => {
    const separator = currentValue.indexOf(':');
    const key = currentValue.substring(0, separator).trim();
    if (key.length > 0) {
      const message = currentValue.substring(separator + 1).trim();
      if (accumulator[key] === undefined) {
        accumulator[key] = [];
      }
      accumulator[key].push(message);
    }
    return accumulator;
  }, {});
};

export function extractErrorMessages(error: unknown): string[] | undefined {
  if ((error as AxiosError).isAxiosError === true) {
    const response = (error as AxiosError).response;
    if (response?.data?.error?.message !== undefined) {
      return [response?.data?.error?.message];
    }
    if (response === undefined) {
      return [String(error)];
    }
    const status = (error as AxiosError)?.response?.status;
    switch (status) {
      case 400:
        return handleBadRequest(error);
      case 403:
        return ['NotAllowed'];
      default:
        return [String(error)];
    }
  } else if (typeof error === 'string') {
    return [error];
  } else if (error instanceof Error) {
    return [error.message];
  } else {
    return [String(error)];
  }
}
