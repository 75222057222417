import { Action } from '@reduxjs/toolkit';
import { Task } from 'redux-saga';
import { fork, take, cancel, ActionPattern } from 'redux-saga/effects';

/**
 * Similar to takeEvery, but also filters by the action parameters
 * see https://redux-saga.js.org/docs/api/#takelatestpattern-saga-args
 * @param patternOrChannel
 * @param keyfn
 * @param saga
 * @param args
 * @returns
 */
export const takeLatestByKey = <TPayload>(
  patternOrChannel: ActionPattern<Action<any>>,
  keyfn: (payload: TPayload) => string | number | symbol,
  saga,
  ...args
) =>
  fork(function* () {
    const tasks: Record<string | number | symbol, Task> = {};
    while (true) {
      const action = yield take(patternOrChannel);
      const key = keyfn(action.payload);
      const isKeyEmpty = key === null && key === undefined;
      if (isKeyEmpty) {
        console.warn(
          `Empty key (${JSON.stringify(key)}) in takeLatestByKey.`,
          patternOrChannel,
        );
      }

      if (!isKeyEmpty) {
        if (tasks[key]) {
          yield cancel(tasks[key]); // cancel is no-op if the task has already terminated
        }
      }
      const task: Task = yield fork(saga, ...args.concat(action));
      if (!isKeyEmpty) {
        tasks[key] = task;
      }
    }
  });
