import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.publicAsset || initialState;

export const selectPublicAsset = createSelector([selectSlice], state => state);

export const selectPublicAssetProcessing = createSelector(
  [selectPublicAsset],
  state => state.processing,
);

export const selectSelectedUnitItem = createSelector(
  [selectPublicAsset],
  state => state.data,
);
export const selectSelectedUnits = createSelector(
  [selectPublicAsset],
  state => state.selectedData,
);
export const selectSelectedUnitSearch = createSelector(
  [selectPublicAsset],
  state => state.dataSearch,
);
export const selectAssetPublicFilter = createSelector(
  [selectPublicAsset],
  state => state.filters,
);
export const selectMenuOpen = createSelector(
  [selectPublicAsset],
  state => state.menuOpen,
);
export const selectGlobalSearchOpen = createSelector(
  [selectPublicAsset],
  state => state.globalSearchOpen,
);
