import { Grow, Tabs, useTheme } from '@material-ui/core';
import { TabsActionRenderer } from './TabsActionRender';
import * as React from 'react';
import { IFilterSettings } from '../../BasicFilter/IFilterSettings';
import { DetectIsMobile } from 'utils/mobileDetect';
import { Row } from 'react-table';
import { IRow } from '../..';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ICanRaiseBasicTableRefreshEvent } from '../../ControlledTable/ControlledTableProps';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import clsx from 'clsx';

export interface TabsToolbarProps<TRow extends IRow>
  extends ICanRaiseBasicTableRefreshEvent {
  rowsCount: number;
  rows: Row<TRow>[];
  leftTabs?: TabsActionRenderer<TRow>[];
  rightTabActions?: TabsActionRenderer<TRow>[];
  selectedValue?: number;
  selectedArray: TRow[];
  toggleAllRowsSelected: (value?: boolean) => void;
  appliedFilters?: IFilterSettings<TRow>[];
  allRowsSelected?: boolean;
  isRowSelectable: (row: Row<TRow>) => boolean;
  getMultiMode?: (mode: boolean) => void;
  onAllRowsSelected?: (rows: Row<TRow>[], selected: boolean) => void;
  disableMultiMode?: boolean;
  defaultMultiMode?: boolean;
  showClear?: boolean;
  compact?: boolean;
  selectMultiLabel?: string;
}

export const TabsToolbar = React.memo(function TabsToolbarFunc<TRow>(
  props: TabsToolbarProps<TRow>,
) {
  const {
    leftTabs,
    selectedValue,
    onRefresh,
    selectedArray,
    toggleAllRowsSelected,
    appliedFilters,
    rowsCount,
    rows,
    allRowsSelected,
    isRowSelectable,
    getMultiMode,
    onAllRowsSelected,
    disableMultiMode,
    defaultMultiMode = true,
    rightTabActions,
    //showClear,
    compact,
  } = props;
  const [value, setValue] = React.useState(selectedValue || 0);
  const isMobile = DetectIsMobile();
  const { t } = useTranslation();
  const theme = useTheme();
  const [multiMode, setMultiMode] = React.useState<boolean>(
    (selectedArray.length > 0 && !disableMultiMode) ||
      defaultMultiMode === true,
  );
  // const handleChecked = React.useCallback(
  //   ev => {
  //     if (!multiMode) {
  //       setMultiMode(true);
  //       !!getMultiMode && getMultiMode(true);
  //     } else {
  //       let selectable = rows.filter(f => isRowSelectable(f));
  //       selectable.forEach(f => {
  //         f.isSelected && f.toggleRowSelected(false);
  //       });
  //       if (!!onAllRowsSelected) {
  //         onAllRowsSelected(selectable, false);
  //       }
  //       setMultiMode(false);
  //       !!getMultiMode && getMultiMode(false);
  //     }
  //   },
  //   [getMultiMode, isRowSelectable, multiMode, onAllRowsSelected, rows],
  // );
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    if (selectedArray.length > 1 || defaultMultiMode === true) {
      //!multiMode &&
      setMultiMode(true);
    } else {
      setMultiMode(false);
    }
  }, [defaultMultiMode, multiMode, selectedArray.length]);
  return (
    <TabsContainer
      theme={theme}
      className={clsx({ shortView: compact || isMobile })}
    >
      {!isMobile && !compact && (
        <div className={'tabsContent'}>
          {!!leftTabs && leftTabs.length > 0 && (
            <Grow in={true}>
              <Tabs
                action={ref => ref?.updateIndicator()}
                selectionFollowsFocus={false}
                indicatorColor="primary"
                value={value}
                onChange={(ev, value) => handleChange(ev, value)}
                aria-label="nav tabs example"
              >
                {leftTabs.map((tab, index) =>
                  tab(
                    rowsCount,
                    rows,
                    toggleAllRowsSelected,
                    selectedArray,
                    allRowsSelected,
                    onRefresh,
                    appliedFilters,
                  ),
                )}
              </Tabs>
            </Grow>
          )}
        </div>
      )}
      <div
        className={clsx('buttonsContent', { shortView: compact || isMobile })}
      >
        {/* selectedArray.length > 0 && (multiMode || showClear) && */}
        {!disableMultiMode && (
          <Button
            key={0}
            variant="ghost"
            size={compact || isMobile ? 'small' : 'medium'}
            startIcon={<Icon icon="times" />}
            disabled={!(selectedArray.length > 0)}
            onClick={event => {
              event.stopPropagation();
              let selectable = rows.filter(f => isRowSelectable(f));
              selectable.forEach(f => {
                f.toggleRowSelected(false);
              });
              if (!!onAllRowsSelected) {
                onAllRowsSelected(selectable, false);
              }
              let allPagesUnselected = selectedArray.length > selectable.length;
              setMultiMode(allPagesUnselected);
              !!getMultiMode && getMultiMode(allPagesUnselected);
            }}
          >
            {compact || isMobile
              ? t(translations.Unselect)
              : t(translations.ClearSelection)}
          </Button>
        )}
        {/* (multiMode === true || selectedArray.length > 0) && */}
        {!disableMultiMode && (
          <Button
            key={1}
            variant="ghost"
            title={t(translations.CoreMultiSelect_info) as string}
            size={compact || isMobile ? 'small' : 'medium'}
            startIcon={<Icon icon="check" />}
            onClick={() => {
              setMultiMode(true);
              !!getMultiMode && getMultiMode(true);
              let selectable = rows.filter(f => isRowSelectable(f));
              selectable.forEach(f => {
                f.toggleRowSelected(true);
              });
              if (!!onAllRowsSelected) {
                onAllRowsSelected(selectable, true);
              }
            }}
          >
            {t(translations.CoreSelectAll)}
          </Button>
        )}
        {/* <BasicToggle
          name="checkedB"
          disabled={disableMultiMode}
          checked={multiMode && !disableMultiMode}
          onClick={handleChecked}
          label={
            compact || isMobile
              ? (t(translations.SelectMultiple) as string)
              : selectMultiLabel ?? (t(translations.SelectMultiple) as string)
          }
        /> */}

        {/* {tabRightActions
          .filter(f => f !== undefined)
          .map((tab, index) => (
            <span key={index}>{tab(rowsCount, rows)}</span>
          ))} */}
      </div>
      {!!rightTabActions && rightTabActions.length > 0 && (
        <>
          {rightTabActions
            .filter(f => f !== undefined)
            .map((tab, index) => (
              <span key={index}>{tab(rowsCount, rows)}</span>
            ))}
        </>
      )}
    </TabsContainer>
  );
});
const TabsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: '8px 32px',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&.shortView': {
    flexDirection: 'column',
    padding: '8px 8px 16px',
    alignItems: 'start',
  },
  '& .tabsContent': {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  '& .buttonsContent': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    //flexWrap: isMobile ? 'wrap' : 'nowrap',
    gap: 16,
    '&.shortView': {
      gap: 0,
    },
  },
}));
