import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button } from 'app/components/BasicButtons/Button';
import { translations } from 'locales/translations';
import { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { FormTextField } from 'app/components/Forms/FormTextField';
import * as Yup from 'yup';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';

export interface PopupSuffixComponentProps {
  onSubmit: (value: string) => void;
  close: () => void;
}
interface ISuffixOptions {
  Suffix: string;
}
const schema: Yup.SchemaOf<ISuffixOptions> = Yup.object({
  Suffix: Yup.string().required(),
});
export function PopupSuffixComponent(props: PopupSuffixComponentProps) {
  const { t } = useTranslation();
  const initialValues: ISuffixOptions = useMemo(() => {
    return {
      Suffix: '',
    };
  }, []);
  const handleSubmitk = useCallback(
    values => {
      props.onSubmit(values.Suffix);
    },
    [props],
  );
  const content = useMemo(() => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnMount={true}
        validateOnChange={true}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitk(values);
        }}
      >
        <Form>
          <DialogTitle>
            {t(translations.AnalyticalSampleIDSuffix)} {'  '}
            <InfoIcon
              title={t(translations.AnalyticalSampleIDSuffix_info) as string}
            />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormTextField name="Suffix" fullWidth label="Suffix" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="white" onClick={props.close}>
              {t(translations.Cancel)}
            </Button>
            <Button variant="main" type="submit">
              {t(translations.Continue)}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    );
  }, [handleSubmitk, initialValues, props.close, t]);
  return <>{content}</>;
}
export interface AnalyticalSampleIDSuffixPopupProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
}
export function AnalyticalSampleIDSuffixPopup({
  show,
  onClose,
  onSubmit,
}: AnalyticalSampleIDSuffixPopupProps) {
  return (
    <>
      <SuffixDialog
        open={show}
        fullWidth={false}
        content={<PopupSuffixComponent close={onClose} onSubmit={onSubmit} />}
      />
    </>
  );
}
export interface SuffixDialogProps {
  open: boolean;
  content: React.ReactNode;
  fullWidth: boolean;
}
export function SuffixDialog(props: SuffixDialogProps) {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.open}
      aria-labelledby={'Suffix-dialog-popup'}
      fullScreen={false}
      fullWidth={props.fullWidth}
    >
      {props.content}
    </Dialog>
  );
}
