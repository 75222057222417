import { PayloadAction } from '@reduxjs/toolkit';
import {
  IRepetitiveDto,
  RepetitiveInit,
  ShortWeekDays,
} from 'types/IRepetitiveDto';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { repetitiveSaga } from './saga';
import { RepetitiveState } from './types';

export const initialState: RepetitiveState = {
  processing: false,
  data: {
    StartDate: undefined,
    RecurringEvents: null,
    RepeatsOnDays: ShortWeekDays().map(f => {
      if (f.Id === 0) f.checked = true;
      return f;
    }),
    RecurringEndDate: undefined,
    RepeatsEveryDay: null,
    RepeatsEveryPeriod: null,
    RepeatsMonthlyBy: null,
  },
  repetitiveValueHandler: undefined,
};

const slice = createSlice({
  name: 'repetitive',
  initialState,
  reducers: {
    resetState(state, action: PayloadAction<Date | string | undefined>) {
      state.data = RepetitiveInit(action.payload ?? new Date());
    },
    saveState(state, action: PayloadAction<IRepetitiveDto>) {},
    saveState_success(state, action: PayloadAction<IRepetitiveDto>) {
      state.data = {
        StartDate: action.payload.StartDate,
        RecurringEvents: action.payload.RecurringEvents,
        RepeatsOnDays: action.payload.RepeatsOnDays,
        RecurringEndDate: action.payload.RecurringEndDate,
        RepeatsEveryDay: action.payload.RepeatsEveryDay,
        RepeatsEveryPeriod: action.payload.RepeatsEveryPeriod,
        RepeatsMonthlyBy: action.payload.RepeatsMonthlyBy,
      };
    },
    setSubmit(state, action: PayloadAction<boolean>) {
      state.processing = action.payload;
    },
    setRepetitiveValue(
      state,
      action: PayloadAction<{
        fieldKey: keyof IRepetitiveDto;
        fieldValue: any;
      }>,
    ) {},
    setRepetitiveValueSuccess(
      state,
      action: PayloadAction<{
        fieldKey: keyof IRepetitiveDto;
        fieldValue: any;
      }>,
    ) {
      state.repetitiveValueHandler = {
        run: true,
        fieldKey: action.payload.fieldKey,
        fieldValue: action.payload.fieldValue,
        shouldValidate: true,
      };
    },
    resetRepetitiveHandler(state, action: PayloadAction) {
      state.repetitiveValueHandler = undefined;
    },
  },
});

export const { actions: repetitiveActions } = slice;

export const useRepetitiveSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: repetitiveSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useRepetitiveSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
