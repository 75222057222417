import * as React from 'react';
import { useDebugEffect } from './useDebugEffect';

/**
 * Similar to React.useEffect but skips first callback and fires only for subsequent dependencies changes
 * @param callback useEffectCallback
 * @param deps dependencies
 */
export const useDidUpdate = (
  callback: React.EffectCallback,
  deps?: React.DependencyList,
) => {
  const hasMount = React.useRef(false);

  React.useEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
    // no need to include the callback in dependencies since it's mutation does not really matter as long as the correct dependencies are used
    // deps are passed as is (unchanged) for the React.useEffect to track their changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
export const useDidUpdateDebug = (
  callback: React.EffectCallback,
  deps?: React.DependencyList,
) => {
  const hasMount = React.useRef(false);

  useDebugEffect(
    () => {
      if (hasMount.current) {
        callback();
      } else {
        hasMount.current = true;
      }
    },
    [callback, deps],
    Object.entries(deps ?? {}).map(f => f[0]),
  );
};
