/**
 *
 * auto generated interface for Default.TimelineGroupBy
 *
 */

export enum TimelineGroupBy {
  NoTimelineGrouped = 0,
  GroupByAssemblyPart = 1,
  GroupByAssetCat = 2,
}
export type TimelineGroupByUnion = keyof typeof TimelineGroupBy;
export const TimelineGroupByUnionArray: Array<TimelineGroupByUnion> = [
  'NoTimelineGrouped',
  'GroupByAssemblyPart',
  'GroupByAssetCat',
];
