import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { Entity } from 'types/common';

const url = '/api/odata/v4/UnitTypesFilter';

export interface UnitTypePickerProps
  extends Omit<AutocompletePickerProps<UnitType>, 'loadData'> {
  type?: number;
  $select?: Array<keyof UnitType>;
}

export interface UnitType extends Entity<number> {
  SourceUnits: Conversion[];
  TargetUnits: Conversion[];
  CategoryType: number | null;
}

interface Conversion {
  ConversionRate: number;
  TargetId: number;
}

export const initUnitTypeData = (initval: string | undefined) => {
  if (initval === undefined) {
    return Promise.resolve([]);
  }
  const id = parseInt(initval);
  const params: {
    $orderby: string;
    $filter?: string;
    $top: number;
    $skip: number;
  } = {
    $orderby: 'Name asc',
    $filter: '(Id eq ' + id + ')',
    $skip: 0,
    $top: 1,
  };
  return httpClient
    .get(url, params)
    .then(response => response.value as UnitType[]);
};

const getLoadData = (
  type?: number,
  $select?: Array<keyof UnitType> | undefined,
) => (searchTerm: string | null) => {
  const params: {
    $orderby: string;
    $filter?: string;
    $select?: string | undefined;
  } = {
    $orderby: 'Name asc',
    $select: $select?.join(','),
  };

  const predicates: Condition<UnitType>[] = [];
  if (type !== undefined) {
    new Condition('CategoryType', ODataOperators.Equals, type);
  }
  params.$filter = new ODataFilterBuilder<UnitType>({
    predicates: predicates,
    stringColumns: ['Name'],
    stringSearch: searchTerm,
  }).toString();

  return httpClient
    .get(url, params)
    .then(response => response.value as UnitType[]);
};
export const UnitTypePicker = ({
  type,
  $select,
  ...props
}: UnitTypePickerProps) => {
  const loadData = React.useMemo(() => getLoadData(type, $select), [
    $select,
    type,
  ]);
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={loadData}
      id={props.id}
      {...props}
    />
  );
};
