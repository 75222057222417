import * as React from 'react';
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import BaseTextField from '../BasicInputs/BaseTextField';
import { BaseFieldProps } from '../BasicInputs/BaseTextField/BaseFieldDefinition';

export const FormNumberField = ({
  label,
  onChange,
  onBlur,
  value,
  name,
  helperText,
  noWarnOnNuN,
  ...props
}: FieldHookConfig<number> &
  BaseFieldProps &
  IFieldProps & { noWarnOnNuN?: boolean }) => {
  const [field, meta] = useField<number>({ name, ...props });
  // const handleChange = e => {
  //   var number = parseFloat(e.target.value);
  //   if (isNaN(number)) {
  //     if (noWarnOnNuN) {
  //       onChange?.(e);
  //       field.onChange(e);
  //     } else {
  //       console.warn(
  //         `NaN value ("${e.target.value}") encountered in FormNumberField`,
  //       );
  //     }
  //   } else {
  //     onChange?.(e);
  //     field.onChange(e);
  //   }
  // };
  const handleChange = e => {
    if (onChange !== undefined) {
      onChange(e);
    }
    field.onChange(e);
  };
  return (
    <BaseTextField
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <React.Fragment>
            {props.info ? <InfoIcon title={props.info} /> : null}
            {props.InputProps?.endAdornment}
          </React.Fragment>
        ),
      }}
      variant="filled"
      fullWidth
      autoFocus={props.autoFocus}
      inputProps={{
        ...props.inputProps,
        type: 'number',
        min: 0,
        'aria-label': `${label ? undefined : 'number input'}`,
      }}
      value={field.value || ''}
      onChange={handleChange}
      onBlur={field?.onBlur}
      id={props.id || 'numberfieldId'}
      label={label}
      name={name}
      error={meta?.error !== undefined}
      helperText={meta.error ?? helperText}
      {...props}
    />
  );
};
