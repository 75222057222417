/**
 *
 * ArticlLink
 *
 */
import { useTranslation } from 'react-i18next';
import { ExternalLink } from 'app/components/ExternalLink';
import { ValueLabel } from 'app/components/ValueLabel';
import { ArticleSource } from '../ArticlesSourcePicker';

export interface ArticleLinkProps {
  doi: string | undefined | null;
  externalId?: string | null;
  externalIdSource?: string | null;
}

export function ArticleDoiLink({
  doi,
  externalIdSource,
  externalId,
}: ArticleLinkProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const doiId = tryGetDoiFromUrl(doi) ?? doi;
  const doiURL = `https://doi.org/${doiId}`;
  const externalIdSourceType = externalIdSource as ArticleSource;
  const linkURL =
    // use pubmed link for pubmed articles
    externalIdSourceType === 'PubMed'
      ? `https://pubmed.ncbi.nlm.nih.gov/${externalId}/`
      : // use dtu findit link for dtu orbit sourced articles
      externalIdSourceType === 'DTUOrbit'
      ? `https://findit.dtu.dk/en/catalog?q=doi:${doiId}`
      : // by default shows the doi url
        doiURL;
  if (doi === undefined) {
    return <ValueLabel value={doi} />;
  } else {
    return (
      <>
        <ExternalLink showIcon href={linkURL}>
          {doi}
        </ExternalLink>
      </>
    );
  }
}

const tryGetDoiFromUrl = (s: string | null | undefined) => {
  try {
    if (s === null) {
      return undefined;
    }
    if (s === undefined) {
      return undefined;
    }
    const url = new URL(s);
    const result = url.pathname.substr(1);
    return result;
  } catch (error) {
    return undefined;
  }
};
