/**
 *
 * TmtType
 *
 */
import { MenuItem } from '@material-ui/core';
import * as React from 'react';
import { RUN_TYPES } from '../RunTypePicker';
import { StyledSelect } from '../../EditableTable/Fields/StyledSelect';
import { BaseInputProps } from '../../EditableTable/EditableCell';

export interface TmtTypeProps extends BaseInputProps<RUN_TYPES> {}
export function TmtType({ value, onChange, onBlur }: TmtTypeProps) {
  const options = ['TMT Pro'];
  return (
    <StyledSelect
      disableUnderline={true}
      value={value || ''}
      onChange={e => onChange(e.target.value as RUN_TYPES)}
      onBlur={onBlur}
      displayEmpty
      autoWidth
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {options.map((item, index) => (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}
