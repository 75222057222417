import { IconName as fontawesome_IconName } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { useTheme } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import * as React from 'react';
import { getColor, TypeColor, TypeColorOptions } from 'styles/colors/types';
import { IconName, IconProp } from 'app/AllIcons';

export interface IconProps
  extends Omit<FontAwesomeIconProps, 'color' | 'icon'> {
  color?: TypeColor;
  colorExtend?: TypeColorOptions;
  icon: IconProp;
  info?: string;
}

export const Icon = React.forwardRef(function Icon(
  { icon, color, colorExtend, info, ...props }: IconProps,
  ref,
) {
  const iconName = icon as IconName;
  //typeof icon === 'string' ? () : (icon as IconProp);
  const theme = useTheme();
  const defColor = color && getColor(theme, color, colorExtend); //|| theme.palette.common.graySubtitle

  const WithTitle = ({ children }: { children: React.ReactNode }) =>
    info ? (
      <Tooltip title={info}>
        <span>{children}</span>
      </Tooltip>
    ) : (
      <>{children}</>
    );
  return (
    <WithTitle>
      <FontAwesomeIcon
        icon={iconName as fontawesome_IconName}
        color={defColor}
        {...props}
      />
    </WithTitle>
  );
});
// variant with ref - using SvgIcon component

// export const Icon = React.forwardRef<any, IconProps>((props, ref) => {
//   const { icon, color, ...rest } = props;
//   const iconName = icon as IconName;
//   const theme = useTheme();
//   const defColor = color && getColor(theme, color);
//   return (
//     <SvgIcon ref={ref}>
//       <FontAwesomeIcon icon={iconName} color={defColor} {...rest} />
//     </SvgIcon>
//   );
// });
