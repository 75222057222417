import { Link } from 'app/components/BasicLinks/Link';
import { useTranslation } from 'react-i18next';
import { getReservationsLink } from '../../ReservationForm/getReservationsLink';
import { EditTrainingSessionLink } from 'app/pages/TrainingSessionsPage/components/EditTrainingSessionsLink';
import { translations } from 'locales/translations';
import { ITrainingSessionDto } from 'api/odata/generated/entities/ITrainingSessionDto';

export interface TrainingSignUpAdminAlertContentProps {
  isAdmin: boolean;
  trainingSignUp?: boolean;
  trainingSession?: ITrainingSessionDto;
}
export function TrainingSignUpAdminAlertContent({
  trainingSession,
  ...props
}: TrainingSignUpAdminAlertContentProps) {
  const { t } = useTranslation();

  if (trainingSession === null) {
    return null;
  }
  if (trainingSession === undefined) {
    return null;
  }
  var show =
    props.isAdmin &&
    props.trainingSignUp &&
    trainingSession !== undefined &&
    trainingSession !== null;
  if (!show) {
    return null;
  }
  const totalUsedSlotsCount = trainingSession.TotalUsedSlots ?? 0;
  return (
    <>
      {t(translations.TrainingSessionsCountMessage, {
        count: totalUsedSlotsCount,
      })}

      {totalUsedSlotsCount > 0 && (
        <>
          <span>&emsp;</span>
          <Link
            to={getReservationsLink({
              TrainingSessionId: trainingSession.Id,
            })}
          >
            {t(translations.ManageSignUps)}
          </Link>
        </>
      )}
      <>
        <span>&emsp;</span>
        <EditTrainingSessionLink trainingSessionId={trainingSession.Id}>
          {t(translations.EditTrainingSession)}
        </EditTrainingSessionLink>
      </>
    </>
  );
}
