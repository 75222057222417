/**
 *
 * MultiToggleProps
 *
 */
import { ValueChangeHandler } from 'types/ValueChangeHandler';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';

export interface MultiToggleProps {
  value?: boolean;
  onchange: ValueChangeHandler<boolean>;
}

export function MultiToggle(props: MultiToggleProps) {
  const { t } = useTranslation();

  const handleChange = (value: boolean) => {
    props?.onchange(value);
  };
  return (
    <>
      <IconButton
        aria-label="Swithch Multiple Mode"
        variant={props.value === true ? 'white' : 'gray'}
        shape="square"
        size={'small'}
        title={t(translations.ToggleMultipleInstruments_info)}
        onClick={event => handleChange(!props.value)}
      >
        <Icon
          size="lg"
          icon={[props.value === true ? 'fas' : 'fal', 'calendars']}
        />
      </IconButton>
    </>
  );
}
