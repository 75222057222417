import * as DHTMLX from 'dhtmlx-scheduler';
import { timelineColumnWidth } from './utils';
import { scheduler_services_collection_name } from './serverListUtils';
import { scheduleServiceTemplate } from './renderServiceLabel';
import { ISchedulerServiceSection } from './types/ISchedulerSection';
import { timelineEventsSortIteratee } from './utils/timelineEventsSortIteratee';

interface ITimelineColumn {
  name?: string;
  css?: string;
  label?: string;
  width: number;
  template: (params: ISchedulerServiceSection) => string;
}

export function createTimelineView(
  scheduler: DHTMLX.SchedulerStatic,
  isMobile: boolean,
  customDays: number,
  useAmPm?: boolean,
) {
  const columns: ITimelineColumn[] = [
    {
      label: '',
      width: timelineColumnWidth(isMobile),
      template: scheduleServiceTemplate,
    },
  ];

  const s = {
    y_unit: scheduler.serverList(scheduler_services_collection_name),
    y_property: 'service_id',
    column_width: 70,
    scrollable: true,
    render: 'tree',
    event_min_dy: isMobile ? 20 : 38,
    columns: columns,
    sort: timelineEventsSortIteratee,
  };

  scheduler.createTimelineView({
    name: 'day_timeline',
    x_unit: 'minute',
    x_date: ' ',
    x_start: 0,
    x_step: 15,
    x_size: 24 * 4,
    x_length: 24 * 4,
    second_scale: {
      x_unit: 'hour',
      x_date: useAmPm ? '%h %A' : '%H:%i',
    },
    ...{
      ...s,
      column_width: 20,
    },
  });
  scheduler.createTimelineView({
    name: 'twodays_timeline',
    x_unit: 'hour',
    x_date: useAmPm ? '%h %A' : '%H:%i', //'%H:%i',
    x_start: 0,
    x_step: 24 / 6,
    x_size: (24 / 6) * 2,
    x_length: (24 / 6) * 2,
    second_scale: {
      x_unit: 'day',
      x_date: '%D, %j %M', // date format which should be used for second scale, "July 01"
    },
    ...s,
  });
  scheduler.createTimelineView({
    name: 'customdays_timeline',
    x_unit: customDays < 28 ? 'hour' : 'day',
    x_date:
      customDays < 7
        ? useAmPm
          ? '%h %A'
          : '%H:%i'
        : customDays < 28
        ? ' '
        : '%d',
    x_start: 0,
    x_step: customDays < 7 ? 24 / 6 : customDays < 28 ? 24 / 4 : 1,
    x_size:
      customDays < 7 ? (24 / 6) * customDays : customDays < 28 ? 4 * 7 : 31,
    x_length:
      customDays < 7 ? (24 / 6) * customDays : customDays < 28 ? 4 * 7 : 31,
    second_scale: {
      x_unit: customDays < 7 ? 'day' : customDays < 28 ? 'day' : 'week',
      x_date:
        customDays < 7
          ? '%D, %j %M'
          : customDays < 28
          ? '%D, %j %M'
          : '%M, W %W', // date format which should be used for second scale, "July 01"
    },
    ...s,
  });
  scheduler.createTimelineView({
    name: 'week_timeline',
    x_unit: 'hour',
    x_date: ' ',
    x_step: 24 / 4,
    x_size: 4 * 7,
    x_start: 0,
    x_length: 4 * 7,
    second_scale: {
      x_unit: 'day',
      x_date: '%D, %j %M', // date format which should be used for second scale, "July 01"
    },
    ...{
      ...s,
      column_width: 36,
    },
  });

  // https://docs.dhtmlx.com/scheduler/custom_views.html
  // sets the week_timeline period to match the week date falls into: week start -> week end according to scheduler's settings
  scheduler.date.week_timeline_start = date => scheduler.date.week_start(date);
  scheduler.date.get_week_timeline_end = start_date =>
    scheduler.date.add(start_date, 7, 'day');

  scheduler.createTimelineView({
    name: 'month_timeline',
    x_unit: 'day',
    x_date: '%j',
    x_step: 1,
    x_size: 31,
    x_start: 0,
    x_length: 31,

    second_scale: {
      x_unit: 'week',
      x_date: '%M, W %W', // date format which should be used for second scale, "July 01"
    },
    // sections of the view (titles of Y-Axis)
    ...{
      ...s,
      column_width: 36,
    },
  });
  scheduler.date['timeline_start'] = scheduler.date.month_start;
  scheduler.createTimelineView({
    name: 'quarter_timeline',
    x_unit: 'week',
    x_date: '%M, W %W',
    x_step: 1,
    x_size: 15,
    x_start: 0,
    x_length: 14,
    second_scale: {
      x_unit: 'month',
      x_date: '%F', // date format which should be used for second scale, "July 01"
    },
    // sections of the view (titles of Y-Axis)
    ...{
      ...s,
      column_width: 70,
    },
  });
  scheduler.createTimelineView({
    name: 'year_timeline',
    x_unit: 'month',
    x_date: '%M',
    x_step: 1,
    x_size: 12,
    x_start: 0,
    x_length: 12,
    second_scale: {
      x_unit: 'year',
      x_date: '%Y',
    },
    // sections of the view (titles of Y-Axis)
    ...{
      ...s,
      column_width: 70,
    },
  });
  return;
}
