import { FieldHookConfig, useField } from 'formik';
import * as React from 'react';
import { Entity } from 'types/common';
import { AutocompleteMultiPicker } from '../BasicPickers/AutocompleteMultiPicker';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export interface FormAutocompleteMultiProps<T> {
  loadData: (searchTerm: string | null) => Promise<Entity<T>[]>;
  initValue?: Entity<T>[];
  size?: 'medium' | 'small';
  mini?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
}

export function FormAutocompleteMulti<T>({
  label,
  loadData,
  id,
  initValue,
  variant,
  ...props
}: FormAutocompleteMultiProps<T> &
  FieldHookConfig<Entity<T>[] | null> &
  IFieldProps) {
  const [field, meta, helpers] = useField<Entity<T>[] | null>(props);
  const handleChange = (e: Entity<T>[] | null) => {
    helpers.setValue(e, true);
    if (props.onChange !== undefined) {
      props.onChange(e);
    }
    //field.onChange(e);
  };
  return (
    <AutocompleteMultiPicker
      loadData={loadData}
      defaultValue={initValue}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      label={label}
      placeholder={props.placeholder}
      required={props.required}
      name={props.name}
      onChange={handleChange}
      onBlur={field?.onBlur}
      value={field.value ?? undefined}
      error={meta?.error !== undefined}
      helperText={meta.error || props.helperText}
      variant={variant}
      id={id}
      info={props.info}
      size={props.size}
    ></AutocompleteMultiPicker>
  );
}
