import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { toQueryString } from 'utils/url-utils';

export const getDefaultFilter = (onlyMyCores: boolean) => {
  const result = (user: IAuthenticatedUser) =>
    toQueryString({
      ownedbyme: onlyMyCores === true ? true : undefined,
    });
  return result;
};
