import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { isEmptyOrWhitespace } from 'utils/typeUtils';

export function DefaultFilter(user: IAuthenticatedUser) {
  const result = new URLSearchParams();

  result.set('active', true.toString());

  return result;
}
export function getRequiredFilter(search: string) {
  const result = isEmptyOrWhitespace(search)
    ? new URLSearchParams()
    : new URLSearchParams(search);
  if (
    result.has('active') === false ||
    result.get('active') === null ||
    result.get('active') === ''
  ) {
    result.set('active', true.toString());
  }
  const res = result.toString();
  return isEmptyOrWhitespace(res) ? undefined : res;
}
