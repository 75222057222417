import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { Icon } from 'app/components/BasicIcons/FontAwesome';

export interface BulletedListItem {
  key: React.Key | null | undefined;
  node: React.ReactNode;
}
export interface BulletedListProps {
  items: BulletedListItem[];
  showBullet?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&': {
        listStyle: 'none',
      },
      '& li:before': {
        content: "'•'",
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        display: 'inline-block',
        width: '1em',
        marginLeft: '-1em',
      },
    },
  }),
);

const bulletedStyles = makeStyles((theme: Theme) =>
  createStyles({
    rows: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0,
      margin: 0,
      gap: '8px',
      flexGrow: 1,
      overflowY: 'auto',
      overflowX: 'clip',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        boxShadow: `inset 0 0 2px ${theme.palette.common.grayBorders}`,
        borderRadius: '6px',
        backgroundColor: theme.palette.common.grayBorders,
      },
      height: 'auto', // isMobile ? '90px' : `${cardcurrentHeight - (84 + 64)}px`,
      maxHeight: 200,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 0,
      gap: 8,
      height: 25,
      // width: '100%',
    },
    rowOverRule: {
      display: 'flex',
      width: '100%',
      whiteSpace: 'pre',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '23ch',
    },
  }),
);
export const StyledList = ({
  children,
  className,
  style,
}: React.PropsWithChildren<{
  className?: string;
  style?: React.CSSProperties;
}>) => {
  const classes = useStyles();
  return (
    <ul
      className={className ? clsx(classes.root, className) : classes.root}
      style={style}
    >
      {children}
    </ul>
  );
};
export const SimpleList = ({
  children,
  className,
  style,
}: React.PropsWithChildren<{
  className?: string;
  style?: React.CSSProperties;
}>) => {
  return (
    <ul className={className} style={{ ...style, listStyle: 'none' }}>
      {children}
    </ul>
  );
};
export const BulletedList = (props: BulletedListProps) => {
  const classes = bulletedStyles();
  const { items, showBullet = true } = props;
  return (
    <SimpleList className={classes.rows}>
      {items.map(c => {
        return (
          <li key={`list-key-${c.key}`} className={classes.row}>
            {showBullet && (
              <Icon icon="circle" fontSize="6px" color="primary" />
            )}
            <Box component="span" className={classes.rowOverRule}>
              {c.node}
            </Box>
          </li>
        );
      })}
    </SimpleList>
  );
};
