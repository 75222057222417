/**
 *
 * YearsPicker
 *
 */
import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import React from 'react';

import { Entity } from 'types/common';
import { dateUtils } from 'utils/date-utils';
import { GetOptionsFilterFunction } from 'utils/enumKeys';

export interface YearsPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {
  date?: Date | string | null;
}
export const MIN_YEAR: number = 2001;
export const MAX_YEAR: number = dateUtils.getYear(
  dateUtils.addYears(new Date(), 10),
);
export const yearsEntityArray = Array.from(
  { length: MAX_YEAR - MIN_YEAR },
  (_, i) => {
    const x = MIN_YEAR + i;
    return {
      Id: x,
      Name: x.toString(),
    } as Entity<number>;
  },
);

export const initYearsData = (
  initval: number | string | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve(
        yearsEntityArray.filter(item => {
          return item.Id.toString() === initval.toString();
        }),
      );
    });
  }
};
export const defaultYearValue =
  yearsEntityArray.find(f => f.Id === dateUtils.getYear(new Date())) ??
  ({
    Id: dateUtils.getYear(new Date()),
    Name: dateUtils.getYear(new Date()).toString(),
  } as Entity<number>);
export const getYearEntityFromDate = (date?: Date | string | null) => {
  if (date === null || date === undefined) {
    return defaultYearValue;
  } else {
    let toDate = dateUtils.dateOrStringToDate(date);
    return (
      yearsEntityArray.find(f => f.Id === dateUtils.getYear(toDate)) ??
      defaultYearValue
    );
  }
};
export function YearsPicker(props: YearsPickerProps) {
  const getOptions = React.useMemo(() => {
    let items = yearsEntityArray;
    return GetOptionsFilterFunction(items);
  }, []);
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      id={props.id || 'yearsId'}
      value={props.value}
      autoHighlight={true}
      autoSelect={true}
      selectOnFocus={true}
      clearOnEscape
      {...props}
    />
  );
}
