import * as React from 'react';
import { useSelector } from 'react-redux';
import { SIDE_PANEL_WIDTH } from 'styles/constantVariables';
import {
  selectExpandedSidePanel,
  selectPageType,
  selectPageTypeProps,
  selectSidePanelOpen,
} from '../../slice/selectors';
import { PageTypeProps, RenderPageType } from '../../slice/type';
import { SidePanelPopup } from './SidePanelPopup';

export interface SidePanelProps {
  renderPageType?: RenderPageType;
  renderPageProps?: PageTypeProps;
}

export function SidePanel(props: SidePanelProps) {
  const sidePanelOpen = useSelector(selectSidePanelOpen);

  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const renderPageType = useSelector(selectPageType);
  const renderPageProps = useSelector(selectPageTypeProps);

  return (
    <SidePanelPopup
      renderPageProps={renderPageProps}
      renderPageType={renderPageType}
      sidePanelOpen={sidePanelOpen}
      sidePanelExpanded={sidePanelExpanded}
      sidePanelWidth={SIDE_PANEL_WIDTH}
    />
  );
}
