import { makeStyles } from '@material-ui/core/styles';

export const useStyles = props =>
  makeStyles(theme => ({
    root: {
      display: 'flex',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
      width: '100%',
      height: '100vh',
    },
  }));
