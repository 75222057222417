/**
 *
 * SymptomCodesPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IRepairCodeDto } from 'api/odata/generated/entities/IRepairCodeDto';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';

import * as React from 'react';

import { Entity } from 'types/common';
export interface IRepairCodesParamsPicker {
  AssetCatIds?: number[];
}
export interface RepairCodesPickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'>,
    IRepairCodesParamsPicker {}

const url = '/api/odata/v4/RepairCodes';
export const initRepairCodesData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: `Id,Name,Active,AssetCatId,AssetCatName`,
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};

export function withParamsFilter(AssetCatIds: number[] | undefined) {
  return (searchTerm: string | null) => {
    const params: { $orderby: string; $filter?: string; $select?: string } = {
      $orderby: 'Name asc',
      $select: 'Id,Name,Active,AssetCatId,AssetCatName',
    };
    const predicates: (string | Condition<IRepairCodeDto>)[] = [];
    if (AssetCatIds !== undefined && AssetCatIds.length > 0) {
      predicates.push(
        AssetCatIds.map(ac =>
          new Condition('AssetCatId', ODataOperators.Equals, ac).toString(),
        ).join(' or '),
      );
    }
    params.$filter = new ODataFilterBuilder<IRepairCodeDto>({
      predicates: predicates,
      stringColumns: ['Name'],
      stringSearch: searchTerm === null ? undefined : searchTerm,
      globalServiceGroupFilter: [],
    }).toString();
    return httpClient
      .get(url, params)
      .then(
        response => (response.value as IRepairCodeDto[]) as Entity<number>[],
      );
  };
}
export function RepairCodesPicker(props: RepairCodesPickerProps) {
  const loadData = withParamsFilter(props.AssetCatIds);
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={loadData}
      id={props.id || 'repaircodesId'}
      {...props}
    />
  );
}
