/**
 *
 * TagsPicker
 *
 */
import { InputBaseComponentProps } from '@material-ui/core';
import { httpClient } from 'api/HttpClient';
import { ITagsFilterDto } from 'api/odata/generated/entities/ITagsFilterDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompleteMultiPickerEx,
  AutocompleteMultiPickerExProps,
} from 'app/components/BasicPickers/AutocompleteMultiPickerEx';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import {
  selectGlobalServiceGroupFilter,
  selectIsAuthenticated,
} from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity, Identifiable } from 'types/common';
import { isNumericPositive } from 'utils/string-utils';

export type TagsUrl = 'base' | 'assetBase' | 'public';
export interface TagsPickerProps
  extends Omit<AutocompleteMultiPickerExProps<ITagsFilterDto>, 'loadData'> {
  inputProps?: InputBaseComponentProps;
  urlType?: TagsUrl;
  separator?: string;
}

const url = '/api/odata/v4/TagsFilter';
const assetTagsUrl = '/api/odata/v4/TagsFilter/AssetTagsFilter';
const publicAssetTagsUrl = '/api/odata/v4/TagsFilter/PublicAssetTagsFilter';
export const initTagsData = (
  initval: string | undefined,
  sourceUrl?: TagsUrl,
  separator?: string,
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    let ids =
      initval.indexOf(separator || '|') !== -1
        ? initval.split(separator || '|')
        : [initval];
    let intIds: Identifiable<number>[] = [];

    if (
      ids.filter(f => {
        let test = isNumericPositive(f);
        return test === true;
      }).length === ids.length
    ) {
      intIds = ids.map(item => {
        return { Id: parseInt(item) };
      }) as Identifiable<number>[];
    }
    let strIds =
      intIds.length > 0
        ? []
        : (ids.map(item => {
            return { Id: item };
          }) as Identifiable<string>[]);
    let filters: string | undefined = undefined;
    if (intIds.length > 0) {
      const predicates: (string | Condition<ITagsFilterDto>)[] = [];
      predicates.push(new Condition('Id', ODataOperators.Includes, intIds));
      filters = predicates.map(f => f.toString()).join(' and ');
    } else if (strIds.length > 0) {
      const predicates: (string | Condition<ITagsFilterDto>)[] = [];
      predicates.push(new Condition('Name', ODataOperators.ContainsM, strIds));
      filters = predicates.map(f => f.toString()).join(' and ');
    }
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
    } = {
      $orderby: 'Name asc',
      $filter: filters,
      $select: 'Id,Name,ServiceGroups',
    };

    let initUrl =
      sourceUrl === 'public'
        ? publicAssetTagsUrl
        : sourceUrl === 'assetBase'
        ? assetTagsUrl
        : url;

    return httpClient
      .get(initUrl, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (
  predicates: (string | Condition<ITagsFilterDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  isAuthenticated?: boolean,
  urlType?: TagsUrl,
) => {
  const sourceUrl =
    urlType === 'public' || !isAuthenticated
      ? publicAssetTagsUrl
      : urlType === 'assetBase'
      ? assetTagsUrl
      : url;

  return getAutoCompleteLoadDataFn<ITagsFilterDto>({
    url: sourceUrl,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: ['Id', 'Name', 'ServiceGroups'],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<ITagsFilterDto>(
              'ServiceGroups',
              ODataOperators.AnyIn,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });
};

export function TagsPicker(props: TagsPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const isAuth = useSelector(selectIsAuthenticated);
  const innerLoadData = loadData(
    props.predicates,
    isAuth ? globalServiceGroupFilter || [] : [],
    isAuth,
    props.urlType,
  );
  return (
    <AutocompleteMultiPickerEx
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={innerLoadData}
      id={props.id || 'tagspickerId'}
      freeSolo={props.freeSolo}
      ariaLabel="Tags search"
      {...props}
    />
  );
}
