import { EntityNumberSchema } from 'app/components/Forms/Schemas';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import * as Yup from 'yup';
import { SavedViewDetailsState } from '../slice/types';

export const getsavedViewSchema: (
  t: TFunction,
) => Yup.SchemaOf<SavedViewDetailsState> = t =>
  Yup.object({
    Id: Yup.number().notRequired().default(-1),
    Name: Yup.string()
      .required(t(translations.err_SavedViewNameRequired))
      .typeError(t(translations.err_SavedViewNameRequired))
      .label(t(translations.SavedViewName)),
    Assets: Yup.array()
      .of(EntityNumberSchema)
      .min(1)
      .required(t(translations.err_savedViewAssetRequired))
      .label(t(translations.SavedViewAssets))
      .typeError(t(translations.err_savedViewAssetRequired)),
    Shared: Yup.boolean().label(t(translations.SavedViewShared)),
    Multiple: Yup.boolean().label(t(translations.SavedViewBookMultiple)),
    // SharedTo: Yup.mixed().label(i18next.t(translations.SavedViewSharedTo)),
    // SharedCores: Yup.array()
    //   .of(EntityNumberSchema)
    //   .label(i18next.t(translations.SavedViewSharedCores)),
    // SharedUsers: Yup.array()
    //   .of(EntityStringSchema)
    //   .label(i18next.t(translations.SavedViewSharedUsers)),
  });
