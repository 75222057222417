import parse from 'html-react-parser';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { OptionsObject, SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMessagesSlice } from './slice';
import { selectMessages } from './slice/selector';
import {
  CalendarMessageProps,
  LoanDesk,
  Message,
  messageType,
  StartedReservation,
  UsageReservation,
} from './slice/type';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Button } from '../BasicButtons/Button';
import { AssetLink } from 'app/pages/AssetPopUp';
import { removeHtml } from 'utils/string-utils';
import { Tooltip } from '../BasicTooltips/Tooltip';
import {
  createStyles,
  Dialog,
  Link,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { AllowedSettings } from 'utils/globalSettings';
import { Box } from '../basic/Wrappers/Box';
import ButtonLink from '../BasicLinks/ButtonLink';
import { useOpenReservationSidePanel } from 'app/pages/ReservationDetails/Details/useOpenReservationSidePanel';
import { H3 } from '../Typography';
import { Entity } from 'types/common';

const useMessageStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.modal,
    },
    item: {
      backgroundColor: theme.palette.background.default + ' !important',
      color: theme.palette.text.primary + ' !important',
      border: '1px solid ' + theme.palette.info.main,
      minWidth: 'auto',
    },
    base: {
      '& > div': {
        zIndex: theme.zIndex.modal - 1,
      },
      //this accesses the div that can effect the justify content.
      //if notistack is updated, it will likely not be needed as the bug has been fixed in newer versions.
      '&>div>div>div': {
        justifyContent: 'flex-end',
      },
    },
  }),
);
const MaxMessage = 3;
const closeAllKey = 'closeAllMessagesKey';

export interface MessageProps {
  pageName: string;
  selectedEquipment?: Entity<number>[];
}

export const Messages = (props: MessageProps) => {
  const classes = useMessageStyles();
  return (
    <span className={classes.base}>
      <SnackbarProvider
        hideIconVariant
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        preventDuplicate
        variant="info"
        maxSnack={MaxMessage + 1}
        classes={{
          root: classes.root,
          variantInfo: classes.item,
        }}
      >
        <MessagesChildren {...props} />
      </SnackbarProvider>
    </span>
  );
};

const MessagesChildren = (props: MessageProps) => {
  const { pageName, selectedEquipment } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { actions } = useMessagesSlice();
  const messages = useSelector(selectMessages);
  const classes = useStyles();
  useEffectOnMount(() => {
    dispatch(
      actions.init({
        pageName: pageName,
        selectedEquipment: selectedEquipment,
      }),
    );

    return () => {
      dispatch(actions.clear());
    };
  });
  useEffect(() => {
    dispatch(actions.clear());
    closeSnackbar();
    dispatch(
      actions.init({
        pageName: pageName,
        selectedEquipment: selectedEquipment,
      }),
    );
  }, [actions, closeSnackbar, dispatch, pageName, selectedEquipment]);
  useEffect(() => {
    if (messages.length > 1) {
      const closeAllOptions: OptionsObject = {
        persist: true,
        key: closeAllKey,
      };

      enqueueSnackbar(
        <ButtonLink
          onClick={() => {
            closeSnackbar();
            dispatch(actions.clear());
          }}
        >
          <Icon icon="close" />
          <WithSpace>
            <CloseAll />
          </WithSpace>
        </ButtonLink>,
        closeAllOptions,
      );
    } else {
      closeSnackbar(closeAllKey);
    }
    messages.slice(0, MaxMessage).forEach(m => {
      const close = () => {
        dispatch(actions.remove(m.key));
        closeSnackbar(m.key);
      };
      const options: OptionsObject = {
        persist: true,
        key: m.key,
        action: key => (
          <Box
            width={'100%'}
            display="flex"
            flexDirection="column-reverse"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* {m.type === messageType.removableAnnouncment && (
              <GotIt
                onClick={() => {
                  dispatch(actions.remove(key));
                  closeSnackbar(key);
                  dispatch(
                    actions.gotIt(
                      AllowedSettings[AllowedSettings.DashboardAnnouncement],
                    ),
                  );
                }}
              />
            )} */}
            <Icon icon="close" onClick={close} />
          </Box>
        ),
      };

      enqueueSnackbar(
        <>
          <span className={classes.root}>
            <RenderMessge message={m} close={close} />
          </span>
          <>
            {m.type === messageType.removableAnnouncment && (
              <GotIt
                onClick={() => {
                  dispatch(actions.remove(m.key));
                  closeSnackbar(m.key);
                  dispatch(
                    actions.gotIt(
                      AllowedSettings[AllowedSettings.DashboardAnnouncement],
                    ),
                  );
                }}
              />
            )}
          </>
        </>,
        options,
      );
    });
  }, [actions, classes, closeSnackbar, dispatch, enqueueSnackbar, messages]);
  return null;
};

const CloseAll = () => {
  const { t } = useTranslation();
  return <>{t(translations.CloseAll)}</>;
};

const GotIt = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <Button size="small" onClick={onClick}>
      {t(translations.GotIt)}
    </Button>
  );
};

const RenderMessge = ({
  message,
  close,
}: withClose<{
  message: Message;
}>) => {
  switch (message.type) {
    case messageType.Announcment:
    case messageType.removableAnnouncment:
      return <Truncicated value={message.props as string} />;
    case messageType.reservationStarted:
      const props = message.props as StartedReservation;
      return <ReservationStartedMessage close={close} {...props} />;
    case messageType.usageReservation:
      return (
        <UsageReservationMessage
          close={close}
          {...(message.props as UsageReservation)}
        />
      );
    case messageType.loadDesk:
      return <LoanDeskMessage {...(message.props as LoanDesk)} />;
    case messageType.calendar:
      return <CalendarMessage {...(message.props as CalendarMessageProps)} />;
    default:
      return null;
  }
};

type withClose<T> = T & { close: () => void };

const ReservationStartedMessage = ({
  close,
  ...props
}: withClose<StartedReservation>) => {
  const { t } = useTranslation();
  const openSidePanel = useOpenReservationSidePanel();
  const assets = (
    <React.Fragment key="asset_space_link">
      <AssetLink id={props.Asset.Id}>{props.Asset.Name}</AssetLink>
    </React.Fragment>
  );
  if (props.AllowUsersToShortenReservations && props.AllUsagesEnded) {
    return (
      <>
        {assets} {t(translations.InstrumentStillReservered)}
        <Button
          onClick={() => {
            openSidePanel({ id: '' + props.Id });
            close();
          }}
        >
          {t(translations.ReleaseUnusedReservation)}
        </Button>
      </>
    );
  }
  let messages: React.ReactNode = '';
  if (props.AllowUsersToShortenReservations) {
    const message = t(translations.YouHaveOngoingReservations);
    messages = message
      .split('{0}')
      .map<React.ReactNode>((f, i) => (
        <React.Fragment>
          <span key={'ReservationStarted' + i}> {f} </span>
        </React.Fragment>
      ))
      .reduce((prev, curr) => [prev, assets, curr]);
  } else if (props.AllowExtendReservation || props.AllowModification) {
    const message = t(translations.ReservationStarted);
    const link = (
      <WithSpace key="res_space_link">
        <Link
          key="res_link"
          onClick={() => openSidePanel({ id: '' + props.Id })}
        >
          {props.Id}
        </Link>
      </WithSpace>
    );
    messages = message
      .split('{0}')
      .map<React.ReactNode>((m, idx) => (
        <span key={'assets_' + idx}>
          {' '}
          {m
            .split('{1}')
            .map<React.ReactNode>((f, i) => (
              <React.Fragment>
                <span key={'ReservationStarted' + i}> {f} </span>
              </React.Fragment>
            ))
            .reduce((prev, curr) => [prev, assets, curr])}
        </span>
      ))
      .reduce((prev, curr) => [prev, link, curr]);
  }
  const canEdit =
    props.AllowUsersToShortenReservations ||
    props.AllowModification ||
    props.AllowExtendReservation;
  return (
    <>
      {messages}
      {canEdit && (
        <Button
          onClick={() => {
            openSidePanel({ id: '' + props.Id });
            close();
          }}
        >
          {t(translations.UpdateOngoingReservations)}
        </Button>
      )}
    </>
  );
};

const UsageReservationMessage = ({
  close,
  ...props
}: withClose<UsageReservation>) => {
  const { t } = useTranslation();
  return (
    <>
      {t(translations.IpRelay_reservationTimeForInstrument).replace(
        '{0}',
        props.Name,
      )}
      <Button
        target="_blank"
        href={{ path: '/Usage.aspx', search: { id: props.Id } }}
        onClick={close}
      >
        {t(translations.IpRelay_LinkToUsage)}
      </Button>
    </>
  );
};

const CalendarMessage = (props: CalendarMessageProps) => {
  return (
    <Truncicated value={props.Body ?? ''}>
      {(props.Title ?? '') !== '' && <H3>{props.Title}</H3>}
      {props.Body && parse(props.Body)}
    </Truncicated>
  );
};

const LoanDeskMessage = (props: LoanDesk) => {
  const { t } = useTranslation();
  const openSidePanel = useOpenReservationSidePanel();
  const message = t(translations.ReservationStartedMessage_LoanDesk);
  const link = (
    <WithSpace>
      <Link onClick={() => openSidePanel({ id: '' + props.Id })}>
        {props.Id}
      </Link>
    </WithSpace>
  );
  const asset = (
    <WithSpace>
      <AssetLink id={props.AssetDetails.Id}>
        {props.AssetDetails.Name}
      </AssetLink>
    </WithSpace>
  );
  const messages = message
    .split('{0}')
    .map(m =>
      m
        .split('{1}')
        .map<React.ReactNode>((f, i) => (
          <React.Fragment key={'LoanDesk' + i}> {f} </React.Fragment>
        ))
        .reduce((prev, curr) => [prev, asset, curr]),
    )
    .reduce((prev, curr) => [prev, link, curr]);
  return <>{messages}</>;
};

const WithSpace = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <>
      &nbsp;
      {children}
      &nbsp;
    </>
  );
};

const Truncicated = ({
  value,
  children,
}: React.PropsWithChildren<{ value: string }>) => {
  const classes = useStyles();
  const cleanedValue = useMemo(() => removeHtml(value), [value]);
  const parsed = parse(value);
  if (cleanedValue.length > 255) {
    return (
      <Tooltip title={<>{parsed}</>}>
        <>
          <span className={classes.truncate}>{children ?? parsed}</span>
          <MoreLink>{children ?? parsed}</MoreLink>
        </>
      </Tooltip>
    );
  }
  return <span>{children ?? parsed}</span>;
};

const MoreLink = ({ children }: React.PropsWithChildren<{}>) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)} variant="text" size="small">
        More
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        {children}
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxWidth: '380px',
      maxHeight: '180px',
      zIndex: theme.zIndex.drawer,
    },
    truncate: {
      overflow: 'hidden',
      textOverflow: 'elipsis',
      height: '140px',
      display: '-webkit-box',
      WebkitLineClamp: 5,
      WebkitBoxOrient: 'vertical',
    },
  }),
);
