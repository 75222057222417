import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column, Row } from 'react-table';
import { AssetLink } from 'app/pages/AssetPopUp';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';
import { DescriptionCell } from 'app/components/BasicTable/components/DescriptionCell';

import ImageRenderer from 'app/components/BasicTable/CellRenderers/ImageRenderer';

export const getColumns = (
  t: TFunction,
  isRowSelectable: (row: Row<ICoresDirectoryRow>) => boolean,
) => {
  const cols: (Column<ICoresDirectoryRow> & { invisible?: boolean })[] = [
    {
      Header: t(translations.Name) as string,
      accessor: 'Name',
      Cell: ({ value, row }) =>
        isRowSelectable(row) ? (
          <AssetLink id={row.original.AssetId ?? undefined}>{value}</AssetLink>
        ) : (
          <>{value}</>
        ),
    },
    {
      Header: t(translations.Image) as string,
      accessor: 'ImageName',
      Cell: ImageRenderer,
    },
    {
      Header: t(translations.Description) as string,
      accessor: 'Description',
      Cell: DescriptionCell({ width: 'long' }),
    },
  ];
  return cols.filter(v => !v.invisible) as Column<ICoresDirectoryRow>[];
};
