import { Button } from 'app/components/BasicButtons/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useServiceRequestsKanbanSlice } from '../../slice';
import { Dto } from '../../slice/types';
import { translations } from 'locales/translations';

export interface ParentTicketDetailsButtonProps {
  item?: Dto;
}

export function ParentTicketDetailsButton({
  item,
}: ParentTicketDetailsButtonProps) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { actions } = useServiceRequestsKanbanSlice();
  const handleOpenParentTicketClick = () => {
    if (item !== undefined) {
      dispatch(actions.showEditParent(item));
    }
  };
  if (
    item === undefined ||
    item.ParentTicketId === null ||
    item.ParentTicketId === undefined
  ) {
    return null;
  }
  return (
    <Button variant="white" onClick={handleOpenParentTicketClick}>
      {t(translations.MainTicket)}
    </Button>
  );
}
