// https://github.com/OliverJAsh/fp-ts-react-hooks/blob/master/src/index.ts

import * as A from 'fp-ts/lib/Array';
import { Eq, fromEquals, strictEqual } from 'fp-ts/lib/Eq';
//import * as O from 'fp-ts/lib/Option';
import * as ReadonlyArray from 'fp-ts/lib/ReadonlyArray';
import * as Record from 'fp-ts/lib/Record';
import * as React from 'react';
import {
  useCustomCompareCallback,
  useCustomCompareEffect,
  useCustomCompareMemo,
} from 'use-custom-compare';

const eqStrict = fromEquals(strictEqual);

export const eq: Eq<unknown> = fromEquals((a, b) =>
  Array.isArray(a) && Array.isArray(b)
    ? eqArray.equals(a, b)
    : eqStrict.equals(a, b),
);

//const eqOption = O.getEq(eq);
const eqArray = A.getEq(eq);

const eqDependencies = ReadonlyArray.getEq(eq);

const eqProps = Record.getEq(eq);

export const useCallback: typeof React.useCallback = (fn, dependencies) =>
  useCustomCompareCallback(
    fn,
    dependencies !== undefined ? dependencies : [],
    eqDependencies.equals,
  );

export const useEffect: typeof React.useEffect = (fn, dependencies) =>
  useCustomCompareEffect(
    fn,
    dependencies !== undefined ? dependencies : [],
    eqDependencies.equals,
  );

export const useMemo: typeof React.useMemo = (fn, dependencies) =>
  useCustomCompareMemo(
    fn,
    dependencies !== undefined ? dependencies : [],
    eqDependencies.equals,
  );

export const memo = <P extends object>(Component: React.FC<P>) =>
  React.memo(Component, eqProps.equals);
