import { InputAdornment } from '@material-ui/core';
import { TextField as BasicTextField } from 'app/components/TextField';
import * as React from 'react';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import { EditorProps } from '../../EditableCell';
import { Icon } from 'app/components/BasicIcons/FontAwesome';

// 'ShortText':

export function TextField({
  parentValue,
  error,
  helperText,
  info,
  autoFocus,
  ...props
}: EditorProps<string>) {
  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => {
    props.onChange(e.target.value);
  };

  return (
    <>
      <BasicTextField
        className={'textTable'}
        InputProps={{
          disableUnderline: true,
          endAdornment: ((!!info && info !== '') || error) && (
            <InputAdornment position="end">
              {!!info && info !== '' && <InfoIcon title={info} error={error} />}
              {error && (
                <Icon
                  icon="info-circle"
                  color="danger"
                  colorExtend="textHover"
                  fontSize="13px"
                  width="13px"
                />
              )}
            </InputAdornment>
          ),
        }}
        variant="standard"
        fullWidth
        error={error}
        inputProps={{
          type: 'text',
        }}
        value={props.value || ''}
        onChange={handleChange}
        onBlur={props.onBlur}
        id={props.id}
        label={props.label}
        autoFocus={autoFocus}
        name={props.name}
      />
      {/* <StyledInput
        type="text"
        value={props.value || ''}
        onChange={handleChange}
        onBlur={props.onBlur}
        error={props.error}
      /> */}
    </>
  );
}
