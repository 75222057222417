import * as React from 'react';
import { ISchedulerEvent } from '../../types/ISchedulerEvent';
import { formatSchedulerTime } from '../../utils/formatSchedulerTime';

/**
 * Event header props
 */
export interface EventHeaderProps {
  event: ISchedulerEvent;
}

/**
 * Event header element
 * @param props props
 * @returns Event header
 */
export function EventHeader(props: EventHeaderProps) {
  return (
    <>
      <div>
        {formatSchedulerTime(props.event.start_date)}
        {' - '}
        {formatSchedulerTime(props.event.end_date)}
      </div>
      <div>{props.event.service_name}</div>
    </>
  );
}
