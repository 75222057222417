import * as React from 'react';
import { useSelector } from 'react-redux';
import { DetectIsMobile } from 'utils/mobileDetect';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { StyledFormProps } from '../StyledForm';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Section } from '../wrappers';
import { SIDE_PANEL_WIDTH } from 'styles/constantVariables';

export const StyledFormFieldsContainer = (
  props: Omit<StyledFormProps, 'onSubmit'>,
) => {
  const { avoidStyles, children, sectionStyle, isCover, ...other } = props;
  const isMobile = DetectIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const showShortView =
    isMobile || (sidePanelOpen && !sidePanelExpanded) || isCover;
  const theme = useTheme();
  return (
    <Section
      theme={theme}
      sidePanelWidth={SIDE_PANEL_WIDTH}
      className={
        avoidStyles
          ? undefined
          : clsx('formsection', { shortView: showShortView })
      }
      {...other}
      style={sectionStyle}
    >
      {children}
    </Section>
  );
};
