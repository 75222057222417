import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => {
  return state.InvoiceDetails || initialState;
};

export const selectLoading = createSelector(
  [selectSlice],
  state => state.loading,
);
export const selectNotFound = createSelector(
  [selectSlice],
  state => state.notFound,
);
export const selectActiveFilters = createSelector(
  [selectSlice],
  state => state.filters,
);
export const selectInvoice = createSelector(
  [selectSlice],
  state => state.invoice,
);
