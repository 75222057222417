import * as ReactDOMServer from 'react-dom/server';
import * as DHTMLX from 'dhtmlx-scheduler';
import { BookitThemeSettings } from 'styles/theme';
import { SimplePaletteColorOptions } from '@material-ui/core/styles/createPalette';
import { assertExhaustive } from 'utils/assertExhaustive';
import { ISchedulerEvent } from '../../types/ISchedulerEvent';
import { TrainingSessionEventConent } from './TrainingSessionEventConent';
import { ReservationEventConent } from './ReservationEventContent';
import { EventType } from '../../slice/types';
import { EventContentProps } from './EventContent';
import { OfflineEventConent } from './OfflineEventContent';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { ISchedulerState } from '../../types/ISchedulerState';
import HTMLReactParser from 'html-react-parser';
import { dateUtils } from 'utils/date-utils';
import { bookitColors } from 'styles/colors/bookitColors';

/**
 * Render fundtion that is used by the scheduler to build event's contents
 * see https://docs.dhtmlx.com/scheduler/api__scheduler_renderevent.html for more details
 * @param container Container DOM element of the rendered event
 * @param ev Event object
 * @param width Event width provided by the scheduler
 * @param height Event height provided by the scheduler
 * @param header_content Event header content
 * @param body_content Event body content
 * @returns true
 */
export const renderEvent = (scheduler: DHTMLX.SchedulerStatic) => (
  container: HTMLDivElement,
  ev: ISchedulerEvent,
  width: number,
  height: number,
  header_content: string,
  body_content: string,
) => {
  try {
    var container_width = container.style.width; // e.g. "105px"
    // set background color on the event container instead of setting it on each child separately
    const backgroundColor = getBackgroundColor({
      type: ev.type,
      color:
        (ev.original as ICalendarReservationDto)?.EquipmentColor ?? ev.color,
    });
    if (backgroundColor !== undefined) {
      container.style.backgroundColor = backgroundColor;
    }
    const topEventHeight = getSlotsFromEvent(
      ev.start_date,
      ev.end_date,
      scheduler.config.scroll_hour,
    );
    // get original full event obj instead of the cloned one
    // start/end on ev might be set to start/end of the day for multi day events get cloned
    const originalEvent: ISchedulerEvent = scheduler.getEvent(ev.id);

    // get a render function for each event type type
    const RenderFC = RenderEvent(originalEvent.type);

    const state: ISchedulerState = scheduler.getState();

    const readonly =
      originalEvent.readonly ||
      // set event to be readonly (disables drag/resize) when the start/end is outside of the time period shown on the timeline
      // this prevents cpu crunch done by the scheduler in onBeforeDrag event handler for long (multi day/year) reservations
      // this prevents dragging multi day event in day calendar when no feedback is provided regarding start/end of the event
      originalEvent.start_date < state.min_date ||
      originalEvent.end_date > state.max_date;
    const notClickable = originalEvent.notClickable;
    // render an html and shove it into event container
    container.innerHTML = ReactDOMServer.renderToStaticMarkup(
      <RenderFC
        containerWidth={container_width}
        event={originalEvent}
        header={header_content}
        body={HTMLReactParser(body_content)}
        height={height - topEventHeight}
        readonly={readonly}
        notClickable={notClickable}
        offsetTopHeight={topEventHeight}
      />,
    );
  } catch (error) {
    console.error(error);
    return false;
  }
  // true - the scheduler displays a custom form
  return true;
};
const getSlotsFromEvent = (
  start: Date,
  end: Date,
  scroll_hour: number,
): number => {
  let duration = dateUtils.intervalToDuration({
    start: start,
    end: end,
  });
  if (
    start.getHours() < 8 &&
    end > new Date(new Date(start).setHours(10)) &&
    ((duration?.hours ?? 0) > 3 || (duration?.days ?? 0) > 0)
  ) {
    //let totalSlotsHeight = 44 * 24;
    let eithSlotsHeight = 44 * scroll_hour;
    let startHeight = ((start.getHours() * 60 + start.getMinutes()) / 60) * 44;
    // let endHeight = ((end.getHours() * 60 + end.getMinutes()) / 60) * 44;
    // if (endHeight <= eithSlotsHeight + 88) {
    //   startHeight = startHeight - (eithSlotsHeight + 88 - endHeight);
    // }
    // let calHeight = document?.querySelector('.dhx_cal_data')?.clientHeight;
    // let shiftHeight =
    //   totalSlotsHeight - (calHeight ?? 0) - (totalSlotsHeight - eventHeight);
    //return Math.floor(shiftHeight);
    return Math.floor(eithSlotsHeight - startHeight);
  }
  return 0;
};

const getBackgroundColor = ({
  type,
  color,
}: Pick<ISchedulerEvent, 'type' | 'color'>): string | undefined => {
  const trainingSessionBackgroundColor = '#99dcfa';
  const defaultColor =
    (BookitThemeSettings.palette?.primary as SimplePaletteColorOptions)?.main ??
    bookitColors.primary.regular;
  // new events won't have any type
  if (type === undefined) {
    return defaultColor;
  }
  switch (type) {
    case 'offline':
      return undefined;
    case 'reservation':
      // todo: set different color for approved/pending/etc.
      return color ?? defaultColor;
    case 'trainingsession':
      return trainingSessionBackgroundColor;
    default:
      assertExhaustive(type);
  }
};

interface RenderEventProps extends EventContentProps {
  event: ISchedulerEvent;
}

function RenderEvent(eventType?: EventType): React.FC<RenderEventProps> {
  if (eventType === undefined) {
    return ReservationEventConent;
  }
  switch (eventType) {
    case 'trainingsession':
      return TrainingSessionEventConent;
    case 'reservation':
      return ReservationEventConent;
    case 'offline':
      return OfflineEventConent;
    default:
      assertExhaustive(eventType);
  }
}
