/**
 *
 * AmPmPicker
 *
 */
import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import React from 'react';

import { Entity } from 'types/common';
import { dateUtils } from 'utils/date-utils';
import { GetOptionsFilterFunction } from 'utils/enumKeys';

export interface AmPmPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<string>>, 'getOptions'> {}
export const AmPmEntityArray = [
  {
    Id: 'am',
    Name: 'AM',
  },
  {
    Id: 'pm',
    Name: 'PM',
  },
] as Entity<string>[];

export const defaultAmPm = AmPmEntityArray[0];
// AmPmEntityArray.find(f => f.Id === dateUtils.getMinutes(new Date())) ??
//   ({
//     Id: dateUtils.getMinutes(new Date()),
//     Name: dateUtils.getMinutes(new Date()).toString(),
//   } as Entity<number>);

export const getAmPmEntityFromDate = (date?: Date | string | null) => {
  if (date === null || date === undefined) {
    return AmPmEntityArray[0];
  } else {
    let meridiem = dateUtils.getMeridiem(date);
    return AmPmEntityArray.find(f => f.Id === meridiem) ?? AmPmEntityArray[0];
  }
};
export const initAmPmData = (
  initval: string | undefined,
): Promise<Entity<string>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<string>[]>((resolve, reject) => {
      resolve([] as Entity<string>[]);
    });
  } else {
    return new Promise<Entity<string>[]>((resolve, reject) => {
      resolve(
        AmPmEntityArray.filter(item => {
          return item.Id.toString() === initval.toString();
        }),
      );
    });
  }
};

export function AmPmPicker(props: AmPmPickerProps) {
  const getOptions = React.useMemo(() => {
    let items = AmPmEntityArray;
    return GetOptionsFilterFunction(items);
  }, []);
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      id={props.id || 'ampmId'}
      value={props.value}
      autoHighlight={true}
      autoSelect={true}
      selectOnFocus={true}
      clearOnEscape
      {...props}
    />
  );
}
