/**
 * Parses the stored relative HRef without in a way to match how URL is parsed to produce the pathName + search
 * Used to save the stored filters/pathnames in the pages with the filters.
 * Has to be backward compatible with the aspx filters where only the search without the path was stored (e.g. foo=bar&baz=...)
 * @param value stored "saved url" value
 * @returns pathName + search
 */
export const parseProfileUrl = (
  value?: string,
): {
  pathName?: string;
  search?: string;
} => {
  if (value === undefined || value === null) {
    return {
      pathName: undefined,
      search: undefined,
    };
  }
  const i = value.indexOf('?');
  if (i < 0) {
    return {
      pathName: undefined,
      search: '?' + value,
    };
  }

  const part1 = value.slice(0, i);
  const part2 = value.slice(i + 1);

  return {
    pathName: undefinedIfIsEmpty(part1),
    search: undefinedIfIsEmpty(part2),
  };
};

export const undefinedIfIsEmpty = (
  s: string | null | undefined,
): string | undefined => (isEmpty(s) ? undefined : s ?? undefined);

export const isEmpty = (s: string | null | undefined): boolean => {
  if (s === null || s === undefined) {
    return true;
  }
  if (s.trim() === '') {
    return true;
  }
  return false;
};
