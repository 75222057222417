import { PayloadAction } from '@reduxjs/toolkit';
import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { internalIdLoginSaga } from './saga';
import { InternalIdLoginState } from './types';

export const initialState: InternalIdLoginState = {
  processing: false,
  error: false,
  internalId: '',
  initialized: false,
  isLargeAnimals: true,
};

const slice = createSlice({
  name: 'internalIdLogin',
  initialState,
  reducers: {
    init(
      state,
      action: PayloadAction<{ isLargeAnimals: boolean; returnUrl?: string }>,
    ) {
      state.isLargeAnimals = action.payload.isLargeAnimals;
      state.initialized = false;
      state.returnUrl = action.payload.returnUrl;
    },
    init_success(state, action: PayloadAction) {
      state.initialized = true;
    },
    login(
      state,
      action: PayloadAction<{ internalId: string; General: boolean }>,
    ) {
      state.processing = true;
      state.error = false;
    },
    success(state, action: PayloadAction<IAuthenticatedUser>) {
      state.processing = false;
      state.identity = action.payload;
      state.internalId = '';
    },
    error(state, action: PayloadAction<any>) {
      state.processing = false;
      state.identity = undefined;
      state.error = true;
      state.internalId = '';
    },
    clearError(state, action: PayloadAction) {
      state.error = false;
      state.processing = false;
    },
    redirectToLogin(state, action: PayloadAction) {},
    logout(state, action: PayloadAction) {
      state.identity = undefined;
      state.internalId = '';
      state.processing = false;
    },
  },
});

export const { actions: internalIdLoginActions } = slice;

export const useInternalIdLoginSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: internalIdLoginSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useInternalIdLoginSlice();
 *E
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
