import { enumStringKeys } from 'utils/enumKeys';
import { AllowedSettings } from 'utils/globalSettings';
import { SettingModel } from 'utils/globalSettings/SettingModel';
import { httpClient } from './HttpClient';
import { Condition, ODataOperators } from './odata/ODataFilter';
import { Identifiable } from 'types/common';

export const SettingsApi = {
  getSystemSettingByKey: (payload: { key: string }) => {
    const url = '/api/odata/v4/SystemSettings';
    const params: {
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $filter: '(Id eq ' + payload.key + ')',
      $select: 'Id,Name,Value',
      $skip: 0,
      $top: 1,
    };
    return httpClient.get(url, params);
  },
  getAllSystemSetting: () => {
    const url = '/api/odata/v4/SystemSettings';
    const params: {
      $select: string;
    } = {
      $select: 'Id,Name,Value',
    };
    return httpClient.get(url, params);
  },
  getAlowedSystemSetting: () => {
    const url = '/api/odata/v4/SystemSettings';
    const params: {
      $select: string;
      $filter?: string;
    } = {
      $select: 'Id,Name,Value',
    };

    let names: Identifiable<string>[] = enumStringKeys(AllowedSettings).map(
      v => {
        return { Id: v };
      },
    );

    let filter =
      new Condition<SettingModel>(
        'Name',
        ODataOperators.Includes,
        names,
      ).toString() ?? '';
    params.$filter = filter;
    return httpClient.get(url, params);
  },
  getAllSystemSettingFromList: (keys: string[]) => {
    const url = '/api/odata/v4/SystemSettings';
    const params: {
      $select: string;
      $filter?: string;
    } = {
      $select: 'Id,Name,Value',
    };

    let names: Identifiable<string>[] = keys.map(v => {
      return { Id: v };
    });

    let filter =
      new Condition<SettingModel>(
        'Name',
        ODataOperators.Includes,
        names,
      ).toString() ?? '';
    params.$filter = filter;
    return httpClient.get(url, params);
  },
  getLoginSystemSettings: () => {
    const url = '/api/v3/appsettings/GetLoginSettings';
    return httpClient.get(url);
  },
  setSystemSetting: (setting: SettingModel) => {
    const url = '/api/odata/v4/SystemSettings';
    return httpClient.post(url, setting);
  },
};
