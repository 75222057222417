import { SampleTypePicker } from 'app/components/pickers/AutocompletePickers/SampleTypePicker.index';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export function SampleTypeFilter(props: IFilterComponentProps) {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <SampleTypePicker
      value={props.value}
      onChange={handleOnChange}
      placeholder={t(translations.AllValues)}
      isOpen={props.open}
      ariaLabel={props.label}
      id={props.id}
      size="small"
    />
  );
}
