import * as React from 'react';
import { Alert } from '@material-ui/lab';
import * as Yup from 'yup';
import { Button } from 'app/components/BasicButtons/Button';
import { useServiceRequestStatusUpdate } from 'app/pages/Samples/components/Utils';
import {
  InternalServiceRequestStatuses,
  InternalServiceRequestStatusesUnion,
} from 'enums/InternalServiceRequestStatuses';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectServiceRequestStatusesByInternalId } from '../../slice/selectors';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import {
  FormRow,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { DialogForm, DialogFormProps } from 'app/components/DialogForm';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { IReason } from '../../slice/types';

export interface QuoteSentToUserAlertProps {
  ServiceRequestId: number;
  ServiceRequestInternalStatus: InternalServiceRequestStatusesUnion;
}
export function QuoteSentToUserAlert(props: QuoteSentToUserAlertProps) {
  const { t } = useTranslation();

  const { changeStatus } = useServiceRequestStatusUpdate({
    serviceRequestStatusId: props.ServiceRequestId,
  });
  const approvedStatus = {
    Id: InternalServiceRequestStatuses.QuoteApproved as number,
    Name: 'QuoteApproved',
    InternalStatusId: 'QuoteApproved',
  } as IServiceRequestStatusDto;
  // const approvedStatus =  useSelector(
  //   state =>
  //     selectServiceRequestStatusesByInternalId(state, 'QuoteApproved')?.[0],
  // );
  const cancelledStatuses = useSelector(state =>
    selectServiceRequestStatusesByInternalId(state, 'Cancelled'),
  );
  const handleApproveQuoteClick = (value: IServiceRequestStatusDto) =>
    changeStatus({
      status: value,
    });
  const handleRejectClick = (values: IReason) => {
    const firstCancelledStatus = cancelledStatuses?.[0];
    if (firstCancelledStatus !== undefined) {
      changeStatus({
        status: firstCancelledStatus,
        reason: values.Reason,
      });
    }
  };
  if (props.ServiceRequestInternalStatus !== 'QuoteSendToUser') {
    return null;
  }
  return (
    <StyledFormFieldsContainer>
      <Alert color="info">
        <span>
          {t(translations.Request_SendQuoteToUser_PendingMessage)}
          &emsp;
          <Button
            key={`status_${approvedStatus.Id}`}
            size="small"
            onClick={() => handleApproveQuoteClick(approvedStatus)}
          >
            {t(translations.Request_SendQuoteToUser_ApproveQuote)}
          </Button>
          &emsp;
          <Reject onSubmit={handleRejectClick} />
        </span>
      </Alert>
    </StyledFormFieldsContainer>
  );
}

interface RejectProps extends Pick<DialogConfirmWithReasonProps, 'onSubmit'> {}
function Reject(props: RejectProps) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Button size="small" variant="danger" onClick={() => setDialogOpen(true)}>
        {t(translations.Request_SendQuoteToUser_RejectQuote)}
      </Button>
      <ConfirmRejectDialog
        onClose={() => setDialogOpen(false)}
        onSubmit={props.onSubmit}
        open={dialogOpen}
      />
    </>
  );
}

interface DialogConfirmWithReasonProps
  extends Pick<DialogFormProps<IReason>, 'onSubmit' | 'onClose' | 'open'> {}
function ConfirmRejectDialog(props: DialogConfirmWithReasonProps) {
  const { t } = useTranslation();
  const validationSchema: Yup.SchemaOf<IReason> = Yup.object({
    Reason: Yup.string().required(t(translations.RejectReasonEmpty_err)),
  });
  return (
    <DialogForm
      {...props}
      validationSchema={validationSchema}
      initialValues={{}}
      title={t(translations.Confirm_RejectQuote_Title)}
    >
      <FormRow fullRow>
        <FormRichTextField
          name="Reason"
          label={t(translations.Confirm_RejectQuote_TextLabel)}
          placeholder={t(translations.Confirm_RejectQuote_TextLabel)}
        />
      </FormRow>
    </DialogForm>
  );
}
