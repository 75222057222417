/**
 *
 * SamplePlatePositionsPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { ISamplePlateDto } from 'api/odata/generated/entities/ISamplePlateDto';
import { SamplePlatePositionNamingTypes } from 'api/odata/generated/enums/SamplePlatePositionNamingTypes';
import {
  AutocompleteMultiPicker,
  AutocompleteMultiPickerProps,
} from 'app/components/BasicPickers/AutocompleteMultiPicker';
import {
  ISample,
  ISampleRun,
} from 'app/pages/Samples/SamplesListPage/slice/types';
import * as React from 'react';
import { Entity } from 'types/common';

export interface SamplePlatePositionsPickerProps
  extends Omit<
    AutocompleteMultiPickerProps<string>,
    'loadData' | 'onChange' | 'value' | 'label' | 'multiple' | 'fullWidth'
  > {
  serviceGroupId?: number;
  name?: string;
  placeholder?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  error?: boolean;
  helperText?: string;
  values?: ISample;
  value: string | null;
  onChange: (value: string | null) => void;
}

export interface ConnectedSamplePlatePositionsPickerProps
  extends SamplePlatePositionsPickerProps {
  samplePlateId: string | null;
}
function range(x) {
  return Array.apply(null, Array(x)).map(function (x, i) {
    return i;
  });
}
const loadData = (plateId?: string | null) => async (
  searchTerm: string | null,
): Promise<Entity<string>[]> => {
  const plate = await httpClient.get<ISamplePlateDto>(
    `/api/odata/v4/sampleplates('${plateId}')`,
  );

  const rowsCount = plate.SamplePlateTypeRows;
  const columnsCount = plate.SamplePlateTypeColumns;
  const rows = range(columnsCount)
    .map(i =>
      plate.SamplePlateTypeColumnsNaming ===
      SamplePlatePositionNamingTypes.Alpha
        ? String.fromCharCode(65 + i)
        : i + 1,
    )
    .flatMap(column =>
      range(rowsCount)
        .map(i =>
          plate.SamplePlateTypeRowsNaming ===
          SamplePlatePositionNamingTypes.Alpha
            ? String.fromCharCode(65 + i)
            : i + 1,
        )
        .map(row => `${column}${row}`),
    );
  const options = rows.map(a => ({ Id: a, Name: a }));
  return options;
};
export function SamplePlatePositionsPicker({
  serviceGroupId,
  samplePlateId,
  value,
  values,
  onChange,
  ...props
}: ConnectedSamplePlatePositionsPickerProps) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDatax = React.useCallback(loadData(samplePlateId), [
    serviceGroupId,
    samplePlateId,
  ]);
  const S2Value =
    value === null
      ? []
      : value
          .split(';')
          .filter(value => value !== '')
          .map(value => ({ Id: value, Name: value }));
  return (
    <AutocompleteMultiPicker
      label=""
      fullWidth={false}
      id={props.id || 'samplepositionId'}
      loadData={loadDatax}
      getOptionLabel={item => item.Name}
      size={props.size}
      onChange={value =>
        onChange(value?.map(item => item.Id)?.join(';') ?? null)
      }
      value={S2Value}
      {...props}
    />
  );
}

export function TargetSamplePlatePositionsPicker({
  values,

  ...props
}: SamplePlatePositionsPickerProps) {
  const samplePlateId = (values as ISampleRun)?.TargetPlateId ?? null;
  return (
    <SamplePlatePositionsPicker samplePlateId={samplePlateId} {...props} />
  );
}
