/**
 *
 * RichTextEditor
 *
 */
import * as React from 'react';
import { Entity } from 'types/common';
import { StartRichEditor } from './RichEditor';

export interface RichTextEditorProps {
  value?: string | undefined;
  onChange: (value: string | undefined) => void;
  placeholder?: string;
  autofocus?: boolean;
  hasMentions?: boolean;
  onChangeMentions?: (mentions: Entity<string>[]) => void;
  additionalButtons?: ((classname, key) => React.ReactNode)[];
  disabled?: boolean;
  isInvoiceTemplateEditor?: boolean;
  extendedMode?: boolean;
}

export function RichTextEditor(props: RichTextEditorProps) {
  return (
    <StartRichEditor
      initialValue={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      autoFocus={props.autofocus}
      hasMentions={props.hasMentions}
      onChangeMentions={props.onChangeMentions}
      additionalButtons={props.additionalButtons}
      disabled={props.disabled}
      isInvoiceTemplateEditor={props.isInvoiceTemplateEditor}
      extendedMode={props.extendedMode}
    />
  );
}
