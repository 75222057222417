import { InvoiceRowReferenceLinks } from 'app/components/InvoiceRowReferenceLinks';
import { CellProps, Renderer } from 'react-table';

type RecordType = Record<string, any>;
const InvoiceRowReferenceRenderer: Renderer<CellProps<RecordType>> = ({
  value,
  row,
}) => (
  <InvoiceRowReferenceLinks
    OfflineServiceId={row.original.OfflineServiceId}
    ReservationId={row.original.ReservationId}
    ServiceRequestId={row.original.ServiceRequestId}
    SubscriptionId={row.original.SubscriptionId}
    UsageId={row.original.UsageId}
  />
);
export default InvoiceRowReferenceRenderer;
