import { Dialog } from '@material-ui/core';
import { Box } from 'app/components/basic/Wrappers/Box';

export interface PopupPrintToPDFDialogProps {
  open: boolean;
  content: React.ReactNode;
  fullWidth: boolean;
  padding: string;
}
export function PopupPrintToPDFDialog(props: PopupPrintToPDFDialogProps) {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.open}
      aria-labelledby={'PrintToPDF-dialog-popup'}
      fullScreen={false}
      fullWidth={props.fullWidth}
    >
      <Box
        style={{
          width: '100%',
          height: '100%',
          padding: props.padding,
          backgroundColor: '#fcfcfd',
        }}
      >
        {props.content}
      </Box>
    </Dialog>
  );
}
