/**
 *
 * Asynchronously loads the component for UserAttributes Page
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UserAttributes = lazyLoad(
  () => import('./index'),
  module => module.UserAttributesPage,
);
