import { omit } from 'lodash';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { Entity, Identifiable } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';
import { ISavedViewDto } from './odata/generated/entities/ISavedViewDto';
import { Condition, ODataOperators } from './odata/ODataFilter';

export const savedViewsApi = {
  initUpdateData: (payload: number) => {
    const url = `/api/odata/v4/savedviews(${payload})`;
    return httpClient.get(url);
  },
  deleteSavedViews: (rows: ISavedViewDto[]): Promise<IResponseType> => {
    const viewIds: number[] = rows.map(item => item.Id);
    const data = { viewIds };
    const url = '/api/odata/v4/SavedViews/DeleteViews';
    return httpClient.post(url, data);
  },
  createSavedView: (payload: ISavedViewDto) => {
    const url = `/api/odata/v4/savedviews`;
    return httpClient.post(url, payload);
  },
  updateSavedView: (payload: ISavedViewDto) => {
    const url = `/api/odata/v4/savedviews(${payload.Id})`;
    return httpClient.put(url, payload);
  },
  patch: (payload: Partial<ISavedViewDto>) => {
    const url = `/api/odata/v4/savedviews(${payload.Id})`;
    return httpClient.patch(url, omit(payload, 'Id'));
  },
  getMySavedViews: (payload: {
    user: AuthenticatedUser | undefined;
    serviceGroups: Entity<number>[] | undefined;
    isAdmin: boolean;
    onlyMyView: boolean | undefined;
  }) => {
    const url = '/api/odata/v4/SavedViews';
    const filters = mySavedViewFilter(
      payload.serviceGroups,
      payload.user,
      payload.isAdmin,
    );
    const params: {
      $orderby: string;
      $filter?: string;
      $expand: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter:
        payload.onlyMyView === true
          ? new Condition(
              'UserName',
              ODataOperators.Equals,
              payload.user?.Id || '',
            ).toString()
          : filters.toString(),
      $expand: 'Assets',
      $skip: 0,
      $top: 100,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as ISavedViewDto[]);
  },
};
const mySavedViewFilter = (
  topServiceGroups: Entity<number>[] | undefined,
  user: AuthenticatedUser | undefined,
  isAdmin: boolean,
): string => {
  const predicates: (string | Condition<ISavedViewDto>)[] = [];
  let result = '';
  const byUser: string[] = [];
  if (user) {
    byUser.push(
      new Condition('UserName', ODataOperators.Equals, user.Id).toString() ||
        '',
    );
    // byUser.push(
    //   '(' +
    //     (new Condition<ISavedViewDto>(
    //       'Shared',
    //       ODataOperators.Equals,
    //       true,
    //     ).toString() || '') +
    //     ' and ' +
    //     (new Condition<ISavedViewDto>(
    //       'SharedUsers',
    //       ODataOperators.Any,
    //       user.Id,
    //       'Name',
    //     ).toString() || '') +
    //     ')',
    // );
    // if (isAdmin && user.AdminServiceGroups.length > 0) {
    //   let ids = user.AdminServiceGroups.map(item => {
    //     return { Id: item.Id };
    //   }) as Identifiable<number>[];
    //   byUser.push(
    //     '(' +
    //       (new Condition<ISavedViewDto>(
    //         'Shared',
    //         ODataOperators.Equals,
    //         true,
    //       ).toString() || '') +
    //       ' and ' +
    //       '(' +
    //       new Condition(
    //         'Assets',
    //         ODataOperators.AllIn,
    //         ids,
    //         'ServiceGroupId',
    //       ).toString() +
    //       '))',
    //   );
    // }
    // if (isAdmin && user.AdminEquipments.length > 0) {
    //   let ids = user.AdminEquipments.map(item => {
    //     return { Id: item };
    //   }) as Identifiable<number>[];
    //   byUser.push(
    //     '(' +
    //       (new Condition<ISavedViewDto>(
    //         'Shared',
    //         ODataOperators.Equals,
    //         true,
    //       ).toString() || '') +
    //       ' and ' +
    //       '(' +
    //       new Condition(
    //         'Assets',
    //         ODataOperators.AllIn,
    //         ids,
    //         'ServiceId',
    //       ).toString() +
    //       '))',
    //   );
    // }
    byUser.push(
      new Condition<ISavedViewDto>(
        'Shared',
        ODataOperators.Equals,
        true,
      ).toString() || '',
    );
    predicates.push(byUser.filter(f => f !== '').join(' or '));
    result = '(' + predicates.map(f => f.toString()).join(' or ') + ')';
    if (topServiceGroups !== undefined) {
      if (topServiceGroups.length > 0) {
        let ids = topServiceGroups.map(item => {
          return { Id: item.Id };
        }) as Identifiable<number>[];
        result +=
          ' and (' +
          new Condition(
            'Assets',
            ODataOperators.AllIn,
            ids,
            'ServiceGroupId',
          ).toString() +
          ')';
      }
    }
  }

  return result;
};
