import { translations } from 'locales/translations';
import { TFunction } from 'i18next';
import { PrintPDFItem } from './slice/types';

export function getPrintPDFSections(
  t: TFunction,
  userProfileSettingsSections: string,
): PrintPDFItem[] {
  let sections = [
    {
      id: 'SubmissionsDetails',
      label: t(translations.ExportPDF_SubmissionsDetails),
      checked: true,
    },
    {
      id: 'RequestForm_Table',
      label: t(translations.ExportPDF_RequestForm_Table),
      checked: true,
    },
    {
      id: 'Comments',
      label: t(translations.ExportPDF_Comments),
      checked: true,
    },
    {
      id: 'RequestRows_Table',
      label: t(translations.ExportPDF_RequestRows_Table),
      checked: true,
    },
    {
      id: 'Milestones_Services',
      label: t(translations.ExportPDF_Milestones_Services),
      checked: true,
    },
    {
      id: 'ModificationHistory',
      label: t(translations.ExportPDF_ModificationHistory),
      checked: false,
    },
  ];
  if (userProfileSettingsSections.trim() !== '') {
    const selected = userProfileSettingsSections.split(',');
    if (selected.length > 0) {
      for (const s of sections) {
        const found = selected.find(f => f === s.id);
        s.checked = found !== undefined;
      }
    }
  }
  return sections;
}
