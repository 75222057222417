/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  prog4biz: () => _t(translations.Prog4biz),
  bookitlabCopyrightNotice: () =>
    _t(translations.BookitlabCopyrightNoticeTitle),
  softwareBy: () => _t(translations.SoftwareBy),
  systemTime: () => _t(translations.SystemTime),
  lastCodeUpdate: () => _t(translations.LastCodeUpdate),
  termOfUse: () => _t(translations.TermsOfUse),
};
