/**
 *
 * MinutesPicker
 *
 */
import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import React from 'react';

import { Entity } from 'types/common';
import { dateUtils } from 'utils/date-utils';
import { GetOptionsFilterFunction } from 'utils/enumKeys';

export interface MinutesPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {
  minutesStep?: number;
}
export const minutesEntityArray = Array.from({ length: 60 }, (_, i) => {
  const x = i;
  return {
    Id: x,
    Name: x.toString(),
  } as Entity<number>;
});

export const defaultMinutes =
  minutesEntityArray.find(f => f.Id === dateUtils.getMinutes(new Date())) ??
  ({
    Id: dateUtils.getMinutes(new Date()),
    Name: dateUtils.getMinutes(new Date()).toString(),
  } as Entity<number>);

export const getMinutesEntityFromDate = (
  date?: Date | string | null,
  minutesStep?: number,
) => {
  if (date === null || date === undefined) {
    return defaultMinutes;
  } else {
    let toDate = dateUtils.dateOrStringToDate(date);
    return (
      minutesEntityArray.find(f => f.Id === toDate.getMinutes()) ??
      defaultMinutes
    );
  }
};
export const initMinutesData = (
  initval: number | string | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve(
        minutesEntityArray.filter(item => {
          return item.Id.toString() === initval.toString();
        }),
      );
    });
  }
};

export function MinutesPicker(props: MinutesPickerProps) {
  const { minutesStep, mini, size, id, value, ...other } = props;
  const getOptions = React.useMemo(() => {
    let items =
      !!minutesStep && minutesStep > 0 && minutesStep <= 30
        ? minutesEntityArray.filter(f => f.Id % minutesStep === 0)
        : minutesEntityArray;
    return GetOptionsFilterFunction(items);
  }, [minutesStep]);
  return (
    <BaseOptionsPicker
      mini={mini ? true : undefined}
      size={size}
      getOptions={getOptions}
      id={id || 'hoursId'}
      value={value}
      autoHighlight={true}
      autoSelect={true}
      selectOnFocus={true}
      clearOnEscape
      {...other}
    />
  );
}
