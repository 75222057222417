import { Grid } from '@material-ui/core';
import { Body, InputLabel } from '../Typography';
import { toRootedURL } from 'utils/url-utils';
import { CommentFile } from './IComment';
import { Button } from '../BasicButtons/Button';
import { Icon } from '../BasicIcons/FontAwesome';
import { Link } from '../ExternalLink';
import { DialogConfirm } from '../DialogConfirm';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useCallback, useState } from 'react';
import { CommentsApi } from 'api/CommentsApi';
import { useResponseModel } from 'app/hooks/useResponseModel';
import { IconButton } from '../BasicButtons/IconButton';

const fileSizeToString = (size: number, dp: number = 1) => {
  const thresh = 1000;
  if (Math.abs(size) < thresh) {
    return size + ' B';
  }
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = -1;
  const r = 10 ** dp;
  do {
    size /= thresh;
    i++;
  } while (
    Math.round(Math.abs(size) * r) / r >= thresh &&
    i < units.length - 1
  );
  return size.toFixed(dp) + ' ' + units[i];
};

export const FileButton = ({
  file,
  canRemove,
  onDeleteFile,
}: {
  file: CommentFile;
  canRemove: Boolean;
  onDeleteFile: (id: string) => void;
}) => {
  const shortenName = (name: string) => {
    const [rawName, suffix] = name.split('.');
    if (rawName.length < 25) return name;
    return rawName.slice(0, 24) + '...' + suffix;
  };
  const { t } = useTranslation();
  const [openDelete, setOpenDelete] = useState(false);
  const sendNotifications = useResponseModel();
  const close = () => setOpenDelete(false);
  const onDelete = useCallback(() => {
    close();
    onDeleteFile(file.Id);
    sendNotifications({
      ErrorMessages: [],
      SuccessMessages: [t(translations.msg_DeleteFile)],
      WarningMessages: [],
    });
  }, [file.Id, onDeleteFile, sendNotifications, t]);
  return (
    <Grid container>
      <Grid item>
        <Button
          variant="white"
          size="small"
          href={toRootedURL('/file.ashx?id=' + file.Id)}
        >
          <Icon icon="link" color="primary" />
        </Button>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Link href={'/file.ashx?id=' + file.Id}>
              <Body>{shortenName(file.Name)}</Body>
            </Link>
          </Grid>
        </Grid>
        <InputLabel size="xs">
          {file.Size && <Grid item>{fileSizeToString(file.Size)}</Grid>}
        </InputLabel>
      </Grid>
      {canRemove && (
        <Grid item>
          <IconButton
            variant="ghost"
            title={t(translations.Delete)}
            icon="times"
            onClick={() => setOpenDelete(true)}
          />
          <DialogConfirm
            onConfirm={() =>
              CommentsApi.removeFile(file.Id)
                .then(onDelete)
                .catch(e => {
                  console.error(e);
                  sendNotifications({
                    ErrorMessages: [t(translations.err_DeleteFileFailure)],
                    SuccessMessages: [],
                    WarningMessages: [],
                  });
                })
            }
            onCancel={close}
            onClose={close}
            body={undefined}
            title={t(translations.AreYouSure)}
            isOpen={openDelete}
          />
        </Grid>
      )}
    </Grid>
  );
};
