/**
 *
 * Asynchronously loads the component for Areas Page
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AreasPage = lazyLoad(
  () => import('./index'),
  module => module.AreasPage,
);
