import { Column } from 'react-table';
import { IRow } from '../IRow';

export type ColumnWithDep<TRow extends IRow> = Column<TRow> & {
  deps?: (keyof TRow)[];
  notShowTitle?: boolean;
};

export const hasDeps = <TRow extends IRow>(
  column: Column<TRow>,
): column is ColumnWithDep<TRow> => {
  return column['deps'] !== undefined && Array.isArray(column['deps']);
};
