export function findParentTarget(
  event: MouseEvent,
  selectors: string[],
): Element | null {
  // check if the click target is a DOM element so that the closest method is available
  if (!(event.target instanceof Element)) {
    return null;
  }
  if (!event.target.closest) {
    return null;
  }
  for (const selector of selectors) {
    try {
      const container = event.target.closest(selector);
      if (container !== null) {
        return container;
      }
    } catch (error) {
      console.log(error);
    }
  }
  return null;
}
