/**
 *
 * auto generated interface for Bookit.Data.Enums.AllowReservationCollaboratorsTypes
 *
 */

export enum AllowReservationCollaboratorsTypes {
  None = 1,
  NotAllowedToStartUsage = 0,
  AllowedToStartUsage = 2,
}
export const AllowReservationCollaboratorsTypesArray = [
  'None',
  'NotAllowedToStartUsage',
  'AllowedToStartUsage',
];
export type AllowReservationCollaboratorsTypesUnion = keyof typeof AllowReservationCollaboratorsTypes;
