import styled from 'styled-components';

export const QuickSearchSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
`;
export const QuickSearchSectionTitle = styled('div')`
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
export const QuickSearchSearchesItems = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  align-self: stretch;
`;
export const QuickSearchRecentItems = styled('div')`
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  align-self: stretch;
  width: 100%;
`;
export const QuickSearchEmptySection = styled('div')`
  display: flex;
  width: 100%;
  height: 260px;
  padding: 0px 16px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;
export const QuickSearchEmptyContent = styled('div')`
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
export const QuickSearchSeeAll = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
`;
export const QuickSearchMainTitleSection = styled('div')`
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
export const QuickSearchAllInstrumentsSection = styled('div')`
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;
