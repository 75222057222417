import {
  formFileSchema,
  formValueSchema,
} from 'app/components/CustomForm/CustomFormUtils';
import {
  EntityNumberSchema,
  EntityStringSchema,
} from 'app/components/Forms/Schemas';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { dateUtils } from 'utils/date-utils';
import * as Yup from 'yup';
import { WorkOrderDetailsState } from '../slice/types';

export const getWorkOrdersShema = (
  t: TFunction,
): Yup.SchemaOf<WorkOrderDetailsState> => {
  return Yup.object({
    Id: Yup.number().notRequired().default(-1),
    Assets: Yup.array()
      .of(EntityNumberSchema)
      .label(t(translations.Asset) as string)
      .min(1)
      .required(t(translations.err_AssetRequired)),
    WorkOrderType: Yup.mixed()
      .label(t(translations.AlertType) as string)
      .required(t(translations.errEvent_Type_Required) as string)
      .typeError(t(translations.errEvent_Type_Required) as string),
    AlertTitle: Yup.string()
      .label(t(translations.AlertName) as string)
      .nullable(true)
      .required(t(translations.err_AlertNameRequired) as string),
    WorkOrderTypeStatus: Yup.mixed()
      .label(t(translations.EventTypeStatus) as string)
      .required(t(translations.errEvent_Type_Status_Required) as string),
    EventStart: Yup.date()
      .label(t(translations.EventDueDate) as string)
      .default(dateUtils.dateOrStringToDate(new Date()))

      .required(() => t(translations.err_EventDueDateRequired) as string),
    EventEnd: Yup.date().notRequired().nullable(),
    RemindBefore: Yup.mixed().label(t(translations.RemindMeBefore) as string),
    AlertStart: Yup.date()
      .label(t(translations.AlertStartTimeLabel) as string)
      .nullable()
      .when('RemindBefore', {
        is: val => val.Id === 6,
        then: Yup.date()
          .label(t(translations.AlertStartTimeLabel) as string)
          .default(
            dateUtils.addDays(dateUtils.dateOrStringToDate(new Date()), -1),
          )
          .nullable()
          .required(
            `${t(translations.AlertStartTimeLabel) as string} is required.`,
          )
          .max(Yup.ref('EventStart')),
      }),
    SymptomCodes: Yup.array()
      .of(EntityNumberSchema)
      .label(t(translations.SymptomCodes) as string),

    AssignUsers: Yup.array()
      .of(EntityStringSchema)
      .label(t(translations.AssignedUsers) as string),
    RepairCodes: Yup.array()
      .of(EntityNumberSchema)
      .label(t(translations.RepairCodes) as string),
    Description: Yup.string()
      .label(t(translations.Description) as string)
      .nullable(),
    Downtime: Yup.boolean().label(t(translations.Downtime) as string),
    OfflineEventStart: Yup.date()
      .label(t(translations.OfflineEventStartLabel) as string)
      .nullable()
      .when('Downtime', {
        is: check => check === true,
        then: Yup.date()
          .label(t(translations.OfflineEventStartLabel) as string)
          .nullable()
          .required(t(translations.OfflineEventStartRequired) as string),
      }),
    OfflineEventEnd: Yup.date()
      .label(t(translations.OfflineEventEndLabel) as string)
      .nullable()
      .when('OfflineEventStart', {
        is: start => start != null,
        then: Yup.date()
          .label(t(translations.OfflineEventEndLabel) as string)
          .nullable()
          .min(
            Yup.ref('OfflineEventStart'),
            t(translations.err_DownTimeEndGreaterThenStart) as string,
          ),
      }),
    OfflineRemarks: Yup.string().nullable(),
    ReasonType: Yup.mixed(),
    ToDown: Yup.boolean().default(false),
    AutoCreateTitle: Yup.string().nullable(true).notRequired(),
    RecurringAssetId: Yup.number().nullable(true).notRequired().default(null),
    RecurringGroupId: Yup.number().nullable(true).notRequired().default(null),
    StaffFormValues: Yup.array().of(formValueSchema),
    SubmissionFormValues: Yup.array().of(formValueSchema),
    SubmissionFormFiles: Yup.array().of(formFileSchema),
    StaffFormFiles: Yup.array().of(formFileSchema),
    StuffCustomForm: Yup.mixed().nullable(true).notRequired().default(null),
    HasAletForm: Yup.boolean().default(false),
    IsClosed: Yup.boolean().default(false),
    ClosedStatuses: Yup.array().of(EntityNumberSchema),
    ReportedBy: Yup.string().nullable(true),
    ShowOnCalibrationHistory: Yup.boolean().default(false),
    NotEditable: Yup.boolean().default(false),
    ReservationId: Yup.number().nullable(true).notRequired().default(null),
  });
};
