import * as React from 'react';
import { MenuList, MenuItem, makeStyles, Theme, Box } from '@material-ui/core';
import { IFilterSettings } from './IFilterSettings';
import { Button } from 'app/components/BasicButtons/Button';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Popper } from 'app/components/Popper';
import { useIsMobile } from 'app/hooks/useIsMobile';
import clsx from 'clsx';

export interface AddFilterButtonProps<TRow> {
  availableFilters: IFilterSettings<TRow>[];
  onChange: (value: IFilterSettings<TRow>) => void;
  filtersSelectionOpen: boolean;
  title: string;
  disabled?: boolean;
  disabledTitle?: string;
}
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  // root: {
  //   width: `${props.winWidth}px`,
  //   height: 'calc(100% - 90px)',
  // },
  selectPaper: {
    height: '100%',
  },
  selectRoot: {
    padding: theme.spacing(2),
  },
  selectTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 8px',
    justifyContent: 'space-between',
    width: '100%',
    '&>label': {
      width: '100%',
      textAlign: 'center',
    },
  },
  selectContent: {
    marginTop: theme.spacing(2),
    boxShadow: 'inset 0px 1px 0px #E0E5EC',
    overflow: 'auto',
    // aim to show 10 top items in the list, with rest hidden behind scrollbar
    maxHeight: '385px',
    '&.mobHorizontal': {
      maxHeight: '200px',
    },
  },
  addButtonRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: 8,
    marginTop: 'unset',
    '&.isMobile': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '16px',
    },
  },
  addCollapsContainer: {
    width: '100%',
  },
}));
export function AddFilterButton<TRow>({
  filtersSelectionOpen,
  ...props
}: AddFilterButtonProps<TRow>) {
  const [open, setOpen] = React.useState(filtersSelectionOpen);
  const { checkIsMobile, isLandscape } = useIsMobile();
  const isMobile = checkIsMobile();
  React.useEffect(() => setOpen(filtersSelectionOpen), [filtersSelectionOpen]);

  const anchorRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<
    EventTarget & HTMLDivElement
  >();
  React.useEffect(() => {
    setAnchorEl(anchorRef.current ?? undefined);
  }, [anchorRef]);
  const handleAddFilterClick = e => {
    setOpen(c => !c);
    setAnchorEl(e.currentTarget);
  };
  // const winWidth = window.innerWidth;
  const { t } = useTranslation();
  const classes = useStyles();
  // close popover on click anywhere outside the menu

  // control field search text value
  const [input, setInnput] = React.useState<string>();

  // manually filter the items and take first 10
  const options = props.availableFilters.filter((item, index) =>
    item.displayName.toLowerCase().includes((input || '').toLowerCase()),
  );

  // handle selected filter event
  const handleFilterSelect = item => {
    props.onChange(item);
    setOpen(false);
    setInnput(undefined);
  };

  return (
    <Box className={clsx(classes.addButtonRoot, { isMobile: isMobile })}>
      {/* TODO: user localization on button */}
      <Button
        variant="gray"
        size="small"
        onClick={handleAddFilterClick}
        ref={anchorRef}
        startIcon={<Icon icon="plus" />}
        disabled={props.disabled}
        title={props.disabled ? props.disabledTitle : undefined}
      >
        {props.title}
      </Button>
      {anchorEl !== undefined && (
        <>
          {/* {isMobile ? (
            <Collapse
              in={open}
              classes={{ container: classes.addCollapsContainer }}
            >
              <TextControlField
                placeholder={t(translations.SearchFilter)}
                startIcon={
                  <Icon icon="search" color="filters" colorExtend="light" />
                }
                value={input || ''}
                onChange={e => setInnput(e.target.value)}
                aria-label={t(translations.SearchFilter)}
                autoFocus
                inputProps={{ 'aria-label': t(translations.SearchFilter) }}
                fullWidth
              />

              <MenuList className={classes.selectContent}>
                {options.map((item, index) => (
                  <MenuItem
                    key={index}
                    button
                    onClick={e => handleFilterSelect(item)}
                  >
                    {item.displayName}
                  </MenuItem>
                ))}
              </MenuList>
            </Collapse>
          ) : ( */}
          <Popper
            open={open}
            anchorEl={anchorEl}
            onClose={() => setOpen(false)}
            title={t(translations.SelectFieldToFilterBy)}
            minWidth="auto"
          >
            <TextControlField
              placeholder={t(translations.SearchFilter)}
              size="small"
              startIcon={
                <Icon icon="search" color="filters" colorExtend="light" />
              }
              value={input || ''}
              aria-label={t(translations.SearchFilter)}
              inputProps={{ 'aria-label': t(translations.SearchFilter) }}
              onChange={e => setInnput(e.target.value)}
              autoFocus
              fullWidth
            />

            <MenuList
              className={clsx(classes.selectContent, {
                mobHorizontal: isMobile && isLandscape,
              })}
            >
              {options.map((item, index) => (
                <MenuItem
                  key={index}
                  tabIndex={index}
                  button
                  onClick={e => handleFilterSelect(item)}
                >
                  {item.displayName}
                </MenuItem>
              ))}
            </MenuList>
          </Popper>
          {/* )} */}
        </>
      )}
    </Box>
  );
}
