import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import useExpandedSideBarState from 'app/hooks/useExpandedSideBarState';

export const OpenMenuButton = ({
  iconSize,
}: {
  iconSize?: 'medium' | 'small';
}) => {
  const { toggle } = useExpandedSideBarState();
  return (
    <IconButton
      edge="start"
      variant="textGray"
      aria-label="open drawer"
      size={iconSize || 'medium'}
      onClick={() => {
        toggle();
      }}
    >
      <MenuIcon />
    </IconButton>
  );
};
