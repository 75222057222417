import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  base: {
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: '6px',
    padding: 'inherit',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
  },
}));
