import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { getIgnoredSearch, toURLSearchParams } from './withSavedHistory';
import { SAVEDVIEW_SEARCH_KEY } from 'app/components/BasicTable/BasicFilter/SavedListsDropDown';

/**
 * Converts applied filters to search string
 * @param items applied filters
 * @param search current search
 * @param reservedQueryStringParameterNames reserved search params
 * @returns search
 */
export function getNextFilter<TRow>(
  items: IFilterSettings<TRow>[],
  search: string,
  reservedQueryStringParameterNames?: string[],
) {
  // preserve the saved view value in addition to the reservedQueryStringParameterNames in order to preserve the selected saved view
  const ignore = [
    ...(reservedQueryStringParameterNames ?? []),
    SAVEDVIEW_SEARCH_KEY,
  ];

  const currentSearch = getIgnoredSearch(search, ignore);
  const filterSearch = toURLSearchParams<TRow>(items);

  for (const key of filterSearch.keys()) {
    const filterValue = filterSearch.get(key);
    if (filterValue !== null) {
      currentSearch.set(key, filterValue);
    }
  }
  return currentSearch;
}
