import { ReservationQueryStringParameters } from './slice/types';
import { useDispatch } from 'react-redux';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { useCallback } from 'react';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';

export const useOpenReservationSidePanel = () => {
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  return useCallback(
    (params: ReservationQueryStringParameters, useSwitchButtons?: boolean) => {
      dispatch(
        actions.openSidePanel({
          type: RenderPageType.ReservationDetails,
          props: {
            useSidePanel: true,
            queryParams: params,
            useSwitchButtons: useSwitchButtons,
          },
        }),
      );
    },
    [actions, dispatch],
  );
};
