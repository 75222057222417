import { IOfflineServiceTypeDto } from 'api/odata/generated/entities/IOfflineServiceTypeDto';
import { IAsset } from 'app/pages/AssetPopUp/IAsset';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { ServiceType } from 'enums/ServiceTypes';
import { intersection } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

export function useAdminPermissions() {
  const user = useSelector(selectAuthenticatedUser);
  const roles: number[] | undefined = user?.Roles;

  const allowedToEdit = React.useCallback(
    (
      row: Pick<IAsset, 'ServiceGroupId' | 'ServiceTypeId' | 'ServiceId'>,
    ): boolean => {
      if (!user) return false;
      return (
        user.HasAdminGroupPermissions(row.ServiceGroupId ?? undefined) ||
        user.HasAdminServicePermissions(row.ServiceTypeId, row.ServiceId)
      );
    },
    [user],
  );
  const allowedToAdd = React.useCallback(
    (row: Pick<IAsset, 'ServiceGroupId'>): boolean => {
      if (!user) return false;
      return (
        user.HasCreateAdminGroupPermissions(row.ServiceGroupId ?? undefined) ||
        user.HasAdminGroupPermissions(row.ServiceGroupId ?? undefined)
      );
    },
    [user],
  );
  const allowedToEditOffline = React.useCallback(
    (row: Pick<IOfflineServiceTypeDto, 'ServiceGroupId' | 'Id'>): boolean => {
      if (!user) return false;
      return (
        user.HasAdminGroupPermissions(row.ServiceGroupId) ||
        user.HasAdminServicePermissions(ServiceType.Offline, row.Id)
      );
    },
    [user],
  );
  const allowedToAddOffline = React.useCallback(
    (row: Pick<IOfflineServiceTypeDto, 'ServiceGroupId'>): boolean => {
      if (!user) return false;
      return (
        user.HasCreateAdminGroupPermissions(row.ServiceGroupId) ||
        user.HasAdminGroupPermissions(row.ServiceGroupId)
      );
    },
    [user],
  );
  const allowedToEditBatch = React.useCallback(
    (row: Pick<IOfflineServiceTypeDto, 'ServiceGroupId' | 'Id'>): boolean => {
      if (!user) return false;
      return (
        user.HasAdminGroupPermissions(row.ServiceGroupId) ||
        user.HasAdminServicePermissions(ServiceType.Offline, row.Id) ||
        user.HasLabTechGroupPermissions(row.ServiceGroupId) ||
        user.HasLabTechServicePermissions(ServiceType.Offline, row.Id)
      );
    },
    [user],
  );
  const allowedroles = [
    Roles.Administrators,
    Roles.EquipmentAdministrator,
    Roles.GroupAdministrators,
    Roles.CreateNewAssetsAdmin,
  ];
  const hasRolePermissions: boolean =
    intersection(roles, allowedroles).length > 0;
  return {
    hasRolePermissions,
    allowedToEdit,
    allowedToAdd,
    allowedToEditOffline,
    allowedToAddOffline,
    allowedToEditBatch,
  };
}
