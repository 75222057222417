import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { pricePeriodActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { translations } from 'locales/translations';
import i18next from 'i18next';
import {
  ConvertModelToInsertEntity,
  ConvertModelToUpdateEntity,
  getDefaultPricePeriod,
  IPriceSheetPeriodDto,
  PricePeriodQueryParams,
  PricePeriodsDetailsState,
  PricePeriodsResponse,
} from './types';
import { PriceSheetApi as api } from 'api/PriceSheetApi';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { PricePeriodDetailsProps } from '..';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { AxiosError } from 'axios';

function* doInitDetails(
  action: PayloadAction<{
    query: PricePeriodQueryParams;
    model?: IPriceSheetPeriodDto;
  }>,
) {
  try {
    let data: IPriceSheetPeriodDto | null = action.payload.model || null;
    let hasError: boolean = false;
    if (data === null) {
      const result = yield call(api.initPeriodDetails, action.payload.query);
      let response = result as PricePeriodsResponse;
      if (response.ErrorMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.ErrorMessages.map(item => {
              return {
                key: 'initPeriodsErr',
                message: item,
                variant: 'error',
              };
            }),
          ),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: 'initPeriodWarn',
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.SuccessMessages.map(item => {
              return {
                key: 'initPeriodSucc',
                message: item,
                variant: 'success',
              };
            }),
          ),
        );
      }
      data = response.data ?? getDefaultPricePeriod();
      hasError = !response.IsValid;
    }
    yield put(
      actions.initDetails_Success({
        hasErrors: hasError,
        data: data,
      }),
    );
  } catch (error: unknown) {
    yield put(actions.initDetails_Error(error));
  }
}
function* doCreatePeriod(action: PayloadAction<PricePeriodsDetailsState>) {
  const httpPayloads: IPriceSheetPeriodDto = ConvertModelToInsertEntity(
    action.payload,
  );
  try {
    const result = yield call(api.insertPricePeriod, httpPayloads);
    let response = result as PricePeriodsResponse;
    let responseErrors = response.ErrorMessages;
    let responseWarnings = response.WarningMessages;
    let responseSuccess = response.SuccessMessages;
    if (responseErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseErrors.map(item => {
            return {
              key: 'periodInsertError',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    } else {
      if (responseSuccess.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: 'periodInsertSuccess',
            message: responseSuccess[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: true,
              itemName: i18next.t(translations.menu_PriceSheetPeriod),
              detailsType: RenderPageType.PricePeriodsDetails,
              detailsTypeProps: {
                useSidePanel: true,
                queryParams: {
                  id: '' + response.Id ?? -1,
                },
                initialPeriod: response.data,
              } as PricePeriodDetailsProps,
            },
            variant: 'success',
          }),
        );
      }
      if (responseWarnings.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            responseWarnings.map(item => {
              return {
                key: 'periodInsertWarning',
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = responseErrors.length > 0;
    yield put(
      actions.createPeriod_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'periodInsert',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createPeriod_Error(Error));
  }
}
function* doUpdatePeriod(
  action: PayloadAction<{
    current: PricePeriodsDetailsState;
    original: PricePeriodsDetailsState;
  }>,
) {
  let updatedPeriod = ConvertModelToUpdateEntity(
    action.payload.current,
    action.payload.original,
  );

  try {
    const results = yield call(
      api.updatePricePeriod,
      updatedPeriod,
      action.payload.current.Id,
    );
    let response = results as PricePeriodsResponse;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'periodUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    } else {
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: 'periodUpdateSuccess',
            message: response.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: false,
              itemName: i18next.t(translations.menu_PriceSheetPeriod),
              detailsType: RenderPageType.PricePeriodsDetails,
              detailsTypeProps: {
                useSidePanel: true,
                queryParams: {
                  id: '' + response.Id ?? -1,
                },
                initialPeriod: response.data,
              } as PricePeriodDetailsProps,
            },
            variant: 'success',
          }),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: 'periodUpdateWarn',
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updatePeriod_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'periodUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updatePeriod_Error(Error));
  }
}

export function* pricePeriodSaga() {
  yield takeLatest(actions.initDetails.type, doInitDetails);
  yield takeLatest(actions.createPeriod.type, doCreatePeriod);
  yield takeLatest(actions.updatePeriod.type, doUpdatePeriod);
}
