import { UserMultiPicker } from 'app/components/pickers/AutocompletePickers/UserMultiPicker';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';

export const UserAssetContactMultiFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<string>[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <UserMultiPicker
      value={props.value as IUserFilterDto[]}
      onChange={handleOnChange}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      size="small"
      ariaLabel={props.label}
      id={props.id}
      urlType={'full'}
      selectArray={['Id', 'Name', 'UserGroupId']}
    />
  );
};
export default UserAssetContactMultiFilter;
