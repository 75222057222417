import { Entity } from 'types/common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectConnectedSettings } from '../slice/selectors';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { AuthenticatedUser } from 'types/AuthenticatedUser';

export interface UserGroupListenerProps<T> {
  userName: Entity<string> | null;
  services: Entity<number>[];
  userGroup: Entity<string> | null | undefined;
  isEdit: boolean;
  setUser: (value: Entity<string> | null) => void;
  setBudget: (value: Entity<number> | null) => void;
  values?: T;
  forceDisabled?: boolean;
  user: AuthenticatedUser | undefined;
}
export function UserGroupListener<T>(props: UserGroupListenerProps<T>) {
  const {
    userName,
    services,
    userGroup,
    isEdit,
    setUser,
    setBudget,
    forceDisabled,
    user,
  } = props;
  const [initilized, setInitilized] = React.useState(!forceDisabled);
  const serviceSettings = useSelector(selectConnectedSettings);
  React.useEffect(() => {
    if (!initilized) {
      if (userGroup !== null) {
        if (
          serviceSettings.budgetVisible &&
          !isEdit &&
          services.length > 0 &&
          userName !== null
        ) {
          if (!serviceSettings.ReservationUserGroupGroupByBudgetUserGroup) {
            if (userGroup?.Id !== (userName as IUserFilterDto)?.UserGroupId) {
              setUser(null);
              if (serviceSettings.budgetVisible && !isEdit) {
                setBudget(null);
              }
            }
          }
        } else if (!serviceSettings.budgetVisible && !isEdit) {
          if (
            !(
              (!!userName?.Id &&
                !!(userName as IUserFilterDto).UserGroupId &&
                (userName as IUserFilterDto).UserGroupId === userGroup?.Id) ||
              (userName?.Id === user?.Id &&
                user?.ActiveUserGroup?.Id === userGroup?.Id)
            )
          ) {
            setUser(null);
          }
        }
      } else {
        if (!serviceSettings.ReservationUserGroupGroupByBudgetUserGroup) {
          setUser(null);
          if (serviceSettings.budgetVisible && !isEdit) {
            setBudget(null);
          }
        }
      }
    }
    setInitilized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup]);

  return null;
}
