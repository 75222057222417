import { DropDown } from 'app/components/DropDown';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { Box, LinearProgress, ListItem, MenuItem } from '@material-ui/core';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import { useTranslation } from 'react-i18next';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { usePromise } from 'app/hooks/usePromise';
import { loadOfflineServiceData } from 'app/components/pickers/AutocompletePickers/OfflineServiceTypePicker';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';

export interface AddServiceProps {
  getServiceTypes?: (
    searchTerm: string | null,
  ) => Promise<IOfflineServiceFilterDto[]>;
  addService: (serviceType: IOfflineServiceFilterDto) => void;
  selected: IOtherServices[];
  disabled?: boolean;
}

export function AddService({
  addService,
  getServiceTypes,
  selected,
  disabled,
}: AddServiceProps) {
  const { t } = useTranslation();

  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);

  const [input, setInnput] = React.useState<string>();
  const fetchServiceTypes = async (): Promise<IOfflineServiceFilterDto[]> => {
    return new Promise(async (resolve, reject) => {
      if (!!getServiceTypes) {
        try {
          const data = await getServiceTypes(input || null);
          resolve(
            data.filter(f => !selected.some(s => s.ServiceTypeID === f.Id)),
          );
        } catch {
          resolve([]);
        }
      } else {
        try {
          const data = await loadOfflineServiceData(
            undefined,
            'base',
            globalServiceGroupFilter || [],
          )(input || null, undefined);
          resolve(
            data.value.filter(
              f => !selected.some(s => s.ServiceTypeID === f.Id),
            ),
          );
        } catch (error) {
          resolve([]);
        }
      }
    });
  };
  const [serviceTypes, setServiceTypes] = useAsyncExtendedState<
    IOfflineServiceFilterDto[]
  >([]);
  const [menuItems, setMenuItems] = React.useState<IOfflineServiceFilterDto[]>(
    serviceTypes,
  );
  const [fetchServiceTypeState, fetchServiceTypePromise] = usePromise(
    fetchServiceTypes,
  );
  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  React.useEffect(() => {
    if (serviceTypes.length === 0) {
      setMenuItems([]);
    } else {
      if (serviceTypes.length > 0) {
        if (input === undefined || input === '') {
          setMenuItems(serviceTypes);
        } else {
          setMenuItems(() =>
            serviceTypes.filter(
              f => f.Name.toLowerCase().indexOf(input.toLowerCase()) > -1,
            ),
          );
        }
      }
    }
  }, [input, serviceTypes]);

  return (
    <DropDown
      variant="white"
      size="small"
      disabled={disabled}
      onOpen={() => setServiceTypes(fetchServiceTypePromise())}
      onHandleClose={() => setInnput(undefined)}
      menuChildren={onClose => [
        <ListItem
          key="lists-search"
          onKeyDown={e => e.stopPropagation()}
          onClickCapture={stopImmediatePropagation}
        >
          <TextControlField
            placeholder={t(translations.SearchFilter)}
            startIcon={
              <Icon icon="search" color="filters" colorExtend="light" />
            }
            value={input || ''}
            size="small"
            aria-label={t(translations.SearchFilter)}
            inputProps={{
              'aria-label': t(translations.SearchFilter),
            }}
            onChange={e => {
              setInnput(e.target.value);
            }}
            autoFocus
            fullWidth
          />
        </ListItem>,
        ...[
          fetchServiceTypeState.status === 'pending'
            ? [
                <ListItem key="loading">
                  <Box width="100%">
                    <LinearProgress variant="query" color="primary" />
                  </Box>
                </ListItem>,
              ]
            : menuItems.length > 0
            ? [
                ...menuItems.map(s => (
                  <MenuItem
                    key={s.Id}
                    onClick={e => {
                      addService(s);
                      onClose();
                    }}
                  >
                    {s.Name}
                  </MenuItem>
                )),
              ]
            : [
                <ListItem key="notfound">
                  <Box width="100%">
                    <BasicTypography display="inline">
                      {t(translations.NotFound)}
                    </BasicTypography>
                  </Box>
                </ListItem>,
              ],
        ],
      ]}
      menuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      addChevron
    >
      {t(translations.AddConsumable)}
    </DropDown>
  );
}
