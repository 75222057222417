import { Link } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { DetectIsMobile } from 'utils/mobileDetect';
import { InvoiceDetails } from '..';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';

export interface InvoiceDetailsSidePanelProps {
  id: number;
}

export const InvoiceDetailsSidePanel = (
  props: InvoiceDetailsSidePanelProps,
) => {
  return <InvoiceDetails id={props.id} isSidePanel={true} />;
};

export const InvoiceDetailsSidePanelLink = ({
  children,
  ...props
}: InvoiceDetailsSidePanelProps & { children: ReactNode }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useLayoutSlice();
  const isMobile = DetectIsMobile();
  const onClick = () => {
    dispatch(
      actions.openSidePanel({
        type: RenderPageType.InvoiceDetails,
        expanded: !isMobile,
        props,
      }),
    );
  };
  return (
    <Tooltip title={t(translations.InvoiceDetails) as string} arrow>
      <Link onClick={onClick}>{children}</Link>
    </Tooltip>
  );
};
