import { GetRawValue } from 'app/components/BasicTable/types/FilterParam';
import { OtherServiceDetailsPageProps } from '.';
import { OfflineServiceQueryStringParameters } from '../Details/slice/types';

export const getParams = (
  params: URLSearchParams,
  props: OtherServiceDetailsPageProps,
): OfflineServiceQueryStringParameters => {
  return {
    id:
      props.match.params['ID'] ||
      (GetRawValue(params, 'ID') ?? undefined) ||
      props.match.params['Id'] ||
      (GetRawValue(params, 'Id') ?? undefined) ||
      props.match.params['id'] ||
      (GetRawValue(params, 'id') ?? undefined),
    ipid:
      props.match.params['ipid'] || (GetRawValue(params, 'ipid') ?? undefined),
    servicedate:
      props.match.params['servicedate'] ||
      (GetRawValue(params, 'servicedate') ?? undefined),

    user:
      props.match.params['user'] || (GetRawValue(params, 'user') ?? undefined),
    bid: props.match.params['bid'] || (GetRawValue(params, 'bid') ?? undefined),
    remarks:
      props.match.params['remarks'] ||
      (GetRawValue(params, 'remarks') ?? undefined),
    be: props.match.params['be'] || (GetRawValue(params, 'be') ?? undefined),
    ecid:
      props.match.params['ecid'] || (GetRawValue(params, 'ecid') ?? undefined),
    po: props.match.params['po'] || (GetRawValue(params, 'po') ?? undefined),
    reference:
      props.match.params['ref'] || (GetRawValue(params, 'ref') ?? undefined),
    factor:
      props.match.params['factor'] ||
      (GetRawValue(params, 'factor') ?? undefined),
    amount:
      props.match.params['amount'] ||
      (GetRawValue(params, 'amount') ?? undefined),
    adg: props.match.params['adg'] || (GetRawValue(params, 'adg') ?? undefined),
    stid:
      props.match.params['stid'] || (GetRawValue(params, 'stid') ?? undefined),
    ReservationId:
      props.match.params['ReservationId'] ||
      (GetRawValue(params, 'ReservationId') ?? undefined),
    UsageId:
      props.match.params['UsageId'] ||
      (GetRawValue(params, 'UsageId') ?? undefined),
    batchId:
      props.match.params['batchId'] ||
      (GetRawValue(params, 'batchId') ?? undefined),
  };
};
