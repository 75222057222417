import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { translations } from 'locales/translations';
import { FormFieldsSection, FormRow } from 'app/components/Forms/FormsLayout';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import {
  ServicesFilter,
  UserPicker,
} from 'app/components/pickers/AutocompletePickers/UserPicker';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { Caption } from 'app/components/Typography';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { quoteODataValue } from 'api/odata/ODataFilter';

export interface TransferReservationProps {
  show: boolean;
  handleTransfer: (value: IUserFilterDto) => void;
  transferAllowedToGroup: boolean;
  services: IServiceTypeFilterDto[];
  adGroup?: string;
  budgetExpirementModuleEnabled?: boolean;
  trnsferSubmitted?: boolean;
  onClose: () => void;
  user?: AuthenticatedUser;
}

export function TransferReservation(props: TransferReservationProps) {
  const {
    show,
    handleTransfer,
    transferAllowedToGroup,
    services,
    adGroup,
    budgetExpirementModuleEnabled,
    trnsferSubmitted,
    user,
    onClose,
  } = props;
  const { t } = useTranslation();
  const [
    trasferToUser,
    setTranferToUser,
  ] = React.useState<IUserFilterDto | null>(null);

  return (
    <DialogConfirm
      isOpen={show}
      title={t(translations.TransferReservation)}
      processing={trnsferSubmitted}
      confirmButtonLabel={t(translations.Transfer)}
      body={
        <React.Fragment>
          <FormFieldsSection
            titleSection={
              <Caption>
                {t(translations.TransferReservation_info) as string}
              </Caption>
            }
          >
            <FormRow fullRow={true}>
              <UserPicker
                name="User"
                variant="filled"
                urlType="full"
                info={t(translations.ReservationUser_Desc)}
                label={t(translations.TransferToUser)}
                disabled={trnsferSubmitted}
                predicates={[
                  ...ServicesFilter(
                    services,
                    budgetExpirementModuleEnabled || false,
                    transferAllowedToGroup ? adGroup : undefined,
                    undefined,
                  ),
                  `(Id ne ${quoteODataValue(user?.Id || '')})`,
                ]}
                onChange={value => setTranferToUser(value)}
                value={trasferToUser}
                error={trasferToUser === null || trasferToUser === undefined}
                helperText={
                  trasferToUser === null || trasferToUser === undefined
                    ? (t(translations.err_UserFieldRequired) as string)
                    : undefined
                }
                fullWidth
                selectArray={['Id', 'Name', 'UserGroups']}
              />
            </FormRow>
          </FormFieldsSection>
        </React.Fragment>
      }
      onConfirm={ev => {
        if (trasferToUser !== null) {
          handleTransfer(trasferToUser);
        }
      }}
      onCancel={() => onClose()}
      onClose={() => onClose()}
      maxWidth="sm"
    />
  );
}
