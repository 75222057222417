import * as React from 'react';
import { FieldHookConfig, useField } from 'formik';
import { SwitchProps } from '@material-ui/core';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { BasicToggle } from '../BasicToggle';

export interface FormSwitchProps extends SwitchProps {
  label?: string;
  helperText?: string;
  boldLebel?: boolean;
  maxLabelWidth?: string | number;
  boxed?: boolean;
}
export const FormSwitch = ({
  label,
  name,
  onBlur,
  onChange,
  boldLebel,
  maxLabelWidth,
  ...props
}: FormSwitchProps & FieldHookConfig<boolean> & IFieldProps) => {
  const [field, meta] = useField<boolean>({ name, ...props });
  const handleChange = (value, event) => {
    if (onChange !== undefined) {
      onChange(event);
    }
    field.onChange(event);
  };
  return (
    <BasicToggle
      error={meta?.error}
      boldLabel={boldLebel}
      label={label}
      info={props.info}
      name={name}
      onChange={handleChange}
      onBlur={field?.onBlur}
      checked={field.value ?? false}
      maxLabelWidth={maxLabelWidth}
      boxed={props.boxed}
      size={props.size}
      {...props}
    />
  );
};
