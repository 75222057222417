import { FieldHookConfig, useField } from 'formik';
import * as React from 'react';
import { EntityType } from 'types/common';
import { AutocompletePicker } from '../BasicPickers/AutocompletePicker';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export interface FormAutocompleteProps<T extends EntityType> {
  loadData: (searchTerm: string | null) => Promise<T[]>;
  initValue?: T | null;
  size?: 'medium' | 'small';
  mini?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
}
export function FormAutocomplete<T extends EntityType>({
  label,
  loadData,
  id,
  initValue,
  variant,
  ...props
}: FieldHookConfig<T | null> & FormAutocompleteProps<T> & IFieldProps) {
  const [field, meta, helpers] = useField<T | null>(props);
  const handleChange = (e: T | null) => {
    helpers.setValue(e, true);
    if (props.onChange !== undefined) {
      props.onChange(e);
    }
    //field.onChange(e);
  };
  return (
    <AutocompletePicker
      loadData={loadData}
      defaultValue={initValue}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      label={label}
      placeholder={props.placeholder}
      name={props.name}
      onChange={handleChange}
      onBlur={field?.onBlur}
      value={field.value ?? undefined}
      error={meta?.error !== undefined}
      variant={variant}
      helperText={meta.error || props.helperText}
      id={id}
      size={props.size}
    />
  );
}
