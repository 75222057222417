/**
 *
 * AssetPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity } from 'types/common';
import { avTypes, getAvFilter } from '../../MultiSelectPickers/AssetsPicker';

export interface AssetPickerProps
  extends Omit<AutocompletePickerProps<IAssetDto>, 'loadData'> {
  restrictByAvType?: avTypes;
  /**
   * Option to filter admin permissions. If set to true will return only the assets authenticated user is an admin of
   */
  admin?: boolean;
  /**
   * Fetches only Active assets/instruments if this property is set to true
   */
  showOnlyActiveAssets?: boolean;
}

const url = '/api/odata/v4/AssetNames';
export const initAssetData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};

const loadData = (
  predicates: (string | Condition<IAssetDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  admin?: boolean,
) => {
  const url =
    admin === true
      ? '/api/odata/v4/AssetNames/AdminAssets'
      : '/api/odata/v4/AssetNames';
  return getAutoCompleteLoadDataFn<IAssetDto>({
    url: url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'AssetCatId',
      'Availability',
      // BookableAsset is not mapped (AssetNamesController)
      //      'BookableAsset',
      'Image',
      'ServiceGroupId',
      'ServiceId',
      'ServiceTypeId',
      'ServiceGroupName',
      'ServiceGroupAssetId',
    ],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<IAssetDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });
};

export function AssetPicker({
  predicates,
  restrictByAvType,
  showOnlyActiveAssets,
  admin,
  ...props
}: AssetPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  let avFilter =
    restrictByAvType === undefined ? undefined : getAvFilter(restrictByAvType);

  let filters =
    avFilter !== undefined
      ? predicates !== undefined
        ? [...predicates, avFilter]
        : [avFilter]
      : predicates;
  if (showOnlyActiveAssets === true) {
    filters?.push(
      new Condition<IAssetDto>('Active', ODataOperators.Equals, true),
    );
  }
  const innerLoadData = loadData(
    filters,
    globalServiceGroupFilter || [],
    admin,
  );

  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      ariaLabel="Asset Filter"
      id={props.id || 'AssetsId'}
      loadData={innerLoadData}
      {...props}
    />
  );
}
