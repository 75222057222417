/**
 *
 * DatePeriods
 *
 */
import { Select, MenuItem, FormControl } from '@material-ui/core';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import StandardInput from '../BasicInputs/BaseInputs/StandardInput';
import { getPeriods } from '../pickers/DatePeriodsPicker';

/**
 * The order of the values in this array does matter for backwards compatibility since there periods type picker used Integer Ids stored in a duplicate array and these might be saved in the saved query string filters for some users.
 *
 */
export const ALL_PERIOD_TYPES = [
  'CustomDate',
  'Today',
  'TodayTomorrow',
  'ThisWeek',
  'NextWeek',
  'ThisMonth',
  'NextMonth',
  'ThisQuarter',
  'NextQuarter',
  'ThisYear',
  'NextYear',
  'LastWeek',
  'LastMonth',
  'LastQuarter',
  'LastYear',
  'Tomorrow',
  'Yesterday',
] as const;
type PeriodTypesTyple = typeof ALL_PERIOD_TYPES;
export type PeriodTypes = PeriodTypesTyple[number];
export interface PeriodTypesEntity extends Entity<PeriodTypes> {}
export const getPeriodTypesEntity = (
  valueType: PeriodTypes,
): PeriodTypesEntity => {
  return getPeriods()
    .map(item => {
      return {
        Id: item.Id,
        Name: translations[item.Name],
        type: item.Name as PeriodTypes,
      };
    })
    .filter(value => {
      return value.type === valueType;
    })[0];
};
export const getPeriodTypesEntityById = (
  id: PeriodTypes | number | undefined,
): PeriodTypesEntity | undefined => {
  if (id === undefined) {
    return undefined;
  }
  const periodType: PeriodTypes | undefined =
    typeof id === 'number' ? ALL_PERIOD_TYPES[id] : id;

  return getPeriods()
    .map(item => {
      return {
        Id: item.Id,
        Name: translations[item.Name],
        type: item.Name as PeriodTypes,
      };
    })
    .filter(value => {
      return value.Id === periodType;
    })[0];
};
export interface DatePeriodsProps {
  value: PeriodTypes;
  onChange: (value: PeriodTypes) => void;
  size?: 'medium' | 'small' | undefined;
}

export function DatePeriods(props: DatePeriodsProps) {
  const { t } = useTranslation();
  return (
    <FormControl variant="filled">
      <Select
        input={<StandardInput margin="dense" />}
        value={props.value}
        onChange={e => props.onChange(e.target.value as PeriodTypes)}
      >
        {ALL_PERIOD_TYPES.map(key => (
          <MenuItem key={key} value={key}>
            {t(key)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
