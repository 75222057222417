import { httpClient } from 'api/HttpClient';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { BaseURLPicker } from 'app/components/BasicPickers/BaseURLPicker';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

const url = '/api/odata/v4/InstrumentCatFilter';
export const initInstrumentCatData = (initval: string | undefined) => {
  if (initval === undefined) {
    return Promise.resolve([]);
  }
  const params: {
    $orderby: string;
    $filter?: string;
    $select: string;
    $top: number;
    $skip: number;
  } = {
    $orderby: 'Name asc',
    $filter: new Condition<Entity<string>>(
      'Id',
      ODataOperators.Equals,
      initval,
    ).toString(),
    $select: 'Id,Name',
    $skip: 0,
    $top: 1,
  };
  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<string>[]);
};

export const InstrumentCatFilter = (props: IFilterComponentProps) => {
  const handleChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  return (
    <BaseURLPicker
      value={props.value}
      size="small"
      onChange={handleChange}
      url={url}
    />
  );
};
