import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import React from 'react';
import {
  RowActionsMenu,
  RowActionsProps,
} from 'app/components/BasicTable/RowActions';
import { CellRenderer } from 'app/components/BasicTable/BasicTableProps';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';
import { Row } from 'react-table';
import { useOpenAssetPopUp } from 'app/pages/AssetPopUp';

export function GetDetailsRowActions(
  t: TFunction,
  isRowSelectable: (row: Row<ICoresDirectoryRow>) => boolean,
): CellRenderer<ICoresDirectoryRow>[] {
  const openAsset = useOpenAssetPopUp();
  const rowActions: CellRenderer<ICoresDirectoryRow>[] = React.useMemo<
    CellRenderer<ICoresDirectoryRow>[]
  >(
    () => [
      ({ row }) => {
        const items: RowActionsProps[] = [];
        if (isRowSelectable(row)) {
          items.push({
            onClick: () => {
              openAsset({ Id: row.original.AssetId ?? undefined });
            },
            text: t(translations.CoreDetails) as string,
            icon: 'list',
          } as RowActionsProps);
          return (
            <RowActionsMenu
              id={`coreSelection_${row.original.Id}`}
              items={items}
            />
          );
        } else {
          return [];
        }
      },
    ],
    [isRowSelectable, openAsset, t],
  );
  return rowActions;
}
