import * as React from 'react';
import { CircularProgress } from '@material-ui/core';

export interface ProgressProps {
  inProgress: boolean;
  size: number;
}
export function Progress({ inProgress, size }: ProgressProps) {
  return (
    <React.Fragment>
      {inProgress ? (
        <CircularProgress
          color="inherit"
          size={size}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 1,
            marginTop: -size / 2,
            marginLeft: -size / 2,
          }}
        />
      ) : null}
    </React.Fragment>
  );
}
