import { Skeleton } from '@material-ui/lab';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';

export interface CustomFormSkeletonProps {
  formFields: IFormFieldDto[];
}
export function CustomFormSkeleton(props: CustomFormSkeletonProps) {
  // TODO: add skeleton based on the formfields definition
  return <Skeleton />;
}
