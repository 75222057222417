import {
  call,
  put,
  takeLatest,
  debounce,
  select,
  takeLeading,
} from 'redux-saga/effects';
import { samplesListActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { httpClient } from 'api/HttpClient';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  IRunDetails,
  ISample,
  ISampleListTableState,
  ISampleRun,
  ISampleRunUpdate,
  ISampleTarget,
} from './types';
import { push } from 'connected-react-router';
import { TableFilterBuilder } from 'app/components/BasicTable/TableFilterBuilder';
import { AxiosError } from 'axios';
import * as Papa from 'papaparse';
import { Identifiable } from 'types/common';
import { selectRunDetails, selectSamplesTableState } from './selectors';
import { RunTypesUnion } from '../../components/RunTypePicker';

import { getExportParameters, buildURL } from 'utils/url-utils';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { selectSamplePlatesTableState } from '../../SamplePlatesPage/slice/selectors';
import { deleteSamples, IDeleteSamplesResult } from 'api/SamplesApi';
import { exportCsv } from 'utils/export';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { ISampleCreateBase } from '../../SamplePlatesPage/slice/types';
import { ISamplePlateDto } from 'api/odata/generated/entities/ISamplePlateDto';
import { IRow } from 'app/components/BasicTable/IRow';
import { BasicTableRefreshEventHandler } from 'app/components/BasicTable/ControlledTable/ControlledTableProps';
import { openExportLink } from 'utils/url-utils';

interface ODataParams {
  $top: number | undefined;
  $skip: number | undefined;
  $count?: boolean;
  $orderby?: string;
  $filter?: string;
  $select?: string;
}
export function getLoadDataParams(
  currentTableState: Partial<ISampleListTableState>,
): ODataParams {
  const params = {
    $count: true,
    $top: currentTableState.pageSize,
    $skip:
      (currentTableState.pageIndex ?? 1) * (currentTableState.pageSize ?? 5),
    $orderby:
      currentTableState.sortBy
        ?.map(f => `${f.id} ${f.desc ?? false ? 'desc' : 'asc'}`)
        .join(',') || undefined,
    //$select: ,
    $filter: TableFilterBuilder(
      currentTableState.customFilters as Array<IFilterSettings<ISample>>,
      currentTableState.globalFilter,
      currentTableState.serviceGroups,
      currentTableState.searchColumns,
    ),
    $select:
      currentTableState.visibleColumns === undefined
        ? undefined
        : currentTableState.visibleColumns.join(','),
  };
  return params;
}
async function loadData(currentTableState) {
  const url = '/api/odata/v4/samples';
  const params = getLoadDataParams(currentTableState);
  const result = await httpClient.get(url, params);
  return result;
}
function* doExport(action: PayloadAction<ISampleListTableState>) {
  try {
    const tableState = yield select(selectSamplesTableState);
    const tableParams = getLoadDataParams({
      ...tableState,
      ...{ visibleColumns: action.payload.visibleColumns },
    });
    const entitySetURl = '/api/odata/v4/samples';
    var params = getExportParameters(tableParams);
    const url = buildURL(entitySetURl, params);
    yield call(openExportLink, url);
  } catch (error) {
    console.error('doExport failed', error);
  }
}
async function loadIds(currentTableState) {
  const url = '/api/odata/v4/samples';
  const params = getLoadDataParams(currentTableState);
  params.$top = undefined;
  params.$skip = undefined;
  params.$count = undefined;
  params.$orderby = undefined;
  params.$select = 'Id';
  const result = await httpClient.get(url, params);
  return result;
}
function* doLoadData(
  action: PayloadAction<{
    tableState: ISampleListTableState;
    columns: (keyof ISample)[] | undefined;
  }>,
) {
  try {
    const data = yield call(loadData, action.payload);
    yield put(actions.loadSamplesList_Success(data));
  } catch (error) {
    console.error(error);
  }
}
function* doUpdateField(action: PayloadAction<Partial<ISample>>) {
  const url = `/api/odata/v4/samples(${action.payload.Id})`;
  const payload: Partial<ISample> = { ...action.payload };
  delete payload.Id;
  try {
    yield call(httpClient.patch, url, payload);
    yield put(actions.updateField_Success());
  } catch (error) {
    const msg = (error as AxiosError)?.response?.data?.error?.innererror
      ?.message;
    if (msg !== undefined) {
      yield put(
        appSettingsActions.addNotification({
          key: 'SamplesListPage.saga.updateField',
          variant: 'error',
          message: msg,
        }),
      );
    }
    yield put(actions.updateField_Error());
  }
}
function* doRunSelectedSamples(
  action: PayloadAction<{ runType?: string; samples: ISample[] | undefined }>,
) {
  try {
    const httpUrl = '/api/odata/v4/sampleruns';
    let samples = action.payload.samples;
    if (samples === undefined) {
      const tableState = yield select(selectSamplesTableState);
      const allsamples = yield call(loadIds, tableState);
      samples = allsamples.value;
    }
    if (samples === undefined) {
      return;
    }
    const httpPayload = {
      RunType: action.payload.runType,
      Samples: samples.map(f => ({
        Id: f.Id,
      })),
    };
    const response = yield call(httpClient.post, httpUrl, httpPayload);
    yield put(push(`/samples/runs/details/${response.Id}`));
  } catch (error: unknown) {
    console.error(error);
  }
}
function* doCompleteRun(action: PayloadAction<IRunDetails>) {
  try {
    const url = `/api/odata/v4/sampleruns(${action.payload.Id})`;
    // note that api call does not return dto but rater a custom summary of the operation
    const completeRunResponse: {
      derivativesCount: number;
      skippedSamplesCount: number;
    } = yield call(httpClient.put, url, action.payload);
    yield put(actions.completeRun_Success);
    const msg = [
      i18next.t(translations.XSamplesWereProcessed, completeRunResponse),
    ];
    if (completeRunResponse.skippedSamplesCount > 0) {
      msg.push(
        i18next.t(translations.XSamplesWereSkipped, completeRunResponse),
      );
    }
    yield put(
      appSettingsActions.addNotification({
        message: msg.join(' '),
        variant:
          completeRunResponse.skippedSamplesCount === 0 &&
          completeRunResponse.derivativesCount > 0
            ? 'success'
            : completeRunResponse.skippedSamplesCount > 0 &&
              completeRunResponse.derivativesCount > 0
            ? 'warning'
            : 'error',
      }),
    );
  } catch (error: unknown) {
    const msg =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      'Operation Failed';
    yield put(
      appSettingsActions.addNotification({
        key: 'SamplesListPage.saga.updateField',
        variant: 'error',
        message: msg,
      }),
    );
  }
}
function* doUpdateRun(action: PayloadAction<ISampleRunUpdate>) {
  try {
    const url = '/api/odata/v4/SampleRuns/SampleRunUpdate';
    const data = {
      runupdate: action.payload,
    };
    const result = yield call(httpClient.post, url, data);
    yield put(actions.updateRun_Success(result));
    yield put(
      appSettingsActions.addNotification({
        message: 'success',
        variant: 'success',
      }),
    );
  } catch (error: unknown) {
    const msg =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      'Operation Failed';
    yield put(
      appSettingsActions.addNotification({
        key: 'SamplesListPage.saga.updateField',
        variant: 'error',
        message: msg,
      }),
    );
  }
}
function* doLoadRunDetqails<TRow extends IRow>(
  action: PayloadAction<{ id: number; columns: Array<keyof TRow> | undefined }>,
) {
  try {
    const url = `/api/odata/v4/sampleruns(${action.payload.id})`;
    const runDetails: IRunDetails = yield call(httpClient.get, url);

    const samples_url = `/api/odata/v4/sampleruns(${action.payload.id})/Samples`;
    const samples_response = yield call(httpClient.get, samples_url);
    runDetails.Samples = samples_response.value;
    yield put(actions.loadRunDetqails_Success(runDetails));
  } catch (error: unknown) {
    console.error(error);
    yield put(actions.completeRun_Success);
  }
}
/**
 * Reads a csv file and returns parse results
 * todo: move to shared utils location
 * @param file File
 * @returns parse results
 */
function readCsvFile(file: File): Promise<Papa.ParseResult<unknown>> {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,

      complete: results => {
        resolve(results);
      },
      error: error => {
        reject(error);
      },
    });
  });
}
const platesUrl = `/api/odata/v4/SamplePlates`;
async function createPlate(samplePlateId: string, plate: ISampleCreateBase) {
  const httpPayload: Partial<ISamplePlateDto> = {
    Id: samplePlateId,
    RoomId: plate.Room?.Id ?? null,
    LocationId: plate.Location?.Id ?? null,
    SamplePlateTypeId: plate.SamplePlateType?.Id,
    ServiceGroupId: plate.ServiceGroup?.Id ?? null,
    Active: plate.Active ?? true,
    IsRack: plate.IsRack ?? false,
  };
  try {
    return await httpClient.post(platesUrl, httpPayload);
  } catch (error: unknown) {}
}
function createPlates(
  runSamples: (Identifiable<number> & ISampleTarget)[],
  plate: ISampleCreateBase,
) {
  return new Promise(async (resolve, reject) => {
    try {
      for (const record of runSamples) {
        const tpid = record.TargetPlateId;
        if (tpid !== undefined && tpid !== null && tpid !== '') {
          const params: {
            $filter: string;
          } = {
            // eslint-disable-next-line no-useless-concat
            $filter: '(Id eq ' + "'" + tpid + "'" + ')',
          };
          try {
            const count = await httpClient.get(`${platesUrl}/$count`, params);
            if (count === 0) {
              await createPlate(tpid, plate);
            }
          } catch {}
        }
      }
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}

function* doImportRunDetails(
  action: PayloadAction<{ file: File; plate: ISampleCreateBase | null }>,
) {
  try {
    const file = action.payload.file;
    const result: Papa.ParseResult<Record<string, any>> = yield call(
      readCsvFile,
      file,
    );
    type t = Identifiable<number> & ISampleTarget;
    const records = result.data
      .filter(record => record['Id'] !== null)
      .map(
        record =>
          ({
            Id: record['Id'] as number,
            TargetPlateId: record['target sample plate id']?.toString()?.trim(),
            TargetPosition: record['target sample plate position']
              ?.toString()
              ?.trim(),
            TargetConcentration: record['target sample concentration (mg/mL)'],
            TargetVolume: record['target sample volume (ul)'],
            TargetTubeLabel: record['target tube id']?.toString()?.trim(),
            TargetSampleType: record['target sample type']?.toString()?.trim(),
          } as t),
      );
    const runDetails: IRunDetails = yield select(selectRunDetails);

    const runSamples = records
      .filter(record =>
        runDetails.Samples.find(sample => sample.Id === record.Id),
      )
      .map(record => {
        const result: Partial<ISampleTarget> = { Id: record.Id };
        const runType = runDetails.RunType;
        const positionImport: RunTypesUnion[] = [
          'Fractioning',
          'Labeling',
          'StandardRun',
        ];
        if (positionImport.includes(runType)) {
          result.TargetPlateId = record?.TargetPlateId;
          result.TargetPosition = record?.TargetPosition;
          result.TargetTubeLabel = record?.TargetTubeLabel;
          result.TargetSampleType = record?.TargetSampleType;
        }
        const volumeImport: RunTypesUnion[] = [
          'Fractioning',
          'Labeling',
          'MassSpec',
        ];
        if (volumeImport.includes(runType)) {
          result.TargetVolume = record.TargetVolume;
          result.TargetConcentration = record.TargetConcentration;
        }
        return record;
      });

    // Checking and creating missing Plates
    if (action.payload.plate !== null) {
      yield call(createPlates, runSamples, action.payload.plate);
    }
    yield put(
      appSettingsActions.addNotification({
        key: 'runsamples_import',
        variant: records.length === runSamples.length ? 'success' : 'warning',
        message:
          'Import finished: Matching sample IDs were updated with the related target plate ID and target plate positions as per the file. Violations for samples already existing in the target plate position will be done on clicking Complete',
      }),
    );
    yield put(actions.readImportFile_Success(runSamples));
  } catch (error) {
    console.error(error);
  }
}

function* doLoadAllSelectedSamples(
  action: PayloadAction<ISample[] | undefined>,
) {
  try {
    const tablestate = yield select(selectSamplesTableState);
    const foo = yield call(loadIds, tablestate);
    yield put(actions.loadAllFilteredSamples_Success(foo));
  } catch {}
}

function* doExportRun(action: PayloadAction<ISampleRun[]>) {
  try {
    const fields = [
      'Id',
      'analytical sample id',
      'TMT Label',
      'TMT Run Set',
      'source sample type',
      'source sample plate id',
      'source sample plate position',
      'source tube id',
      'source sample volume (ul)',
      'source sample concentration (mg/mL)',
      'target sample type',
      'target sample plate id',
      'target sample plate position',
      'target tube id',
      'target sample volume (ul)',
      'target sample concentration (mg/mL)',
    ];
    const data = action.payload.map(item => [
      item.Id,
      item.AnalyticalSampleId,
      item.TMT_Label,
      item.TMT_Set,
      item.TMT_Type,
      item.SamplePlateId,
      item.Position,
      item.TubeLabel,
      item.Volume,
      item.Concentration,
      item.TargetSampleType,
      item.TargetPlateId,
      item.TargetPosition,
      item.TargetTubeLabel,
      item.TargetVolume,
      item.TargetConcentration,
    ]);

    yield call(() =>
      exportCsv({
        fields: fields,
        data: data,
        fileName: 'file.csv',
      }),
    );
  } catch (error) {
    console.error(error);
  }
}

function* doDeleteSamples(
  action: PayloadAction<{
    selectedRows: ISample[];
    refresh: BasicTableRefreshEventHandler | undefined;
  }>,
) {
  let r: IDeleteSamplesResult = yield call(
    deleteSamples,
    action.payload.selectedRows,
  );
  action.payload.refresh?.();
  for (const error of r.errors) {
    yield put(
      appSettingsActions.addNotification({
        variant: 'error',
        message: error.message,
      }),
    );
  }
  if (r.successCount > 0) {
    // reload table data
    const tableState = yield select(selectSamplePlatesTableState);
    yield put(actions.loadSamplesList(tableState));

    // post success notification of deleted records
    yield put(
      appSettingsActions.addNotification({
        variant: 'success',
        message: i18next.t(translations.XRecordsWereBeDeleted, {
          x: r.successCount,
        }),
      }),
    );
  }
}

export function* samplesListSaga() {
  yield debounce(500, actions.loadSamplesList.type, doLoadData);
  yield debounce(500, actions.updateField.type, doUpdateField);
  yield takeLatest(actions.runSelectedSamples.type, doRunSelectedSamples);
  yield takeLatest(actions.completeRun.type, doCompleteRun);
  yield takeLatest(actions.updateRun.type, doUpdateRun);
  yield takeLatest(actions.loadRunDetqails.type, doLoadRunDetqails);
  yield takeLatest(actions.readImportFile.type, doImportRunDetails);
  yield takeLatest(
    actions.loadAllFilteredSamples.type,
    doLoadAllSelectedSamples,
  );
  yield takeLatest(actions.exportRun.type, doExportRun);
  yield takeLeading(actions.deleteSamples.type, doDeleteSamples);
  yield takeLatest(actions.export.type, doExport);
}
