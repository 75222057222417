import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectGlobalServiceGroupFilter,
  selectShowGlobalServiceGroupFilter,
  selectShowGlobalServiceGroupsAsList,
} from 'app/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { CoreSelectionProps } from 'app/pages/CoreSelectionPage/CoreSelection';
import TopGroupButton from '../TopGroupButton';
import { Icon } from '../../BasicIcons/FontAwesome';
import { CoreDashboardMenu } from '../CoreDashboardButton';
import useExpandedSideBarState from 'app/hooks/useExpandedSideBarState';
import { DetectIsMobile } from 'utils/mobileDetect';

export interface CoreSelectionButtonProps {}
export function CoreSelectionButton(props) {
  const dispatch = useDispatch();
  const { actions: layoutActions } = useLayoutSlice();
  const savedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const showGroups = useSelector(selectShowGlobalServiceGroupFilter);
  const showInlineGroups = useSelector(selectShowGlobalServiceGroupsAsList);

  const { expandLocal } = useExpandedSideBarState();
  const isMobile = DetectIsMobile();
  const [show, setShow] = React.useState<boolean>(false);
  React.useEffect(() => {
    setShow(showGroups);
  }, [showGroups]);

  const handleClick = event => {
    dispatch(
      layoutActions.openSidePanel({
        type: RenderPageType.CoreSelection,
        props: { useSidePanel: true } as CoreSelectionProps,
        expanded: !isMobile,
      }),
    );
    expandLocal(false);
  };

  const firstServiceGroupName = React.useMemo(() => {
    return savedServiceGroups !== undefined && savedServiceGroups?.length > 0
      ? savedServiceGroups[0]?.Name
      : undefined;
  }, [savedServiceGroups]);

  return show ? (
    <TopGroupButton
      savedServiceGroups={savedServiceGroups}
      firstServiceGroupName={firstServiceGroupName}
      startIcon={
        <Icon
          icon="layer-group"
          color={
            savedServiceGroups !== undefined && savedServiceGroups?.length > 0
              ? 'primary'
              : 'black'
          }
        />
      }
      endIcon={<Icon icon={'chevron-down'} />}
      onClick={handleClick}
    />
  ) : showInlineGroups ? (
    <CoreDashboardMenu />
  ) : null;
}
