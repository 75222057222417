/**
 *
 * Asynchronously loads the component for UserTrainingPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UserTrainingPage = lazyLoad(
  () => import('./index'),
  module => module.UserTrainingPage,
);
