import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import { Button } from 'app/components/BasicButtons/Button';
import { FormNumberField } from 'app/components/Forms/FormNumberField';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import {
  FormRightSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { Formik } from 'formik';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import * as Yup from 'yup';
import { IServiceRequestMilestoneChargeForm } from '../../RequestSamplesPage/slice/types';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IServiceRequestMilestoneChargeDto } from 'api/odata/generated/entities/IServiceRequestMilestoneChargeDto';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { dateUtils } from 'utils/date-utils';
import { FormUnitTypeTypePicker } from 'app/components/Forms/FormUnitTypePicker';
import { selectServiceRequestMilestoneProcessing } from '../../RequestSamplesPage/slice/selectors';
import { FormServiceRequestMilestoneChargeServicePicker } from 'app/components/Forms/FormServiceRequestMilestoneChargeServicePicker';
import { BaseToggleButtonGroup } from 'app/components/BasicButtons/BaseToggleButtonGroup';
import { FormBookitDatePicker } from 'app/components/Forms/FormBookitDatePicker';

export type ServiceRequestMilestoneChargeType = 'service' | 'charge';
export interface ServiceRequestMilestoneChargeDetailsProps {
  serviceRequestMilestoneId: number;
  serviceRequestId: number;
  type: ServiceRequestMilestoneChargeType;
  enableAddonCharge: boolean;
  enableManualCharge: boolean;
  value?: IServiceRequestMilestoneChargeDto;
}
export function ServiceRequestMilestoneChargeDetails(
  props: ServiceRequestMilestoneChargeDetailsProps,
) {
  const { t } = useTranslation();
  const submitRef = React.useRef<(() => Promise<void>) | undefined>();
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();
  const processing = useSelector(selectServiceRequestMilestoneProcessing);
  const [selectedType, setSelectedType] = React.useState(props.type);
  const formType =
    props.value?.Id !== undefined && props.value.Id > 0 ? 'Update' : 'Insert';
  const title =
    formType === 'Insert'
      ? selectedType === 'charge'
        ? t(translations.AddCharge)
        : t(translations.AddService)
      : selectedType === 'charge'
      ? t(translations.Charge)
      : t(translations.Service);

  const insertCharge = React.useCallback(
    (value: IServiceRequestMilestoneChargeForm) => {
      if (value.Type === 'service' && value.Service === undefined) {
        return;
      }

      switch (value.Type) {
        case 'service':
          dispatch(
            actions.insertMilestoneCharge({
              OfflineServiceType: value.Service, // value.Service?.Id,
              Quantity: value.Quantity,
              Remarks: value.Remarks,
              ServiceRequestId: props.serviceRequestId,
              ServiceRequestMilestoneId: props.serviceRequestMilestoneId,
              Date:
                value.Date === null
                  ? undefined
                  : value.Date === undefined
                  ? undefined
                  : dateUtils.formatISO(value.Date),
            }),
          );
          break;
        case 'charge':
          dispatch(
            actions.insertMilestoneCharge({
              Quantity: value.Quantity,
              Rate: value.Rate,
              Remarks: value.Remarks,
              ServiceRequestId: props.serviceRequestId,
              ServiceRequestMilestoneId: props.serviceRequestMilestoneId,
              Date:
                value.Date === null
                  ? undefined
                  : value.Date === undefined
                  ? undefined
                  : dateUtils.formatISO(value.Date),
              // Id: props.value?.Id,
              Name: value.Name,
              Unit: value.Unit,
            }),
          );
          break;
      }
    },

    [
      actions,
      dispatch,
      props.serviceRequestId,
      props.serviceRequestMilestoneId,
    ],
  );
  const updateCharge = React.useCallback(
    (value: IServiceRequestMilestoneChargeForm) => {
      if (props.value?.Id === undefined) {
        return;
      }
      switch (value.Type) {
        case 'service':
          dispatch(
            actions.updateMilestoneCharge({
              Id: props.value?.Id,
              OfflineServiceType: value.Service, // value.Service?.Id,
              Quantity: value.Quantity,
              Remarks: value.Remarks,
              ServiceRequestId: props.serviceRequestId,
              ServiceRequestMilestoneId: props.serviceRequestMilestoneId,
              Date:
                value.Date === null
                  ? undefined
                  : value.Date === undefined
                  ? undefined
                  : dateUtils.formatISO(value.Date),
            }),
          );
          break;
        case 'charge':
          dispatch(
            actions.updateMilestoneCharge({
              Id: props.value?.Id,
              Quantity: value.Quantity,
              Rate: value.Rate,
              Remarks: value.Remarks,
              ServiceRequestId: props.serviceRequestId,
              ServiceRequestMilestoneId: props.serviceRequestMilestoneId,
              Date:
                value.Date === null
                  ? undefined
                  : value.Date === undefined
                  ? undefined
                  : dateUtils.formatISO(value.Date),
              // Id: props.value?.Id,
              Name: value.Name,
              Unit: value.Unit,
            }),
          );
          break;
      }
    },

    [
      actions,
      dispatch,
      props.serviceRequestId,
      props.serviceRequestMilestoneId,
      props.value?.Id,
    ],
  );

  const handleSubmit = React.useCallback(
    (values: IServiceRequestMilestoneChargeForm) => {
      switch (formType) {
        case 'Insert':
          insertCharge(values);
          break;
        case 'Update':
          updateCharge(values);
          break;
      }
    },
    [formType, insertCharge, updateCharge],
  );

  const initialValues = toInitialValues(props.type, props.value);

  return (
    <PageWrapper
      useSidePanel
      closable
      disableExpandToggle
      topProcessing={processing}
      isCover={true}
      pageName={title}
      titlePage={title}
      leftActions={[
        () => (
          <Button
            onClick={() => {
              submitRef.current?.();
            }}
            size="small"
            processing={processing}
            startIcon={<Icon icon="save" />}
          >
            {t(translations.Submit)}
          </Button>
        ),
      ]}
      closeSidePanel={() => dispatch(actions.closeSidePanel)}
      rightActions={[
        () => (
          <Button
            onClick={() => dispatch(actions.closeSidePanel())}
            size="small"
            startIcon={<Icon icon="times" />}
          >
            {t(translations.Cancel)}
          </Button>
        ),
      ]}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={addServiceSchema}
        onSubmit={handleSubmit}
        handle
      >
        {formik => {
          submitRef.current = formik.submitForm;
          if (!formik.isValid) {
            console.debug('form errors', formik.errors);
          }

          return (
            <StyledForm>
              <FormRightSection>
                {formType === 'Insert' &&
                  props.enableAddonCharge &&
                  props.enableManualCharge && (
                    <FormRow>
                      <ChargeTypeToggle
                        value={formik.values.Type ?? 'charge'}
                        onChange={value => {
                          formik.setFieldValue('Type', value);
                          setSelectedType(value);
                        }}
                      />
                    </FormRow>
                  )}
                {formik.values.Type === 'charge' && (
                  <FormRow fullRow>
                    <FormTextField
                      name="Name"
                      id="Name"
                      label={t(translations.Name)}
                      fullWidth
                      autoFocus
                    />
                  </FormRow>
                )}
                {formik.values.Type === 'charge' && (
                  <FormRow fullRow>
                    <FormUnitTypeTypePicker
                      name="Unit"
                      id="Unit"
                      label={t(translations.Unit)}
                      $select={['Id', 'Name']}
                      fullWidth
                    />
                  </FormRow>
                )}
                <FormRow fullRow>
                  <FormBookitDatePicker
                    name="srDate"
                    id="srDate"
                    label={t(translations.Date)}
                    fullWidth
                    autoFocus={formik.values.Type !== 'charge'}
                    onChange={value => {
                      formik.setFieldValue('Date', value);
                    }}
                  />
                </FormRow>
                {/* todo: filter out the consumable services */}
                {formik.values.Type === 'service' && (
                  <FormRow fullRow>
                    <FormServiceRequestMilestoneChargeServicePicker
                      serviceRequestId={props.serviceRequestId}
                      name="Service"
                      id="Service"
                      label={t(translations.Service)}
                      fullWidth
                    />
                  </FormRow>
                )}
                {formik.values.Type === 'charge' && (
                  <FormRow>
                    <FormNumberField
                      name="Rate"
                      id="Rate"
                      label={t(translations.Rate)}
                      min={0.01}
                      max={999999}
                      step={0.01}
                      fullWidth
                    />
                  </FormRow>
                )}
                <FormRow>
                  <FormNumberField
                    name="Quantity"
                    id="Quantity"
                    label={t(translations.Quantity)}
                    min={0.01}
                    max={999999}
                    step={0.01}
                    fullWidth
                  />
                </FormRow>
                <FormRichTextField
                  name="Remarks"
                  id="Remarks"
                  label={t(translations.Remarks)}
                  fullWidth
                />
              </FormRightSection>
            </StyledForm>
          );
        }}
      </Formik>
    </PageWrapper>
  );
}

const addServiceSchema: Yup.SchemaOf<IServiceRequestMilestoneChargeForm> = Yup.object(
  {
    Id: Yup.number().optional(),
    Date: Yup.date().optional().nullable(),
    Unit: Yup.mixed().when('Type', {
      is: 'charge',
      then: Yup.mixed().required(),
    }),
    Service: Yup.mixed().when('Type', {
      is: 'service',
      then: Yup.mixed().required(),
    }),
    Name: Yup.string().when('Type', {
      is: 'charge',
      then: Yup.string().required(),
    }),
    Rate: Yup.number().when('Type', {
      is: 'charge',
      then: Yup.number().required(),
    }),
    Quantity: Yup.number().positive().required(),
    Type: Yup.string().oneOf(['charge', 'service']).required() as Yup.SchemaOf<
      ServiceRequestMilestoneChargeType
    >,
    Remarks: Yup.mixed().optional(),
  },
);

interface ChargeTypeToggleProps {
  value: ServiceRequestMilestoneChargeType;
  onChange?: (value: ServiceRequestMilestoneChargeType) => void;
}
function ChargeTypeToggle(props: ChargeTypeToggleProps) {
  const { t } = useTranslation();
  const options = React.useMemo<Entity<ServiceRequestMilestoneChargeType>[]>(
    () => [
      {
        Id: 'service',
        Name: t(translations.Service),
      },
      {
        Id: 'charge',
        Name: t(translations.Charge),
      },
    ],
    [t],
  );
  const handleChange = (
    value: Entity<ServiceRequestMilestoneChargeType> | null,
  ) => {
    if (value !== null) {
      props.onChange?.(value.Id);
    }
  };
  const entityValue = options.find(f => f.Id === props.value);

  return (
    <BaseToggleButtonGroup
      options={options}
      onChange={handleChange}
      value={entityValue ?? null}
      fullWidth
    />
  );
}

const toInitialValues = (
  type: ServiceRequestMilestoneChargeType,
  value?: IServiceRequestMilestoneChargeDto,
) => {
  const result: IServiceRequestMilestoneChargeForm =
    value === undefined
      ? {
          Date: null,
        }
      : {
          Id: value.Id,
          Service: value.Service ?? undefined,
          Quantity: value.Quantity ?? undefined,
          Remarks: value.Remarks ?? undefined,
          Date: value.Date === null ? null : dateUtils.parseISO(value.Date),
          Name: value.Name ?? undefined,
          Rate: value.Rate ?? undefined,
          Type: type,
          Unit: value.UnitType ?? undefined,
        };

  result.Type = type;
  return result;
};
