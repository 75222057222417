/**
 *
 * auto generated interface for Bookit.Data.DisplayTypes
 *
 */

export enum DisplayTypes {
  AlwaysVisible = 0,
  VisibleToAdminOnly = 1,
  VisibleToUserOnDoneRequest = 2,
}
export type DisplayTypesUnion = keyof typeof DisplayTypes;
export const DisplayTypesUnionArray: Array<DisplayTypesUnion> = [
  'AlwaysVisible',
  'VisibleToAdminOnly',
  'VisibleToUserOnDoneRequest',
];
