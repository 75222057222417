import { useFormikContext } from 'formik';
import { Entity } from 'types/common';
import * as React from 'react';
import { GlobalSettingsType } from '../useGlobalSettingsHook';
import { useSelector } from 'react-redux';
import { selectEquipmentsSettings } from '../../slice/selectors';

import { AuthenticatedUser } from 'types/AuthenticatedUser';

import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { getWorkFlowName } from '../utils';

export interface UserNameListenerProps<T> {
  userName: Entity<string> | null;
  equipments: Entity<number>[];
  globalSettings: GlobalSettingsType;
  user: AuthenticatedUser | undefined;
  isEdit: boolean;
  changeHandler: (values: T, changeStatus?: boolean | undefined) => void;
  values: T;
}
export function UserNameListener<T>(props: UserNameListenerProps<T>) {
  const { userName, equipments, globalSettings, isEdit } = props;

  const equipmentSettings = useSelector(selectEquipmentsSettings);
  const [initilized, setInitilized] = React.useState(true);
  const ReservationUserGroupGroupByBudgetUserGroup = React.useMemo(() => {
    return !!equipmentSettings
      ? globalSettings.reservationUserGroupGroupByBudgetUserGroup &&
          !equipmentSettings.HasHideProjects &&
          !equipmentSettings.HasConflictProjects &&
          equipmentSettings.BudgetsTurnedOn
      : globalSettings.reservationUserGroupGroupByBudgetUserGroup;
  }, [
    equipmentSettings,
    globalSettings.reservationUserGroupGroupByBudgetUserGroup,
  ]);

  const { setFieldValue } = useFormikContext<T>();

  React.useEffect(() => {
    if (!initilized) {
      if (equipments.length > 0 && !isEdit) {
        if (userName !== null) {
          if (!ReservationUserGroupGroupByBudgetUserGroup) {
            setFieldValue('ADGroup', {
              Id: (userName as IUserFilterDto).UserGroupId,
              Name: (userName as IUserFilterDto).UserGroupName,
            });
          }
          if (globalSettings.AllowWorkflowBooking) {
            setFieldValue('WorkflowBooking', getWorkFlowName(userName.Id));
          }
        }
      }
    }
    setInitilized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  return null;
}
