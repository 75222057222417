import { FormikProps } from 'formik';
import { ReservationDetailsState, ServiceSettingsState } from '../slice/types';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';

export function getReservationCollaboratorsFilter(
  equipmentSettings: ServiceSettingsState | undefined,
  formik: FormikProps<ReservationDetailsState>,
) {
  return [
    equipmentSettings?.BudgetVisible === true
      ? new Condition<IUserFilterDto>(
          'Budgets',
          ODataOperators.Any,
          formik.values.Budget?.Id ?? 0,
        )
      : undefined,
    formik.values.BookedBy?.Id !== undefined
      ? new Condition<IUserFilterDto>(
          'Id',
          ODataOperators.NotEquals,
          formik.values.BookedBy?.Id,
        )
      : undefined,
  ].filter(f => f !== undefined) as Condition<IUserFilterDto>[];
}
