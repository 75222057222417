import { useIsMobile } from 'app/hooks/useIsMobile';
import * as rdd from 'react-device-detect';

export function DetectIsMobile() {
  const { checkIsMobile } = useIsMobile();
  return checkIsMobile();
}
export function GetBrowserInfo() {
  console.debug(`
    fullBrowserVersion: ${rdd.fullBrowserVersion};
    browserName: ${rdd.browserName};
    browserVersion: ${rdd.browserVersion};
    deviceType: ${rdd.deviceType};
    engineName: ${rdd.engineName};
    engineVersion: ${rdd.engineVersion};
    osName: ${rdd.osName};
    osVersion: ${rdd.osVersion};
    mobileModel: ${rdd.mobileModel};
    mobileVendor: ${rdd.mobileVendor};
  `);
}
