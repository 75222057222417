import { IWorkingHoursDto } from 'api/odata/generated/entities/IWorkingHoursDto';
import HTMLReactParser from 'html-react-parser';
import i18next from 'i18next';
import { translations } from 'locales/translations';

import { formatSchedulerDateTime } from '../../../utils/formatSchedulerTime';
import { SchedulerEventTooltipProps } from '../SchedulerEventTooltipProps';
import { TooltipLine } from '../TooltipLine';

export function OfflineTooltip(props: SchedulerEventTooltipProps) {
  let remarks =
    (props.event.original as IWorkingHoursDto).Remarks === null
      ? null
      : (props.event.original as IWorkingHoursDto).Remarks;
  return (
    <>
      <TooltipLine
        label={i18next.t(translations.Service)}
        value={props.event.service_name}
      />
      <TooltipLine
        label={i18next.t(translations.User)}
        value={props.event.user_name}
      />
      <TooltipLine
        label={i18next.t(translations.Start)}
        value={formatSchedulerDateTime(props.event.start_date)}
      />
      <TooltipLine
        label={i18next.t(translations.End)}
        value={formatSchedulerDateTime(props.event.end_date)}
      />
      <TooltipLine
        label={i18next.t(translations.Reason)}
        value={(props.event.original as IWorkingHoursDto).ReasonTypeName}
      />
      <TooltipLine
        label={i18next.t(translations.Remarks)}
        value={remarks === null ? null : HTMLReactParser(remarks)}
      />
    </>
  );
}
