import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { SearchColumnType } from 'app/components/BasicTable/BasicTableProps';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { CustomDate } from 'types/CustomDate';
import { dateUtils } from 'utils/date-utils';

/**
 * Supported OData operators
 * TODO: extend to greater than, etc
 */
export enum ODataOperators {
  Equals = 'eq',
  NotEquals = 'ne',
  GreaterThan = 'gt',
  GreaterThanEqual = 'ge',
  LessThan = 'lt',
  LessThanEqual = 'le',
  Contains = 'contains',
  Between = 'between',
  Intersection = 'intersection',
  Any = 'any',
  NotAny = 'notAny',
  In = 'in',
  Includes = 'includes',
  Excludes = 'excludes',
  InM = 'inm',
  AnyIn = 'AnyIn',
  ContainsM = 'containsm',
  HasNoAny = 'hasNoAny',
  All = 'all',
  AllIn = 'allIn',
}
export type OdataFilterOperatorType =
  | ODataOperators
  | ((value: FilterValueType | undefined) => string);
export const operatorToString = (
  key: ODataOperators,
  value: FilterValueType | undefined,
  t: TFunction,
): string => {
  return key === ODataOperators.Equals
    ? t(translations.ODataEquals)
    : key === ODataOperators.NotEquals
    ? t(translations.ODataNotEquals)
    : key === ODataOperators.GreaterThan
    ? value instanceof Date
      ? t(translations.ODataGtAfter)
      : t(translations.ODataGreaterThan)
    : key === 'lt'
    ? value instanceof Date
      ? t(translations.ODataLtBefore)
      : t(translations.ODataLessThan)
    : key === ODataOperators.GreaterThanEqual
    ? t(translations.ODataGreaterThanEqual)
    : key === ODataOperators.LessThanEqual
    ? t(translations.ODataLessThanEqual)
    : key === ODataOperators.Intersection
    ? t(translations.ODataIntersection)
    : key === ODataOperators.NotAny
    ? t(translations.ODataNotAny)
    : key === ODataOperators.Includes
    ? t(translations.ODataIncludes)
    : key === ODataOperators.Excludes
    ? t(translations.ODataExcludes)
    : key === ODataOperators.AnyIn
    ? t(translations.ODataAnyIn)
    : key === ODataOperators.ContainsM
    ? t(translations.ODataContainsM)
    : key === ODataOperators.InM
    ? t(translations.ODataInM)
    : key === ODataOperators.HasNoAny
    ? t(translations.ODataHasNoAny)
    : key === ODataOperators.All
    ? t(translations.ODataAll)
    : key === ODataOperators.AllIn
    ? t(translations.ODataAllIn)
    : key === ODataOperators.In
    ? t(translations.ODataIn)
    : key === ODataOperators.Any
    ? t(translations.ODataAny)
    : key;
};
/**
 * Supported value types
 */
export type ODataFieldType = string | number | Date | boolean;

/**
 * Formats a const value in oData format.
 * @param value value
 * @returns formatted value that can be used in odata filter expression
 */
export function quoteODataValue(
  value: FilterValueType | Array<FilterValueType>,
) {
  if (value === null) {
    return null;
  } else if (typeof value === 'number') {
    return value.toString();
  } else if (typeof value === 'boolean') {
    return value.toString();
  } else if (typeof value === 'string') {
    return `'${escapeODataValue(value)}'`;
  } else if (value instanceof CustomDate) {
    return `${dateUtils.formatISO(value, {
      representation: (value as CustomDate).representation ?? 'date',
    })}`;
  } else if (value instanceof Date) {
    return `${dateUtils.formatISO(value, { representation: 'complete' })}`;
  } else if (Array.isArray(value)) {
    return `(${value.map(v => quoteODataValue(v)).join(',')})`;
  } else if ('Id' in value) {
    return quoteODataValue(value.Id);
  } else {
    console.error(`Value type not supported: ${value}`, value);
    throw new Error(`Value type not supported: ${value}`);
  }
}
/**
 * Escapes const OData value
 * @param value value
 * @returns
 */
export function escapeODataValue(value: string): string {
  return value.replaceAll("'", "''");
}

export function withisInversed<
  T extends string | undefined = string | undefined
>(condition: T, isInversed: boolean | undefined) {
  if (!isInversed || (condition ?? '') === '') {
    return condition;
  }
  return '(' + condition + ') eq false';
}

/**
 * Filter condition to be used in future custom filters
 */
export class Condition<TRow> {
  public field: keyof TRow;
  public field1?: keyof TRow;
  public operator?: ODataOperators;
  public value?: FilterValueType;
  public isInversed?: boolean;
  constructor(
    field: keyof TRow,
    operator: ODataOperators,
    value: FilterValueType | undefined,
    field1?: keyof TRow,
    isInversed?: boolean,
  ) {
    this.field = field;
    this.field1 = field1;
    this.operator = operator;
    this.value = value;
    this.isInversed = isInversed;
  }

  /**
   * Format the range filter (between operator)
   * @returns odata filter string
   */
  between() {
    const c1: Condition<TRow>[] = [];
    const start = (this.value as any).start;
    if (start !== null) {
      c1.push(
        new Condition(this.field, ODataOperators.GreaterThanEqual, start),
      );
    }
    const end = (this.value as any).end;
    if (end !== null) {
      c1.push(new Condition(this.field, ODataOperators.LessThanEqual, end));
    }
    const result =
      c1.length > 0 ? `(${c1.map(c => c.toString()).join(' and ')})` : '';
    return result;
  }
  /**
   * Format the range filter (intersection operator)
   * @returns odata filter string
   */
  intersection() {
    if (this.field1 === undefined) return '';
    const c1: Condition<TRow>[] = [];
    const start = (this.value as any).start;
    if (start !== null) {
      c1.push(new Condition(this.field1, ODataOperators.GreaterThan, start));
    }
    const end = (this.value as any).end;
    if (end !== null) {
      c1.push(new Condition(this.field, ODataOperators.LessThan, end));
    }
    const result =
      c1.length > 0 ? `${c1.map(c => c.toString()).join(' and ')}` : '';
    return result;
  }
  hasAny() {
    //if (this.field1 === undefined) return '';
    const result = `${this.field}/any(g: g${
      this.field1 ? '/' + this.field1 : ''
    } eq ${quoteODataValue(this.value as number)})`;
    return result;
  }
  haNoAny() {
    const result = `not ${this.field}/any()`;
    return result;
  }
  notAny() {
    if (this.field1 === undefined) return '';
    const result = `${this.field}/any(g: g/${this.field1} eq ${quoteODataValue(
      this.value as number,
    )}) eq false`;
    return result;
  }
  anyIn() {
    if (!Array.isArray(this.value)) return '';
    const result = `${this.field}/any(g: g${
      !!this.field1 ? '/' + this.field1 : ''
    } in ${quoteODataValue(this.value)})`;
    return result;
  }
  allIn() {
    if (!Array.isArray(this.value)) return '';
    const result = `${this.field}/all(g: g${
      this.field1 ? '/' + this.field1 : ''
    } in ${quoteODataValue(this.value)})`;
    return result;
  }
  all() {
    if (this.field1 === undefined) return '';
    const result = `${this.field}/all(g: g/${this.field1} eq ${quoteODataValue(
      this.value as number,
    )})`;
    return result;
  }
  inM() {
    let res: string[] | undefined =
      this.value !== null && Array.isArray(this.value)
        ? (this.value as Array<FilterValueType>).map(v => {
            return `${quoteODataValue(v)} in ${this.field}`;
          })
        : undefined;
    return res !== undefined ? `(${res.join(' or ')})` : '';
  }
  public withNull(fieldName?: string) {
    const res = this.toString();
    const name = fieldName ?? String(this.field);
    if (res === undefined) return res;
    return '((' + res + ') or ' + name + ' eq null)';
  }
  public toString(): string | undefined {
    if (this.value === undefined) {
      return undefined;
    }
    if (this.operator === undefined) {
      return undefined;
    }
    switch (this.operator) {
      case ODataOperators.Equals:
      case ODataOperators.NotEquals:
      case ODataOperators.GreaterThan:
      case ODataOperators.GreaterThanEqual:
      case ODataOperators.LessThan:
      case ODataOperators.LessThanEqual:
        return `${this.field} ${this.operator} ${quoteODataValue(this.value)}`;
      case ODataOperators.Contains:
        return `contains(tolower(${this.field}), ${quoteODataValue(
          this.value,
        ).toLowerCase()})`;
      case ODataOperators.ContainsM:
        let resCM: string[] | undefined =
          this.value !== null && Array.isArray(this.value)
            ? (this.value as Array<FilterValueType>).map(v => {
                return `contains(tolower(${this.field}), ${quoteODataValue(
                  v?.['Name'] ?? v,
                ).toLowerCase()})`;
              })
            : undefined;
        return resCM !== undefined ? resCM.join(' or ') : '';
      case ODataOperators.Between:
        return this.between();
      case ODataOperators.Intersection:
        return this.intersection();
      case ODataOperators.Any:
        return this.hasAny();
      case ODataOperators.NotAny:
        return this.notAny();
      case ODataOperators.In:
        return `(${quoteODataValue(this.value)} in ${this.field})`;
      case ODataOperators.InM:
        return this.inM();
      case ODataOperators.Includes:
        return `(${this.field} in ${quoteODataValue(this.value)})`;
      case ODataOperators.Excludes:
        return `(${this.field} in ${quoteODataValue(this.value)} eq false)`;
      case ODataOperators.AnyIn:
        return this.anyIn();
      case ODataOperators.HasNoAny:
        return this.haNoAny();
      case ODataOperators.All:
        return this.all();
      case ODataOperators.AllIn:
        return this.allIn();
      default:
        return undefined;
    }
  }
}

/**
 * OData filter expression builder
 */
export class ODataFilterBuilder<T> {
  predicates: (string | Condition<T>)[] = [];
  globalServiceGroupFilter: Condition<T>[] = [];
  stringColumns?: Array<SearchColumnType<T>>;
  stringSearch?: string | null;
  isOptionalServiceGroup?: boolean;
  constructor(x: {
    predicates: (string | Condition<T>)[];
    stringColumns?: Array<SearchColumnType<T>>;
    stringSearch?: string | null;
    globalServiceGroupFilter?: Condition<T>[];
    timezone?: string;
    isOptionalServiceGroup?: boolean;
  }) {
    this.predicates = x.predicates.filter(
      f => f.toString() !== '' && f.toString() !== undefined,
    );
    this.stringColumns = x.stringColumns;
    this.stringSearch = x.stringSearch;
    this.globalServiceGroupFilter = x.globalServiceGroupFilter || [];
    this.isOptionalServiceGroup = x.isOptionalServiceGroup;
  }
  public toString(): string | undefined {
    const x: string[] = [];
    if (
      this.stringSearch !== undefined &&
      this.stringSearch !== null &&
      this.stringSearch !== ''
    ) {
      if (this.stringColumns !== undefined) {
        const stringValue = this.stringSearch.toLowerCase();
        x.push(
          '(' +
            this.stringColumns
              .map(c =>
                typeof c === 'function'
                  ? c("'" + escapeODataValue(stringValue) + "'")
                  : new Condition<T>(
                      c as keyof T,
                      ODataOperators.Contains,
                      stringValue,
                    ),
              )
              .map(c => c.toString())
              .join(' or ') +
            ')',
        );
      }
    }
    if (this.predicates?.length > 0) {
      x.push(
        '(' +
          this.predicates
            .map(p => withisInversed(p.toString(), p['isInversed']))
            .filter(p => p !== '' && p !== undefined)
            .join(' and ') +
          ')',
      );
    }
    if (
      this.globalServiceGroupFilter.length > 0 &&
      this.globalServiceGroupFilter[0].field !== ''
    ) {
      if (this.globalServiceGroupFilter[0].field === 'ServiceGroupId') {
        x.push(
          '(' +
            (this.isOptionalServiceGroup === true
              ? this.globalServiceGroupFilter
                  .concat(
                    new Condition<any>(
                      'ServiceGroupId',
                      ODataOperators.Equals,
                      null,
                    ),
                  )
                  .map(p => p.toString())
                  .join(' or ')
              : this.globalServiceGroupFilter
                  .map(p => p.toString())
                  .join(' or ')) +
            ')',
        );
      } else {
        if (this.isOptionalServiceGroup !== true) {
          x.push(
            '(' +
              this.globalServiceGroupFilter
                .map(p => p.toString())
                .join(' or ') +
              ')',
          );
        }
      }
    }
    const result =
      x.length === 0
        ? undefined
        : x.length === 1 && x[0] === '()'
        ? undefined
        : x.join(' and ');
    return result;
  }
}

export type oDataValue = { operator: ODataOperators; value: string };
