/**
 *
 * EmailTypePicker
 *
 */

import {
  EmailTypeUnionArray,
  EmailTypeUnion,
} from 'api/odata/generated/enums/EmailType';
import {
  EnumPicker,
  EnumPickerProps,
  withEnumOptions,
} from 'app/components/BasicPickers/EnumPicker';

export type EmailTypePickerProps = Omit<
  EnumPickerProps<EmailTypeUnion>,
  'EnumNamesArray'
>;
export const EmailTypePicker = withEnumOptions<EmailTypeUnion>(
  EmailTypeUnionArray,
)(EnumPicker);
