/**
 *
 * MultiSelectBasicPicker
 *
 */
import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { Entity, EntityType } from 'types/common';
import { ODataFilterBuilder } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from '../AutocompletePicker';

export interface BaseURLPickerProps<T extends EntityType>
  extends Omit<AutocompletePickerProps<T>, 'loadData'> {
  url: string;
  queryfilter?: string | undefined;
}

export function BaseURLPicker<T extends EntityType>(
  props: BaseURLPickerProps<T>,
) {
  const { url, queryfilter, ...other } = props;
  const loadData = async (searchTerm: string | null) => {
    const params: { $orderby: string; $top: number; $filter?: string } = {
      $orderby: 'Name asc',
      $top: 100,
    };
    const predicates: string[] = [];
    if (queryfilter !== undefined && queryfilter !== '') {
      predicates.push(queryfilter);
    }
    if (searchTerm !== null || predicates.length > 0) {
      params.$filter = new ODataFilterBuilder<Entity<number>>({
        predicates: predicates,
        stringColumns: ['Name'],
        stringSearch: searchTerm ?? undefined,
      }).toString();
    }
    const response = await httpClient.get(url, params);
    return response.value;
  };
  return <AutocompletePicker loadData={loadData} {...other} />;
}
