import { httpClient } from './HttpClient';

export const ExternalApi = {
  getExternalItems(
    formFieldId: number,
    payload: { filter: string | null; pageSize: number; pageIndex: number },
  ) {
    const url = `/api/externalitems/${formFieldId}/query`;
    const data = { param: payload };
    return httpClient.get(url, data);
  },
};
