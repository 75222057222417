/**
 *
 * FormTrainingSessionPicker
 *
 */
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { ITrainingSessionDto } from 'api/odata/generated/entities/ITrainingSessionDto';
import { TrainingSessionPicker } from '../pickers/AutocompletePickers/TrainingSessionPicker';

export const FormTrainingSessionPicker = ({
  label,
  ...props
}: FieldHookConfig<ITrainingSessionDto | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<ITrainingSessionDto | null>(props);

  return (
    <TrainingSessionPicker
      predicates={props.predicates}
      name={props.name}
      placeholder={props.placeholder}
      label={label}
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      onBlur={field?.onBlur}
      value={field.value ?? undefined}
      error={meta?.error !== undefined}
      variant="filled"
      disabled={props.disabled}
      helperText={meta.error}
      fullWidth={props.fullWidth}
      info={props.info}
    />
  );
};
