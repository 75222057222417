/**
 *
 * UserMultiPicker
 *
 */
import { InputBaseComponentProps } from '@material-ui/core';
import { httpClient } from 'api/HttpClient';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompleteMultiPickerEx,
  AutocompleteMultiPickerExProps,
} from 'app/components/BasicPickers/AutocompleteMultiPickerEx';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';

import * as React from 'react';
import { Entity, Identifiable } from 'types/common';
import { UserUrl } from '../UserPicker';

export interface UserPickerProps
  extends Omit<AutocompleteMultiPickerExProps<IUserFilterDto>, 'loadData'> {
  inputProps?: InputBaseComponentProps;
  selectArray?: Array<keyof IUserFilterDto>;
  urlType?: UserUrl;
  separator?: string;
}

const defaultUrl = '/api/odata/v4/UserFilter';
const fullActiveUrl = '/api/odata/v4/UserFilter/GetUserSelections';
const adminsUrl = '/api/odata/v4/UserFilter/GetAdminUsers';
export const initUserMultiData = (
  initval: string | undefined,
  sourceUrl?: UserUrl,
  separator?: string,
) => {
  if (initval === undefined) {
    return new Promise<Entity<string>[]>((resolve, reject) => {
      resolve([] as Entity<string>[]);
    });
  } else {
    let ids = (initval.indexOf(separator || '|') !== -1
      ? initval.split(separator || '|')
      : [initval]
    ).map(item => {
      return { Id: item };
    }) as Identifiable<string>[];
    let filters: string | undefined = undefined;
    if (ids.length > 0) {
      const predicates: (string | Condition<IUserFilterDto>)[] = [];
      predicates.push(new Condition('Id', ODataOperators.Includes, ids));
      filters = predicates.map(f => f.toString()).join(' and ');
    }
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: filters,
      $skip: 0,
      $top: 1,
    };
    let initUrl =
      sourceUrl === 'full'
        ? fullActiveUrl
        : sourceUrl === 'admin'
        ? adminsUrl
        : defaultUrl;

    return httpClient
      .get(initUrl, params)
      .then(response => response.value as Entity<string>[]);
  }
};

export function UserMultiPicker(props: UserPickerProps) {
  const { selectArray, predicates, urlType, ...other } = props;
  const loadData = getAutoCompleteLoadDataFn<IUserFilterDto>({
    url:
      urlType === 'full'
        ? fullActiveUrl
        : urlType === 'admin'
        ? adminsUrl
        : defaultUrl,
    predicates: predicates,
    select: selectArray ?? [
      'Id',
      'Name',
      'UserGroups',
      'Budgets',
      'IsAdminPanel',
      'IsEquipmentAdmin',
      'IsGroupAdmin',
      'IsLabTech',
      'ServiceGroupIds',
      'Services',
      'CustomRoles',
      'UserGroupId',
      'UserGroupName',
      'UserRoles',
    ],
    expand: 'Services,UserGroups',
  });
  return (
    <AutocompleteMultiPickerEx
      mini={props.mini ? true : undefined}
      size={props.size}
      ariaLabel="Users search"
      id={props.id || 'usermultiUId'}
      loadData={loadData}
      {...other}
    />
  );
}

//PREDICATES FUNCTION
export function ContactsByAssetIdsFilter(
  AssetIds: number[],
): (string | Condition<IUserFilterDto>)[] {
  const predicates: (string | Condition<IUserFilterDto>)[] = [];
  if (AssetIds.length > 0) {
    let ids = AssetIds.map(f => {
      return { Id: f } as Identifiable<number>;
    });
    predicates.push(
      new Condition<IUserFilterDto>('AssetIds', ODataOperators.Includes, ids),
    );
  }
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}
