import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  tableRoot: {
    // paddingInline: theme.spacing(2),
  },
  filterPanel: {
    padding: theme.spacing(1),
  },
  tableScrolling: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      boxShadow: `inset 0 0 2px ${theme.palette.common.grayBorders}`,
      borderRadius: '6px',
      backgroundColor: theme.palette.common.grayBorders,
    },
  },
  tableContent: {
    padding: theme.spacing(0, 2, 0, 4),
    display: 'flex',
    flexDirection: 'column',
    /* will cause table footer to be at the bottom of the screen */
    //height: '100%',
    justifyContent: 'space-between',
    overflowY: 'hidden',
  },
  tableContentShort: {
    padding: theme.spacing(0, 1, 0, 2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    overflowY: 'hidden',
  },
  table: {
    backgroundColor: theme.palette.background.paper,
    whiteSpace: 'pre',
    '&.withFooter': {
      borderRadius: '8px 8px 0px 0px',
    },
  },
  tableButtonsRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 32px 16px',
    gap: theme.spacing(1.5),
    flexWrap: 'wrap',
  },
  tableButtonsRootShort: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'normal',
    padding: '8px 16px 16px',
    gap: theme.spacing(1.5),
  },
  filterDisplayRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0px 32px 16px',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
  filterDisplayRootShort: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'normal',
    justifyContent: 'flex-start',
    padding: '0px 32px 16px',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
  tableButtonsLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(1.5),
    flexWrap: 'wrap',
  },
  tableButtonsLeftShort: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(1.5),
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxWidth: '90%',
  },
  tableButtonWrap: {
    flexWrap: 'wrap',
  },
  tableButtonNoWrap: {
    flexWrap: 'nowrap',
  },
  tableButtonsRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(1),
    marginLeft: 'auto',
  },
  tableButtonsRightShort: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(1),
    justifyContent: 'space-between',
  },
  menuPaper: {
    width: '160px',
  },
  subHeader: {
    // same padding as in all child elements of the table container
    padding: '0px 32px 16px',
  },
  isAggregated: {
    fontWeight: 'bold',
  },
  tableFooter: {
    width: '100%',
    backgroundColor: 'inherit',
    minHeight: '42px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      boxShadow: `inset 0 0 2px ${theme.palette.common.grayBorders}`,
      borderRadius: '6px',
      backgroundColor: theme.palette.common.grayBorders,
    },
    // '&.tableFooterShortView': {
    //   minHeight: '56px',
    // },
  },
  tableFooterWithBorder: {
    border: '1px solid ' + theme.palette.divider,
    borderTop: `1px solid ${theme.palette.common.grayBorders}`,
    borderRadius: '5px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
  },
}));
