/**
 *
 * RestrictedRoute
 *
 */
import {
  selectAuthenticatedUser,
  selectSiteMapProvider,
} from 'app/slice/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { RouteProps, Redirect, Route } from 'react-router-dom';

export function RestrictedRoute(props: RouteProps): React.ReactElement {
  const User = useSelector(selectAuthenticatedUser);
  const { pathname } = useLocation();
  const siteMapProvider = useSelector(selectSiteMapProvider);

  const { component: Component, ...rest } = props;

  const render = propss => {
    let allowedNode = siteMapProvider.accesibleByRole(pathname, User);
    if (allowedNode === false) {
      return <Redirect to={'/notavailable'} />;
    } else if (allowedNode === true) {
      return <Route component={props.component} {...props} />;
    } else {
      return undefined;
    }
  };
  return <Route {...rest} render={render} />;
}
