/**
 *
 * SideBar
 *
 */
import * as React from 'react';

import { useSelector } from 'react-redux';
import { selectSiteMapSettings } from 'app/slice/selectors';
import { SideBarMenu } from './SideBarMenu';
import { SideBarHeader } from './SideBarHeader';
import { SiteMapNode, SiteMapProvider } from 'types/SiteMapSettings';
import { isNullOrUndefined } from 'utils/typeUtils';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { SideBarFooter } from './SideBarFooter';
interface Props {
  isOpen: boolean;
}

const SideBarComponent = (props: Props) => {
  const { isOpen } = props;
  const siteMapSettings = useSelector(selectSiteMapSettings);
  const [currentNode, setCurrentNode] = React.useState<SiteMapNode | undefined>(
    undefined,
  );
  const location = useLocation();
  const [currentNodes, setCurrentNodes] = React.useState<SiteMapNode[]>([]);
  const loading = isNullOrUndefined(siteMapSettings);
  React.useEffect(() => {
    let active = !isNullOrUndefined(siteMapSettings);
    if (currentNodes.length > 0) {
      return undefined;
    }
    if (active) {
      let siteMapProvider = new SiteMapProvider(siteMapSettings);
      setCurrentNode(() =>
        siteMapProvider.getCurrentMainNode(
          location?.pathname || window.location.pathname,
        ),
      );
      setCurrentNodes(
        siteMapProvider.getCurrentNodes(
          location?.pathname || window.location.pathname,
        ),
      );
    }
    return () => {
      active = false;
    };
  }, [currentNodes, loading, location, siteMapSettings]);
  return (
    <React.Fragment>
      <SideBarHeader isOpen={isOpen} />
      <SideBarMenu
        menuItems={currentNodes}
        isOpen={isOpen}
        currentNode={currentNode}
        loading={loading}
      />
      {isMobile && <SideBarFooter />}
    </React.Fragment>
  );
};
export const SideBar = React.memo(SideBarComponent);
