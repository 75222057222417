/**
 *
 * Asynchronously loads the component for BillingActions Page
 *
 */

import { lazyLoad } from 'utils/loadable';
import { withoutGlobalServiceGroupFilter } from 'app/components/BasicTable/withoutGlobalServiceGroupFilter';

export const SavedViews = lazyLoad(
  () => import('./index'),
  module => withoutGlobalServiceGroupFilter(module.SavedViewsPage),
);
