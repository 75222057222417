import { IReservationEquipmentDto } from './types';

export function TryDisableRemoveableEquipments(
  equipments: IReservationEquipmentDto[],
  condition: (item: IReservationEquipmentDto) => boolean,
  title?: string,
): IReservationEquipmentDto[] {
  let disabled = equipments
    .filter(f => condition(f) === true)
    .map(f =>
      Object.assign({}, f, {
        noremoved: true,
        titleInfo: title,
      }),
    );
  let other = equipments.filter(f => condition(f) === false);
  return [...disabled, ...other];
}
