import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useRef, useState } from 'react';
import { toQueryString } from 'utils/url-utils';

import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { IRunDetails, ISample } from '../../SamplesListPage/slice/types';
import * as Yup from 'yup';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { Entity } from 'types/common';
import { Button } from 'app/components/BasicButtons/Button';
import {
  FormGradientPicker,
  IGradientDto,
} from 'app/components/Forms/FormGradientPicker';
import { FormOnlineServicesSinglePicker } from 'app/components/Forms/FormEquipmentsPicker';
import { ServiceGroupFilter } from 'app/components/pickers/MultiSelectPickers/OnlineServicesPicker';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import { useSamplesListSlice } from '../../SamplesListPage/slice';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { GetSchedulerPagePath } from 'app/pages/CalendarPage/GetCalendarPagePath';
import { FormNumberField } from 'app/components/Forms/FormNumberField';

export interface CreateReservationComponentProps {
  runDetails?: IRunDetails;
  sampleRunId?: number;
  rows: ISample[];
}
interface ICreateReservationOptions {
  Gradient?: IGradientDto | null;
  SampleOverhead?: number | null;
  TotalOverhead?: number | null;
  Equipment?: Entity<number> | null;
  SecondaryEquipment?: Entity<number> | null;
  ACQUISITION_TYPE?: string | null;
}
const schema: Yup.SchemaOf<ICreateReservationOptions> = Yup.object({
  Gradient: Yup.mixed().nullable(),
  SampleOverhead: Yup.number().positive().nullable(),
  TotalOverhead: Yup.number().positive().nullable(),
  Equipment: Yup.mixed().required().nullable(),
  SecondaryEquipment: Yup.mixed().notRequired().nullable(),
  ACQUISITION_TYPE: Yup.string().notRequired().nullable(),
});

export const CreateReservationComponent = ({
  runDetails,
  sampleRunId,
  rows,
}: CreateReservationComponentProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [save, setSave] = useState(false);
  const dispatch = useDispatch();
  const { actions } = useSamplesListSlice();
  const formikRef = useRef<FormikProps<FormikValues>>(null);

  const serviceRequestId = rows?.map(item => item.ServiceRequestId)[0];
  const serviceGroupId = rows?.map(item => item.ServiceGroupId)[0];
  const handleCreateReservationButtonClick = (
    values: ICreateReservationOptions,
  ) => {
    if (save) {
      setSave(false);
      dispatch(
        actions.updateRun({
          SampleRunId: sampleRunId,
          InstrumentId:
            values.Equipment !== undefined && values.Equipment !== null
              ? values.Equipment.Id
              : undefined,
          SecondaryInstrumentId:
            values.SecondaryEquipment !== undefined &&
            values.SecondaryEquipment !== null
              ? values.SecondaryEquipment.Id
              : undefined,
          GradientId:
            values.Gradient !== undefined && values.Gradient !== null
              ? values.Gradient.Id
              : undefined,
          ACQUISITION_TYPE:
            values.ACQUISITION_TYPE !== null ? values.ACQUISITION_TYPE : null,
        }),
      );
      setOpen(false);
    } else {
      var p: Record<string, any> = {
        srid: serviceRequestId,
        sampleRunId: sampleRunId,
        eid: [values.Equipment?.Id, values.SecondaryEquipment?.Id],
        srsid:
          sampleRunId === undefined
            ? rows?.map(r => r.Id).join(',')
            : undefined,
        duration: getReservationDuration(values, rows),
      };
      dispatch(
        push({
          pathname: GetSchedulerPagePath('calendar'),
          search: toQueryString(p).toString(),
        }),
      );
      setOpen(false);
    }
  };
  const initialValues: ICreateReservationOptions = {
    Gradient: runDetails?.Gradient as IGradientDto,
    //SampleOverhead: 0,
    //TotalOverhead: 0,
    Equipment: runDetails?.Instrument,
    SecondaryEquipment: runDetails?.SecondaryInstrument,
    ACQUISITION_TYPE: runDetails?.ACQUISITION_TYPE,
  };
  return (
    <>
      <Button onClick={e => setOpen(true)}>
        {t(translations.CreateReservation)}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            handleCreateReservationButtonClick(values);
          }}
        >
          {formik => (
            <Form>
              <DialogTitle>
                {t(translations.RunDetailsCreateReservation)} {'  '}
                <InfoIcon title={t(translations.SaveGradient_info) as string} />
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormGradientPicker
                      fullWidth
                      name="Gradient"
                      label="Gradient"
                      placeholder={t(translations.SampleRunGradient)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormNumberField
                      name="SampleOverhead"
                      fullWidth
                      label="Sample overhead time (minutes)"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormNumberField
                      name="TotalOverhead"
                      fullWidth
                      label="Total overhead time (minutes)"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormOnlineServicesSinglePicker
                      name="Equipment"
                      fullWidth
                      predicates={ServiceGroupFilter(serviceGroupId)}
                      placeholder={t(translations.Equipment)}
                      label="Equipment"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormOnlineServicesSinglePicker
                      name="SecondaryEquipment"
                      fullWidth
                      predicates={ServiceGroupFilter(serviceGroupId)}
                      placeholder={t(translations.Equipment)}
                      label="Secondary equipment"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField
                      name="ACQUISITION_TYPE"
                      fullWidth
                      placeholder={t(translations.ACQUISITION_TYPE)}
                      label="Acquisition type"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  variant="white"
                  onClick={() => setOpen(false)}
                >
                  {t(translations.Cancel)}
                </Button>
                <Button
                  variant="main"
                  onClick={() => {
                    setSave(true);
                    formikRef.current?.handleSubmit();
                  }}
                >
                  {t(translations.Save)}
                </Button>
                <Button
                  variant="main"
                  onClick={() => {
                    setSave(false);
                    formikRef.current?.handleSubmit();
                  }}
                >
                  {t(translations.CreateReservation)}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
function getReservationDuration(
  values: ICreateReservationOptions,
  rows: ISample[],
) {
  var timePerSample =
    (values.Gradient?.EstimatedRuntimeMinutes ?? 0) +
    (values.SampleOverhead ?? 0);
  var totalMinutes = timePerSample * rows.length + (values?.TotalOverhead ?? 0);
  const duration = totalMinutes > 0 ? totalMinutes : undefined;
  return duration;
}
