/**
 *
 * FullScreenSchedulerButton
 *
 */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthenticatedUser,
  selectGlobalSetting,
} from 'app/slice/selectors';
import { AllowedSettings } from 'utils/globalSettings';
import { Roles } from 'app/slice/types';
import { tryParseInt } from 'utils/string-utils';
import { dateUtils } from 'utils/date-utils';
import { schedulerActions } from '../../slice';
import { FullScreenButton } from 'app/components/FullScreenButton';
import { useSystemDate } from 'app/hooks/useSystemDate';

export interface FullScreenSchedulerButtonProps {}

export function FullScreenSchedulerButton(
  props: FullScreenSchedulerButtonProps,
) {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const intervalRefreshingCalendar = useSelector(state =>
    selectGlobalSetting(state, AllowedSettings.IntervalRefreshingCalendar),
  );
  const calendarOnly = authenticatedUser?.Roles?.includes(Roles.CalendarOnly);
  const { newDate } = useSystemDate();
  React.useEffect(() => {
    let handle = 0;

    if (calendarOnly === true) {
      const DEFAULT_REFRESH_INTERVAL_MINUTES = 10;
      const configuredRefreshInterval =
        tryParseInt(intervalRefreshingCalendar) ??
        DEFAULT_REFRESH_INTERVAL_MINUTES;
      const refreshInterval =
        configuredRefreshInterval < 2 || configuredRefreshInterval > 120
          ? DEFAULT_REFRESH_INTERVAL_MINUTES
          : configuredRefreshInterval;

      handle = setInterval(() => {
        const date = dateUtils.formatISO(newDate());
        dispatch(schedulerActions.setDate(date));
      }, refreshInterval * 60000);
    }

    return () => clearInterval(handle);
  }, [calendarOnly, dispatch, intervalRefreshingCalendar, newDate]);

  // if (!calendarOnly) {
  //   return null;
  // }

  return <FullScreenButton selector="#main_div_content" />;
}
