import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { workOrderActions as actions } from '.';
import { WorkOrderApi as api } from 'api/WorkOrderApi';
import { FileStoreApi } from 'api/fileStoreApi';
import {
  ConvertModelToEntity,
  ConvertNewWorkOrdersToModel,
  DowntimeOverlaps,
  ReservationsOfflineData,
  SendEmailOnOffline,
  WorkOrderDetailsState,
  WorkOrderGlobalState,
  WorkOrderQueryStringParameters,
  WorkOrderResponse,
} from './types';
import { appSettingsActions } from 'app/slice';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import { AxiosError } from 'axios';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { WorkOrderDetailsProps } from '..';
import { IResponseType } from 'types/ResponseType';
import { dateUtils } from 'utils/date-utils';
import { isNullOrUndefined } from 'utils/typeUtils';
import { selectSavedLinkedOrders, selectUpdateAllRecurrent } from './selectors';
import { FileTypes } from 'api/odata/generated/enums/FileTypes';
import { CustomFormTypeEnum } from 'enums/CustomFormType';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { getFilesModel, UpdateFilesModel } from 'utils/fileStoreHelper';
import { IsEntity } from 'app/components/BasicTable/BasicFilter/AppliedFilterComponent';
import { UserProfileSettings } from 'types/UserProfileSettings';
import { routerActions } from 'connected-react-router';

function* doInitCreate(action: PayloadAction<WorkOrderQueryStringParameters>) {
  try {
    const data = yield call(api.initCreateData, action.payload);
    yield put(actions.initWorkOrder_Success(data));
  } catch (error: unknown) {
    yield put(actions.initWorkOrder_Error(error));
  }
}
function* doInitUpdate(action: PayloadAction<WorkOrderQueryStringParameters>) {
  try {
    const data: IWorkOrderDetailsDto = yield call(
      api.initUpdateData,
      action.payload,
    );
    if (!data || data === null) {
      yield put(routerActions.push({ pathname: '/notallowed', search: '' }));
    } else {
      // set the offline event start to value provided through the params
      // for example, if an alert is edited from the scheduler, params will have an alert id and new start/end dates
      // these dates should override current alert offline event start/end
      // the values have to pass through formatQueryStringDate since alert details does not accept ISO properly and will shift the dates otherwise
      if (action.payload.offStart !== undefined) {
        data.OfflineEventStart = action.payload.offStart;
      }
      if (action.payload.offEnd !== undefined) {
        data.OfflineEventEnd = action.payload.offEnd;
      }

      yield put(actions.initUpdateWorkOrder_Success(data));
    }
  } catch (error: unknown) {
    yield put(actions.initUpdateWorkOrder_Error(Error));
  }
}
function* doCreate(action: PayloadAction<WorkOrderGlobalState>) {
  const user: AuthenticatedUser | undefined = yield select(
    selectAuthenticatedUser,
  );
  const httpPayloads: IWorkOrderDetailsDto[] = ConvertNewWorkOrdersToModel(
    action.payload,
    user,
  );
  const submissionFormFiles =
    action.payload.workOrderDetails.SubmissionFormFiles;
  const savedLinkeCalibrations = yield select(selectSavedLinkedOrders);
  //console.log(httpPayloads);
  try {
    let responses: WorkOrderResponse[] = [];
    for (let idx = 0; idx < httpPayloads.length; idx++) {
      const result = yield call(api.insertWorkOrder, httpPayloads[idx]);
      responses.push(result);
    }
    let fileResponses: IResponseType[] = [];
    if (submissionFormFiles.length > 0) {
      let resp = responses.filter(f => f.Id !== undefined);
      for (let i = 0; i < resp.length; i++) {
        let data = getFilesModel(
          [],
          submissionFormFiles,
          FileTypes.AlertTypeFile,
          resp[i].Id || -1,
          CustomFormTypeEnum.AlertType,
          1,
          null,
          null,
          resp[i].WorkOrderTypeId || null,
        );
        let fileRes = yield call(FileStoreApi.saveFormFiles, data);
        fileResponses.push(fileRes);
      }
    }

    let responseErrors = responses
      .filter(err => err.ErrorMessages.length > 0)
      .flatMap(f => f.ErrorMessages);

    if (fileResponses.length > 0) {
      fileResponses.forEach(f => {
        if (f.ErrorMessages.length > 0) {
          responseErrors = [...responseErrors, ...f.ErrorMessages];
        }
      });
    }
    let responseWarnings = responses
      .filter(err => err.WarningMessages.length > 0)
      .flatMap(f => f.WarningMessages);
    let responseSuccess = responses
      .filter(err => err.SuccessMessages.length > 0)
      .flatMap(f => f.SuccessMessages);
    if (responseErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseErrors.map(item => {
            return {
              key: 'workOrderInsertError',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (responseWarnings.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseWarnings.map(item => {
            return {
              key: 'workOrderInsertWarning',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }

    if (responseSuccess.length > 0) {
      yield put(actions.setSaveNewComments(true));
      yield put(
        actions.setOrderNumbers(
          responses.map(f => f.Id ?? 0).filter(f => f !== 0),
        ),
      );
      for (let i = 0; i < responseSuccess.length; i++) {
        yield put(
          appSettingsActions.addNotification({
            key: 'workOrderInsertSuccess_' + i,
            message: responseSuccess[i],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: responses[i].Id,
              created: true,
              itemName: i18next.t(translations.WorkOrdersPageName),
              detailsType: RenderPageType.WorkOrderDetails,
              detailsTypeProps: {
                queryParams: {
                  id: '' + responses[i].Id,
                } as WorkOrderQueryStringParameters,
                useSidePanel: true,
              } as WorkOrderDetailsProps,
            },
            variant: 'success',
          }),
        );
        if (savedLinkeCalibrations.length > 0) {
          for (let idx = 0; idx < savedLinkeCalibrations.length; idx++) {
            for (let widx = 0; widx < responses.length; widx++) {
              yield put(
                actions.addLinkedCalibration({
                  id: responses[widx].Id ?? 0,
                  link: savedLinkeCalibrations[idx].Id,
                }),
              );
            }
          }
        }
      }
      yield put(
        appSettingsActions.updateUserProfileSettings({
          key: 'LastUsedDowntimeType',
          model: {
            UserName: user?.Id,
            Key: 'LastUsedDowntimeType',
            Value: action.payload.workOrderDetails.WorkOrderType?.Id?.toString(),
          } as UserProfileSettings,
        }),
      );
      yield put(layoutActions.setRefreshTable(true));
    }
    //REPLACED WITH THIS^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    //TO SHOW MULTIPLE NOTIFICATIONS FOR EACH CREATED WORK ORDER
    /* if (responseSuccess.length > 0) {
      let res = responses[0];
      yield put(
        appSettingsActions.addNotification({
          key: 'workOrderInsertSuccess',
          message: responseSuccess[0],
          messageType: SnackBarMessageType.openSidepanelDetails,
          messageTypeProps: {
            Id: res.Id,
            created: true,
            itemName: i18next.t(translations.WorkOrdersPageName),
            detailsType: RenderPageType.workOrderDetails,
            detailsTypeProps: {
              queryParams: {
                id: '' + res.Id,
              } as WorkOrderQueryStringParameters,
              useSidePanel: true,
            } as WorkOrderDetailsProps,
          },
          variant: 'success',
        }),
      );
      yield put(layoutActions.setRefreshTable(true));
    } */
    let hasErrors =
      responses.filter(f => f.ErrorMessages.length > 0).length > 0;
    let hasResOffline =
      responses.filter(f => f.sendEmailOnOffline === true).length > 0;
    let offData = responses
      .map(m => {
        return isNullOrUndefined<ReservationsOfflineData>(
          m.ReservationOffline[0],
        )
          ? undefined
          : {
              AlertId: m.ReservationOffline[0]?.AlertId,
              offStart: dateUtils.formatISO(
                dateUtils.dateOrStringToDate(m.ReservationOffline[0]?.offStart),
              ),
              offEnd: dateUtils.formatISO(
                dateUtils.dateOrStringToDate(m.ReservationOffline[0]?.offEnd),
              ),
              ReasonName: m.ReservationOffline[0].ReasonName,
              Remarks: m.ReservationOffline[0].Remarks,
              reservationIds: m.ReservationOffline[0].reservationIds,
            };
      })
      .filter(f => f !== undefined) as ReservationsOfflineData[];
    yield put(
      actions.createWorkOrder_Success({
        hasErrors: hasErrors,
        sendEmailOnOffline: hasResOffline,
        reservationOffline: offData,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'workOrderInsert',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createWorkOrder_Error(Error));
  }
}
function* doUpdate(
  action: PayloadAction<{
    current: WorkOrderDetailsState;
    original: WorkOrderDetailsState | undefined;
    originalSubFormFiles: IFormFileValue[];
    originalStaffFormFiles: IFormFileValue[];
  }>,
) {
  const httpPayloads: IWorkOrderDetailsDto = ConvertModelToEntity(
    action.payload.current,
    action.payload.original,
  );
  const submissionFormFiles = action.payload.current.SubmissionFormFiles;
  const staffFormFiles = action.payload.current.StaffFormFiles;
  const updateAll = yield select(selectUpdateAllRecurrent);
  httpPayloads.UpdateAll = updateAll;
  let filesData: UpdateFilesModel[] = [];
  if (
    submissionFormFiles.length > 0 ||
    action.payload.originalSubFormFiles.length > 0
  ) {
    filesData = getFilesModel(
      action.payload.originalSubFormFiles,
      submissionFormFiles,
      FileTypes.AlertTypeFile,
      action.payload.current.Id,
      CustomFormTypeEnum.AlertType,
      updateAll === true ? 3 : 1,
      action.payload.current.RecurringAssetId,
      action.payload.current.RecurringGroupId,
      action.payload.current.WorkOrderType?.Id || null,
    );
  }
  if (
    staffFormFiles.length > 0 ||
    action.payload.originalStaffFormFiles.length > 0
  ) {
    filesData = [
      ...filesData,
      ...getFilesModel(
        action.payload.originalStaffFormFiles,
        staffFormFiles,
        FileTypes.AlertFile,
        action.payload.current.Id,
        CustomFormTypeEnum.Alert,
        updateAll === true ? 3 : 1,
        action.payload.current.RecurringAssetId,
        action.payload.current.RecurringGroupId,
        null,
      ),
    ];
  }
  try {
    const results = yield call(api.updateWorkOrder, httpPayloads);
    let response = results as WorkOrderResponse;
    let respErrors = response.ErrorMessages;
    if (filesData.length > 0) {
      let fileRes = yield call(FileStoreApi.updateFormFiles, filesData);
      let fileResponses = fileRes as IResponseType;
      if (fileResponses.ErrorMessages.length > 0) {
        respErrors = [...respErrors, ...fileResponses.ErrorMessages];
      }
    }

    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'workOrderUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'workOrderUpdateWarn',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          key: 'workOrderUpdate',
          message: response.SuccessMessages[0],
          messageType: SnackBarMessageType.openSidepanelDetails,
          messageTypeProps: {
            Id: response.Id,
            created: false,
            itemName: i18next.t(translations.WorkOrdersPageName),
            detailsType: RenderPageType.WorkOrderDetails,
            detailsTypeProps: {
              queryParams: {
                id: '' + response.Id,
              } as WorkOrderQueryStringParameters,
              useSidePanel: true,
            } as WorkOrderDetailsProps,
          },
          variant: 'success',
        }),
      );
      yield put(layoutActions.setRefreshTable(true));
      if (response.AutoCreatedOrders.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: `workOrderUpdateAuto-${response.AutoCreatedOrders[0].AlertId}`,
            message: response.SuccessMessages[0],
            messageTypeProps: {
              Id: response.Id,
              created: false,
              itemName: 'The following next Work Order',
              itemEndName: `has been created and set to start at ${
                dateUtils.longDateTimeFormat(
                  response.AutoCreatedOrders[0].Date,
                ) ?? ''
              }`,
              detailsType: RenderPageType.WorkOrderDetails,
              detailsTypeProps: {
                queryParams: {
                  id: '' + response.AutoCreatedOrders[0].AlertId,
                } as WorkOrderQueryStringParameters,
                useSidePanel: true,
              } as WorkOrderDetailsProps,
            },
            variant: 'success',
          }),
        );
      }
    }
    let hasErrors = response.ErrorMessages.length > 0;
    let hasResOffline = response.sendEmailOnOffline === true;
    let offData =
      response.ReservationOffline.length > 0
        ? response.ReservationOffline.map(m => {
            return {
              AlertId: m.AlertId,
              offStart: dateUtils.formatISO(
                dateUtils.dateOrStringToDate(m.offStart),
              ),
              offEnd: dateUtils.formatISO(
                dateUtils.dateOrStringToDate(m.offEnd),
              ),
              ReasonName: m.ReasonName,
              Remarks: m.Remarks,
              reservationIds: m.reservationIds,
            };
          })
        : ([] as ReservationsOfflineData[]);
    yield put(
      actions.updateWorkOrder_Success({
        hasErrors: hasErrors,
        sendEmailOnOffline: hasResOffline,
        reservationOffline: offData,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'workOrderUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateWorkOrder_Error(Error));
  }
}
function* doSendReservationsOfflineEmail(
  action: PayloadAction<{
    cancel: boolean;
    deleted: boolean;
    insert: boolean;
    doNotNotifyUsers: boolean;
    allAffectedUsers: boolean;
    reservationOffline: ReservationsOfflineData[];
  }>,
) {
  try {
    const paload = {
      cancel: action.payload.cancel,
      deleted: action.payload.deleted,
      insert: action.payload.insert,
      doNotNotifyUsers: action.payload.doNotNotifyUsers,
      allAffectedUsers: action.payload.allAffectedUsers,
      sendData: action.payload.reservationOffline,
    } as SendEmailOnOffline;
    const result = yield call(api.sendEmailOnOffline, paload);
    let response = result as IResponseType;
    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.map(item => {
            return {
              key: 'sendOfflineEmail',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'sendOfflineEmail',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.SuccessMessages.map(item => {
            return {
              key: 'sendOfflineEmail',
              message: item,
              variant: 'success',
            };
          }),
        ),
      );
    }
    yield put(
      actions.sendReservationsOfflineEmail_Success(
        response.ErrorMessages.length > 0,
      ),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'sendOfflineEmail',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.sendReservationsOfflineEmail_Error(Error));
  }
}
function* doRemoveFromLinked(action: PayloadAction<number>) {
  const res = yield call(api.removefromLinks, action.payload);
  if (typeof res === 'number') {
    yield put(actions.removeFromLinked_success(res));
    return;
  }
  const response = res as IResponseType;
  yield put(
    appSettingsActions.addNotifications(
      response.ErrorMessages.map(r => ({ message: r, variant: 'error' })),
    ),
  );
}
function* doAddToLinked(action: PayloadAction<{ id: number; link: number }>) {
  try {
    const res = yield call(api.addToLinked, action.payload);
    if (IsEntity(res)) {
      yield put(actions.addToLink_success(res));
      return;
    }
    const response = res.value as IResponseType;
    yield put(
      appSettingsActions.addNotifications(
        response.ErrorMessages.map(r => ({ message: r, variant: 'error' })),
      ),
    );
  } catch (error) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        message: message,
        variant: 'error',
      }),
    );
  }
}
function* doRemoveLinkedCalibration(
  action: PayloadAction<{ id: number; link: number }>,
) {
  const res = yield call(api.removeLinkedCalibration, action.payload);
  const response = res as IResponseType;
  if (response.ErrorMessages.length > 0) {
    yield put(
      appSettingsActions.addNotifications(
        response.ErrorMessages.map(r => ({ message: r, variant: 'error' })),
      ),
    );
  }
  yield put(actions.removeLinkedCalibration_success());
}
function* doaddLinkedCalibration(
  action: PayloadAction<{ id: number; link: number }>,
) {
  try {
    const res = yield call(api.addLinkedToCalibration, action.payload);
    const response = res as IResponseType;
    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.map(r => ({ message: r, variant: 'error' })),
        ),
      );
    }
    yield put(actions.addLinkedCalibration_success());
  } catch (error) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        message: message,
        variant: 'error',
      }),
    );
  }
}
function* doSetAny(
  action: PayloadAction<{
    fieldKey: keyof WorkOrderDetailsState;
    fieldValue: any;
  }>,
) {
  yield put(actions.setAnyValueSuccess(action.payload));
}
function* doSetCreatedOrders(action: PayloadAction<number[]>) {
  yield put(actions.setOrderNumbers_success(action.payload));
}
function* doCheckDowntimeOverlaps(action: PayloadAction<number>) {
  try {
    const res = yield call(api.getOverlappingDowntimes, action.payload);
    const response = res as DowntimeOverlaps[];
    yield put(actions.checkDowntimeOverlaps_Success(response));
  } catch (error) {
    yield put(actions.checkDowntimeOverlaps_Success(undefined));
  }
}
export function* workOrderSaga() {
  yield takeLatest(actions.initWorkOrder.type, doInitCreate);
  yield takeLatest(actions.initUpdateWorkOrder.type, doInitUpdate);
  yield takeLatest(actions.createWorkOrder.type, doCreate);
  yield takeLatest(actions.updateWorkOrder.type, doUpdate);
  yield takeLatest(
    actions.sendReservationsOfflineEmail.type,
    doSendReservationsOfflineEmail,
  );
  yield takeLatest(actions.removeFromLinked.type, doRemoveFromLinked);
  yield takeLatest(actions.addToLinked.type, doAddToLinked);
  yield takeLatest(
    actions.removeLinkedCalibration.type,
    doRemoveLinkedCalibration,
  );
  yield takeLatest(actions.addLinkedCalibration.type, doaddLinkedCalibration);
  yield takeLatest(actions.setAnyValue.type, doSetAny);
  yield takeLatest(actions.setOrderNumbers.type, doSetCreatedOrders);
  yield takeLatest(actions.checkDowntimeOverlaps.type, doCheckDowntimeOverlaps);
}
