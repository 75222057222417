/**
 *
 * RadioGroup
 *
 */
import { RadioGroup as MuiRadioGroup } from '@material-ui/core';
import * as React from 'react';
import { Entity } from 'types/common';
import { Icon } from '../BasicIcons/FontAwesome';
import Radio from '../BasicInputs/Radio';
import { Tooltip } from '../BasicTooltips/Tooltip';
import FormControlLabel from '../Forms/FormControls/FormControlLabel';

export interface RadioGroupProps {
  options: Array<Entity<string>>;
  value?: string;
  className?: string;
  onChange?: (
    value: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  id: string;
  //formik
  name?: string;
  error?: boolean;
  label?: string;
  disabled?: boolean;
  helperText?: string;
  row?: boolean;
}

export function RadioGroup({
  name,
  id,
  value,
  options,
  onChange,
  error,
  row,
  helperText,
  ...props
}: RadioGroupProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    if (onChange !== undefined) {
      onChange(value, event);
    }
  };
  return (
    <MuiRadioGroup
      key={id}
      aria-label={id}
      name={name}
      value={value || ''}
      onChange={handleChange}
      row={row}
    >
      {options.map((option, index) => (
        <span key={option.Id}>
          <FormControlLabel
            key={option.Id}
            value={option.Id}
            control={<Radio key={option.Id} />}
            label={option.Name}
            disabled={props.disabled}
          />
          {(option as any).Name.length > 28 && (
            <Tooltip key={option.Id + index} title={(option as any).Name}>
              <span>
                <Icon
                  key={option.Id}
                  icon="info-circle"
                  color="default"
                  colorExtend="textHover"
                />
              </span>
            </Tooltip>
          )}
        </span>
      ))}
    </MuiRadioGroup>
  );
}
