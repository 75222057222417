import { CellProps, Renderer } from 'react-table';
import { Description } from 'app/components/Description';
import { BooleanRender } from '../../components/BooleanRender';

/**
 * Type of the record to reference. Record<string, any> will work for all cases, but can be substituted for specific type if needed.
 */
type recordType = Record<string, any>;
const DefaultRenderer: Renderer<CellProps<recordType>> = props => {
  const value = props.value;
  if (value === null || value === undefined) {
    return null;
  } else if (typeof value === 'string') {
    return <Description value={value} width="medium" />;
  } else if (typeof value === 'boolean') {
    return BooleanRender(props);
  }
  //TODO: add default renderers for other common types here
  else {
    return <>{String(value)}</>;
  }
};
export default DefaultRenderer;
