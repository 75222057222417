import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.billingActionsDetail || initialState;

export const selectBillingActionsDetails = createSelector(
  [selectSlice],
  state => state,
);
//====================================================================
export const selectCreateBillingActionsDetails = createSelector(
  [selectBillingActionsDetails],
  state => state.createState.data,
);

export const selectCreateBillingActionsDetailsCompleted = createSelector(
  [selectBillingActionsDetails],
  state => state.createState.completed,
);

export const selectCreateBillingActionsDetailsHasError = createSelector(
  [selectBillingActionsDetails],
  state => state.createState.hasErrors,
);
//====================================================================
export const selectUpdateBillingActionsDetails = createSelector(
  [selectBillingActionsDetails],
  state => state.updateState.data,
);

export const selectUpdateBillingActionsDetailsCompleted = createSelector(
  [selectBillingActionsDetails],
  state => state.updateState.completed,
);
export const selectUpdateBillingActionsDetailsHasError = createSelector(
  [selectBillingActionsDetails],
  state => state.updateState.hasErrors,
);
//====================================================================
export const selectBillingActionsDetailsProcessing = createSelector(
  [selectBillingActionsDetails],
  state => state.processing,
);
