import { PayloadAction } from '@reduxjs/toolkit';
import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { articlesSaga } from './saga';
import {
  ArticlesState,
  IExternalArticleSearchPayload,
  IExternalArticleSearchSuccessPayload,
} from './types';

export const initialState: ArticlesState = {
  externalSearchResults: {},
  externalSearchPageIndex: 0,
  externalSearchPageSize: 10,
  showEditArticleAssociation: false,
  articleDetailsBusy: false,
  editArticleAssociationBusy: false,
};

const slice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    showDetails(state, action: PayloadAction<IArticleDto>) {
      state.articleDetails = action.payload;
      state.articleDetailsBusy = true;
      state.editArticleAssociationBusy = false;
    },
    showDetails_Success(state, action: PayloadAction<IArticleDto | undefined>) {
      state.articleDetailsBusy = false;
      if (action.payload !== undefined) {
        if (state.articleDetails !== undefined) {
          state.articleDetails.Id = action.payload.Id;
          state.articleDetails.Assets = action.payload.Assets;
        }
      }
    },
    hideDetails(state, action: PayloadAction) {
      delete state.articleDetails;
    },
    showEditArticleAssociation(state, action: PayloadAction<boolean>) {
      state.showEditArticleAssociation = action.payload;
    },
    saveArticleAssociation(state, action: PayloadAction<IArticleDto>) {
      state.editArticleAssociationBusy = true;
    },
    saveArticleAssociation_Success(state, action: PayloadAction<IArticleDto>) {
      state.articleDetails = action.payload;
      state.showEditArticleAssociation = false;
      state.editArticleAssociationBusy = false;
    },
    saveArticleAssociation_Error(state, action: PayloadAction<IArticleDto>) {
      state.editArticleAssociationBusy = false;
    },

    externalSearch(
      state,
      action: PayloadAction<IExternalArticleSearchPayload>,
    ) {
      state.externalSearchFormDetails = action.payload.searchForm;
      state.externalSearchPageIndex = action.payload.pageIndex;
      state.externalSearchPageSize = action.payload.pageSize;
      state.externalSearchResults = {
        processing: true,
      };
    },
    chnagePage(state, action: PayloadAction<IExternalArticleSearchPayload>) {
      state.externalSearchResults.processing = true;
      if (state.externalSearchPageSize !== action.payload.pageSize) {
        state.externalSearchResults.data = {};
      }
      state.externalSearchPageIndex = action.payload.pageIndex;
      state.externalSearchPageSize = action.payload.pageSize;
    },
    externalSearch_Skip(state, action: PayloadAction) {
      state.externalSearchResults.processing = false;
    },
    externalSearch_Success(
      state,
      action: PayloadAction<IExternalArticleSearchSuccessPayload>,
    ) {
      state.externalSearchResults.processing = false;
      state.externalSearchResults.dataLength = action.payload.count;
      if (state.externalSearchResults.data === undefined) {
        state.externalSearchResults.data = {};
      }
      state.externalSearchResults.data[action.payload.pageIndex] =
        action.payload.items;
    },
    externalSearch_Error(state, action: PayloadAction<string>) {
      state.externalSearchResults = { error: action.payload };
    },
    someAction(state, action: PayloadAction<any>) {},
  },
});

export const { actions: articlesActions } = slice;

export const useArticlesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: articlesSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useArticlesSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
