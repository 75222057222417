import { createSelector } from '@reduxjs/toolkit';
import { allowedToViewAlert } from 'app/permissions/WorkOrders/workOrdersPermissionUtils';
import {
  selectAuthenticatedUser,
  selectGlobalServiceGroupFilter,
  selectglobalSettings,
  selectKnownModule,
  selectUserProfileSettings,
} from 'app/slice/selectors';
import { RootState } from 'types';
import { dateUtils } from 'utils/date-utils';
import { initialState } from '.';
import { getViewEndDate, getViewStartDate } from '../utils';
import { EventType, SchedulerState } from './types';
import { selectGlobalSetting } from 'app/slice/selectors';
import { AllowedSettings } from 'utils/globalSettings';
import { MultipleInstrumentReservations } from 'enums/MultipleInstrumentReservations';
import { selectSelectedSavedView } from 'app/pages/SavedViewsPage/SavedViewPage/slice/selectors';
import { selectIsMobileView } from 'app/Layout/FrontendLayout/slice/selectors';
import {
  compileHandlebarsTemplate,
  parse,
} from '../components/event_text/handlebars';
import { ExtractPathsVisitor } from '../components/event_text/ExtractPathsVisitor';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { union } from 'lodash';
import { Identifiable } from 'types/common';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { canEditReservation } from 'app/permissions/Reservations/reservationPermissions';
import { ServiceType } from 'enums/ServiceTypes';
import { IWorkingHoursDto } from 'api/odata/generated/entities/IWorkingHoursDto';
import { mapEvents } from '../utils/syncSchedulerEvents';
import { KnownModules } from 'types/KnownModules';

const selectSlice = (state: RootState) =>
  (state.scheduler || initialState) as SchedulerState;

export const selectScheduler = createSelector([selectSlice], state => state);

const selectEvents = createSelector([selectScheduler], state => state.events);

export const selectSchedulerEvents = createSelector([selectScheduler], state =>
  state.viewState?.date === undefined
    ? undefined
    : state.events[state.viewState?.date],
);

export const selectSchedulerOfflineHours = createSelector(
  [selectScheduler],
  state => state.offlineHours,
);
export const selectTrainingSessions = createSelector(
  [selectScheduler],
  state => state.trainingSessions,
);

export const selectSchedulerViewState = createSelector(
  [selectSlice],
  state => state.viewState,
);
export const selectSchedulerDateTs = createSelector(
  [selectSchedulerViewState],
  state => state?.date,
);
export const selectSchedulerViewType = createSelector(
  [selectSchedulerViewState],
  state => state?.viewType,
);

export const selectSchedulerDate = createSelector(
  [selectSchedulerDateTs],
  state => (state === undefined ? undefined : dateUtils.parseISO(state)),
);

export const selectNonWorkingHours = createSelector(
  [selectScheduler],
  state => state.nonWorkingHours,
);
export const selectSchedulerTempEvents = createSelector(
  [selectSlice],
  state => state.tempReservations,
);

export const selectLoadedServices = createSelector(
  [selectScheduler],
  state => state.selectedServices,
);
export const selectSchedulerServicesLimit = createSelector(
  [selectLoadedServices, selectglobalSettings, selectSchedulerViewState],
  (services, globalSettings, viewState) => {
    const limitKey =
      viewState?.viewType === 'calendar'
        ? 'CalendarServicesPickerCapacity'
        : viewState?.viewType === 'timeline'
        ? 'MaximumTimelineRowsLimit'
        : undefined;
    const limitString =
      limitKey === undefined ? undefined : globalSettings.TryGet(limitKey);
    const limitNumber =
      limitString === undefined || limitString === null
        ? undefined
        : parseInt(limitString);
    return limitNumber;
  },
);
export const selectSchedulerFilters = createSelector(
  [selectSlice],
  state => state.filters,
);
export const selectWithReservationsOnly = createSelector(
  [selectSchedulerFilters],
  state =>
    (state?.['WithReservationsOnly']?.value as Identifiable<boolean>)?.Id,
);
export const selectSchedulerServicesLimitReached = createSelector(
  [selectLoadedServices, selectSchedulerServicesLimit],
  (services, limitNumber) =>
    services !== undefined &&
    limitNumber !== undefined &&
    services?.length > limitNumber,
);
export const selectSchedulerSelectedServices = createSelector(
  [selectLoadedServices, selectSchedulerServicesLimit],
  (services, limitNumber) => {
    if (services === undefined) {
      return undefined;
    }
    if (limitNumber !== undefined && !isNaN(limitNumber)) {
      return services.slice(0, limitNumber);
    } else {
      return services;
    }
  },
);
export const selectSchedulerDerivedServices = createSelector(
  [selectScheduler],
  state => state.derivedServices,
);
/**
 * map reservations in scheduler prasable format
 */
const selectMappedReservations = createSelector(
  [selectEvents, selectSchedulerDateTs],
  (items, date) =>
    date === undefined
      ? undefined
      : mapEvents('reservation', items[date], f => ({
          id: String(f.Id),
          reservation_id: f.Id,
          start_date: f.StartTime,
          end_date: f.EndTime,
          color: f.EquipmentColor ?? undefined,
          service_id: f.EquipmentId,
          service_name: f.EquipmentName,
        })),
);
/**
 * map offline hours in scheduler prasable format
 */
const selectMappedOfflineHours = createSelector(
  [selectSchedulerOfflineHours, selectSchedulerDateTs],
  (items, date) =>
    date === undefined
      ? undefined
      : mapEvents('offline', items[date], offline => ({
          id: `offline_${offline.Id}`,
          alert_id: offline.AlertId ?? undefined,

          start_date: offline.StartTime,
          end_date: offline.EndTime,
          service_id: offline.EquipmentId,
          service_name: offline.EquipmentName,
          offline_id: offline.Id,
        })),
);
/**
 * map training session in scheduler prasable format
 */
const selectMappedTrainingSessions = createSelector(
  [selectTrainingSessions, selectSchedulerDateTs],
  (items, date) =>
    date === undefined
      ? undefined
      : mapEvents('trainingsession', items[date], item => {
          const x = {
            id: `trainingsession_${item.Id}`,
            reservation_id: item.ReservationId ?? undefined,
            start_date: item.StartTime,
            end_date: item.EndTime,
            service_id: item.EquipmentId,
            service_name: item.EquipmentName,
            color: item.EquipmentColor ?? undefined,
            trainingsession_id: item.Id,
          };
          return x;
        }),
);
/**
 * combine all items shown as events
 */
const selectMapped = createSelector(
  [
    selectMappedReservations,
    selectMappedOfflineHours,
    selectMappedTrainingSessions,
  ],
  (a, b, c) =>
    a === undefined && b === undefined && c === undefined
      ? undefined
      : [...(a ?? []), ...(b ?? []), ...(c ?? [])],
);
/**
 * Selects combined reservations, training sessions & offline hours used to be parsed by the dhtmlx scheduler
 */
export const selectSchedulerParseEvents = createSelector(
  [selectMapped, selectAuthenticatedUser, selectSchedulerViewType],
  (mappedEvents, authenticatedUser, viewType) => {
    var items =
      viewType === 'calendar'
        ? mappedEvents?.filter(
            f =>
              !(
                f.type === 'reservation' &&
                (f.original as ICalendarReservationDto).TrainingSignUp === true
              ),
          )
        : mappedEvents;
    return items?.map(item => {
      const viewType = item.type;

      let readonly = true;
      let notClickable = false;
      switch (viewType) {
        case 'reservation':
          readonly =
            !canEditReservation(authenticatedUser, {
              ServiceId: item.original.EquipmentId ?? 0,
              ServiceTypeId: ServiceType.Online,
              ServiceGroupId: item.original.ServiceGroupId ?? 0,
              UserId: (item.original as ICalendarReservationDto).BookedById,
            }) ||
            (item.original as ICalendarReservationDto).Restricted === true ||
            (item.original as ICalendarReservationDto).TrainingSignUp === true;
          notClickable =
            !canEditReservation(authenticatedUser, {
              ServiceId: item.original.EquipmentId ?? 0,
              ServiceTypeId: ServiceType.Online,
              ServiceGroupId: item.original.ServiceGroupId ?? 0,
              UserId: (item.original as ICalendarReservationDto).BookedById,
            }) ||
            (item.original as ICalendarReservationDto).Restricted === true;
          break;
        case 'offline':
          readonly = notClickable = !allowedToViewAlert(authenticatedUser, {
            ReportedBy: (item.original as IWorkingHoursDto).BookedById,
            ServiceGroupId: (item.original as IWorkingHoursDto).ServiceGroupId,
            ServiceId: item.original.EquipmentId ?? 0,
            ServiceTypeId: ServiceType.Online,
          });
      }

      return {
        ...item,
        readonly: readonly,
        notClickable: notClickable,
        // disables start event resize on timeline view
        _no_resize_end: readonly,
        // disabled end event drag on timelene view
        _no_resize_start: readonly,
        // disables drag on matrix view
        _no_drag_move: readonly,
      };
    });
  },
);
export const selectSchedulerServices = createSelector(
  [
    selectSchedulerSelectedServices,
    selectSchedulerDerivedServices,
    selectGlobalServiceGroupFilter,
    selectWithReservationsOnly,

    selectMapped,
  ],
  (
    selectedServices,
    derivedServices,
    serviceGroups,
    WithReservationsOnly,
    events,
  ) => {
    // use selectedServices if case a service have been selected
    // if no service was selected use the services of reservations returned by the filter
    const services =
      (selectedServices ?? []).length > 0 ? selectedServices : derivedServices;

    // show only services of the selected top service group filter if it has been applied
    const intermediate1 = filterServicesByServiceGroups(
      serviceGroups,
      services,
    );

    // applies WithReservationsOnly filter to the services
    const intermediate2 = filterServicesByEvents(
      events,
      intermediate1,
      WithReservationsOnly,
    );

    return intermediate2;
  },
);
export const selectSchedulerPreviousViewState = createSelector(
  [selectSlice],
  state => state.previousViewState,
);
const instrumentMultipleEnabled = state =>
  selectGlobalSetting(state, AllowedSettings.MultipleInstrumentReservations);

export const selectMultipleModeState = createSelector(
  [
    instrumentMultipleEnabled,
    selectSelectedSavedView,
    selectUserProfileSettings,
  ],
  (multipleInstrumentsSetting, currentSelected, userProfile) => {
    if (
      multipleInstrumentsSetting !== undefined &&
      MultipleInstrumentReservations[multipleInstrumentsSetting] !==
        MultipleInstrumentReservations.NotAllowed
    ) {
      // todo:

      const instrumentMultipleEnabled =
        userProfile.GetSettingValueBy(
          (f: { Key: string }) =>
            f.Key.toLowerCase() === 'BookMultipleInstruments'.toLowerCase(),
        )?.IsMultiple ?? undefined;
      if (!!currentSelected) {
        return currentSelected.Multiple;
      } else if (instrumentMultipleEnabled !== undefined) {
        return instrumentMultipleEnabled;
      } else {
        return (
          MultipleInstrumentReservations[multipleInstrumentsSetting] ===
          MultipleInstrumentReservations.AllowedDefaultMultiple
        );
      }
    } else {
      return undefined;
    }
  },
);

export const selectSchedulerViewStartDate = createSelector(
  [selectSchedulerViewState],
  state =>
    state === undefined
      ? undefined
      : getViewStartDate(state.viewLength, state.date, state.customStart),
);
export const selectSchedulerViewEndDate = createSelector(
  [selectSchedulerViewState],
  state =>
    state === undefined
      ? undefined
      : getViewEndDate(
          state.viewLength,
          state.date,
          state.customDays,
          state.customStart,
        ),
);
export const selectSchedulerViewCustomDays = createSelector(
  [selectSchedulerViewState],
  state => state?.customDays,
);
export const selectSchedulerViewCustomStart = createSelector(
  [selectSchedulerViewState],
  state => state?.customStart,
);
export const selectNotUseLocation = createSelector(
  [selectSlice],
  state => state.notUseLocation,
);
export const selectEmptySlotAdminClick = createSelector(
  [selectScheduler],
  state => state.emptySlotAdminClick,
);
export const selectSchedulerNewEventServices = createSelector(
  [
    selectSchedulerViewState,
    selectSchedulerTempEvents,
    selectSchedulerSelectedServices,
    selectSchedulerDerivedServices,
    selectIsMobileView,
  ],
  (
    viewState,
    tempReservation,
    selectedServices,
    derivedServices,
    isMobileView,
  ) => {
    // timeline + daily calendar (unit) has separate row/column for each service
    // so in this state new event is placed directly on an instrument
    const singleServiceEvent =
      viewState?.viewType === 'timeline' ||
      (viewState?.viewType === 'calendar' &&
        viewState.viewLength === 'day' &&
        !isMobileView);

    const getSingleServiceEventServices = () => {
      // try looking up both directly selected services and services derived from the events shown on the calendar when no service is directly selected
      const s =
        selectedServices?.find(f => f.Id === tempReservation?.service_id) ??
        derivedServices?.find(f => f.Id === tempReservation?.service_id);
      if (s === undefined) {
        return [];
      } else {
        return [s];
      }
    };
    const services =
      (singleServiceEvent
        ? getSingleServiceEventServices()
        : selectedServices) ?? [];

    return services;
  },
);
export const selectTwoDaysTimelineButtonEnabled = createSelector(
  [selectglobalSettings],
  globalSettings => {
    return globalSettings.GetBoolean('EnableTwoDaysTimelineButton');
  },
);
export const selectCustomDaysTimelineButtonEnabled = createSelector(
  [selectglobalSettings],
  globalSettings => {
    return globalSettings.GetBoolean('EnableCustomDaysTimelineButton');
  },
);
export const selectSelectedReservationEvents = createSelector(
  [selectSlice],
  state => state.selectedReservationEvents,
);

export const selectDefaultCustomDaysTimelineValue = createSelector(
  [selectglobalSettings],
  globalSettings => {
    const daysString = globalSettings.TryGet('DefaultCustomDaysTimelineValue');
    const daysNumber =
      daysString === undefined || daysString === null
        ? undefined
        : parseInt(daysString);
    return daysNumber;
  },
);

export const selectSchedulerReservationBodyTemplate = createSelector(
  [
    (state: RootState) =>
      selectGlobalSetting(
        state,
        AllowedSettings.SchedulerReservationBodyTemplate,
      ),
  ],
  f => compileHandlebarsTemplate(f),
);

export const selectSchedulerReservationTooltipTemplate = createSelector(
  [
    (state: RootState) =>
      selectGlobalSetting(
        state,
        AllowedSettings.SchedulerReservationTooltipTemplate,
      ),
  ],
  f => compileHandlebarsTemplate(f),
);

export const selectCalendarReservationColumns = createSelector(
  [
    (state: RootState) =>
      selectGlobalSetting(
        state,
        AllowedSettings.SchedulerReservationBodyTemplate,
      ),

    (state: RootState) =>
      selectGlobalSetting(
        state,
        AllowedSettings.SchedulerReservationTooltipTemplate,
      ),
    (state: RootState) =>
      selectKnownModule(state, KnownModules.EquipmentAssemblies),
    (state: RootState) =>
      selectGlobalSetting(state, AllowedSettings.AllowWorkflowBooking),
  ],
  (t1, t2, EquipmentAssemblies, workflowBooking) => {
    const y = new ExtractPathsVisitor();
    y.accept(parse(t1));
    y.accept(parse(t2));
    const reservationHistorySelectArray: Array<
      keyof ICalendarReservationDto
    > = [
      'Id',
      'StartTime',
      'EndTime',
      'EquipmentId',
      'EquipmentName',
      'ServiceGroupId',
      'EquipmentColor',
      'BookedBy',
      'BookedById',
      'StatusId',
      'Tutoring',
      'Restricted',
      'TrainingSignUp',
      'FullDayReservation',
    ];
    if (EquipmentAssemblies) {
      reservationHistorySelectArray.push('TopAssemblyReservationId');
    }
    if (workflowBooking) {
      reservationHistorySelectArray.push('WorkFlowBookingName');
    }
    const p = union(reservationHistorySelectArray, y.paths);
    return p;
  },
);
function filterServicesByServiceGroups(
  serviceGroups?: Identifiable<number>[],
  services?: IServiceFilterDto[],
) {
  const serviceGroupIds = (serviceGroups ?? [])?.map(f => f.Id);
  return serviceGroupIds.length === 0
    ? services
    : services?.filter(
        service =>
          service.ServiceGroupId === undefined ||
          serviceGroupIds.includes(service.ServiceGroupId),
      );
}

function filterServicesByEvents(
  events: Array<{ type: EventType; service_id: number }> | undefined,
  services: IServiceFilterDto[] | undefined,
  WithReservationsOnly: boolean | undefined,
) {
  if (WithReservationsOnly === true) {
    const servicesWithEvents = new Set(
      events
        ?.filter(f => f.type === 'reservation' || f.type === 'trainingsession')
        .map(f => f.service_id),
    );

    const xx = services?.filter(service => servicesWithEvents.has(service.Id));
    return xx;
  } else {
    return services;
  }
}

export const selectSchedulerLoadingEvents = createSelector(
  [selectSlice],
  state => state.loadingEvents,
);

export const selectTimelineGroupBy = createSelector(
  [selectSchedulerViewState],
  state => state?.groupBy,
);

export const selectExportToPDF = createSelector(
  [selectSlice],
  state => state.exportToPDF,
);
