/**
 *
 * SamplePlatePicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { ISamplePlateDto } from 'api/odata/generated/entities/ISamplePlateDto';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { EditorProps } from 'app/pages/Samples/EditableTable/EditableCell';
import { ISample } from 'app/pages/Samples/SamplesListPage/slice/types';
import * as React from 'react';
import { Entity } from 'types/common';

export interface SamplePlatePickerProps
  extends Omit<
      AutocompletePickerProps<Entity<string | null>>,
      'loadData' | 'onChange' | 'onBlur' | 'value' | 'name' | 'error'
    >,
    EditorProps<string | null> {
  serviceGroupId?: number;
  placeholder?: string;
  helperText?: string;
  values?: ISample;
}

const url = '/api/odata/v4/SamplePlates';
export const initSamplePlateData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (serviceGroupId?: number) => (searchTerm: string | null) => {
  const params: { $orderby: string; $filter?: string; $select: string } = {
    $orderby: 'Id asc',
    $select: 'Id',
  };
  const predicates: Condition<ISamplePlateDto>[] = [
    new Condition('Active', ODataOperators.Equals, true),
  ];
  if (serviceGroupId !== undefined) {
    predicates.push(
      new Condition('ServiceGroupId', ODataOperators.Equals, serviceGroupId),
    );
  }
  params.$filter = new ODataFilterBuilder<ISamplePlateDto>({
    predicates,
    stringColumns: ['Id'],
    stringSearch: searchTerm ?? undefined,
  }).toString();

  return httpClient
    .get(url, params)
    .then(response =>
      response.value.map(item => ({ Id: item.Id, Name: item.Id })),
    );
};
export function SamplePlatePicker({
  parentValue,
  serviceGroupId,
  value,
  values,
  onChange,
  ...props
}: SamplePlatePickerProps) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDatax = React.useCallback(loadData(values?.ServiceGroupId), [
    values?.serviceGroupId,
  ]);
  const S2Value = value === null ? null : { Id: value, Name: value };
  return (
    <AutocompletePicker
      loadData={loadDatax}
      id={props.id || 'samplePlateId'}
      size={props.size}
      onChange={value => onChange(value?.Id ?? null)}
      value={S2Value}
      {...props}
    />
  );
}
