/**
 *
 * ServiceGroupFilter
 *
 */

import { ServiceGroupPicker } from 'app/components/pickers/ServiceGroupPicker';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'app/slice/selectors';

export const ServiceGroupFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  const isAuth = useSelector(selectIsAuthenticated);
  return (
    <ServiceGroupPicker
      onChange={handleOnChange}
      urlType={isAuth === true ? 'base' : 'public'}
      value={props.value}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      ariaLabel={props.label}
      id={props.id}
      predicates={props.predicates}
      size="small"
      onPickerOpen={props.onOpen}
    />
  );
};
export default ServiceGroupFilter;
