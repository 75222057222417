/**
 *
 * PopupImport
 *
 */
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '../BasicButtons/Button';
import { DialogConfirm } from '../DialogConfirm';
import { InfoIcon } from '../InfoIcon';
import parse from 'html-react-parser';

import { uniqueId } from 'lodash';
import BasicTypography from '../Typography/BasicTypography';

export interface PopupImportProps
  extends Pick<ButtonProps, 'size' | 'variant'> {
  onImportButtonClick: () => void;
  onDownloadTemplateButtonClick?: () => Promise<void>;
  onConfirm: (event, file: any) => void;
  onCancel: () => void;
  processing: boolean;
  open: boolean;
  title?: string;
  accept: '.xlsx' | '.xls' | '.csv';
  disabled?: boolean;
  hideImportButton?: boolean;
  hideMessage?: boolean;
}

export function PopupImport(props: PopupImportProps) {
  const { t } = useTranslation();
  const ref = React.useRef<HTMLInputElement>(null);
  const handleConfirm = event => {
    const file = ref.current?.files?.[0];
    if (file !== undefined) {
      props.onConfirm(event, file);
    }
  };
  const [
    downloadTemplateprocessing,
    setDownloadTemplateProcessing,
  ] = React.useState<boolean>(false);
  const [fileId] = React.useState(uniqueId('import-file-'));
  const [buttonId] = React.useState(uniqueId('import-file-'));
  return (
    <>
      {props.hideImportButton === undefined ||
        (props.hideImportButton === false && (
          <Button
            disabled={props.disabled}
            onClick={props.onImportButtonClick}
            size={props.size}
            variant={props.variant}
          >
            {t(translations.Import)}
          </Button>
        ))}
      <DialogConfirm
        onConfirm={handleConfirm}
        processing={props.processing}
        onCancel={props.onCancel}
        isOpen={props.open}
        confirmButtonLabel={t(translations.Import)}
        title={props.title ?? t(translations.ImportRecords)}
        body={
          <>
            {props.onDownloadTemplateButtonClick !== undefined && (
              <BasicTypography>
                {t(translations.ImportRecordsDescription)}
                <InfoIcon title={parse(t(translations.ImportRecordsSteps))} />
              </BasicTypography>
            )}
            {props.onDownloadTemplateButtonClick !== undefined && (
              <BasicTypography>
                <label htmlFor={buttonId}>
                  {t(translations.ToImportNewRecords)}:
                </label>
                &emsp;
                <Button
                  id={buttonId}
                  onClick={async () => {
                    if (props.onDownloadTemplateButtonClick !== undefined) {
                      setDownloadTemplateProcessing(true);
                      await props.onDownloadTemplateButtonClick();
                      setDownloadTemplateProcessing(false);
                    }
                  }}
                  processing={downloadTemplateprocessing}
                >
                  {t(translations.DownloadTemplate)}
                </Button>
              </BasicTypography>
            )}
            <BasicTypography>
              {props.hideMessage === undefined ||
                (props.hideMessage === false && (
                  <label htmlFor={fileId}>
                    {t(translations.UploadTheUpdatedFile)}:
                  </label>
                ))}
              &emsp;
              <input
                id={fileId}
                type="file"
                name="file"
                ref={ref}
                accept={props.accept}
              />
            </BasicTypography>
          </>
        }
      />
    </>
  );
}
