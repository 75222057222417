import { useCalendarLink } from 'app/pages/CalendarPage/GetCalendarPagePath';
import { ViewTypes } from 'app/pages/CalendarPage/Scheduler/slice/types';
import { Button, ButtonProps } from '../BasicButtons/Button';

export interface CalendarButtonProps extends Omit<ButtonProps, 'href'> {
  equipments?: Array<number | undefined>;
  start?: string | Date;
  openToBook?: boolean;
  viewType?: ViewTypes;
  defaultMulti?: boolean;
}

export function CalendarButton({
  equipments,
  start,
  openToBook,
  viewType = 'calendar',
  defaultMulti,
  ...props
}: CalendarButtonProps) {
  const link = useCalendarLink({
    equipments,
    openToBook,
    start,
    viewType,
    defaultMulti,
  });
  return <Button href={link} {...props} />;
}
