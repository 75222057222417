/**
 *
 * FilterBox
 *
 */
import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { DetectIsMobile } from 'utils/mobileDetect';
import clsx from 'clsx';

export interface FilterBoxCoreProps {
  direction: Directions;
}
export interface FilterBoxProps
  extends React.PropsWithChildren<
    FilterBoxCoreProps & React.HTMLAttributes<HTMLElement>
  > {}

export const FilterBox = React.forwardRef(function FilterBox(
  props: FilterBoxProps,
  ref: any,
) {
  const isMobile = DetectIsMobile();
  const classes = useStyles({
    isMobile: isMobile,
    direction: props.direction,
  });
  return (
    <div
      className={clsx(classes.root, {
        isMobile: isMobile,
        horizontal: props.direction === 'horizontal',
      })}
      ref={ref}
      {...props}
    >
      {props.children}
    </div>
  );
});

export type Directions = 'vertical' | 'horizontal';

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      gap: 8,
      position: 'relative',
      '&.isMobile': {
        display: 'inline-flex',
        flexWrap: 'wrap',
      },
      '&.horizontal': {
        display: 'inline-flex',
        flexWrap: 'wrap',
      },
    },
  });
});
