/**
 * Asynchronously loads the component for CalendarPage
 */
import { withGlobalServiceGroupFilter } from 'app/components/BasicTable/withGlobalServiceGroupFilter';
import { lazyLoad } from 'utils/loadable';
import { withSaveAsLastVisitedPage } from '../../hocs/withSaveAsLastVisitedPage';

export const CalendarPage = lazyLoad(
  () => import('./index'),
  module =>
    withSaveAsLastVisitedPage(
      withGlobalServiceGroupFilter(module.CalendarPage, true),
    ),
);

export const CalendarPageDefaultRedir = lazyLoad(
  () => import('./index'),
  module => module.CalendarPageDefaultRedir,
);
