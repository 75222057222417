export enum OnlineServiceType {
  Software = 0,
  Relay = 1,
  IpRelay = 2,
  UsageForm = 3,
  DoorAccess = 4,
  WebRelayTm = 5,
  NiDAQmx = 7,
  LoanDesk = 6,
  Room = 10,
  KmTronicRelay = 11,
  WebRelayX600m = 12,
  RaspberryPi = 13,
}
