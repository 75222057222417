/**
 *
 * FormServiceRequestMilestoneStatusPicker
 *
 */

import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import {
  ServiceRequestMilestoneStatusPicker,
  ServiceRequestMilestoneStatusPickerProps,
} from '../pickers/StaticOptionsPickers/ServiceRequestMilestoneStatusPicker';
import { ServiceRequestMilestoneStatusesUnion } from 'api/odata/generated/enums/ServiceRequestMilestoneStatuses';

export const FormServiceRequestMilestoneStatusPicker = ({
  label,
  ...props
}: FieldHookConfig<ServiceRequestMilestoneStatusesUnion | null> &
  Omit<IFieldProps, 'onChange'> &
  Pick<ServiceRequestMilestoneStatusPickerProps, 'onChange'>) => {
  const [
    field,
    meta,
    helpers,
  ] = useField<ServiceRequestMilestoneStatusesUnion | null>(props);
  return (
    <>
      <ServiceRequestMilestoneStatusPicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        variant="filled"
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        info={props.info}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
      />
    </>
  );
};
