import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
//import { selectPublicUrl } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
////import { useSelector } from 'react-redux';
import * as React from 'react';
import { CoreSelectionTable } from './Table/CoreSelectionTable';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';
import { toURLSearchParams } from 'app/components/BasicTable/withSavedHistory';
import { useDispatch, useSelector } from 'react-redux';
import { useCoreSelectionSlice } from './slice';
import { selectCoreSelectionFilters } from './slice/selectors';
import { buildURL } from 'utils/url-utils';
import {
  selectAuthenticatedUser,
  selectglobalSettings,
  selectPublicUrl,
} from 'app/slice/selectors';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { useProfileSetting } from 'app/components/BasicTable/useProfileSetting';
import { selectHideNavigation } from 'app/Layout/FrontendLayout/slice/selectors';
import { Row } from 'react-table';
import { AllowedSettings } from 'utils/globalSettings';
import { ServiceGroupRegistrationStatus } from 'enums/ServiceGroupRegistrationStatus';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';

export interface CoreSelectionProps extends SidePanelContentProps {}

export function CoreSelection(props: CoreSelectionProps) {
  const { useSidePanel } = props;
  const publicUrl = useSelector(selectPublicUrl);
  const { t } = useTranslation();
  const { actions: layoutActions } = useLayoutSlice();
  const settings = useSelector(selectglobalSettings);
  const noDashboardRedirectByClick =
    settings.GetBoolean(
      AllowedSettings[AllowedSettings.NoDashboardRedirectOnCoreSelection],
    ) === true;
  const redirectToDash = React.useMemo(() => {
    return (
      window.location.pathname.indexOf('/dashboards/core/') >= 0 ||
      window.location.pathname.indexOf('/systemdash') >= 0 ||
      !noDashboardRedirectByClick
    );
  }, [noDashboardRedirectByClick]);

  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const filters = useSelector(selectCoreSelectionFilters);
  const hideNavigation = useSelector(selectHideNavigation);
  const dispatch = useDispatch();
  const { actions } = useCoreSelectionSlice();
  // const openAsset = useOpenAssetPopUp();
  // const history = useHistory();
  const [cover, setCover] = React.useState<React.ReactNode>();
  const {
    savedEnabled: isFirstLogin,
    updateEnabled: updateIsFirstLogin,
  } = useProfileSetting({
    profileSettingKey: 'IsFirstLoginUser',
  });
  const {
    savedEnabled: OptOutCoreSelection,
    updateEnabled: updateIsOptOut,
  } = useProfileSetting({
    profileSettingKey: 'CoreSelectionOptOut',
  });
  const pagelink = React.useMemo(
    () => buildURL(`${publicUrl}coreselection`, filters ?? {}),
    [filters, publicUrl],
  );
  useEffectOnMount(() => {
    dispatch(
      layoutActions.setHideNavigation(
        !!authenticatedUser &&
          !authenticatedUser.HasOptOutCoreSelection &&
          !useSidePanel &&
          isFirstLogin,
      ),
    );
  });
  const closeCover = () => {
    setCover(undefined);
  };

  const closeSidePanel = React.useCallback(() => {
    if (isFirstLogin && hideNavigation) {
      updateIsFirstLogin(false);
    }
    dispatch(layoutActions.resetSidePanel());
  }, [
    dispatch,
    hideNavigation,
    isFirstLogin,
    layoutActions,
    updateIsFirstLogin,
  ]);
  const isRowSelectable = (row: Row<ICoresDirectoryRow>): boolean => {
    let isAdmin = authenticatedUser?.AdminServiceGroups?.some(
      sg => sg.Id === row.original.Id,
    );
    let canSwitch =
      row.original.Private === false ||
      isAdmin === true ||
      row.original.ApprovalStatusId === ServiceGroupRegistrationStatus.Approved;
    return canSwitch;
  };
  // const primaryAction = (row: Row<ICoresDirectoryRow>) => {
  //   if (useSidePanel) {
  //     if (redirectToDash) {
  //       dispatch(layoutActions.resetSidePanel());
  //       history.push('/dashboards/core/' + row.original.Id);
  //     } else {
  //       dispatch(layoutActions.resetSidePanel());
  //       // setCover(
  //       //   <AssetPopUp
  //       //     closeCover={closeCover}
  //       //     isCover
  //       //     Id={row.original.AssetId ?? undefined}
  //       //     onClose={closeCover}
  //       //   />,
  //       // );
  //     }
  //   } else {
  //     if (hideNavigation || redirectToDash) {
  //       history.push('/dashboards/core/' + row.original.Id);
  //     } else {
  //       if (!redirectToDash) {
  //         openAsset({ Id: row.original.AssetId ?? undefined });
  //       }
  //     }
  //   }
  // };
  const setfilterCallback = React.useCallback(
    (v: IFilterSettings<ICoresDirectoryRow>[] | undefined) => {
      const res = {};
      if (!!v) {
        toURLSearchParams(v).forEach((val, name) => {
          res[name] = val;
        });
      }
      dispatch(actions.setFilters(!v || v.length === 0 ? undefined : res));
    },
    [actions, dispatch],
  );
  return (
    <PageWrapper
      pageLink={pagelink}
      useSidePanel={useSidePanel}
      //loading={loading}
      //pageName={<Link href="coreselection">{t(translations.menu_CoreSelection)}</Link>}
      titlePage={t(translations.menu_CoreSelection)}
      closable={useSidePanel}
      //disableExpandToggle={true}
      //rightTopActions={topButtons}
      isCover={false}
      hideTitleBar={true}
      cover={cover}
      closeCover={closeCover}
      children={
        <CoreSelectionTable
          isSidePanel={useSidePanel}
          setFilters={setfilterCallback}
          authenticatedUser={authenticatedUser}
          closeSidePanel={closeSidePanel}
          updtFirstLogin={updateIsFirstLogin}
          updtOptOut={updateIsOptOut}
          optOutCoreSelection={OptOutCoreSelection}
          isRowSelectable={isRowSelectable}
          redirectToDash={redirectToDash}
          isFirstLogin={isFirstLogin}
        />
      }
    />
  );
}
