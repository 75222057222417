import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ReactActions } from 'app/components/ReactActions';
import { ActionRenderer } from '../PageActions/ActionRender';
import { Body } from 'app/components/Typography';
import { Button } from 'app/components/BasicButtons/Button';
import { useTheme } from '@material-ui/core';
import { TopButtonsContainer } from '../styled';

export interface ButtonsPanelProps {
  leftActions?: ActionRenderer[];
  rightActions?: ActionRenderer[];
  isMobile: boolean;
  sidePanelExpanded: boolean;
  disableExpandToggle: boolean | undefined;
  useSidePanel: boolean;
  onSubmit?: () => void;
  expandPanel: () => void;
  leftActionsMaxLength?: number;
}

export function ButtonsPanel(props: ButtonsPanelProps) {
  const { t } = useTranslation();
  const {
    leftActions,
    rightActions,
    isMobile,
    sidePanelExpanded,
    disableExpandToggle,
    useSidePanel,
    onSubmit,
    expandPanel,
    leftActionsMaxLength = 1,
  } = props;
  const theme = useTheme();
  return (
    <TopButtonsContainer theme={theme}>
      <div className={'leftButtons'}>
        <React.Fragment>
          {!!leftActions && leftActions?.length > 0 && (
            <React.Fragment>
              {leftActions.length > leftActionsMaxLength ? (
                <React.Fragment>
                  {[...Array(leftActionsMaxLength).keys()].map(x => (
                    <span key={`Act${x}`}>{leftActions[x](onSubmit)}</span>
                  ))}
                  {!isMobile && useSidePanel && !disableExpandToggle && (
                    <Button
                      variant="ghost"
                      size="small"
                      id="pw_bp_showMore"
                      onClick={expandPanel}
                    >
                      <Body bold={true} size="small">
                        {sidePanelExpanded
                          ? t(translations.ShowLess)
                          : t(translations.MoreOptionsMobile)}
                      </Body>
                    </Button>
                  )}
                  <ReactActions
                    id={`left_bottom_actions`}
                    iconVariant="ghost"
                    size="small"
                    items={leftActions
                      .filter((item, index) => index > leftActionsMaxLength - 1)
                      .filter(item => item !== undefined)
                      .map((item, index) => (
                        <span key={index}>{item(onSubmit)}</span>
                      ))}
                  ></ReactActions>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {leftActions?.map((item, index) => (
                    <span key={index}>{item(onSubmit)}</span>
                  ))}
                  {!isMobile && useSidePanel && !disableExpandToggle && (
                    <Button
                      variant="ghostColor"
                      size="small"
                      id="pw_bp_showMore"
                      onClick={expandPanel}
                    >
                      <Body bold={true} size="small">
                        {sidePanelExpanded
                          ? t(translations.ShowLess)
                          : t(translations.MoreOptionsMobile)}
                      </Body>
                    </Button>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
      <div className={'rightButtons'}>
        <>
          {!!rightActions &&
            rightActions?.length > 0 &&
            rightActions?.map((item, index) => (
              <span key={index}>{item(onSubmit)}</span>
            ))}
        </>
      </div>
    </TopButtonsContainer>
  );
}
