/**
 *
 * Asynchronously loads the component for Building Page
 *
 */

import { lazyLoad } from 'utils/loadable';

export const BuildingPage = lazyLoad(
  () => import('./index'),
  module => module.BuildingPage,
);
