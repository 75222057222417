/**
 *
 * FullScreenButton
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useDispatch, useSelector } from 'react-redux';
import { appSettingsActions } from 'app/slice';
import { selectIsFullScreen } from 'app/slice/selectors';
import { TopActionButton } from '../BasicButtons/TopActionButton';
import { IconProp } from 'app/AllIcons';

export interface FullScreenButtonProps {
  selector?: string;
}

/**
 * Icon button to switch elemnt/document into full screen mode in the browser
 * @param props.selector element selector to show in full screen mode. optionnal - documentElement is used if undefined
 * @returns
 */
export function FullScreenButton(props: FullScreenButtonProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isFullScreen = useSelector(selectIsFullScreen);
  const handleClick = () => {
    if (isFullScreen) {
      dispatch(appSettingsActions.exitFullScreen());
    } else {
      dispatch(
        appSettingsActions.enterFullScreen({ selector: props.selector }),
      );
    }
  };
  const icon = isFullScreen
    ? (['fal', 'minimize'] as IconProp)
    : (['fal', 'maximize'] as IconProp);
  return (
    <TopActionButton
      onClick={handleClick}
      size="small"
      variant="white"
      icon={icon}
      startIcon={icon}
      title={t(translations.OpenInFullScreen)}
      text={t(translations.OpenInFullScreen)}
    />
  );
}
