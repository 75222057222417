/**
 *
 * FormPriorityPicker
 *
 */

import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { PriorityPicker } from '../pickers/StaticOptionsPickers/PriorityPicker';
import { PrioritiesUnion } from 'api/odata/generated/enums/Priorities';

export const FormPriorityPicker = ({
  label,
  ...props
}: FieldHookConfig<PrioritiesUnion | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<PrioritiesUnion | null>(props);
  return (
    <>
      <PriorityPicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        variant="filled"
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        info={props.info}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
      />
    </>
  );
};
