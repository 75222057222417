/**
 *
 * ServiceTypeFilter
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { ServiceTypePicker } from 'app/components/pickers/AutocompletePickers/ServiceTypePicker';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const ServiceTypeFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <ServiceTypePicker
      onChange={handleOnChange}
      value={props.value as Entity<number> | undefined}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      additionalItem={props.additionalItem as Entity<number> | undefined}
      size="small"
      ariaLabel={props.label}
      id={props.id}
    />
  );
};
export default ServiceTypeFilter;
