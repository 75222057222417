/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';

import { createReducer as createReducerWithRouter } from './reducers';
import { history } from './history';
import { barcodeScannerMiddleware } from './BarcodeScanner';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const { run: runSaga } = sagaMiddleware;

  // List middlewares here
  const middlewares = [
    // Create the store with saga middleware
    sagaMiddleware,
    // routerMiddleware is needed to to dispatch history actions (e.g. to change URL with push('/path/to/somewhere')).
    createRouterMiddleware(history),
    barcodeScannerMiddleware,
  ];

  // dev logging
  // Log only in development
  if (process.env.NODE_ENV === `development`) {
    const { createLogger } = require(`redux-logger`);
    middlewares.push(
      createLogger({
        collapsed: true,
      }),
    );
  }

  // Provide the created history to the root reducer creator.
  const createReducer = createReducerWithRouter(history);

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: [
      ...middlewares,
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: [
            'workOrder.createState.data.StaffFormFiles',
            'workOrder.createState.data.SubmissionFormFiles',
          ],
          ignoredActionPaths: [
            'payload.workOrderDetails.SubmissionFormFiles',
            'payload.workOrderDetails.StaffFormFiles',
            'payload.current.SubmissionFormFiles',
            'payload.current.StaffFormFiles',
            'payload.customFormFiles',
            'payload.currentCustomFormFiles',
            'payload.originalCustomFormFiles',
            'payload.state.customFormFiles',
          ],
        },
      }),
    ],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
  });

  return store;
}
