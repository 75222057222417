/**
 *
 * PosLayout
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useStyles } from './styles';

export interface PosLayoutProps {
  children: React.ReactNode;
}

/**
 * Empty Layout optimized for touch enabled devices - medium screen size
 * Originally developed for a POS (large anminals - technion anm)
 */
export function PosLayout({ children }: PosLayoutProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
  );
}
