/**
 *
 * ServiceRequestSubmissionDetails
 *
 */
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ServiceRequestStatusPicker } from 'app/components/pickers/AutocompletePickers/ServiceRequestStatusPicker';
import { useDispatch, useSelector } from 'react-redux';
import { selectRequestDetailsFieldsState } from '../../RequestSamplesPage/slice/selectors';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { EventBudgetPicker } from '../EventBudgetPicker';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRightSection,
  FormRow,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { dateUtils } from 'utils/date-utils';
import { ReadonlyField } from '../ReadonlyField';
import { useServiceRequestStatusUpdate } from '../Utils';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { IRequestDetailsModel } from '../../RequestSamplesPage/slice/types';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';

export interface ServiceRequestSubmissionDetailsProps {
  requestDetails: IRequestDetailsModel;
  isAdmin: boolean;
  isOwner: boolean;
  sectionStyles?: React.CSSProperties;
}

export function ServiceRequestSubmissionDetails({
  requestDetails,
  ...props
}: ServiceRequestSubmissionDetailsProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const fieldsState = useSelector(selectRequestDetailsFieldsState);
  const { actions } = useRequestSamplesSlice();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthenticatedUser);
  const excludedQuoteStatuses: Array<InternalServiceRequestStatusesUnion> = [
    'QuoteSendToUser',
    'QuoteApproved',
    'WaitingForQuote',
  ];
  useEffectOnMount(() => {
    dispatch(actions.init());
  });
  const {
    changeStatus,
    fieldState: statusFieldState,
  } = useServiceRequestStatusUpdate({
    serviceRequestStatusId: requestDetails.Id,
  });
  const handleBudgetChange = value => {
    value !== null &&
      dispatch(
        actions.updateBudget({
          Id: requestDetails.Id,
          Budget: value,
        }),
      );
  };
  const handleStatusChange = value => {
    changeStatus({
      status: value,
    });
  };
  return (
    <StyledFormFieldsContainer>
      <FormLeftSection>
        <FormFieldsSection
          styleSection={props.sectionStyles}
          titleSection={t(translations.ServiceRequestSubmissionDetails)}
        >
          <FormRow>
            <ReadonlyField
              title={t(translations.UserGroup)}
              value={requestDetails.UserGroup?.Name}
            />
          </FormRow>
          <FormRow>
            <ReadonlyField
              title={t(translations.CreatedFor)}
              value={requestDetails.CreatedFor?.Name}
            />
          </FormRow>
          <FormRow>
            <ReadonlyField
              title={t(translations.CreatedBy)}
              value={requestDetails.CreatedBy?.Name}
            />
          </FormRow>
          <FormRow>
            <ReadonlyField
              title={t(translations.SubmittedBy)}
              value={requestDetails.SubmittedBy?.Name}
            />
          </FormRow>
          <FormRow>
            <ReadonlyField
              title={t(translations.SubmittedAt)}
              value={dateUtils.longDateTimeFormat(requestDetails.SubmittedAt)}
            />
          </FormRow>
          <FormRow>
            <ReadonlyField
              title={t(translations.UpdatedBy)}
              value={requestDetails.UpdatedBy?.Name}
            />
          </FormRow>
          <FormRow>
            <ReadonlyField
              title={t(translations.UpdatedAt)}
              value={dateUtils.longDateTimeFormat(requestDetails.UpdatedAt)}
            />
          </FormRow>
        </FormFieldsSection>
      </FormLeftSection>
      <FormRightSection>
        <FormFieldsSection
          styleSection={props.sectionStyles}
          titleSection={t(translations.StatusAndBudget)}
        >
          <FormRow fullRow>
            {props.isAdmin ? (
              <ServiceRequestStatusPicker
                label={t(translations.Status)}
                predicates={
                  !requestDetails.Service.SendQuoteToUser
                    ? [
                        new Condition<IServiceRequestStatusDto>(
                          'InternalStatusId',
                          ODataOperators.Excludes,
                          excludedQuoteStatuses,
                        ),
                      ]
                    : undefined
                }
                value={requestDetails.Status}
                disabled={statusFieldState?.status === 'pending'}
                disableClearable={true}
                onChange={handleStatusChange}
                fullWidth
                variant="filled"
              />
            ) : (
              <ReadonlyField
                title={t(translations.Status)}
                value={requestDetails.Status?.Name}
              />
            )}
          </FormRow>

          <FormRow fullRow>
            {props.isAdmin ? (
              <EventBudgetPicker
                userId={requestDetails.CreatedFor?.Id ?? authUser?.Id}
                userGroupId={
                  requestDetails.UserGroup?.Id ?? authUser?.ActiveUserGroup?.Id
                }
                isAdmin={props.isAdmin}
                service={requestDetails.Service}
                label={t(translations.BudgetNumber)}
                value={requestDetails.Budget}
                onChange={handleBudgetChange}
                disabled={fieldsState['Budget']?.status === 'pending'}
                fullWidth
                variant="filled"
              />
            ) : (
              <ReadonlyField
                title={t(translations.Budget)}
                value={requestDetails.Budget?.Name}
              />
            )}
          </FormRow>
        </FormFieldsSection>
      </FormRightSection>
    </StyledFormFieldsContainer>
  );
}
