/**
 *
 * AsyncPicker
 *
 */
import * as React from 'react';
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from '../BasicPickers/AutocompletePicker';
import { httpClient } from 'api/HttpClient';

export interface FormGradientPickerProps
  extends Omit<
    AutocompletePickerProps<IGradientDto>,
    'onBlur' | 'value' | 'error' | 'loadData' | 'onChange'
  > {
  serviceGroupId?: number;
}
export interface IGradientDto extends Entity<number> {
  EstimatedRuntimeMinutes: number;
}
export const FormGradientPicker = ({
  serviceGroupId,
  onChange,
  onBlur,
  ...props
}: FieldHookConfig<IGradientDto | null> & FormGradientPickerProps) => {
  const [field, meta, helpers] = useField<IGradientDto | null>(props);
  const handleChange: (value: IGradientDto | null) => void = (
    value: IGradientDto | null,
  ) => helpers.setValue(value, true);
  const loadData = React.useCallback(
    async (searchTerm: string | null): Promise<any> => {
      const data = await httpClient.get(`/odata.svc/Gradients`, {
        $filter:
          serviceGroupId === undefined
            ? undefined
            : `ServiceGroupId eq ${serviceGroupId}`,
        $orderby: 'Name',
      });
      const result: IGradientDto[] = data.d.map(item => ({
        Id: item.Id,
        Name: item.Name,
        EstimatedRuntimeMinutes: item.EstimatedRuntimeMinutes,
      }));
      return result;
    },
    [serviceGroupId],
  );
  return (
    <>
      <AutocompletePicker
        fullWidth
        loadData={loadData}
        onChange={handleChange}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        {...props}
      />
    </>
  );
};
