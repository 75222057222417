/**
 *
 * Asynchronously loads the component for AdHocReportListPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AdHocReportListPage = lazyLoad(
  () => import('./index'),
  module => module.AdHocReportListPage,
);
