import { IReservationHistoryDto } from 'api/odata/generated/entities/IReservationHistoryDto';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { ServiceType } from 'enums/ServiceTypes';
import { intersection } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  AuthenticatedUser,
  IIsAdminOrOwnerOfParams,
} from 'types/AuthenticatedUser';

export function useReservationPermissions() {
  const user = useSelector(selectAuthenticatedUser);
  const roles: number[] | undefined = user?.Roles;

  const allowedToView = React.useCallback(
    (row: IReservationHistoryDto): boolean => {
      return canEditReservation(user, {
        ServiceGroupId: row.ServiceGroupId,
        ServiceId: row.EquipmentId,
        ServiceTypeId: ServiceType.Online,
        UserId: row.BookedById,
      });
    },
    [user],
  );
  const allowedroles = [
    Roles.Administrators,
    Roles.EquipmentAdministrator,
    Roles.GroupAdministrators,
    Roles.LabTech,
  ];
  const hasRolePermissions: boolean =
    intersection(roles, allowedroles).length > 0;
  return {
    hasRolePermissions,
    allowedToView,
  };
}
/**
 * Determines if current user has permissions to edit reservation
 * @param user authenticated user
 * @param row reservation like DTO
 * @returns true if current user is allowed to edit the reservation
 */
export function canEditReservation(
  user: AuthenticatedUser | undefined,
  row: IIsAdminOrOwnerOfParams,
) {
  return user?.IsAdminOrOwnerOf(row) ?? false;
}
