export enum ConfigurableTypes {
  ColumnScreenConfig = 1,
  ColumnExportConfig = 2,
  ColumnFinExportConfig = 3,
}
export enum ExportTypes {
  CSV = 1,
  XLS = 2,
  XLSX = 3,
}
export enum ScreensId {
  // "~/UsageHistory.aspx"
  UsageHistory = 1,
  // "~/History.aspx"
  ReservationHistory = 2,
  // "~/UsagePaymentReport.aspx"
  UsagePaymentReport = 3,
  // "~/Budgets.aspx"
  Budgets = 4,
  // "~/ServiceRequests.aspx"
  ServiceRequests = 5,
  // "~/UserTraining.aspx"
  UserTraining = 6,
  // "~/Alerts/default.aspx"
  Alerts = 7,
  // "~/Invoices/Default.aspx"
  Invoices = 8,
  // "~/Invoices/Default.aspx"
  InvoicesList = 9,
  // "~/ProjectPlanningReport.aspx"
  ProjectPlanningReport = 10,
  // "~/Assets/Default.aspx"
  AssetSearch = 11,
  // "~/Admin/Assets/Default.aspx"
  AssetsList = 12,
  // "~/Invoices/Create.aspx"
  EstimatedInvoices = 13,
  // "~/Alerts/UserTickets.aspx"
  AlertsTickets = 14,
  // "~/Alerts/AssignedToMe.aspx"
  AlertsAssignedTo = 15,
  // "~/Alerts/MyAssets.aspx"
  MyAssets = 16,
  // "~/TrainingProgramsList.aspx"
  UpcomingTrainingSessions = 17,
  // "~/CoresDirectory.aspx"
  CoresDirectory = 18,
  // "~/Maps/Buildings.aspx"
  Buildings = 19,
  // "~/Maps/Rooms.aspx"
  Rooms = 20,
  // "~/Reports/Default.aspx"
  AdHocReports = 21,
  // "~/Documents.aspx"
  Documents = 22,
  // "~/Messages.aspx"
  CalendarMessages = 23,
  // "~/TrainingWaitingList"
  TrainingWaitingList = 24,
  // "~/Users"
  Users = 25,
  //~/EmailNotifications
  Email = 26,
  //~/Experiments/experimenttemplates
  ExperimentTemplate = 27,
  //~/UsersHistory
  UsersHistory = 28,
  // ~/Assets/Details.aspx?id=
  ShortAssetsList = 29,
  //~/Quizzes
  Quizzes = 30,
  //~/Submissions
  Submissions = 31,
  //~/UserTrainingHistory
  UserTrainingHistory = 32,
  //~/OtherServices
  OtherServices = 33,
  //~/Invoices/InvoiceLetterTemplates.aspx
  InvoiceLetters = 34,
  //~/Charges
  Charges = 35,
  //~/Locations
  Locations = 36,
  //~/Locations/Building
  BuildingRooms = 37,
  //~/Campuses.aspx
  Campuses = 38,
  //~/Areas.aspx
  Areas = 39,
  //~/LocationList.aspx
  LocationList = 40,
  //~/Categories.aspx
  Categories = 41,
  //~/CategoryGroups.aspx
  CategoryGroups = 42,
  //~/SendEmailToUsers
  SendEmailToUsers = 43,
  //~/OnlineBudgetsStatus
  OnlineBudgetsStatus = 366659,
  //~/InvoiceBatches
  InvoiceBatches = 44,
  //"~/Invoices/Details"
  InvoiceCharges = 45,
  //~/BillingActions
  BillingActions = 46,
  //~/LocationTypes
  LocationTypes = 47,
  //~/mylists
  SavedViews = 48,
  SubLocationList = 49,
  SubLocationTypes = 50,
  Schedule = 51,
  PricePeriod = 52,
  InvoiceTemplates = 53,
  Articles = 54,
  Expenses = 55,
  UserAttributes = 56,
  SampleRunsDetails = 57,
  SamplesList = 58,
  SampleRequests = 59,
  OfflineServiceTypes = 60,
  SampelsRunsList = 61,
  SamplesPlates = 62,
  SamplesModifications = 63,
  PublicAssets = 64,
  // ~/Invoices/SubscriptionTypes/Default.aspx
  SubscriptionTypes = 68,
}
