/**
 *
 * TimelineGroupByPicker
 *
 */
import { useDispatch, useSelector } from 'react-redux';
import { useSchedulerSlice } from '../../slice';
import { TimelineGroupByUnion } from 'api/odata/generated/enums/TimelineGroupBy';
import { selectTimelineGroupBy } from '../../slice/selectors';
import { EnumPicker } from 'app/components/BasicPickers/EnumPicker';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

export interface TimelineGroupByPickerProps {
  EnumNamesArray: TimelineGroupByUnion[];
}

export function TimelineGroupByPicker(props: TimelineGroupByPickerProps) {
  const dispatch = useDispatch();
  const { actions } = useSchedulerSlice();
  const value = useSelector(selectTimelineGroupBy);
  const { t } = useTranslation();

  const handleChange = (value: TimelineGroupByUnion | null) => {
    dispatch(actions.changeViewModePartial({ groupBy: value ?? undefined }));
  };
  return (
    <EnumPicker
      value={value ?? null}
      onChange={handleChange}
      label={t(translations.GroupReservationBy)}
      title={t(translations.GroupReservationBy)}
      {...props}
    />
  );
}
