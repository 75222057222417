import * as DHTMLX from 'dhtmlx-scheduler';
import { ISchedulerService } from './slice/types';
import { orderBy } from 'lodash';
import {
  ISchedulerSection,
  ISchedulerServiceSection,
} from './types/ISchedulerSection';
import { TimelineGroupByUnion } from 'api/odata/generated/enums/TimelineGroupBy';
import { prepareSchedulerSections } from './servicesToSections';
import { AppSettings } from 'types/AppSettings';

/**
 * The "services" server list name
 */
export const scheduler_services_collection_name = 'services';
/**
 * Sets the Services server list
 * @param scheduler scheduler instance
 * @param services services
 */
export function setServerList(
  scheduler: DHTMLX.SchedulerStatic,
  services: ISchedulerService[] = [],
  appSettings: AppSettings | null,
  groupBy?: TimelineGroupByUnion,
) {
  const sections = prepareSchedulerSections({
    appSettings: appSettings,
    groupBy: groupBy ?? 'NoTimelineGrouped',
    services: services,
  });
  // order the services so that they will show up sorted in the timeline/unit
  const orderedServerListData = orderBy(sections ?? [], f => f.label);
  // set the scheduler's server list
  scheduler.serverList(
    scheduler_services_collection_name,
    orderedServerListData,
  );
}

/**
 * Retrieves the Services stored in scheduler's server list.
 * @param scheduler
 * @returns
 */
export function getServerList(
  scheduler: DHTMLX.SchedulerStatic,
): Array<ISchedulerSection> {
  const serverList = scheduler?.serverList(
    scheduler_services_collection_name,
  ) as Array<ISchedulerSection>;
  const result = serverList.filter(
    f => (f as ISchedulerSection).type === 'service',
  ) as Array<ISchedulerServiceSection>;
  return result;
}

/**
 * Updates the Services stored in scheduler's server list.
 * @param scheduler
 * @param services
 */
export function updateServerList(
  scheduler: DHTMLX.SchedulerStatic,
  services: Array<ISchedulerService> = [],
  appSettings: AppSettings | null,
  groupBy?: TimelineGroupByUnion,
) {
  const sections = prepareSchedulerSections({
    appSettings: appSettings,
    services: services,
    groupBy: groupBy,
  });

  scheduler.updateCollection(scheduler_services_collection_name, sections);
}

/**
 * Retrieves a single Service details (if there's any) stored in the scheduler's server list
 * @param scheduler
 * @param serviceId
 * @returns
 */
export function getServerListService(scheduler, serviceId: number | undefined) {
  const services = getServerList(scheduler);
  const service =
    serviceId === undefined
      ? undefined
      : (services?.find(
          f => f.type === 'service' && f.key === serviceId,
        ) as ISchedulerServiceSection);
  return service?.data;
}
