import { ButtonProps } from 'app/components/BasicButtons/Button';
import {
  selectAuthenticatedUser,
  selectglobalSettings,
} from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AllowedSettings } from 'utils/globalSettings';
import { useLabelPrintSlice } from '../slice';
import { Identifiable } from 'types/common';
import { SelectedRowsActionIconButton } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionIconButton';
import { IResponseType } from 'types/ResponseType';
import { ICanRaiseBasicTableRefreshEvent } from 'app/components/BasicTable/ControlledTable/ControlledTableProps';

export interface PrintAssetLabelsButtonProps
  extends ButtonProps,
    ICanRaiseBasicTableRefreshEvent {
  rows: Array<Identifiable<number>>;
}

export function PrintSelectedAssetLabelsButton({
  rows,
  onRefresh,
  onClick,
  ...props
}: PrintAssetLabelsButtonProps) {
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const allowedRoles = [
    Roles.Administrators,
    Roles.LabTech,
    Roles.GroupAdministrators,
    Roles.EquipmentAdministrator,
  ];
  const systemSettings = useSelector(selectglobalSettings);
  // enable print only if any of the supported printers settings has been turned on
  const printEnabled =
    systemSettings.GetBooleanByKey(AllowedSettings.DymoPrinterSupported) ||
    systemSettings.GetBooleanByKey(AllowedSettings.ZebraPrinterSupported);

  const show =
    printEnabled &&
    authenticatedUser?.Roles?.some(role => allowedRoles.includes(role));

  const dispatch = useDispatch();
  const { actions } = useLabelPrintSlice();

  if (!show) {
    return <></>;
  } else {
    const assetIds = rows.map(f => f.Id);
    props.children = props.children ?? t(translations.PrintLabels);
    props.title = props.title ?? t(translations.PrintLabels);
    props.disabled = props.disabled || assetIds.length === 0;
    return (
      <SelectedRowsActionIconButton
        text={t(translations.PrintLabels)}
        title={t(translations.PrintLabels)}
        onRefresh={onRefresh}
        rows={[]}
        variantButton="button"
        action={() => {
          dispatch(actions.open({ ids: assetIds, type: 'Asset' }));
          return Promise.resolve<IResponseType>({
            ErrorMessages: [],
            SuccessMessages: [],
            WarningMessages: [],
          });
        }}
      />
    );
  }
}
