export enum PriceSheetBy {
  InstrumentsAndServices = 1,
  InstrumentCategoryAndProjectCategory = 2,
}

export enum BudgetType {
  Regular = 1,
  Expanses = 2,
}
export enum UserRegistersToSpecificCoresType {
  UserRegistersToSpecificCores_NA = 1,
  UserRegistersToSpecificCores_Optional = 2,
  UserRegistersToSpecificCores_Mandatory = 3,
}

export enum AutomaticAssetNamings {
  No,
  Cat,
  Nick,
}
export enum UserReservationsRestricted {
  NotRestricted = 0,
  UserGroupRestricted = 1,
  UserOwnRestricted = 2,
}
