import { ISchedulerEvent } from '../types/ISchedulerEvent';

/**
 * Custom compare function that controls events order on the timeline:
 * 1. Earlier events must appear first - this controls the cascading/overlapping display.
 * 2. Training sessions must appear on top with signups below
 * This function is ultimately plugged as an argument into events.sort after which the events are arranged on the timeline to accomodate for overlapping, row height, etc.
 * @param a event
 * @param b event
 * @returns 1 | -1 | 0
 */
export function timelineEventsSortIteratee(
  a: ISchedulerEvent,
  b: ISchedulerEvent,
): 1 | -1 | 0 {
  return (
    // dates sort must appear first for proper arrangement of overlaps
    compareFn(a, b, event => event.start_date.valueOf()) ??
    // only later same date/time events are arranged so that training session will be on top
    compareFn(a, b, event => (event.type === 'trainingsession' ? -1 : 1)) ??
    // fallback to event id to ensure stable sorting
    compareFn(a, b, event => event.id) ??
    0
  );
}

function compareFn<T>(
  a: ISchedulerEvent,
  b: ISchedulerEvent,
  accessor: (c: ISchedulerEvent) => T,
) {
  const valueA = accessor(a);
  const valueB = accessor(b);
  if (valueA === valueB) {
    return undefined;
  } else if (valueA < valueB) {
    return -1;
  } else {
    return 1;
  }
}
