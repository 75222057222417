/**
 * Asynchronously loads the component for NewMessagePage
 */

import { lazyLoad } from 'utils/loadable';

export const NewMessagePage = lazyLoad(
  () => import('./index'),
  module => module.NewMessagePage,
);
