/**
 *
 * Asynchronously loads the component for AssetSelectionPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AssetSelectionPage = lazyLoad(
  () => import('./index'),
  module => module.AssetSelectionPage,
);
