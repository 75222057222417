import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

const TablePaginationActions: React.FC<TablePaginationActionsProps> = props => {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box component={'div'} className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="ghost"
        size="xs"
        shape="square"
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? (
          <Icon icon="chevron-double-right" />
        ) : (
          <Icon icon="chevron-double-left" />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        variant="ghost"
        shape="square"
        size="xs"
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <Icon icon="chevron-right" />
        ) : (
          <Icon icon="chevron-left" />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        variant="ghost"
        shape="square"
        size="xs"
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <Icon icon="chevron-left" />
        ) : (
          <Icon icon="chevron-right" />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        variant="ghost"
        shape="square"
        size="xs"
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? (
          <Icon icon="chevron-double-left" />
        ) : (
          <Icon icon="chevron-double-right" />
        )}
      </IconButton>
    </Box>
  );
};

// TablePaginationActions.propTypes = {
//   count: PropTypes.number.isRequired,
//   onChangePage: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
// };

export default TablePaginationActions;
