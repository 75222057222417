import React from 'react';
import { Box } from 'app/components/basic/Wrappers/Box';
import { Check, CheckProps } from 'app/components/BasicInputs/Check';

export interface CheckBoxProps
  extends Pick<CheckProps, 'value' | 'name' | 'id' | 'label' | 'onChange'> {
  checked: (name: string | undefined) => boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CheckBox({ checked, ...props }: CheckBoxProps) {
  return (
    <Box>
      <Check
        label="This dashboard"
        color={'primary'}
        checked={checked(props.name)}
        {...props}
        size="small"
      />
      {props.label}
    </Box>
  );
}
