/**
 *
 * Asynchronously loads the component for InvoiceTemplates Page
 *
 */

import { lazyLoad } from 'utils/loadable';

export const InvoiceTemplates = lazyLoad(
  () => import('./index'),
  module => module.InvoiceTemplatesPage,
);
