import {
  AssetQuickSearchDetails,
  IQuickAssetsSearchResult,
} from 'app/components/AssetQuickSearch/slice/types';
import { Identifiable } from 'types/common';
import { httpClient } from './HttpClient';
import { Condition, ODataOperators } from './odata/ODataFilter';

export const AssetQuickSearchApi = {
  getAssetSearch: async (payload: {
    q: string;
    serviceGroups: Identifiable<number>[];
    serviceTypeId?: number;
  }) => {
    const url = `/api/odata/v4/AssetQuickSearch?q=${payload.q}`;
    let filters: Array<string> = [];
    if (payload.serviceGroups.length > 0) {
      filters.push(
        `${new Condition<AssetQuickSearchDetails>(
          'ServiceGroupId',
          ODataOperators.Includes,
          payload.serviceGroups,
        ).toString()}`,
      );
    }
    if (!!payload.serviceTypeId) {
      filters.push(
        `${new Condition<AssetQuickSearchDetails>(
          'ServiceTypeId',
          ODataOperators.Equals,
          payload.serviceTypeId,
        ).toString()}`,
      );
    }
    const params: {
      $orderby: string;
      $filter?: string;
      $count: boolean;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: filters.length > 0 ? filters.join(' and ') : undefined,
      $count: true,
      $select:
        'Id,Name,Image,AvailabilityTypeId,AvailabilityTypeName,BookableAsset,ServiceId,ServiceGroupId,ApprovalRequired',
      $skip: 0,
      $top: 5,
    };
    try {
      const response = await httpClient.get(url, params);
      return {
        Results: response.value,
        TotalResultsLength: response['@odata.count'],
        ServiceType: payload.serviceTypeId,
      } as IQuickAssetsSearchResult;
    } catch {
      return {
        Results: [],
        TotalResultsLength: 0,
        ServiceType: undefined,
      } as IQuickAssetsSearchResult;
    }
  },
  getRecentAssets: async (payload: {
    serviceGroups: Identifiable<number>[];
  }) => {
    const url = `/api/odata/v4/AssetQuickSearch/RecentAssets`;
    let filters: Array<string> = [];
    if (payload.serviceGroups.length > 0) {
      filters.push(
        `${new Condition<AssetQuickSearchDetails>(
          'ServiceGroupId',
          ODataOperators.Includes,
          payload.serviceGroups,
        ).toString()}`,
      );
    }
    const params: {
      $orderby: string;
      $filter?: string;
      $count: boolean;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: filters.length > 0 ? filters.join(' and ') : undefined,
      $count: true,
      $select:
        'Id,Name,Image,AvailabilityTypeId,AvailabilityTypeName,BookableAsset,ServiceId,ServiceGroupId,ApprovalRequired',
      $skip: 0,
      $top: 10,
    };
    try {
      const response = await httpClient.get(url, params);
      return {
        Results: response.value,
        TotalResultsLength: response['@odata.count'],
        ServiceType: undefined,
      } as IQuickAssetsSearchResult;
    } catch {
      return {
        Results: [],
        TotalResultsLength: 0,
        ServiceType: undefined,
      } as IQuickAssetsSearchResult;
    }
  },
};
