import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DetectIsMobile } from 'utils/mobileDetect';
import { ActionRenderer } from './PageActions/ActionRender';
import { useLayoutSlice } from '../../slice';
import {
  selectExpandedSidePanel,
  selectHideNavigation,
  selectIsCoverExpanded,
  selectIsCoverOpen,
} from '../../slice/selectors';
import clsx from 'clsx';
import { CoverWrapper } from '../CoverWrapper';
import { Head } from './components/Head';
import { ShortHead } from './components/ShortHead';
import { ButtonsPanel } from './components/ButtonsPanel';
import { TopButtonsPanel } from './components/TopButtonsPanel';
import { SwitchActionsProps } from './PageActions/SwitchActions';
import { ButtonsSkeleton, FormSkeleton } from './components/FormSkeleton';
import styled from 'styled-components';
import { useTheme } from '@material-ui/styles';
import { bookitShadows } from 'styles/shadows/bookitShadow';
import { bookitColors } from 'styles/colors/bookitColors';
export type PageContentRenderer = () => React.ReactNode;

export interface PageWrapperProps extends CoverProps {
  children: React.ReactNode;
  pageName?: React.ReactNode;
  titlePage?: string;
  subTitlePage?: React.ReactNode;
  leftActions?: ActionRenderer[];
  rightActions?: ActionRenderer[];
  rightTopActions?: ActionRenderer[];
  leftTopActions?: ActionRenderer[];
  onSubmit?: () => void;
  onClose?: () => void;
  closeSidePanel?: () => void;
  closable?: boolean;
  useSidePanel: boolean;
  loading?: boolean;
  confirm?: JSX.Element;
  pageLink?: string;
  cover?: React.ReactNode;
  disableExpandToggle?: boolean;
  titleTooltip?: string;
  switchState?: SwitchActionsProps;
  useSwitchActions?: boolean;
  topProcessing?: boolean;
  maxLeftTopButtonsLength?: number;
  rightTopActionsAsMenu?: boolean;
  hideTitleBar?: boolean;
  leftActionsMaxLength?: number;
}

export interface CoverProps {
  isCover?: boolean;
  closeCover?: () => void;
}

const PageWrapperRoot = styled('div')(({ theme }) => ({
  '&.page-wrapper-root': {
    background: bookitColors.base.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    minWidth: '360px',
    gap: 8,
    '&.covered': {
      filter: 'brightness(30%)',
    },
    '&.cover': {
      // height: '95vh',
      height: 'calc(100vh - 50px)',
      '& [class*="tableButtonsRootShort"]': {
        maxWidth: '320px',
      },
    },
    '&.baseNavigationHeight': {
      height: '97vh',
    },
    '&.baseRootUsePanelHeight': {
      height: '99.9vh',
    },
    '&.baseRootUsePanelShortHeight': {
      // height: '97vh',
      height: 'calc(100vh - 50px)',
    },
    '&.baseRootHeight': {
      height: '91vh',
    },
    '.content': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      padding: '8px 0 0',
      '&.contentRoot': {
        justifyContent: 'space-between',
        height: '100%',
        overflow: 'hidden',
      },
      '&.contentMobRoot': {
        justifyContent: 'space-between',
        padding: theme.spacing(1, 0, 0, 0),
        height: '100%',
        //height: '90%',
        //gap: theme.spacing(2),
        flex: '0 1 calc(100% - 50px)',
        overflow: 'hidden',
        '&.hideTitleBar': {
          padding: '0px',
          flex: '0 1 100%',
        },
      },
      '&.hideTitleBar': {
        padding: '0px',
      },
      '.contentContainer': {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        width: '100%',
        height: '100%',
        position: 'relative',
        overflowY: 'auto',
        gap: '32px',
        '&::-webkit-scrollbar': {
          width: '10px',
          // '&&': {
          //   background: 'yelow',
          // },
        },
        '&::-webkit-scrollbar-thumb': {
          boxShadow: `inset 0 0 2px ${theme.palette.common.grayBorders}`,
          borderRadius: '6px',
          backgroundColor: theme.palette.common.grayBorders,
        },
        '&.showShortView': {
          gap: '16px',
        },
      },
      //TO DO: find a way to add a box-shadow to bottom toolbar in case when content is scrollable so it match to the Figma.
      '.bottomToolbar': {
        position: 'static',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: bookitColors.base.white,
        borderRadius: 'unset',
        padding: theme.spacing(2, 3, 2, 3),
        boxShadow: bookitShadows.shadowBorderTop,
        '&.showShortView': {
          gap: '8px',
          boxShadow: bookitShadows.shadowBorderTop,
        },
      },
    },
  },
}));

export const PageWrapper = React.memo(function PageWrapper(
  props: PageWrapperProps,
) {
  const {
    pageName,
    titlePage,
    subTitlePage,
    children,
    leftActions,
    rightActions,
    rightTopActions,
    leftTopActions,
    loading,
    onSubmit,
    onClose,
    closeSidePanel,
    closable,
    useSidePanel,
    confirm,
    pageLink,
    cover,
    closeCover,
    disableExpandToggle,
    titleTooltip,
    isCover,
    switchState,
    useSwitchActions: useSwithActions,
    topProcessing,
    maxLeftTopButtonsLength,
    rightTopActionsAsMenu,
    hideTitleBar,
    leftActionsMaxLength,
  } = props;
  // const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const coverExpanded = useSelector(selectIsCoverExpanded);
  const hideNavigation = useSelector(selectHideNavigation);
  const isCoverOpen = useSelector(selectIsCoverOpen);
  const isCovered = !!cover;
  const showShortView = React.useMemo(() => {
    return (
      isMobile ||
      (useSidePanel && !sidePanelExpanded) ||
      (isCover && !coverExpanded)
    );
  }, [coverExpanded, isCover, isMobile, sidePanelExpanded, useSidePanel]);

  const closePanel = () => {
    if (isCover) {
      closeCover?.();
      dispatch(actions.setIsCoverOpen(false));
      return;
    }
    if (closeSidePanel) {
      closeSidePanel();
    } else {
      dispatch(actions.resetSidePanel());
    }
    if (onClose) {
      onClose();
    }
  };
  const expandPanel = () => {
    if (isCover) {
      dispatch(actions.setIsCoverExpanded(!coverExpanded));
    } else {
      dispatch(actions.setExpanded(!sidePanelExpanded));
    }
  };
  const onCoveredClick = event => {
    if (isCovered && isCoverOpen && event.target.id === 'page-wrapper-root') {
      closeCover?.();
      dispatch(actions.setIsCoverOpen(false));
    }
  };
  const theme = useTheme();
  return (
    <React.Fragment>
      <PageWrapperRoot
        theme={theme}
        id="page-wrapper-root"
        key="page-wrapper-root"
        onClick={onCoveredClick}
        className={clsx('page-wrapper-root', {
          covered: isCovered,
          cover: isCover,
          baseRootUsePanelHeight:
            (useSidePanel || isMobile) &&
            !(useSidePanel && isMobile) &&
            !isCover,
          baseRootUsePanelShortHeight: useSidePanel && isMobile,
          baseRootHeight: !useSidePanel && !hideNavigation && !isMobile,
          baseNavigationHeight: !useSidePanel && hideNavigation,
        })}
      >
        {!hideTitleBar && (
          <React.Fragment>
            {showShortView ? (
              <ShortHead
                closable={closable}
                useSidePanel={useSidePanel}
                closePanel={closePanel}
                pageName={pageName}
                titlePage={titlePage}
                pageLink={pageLink}
                titleTooltip={titleTooltip}
                isMobile={isMobile}
                disableExpandToggle={disableExpandToggle || isCover}
                expandPanel={expandPanel}
                subTitlePage={subTitlePage}
                leftTopActions={leftTopActions}
                rightTopActions={rightTopActions}
                onSubmit={onSubmit}
                switchState={switchState}
                useSwithActions={useSwithActions && !loading}
                showProcessing={topProcessing}
                loading={!!loading}
                rightTopActionsAsMenu={rightTopActionsAsMenu}
                isCover={isCover}
              />
            ) : (
              <Head
                closable={closable}
                useSidePanel={useSidePanel}
                closePanel={closePanel}
                pageName={pageName}
                titlePage={titlePage}
                pageLink={pageLink}
                titleTooltip={titleTooltip}
                isMobile={isMobile}
                disableExpandToggle={disableExpandToggle || isCover}
                expandPanel={expandPanel}
                subTitlePage={subTitlePage}
                leftTopActions={leftTopActions}
                rightTopActions={rightTopActions}
                onSubmit={onSubmit}
                switchState={switchState}
                useSwithActions={useSwithActions && !loading}
                showProcessing={topProcessing}
                loading={!!loading}
                isCover={isCover}
              />
            )}
          </React.Fragment>
        )}
        <div
          className={clsx('content', {
            contentRoot: !showShortView,
            contentMobRoot: showShortView,
            hideTitleBar: hideTitleBar,
          })}
        >
          <React.Fragment>
            <div
              className={clsx('contentContainer', {
                showShortView: showShortView,
              })}
            >
              {loading ? (
                <FormSkeleton fullPage={!showShortView} />
              ) : (
                <React.Fragment>
                  {showShortView &&
                    leftTopActions &&
                    leftTopActions.length > 0 && (
                      <TopButtonsPanel
                        leftTopActions={leftTopActions}
                        rightTopActions={rightTopActions}
                        showShortView={showShortView}
                        onSubmit={onSubmit}
                        expandPanel={expandPanel}
                        maxLeftTopButtonsLength={maxLeftTopButtonsLength}
                      />
                    )}
                  <React.Fragment>{children}</React.Fragment>
                </React.Fragment>
              )}
            </div>
            <React.Fragment>
              {confirm}
              {leftActions && leftActions?.length > 0 && (
                <div
                  className={clsx('bottomToolbar', {
                    showShortView: showShortView,
                  })}
                >
                  {/* {showShortView &&
                    !isMobile &&
                    useSidePanel &&
                    !disableExpandToggle &&
                    leftActions &&
                    leftActions?.length > 1 && (
                      <React.Fragment>
                        {loading ? (
                          <div className="showMore">
                            <Skeleton width="8ch" />
                          </div>
                        ) : (
                          <div className="showMore">
                            <Button
                              variant="text"
                              size="small"
                              onClick={expandPanel}
                            >
                              <Body bold={true} size="small">
                                {sidePanelExpanded
                                  ? t(translations.ShowLess)
                                  : t(translations.ShowMore)}
                              </Body>
                            </Button>
                          </div>
                        )}
                      </React.Fragment>
                    )} */}
                  {loading ? (
                    <ButtonsSkeleton />
                  ) : (
                    <ButtonsPanel
                      leftActions={leftActions}
                      rightActions={rightActions}
                      isMobile={isMobile}
                      sidePanelExpanded={sidePanelExpanded}
                      disableExpandToggle={disableExpandToggle || isCover}
                      useSidePanel={useSidePanel}
                      onSubmit={onSubmit}
                      expandPanel={expandPanel}
                      leftActionsMaxLength={leftActionsMaxLength}
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          </React.Fragment>
        </div>
        {useSidePanel && !!cover && (
          <CoverWrapper onClose={closeCover} open={isCovered}>
            {cover}
          </CoverWrapper>
        )}
      </PageWrapperRoot>
    </React.Fragment>
  );
});
