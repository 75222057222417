import { CellProps, Renderer } from 'react-table';
import { AlignRight } from '../AlignRight';

const PercentageRenderer: Renderer<CellProps<Record<string, any>>> = ({
  value,
}: {
  value: number | null;
}) => (value === null ? null : <AlignRight>{value?.toFixed(2)} %</AlignRight>);

export default PercentageRenderer;
