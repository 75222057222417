import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.assetQuickSearch || initialState;

export const selectAssetQuickSearch = createSelector(
  [selectSlice],
  state => state,
);

export const selectRecentAssets = createSelector(
  [selectAssetQuickSearch],
  state => state.recentAssets,
);
export const selectRecentSearchTerms = createSelector(
  [selectAssetQuickSearch],
  state => state.recentSearchTerms,
);
export const selectSearchResults = createSelector(
  [selectAssetQuickSearch],
  state => state.searchResults,
);
export const selectAllSearchResults = createSelector(
  [selectAssetQuickSearch],
  state => state.allSearchResults,
);
export const selectSearchTerm = createSelector(
  [selectAssetQuickSearch],
  state => state.searchTerm,
);
export const selectOpen = createSelector(
  [selectAssetQuickSearch],
  state => state.open,
);
export const selectShowSearchInSidebar = createSelector(
  [selectAssetQuickSearch],
  state => state.showSearchInSidebar,
);
export const selectOnlineSearchResults = createSelector(
  [selectAssetQuickSearch],
  state => state.onlineSearchResults,
);
export const selectOfflineSearchResults = createSelector(
  [selectAssetQuickSearch],
  state => state.offlineSearchResults,
);
export const selectRequestSearchResults = createSelector(
  [selectAssetQuickSearch],
  state => state.requestSearchResults,
);
export const selectTotalSearchCount = createSelector(
  [selectAssetQuickSearch],
  state => state.totalSearchCount,
);
