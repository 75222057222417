/**
 *
 * ArticleDetails
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import { useArticlesSlice } from 'app/pages/ArticlesPage/slice';
import { InputLabel } from 'app/components/Typography';
import {
  selectArticleDetails,
  selectArticleDetailsBusy,
  selectArticleDetailsForm,
  selectShowEditArticleAssociation,
} from 'app/pages/ArticlesPage/slice/selectors';
import { ArticleAssociationForm } from 'app/pages/ArticlesPage/ArticleAssociationForm';
import { ValueLabel } from 'app/components/ValueLabel';
import { Box } from 'app/components/basic/Wrappers/Box';
import { ArticleDoiLink } from 'app/pages/ArticlesPage/ArticleDoiLink';
import { AssetLink } from 'app/pages/AssetPopUp';
import { isNullOrUndefined } from 'utils/typeUtils';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { DateTimeLabel } from 'app/components/DateTimeLabel';
import { EditArticleDetailsFormProps } from '../EditArticleDetailsForm';
import { ArticleStatus } from 'enums/Article';
import { useCanEdit } from '../Hooks/useAllowedToChangeStatus';

export interface ArticleDetailsProps extends CoverProps {
  useSidePanel: boolean;
  value?: IArticleDto;
  onClose?: () => void;
}

export function ArticleDetails({ ...props }: ArticleDetailsProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useArticlesSlice();
  const selectedArticle = useSelector(selectArticleDetails);
  const value = useSelector(selectArticleDetailsForm);
  const busy = useSelector(selectArticleDetailsBusy);
  const isSaved = value?.Id !== undefined && value?.Id > 0;
  const [canEdit, msg] = useCanEdit();
  const setCover = React.useCallback(
    showCover => {
      if (
        selectedArticle !== undefined &&
        selectedArticle.ExternalIdSource === 'Manual Entry'
      ) {
        const sidePanelProps: EditArticleDetailsFormProps = {
          useSidePanel: true,
          value: selectedArticle,
        };
        dispatch(
          layoutActions.openSidePanel({
            type: RenderPageType.ArticleEdit,
            props: sidePanelProps,
          }),
        );
      } else {
        dispatch(actions.showEditArticleAssociation(showCover));
      }
    },
    [actions, dispatch, selectedArticle],
  );
  const cover = useSelector(selectShowEditArticleAssociation);

  //CSS
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      articleLabelColorPaddingTop: {
        color: 'grey',
        paddingTop: '10px',
      },
      articleValueLabelBold: {
        fontWeight: 'bold',
      },
    }),
  );
  const classes = useStyles();

  return (
    <PageWrapper
      pageName={t(translations.PublicationDetails)}
      titlePage={t(translations.PublicationDetails)}
      useSidePanel={true}
      closable={true}
      closeSidePanel={() => {
        dispatch(layoutActions.setNotSavedChanges(false));
        dispatch(actions.hideDetails());
      }}
      loading={busy}
      leftTopActions={[
        () => (
          <>
            <EditAssociationButton
              item={selectedArticle}
              onClick={() => setCover(true)}
              disabled={!canEdit}
            />
          </>
        ),
      ]}
      cover={
        cover && <ArticleAssociationForm closeCover={() => setCover(false)} />
      }
      closeCover={() => setCover(false)}
      useSwitchActions={true}
    >
      <Box height={'100%'} p={1}>
        <Grid
          container
          direction="row"
          justify="space-around"
          spacing={0}
          style={{ paddingBottom: '5px', paddingTop: '5px' }}
        >
          {msg !== '' && (
            <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
              <InputLabel size="xs">{msg}</InputLabel>
            </Grid>
          )}
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.Title)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ValueLabel value={value.Title} />
          </Grid>
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.Abstract)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ValueLabel value={value.Abstract} />
          </Grid>
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.Authors)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ValueLabel value={value.Authors?.join(', ')} />
          </Grid>
          {!isNullOrUndefined(value.ExternalIdSource) && (
            <>
              <Grid
                item
                xs={12}
                className={classes.articleLabelColorPaddingTop}
              >
                <InputLabel size="xs">{t(translations.Source)}</InputLabel>
              </Grid>
              <Grid item xs={12} className={classes.articleValueLabelBold}>
                <ValueLabel value={value.ExternalIdSource} />
              </Grid>
            </>
          )}
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.EnteredBy)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ValueLabel value={value.UserDisplayName} />
          </Grid>
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.InsertedAt)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <DateTimeLabel value={value.EnteredAt ?? null} />
          </Grid>
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.Doi)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ArticleDoiLink doi={value.Doi} />
          </Grid>
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.ISSN)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ValueLabel value={value.ISSN} />
          </Grid>
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.ORCID)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ValueLabel value={value.ORCIDs?.join(', ')} />
          </Grid>
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.Year)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ValueLabel value={value.Year} />
          </Grid>
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.ArticleStatus)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ValueLabel
              value={
                value.Status !== undefined
                  ? (t('Article' + ArticleStatus[value.Status]) as string)
                  : 'undefined'
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.RelatedUser)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ValueLabel value={value.RelatedUserDisplayName} />
          </Grid>
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">{t(translations.Journal)}</InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ValueLabel value={value.Journal} />
          </Grid>
          <Grid item xs={12} className={classes.articleLabelColorPaddingTop}>
            <InputLabel size="xs">
              {t(translations.ArticlePageNumber)}
            </InputLabel>
          </Grid>
          <Grid item xs={12} className={classes.articleValueLabelBold}>
            <ValueLabel value={value.PageNumber} />
          </Grid>
          {isSaved && (
            <>
              <Grid
                item
                xs={12}
                className={classes.articleLabelColorPaddingTop}
              >
                <InputLabel size="xs">
                  {t(translations.ParticipatedLabs)}
                </InputLabel>
              </Grid>
              <Grid item xs={12} className={classes.articleValueLabelBold}>
                {value.ServiceGroupAssets.length === 0 ? (
                  t(translations.NA)
                ) : (
                  <ul>
                    {value.ServiceGroupAssets.map(asset => (
                      <li key={asset.Id}>
                        <AssetLink id={asset.Id}>{asset.Name}</AssetLink>
                      </li>
                    ))}
                  </ul>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.articleLabelColorPaddingTop}
              >
                <InputLabel size="xs">
                  {t(translations.ParticipatedInstruments)}
                </InputLabel>
              </Grid>
              <Grid item xs={12} className={classes.articleValueLabelBold}>
                {value.InstrumentAssets.length === 0 ? (
                  t(translations.NA)
                ) : (
                  <ul>
                    {value.InstrumentAssets.map(asset => (
                      <li key={asset.Id}>
                        <AssetLink id={asset.Id}>{asset.Name}</AssetLink>
                      </li>
                    ))}
                  </ul>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </PageWrapper>
  );
}
function EditAssociationButton({
  item,
  onClick,
  disabled,
}: {
  item?: IArticleDto;
  onClick: () => void;
  disabled: boolean;
}) {
  const { t } = useTranslation();
  //const authenticatedUser = useSelector(selectAuthenticatedUser);
  if (item === undefined) {
    return <></>;
  }
  /*const show =
    authenticatedUser?.Roles?.includes(Roles.GroupAdministrators) === true;
  if (!show) {
    return <></>;
  }*/
  if (item.Id > 0) {
    if (item.ExternalIdSource === 'Manual Entry') {
      return (
        <Button
          title={t(translations.EditArticle)}
          startIcon={<Icon icon="edit" />}
          onClick={onClick}
          disabled={disabled}
        >
          {t(translations.EditArticle)}
        </Button>
      );
    } else {
      return (
        <Button
          title={t(translations.EditAssociation)}
          startIcon={<Icon icon="edit" />}
          onClick={onClick}
          disabled={disabled}
        >
          {t(translations.EditAssociation)}
        </Button>
      );
    }
  } else {
    return (
      <Button
        title={t(translations.AssociateThisPublicationTitle)}
        startIcon={<Icon icon="add" />}
        onClick={onClick}
      >
        {t(translations.AssociateThisPublication)}
      </Button>
    );
  }
}
