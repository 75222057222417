import {
  ConvertModelToEntityUserAttributesDetails,
  UserAttributesDetailsQStringParameters,
  UserAttributesDetailsState,
  UserAttributesState,
} from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { IUserAttributesDto } from 'api/odata/generated/entities/IUserAttributesDto';
import { userAttributesDetailsSaga } from './saga';

export const initialState: UserAttributesState = {
  processing: false,
  createState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  updateState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
};

const slice = createSlice({
  name: 'userAttributesDetail',
  initialState,
  reducers: {
    initUserAttributesDetails(
      state,
      action: PayloadAction<UserAttributesDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initUpdateUserAttributesDetails(
      state,
      action: PayloadAction<UserAttributesDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initUserAttributesDetails_Success(
      state,
      action: PayloadAction<IUserAttributesDto>,
    ) {
      state.processing = false;
      state.createState.data = ConvertModelToEntityUserAttributesDetails(
        action.payload,
      );
    },
    initUpdateUserAttributesDetails_Success(
      state,
      action: PayloadAction<IUserAttributesDto>,
    ) {
      state.processing = false;
      state.updateState.data = ConvertModelToEntityUserAttributesDetails(
        action.payload,
      );
    },
    initUserAttributesDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    initUpdateUserAttributesDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    //====================================================================
    resetUpdateUserAttributesDetailsState(state, action: PayloadAction) {
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    resetCreateUserAttributesDetailsState(state, action: PayloadAction) {
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    //====================================================================
    updateUserAttributesDetails(
      state,
      action: PayloadAction<{
        current: UserAttributesDetailsState;
        original: UserAttributesDetailsState | undefined;
      }>,
    ) {
      state.updateState.processing = true;
      state.updateState.completed = undefined;
      state.updateState.hasErrors = undefined;
    },
    updateUserAttributesDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.updateState.processing = false;
      state.updateState.hasErrors = action.payload.hasErrors;
      state.updateState.completed = true;
    },
    updateUserAttributesDetails_Error(state, action: PayloadAction<any>) {
      state.updateState.processing = false;
      state.updateState.completed = true;
      state.updateState.hasErrors = true;
    },
    //====================================================================
    createUserAttributesDetails(
      state,
      action: PayloadAction<UserAttributesDetailsState>,
    ) {
      state.createState.processing = true;
      state.createState.completed = undefined;
      state.createState.hasErrors = undefined;
    },
    createUserAttributesDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.createState.processing = false;
      state.createState.hasErrors = action.payload.hasErrors;
      state.createState.completed = true;
    },
    createUserAttributesDetails_Error(state, action: PayloadAction<any>) {
      state.createState.processing = false;
      state.createState.completed = true;
      state.createState.hasErrors = true;
    },
  },
});

export const { actions: userAttributesDetailsActions } = slice;

export const useUserAttributesDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: userAttributesDetailsSaga });
  return { actions: slice.actions };
};
