/**
 *
 * MonthOfYearPicker
 *
 */
import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import React from 'react';

import { Entity } from 'types/common';
import { dateUtils } from 'utils/date-utils';
import { GetOptionsFilterFunction } from 'utils/enumKeys';

export interface MonthEntity extends Entity<number> {
  FullName: string;
  MonthId: number;
}
export interface MonthOfYearPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {
  date?: Date | string | null;
}
export const monthEntityArray = Array.from({ length: 12 }, (_, i) => {
  const x = i;
  return {
    Id: x,
    Name: dateUtils.format(
      dateUtils.setMonth(new Date(), x),
      dateUtils.DateIOFormats.monthShort,
    ),
    FullName: dateUtils.format(
      dateUtils.setMonth(new Date(), x),
      dateUtils.DateIOFormats.month,
    ),
    MonthId: x + 1,
  } as Entity<number>;
});

export const defaultMonthValue =
  monthEntityArray.find(f => f.Id === dateUtils.getMonth(new Date())) ??
  ({
    Id: dateUtils.getMonth(new Date()),
    Name: dateUtils.format(new Date(), dateUtils.DateIOFormats.monthShort),
    FullName: dateUtils.format(new Date(), dateUtils.DateIOFormats.month),
    MonthId: dateUtils.getMonth(new Date()) + 1,
  } as Entity<number>);
export const getMonthEntityFromDate = (date?: Date | string | null) => {
  if (date === null || date === undefined) {
    return defaultMonthValue;
  } else {
    let toDate = dateUtils.dateOrStringToDate(date);
    return (
      monthEntityArray.find(f => f.Id === toDate.getMonth()) ??
      defaultMonthValue
    );
  }
};
export const initMonthData = (
  initval: number | string | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve(
        monthEntityArray.filter(item => {
          return item.Id.toString() === initval.toString();
        }),
      );
    });
  }
};

export function MonthOfYearPicker(props: MonthOfYearPickerProps) {
  const getOptions = React.useMemo(() => {
    let items = monthEntityArray;

    return GetOptionsFilterFunction(items, (f, search) =>
      (f as MonthEntity).MonthId.toString()
        .toLowerCase()
        .startsWith(search.toLowerCase()),
    );
  }, []);
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      id={props.id || 'monthId'}
      value={props.value}
      filterSelectedOptions={false}
      autoHighlight={true}
      autoSelect={true}
      selectOnFocus={true}
      clearOnEscape
      includeInputInList
      {...props}
    />
  );
}
