import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { Entity } from 'types/common';
import { IMyAssetsRow } from '../MyAssetsPage/IMyAssetsRow';

export interface IAsset extends Entity<number> {
  ImageName: string;
  ServiceGroupName: string;
  DivisionName: string;
  Accessories: Entity<number>[];
  AssetCatGroupName: string;
  AssetCatName: string;
  NickName: string;
  OtherNames: string;
  Brand: string;
  Model: string;
  ModelID?: number;
  ModelNumber: string;
  Campus?: number;
  Building: string;
  Room: string;
  LocationId?: number;
  LocationName: string;
  Experts?: AssetUser[];
  Contacts?: AssetUser[];
  Suppliers?: Entity<number>[];
  MainSpecRange: string;
  ElectricalSpecs: string;
  FunctionalCharacteristics: string;
  Description: string;
  WebLink: string;
  Raac?: boolean;
  Tags?: Entity<number>[];
  ExtraDate0?: Date;
  ExtraDate1?: Date;
  ExtraDate2?: Date;
  ExtraDate3?: Date;
  ExtraDate4?: Date;
  ExtraDate5?: string;
  ExtraDate6?: string;
  ExtraDate7?: string;
  ExtraDate8?: string;
  ExtraDate9?: string;
  ExtraText0?: string;
  ExtraText1?: string;
  ExtraText2?: string;
  ExtraText3?: string;
  ExtraText4?: string;
  ExtraText5?: string;
  ExtraText6?: string;
  ExtraText7?: string;
  ExtraText8?: string;
  ExtraText9?: string;
  ExtraText10?: string;
  ExtraText11?: string;
  ExtraText12?: string;
  ExtraText13?: string;
  ExtraText14?: string;
  ExtraText15?: string;
  ExtraInt0?: number;
  ExtraInt1?: number;
  ExtraInt2?: number;
  ExtraInt3?: number;
  ExtraInt4?: number;
  ExtraDec0?: number;
  ExtraDec1?: number;
  ExtraDec2?: number;
  ExtraDec3?: number;
  ExtraDec4?: number;
  ExtraBool0?: boolean;
  ExtraBool1?: boolean;
  ExtraBool2?: boolean;
  ExtraBool3?: boolean;
  ExtraBool4?: boolean;
  ExtraUrl0: string;
  ExtraUrl1: string;
  ExtraUrl2: string;
  ExtraUrl3: string;
  ExtraUrl4: string;
  ExtraRichText0: string;
  ExtraRichText1: string;
  ExtraRichText2: string;
  ExtraRichText3: string;
  ExtraRichText4: string;
  ExtraDDL0?: number;
  ExtraDDL1?: number;
  ExtraDDL2?: number;
  ExtraDDL3?: number;
  ExtraDDL4?: number;
  ExtraDDL5?: number;
  ExtraDDL6?: number;
  ExtraDDL7?: number;
  ExtraDDL8?: number;
  ExtraDDL9?: number;
  ExtraDDL10?: number;
  ExtraDDL11?: number;
  ExtraDDL12?: number;
  ExtraDDL13?: number;
  ExtraDDL14?: number;
  ExtraDDL15?: number;
  ExtraDDL0Value?: string;
  ExtraDDL1Value?: string;
  ExtraDDL2Value?: string;
  ExtraDDL3Value?: string;
  ExtraDDL4Value?: string;
  ExtraDDL5Value?: string;
  ExtraDDL6Value?: string;
  ExtraDDL7Value?: string;
  ExtraDDL8Value?: string;
  ExtraDDL9Value?: string;
  ExtraDDL10Value?: string;
  ExtraDDL11Value?: string;
  ExtraDDL12Value?: string;
  ExtraDDL13Value?: string;
  ExtraDDL14Value?: string;
  ExtraDDL15Value?: string;
  BrandCode: string;
  ServiceContract: string;
  POnumber: string;
  WarrantyEndDate?: Date;
  LifeDate?: Date;
  Age: string;
  ExportLicense: boolean;
  InternalCode: string;
  AccountingId: string;
  CanEndUsage: boolean;
  UsageId?: number;
  ServiceId?: number;
  ServiceTypeId: number;
  Allowed_ByTraining?: boolean;
  AllowToUser?: boolean;
  CanBeCreatedFromLinkOnly?: boolean;
  NotifyExists: boolean;
  EquipmentRestrictionsOverview: string;
  AssemblyId?: number;
  EquipmentMinOrder: string;
  EquipmentMaxOrder: string;
  AvailabilityTypeId?: number;
  LoanTypeId?: number;
  AssetCatInternalServiceAvaliable?: boolean;
  LocationUpdatedAt?: Date;
  LocationUpdatedByDn: string;
  Alerts: Entity<number>[];
  AlertInfo?: AlertInfo;
  AmAdmin: boolean;
  AlertsEnabled: boolean;
  ReservationEnabled: boolean;
  UsageEnabled: boolean;
  CanStartUsage: boolean;
  PlanedEventsEnabled: boolean;
  ShowFeed: boolean;
  ServiceGroupId?: number;
  DivisionId?: number;
  EndUsageIds: number[];
  Active?: boolean;
  Type2Counter?: number;
  AvailabilityTypeName: string;
  minorder?: number;
  maxorder?: number;
  ReservationMinimumGap?: number;
  MinTimeAheadPeriodType?: number;
  MinTimeAheadPeriodValue?: number;
  MinTimeAheadMinutesSinceMidnight?: number;
  PrerequisiteEquipments: Entity<number>[];
  ForceTutoring: boolean;
  MinimumTimeAhead?: number;
  CarryOverTimeSlotGap: number | null;
  CarryOverTimeSlotTypeId: number | null;
  CarryOverTimeSlotTypeName: string | null;
  ApprovalRequired: boolean;
}

export interface AlertInfo {
  show: boolean;
  hasActive: boolean;
  hasDownActive: boolean;
  noAlerts: boolean;
  assetId?: number;
  alertId?: number;
  isAdmin: boolean;
}

export interface AssetUser extends Entity<number> {
  DisplayName: string;
  Email: string;
  OfficeTel: string;
}

export interface IRestrictions {
  Id: number;
  Name: string;
  AssetId: number | null;
  ServiceGroupId: number;
  LimitlessReservationAllowed: boolean | null;
  PrerequisiteEquipments: IAssetDto[];
  ForceTutoring: boolean;
  RestrictionsOverview: string | null;
  ReservationRestrictions: string | null;
  MinReservationOrder: number | null;
  MaxReservationOrder: number | null;
  ReservationMinimumGap: number | null;
  MinTimeAheadPeriodValue: number | null;
  MinTimeAheadPeriodType: number | null;
  MinTimeAheadMinutesSinceMidnight: number | null;
  BypassQuotas: boolean;
  BypassUserQuotasAndGaps: boolean;
  BypassMinResDurationD: boolean;
  BypassMinResDurationT: number | null;
  BypassUserQuotasAndGapsD: number | null;
  MinReservationOrderGap: number;
  UserReservationGap: number;
  UserGroupReservationGap: number;
  InstituteReservationGap: number;
  CarryOverTimeSlotGap: number | null;
  CarryOverTimeSlotTypeId: number | null;
  CarryOverTimeSlotTypeName: string | null;
}
export const mapToAssetDto = (myAsset: IMyAssetsRow): IAssetDto => {
  return {
    Id: myAsset.Id,
    Name: myAsset.Name,
    AssetCatId: myAsset.AssetCatId,
    Availability: {
      Id: myAsset.AvailabilityTypeId,
      Name: myAsset.AvailabilityTypeName,
    },
    ServiceGroupId: myAsset.ServiceGroupId,
    ServiceGroupName: myAsset.ServiceGroupName,
    ServiceId: myAsset.ServiceId,
    ServiceTypeId: myAsset.ServiceTypeId,
    ServiceGroupAssetId: myAsset.ServiceGroupAssetId,
  } as IAssetDto;
};
