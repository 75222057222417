import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectLoading } from 'app/slice/selectors';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useTabTitle } from './useTabTitle';

export interface HeadTitleProps {
  isDispatcherLoading: boolean;
  i18Language: string;
}

export function HeadTitle() {
  const { i18n } = useTranslation();
  const isDispatcherLoading = useSelector(selectLoading);

  const [
    favIcon,
    title,
    handleFavIconChange,
    handleTitleChange,
    loaded,
  ] = useTabTitle();
  useEffect(() => {
    let active = !isDispatcherLoading && loaded;

    if (active) {
      handleFavIconChange(favIcon);
      handleTitleChange(title);
    }
    return () => {
      active = false;
    };
  }, [
    favIcon,
    handleFavIconChange,
    handleTitleChange,
    isDispatcherLoading,
    loaded,
    title,
  ]);

  // const globalSettings = useSelector(selectglobalSettings);
  // const siteMapProvider = useSelector(selectSiteMapProvider);
  // const [currentNode, setCurrentNode] = React.useState<SiteMapNode | undefined>(
  //   undefined,
  // );
  // const [favIcon, setFavIcon] = React.useState<string | undefined>(undefined);
  // const [favIconTitle, setFavIconTitle] = React.useState<string | undefined>(
  //   undefined,
  // );
  // const [, setLink] = React.useState<HTMLLinkElement | null>(null);
  // const createLink = React.useMemo(() => {
  //   let link: HTMLLinkElement | null = document.querySelector(
  //     "link[rel~='icon']",
  //   );
  //   if (link === null) {
  //     link = document.createElement('link');
  //     link.rel = 'icon';
  //     document.getElementsByTagName('head')[0].appendChild(link);
  //   }
  //   if (favIcon !== undefined && favIcon !== '') {
  //     link.href = toRootedURL('/uploads/Branding/' + favIcon);
  //   } else {
  //     link.href = process.env.PUBLIC_URL + '/favicon.png';
  //   }
  //   return link;
  // }, [favIcon]);
  //  React.useMemo(() => {
  //   return siteMapProvider.findNode(location.pathname);
  // }, [siteMapProvider, location.pathname]);
  // const [pageTitle, setPageTitle] = useState<string | undefined>(undefined);
  // useEffect(() => {
  //   let active = !isDispatcherLoading;
  //   if (active) {
  //     setCurrentNode(() => siteMapProvider.findNode(location.pathname));
  //     setFavIcon(() => globalSettings.GetString(AllowedSettings.FavIconImg));
  //     setFavIconTitle(() =>
  //       globalSettings.GetString(AllowedSettings.FavIconTitle),
  //     );
  //     setPageTitle(
  //       () =>
  //         `${favIconTitle} - ${
  //           currentNode === undefined ? '' : currentNode?.Title
  //         }`,
  //     );
  //     setLink(() => createLink);
  //   }
  //   return () => {
  //     active = false;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   currentNode,
  //   favIcon,
  //   favIconTitle,
  //   globalSettings,
  //   isDispatcherLoading,
  //   location.pathname,
  // ]);
  //RENDER
  return (
    <Helmet
      titleTemplate="%s - BookitLab!"
      defaultTitle={title || 'BookitLab!'}
      htmlAttributes={{ lang: i18n.language }}
    >
      <meta name="description" content="{process.env.PUBLIC_URL}" />
    </Helmet>
  );
}
