import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { AssetsPicker } from 'app/components/pickers/MultiSelectPickers/AssetsPicker';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const AssetMultiFilter = (props: IFilterComponentProps) => {
  const {
    onChange,
    value,
    additionalItem,
    customData,
    withoutServiceGroups,
    urlType,
    dateTimeClear,
    label,
    labelFunc,
    onOpen,
    onClose,
    open,
    fullWidth,
    ...other
  } = props;
  const handleChange = (value: Entity<Number>[]) => {
    onChange(value);
  };
  return (
    <AssetsPicker
      onChange={handleChange}
      value={value as IAssetDto[]}
      restrictByAvType="serviceRelated"
      size="small"
      {...other}
    />
  );
};
export const AssetWorkOrderMultiFilter = (props: IFilterComponentProps) => {
  const {
    onChange,
    value,
    additionalItem,
    customData,
    withoutServiceGroups,
    urlType,
    dateTimeClear,
    label,
    labelFunc,
    onOpen,
    onClose,
    open,
    ...other
  } = props;
  const handleChange = (value: Entity<Number>[]) => {
    onChange(value);
  };
  return (
    <AssetsPicker
      onChange={handleChange}
      value={value as IAssetDto[]}
      restrictByAvType="workOrderRelated"
      variant="standard"
      size="small"
      {...other}
    />
  );
};
