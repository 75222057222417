/**
 *
 * FormReservationStatusPicker
 *
 */
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { ReservationStatusPicker } from '../pickers/AutocompletePickers/ReservationStatusPicker';

export const FormReservationStatusPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <ReservationStatusPicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        variant="filled"
        disableClearable
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        info={props.info}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
      />
    </>
  );
};
