import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { ToggleButton, ToggleButtonProps } from '@material-ui/lab';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import * as React from 'react';
import clsx from 'clsx';
import { IconProp } from 'app/AllIcons';
import { ButtonsVariant } from '../Button';
import { capitalize } from 'lodash';

export interface BaseToggleButtonProps
  extends Omit<ToggleButtonProps, 'variant'> {
  disabled?: boolean;
  children?: React.ReactNode;
  processing?: boolean;
  variant?: ButtonsVariant;
  fullWidth?: boolean;
  component?: any;
  to?: string;
  target?: string;
  mainIcon?: IconProp;
  startIcon?: IconProp;
  endIcon?: IconProp;
  separated?: boolean;
}

export const BaseToggleButton = React.forwardRef<
  HTMLButtonElement,
  BaseToggleButtonProps
>(function BaseToggleButton(
  {
    children,
    disabled,
    processing,
    size,
    variant,
    component,
    title,
    to,
    mainIcon,
    fullWidth,
    separated,
    startIcon,
    endIcon,
    ...props
  },
  ref,
) {
  const handleClick = (e: any) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };
  const WithTitle = React.forwardRef(
    (
      {
        children,
        title,
      }: { children: React.ReactNode; title: React.ReactChild },
      ref,
    ) => (
      <Tooltip title={title} ref={ref}>
        <span className={'withTitle'}>{children}</span>
      </Tooltip>
    ),
  );
  const startIconComponent = startIcon && (
    <span className={clsx('startIcon', `iconSize${capitalize(size)}`)}>
      <Icon icon={startIcon} />
    </span>
  );
  const endIconComponent = endIcon && (
    <span className={clsx('endIcon', `iconSize${capitalize(size)}`)}>
      <Icon icon={endIcon} />
    </span>
  );
  return title ? (
    <WithTitle title={title}>
      <ToggleButton
        variant={
          variant === 'text' || variant === 'textGray' ? 'text' : undefined
        }
        size={size}
        disabled={(processing || disabled) ?? false}
        ref={ref}
        component={component}
        disableFocusRipple
        onClick={event => {
          handleClick(event);
        }}
        className={props.className}
        {...props}
      >
        {mainIcon !== undefined ? (
          <span className={clsx('mainIcon', `iconSize${capitalize(size)}`)}>
            <Icon icon={mainIcon as IconProp} />
          </span>
        ) : !!startIcon || !!endIcon ? (
          <React.Fragment>
            {!!startIconComponent && startIconComponent}
            <span className={'buttonLabel'}>{children}</span>
            {!!endIconComponent && endIconComponent}
          </React.Fragment>
        ) : (
          <span className={'buttonLabel'}>{children}</span>
        )}
      </ToggleButton>
    </WithTitle>
  ) : (
    <ToggleButton
      variant={
        variant === 'text' || variant === 'textGray' ? 'text' : undefined
      }
      size={size}
      disabled={(processing || disabled) ?? false}
      ref={ref}
      component={component}
      onClick={event => {
        handleClick(event);
      }}
      className={props.className}
      {...props}
    >
      {mainIcon !== undefined ? (
        <span className={clsx('mainIcon', `iconSize${capitalize(size)}`)}>
          <Icon icon={mainIcon as IconProp} />
        </span>
      ) : !!startIcon || !!endIcon ? (
        <React.Fragment>
          {!!startIconComponent && startIconComponent}
          <span className={'buttonLabel'}>{children}</span>
          {!!endIconComponent && endIconComponent}
        </React.Fragment>
      ) : (
        <span className={'buttonLabel'}>{children}</span>
      )}
    </ToggleButton>
  );
});
