/**
 *
 * DialogAlert
 *
 */
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';

export interface DialogAlertProps {
  title: string;
  value: string | undefined;
  buttonText: string;
  show: boolean;
  onButtonClick: () => void;
  onClose: () => void;
}

export const DialogAlert = ({
  title,
  value,
  buttonText,
  show,
  onButtonClick,
  onClose,
}: DialogAlertProps) => {
  const handleClose = () => {
    onClose();
  };
  const handleButtonClick = () => {
    onButtonClick();
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (show) {
    return (
      <Dialog
        open={true}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {value}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleButtonClick} color="primary">
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return <> </>;
  }
};
