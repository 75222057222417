import Divider from '@material-ui/core/Divider';
import { AssetQuickSearch } from 'app/components/AssetQuickSearch';
import { selectShowSearchInSidebar } from 'app/components/AssetQuickSearch/slice/selectors';
import { Box } from 'app/components/basic/Wrappers/Box';
import { BookitlabLogo } from 'app/components/BookitlabLogo';
import { BookitlabShortLogo } from 'app/components/BookitlabLogo/BookitlabShortLogo';
import { CoreSelectionButton } from 'app/components/GlobalServiceGroupFilter/CoreSelectionButton';
import { selectMinimizedBookitlabLogo } from 'app/slice/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { DetectIsMobile } from 'utils/mobileDetect';
import { CustomerLogo } from '../../CustomerLogo';
import { ProfileMenu } from '../../TopBar/ProfileMenu';
import { useStyles } from '../styles';

export interface SideBarHeaderProps {
  children?: React.ReactNode;
  isOpen: boolean;
}

export const SideBarHeader = React.memo(function SideBarHeader(
  props: SideBarHeaderProps,
) {
  const { children, isOpen } = props;
  const classes = useStyles({ isOpen: isOpen });
  const isMobile = DetectIsMobile();
  const showSearchInSidebar = useSelector(selectShowSearchInSidebar);
  const logoMinimized = useSelector(selectMinimizedBookitlabLogo);
  return (
    <React.Fragment>
      <div className={classes.sideBarHeader}>
        {isMobile ? (
          <CustomerLogo />
        ) : isOpen && !logoMinimized ? (
          <BookitlabLogo />
        ) : (
          <BookitlabShortLogo />
        )}
        {isMobile && isOpen && <ProfileMenu />}
        {children}
      </div>
      {isMobile && isOpen && showSearchInSidebar && (
        <div
          style={{
            padding: '0px 16px',
            display: 'flex',
            width: '100%',
            // flexFlow: 'column',
          }}
        >
          <AssetQuickSearch />
        </div>
      )}
      {isMobile && isOpen && (
        <Box
          style={{
            padding: '8px 16px',
            display: 'flex',
            flexFlow: 'column',
          }}
        >
          <CoreSelectionButton />
          {/* <GlobalServiceGroupFilter useButtons /> */}
        </Box>
      )}
      <Divider />
    </React.Fragment>
  );
});
