import { Switch, SwitchProps } from '@material-ui/core';

export interface ToggleProps extends SwitchProps {
  danger?: boolean;
  label?: string;
}

export function Toggle(props: ToggleProps) {
  const {
    color = 'primary',
    label,
    danger,
    size = 'medium',
    className,
    ...other
  } = props;
  return (
    <Switch
      disableRipple
      color={danger ? 'secondary' : color === 'default' ? 'primary' : color}
      inputProps={{ 'aria-label': !!label ? label : 'decorative toggle' }}
      className={className}
      size={size}
      {...other}
    />
  );
}
