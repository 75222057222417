import { FieldHookConfig, useField } from 'formik';
import {
  BaseURLPicker,
  BaseURLPickerProps,
} from '../BasicPickers/BaseURLPicker';
import { Entity } from 'types/common';

export const FormServiceRequestMilestoneChargeServicePicker = ({
  serviceRequestId,
  ...props
}: FieldHookConfig<Entity<number> | null> &
  Omit<BaseURLPickerProps<Entity<number>>, 'onChange' | 'url'> & {
    serviceRequestId: number;
  }) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  const handleChange = val => {
    props.onChange?.(val);
    helpers.setValue(val, true);
  };
  const url = `/api/odata/v4/ServiceRequests/${serviceRequestId}/ConsumableServices`;
  return (
    <BaseURLPicker
      url={url}
      predicates={props.predicates}
      name={props.name}
      placeholder={props.placeholder}
      disabled={props.disabled}
      label={props.label}
      onChange={handleChange}
      onBlur={field?.onBlur}
      value={field.value}
      error={meta?.error !== undefined}
      helperText={meta.error}
      fullWidth={props.fullWidth}
      variant="filled"
    />
  );
};
