import { httpClient } from 'api/HttpClient';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { AutocompleteMultiPickerExProps } from 'app/components/BasicPickers/AutocompleteMultiPickerEx';
import { AssetsPicker } from '../../MultiSelectPickers/AssetsPicker';

export interface AssetMultiPickerProps
  extends AutocompleteMultiPickerExProps<IAssetDto> {}

const url = '/api/odata/v4/AssetNames';

export const initAssetMultiData = (initval: string | undefined) => {
  if (initval === undefined) {
    return Promise.resolve([] as IAssetDto[]);
  }
  const ids = initval.split('|').map(v => parseInt(v));
  const params: {
    $orderby: string;
    $filter?: string;
    $top: number;
  } = {
    $orderby: 'Id asc',
    $filter: '(Id in (' + ids.join(',') + '))',
    $top: ids.length,
  };
  return httpClient
    .get(url, params)
    .then(response => response.value as IAssetDto[]);
};

export const AssetMultiPicker = (props: AssetMultiPickerProps) => {
  return <AssetsPicker {...props} />;
};
