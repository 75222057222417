/**
 *
 * OtherServiceDetailsPage
 *
 */
import { OfflineServiceQueryStringParameters } from '../Details/slice/types';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { OtherServiceDetails, OtherServiceDetailsProps } from '../Details';
import { getParams } from './getQueryStringParameter';
import { useDispatch } from 'react-redux';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { Button } from 'app/components/BasicButtons/Button';

export interface OtherServiceDetailsPageProps<
  Params extends {
    [K in keyof OfflineServiceQueryStringParameters]?: string | undefined;
  } = {}
> extends RouteComponentProps<Params> {}
export function OtherServiceDetailsPage(props: OtherServiceDetailsPageProps) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryProps = getParams(params, props);
  return <OtherServiceDetails useSidePanel={false} queryParams={queryProps} />;
}
export function OtherServiceEditLinkprops(props: { id: number }) {
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  return (
    <Button
      variant="text"
      size="small"
      onClick={() =>
        dispatch(
          actions.openSidePanel({
            type: RenderPageType.OtherServiceDetails,
            props: {
              useSidePanel: true,
              queryParams: { id: props.id.toString() },
              initialService: undefined,
            } as OtherServiceDetailsProps,
          }),
        )
      }
    >
      {props.id}
    </Button>
  );
}
