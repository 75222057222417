/**
 * Represents Date with representation info - e.g. representation: 'complete' | 'date' | 'time'
 */
export class CustomDate extends Date {
  representation: DateType = 'date';
  constructor(value?: number | string | Date, type?: DateType) {
    super(value ?? new Date());
    this.representation = type ?? 'complete';
  }
}

export type DateType = 'complete' | 'date' | 'time';
