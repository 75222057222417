import { useFormikContext } from 'formik';
import { Entity } from 'types/common';
import * as React from 'react';
import { GlobalSettingsType } from '../useGlobalSettingsHook';
import { useSelector } from 'react-redux';
import { selectEquipmentsSettings } from '../../slice/selectors';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { IReservationEquipmentDto } from '../../slice/types';
import { getBudgetVisible } from '../../slice/getBudgetVisible';

export interface UserGroupListenerProps<T> {
  userName: Entity<string> | null;
  equipments: Entity<number>[];
  userGroup: Entity<string> | null | undefined;
  startTime: Date | string;
  endTime: Date | string;
  globalSettings: GlobalSettingsType;
  user: AuthenticatedUser | undefined;
  isEdit: boolean;
  values?: T;
}
export function UserGroupListener<T>(props: UserGroupListenerProps<T>) {
  const { userName, equipments, userGroup, globalSettings, isEdit } = props;
  const [initilized, setInitilized] = React.useState(true);
  const equipmentSettings = useSelector(selectEquipmentsSettings);
  const budgetVisible = React.useMemo(() => {
    return getBudgetVisible(
      globalSettings,
      equipments as IReservationEquipmentDto[],
    );
  }, [equipments, globalSettings]);

  const ReservationUserGroupGroupByBudgetUserGroup = React.useMemo(() => {
    return !!equipmentSettings
      ? globalSettings.reservationUserGroupGroupByBudgetUserGroup &&
          !equipmentSettings.HasHideProjects &&
          !equipmentSettings.HasConflictProjects
      : globalSettings.reservationUserGroupGroupByBudgetUserGroup;
  }, [
    equipmentSettings,
    globalSettings.reservationUserGroupGroupByBudgetUserGroup,
  ]);
  const { setFieldValue } = useFormikContext<T>();
  React.useEffect(() => {
    if (!initilized) {
      if (userGroup !== null) {
        if (
          budgetVisible &&
          !isEdit &&
          equipments.length > 0 &&
          userName !== null
        ) {
          if (!ReservationUserGroupGroupByBudgetUserGroup) {
            setFieldValue('Budget', null);
          }
        }
      } else {
        if (!ReservationUserGroupGroupByBudgetUserGroup) {
          setFieldValue('BookedBy', null);
          if (budgetVisible && !isEdit) {
            setFieldValue('Budget', null);
          }
        }
      }
    }
    setInitilized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup]);

  return null;
}
