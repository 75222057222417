import * as React from 'react';
import { FieldHookConfig, useField } from 'formik';
import { FormControl } from '@material-ui/core';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { Entity } from 'types/common';
import CustomPickerFilter from '../BasicTable/Filters/CustomPickerFilter';

export interface FormCustomPickerProps extends IFieldProps {
  data: Entity<number>[];
}
export const FormCustomPicker = ({
  label,
  disableFuture,
  disablePast,
  data,
  ...props
}: FieldHookConfig<Entity<number> | null> & FormCustomPickerProps) => {
  const [field, , helpers] = useField<Entity<number> | null>(props);
  return (
    <FormControl fullWidth={true}>
      <CustomPickerFilter
        id={props.id}
        name={props.name}
        label={label}
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        value={field.value}
        customData={data}
        fullWidth={true}
        open={false}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
    </FormControl>
  );
};
