import { ExternalLink } from 'app/components/ExternalLink';

/**
 * Link to edit a training session details (admin)
 * @param param0
 * @returns
 */
export function EditTrainingSessionLink({
  trainingSessionId: assetId,
  ...props
}: React.PropsWithChildren<{ trainingSessionId: number }>) {
  return (
    <ExternalLink
      href={{
        path: '/TrainingSession/Edit.aspx',
        search: { id: assetId },
      }}
      {...props}
    />
  );
}
