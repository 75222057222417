import { SchedulerStatic } from 'dhtmlx-scheduler';
import { useDispatch } from 'react-redux';
import { usePrintPDFSlice } from '../slice';
import React, { useCallback } from 'react';
import { PrintToPDFPopup } from '../Components/PrintToPDFPopup';
import { add_export_methods } from './ExportMethods';

export interface PrintSchedulerToPDFProps {
  title: string;
  print: boolean;
  printTitle: string;
  saveMarkedSections?: boolean;
  scheduler?: SchedulerStatic;
  close: () => void;
}
export function PrintSchedulerToPDF(props: PrintSchedulerToPDFProps) {
  const dispatch = useDispatch();
  const { actions } = usePrintPDFSlice();
  const handleSubmit = useCallback(() => {
    add_export_methods(props.scheduler);
    props.scheduler?.exportToPDF({
      name: props.printTitle + '.pdf',
      format: 'A4',
      orientation: 'landscape',
      zoom: 1,
      header: `<h1>${props.printTitle}</h1> <style>${styles}</style>`,
      //footer: '<h4>Bottom line</h4>',
    });
    dispatch(actions.setPrinting(false));
    props.close();
  }, [actions, dispatch, props]);
  return (
    <>
      {props.print && (
        <PrintToPDFPopup
          title={props.title}
          show={props.print}
          items={[]}
          fullWidth={false}
          onClose={() => {
            props.close();
          }}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}
const styles = `.dhx_timeline_label_column {
  justify-content: left;
}
.dhx_cal_navline div {
  position: relative;
}

.medium_lines_section {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAQCAYAAABk1z2tAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE5OUE3RTM4OTQ0OTExRTFCNzFDRTY3NEMwNjJGNjQzIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE5OUE3RTM5OTQ0OTExRTFCNzFDRTY3NEMwNjJGNjQzIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTk5QTdFMzY5NDQ5MTFFMUI3MUNFNjc0QzA2MkY2NDMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTk5QTdFMzc5NDQ5MTFFMUI3MUNFNjc0QzA2MkY2NDMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4Z9pN0AAAAhUlEQVR42uyUSwrAMAhE1cP0/ufpZWy6KI2hxOIYGkrcJQPDw88w3bWRrb15G11Vjc7MIb38d/25B+FB1noxDEFWgI/+LWAY8gSMdNLzF6RrGaP3/AUd7WhIydg/5Eg8f/66e95OCgqEdvLNkdDMkDLraC//lYMrB3+Xg+jODs/BbP0QYAB+HaheBAg0CAAAAABJRU5ErkJggg==);
}
.fat_lines_section {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAASCAYAAAAzI3woAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjRERUI1OEJFOTQ0OTExRTE4RTFCQjY5QkY2ODNGRjA3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjRERUI1OEJGOTQ0OTExRTE4RTFCQjY5QkY2ODNGRjA3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NERFQjU4QkM5NDQ5MTFFMThFMUJCNjlCRjY4M0ZGMDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NERFQjU4QkQ5NDQ5MTFFMThFMUJCNjlCRjY4M0ZGMDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz71ubGsAAAAlUlEQVR42syVSw7AIAhEcdI7eP/jeQo/u6ZRxBbHkpi4IONTYLxEJEo/Us5ZtAghRJnHkg6UvFgTVaF6UDIALenAU8xDB943tOq0/XO18mLHs3/Rwa5eeKtzLxkVygL0C6heUx+FGk3ZMSht7I9AzXyIDjU1RrYlwPJBMqFg/bVZULCYFRMKVgf1hhoNDFZsnQFVBBgA5xp1SmVfHOUAAAAASUVORK5CYII=);
}
.small_lines_section {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjMwMzFDOUQ3OTQ0QTExRTFBOUU1QzkwQ0U3QzU5MTFGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjMwMzFDOUQ4OTQ0QTExRTFBOUU1QzkwQ0U3QzU5MTFGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzAzMUM5RDU5NDRBMTFFMUE5RTVDOTBDRTdDNTkxMUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzAzMUM5RDY5NDRBMTFFMUE5RTVDOTBDRTdDNTkxMUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz77M37BAAAAHUlEQVR42mJgQABfBlSAzsetiHyd1FNEmfEAAQYAdS4E0c+LoDcAAAAASUVORK5CYII=);
}
.dhx_time_block {
  border: 1px solid #ccc;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAASCAYAAAAzI3woAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjRERUI1OEJFOTQ0OTExRTE4RTFCQjY5QkY2ODNGRjA3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjRERUI1OEJGOTQ0OTExRTE4RTFCQjY5QkY2ODNGRjA3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NERFQjU4QkM5NDQ5MTFFMThFMUJCNjlCRjY4M0ZGMDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NERFQjU4QkQ5NDQ5MTFFMThFMUJCNjlCRjY4M0ZGMDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz71ubGsAAAAlUlEQVR42syVSw7AIAhEcdI7eP/jeQo/u6ZRxBbHkpi4IONTYLxEJEo/Us5ZtAghRJnHkg6UvFgTVaF6UDIALenAU8xDB943tOq0/XO18mLHs3/Rwa5eeKtzLxkVygL0C6heUx+FGk3ZMSht7I9AzXyIDjU1RrYlwPJBMqFg/bVZULCYFRMKVgf1hhoNDFZsnQFVBBgA5xp1SmVfHOUAAAAASUVORK5CYII=);
}
.event_offline {
  border: 1px solid #ccc;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAQCAYAAABk1z2tAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE5OUE3RTM4OTQ0OTExRTFCNzFDRTY3NEMwNjJGNjQzIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE5OUE3RTM5OTQ0OTExRTFCNzFDRTY3NEMwNjJGNjQzIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTk5QTdFMzY5NDQ5MTFFMUI3MUNFNjc0QzA2MkY2NDMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTk5QTdFMzc5NDQ5MTFFMUI3MUNFNjc0QzA2MkY2NDMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4Z9pN0AAAAhUlEQVR42uyUSwrAMAhE1cP0/ufpZWy6KI2hxOIYGkrcJQPDw88w3bWRrb15G11Vjc7MIb38d/25B+FB1noxDEFWgI/+LWAY8gSMdNLzF6RrGaP3/AUd7WhIydg/5Eg8f/66e95OCgqEdvLNkdDMkDLraC//lYMrB3+Xg+jODs/BbP0QYAB+HaheBAg0CAAAAABJRU5ErkJggg==);
}

.dhx_cal_event .dhx_body,
.dhx_cal_event .dhx_footer,
.dhx_cal_event .dhx_header,
.dhx_cal_event .dhx_title {
  background-color: initial;
  padding: 3px 5px 0px 5px;
}

.dhx_title .dhx_training_status {
  float: right;
  margin: 0px 4px;
}

.dhx_title > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dhx_cal_event .dhx_event_move {
  cursor: pointer;
}
.dhx_cal_event_line,
.dhx_cal_event .dhx_body,
.dhx_cal_event.dhx_cal_select_menu .dhx_body {
  font-weight: normal;
}
.dhx_scale_holder .dhx_cal_event .dhx_body {
  overflow: hidden;
}
.scheduler_tooltip {
  max-width: 350px;
}
.dhx_cal_event_line p,
.scheduler_tooltip p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.scheduler_tooltip div span p {
  margin: 0;
  white-space: pre-wrap;
}
.dhx_cal_event .dhx_body p {
  white-space: break-spaces;
  margin: 0;
  margin-right: 1%;
}
.dhx_cal_event_line {
  padding: 0 0 1px 6px;
}
.dhx_cal_event {
  z-index: 2;
}

.dhx_cal_event .dhx_title:not(.dhx_event_move) {
  cursor: initial;
}

.dhx_timeline_label_column .schedule_service_label {
  justify-content: start;
  align-self: center;
}
.dhx_scale_bar .schedule_service_container {
  flex-wrap: wrap-reverse;
}
.dhx_cal_header div.schedule_service_label {
  box-sizing: border-box;
}
.dhx_matrix_scell.item .dhx_scell_name {
  padding-left: 0px;
}
.schedule_service_container {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-direction: row;
  padding: 0px;
  gap: 10px;
  height: 38px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.schedule_service_image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 64px;
  height: 38px;

  background: #eef2f6;
  border-radius: 4px;
  cursor: pointer;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.schedule_service_image svg {
  align-self: center;
}
.schedule_service_image img {
  max-height: 38px;
  height: auto;
  width: auto;
}
.dhx_scheduler_day_unit .schedule_service_image {
  width: 24px;
  height: 24px;
}
.dhx_scheduler_day_unit .schedule_service_image img {
  max-height: 24px;
  max-width: 24px;
  height: inherit;
}
.dhx_scheduler_day_unit .schedule_service_image svg {
  align-self: center;
  justify-self: center;
}
.dhx_scheduler_day_unit .dhx_scale_bar .schedule_service_container {
  gap: 6px;
  flex-wrap: nowrap;
}
.schedule_service_label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  height: 24px;

  border-radius: 4px;

  flex: none;
  order: 2;
  flex-grow: 0;
  line-height: initial;
  color: white;
  cursor: pointer;

  max-width: min(100%, 26ch);
}
.schedule_service_label_padding {
  padding: 3px 8px;
}
.schedule_service_label_mobile_padding {
  padding: 2px 2px;
}
.schedule_service_label_text_mobile {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 12ch;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 141%;
  -webkit-letter-spacing: 0.1px;
  -moz-letter-spacing: 0.1px;
  -ms-letter-spacing: 0.1px;
  letter-spacing: 0.1px;
  font-family: 'Lato', sans-serif;
}
.schedule_service_label_text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  text-transform: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 141%;
  -webkit-letter-spacing: 0.1px;
  -moz-letter-spacing: 0.1px;
  -ms-letter-spacing: 0.1px;
  letter-spacing: 0.1px;
  font-family: 'Lato', sans-serif;
}

.dhx_timeline_scale_header.dhx_timeline_second_scale {
  width: 300px !important;
  height: 30px !important;
}
.dhx_cal_header div .dhx_scale_bar.dhx_second_scale_bar {
  border-left: 1px solid #e0e0e0;
  text-align: left;
  padding-left: 3px;
}
.dhx_timeline_second_scale.dhx_timeline_scale_header {
  height: 60px !important;
}
.dhx_cal_mobile .dhx_timeline_second_scale.dhx_timeline_scale_header {
  height: 70px !important;
  width: 100px !important;
}

.GroupByAssemblyPart .dhx_matrix_cell,
.GroupByAssemblyPart .dhx_matrix_scell {
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
.GroupByAssemblyPart .dhx_matrix_scell.folder,
.GroupByAssemblyPart .dhx_data_table.folder .dhx_matrix_cell {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.GroupByAssemblyPart .dhx_matrix_scell.folder,
.GroupByAssemblyPart .dhx_data_table.folder .dhx_matrix_cell,
.GroupByAssemblyPart .dhx_matrix_scell.folder.opened .dhx_scell_expand::after,
.GroupByAssemblyPart .dhx_matrix_scell.folder.closed .dhx_scell_expand::after {
  background-color: #fff; 
}

.GroupByAssemblyPart .dhx_matrix_scell.folder,
.GroupByAssemblyPart .dhx_data_table.folder .dhx_matrix_cell {
  cursor: auto;
}

.GroupByAssemblyPart .dhx_scell_expand::after,
.GroupByAssemblyPart .dhx_scell_level {
  cursor: pointer;
}
`;
