import { httpClient } from 'api/HttpClient';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { Entity } from 'types/common';

export const getEnabledAttributes = async (
  enabled: string[],
  isAuthenticated?: boolean,
) => {
  let url = '/Api/Odata/v4/AssetAttributes';
  let publicUrl = '/api/odata/v4/AssetAttributes/PublicAssetAttributes';
  try {
    const params = {
      $filter: new Condition(
        'Id',
        ODataOperators.Includes,
        enabled.map(s => ({ Id: s })),
      ).toString(),
    };
    let result = await httpClient.get(
      isAuthenticated === true ? url : publicUrl,
      params,
    );
    const filtered = result.value;
    return filtered as Entity<string>[];
  } catch (err) {
    return [];
  }
};
