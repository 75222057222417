import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { RenewStockState } from '../Details/slice/types';
import React from 'react';
import * as Yup from 'yup';
import { translations } from 'locales/translations';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';

import {
  selectInventoryBatchEditable,
  selectRenewStockHasErrors,
  selectRenewStockValueHandler,
} from '../Details/slice/selectors';
import { useOfflineServiceStateSlice } from '../Details/slice';
import { isEmpty } from 'lodash';
import {
  FieldHandler,
  FormListener,
  SubmittingHandler,
} from 'app/components/Forms/FormRender/FormRenderer';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { RenewStockRelatedFilter } from 'app/components/pickers/AutocompletePickers/OfflineServiceTypePicker';
import { ServiceTypeSchema } from 'app/components/Forms/Schemas';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { FormOfflineServiceTypePicker } from 'app/components/Forms/FormOfflineServiceTypePicker';
import { FormNumberField } from 'app/components/Forms/FormNumberField';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { FormInventoryBatchPicker } from 'app/components/Forms/FormInventoryBatchPicker';
import { FormLabelText } from 'app/components/Forms/FormLabelText';
import { Box } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { BatchesByServiceTypeFilter } from 'app/components/pickers/AutocompletePickers/InventoryBatchesPicker';
import { FormBookitDateTimePicker } from 'app/components/Forms/FormBookitDateTimePicker';

export interface RenewStockFormProps {
  onSubmit: (item: RenewStockState) => void;
  initialValues: RenewStockState;
  processing?: boolean;
  bindSubmitForm: any;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  innerFormRef: React.RefObject<FormikProps<RenewStockState>>;
  globalSettings: GlobalSettingsType;
  isEdit: boolean;
  editCreatable?: boolean;
  onBatchClick: (
    batch: IInventoryBatchDto | null,
    serviceTypeId: number,
  ) => void;
}

export const RenewStockForm = React.memo(function RenewStockForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isAdmin,
  user,
  globalSettings,
  innerFormRef,
  isEdit,
  editCreatable,
  onBatchClick,
}: RenewStockFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();

  const fieldHandler = useSelector(selectRenewStockValueHandler);
  const hasError = useSelector(selectRenewStockHasErrors);
  const batchRequested = useSelector(selectInventoryBatchEditable);
  const { newDate } = useSystemDate();
  // const readonly = !isAdmin;
  const [submitting, setSubmitting] = React.useState<boolean | undefined>(
    undefined,
  );
  const resetSubmitting = () => {
    setSubmitting(true);
  };
  const handleSubmit = (values: RenewStockState) => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(values);
    }
  };
  const resetFieldHandler = () => {
    dispatch(actions.resetRenewStockValueHandler());
  };

  /// Local State ///
  const [serviceChanged, setServiceChanged] = React.useState(false);
  const [batchChanged, setBatchChanged] = React.useState(false);

  const [serviceTypeSelected, setServiceTypeSelected] = useAsyncExtendedState<
    IOfflineServiceFilterDto | undefined
  >(initialValues.ServiceType ?? undefined);
  const minStep = React.useMemo(() => {
    return !!serviceTypeSelected
      ? serviceTypeSelected.IntQuantityOnly === true
        ? 1
        : 0.01
      : 1;
  }, [serviceTypeSelected]);
  const step = React.useMemo(() => {
    return !!serviceTypeSelected
      ? serviceTypeSelected.IntQuantityOnly === true
        ? 1
        : 0.01
      : 1;
  }, [serviceTypeSelected]);

  const maxStep = 9999;
  const inputProps = {
    step: step,
    min: minStep,
    max: maxStep,
    'aria-label': 'Counter',
  };

  // const IsAdmin = React.useCallback(
  //   (service: IOfflineServiceFilterDto) => {
  //     return user?.IsAllGroupOrLabTechAdmin(
  //       [service.ServiceGroupId ?? 0],
  //       [
  //         {
  //           Id: service.Id,
  //           Name: service.Name,
  //           ServiceTypeId: ServiceType.Offline,
  //         } as IServiceTypeFilterDto,
  //       ],
  //     );
  //   },
  //   [user],
  // );

  const handleFormChange = React.useCallback(
    (values: RenewStockState) => {
      if (serviceChanged) {
        setServiceTypeSelected(values.ServiceType ?? undefined);
        // dispatch(
        //   connectedFiltersActions.extendSettingsState({
        //     services:
        //       values.Services.length < 1
        //         ? []
        //         : values.Services.map(f => {
        //             return {
        //               Id: f.ServiceTypeId,
        //               Name: f.ServiceTypeName,
        //               ServiceGroupId: f.ServiceGroupId,
        //               ServiceTypeId: ServiceType.Offline,
        //               Active: true,
        //               BudgetsTurnedOn: f.BudgetsTurnedOn,
        //               HideProject: f.HideProjects,
        //             } as IServiceTypeFilterDto;
        //           }),
        //     globalSettings: globalSettings,
        //     settings: serviceSettings,
        //     isEdit: false,
        //     data: connectedData,
        //   }),
        // );
        // if (values.Services.length > 0 && connectedData?.User !== null) {
        //   const parameters = {
        //     ServiceTypes: values.Services.map(f => f.ServiceTypeID),
        //     ReservationId: null,
        //     UsageId: null,
        //     BookedBy: connectedData?.User?.Id || null,
        //     ADGroup: connectedData?.UserGroup?.Id || null,
        //     Start: dateUtils.formatISO(
        //       dateUtils.dateOrStringToDate(
        //         connectedData?.StartTime || newDate(),
        //       ),
        //     ),
        //     BudgetId: connectedData?.Budget?.Id || null,
        //     FundingTypeId: connectedData?.FundingType?.Id || 0,
        //   } as ServiceChangeStateParameters;
        //   dispatch(actions.initServiceChangeStateData(parameters));
        // }
        //setServiceTypesSelected(values.Services);
        setServiceChanged(false);
      }
      if (batchChanged) {
        setBatchChanged(false);
      }
    },
    [batchChanged, serviceChanged, setServiceTypeSelected],
  );

  // useEffects
  React.useEffect(() => {
    if (innerFormRef.current) {
      innerFormRef.current.validateForm();
    }
  }, [innerFormRef]);
  React.useEffect(() => {
    if (batchRequested !== undefined) {
      dispatch(
        actions.setRenewStockValue({
          fieldKey: 'InventoryBatch',
          fieldValue: batchRequested,
        }),
      );
      dispatch(actions.setInventoryBatchEditable(undefined));
    }
  }, [actions, batchRequested, dispatch]);
  // React.useEffect(() => {
  //   if (!!serviceChangeState) {
  //     console.log('service settings: ', serviceSettings);
  //     if (!!serviceChangeState) {
  //       if (serviceSettings.budgetVisible) {
  //         dispatch(
  //           connectedFiltersActions.setConnectedValue({
  //             fieldKey: 'Budget',
  //             fieldValue: serviceChangeState.DefaultBudget,
  //           }),
  //         );
  //         if (
  //           serviceSettings.ReservationUserGroupGroupByBudgetUserGroup &&
  //           serviceChangeState.DefaultBudget !== null
  //         ) {
  //           dispatch(
  //             connectedFiltersActions.setConnectedValue({
  //               fieldKey: 'UserGroup',
  //               fieldValue: {
  //                 Id: serviceChangeState.DefaultBudget.UserGroupId,
  //                 Name: serviceChangeState.DefaultBudget.UserGroupName,
  //               },
  //             }),
  //           );
  //         }
  //         if (
  //           serviceSettings.budgetExpirementVisible &&
  //           serviceChangeState.DefaultExperiment !== null
  //         ) {
  //           dispatch(
  //             connectedFiltersActions.setConnectedValue({
  //               fieldKey: 'BudgetExperiment',
  //               fieldValue: serviceChangeState.DefaultExperiment,
  //             }),
  //           );
  //         }
  //       } else {
  //         if (serviceSettings.isUserGroupCoordinator) {
  //           dispatch(
  //             connectedFiltersActions.setConnectedValue({
  //               fieldKey: 'UserGroup',
  //               fieldValue: {
  //                 Id: user?.ActiveUserGroup?.Id,
  //                 Name: user?.ActiveUserGroup?.Name,
  //               },
  //             }),
  //           );
  //         }
  //       }
  //     }
  //     dispatch(actions.resetServiceChangeState());
  //   }
  // }, [
  //   actions,
  //   connectedFiltersActions,
  //   dispatch,
  //   serviceChangeState,
  //   serviceSettings,
  //   user?.ActiveUserGroup?.Id,
  //   user?.ActiveUserGroup?.Name,
  // ]);
  const computedSchema = React.useMemo(() => {
    const renewStockSchema: Yup.SchemaOf<RenewStockState> = Yup.object({
      Id: Yup.number().notRequired().default(-1),
      ServiceType: ServiceTypeSchema.nullable().required(
        t(translations.err_OfflineServiceTypeRequired),
      ),
      Quantity: Yup.number()
        .label(t(translations.Quantity) as string)
        .required(t(translations.err_Quantity_required))
        .nullable()
        .default(null)
        .max(maxStep)
        .min(minStep)
        .test(
          'empty',
          t(translations.err_Quantity_required) as string,
          (value, context) => {
            let currVal = (context as any).from[0].value.Quantity;
            if (
              currVal === undefined ||
              currVal === null ||
              isNaN(currVal) ||
              currVal === 0
            ) {
              return false;
            }
            return true;
          },
        )
        .test(
          'integerOnly',
          t(translations.err_IntQuantityOnly) as string,
          (value, context) => {
            let currVal = (context as any).from[0].value.Quantity;
            if (
              !!serviceTypeSelected &&
              serviceTypeSelected.IntQuantityOnly &&
              currVal % 1 !== 0
            ) {
              return false;
            }
            return true;
          },
        ),
      DateReceived: Yup.date()
        .default(newDate())
        .label(t(translations.DateReceived) as string)
        .required(t(translations.err_DateReceived_required) as string),
      Remarks: Yup.string()
        .nullable()
        .label(t(translations.Remarks) as string),
      InventoryBatch: (Yup.mixed() as Yup.SchemaOf<IInventoryBatchDto>)
        .nullable()
        .label(t(translations.Batch) as string),
    });
    if (!!serviceTypeSelected && serviceTypeSelected.TrackInventory) {
      if (serviceTypeSelected?.NotLessThanZero) {
        if (serviceTypeSelected.Inventory === 0) {
          renewStockSchema.fields.Quantity.withMutation(schema =>
            schema
              .label(t(translations.Quantity) as string)
              .required(t(translations.err_Quantity_required))
              .default(null)
              .nullable()
              .max(maxStep)
              .min(minStep)
              .test(
                'empty',
                t(translations.err_Quantity_required) as string,
                (value, context) => {
                  let currVal = context.from[0].value.Quantity;
                  if (
                    currVal === undefined ||
                    currVal === null ||
                    isNaN(currVal) ||
                    currVal === 0
                  ) {
                    return false;
                  }
                  return true;
                },
              )
              .test(
                'minQuantWithZero',
                t(
                  translations.NotAllowNegativeInventory_currentEqualsZero_err,
                ) as string,
                (value, context) => {
                  let currVal = context.from[0].value.Quantity;
                  let current = currVal as number;
                  if (current < 1) {
                    return false;
                  }
                  return true;
                },
              ),
          );
        } else {
          renewStockSchema.fields.Quantity.withMutation(schema =>
            schema
              .label(t(translations.Quantity) as string)
              .required(t(translations.err_Quantity_required))
              .max(maxStep)
              .min(minStep)
              .test(
                'empty',
                t(translations.err_Quantity_required) as string,
                (value, context) => {
                  let currVal = context.from[0].value.Quantity;
                  if (
                    currVal === undefined ||
                    currVal === null ||
                    isNaN(currVal) ||
                    currVal === 0
                  ) {
                    return false;
                  }
                  return true;
                },
              )
              .test(
                'minQuant',
                (t(
                  translations.NotAllowNegativeInventory_currentMoreThanZero_err,
                ) as string).replace(
                  '{0}',
                  serviceTypeSelected.Inventory.toString(),
                ),
                (value, context) => {
                  let currVal = context.from[0].value.Quantity;
                  let current = currVal as number;
                  if (serviceTypeSelected.Inventory - current < 0) {
                    return false;
                  }
                  return true;
                },
              ),
          );
        }
      }
      if (serviceTypeSelected?.InventoryBatchesEnabled) {
        renewStockSchema.fields.InventoryBatch.withMutation(schema =>
          schema
            .label(t(translations.Batch) as string)
            .nullable()
            .required(t(translations.err_InventoryBatch_required) as string),
        );
      }
    }
    return renewStockSchema;
  }, [minStep, newDate, serviceTypeSelected, t]);

  // const loadServiceTypes = async (
  //   searchTerm: string | null,
  // ): Promise<IOfflineServiceFilterDto[]> => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const data = await loadOfflineServiceType(
  //         undefined,
  //         [],
  //         undefined,
  //         'InventoryBatches',
  //       )(searchTerm, undefined);
  //       resolve(data.value);
  //     } catch (error) {
  //       resolve([]);
  //     }
  //   });
  // };

  return (
    <>
      <Formik
        validationSchema={computedSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        innerRef={innerFormRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle

          formikHelpers.validateForm(values).then(responseErrors => {
            if (!isEmpty(responseErrors)) {
              formikHelpers.setSubmitting(false);
              console.debug('errors', responseErrors);
            } else {
              console.debug('submitting', values);
              formikHelpers.setSubmitting(true);
              handleSubmit(values);
            }
          });
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            console.log('Consumables errors', formik.errors);
          }
          console.log('Consumables submitting', formik.isSubmitting);
          return (
            <React.Fragment>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['ServiceType']}
                />
                <FieldHandler
                  {...fieldHandler}
                  resetFieldState={resetFieldHandler}
                />
                <SubmittingHandler
                  value={hasError === true && submitting === undefined}
                  resetSubmitting={resetSubmitting}
                />
                <FormLeftSection
                // warningNodes={warningMessages}
                // infoNodes={infoMessages}
                // errors={errorMessages}
                // successNodes={successMessages}
                >
                  <FormFieldsSection
                    titleSection={t(translations.RenewStockInfo) as string}
                  >
                    {editCreatable ? (
                      <FormRow>
                        <FormLabelText
                          id="ServiceTypeID"
                          name="ServiceType"
                          label={t(translations.Consumable)}
                          html={
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              {formik.values.ServiceType !== undefined &&
                              formik.values.ServiceType !== null
                                ? formik.values.ServiceType.Name
                                : (t(translations.NA) as string)}
                            </Box>
                          }
                        />
                      </FormRow>
                    ) : (
                      <FormRow fullRow={true}>
                        <FormOfflineServiceTypePicker
                          fullWidth
                          id="ServiceTypeID"
                          name="ServiceType"
                          predicates={RenewStockRelatedFilter()}
                          urlType="base"
                          label={t(translations.Consumable)}
                          placeholder={t(translations.PleaseSelectServices)}
                          disabled={formik.isSubmitting || isEdit}
                          onChange={val => {
                            setServiceChanged(true);
                          }}
                        />
                      </FormRow>
                    )}
                    <FormRow fullRow>
                      <FormNumberField
                        fullWidth
                        id="QuantityID"
                        name="Quantity"
                        inputMode="decimal"
                        placeholder={t(translations.Quantity)}
                        label={t(translations.Quantity)}
                        disabled={formik.isSubmitting}
                        onChange={event => {
                          const precision =
                            formik.values.ServiceType?.IntQuantityOnly === false
                              ? 2
                              : 0;
                          const value = +(+event.target.value).toFixed(
                            precision,
                          );
                          console.log('qyt', value);
                        }}
                        inputProps={inputProps}
                      />
                    </FormRow>
                    <FormRow fullRow>
                      <FormBookitDateTimePicker
                        fullWidth
                        id="DateReceivedID"
                        name="DateReceived"
                        // clear={false}
                        label={t(translations.ReceivedDateTimeLabel) as string}
                        labelInline={true}
                        disabled={formik.isSubmitting}
                      />
                    </FormRow>
                    {!!serviceTypeSelected &&
                      serviceTypeSelected?.InventoryBatchesEnabled && (
                        <React.Fragment>
                          <FormRow>
                            <FormInventoryBatchPicker
                              name="InventoryBatch"
                              id="batchId"
                              fullWidth
                              label={t(translations.Batch)}
                              disabled={formik.isSubmitting}
                              onChange={val => {
                                setBatchChanged(true);
                              }}
                              predicates={BatchesByServiceTypeFilter(
                                serviceTypeSelected.Id,
                              )}
                            />
                          </FormRow>
                          {formik.values.ServiceType !== null &&
                            formik.values.ServiceType !== undefined && (
                              <React.Fragment>
                                <Button
                                  variant="text"
                                  size="small"
                                  onClick={e =>
                                    onBatchClick(
                                      null,
                                      formik.values.ServiceType?.Id ?? 0,
                                    )
                                  }
                                >
                                  {t(translations.AddNewBatch)}
                                </Button>
                                {formik.values.InventoryBatch !== null &&
                                  formik.values.InventoryBatch !==
                                    undefined && (
                                    <Button
                                      variant="text"
                                      size="small"
                                      onClick={e =>
                                        onBatchClick(
                                          formik.values.InventoryBatch ?? null,
                                          formik.values.ServiceType?.Id ?? 0,
                                        )
                                      }
                                    >
                                      {t(translations.EditSelectedBatch)}
                                    </Button>
                                  )}
                              </React.Fragment>
                            )}
                        </React.Fragment>
                      )}

                    <FormRow fullRow={true}>
                      <FormRichTextField
                        name="Remarks"
                        placeholder={t(translations.Remarks)}
                        disabled={formik.isSubmitting}
                        fullWidth={true}
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormLeftSection>
              </StyledForm>
            </React.Fragment>
          );
        }}
      </Formik>
    </>
  );
});
