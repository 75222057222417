import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import {
  SampleTypePicker,
  SampleTypePickerProps,
} from '../pickers/AutocompletePickers/SampleTypePicker.index';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';

interface FormOfflineServicePickerIntrinsicProps
  extends Omit<
    SampleTypePickerProps,
    'onBlur' | 'value' | 'error' | 'onChange'
  > {
  serviceGroupId?: number;
}
export const FormSampleTypePicker = ({
  onChange,
  onBlur,
  ...props
}: FieldHookConfig<Entity<number> | null> &
  FormOfflineServicePickerIntrinsicProps) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <SampleTypePicker
        onChange={value => helpers.setValue(value, true)}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        queryfilter={
          props.serviceGroupId
            ? new Condition(
                'ServiceGroupId',
                ODataOperators.Equals,
                props.serviceGroupId,
              ).toString()
            : undefined
        }
        {...props}
      />
    </>
  );
};
