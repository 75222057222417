import { Grid } from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { Box } from 'app/components/basic/Wrappers/Box';
import { selectglobalSettings } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectInvoice } from '../Slice/selector';
import { getRows } from './Rows';
import { useStyles } from './styles';

export interface InvoiceReadOnlyProps {
  id: number;
  openChangeStatus?: () => void;
  openChangeInvoiceTemplate?: () => void;
}
export const ReadOnlyDetails = (props: InvoiceReadOnlyProps) => {
  const { id, openChangeStatus, openChangeInvoiceTemplate } = props;
  const { t } = useTranslation();
  const invoice = useSelector(selectInvoice);
  const settings = useSelector(selectglobalSettings);
  const rows = useMemo(
    () => getRows(t, settings, openChangeStatus, openChangeInvoiceTemplate),
    [openChangeStatus, openChangeInvoiceTemplate, settings, t],
  );
  const classes = useStyles();

  if (!invoice) {
    return <>{t(translations.InvoiceNotFound).replace('{0}', id.toString())}</>;
  }

  return (
    <Box component="div" className={classes.base}>
      <Grid
        container
        direction="row"
        justify="space-between"
        spacing={2}
        wrap="wrap"
      >
        {rows.map(r => {
          const cell = r.Cell ?? (({ value }) => <>{value}</>);
          const val = cell({ original: invoice, value: invoice[r.accessor] });
          if (val) {
            const header =
              typeof r.Header === 'string'
                ? () => (
                    <Body size="small" bold={true}>
                      {r.Header}
                    </Body>
                  )
                : r.Header;
            return (
              <Grid item key={r.accessor} xs={12} sm={4}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  wrap="wrap"
                >
                  {header && (
                    <Grid item xs={6}>
                      {header({
                        original: invoice,
                        value: invoice[r.accessor],
                      })}
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    {val}
                  </Grid>
                </Grid>
              </Grid>
            );
          }
          return undefined;
        })}
      </Grid>
    </Box>
  );
};
