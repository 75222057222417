/**
 *
 * FormOnlineServicesComboPicker - FormOnlineServicesSinglePicker
 *
 */
import { FieldHookConfig, useField } from 'formik';
import {
  OnlineServiceMultiPicker,
  OnlineServicePickerProps,
  OnlineServiceSinglePicker,
} from '../pickers/MultiSelectPickers/OnlineServicesPicker';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';

export const FormOnlineServicesComboPicker = ({
  label,
  ...props
}: FieldHookConfig<IServiceFilterDto[] | null> &
  IFieldProps & { multiple: boolean } & OnlineServicePickerProps) => {
  const [field, meta, helpers] = useField<IServiceFilterDto[] | null>(props);
  if (props.multiple) {
    return (
      <OnlineServiceMultiPicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        predicates={props.predicates}
        disabled={props.disabled}
        onChipClick={props.onChipClick}
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onBlur={field?.onBlur}
        value={field.value ?? undefined}
        variant="filled"
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        info={props.info}
        urlType={props.urlType}
      />
    );
  } else {
    return (
      <OnlineServiceSinglePicker
        predicates={props.predicates}
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onBlur={field?.onBlur}
        value={field.value ?? undefined}
        error={meta?.error !== undefined}
        variant="filled"
        disabled={props.disabled}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        urlType={props.urlType}
        info={props.info}
      />
    );
  }
};
export const FormOnlineServicesSinglePicker = ({
  label,
  ...props
}: FieldHookConfig<IServiceFilterDto | null> &
  IFieldProps &
  OnlineServicePickerProps) => {
  const [field, meta, helpers] = useField<IServiceFilterDto | null>(props);

  return (
    <OnlineServiceSinglePicker
      predicates={props.predicates}
      name={props.name}
      placeholder={props.placeholder}
      label={label}
      onChange={value => {
        helpers.setValue(value[0], true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      onBlur={field?.onBlur}
      value={field.value === null ? undefined : [field.value]}
      error={meta?.error !== undefined}
      variant="filled"
      disabled={props.disabled}
      helperText={meta.error}
      urlType={props.urlType}
      fullWidth={props.fullWidth}
      info={props.info}
    />
  );
};
