/**
 *
 * Asynchronously loads the component for OtherService Page
 *
 */
import { lazyLoad } from 'utils/loadable';

export const OtherServicesPage = lazyLoad(
  () => import('./index'),
  module => module.OtherServicesPage,
);
