import { H3 } from 'app/components/Typography';
import { H4 } from 'app/components/Typography';
import { Box } from 'app/components/basic/Wrappers/Box';
import { FlexRowDiv } from 'app/components/basic/Wrappers/FlexWrappers/flexRow';

export interface CustomEmptyTableProps {
  id?: string;
  headTitle?: string;
  title?: string;
}

export function CustomEmptyTable(props: CustomEmptyTableProps) {
  const { headTitle, title } = props;
  return (
    <FlexRowDiv>
      <Box textAlign="center" style={{ marginTop: '5%' }}>
        {headTitle && <H3>{headTitle}</H3>}
        {title && <H4>{title}</H4>}
      </Box>
    </FlexRowDiv>
  );
}
