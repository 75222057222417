/**
 *
 * Asynchronously loads the component for Users Page
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Users = lazyLoad(
  () => import('./index'),
  module => module.UsersPage,
);
