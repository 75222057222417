import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/RootState';
import { initialState } from '.';

const selectSlice = (state: RootState) => {
  return state.layout || initialState;
};
export const selectSidePanelState = createSelector(
  [selectSlice],
  state => state.sidePanelState,
);
export const selectSidePanelOpen = createSelector(
  [selectSlice],
  state => state.sidePanelState.sidePanelOpen,
);
export const selectPageType = createSelector(
  [selectSlice],
  state => state.sidePanelState.pageType,
);
export const selectExpandedSidePanel = createSelector(
  [selectSlice],
  state => state.sidePanelState.expanded,
);
export const selectPageTypeProps = createSelector(
  [selectSlice],
  state => state.sidePanelState.pageProps,
);
export const selectConfirmState = createSelector(
  [selectSlice],
  state => state.confirmState,
);
export const selectConfirmOpen = createSelector(
  [selectSlice],
  state => state.confirmState.confirmOpen,
);
export const selectConfirmApproved = createSelector(
  [selectSlice],
  state => state.confirmState.approved,
);
export const selectConfirmRejected = createSelector(
  [selectSlice],
  state => state.confirmState.rejected,
);
export const selectHasNotSavedChanges = createSelector(
  [selectSlice],
  state => state.hasAnyNotSavedChanges,
);
export const selectDoRefreshTable = createSelector(
  [selectSlice],
  state => state.doRefreshTable,
);
export const selectShowLocalConfirm = createSelector(
  [selectSlice],
  state => state.confirmState.showLocalConfirm,
);
export const selectContinueOnApprove = createSelector(
  [selectSlice],
  state => state.continueState.continueOnApprove,
);
export const selectContinueState = createSelector(
  [selectSlice],
  state => state.continueState,
);
export const selectContinueToLink = createSelector(
  [selectSlice],
  state => state.confirmState.continueToLink,
);
export const selectHideNavigation = createSelector(
  [selectSlice],
  state => state.hideNavigation,
);
export const selectSnackBarIndex = createSelector(
  [selectSlice],
  state => state.upsnackbarIndex,
);
export const selectIsMobileView = createSelector(
  [selectSlice],
  state => state.isMobileView,
);
export const selectActionTableProcessing = createSelector(
  [selectSlice],
  state => state.actionTableProcessing,
);
export const selectProcessingIndicator = createSelector(
  [selectActionTableProcessing],
  processing => (methodName: string) => {
    if (processing === undefined) {
      return undefined;
    } else if (methodName === processing.name) {
      return processing.processing;
    } else {
      return undefined;
    }
  },
);
export const selectIsCoverOpen = createSelector(
  [selectSidePanelState],
  state => state.coverOpen,
);
export const selectIsCoverExpanded = createSelector(
  [selectSidePanelState],
  state => state.coverExpanded,
);
