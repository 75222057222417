import { IModificationsDto } from 'api/odata/generated/entities/IModificationsDto';
import { filterHandler } from 'app/components/BasicTable/UnconfigurableTable/FilterHandler';
import { UnconfigurableTable } from 'app/components/BasicTable/UnconfigurableTable/UnconfigurableTable';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { translations } from 'locales/translations';
import React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getColumns } from './Columns';
import { GetFilters } from './Filter';

export interface ReservationModificationsProps {
  resId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
}
export const ReservationModifications = ({
  resId,
  open,
  setOpen,
}: ReservationModificationsProps) => {
  const { t } = useTranslation();
  const columns = useMemo(() => getColumns(t), [t]);
  const url = `/api/odata/v4/ModificationHistory/Reservations`;
  return (
    <DialogConfirm
      isOpen={open}
      confirmButtonLabel={t(translations.Done)}
      body={
        <React.Fragment>
          <TTable
            api={{ path: url, search: { key: resId } }}
            screenName={t(translations.ModificationsHistory)}
            columns={columns}
            serviceGroups={[]}
            additionalColumns={['UserName', 'Id']}
            searchColumns={[
              'cast(Id,Edm.String)',
              'Field',
              'OldValue',
              'UserName',
              'NewValue',
              'UserDisplayName',
            ]}
          />
        </React.Fragment>
      }
      onCancel={() => setOpen(false)}
      maxWidth="md"
    />
  );
};

const TTable = filterHandler<IModificationsDto, {}>(
  UnconfigurableTable,
  GetFilters,
  {
    isSidePanel: true,
  },
);
