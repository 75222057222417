import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { Link } from 'app/components/ExternalLink';
import { IAsset } from 'app/pages/AssetPopUp/IAsset';
import { useAdminPermissions } from 'app/permissions/Asset/assetPermissions';
import { ServiceType } from 'enums/ServiceTypes';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { CellProps, Renderer } from 'react-table';
import { toRootedURL } from 'utils/url-utils';

const ServiceIdRenderer: Renderer<CellProps<IAsset>> = ({ value, row }) => {
  const { allowedToEdit } = useAdminPermissions();
  const { t } = useTranslation();
  const hrefPath = React.useMemo(() => {
    if (row.original.ServiceTypeId === (ServiceType.Offline as number)) {
      return {
        path: '/offlineServicetype.aspx',
        search: { ID: row.original.ServiceId, back: 'myassets' },
      };
    } else if (row.original.ServiceTypeId === (ServiceType.Sample as number)) {
      return {
        path: '/SampleType.aspx',
        search: { id: row.original.ServiceId, back: 'myassets' },
      };
    } else {
      return {
        path: '/NewResource.aspx',
        search: { Edit: row.original.ServiceId, back: 'myassets' },
      };
    }
  }, [row.original.ServiceId, row.original.ServiceTypeId]);
  return allowedToEdit(row.original) ? (
    <Tooltip title={t(translations.EditSettings) as string} arrow>
      <span>
        <Link href={hrefPath} target={'_blank'}>
          {value}
        </Link>
      </span>
    </Tooltip>
  ) : (
    // <Button
    //   variant="text"
    //   size="small"
    //   title={t(translations.EditSettings) as string}
    //   href={`/NewResource.aspx?Edit=${row.original.ServiceId}&back=myassets`}
    // >
    //   {value}
    // </Button>
    <>{value}</>
  );
};
export const useServiceIdAction = () => {
  const { allowedToEdit } = useAdminPermissions();
  return React.useCallback(
    row => {
      if (!allowedToEdit(row.original)) return;
      const hrefPath = (() => {
        if (row.original.ServiceTypeId === (ServiceType.Offline as number)) {
          return {
            path: '/offlineServicetype.aspx',
            search: { ID: row.original.ServiceId, back: 'myassets' },
          };
        } else if (
          row.original.ServiceTypeId === (ServiceType.Sample as number)
        ) {
          return {
            path: '/SampleType.aspx',
            search: { id: row.original.ServiceId, back: 'myassets' },
          };
        } else {
          return {
            path: '/NewResource.aspx',
            search: { Edit: row.original.ServiceId, back: 'myassets' },
          };
        }
      })();
      window.location.href = toRootedURL(hrefPath.path, hrefPath.search);
    },
    [allowedToEdit],
  );
};
export default ServiceIdRenderer;
