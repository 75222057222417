import {
  BaseURLPicker,
  BaseURLPickerProps,
} from 'app/components/BasicPickers/BaseURLPicker';
import { Entity } from 'types/common';

const url = '/api/odata/v4/InvoiceStatus';
export interface InvoiceStatusPickerProps
  extends Omit<BaseURLPickerProps<Entity<number>>, 'url'> {}

export const InvoiceStatusPicker = (props: InvoiceStatusPickerProps) => {
  return (
    <BaseURLPicker url={url} {...props} id={props.id || 'invoiceStatusId'} />
  );
};
