import { Checkbox, CheckboxProps } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';

export type CheckColor = 'primary';

export interface CheckProps extends Omit<CheckboxProps, 'color'> {
  color?: CheckColor;
  checkicon?: React.ReactNode;
  uncheckicon?: React.ReactNode;
  indetermicon?: React.ReactNode;
  withPadding?: boolean;
  danger?: boolean;
  label?: string;
}

export function Check(props: CheckProps) {
  const {
    color,
    checkicon,
    uncheckicon,
    indetermicon,
    label,
    withPadding = true,
    danger,
    size = 'medium',
    ...other
  } = props;
  const classes = useStyles({
    size: size,
    color: color,
    withPadding: withPadding,
    danger: danger,
  });
  return (
    <Checkbox
      disableRipple
      classes={{ root: classes.root }}
      checkedIcon={
        !!checkicon ? (
          checkicon
        ) : (
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        )
      }
      icon={!!uncheckicon ? uncheckicon : <span className={classes.icon} />}
      indeterminateIcon={
        !!indetermicon ? (
          indetermicon
        ) : (
          <span className={clsx(classes.icon, classes.indeterminateIcon)} />
        )
      }
      inputProps={{ 'aria-label': !!label ? label : 'decorative checkbox' }}
      size={size}
      color={'default'}
      {...other}
    />
  );
}
