import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { ExternalLink } from 'app/components/ExternalLink';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { selectGlobalDbSetting_EnableLegacyScheduler } from 'app/slice/globalDbSettingsSelectors';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IPathAndQuery } from 'utils/url-utils';
import { ReservationDetailsProps } from '../../pages/ReservationDetails/Details';
import { ReservationQueryStringParameters } from '../../pages/ReservationDetails/Details/slice/types';
import ButtonLink, { ButtonLinkProps } from '../BasicLinks/ButtonLink';

export interface ReservationEditButtonProps extends ButtonProps {
  reservationId: number;
}

export function ReservationEditButton({
  variant = 'white',
  size = 'small',
  onClick,
  startIcon,
  reservationId,
  ...props
}: ReservationEditButtonProps) {
  props.children = props.children ?? reservationId;
  const p = useReservationEditBehavior({
    onClick: onClick,
  });
  return (
    <Button
      variant={variant}
      size={size}
      startIcon={startIcon}
      {...p(reservationId)}
      {...props}
    />
  );
}
export interface ReservationEditButtonLinkProps extends ButtonLinkProps {
  reservationId: number;
}
export function ReservationEditButtonLink({
  onClick,
  reservationId,
  ...props
}: ReservationEditButtonLinkProps) {
  props.children = props.children ?? reservationId;
  const p = useReservationEditBehavior({
    onClick: onClick,
  });
  return <ButtonLink onClick={p(reservationId).onClick} {...props} />;
}

export interface ReservationEditBehaviorProps<TElement = HTMLElement> {
  onClick?: React.MouseEventHandler<TElement>;
}
export function useReservationEditBehavior<TElement = HTMLElement>(
  props: ReservationEditBehaviorProps<TElement>,
) {
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const User = useSelector(selectAuthenticatedUser);
  const EnableLegacyScheduler = useSelector(
    selectGlobalDbSetting_EnableLegacyScheduler,
  );
  return (reservationId: number) => {
    const params = {
      id: String(reservationId),
      un: User?.Id,
    } as ReservationQueryStringParameters;

    const handleClick = e => {
      if (!EnableLegacyScheduler) {
        dispatch(
          actions.openSidePanel({
            type: RenderPageType.ReservationDetails,
            props: {
              useSidePanel: true,
              queryParams: params,
            } as ReservationDetailsProps,
          }),
        );
      }
      props.onClick?.(e);
    };
    const legacyURL: IPathAndQuery = {
      path: '/Reservation.aspx',
      search: { Edit: reservationId },
    };
    const p = EnableLegacyScheduler
      ? {
          component: ExternalLink,
          href: legacyURL,
        }
      : {
          onClick: handleClick,
        };
    return p;
  };
}
