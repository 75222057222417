import * as ReactDOMServer from 'react-dom/server';
import * as DHTMLX from 'dhtmlx-scheduler';
import { getLogger } from 'utils/logLevel';
import { ISchedulerEvent } from '../../types/ISchedulerEvent';
import { SchedulerEventTooltipProps } from './SchedulerEventTooltipProps';
import { TrainingSessionTooltip } from './TrainingSessionTooltip';
import { assertExhaustive } from 'utils/assertExhaustive';
import { OfflineTooltip } from './OfflineTooltip';
import { EventType } from '../../slice/types';
import { ReservationTooltip } from './ReservationTooltip';
import React from 'react';

// tooltip template funciton signature
type ToolTipTextFn = (start: Date, end: Date, e: any) => string | boolean;

/**
 * Customizing event tooltip template - https://docs.dhtmlx.com/scheduler/api__scheduler_tooltip_text_template.html
 * @param scheduler scheduler instance
 * @returns HTML generating function
 */
export function tooltip_text(
  scheduler: DHTMLX.SchedulerStatic,
  reservationTooltipTemplate,
): ToolTipTextFn {
  const log = getLogger('tooltip_text');
  try {
    return tooltip_text_fn(scheduler, reservationTooltipTemplate);
  } catch (error) {
    log.error('Error compiling tooltip template', error);
    return () => '';
  }
}

/**
 * This function is called by the scheduler to get the tooltip
 * @returns tooltip text thmls
 */
function tooltip_text_fn(
  scheduler: DHTMLX.SchedulerStatic,
  reservationTooltipTemplate: HandlebarsTemplateDelegate,
): ToolTipTextFn {
  return (start: Date, end: Date, event: ISchedulerEvent) => {
    const HIDE_TOOLTIP = false;
    const schedulerEvent: ISchedulerEvent = scheduler.getEvent(event.id);
    try {
      const type = schedulerEvent.type;
      if (type === undefined) {
        return HIDE_TOOLTIP;
      }
      const TooltipElement = SchedulerEventTooltip(
        type,
        reservationTooltipTemplate,
      );
      if (TooltipElement === undefined) {
        return HIDE_TOOLTIP;
      }
      const tooltipElementProps = GetSchedulerEventTooltipProps(
        start,
        end,
        event,
        reservationTooltipTemplate,
      );
      if (tooltipElementProps === undefined) {
        return HIDE_TOOLTIP;
      }
      const html = ReactDOMServer.renderToStaticMarkup(
        React.createElement(TooltipElement, tooltipElementProps),
      );
      return html;
    } catch (error) {
      return HIDE_TOOLTIP;
    }
  };
}

function GetSchedulerEventTooltipProps(
  start: Date,
  end: Date,
  event: ISchedulerEvent,
  reservationTooltipTemplate: HandlebarsTemplateDelegate,
): SchedulerEventTooltipProps | undefined {
  if (event.type === undefined) {
    return undefined;
  }
  switch (event.type) {
    case 'offline':
    case 'trainingsession':
      return { start, end, event: event };

    case 'reservation':
      return {
        start,
        end,
        event: event,
        reservationTooltipTemplate: reservationTooltipTemplate,
      };
    default:
      assertExhaustive(event.type);
  }
}

function SchedulerEventTooltip(
  type: EventType,
  reservationTooltipTemplate?: HandlebarsTemplateDelegate,
): React.FC<SchedulerEventTooltipProps> | undefined {
  switch (type) {
    case 'offline':
      return OfflineTooltip;
    case 'trainingsession':
      return TrainingSessionTooltip;
    case 'reservation':
      if (reservationTooltipTemplate === undefined) {
        return undefined;
      }
      return ReservationTooltip;
    default:
      assertExhaustive(type);
  }
}
