import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { IInvoice } from '../ReadOnly/IInvoice';
import { InvoiceDetailsSaga } from './saga';
import { InvoiceDetailsState } from './type';

export const initialState: InvoiceDetailsState = {
  loading: false,
  notFound: false,
  invoice: null,
};

const slice = createSlice({
  name: 'InvoiceDetails',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    notFound(state, action: PayloadAction) {
      state.notFound = true;
    },
    setFilters(state, action: PayloadAction<Record<string, any> | undefined>) {
      state.filters = action.payload;
    },
    setInvoice(state, action: PayloadAction<IInvoice>) {
      state.invoice = action.payload;
    },
    getInvoice(state, action: PayloadAction<number>) {},
    reset(state, action: PayloadAction) {
      state.filters = undefined;
      state.notFound = false;
      state.loading = false;
      state.invoice = null;
    },
  },
});

export const { actions: InvoiceDetailActions } = slice;

export const useInvoiceDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: InvoiceDetailsSaga });
  return { actions: slice.actions };
};
