import * as React from 'react';
import { Button } from 'app/components/BasicButtons/Button';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOnlineBudgetsStatusSlice } from '../slice';
import BasicTypography from 'app/components/Typography/BasicTypography';
import {
  selectChargeOnlineBudgetsState,
  selectOnlineBudgetsStatusSelected,
} from '../slice/selectors';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
} from '@material-ui/core';
import { ButtonConfirm } from 'app/components/ButtonConfirm';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
const maxErrorsToShow = 5;
export interface ChargeOnlineBudgetsButtonProps {
  disabled?: boolean;
  disabledTitle?: string;
}
export const ChargeOnlineBudgetsButton = ({
  disabled,
  disabledTitle,
  ...props
}: ChargeOnlineBudgetsButtonProps) => {
  const { t } = useTranslation();
  const selected = useSelector(selectOnlineBudgetsStatusSelected);
  const state = useSelector(selectChargeOnlineBudgetsState);
  const dispatch = useDispatch();
  const { actions } = useOnlineBudgetsStatusSlice();

  const handleChargeOnlineBudgetsButtonClick = () => {
    dispatch(actions.chargeOnlineBudgets(selected));
  };
  const handleDialogClose = () => {
    if (state.processing) {
      return;
    }
    dispatch(actions.chargeOnlineBudgets_SetDialogOpen(false));
  };
  const confirmationMessage = `${
    selected.length
  } records will be processed. ${t(translations.AreYouSure)}`;
  return (
    <>
      <Tooltip title={disabledTitle}>
        <span>
          <ButtonConfirm
            disabled={disabled === true || selected.length === 0}
            onClick={handleChargeOnlineBudgetsButtonClick}
            confirmationDialogTitle={t(translations.SendBalanceToSap)}
            confirmationDialogBody={confirmationMessage}
            processing={state.processing}
          >
            {t(translations.ChargeBalanceOnline)}
            {selected.length > 0 && <> - {selected.length}</>}
          </ButtonConfirm>
        </span>
      </Tooltip>
      <Dialog open={state.dialogOpen ?? false} onClose={handleDialogClose}>
        {state.processing && (
          <LinearProgress variant="determinate" value={state.progress} />
        )}
        <DialogTitle>{t(translations.ChargeBalanceOnline)}</DialogTitle>
        <DialogContent>
          {state.currentRecord !== undefined && (
            <BasicTypography>{`Processing ${state.currentRecord?.ServiceGroup} ${state.currentRecord?.Budget}`}</BasicTypography>
          )}
          <>
            {state.errors !== undefined && (
              <List>
                {state?.errors
                  ?.slice(0, maxErrorsToShow)
                  ?.map((message, index) => (
                    <ListItem key={`msg-${index}`}>{message}</ListItem>
                  ))}
              </List>
            )}
            {(state?.errors?.length ?? 0) > maxErrorsToShow && (
              <Button
                onClick={() =>
                  dispatch(
                    actions.chargeOnlineBudgets_DownloadErrors(state.errors),
                  )
                }
              >
                {t(translations.DownloadErrors)}
              </Button>
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button
            type="reset"
            onClick={handleDialogClose}
            disabled={state.processing}
            processing={state.processing}
          >
            {t(translations.Close)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
