/**
 *
 * BasicDialogActions
 *
 */
import {
  Box,
  createStyles,
  DialogActions as MuiDialogActions,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { DialogActionsRenderer } from './DialogActionsRender';

const actionsStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      margin: 0,
      padding: theme.spacing(3, 4),
      boxShadow: 'inset 0px 1px 0px #E0E5EC',
    },
    leftActions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 0,
      flexWrap: 'wrap',
      gap: 8,
    },
    rightActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px 16px',
      flexWrap: 'wrap',
      gap: 8,
    },
  }),
);
export interface BasicDialogActionsProps {
  leftActions: DialogActionsRenderer[];
  rightActions: DialogActionsRenderer[];
}

export function BasicDialogActions(props: BasicDialogActionsProps) {
  const { leftActions, rightActions } = props;
  const classes = actionsStyles();
  return (
    <MuiDialogActions className={classes.root}>
      {leftActions.length > 0 && (
        <Box className={classes.leftActions}>
          {leftActions.map((item, index) => (
            <span key={index}>{item()}</span>
          ))}
        </Box>
      )}
      <Box className={classes.rightActions}>
        {rightActions.length > 0 &&
          rightActions.map((item, index) => <span key={index}>{item()}</span>)}
      </Box>
    </MuiDialogActions>
  );
}
