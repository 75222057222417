/**
 *
 * Asynchronously loads the component for UsersHistory
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UsersHistoryPage = lazyLoad(
  () => import('./index'),
  module => module.UsersHistory,
);
