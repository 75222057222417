import * as ReactDOMServer from 'react-dom/server';
import { SimplePaletteColorOptions } from '@material-ui/core/styles/createPalette';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { BookitThemeSettings } from 'styles/theme';
import { isMobile } from 'react-device-detect';
import {
  ISchedulerSection,
  ISchedulerServiceSection,
} from './types/ISchedulerSection';
import { assertExhaustive } from 'utils/assertExhaustive';

/**
 * Renders static html for service column/header
 * @param params
 * @returns
 */
export function scheduleServiceTemplate(params: ISchedulerSection) {
  const type = params.type;

  switch (type) {
    case 'service':
      const component = TimelineServiceContent(params);
      const html = ReactDOMServer.renderToStaticMarkup(component);
      const foo = html.replace(
        '<img',
        '<img onError="this.style.opacity=\'0\';this.onError=null;" ',
      );
      return foo;
    case 'assetCat':
      return params.label;
    case 'empty':
      return '';
    default:
      assertExhaustive(type);
  }
}

export const scheduleServiceContainerClassName = 'schedule_service_label';
export const scheduleServicePadding = 'schedule_service_label_padding';
export const scheduleServicePaddingMobile =
  'schedule_service_label_mobile_padding';
export const scheduleImageClassName = 'schedule_service_image';
export const scheduleServiceIdAttributeName = 'data-equipment-id';
export const scheduleServiceLabelText = 'schedule_service_label_text';
export const scheduleServiceLabelTextMobile =
  'schedule_service_label_text_mobile';
/**
 * Used to render service content on timeline column & unit header
 * @param props props containing the service details
 * @returns scheduler service label content
 */
export function TimelineServiceContent(props: ISchedulerServiceSection) {
  const mobileView = isMobile || window.innerWidth < 600;
  try {
    if (props.data === undefined) {
      return <></>;
    }
    if (props.key === undefined) {
      return <></>;
    }
    const backgroundColor =
      props.data.Color ??
      (BookitThemeSettings.palette?.primary as SimplePaletteColorOptions)?.main;

    const style: React.CSSProperties = {
      backgroundColor: backgroundColor,
    };
    const containerProps = {
      className: `${scheduleServiceContainerClassName} ${
        mobileView ? scheduleServicePaddingMobile : scheduleServicePadding
      }`,
    };
    containerProps[scheduleServiceIdAttributeName] = props.data?.Id;
    const imageProps = {
      className: scheduleImageClassName,
    };
    imageProps[scheduleServiceIdAttributeName] = props.data?.Id;
    const labelTextProps = {
      className: mobileView
        ? scheduleServiceLabelTextMobile
        : scheduleServiceLabelText,
    };

    /**
     * imageName is populated with the ImagePath in the following file
     * @see src\app\pages\CalendarPage\Scheduler\index.tsx
     * @todo use the raw ImageName on IServiceFilterDto object and calculated required image path here
     */
    // Asset image is set in
    const src = props.data.ImageName ?? '';
    return (
      <>
        <span className="schedule_service_container">
          {!isMobile && (
            <span {...imageProps}>
              {src === undefined || src.indexOf('Default') > 0 ? (
                <Icon icon="microscope" />
              ) : (
                <img src={src} alt="" />
              )}
            </span>
          )}

          <div {...containerProps} style={style} title={props.label}>
            <span {...labelTextProps}>{props.label}</span>
          </div>
        </span>
      </>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
}
