import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { publicAssetSaga } from './saga';
import { BrowseByUnitItemDto, PublicAssetState } from './types';

export const initialState: PublicAssetState = {
  processing: false,
  error: undefined,
  data: undefined,
  selectedData: [],
  dataSearch: undefined,
  filters: undefined,
  menuOpen: undefined,
  globalSearchOpen: undefined,
};

const slice = createSlice({
  name: 'publicAsset',
  initialState,
  reducers: {
    setSelectedItem(
      state,
      action: PayloadAction<{
        item: BrowseByUnitItemDto | undefined;
        search: string;
        init?: boolean;
      }>,
    ) {
      state.processing = true;
      state.error = undefined;
    },
    setSelectedItem_Success(
      state,
      action: PayloadAction<{
        item: BrowseByUnitItemDto | undefined;
        search: string;
        init?: boolean;
      }>,
    ) {
      state.processing = false;
      state.error = undefined;
      state.data = action.payload.item;
      if (!action.payload.init) {
        state.dataSearch = action.payload.search;
      }
    },
    setSelectedItems(
      state,
      action: PayloadAction<{
        items: BrowseByUnitItemDto[];
        search: string;
        init?: boolean;
      }>,
    ) {
      state.processing = true;
      state.error = undefined;
    },
    setSelectedItems_Success(
      state,
      action: PayloadAction<{
        items: BrowseByUnitItemDto[];
        search: string;
        init?: boolean;
      }>,
    ) {
      state.processing = false;
      state.error = undefined;
      state.selectedData = action.payload.items;
      if (!action.payload.init) {
        state.dataSearch = action.payload.search;
      }
    },
    setAssetPublicFilters(
      state,
      action: PayloadAction<Record<string, any> | undefined>,
    ) {},
    setAssetPublicFilters_success(
      state,
      action: PayloadAction<Record<string, any> | undefined>,
    ) {
      state.filters = action.payload;
    },
    setMenuOpen(state, action: PayloadAction<boolean | undefined>) {
      state.menuOpen = action.payload;
    },
    toggleMenuOpen(state, action: PayloadAction) {
      state.menuOpen = !state.menuOpen;
    },
    setGlobalSearchOpen(state, action: PayloadAction<boolean | undefined>) {
      state.globalSearchOpen = action.payload;
    },
    toggleGlobalSearchOpen(state, action: PayloadAction) {
      state.globalSearchOpen = !state.globalSearchOpen;
    },
  },
});

export const { actions: publicAssetActions } = slice;

export const usePublicAssetSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: publicAssetSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = usePublicAssetSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
