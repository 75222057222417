/**
 *
 * AppliedFiltersContainer
 *
 */
import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Directions } from '../FilterBox';
import { useIsMobile } from 'app/hooks/useIsMobile';
import clsx from 'clsx';

export interface AppliedFiltersContainerProps {
  direction: Directions;
}

export function AppliedFiltersContainer({
  children,
  ...props
}: React.PropsWithChildren<AppliedFiltersContainerProps>) {
  const classes = useStyles();
  const { checkIsMobile, isLandscape } = useIsMobile();
  const isMobile = checkIsMobile();
  return (
    <div
      className={clsx(classes.root, {
        [classes.horizontal]: props.direction === 'horizontal',
        [classes.mobileRoot]: isMobile && !isLandscape,
        [classes.mobileHorizontalRoot]: isMobile && isLandscape,
      })}
    >
      {children}
    </div>
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      // maxHeight was taken to contain 3 AppliedFilterComponents vertically
      maxHeight: theme.spacing(54),
      height: 'auto',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0,
      gap: 12,
      width: '100%',
      '&.horizontal': {
        maxHeight: theme.spacing(21),
      },
      '&.mobileRoot': {
        maxHeight: '100%',
      },
      '&.mobileHorizontalRoot': {
        maxHeight: '100%',
      },
    },
    mobileRoot: {
      height: 'calc(100% - 48px)',
    },
    mobileHorizontalRoot: {
      height: 'calc(100% - 48px)',
    },
  }),
);
