import * as React from 'react';
import { getIn } from 'formik';
import { useContext, createContext, ReactNode } from 'react';

interface IRequiredFields {
  requiredFields: Record<string, boolean> | undefined;
}
const RequiredFieldsContext: React.Context<IRequiredFields> = createContext<
  IRequiredFields
>({
  requiredFields: undefined,
});
/**
 * Usage example:
 *      <Formik
 *        validationSchema={schema}
 *        ...>
 *            <ValidationSchemaProvider schema={schema}>
 *                Form fields/form content
 *            </ValidationSchemaProvider>
 *        </Formik>
 * @param props Yup schema + children
 * @returns Validation schema provider
 */
export const ValidationSchemaProvider = (props: {
  schema: any;
  children: ReactNode;
  skipValidation?: boolean;
}) => {
  const { schema, children, skipValidation } = props;

  const requiredFields = {} as Record<string, boolean>;
  const fields = schema.describe().fields;
  for (const key in fields) {
    if (fields.hasOwnProperty(key)) {
      const fieldValidationSchema = fields ? getIn(fields, key) : false;
      const tests =
        fieldValidationSchema && !skipValidation
          ? fieldValidationSchema.tests
          : false;
      requiredFields[key] = tests
        ? !!tests.find(test => test.name === 'required')
        : false;
    }
  }

  return (
    <RequiredFieldsContext.Provider value={{ requiredFields }}>
      {children}
    </RequiredFieldsContext.Provider>
  );
};
/**
 * Provides access to required setting of a field in Formik+Yup form
 * Usage example:
 *  const isRequired = useIsRequired('email');
 *  <FormControl required={isRequried} ...
 * @param name form element name
 * @returns true if field is required
 */
export const useIsRequired = (name: string) => {
  const { requiredFields } = useContext(RequiredFieldsContext);
  return requiredFields?.[name];
};
export const useRequiredFields = () => {
  const { requiredFields } = useContext(RequiredFieldsContext);

  return {
    requiredFields,
  };
};
