import * as React from 'react';
import { LinearProgress } from '@material-ui/core';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { TitlePage } from './TitlePage';
import { Skeleton } from '@material-ui/lab';
import { ReactActions } from 'app/components/ReactActions';
import { ActionRenderer } from '../PageActions/ActionRender';
import {
  SwitchActions,
  SwitchActionsProps,
} from '../PageActions/SwitchActions';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core';
import clsx from 'clsx';

export interface ShortHeadProps {
  closable: boolean | undefined;
  useSidePanel: boolean;
  closePanel: () => void;
  pageName: React.ReactNode;
  titlePage: string | undefined;
  pageLink: string | undefined;
  titleTooltip: string | undefined;
  isMobile: boolean;
  disableExpandToggle: boolean | undefined;
  expandPanel: () => void;
  subTitlePage?: React.ReactNode;
  leftTopActions?: ActionRenderer[];
  rightTopActions?: ActionRenderer[];
  rightTopActionsAsMenu?: boolean;
  switchState?: SwitchActionsProps;
  useSwithActions?: boolean;
  onSubmit?: () => void;
  showProcessing?: boolean;
  loading: boolean;
  isCover?: boolean;
}
const ShortTitleRoot = styled('div')(({ theme }) => ({
  '&.page-wrapper-short-title-root': {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    gap: theme.spacing(1.25),
    '.shortTitleContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(2, 3, 1.5),
      alignItems: 'center',
      width: '100%',
      gap: theme.spacing(0.5),
      '.iconAndTitle': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        width: '100%',
        gap: theme.spacing(1.5),
      },
      '.shortRightIcons': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: 0,
        gap: '12px',
      },
    },
    '.shortSwithContainer': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
      width: '100%',
      gap: 10,
      padding: theme.spacing(1, 3, 2),
      // '.shortSwithButtons': {
      //   display: 'flex',
      //   flexDirection: 'row',
      //   alignItems: 'center',
      //   width: '100%',
      //   height: '32px',
      //   border: `1px solid ${bookitColors.grayscale.graySelection}`,
      //   background: bookitColors.base.white,
      //   borderRadius: '6px',
      //   //padding: theme.spacing(0.25, 0.25),
      //   //gap: '12px',
      //   justifyContent: 'stretch',
      // },
    },
  },
}));
export function ShortHead(props: ShortHeadProps) {
  const { t } = useTranslation();
  const {
    closable,
    useSidePanel,
    closePanel,
    titlePage,
    pageLink,
    titleTooltip,
    isMobile,
    disableExpandToggle,
    expandPanel,
    rightTopActions,
    rightTopActionsAsMenu,
    onSubmit,
    useSwithActions,
    switchState,
    showProcessing,
    loading,
    isCover,
  } = props;
  const theme = useTheme();
  const topIconsLength = React.useMemo(() => {
    return (
      (!isMobile && useSidePanel && !disableExpandToggle ? 1 : 0) +
      (closable && useSidePanel ? 1 : 0) +
      (rightTopActions !== undefined && rightTopActions.length > 0
        ? rightTopActions.length === 1 && !rightTopActionsAsMenu
          ? 1
          : rightTopActions.length === 2 && !rightTopActionsAsMenu
          ? 2
          : 1
        : 0)
    );
  }, [
    closable,
    disableExpandToggle,
    isMobile,
    rightTopActions,
    rightTopActionsAsMenu,
    useSidePanel,
  ]);

  return (
    <ShortTitleRoot
      theme={theme}
      className={clsx('page-wrapper-short-title-root', { cover: isCover })}
    >
      <div className={'shortTitleContainer'}>
        <div className={'iconAndTitle'}>
          {loading ? (
            <Skeleton variant="text" width="20ch" />
          ) : (
            <React.Fragment>
              {titlePage && (
                <TitlePage
                  title={titlePage}
                  pageLink={pageLink}
                  useSidePanel={useSidePanel}
                  buttonsLenth={topIconsLength}
                  tooltip={titleTooltip}
                  isCover={isCover}
                />
              )}
            </React.Fragment>
          )}
        </div>
        <div className={'shortRightIcons'}>
          {loading ? (
            <Skeleton variant="rect" width="32px" height="32px" />
          ) : (
            <React.Fragment>
              {rightTopActions !== undefined && rightTopActions.length > 0 && (
                <>
                  {rightTopActions.length === 1 && !rightTopActionsAsMenu ? (
                    <span>{rightTopActions[0](onSubmit)}</span>
                  ) : rightTopActions.length === 2 && !rightTopActionsAsMenu ? (
                    <React.Fragment>
                      <span>{rightTopActions[0](onSubmit)}</span>
                      <span>{rightTopActions[1](onSubmit)}</span>
                    </React.Fragment>
                  ) : (
                    <ReactActions
                      size="small"
                      id={`sidepanel_top_actions`}
                      items={
                        [
                          ...rightTopActions?.map((item, index) => (
                            <span key={index}>{item(onSubmit)}</span>
                          )),
                        ].filter(v => !!v) as JSX.Element[]
                      }
                    ></ReactActions>
                  )}
                </>
              )}
              <React.Fragment>
                {!isMobile && useSidePanel && !disableExpandToggle && (
                  <IconButton
                    variant="ghost"
                    aria-label="maximize"
                    size="small"
                    shape="square"
                    onClick={expandPanel}
                  >
                    <Icon
                      icon={'external-link-alt'}
                      color="default"
                      colorExtend="textHover"
                    />
                  </IconButton>
                )}
                {closable && useSidePanel && (
                  <IconButton
                    variant="ghost"
                    aria-label="close"
                    size="small"
                    shape="square"
                    onClick={closePanel}
                    title={t(translations.CloseSidePanel)}
                  >
                    <Icon
                      icon="times"
                      size="lg"
                      color="default"
                      colorExtend="textHover"
                    />
                  </IconButton>
                )}
              </React.Fragment>
            </React.Fragment>
          )}
        </div>
      </div>
      {useSwithActions && switchState && !loading && (
        <div className={'shortSwithContainer'}>
          <SwitchActions
            target={switchState.target}
            getProps={switchState.getProps}
          />
        </div>
      )}
      {showProcessing && (
        <div style={{ width: '100%' }}>
          <LinearProgress variant="query" color="primary" />
        </div>
      )}
    </ShortTitleRoot>
  );
}
