/**
 *
 * RoomPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IReservationFilterDto } from 'api/odata/generated/entities/IReservationFilterDto';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import * as React from 'react';
import { Entity } from 'types/common';
import { CustomDate } from 'types/CustomDate';
import { dateUtils } from 'utils/date-utils';

export interface ITrainingResFilterProps {
  equipment?: Entity<number>;
  user?: Entity<string>;
  trainingDate?: Date;
}

export interface TrainingResevationsPickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'>,
    ITrainingResFilterProps {}

const url = '/api/odata/v4/ReservationFilter';
const formatName = (
  reservations: IReservationFilterDto[],
): IReservationFilterDto[] => {
  reservations.forEach(item => {
    item.Name =
      dateUtils.shortDateTimeFormat(item.Start) +
      ' - ' +
      dateUtils.shortDateTimeFormat(item.End);
  });
  return reservations;
};
export function withUserTrainingFilter(
  equipment: Entity<number> | undefined,
  user: Entity<string> | undefined,
  trainingDate: Date | undefined,
) {
  return (searchTerm: string | null) => {
    const params: { $orderby: string; $filter?: string } = {
      $orderby: 'Name asc',
    };
    const predicates: Condition<IReservationFilterDto>[] = [];
    if (equipment !== undefined) {
      predicates.push(
        new Condition('EquipmentId', ODataOperators.Equals, equipment.Id),
      );
    }
    if (user !== undefined) {
      predicates.push(
        new Condition('UserName', ODataOperators.Equals, user.Id),
      );
    }
    if (trainingDate !== undefined) {
      let tDate = dateUtils.parseISO(trainingDate);
      let strDate = new Date() > tDate ? new Date() : tDate;
      let endDate = new CustomDate(
        new Date(
          strDate.getFullYear(),
          strDate.getMonth(),
          strDate.getDate() + 1,
        ),
        'date',
      );
      predicates.push(
        new Condition('Start', ODataOperators.GreaterThanEqual, strDate),
      );
      predicates.push(
        new Condition('End', ODataOperators.LessThanEqual, endDate),
      );
    }
    params.$filter = new ODataFilterBuilder<IReservationFilterDto>({
      predicates: predicates,
      stringColumns: ['Name'],
      stringSearch: searchTerm === null ? undefined : searchTerm,
      globalServiceGroupFilter: [],
    }).toString();

    return httpClient
      .get(url, params)
      .then(
        response =>
          formatName(response.value as IReservationFilterDto[]) as Entity<
            number
          >[],
      );
  };
}
export const initTrainingResevationsData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name,Start,End',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(
        response =>
          formatName(response.value as IReservationFilterDto[]) as Entity<
            number
          >[],
      );
  }
};

export function TrainingResevationsPicker(
  props: TrainingResevationsPickerProps,
) {
  const loadData = withUserTrainingFilter(
    props.equipment,
    props.user,
    props.trainingDate,
  );

  return (
    <AutocompletePicker
      loadData={loadData}
      id={props.id || 'trainingrespickerId'}
      mini={props.mini ? true : undefined}
      size={props.size}
      additionalItem={props.additionalItem}
      {...props}
    />
  );
}
