import { httpClient } from 'api/HttpClient';
import { Condition } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';

const url = '/api/odata/v4/ServiceRequestStatusFilter';

export interface ServiceRequestStatusPickerProps
  extends Omit<AutocompletePickerProps<IServiceRequestStatusDto>, 'loadData'> {
  predicates?: (string | Condition<IServiceRequestStatusDto>)[];
}

export const initServiceRequestStatusData = (
  initialValue: string | undefined,
) => {
  if (initialValue === undefined) {
    return new Promise<IServiceRequestStatusDto[]>((resolve, reject) => {
      resolve([] as IServiceRequestStatusDto[]);
    });
  } else {
    const id = parseInt(initialValue);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as IServiceRequestStatusDto[]);
  }
};
const loadData = (
  predicates?: (string | Condition<IServiceRequestStatusDto>)[],
) =>
  getAutoCompleteLoadDataFn<IServiceRequestStatusDto>({
    url: url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: ['Id', 'Name', 'InternalStatusId'],
  });

export function ServiceRequestStatusPicker(
  props: ServiceRequestStatusPickerProps,
) {
  return (
    <AutocompletePicker
      mini={!!props.mini}
      size={props.size}
      id={props.id || 'serviceRequestStatusId'}
      loadData={loadData(props.predicates)}
      {...props}
    />
  );
}
