import { Box } from '@material-ui/core';
import { RestrictionsApi } from 'api/RestrictionsApi';
import { BooleanRender } from 'app/components/BasicTable/components/BooleanRender';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { FormLabelText } from 'app/components/Forms/FormLabelText';
import {
  FormFieldsSection,
  FormRow,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { usePromise } from 'app/hooks/usePromise';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { useAppSettingsSlice } from 'app/slice';
import { MinTimeAheadPeriodTypes } from 'enums/MinTimeAheadPeriodTypes';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dateUtils } from 'utils/date-utils';
import { enumToEntityArray } from 'utils/enumKeys';
import { IRestrictions } from '../../AssetPopUp/IAsset';
import { Link as MuiLink } from '@material-ui/core';
import {
  selectAppSettings,
  selectAuthenticatedUser,
} from 'app/slice/selectors';
import { UserOwnPermissions } from './components/UserOwnPermissions';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { toRootedURL } from 'utils/url-utils';
import { Entity } from 'types/common';
import { IUserRestrictionsResult } from 'api/odata/generated/entities/IRestrictedUserPermissionResult';
import { Body } from 'app/components/Typography';
import { TrainingStatus } from 'enums/TrainingStatus';
import { UserPermissionsApi } from 'api/UserPermissionsApi';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import { ServiceType } from 'enums/ServiceTypes';
import {
  convertAuthToIUserFilterDto,
  IUserFilterDto,
} from 'api/odata/generated/entities/IUserFilterDto';
import { initAssetsData } from 'app/components/pickers/MultiSelectPickers/AssetsPicker';
import { AssetPicker } from 'app/components/pickers/AutocompletePickers/AssetPicker';
import { BasicCard } from 'app/components/BasicCards';
import { getAssetImageThumbSrc } from 'app/components/AssetThumbImage/getAssetImageSrc';
import { DetectIsMobile } from 'utils/mobileDetect';
import { initUserData } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { IsAssetImageExist } from 'types/AppSettings';
import { bookitColors } from 'styles/colors/bookitColors';

export interface InstrumentRestrictionsProps
  extends CoverProps,
    SidePanelContentProps {
  serviceId?: number;
  serviceGroupId?: number;
  initUser?: string;
  onClose?: () => void;
  showTable?: boolean;
}
export interface Prerequisites {
  Id: number;
  Name: string;
  ServiceId: number;
  StatusName?: string;
  StatusId?: number;
  ImageName?: string;
}
export function InstrumentRestrictions(props: InstrumentRestrictionsProps) {
  const {
    serviceId,
    serviceGroupId,
    onClose,
    useSidePanel,
    showTable,
    initUser,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const authuser = useSelector(selectAuthenticatedUser);
  const appSettings = useSelector(selectAppSettings);
  //const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const isMobile = DetectIsMobile();
  const [equipmentId, setEquipmentId] = React.useState<number | undefined>(
    serviceId,
  );
  const [equipmentGroupId, setEquipmentGroupId] = React.useState<
    number | undefined
  >(serviceGroupId);
  const [
    selectedService,
    setSelectedService,
  ] = useAsyncExtendedState<IAssetDto | null>(null);
  const isAdmin = React.useMemo(() => {
    return (
      authuser !== undefined &&
      (authuser.HasAdminServicePermissions(ServiceType.Online, equipmentId) ||
        authuser.HasLabTechServicePermissions(
          ServiceType.Online,
          equipmentId,
        ) ||
        authuser?.HasAdminGroupPermissions(equipmentGroupId) ||
        authuser?.HasLabTechGroupPermissions(equipmentGroupId))
    );
  }, [equipmentGroupId, equipmentId, authuser]);
  const [selectedUser, setSelectedUser] = React.useState<IUserFilterDto | null>(
    !!authuser && !isAdmin
      ? convertAuthToIUserFilterDto(authuser)
      : !!initUser
      ? ({ Id: initUser, Name: initUser } as IUserFilterDto)
      : null,
  );

  const fetchRestrictions = async (
    serviceId?: number,
  ): Promise<IRestrictions> => {
    return RestrictionsApi.getRestrictionByService(serviceId);
  };
  const [restrictions, setRestriction] = useAsyncExtendedState<
    IRestrictions | undefined
  >(undefined);
  const [fetchRestrictionsState, fetchRestrictionsPromise] = usePromise(
    fetchRestrictions,
  );

  const [userRestrictions, setUserRestrictions] = useAsyncExtendedState<
    IUserRestrictionsResult | undefined
  >(undefined);

  const fetchUserRestrictions = async (
    user: Entity<string>,
    serviceId: number,
  ): Promise<IUserRestrictionsResult> => {
    return UserPermissionsApi.getUserSlotPermissions({
      serviceId: serviceId,
      username: user.Id,
    });
  };
  const [fetchUserRestrictionsState, fetchUserRestrictionsPromise] = usePromise(
    fetchUserRestrictions,
  );
  const fetchAsset = async (serviceId: number): Promise<IAssetDto> => {
    return initAssetsData(serviceId.toString(), true, undefined, true).then(
      result => result[0],
    );
  };
  const [fetchAssetState, fetchAssetPromise] = usePromise(fetchAsset);

  const openAsset = React.useCallback(
    (item: IAssetDto) => {
      if (props.isCover) {
        window.location.href = toRootedURL(
          `/Assets/Details.aspx?id=${item.Id}`,
        );
      } else {
        dispatch(
          layoutActions.openSidePanel({
            props: { Id: item.Id, serviceId: item.ServiceId ?? -1 },
            type: RenderPageType.AssetDetails,
            expanded: false,
          }),
        );
      }
    },
    [dispatch, layoutActions, props.isCover],
  );
  useEffectOnMount(() => {
    setRestriction(fetchRestrictionsPromise(equipmentId));
    if (isAdmin) {
      if (!!initUser) {
        (async () => {
          try {
            const data = await initUserData(initUser, 'full');
            if (!!data && data.length > 0) {
              let selected = (data[0] ?? {
                Id: initUser,
                Name: initUser,
              }) as IUserFilterDto;
              setSelectedUser(selected);
              setUserRestrictions(
                fetchUserRestrictionsPromise(selected, equipmentId || -1),
              );
            }
          } catch {}
        })();
      } else {
        setSelectedUser(null);
      }
    } else {
      if (selectedUser !== null) {
        setUserRestrictions(
          fetchUserRestrictionsPromise(selectedUser, equipmentId || -1),
        );
      }
    }
    if (isAdmin) {
      setSelectedService(fetchAssetPromise(equipmentId || -1));
    }

    return () => {};
  });
  React.useEffect(() => {
    const messageKey = 'QuizLoad';
    if (fetchRestrictionsState.error) {
      dispatch(
        actions.addNotification({
          variant: 'error',
          message: 'Something went wrong.',
          key: messageKey,
        }),
      );
    }
  }, [fetchRestrictionsState.error, actions, dispatch]);
  const minTimeAhead = (rest: IRestrictions): string => {
    if (rest.MinTimeAheadPeriodValue !== null) {
      return `${t(translations.Before)}: ${rest.MinTimeAheadPeriodValue.toFixed(
        2,
      )}
       ${(t(translations.AdvancedReservation) as string).replace(
         '{0}',
         enumToEntityArray(MinTimeAheadPeriodTypes).filter(
           f => f.Id === rest.MinTimeAheadPeriodType,
         )[0].Name,
       )}`;
    } else if (rest.MinTimeAheadMinutesSinceMidnight !== null) {
      return `${t(translations.OnTheSameDay)}: ${(t(
        translations.ToBeBookedBy,
      ) as string).replace(
        '{0}',
        dateUtils.dateFnsUtils.format(
          new Date(0, 0, 0, 0, rest.MinTimeAheadMinutesSinceMidnight),
          dateUtils.DateIOFormats.fullDateTime24h,
        ),
      )}`;
    } else {
      return '';
    }
  };
  const getStatus = React.useCallback(
    (asset: IAssetDto) => {
      let validRender =
        !!userRestrictions &&
        userRestrictions.PrerequisiteTrainingsIndicators.length > 0 &&
        userRestrictions.PrerequisiteTrainingsIndicators.some(
          f => f.Id === asset.ServiceId,
        );
      if (!!userRestrictions && validRender) {
        let found = userRestrictions.PrerequisiteTrainingsIndicators.find(
          f => f.Id === asset.ServiceId,
        );
        let status = enumToEntityArray(TrainingStatus).find(
          f => f.Id === found?.Status,
        )?.Name;
        let statusName = `${status} ${
          asset.PrerequisiteFor === 1
            ? ' - ( ' + t(translations.CoreWideRequirement) + ' )'
            : ''
        }`;
        return {
          StatusName: statusName,
          Status: found?.Status,
        };
      }
      return undefined;
    },
    [t, userRestrictions],
  );
  const prerequisites: Prerequisites[] = React.useMemo(() => {
    let results: Prerequisites[] = [];
    if (!!restrictions && restrictions.PrerequisiteEquipments.length > 0) {
      results = restrictions.PrerequisiteEquipments.map(preq => {
        let usrrest = getStatus(preq);
        return {
          Id: preq.Id,
          Name: preq.Name,
          ServiceId: preq.ServiceId,
          ImageName: preq.Image,
          StatusId: usrrest?.Status,
          StatusName: usrrest?.StatusName,
        } as Prerequisites;
      });
    }
    return results;
  }, [getStatus, restrictions]);

  // const PrerequisiteWithStatus = ({ asset }: { asset: IAssetDto }) => {
  //   let validRender =
  //     !!userRestrictions &&
  //     userRestrictions.PrerequisiteTrainingsIndicators.length > 0 &&
  //     userRestrictions.PrerequisiteTrainingsIndicators.some(
  //       f => f.Id === asset.ServiceId,
  //     );

  //   if (!!userRestrictions && validRender) {
  //     let found = userRestrictions.PrerequisiteTrainingsIndicators.find(
  //       f => f.Id === asset.ServiceId,
  //     );
  //     let status = enumToEntityArray(TrainingStatus).find(
  //       f => f.Id === found?.Status,
  //     )?.Name;
  //     let statusName = `${status} - ${
  //       asset.PrerequisiteFor === 1
  //         ? '( ' + t(translations.CoreWideRequirement) + ' )'
  //         : ''
  //     }`;
  //     return (
  //       <Body size="small" bold={true}
  //         color={found?.Status === TrainingStatus.Passed ? 'success' : 'danger'}
  //       >
  //         {cliTruncate(statusName, 25, { position: 'end' })}
  //       </Body>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  const userOnChange = (value: Entity<string> | null) => {
    setSelectedUser(value as IUserFilterDto);
    if (value === null) {
      setUserRestrictions(undefined);
    } else {
      setUserRestrictions(
        fetchUserRestrictionsPromise(value, equipmentId || -1),
      );
    }
  };
  const assetOnChange = (value: IAssetDto | null) => {
    setSelectedService(value);
    setRestriction(fetchRestrictionsPromise(value?.ServiceId ?? undefined));
    setSelectedUser(null);
    setUserRestrictions(undefined);
  };
  React.useEffect(() => {
    if (fetchAssetState.status !== 'pending') {
      setEquipmentId(selectedService?.ServiceId ?? undefined);
      setEquipmentGroupId(selectedService?.ServiceGroupId ?? undefined);
    }
    return () => {};
  }, [
    fetchAssetState.status,
    selectedService?.ServiceGroupId,
    selectedService?.ServiceId,
  ]);
  // const minReservationGap = (rest: IRestrictions): string => {
  //   let gaps = [
  //     rest.MinReservationOrderGap,
  //     rest.UserReservationGap,
  //     rest.UserGroupReservationGap,
  //     rest.InstituteReservationGap,
  //   ];
  //   if (gaps.filter(g => g > 0).length > 0) {
  //     return Math.min(...gaps.filter(g => g > 0)).toString();
  //   }
  //   return '';
  // };
  const RenderContent = () => {
    return !!restrictions ? (
      <StyledFormFieldsContainer>
        <FormFieldsSection>
          {isAdmin && (
            <React.Fragment>
              <FormRow>
                <AssetPicker
                  ariaLabel={t(translations.Resource)}
                  label={t(translations.Resource)}
                  id="available-resources"
                  onChange={assetOnChange}
                  admin={true}
                  variant="filled"
                  fullWidth={true}
                  showOnlyActiveAssets={true}
                  value={selectedService}
                />
              </FormRow>
              <UserOwnPermissions
                user={selectedUser}
                isAdmin={true}
                onUserChange={userOnChange}
                permissions={
                  !!userRestrictions ? userRestrictions.Permissions : []
                }
                permLoading={fetchUserRestrictionsState.status === 'pending'}
                restrictedOption={restrictions.LimitlessReservationAllowed}
                EquipmentName={restrictions.Name}
                serviceGroupId={equipmentGroupId || restrictions.ServiceGroupId}
                serviceId={equipmentId || restrictions.Id}
              />
            </React.Fragment>
          )}
          {prerequisites.length > 0 && (
            <FormRow>
              <FormLabelText
                name="PrerequisiteTrainings"
                html={
                  <React.Fragment>
                    <Body size="small" bold={true}>
                      {t(translations.PrerequisiteTrainings)}
                    </Body>

                    <Box
                      style={{
                        display: 'flex',
                        flexDirection:
                          props.isCover || isMobile ? 'column' : 'row',
                        flex: '1 1 100%',
                        alignItems: `${
                          props.isCover || isMobile ? 'stretch' : 'flex-start'
                        }`,
                        gap: '8px',
                      }}
                    >
                      {prerequisites.map(item => {
                        return (
                          <BasicCard
                            key={item.ServiceId}
                            position="horizontal"
                            cardSize="small"
                            title={item.Name}
                            cardContent={
                              item.StatusId === undefined ? undefined : (
                                <Body
                                  size="small"
                                  bold={true}
                                  style={{
                                    color:
                                      item.StatusId === TrainingStatus.Passed
                                        ? bookitColors.success.regular
                                        : item.StatusId ===
                                          TrainingStatus.Expired
                                        ? bookitColors.warning.regular
                                        : bookitColors.danger.regular,
                                  }}
                                >
                                  {item.StatusName}
                                </Body>
                                //<PrerequisiteWithStatus asset={item} />
                              )
                            }
                            imageSrc={getAssetImageThumbSrc(
                              item.ImageName || null,
                              'horizontal',
                              IsAssetImageExist(
                                appSettings,
                                item.ImageName || null,
                                'horizontal',
                              ),
                            )}
                            useImage={true}
                            // status={
                            //   item.StatusId === undefined
                            //     ? 'info'
                            //     : item.StatusId === TrainingStatus.Passed
                            //     ? 'success'
                            //     : item.StatusId === TrainingStatus.Expired
                            //     ? 'warning'
                            //     : 'error'
                            // }
                            maxContentHeight={40}
                            useButtons={false}
                            primaryAction={() => openAsset(item)}
                            backStyle="white"
                          />
                          // <Box
                          //   style={{
                          //     display: 'flex',
                          //     flexDirection: 'row',
                          //     alignItems: 'center',
                          //     gap: '8px',
                          //   }}
                          //   key={item.ServiceId}
                          // >
                          //   <BookitChip
                          //     onClick={() => openAsset(item)}
                          //     variant="default"
                          //     size="small"
                          //     label={item.Name}
                          //     key={item.Id}
                          //   />
                          //   <PrerequisiteWithStatus asset={item} />
                          // </Box>
                        );
                      })}
                    </Box>
                  </React.Fragment>
                }
              />
            </FormRow>
          )}

          {restrictions.ForceTutoring === true && (
            <FormRow>
              <FormLabelText
                name="ApprovalRequired"
                label={t(translations.TrainingMandatory)}
                text={
                  !userRestrictions ||
                  (!!userRestrictions && !userRestrictions.PreApproved) ? (
                    <>{t(translations.ApprovalRequired)}</>
                  ) : (
                    <React.Fragment>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: 8,
                        }}
                      >
                        <Icon icon="check" color="success" />
                        {t(translations.PreApproved) as string}
                        <InfoIcon
                          title={t(translations.PreApproved_info) as string}
                        />
                      </Box>
                    </React.Fragment>
                  )
                }
              />
            </FormRow>
          )}

          {restrictions.MinReservationOrder !== null &&
            restrictions.MinReservationOrder > 0 && (
              <FormRow>
                <FormLabelText
                  name="MinReservationOrder"
                  label={t(translations.MinimumReservationDurationH)}
                  text={restrictions.MinReservationOrder}
                />
              </FormRow>
            )}

          {restrictions.MaxReservationOrder !== null &&
            restrictions.MaxReservationOrder > 0 && (
              <FormRow>
                <FormLabelText
                  name="MaxReservationOrder"
                  label={t(translations.MaximumReservationDurationH)}
                  text={restrictions.MaxReservationOrder}
                />
              </FormRow>
            )}

          {restrictions.ReservationMinimumGap !== null &&
            restrictions.ReservationMinimumGap > 0 && (
              <FormRow>
                <FormLabelText
                  name="ReservationMinimumGap"
                  label={t(translations.ReservationMinimumGap)}
                  text={restrictions.ReservationMinimumGap}
                />
              </FormRow>
            )}
          {restrictions.CarryOverTimeSlotTypeId !== null &&
            restrictions.CarryOverTimeSlotGap != null && (
              <FormRow>
                <FormLabelText
                  name="CarryOverTimeSlotTypeId"
                  label={t(translations.CarryOverTimeSlotGap)}
                  text={`${restrictions.CarryOverTimeSlotGap} hours over timeslot ${restrictions.CarryOverTimeSlotTypeName}`}
                />
              </FormRow>
            )}
          {(restrictions.MinTimeAheadPeriodValue !== null ||
            restrictions.MinTimeAheadMinutesSinceMidnight != null) && (
            <FormRow>
              <FormLabelText
                name="MinimumTimeAhead"
                label={t(translations.MinimumTimeAhead)}
                info={
                  restrictions.MinTimeAheadPeriodValue !== null
                    ? t(translations.AdvancedReservation_info)
                    : t(translations.ToBeBookedBy_info)
                }
                text={minTimeAhead(restrictions)}
              />
            </FormRow>
          )}
          {restrictions.RestrictionsOverview !== null &&
            restrictions.RestrictionsOverview !== '' && (
              <FormRow>
                <FormLabelText
                  name="RestrictionsOverview"
                  label={t(translations.RestrictionsOverview)}
                  text={restrictions.RestrictionsOverview}
                />
              </FormRow>
            )}
          {restrictions.BypassQuotas === true && (
            <FormRow>
              <FormLabelText
                name="BypassQuotasForReservations"
                label={t(translations.BypassQuotasForReservations)}
                text={<BooleanRender value={restrictions.BypassQuotas} />}
              />
            </FormRow>
          )}
          {restrictions.BypassUserQuotasAndGaps &&
            restrictions.BypassUserQuotasAndGapsD !== null &&
            restrictions.BypassUserQuotasAndGapsD > 0 && (
              <FormRow>
                <FormLabelText
                  name="BypassUserQuotasAndGapsD"
                  label={t(translations.BypassUserQuotasAndGapsD_Description)}
                  text={`${restrictions.BypassUserQuotasAndGapsD} ${t(
                    translations.BypassUserQuotasAndGapsD_DescriptionCont,
                  )}`}
                />
              </FormRow>
            )}
          {restrictions.BypassMinResDurationD &&
            restrictions.BypassMinResDurationT !== null &&
            restrictions.BypassMinResDurationT > 0 && (
              <FormRow>
                <FormLabelText
                  name="BypassMinResDurationT"
                  label={t(translations.BypassMinResDurationT)}
                  text={`${restrictions.BypassMinResDurationT} ${t(
                    translations.BypassMinResDurationD_DescriptionCont,
                  )}`}
                />
              </FormRow>
            )}
        </FormFieldsSection>
        {!isAdmin && restrictions.LimitlessReservationAllowed !== true && (
          <FormFieldsSection
            titleSection={t(translations.AdditionalRestrictions)}
            titleLink={
              <Tooltip title={t(translations.SpecificUserSlotPermissions_info)}>
                <span style={{ marginLeft: '1ch' }}>
                  <Icon icon="info-circle" />
                </span>
              </Tooltip>
            }
          >
            <UserOwnPermissions
              user={!!authuser ? convertAuthToIUserFilterDto(authuser) : null}
              isAdmin={false}
              onUserChange={userOnChange}
              permissions={
                !!userRestrictions ? userRestrictions.Permissions : []
              }
              permLoading={fetchUserRestrictionsState.status === 'pending'}
              restrictedOption={restrictions.LimitlessReservationAllowed}
              EquipmentName={restrictions.Name}
              serviceGroupId={equipmentGroupId || restrictions.ServiceGroupId}
              serviceId={equipmentId || restrictions.Id}
            />
          </FormFieldsSection>
        )}

        {/* this functionallity hidden by the task https://prog4biz.atlassian.net/browse/BKT-6141  */}
        {/* {(!showTable || (useSidePanel && !sidePanelExpanded)) && (
                <FormFieldsSection
                  titleSection={t(translations.ViewAllInstrumentPermissions)}
                  titleLink={
                    <RestrictionsLink
                      serviceId={serviceId || -1}
                      title={t(translations.ShowRestrictionsFull)}
                      showTable={true}
                      useSidePanel={true}
                    >
                      <Icon icon={['fal', 'table']} size="lg" />
                    </RestrictionsLink>
                  }
                >
                  {' '}
                </FormFieldsSection>
              )}
              {showTable && (sidePanelExpanded || !useSidePanel) && (
                <UserPermissionsTable
                  serviceId={serviceId || -1}
                  isSidePanel={useSidePanel || false}
                  byUserFilter={false}
                />
              )} */}
      </StyledFormFieldsContainer>
    ) : (
      <React.Fragment>{}</React.Fragment>
    );
  };
  return (
    <PageWrapper
      useSidePanel={useSidePanel}
      closable={useSidePanel || props.isCover}
      disableExpandToggle={props.isCover}
      loading={fetchRestrictionsState.status !== 'resolved'} //loading || rows.length === 0
      pageLink={`/instrumentRestrictions?eid=${equipmentId}`}
      pageName={t(translations.InstrumentRestrictions)}
      titlePage={
        restrictions
          ? `${restrictions.Name} - ${t(translations.ReservationRestrictions)}`
          : ''
      }
      titleTooltip={
        restrictions
          ? `${restrictions.Name} - ${t(translations.ReservationRestrictions)}`
          : ''
      }
      children={
        <Box height={'100%'} padding={'8px 0px'}>
          {restrictions ? (
            <RenderContent />
          ) : (
            <>{t(translations.err_Equipment_NotFound)}</>
          )}
        </Box>
      }
      closeSidePanel={onClose}
      //rightTopActions={data?.AmAdmin ? [EditButton] : undefined}
      isCover={props.isCover && !showTable}
      closeCover={props.closeCover}
    />
  );
}
export const RestrictionsLink = (props: {
  serviceId?: number;
  children: React.ReactNode;
  useSidePanel?: boolean;
  showTable?: boolean;
  title?: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const open = () => {
    dispatch(
      actions.openSidePanel({
        type: RenderPageType.RestrictionDetails,
        props: {
          serviceId: props.serviceId,
          showTable: props.showTable,
          useSidePanel: props.useSidePanel || true,
          isCover: false,
        },
        expanded: props.showTable,
      }),
    );
  };
  return (
    <Tooltip title={props.title ?? t(translations.ShowRestrictionsFull)}>
      <MuiLink style={{ cursor: 'pointer' }} onClick={() => open()}>
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};
