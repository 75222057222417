/**
 * A copy of local\Bookit.Data\Enums\BudgetFields.cs
 * replace attributes: ^ *\[[^\]]+\]\n
 */
export enum BudgetFields {
  Active = 1,

  Number = 2,

  BudgetManager = 3,

  UserGroup = 4,

  BudgetCat = 5,

  HideExperiments = 6,

  StartDate = 7,

  EndDate = 8,

  Confidential = 9,

  FundingType = 10,

  AllowJoinRequests = 11,

  DefaultExperimentTemplate = 12,

  Default = 13,

  BudgetIsOnline = 14,

  BudgetFund = 15,

  FacilityProject = 16,

  BudgetInternalId = 17,

  BudgetStatus = 18,

  ExternalEndDate = 19,

  ExternalUpdatedAt = 20,

  BudgetLeader = 21,

  BudgetInitialCost = 22,

  BudgetBudget = 23,

  BudgetOwner = 24,

  BdmUsername = 25,

  SubsidyProfit = 26,

  SubsProfProjectNumber = 27,

  SubsProfCostCenter = 28,
  SubsProfActivity = 29,

  SubsProfAccountNumber = 30,
  SubsidyFinAccountType = 31,
  BudgetRemarks = 32,

  Company = 33,

  BudgetManagerCanApproveExperiment = 34,

  BudgetAmount = 35,

  HardLimit = 36,

  SoftLimit = 37,

  BudgetLimitsNotificationSettings = 38,

  BudgetExtraTextField1 = 39,

  BudgetExtraTextField2 = 40,

  BudgetExtraTextField3 = 41,

  BudgetExtraTextField4 = 42,

  BudgetExtraTextField5 = 43,

  BudgetExtraBoolField1 = 44,

  BudgetExtraBoolField2 = 45,

  BudgetExtraBoolField3 = 46,

  BudgetExtraBoolField4 = 47,

  BudgetExtraBoolField5 = 48,

  BudgetExtraDateField1 = 49,

  BudgetExtraDateField2 = 50,

  BudgetExtraDateField3 = 51,

  BudgetExtraDateField4 = 52,

  BudgetExtraDateField5 = 53,

  BudgetExtraNumField1 = 54,

  BudgetExtraNumField2 = 55,

  BudgetExtraNumField3 = 56,

  BudgetExtraNumField4 = 57,

  BudgetExtraNumField5 = 58,

  InvoicingRemarks = 59,
  ProjectBilingEmail = 60,
  PendingProjectAmount = 61,
  SecondaryCoordinators = 62,
  PurchaseOrderOption = 63,
}
