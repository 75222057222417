/**
 *
 * AdminUserPicker
 *
 */
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { UserMultiPicker } from '../pickers/AutocompletePickers/UserMultiPicker';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { UserPicker } from '../pickers/AutocompletePickers/UserPicker';

export const FormAdminUsersPicker = ({
  label,
  ...props
}: FieldHookConfig<IUserFilterDto[] | null> &
  IFieldProps & { multiple: boolean }) => {
  const [field, meta, helpers] = useField<IUserFilterDto[] | null>(props);
  if (props.multiple) {
    return (
      <UserMultiPicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        predicates={props.predicates}
        disabled={props.disabled}
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onBlur={field?.onBlur}
        value={field.value ?? undefined}
        variant="filled"
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        info={props.info}
        urlType="admin"
      />
    );
  } else {
    return (
      <UserPicker
        predicates={props.predicates}
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={value => {
          if (value !== null) {
            helpers.setValue([value], true);
          } else {
            helpers.setValue([], true);
          }
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onBlur={field?.onBlur}
        // value={field.value ?? undefined}
        value={
          field.value !== null
            ? field.value.length > 0
              ? (field.value[0] as IUserFilterDto | null | undefined)
              : null
            : null
        }
        error={meta?.error !== undefined}
        variant="filled"
        disabled={props.disabled}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        info={props.info}
        urlType="admin"
      />
    );
  }
};
export const FormAdminSingleUsersPicker = ({
  label,
  ...props
}: FieldHookConfig<IUserFilterDto | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<IUserFilterDto | null>(props);

  return (
    <UserPicker
      predicates={props.predicates}
      name={props.name}
      placeholder={props.placeholder}
      label={label}
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      onBlur={field?.onBlur}
      value={field?.value}
      error={meta?.error !== undefined}
      variant="filled"
      disabled={props.disabled}
      helperText={meta.error}
      fullWidth={props.fullWidth}
      info={props.info}
      urlType="admin"
    />
  );
};
