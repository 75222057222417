/**
 *
 * AsyncPicker
 *
 */
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { WorkOrderTypePicker } from '../pickers/AutocompletePickers/WorkOrderTypePicker';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';

export const FormWorkOrderTypePicker = ({
  label,
  ...props
}: FieldHookConfig<IWorkOrderTypeDto | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<IWorkOrderTypeDto | null>(props);
  return (
    <>
      <WorkOrderTypePicker
        name={props.name}
        variant="filled"
        disableClearable={props.disableClearable}
        placeholder={props.placeholder}
        label={label}
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        disabled={props.disabled}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        predicates={props.predicates}
        fullWidth={props.fullWidth}
      />
    </>
  );
};
