/**
 *
 * TopToolbar
 *
 */
import * as React from 'react';
import { ToolbarProps } from '@material-ui/core/Toolbar';
import { CustomerLogo } from '../../CustomerLogo';
import useExpandedSideBarState from 'app/hooks/useExpandedSideBarState';
import { ProfileMenu } from '../ProfileMenu';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import styled from 'styled-components';
import { StyledToolbar } from 'app/components/BasicTable/BasicFilter/TableToolbar/styled';
import { useTheme } from '@material-ui/core/styles';
import { Icon } from 'app/components/BasicIcons/FontAwesome';

export interface TopToolbarProps extends ToolbarProps {}
const Separator = styled('div')`
  flex-grow: 1;
`;
export const TopToolbar = React.memo(function TopToolbar(
  props: TopToolbarProps,
) {
  const { toggle } = useExpandedSideBarState();

  const handleClick = React.useCallback(() => {
    toggle();
  }, [toggle]);
  const theme = useTheme();
  return (
    <React.Fragment>
      <StyledToolbar variant="dense" theme={theme} className={'toptoolbar'}>
        <div className="titleSection">
          <IconButton
            variant="textGray"
            aria-label="open drawer"
            onClick={handleClick}
          >
            <Icon icon="bars" size="xl" />
          </IconButton>
          <CustomerLogo />
        </div>
        {/* acts as a separator between visually left and right components of the side bar */}
        <Separator />
        <ProfileMenu />
      </StyledToolbar>
    </React.Fragment>
  );
});
