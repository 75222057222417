import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ExternalLink } from 'app/components/ExternalLink';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { selectGlobalDbSetting_EnableLegacyScheduler } from 'app/slice/globalDbSettingsSelectors';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IPathAndQuery } from 'utils/url-utils';
import { ReservationDetailsProps } from '../../pages/ReservationDetails/Details';
import { ReservationQueryStringParameters } from '../../pages/ReservationDetails/Details/slice/types';

export interface ReservationCreateButtonProps extends ButtonProps {
  instruments: number[];
}
export function ReservationCreateButton({
  variant = 'white',
  size = 'small',
  onClick,
  startIcon,
  instruments,
  ...props
}: ReservationCreateButtonProps) {
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const User = useSelector(selectAuthenticatedUser);
  const EnableLegacyScheduler = useSelector(
    selectGlobalDbSetting_EnableLegacyScheduler,
  );

  const params = {
    selectedIds: String(instruments.join(',')),
    un: User?.Id,
  } as ReservationQueryStringParameters;

  const handleClick = e => {
    if (!EnableLegacyScheduler) {
      dispatch(
        actions.openSidePanel({
          type: RenderPageType.ReservationDetails,
          props: {
            useSidePanel: true,
            queryParams: params,
          } as ReservationDetailsProps,
        }),
      );
    }
    onClick?.(e);
  };
  const legacyURL: IPathAndQuery = {
    path: '/Reservation.aspx',
    search: { id: instruments.join(',') },
  };
  const p = EnableLegacyScheduler
    ? {
        component: ExternalLink,
        href: legacyURL,
      }
    : {};
  return (
    <Button
      variant={variant}
      size={size}
      startIcon={startIcon ?? <Icon icon="plus" />}
      onClick={handleClick}
      {...p}
      {...props}
    />
  );
}
