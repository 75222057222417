/**
 *
 * Asynchronously loads the component for PriceSheetPeriodDetailsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PriceSheetPeriodDetailsPage = lazyLoad(
  () => import('./index'),
  module => module.PriceSheetPeriodDetailsPage,
);
