import { Portal } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CustomFormApi } from 'api/CustomFormApi';
import { ICustomFormDto } from 'api/odata/generated/entities/ICustomFormDto';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { CustomForm } from 'app/components/CustomForm/CusomFormControl/Form';
import { initUserData } from 'app/components/CustomForm/CustomFormFields/CustomFormUserSelection';
import {
  DeserializeValue,
  IFormFileValue,
  orderFields,
  ParseFormFieldEnums,
} from 'app/components/CustomForm/CustomFormUtils';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { usePromise } from 'app/hooks/usePromise';
import { useAppSettingsSlice } from 'app/slice';
import { CustomFormTypeEnum } from 'enums/CustomFormType';
import React from 'react';
import { MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import { isEmptyOrWhitespace, isNullOrUndefined } from 'utils/typeUtils';
import { InitFormFiles } from '../CustomFormFields/CustomFormFileUpload';

export interface CustomFormControlProps {
  CustomFormId: number;
  FormType: CustomFormTypeEnum;
  values?: IFormValueDto[];
  submitFormRef: MutableRefObject<any>;
  saveFormValues: (
    values: IFormValueDto[],
    files: IFormFileValue[],
    originalFiles: IFormFileValue[],
  ) => void;
  isAdmin?: boolean;
  formIsValid?: (isValid: boolean) => void;
  readonly?: boolean;
  formName?: string;
  disabled?: boolean;
  onLoadCompleted?: () => void;
  onLoadStarted?: () => void;
  validationEnabled?: boolean;
  withTopSeparator?: boolean;
  withBottomSeparator?: boolean;
}

export function CustomFormControl(props: CustomFormControlProps) {
  const {
    CustomFormId,
    values,
    submitFormRef,
    saveFormValues,
    FormType,
    isAdmin,
    formIsValid,
    readonly,
    formName,
    disabled,
    onLoadCompleted,
    onLoadStarted,
    validationEnabled,
    withTopSeparator,
    withBottomSeparator,
  } = props;

  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const [originalFiles, setOriginalFiles] = React.useState<IFormFileValue[]>(
    [],
  );
  // const [currentFormId, setCurrentFormId] = React.useState<number | undefined>(
  //   CustomFormId,
  // );
  const fetchCustomForm = async (formType: number, customFormId: number) =>
    CustomFormApi.getSingle(formType, customFormId).then(response => {
      let item = response.value[0] as ICustomFormDto;
      ParseFormFieldEnums(item.FormFields);
      return item;
    });
  const loadValue = async (
    field: IFormFieldDto,
    value: IFormValueDto | null,
  ): Promise<ValueResults> => {
    if (field.Type === 'UserSelection') {
      if (isNullOrUndefined(value?.Value)) {
        return new Promise<ValueResults>(resolve => {
          resolve({
            fieldId: field.Id,
            value: field.IsMultiple ? [] : null,
          } as ValueResults);
        });
      } else {
        try {
          let data = await initUserData(value?.Value ?? '', field);
          return {
            fieldId: field.Id,
            value: field.IsMultiple ? data : data[0],
          } as ValueResults;
        } catch (ex) {
          return {
            fieldId: field.Id,
            value: null,
          } as ValueResults;
        }
      }
    } else if (field.Type === 'File') {
      if (isNullOrUndefined(value?.Value)) {
        return new Promise<ValueResults>(resolve => {
          resolve({
            fieldId: field.Id,
            value: [],
          } as ValueResults);
        });
      } else {
        try {
          let results = await InitFormFiles(value?.Value ?? '', field);
          const files =
            results.length > 0
              ? results.map(r => {
                  return {
                    Id: field.Id,
                    Value: r.Id,
                    DisplayValue: r.Name,
                    Size: r.Size,
                    PostedFile: null,
                  } as IFormFileValue;
                })
              : [];
          setOriginalFiles(prev => [...prev, ...files]);
          return {
            fieldId: field.Id,
            value: files,
          } as ValueResults;
        } catch (ex) {
          return {
            fieldId: field.Id,
            value: [],
          } as ValueResults;
        }
      }
    } else {
      return new Promise<ValueResults>(resolve => {
        const opts = DeserializeValue(field, value);
        resolve({
          fieldId: field.Id,
          value: opts,
        } as ValueResults);
      });
    }
  };
  interface ValueResults {
    fieldId: number;
    value: any;
  }
  const [fetchFormValues, getFormValues] = usePromise(
    async (
      formFields: IFormFieldDto[],
      formValues: IFormValueDto[] | undefined,
    ) => {
      return Promise.all(
        formFields
          .filter(f => f.Active === true)
          .map(async field => {
            try {
              const resvalue = await loadValue(
                field,
                formValues?.find(v => v.FormFieldId === field.Id) || null,
              );
              return resvalue;
            } catch {
              return {
                fieldId: field.Id,
                value: null,
              } as ValueResults;
            }
          }),
      );
    },
  );
  const [fetchFormState, fetchFormPromise] = usePromise(fetchCustomForm);
  const [customForm, setState] = useAsyncExtendedState<
    ICustomFormDto | undefined
  >(undefined);
  const [loadedValues, setValues] = useAsyncExtendedState<
    Record<string, any> | undefined
  >(undefined);
  // useEffectOnMount(() => {
  //   setState(fetchFormPromise(FormType as number, currentFormId));
  //   return () => {};
  // });
  React.useEffect(() => {
    if (CustomFormId) {
      !!onLoadStarted && onLoadStarted();
      setState(fetchFormPromise(FormType as number, CustomFormId));
    }
    return () => {
      setState(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CustomFormId]);
  React.useEffect(() => {
    if (customForm) {
      const serializedvalues: Record<string, any> = {};
      setValues(
        getFormValues(customForm.FormFields, values).then(vals => {
          vals.forEach(
            item => (serializedvalues[item.fieldId.toString()] = item.value),
          );
          return serializedvalues;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customForm]);
  React.useEffect(() => {
    const messageKey = 'formLoad';
    let active = !!fetchFormState && !!fetchFormState.status;
    if (active) {
      if (fetchFormState.error) {
        let msg = fetchFormState.error.message;
        !!onLoadCompleted && onLoadCompleted();
        dispatch(
          actions.addNotification({
            variant: 'error',
            message: 'Something went wrong. ' + msg,
            key: messageKey,
          }),
        );
      }
      if (
        fetchFormState.status === 'resolved' &&
        fetchFormValues?.status === 'resolved' &&
        !!customForm &&
        !!loadedValues
      ) {
        !!onLoadCompleted && onLoadCompleted();
      }
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    dispatch,
    fetchFormValues,
    onLoadCompleted,
    customForm,
    loadedValues,
    fetchFormState,
  ]);

  const portalRef = React.useRef(null);
  const bindSubmitForm = React.useCallback(
    submitForm => {
      submitFormRef.current = submitForm;
    },
    [submitFormRef],
  );
  const handleSubmit = React.useCallback(
    (values: Record<number, string | null>, files: IFormFileValue[]) => {
      const formValues: IFormValueDto[] = Object.entries(values).map(
        ([formFieldId, value]) => ({
          Id: -1,
          FormFieldId: +formFieldId,
          Value: isEmptyOrWhitespace(value) ? null : value,
          DisplayValue: null,
          SourceValueId: null,
          AlertId: null,
          AlertTypeId: null,
          Readonly: false,
          CustomFormId: customForm?.Id || CustomFormId,
          CustomFormName: customForm?.Name || null,
          BudgetExperimentId: null,
          ExperimentItemId: null,
          RequestId: null,
          ReservationId: null,
          ServiceGroupId: null,
          ServiceGroupUserId: null,
        }),
      );
      saveFormValues(formValues, files, originalFiles);
    },
    [
      CustomFormId,
      customForm?.Id,
      customForm?.Name,
      originalFiles,
      saveFormValues,
    ],
  );
  const formFields = React.useMemo(() => {
    return customForm !== undefined
      ? validationEnabled
        ? customForm.FormFields.sort(orderFields)
        : customForm.FormFields.sort(orderFields).map(field =>
            Object.assign(field, { Required: 0 }),
          )
      : [];
  }, [customForm, validationEnabled]);
  return (
    <React.Fragment>
      <div ref={portalRef} id="custFormPort" style={{ width: '100%' }} />
      {fetchFormState.status === 'resolved' &&
      customForm !== undefined &&
      !!loadedValues &&
      fetchFormValues.status === 'resolved' ? (
        <Portal container={portalRef.current}>
          <CustomForm
            bindSubmitForm={bindSubmitForm}
            formFields={formFields}
            formValues={loadedValues}
            formName={formName || customForm.Name}
            onSubmit={handleSubmit}
            formType={FormType}
            isAdmin={isAdmin}
            CustomFormId={CustomFormId}
            formIsValid={formIsValid}
            readonly={readonly}
            disabled={disabled}
            validationEnabled={validationEnabled}
            withBottomSeparator={withBottomSeparator}
            withTopSeparator={withTopSeparator}
          />
        </Portal>
      ) : (
        <React.Fragment>
          <Skeleton variant="rect" width="100%" height="auto" />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
