import { httpClient } from 'api/HttpClient';

const SESSIONTIMEOUT_ASHX_CACHE_BUST = new Date().getTime();

function GetData(
  url: string,
  Fulfilled: (value: string) => void,
  Rejected: () => null,
) {
  httpClient
    .get(url, { _: SESSIONTIMEOUT_ASHX_CACHE_BUST })
    .then(response => Fulfilled(response as string), Rejected);
}

var checkFailCount: number = 0;
export const CheckSessionTimeout = function (
  loginUrl: string,
  appRoot: string,
  showWarning: () => void,
  /*, hideWarning*/
) {
  var checkInterval: number = 60000;
  var redirectAction = function () {
    window.onbeforeunload = null;
    var currentUrl = window.location.pathname + window.location.search;
    var redirUrl = loginUrl + '?ReturnUrl=' + encodeURIComponent(currentUrl);
    console.log('redirecting to login page');
    window.location.href = redirUrl;
  };
  var check = function () {
    GetData(
      appRoot,
      (data: string) => {
        checkFailCount = 0;
        switch (data) {
          case 'ok':
            //showWarning(); //for test
            break;
          case 'logout':
            redirectAction();
            break;
          case 'alert':
            showWarning();
            break;
          default:
            redirectAction();
            break;
        }
        setTimeout(check, checkInterval);
      },
      () => {
        checkFailCount++;
        console.error(
          'Failed to check active session on the server x' + checkFailCount,
        );
        if (checkFailCount > 2) {
          redirectAction();
        }
        return null;
      },
    );
  };
  setTimeout(check, checkInterval);
};

export function ExtendSessionTimeout(Fulfilled: (value: string) => void) {
  httpClient
    .post('/svc/Script/AjaxService.svc/ExtendSessionTimeout', {
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
    })
    .then(response => Fulfilled(response as string));
}
