/**
 * Formats string similar to .net string.Format
 * @param s format string
 * @param params interpolated values
 */
export function stringFormat(s, ...args: any) {
  return s.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
}

/**
 * Removes html tags and leaves only the text
 * @param value html text
 * @returns plain text without tags
 */
export function removeHtml(value: string) {
  return value.replace(/<[^>]+>/g, '');
}

export function tryParseWithoutNaN(parse: (value: string) => number) {
  return (value: string) => {
    const x = parse(value);
    if (isNaN(x)) {
      return undefined;
    } else {
      return x;
    }
  };
}

export function tryParse<TResult>(
  parse: (value: string) => TResult,
): (value: string | null | undefined) => TResult | undefined {
  return (value: string | null | undefined) => {
    if (value === null) {
      return undefined;
    }
    if (value === undefined) {
      return undefined;
    }
    try {
      const x = parse(value);
      return x;
    } catch {
      return undefined;
    }
  };
}

/**
 * Tries to parse string-like value.
 * Usefull to reduce bloat when an integer value needs to be converted to number without resorting to null/undefined checks.
 * @param value string/null/undefined
 * @returns number if it's int-like or undefined otherwise
 */
export function tryParseInt(value: string | null | undefined) {
  return tryParse(tryParseWithoutNaN(parseInt))(value);
}

export function tryParseFloat(value: string | null | undefined) {
  return tryParse(tryParseWithoutNaN(parseFloat))(value);
}
export function tryParsePositiveFloat(value: string | null | undefined) {
  return tryParse(value => {
    const x = parseFloat(value);
    if (x > 0) {
      return x;
    }
  })(value);
}

export function tryParseBool(value: string | null | undefined) {
  return tryParse(value => value.toLowerCase() === 'true')(value);
}
export function isNumericPositive(value: string): boolean {
  let res = /^\d+$/.test(value);
  return res === true;
}
