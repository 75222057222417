import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  styled,
} from '@material-ui/core';
import { FieldHookConfig, useField } from 'formik';
import { Body } from '../Typography';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import { RichTextEditor, RichTextEditorProps } from '../RichTextEditor';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { FormControlProps } from './FormControls/FormControl';

export const FormRichTextField = ({
  onChange,
  onBlur,
  value,
  hasMentions,
  onChangeMentions,
  autoFocus,
  additionalButtons,
  isInvoiceTemplateEditor,
  extendedMode,
  ...props
}: FieldHookConfig<string> &
  IFieldProps &
  Omit<RichTextEditorProps, 'onChange'>) => {
  const [field, meta, helpers] = useField<string>({ ...props });
  const handleChange = (value: string | undefined | null) => {
    // formik is not happy with neither undefined nor null
    const formikValue = value ?? '';
    field.onChange(formikValue);
    helpers.setValue(formikValue, true);
    onChange?.(value);
  };

  return (
    <RichTextField
      className={props.className}
      fullWidth={props.fullWidth !== false}
      disabled={props.disabled}
      variant="outlined"
      label={props.label}
      error={meta.error}
      info={props.info}
      helperText={props.helperText}
      value={field.value}
      onChange={handleChange}
      hasMentions={hasMentions}
      onChangeMentions={onChangeMentions}
      autofocus={autoFocus}
      additionalButtons={additionalButtons}
      isInvoiceTemplateEditor={isInvoiceTemplateEditor}
      extendedMode={extendedMode}
      {...props}
    />
  );
};
export interface RichTextFieldProps
  extends RichTextEditorProps,
    Pick<FormControlProps, 'className' | 'fullWidth' | 'variant'> {
  error?: string;
  label?: string;
  info?: string;

  helperText?: string;
}
export function RichTextField({
  className,
  error,
  fullWidth,
  disabled,
  label,
  info,
  variant = 'outlined',
  helperText,
  ...props
}: RichTextFieldProps) {
  const StyledInfo = styled(InfoIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1),
  }));
  return (
    <>
      <FormControl
        className={className}
        error={Boolean(error)}
        fullWidth={fullWidth !== false}
        disabled={disabled}
        variant={variant}
      >
        {label && (
          <FormLabel error={Boolean(error)}>
            <Box>
              <Body bold={true}>{label}</Body>
              {info && <StyledInfo title={info} />}
            </Box>
          </FormLabel>
        )}

        <RichTextEditor {...props} />
        {error !== undefined ||
          (!!helperText && (
            <FormHelperText error={Boolean(error)}>
              {error || helperText}
            </FormHelperText>
          ))}
      </FormControl>
    </>
  );
}
