import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';
import { connectedFiltersActions as actions } from '.';
import { IConnectedFiltersDto } from 'types/IConnectedFiltersDto';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { ConnectedFiltersSettings, mapSettings } from './types';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { selectConnectedFiltersData } from './selectors';

function* doSetConnectedValue(
  action: PayloadAction<{
    fieldKey: keyof IConnectedFiltersDto;
    fieldValue: any;
  }>,
) {
  yield put(actions.setConnectedValueSuccess(action.payload));
}
function* doUpdateEquipmentSettigs(
  action: PayloadAction<{
    services: IServiceTypeFilterDto[];
    globalSettings: GlobalSettingsType;
    settings: ConnectedFiltersSettings;
    isEdit: boolean;
    data?: IConnectedFiltersDto;
  }>,
) {
  const user: AuthenticatedUser | undefined = yield select(
    selectAuthenticatedUser,
  );
  const currentData: IConnectedFiltersDto = yield select(
    selectConnectedFiltersData,
  );
  let newSettings = mapSettings(
    action.payload.services,
    action.payload.globalSettings,
    action.payload.settings,
    action.payload.isEdit,
    user,
    action.payload.data || currentData,
  );

  yield put(actions.updateSettingsState(newSettings));
}
function* doResetState(action: PayloadAction) {
  yield put(actions.resetConnectedState_success());
}
export function* connectedFiltersSaga() {
  yield takeLatest(actions.setConnectedValue.type, doSetConnectedValue);
  yield takeLatest(actions.extendSettingsState.type, doUpdateEquipmentSettigs);
  yield takeLatest(actions.resetConnectedState.type, doResetState);
}
