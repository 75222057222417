import { PayloadAction } from '@reduxjs/toolkit';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { ITrainingSessionSlotDto } from 'api/odata/generated/entities/ITrainingSessionSlotDto';
import { IWorkingHoursDto } from 'api/odata/generated/entities/IWorkingHoursDto';
import { IEquipmentTimeSlot } from 'api/odata/generated/complexTypes/IEquipmentTimeSlot';
import { TimelineGroupByUnion } from 'api/odata/generated/enums/TimelineGroupBy';
import { Condition } from 'api/odata/ODataFilter';
import { SchedulerDatePositionUnion } from 'app/components/BasicTable/Filters/DatesPeriodFilter';
import { IOptionWithIcon } from 'app/components/Dashboard/TableViewEdit/ViewTypePicker';
import { EmptySlotAdminClick } from 'enums/EmptySlotAdminClick';
import { RestrictDurationUnitTypes } from 'enums/RestrictDurationUnitTypes';
import { Entity } from 'types/common';
import { Timestamp } from 'types/Timestamp';
import { ISchedulerParseEvent } from '../types/ISchedulerParseEvent';

/* --- STATE --- */

export interface SchedulerState {
  viewState?: IViewState;
  previousViewState?: IViewState;
  selectedServices?: Array<ISchedulerService>;
  derivedServices?: Array<ISchedulerService>;
  filters?: ISerializableFilterSettings;
  events: Record<string,Array<ICalendarReservationDto>>;
  offlineHours:Record<string,Array<IWorkingHoursDto>>;
  trainingSessions:Record<string,Array<ITrainingSessionSlotDto>>;
  nonWorkingHours?:Array<IEquipmentTimeSlot>;
  tempReservations?: INewEvent;
  multipleInstrumentsMode?: boolean;
  notUseLocation?:boolean;
  emptySlotAdminClick: EmptySlotAdminClick;
  selectedReservationEvents: Array<IEditEvent>;
  loadingEvents?: boolean;
  TimelineGroupBy: TimelineGroupByUnion;
  exportToPDF: boolean;
}
export type ViewTypes = 'calendar' | 'timeline';
export type ViewMultiToggleType = 'single' | 'multi';
export const ViewLengthsArray = ['day' , 'twodays' , 'customdays', 'week' , 'month', 'quarter', 'year', 'customPeriod' ] as const;
type ViewLengthsTuple = typeof ViewLengthsArray;
export type ViewLengths = ViewLengthsTuple[number];
export const viewLengthOptions: IOptionWithIcon<ViewLengths>[] = [
  { Id: 'day', Name: 'SchedulerDay', shortName: 'SchedulerShortDay'},
  { Id: 'twodays', Name: 'TimelineTwoDays', shortName: 'TimelineShortTwoDays'},
  { Id: 'customdays', Name: 'TimelineCustomDays', shortName: 'TimelineShortCustomDays'},
  { Id: 'week', Name: 'SchedulerWeek', shortName: 'SchedulerShortWeek'},
  { Id: 'month', Name: 'SchedulerMonth', shortName: 'SchedulerShortMonth'},
  { Id: 'quarter', Name: 'TimelinePeriodQuarter', shortName: 'TimelineShortQuarter'},
  { Id: 'year', Name: 'TimelinePeriodYear', shortName: 'TimelineShortYear'},
  { Id: 'customPeriod', Name: 'TimelineCustomDays', shortName: 'TimelineShortCustomDays', Icon: 'cog'},
  
];
export const viewTypeOptions: IOptionWithIcon<ViewTypes>[] =
  [
      {
        Id: 'calendar',
        Name: 'Calendar',
        Icon: 'calendar',
      },
      {
        Id: 'timeline',
        Name: 'Timeline',
        Icon: 'list-timeline',
      },
    ];
export type ISerializableFilterSettings = Record<string, {
  value: any,
  predicate:string | Condition<ICalendarReservationDto>
}>;
export const getViewType = (type: ViewTypes) => {
  return viewTypeOptions.find(f=>f.Id === type);
}
export const getViewLength = (length: ViewLengths) => {
  return viewLengthOptions.find(f=>f.Id === length);
}
// Parsing error: Type expected.
// TODO: upgrade prettier to support Template Literal Types (https://www.typescriptlang.org/docs/handbook/2/template-literal-types.html)
// prettier v2.2.0 + - https://prettier.io/blog/2020/11/20/2.2.0.html#typescript
// eslint-disable-next-line prettier/prettier
export type ViewModesTimeline = `${ViewLengths}_timeline`;
// eslint-disable-next-line prettier/prettier
export type ViewModesUnit = `${ViewLengths}_unit`
export type ViewModes = ViewLengths | ViewModesTimeline | ViewModesUnit;
  

export interface IInstrument extends Entity<number> {
  ServiceGroup: Entity<number>;
  Color: string;
}

export interface IChangeViewModePayload{
  current: IViewState;
  withNoChangeLocation?: boolean;
  forceEvents?: boolean;
}

export interface IEventBase{
  start_date: Timestamp;
  end_date: Timestamp;
  service_id: number;
  service_name: string;
  color?: string|null;
}

export interface IEvent extends IEventBase{
  id: number;
  reservation_id?: number;
  alert_id?:number;
  service_id: number;
  service_name: string;
  user_id: string;
  user_name: string;
}

export interface INonWorkingHours extends IEventBase{
  id: `nonworking_${number}`
}
export interface IOfflineHours extends IEventBase{
  id: `offline_${number}`
  offline: true;
}

export type ISchedulerService = IServiceFilterDto

export interface INewEvent {
  id: string|number;
  start_date: Timestamp;
  end_date: Timestamp;
  service_id?: number | Array<number>;
  alert_type?: number;
  sample_run_id?: number;
  defaultMulti?:boolean;
}
export interface IEditEvent extends ISchedulerParseEvent{
  
}

export type EventType = 'offline' | 'reservation' | 'trainingsession';

export interface IViewState {
  preset: SchedulerDatePositionUnion|null;
  viewType: ViewTypes;
  viewLength: ViewLengths;
  date: Timestamp;
  search:string;
  customDays?: number;
  customStart?: boolean;
  groupBy?: TimelineGroupByUnion
  
}
export interface RestrictDuration {
  durationType: RestrictDurationUnitTypes;
  durationAmount: number;
}

export type ChangeViewModeAction = PayloadAction<IChangeViewModePayload>;
export type GetServicesAction = PayloadAction<Array<ISchedulerService>|undefined>;
export type ChangeServicesAction = PayloadAction<Array<number>>;
export type SetFilterAction = PayloadAction<ISerializableFilterSettings|undefined>;

export interface IOnlineEventDto
{
    StartTime: string;
    EndTime: string;
    EquipmentId: number;
    ServiceGroupId: number;
    AssetRoomId: number|null;
    AssetCampusId: number|null;
    AssetBuildingId: number|null;
    AssetGroupId: number|null;
    AssetCatId: number|null;
    AvailabilityTypeId: number|null;
    BookedById: string;
}
export interface DateSection {
  date: Date;
  section?: number;
}
export interface LeftTop {
  left: number;
  top: number;
}
export type ScrollPosition  = DateSection | LeftTop | Date | number;