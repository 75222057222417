/**
 *
 * Asynchronously loads the component for User Training (Modification) History Page
 *
 */
import { lazyLoad } from 'utils/loadable';

export const UserTrainingHistoryPage = lazyLoad(
  () => import('./index'),
  module => module.UserTrainingHistory,
);
