import i18next, { TFunction } from 'i18next';
import { Entity, INamedEntity } from 'types/common';

const isNumber = (x: string | number): x is number => !isNaN(+x);
const onlyNumbersReducer = (nums: number[], x: string | number): number[] =>
  isNumber(x) ? [...nums, x] : nums;
const onlyStringReducer = (nums: string[], x: string | number): string[] =>
  isNumber(x) ? nums : [...nums, x];

type Descripted<T> = {
  [K in keyof T]: {
    readonly id: T[K];
    readonly description: string;
  };
}[keyof T];

type NonFunctional<T> = T extends Function ? never : T;

/**
 * Helper function to map with a TypeScript enum
 * This returns an array of numbers (e.g. [0,1,2,...])
 * corresponding to the enum, so you can create arrays that the enum can index.
 * @param keys TypeScript enum
 */
export const enumKeys = (keys: unknown): number[] =>
  Object.keys(keys as string[]).reduce(onlyNumbersReducer, []);

export const enumStringKeys = (keys: unknown): string[] =>
  Object.keys(keys as string[]).reduce(onlyStringReducer, []);

/**
 * Helper to produce an array of enum descriptors.
 * @param enumeration Enumeration object.
 * @param separatorRegex Regex that would catch the separator in your enum key.
 */
export function enumToDescriptedArray<T extends {}>(
  enumeration: T,
): Descripted<T>[] {
  return (Object.keys(enumeration) as Array<keyof T>)
    .filter(key => isNaN(Number(key)))
    .filter(
      key =>
        typeof enumeration[key] === 'number' ||
        typeof enumeration[key] === 'string',
    )
    .map(key => ({
      id: enumeration[key],
      description: String(key),
    }));
}

/**
 * TFunction may throw an error if called before i18next has been initialized.
 * There's really good point to try and use localization before it's been localized but it's used this way in a couple of places already
 * @param key localizaiton key
 * @param t TFunction
 * @returns localized value or null
 */
function tryGetName(key: string, t?: TFunction): string {
  try {
    const tFunction = t ?? GetTFunction();
    return tFunction?.(key) ?? key;
  } catch (error) {
    return key;
  }
}
/**
 * Gets TFunction from i18next instance
 * @returns TFunction
 */
const GetTFunction: () => TFunction | undefined = () => {
  const i18n = i18next;
  if (i18n.isInitialized) {
    const defaultNS = 'translation';
    return i18n.getFixedT(
      null,
      i18n.options.ns ?? i18n.options.defaultNS ?? defaultNS,
    );
  }
  return undefined;
};

export function enumToEntityArray<T extends {}>(
  enumeration: T,
  t?: TFunction,
  prefixKey?: string,
): Entity<number>[] {
  return Object.keys(enumeration)
    .filter(key => isNaN(Number(key)))
    .filter(
      key =>
        typeof enumeration[key] === 'number' ||
        typeof enumeration[key] === 'string',
    )
    .map(key => {
      return {
        Id: enumeration[key],
        Name: !!prefixKey
          ? tryGetName(prefixKey + String(key), t)
          : tryGetName(String(key), t),
      };
    });
}
export function GetOptionsFilterFunction<TItem extends INamedEntity>(
  items: Array<TItem>,
  customSearch?: (value: TItem, search: string) => boolean,
) {
  return (searchTerm: string | null) => {
    if (searchTerm !== null && searchTerm !== '' && searchTerm !== undefined) {
      return items.filter(
        f =>
          f.Name.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
          f.Name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          (!!customSearch && customSearch(f, searchTerm)),
      );
    }
    return items;
  };
}

/**
 * Helper to produce an array of enum values.
 * @param enumeration Enumeration object.
 */
export function enumToArray<T extends {}>(
  enumeration: T,
): NonFunctional<T[keyof T]>[] {
  return Object.keys(enumeration)
    .filter(key => isNaN(Number(key)))
    .map(key => enumeration[key])
    .filter(val => typeof val === 'number' || typeof val === 'string');
}

export class EnumHelpers {
  static getNamesAndValues<T extends number>(e: any) {
    return EnumHelpers.getNames(e).map(n => ({ name: n, value: e[n] as T }));
  }

  static getNames(e: any) {
    return EnumHelpers.getObjValues(e).filter(
      v => typeof v === 'string',
    ) as string[];
  }

  static getValues<T extends number>(e: any) {
    return EnumHelpers.getObjValues(e).filter(
      v => typeof v === 'number',
    ) as T[];
  }

  static getSelectList<T extends number, U>(
    e: any,
    stringConverter: (arg: U) => string,
  ) {
    const selectList = new Map<T, string>();
    this.getValues(e).forEach(val =>
      selectList.set(val as T, stringConverter((val as unknown) as U)),
    );
    return selectList;
  }

  static getSelectListAsArray<T extends number, U>(
    e: any,
    stringConverter: (arg: U) => string,
  ) {
    return Array.from(this.getSelectList(e, stringConverter), value => ({
      value: value[0] as T,
      presentation: value[1],
    }));
  }

  private static getObjValues(e: any): (number | string)[] {
    return Object.keys(e).map(k => e[k]);
  }
}
