export const CellPositionToNumber = (value: string, columns: number) => {
  let res = 0;
  if (value !== null) {
    const s = value.match(/[a-zA-Z]+|[0-9]+/g);
    if (s !== null) {
      if (s.length === 1) {
        const c1 = s[0][0].charCodeAt(0) - 64;
        const r1 = s[0][1].charCodeAt(0) - 64;
        res = (r1 - 1) * columns + c1;
      } else {
        const c = parseInt(s[0]);
        const r = parseInt(s[1]);
        if (!isNaN(c) && !isNaN(r)) {
          res = (r - 1) * columns + c;
        } else {
          if (isNaN(c) && !isNaN(r)) {
            const c1 = s[0].charCodeAt(0) - 64;
            res = (r - 1) * columns + c1;
          } else {
            if (!isNaN(c) && isNaN(r)) {
              const r1 = s[1].charCodeAt(0) - 64;
              res = (r1 - 1) * columns + c;
            }
          }
        }
      }
    }
  }
  return res;
};
