import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';
import { AddonTypesUnion } from 'api/odata/generated/enums/AddonTypes';
import { assertExhaustive } from 'utils/assertExhaustive';

export interface showAddonChargeProps {
  ServiceRequestInternalStatus: InternalServiceRequestStatusesUnion;
  Charged?: boolean;
  ServiceRequestAddonTypes: AddonTypesUnion;
  isAdmin: boolean;
}
export function showAddonCharge(props: showAddonChargeProps) {
  if (props.Charged === true) {
    return false;
  }
  if (props.ServiceRequestInternalStatus === 'Completed') {
    return false;
  }

  if (!props.isAdmin) {
    const userEditableStatuses: Array<InternalServiceRequestStatusesUnion> = [
      'Draft',
      'ReturnedToUser',
    ];
    if (!userEditableStatuses.includes(props.ServiceRequestInternalStatus)) {
      return false;
    }
  }

  switch (props.ServiceRequestAddonTypes) {
    case 'Addons':
    case 'Milestones':
      return props.isAdmin;
    case 'UserAddons':
      return true;
    case 'NA':
      return false;
    default:
      assertExhaustive(props.ServiceRequestAddonTypes);
  }
}

export function showManualCharge(props: showAddonChargeProps) {
  if (props.ServiceRequestAddonTypes === 'Milestones') {
    return !props.Charged && props.isAdmin;
  } else {
    return false;
  }
}
