import { CustomFormColumnTypes } from '../../RequestSamplesPage/slice/types';
import { dateUtils } from 'utils/date-utils';
import { Entity } from 'types/common';
import { assertExhaustive } from 'utils/assertExhaustive';

export function getValueRenderer(props: { columnType: CustomFormColumnTypes }) {
  switch (props.columnType) {
    case 'CheckBox':
      return (value: boolean | null) => String(value?.toString());
    case 'Date':
      return (value: Date | null) => String(dateUtils.shortDateFormat(value));
    case 'DropDownList':
    case 'UserSelection':
      return (value: Entity<string> | null) => String(value?.Name);
    case 'ShortText':
      return (value: string | null) => String(value);
    case 'Serial':
      return (value: any) => {
        return String(value);
      };
    case 'Email':
      return (value: string | null) => String(value);
    case 'PositiveInteger':
    case 'Number':
    case 'PositiveDecimal':
      return (value: number | null) => String(value);
    default:
      assertExhaustive(props.columnType);
  }
}

export function getReadonlyCell(props: { columnType: CustomFormColumnTypes }) {
  return ({ value }) =>
    value === null || value === undefined ? '' : getValueRenderer(props)(value);
}
