/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';

import { FrontendLayout } from 'app/Layout/FrontendLayout/Loadable';

import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  jssPreset,
} from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';

import { CreateTheme } from 'styles/theme';
import { SnackbarProvider } from 'notistack';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import DateFnsUtils from '@date-io/date-fns';
import * as dateFnsLocales from 'date-fns/locale';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectAppSettings,
  selectDateFnsLocale,
  selectIsAuthenticated,
  selectLoading,
  selectLoadingUser,
} from 'app/slice/selectors';
import { useAppSettingsSlice } from './slice';
import { useEffect } from 'react';

// import i18n (needs to be bundled ;))
import '../locales/i18n';
import { AnimalTypesPage } from './pages/AnimalTypesPage/Loadable';

import { DialogAlert } from './components/DialogAlert';
import { ErrorBoundary } from './components/ErrorBoundary';
import { SnackbarManager } from './Layout/FrontendLayout/components/Snackbar/Loadable';
import { InternalIdLoginPage } from './pages/Animals/InternalIdLoginPage/Loadable';
import { AnimalsListPage } from './pages/AnimalsListPage/Loadable';
import { PosLayout } from './Layout/PosLayout';
import { AnimalCaseConsumablesPage } from './pages/Animals/AnimalCaseConsumablesPage/Loadable';
import { LoginLayout } from './Layout/LoginLayout';
import { AnimalsHomePage } from './pages/Animals/AnimalsHomePage/Loadable';
import { ConnectedRouter } from 'connected-react-router';

import { PrivateAnimalRoute } from './pages/Animals/PrivateAnimalRoute';
import Grow from '@material-ui/core/Grow';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Box, CssBaseline } from '@material-ui/core';
import { CreateNewCasePage } from './pages/Animals/CreateNewCasePage/Loadable';
import { SamplesListPage } from './pages/Samples/SamplesListPage/Loadable';
import { RunDetailsPage } from './pages/Samples/RunDetailsPage/Loadable';
import {
  RequestDetailsPage,
  RequestSamplesPage,
} from './pages/Samples/RequestSamplesPage/Loadable';
import { TrainingSessionsPage } from './pages/TrainingSessionsPage/Loadable';
import { BuildingsPage } from './pages/Buildings/Loadable';
import { History } from './pages/ReservationHistoryPage/Loadable';
import { AddNewReportPage } from './pages/AddNewReportPage/Loadable';
import { Progress } from './components/LoadingIndicator';
import { TrainingWaitingListPage } from './pages/TrainingWaitingListPage/Loadable';
import { history } from 'store/history';
import { EmailNotificationsPage } from './pages/EmailNotificationsPage/Loadable';
import { ExperimentTemplatesPage } from './pages/ExperimentTemplates/Loadable';
import { UsersHistoryPage } from './pages/UsersHistoryPage/Loadable';
import { UserTrainingPage } from './pages/UserTrainingsPage/Loadable';
import { NotAvailable } from './pages/NotAvailablePage/Loadable';
import { RestrictedRoute } from './components/Routers/RestrictedRoute';
import { Users } from './pages/UsersPage/loadable';
import { AnimalExtendedBillingReportPage } from './pages/Animals/AnimalExtendedBillingReportPage/Loadable';
import { UserTrainingHistoryPage } from './pages/UserTrainingHistoryPage/Loadable';
import { OtherServicesPage } from './pages/OtherServicesPage/Loadable';
import { BudgetsPage } from './pages/BudgetsPage/Loadable';
import { ServiceRequestPage } from './pages/ServiceRequestListPage/Loadable';
import { WorkOrdersPage } from './pages/WorkOrdersPage/Loadable';
import { DocumentsPage } from './pages/Documents/Loadable';
import { QuizPage } from './pages/QuizPage/Loadable';
import { QuizDetailsPage } from './pages/QuizDetailsPage/Loadable';
import { UserTrainingDetailsPage } from './pages/UserTrainings/TrainingDetailsPage/Loadable';
import { SampleModificationsPage } from './pages/Samples/SampleModificationsPage/Loadable';
import { UsageHistory } from './pages/UsageHistoryPage/Loadable';
import { RunsListPage } from './pages/Samples/RunsListPage/Loadable';
import { AnimalCasesHistoryPage } from './pages/Animals/AnimalCaseEditPage/AnimalCasesHistory/Loadable';
import { SendEmailToUsersPage } from './pages/SendEmailToUsers/Loadable';
import { Charges } from './pages/ChargesPage/Loadable';
import { MyAssetsPage } from './pages/MyAssetsPage/Loadable';
import { Locations } from './pages/LocationsPage/loadable';
import { BuildingPage } from './pages/LocationsPage/BuildingPage/loadable';
import { CampusesPage } from './pages/LocationsPage/CampusesPage/loadable';
import { AreasPage } from './pages/LocationsPage/AreasPage/loadable';
import { LocationListPage } from './pages/LocationsPage/LocationListPage/loadable';
import { SubLocationListPage } from './pages/LocationsPage/SubLocationListPage/loadable';
import { InvoiceBatches } from './pages/InvoiceBatches/Loadable';
import { CategoriesPage } from './pages/CategoriesPage/Loadable';
import { CategoryGroupsPage } from './pages/CategoryGroupsPage/Loadable';
import { BillingPreviewPage } from './pages/BillingPreviewPage/Loadable';
import { OnlineBudgetsStatusPage } from './pages/OnlineBudgetsStatusPage';
import { InvoiceDetailsPage } from './pages/InvoiceDetails/Loadable';
import { WorkOrderDetailsPage } from './pages/WorkOrders/WorkOrderDetailsPage/Loadable';
import { Billing } from './pages/InvoiceBillingPage/Loadable';
import { BillingActions } from './pages/BillingActions/BillingActionsPage/Loadable';
import { BillingActionsDetailsPage } from './pages/BillingActions/BillingActionsDetailsPage/Loadable';
import { LocationTypesPage } from './pages/LocationsPage/LocationTypesPage/loadable';
import { SubLocationTypesPage } from './pages/LocationsPage/SubLocationTypesPage/loadable';
import { GetBrowserInfo } from 'utils/mobileDetect';
import { SavedViewDetailsPage } from './pages/SavedViewsPage/SavedViewPage/Loadable';
import { SavedViews } from './pages/SavedViewsPage/SavedViewsPage/Loadable';
import { LabelTypesPage } from './pages/LabelTypesPage/Loadable';
import { AssetDetailsPage } from './pages/AssetDetailsPage/Loadable';
import { LabelPrintDialog } from './components/LabelPrint/LabelPrintDialog/Loadable';
import { KioskProjectsPage } from './pages/KioskProjects/Loadable';
import { KioskProjectConsumablesPage } from './pages/KioskProjects/KioskProjectConsumablesPage/Loadable';
import { PrivateKioskProjectsRoute } from './pages/KioskProjects/PrivateKioskProjectsRoute/Loadable';
import { SessionTimeout } from './components/SessionTimeout';
import { SubmissionsPage } from './pages/QuizSubmissionsPage/Loadable';
import { QuizzesPage } from './pages/QuizzesPage/Loadable';
import { AdHocReportListPage } from './pages/AdHocReportListPage/Loadable';
import { Rooms } from './pages/RoomsPage/Loadable';
import { CalendarMessages } from './pages/CalendarMessages/CalendarMessagesPage/Loadable';
import { CoresDirectoryPage } from './pages/CoresDirectoryPage/Loadable';
import { SamplePlatesPage } from './pages/Samples/SamplePlatesPage/Loadable';
import { HeadTitle } from './Layout/FrontendLayout/components/HeadTitle';
import { InstrumentRestrictionsPage } from './pages/InstrumentRestrictionsPage/Loadable';
import { LabelTypeDetailsPage } from './pages/LabelTypeDetailsPage/Loadable';
import { ConfigurableColumnExportImportPage } from './pages/ConfigurableColumnExportImport/Loadable';
import { AppSettings } from 'types/AppSettings';
import { ServerBarcodeRoute } from './components/Routers/ServerBarcodesRoute';
import { useLayoutSlice } from './Layout/FrontendLayout/slice';
import { NewMessagePage } from './pages/NewMessagePage/Loadable';
import { OtherServiceDetailsPage } from './pages/OtherServiceDetails/OtherServiceDetailsPage/Loadable';
import { InvoiceTemplates } from './pages/InvoiceTemplates/InvoiceTemplatesPage/Loadable';
import { Expenses } from './pages/ExpensesPage/Loadable';
import { PricePeriodsPagePage } from './pages/PriceSheetPeriodsPage/Loadable';
import { PriceSheetPeriodDetailsPage } from './pages/PriceSheetPeriodDetails/PriceSheetPeriodDetailsPage/Loadable';
import { ArticlesPagePath, ArticlesPage } from './pages/ArticlesPage/Loadable';
import {
  ArticlesExternalSearchPagePath,
  ArticlesExternalSearchPage,
} from './pages/ArticlesExternalSearchPage/Loadable';
import { AccountLayout } from './Layout/AccountLayout/Loadable';
import { RegisterPage } from './pages/Account/Register/Loadable';
import { anonimusUrls } from './slice/types';
import {
  CalendarPage,
  CalendarPageDefaultRedir,
} from './pages/CalendarPage/Loadable';
import {
  calendarPageDefaultPath,
  calendarPageFullPath,
  CalendarPagePaths,
} from './pages/CalendarPage';
import { ReservationDetailsPage } from './pages/ReservationDetails/ReservationDetailsPage/Loadable';
import { HomePage } from './pages/HomePage/Loadable';
import { SystemPage } from './pages/SystemPage/Loadable';
import { DashboardPage } from './pages/DashboardPage/Loadable';
import { CoreSelectionPage } from './pages/CoreSelectionPage/Loadable';
import { CoreHomePage } from './pages/CoreHomePage/Loadable';
import { CoreStatsPage } from './pages/CoreStatsPage/Loadable';
import { ReportingPage } from './pages/ReportingPage/Loadable';
import { BookitLabDashboadPage } from './pages/BookitLabDashboardPage/Loadable';
import { RedirectWithSearch } from './components/RedirectWithSearch';
import { AssetSelectionPage } from './pages/MyAssetsPage/AssetsSelection/Loadable';
import { TermsOfUseAgreementPage } from './pages/TermsOfUseAgreementPage/Loadable';
import { SnackWrapper } from './Layout/FrontendLayout/components/Snackbar/SnackWrapper';
import { ActiveUserGroupPage } from './pages/ActiveUserGroupPage/Loadable';
import { CannedReportsPage } from './pages/CannedReports/Loadable';
import { ServiceRequestDetailsPath } from './pages/Samples/RequestSamplesPage/slice/path';
import { UserAttributes } from './pages/UserAttributes/UserAttributesPage/Loadable';
import { ReportConsumablesPage } from './pages/OtherServiceDetails/ReportConsumables/ReportConsumablesPage/Loadable';
import { LoginPage } from './pages/LoginPage/Loadable';
import { CalendarMessagesDetailsPage } from './pages/CalendarMessages/CalendarMessagesDetails/Loadable';
import { ServiceRequestsKanbanPage } from './pages/ServiceRequestsKanbanPage/Loadable';
import { OfflineServiceTypesPage } from './pages/OfflineServiceTypesPage/Loadable';
import { RUN_DETAILS_PAGE_PATH } from './pages/Samples/RunDetailsPage';
import { NOTIFICATIONS_LOG_PAGE_PATH } from './pages/EmailNotificationsPage';
import { AnimalCaseEditPage } from './pages/Animals/AnimalCaseEditPage/Loadable';
import { ServiceRequestsKanbanPagePath } from './pages/ServiceRequestsKanbanPage';
import './AllIcons';
import { PublicAssetsLayout } from './Layout/PublicAssetsLayout';
import { PublicAssetsPage } from './pages/PublicAssets/Loadable';
import { NotAllowedPage } from './pages/NotAllowedPage/Loadable';
import { PlayGroundPage } from './pages/PlayGround/Loadable';
import { SubscriptionTypesPage } from './pages/SubscriptionTypesPage/Loadable';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userLoading = useSelector(selectLoadingUser);
  const isLoading = useSelector(selectLoading);
  const isLoginPage = React.useMemo(() => {
    return window.location.pathname.toLowerCase().indexOf('login') > -1;
  }, []);
  //const [noValidLocation, setNoValidLocation] = React.useState<boolean>(false);
  const useEffectOnMount = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
  };
  const { actions } = useAppSettingsSlice();
  // run the layout slice ahead of time so that it will process global actions like openSidePanel, etc.
  useLayoutSlice();
  useEffectOnMount(() => {
    if (!isLoginPage) {
      dispatch(actions.getAuthenticatedUser());
    } else {
      dispatch(actions.loadLoginSystemSettings());
    }
  });
  React.useEffect(() => {
    if (!isLoginPage) {
      if (!userLoading && isAuthenticated !== undefined) {
        if (isAuthenticated) {
          // if (noValidLocation) {
          //   window.location.href = toRootedURL('/Divisions.aspx');
          // }
          dispatch(actions.loadAppSettings(true));
          dispatch(actions.loadSiteMapSettings());
          dispatch(actions.loadUserProfileSettings());
          dispatch(actions.loadGlobalServiceGroupFilter());
          dispatch(actions.loadSystemSettings());
          dispatch(actions.loadEventsCount());

          GetBrowserInfo();
        } else {
          if (
            anonimusUrls.some(
              f => window.location.pathname.toLowerCase().indexOf(f) > -1,
            )
          ) {
            dispatch(actions.loadAppSettings(false));
          } else {
            //dispatch(actions.navigate('/login'));
          }
        }
      }
    }
  }, [actions, dispatch, isAuthenticated, isLoginPage, userLoading]);
  // React.useEffect(() => {
  //   setNoValidLocation(window.location.pathname.endsWith('index.html'));
  // }, []);

  const dir = i18n.dir();
  const bookitTheme = CreateTheme('ltr', 'bookit');
  const defaultTheme = CreateTheme(dir, 'default');
  const animalTheme = CreateTheme(dir, 'animals');
  const kioskTheme = CreateTheme('ltr', 'animals');

  document.body.dir = dir;
  const dateFnsLocale = useSelector(selectDateFnsLocale);
  const locale = React.useMemo(
    () =>
      dateFnsLocale === undefined ? undefined : dateFnsLocales[dateFnsLocale],
    [dateFnsLocale],
  );
  const appSettings: AppSettings | null = useSelector(selectAppSettings);

  return (
    <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={defaultTheme}>
          <CssBaseline />
          {/* basename is required to properly route addresses when app is served from a non-root location like https://host/application/ */}
          <ConnectedRouter history={history}>
            {isLoading === false &&
              //!noValidLocation &&
              isAuthenticated === true && (
                <ServerBarcodeRoute appSettings={appSettings} />
              )}
            {isLoading === false && <HeadTitle />}
            {isLoading === false &&
            //!noValidLocation &&
            (isAuthenticated !== undefined || isLoginPage) ? (
              <React.Fragment>
                {isAuthenticated === true ? (
                  <Switch>
                    <Route
                      key="route-defaultTheme"
                      exact
                      path={[
                        '/dashboards/home/:name?',
                        '/dashboards/core/:serviceGroupId?',
                        '/dashboards/coreStats',
                        '/dashboards/reporting',
                        '/dashboards',
                        '/systemdash',
                        '/coreselection',
                        '/BookitLabSupportDashboard',
                        ...CalendarPagePaths,
                        '/reservations',
                        '/animaltypes',
                        '/animals',
                        '/samples',
                        ServiceRequestDetailsPath,
                        '/requests/:id/samples',
                        '/requests',
                        RUN_DETAILS_PAGE_PATH,
                        '/samples/plates',
                        '/samples/modifications',
                        '/coresdirectory',
                        '/trainingsessions',
                        '/trainingwaitinglist',
                        NOTIFICATIONS_LOG_PAGE_PATH,
                        '/usertraining',
                        '/usertraining/details/',
                        '/usertraining/details/:id',
                        '/CalendarMessages',
                        '/CalendarMessages/details/',
                        '/CalendarMessages/details/:id',
                        '/Maps/Buildings',
                        '/Maps/Rooms',
                        '/Documents',
                        '/AdHocReports',
                        '/myassets',
                        '/assets/details/:id',
                        '/AddNewReportPage',
                        '/History',
                        '/Users',
                        '/Experiments/experimenttemplates',
                        '/UsersHistory',
                        '/quizzes',
                        '/quizzes/create/:id',
                        '/quizzes/details/:id',
                        '/submissions',
                        '/animals/cases/AnimalExtendedBillingReport',
                        '/notavailable',
                        '/notallowed',
                        '/UserTrainingHistory',
                        '/OtherServices',
                        '/OtherServices/details/',
                        '/OtherServices/details/:id',
                        '/reportConsumables',
                        '/Budgets/BudgetType/:id',
                        '/WorkOrders',
                        '/WorkOrders/details/',
                        '/WorkOrders/details/:id',
                        '/UsageHistory',
                        '/Charges',
                        '/serviceRequests',
                        '/serviceRequests/kanban',
                        // ServiceRequestTicketDetailsPagePath,
                        '/samples/runs',
                        '/animals/cases/AnimalCasesHistory/:id',
                        '/SendEmailToUsers',
                        '/Locations',
                        '/Building',
                        '/Campuses',
                        '/Areas',
                        '/LocationList',
                        '/SubLocationList',
                        '/InvoiceBatches',
                        '/Categories',
                        '/CategoryGroups',
                        '/BillingPreview',
                        '/OnlineBudgetsStatus',
                        '/OnlineBudgetsStatus/:endDate',
                        '/Invoices/Details',
                        '/Invoices/Details/:id',
                        '/Billing',
                        '/BillingActions',
                        '/BillingActions/details/',
                        '/BillingActions/details/:id',
                        '/LocationTypes',
                        '/SubLocationTypes',
                        '/mylists/details:id',
                        '/mylists/details',
                        '/mylists',
                        '/labeltypes',
                        '/labeltypes/details/',
                        '/labeltypes/details/:id',
                        '/instrumentRestrictions',
                        '/NewMessage/:id',
                        '/InvoiceTemplates',
                        '/Expenses',
                        '/ConfigurableColumnExportImport',
                        '/reservation.aspx',
                        '/reservations/details:id',
                        '/reservations/details',
                        '/PricePeriods',
                        '/PricePeriods/details:id',
                        '/PricePeriods/details',
                        '/AssetSearch',
                        '/TermsOfUseAgreement',
                        '/ActiveUserGroup/:shrink',
                        '/CannedReports',
                        ArticlesExternalSearchPagePath,
                        ArticlesPagePath,
                        '/UserAttributes',
                        '/OfflineServiceTypes',
                        // '/Assets',
                        '/Assets/Public',
                        '/PlayGround',
                        '/SubscriptionTypes',
                      ]}
                    >
                      <MuiThemeProvider theme={bookitTheme}>
                        {/* todo: fix the LatoStyle - after initial load, first dialog open results in a glitch: text elements are switched from lato to default and immediately back. this results in elements on the page jumping back and forth  */}
                        {/* <LatoStyle /> */}
                        <FrontendLayout>
                          {/* todo : prepend public url like so to support virtual applications hosted at {process.env.PUBLIC_URL + '/Calendar'} */}
                          <Switch key="switch-bookit-theme">
                            <Route
                              path={'/dashboards/home/:name?)'}
                              component={HomePage}
                            />
                            <Route
                              path={['/dashboards/core/:serviceGroupId?)']}
                              component={CoreHomePage}
                            />
                            <Route
                              path={'/dashboards/coreStats'}
                              component={CoreStatsPage}
                            />
                            <Route
                              path={'/dashboards/reporting'}
                              component={ReportingPage}
                            />
                            <Route
                              path={'/dashboards'}
                              component={DashboardPage}
                            />
                            <Route
                              path={'/coreselection'}
                              component={CoreSelectionPage}
                            />
                            <Route
                              path={'/systemdash'}
                              component={SystemPage}
                            />
                            <Route
                              path={'/BookitLabSupportDashboard'}
                              component={BookitLabDashboadPage}
                            />
                            <Route
                              exact
                              path={calendarPageFullPath}
                              component={CalendarPage}
                            />
                            <Route
                              exact
                              path={calendarPageDefaultPath}
                              component={CalendarPageDefaultRedir}
                            />
                            <Route
                              exact
                              path={'/animaltypes'}
                              component={AnimalTypesPage}
                            />
                            <Route
                              exact
                              path={'/animals'}
                              component={AnimalsListPage}
                            />
                            <Route
                              exact
                              path={'/serviceRequests'}
                              component={ServiceRequestPage}
                            />
                            <Route
                              exact
                              path={ServiceRequestsKanbanPagePath}
                              component={ServiceRequestsKanbanPage}
                            />
                            <Route
                              exact
                              path={'/requests/:id/samples'}
                              component={RequestSamplesPage}
                            />
                            <Route
                              exact
                              path={ServiceRequestDetailsPath}
                              component={RequestSamplesPage}
                            />
                            <Route
                              exact
                              path={'/requests'}
                              component={RequestDetailsPage}
                            />
                            <Route
                              exact
                              path={'/samples'}
                              component={SamplesListPage}
                            />
                            <Route
                              exact
                              path={RUN_DETAILS_PAGE_PATH}
                              component={RunDetailsPage}
                            />
                            <Route
                              exact
                              path="/samples/plates"
                              component={SamplePlatesPage}
                            />

                            <Route
                              exact
                              path={'/samples/runs'}
                              component={RunsListPage}
                            />
                            <Route
                              exact
                              path="/samples/modifications"
                              component={SampleModificationsPage}
                            />
                            <Route
                              exact
                              path={'/coresdirectory'}
                              component={CoresDirectoryPage}
                            />
                            <Route
                              exact
                              path={'/trainingsessions'}
                              component={TrainingSessionsPage}
                            />
                            <Route
                              exact
                              path={'/trainingwaitinglist'}
                              component={TrainingWaitingListPage}
                            />
                            <Route
                              exact
                              path={NOTIFICATIONS_LOG_PAGE_PATH}
                              component={EmailNotificationsPage}
                            />
                            <Route
                              exact
                              path={'/Experiments/experimenttemplates'}
                              component={ExperimentTemplatesPage}
                            />
                            <Route
                              exact
                              path={'/usertraining'}
                              component={UserTrainingPage}
                            />
                            <Route
                              path={'/usertraining/details/:id'}
                              component={UserTrainingDetailsPage}
                            />
                            <Route
                              path={'/usertraining/details/'}
                              component={UserTrainingDetailsPage}
                            />
                            <Route
                              exact
                              path={'/Maps/Buildings'}
                              component={BuildingsPage}
                            />
                            <Route
                              exact
                              path={'/Documents'}
                              component={DocumentsPage}
                            />
                            <Route
                              path={'/CalendarMessages'}
                              component={CalendarMessages}
                            />
                            <Route
                              path={'/CalendarMessages/details/:id'}
                              component={CalendarMessagesDetailsPage}
                            />
                            <Route
                              path={'/CalendarMessages/details'}
                              component={CalendarMessagesDetailsPage}
                            />
                            <Route
                              exact
                              path={'/adhocreportlist'}
                              component={AdHocReportListPage}
                            />
                            <Route
                              exact
                              path={'/Maps/Rooms'}
                              component={Rooms}
                            />
                            <Route
                              exact
                              path={'/AdHocReports'}
                              component={AdHocReportListPage}
                            />
                            <Route
                              exact
                              path={'/myassets'}
                              component={MyAssetsPage}
                            />
                            <Route
                              exact
                              path="/assets/details/:id"
                              component={AssetDetailsPage}
                            />
                            <Route
                              exact
                              path={'/AddNewReportPage'}
                              component={AddNewReportPage}
                            />
                            <Route
                              exact
                              path={'/reservations'}
                              component={History}
                            />
                            <RestrictedRoute
                              exact
                              path={'/Users'}
                              component={Users}
                            />
                            <RestrictedRoute
                              exact
                              path={'/UsersHistory'}
                              component={UsersHistoryPage}
                            />

                            <Route
                              path={'/quizzes/create/:id'}
                              component={QuizPage}
                            />
                            <Route
                              path={'/quizzes/details/:id'}
                              component={QuizDetailsPage}
                            />
                            <Route
                              exact
                              path={'/quizzes'}
                              component={QuizzesPage}
                            />
                            <Route
                              exact
                              path={'/submissions'}
                              component={SubmissionsPage}
                            />
                            <RestrictedRoute
                              exact
                              path={'/UserTrainingHistory'}
                              component={UserTrainingHistoryPage}
                            />
                            <Route
                              exact
                              path={'/OtherServices'}
                              component={OtherServicesPage}
                            />
                            <Route
                              path={'/OtherServices/details/:id'}
                              component={OtherServiceDetailsPage}
                            />
                            <Route
                              path={'/OtherServices/details/'}
                              component={OtherServiceDetailsPage}
                            />
                            <Route
                              path={'/reportConsumables'}
                              component={ReportConsumablesPage}
                            />
                            <Route
                              exact
                              path={'/WorkOrders'}
                              component={WorkOrdersPage}
                            />
                            <Route
                              path={'/WorkOrders/details/:id'}
                              component={WorkOrderDetailsPage}
                            />
                            <Route
                              path={'/WorkOrders/details/'}
                              component={WorkOrderDetailsPage}
                            />
                            <Route
                              exact
                              path={'/UsageHistory'}
                              component={UsageHistory}
                            />
                            <Route
                              exact
                              path={'/Charges'}
                              component={Charges}
                            />
                            <Route
                              path="/OnlineBudgetsStatus/:endDate?"
                              component={OnlineBudgetsStatusPage}
                            />
                            <Route
                              component={NotAvailable}
                              path={'/notavailable'}
                            />
                            <Route
                              component={NotAllowedPage}
                              path={'/notallowed'}
                            />
                            <Route
                              exact
                              path={
                                '/animals/cases/AnimalExtendedBillingReport'
                              }
                              component={AnimalExtendedBillingReportPage}
                            />
                            <Route
                              exact
                              path={'/Budgets/BudgetType/:id'}
                              component={BudgetsPage}
                            />
                            <Route
                              exact
                              path={'/animals/cases/AnimalCasesHistory/:id'}
                              component={AnimalCasesHistoryPage}
                            />

                            <Route
                              exact
                              path={'/SendEmailToUsers'}
                              component={SendEmailToUsersPage}
                            />
                            <RestrictedRoute
                              exact
                              path={'/Locations'}
                              component={Locations}
                            />
                            <Route
                              path={'/Building'}
                              component={BuildingPage}
                            />
                            <Route
                              path={'/Campuses'}
                              component={CampusesPage}
                            />
                            <Route path={'/Areas'} component={AreasPage} />
                            <Route
                              path={'/SubLocationList'}
                              component={SubLocationListPage}
                            />
                            <Route
                              path={'/LocationList'}
                              component={LocationListPage}
                            />
                            <Route
                              path={'/LocationTypes'}
                              component={LocationTypesPage}
                            />
                            <Route
                              path={'/SubLocationTypes'}
                              component={SubLocationTypesPage}
                            />
                            <Route
                              //exact
                              path={'/InvoiceBatches'}
                              component={InvoiceBatches}
                            />
                            <Route
                              path={'/Categories'}
                              component={CategoriesPage}
                            />
                            <Route
                              path={'/CategoryGroups'}
                              component={CategoryGroupsPage}
                            />
                            <Route
                              path={'/BillingPreview'}
                              component={BillingPreviewPage}
                            />
                            <Route
                              path={'/Invoices/Details/:id'}
                              component={InvoiceDetailsPage}
                            />
                            <Route
                              path={'/Invoices/Details/'}
                              component={InvoiceDetailsPage}
                            />
                            <Route path={'/Billing'} component={Billing} />
                            <RestrictedRoute
                              path={'/BillingActions'}
                              component={BillingActions}
                            />
                            <Route
                              path={'/BillingActions/details/:id'}
                              component={BillingActionsDetailsPage}
                            />
                            <Route
                              path={'/BillingActions/details'}
                              component={BillingActionsDetailsPage}
                            />
                            <Route path={'/mylists'} component={SavedViews} />
                            <Route
                              path={'/mylists/details'}
                              component={SavedViewDetailsPage}
                            />
                            <Route
                              path={'/mylists/details/:id'}
                              component={SavedViewDetailsPage}
                            />
                            <Route
                              path={'/labeltypes'}
                              exact
                              component={LabelTypesPage}
                            />
                            <Route
                              path={'/labeltypes/details/:id'}
                              component={LabelTypeDetailsPage}
                            />
                            <Route
                              path={'/labeltypes/details'}
                              component={LabelTypeDetailsPage}
                            />
                            <Route
                              path={'/instrumentRestrictions'}
                              component={InstrumentRestrictionsPage}
                            />
                            <RestrictedRoute
                              path={'/InvoiceTemplates'}
                              component={InvoiceTemplates}
                            />
                            <RestrictedRoute
                              path={'/Expenses'}
                              component={Expenses}
                            />
                            <Route
                              path={'/ConfigurableColumnExportImport'}
                              component={ConfigurableColumnExportImportPage}
                            />
                            <Route
                              path={'/PlayGround'}
                              component={PlayGroundPage}
                            />
                            {/* 
                              direct navigation/aspx links are redirected on the server side,
                              so reservation details should get the /reservation/details here
                              reservation.aspx link might be added by mistake somewhere in react, so these cases are handled here
                            */}
                            <RedirectWithSearch
                              from="/reservation.aspx"
                              to="/reservations/details"
                            />
                            <Route
                              path={'/reservations/details:id'}
                              component={ReservationDetailsPage}
                            />
                            <Route
                              path={'/reservations/details/'}
                              component={ReservationDetailsPage}
                            />
                            <Route
                              path={'/PricePeriods'}
                              component={PricePeriodsPagePage}
                            />
                            <Route
                              path={'/PricePeriods/details:id'}
                              component={PriceSheetPeriodDetailsPage}
                            />
                            <Route
                              path={'/PricePeriods/details'}
                              component={PriceSheetPeriodDetailsPage}
                            />
                            <Route
                              path={ArticlesExternalSearchPagePath}
                              component={ArticlesExternalSearchPage}
                            />
                            <Route
                              path={'/AssetSearch'}
                              component={AssetSelectionPage}
                            />
                            <Route
                              path={ArticlesPagePath}
                              component={ArticlesPage}
                            />
                            <Route
                              path={'/TermsOfUseAgreement'}
                              component={TermsOfUseAgreementPage}
                            />
                            <Route
                              path={'/ActiveUserGroup/:shrink'}
                              component={ActiveUserGroupPage}
                            />
                            <Route
                              path={'/CannedReports'}
                              component={CannedReportsPage}
                            />
                            <Route
                              path={'/UserAttributes'}
                              component={UserAttributes}
                            />
                            {/* <Route
                              path={'/Assets'}
                              component={PublicAssetsPage}
                            /> */}
                            <Route
                              path={'/Assets/Public'}
                              component={PublicAssetsPage}
                            />
                            <RestrictedRoute
                              path={'/OfflineServiceTypes'}
                              component={OfflineServiceTypesPage}
                            />
                            <Route
                              path={'/SubscriptionTypes'}
                              component={SubscriptionTypesPage}
                            />
                            <Route component={NotFoundPage} />
                          </Switch>
                        </FrontendLayout>
                      </MuiThemeProvider>
                    </Route>

                    <Route
                      key="route-postLogin"
                      path={['/TermsOfUseAgreement/:exp', '/ActiveUserGroup']}
                    >
                      <MuiThemeProvider theme={bookitTheme}>
                        <GlobalStyle />
                        <PosLayout>
                          <Route
                            path={'/TermsOfUseAgreement/:exp'}
                            component={TermsOfUseAgreementPage}
                          />
                          <Route
                            path={'/ActiveUserGroup'}
                            component={ActiveUserGroupPage}
                          />
                        </PosLayout>
                      </MuiThemeProvider>
                    </Route>

                    <Route exact path={'/InternalIdLogin'}>
                      <MuiThemeProvider theme={animalTheme}>
                        <GlobalStyle />
                        <LoginLayout>
                          <Route
                            exact
                            path={'/InternalIdLogin'}
                            component={InternalIdLoginPage}
                          />
                        </LoginLayout>
                      </MuiThemeProvider>
                    </Route>

                    <PrivateAnimalRoute
                      exact
                      path={[
                        '/animals/home',
                        '/animals/cases/create',
                        '/animals/cases/details/:id',
                        '/animals/cases/consumables/:id',
                      ]}
                    >
                      <MuiThemeProvider theme={animalTheme}>
                        <PosLayout>
                          <Route
                            exact
                            path={'/animals/cases/create'}
                            component={CreateNewCasePage}
                          />
                          <Route
                            exact
                            path={'/animals/home'}
                            component={AnimalsHomePage}
                          />

                          <Route
                            exact
                            path={'/animals/cases/consumables/:id'}
                            component={AnimalCaseConsumablesPage}
                          />
                          <Route
                            exact
                            path={'/animals/cases/details/:id'}
                            component={AnimalCaseEditPage}
                          />
                          <Route
                            path={'/NewMessage/:id'}
                            component={NewMessagePage}
                          />
                        </PosLayout>
                      </MuiThemeProvider>
                    </PrivateAnimalRoute>
                    <PrivateKioskProjectsRoute
                      exact
                      path={[
                        '/kiosk/projects',
                        '/kiosk/projects/consumables',
                        '/kiosk/projects/consumables/:id',
                      ]}
                    >
                      <MuiThemeProvider theme={kioskTheme}>
                        <PosLayout>
                          <Route
                            exact
                            path={'/kiosk/projects'}
                            component={KioskProjectsPage}
                          />
                          <Route
                            exact
                            path={'/kiosk/projects/consumables'}
                            component={KioskProjectConsumablesPage}
                          />
                          <Route
                            exact
                            path={'/kiosk/projects/consumables/:id'}
                            component={KioskProjectConsumablesPage}
                          />
                        </PosLayout>
                      </MuiThemeProvider>
                    </PrivateKioskProjectsRoute>
                    <Route exact path={['/RegisterAccount', '/Login']}>
                      <MuiThemeProvider theme={bookitTheme}>
                        <AccountLayout>
                          <Route
                            exact
                            path={'/RegisterAccount'}
                            component={RegisterPage}
                          />
                          <Route exact path={'/login'} component={LoginPage} />
                        </AccountLayout>
                      </MuiThemeProvider>
                    </Route>
                    {/* <Route exact path={'/Login'}>
                      <MuiThemeProvider theme={bookitTheme}>
                        <AccountLayout>
                          <Route exact path={'/login'} component={LoginPage} />
                        </AccountLayout>
                      </MuiThemeProvider>
                    </Route> */}

                    <Route component={NotFoundPage} />
                    <Route component={NotAvailable} path={'/notavailable'} />
                    <Route component={NotAllowedPage} path={'/notallowed'} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path={['/RegisterAccount', '/Login']}>
                      <MuiThemeProvider theme={bookitTheme}>
                        <AccountLayout>
                          <Route
                            exact
                            path={'/RegisterAccount'}
                            component={RegisterPage}
                          />
                          <Route exact path={'/login'} component={LoginPage} />
                        </AccountLayout>
                      </MuiThemeProvider>
                    </Route>
                    <Route exact path={['/Assets/Public']}>
                      <MuiThemeProvider theme={bookitTheme}>
                        <PublicAssetsLayout>
                          {/* <Route
                            exact
                            path={'/Assets'}
                            component={PublicAssetsPage}
                          /> */}
                          <Route
                            exact
                            path={'/Assets/Public'}
                            component={PublicAssetsPage}
                          />
                        </PublicAssetsLayout>
                      </MuiThemeProvider>
                    </Route>
                  </Switch>
                )}
              </React.Fragment>
            ) : (
              <Box component="div">
                <Progress inProgress={isLoading} size={80} />
              </Box>
            )}
            {/* <GlobalStyle /> */}
          </ConnectedRouter>
          <MuiThemeProvider theme={bookitTheme}>
            {isLoading === false &&
              //!noValidLocation &&
              isAuthenticated === true && <SessionTimeout />}

            <ErrorBoundary>
              <DialogAlert />
            </ErrorBoundary>
            <ErrorBoundary>
              <SnackWrapper>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  TransitionComponent={
                    Grow as React.ComponentType<TransitionProps>
                  }
                >
                  <SnackbarManager />
                </SnackbarProvider>
              </SnackWrapper>
            </ErrorBoundary>
            {isLoading === false &&
              //!noValidLocation &&
              isAuthenticated === true && (
                <ErrorBoundary>
                  <LabelPrintDialog />
                </ErrorBoundary>
              )}
          </MuiThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </MuiPickersUtilsProvider>
  );
}
