export enum AssetAttributes {
  NotActive,
  AssetAccessories,
  AssetCat,
  AssetCatGroup,
  AccountingId,
  NickName,
  OtherNames,
  Type2Counter,
  Brand,
  Model,
  ModelID,
  ModelNumber,
  Building,
  Room,
  Experts,
  Contacts,
  Hide,
  Public,
  Raac,
  MainSpecRange,
  FunctionalCharacteristics,
  ElectricalSpecs,
  WebLink,
  Tags,
  Suppliers,
  BrandCode,
  InternalCode,
  POnumber,
  Campus,
  LifeDate,
  ExtraText0,
  ExtraText1,
  ExtraText2,
  ExtraText3,
  ExtraText4,
  ExtraText5,
  ExtraText6,
  ExtraText7,
  ExtraText8,
  ExtraText9,
  ExtraText10,
  ExtraText11,
  ExtraText12,
  ExtraText13,
  ExtraText14,
  ExtraText15,
  ExtraInt0,
  ExtraInt1,
  ExtraInt2,
  ExtraInt3,
  ExtraInt4,
  ExtraDec0,
  ExtraDec1,
  ExtraDec2,
  ExtraDec3,
  ExtraDec4,
  ExtraBool0,
  ExtraBool1,
  ExtraBool2,
  ExtraBool3,
  ExtraBool4,
  ExtraUrl0,
  ExtraUrl1,
  ExtraUrl2,
  ExtraUrl3,
  ExtraUrl4,
  ExtraRichText0,
  ExtraRichText1,
  ExtraRichText2,
  ExtraRichText3,
  ExtraRichText4,
  ExtraDDL0,
  ExtraDDL1,
  ExtraDDL2,
  ExtraDDL3,
  ExtraDDL4,
  ExtraDDL5,
  ExtraDDL6,
  ExtraDDL7,
  ExtraDDL8,
  ExtraDDL9,
  ExtraDDL10,
  ExtraDDL11,
  ExtraDDL12,
  ExtraDDL13,
  ExtraDDL14,
  ExtraDDL15,
  ExtraDate0,
  ExtraDate1,
  ExtraDate2,
  ExtraDate3,
  ExtraDate4,
  ExtraDate5,
  ExtraDate6,
  ExtraDate7,
  ExtraDate8,
  ExtraDate9,
  UnderOriginalWarranty,
  WarrantyEndDate,
  ServiceContract,
  GasSource,
  ImageName,
  Description,
  ShortDescription,
  // not present on asset search results
  LocationsList,
  // not present on asset search results
  Range,
  ExportLicense,
  ForceTutoring,
  SubLocationsList,
}
