import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { serviceRequestsKanbanSaga } from './saga';
import {
  Dto,
  IDataList,
  ServiceRequestsKanbanState,
  ReorderPayload,
} from './types';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import { isEqual, orderBy } from 'lodash';
import { IServiceRequestTicketDto } from 'api/odata/generated/entities/IServiceRequestTicketDto';
import { PartialExceptId } from 'utils/typeUtils';

export const initialState: ServiceRequestsKanbanState = {
  data: {},
  selectedStatuses: [],
  selectedStatusIds: [],
  fetchCount: 2,
  processingUpdate: false,
  processingInsert: false,
  ticket: {},
};

const slice = createSlice({
  name: 'serviceRequestsKanban',
  initialState,
  reducers: {
    setSelectedStatuses(
      state,
      action: PayloadAction<IServiceRequestStatusDto[] | undefined>,
    ) {
      const ids = orderBy(action.payload?.map(f => f.Id));
      const p = orderBy(state.selectedStatuses.map(f => f.Id));
      if (!isEqual(ids, p)) {
        state.selectedStatusIds = ids;
      }
      state.selectedStatuses = action.payload ?? [];
    },
    getItems(state, action: PayloadAction<any>) {},

    getItems_Success(state, action: PayloadAction<Array<IDataList<Dto>>>) {
      action.payload.forEach(item => {
        state.data[item.id.toString()] = item;
      });
    },
    getItems_Error(state, action: PayloadAction<any>) {
      // state.processing = false;
      // state.error = 'ERROR';
    },
    getMoreItems(state, action: PayloadAction<number>) {},
    getMoreItems_Success(state, action: PayloadAction<IDataList<Dto>>) {
      const section = state.data[action.payload.id.toString()];
      section.items.push(...action.payload.items);
      section.more = action.payload.more;
    },
    reorder(state, action: PayloadAction<ReorderPayload>) {
      const sourceSection =
        state.data[action.payload.sourceSectionId.toString()];
      if (sourceSection === undefined) {
        return;
      }
      const destinationSection =
        state.data[action.payload.destinationSectionId.toString()];
      if (destinationSection === undefined) {
        return;
      }
      const [removed] = sourceSection?.items?.splice(
        action.payload.sourceIndex,
        1,
      );

      destinationSection?.items?.splice(
        action.payload.destinationIndex,
        0,
        removed,
      );
    },
    reorder_Success(
      state,
      action: PayloadAction<
        Array<Pick<Dto, 'Id'> & Partial<Pick<Dto, 'Status' | 'Index'>>>
      >,
    ) {
      action.payload.forEach(item => {
        Object.values(state.data).forEach(section => {
          const { Id, ...props } = item;
          const ticket = section.items.find(f => f.Id === Id);
          if (ticket !== undefined) {
            if (props.Index !== undefined) ticket.Index = props.Index;
            if (props.Status !== undefined) ticket.Status = props.Status;
          }
        });
      });
    },
    //todo reuse update & updateError function bodies
    reorder_Error(state, action: PayloadAction<ReorderPayload>) {
      const sourceSection =
        state.data[action.payload.sourceSectionId.toString()];
      if (sourceSection === undefined) {
        return;
      }
      const destinationSection =
        state.data[action.payload.destinationSectionId.toString()];
      if (destinationSection === undefined) {
        return;
      }
      const [removed] = sourceSection?.items?.splice(
        action.payload.sourceIndex,
        1,
      );

      destinationSection?.items?.splice(
        action.payload.destinationIndex,
        0,
        removed,
      );
    },

    showAddSubTicket(state, action: PayloadAction<Partial<Dto> | undefined>) {
      state.showAddSubTicket = action.payload !== undefined;
      state.parentTicket = action.payload;
    },
    showEdit(state, action: PayloadAction<Dto>) {
      state.ticket.status = 'pending';
    },
    showEditParent(state, action: PayloadAction<Dto>) {
      state.ticket.status = 'pending';
    },
    showEdit_Success(state, action: PayloadAction<Dto>) {
      state.ticket.status = 'resolved';
      state.ticket.value = action.payload;
    },
    showEdit_Error(state, action: PayloadAction) {
      state.ticket.status = 'rejected';
    },
    insert(state, action: PayloadAction<Partial<IServiceRequestTicketDto>>) {
      state.processingInsert = true;
    },
    insert_Success(state, action: PayloadAction<IServiceRequestTicketDto>) {
      // subtract number of samples from the samples count on the main ticket - for sub tickets only
      updateSamplesCount(action.payload, state);

      // insert newly created ticket into corresponding section based on the status
      // and push the index on the tickets that come after the newly inserted ticket
      const statusId = action.payload.Status?.Id;
      if (statusId !== undefined) {
        const index = action.payload.Index;
        if (index !== undefined) {
          const section = state.data[statusId.toString()];
          const items = section.items;
          if (items !== undefined) {
            items.slice(index).forEach(ticket => (ticket.Index += 1));
            items.splice(index, 0, action.payload);
          }
        }
      }
      state.parentTicket = undefined;
      state.showAddSubTicket = false;
      state.processingInsert = false;
    },
    insert_Error(state, action: PayloadAction) {
      state.processingInsert = false;
    },

    update(
      state,
      action: PayloadAction<PartialExceptId<IServiceRequestTicketDto>>,
    ) {
      state.processingUpdate = true;
    },
    update_Success(
      state,
      action: PayloadAction<PartialExceptId<IServiceRequestTicketDto>>,
    ) {
      Object.keys(state.data).forEach(key => {
        const item = Object.values(state.data)
          .flatMap(section => section.items)
          .find(f => f.Id === action.payload.Id);
        if (item !== undefined) {
          //item = {...item, ...action.payload};
          Object.assign(item, action.payload);
        }
      });
      updateSamplesCount(
        action.payload,
        state,
        state.ticket.value?.SamplesCount ?? undefined,
      );
      state.processingUpdate = false;
    },
    update_Error(state, action: PayloadAction) {
      state.processingUpdate = false;
    },
  },
});

export const { actions: serviceRequestsKanbanActions } = slice;

export const useServiceRequestsKanbanSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: serviceRequestsKanbanSaga });
  return { actions: slice.actions };
};

function updateSamplesCount(
  payload: Partial<IServiceRequestTicketDto>,

  state,
  originalSamplesCount?: number,
) {
  if (payload.SamplesCount !== undefined) {
    if (payload.ParentTicket !== undefined) {
      const parent = state.data[
        payload.ParentTicket.Status.Id.toString()
      ].items.find(f => f.Id === payload.ParentTicket?.Id);
      if (parent !== undefined) {
        parent.SamplesCount -=
          (payload.SamplesCount ?? 0) - (originalSamplesCount ?? 0);
      }
    }
  }
}
/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useServiceRequestsKanbanSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
