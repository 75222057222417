/**
 *
 * FormUserPicker
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { UserPicker, UserUrl } from '../pickers/AutocompletePickers/UserPicker';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';

export const FormUserPicker = ({
  label,
  ...props
}: FieldHookConfig<IUserFilterDto | null> & {
  selectArray?: Array<keyof IUserFilterDto>;
  urlType?: UserUrl;
} & IFieldProps) => {
  const [field, meta, helpers] = useField<IUserFilterDto | null>(props);
  return (
    <>
      <UserPicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onBlur={field?.onBlur}
        value={field.value}
        variant="filled"
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        info={props.info}
        disabled={props.disabled}
        predicates={props.predicates}
        selectArray={props.selectArray}
        urlType={props.urlType}
      />
    </>
  );
};
