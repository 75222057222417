import { dateUtils } from 'utils/date-utils';

/**
 * Shared funciton used in scheduler to format time labels
 * @param d date
 * @returns string representation of the time part
 */
export const formatSchedulerTime = (d: Date) => dateUtils.format(d, 'p');

/**
 * Shared funciton used in scheduler to format time labels
 * @param d date
 * @returns string representation of the time part
 */
export const formatSchedulerDateTime = (d: Date) => dateUtils.format(d, 'Pp');
