import { ILabelTypeDto } from 'api/odata/generated/entities/ILabelTypeDto';
import Handlebars from 'handlebars';
import { ILabelPrinter } from '.';
import { LabelPrinter } from '../slice/types';

Handlebars.registerHelper('padStart', (context, maxLength, fillString) => {
  return String(context).padStart(maxLength, fillString);
});
Handlebars.registerHelper('padEnd', (context, maxLength, fillString) => {
  return String(context).padEnd(maxLength, fillString);
});

export const zebra: ILabelPrinter = {
  getPrinters: () => {
    return new Promise((resolve, reject) => {
      globalThis.BrowserPrint.getLocalDevices(
        device_list => {
          resolve(
            device_list.printer.map(p => {
              var r: LabelPrinter = {
                Id: p.uid,
                Type: 'Zebra',
                Name: `Zebra - ${p.name}`,
                Device: p,
              };
              return r;
            }),
          );
        },
        reason => reject(reason),
      );
    });
  },
  print: (printer, labelType, data, options) => {
    const t = Handlebars.compile(labelType.Xml);
    const labels = data.map(item => t(item));
    const zebra = new globalThis.Zebra.Printer(printer.Device);
    globalThis.Zebra.stopWatching(printer.Device);
    return Promise.all(
      labels.map(
        item =>
          new Promise((resolve, reject) => {
            console.debug('Zebra Printer - printing', item, zebra);

            zebra.send(
              item,
              function (success) {
                resolve(success);
              },
              function (error) {
                console.error('Error:' + error);
                reject(error);
              },
            );
          }),
      ),
    );
  },
  preview: function (
    printer: LabelPrinter,
    labelType: ILabelTypeDto,
    data: any[],
    options: {
      AssetBarcodeUrlTemplate: string;
      RoomBarcodeUrlTemplate: string;
      LocationBarcodeUrlTemplate: string;
      SubLocationBarcodeUrlTemplate: string;
      ConsumableTypeBarcodeUrlTemplate: string;
    },
  ): Promise<any> {
    throw new Error('Function not implemented.');
  },
  download: function (
    printer: LabelPrinter,
    labelType: ILabelTypeDto,
    data: any[],
    options: {
      RoomBarcodeUrlTemplate: string;
      AssetBarcodeUrlTemplate: string;
      LocationBarcodeUrlTemplate: string;
      SubLocationBarcodeUrlTemplate: string;
      ConsumableTypeBarcodeUrlTemplate: string;
    },
  ): Promise<any> {
    throw new Error('Function not implemented.');
  },
};
