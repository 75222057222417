import { PayloadAction } from '@reduxjs/toolkit';
import { IInvoiceBudgetBalanceDto } from 'api/odata/generated/entities/IInvoiceBudgetBalanceDto';
import { IResponseType } from 'types/ResponseType';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { onlineBudgetsStatusSaga } from './saga';
import { IPayActionPayload, OnlineBudgetsStatusState } from './types';

export const initialState: OnlineBudgetsStatusState = {
  endDate: null,
  needTableRefresh: false,
  tableState: {
    processing: false,
  },
  selected: [],
  generateInvoicesState: {
    processing: false,
  },
  charge: {
    processing: false,
  },
  pay: {},
  generateInvoicesAndSendToSap: {},
};

const slice = createSlice({
  name: 'onlineBudgetsStatus',
  initialState,
  reducers: {
    setEndDate(state, action: PayloadAction<string | null>) {
      state.endDate = action.payload;
    },
    setneedTableRefresh(state, action: PayloadAction<boolean>) {
      state.needTableRefresh = action.payload;
    },
    getTableData(state, action: PayloadAction<{ endDate: Date }>) {
      state.tableState.processing = true;
      state.tableState.error = undefined;
    },
    getTableData_Success(
      state,
      action: PayloadAction<Array<IInvoiceBudgetBalanceDto>>,
    ) {
      state.tableState.processing = false;
      state.tableState.error = undefined;
      state.tableState.data = action.payload;
    },
    getTableData_Error(state, action: PayloadAction<any>) {
      state.tableState.processing = false;
      state.tableState.error = 'ERROR';
    },

    setSelected(state, action: PayloadAction<Array<IInvoiceBudgetBalanceDto>>) {
      state.selected = action.payload;
    },

    generateInvoices(
      state,
      action: PayloadAction<{
        endDate: Date;
      }>,
    ) {
      state.generateInvoicesState.processing = true;
    },
    generateInvoices_Success(state, action: PayloadAction) {
      state.generateInvoicesState.processing = false;
    },
    generateInvoices_Error(state, action: PayloadAction) {
      state.generateInvoicesState.processing = false;
    },

    chargeOnlineBudgets(
      state,
      action: PayloadAction<IInvoiceBudgetBalanceDto[]>,
    ) {
      state.charge = {
        data: action.payload,
        processing: true,
        dialogOpen: true,
      };
    },

    chargeOnlineBudgets_SetCurrentRecord(
      state,
      action: PayloadAction<IInvoiceBudgetBalanceDto | undefined>,
    ) {
      state.charge.currentRecord = action.payload;
    },
    chargeOnlineBudgets_SetStatus(state, action: PayloadAction<number>) {
      state.charge.progress = action.payload;
    },
    chargeOnlineBudgets_SetDialogOpen(state, action: PayloadAction<boolean>) {
      state.charge.dialogOpen = action.payload;
    },

    chargeOnlineBudgets_Success(
      state,
      action: PayloadAction<IInvoiceBudgetBalanceDto[]>,
    ) {
      state.charge.processing = false;
    },
    chargeOnlineBudgets_Error(
      state,
      action: PayloadAction<string[] | undefined>,
    ) {
      state.charge.processing = false;
      state.charge.errors = action.payload;
    },
    chargeOnlineBudgets_DownloadErrors(
      state,
      action: PayloadAction<string[] | undefined>,
    ) {},

    pay(state, action: PayloadAction<IPayActionPayload>) {
      state.pay.data = action.payload;
      state.pay.dialogOpen = true;
      state.pay.status = 'pending';
    },
    pay_setStatus(state, action: PayloadAction<number>) {
      state.pay.progress = action.payload;
    },
    pay_Open(state, action: PayloadAction<IInvoiceBudgetBalanceDto[]>) {
      state.pay = {
        dialogOpen: true,
        selected: action.payload,
      };
    },
    pay_SetSelected(state, action: PayloadAction<IInvoiceBudgetBalanceDto[]>) {
      state.pay.selected = action.payload;
    },

    pay_Finish(state, action: PayloadAction<IResponseType[]>) {
      state.pay.status = 'resolved';
      state.pay.messages = action.payload;
      // action.payload.reduce((acc, val) => ({
      //   SuccessMessages: acc.SuccessMessages.concat(val.SuccessMessages),
      //   ErrorMessages: acc.ErrorMessages.concat(val.ErrorMessages),
      //   WarningMessages: acc.WarningMessages.concat(val.WarningMessages),
      // }));
    },
    pay_Close(state, action: PayloadAction) {
      state.pay = {
        dialogOpen: false,
      };
    },

    GenerateInvoicesAndChargeOnlineBudgets(
      state,
      action: PayloadAction<{
        endDate: Date;
        selected: IInvoiceBudgetBalanceDto[];
        chargeOnlineBudgets: boolean;
      }>,
    ) {
      state.generateInvoicesAndSendToSap = {
        dialogOpen: true,
        progress: 0,
        processing: true,
        selected: action.payload.selected,
        endDate: action.payload.endDate,
      };
    },
    GenerateSelectedInvoices_Success(state, action: PayloadAction) {
      state.generateInvoicesAndSendToSap = {};
      state.charge = {};
    },
    GenerateSelectedInvoices_Error(state, action: PayloadAction) {
      state.generateInvoicesAndSendToSap = {};
      state.charge = {};
    },
    GenerateInvoicesAndChargeOnlineBudgets_SetCurrentRecord(
      state,
      action: PayloadAction<IInvoiceBudgetBalanceDto>,
    ) {
      state.generateInvoicesAndSendToSap.currentRecord = action.payload;
    },
    GenerateInvoicesAndChargeOnlineBudgets_SetProgress(
      state,
      action: PayloadAction<number>,
    ) {
      state.generateInvoicesAndSendToSap.progress = action.payload;
    },
    GenerateSelectedInvoices_SetDialogOpen(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.generateInvoicesAndSendToSap.dialogOpen = action.payload;
    },
  },
});

export const { actions: onlineBudgetsStatusActions } = slice;

export const useOnlineBudgetsStatusSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: onlineBudgetsStatusSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useOnlineBudgetsStatusSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.getTableData());
 *   };
 * }
 */
