import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import en from './en/GRes.json';
import { convertLanguageJsonToObject } from './translations';

export const translationsJson = {
  en: {
    translation: en,
  },
};

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en);

// https://github.com/i18next/i18next-http-backend
const backendOptions = {
  // path where resources get loaded from, or a function
  // '/api/locales/{{lng}}/{{ns}}.json',
  // returning a path:
  // function(lngs, namespaces) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  //
  // If allowMultiLoading is false, lngs and namespaces will have only one element each,
  // If allowMultiLoading is true, lngs and namespaces can have multiple elements
  loadPath: function (lngs, namespaces) {
    console.log('i18next-httpBackend', lngs, namespaces);
    return process.env.PUBLIC_URL + '/api/locales?lng={{lng}}&ns={{ns}}';
  },
};

export const i18n = i18next
  // use i18next-http-backend to fetch localization files from the server
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // The whole Animals Kiosk must be localized to he
    // TODO: use the system/app setting to set initial locale here
    lng: 'en',
    fallbackLng: 'en',
    // defaultNS: 'GRes',
    // fallbackNS: 'GRes',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    // defaultNS: 'tran',
    appendNamespaceToMissingKey: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: backendOptions,
  });
