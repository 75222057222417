/**
 *
 * FormAccompaniedServicesPicker
 *
 */
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { IEquipmentAccServiceDto } from 'api/odata/generated/entities/IAccServiceDto';
import {
  AccompaniedServicesPicker,
  SingleAccompaniedServicesPicker,
} from '../pickers/MultiSelectPickers/AccompaniedServicesPicker';

export const FormAccompaniedServicesPicker = ({
  label,
  ...props
}: FieldHookConfig<IEquipmentAccServiceDto[] | null> &
  IFieldProps & { multiple: boolean }) => {
  const [field, meta, helpers] = useField<IEquipmentAccServiceDto[] | null>(
    props,
  );
  if (props.multiple) {
    return (
      <AccompaniedServicesPicker
        predicates={props.predicates}
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onChipClick={props.onChipClick}
        onBlur={field?.onBlur}
        value={field.value ?? undefined}
        error={meta?.error !== undefined}
        variant="filled"
        disabled={props.disabled}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        info={props.info}
      />
    );
  } else {
    return (
      <SingleAccompaniedServicesPicker
        predicates={props.predicates}
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onChipClick={props.onChipClick}
        onBlur={field?.onBlur}
        value={field.value ?? undefined}
        error={meta?.error !== undefined}
        variant="filled"
        disabled={props.disabled}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        info={props.info}
      />
    );
  }
};
