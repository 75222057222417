import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { uploadStyle } from './styles';
import * as React from 'react';
import { lookup } from 'mime-types';
import { isEmptyOrWhitespace } from 'utils/typeUtils';
import { openExportLink, toRootedURL } from 'utils/url-utils';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import cliTruncate from 'cli-truncate';
import { Body } from 'app/components/Typography';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import prettyBytes from 'pretty-bytes';
import { useSelector } from 'react-redux';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';

export interface FileViewProps {
  file: IFormFileValue;
  onRemove: (value: any) => void;
}

export function FileView(props: FileViewProps) {
  const { file, onRemove } = props;
  const { t } = useTranslation();

  const fileName =
    file.DisplayValue !== null && file.DisplayValue !== undefined
      ? file.DisplayValue
      : '';
  const fileSize =
    file.Size == null
      ? null
      : prettyBytes(file.Size, { maximumFractionDigits: 2 });
  const mimeType = lookup(fileName);
  const fileUrl =
    file.PostedFile === null || file.PostedFile === undefined
      ? toRootedURL('/file.ashx?id=' + file.Value)
      : file.PostedFile.webkitRelativePath;
  const previewImg =
    !isEmptyOrWhitespace(file.DisplayValue) &&
    mimeType !== false &&
    mimeType.startsWith('image/');
  const downloadClick = async () => {
    if (file.PostedFile === null || file.PostedFile === undefined) {
      await openExportLink(fileUrl);
    }
  };
  // const fileDownloadClick = async () => {
  //   fileDownload(res.data, filename)
  // };

  const classes = uploadStyle();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  return (
    <React.Fragment>
      <div className={classes.previewContainer}>
        <div className={classes.previewIconAndText}>
          {file.Value !== null ? (
            <Tooltip title={t(translations.Download)}>
              <div
                className={classes.previewIcon}
                onClick={() => downloadClick()}
              >
                {previewImg ? (
                  <img alt={fileName} src={fileUrl} />
                ) : (
                  <Icon icon="link" />
                )}
              </div>
            </Tooltip>
          ) : (
            <div className={classes.previewIcon}>
              <Icon icon="link" />
            </div>
          )}
          <div className={classes.previewText}>
            <Body bold={true} size="small">
              {cliTruncate(fileName, sidePanelExpanded ? 40 : 25, {
                position: 'middle',
              })}
            </Body>

            {fileSize && (
              <Body size="small" className={classes.previewTextSize}>
                {fileSize}
              </Body>
            )}
          </div>
        </div>
        <div className={classes.previewCloseButton}>
          <IconButton
            variant="ghost"
            size="xs"
            shape="square"
            onClick={() => onRemove(fileName)}
          >
            <Icon icon="times" className={classes.previewCloseButtonIcon} />
          </IconButton>
        </div>
      </div>
    </React.Fragment>
  );
}
