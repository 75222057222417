import { useTranslation } from 'react-i18next';
import { dateUtils, WeekStartsOn } from 'utils/date-utils';
import { enumToEntityArray } from 'utils/enumKeys';
import { WorkWeekDays } from './WorkWeekDays';

export enum RepetitiveOptions {
  DoNotRepeat,
  RepeatDaily,
  RepeatWeeklyOn,
  RepeatMonthlyOn,
  RepeatEveryWeekDay,
  RepeatCustom,
}
export enum RepeatPeriod {
  Days = 1,
  Weeks = 2,
  Months = 3,
}
export enum RepeatMonthlyBy {
  RepeatsBy_DayOfMonth = 1,
  RepeatsBy_DayOfWeek = 2,
}
export function GetRepetitiveOptions(
  startDate: Date | null,
  firstDayOfWeek: number,
) {
  let items = enumToEntityArray(RepetitiveOptions);
  let date = dateUtils.dateOrStringToDate(startDate ?? new Date());
  let dayName = dateUtils.getDayText(date);

  const { t } = useTranslation();
  let weekName = enumToEntityArray(WorkWeekDays, t).filter(
    f =>
      dateUtils.getWeekNumberOfMonth(date, firstDayOfWeek as WeekStartsOn) ===
      f.Id,
  )[0].Name;

  let weekDays = `${dateUtils.getLongDaysArray()[firstDayOfWeek]} - ${
    dateUtils.getLongDaysArray()[dateUtils.getLongDaysArray().length - 2]
  }`;

  items.forEach(item => {
    if (item.Id === RepetitiveOptions.RepeatWeeklyOn) {
      item.Name = item.Name.replace('{0}', dayName);
    }
    if (item.Id === RepetitiveOptions.RepeatMonthlyOn) {
      item.Name = item.Name.replace('{0}', weekName).replace('{1}', dayName);
    }
    if (item.Id === RepetitiveOptions.RepeatEveryWeekDay) {
      item.Name = item.Name.replace('{0}', weekDays);
    }
  });
  return items;
}
