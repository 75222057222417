import { RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';
import { AuthenticatedUser, IAuthenticatedUser } from 'types/AuthenticatedUser';
import { SiteMapProvider } from 'types/SiteMapSettings';
import { dateUtils } from 'utils/date-utils';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import { SettingModel } from 'utils/globalSettings/SettingModel';
import { DetectIsMobile } from 'utils/mobileDetect';
import { UserProfile } from 'utils/userProfileSettings';
import { initialState } from '.';
import { UserRegistersToSpecificCoresType } from 'enums/enums';
import { appendTrailingSlash } from 'utils/url-utils';
import { KnownModules } from 'types/KnownModules';
import { ReservationStatus } from 'app/pages/ReservationDetails/Details/slice/types';
import { TFunction } from 'i18next';
import { Entity } from 'types/common';

const selectSlice = (state: RootState) => {
  return state.appSettings || initialState;
};
export const selectAppSettings = createSelector(
  [selectSlice],
  state => state.appSettings,
);
export const selectKnownModules = createSelector(
  [selectAppSettings],
  state => state?.Modules,
);
export const selectKnownModule = createSelector(
  [selectKnownModules, (state, knownModules: KnownModules) => knownModules],
  (state, knownModules: KnownModules) => state?.includes(knownModules) ?? false,
);
export const selectSystemLocale = createSelector(
  [selectAppSettings],
  state => state?.Globalization.Locale,
);
export const selectDateFnsLocale = createSelector(
  [selectAppSettings],
  state => {
    const locale = state?.Globalization?.Locale;
    return locale === undefined
      ? undefined
      : dateUtils.dateFnsCultureName(locale);
  },
);
export const selectUseAmPm = createSelector([selectAppSettings], state => {
  return state?.Globalization?.UseAmPm;
});
export const selectSystemFirstDayOfWeek = createSelector(
  [selectAppSettings],
  state => state?.Globalization.FirstDayOfWeek ?? 1,
);
export const selectWebVersion = createSelector(
  [selectAppSettings],
  state => state?.WebVersion,
);
const DefaultUploadFileSizeLimit = 500000;
export const selectUploadFileSizeLimit = createSelector(
  [selectAppSettings],
  state => state?.UploadFileSizeLimit || DefaultUploadFileSizeLimit,
);
export const selectMinimizedBookitlabLogo = createSelector(
  [selectAppSettings],
  state => state?.MinimizedBookitlabLogo || false,
);
const defaultForbiddenFileExtensions = [
  '.dll',
  '.exe',
  '.vbs',
  '.js',
  '.ts',
  '.tsx',
  '.msi',
  '.com',
  '.bat',
];
export const selectForbiddenFileExtensions = createSelector(
  [selectAppSettings],
  state => state?.ForbiddenFileExtensions ?? defaultForbiddenFileExtensions,
);
export const selectLastCodeUpdate = createSelector(
  [selectAppSettings],
  state => state?.LastCodeUpdate,
);
export const selectAppTimeZone = createSelector(
  [selectAppSettings],
  state => state?.TimeZone,
);
export const selectPublicUrl = createSelector([selectAppSettings], state =>
  state?.PublicBaseUrl === undefined
    ? undefined
    : appendTrailingSlash(state.PublicBaseUrl),
);
export const selectRegisterUserGroup = createSelector(
  [selectAppSettings],
  state => state?.DefaultRegistrationGroup,
);
export const selectOffsetDate = createSelector(
  [selectAppTimeZone, (state, date: Date | string | undefined | null) => date],
  (timeZone, date) => {
    const systemOffset = timeZone?.TimezoneOffsetMinutes ?? 0;
    let correctDate =
      date === null || date === undefined
        ? dateUtils.dateOrStringToDate(new Date())
        : dateUtils.dateOrStringToDate(date);
    const currentDateOffset = correctDate.getTimezoneOffset();
    const dateOrNow = dateUtils.addMinutes(
      correctDate,
      systemOffset + currentDateOffset,
    );
    return dateOrNow;
  },
);
export const selectUserRegistersToSpecificCores = createSelector(
  [selectAppSettings],
  state =>
    (state?.UserRegistersToSpecificCores as UserRegistersToSpecificCoresType) ||
    UserRegistersToSpecificCoresType.UserRegistersToSpecificCores_NA,
);
export const selectEmailFromAddress = createSelector(
  [selectAppSettings],
  state => state?.EmailFromAddress,
);
export const selectExistingImages = createSelector(
  [selectAppSettings],
  state => state?.ExistingImages,
);
const constructAuthenticatedUser = (a?: IAuthenticatedUser) => {
  return a === undefined ? undefined : new AuthenticatedUser(a);
};
const selectAuthenticatedUserObject = createSelector(
  [selectSlice],
  state => state.authenticatedUser,
);
export const selectAuthenticatedUser = createSelector(
  [selectAuthenticatedUserObject],
  state => {
    const a = state;
    const b = constructAuthenticatedUser(a);
    return b;
  },
);
export const selectIsFullScreen = createSelector(
  [selectSlice],
  state => state.isFullScreen,
);
export const selectCurrentLanguage = createSelector(
  [selectSlice],
  state => state.currentLanguage,
);

export const selectLoading = createSelector([selectSlice], state => {
  let loading =
    state.isAuthenticated === true
      ? state.loadingAppSettings ||
        state.loadingSiteMapSettings ||
        state.loadingUserProfileSettings ||
        state.loadingSystemSettings
      : state.isAuthenticated === false
      ? state.loadingAppSettings || state.loadingSystemSettings
      : state.loadingAuthenticatedUser || state.loadingSystemSettings;
  return loading;
});
export const selectError = createSelector([selectSlice], state => state.error);
export const selectLoadingUser = createSelector(
  [selectSlice],
  state => state.loadingAuthenticatedUser,
);
export const selectNotifications = createSelector(
  [selectSlice],
  state => state.notifications,
);
const selectGlobalSettingsObj = createSelector(
  [selectSlice],
  state => state.systemSettings,
);
export const selectglobalSettings = createSelector(
  [selectGlobalSettingsObj],
  state => new GlobalSettings(state || ([] as SettingModel[])),
);
export const selectGlobalSetting = createSelector(
  [selectGlobalSettingsObj, (state, name: AllowedSettings) => name],
  (globalSettingsObj, name) => {
    const x = globalSettingsObj.find(
      f =>
        f.Name.trim().toLowerCase() ===
        AllowedSettings[name].trim().toLowerCase(),
    );
    return x?.Value ?? undefined;
  },
);
export const selectImageExist = createSelector(
  [selectExistingImages, (state, image: string | null) => image],
  (existingImages, image) => {
    let result = false;
    if (image === null) {
      return false;
    }
    if (!!existingImages && existingImages.length > 0) {
      result = existingImages.some(f => f === image);
    }
    return result;
  },
);
export const selectGlobalSettingBool = createSelector(
  [selectGlobalSettingsObj, (state, name: AllowedSettings) => name],
  (globalSettingsObj, name) => {
    const x = globalSettingsObj.find(
      f =>
        f.Name.trim().toLowerCase() ===
        AllowedSettings[name].trim().toLowerCase(),
    );
    return x?.Value === null ||
      x?.Value === undefined ||
      x.Value === '' ||
      x.Value.toLowerCase() === 'false'
      ? false
      : x.Value.toLowerCase() === 'true';
  },
);
export const selectSiteMapSettings = createSelector(
  [selectSlice],
  state => state.siteMapSettings,
);
export const selectSiteMapProvider = createSelector(
  [selectSlice],
  state => state.siteMapProvider ?? new SiteMapProvider(state.siteMapSettings),
);
export const selectEventsCount = createSelector(
  [selectSlice],
  state => state.eventsCount,
);
export const selectEventsCountLoading = createSelector(
  [selectSlice],
  state => state.loadingEventsCount,
);
export const selectUserProfileSettings = createSelector(
  [selectSlice],
  state => new UserProfile(state.userProfileSettings),
);
/**
 * Selects specific user profile setting value provided by the key
 * usage example:
 * const userProfileValue = useSelector(state => selectUserProfileSetting(state, "userProfileKey"))
 */
export const selectUserProfileSetting = createSelector(
  [selectUserProfileSettings, (state, key) => key],
  (state, key) => state.GetSettingByKey(key)?.Value,
);
export const selectIsExpanded = createSelector(
  [selectSlice],
  state => state.isExpanded,
);
export const selectUpdatedUserProfileSettings = createSelector(
  [selectSlice],
  state => state.updatedUserProfileSettings,
);
export const selectIsAuthenticated = createSelector(
  [selectSlice],
  state => state.isAuthenticated,
);
export const selectIsExpandedSideBar = createSelector([selectSlice], state => {
  let result: boolean = false;
  if (DetectIsMobile()) {
    result = state.isExpanded;
  } else {
    if (state.updatedUserProfileSettings) {
      result = state.isExpanded;
    } else {
      let sett = new UserProfile(state.userProfileSettings);
      let expanded = sett.GetSideBarExpanded('SideBarExpanded');
      result = expanded !== null ? expanded : true;
    }
  }
  return result;
});
export const selectGlobalServiceGroupFilter = createSelector(
  [selectSlice],
  state => {
    let topServiceGroupsIsHidden = state.systemSettings.find(
      f => f.Id === 'HideTopServiceGroupFilter',
    );
    if (
      topServiceGroupsIsHidden !== null &&
      topServiceGroupsIsHidden !== undefined
    ) {
      if (topServiceGroupsIsHidden.Value === 'True') {
        return [];
      } else {
        return state.globalServiceGroupFilter;
      }
    } else {
      return state.globalServiceGroupFilter;
    }
  },
);
export const selectShowGlobalServiceGroupFilter = createSelector(
  [selectSlice],
  state => state.showGlobalServiceGroupFilter,
);
export const selectShowGlobalServiceGroupsAsList = createSelector(
  [selectSlice],
  state => state.showGlobalServiceGroupsAsList,
);
export const selectBarcodeScanTarget = createSelector(
  [selectSlice],
  state => state.barcodeScanTarget,
);
export const selectShowAllCores = createSelector(
  [selectSlice],
  state => state.showAllCores,
);

/**
 * Selects reservation statuses enabled on the system level
 * ```
 * const statuses = useSelector(state => selectReservationStatuses(state, t));
 * ```
 */
export const selectReservationStatuses = createSelector(
  [
    selectSlice,
    state => selectKnownModule(state, KnownModules.UserGroupCoordRole),
    state =>
      selectGlobalSettingBool(state, AllowedSettings.AllowWorkflowBooking) &&
      selectGlobalSettingBool(
        state,
        AllowedSettings.MultipleTentativeReservationsUnderWorkflowBooking,
      ),
    state =>
      selectGlobalSettingBool(state, AllowedSettings.DraftReservationsEnabled),
    (state, t: TFunction) => t,
  ],
  (
    state,
    UserGroupCoordRole,
    AllowWorkflowBooking,
    DraftReservationsEnabled,
    t,
  ) => {
    const GetListItem = (s: ReservationStatus) => ({
      Id: s,
      Name: t(ReservationStatus[s]),
    });
    const list: Array<Entity<number>> = [];

    list.push(GetListItem(ReservationStatus.Approved));
    list.push(GetListItem(ReservationStatus.Pending));
    if (UserGroupCoordRole) {
      list.push(GetListItem(ReservationStatus.PendingCoord));
    }
    list.push(GetListItem(ReservationStatus.Cancelled));
    list.push(GetListItem(ReservationStatus.CancelledByAdmin));
    if (AllowWorkflowBooking) {
      list.push(GetListItem(ReservationStatus.Tentative));
    }
    if (DraftReservationsEnabled) {
      list.push(GetListItem(ReservationStatus.Draft));
    }
    return list;
  },
);
