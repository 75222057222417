import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { trainingActions as actions } from '.';
import { UserTrainingApi as api } from 'api/UserTrainingApi';
import { IUserTrainingDto } from 'api/odata/generated/entities/IUserTrainingDto';
import {
  ConvertModelToEntity,
  TrainingDetailsState,
  TrainingsResponse,
} from './types';
import { appSettingsActions } from 'app/slice';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { AxiosError } from 'axios';
import { IResponseType } from 'types/ResponseType';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { TrainingDetailsProps } from '..';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';

function* doInitCreate(
  action: PayloadAction<{
    equId?: string | null;
    equIds?: string | null;
    fatherId?: string | null;
    rid?: string | null;
    date?: string | null;
    user?: string | null;
  }>,
) {
  try {
    const data = yield call(api.initCreateData, action.payload);
    yield put(actions.initCreateTraining_Success(data));
  } catch (error: unknown) {
    yield put(actions.initCreateTraining_Error(Error));
  }
}
function* doInitUpdate(action: PayloadAction<{ Id: number }>) {
  try {
    const data = yield call(api.initUpdateData, action.payload);
    yield put(actions.initUpdateTraining_Success(data.value[0]));
  } catch (error: unknown) {
    yield put(actions.initUpdateTraining_Error(Error));
  }
}
function* doCreate(action: PayloadAction<TrainingDetailsState>) {
  const httpPayloads: IUserTrainingDto[] = ConvertModelToEntity(
    action.payload,
    undefined,
  );
  try {
    const result = yield call(api.submitTrainings, httpPayloads);
    let trainingsResponse = result as TrainingsResponse;
    if (trainingsResponse.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          trainingsResponse.ErrorMessages.map(item => {
            return {
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (trainingsResponse.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          trainingsResponse.WarningMessages.map(item => {
            return {
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (trainingsResponse.SuccessMessages.length > 0) {
      if (trainingsResponse.Id) {
        yield put(
          appSettingsActions.addNotification({
            key: 'insertedSuccess',
            message: trainingsResponse.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: trainingsResponse.Id,
              created: true,
              itemName: i18next.t(translations.UserTraining),
              detailsType: RenderPageType.TrainingDetails,
              detailsTypeProps: {
                id: '' + trainingsResponse.Id,
                useSidePanel: true,
              } as TrainingDetailsProps,
            },
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      } else {
        yield put(
          appSettingsActions.addNotification({
            key: 'insertedSuccess',
            message: trainingsResponse.SuccessMessages[0],
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      }
    }
    yield put(actions.createTraining_Success(trainingsResponse));
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'UserTrainingCreate',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createTraining_Error(Error));
  }
}
function* duUpdateTraining(
  action: PayloadAction<{
    current: TrainingDetailsState;
    original: TrainingDetailsState | undefined;
  }>,
) {
  const httpPayloads: IUserTrainingDto[] = ConvertModelToEntity(
    action.payload.current,
    action.payload.original,
  );
  try {
    const result = yield call(api.submitTrainings, httpPayloads);
    let trainingsResponse = result as TrainingsResponse;
    if (trainingsResponse.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          trainingsResponse.ErrorMessages.map(item => {
            return {
              key: 'UpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (trainingsResponse.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          key: 'updateWarning',
          message: trainingsResponse.WarningMessages[0],
          variant: 'warning',
        }),
      );
    }
    if (trainingsResponse.SuccessMessages.length > 0) {
      if (trainingsResponse.Id) {
        yield put(
          appSettingsActions.addNotification({
            key: 'updatedSuccess',
            message: trainingsResponse.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: trainingsResponse.Id,
              created: false,
              itemName: i18next.t(translations.UserTraining),
              detailsType: RenderPageType.TrainingDetails,
              detailsTypeProps: {
                id: '' + trainingsResponse.Id,
                useSidePanel: true,
              } as TrainingDetailsProps,
            },
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      } else {
        yield put(
          appSettingsActions.addNotification({
            key: 'updatedSuccess',
            message: trainingsResponse.SuccessMessages[0],
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      }
    }
    yield put(actions.updateTraining_Success(trainingsResponse));
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'UserTrainingUpdate',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateTraining_Error(Error));
  }
}
function* doApproveRejectReservations(
  action: PayloadAction<{
    trainings: number[];
    approve: boolean;
  }>,
) {
  try {
    const result = yield call(api.approveRejectReservations, action.payload);
    let response = result as IResponseType;
    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.map(item => {
            return {
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.SuccessMessages.map(item => {
            return {
              message: item,
              variant: 'success',
            };
          }),
        ),
      );
    }
    yield put(actions.approveRejectReservations_success());
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'UserTrainingResUpdate',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.approveRejectReservations_error(Error));
  }
}
function* doActivate(
  action: PayloadAction<{ Id?: number; activate?: boolean }>,
) {
  try {
    const result = yield call(api.activateTraining, action.payload);
    let response = result as IResponseType;
    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.map(item => {
            return {
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.SuccessMessages.map(item => {
            return {
              message: item,
              variant: 'success',
            };
          }),
        ),
      );
    }
    yield put(
      actions.activateDeactivateTraining_success(action.payload.activate),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'UserTrainingResUpdate',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.activateDeactivateTraining_error(Error));
  }
}
export function* trainingSaga() {
  yield takeLatest(actions.initCreateTraining.type, doInitCreate);
  yield takeLatest(actions.initUpdateTraining.type, doInitUpdate);
  yield takeLatest(actions.createTraining.type, doCreate);
  yield takeLatest(actions.updateTraining.type, duUpdateTraining);
  yield takeLatest(
    actions.approveRejectReservations.type,
    doApproveRejectReservations,
  );
  yield takeLatest(actions.activateDeactivateTraining.type, doActivate);
}
