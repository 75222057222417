/**
 *
 * FormReservationEquipmentsPicker
 *
 */
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import {
  ReservationEquipmentsPicker,
  ReservationSingleEquipmentPicker,
} from '../pickers/MultiSelectPickers/EquipmentReservationPicker';
import { IReservationEquipmentDto } from 'app/pages/ReservationDetails/Details/slice/types';

export const FormReservationEquipmentsPicker = ({
  label,
  ...props
}: FieldHookConfig<IReservationEquipmentDto[] | null> &
  IFieldProps & { multiple: boolean; autoExpand?: string }) => {
  const [field, meta, helpers] = useField<IReservationEquipmentDto[] | null>(
    props,
  );
  if (props.multiple) {
    return (
      <ReservationEquipmentsPicker
        predicates={props.predicates}
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onChipClick={props.onChipClick}
        onBlur={field?.onBlur}
        value={field.value ?? undefined}
        error={meta?.error !== undefined}
        variant="filled"
        disabled={props.disabled}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        info={props.info}
        expandedColumns={props.autoExpand}
      />
    );
  } else {
    return (
      <ReservationSingleEquipmentPicker
        predicates={props.predicates}
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onChipClick={props.onChipClick}
        onBlur={field?.onBlur}
        value={field.value ?? undefined}
        error={meta?.error !== undefined}
        variant="filled"
        disabled={props.disabled}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        info={props.info}
        expandedColumns={props.autoExpand}
      />
    );
  }
};
