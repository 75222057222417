import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { groupBy, values } from 'lodash';
import {
  IEquipmentOfflineServiceTypeDto,
  IReservationEquipmentDto,
} from './types';

/**
 * Combines offline services from multiple instrument into a single list.
 * E.g. on multi instrument reservations, offline services section is shared across all instruments
 * @param equipments online services
 * @returns list of offline service types applicable to selected services
 */
export function combineOfflineServiceTypes(
  equipments: IReservationEquipmentDto[] | undefined,
): IEquipmentOfflineServiceTypeDto[] | undefined {
  if (equipments === undefined) {
    return undefined;
  }
  let haidProject = equipments.every(f => f.HideProjects === true);
  return (
    values(
      groupBy(
        equipments
          //ignore equipments without offline services enabled
          .filter(f => f.ReservationOfflineServicesEnabled)
          .flatMap(f => f.OfflineServices),
        f => f.Id,
      ),
    )
      // offline service must be enabled on each instrument
      .filter(group => group.length === equipments.length)
      // offline service is mandatory if it's mandatory on one of the instruments
      .map(f => {
        return {
          ...f[0],
          Mandatory: f.some(g => g.Mandatory),
        };
      })
      .filter(o => o.HideProject === haidProject)
  );
}
export function combineMandatoryOfflineServices(
  services: IOtherServices[] | undefined,
): IOtherServices[] | undefined {
  if (services === undefined) {
    return undefined;
  }
  return values(groupBy(services, f => f.ServiceTypeID)).map(f => {
    return f[0];
  });
}
