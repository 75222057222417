import { isEmptyOrWhitespace } from 'utils/typeUtils';
import { SavedViewSelected } from '../SavedViewsPage/SavedViewPage/slice/types';

export function MyListFilter(search: string, selectedView: SavedViewSelected) {
  const result = isEmptyOrWhitespace(search)
    ? new URLSearchParams()
    : new URLSearchParams(search);
  result.set('eid', selectedView.ids || '');
  const res = result.toString();
  return isEmptyOrWhitespace(res) ? undefined : res;
}
