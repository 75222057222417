import { H3 } from 'app/components/Typography';
import { H4 } from 'app/components/Typography';
import { Box } from 'app/components/basic/Wrappers/Box';
import { FlexRowDiv } from 'app/components/basic/Wrappers/FlexWrappers/flexRow';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';

export function NoServices() {
  const { t } = useTranslation();
  return (
    <FlexRowDiv>
      <Box textAlign="center" style={{ marginTop: '5%' }}>
        <H3>{t(translations.NothingToShowForThisScheduler_Title)}</H3>
        <H4>{t(translations.NothingToShowForThisScheduler_Message)}</H4>
      </Box>
    </FlexRowDiv>
  );
}
