import { IInvoiceBudgetBalanceDto } from 'api/odata/generated/entities/IInvoiceBudgetBalanceDto';
import { AsyncDataState, AsyncProgressState } from 'types/common';
import { IResponseType } from 'types/ResponseType';

export interface IChargeOnlineBudgetState
  extends AsyncProgressState<Array<IInvoiceBudgetBalanceDto>> {
  currentRecord?: IInvoiceBudgetBalanceDto;
  errors?: string[];
}

/* --- STATE --- */
export interface OnlineBudgetsStatusState {
  endDate: string | null;
  needTableRefresh: boolean;
  tableState: AsyncDataState<Array<IInvoiceBudgetBalanceDto>>;
  selected: IInvoiceBudgetBalanceDto[];
  generateInvoicesState: AsyncDataState<any>;
  charge: IChargeOnlineBudgetState;
  pay: IPayState;
  generateInvoicesAndSendToSap: IGenerateInvoicesAndSendToSapState;
}

export interface IPayState {
  currentRecord?: IInvoiceBudgetBalanceDto;
  selected?: IInvoiceBudgetBalanceDto[];
  progress?: number;
  status?: PromiseStatus;
  dialogOpen?: boolean;
  data?: IPayActionPayload;
  messages?: IResponseType[];
}
export type PromiseStatus = `pending` | `resolved` | `rejected`;

export enum PaymentMethods {
  BudgetTransfer = 1,
  CreditCard = 2,
  Wire = 3,
  Cash = 4,
  Check = 5,
  Other = 6,
}

export interface IPayActionPayload {
  PaymentMethod: PaymentMethods;
  EndDate: Date;
  Rows: Array<IInvoiceBudgetBalanceDto>;
}

export interface IProgressAction<T> {
  selected: T[];
  progress?: number;
  status?: PromiseStatus;
}

export interface ChargeOnlineBudgetResponse {
  Messages: string[];
  SuccessTransactionsCount: number;
  TransactionsCount: number;
  FailedTransactionsCount: number;
}

export interface IGenerateInvoicesAndSendToSapState {
  processing?: boolean;
  dialogOpen?: boolean;
  progress?: number;
  selected?: IInvoiceBudgetBalanceDto[];
  endDate?: Date;
  currentRecord?: IInvoiceBudgetBalanceDto;
}
