import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { selectglobalSettings } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import { AllowedSettings } from 'utils/globalSettings';
import * as React from 'react';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

export interface SaveFormButtonProps extends ButtonProps {
  edit: boolean;
  text: string;
  onClick: (e: any) => void;
}
export interface ActivateButtonProps extends ButtonProps {
  edit: boolean;
  text: string;
  onClick: () => void;
  ServiceGroupId?: number;
  Active: boolean;
}

export function SaveFormButton({
  edit,
  text,
  onClick,
  ...props
}: SaveFormButtonProps) {
  const { t } = useTranslation();
  const settings = useSelector(selectglobalSettings);
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);

  return edit &&
    settings.GetBooleanByKey(
      AllowedSettings.TrainingActivationAllowedBySGAOnly,
    ) ? (
    <React.Fragment>
      <Button {...props} onClick={() => setShowConfirm(true)}>
        {text}
      </Button>
      <DialogConfirm
        isOpen={showConfirm}
        title={t(translations.UpdateActiveTrainingWarningTitle)}
        body={t(translations.DeactivateTrainingMessage)}
        confirmButtonLabel={t(translations.Update)}
        cancelButtonLabel={t(translations.Cancel)}
        onConfirm={onClick}
        onCancel={() => setShowConfirm(false)}
      />
    </React.Fragment>
  ) : (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
}
export function ActivateButton({
  edit,
  text,
  onClick,
  ServiceGroupId,
  Active,
  ...props
}: ActivateButtonProps) {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      {Active ? (
        <React.Fragment>
          <Button size="small" onClick={() => setShowConfirm(true)} {...props}>
            {text}
          </Button>
          <DialogConfirm
            isOpen={showConfirm}
            title={t(translations.DeactivateTraining)}
            body={t(translations.DeactivateTrainingMessage)}
            confirmButtonLabel={t(translations.Deactivate)}
            cancelButtonLabel={t(translations.Cancel)}
            onConfirm={onClick}
            onCancel={() => setShowConfirm(false)}
          />
        </React.Fragment>
      ) : (
        <Button size="small" onClick={onClick} {...props}>
          {text}
        </Button>
      )}
    </React.Fragment>
  );
}
