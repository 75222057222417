/**
 *
 * Asynchronously loads the component for AnimalCasesHistoryPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AnimalCasesHistoryPage = lazyLoad(
  () => import('./index'),
  module => module.AnimalCasesHistoryPage,
);
