import {
  FormControlLabelProps as MuiFormControlLabelProps,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

export interface FormControlLabelProps extends MuiFormControlLabelProps {
  boldLabel?: boolean;
  withInfo?: boolean;
  boxed?: boolean;
  boxedChecked?: boolean;
  boxedError?: boolean;
}
const FormControlLabel = (props: FormControlLabelProps) => {
  const {
    boldLabel,
    withInfo,
    boxed,
    boxedChecked,
    boxedError,
    ...other
  } = props;
  return (
    <MuiFormControlLabel
      {...other}
      className={clsx(props.className, {
        withInfo: withInfo,
        boxed: boxed,
        boxedChecked: boxedChecked,
        boxedError: boxedError,
        boldLabel: boldLabel === true,
      })}
    />
  );
};

export default FormControlLabel;
