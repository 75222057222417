/**
 *
 * BasicDialogContentWithTitle
 *
 */
import {
  Box,
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
  LinearProgress,
  Theme,
  withStyles,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { bookitColors } from 'styles/colors/bookitColors';
import { IconButton } from '../BasicButtons/IconButton';
import { Caption, H4 } from '../Typography';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(3, 6, 2, 3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      borderRadius: '12px 12px 0px 0px',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
    },
    titleSubtitle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: theme.palette.grey[500],
    },
    title: {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    subtitle: {},
  }),
);
export interface TitleProps {
  titles: React.ReactNode;
  subtitle?: React.ReactNode;
  onClose?: () => void;
  processing?: boolean;
  disabled?: boolean;
}
export interface BasicDialogContentWithTitleProps
  extends MuiDialogContentProps {
  children: React.ReactNode;
}
export function BasicDialogContentWithTitle({
  titles,
  subtitle,
  onClose,
  children,
  processing,
  disabled,
}: BasicDialogContentWithTitleProps & TitleProps) {
  const classes = useStyles();

  return (
    <DialogContent>
      <Box className={classes.titleContainer}>
        {!!onClose ? (
          <IconButton
            style={{
              position: 'absolute',
              right: 12,
              top: 12,
              color: bookitColors.grayscale.graySubtitles,
            }}
            variant="ghost"
            aria-label="close"
            onClick={onClose}
            shape="square"
            disabled={disabled}
            size="small"
            icon="times"
          />
        ) : null}
        <H4 className={classes.title}>{titles}</H4>
        {subtitle && <Caption className={classes.subtitle}>{subtitle}</Caption>}
        {processing && (
          <Box style={{ width: '100%' }}>
            <LinearProgress variant="query" color="primary" />
          </Box>
        )}
      </Box>
      <Box className={classes.formContainer}>{children}</Box>
    </DialogContent>
  );
}
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
    borderTop: 'unset',
    '&:first-child': {
      paddingTop: theme.spacing(4),
    },
  },
}))(MuiDialogContent);
