/**
 *
 * Asynchronously loads the component for InvoiceBatches Page
 *
 */
import { lazyLoad } from 'utils/loadable';

export const InvoiceBatches = lazyLoad(
  () => import('./index'),
  module => module.InvoiceBatchesPage,
);
