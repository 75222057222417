export enum FileTypes {
  Undefined = -1,
  ServiceFile = 0,
  ServiceRequestFile = 1,
  ServiceRequestComment = 2,
  ServiceRequestRowFile = 3,
  ServiceRequestRowComment = 4,
  ExperimentFile = 5,
  ExperimentComment = 6,
  AssetFile = 7,
  SrpFile = 8,
  SrpComment = 9,
  AlertFile = 10,
  BudgetExperimentFile = 11,
  Expense = 12,
  ReservationFile = 13,
  AlertComment = 14,
  AlertTypeFile = 15,
  ServiceGroupFile = 16,
  InvoiceTemplate = 17,
}
