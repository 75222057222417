export enum PartOfDay {
  Undefined = -1,
  /// <summary>
  /// Combines what was perviously named as Day/Night. Replaced by the TimeSlotTypes
  /// </summary>
  BusinessHours = 0,
  OffLine = 2,
  Tutoring = 3,
  NoTutoring = 4,
}
