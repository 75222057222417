import { Box } from '@material-ui/core';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import * as React from 'react';
import { FileInput, FileInputProps } from './FileInput';
import { FilesPreview } from './FilesPreview';

export interface FileUploadProps {
  values: IFormFileValue[];
  multiple?: boolean;
  uploadLabel?: string;
  showPreview?: boolean;
  fileSizeLimit?: number;
  saveFiles?: (files: IFormFileValue[]) => void;
  fileInputProps: FileInputProps;
}

export function FileUpload(props: FileUploadProps) {
  const { values, multiple, showPreview, saveFiles, fileInputProps } = props;

  const [innerFiles, setInnerFiles] = React.useState<Record<string, File>>({});
  const [fileValues, setFileValues] = React.useState<IFormFileValue[] | null>(
    values,
  );
  const referenceId = fileInputProps.referenceId;
  const convertFilesToArray = (files: Record<string, File>) =>
    Object.keys(files).map(key => files[key]);

  const addNewFiles = (newFiles: FileList) => {
    let files = multiple ? innerFiles : {};
    for (let file of newFiles) {
      if (!multiple) {
        // return { file };
        files[file.name] = file;
        return { ...files };
      }
      files[file.name] = file;
    }
    return { ...files };
  };
  const updateState = (files: File[], deleteFileName?: string) => {
    let savedFiles = fileValues ?? [];
    if (deleteFileName) {
      savedFiles =
        fileValues?.filter(f => f.DisplayValue !== deleteFileName) ?? [];
    } else {
      files?.forEach(file => {
        if (
          savedFiles?.filter(f => f.DisplayValue === file.name)?.length === 0
        ) {
          savedFiles?.push({
            Id: referenceId || -1,
            Value: null,
            DisplayValue: file.name,
            Size: file.size,
            PostedFile: file,
          } as IFormFileValue);
        }
      });
    }
    return savedFiles;
  };
  const updateFiles = (
    files: Record<string, File>,
    deleteFileName?: string,
  ) => {
    const filesAsArray = convertFilesToArray(files);
    const savedFiles = updateState(filesAsArray, deleteFileName);
    setFileValues(savedFiles);
    if (saveFiles !== undefined) {
      saveFiles(savedFiles ?? []);
    }
  };
  const handleChange = (files: FileList | null) => {
    let updatedFiles = {};
    if (files?.length) {
      updatedFiles = addNewFiles(files);
      setInnerFiles(updatedFiles);
    } else {
      setInnerFiles({});
    }
    updateFiles(updatedFiles);
  };
  const removeFile = (fileName: string) => {
    let files = innerFiles;
    delete files[fileName];
    setInnerFiles({ ...files });

    updateFiles({ ...files }, fileName);
  };
  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px" width="100%">
      <FileInput
        onFileChange={handleChange}
        onRemove={removeFile}
        file={fileValues && fileValues.length > 0 ? fileValues[0] : undefined}
        {...fileInputProps}
      />
      {multiple && showPreview && (
        <FilesPreview
          files={fileValues}
          multiple={multiple}
          onRemove={removeFile}
        />
      )}
    </Box>
  );
}
