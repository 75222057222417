/**
 *
 * NumbersRangeFilter
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IFilterComponentProps } from 'app/components/BasicTable/Filters/IFilterComponentProps';
import { INumbersRange } from 'types/INumbersRange';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import { debounce } from 'lodash';

/**
 * Number picker to be used as a BasicTable filter component
 */
export const NumbersRangeFilter = ({
  value: propsValue,
  onChange,
  additionalItem,
  labelFunc,
  ...props
}: IFilterComponentProps) => {
  const { t } = useTranslation();

  const [value, setValue] = React.useState<INumbersRange | null>(propsValue);
  const handleStartChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    setValue(value => {
      const newState = {
        end: value?.end ?? null,
        start: +event.target.value ?? null,
      };
      d(newState);
      return newState;
    });
  };
  const handleEndChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    setValue(value => {
      const newState = {
        start: value?.start ?? null,
        end: +event.target.value ?? null,
      };
      d(newState);
      return newState;
    });
  };
  const d = debounce(onChange, 250);
  return (
    <>
      {value !== undefined && (
        <>
          <>
            <TextControlField
              value={value?.start ?? ''}
              min={value?.end ?? undefined}
              type="number"
              size="small"
              onChange={handleStartChange}
              placeholder={t(translations.AllValues)}
              id="numberstartId"
              {...props}
              inputProps={{ 'aria-label': 'Number Start Filter' }}
            />

            <Icon
              icon="arrow-from-left"
              color="primary"
              style={{ marginLeft: '4px', marginRight: '4px' }}
            />
            <TextControlField
              value={value?.end || null}
              min={value?.start ?? undefined}
              size="small"
              type="number"
              onChange={handleEndChange}
              placeholder={t(translations.AllValues)}
              id="numberendid"
              inputProps={{ 'aria-label': 'Number End Filter' }}
              {...props}
            />
          </>
        </>
      )}
    </>
  );
};
export default NumbersRangeFilter;
