export enum TrainingStatus {
  Passed = 1,
  Missing = 2,
  Expired = 3,
}
export enum PrerequisiteFor {
  NotAllow = 0,
  WithinMyCore = 1,
  WithinCorAssetCatGroup = 2,
  WithinCoreAssetCat = 3,
  WithinSpecificCore = 4,
  AccrossSystem = 5,
}
