import * as React from 'react';
import { EntityType } from 'types/common';
import { FormAutocomplete, FormAutocompleteProps } from './FormAutocomplete';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export interface FormAutocompleteOptionsProps<T extends EntityType>
  extends Omit<FormAutocompleteProps<T>, 'loadData'>,
    IFieldProps {
  getOptions: (searchTerm: string | null) => T[];
  disabled?: boolean;
  id?: string;
}

export function FormAutocompleteOptions<T extends EntityType>({
  getOptions,
  ...props
}: FormAutocompleteOptionsProps<T>) {
  const loadData = async (searchTerm: string | null): Promise<T[]> =>
    new Promise<T[]>(resolve => {
      const opts = getOptions(searchTerm);
      resolve(opts);
    });
  return <FormAutocomplete loadData={loadData} variant="filled" {...props} />;
}
