/**
 *
 * Asynchronously loads the component for QuizPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const QuizPage = lazyLoad(
  () => import('./index'),
  module => module.QuizPage,
);
