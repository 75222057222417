/**
 *
 * ProfileMenu
 *
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectEventsCountLoading } from 'app/slice/selectors';
import { EventsCount } from './EventsCount';
import { AssetQuickSearch } from 'app/components/AssetQuickSearch';
import { useAssetQuickSearchSlice } from 'app/components/AssetQuickSearch/slice';
import { translations } from 'locales/translations';
import { selectShowSearchInSidebar } from 'app/components/AssetQuickSearch/slice/selectors';
import { UserMenu } from './UserMenu';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { CoreSelectionButton } from 'app/components/GlobalServiceGroupFilter/CoreSelectionButton';
import { bookitColors } from 'styles/colors/bookitColors';
import { useTheme } from '@material-ui/core/styles';
import { ProfileSection } from '../styled';
import { SwitchSites } from './SwitchSites';

export const ProfileMenu = React.memo(function ProfileMenu() {
  const { t } = useTranslation();
  const eventsLoading = useSelector(selectEventsCountLoading);
  const { checkIsMobile } = useIsMobile();
  const isMobile = checkIsMobile();
  const dispatch = useDispatch();
  const { actions: assetSearchActions } = useAssetQuickSearchSlice();
  const showSearchInSidebar = useSelector(selectShowSearchInSidebar);

  const theme = useTheme();
  return (
    <ProfileSection theme={theme} className={'mainContainer'}>
      {!isMobile && (
        <div className={'mainMenu menuSearch'}>
          <AssetQuickSearch />
        </div>
      )}
      {!eventsLoading && (
        <div className={'mainMenu menuIcon'}>
          <EventsCount />
        </div>
      )}
      {isMobile && (
        <div className={'mainMenu menuIcon'}>
          <IconButton
            variant="ghost"
            size="small"
            aria-label="search"
            onClick={() => {
              dispatch(
                assetSearchActions.showSearchInput(!showSearchInSidebar),
              );
            }}
            title={t(translations.SearchAssets)}
            shape="square"
          >
            <Icon
              icon="search"
              style={{
                fontSize: '20px',
                color: bookitColors.grayscale.grayComponentsLight,
              }}
            />
          </IconButton>
        </div>
      )}
      {!isMobile && (
        <div className={'mainMenu menuCores'}>
          {/* <GlobalServiceGroupFilter useButtons /> */}
          <CoreSelectionButton />
        </div>
      )}

      <div className={'mainMenu userMenu'}>
        <SwitchSites />
      </div>
      <div className={'mainMenu userMenu'}>
        <UserMenu />
      </div>
    </ProfileSection>
  );
});
