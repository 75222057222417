/**
 * Internal ServiceRequest Statuses enum. Generated from Bookit.Data.Enums.InternalServiceRequestStatus
 */
export enum InternalServiceRequestStatuses {
  Draft = 0,

  WaitingForQuote = 1,

  QuoteSendToUser = 2,

  QuoteApproved = 3,

  Completed = 5,

  Cancelled = 6,

  InProcess = 7,

  ReturnedToUser = 8,

  Pending = 9,

  Started = 10,

  Skipped = 11,
}

export type InternalServiceRequestStatusesUnion = keyof typeof InternalServiceRequestStatuses;
