import { Box, MenuItem } from '@material-ui/core';
import { IWorkOrderTypeStatusDto } from 'api/odata/generated/entities/IWorkOrderTypeStatusDto';
import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { DropDown } from 'app/components/DropDown';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface SaveFormButtonProps extends ButtonProps {
  edit: boolean;
  text: string;
  onClick: (e: any) => void;
}
export interface CloseWorkOrderButtonProps extends ButtonProps {
  text: string;
  closedStatuses: IWorkOrderTypeStatusDto[];
  handleClose: (e: any, status: IWorkOrderTypeStatusDto) => void;
  body?: string;
}
export interface ReopenOrderButtonProps extends ButtonProps {
  text: string;
  handleOpen: (e: any) => void;
}
export interface DeleteButtonProps extends ButtonProps {
  text: string;
  handleDelete: (e: any) => void;
}

export function SaveFormButton({
  edit,
  text,
  onClick,
  ...props
}: SaveFormButtonProps) {
  return (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
}
export function ReopenOrderButton({
  text,
  handleOpen,
  ...props
}: ReopenOrderButtonProps) {
  return (
    <Button {...props} onClick={e => handleOpen(e)}>
      {text}
    </Button>
  );
}
export function DeleteOrderButton({
  text,
  handleDelete,
  ...props
}: DeleteButtonProps) {
  return (
    <Button {...props} onClick={e => handleDelete(e)}>
      {text}
    </Button>
  );
}

export function CloseWorkOrderButton({
  text,
  closedStatuses,
  handleClose,
  ...props
}: CloseWorkOrderButtonProps) {
  //PROPS
  const { t } = useTranslation();

  //STATES
  const [open, setOpen] = React.useState(false);
  const [closedStatus, setClosedStatus] = React.useState<
    IWorkOrderTypeStatusDto | undefined
  >(undefined);
  const useOpen = React.useMemo(() => {
    return open;
  }, [open]);
  //RENDER
  return (
    <React.Fragment>
      {closedStatuses.length === 1 ? (
        <Button
          {...props}
          onClick={() => {
            setOpen(true);
            setClosedStatus(closedStatuses[0]);
          }}
        >
          {text}
        </Button>
      ) : (
        <DropDown
          menuChildren={() =>
            closedStatuses.map(s => (
              <Box key={s.StatusId}>
                <MenuItem
                  onClick={() => {
                    setOpen(true);
                    setClosedStatus(s);
                  }}
                >
                  {s.Name}
                </MenuItem>
              </Box>
            ))
          }
          menuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
          addChevron
          {...props}
        >
          {text}
        </DropDown>
      )}
      {useOpen && (
        <DialogConfirm
          isOpen={useOpen}
          title={t(translations.CloseEvent)}
          processing={props.processing}
          body={props.body}
          confirmButtonLabel={t(translations.Save)}
          cancelButtonLabel={t(translations.Cancel)}
          onConfirm={e => {
            if (!!closedStatus) {
              handleClose(e, closedStatus);
            }
          }}
          onCancel={() => {
            setClosedStatus(undefined);
            setOpen(false);
          }}
          onClose={() => {
            setClosedStatus(undefined);
            setOpen(false);
          }}
          maxWidth="md"
        />
      )}
    </React.Fragment>
  );
}
