import { PayloadAction } from '@reduxjs/toolkit';
import { ISamplePlateDto } from 'api/odata/generated/entities/ISamplePlateDto';
import { Entity } from 'types/common';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { toEntity } from 'utils/entity-utils';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { samplePlatesSaga } from './saga';
import {
  IBulkSamplePlateCreate,
  ISamplePlateCreate,
  SamplePlatesState,
  SamplePlatesTableState,
} from './types';

export const initialState: SamplePlatesState = {
  data: { processing: false },
  create: { processing: false },
  bulckCreate: { processing: false },
  update: { processing: false },
  delete: { processing: false },
  tableState: {
    pageSize: 10,
    pageIndex: 0,
    sortBy: [{ id: 'Id', desc: true }],
    customFilters: [],
    globalFilter: '',
  },
};

const slice = createSlice({
  name: 'samplePlates',
  initialState,
  reducers: {
    get(state, action: PayloadAction<SamplePlatesTableState>) {
      state.data.processing = true;
      state.tableState = {
        pageSize: action.payload.pageSize,
        pageIndex: action.payload.pageIndex,
        sortBy: action.payload.sortBy,
        globalFilter: action.payload.globalFilter,
        serviceGroups:
          action.payload.serviceGroups?.length !== 0
            ? action.payload.serviceGroups
            : undefined,
        visibleColumns: action.payload.visibleColumns,
        customFilters: action.payload.customFilters?.map(filter => ({
          fieldName: filter.fieldName,
          operator: filter.operator,
          value: filter.value,
        })),
      };
    },
    get_Success(state, action: PayloadAction<any>) {
      state.data.processing = false;
      state.data.error = undefined;
      state.data.data = action.payload.value;
      state.data.dataLength = action.payload['@odata.count'];
    },
    get_Error(state, action: PayloadAction<any>) {},

    export(state, action: PayloadAction) {},

    getPlateTypes_Success(state, action: PayloadAction<Entity<number>[]>) {
      state.plateTypes = action.payload;
    },

    showCreate(
      state,
      action: PayloadAction<{ serviceGroups: Entity<number>[] }>,
    ) {
      state.create.open = true;
      state.create.initialValues = {
        IsRack: false,
        ServiceGroup:
          action.payload.serviceGroups.length === 1
            ? action.payload.serviceGroups[0]
            : undefined,
        SamplePlateType: state.plateTypes?.[0],
        Active: true,
      };
    },
    showBulkCreate(
      state,
      action: PayloadAction<{ serviceGroups: Entity<number>[] }>,
    ) {
      state.bulckCreate.open = true;
      state.bulckCreate.initialValues = {
        IsRack: false,
        ServiceGroup:
          action.payload.serviceGroups.length === 1
            ? action.payload.serviceGroups[0]
            : undefined,
        SamplePlateType: state.plateTypes?.[0],
        Active: true,
      };
    },
    hideCreate(state, action: PayloadAction) {
      state.create.processing = false;
      state.create.open = false;
    },
    hideBulkCreate(state, action: PayloadAction) {
      state.bulckCreate.processing = false;
      state.bulckCreate.open = false;
    },
    create(state, action: PayloadAction<ISamplePlateCreate>) {
      state.create.processing = true;
    },
    bulkCreate(state, action: PayloadAction<IBulkSamplePlateCreate>) {
      state.bulckCreate.processing = true;
    },
    bulkCreate_SetProgress(state, action: PayloadAction<number>) {
      state.bulckCreate.progress = action.payload;
    },
    create_Success(state, action: PayloadAction<ISamplePlateDto>) {
      state.create.processing = false;
      state.create.open = false;
    },
    bulkCreate_Success(state, action: PayloadAction) {
      state.bulckCreate.processing = false;
      state.bulckCreate.open = false;
    },
    create_Error(state, action: PayloadAction<any>) {},
    bulkCreate_Error(state, action: PayloadAction<any>) {},

    hideUpdate(state, action: PayloadAction) {
      state.update.processing = false;
      state.update.open = false;
    },
    edit(state, action: PayloadAction<ISamplePlateDto>) {
      state.update.processing = false;
      state.update.open = true;
      state.update.initialValues = {
        Id: action.payload.Id,
        Location: toEntity(
          action.payload.LocationId,
          action.payload.LocationName,
        ),
        Room: toEntity(action.payload.RoomId, action.payload.RoomName),
        SamplePlateType: toEntity(
          action.payload.SamplePlateTypeId,
          action.payload.SamplePlateTypeName,
        ),
        ServiceGroup: toEntity(
          action.payload.ServiceGroupId,
          action.payload.ServiceGroupName,
        ),
        IsRack: action.payload.IsRack,
        Active: action.payload.Active,
      };
    },
    update(
      state,
      action: PayloadAction<{
        original: ISamplePlateCreate;
        current: Partial<ISamplePlateCreate>;
      }>,
    ) {
      state.update.processing = true;
    },
    update_Success(state, action: PayloadAction<Partial<ISamplePlateDto>>) {
      state.update.processing = false;
      state.update.open = false;
      state.update.initialValues = undefined;
    },
    update_Error(state, action: PayloadAction<any>) {},

    delete(state, action: PayloadAction<string>) {
      state.delete.processing = true;
    },
    delete_Success(state, action: PayloadAction<ISamplePlateDto>) {},
    delete_Error(state, action: PayloadAction<any>) {},

    deactivatePlates(state, action: PayloadAction<ISamplePlateDto[]>) {},
    deactivatePlates_Success(state, action: PayloadAction) {},
    deactivatePlates_Error(state, action: PayloadAction) {},

    deletePlates(state, action: PayloadAction<ISamplePlateDto[]>) {},
    deletePlates_Success(state, action: PayloadAction) {},
    deletePlates_Error(state, action: PayloadAction) {},
  },
});

export const { actions: samplePlatesActions } = slice;

export const useSamplePlatesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: samplePlatesSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSamplePlatesSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
