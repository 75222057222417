import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { IconProp } from 'app/AllIcons';
import { TFunctionResult } from 'i18next';
import { translations } from 'locales/translations';
import {
  bindMenu,
  usePopupState,
  bindTrigger,
  anchorRef,
} from 'material-ui-popup-state/hooks';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook } from 'react-table';
import { IPathAndQuery, toRootedURL } from 'utils/url-utils';
import { IRow } from '.';
import { RouterLink } from '../BasicLinks/RouterLink';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import { Tooltip } from '../BasicTooltips/Tooltip';
import { CellRenderer } from './BasicTableProps';

export interface RowActionsProps {
  href?: string | IPathAndQuery;
  to?: string;
  text: string;
  icon: IconProp;
  title?: string;
  onClick?: () => Promise<void>;
}
export interface RowActionsMenuProps {
  id: string;
  items: RowActionsProps[];
  icon?: IconProp;
  tooltip?: React.ReactNode | TFunctionResult;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
const StyledItemIcon = withStyles({
  root: {
    minWidth: 'unset',
  },
})(ListItemIcon);
const RowMenuActions = React.forwardRef<any, RowActionsMenuProps>(
  function RowMenuActions(props, ref) {
    const { id, items } = props;
    const classes = useStyles();
    const popupState = usePopupState({
      variant: 'popper',
      popupId: id,
    });
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const toAbsolutePath = (href: string | IPathAndQuery) => {
      return typeof href === 'string'
        ? toRootedURL(href)
        : toRootedURL(
            (href as IPathAndQuery).path,
            (href as IPathAndQuery).search,
          );
    };
    React.useEffect(() => {
      if (popupState.isOpen) {
        setTooltipOpen(false);
      }
    }, [popupState.isOpen]);

    return (
      <div ref={anchorRef(popupState)} className={classes.root}>
        <Tooltip
          title={props.tooltip}
          open={tooltipOpen}
          onOpen={() => {
            setTooltipOpen(!popupState.isOpen);
          }}
          onClose={() => setTooltipOpen(false)}
        >
          <span>
            <IconButton
              variant="ghost"
              shape="square"
              aria-label="Open Menu"
              size="xs"
              {...bindTrigger(popupState)}
            >
              <Icon icon={props.icon ?? 'ellipsis-v'} />
            </IconButton>
          </span>
        </Tooltip>

        <Menu
          {...bindMenu(popupState)}
          ref={ref}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
        >
          {items.map((item, index) => {
            return item.href ? (
              <MenuItem
                dense
                href={toAbsolutePath(item.href)}
                component={'a'}
                key={index}
                ref={ref}
                onClick={popupState.close}
              >
                <StyledItemIcon>
                  <Icon icon={item.icon} />
                </StyledItemIcon>
                <ListItemText>{item.text}</ListItemText>
              </MenuItem>
            ) : item.to ? (
              <MenuItem
                dense
                component={RouterLink}
                to={item.to}
                key={index}
                ref={ref}
                onClick={popupState.close}
              >
                <StyledItemIcon>
                  <Icon icon={item.icon} />
                </StyledItemIcon>
                <ListItemText>{item.text}</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                dense
                key={index}
                ref={ref}
                onClick={async () => {
                  popupState.close();
                  if (item.onClick) {
                    await item.onClick();
                  }
                }}
              >
                <StyledItemIcon>
                  <Icon icon={item.icon} />
                </StyledItemIcon>
                <ListItemText>{item.text}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  },
);

export function RowActionsMenu(props: RowActionsMenuProps) {
  return <RowMenuActions {...props} />;
}
export function useRowActionsColumn<TRow extends IRow>(
  rowActions: CellRenderer<TRow>[] | undefined,
  tablePlugins: PluginHook<TRow>[],
) {
  const { t } = useTranslation();
  if (rowActions !== undefined) {
    if (rowActions.length > 0) {
      const actionColumns = rowActions?.map((column, index) => ({
        id: `ActionColumn_${index}`,
        Header: t(translations.Action),
        disableSortBy: true,
        Cell: column,
      }));
      tablePlugins.push(hooks => {
        hooks.visibleColumns.push(columns => [...columns, ...actionColumns]);
      });
    }
  }
}
export function useInlineRowActionsColumn<TRow extends IRow>(
  inlineRowActions: CellRenderer<TRow>[] | undefined,
  tablePlugins: PluginHook<TRow>[],
) {
  const { t } = useTranslation();
  if (inlineRowActions !== undefined) {
    if (inlineRowActions.length > 0) {
      const actionColumns = inlineRowActions?.map((column, index) => ({
        id: `InlineActionColumn_${index}`,
        Header: t(translations.Action),
        disableSortBy: true,
        Cell: column,
      }));
      tablePlugins.push(hooks => {
        hooks.visibleColumns.push(columns => [...columns, ...actionColumns]);
      });
    }
  }
}
