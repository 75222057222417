import styled from 'styled-components';
import { Theme } from '@material-ui/core/styles';

export const Section = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
  sidePanelWidth: number;
}>`
  &.formsection {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 32px;
    max-width: 100%;
    width: 100%;
    gap: ${props => props.theme.spacing(17)}px;
    &.shortView {
      .baseRootHeight & {
        flex-direction: row;
        padding: 0px 32px;
        width: 100%;
        gap: ${props => props.theme.spacing(17)}px;
      }
      flex-direction: column;
      padding: 0px 16px 0px 24px;
      width: ${props => props.sidePanelWidth}px;
      gap: ${props => props.theme.spacing(2)}px;
    }
    @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
      &.shortView {
        width: 100%;
      }
    }
  }
`;
export const LeftSection = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  &.leftsection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    padding: 0px;

    width: 100%;
    gap: ${props => props.theme.spacing(6)}px;
    &.shortView {
      gap: ${props => props.theme.spacing(3)}px;
      .baseRootHeight & {
        gap: ${props => props.theme.spacing(6)}px;
      }
    }
  }
`;
export const RightSection = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0px;
  width: 40%;
  gap: ${props => props.theme.spacing(6)}px;
  &.shortView {
    width: 100%;
    gap: ${props => props.theme.spacing(2)}px;
    padding-bottom: ${props => props.theme.spacing(3)}px;
    .baseRootHeight & {
      width: 40%;
      gap: ${props => props.theme.spacing(6)}px;
      padding-bottom: 0px;
    }
  }
`;

export const RowSection = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: `${theme.spacing(2)}px`,
  flex: '1 1 49%',
  '&.hideRow': {
    display: 'none',
  },
  '&.shortView': {
    flex: '1 1 100%',
  },
  '&.fullRow': {
    flex: '1 1 100%',
  },
  '&.minRow': {
    flex: '1 1 25%',
    [theme.breakpoints.down('md')]: {
      flex: '1 1 40%',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 40%',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '1 1 100%',
    },
    // [theme.breakpoints.down('sm')]: {
    //   flex: '1 1 100%',
    // },
  },
}));

export const FormFieldsBox = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: ${props => props.theme.spacing(1.5)}px;
  & .sectiontitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${props => props.theme.spacing(0, 0, 0.5)};
    gap: ${props => props.theme.spacing(2)}px;
  }
  & .fields {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    gap: ${props => props.theme.spacing(2)}px;
  }
`;
