/**
 *
 * Asynchronously loads the component for AnimalCasePage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AnimalCaseEditPage = lazyLoad(
  () => import('./index'),
  module => module.AnimalCaseEditPage,
);
