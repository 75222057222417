import { ITrainingSessionSlotDto } from 'api/odata/generated/entities/ITrainingSessionSlotDto';
import { Icon, IconProps } from 'app/components/BasicIcons/FontAwesome';
import { TrainingState, TrainingStateUnion } from 'enums/TrainingState';
import { assertExhaustive } from 'utils/assertExhaustive';
import { ISchedulerEvent } from '../../types/ISchedulerEvent';

export interface TrainingSessionStatusProps extends Omit<IconProps, 'icon'> {
  event: ISchedulerEvent;
}
export function TrainingSessionStatus({
  event,
  size,
  className,
}: TrainingSessionStatusProps) {
  const training = event.original as ITrainingSessionSlotDto;
  const stateid = training.TrainingState;
  if (stateid === undefined) {
    return null;
  }
  const state = TrainingState[stateid] as TrainingStateUnion;
  switch (state) {
    case 'SignedUp':
      return <Icon icon="calendar-check" size={size} className={className} />;
    // icon = icon.replace(":title", '<%= Bookit.CustomResourceProviderFactory.GetJsString("TrainingSessions_Calendar_SignedUp") %>');
    case 'UnderMinimum':
      return (
        <Icon icon="calendar-exclamation" size={size} className={className} />
      );
    // icon = icon.replace(":title", '<%= Bookit.CustomResourceProviderFactory.GetJsString("TrainingSessions_Calendar_UnderMinimum") %>');
    case 'Available':
      return <Icon icon="calendar-plus" size={size} className={className} />;
    // icon = icon.replace(":title", '<%= Bookit.CustomResourceProviderFactory.GetJsString("TrainingSessions_Calendar_Available") %>');
    case 'Maximum':
      return <Icon icon="calendar-times" size={size} className={className} />;
    // icon = icon.replace(":title", '<%= Bookit.CustomResourceProviderFactory.GetJsString("TrainingSessions_Calendar_Maximum") %>');
    default:
      assertExhaustive(state);
  }
}
