import { ViewTypes } from '../slice/types';

export const parseType = (s?: ViewTypes) => {
  if (s === undefined) {
    return undefined;
  }
  switch (s) {
    case 'calendar':
    case 'timeline':
      return s;
    default:
      return undefined;
  }
};
