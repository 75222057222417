import { IEditEvent } from './slice/types';
import { dateUtils } from 'utils/date-utils';
import { omit } from 'lodash';
import { ISchedulerEvent } from './types/ISchedulerEvent';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';

/**
 * Transforms the scheduler event to common form:
 * * dates are converted to timestamps (string)
 * * assembly part reservations are redirected to top assembly reservations
 * @param event internal scheduler's event
 * @returns event consumable by the reservation details components
 */
export const transformToSerializableEvent = (
  event: ISchedulerEvent,
): IEditEvent => {
  const TopAssemblyReservationId = (event.original as ICalendarReservationDto)
    .TopAssemblyReservationId;
  /**
   * Clicking on the assembly part reservation should open the top assembly reservation
   */
  const reservation_id = TopAssemblyReservationId ?? event.reservation_id;
  const result = {
    ...omit(event, 'start_date', 'end_date'),
    ...{
      start_date: dateUtils.formatISO(event.start_date),
      end_date: dateUtils.formatISO(event.end_date),
      reservation_id: reservation_id,
    },
  };
  return result;
};
