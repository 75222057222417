/**
 *
 * ButtonConfirm
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { DialogConfirm } from 'app/components/DialogConfirm';

export interface ButtonConfirmProps extends Omit<ButtonProps, 'onClick'> {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  confirmationDialogTitle?: string;
  confirmationDialogBody?: string;
  disabled?: boolean;
}

/**
 * Button with a confirmation dialog
 * @param param0 primary click handler
 * @returns
 */
export function ButtonConfirm({
  onClick,
  confirmationDialogTitle,
  confirmationDialogBody,
  ...props
}: ButtonConfirmProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const title = confirmationDialogTitle ?? t(translations.Confirm);
  const body = confirmationDialogBody ?? t(translations.AreYouSure);
  const handleConfirmClick = e => {
    setOpen(false);
    onClick(e);
  };
  return (
    <>
      <Button {...props} onClick={() => setOpen(true)} />
      <DialogConfirm
        isOpen={open}
        title={title}
        body={body}
        onCancel={() => setOpen(false)}
        onConfirm={handleConfirmClick}
      />
    </>
  );
}
