import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { IUserTrainingDto } from 'api/odata/generated/entities/IUserTrainingDto';
import { AsyncDataState, Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { dateUtils } from 'utils/date-utils';
import { toEntity } from 'utils/entity-utils';
import { toNumberOrNull } from 'utils/typeUtils';

/* --- STATE --- */
export interface TrainingReservationsState {
  showApproveReservations: boolean;
  showRejectReservations: boolean;
  ApproveRejectRecords: number[];
  processing: boolean;
  completed?: boolean;
}
export interface TrainingState {
  createState: AsyncDataState<TrainingDetailsState>;
  updateState: AsyncDataState<TrainingDetailsState>;
  reservationsUpdateState: TrainingReservationsState;
  processing: boolean;
}

export interface TrainingDetailsState {
  Id: number | null | undefined;
  Users: Entity<string>[];
  Equipments: Entity<number>[];
  UnhideServiceGroup: boolean | null | undefined;
  TrainingDate: Date | string | null | undefined;
  ExpiredDate: Date | string | null | undefined;
  Trainer: Entity<string> | null | undefined;
  MustOrderEveryDays: number | null | undefined;
  MustUseEveryDays: number | null | undefined;
  RequiresAdminApproval: boolean | null | undefined;
  AllowBookingFromTrainingDate: boolean | null | undefined;
  Reservations: Entity<number> | null | undefined;
  TrainingLevel: Entity<number> | null | undefined;
  Remarks?: string | null;
  Active: boolean | null | undefined;
  FatherId: number | null | undefined;
  ServiceGroupId: number | null | undefined;
  TrainingProgram: Entity<number> | null | undefined;
}

export interface TrainingsResponse extends IResponseType {
  ShowApproveReservationsMessage: boolean;
  ShowReviseToPendingMessage: boolean;
  ApproveRejectRecords: number[];
  Id?: number;
}
export function ConvertEntityToModel(
  entity: IUserTrainingDto,
): TrainingDetailsState | undefined {
  if (!entity) {
    return undefined;
  } else {
    return {
      Id: entity.Id,
      Active: entity.Active,
      AllowBookingFromTrainingDate: entity.AllowBookingFromTrainingDate,
      Equipments: [
        {
          Id: entity.EquipmentId,
          Name: entity.EquipmentName,
          ServiceGroupId: entity.ServiceGroupId,
          ServiceTypeId: 1,
        } as IServiceFilterDto,
      ] as Entity<number>[],
      ExpiredDate:
        entity.ExpiredDate === null
          ? null
          : dateUtils.formatISO(dateUtils.parseISO(entity.ExpiredDate), {
              representation: 'date',
            }),
      FatherId: null,
      MustOrderEveryDays: entity.MustOrderEveryDays,
      MustUseEveryDays: entity.MustUseEveryDays,
      Remarks: entity.Remarks,
      RequiresAdminApproval: entity.RequiresAdminApproval,
      Reservations:
        entity.ReservationId == null
          ? null
          : toEntity(
              entity.ReservationId,
              dateUtils.shortDateTimeFormat(entity.ReservationStart) +
                ' - ' +
                dateUtils.shortDateTimeFormat(entity.ReservationEnd),
            ),
      Trainer: toEntity(entity.TrainerId, entity.TrainerName) as IUserFilterDto,
      TrainingDate: dateUtils.formatQueryStringDate(
        dateUtils.dateOrStringToDate(entity.TrainingDate),
        {
          representation: 'date',
        },
      ),
      TrainingLevel: toEntity(entity.TrainingLevelId, entity.TrainingLevelName),
      Users: [{ Id: entity.UserId, Name: entity.UserDisplayName }],
      ServiceGroupId: entity.ServiceGroupId,
      UnhideServiceGroup: entity.UnhideServiceGroup,
      TrainingProgram: toEntity(
        entity.TrainingProgramId,
        entity.TrainingProgramName,
      ),
    } as TrainingDetailsState;
  }
}
export function ConvertModelToEntity(
  model: TrainingDetailsState,
  original: TrainingDetailsState | undefined,
): IUserTrainingDto[] {
  let entities = [] as IUserTrainingDto[];
  model.Equipments.forEach(eq => {
    model.Users.forEach(ur => {
      entities.push({
        Id:
          original === undefined
            ? -1
            : original?.Equipments[0].Id === eq.Id &&
              original.Users[0].Id === ur.Id
            ? model.Id ?? -1
            : -1,
        EquipmentId: eq.Id,
        EquipmentName: eq.Name,
        ServiceGroupId: (eq as IServiceFilterDto).ServiceGroupId ?? null,
        UserId: ur.Id,
        UserDisplayName: ur.Name,
        Active: model.Active ?? false,
        AllowBookingFromTrainingDate:
          model.AllowBookingFromTrainingDate ?? true,
        ExpiredDate:
          model.ExpiredDate === null || model.ExpiredDate === undefined
            ? null
            : dateUtils.formatISO(
                dateUtils.dateOrStringToDate(model.ExpiredDate),
              ),
        MustOrderEveryDays: toNumberOrNull(model.MustOrderEveryDays),
        MustUseEveryDays: toNumberOrNull(model.MustUseEveryDays),
        Remarks: model.Remarks ?? null,
        RequiresAdminApproval: model.RequiresAdminApproval ?? false,
        ReservationId: model.Reservations?.Id ?? null,
        TrainerId: model.Trainer?.Id ?? null,
        TrainingDate: dateUtils.formatISO(
          dateUtils.dateOrStringToDate(model.TrainingDate || new Date()),
        ),
        TrainingLevelId: model.TrainingLevel?.Id ?? null,
        TrainingLevelName: model.TrainingLevel?.Name ?? null,
        FatherId: model.FatherId ?? null,
        UnhideServiceGroup: model.UnhideServiceGroup ?? false,
        TrainingProgramId: model.TrainingProgram?.Id ?? null,
        TrainingProgramName: model.TrainingProgram?.Name ?? null,
      } as IUserTrainingDto);
    });
  });
  return entities.filter(item => item !== undefined && item !== null);
}
