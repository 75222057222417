import { Box, Divider } from '@material-ui/core';
import { BookitlabShortLogo } from 'app/components/BookitlabLogo/BookitlabShortLogo';
import { Link } from 'app/components/ExternalLink';
import { selectWebVersion } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useStyles } from '../styles';

export interface SideBarFooterProps {
  isOpen?: boolean;
}

export const SideBarFooter = React.memo(function SideBarFooter(
  props: SideBarFooterProps,
) {
  const classes = useStyles({ isOpen: props.isOpen ?? false });
  const { t } = useTranslation();
  const webVersion = useSelector(selectWebVersion);
  return (
    <React.Fragment>
      <Divider />
      <div className={classes.sideBarFooter}>
        <BookitlabShortLogo />
        <Box>{`V: ${webVersion} `}</Box>
        <Box>
          <Link href="/TermsOfUseAgreement.aspx" target="_blank">
            {t(translations.TermsOfUse)}
          </Link>
        </Box>
      </div>
    </React.Fragment>
  );
});
