import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useRequestSamplesSlice } from '../../slice';
import { useDispatch } from 'react-redux';
import { ButtonConfirm } from 'app/components/ButtonConfirm';
import { Icon } from 'app/components/BasicIcons/FontAwesome';

export interface ICancelServiceRequestButtonProps {
  serviceRequestId?: number;
}
export function CancelServiceRequestButton(
  props: ICancelServiceRequestButtonProps,
) {
  const { t } = useTranslation();
  const { actions } = useRequestSamplesSlice();
  const dispatch = useDispatch();
  const handleClick = () => {
    if (props.serviceRequestId !== undefined) {
      dispatch(
        actions.cancelServiceRequest({
          serviceRequestId: props.serviceRequestId,
        }),
      );
    }
  };
  return (
    <ButtonConfirm
      size="small"
      startIcon={<Icon icon="times" />}
      aria-label="history"
      onClick={handleClick}
      variant="white"
      title={t(translations.CancelRequest)}
      confirmationDialogBody={t(translations.AreYouSure)}
    >
      {t(translations.CancelRequest)}
    </ButtonConfirm>
  );
}
