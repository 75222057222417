import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';
import { Dto, ISection } from './types';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';

const selectSlice = (state: RootState) =>
  state.serviceRequestsKanban || initialState;

export const selectServiceRequestsKanban = createSelector(
  [selectSlice],
  state => state,
);

export const selectServiceRequestsKanbanProcessingUpdate = createSelector(
  [selectSlice],
  state => state.processingUpdate,
);
export const selectServiceRequestsKanbanProcessingInsert = createSelector(
  [selectSlice],
  state => state.processingInsert,
);

export const selectServiceRequestsKanbanTickets = createSelector(
  [selectServiceRequestsKanban],
  state => state.data,
);
export const selectServiceRequestsKanbanSelectedStatuses = createSelector(
  [selectSlice],
  state => state.selectedStatuses,
);
export const selectServiceRequestsKanbanData = createSelector(
  [
    selectServiceRequestsKanbanSelectedStatuses,
    selectServiceRequestsKanbanTickets,
  ],
  (selectedStatuses, tickets) => {
    if (selectedStatuses !== undefined) {
      if (selectedStatuses.some(f => f.Name !== undefined)) {
        return selectedStatuses.map(status => {
          const dataList = tickets[status.Id.toString()];
          const b: ISection<IServiceRequestStatusDto, Dto> = {
            id: status.Id,
            section: status,
            data: dataList,
          };
          return b;
        });
      }
    }
  },
);

export const selectShowAddSubTicket = createSelector(
  [selectSlice],
  state => state.showAddSubTicket,
);
export const selectParentTicket = createSelector(
  [selectSlice],
  state => state.parentTicket,
);
export const selectEdit = createSelector([selectSlice], state => state.ticket);
