/**
 *
 * ServiceTypePicker
 *
 */
import { httpClient } from 'api/HttpClient';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import { Entity } from 'types/common';
import { IApplyGlobalServiceGroupFilter } from 'types/IApplyGlobalServiceGroupFilter';
import { IServiceGroupRelated } from 'types/IServiceGroupRelated';

interface IServiceDto extends Entity<number>, IServiceGroupRelated {}

export interface ServiceTypePickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'>,
    IApplyGlobalServiceGroupFilter {}

const url = '/api/odata/v4/ServiceTypeFilter';

export function withServiceGroupFilter(
  globalServiceGroupFilter: Entity<number>[],
) {
  return (searchTerm: string | null) => {
    const params: { $orderby: string; $filter?: string } = {
      $orderby: 'Name asc',
    };

    params.$filter = new ODataFilterBuilder<IServiceDto>({
      predicates: [],
      stringColumns: ['Name'],
      stringSearch: searchTerm === null ? undefined : searchTerm,
      globalServiceGroupFilter:
        !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
          ? [
              new Condition<IServiceDto>(
                'ServiceGroupId',
                ODataOperators.Includes,
                globalServiceGroupFilter.map(f => f.Id),
              ),
            ]
          : [],
    }).toString();

    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  };
}
export const initServiceTypeData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name,ServiceGroupId',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};

export function ServiceTypePicker(props: ServiceTypePickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const loadData = withServiceGroupFilter(globalServiceGroupFilter || []);

  return (
    <AutocompletePicker
      loadData={loadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'servicetypepickerId'}
      additionalItem={props.additionalItem}
      {...props}
    />
  );
}
