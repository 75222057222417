import { Theme } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';
import { SIDE_PANEL_WIDTH } from 'styles/constantVariables';
import { verticalScrolling } from 'styles/CssVariables/styledVariables';
// width: 370px;
//     left: calc(100vw - 370px);
// transition: ${transitions.create('width', {
//   easing: transitions.easing.sharp,
//   duration: 500,
// })};
export const LayoutRoot = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  direction: ltr;
  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    display: flex;
  }
  & .sidePanelRoot {
    height: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
  & .sidePanelMobileRoot {
    height: 0;
    position: absolute;
    top: 50px;
    left: 0;
    max-height: calc(100vh - 50px);
    width: 100%;
  }
  & .backdropOpen {
    height: 50px;
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    background: ${bookitColors.overlays.overlayBlack};
  }
  & .sidePanelRootExpanded {
    width: 100%;
    transition: ${transitions.create('width', {
      easing: transitions.easing.easeInOut,
      duration: 500,
    })};
  }
  & .sidePanelRootCollapsed {
  }
`;
export const Main = styled.main.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  direction: ltr;
  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    display: flex;
  }

  flex-grow: 1;
  min-height: 100vh;
  background: ${props => props.theme.palette.background.paper};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0;
  overflow: hidden;
  height: 100vh;
  ${verticalScrolling}
  &.shiftLeft {
    margin-right: ${SIDE_PANEL_WIDTH}px;
  }
  & .appBarSpacer {
    min-height: 48px;
  }
  & .container {
    padding: 0px;
    position: relative;
    background-color: ${props => props.theme.palette.background.paper};
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    overflow-y: auto;
    overflow-x: hidden;
    &.mobileOpen {
      position: absolute;
    }
    &.useselectable {
      overflow-y: hidden;
    }
  }
  & .footer {
    margin-top: auto;
    & .copyRight {
      padding-top: 8px;
      margin-top: auto;
      margin-inline: '8px';
    }
  }
`;
