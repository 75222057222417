import {
  IComment,
  NewComment,
} from 'app/components/CommentsContainer/IComment';
import { httpClient } from './HttpClient';
const url = '/api/odata/v4/Comments';
export const CommentsApi = {
  GetComments: (type: number, reference: number) => {
    return httpClient
      .get(url, {
        reference,
        type,
        $expand: ['Files', 'CreatedBy'].join(','),
      })
      .then(v => v.value as IComment[]);
  },
  SendComment: (
    typeId: number,
    referenceId: number,
    { Files, Text, IsInternal }: Omit<NewComment, 'Users'>,
  ) => {
    const form = new FormData();
    for (let i = 0; !!Files && i < Files?.length; i++) {
      let item = Files?.item(i);
      if (!!item) {
        form.append('file' + i, item);
      }
    }
    form.append('TypeId', typeId as any);
    form.append('ReferenceId', referenceId as any);
    form.append('Text', Text ?? '');
    form.append('IsInternal', !!IsInternal as any);

    return httpClient.post(url, form);
  },
  removeFile: (id: string) => {
    const delteUrl = `/odata.svc/Files(guid'${id}')`;
    return httpClient.delete(delteUrl);
  },
};
