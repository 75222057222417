import { httpClient } from './HttpClient';
import { IUserTrainingDto } from './odata/generated/entities/IUserTrainingDto';

export const UserTrainingApi = {
  initCreateData: (payload: {
    equId?: string | null;
    equIds?: string | null;
    fatherId?: string | null;
    user?: string | null;
    rid?: string | null;
    date?: string | null;
  }) => {
    const url = `/api/odata/v4/usertrainings/initNewTraining`;
    return httpClient.post(url, payload);
  },
  initUpdateData: (payload: { Id: number }) => {
    const url = '/api/odata/v4/usertrainings';
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Id asc',
      $filter: '(Id eq ' + payload.Id + ')',
      $select:
        'Id,AttachmentExist,EquipmentId,UserGroupId,AllowBookingFromTrainingDate,MustOrderEveryDays,MustUseEveryDays,ReservationName,TrainerId' +
        ',AssetId,UserDisplayName,UserId,ServiceGroupId,ExpiredDate,UserGroupName,Active,Remarks,RequiresAdminApproval,ReservationId,TrainingLevelName,TrainingLevelId,TrainerName' +
        ',TrainingDate,EquipmentName,ServiceGroupName,ReservationStart,ReservationEnd',
      $skip: 0,
      $top: 1,
    };
    return httpClient.get(url, params);
  },

  submitTrainings: (payload: IUserTrainingDto[]) => {
    const data = {
      trainings: payload,
    };
    const url = '/api/odata/v4/usertrainings/SubmitTrainings';
    return httpClient.post(url, data);
  },
  approveRejectReservations: (payload: {
    trainings: number[];
    approve: boolean;
  }) => {
    const data = {
      rows: payload.trainings,
    };
    const url = payload.approve
      ? `/api/odata/v4/usertrainings/ApproveReservations`
      : `/api/odata/v4/usertrainings/RejectReservations`;
    return httpClient.post(url, data);
  },
  hasUntrainedNotAllowed: (payload: { services: string; users: string }) => {
    const url = `/api/odata/v4/usertrainings/UntrainedNotAllowed`;
    return httpClient.post(url, payload);
  },
  activateTraining: (payload: { Id?: number; activate?: boolean }) => {
    const url = `/api/odata/v4/usertrainings/ActivateDeactivateTraining`;
    return httpClient.post(url, payload);
  },
};
