/**
 *
 * auto generated interface for Bookit.Data.InternalServiceRequestStatus
 *
 */

export enum InternalServiceRequestStatus {
  Draft = 0,
  WaitingForQuote = 1,
  QuoteSendToUser = 2,
  QuoteApproved = 3,
  Completed = 5,
  Cancelled = 6,
  InProcess = 7,
  ReturnedToUser = 8,
  Pending = 9,
  Started = 10,
  Skipped = 11,
}
export type InternalServiceRequestStatusUnion = keyof typeof InternalServiceRequestStatus;
export const InternalServiceRequestStatusUnionArray: Array<InternalServiceRequestStatusUnion> = [
  'Draft',
  'WaitingForQuote',
  'QuoteSendToUser',
  'QuoteApproved',
  'Completed',
  'Cancelled',
  'InProcess',
  'ReturnedToUser',
  'Pending',
  'Started',
  'Skipped',
];
