/**
 *
 * FormServiceRequestStatusPicker
 *
 */

import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { ServiceRequestStatusPicker } from '../pickers/AutocompletePickers/ServiceRequestStatusPicker';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';

type optionType = IServiceRequestStatusDto;
export const FormServiceRequestStatusPicker = ({
  label,
  ...props
}: FieldHookConfig<optionType | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<optionType | null>(props);
  return (
    <>
      <ServiceRequestStatusPicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        variant="filled"
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        info={props.info}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        predicates={props.predicates}
      />
    </>
  );
};
