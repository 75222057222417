import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { MenuItem } from '@material-ui/core';

export interface ExportToPDFProps {
  onClick: () => void;
}

export function ExportToPDF(props: ExportToPDFProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  return (
    <MenuItem onClick={props.onClick} title={t(translations.ExportToPDF)}>
      <Icon icon={'file-pdf'} />
      {t(translations.ExportToPDF)}
    </MenuItem>
  );
}
