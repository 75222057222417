/**
 *
 * CatRateTypePicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { ICatRateTypeFilterDto } from 'api/odata/generated/entities/ICatRateTypeFilterDto';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import * as React from 'react';
import { Entity } from 'types/common';

export interface CatRateTypeProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'> {
  /**
   * Set this to "true" if only "Manual" types need to be displayed
   *
   */
  manual?: boolean;
  bySystemSetting?: boolean;
}

const url = '/api/odata/v4/CatRateTypeFilter';
const systemUrl = url + '/WithSystemSetting';
export const initCatRateTypeData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const getLoadData = (manual: boolean, bysSystemSetting?: boolean) => (
  searchTerm: string | null,
): Promise<Entity<number>[]> => {
  const params: { $orderby: string; $filter?: string } = {
    $orderby: 'Name asc',
  };

  const predicates: Condition<ICatRateTypeFilterDto>[] = [
    new Condition<ICatRateTypeFilterDto>('Active', ODataOperators.Equals, true),
  ];
  if (manual) {
    predicates.push(
      new Condition<ICatRateTypeFilterDto>(
        'Manual',
        ODataOperators.Equals,
        true,
      ),
    );
  }
  params.$filter = new ODataFilterBuilder<ICatRateTypeFilterDto>({
    predicates: predicates,
    stringColumns: ['Name'],
    stringSearch: searchTerm ?? undefined,
  }).toString();

  return httpClient
    .get(bysSystemSetting ? systemUrl : url, params)
    .then(response => response.value as Entity<number>[]);
};
export function CatRateTypePicker({
  manual,
  bySystemSetting,
  ...props
}: CatRateTypeProps) {
  const loadData = React.useCallback(
    (search: string | null) =>
      getLoadData(manual === true, bySystemSetting)(search),
    [bySystemSetting, manual],
  );
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'catRateTypeId'}
      loadData={loadData}
      {...props}
    />
  );
}
