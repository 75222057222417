/**
 *
 * WorkOrderPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import * as React from 'react';
import { Entity } from 'types/common';

export interface WorkOrderPickerProps
  extends Omit<AutocompletePickerProps<IWorkOrderDetailsDto>, 'loadData'> {
  name?: string;
  placeholder?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  error?: boolean;
  helperText?: string;
  useSearchOrPredicate?: boolean;
}

const url = '/api/odata/v4/WorkOrders';
export const initWorkOrdersData = (
  initval: string | undefined,
  select?: (keyof IWorkOrderDetailsDto)[],
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
      $select: string;
      $expand?: string;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $skip: 0,
      $top: 1,
      $select: !!select
        ? select.join(',')
        : [
            'Id',
            'Name',
            'AssetName',
            'AssetId',
            'ServiceGroup',
            'ServiceGroupId',
            'ServiceId',
            'ServiceTypeId',
            'AssetCatId',
            'AssetCatGroupId',
            'AssetCatName',
            'AssetCatGroupName',
            'AlertTypeId',
            'AlertTitle',
            'AlertDescription',
            'StatusName',
            'AlertType',
            'EventStart',
            'ReportedBy',
            'ReportedByDisplayName',
            'LastUpdated',
            'Reservation',
            'AlertOfflineTime',
            'OfflineEventEnd',
            'OfflineEventStart',
            'OfflineRemarks',
            'ReasonTypeId',
            'ReasonTypeName',
            'StatusId',
            'IsClosed',
            'UserTicket',
            'InstrumentWasDown',
            'IsAutoCreatedType',
            'AlertStart',
            'CreateDate',
            'DoneDate',
            'RecurringGroupId',
            'RecurringAssetId',
            'WorkOrderType',
            'StaffOnly',
            'WorkingHoursId',
            'ShowOnCalibrationHistory',
            'NotEditable',
          ].join(','),
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};

const loadData = (
  predicates: (string | Condition<IWorkOrderDetailsDto>)[] | undefined,
  queryUrl?: string,
  useSearchOrPredicate?: boolean,
) =>
  getAutoCompleteLoadDataFn<IWorkOrderDetailsDto>({
    url: url ?? queryUrl,
    predicates: predicates,
    select: [
      'Id',
      'Name',
      'AssetName',
      'AssetId',
      'ServiceGroup',
      'ServiceGroupId',
      'ServiceId',
      'ServiceTypeId',
      'AssetCatId',
      'AssetCatGroupId',
      'AssetCatName',
      'AssetCatGroupName',
      'AlertTypeId',
      'AlertTitle',
      'AlertDescription',
      'StatusName',
      'AlertType',
      'EventStart',
      'ReportedBy',
      'ReportedByDisplayName',
      'LastUpdated',
      'Reservation',
      'AlertOfflineTime',
      'OfflineEventEnd',
      'OfflineEventStart',
      'OfflineRemarks',
      'ReasonTypeId',
      'ReasonTypeName',
      'StatusId',
      'IsClosed',
      'UserTicket',
      'InstrumentWasDown',
      'IsAutoCreatedType',
      'AlertStart',
      'CreateDate',
      'DoneDate',
      'RecurringGroupId',
      'RecurringAssetId',
      'WorkOrderType',
      'StaffOnly',
      'WorkingHoursId',
      'ShowOnCalibrationHistory',
      'NotEditable',
    ],
    useSearchOrPredicate: useSearchOrPredicate,
  });
export function WorkOrderPicker(props: WorkOrderPickerProps) {
  const innerLoadData = (predicates, queryUrl) =>
    loadData(props.predicates, queryUrl, props.useSearchOrPredicate);
  const queryUrl = '/api/odata/v4/WorkOrders';

  return (
    <AutocompletePicker
      loadData={innerLoadData(props.predicates, queryUrl)}
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'WorkOrdersId'}
      {...props}
    />
  );
}
export const WorkOrdersCalibrationFilter = (
  assetId?: number,
  woTypeId?: number,
  excludeIds?: number[],
) => {
  const predicates: (string | Condition<IWorkOrderDetailsDto>)[] = [];
  if (!!assetId) {
    predicates.push(
      new Condition<IWorkOrderDetailsDto>(
        'AssetId',
        ODataOperators.Equals,
        assetId,
      ),
    );
  }
  if (!!woTypeId) {
    predicates.push(
      new Condition<IWorkOrderDetailsDto>(
        'AlertTypeId',
        ODataOperators.Equals,
        woTypeId,
      ),
    );
  }
  if (!!excludeIds && excludeIds?.length > 0) {
    predicates.push(
      new Condition<IWorkOrderDetailsDto>(
        'Id',
        ODataOperators.Excludes,
        excludeIds,
      ),
    );
  }
  predicates.push(
    new Condition<IWorkOrderDetailsDto>(
      'IsClosed',
      ODataOperators.NotEquals,
      true,
    ),
  );
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
};
export const loadWorkOrders = loadData;
