import { dateUtils } from 'utils/date-utils';
import { IPathAndQuery } from 'utils/url-utils';
import { PeriodTypes } from 'app/components/DatePeriods';

/**
 * Filter parameters must match the reservations filter settings
 * {@see app/pages/ReservationHistoryPage/Filter}
 */
type getReservationsLinkProps = {
  period?: PeriodTypes;
  startDate?: string | Date;
  endDate?: string | Date;
  serviceId?: number | null;
  trainingSignUp?: boolean;
  TrainingSessionId?: number;
};

/**
 * Helper function to get a link to reservations list page
 * @param session
 * @param newDate
 * @returns
 */
export function getReservationsLink(
  props: getReservationsLinkProps,
): IPathAndQuery {
  return {
    path: '/reservations',
    search: {
      period: props.period,
      st:
        props.startDate === undefined
          ? undefined
          : dateUtils.formatQueryStringDate(
              dateUtils.dateOrStringToDate(props.startDate),
            ),
      et:
        props.endDate === undefined
          ? undefined
          : dateUtils.formatQueryStringDate(
              dateUtils.dateOrStringToDate(props.endDate),
            ),
      eid: props.serviceId,
      trainingSignUp: props.trainingSignUp,
      TrainingSessionId: props.TrainingSessionId,
    },
  };
}
