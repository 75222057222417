/**
 *
 * SubLocationListPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { ISubLocationListDto } from 'api/odata/generated/entities/ISubLocationListDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity } from 'types/common';

export interface SubLocationListPickerProps
  extends Omit<AutocompletePickerProps<ISubLocationListDto>, 'loadData'> {}

const url = '/api/odata/v4/SubLocationList';
export const initSubLocationListData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<ISubLocationListDto[]>((resolve, reject) => {
      resolve([] as ISubLocationListDto[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as ISubLocationListDto[]);
  }
};

const loadData = (
  predicates: (string | Condition<ISubLocationListDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
) =>
  getAutoCompleteLoadDataFn<ISubLocationListDto>({
    url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'LocationListId',
      'LocationListLocationType',
      'LocationListLocationType1Name',
      'LocationListName',
      'LocationType',
      'LocationTypeId',
      'RoomId',
      'RoomName',
      'ServiceGroups',
    ],
    globalServiceGroupFilter:
      globalServiceGroupFilter?.map(
        serviceGroup =>
          new Condition<ISubLocationListDto>(
            'ServiceGroups',
            ODataOperators.AnyIn,
            serviceGroup.Id,
            'Id',
          ),
      ) ?? [],
  });

export function SubLocationListPicker(props: SubLocationListPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(
    props.predicates,
    globalServiceGroupFilter || [],
  );

  return (
    <AutocompletePicker
      size={props.size}
      loadData={innerLoadData}
      id={props.id || 'sublocationlistId'}
      {...props}
    />
  );
}
