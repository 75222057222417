/**
 *
 * EditArticleDetailsForm
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import * as Yup from 'yup';
import { selectArticleDetailsForm, selectArticles } from '../slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { Formik } from 'formik';
import {
  FormFieldsSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormAssetsPicker } from 'app/components/Forms/FormAssetsPicker';
import { useArticlesSlice } from '../slice';
import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { Button } from 'app/components/BasicButtons/Button';
import { Box } from 'app/components/basic/Wrappers/Box';
import { useTranslation } from 'react-i18next';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { InputLabel } from 'app/components/Typography';
import { ValueLabel } from 'app/components/ValueLabel';
import { Entity } from 'types/common';
import { FormUserPicker } from 'app/components/Forms/FormUserPicker';
import { FormAssetPicker } from 'app/components/Forms/FormAssetPicker';
import { FormListener } from 'app/components/Forms/FormRender/FormRenderer';
import {
  assetSchema,
  assetsSchema,
  GetInstrumentFilter,
  IArticleNewEditForm,
} from '../utils';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { ArticleStatus } from 'enums/Article';
import { EntityNumberSchema } from 'app/components/Forms/Schemas';
import { FormCustomPicker } from 'app/components/Forms/FormCustomPicker';
import { useAllowedToChangeStatus } from '../Hooks/useAllowedToChangeStatus';

export interface EditArticleDetailsFormProps extends CoverProps {
  useSidePanel: boolean;
  value: IArticleDto;
  onClose?: () => void;
  Journal?: string | null;
  PageNumber?: number | null;
}
export function EditArticleDetailsForm(props: EditArticleDetailsFormProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const state = useSelector(selectArticles);
  const articleDetailsForm = useSelector(selectArticleDetailsForm);
  //const authenticatedUser = useSelector(selectAuthenticatedUser);
  const { actions: layoutActions } = useLayoutSlice();
  const [canEditStatus, setCanEditStatus] = React.useState<boolean>(false);
  const allowedToChangeStatus = useAllowedToChangeStatus();
  const schema: Yup.SchemaOf<IArticleNewEditForm> = Yup.object().shape(
    {
      Title: Yup.string().required().label(t(translations.Title)).trim(),
      Authors: Yup.string().required().label(t(translations.Authors)).trim(),
      Abstract: Yup.string().required().label(t(translations.Abstract)).trim(),
      Doi: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.Doi))
        .trim(),
      ISSN: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.ISSN))
        .trim(),
      ORCIDs: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.ORCID))
        .trim(),
      Year: Yup.number().required().label(t(translations.Year)).min(1),
      RelatedUser: Yup.mixed().nullable().notRequired(),
      /*ServiceGroupAssets: assetsSchema.when(['InstrumentAssets'], {
        is: v => (v === undefined ? true : v.length === 0),
        then: assetsSchema
          .required()
          .label(t(translations.ServiceGroup))
          .min(1)
        otherwise: assetsSchema.notRequired(),
      }),*/
      ServiceGroupAsset: assetSchema.when(['InstrumentAssets'], {
        is: v => (v === undefined ? true : v.length === 0),
        then: assetSchema
          .required()
          .label(t(translations.ServiceGroup))
          .nullable(),
        otherwise: assetSchema.nullable().notRequired(),
      }),
      /*InstrumentAssets: assetsSchema.when(['ServiceGroupAssets'], {
        is: v => (v === undefined ? true : v.length === 0),
        then: assetsSchema.required().label(t(translations.Instruments)).min(1),
        otherwise: assetsSchema.notRequired(),
      }),*/
      InstrumentAssets: assetsSchema.when(['ServiceGroupAsset'], {
        is: v => v === null,
        then: assetsSchema.required().label(t(translations.Instruments)).min(1),
        otherwise: assetsSchema.notRequired(),
      }),
      Status: EntityNumberSchema.required().label(
        t(translations.ArticleStatus),
      ),
      Journal: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.Journal))
        .trim(),
      PageNumber: Yup.number().nullable().default(null),
    },
    [['ServiceGroupAsset', 'InstrumentAssets']],
  );
  const GetServiceGroupAsset = React.useCallback(asset => {
    /*if (asset !== undefined && asset !== null) {
        return asset.ServiceGroupId !== null &&
          asset.ServiceGroupId !== undefined &&
          (authenticatedUser?.HasAdminGroupOnlyPermissions(
            asset.ServiceGroupId,
          ) ||
            authenticatedUser?.HasLabTechGroupPermissions(asset.ServiceGroupId))
          ? asset
          : null;
      } else {
        return null;
      }*/
    return asset;
  }, []);
  const initialValues: IArticleNewEditForm = React.useMemo(
    () => ({
      Title: props.value.Title === null ? '' : props.value.Title,
      Authors:
        props.value.Authors === null ? '' : props.value.Authors.join(','),
      Abstract:
        props.value.Abstract === null ? '' : props.value.Abstract.trim(),
      Doi: props.value.Doi,
      ISSN: props.value.ISSN,
      ORCIDs: props.value.ORCIDs === null ? null : props.value.ORCIDs.join(','),
      Year: props.value.Year,
      Journal: props.value.Journal,
      PageNumber: props.value.PageNumber,
      RelatedUser: {
        Id: props.value.RelatedUser,
        Name: props.value.RelatedUserDisplayName,
      } as Entity<string>,
      InstrumentAssets:
        articleDetailsForm !== undefined
          ? articleDetailsForm?.InstrumentAssets
          : /*? articleDetailsForm?.InstrumentAssets.filter(
              asset =>
                asset.ServiceId !== null &&
                asset.ServiceId !== undefined &&
                (authenticatedUser?.HasAdminServicePermissions(
                  asset.ServiceTypeId ?? 1,
                  asset.ServiceId,
                ) ||
                  authenticatedUser?.HasLabTechServicePermissions(
                    asset.ServiceTypeId ?? 1,
                    asset.ServiceId,
                  ) ||
                  authenticatedUser?.HasAdminGroupPermissions(
                    asset.ServiceGroupId ?? undefined,
                  ) ||
                  authenticatedUser?.HasLabTechGroupPermissions(
                    asset.ServiceGroupId ?? undefined,
                  )),
            )*/
            [],
      /*ServiceGroupAssets:
        articleDetailsForm !== undefined
          ? articleDetailsForm?.ServiceGroupAssets.filter(
              asset =>
                asset.ServiceGroupId !== null &&
                asset.ServiceGroupId !== undefined &&
                (authenticatedUser?.HasAdminGroupPermissions(
                  asset.ServiceGroupId,
                ) ||
                  authenticatedUser?.HasLabTechGroupPermissions(
                    asset.ServiceGroupId,
                  )),
            )
          : [],*/
      ServiceGroupAsset:
        articleDetailsForm !== undefined
          ? GetServiceGroupAsset(articleDetailsForm?.ServiceGroupAssets[0])
          : null,
      Status:
        articleDetailsForm !== undefined &&
        articleDetailsForm.Status !== undefined
          ? {
              Id: articleDetailsForm.Status,
              Name: t('Article' + ArticleStatus[articleDetailsForm.Status]),
            }
          : { Id: 0, Name: t('Article' + ArticleStatus[0]) },
    }),
    [
      GetServiceGroupAsset,
      articleDetailsForm,
      props.value.Abstract,
      props.value.Authors,
      props.value.Doi,
      props.value.ISSN,
      props.value.Journal,
      props.value.ORCIDs,
      props.value.PageNumber,
      props.value.RelatedUser,
      props.value.RelatedUserDisplayName,
      props.value.Title,
      props.value.Year,
      t,
    ],
  );
  const [oldServiceGroups, setOldServiceGroups] = React.useState<IAssetDto[]>(
    //initialValues.ServiceGroupAssets,
    initialValues.ServiceGroupAsset === null
      ? []
      : [initialValues.ServiceGroupAsset],
  );
  const [serviceGroups, setServiceGroups] = React.useState<IAssetDto[]>(
    //initialValues.ServiceGroupAssets,
    initialValues.ServiceGroupAsset === null
      ? []
      : [initialValues.ServiceGroupAsset],
  );
  const instrumentFilter = React.useCallback(() => {
    return GetInstrumentFilter(serviceGroups);
  }, [serviceGroups]);
  const handleFormChange = React.useCallback(
    (
      values: IArticleNewEditForm,
      isValid,
      dirty,
      setValue,
      setTouched,
      validateField,
      setError,
      validate,
    ) => {
      if (
        serviceGroups.length !== oldServiceGroups.length ||
        serviceGroups[0] !== oldServiceGroups[0]
      ) {
        setOldServiceGroups(serviceGroups);
        setValue('InstrumentAssets', []);
        return;
      }
      if (
        values.ServiceGroupAsset === null &&
        values.InstrumentAssets.length > 0
      ) {
        setOldServiceGroups(serviceGroups);
        setValue('ServiceGroupAsset', {
          Id: values.InstrumentAssets[0].ServiceGroupAssetId,
          Name: values.InstrumentAssets[0].ServiceGroupName,
          ServiceGroupId: values.InstrumentAssets[0].ServiceGroupId,
          ServiceGroupName: values.InstrumentAssets[0].ServiceGroupName,
        });
      }
      setCanEditStatus(allowedToChangeStatus(serviceGroups[0]));
    },
    [allowedToChangeStatus, oldServiceGroups, serviceGroups],
  );
  const dispatch = useDispatch();
  const { actions } = useArticlesSlice();
  const handleSubmit = React.useCallback(
    (values: IArticleNewEditForm) => {
      if (
        //values.ServiceGroupAssets.length === 0 &&
        (values.ServiceGroupAsset === null &&
          values.InstrumentAssets.length === 0) ||
        values.Status === null
      ) {
        return;
      }
      const payload: IArticleDto = {
        ...props.value,
        Title: values.Title.trim(),
        Authors: values.Authors.trim().split(','),
        Abstract: values.Abstract.trim(),
        Doi:
          values.Doi !== undefined && values.Doi !== null
            ? values.Doi.trim()
            : null,
        ISSN:
          values.ISSN !== undefined && values.ISSN !== null
            ? values.ISSN.trim()
            : null,
        ORCIDs:
          values.ORCIDs !== undefined && values.ORCIDs !== null
            ? values.ORCIDs.trim().split(',')
            : [],
        Year: values.Year,
        Status: values.Status.Id,
        Journal: values.Journal !== undefined ? values.Journal : null,
        PageNumber: values.PageNumber !== undefined ? values.PageNumber : null,
        RelatedUser:
          values.RelatedUser !== null ? values.RelatedUser?.Id : null,
        ExternalId: props.value?.ExternalId,
        ExternalIdSource: 'Manual Entry',
        ...{
          //Assets: values.ServiceGroupAssets.concat(values.InstrumentAssets),
          Assets:
            values.ServiceGroupAsset !== null
              ? values.InstrumentAssets.concat([values.ServiceGroupAsset])
              : values.InstrumentAssets,
        },
      };
      dispatch(actions.saveArticleAssociation(payload));
    },
    [actions, dispatch, props.value],
  );
  const processing = state.editArticleAssociationBusy;
  const statusPickerData: Entity<number>[] = React.useMemo(() => {
    const data = [
      {
        Id: ArticleStatus.AwaitingApproval,
        Name: t('Article' + ArticleStatus[ArticleStatus.AwaitingApproval]),
      },
      {
        Id: ArticleStatus.Approved,
        Name: t('Article' + ArticleStatus[ArticleStatus.Approved]),
      },
    ] as Entity<number>[];
    return data;
  }, [t]);

  return (
    <PageWrapper
      useSidePanel
      closable
      disableExpandToggle
      pageName={t(translations.EditArticle)}
      titlePage={t(translations.EditArticle)}
      titleTooltip={t(translations.EditArticle)}
      closeSidePanel={() => {
        dispatch(layoutActions.setNotSavedChanges(false));
        dispatch(actions.hideDetails());
      }}
      topProcessing={processing}
    >
      <Box height={'100%'} p={1}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validateOnMount={true}
          validateOnBlur={false}
        >
          {formik => (
            <StyledForm onSubmit={formik.handleSubmit}>
              <FormFieldsSection>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['ServiceGroupAsset']}
                />
                <FormRow>
                  <FormTextField
                    name="Title"
                    id="TitleId"
                    label={t(translations.Title)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="Authors"
                    id="AuthorsId"
                    label={t(translations.Authors)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="Abstract"
                    id="AbstractId"
                    label={t(translations.Abstract)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <InputLabel size="xs">{t(translations.Source)}</InputLabel>
                  <ValueLabel value="Manual Entry" />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="Doi"
                    id="DoiId"
                    label={t(translations.Doi)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="ISSN"
                    id="ISSNId"
                    label={t(translations.ISSN)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="ORCIDs"
                    id="ORCIDsId"
                    label={t(translations.ORCID)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="Year"
                    id="YearId"
                    label={t(translations.Year)}
                    type="number"
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="Journal"
                    id="JournalId"
                    label={t(translations.Journal)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="PageNumber"
                    id="PageNumberId"
                    label={t(translations.ArticlePageNumber)}
                    type="number"
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormCustomPicker
                    name="Status"
                    label={t(translations.ArticleStatus)}
                    placeholder={t(translations.ArticleStatus)}
                    disabled={formik.isSubmitting || !canEditStatus}
                    fullWidth
                    data={statusPickerData}
                  />
                </FormRow>
                <FormRow>
                  <FormUserPicker
                    name="RelatedUser"
                    label={t(translations.RelatedUser)}
                    placeholder={t(translations.PleaseSelectAUser)}
                    disabled={formik.isSubmitting || !canEditStatus}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormAssetPicker
                    admin={false}
                    showOnlyActiveAssets={true}
                    name="ServiceGroupAsset"
                    restrictByAvType="serviceGroup"
                    label={t(translations.ServiceGroup)}
                    placeholder={t(translations.PleaseSelectAsset)}
                    disabled={processing}
                    fullWidth
                    onChange={(value: any) => {
                      setOldServiceGroups(serviceGroups);
                      setServiceGroups([value]);
                    }}
                  />
                </FormRow>
                <FormRow>
                  <FormAssetsPicker
                    admin={false}
                    filter={instrumentFilter()}
                    showOnlyActiveAssets={true}
                    name="InstrumentAssets"
                    restrictByAvType="bookableAndnotbookable"
                    label={t(translations.Instruments)}
                    placeholder={t(translations.PleaseSelectAsset)}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <Button processing={processing} type="submit">
                    {t(translations.Submit)}
                  </Button>
                </FormRow>
              </FormFieldsSection>
            </StyledForm>
          )}
        </Formik>
      </Box>
    </PageWrapper>
  );
}
