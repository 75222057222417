import { getSingleOnlineServiceType } from 'app/components/pickers/StaticOptionsPickers/OnlineServiceTypePicker';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { CellProps, Renderer } from 'react-table';

const OperationTypeIdRenderer: Renderer<CellProps<Record<string, any>>> = ({
  value,
}: {
  value: number | null;
}) => {
  const { t } = useTranslation();
  if (value === null || value === undefined) {
    return <>{t(translations.NA)}</>;
  } else {
    return <>{getSingleOnlineServiceType(value).Name}</>;
  }
};

export default OperationTypeIdRenderer;
