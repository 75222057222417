import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    printtopdf: {
      '@media print': {
        '& button': { display: 'none' },
      },
    },
  }),
);
