import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.onlineBudgetsStatus || initialState;

export const selectOnlineBudgetsStatus = createSelector(
  [selectSlice],
  state => state,
);

export const selectOnlineBudgetsStatusTableState = createSelector(
  [selectOnlineBudgetsStatus],
  state => state.tableState,
);
export const selectOnlineBudgetsStatusSelected = createSelector(
  [selectOnlineBudgetsStatus],
  state => state.selected,
);
export const selectPayState = createSelector(
  [selectOnlineBudgetsStatus],
  state => state.pay,
);

export const selectGenerateInvoicesState = createSelector(
  [selectOnlineBudgetsStatus],
  state => state.generateInvoicesState,
);
export const selectChargeOnlineBudgetsState = createSelector(
  [selectOnlineBudgetsStatus],
  state => state.charge,
);
export const selectEndDate = createSelector(
  [selectOnlineBudgetsStatus],
  state => state.endDate,
);

export const selectIGenerateInvoicesAndSendToSapState = createSelector(
  [selectOnlineBudgetsStatus],
  state => state.generateInvoicesAndSendToSap,
);
