import {
  BaseURLPicker,
  BaseURLPickerProps,
} from 'app/components/BasicPickers/BaseURLPicker';
import { Entity } from 'types/common';
const url = '/api/odata/v4/PaymentMethodFilter/WithSystemSettings';
export interface PaymentMethodPickerProps
  extends Omit<BaseURLPickerProps<Entity<number>>, 'url'> {}
export const PaymentMethodPicker = (props: PaymentMethodPickerProps) => {
  return (
    <BaseURLPicker url={url} {...props} id={props.id || 'paymenttypeId'} />
  );
};
