import { ListMessagesProps, OpenDetailsProps } from './Actions';

export enum SnackBarActionType {
  refreshTable = 1,
}
export enum SnackBarMessageType {
  openSidepanelDetails = 1,
  showLinkMessage = 2,
  HTML = 3,
  messageList = 4,
}
export type SnackBarMessageProps = OpenDetailsProps | ListMessagesProps;
