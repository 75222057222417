import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectServiceRequestRowStatues } from 'app/pages/Samples/RequestSamplesPage/slice/selectors';
import { SRSelect } from '../../EditableTable/Fields/DropDownList';
import { IServiceRequestRowStatusDto } from 'api/odata/generated/entities/IServiceRequestRowStatusDto';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { CellProps } from 'react-table';
import { IServiceRequestTableRowModel } from '../../RequestSamplesPage/slice/types';
import { FastFieldProps } from 'formik';
import { isNullOrUndefined } from 'utils/typeUtils';

export interface ServiceRequestRowStatusPickerProps {
  value: IServiceRequestRowStatusDto | null;
  serviceRequestId: number;
  serviceRequestRowId: number;
  onChange: (value: IServiceRequestRowStatusDto | null) => void;
  error?: boolean;
  helperText?: string;
}

export function ServiceRequestRowStatusPicker(
  props: ServiceRequestRowStatusPickerProps,
) {
  const statuses = useSelector(selectServiceRequestRowStatues);
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();
  const handleChange = React.useCallback(
    (value: IServiceRequestRowStatusDto | null) => {
      props.onChange?.(value);
      dispatch(
        actions.updateServiceRequestRowStatus({
          serviceRequestId: props.serviceRequestId,
          serviceRequestRowId: props.serviceRequestRowId,
          status: value,
        }),
      );
    },
    [actions, dispatch, props],
  );
  const handleBlur = React.useCallback(e => {}, []);

  return (
    <SRSelect
      multiple={false}
      value={props.value}
      options={statuses ?? []}
      onChange={handleChange}
      onBlur={handleBlur}
      error={props.error}
      helperText={props.helperText}
    />
  );
}

export const ServiceREquestRowStatusCell = React.memo(
  function ServiceREquestRowStatusCell(
    props: Pick<
      CellProps<IServiceRequestTableRowModel, IServiceRequestRowStatusDto>,
      'value'
    > & {
      isEditable: boolean;
      serviceRequestId: number;
      serviceRequestRowId: number;
    } & {
      fieldProps: FastFieldProps;
      onChange?: (value: IServiceRequestRowStatusDto | null) => void;
    },
  ) {
    const handleChange = React.useCallback(
      value => {
        props.fieldProps?.field?.onChange({
          target: { value: value, name: props.fieldProps?.field?.name },
        });
        props.onChange?.(value);
      },
      [props],
    );

    if (props.isEditable) {
      return (
        <>
          <ServiceRequestRowStatusPicker
            value={props.value}
            serviceRequestId={props.serviceRequestId}
            serviceRequestRowId={props.serviceRequestRowId}
            onChange={handleChange}
            error={!isNullOrUndefined(props.fieldProps?.meta?.error)}
            helperText={props.fieldProps?.meta?.error}
          />
        </>
      );
    } else {
      return <>{props.value.Name}</>;
    }
  },
);
