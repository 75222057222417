import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import * as React from 'react';

export interface UserMenuButtonProps extends ButtonProps {
  children: React.ReactNode;
}

export default function UserMenuButton(props: UserMenuButtonProps) {
  const { children, ...other } = props;
  return (
    <Button
      disableRipple
      variant="ghost"
      color="default"
      size="small"
      {...other}
    >
      {children}
    </Button>
  );
}
