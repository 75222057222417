import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectContinueState,
  selectContinueToLink,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { useAppSettingsSlice } from 'app/slice';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { push } from 'connected-react-router';
import { FormikProps } from 'formik';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Beforeunload } from 'react-beforeunload';
import { useOfflineServiceStateSlice } from '../Details/slice';
import {
  selectInventoryBatchCompleted,
  selectInventoryBatchData,
  selectInventoryBatchHasErrors,
  selectInventoryBatchIsEdit,
  selectInventoryBatchProcessing,
} from '../Details/slice/selectors';
import {
  InventoryBatchParams,
  InventoryBatchState,
} from '../Details/slice/types';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { ServiceType } from 'enums/ServiceTypes';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { Box } from 'app/components/basic/Wrappers/Box';
import { Progress } from 'app/components/LoadingIndicator';
import { buildURL } from 'utils/url-utils';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { InventoryBatchForm } from './InventoryBatchForm';
import { Roles } from 'app/slice/types';

export interface InventoryBatchProps extends CoverProps, SidePanelContentProps {
  queryParams: InventoryBatchParams;
  initialBatch?: IInventoryBatchDto;
  onClose?: () => void;
  saveCreatable?: boolean;
  preventRefreshTable?: boolean;
}
export const InventoryBatch = React.memo(function InventoryBatch(
  props: InventoryBatchProps,
) {
  const {
    useSidePanel,
    queryParams,
    initialBatch,
    onClose,
    saveCreatable,
    preventRefreshTable,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();
  const processing = useSelector(selectInventoryBatchProcessing);
  const actionCompleted = useSelector(selectInventoryBatchCompleted);
  const User = useSelector(selectAuthenticatedUser);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const confirmApproved = useSelector(selectConfirmApproved);
  const confirmRejected = useSelector(selectConfirmRejected);
  const continueToOther = useSelector(selectContinueState);
  const continueLink = useSelector(selectContinueToLink);
  const hasError = useSelector(selectInventoryBatchHasErrors);
  const inventoryBatchData = useSelector(selectInventoryBatchData);
  const publicUrl = useSelector(selectPublicUrl);
  const isEdit = useSelector(selectInventoryBatchIsEdit);
  //   const { checkIsMobile } = useIsMobile();
  //   const mobile = checkIsMobile();
  const globalSettings = useGlobalSettingsHook();
  //Local State
  const [cover, setCover] = React.useState<React.ReactNode>();
  const [busy, setBusy] = React.useState<boolean | undefined>(false);
  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);

  //Refs
  const submitFormRef = React.useRef<any>(null);
  const innerFormRef = React.useRef<FormikProps<InventoryBatchState>>(null);

  const isReadOnlyUser = React.useMemo(() => {
    return (
      User !== undefined &&
      (User.Roles.includes(Roles.CalendarOnly) ||
        User.Roles.includes(Roles.Readonly) ||
        User.Roles.includes(Roles.RoomOnly))
    );
  }, [User]);

  //Methods
  const closeCover = () => {
    setCover(undefined);
  };
  const handleClose = () => {
    if (hasChanges) {
      dispatch(layoutActions.setConfirmOpen(true));
    } else {
      handleCloselClick();
    }
  };
  const handleCloselClick = React.useCallback(() => {
    if (!!onClose) {
      onClose();
    } else {
      if (useSidePanel) {
        dispatch(layoutActions.resetSidePanel());
      } else {
        dispatch(push('/otherServices'));
      }
    }
    dispatch(actions.resetInventoryBatchState());
  }, [actions, dispatch, layoutActions, onClose, useSidePanel]);
  const handleSaveClick = React.useCallback((e: any) => {
    handleSubmitForm(e);
  }, []);

  const handleSubmitForm = (e: any) => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (values: InventoryBatchState) => {
      setBusy(true);
      if (isEdit) {
        dispatch(
          actions.updateInventoryBatch({
            current: values,
            original: inventoryBatchData || values,
            saveCreatable: saveCreatable,
            preventRefreshTable: preventRefreshTable,
          }),
        );
      } else {
        dispatch(
          actions.createInventoryBatch({
            model: values,
            saveCreatable: saveCreatable,
            preventRefreshTable: preventRefreshTable,
          }),
        );
      }
    },
    [
      actions,
      dispatch,
      inventoryBatchData,
      isEdit,
      preventRefreshTable,
      saveCreatable,
    ],
  );

  React.useEffect(() => {
    let active = firstLoad;
    if (globalSettings.loadCompleted && active) {
      setFirstLoad(false);
      dispatch(
        actions.initInventoryBatch({
          query: queryParams,
          batch: initialBatch,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [actions, dispatch, firstLoad, globalSettings, initialBatch, queryParams]);
  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      if (actionCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          handleCloselClick();
        }
      } else if (actionCompleted === false) {
        setRequestCompleted(undefined);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [actionCompleted, handleCloselClick, hasError, processing]);
  React.useEffect(() => {
    if (confirmApproved) {
      dispatch(actions.resetInventoryBatchState());
      if (continueToOther.continueOnApprove) {
        dispatch(layoutActions.resetSidePanel());
        setTimeout(() => {
          dispatch(
            layoutActions.openSidePanel({
              type: continueToOther.pageType,
              props: continueToOther.pageProps,
              expanded: continueToOther.expanded,
            }),
          );
          dispatch(layoutActions.resetContinueState());
        }, 200);
      } else if (continueLink !== undefined) {
        dispatch(appSettingsActions.navigate(continueLink));
        dispatch(layoutActions.resetSidePanel());
      } else {
        dispatch(layoutActions.resetSidePanel());
      }
      //dispatch(actions.setChangedState(false));
    }
    if (confirmRejected) {
      dispatch(layoutActions.resetConfirmState());
    }

    return () => undefined;
  }, [
    actions,
    appSettingsActions,
    confirmApproved,
    confirmRejected,
    continueLink,
    continueToOther,
    dispatch,
    layoutActions,
  ]);

  //memo
  const IsAdmin = React.useMemo(() => {
    return !!innerFormRef.current &&
      innerFormRef.current.values.ServiceType !== null
      ? User?.IsAllGroupOrLabTechAdmin(
          [innerFormRef.current.values.ServiceType.ServiceGroupId],
          [
            {
              Id: innerFormRef.current.values.ServiceType.Id,
              Name: innerFormRef.current.values.ServiceType.Name,
              ServiceTypeId: ServiceType.Offline,
            } as IServiceTypeFilterDto,
          ],
        )
      : false;
  }, [User]);
  const rightActions = React.useMemo(() => {
    let actions: ActionRenderer[] = [];
    actions.push(() => (
      <Button
        size="small"
        startIcon={<Icon icon="save" />}
        onClick={handleSaveClick}
        disabled={
          busy ||
          !(
            !!innerFormRef.current &&
            (innerFormRef.current.values.ServiceType ?? null) !== null
          )
        }
        processing={!requestCompleted && busy}
      >
        {t(translations.Save)}
      </Button>
    ));
    actions.push(() => (
      <Button
        variant="ghost"
        size="small"
        startIcon={<Icon icon="times" />}
        onClick={handleCloselClick}
      >
        {t(translations.Close)}
      </Button>
    ));
    return actions;
  }, [busy, handleCloselClick, handleSaveClick, requestCompleted, t]);

  return (
    <React.Fragment>
      <PageWrapper
        pageName={t(translations.InventoryBatch)}
        titlePage={
          inventoryBatchData
            ? isEdit
              ? ` ${t(translations.Batch)} #${queryParams.id ?? 'N/A'}`
              : ` ${t(translations.CreateNewBatch)}`
            : ` ${t(translations.Batch)}`
        }
        loading={processing}
        useSidePanel={useSidePanel}
        closable={useSidePanel || props.isCover}
        disableExpandToggle={true}
        closeSidePanel={props.isCover ? onClose : handleClose}
        // leftTopActions={topButtons}
        //rightTopActions={topButtons}
        rightTopActionsAsMenu={true}
        //leftTopActions={topActions}
        //topProcessing={equipmentChangeCompleted === false}
        leftActions={isReadOnlyUser ? [] : rightActions}
        //rightActions={rightActions}
        children={
          inventoryBatchData !== undefined &&
          !processing &&
          globalSettings.loadCompleted ? (
            <InventoryBatchForm
              initialValues={inventoryBatchData || ({} as InventoryBatchState)}
              bindSubmitForm={bindSubmitForm}
              onSubmit={handleSubmit}
              isAdmin={IsAdmin || false}
              user={User}
              globalSettings={globalSettings}
              innerFormRef={innerFormRef}
              isEdit={isEdit ?? false}
            />
          ) : (
            <React.Fragment>
              {' '}
              <Box component="div">
                <Progress inProgress={processing || false} size={80} />
              </Box>
            </React.Fragment>
          )
        }
        pageLink={buildURL(publicUrl + '/inventoryBatch', queryParams)}
        isCover={props.isCover}
        cover={cover}
        closeCover={props.closeCover || closeCover}
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </React.Fragment>
  );
});
