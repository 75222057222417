/**
 *
 * AsyncPicker
 *
 */
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import {
  ITrainingResFilterProps,
  TrainingResevationsPicker,
} from '../pickers/AutocompletePickers/TrainingReservationsPicker';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export const FormTrainingReservationsPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> &
  IFieldProps &
  ITrainingResFilterProps) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <TrainingResevationsPicker
        equipment={props.equipment}
        user={props.user}
        trainingDate={props.trainingDate}
        name={props.name}
        variant="filled"
        placeholder={props.placeholder}
        label={label}
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
      />
    </>
  );
};
