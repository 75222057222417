/**
 *
 * AsyncPicker
 *
 */
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { UserMultiPicker } from '../pickers/AutocompletePickers/UserMultiPicker';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';

export const FormUsersPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<string>[] | null> & {
  selectArray?: Array<keyof IUserFilterDto>;
} & IFieldProps) => {
  const [field, meta, helpers] = useField<Entity<string>[] | null>(props);
  return (
    <UserMultiPicker
      name={props.name}
      placeholder={props.placeholder}
      label={label}
      predicates={props.predicates}
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      disabled={props.disabled}
      onBlur={field?.onBlur}
      value={field.value as IUserFilterDto[]}
      variant="filled"
      error={meta?.error !== undefined}
      helperText={meta.error}
      fullWidth={props.fullWidth}
      info={props.info}
      selectArray={props.selectArray}
    />
  );
};
