import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRequestSamplesSlice } from '../../slice';
import { Identifiable } from 'types/common';

export interface CloneServiceRequestButtonProps {
  item?: Identifiable<number>;
}
export function CloneServiceRequestButton(
  props: CloneServiceRequestButtonProps,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();

  const handleCloneButtonClick = React.useCallback(() => {
    if (props.item !== undefined) {
      dispatch(actions.cloneServiceRequest(props.item.Id));
    }
  }, [actions, dispatch, props.item]);

  if (props.item === undefined) {
    return null;
  }

  return (
    <TopActionButton
      variant="ghost"
      size="small"
      shape="square"
      startIcon="copy"
      icon="copy"
      aria-label="copy"
      onClick={handleCloneButtonClick}
      noChangeOnMobile
      text={t(translations.Clone)}
      title={t(translations.Clone)}
    />
  );
}
