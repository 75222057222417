import { IAuthenticatedUser } from 'types/AuthenticatedUser';

import { toQueryString } from 'utils/url-utils';

export function getDefaultFilter(user: IAuthenticatedUser) {
  const result = toQueryString({
    HasBalanceOrEstimatedCharges: true,
    BudgetIsOnline: true,
  });
  return result;
}
