/* eslint-disable prettier/prettier */
import { Box } from 'app/components/basic/Wrappers/Box';
import React, { useCallback, useMemo } from 'react';
import { useStyles } from './styles';
import { CheckBox } from './CheckBox';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import clsx from 'clsx';
import { Button } from 'app/components/BasicButtons/Button';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useDispatch } from 'react-redux';
import { usePrintPDFSlice } from '../slice';
import { ServiceRequestSectionTypesArray } from '../ServiceRequestSectionTypes';
import { PrintPDFItem } from '../slice/types';
import { H4 } from 'app/components/Typography';
import { LinearProgress } from '@material-ui/core';

const convertItemsToFlags = (items: PrintPDFItem[]) => {
  const res = {};
  for (const item of items) {
    res[item.id] = item.checked;
  }
  return res;
};

export interface PopupPrintToPDFComponentProps {
  title: string;
  items: PrintPDFItem[];
  processing?: boolean;
  preparing?: boolean;
  disabled?: boolean;
  onSubmit: () => void;
  close: () => void;
}

export function PopupPrintToPDFComponent(props: PopupPrintToPDFComponentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = usePrintPDFSlice();
  const classes = useStyles();
  const [checkboxes, setCheckboxes] = React.useState<object>(
    convertItemsToFlags(props.items),
  );
  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const id = ServiceRequestSectionTypesArray.find(
        f => f === event.target.value,
      );

      if (id !== undefined) {
        dispatch(
          actions.updateSection({ id: id, checked: event.target.checked }),
        );
        setCheckboxes({
          ...checkboxes,
          [id]: event.target.checked,
        });
      }
    },
    [actions, checkboxes, dispatch],
  );
  const handleCheckboxChecked = useCallback(
    name => {
      return name !== undefined && checkboxes[name];
    },
    [checkboxes],
  );
  const content = useMemo(() => {
    if (props.items.length > 0) {
      const res = props.items.map(item => {
        return (
          <CheckBox
            name={item.id}
            id={item.id}
            value={item.id}
            checked={handleCheckboxChecked}
            onChange={handleCheckboxChange}
            label={item.label}
          />
        );
      });
      return res;
    } else {
      return <></>;
    }
  }, [handleCheckboxChange, handleCheckboxChecked, props.items]);
  return (
    <>
      <Box className={clsx(classes.root)}>
        <Box style={{ height: '85%' }}>
          <Box className={classes.titleContainer}>
            <IconButton
              variant="ghost"
              aria-label="close"
              className={classes.closeButton}
              onClick={props.close}
              size="small"
            >
              <Icon icon="times" />
            </IconButton>
            <H4>{props.title}</H4>
          </Box>
          {props.preparing !== undefined && props.preparing && (
            <Box style={{ width: '100%' }}>
              <LinearProgress variant="query" color="primary" />
            </Box>
          )}
          <Box style={{ marginBottom: '15px' }}>{content}</Box>
        </Box>
        <Box style={{ height: '15%' }} className={clsx(classes.container)}>
          <Box style={{ display: 'flex' }}>
            <Button
              processing={props.processing}
              disabled={props.disabled}
              onClick={() => props.onSubmit()}
            >
              {'Print'}
            </Button>
            <Button
              variant="ghost"
              onClick={props.close}
              style={{ marginLeft: '10px' }}
            >
              {t(translations.Cancel)}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
