/**
 * Compile time guard on Union Type switch
 * Usage example:
 * ```
 * type Union = 'apple' | 'banana' | 'orange';
 * function test(arg: Union) {
 *    switch (arg) {
 *      case 'apple':
 *        ...
 *      case 'banana':
 *        ...
 *      default:
 *        // this will generate a comiple warning since 'orange' is not handled in the switch
 *        assertExhaustive(arg);
 *    }
 * }
 */
export function assertExhaustive(value: never, message?: string): never {
  throw new Error(
    message ?? `Reached unexpected case in exhaustive switch: ${value}`,
  );
}
