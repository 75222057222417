/**
 *
 * TrainingSessionPicker
 *
 */
import * as React from 'react';
import { InputBaseComponentProps } from '@material-ui/core';
import { httpClient } from 'api/HttpClient';
import { ITrainingSessionDto } from 'api/odata/generated/entities/ITrainingSessionDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { dateUtils } from 'utils/date-utils';
import i18next from 'i18next';

export type UserUrl = 'restricted' | 'full' | 'admin';
export interface TrainingSessionPickerProps
  extends Omit<AutocompletePickerProps<ITrainingSessionDto>, 'loadData'> {
  inputProps?: InputBaseComponentProps;
  selectArray?: Array<keyof ITrainingSessionDto>;
}

const url = '/api/odata/v4/TrainingSessions';
export const generateTrainingSessionName = (
  dto: ITrainingSessionDto,
): ITrainingSessionDto => {
  let name = `${dateUtils.format(
    dto.StartDate,
    dateUtils.DateIOFormats.fullDateTime,
  )} ${dto.Duration}(h) ${dto.Trainer} ${
    dto.RemainedSlots === null
      ? i18next.t(translations.MaxAttendees_NA)
      : dto.RemainedSlots
  }`;
  return Object.assign({}, dto, { Name: name });
};
export const initSessionsData = (id: string) => {
  const params: {
    $orderby: string;
    $filter?: string;
    $top: number;
    $skip: number;
  } = {
    $orderby: 'StartDate asc',
    $filter: new Condition('Id', ODataOperators.Equals, id).toString(),
    $skip: 0,
    $top: 1,
  };
  return httpClient
    .get(url, params)
    .then(
      response =>
        (response.value as ITrainingSessionDto[]).map(f =>
          generateTrainingSessionName(f),
        ) as Entity<number>[],
    );
};

export function TrainingSessionPicker(props: TrainingSessionPickerProps) {
  const { t } = useTranslation();
  const loadData = getAutoCompleteLoadDataFn<ITrainingSessionDto>({
    url: url,
    predicates: props.predicates,
    select: props.selectArray ?? [
      'Id',
      'Name',
      'StartDate',
      'EndDate',
      'RemainedSlots',
      'TrainingState',
      'Trainer',
      'Duration',
    ],
    transformModel: generateTrainingSessionName,
  });
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={loadData}
      id={props.id || 'sessionId'}
      placeholder={
        props.placeholder ?? t(translations.PleaseSelectATRainingSession)
      }
      {...props}
    />
  );
}
export function ReservRelatedFilter(
  EquipmentId?: number,
): (string | Condition<ITrainingSessionDto>)[] {
  const predicates: (string | Condition<ITrainingSessionDto>)[] = [];
  if (EquipmentId !== undefined) {
    predicates.push(
      new Condition<ITrainingSessionDto>(
        'ServiceId',
        ODataOperators.Equals,
        EquipmentId,
      ),
    );
  }
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}
