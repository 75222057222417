import BasicTypography, { BasicTypographyProps } from './BasicTypography';

export function H1(props: BasicTypographyProps) {
  const { children, ...other } = props;
  return (
    <BasicTypography variant="h1" {...other}>
      {children}
    </BasicTypography>
  );
}
export function H2(props: BasicTypographyProps) {
  const { children, ...other } = props;
  return (
    <BasicTypography variant="h2" {...other}>
      {children}
    </BasicTypography>
  );
}
export function H3(props: BasicTypographyProps) {
  const { children, ...other } = props;
  return (
    <BasicTypography variant="h3" {...other}>
      {children}
    </BasicTypography>
  );
}
export function H4(props: BasicTypographyProps) {
  const { children, ...other } = props;
  return (
    <BasicTypography variant="h4" {...other}>
      {children}
    </BasicTypography>
  );
}
export interface SubTitleProps extends BasicTypographyProps {
  size?: 'small' | 'medium';
  children: React.ReactNode;
}
export function SubTitle(props: SubTitleProps) {
  const { size, children, ...other } = props;
  const subtitleVarian = size === 'small' ? 'subtitle2' : 'subtitle1';
  return (
    <BasicTypography variant={subtitleVarian} {...other}>
      {children}
    </BasicTypography>
  );
}

export interface BodyProps extends BasicTypographyProps {
  size?: 'small' | 'medium' | 'large';
  bold?: boolean;
  children: React.ReactNode;
}
export function Body(props: BodyProps) {
  const { size, bold, children, ...other } = props;
  const bodyVarian =
    size === 'large'
      ? bold
        ? 'boldL'
        : 'bodyL'
      : size === 'small'
      ? bold
        ? 'boldS'
        : 'bodyS'
      : bold
      ? 'boldM'
      : 'bodyM';
  return (
    <BasicTypography variant={bodyVarian} {...other}>
      {children}
    </BasicTypography>
  );
}

export interface CaptionProps extends BasicTypographyProps {
  bold?: boolean;
  children: React.ReactNode;
}
export function Caption(props: CaptionProps) {
  const { bold, children, ...other } = props;
  const captionVarian = bold ? 'captionBold' : 'captionRegular';
  return (
    <BasicTypography variant={captionVarian} {...other}>
      {children}
    </BasicTypography>
  );
}
export interface UppercaseProps extends BasicTypographyProps {
  children: React.ReactNode;
}
export function Uppercase(props: UppercaseProps) {
  const { children, ...other } = props;
  return (
    <BasicTypography variant="uppercase" {...other}>
      {children}
    </BasicTypography>
  );
}
export interface TipsProps extends BasicTypographyProps {
  bold?: boolean;
  children: React.ReactNode;
}
export function Tips(props: TipsProps) {
  const { bold, children, ...other } = props;
  const tipsVarian = bold ? 'tipsBold' : 'tipsRegular';
  return (
    <BasicTypography variant={tipsVarian} {...other}>
      {children}
    </BasicTypography>
  );
}
export interface InputLabelProps extends BasicTypographyProps {
  size?: 'xs' | 'medium';
  children: React.ReactNode;
}
export function InputLabel(props: InputLabelProps) {
  const { size, children, ...other } = props;
  const inputLabelVarian = size === 'xs' ? 'inputLabelXS' : 'inputLabelM';
  return (
    <BasicTypography variant={inputLabelVarian} {...other}>
      {children}
    </BasicTypography>
  );
}
export interface InputValueProps extends BasicTypographyProps {
  size?: 'small' | 'medium';
  children: React.ReactNode;
}
export function InputValue(props: InputValueProps) {
  const { size, children, ...other } = props;
  const inputValueVarian = size === 'small' ? 'inputValueS' : 'inputValueM';
  return (
    <BasicTypography variant={inputValueVarian} {...other}>
      {children}
    </BasicTypography>
  );
}

export interface ButtonLabelProps extends BasicTypographyProps {
  size?: 'small' | 'medium';
  children: React.ReactNode;
}
export function ButtonLabel(props: ButtonLabelProps) {
  const { size, children, ...other } = props;
  const buttonLabeVarian = size === 'small' ? 'buttonS' : 'buttonM';
  return (
    <BasicTypography variant={buttonLabeVarian} {...other}>
      {children}
    </BasicTypography>
  );
}
