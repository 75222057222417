/**
 *
 * auto generated interface for Bookit.Data.Enums.Priorities
 *
 */

export enum Priorities {
  Lowest = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  Highest = 4,
}
export type PrioritiesUnion = keyof typeof Priorities;
export const PrioritiesUnionArray: Array<PrioritiesUnion> = [
  'Lowest',
  'Low',
  'Medium',
  'High',
  'Highest',
];
