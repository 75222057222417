/**
 *
 * ShortURLRoute
 *
 */
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import { RouteProps, Route } from 'react-router-dom';
import { toRootedURL } from 'utils/url-utils';

export interface ShortURLRouteProps extends Omit<RouteProps, 'render'> {}
/**
 * Forces url reload for external pages after navigation from react router (push/Redirect/etc.) and the page needs server side reload
 * @param props route props
 * @returns redirect
 */
export function ShortURLRoute(props: ShortURLRouteProps): React.ReactElement {
  const location = useLocation();
  const render = (propss: RouteComponentProps) => {
    const path =
      propss.location.pathname + propss.location.search + propss.location.hash;
    const rooted = toRootedURL(path);
    console.info(`Reloading external url ${path}`);
    if (
      propss.location.pathname.toLowerCase() === location.pathname.toLowerCase()
    ) {
      return null;
    }
    window.location.href = rooted;
    return null;
  };

  return <Route {...props} render={render} />;
}

export const DebugRouter = ({ children }: { children: any }) => {
  const { location } = useHistory();
  if (process.env.NODE_ENV === 'development') {
    console.log(
      `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
        location.state,
      )}`,
    );
  }

  return children;
};
