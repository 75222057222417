import * as React from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { RadioGroup, RadioGroupProps } from '../RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, FormLabel, styled } from '@material-ui/core';
import { useIsRequired } from './ValidationSchemaProvider';
import { buttonM, buttonS } from 'styles/typography/bookitTypography';

export type FormRadioGroupProps = FieldHookConfig<string> & RadioGroupProps;
export const FormRadioGroup = ({
  id,
  label,
  onChange,
  value,
  name,
  error,
  title,
  helperText,
  ...props
}: FieldHookConfig<string> & RadioGroupProps) => {
  const [field, meta] = useField<string>({ name, ...props });
  const handleChange = (value, event) => {
    if (onChange !== undefined) {
      onChange(value, event);
    }
    field.onChange(event);
  };
  const { submitCount } = useFormikContext();
  const isRequired = useIsRequired(name);
  const hasError = meta?.error !== undefined;
  const showError = hasError && (meta.touched || submitCount > 0);
  return (
    <StyledFormControl required={isRequired} error={showError}>
      <StyledFormLabel
        style={title ? { marginBottom: '8px' } : { marginBottom: '24px' }}
      >
        {label}
      </StyledFormLabel>
      {title && <StyledFormTitle>{title}</StyledFormTitle>}
      <RadioGroup
        id={id}
        name={name}
        label={label}
        onChange={handleChange}
        value={field.value ?? ''}
        disabled={props.disabled}
        {...props}
      />
      <StyledFormHelperText required={isRequired} error={showError}>
        <span>{showError ? meta.error : helperText}</span>
      </StyledFormHelperText>
    </StyledFormControl>
  );
};

// spacing is needed in the quizzes
const StyledFormControl = styled(FormControl)(props => ({
  marginBottom: props.theme.spacing(0),
}));

// the label needs to be stronger in quizzes
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.common.blackRegular,
  ...buttonM,
  '&[class*="Mui-focused"]': {
    color: theme.palette.common.blackRegular,
  },
}));
// the label needs to be stronger in quizzes
const StyledFormTitle = styled('label')(({ theme }) => ({
  color: theme.palette.common.blackRegular,
  ...buttonS,
  fontStyle: 'italic',
  marginBottom: theme.spacing(3),
}));
const StyledFormHelperText = styled(FormHelperText)(props => ({
  // empty helperText results in zero element height. So when field is validated the appearance of the
  // error message in the helperText causes the form element to increase in height and forces a redraw
  // empty char \00a0 causes the FormHelperText to always occupy one line height and prevents reflows
  '&::before': {
    content: '"\\00a0"',
  },
}));
