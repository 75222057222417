/**
 *
 * ValueLabel
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

export interface ValueLabelProps {
  value: string | number | undefined | null;
}

export function ValueLabel({ value }: ValueLabelProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const isEmpty =
    value === undefined ||
    value === null ||
    (typeof value === 'string' && value.trim() === '');
  const displayValue = isEmpty ? t(translations.NA) : value;
  return <>{displayValue}</>;
}
