import { getCustomFormColumnSchema } from 'app/components/CustomForm/CustomFormUtils';
import { transform } from 'lodash';
import * as Yup from 'yup';
import { ServiceRequestTableColumnsCollection } from '../../RequestSamplesPage/slice/utils/ServiceRequestTableColumn';

export const getSchema = (props: {
  columns: ServiceRequestTableColumnsCollection;
  isAdmin: boolean;
}) =>
  // TODO: fix custom form schema typings starting from the getFieldSchema. In the end this : Yup.SchemaOf<Array<IServiceRequestTableRowModel>>
  {
    const columnSchema = (result, column, key) =>
      (result[column.getFieldName()] = getCustomFormColumnSchema({
        ColumnType: column.ColumnType,
        Label: column.Label,
        SizeLimit: column.SizeLimit,
        isRequired: column.isRequired,
        Options: column.Options,
      }).nullable());

    const x = transform(props.columns.columnsObj, columnSchema);

    const dynamicValuesSchema = Yup.object(x);

    return Yup.array(
      Yup.object({
        Values: dynamicValuesSchema,
      }),
    );
  };
