import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Alert, AlertProps } from '@material-ui/lab';

export interface TableErrorProps extends Pick<AlertProps, 'id'> {}
export function TableError(props: TableErrorProps) {
  const { t } = useTranslation();
  return (
    <Alert severity="error" {...props}>
      {t(translations.LoadingFailed)}
    </Alert>
  );
}
