import {
  //selectAppSettings,
  selectglobalSettings,
  selectIsAuthenticated,
  selectLoading,
  selectSiteMapProvider,
  selectSiteMapSettings,
} from 'app/slice/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
//import { AppSettings } from 'types/AppSettings';
import { NoAuthNodes } from 'types/SiteMapSettings';
import { AllowedSettings } from 'utils/globalSettings';
import { toLowerCaseSafely } from 'utils/typeUtils';
import { toRootedURL } from 'utils/url-utils';

export interface TabTitleProps {
  isDispatcherLoading: boolean;
  i18Language: string;
}

export const useTabTitle = (): [
  string | undefined, //pageTitle:
  string | undefined, //pageIcon:
  (string) => void, //setPageTitle:
  (string) => void, //setPageIcon:
  boolean,
] => {
  const location = useLocation();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const selectorLoading = useSelector(selectLoading);
  const { t } = useTranslation();
  //const appSettings: AppSettings | null = useSelector(selectAppSettings);
  const siteMapSettings = useSelector(selectSiteMapSettings);
  const siteMapProvider = useSelector(selectSiteMapProvider);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [title, setTitle] = React.useState<string | undefined>(undefined);
  //const favIconImg: string | undefined = appSettings?.FavIconImg;
  //const favIconTitle: string | undefined = appSettings?.FavIconTitle;
  const globalSettings = useSelector(selectglobalSettings);
  const favIconImg: string | undefined = globalSettings.GetString(
    AllowedSettings.FavIconImg,
  );
  const favIconTitle: string | undefined = globalSettings.GetString(
    AllowedSettings.FavIconTitle,
  );
  const getFavIconLink = () =>
    document.getElementById('tabFavIcon') as HTMLLinkElement;

  const handleFavIconChange = (favIcon: string | undefined) => {
    let favLink = getFavIconLink();
    if (favLink !== null) {
      if (favIcon !== undefined && favIcon !== '') {
        favLink.href = toRootedURL('/uploads/Branding/' + favIcon);
      } else {
        favLink.href = process.env.PUBLIC_URL + '/favicon.png';
      }
    }
  };
  const handleTitleChange = (title: string | undefined) => {
    if (title !== undefined && title !== '') {
      document.title = title;
    } else {
      document.title = 'BookitLab!';
    }
  };
  React.useEffect(() => {
    let active = !selectorLoading && !loaded;
    if (active) {
      if (isAuthenticated === true) {
        if (!!siteMapSettings && siteMapSettings !== null) {
          let node = siteMapProvider.findNode(location.pathname);
          if (node !== undefined && !!favIconImg && !!favIconTitle) {
            setTitle(
              () => `${favIconTitle} - ${node === undefined ? '' : node.Title}`,
            );
            setLoaded(true);
          }
        }
      } else {
        let node = NoAuthNodes(t).find(element => {
          if (element !== undefined) {
            const reactUrl = toLowerCaseSafely(element.reactUrl);
            if (reactUrl !== null) {
              let rpath = reactUrl.split('?')[0] ?? reactUrl;
              return location.pathname.toLowerCase().indexOf(rpath) > -1;
            } else {
              return (
                element.Url.toLowerCase().indexOf(
                  location.pathname.toLowerCase(),
                ) > -1
              );
            }
          }
          return undefined;
        });
        if (node !== undefined && !!favIconImg && !!favIconTitle) {
          setTitle(
            () => `${favIconTitle} - ${node === undefined ? '' : node.Title}`,
          );
          setLoaded(true);
        }
      }
    }
    return () => {
      active = false;
    };
  }, [
    favIconImg,
    favIconTitle,
    isAuthenticated,
    loaded,
    location.pathname,
    selectorLoading,
    siteMapProvider,
    siteMapSettings,
    t,
  ]);
  return [favIconImg, title, handleFavIconChange, handleTitleChange, loaded];
};
