import { ODataOperators } from 'api/odata/ODataFilter';
import { IServiceRequestDto } from 'api/odata/generated/entities/IServiceRequestDto';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import {
  URLSearchParamsCI,
  arrayToQueryString,
  getNumberEntityArray,
  initMultiPickerValue,
} from 'app/components/BasicTable/types/FilterParam';
import {
  ServiceRequestStatusMultiPicker,
  initServiceRequestStatusMultiData,
} from 'app/components/pickers/AutocompletePickers/ServiceRequestStatusMultiPicker';
import { translations } from 'locales/translations';

export const StatusFilterId = 'Status';
export const GetFilters: GetPageFilters<IServiceRequestDto> = (
  t,
  appSettings,
  user,
  settings,
) => search => {
  const params = new URLSearchParamsCI(search);
  const result: IFilterSettings<IServiceRequestDto>[] = [
    {
      id: StatusFilterId,
      fieldName: 'Status',
      queryStringSerializer: arrayToQueryString('RequestStatusInclude'),
      displayName: t(translations.IncludeStatus) as string,
      operator: ODataOperators.Includes,
      value: getNumberEntityArray(params, 'RequestStatusInclude'),
      component: ServiceRequestStatusMultiPicker,
      initData: initMultiPickerValue(initServiceRequestStatusMultiData),
      urlKey: 'RequestStatusInclude',
    },
  ];
  return result;
};
