import React from 'react';
import PropTypes from 'prop-types';
import MenuItemComponent from './MenuItemComponent';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { useDispatch, useSelector } from 'react-redux';
import { DetectIsMobile } from 'utils/mobileDetect';
import { selectSiteMapSettings } from 'app/slice/selectors';
import { HoverSideMenu } from './HoverMenu';
import { SiteMapProvider } from 'types/SiteMapSettings';
import { isNullOrUndefined } from 'utils/typeUtils';
import { useLocation } from 'react-router-dom';
import useExpandedSideBarState from 'app/hooks/useExpandedSideBarState';
import { selectHasNotSavedChanges } from 'app/Layout/FrontendLayout/slice/selectors';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';

export const MenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  external: PropTypes.bool.isRequired,
  Icon: PropTypes.element,
  items: PropTypes.array,
  selected: PropTypes.bool.isRequired,
  indexChanged: PropTypes.func,
  index: PropTypes.number.isRequired,
  chIndex: PropTypes.number,
  onChange: PropTypes.func,
};
type MenuItemPropTypesItems = PropTypes.InferProps<typeof MenuItemPropTypes>;
type MenuItemPropTypesNoItems = Omit<MenuItemPropTypesItems, 'items'>;

export type SidebarMenuItemProps = MenuItemPropTypesNoItems & {
  items?: SidebarMenuItemProps[];
};
const SidbarMenuItem = React.forwardRef<any, SidebarMenuItemProps>(
  function SidbarMenuItem(props, ref) {
    const {
      name,
      link,
      external,
      Icon,
      items = [],
      selected,
      indexChanged,
      index,
      chIndex,
    } = props;
    //const classes = useStyles();
    const isExpandable = items && items.length > 0;
    const isMobile = DetectIsMobile();
    const [open, setOpen] = React.useState(selected);
    const [isSelected, setSelected] = React.useState(selected);
    const [, setChildIndex] = React.useState(chIndex);
    const hasChanges = useSelector(selectHasNotSavedChanges);
    const dispatch = useDispatch();
    const { actions } = useLayoutSlice();
    const {
      expanded: sidebarExpanded,
      expandLocal,
    } = useExpandedSideBarState();
    const siteMapSettings = useSelector(selectSiteMapSettings);
    const location = useLocation();
    const [urlLink, setUrlLink] = React.useState<string | null | undefined>(
      link,
    );
    React.useEffect(() => {
      let active =
        !isNullOrUndefined(siteMapSettings) && link === location?.pathname;
      if (active) {
        let siteMapProvider = new SiteMapProvider(siteMapSettings);
        let node = siteMapProvider.findNode(
          location?.pathname || window.location.pathname,
        );

        setUrlLink(() => {
          if (node !== undefined) {
            const reactUrl = node.reactUrl;
            if (reactUrl !== undefined && reactUrl !== null) {
              const elementPath = reactUrl.split('?')[0] ?? reactUrl;
              if (elementPath === location.pathname) {
                return reactUrl;
              } else {
                return link;
              }
            } else {
              return link;
            }
          } else {
            return link;
          }
        });
      }
      return () => {
        active = false;
      };
    }, [link, location.pathname, siteMapSettings]);
    function handleClick(event, index) {
      if (hasChanges && (props.items === undefined || props.items.length < 1)) {
        dispatch(
          actions.setContinueToLink(
            props.link === null || props.link === undefined
              ? location.pathname
              : props.link,
          ),
        );
        dispatch(actions.setConfirmOpen(true));
        event.preventDefault();
      } else {
        setOpen(!open);
        if (props.index !== index) {
          setSelected(!isSelected);
        } else {
          if (props.items !== undefined && props.items?.length > 0) {
            setSelected(!selected);
          }
        }
        if (indexChanged !== undefined && indexChanged !== null) {
          indexChanged(event, index);
        }
      }
    }
    const handleChildClick = (event, index) => {
      if (hasChanges) {
        dispatch(
          actions.setContinueToLink(
            props.link === null || props.link === undefined
              ? location.pathname
              : props.link,
          ),
        );
        dispatch(actions.setConfirmOpen(true));
        event.preventDefault();
      } else {
        if (isMobile) {
          expandLocal(false);
        }
        setChildIndex(prev => {
          if (props.chIndex !== index) {
            return index;
          }
        });
        setChildIndex(index);
      }
    };

    const parentId = `menuitem-${index}`;
    return isExpandable && !sidebarExpanded ? (
      <HoverSideMenu
        ref={ref}
        items={items}
        selected={selected}
        handleClick={handleClick}
        handleChildClick={handleChildClick}
        external={external}
        id={`index-${index}`}
        index={index}
        link={urlLink || link}
        Icon={Icon}
        name={name}
      />
    ) : (
      <>
        <MenuItemComponent
          id={`${parentId}${chIndex === undefined ? '' : '-' + chIndex}`}
          className="menuItem"
          role={isExpandable ? 'menu' : 'menuitem'}
          link={urlLink || link}
          onClick={handleClick}
          external={external}
          selected={selected}
          currentIndex={index}
        >
          {/* Display an icon if any */}
          <div className="menuItemContent">
            {!!Icon && (
              <ListItemIcon className="menuItemIcon">{Icon}</ListItemIcon>
            )}
            <ListItemText
              className={!Icon ? 'menuItemTextInset' : 'menuItemText'}
              primary={name}
              inset={!Icon}
            />
          </div>
        </MenuItemComponent>

        {/* Display the expand menu if the item has children */}
        {isExpandable && (open || isSelected) && selected && (
          <List
            component="div"
            className="childRoot"
            disablePadding
            role="menu"
            data-parent-id={parentId}
          >
            {items.map((item, chIndex) => (
              <SidbarMenuItem
                {...item}
                index={index}
                chIndex={chIndex}
                key={chIndex}
                indexChanged={handleChildClick}
                selected={selected && item.selected}
              />
            ))}
          </List>
        )}
      </>
    );
  },
);

export default SidbarMenuItem;
