/**
 *
 * ExportButton
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Menu, MenuItem } from '@material-ui/core';
import { IRow } from 'app/components/BasicTable';
import { useDispatch, useSelector } from 'react-redux';
import { useDialogAlertSlice } from 'app/components/DialogAlert/slice';
import { ExportImportActions } from 'app/components/PopupImportExport/Actions';
import { ScreensId } from 'enums/ConfigurableTypes';
import fileDownload from 'js-file-download';
import { useAppSettingsSlice } from 'app/slice';
import { selectglobalSettings } from 'app/slice/selectors';
import { AllowedSettings } from 'utils/globalSettings';

export interface ExportButtonProps<T extends IRow> {
  //selectedRows: T[];
  onClick: () => Promise<void>;
  onDownloadTemplateClick?: () => void;
  useExportForImport?: boolean;
  getAllFilteredRowsCallback?: (prop: keyof T) => Promise<T[]>;
  selectedFilterRowProp?: keyof T;
  screenId?: ScreensId;
}

export function ExportButton<T extends IRow>(props: ExportButtonProps<T>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const { actions } = useDialogAlertSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();
  const settings = useSelector(selectglobalSettings);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleExport = async () => {
    handleClose();
    setProcessing(true);
    await props.onClick();
    setProcessing(false);
  };

  const handleExportForImportClick = () => {
    if (
      !!props.getAllFilteredRowsCallback &&
      !!props.selectedFilterRowProp &&
      !!props.screenId
    ) {
      handleExportForImport(
        props.getAllFilteredRowsCallback,
        props.selectedFilterRowProp,
        props.screenId,
      );
    } else {
      dispatch(actions.showAlert('Import for Export is not configured!'));
    }
    // if (
    //   props.selectedRows.length > 0 &&
    //   props.onExportForImportClick !== undefined
    // ) {
    //   props.onExportForImportClick(props.selectedRows);
    // } else {
    //   dispatch(actions.showAlert(t(translations.PleaseSelectAtLeasOneRow)));
    // }
    handleClose();
  };
  const handleExportForImport = async (
    rowsCallback: (prop: keyof T) => Promise<T[]>,
    p: keyof T,
    screenId: ScreensId,
  ) => {
    let toBigQS = '';
    try {
      const selectedRows = await rowsCallback(p);
      const exportRows = !!selectedRows
        ? selectedRows.map(item => item[p])
        : [];
      toBigQS = exportRows.join(',');
      const res = await ExportImportActions.ExportForImport(
        screenId,
        exportRows,
      );
      const cd = res.headers['content-disposition'];
      const filename =
        cd !== undefined ? cd.split('filename=')[1] : 'ExportForImport.xlsx';
      fileDownload(res.data, filename);
      if (ScreensId.MyAssets === screenId) {
        const MaxRecordsAllowedAssetImport = parseInt(
          settings.GetString(AllowedSettings.MaxRecordsAllowedAssetImport),
        );
        if (exportRows.length > MaxRecordsAllowedAssetImport) {
          dispatch(
            appSettingsActions.addNotification({
              variant: 'warning',
              message: `Only ${MaxRecordsAllowedAssetImport} rows were exported`,
            }),
          );
        }
      }
    } catch (error) {
      let messageLongQS =
        toBigQS.length > 5000
          ? (t(translations.err_ImportForExportFailed_longQS) as string)
          : undefined;
      let deffMessage = t(translations.err_ImportForExportFailed) as string;
      dispatch(
        appSettingsActions.addNotification({
          variant: 'error',
          message: !!messageLongQS ? messageLongQS : deffMessage,
        }),
      );
    }
  };
  const handleDownloadTemplateClick = () => {
    if (props.onDownloadTemplateClick !== undefined) {
      props.onDownloadTemplateClick();
    }
  };

  const showSingleExportButton = ![
    props.useExportForImport === true ? true : undefined,
    props.onDownloadTemplateClick,
  ].some(item => item !== undefined);

  // render a menu if both set of columns are provided
  // render single button if only export columns are present and the "for import" is not set
  return (
    <React.Fragment>
      <Button
        id="export-button"
        aria-controls="export-menu-button"
        aria-haspopup="true"
        variant="gray"
        size="small"
        startIcon={<Icon icon="print" size="sm" />}
        onClick={showSingleExportButton ? handleExport : handleClick}
        processing={processing}
        title={t(translations.ExportButtonTooltip) as string}
      >
        {t(translations.Export)}
      </Button>
      {!showSingleExportButton && (
        <>
          <Menu
            id="export-menu-button"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{ dense: true }}
            getContentAnchorEl={null}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleExport}>
              {t(translations.ExportToXLSX)}
            </MenuItem>
            {props.useExportForImport === true && (
              <MenuItem onClick={handleExportForImportClick}>
                {t(translations.ExportForImport)}
              </MenuItem>
            )}
            {props.onDownloadTemplateClick !== undefined && (
              <MenuItem onClick={handleDownloadTemplateClick}>
                {t(translations.DownloadTemplate)}
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </React.Fragment>
  );
}
