import * as React from 'react';
import { Entity } from 'types/common';
import { EditorProps } from '../../EditableCell';
import { Autocomplete, AutocompleteProps, Value } from '@material-ui/lab';
import { TextField } from 'app/components/TextField';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import { InputAdornment } from '@material-ui/core';
import { omit } from 'lodash';
import { Icon } from 'app/components/BasicIcons/FontAwesome';

// 'DropDownList':
export interface IWithOptions<TKey, TEntity extends Entity<TKey>> {
  options: TEntity[];
}
export interface DropDownListProps<TKey, TEntity extends Entity<TKey>>
  extends Omit<EditorProps<TEntity>, 'value'>,
    IWithOptions<TKey, TEntity> {
  value: EditorProps<TEntity>['value'] | null;
}

/**
 * Injects options into options prop of a component e.g DropDownList above
 * @param Component component that accepts options array
 * @param options options
 * @returns
 */
export function withOptions<TKey, TEntity extends Entity<TKey>, TProps>(
  Component: React.FC<TProps & IWithOptions<TKey, TEntity>>,
  options: IWithOptions<TKey, TEntity>['options'],
): React.FC<TProps> {
  const WithOptionsComponent = (props: TProps) => {
    return <Component options={options} {...props} />;
  };

  return WithOptionsComponent;
}

export interface SRSelectProps<
  TKey,
  T extends Entity<TKey>,
  Multiple extends boolean | undefined
> extends Omit<
      AutocompleteProps<T, Multiple, false, false>,
      | 'onChange'
      | 'getOptionLabel'
      | 'getOptionSelected'
      | 'filterOptions'
      | 'renderInput'
      | 'options'
    >,
    IWithOptions<TKey, T> {
  onChange: (value: Value<T, Multiple, false, false>) => void;
  parentValue?: any;
  error?: boolean;
  helperText?: string;
  info?: string;
  autoFocus?: boolean;
}

export function SRSelect<
  TKey,
  T extends Entity<TKey>,
  Multiple extends boolean | undefined
>({
  parentValue,
  onChange,
  error,
  helperText,
  info,
  autoFocus,
  ...props
}: SRSelectProps<TKey, T, Multiple>) {
  return (
    <>
      <Autocomplete
        onChange={(event, value, reason, details) => {
          onChange?.(value);
        }}
        value={props.value ?? undefined}
        getOptionLabel={option => option.Name}
        className="atable"
        getOptionSelected={(a, b) => a?.Id === b?.Id}
        size="small"
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              disableUnderline: true,
              autoFocus: autoFocus,
              startAdornment:
                !!info || error ? (
                  <InputAdornment position="start">
                    {params.InputProps.startAdornment}
                    {!!info && info !== '' && (
                      <InfoIcon
                        title={info}
                        error={error}
                        className={'tableInfo'}
                      />
                    )}
                    {error && (
                      <Icon
                        icon="info-circle"
                        color="danger"
                        colorExtend="textHover"
                        style={{ width: 13, height: 13 }}
                        fontSize="13px"
                        width="13px"
                      />
                    )}
                  </InputAdornment>
                ) : (
                  params.InputProps.startAdornment
                ),

              ...omit(params.InputProps, 'startAdornment'),
            }}
            variant="standard"
            error={error}
          />
        )}
        {...props}
      />
    </>
  );
}
