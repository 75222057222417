import * as React from 'react';
import { ISchedulerEvent } from '../../types/ISchedulerEvent';
import { EventContent, EventContentProps } from './EventContent';
import { EventHeader } from './EventHeader';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
export interface OfflineEventContentProps extends EventContentProps {
  event: ISchedulerEvent;
}
export function OfflineEventConent(props: OfflineEventContentProps) {
  return (
    <EventContent
      containerWidth={props.containerWidth}
      backgroundColor={
        (props.event.original as ICalendarReservationDto)?.EquipmentColor ||
        undefined
      }
      header={<EventHeader event={props.event} />}
      body={props.body}
      height={props.height}
      readonly={props.readonly}
      offsetTopHeight={props.offsetTopHeight}
      notClickable={props.notClickable}
    />
  );
}
