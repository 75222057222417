import { EndUsageData } from 'app/components/EndUsageConfirm';
import { CancelToken } from 'axios';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';

const url = '/api/odata/v4/AssetDetails';
const publicUrl = '/api/odata/v4/PublicAsset';
export const AssetDetailsApi = {
  getAssetById: (
    id: number | undefined,
    serviceId: number | undefined,
    columns: string[],
    expandable: string[],
    cancel?: CancelToken,
    isAuthenticated?: boolean,
  ) => {
    const params: {
      $select: string;
      serviceId: number;
      $expand?: string;
    } = {
      $select: columns.join(','),
      serviceId: serviceId ?? -1,
    };
    let expand = columns.filter(c => expandable.includes(c));
    if (expand && expand.length) {
      params.$expand = expand
        // articles need 2 level expand which don't fit into the column propName
        .map(f => (f === 'Articles' ? 'Articles($expand=Assets)' : f))
        .join(',');
    }
    const sourceUrl = isAuthenticated
      ? url + '(' + (id ?? -1) + ')'
      : publicUrl + '/' + (id ?? -1);
    return httpClient.get(sourceUrl, params, cancel);
  },
  endUsage: (id: number, data: EndUsageData): Promise<IResponseType> => {
    const params = {
      id: id,
      remarks: data.remarks ?? '',
      sids:
        data.services.map(s => s.service?.Id + ':' + s.quantity).join(',') ??
        '',
    };
    return httpClient.post(url + '/EndUsage', params);
  },
  downloadImages: (assetIds: string): Promise<any> => {
    const downUrl = `api/AssetImages/Selected?selectedIds=${assetIds}`;
    return httpClient.get(downUrl);
  },
};
