/**
 *
 * Asynchronously loads the component for SamplesListPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SamplesListPage = lazyLoad(
  () => import('./index'),
  module => module.SamplesListPage,
);
