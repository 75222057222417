/**
 *
 * EmailAttachmentTypePicker(multi)
 *
 */

import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { Entity } from 'types/common';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import i18next from 'i18next';
import { IEmailAttachmentTypesDto } from 'app/pages/BillingActions/BillingActionsPage/IEmailAttachmentTypesDto';
import { EATBaseURLMultiPicker } from './EATBaseURLMultiPicker';

export interface EmailAttachmentTypePickerProps {
  value?: Entity<number>[] | null;
  name?: string;
  error?: boolean;
  label?: string;
  className?: string | undefined;
  helperText?: string;
  placeholder?: string | undefined;
  // value: Entity<number>[];
  defaultValue?: Entity<number>[];
  onChange: (value: Entity<number>[]) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  variant?: 'standard' | 'filled' | 'outlined';
  info?: string;
  id?: string;
  required?: boolean;
  fullWidth?: boolean;
}

const url = '/api/odata/v4/EmailAttachmentTypesFilter';

export const initEmailAttachmentTypesData = (
  initval: string | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: new Condition('Id', ODataOperators.Equals, id).toString(),
      $select: 'Id,Name',
      $skip: 0,
      $top: 1,
    };
    return httpClient.get(url, params).then(
      response =>
        response.value.map(i => {
          return {
            Id: i.Id,
            Name: i18next.t(i.Name) as string,
          } as IEmailAttachmentTypesDto;
        }) as Entity<number>[],
    );
  }
};

export function EmailAttachmentTypePicker({
  label,
  defaultValue,
  placeholder,
  className,
  required,
  helperText,
  onChange,
  variant,
  info,
  id,
  value,
  ...props
}: EmailAttachmentTypePickerProps) {
  return (
    <EATBaseURLMultiPicker
      url={url}
      queryfilter={``}
      variant={variant}
      onChange={onChange}
      info={info}
      id={id || 'users-filter'}
      helperText={helperText}
      required={required}
      className={className}
      onBlur={props.onBlur}
      placeholder={placeholder}
      defaultValue={defaultValue ?? []}
      label={label}
      value={value ?? []}
      {...props}
      error={props.error}
    />
  );
}
