import { ITrainingSessionSlotDto } from 'api/odata/generated/entities/ITrainingSessionSlotDto';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ISchedulerEvent } from '../../types/ISchedulerEvent';
import { EventContent, EventContentProps } from './EventContent';
import { EventHeader } from './EventHeader';
import { TrainingSessionStatus } from './TrainingSessionStatus';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';

export interface TrainingSessionEventContentProps extends EventContentProps {
  event: ISchedulerEvent;
}
export function TrainingSessionEventConent(
  props: TrainingSessionEventContentProps,
) {
  return (
    <EventContent
      containerWidth={props.containerWidth}
      height={props.height}
      backgroundColor={
        (props.event.original as ICalendarReservationDto)?.EquipmentColor ||
        undefined
      }
      header={<TrainingSessionEventHeader event={props.event} />}
      body={<TrainingSessionEventBody event={props.event} />}
      readonly
      offsetTopHeight={props.offsetTopHeight}
      notClickable={props.notClickable}
    />
  );
}
export interface TrainingSessionEventHeaderProps {
  event: ISchedulerEvent;
}
export function TrainingSessionEventHeader(
  props: TrainingSessionEventHeaderProps,
) {
  return (
    <>
      <TrainingSessionStatus
        size="2x"
        className="dhx_training_status"
        event={props.event}
        // {...props}
      />
      <EventHeader event={props.event} />
    </>
  );
}

export interface TrainingSessionEventBodyProps {
  event: ISchedulerEvent;
}
export function TrainingSessionEventBody(props: TrainingSessionEventBodyProps) {
  const dto = props.event.original as ITrainingSessionSlotDto;
  return (
    <>
      <Icon icon="dumbbell" />
      {dto.TrainerName != null && (
        <>
          &ensp;
          {dto.TrainerName}
        </>
      )}
    </>
  );
}
