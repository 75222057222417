/**
 *
 * OnlineServicePicker
 *
 */
import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { Entity, Identifiable } from 'types/common';
import {
  AutocompleteMultiPickerEx,
  AutocompleteMultiPickerExProps,
} from 'app/components/BasicPickers/AutocompleteMultiPickerEx';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { InputBaseComponentProps } from '@material-ui/core';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { useSelector } from 'react-redux';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { AutocompletePicker } from 'app/components/BasicPickers/AutocompletePicker';
import { ServiceType } from 'enums/ServiceTypes';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { splitFilterValue } from 'app/components/BasicTable/types/FilterParam';
export type ServiceUrl = 'base' | 'admin' | 'reserved';
export interface OnlineServicePickerProps
  extends Omit<AutocompleteMultiPickerExProps<IServiceFilterDto>, 'loadData'> {
  inputProps?: InputBaseComponentProps;
  urlType?: ServiceUrl;
}

const url = '/api/odata/v4/ServiceFilter';
const adminUrl = '/api/odata/v4/ServiceFilter/AdminEquipments';
const reservableUrl = '/api/odata/v4/ServiceFilter/ReservableEquipments';
export const initOnlineServicesData = (
  initval: string | undefined,
  sourceurl?: ServiceUrl,
  separator?: string,
  serviceGroups?: Identifiable<number>[],
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    let ids = splitFilterValue(initval).map(item => {
      return { Id: parseInt(item) };
    }) as Identifiable<number>[];

    let foo: string | undefined = undefined;
    const predicates: string | Condition<IServiceFilterDto>[] = [];
    if (ids.length > 0) {
      predicates.push(new Condition('Id', ODataOperators.Includes, ids));
    }
    // apply only global service group filter related items filter
    // items not related to one of the selected cores are discarded
    if (serviceGroups !== undefined && serviceGroups?.length > 0) {
      predicates.push(
        new Condition(
          'ServiceGroupId',
          ODataOperators.Includes,
          serviceGroups.map(f => f.Id),
        ),
      );
    }
    foo = predicates.map(f => f.toString()).join(' and ');

    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
      $expand: string;
    } = {
      $orderby: 'Name asc',
      $filter: foo,
      $skip: 0,
      $top: 10,
      $expand: 'WorkingHours',
    };
    let initUrl =
      sourceurl === undefined
        ? url
        : sourceurl === 'reserved'
        ? reservableUrl
        : sourceurl === 'admin'
        ? adminUrl
        : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (
  predicates: (string | Condition<IServiceFilterDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  urlType: ServiceUrl | undefined,
) =>
  getAutoCompleteLoadDataFn<IServiceFilterDto>({
    url:
      urlType === 'reserved'
        ? reservableUrl
        : urlType === 'admin'
        ? adminUrl
        : url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'ServiceGroupId',
      'ServiceTypeId',
      'UseServiceGroupTrainingLevels',
      'Color',
      'ImageName',
      'ServiceGroupName',
      'RestrictDurationUnitsAmount',
      'RestrictDurationUnitType',
      'DefaultDuration',
      'ForceFullDayReservations',
      'AssemblyId',
      'TopAssemblyId',
      'AssetCatId',
      'AssetCatName',
      'RestrictReservationToTimeSlots',
      'MinOrderHours',
      'WorkingHours',
    ],
    expand: 'WorkingHours',
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<IServiceFilterDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });

export function OnlineServiceMultiPicker({
  label,
  defaultValue,
  placeholder,
  className,
  required,
  helperText,
  onChange,
  variant,
  info,
  id,
  value,
  predicates,
  disabled,
  inputProps,
  urlType,
  ...props
}: OnlineServicePickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(
    predicates,
    globalServiceGroupFilter || [],
    urlType,
  );
  return (
    <AutocompleteMultiPickerEx
      variant={variant}
      loadData={innerLoadData}
      onChange={onChange}
      disabled={disabled}
      info={info}
      id={id || 'online-service-multi-filter'}
      helperText={helperText}
      required={required}
      className={className}
      onBlur={props.onBlur}
      placeholder={placeholder}
      defaultValue={defaultValue ?? []}
      label={label}
      value={value ?? []}
      ariaLabel={'Equipment search'}
      {...props}
      error={props.error}
    />
  );
}
export function OnlineServiceSinglePicker({
  label,
  defaultValue,
  placeholder,
  className,
  required,
  helperText,
  onChange,
  variant,
  info,
  id,
  value,
  predicates,
  disabled,
  inputProps,
  urlType,
  ...props
}: OnlineServicePickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(
    predicates,
    globalServiceGroupFilter || [],
    urlType,
  );
  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      variant={variant}
      onChange={value => {
        if (!!onChange) {
          if (value !== null) {
            return onChange([value]);
          } else {
            return onChange([]);
          }
        }
      }}
      disabled={disabled}
      info={info}
      id={id || 'online-service-single-filter'}
      helperText={helperText}
      required={required}
      className={className}
      onBlur={props.onBlur}
      placeholder={placeholder}
      defaultValue={
        !!defaultValue
          ? defaultValue.length > 0
            ? (defaultValue[0] as IServiceFilterDto | undefined | null)
            : undefined
          : undefined
      }
      label={label}
      value={
        !!value
          ? value.length > 0
            ? (value[0] as IServiceFilterDto | undefined | null)
            : undefined
          : undefined
      }
      ariaLabel={'Equipment search'}
      {...props}
      error={props.error}
    />
  );
}

export function RestrictedByAdminFilter(
  admin?: IUserFilterDto,
): (string | Condition<IServiceFilterDto>)[] {
  const predicates: (string | Condition<IServiceFilterDto>)[] = [];
  if (!!admin) {
    if (!!admin.ServiceGroupIds && admin.ServiceGroupIds.length > 0) {
      let ids = admin.ServiceGroupIds.map(f => {
        return { Id: f } as Identifiable<number>;
      });
      predicates.push(
        new Condition<IServiceFilterDto>(
          'ServiceGroupId',
          ODataOperators.Includes,
          ids,
        ),
      );
    }
    if (!!admin.Services && admin.Services.length > 0) {
      let ids = admin.Services.filter(
        f => f.ServiceTypeId === (ServiceType.Online as number),
      ).map(f => {
        return { Id: f.Id } as Identifiable<number>;
      });
      predicates.push(
        new Condition<IServiceFilterDto>('Id', ODataOperators.Includes, ids),
      );
    }
    if (predicates.length > 0) {
      return [predicates.map(f => f.toString()).join(' or ')];
    } else {
      if (admin.IsAdminPanel) {
        return [];
      } else {
        return [
          new Condition<IServiceFilterDto>('Id', ODataOperators.Equals, 0),
        ];
      }
    }
  } else {
    return [];
  }
}
export function ServiceGroupFilter(
  serviceGroupId?: number,
): (string | Condition<IServiceFilterDto>)[] {
  const predicates: (string | Condition<IServiceFilterDto>)[] = [];
  if (!!serviceGroupId) {
    predicates.push(
      new Condition<IServiceFilterDto>(
        'ServiceGroupId',
        ODataOperators.Equals,
        serviceGroupId,
      ),
    );
    return predicates;
  } else {
    return [];
  }
}
