/**
 *
 * FilterContainer
 *
 */
import * as React from 'react';
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  styled,
  Theme,
} from '@material-ui/core';
import { DetectIsMobile } from 'utils/mobileDetect';
import { Popper } from 'app/components/Popper';
import { useTranslation } from 'react-i18next';
import { IFilterSettings } from '../BasicFilter/IFilterSettings';
import { translations } from 'locales/translations';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Button } from 'app/components/BasicButtons/Button';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import useGlobalDOMEvents from 'app/hooks/useGlobalDomEvents';
import clsx from 'clsx';
import { IsEmptyArray } from '../BasicFilter/AppliedFilterComponent';

interface FilterContainerProps<TRow> {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  anchorEl: any;
  appliedFilters: IFilterSettings<TRow>[];
  onChange: (items: IFilterSettings<TRow>[]) => void;
  compact?: boolean;
}
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    filterRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0,
      gap: 16,
      height: '100%',
      overflow: 'hidden',
      maxWidth: 700,
    },
    scrollPaperCompact: {
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
    scrollPaper: {
      paddingTop: 40,
    },
  }),
);

export function FilterContainer<TRow>({
  anchorEl,
  onClose,
  onChange,
  open,
  children,
  compact,
  ...props
}: FilterContainerProps<TRow>) {
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  const handleClose = () => {
    onClose();
  };
  // save provided applied filters to be used in change callbacks
  const [, setVisibleFilters] = React.useState<IFilterSettings<TRow>[]>(
    props.appliedFilters,
  );

  // refresh applied filters if they were changed from outside
  React.useEffect(() => {
    setVisibleFilters(props.appliedFilters);
  }, [props.appliedFilters]);

  const handleClearClick = () => {
    setVisibleFilters(currentState => {
      const newState = currentState.map(f => ({ ...f, ...{ value: null } }));
      onChange(newState);
      return newState;
    });
    if (isMobile || compact) {
      onClose();
    }
  };
  const elementRef = React.useRef<HTMLDivElement>(null);
  // manually trigger rerender using a useless usState hook
  // this ensures that the popper is rendered only after the div is rendered and have provided the elementRef value to ancor the popper
  const countState = React.useState(0);
  React.useEffect(() => {
    countState[1](c => c + 1);
    // setCount (rerender) needs to occur after element elementRef.current value is bound
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);
  const classes = useStyles();
  const appliedFitltersCount = props.appliedFilters.filter(
    f =>
      f.value !== null &&
      f.value !== undefined &&
      f.value !== '' &&
      !IsEmptyArray(f.value),
  ).length;

  useGlobalDOMEvents({
    keyup(ev) {
      if ((ev as KeyboardEvent).key === 'Escape') {
        onClose();
      }
    },
  });

  const title = `${t(translations.Filters)} (${appliedFitltersCount})`;
  if (isMobile || compact) {
    return (
      <Dialog
        fullScreen={isMobile}
        open={open}
        onClose={handleClose}
        PaperComponent={StyledDialogPaper}
        BackdropProps={{
          invisible: true,
        }}
        className={clsx({
          [classes.scrollPaperCompact]: compact,
          [classes.scrollPaper]: !compact,
        })}
      >
        {/* <Box className={classes.filterPanel}>
          <Box component="div" className={classes.filterRoot}>
            
          </Box>
        </Box> */}
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            variant="ghost"
            size="small"
            shape="square"
            onClick={() => {
              onClose();
            }}
            style={{ position: 'absolute', right: 4, top: 4 }}
          >
            <Icon icon="times" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={
            isMobile ? { overflow: 'hidden', padding: '8px 16px' } : undefined
          }
        >
          {
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                height: '100%',
                width: '100%',
              }}
            >
              {children}
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            color="primary"
            onClick={() => onClose()}
            aria-label={t(translations.apply)}
          >
            {t(translations.apply)}
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={handleClearClick}
            aria-label={t(translations.ClearAll)}
          >
            {t(translations.ClearAll)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <>
        <Popper
          id="basic-filter-group-popover"
          open={open}
          anchorEl={anchorEl}
          title={title}
          onClose={() => onClose()}
          clickAway={true}
          clickAwayFunc={e => {
            onClose();
          }}
          maxWidth={'fit-content'}
        >
          <div
            className={classes.filterRoot}
            ref={elementRef}
            role="presentation"
          >
            <>{children}</>
          </div>
        </Popper>
      </>
    );
  }
}
// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   paddingTop: theme.spacing(5),
// }));
const StyledDialogPaper = styled(Paper)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  margin: 0,
}));
