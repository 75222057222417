import { InvoiceDetailsSidePanelLink } from 'app/pages/InvoiceDetails/InvoiceDetailsSidePanel';
import { CellProps, Renderer } from 'react-table';

type RecordType = Record<string, any>;
const InvoiceIdRenderer: Renderer<CellProps<RecordType>> = ({ value, row }) => {
  return value !== null && value !== undefined && typeof value === 'number' ? (
    <InvoiceDetailsSidePanelLink id={value}>
      {value}
    </InvoiceDetailsSidePanelLink>
  ) : (
    <>{value}</>
  );
};
export default InvoiceIdRenderer;
