/**
 *
 * MyAssetAvailabilityTypeMultiFilter
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { MyAssetAvailabilityTypeMultiPicker } from 'app/components/pickers/AutocompletePickers/MyAssetAvailabilityTypeMultiPicker';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { AvailabilityTypes } from 'enums/asset';

export const MyAssetAvailabilityTypeMultiFilter = (
  props: IFilterComponentProps,
) => {
  const handleOnChange = (value: Entity<number>[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <MyAssetAvailabilityTypeMultiPicker
      isOpen={props.open}
      ariaLabel={props.label}
      id={props.id}
      placeholder={t(translations.AllValues)}
      value={props.value}
      size="small"
      onChange={handleOnChange}
      filterSelectedOptions
    />
  );
};
export const AvailabilityTypeAvailableMultiFilter = (
  props: IFilterComponentProps,
) => {
  const handleOnChange = (value: Entity<number>[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <MyAssetAvailabilityTypeMultiPicker
      isOpen={props.open}
      ariaLabel={props.label}
      id={props.id}
      placeholder={t(translations.AllValues)}
      size="small"
      value={props.value}
      predicates={[
        `${new Condition('Id', ODataOperators.Excludes, [
          { Id: AvailabilityTypes.NotAvailable as number },
          { Id: AvailabilityTypes.AvailableNotBookable as number },
        ]).toString()}`,
      ]}
      onChange={handleOnChange}
      filterSelectedOptions
    />
  );
};
export const AvailabilityTypeAvailableOnlineMultiFilter = (
  props: IFilterComponentProps,
) => {
  const handleOnChange = (value: Entity<number>[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <MyAssetAvailabilityTypeMultiPicker
      isOpen={props.open}
      ariaLabel={props.label}
      size="small"
      id={props.id}
      placeholder={t(translations.AllValues)}
      value={props.value}
      urlType="onlineServices"
      onChange={handleOnChange}
      filterSelectedOptions
    />
  );
};
export default MyAssetAvailabilityTypeMultiFilter;
