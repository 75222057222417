import { RefObject } from 'react';
import useEventListener from './useEventListener';

type Handler = (event: KeyboardEvent) => void;

export function useKeyUpOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  keyboardEvent: 'keyup',
  key: string,
): void {
  useEventListener(keyboardEvent, event => {
    if ((event as KeyboardEvent).key === key) {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    }
  });
}
