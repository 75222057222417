import { styled } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import {
  //selectAuthenticatedUser,
  selectEventsCount,
} from 'app/slice/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Link } from 'react-router-dom';
import { dateUtils } from 'utils/date-utils';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { bookitColors } from 'styles/colors/bookitColors';
export interface EventsCountProps {}

export const EventsCount = React.memo(function EventsCount(
  props: EventsCountProps,
) {
  const count = useSelector(selectEventsCount);
  const { t } = useTranslation();
  const { newDate } = useSystemDate();
  //const user = useSelector(selectAuthenticatedUser);
  const StyledSpanIcon = styled('span')(({ theme }) => ({
    fontSize: '20px',
    color: bookitColors.grayscale.grayComponentsLight,
    '&:hover': { color: bookitColors.grayscale.grayComponents },
  }));
  return (
    <>
      <Link
        color="inherit"
        aria-label={t(translations.Alerts) as string}
        to={`/workorders?period=1&et=${dateUtils.formatQueryStringDate(
          newDate(),
        )}`}
      >
        <Tooltip title={t(translations.Alerts) as string}>
          <StyledSpanIcon className="fa-layers fa-fw">
            <Icon icon={['fas', 'tools']} />
            <span
              className="fa-layers-counter"
              style={{
                whiteSpace: 'nowrap',
                fontSize: '26px',
                right: '-4px',
                top: '-4px',
              }}
            >
              {count}
            </span>
          </StyledSpanIcon>
        </Tooltip>
      </Link>
    </>
  );
});
