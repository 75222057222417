import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { getGetUserSelectionsAutocompleteFn } from 'app/components/CustomForm/CustomFormFields/CustomFormUserSelection';
import * as React from 'react';
import { Entity } from 'types/common';
import { IWithOptions, SRSelectProps } from './index';
import { useAsyncOptions } from './useAsyncOptions';

/**
 * Injects async options into options prop of a component e.g DropDownList above
 * @param Component component that accepts options array
 * @param options options
 * @returns
 */
export function withAsyncOptions<
  TKey,
  TEntity extends Entity<TKey>,
  TMultiple extends boolean | undefined,
  TProps extends SRSelectProps<TKey, TEntity, TMultiple>
>(
  Component: React.FC<TProps & IWithOptions<TKey, TEntity>>,
  hocProps: {
    multiple: TMultiple;
  } & Pick<IFormFieldDto, 'CustomRoles' | 'FixedRoles' | 'UserSelectionType'>,
): React.FC<TProps> {
  const WithOptionsComponent = ({ ...props }: TProps) => {
    const fetchOptionsCallback = React.useCallback(async search => {
      const fn = getGetUserSelectionsAutocompleteFn({
        CustomRoles: hocProps.CustomRoles,
        FixedRoles: hocProps.FixedRoles,
        UserSelectionType: hocProps.UserSelectionType,
      });
      const options = await fn(search);
      return options;
    }, []);
    const asyncOptionProps = useAsyncOptions<Entity<string>>({
      fetchOptions: fetchOptionsCallback,
    });
    return (
      <Component
        multiple={hocProps.multiple}
        {...asyncOptionProps}
        {...props}
      />
    );
  };

  return WithOptionsComponent;
}
