import * as React from 'react';
import clsx from 'clsx';

/**
 * EventContent props
 */
export interface EventContentProps {
  /**
   * Background color of the event (instrument color)
   */
  backgroundColor?: string;
  /**
   * Width of the container
   */
  containerWidth: string;
  /**
   * Width in pixels of the event container element (e.g. event length * time scale)
   */
  height: number;
  /**
   * Header content
   */
  header?: React.ReactNode;
  /**
   * Body content
   */
  body?: React.ReactNode;
  /**
   * Readonly
   */
  readonly?: boolean;
  /**
   * calculated top position of the event content on calendar week/unit view
   */
  offsetTopHeight?: number;
  notClickable?: boolean;
}

/**
 * Provides a layout of event element
 * @param props props
 * @returns Event Content
 */
export function EventContent(props: EventContentProps) {
  // overrides .dhx_cal_event .dhx_title height property from dhtmlxscheduler_material.css
  const EVENT_TITLE_HEIGHT = 34;
  return (
    <>
      {/* this one is in the dhtmlx's demo/docs, but this element does not appear much */}
      {!props.readonly && (
        <div className="dhx_event_move dhx_header">&nbsp;</div>
      )}
      {/* drag handle on the event title */}
      {props.header !== undefined && (
        <div
          className={clsx(
            {
              dhx_event_move: !props.readonly,
              dhx_event_click: !props.notClickable,
            },
            'dhx_title',
          )}
          style={{
            width: props.containerWidth,
            backgroundColor: props.backgroundColor,
            height: EVENT_TITLE_HEIGHT,
          }}
        >
          {props.header}
        </div>
      )}
      <div
        className={clsx(
          {
            dhx_event_move: !props.readonly,
            dhx_event_click: !props.notClickable,
          },
          'dhx_body',
        )}
        style={{
          height:
            props.height -
            (props.header === undefined ? 0 : EVENT_TITLE_HEIGHT),
          position: 'absolute',
          top:
            (props.offsetTopHeight ?? 0) === 0
              ? 'unset'
              : props.offsetTopHeight ?? 0,
        }}
      >
        {props.body}
      </div>
      {/* resize handle on the bottom of the event */}
      {!props.readonly && (
        <div
          // dhx_event_resize must be the first className of the resize handle
          className="dhx_event_resize dhx_footer"
          style={{
            width: props.containerWidth,
          }}
        ></div>
      )}
    </>
  );
}
