import * as DHTMLX from 'dhtmlx-scheduler';
import { ScrollPosition } from './slice/types';

/**
 * Updates the view and preserves scroll position on the timeline.
 * Rerenders entire scheduler including scales, headers, etc.
 * @param scheduler scheduler instance
 */
export function updateView(
  scheduler?: DHTMLX.SchedulerStatic,
  currentPosition?: ScrollPosition,
  forceUpdate?: boolean,
) {
  const currentView = scheduler?.getView();
  if (forceUpdate === true) {
    scheduler?.updateView();
    // updateView causes the mark_now to disappear (inside of the reset_scales fn) for some reason.
    // _mark_now is used here to reset the current time (mark_now) marking
    try {
      (scheduler as any)?._mark_now();
    } catch (error) {}
  }
  if (currentPosition !== undefined) {
    currentView.scrollTo(currentPosition);
  }
}
