import React from 'react';
import { ListItem, ListItemProps } from '@material-ui/core';
import { LinkBehavior } from 'app/components/ExternalLink';
import { Link } from 'react-router-dom';

export interface MenuItemComponentProps
  extends Pick<ListItemProps, 'id' | 'className' | 'role'> {
  link?: string | null; // because the InferProps props allows alows null value
  onClick: (event: React.MouseEvent<HTMLElement>, index: number) => void;
  external: boolean;
  selected: boolean;
  currentIndex: number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>, index: number) => void;
}
const MenuItemComponent: React.FC<MenuItemComponentProps> = props => {
  const {
    className,
    link,
    onClick,
    children,
    external,
    selected,
    currentIndex: currentindex,
    onKeyDown,

    ...other
  } = props;
  // If link is not set return the orinary ListItem
  if (!link || typeof link !== 'string') {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={event => onClick(event, currentindex)}
        onKeyDown={event => onKeyDown && onKeyDown(event, currentindex)}
        selected={selected}
        {...other}
      />
    );
  } else {
    if (external) {
      return (
        <ListItem
          button
          className={className}
          children={children}
          onClick={event => onClick(event, currentindex)}
          onKeyDown={event => onKeyDown && onKeyDown(event, currentindex)}
          component={LinkBehavior}
          href={link}
          selected={selected}
          {...other}
        />
      );
    }
    return (
      <ListItem
        button
        className={className}
        children={children}
        component={Link}
        to={link}
        onClick={event => onClick(event, currentindex)}
        onKeyDown={event => onKeyDown && onKeyDown(event, currentindex)}
        selected={selected}
        {...other}
      />
    );
  }

  // Return a LitItem with a link component
};

export default MenuItemComponent;
