import {
  Dialog,
  DialogTitle,
  DialogContent,
  //DialogContentText,
  DialogActions,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { TextField } from 'app/components/TextField';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { translations } from 'locales/translations';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IInvoiceTemplatePopUp } from '../IInvoiceTemplatePopUp';

export interface HtmlTextBoxProps extends ButtonProps {
  Id: number | undefined;
  className?: string | undefined;
  setInitialValue: Dispatch<SetStateAction<IInvoiceTemplatePopUp>>;
  isEdit: boolean;
  setBusy: (value: SetStateAction<boolean>) => void;
  formikValues: IInvoiceTemplatePopUp;
}

export const HtmlTextBox = ({
  Id,
  className,
  setInitialValue,
  isEdit,
  setBusy,
  formikValues,
}: HtmlTextBoxProps): JSX.Element => {
  //PROPS
  const { t } = useTranslation();

  //REDUX
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();

  //STATES
  const [open, setOpen] = useState<boolean>(false);
  const [textBoxValue, setTextBoxValue] = useState<string | null | undefined>(
    formikValues.Html,
  );

  //FUNCTIONS
  const OnUpdate = useCallback(
    (invoiceTemplate: IInvoiceTemplatePopUp) => {
      setBusy(true);
      setInitialValue(invoiceTemplate);
      dispatch(actions.resetSidePanel());
      //if Add mode
      if (!isEdit) {
        dispatch(
          actions.openSidePanel({
            type: RenderPageType.InvoiceTemplatePopUp,
            expanded: true,
            props: {},
          }),
        );
      } else {
        //if Edit mode
        dispatch(
          actions.openSidePanel({
            type: RenderPageType.InvoiceTemplatePopUp,
            expanded: true,
            props: {
              Id: Id,
            },
          }),
        );
      }
      setBusy(false);
    },
    [Id, actions, dispatch, isEdit, setBusy, setInitialValue],
  );

  //CSS
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      invoiceTemplateHtmlTextBox: {
        width: '100%',
        minWidth: 500,
        backgroundColor: theme.palette.background.default,
        //position: 'relative',
        //overflow: 'auto',
        maxHeight: '63vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          boxShadow: `inset 0 0 2px ${theme.palette.common.grayBorders}`,
          borderRadius: '6px',
          backgroundColor: theme.palette.common.grayBorders,
        },
      },
    }),
  );
  const classes = useStyles();

  //RENDER
  return (
    <React.Fragment>
      <Button
        className={className}
        variant="ghost"
        size="small"
        startIcon={<Icon icon="code" />}
        onClick={() => {
          setOpen(true);
        }}
      ></Button>
      <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t(translations.InvoiceTemplateHtmlSource)}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            {t(translations.InvoiceTemplateHtmlSource)}
          </DialogContentText> */}
          <TextField
            className={classes.invoiceTemplateHtmlTextBox}
            multiline={true}
            id="HtmlTextBoxID"
            defaultValue={formikValues.Html}
            onChange={event => {
              setTextBoxValue(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            variant="ghost"
          >
            {t(translations.Cancel)}
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              let values = { ...formikValues };
              values.Html = textBoxValue;
              OnUpdate(values);
            }}
            variant="main"
          >
            {t(translations.Update)}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
