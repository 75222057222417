/**
 * Bookit.Data.CatRate.CatRateTypes
 */
export enum CatRateTypes {
  Usage = 1,

  FixedUsage = 2,

  Reservation = 3,

  FixedReservation = 4,

  TutoringUsage = 5,

  FixedTutoringUsage = 6,

  TutoringReservation = 7,

  FixedTutoringReservation = 8,

  ReservationCancelation = 9,

  OfflineService = 10,

  ServiceRequestLine = 11,

  ServiceRequest = 12,

  Other = 13,

  UsageExceededReservation = 16,

  /// <summary>
  /// This Fee Type is used for display purposes in the invoice rows to differentiate offlin services from service requests
  /// </summary>

  ServiceRequestServices = 17,
  /// <summary>
  /// This Fee Type is used for setup Milistones Requests in the Price Sheet screen
  /// </summary>

  ServiceRequestMilestones = 18,

  TrainingSessions = 19,

  FixedTrainingSessions = 20,

  Subscription = 21,

  PartialReservationCancellation = 22,

  Refund = 23,
}
