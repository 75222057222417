import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { TimelineGroupByUnion } from 'api/odata/generated/enums/TimelineGroupBy';
import { getAssetImageThumbSrc } from 'app/components/AssetThumbImage/getAssetImageSrc';
import produce from 'immer';
import { chain } from 'lodash';
import { AppSettings, IsAssetImageExist } from 'types/AppSettings';
import { Entity } from 'types/common';
import { assertExhaustive } from 'utils/assertExhaustive';
import {
  ISchedulerAssetCatSection,
  ISchedulerSection,
  ISchedulerServiceSection,
} from './types/ISchedulerSection';

export interface fooProps {
  services: IServiceFilterDto[] | undefined;
  appSettings: AppSettings | null;
  groupBy?: TimelineGroupByUnion;
}

export function prepareSchedulerSections(props: fooProps): ISchedulerSection[] {
  if (props.services === undefined || props.services.length === 0) {
    return [{ label: '', type: 'empty' }];
  }
  const r1 = props.services.map(setImageName(props.appSettings));
  const groupByOption = props.groupBy ?? 'NoTimelineGrouped';
  switch (groupByOption) {
    case 'GroupByAssemblyPart':
      return groupAssemblyParts(r1);
    case 'GroupByAssetCat':
      return groupAssetCats(r1);
    case 'NoTimelineGrouped':
      return r1.map(mapServiceToSection);
    default:
      assertExhaustive(groupByOption);
  }
}
function mapServiceToSection(
  service: IServiceFilterDto,
): ISchedulerServiceSection {
  return {
    type: 'service',
    key: service.Id,
    label: service.Name,
    data: service,
  };
}

export function groupAssetCats(
  services: Array<IServiceFilterDto>,
): ISchedulerSection[] {
  const result = chain(services)
    .groupBy(service =>
      JSON.stringify({ Id: service.AssetCatId, Name: service.AssetCatName }),
    )
    .map((value, key) => {
      const assetCat: Entity<number> = JSON.parse(key);
      const section: ISchedulerAssetCatSection = {
        key: `assetCat_${assetCat.Id}`,
        label: assetCat.Name,
        open: true,
        type: 'assetCat',
        children: value.map(mapServiceToSection),
      };
      return section;
    })
    .value();
  return result;
}
/**
 * Groups services by their respective assembly configuration
 * @param services
 * @returns
 */
export function groupAssemblyParts(
  services: Array<IServiceFilterDto>,
): ISchedulerSection[] {
  const sections: ISchedulerServiceSection[] = services.map(item => ({
    type: 'service',
    key: item.Id,
    label: item.Name,
    open: false,
    data: { ...item },
  }));

  const result = sections.reduce(
    (accumulator, currentValue, currentIndex, array) => {
      const parent =
        array.find(f => f.key === currentValue.data.AssemblyId) ??
        array.find(f => f.key === currentValue.data.TopAssemblyId);

      if (parent !== undefined) {
        accumulator.splice(accumulator.indexOf(currentValue), 1);
        if (parent.children === undefined) {
          parent.children = [];
        }
        parent.children?.push(currentValue);
      }
      return accumulator;
    },
    [...sections],
  );

  return result;
}

function setImageName(appSettings: AppSettings | null | undefined) {
  return produce((service: IServiceFilterDto) => {
    service.ImageName =
      getAssetImageThumbSrc(
        service.ImageName || null,
        'thumbSmall',
        IsAssetImageExist(
          appSettings ?? null,
          service.ImageName || null,
          'thumbSmall',
        ),
      ) ?? null;
  });
}
