import { IsEmptyArray } from 'app/components/BasicTable/BasicFilter/AppliedFilterComponent';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import {
  findIsfilterInversed,
  URLSearchParamsCI,
} from 'app/components/BasicTable/types/FilterParam';
import { toURLSearchParams } from 'app/components/BasicTable/withSavedHistory';
import { initFilterData } from 'app/components/BasicTable/initFilterData';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { savedViewActions } from 'app/pages/SavedViewsPage/SavedViewPage/slice';
import { SavedViewSelected } from 'app/pages/SavedViewsPage/SavedViewPage/slice/types';
import {
  selectAppSettings,
  selectAuthenticatedUser,
  selectglobalSettings,
} from 'app/slice/selectors';
import { routerActions } from 'connected-react-router';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppSettings } from 'types/AppSettings';
import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { tryParseInt } from 'utils/string-utils';
import { isEmptyOrWhitespace } from 'utils/typeUtils';
import { SAVEDVIEW_SEARCH_KEY } from '../BasicFilter/SavedListsDropDown';
import { getDependencyFilters } from '../BasicFilter';
import { BasicTableProps, IRow } from './UnconfigurableTable';
import React from 'react';

function visibleFiltersFilter<TRow>(item: IFilterSettings<TRow>) {
  return (
    (item.value !== undefined && !IsEmptyArray(item.value)) || item.notNullable
  );
}

export function filterHandler<TRow extends IRow, P extends object>(
  Component: React.ComponentType<BasicTableProps<TRow>>,
  GetFiltersDefinition: GetPageFilters<TRow>,
  other?: {
    getDefaultFilter?: (user: IAuthenticatedUser) => URLSearchParams;
    reservedQueryStringParameterNames?: string[];
    isSidePanel?: boolean;
    setFilters?: (appliedFilters: IFilterSettings<TRow>[] | undefined) => void;
    getRequiredFilter?: (
      search: string,
      appSettings?: AppSettings | null,
    ) => string | undefined;
    getSelectedViewFilter?: (
      search: string,
      view: SavedViewSelected,
    ) => string | undefined;
    enableSavedLists?: boolean;
  },
): React.FC<P & BasicTableProps<TRow>> {
  return ({
    onFilterChange,
    availableFilters,
    savedListSettings,
    ...props
  }: BasicTableProps<TRow>) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authenticatedUser = useSelector(selectAuthenticatedUser);
    const showFiltersFn = React.useCallback(visibleFiltersFilter, []);
    const {
      isSidePanel,
      getDefaultFilter,
      setFilters,
      reservedQueryStringParameterNames,
      getRequiredFilter,
      // getSelectedViewFilter,
      enableSavedLists,
    } = useMemo(() => other ?? {}, []);
    if (authenticatedUser === undefined) {
      return <>not authenticated</>;
    }

    useEffectOnMount(() => {
      const cancelToken = { mounted: true };
      const preset = getPresetFilter(
        isSidePanel
          ? undefined
          : withoutReserved(location.search, reservedQueryStringParameterNames),
        authenticatedUser,
      );
      const defaultSearch =
        getDefaultFilter !== undefined && getDefaultFilter !== null
          ? getDefaultFilter(authenticatedUser)?.toString()
          : undefined;
      let search =
        isSidePanel || isEmptyOrWhitespace(preset)
          ? defaultSearch ?? ''
          : location.search;
      const requiredSearch =
        getRequiredFilter !== null && getRequiredFilter !== undefined
          ? getRequiredFilter(search, appSettings)
          : undefined;
      search = requiredSearch === undefined ? search : requiredSearch ?? '';

      const savedViewId = tryParseInt(
        new URLSearchParamsCI(search).get(SAVEDVIEW_SEARCH_KEY),
      );
      dispatch(savedViewActions.selectSavedView(savedViewId));
      // const savedViewSearch = !!getSelectedViewFilter ? getSelectedViewFilter()

      const localizedFilters = getLocalizedFilters(search);
      // let fooFilters = foo?.filter(
      //   item => item.value !== undefined && !IsEmptyArray(item.value),
      // );
      // let depFilters = getDependencyFilters(fooFilters);
      let visibleFilters = localizedFilters
        ?.map(f => {
          return {
            ...f,
            isInversed: findIsfilterInversed(
              search,
              f.urlKey ??
                (typeof f.queryStringSerializer === 'string'
                  ? f.queryStringSerializer
                  : undefined),
            ),
          };
        })
        .filter(showFiltersFn);
      let depFilters = getDependencyFilters(visibleFilters);

      setAppliedFilters(depFilters);
      setAppliedFilters(initFilterData(depFilters), cancelToken);
      if (!isSidePanel) {
        if (
          isEmptyOrWhitespace(
            withoutReserved(location.search, reservedQueryStringParameterNames),
          )
        ) {
          if (defaultSearch !== undefined) {
            const withReserved = addReserved(
              new URLSearchParams(defaultSearch),
              location.search,
              reservedQueryStringParameterNames,
            ).toString();
            dispatch(
              routerActions.replace({
                pathname: location.pathname,
                search: withReserved,
              }),
            );
          }
        }
      }
      return () => {
        cancelToken.mounted = false;
      };
    });

    const location = useLocation();
    const removeEmptyFilters = (
      filter: string | undefined,
    ): string | undefined => {
      if (filter === undefined || filter === null) {
        return undefined;
      } else {
        const params = new URLSearchParams(filter);
        let newParams = new URLSearchParams();
        for (const [name, value] of params) {
          if (value !== undefined && value !== null && value !== '') {
            newParams.append(name, value);
          }
        }
        return newParams.toString();
      }
    };

    function getPresetFilter(
      savedFilter: string | undefined | null,
      user: IAuthenticatedUser,
    ): string | undefined {
      const saved =
        savedFilter !== undefined &&
        savedFilter !== null &&
        !isEmptyOrWhitespace(savedFilter)
          ? removeEmptyFilters(savedFilter)
          : undefined;
      const result = saved === undefined || saved === '' ? undefined : saved;

      return result;
    }

    const appSettings = useSelector(selectAppSettings);
    const settings = useSelector(selectglobalSettings);
    const getLocalizedFilters = useMemo(() => {
      const result = GetFiltersDefinition(
        t,
        appSettings,
        authenticatedUser,
        settings,
      );
      return result;
    }, [appSettings, authenticatedUser, settings, t]);
    const [appliedFilters, setAppliedFilters] = useAsyncExtendedState<
      IFilterSettings<TRow>[] | undefined
    >(undefined);

    const allfilters = useMemo(() => {
      var result = !appliedFilters
        ? getLocalizedFilters('')
        : getLocalizedFilters(
            toURLSearchParams<TRow>(appliedFilters).toString(),
          );
      return result;
    }, [getLocalizedFilters, appliedFilters]);

    useEffect(() => {
      setFilters?.(appliedFilters);
    }, [appliedFilters, setFilters]);

    const handleFilterChange: (
      items: IFilterSettings<TRow>[],
    ) => void = items => {
      const currentSearch = toURLSearchParams<TRow>(appliedFilters ?? []);
      if (!isSidePanel && reservedQueryStringParameterNames) {
        const p = new URLSearchParams(location.search);
        [...p.keys()].forEach(key => {
          if (reservedQueryStringParameterNames.includes(key)) {
            const val = p.get(key);
            if (val !== null) {
              currentSearch.set(key, val);
            }
          }
        });
      }
      const filterSearch = toURLSearchParams<TRow>(items);
      for (const key of filterSearch.keys()) {
        const filterValue = filterSearch.get(key);
        if (filterValue !== null) {
          currentSearch.set(key, filterValue);
        }
      }
      const originalSearch = new URLSearchParamsCI(location.search);

      // clear saved view selection when instrument selection changes
      if (enableSavedLists && savedListSettings !== undefined) {
        if (savedListSettings?.savedListSerializedKey !== undefined) {
          const currentInstrumentSelection = currentSearch.get(
            savedListSettings?.savedListSerializedKey,
          );
          const originalInstrumentSelection = originalSearch.get(
            savedListSettings?.savedListSerializedKey,
          );
          if (currentInstrumentSelection !== originalInstrumentSelection) {
            // remove the selection from the search
            currentSearch.delete(SAVEDVIEW_SEARCH_KEY);
            // clear redux state
            dispatch(savedViewActions.selectMyList());
          }
        }
      }

      setAppliedFilters(getDependencyFilters([...items]));
      // push current filter state to the URL search if it's not equivalent
      if (!originalSearch.equals(currentSearch) && !isSidePanel) {
        dispatch(
          routerActions.replace({
            pathname: location.pathname,
            search: currentSearch.toString(),
          }),
        );
      }
    };

    return (
      <>
        {appliedFilters !== undefined && (
          <Component
            availableFilters={allfilters}
            appliedFilters={appliedFilters}
            onFilterChange={handleFilterChange}
            savedListSettings={savedListSettings}
            {...(props as BasicTableProps<TRow>)}
          />
        )}
      </>
    );
  };
}

const withoutReserved = (
  search: string,
  reserved: string[] | undefined,
): string => {
  if (reserved === undefined || reserved.length === 0) return search;
  const originalSearch = new URLSearchParamsCI(search);
  reserved.forEach(f => originalSearch.delete(f));
  return originalSearch.toString();
};

const addReserved = (
  current: URLSearchParams,
  search: string,
  reserved: string[] | undefined,
) => {
  if (reserved === undefined || reserved.length === 0) return current;
  const p = new URLSearchParams(search);
  [...p.keys()].forEach(key => {
    if (reserved.includes(key)) {
      const val = p.get(key);
      if (val !== null) {
        current.set(key, val);
      }
    }
  });
  return current;
};

// function getIgnoredSearch(search: string, ignore: string[]) {
//   var p = new URLSearchParams(search);
//   [...p.keys()].forEach(key => {
//     if (!ignore.includes(key)) {
//       p.delete(key);
//     }
//   });

//   return p;
// }
