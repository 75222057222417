import { FieldHookConfig, useField } from 'formik';
import { Entity } from 'types/common';
import {
  PaymentMethodPicker,
  PaymentMethodPickerProps,
} from '../pickers/AutocompletePickers/PaymentMethodPicker';

export const FormPaymentMethodPicker = ({
  onChange,
  ...props
}: FieldHookConfig<Entity<number> | null> &
  Omit<PaymentMethodPickerProps, 'onChange'>) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <PaymentMethodPicker
        onChange={value => helpers.setValue(value, true)}
        onBlur={field?.onBlur}
        value={field.value}
        variant="filled"
        error={meta?.error !== undefined && meta?.touched}
        helperText={meta.error}
        {...props}
      />
    </>
  );
};
