/**
 *
 * CustomPickerFilter
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { CustomPicker } from 'app/components/pickers/CustomPicker';

export interface CustomPickerFilterProps extends IFilterComponentProps {
  disabled?: boolean;
  placeholder?: string;
}
export function CustomPickerFilter(props: CustomPickerFilterProps) {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <CustomPicker
      value={props.value}
      customData={props.customData as Entity<number>[]}
      isOpen={props.open}
      onChange={handleOnChange}
      size="small"
      placeholder={
        props.placeholder !== undefined
          ? props.placeholder
          : t(translations.AllValues)
      }
      ariaLabel={props.label}
      id={props.id}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    />
  );
}
export default CustomPickerFilter;
