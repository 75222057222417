import {
  FeeTypeEntitySchema,
  OfflineServiceTypeEntitySchema,
  OnlineServiceEntitySchema,
  UserEntitySchema,
  UserGroupEntitySchema,
} from 'app/components/Forms/Schemas';
import { InvoiceChildRowBaseSchema } from 'app/components/Forms/Schemas/InvoiceChildRowBaseSchema';
import { CatRateTypes } from 'enums/CatRateTypes';
import { TFunction } from 'i18next';
import { Entity } from 'types/common';
import * as Yup from 'yup';
import { ICharge } from '../AddChargePage/ICharge';

export function GetChargeSchema(
  t: TFunction,
  isEdit: boolean,
): Yup.SchemaOf<ICharge> {
  const result = InvoiceChildRowBaseSchema(t).concat(
    Yup.object({
      User: UserEntitySchema.nullable().notRequired(),
      Resource: OnlineServiceEntitySchema.notRequired().nullable(),
      OtherService: OfflineServiceTypeEntitySchema.notRequired().nullable(),
      UserGroup: UserGroupEntitySchema.nullable().when(
        'User',
        (_, schema: Yup.SchemaOf<Entity<string>>) =>
          isEdit ? schema.notRequired() : schema.required(),
      ),
      SampleType: OfflineServiceTypeEntitySchema.notRequired().nullable(),
      FeeType: FeeTypeEntitySchema.required().nullable(),
      Quantity: Yup.number().defined(),
      Rate: Yup.number()
        .defined()
        .when('FeeType', (catRateType, schema: Yup.NumberSchema) => {
          return (catRateType as Entity<number>)?.Id === CatRateTypes.Refund
            ? schema.negative().max(0)
            : schema.positive().min(0);
        }),
      Amount: Yup.number()
        .defined()
        .when('FeeType', (catRateType, schema: Yup.NumberSchema) => {
          return (catRateType as Entity<number>)?.Id === CatRateTypes.Refund
            ? schema.negative().max(0)
            : schema.positive().min(0);
        }),
      Manual: Yup.bool().notRequired(),
      ActualRate: Yup.number().notRequired(),
      Remarks: Yup.string().nullable().default('').notRequired(),
    }),
  );
  return result;
}
