import * as Handlebar from 'handlebars';
import { dateUtils } from 'utils/date-utils';
import sanitizeHtml from 'sanitize-html';

Handlebar.registerHelper('formatDate', (date, format) => {
  try {
    return dateUtils.format(date, format);
  } catch (error) {
    return error;
  }
});
Handlebar.registerHelper('sanitize', value => {
  try {
    return sanitizeHtml(value);
  } catch (error) {
    return error;
  }
});

const customFormAst = Handlebar.parse(
  '{{#each CustomForm}}<p>{{Id}}: {{Value}}</p>{{/each}}',
);
export const parse = (template: string | undefined) =>
  Handlebar.parse(template ?? '');
/**
 * Compiles Handlebars template to be used in scheduler text templates.
 * * adds formatDate support
 * * adds support for {{CustomForm}} - by replacing it with the custom form - specific template
 * @param template user-input template text
 * @returns
 */
export const compileHandlebarsTemplate = <T>(template?: string) => {
  if (template === undefined) {
    return undefined;
  }

  // first parse the template
  const ast = Handlebar.parse(template);

  // mutate - replace the CustomForm placeholder by expanding it using another AST
  new ReplaceMutatingVisitor('CustomForm', customFormAst).accept(ast);

  // finally compile the resulting AST
  const result = Handlebar.compile<T>(ast);

  return result;
};

/**
 * Mutates AST tree by replacing a given node.
 */
class ReplaceMutatingVisitor extends Handlebar.Visitor {
  _replacementStatement = null;
  _path = null;
  mutating = true;
  /**
   * Initializes the Visitor
   * @param path original Path name to replace the node at
   * @param replacementStatement replacement expression
   */
  constructor(path, replacementStatement) {
    super();
    this._path = path;
    this._replacementStatement = replacementStatement;
  }
  MustacheStatement(mustache: hbs.AST.MustacheStatement) {
    if ((mustache.path as hbs.AST.PathExpression)?.original === this._path) {
      return this._replacementStatement;
    }
  }
}
