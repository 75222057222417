/**
 *
 * CoreDashboardMenu
 *
 */
import {
  Avatar,
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popover,
} from '@material-ui/core';
import { IServiceGroupDto } from 'api/odata/generated/entities/IServiceGroupDto';
import { AssetThumbImage } from 'app/components/AssetThumbImage';
import { Body } from 'app/components/Typography';
import { RouterLink } from 'app/components/BasicLinks/RouterLink';
import { Button } from 'app/components/BasicButtons/Button';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { initFiltersServiceGroupsData } from 'app/components/pickers/MultiSelectPickers/ServiceGroupsPicker';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useAppSettingsSlice } from 'app/slice';
import {
  selectGlobalServiceGroupFilter,
  selectShowGlobalServiceGroupsAsList,
} from 'app/slice/selectors';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Entity } from 'types/common';
import { useListStyles, useStyles } from '../styles';
import TopGroupButton from '../TopGroupButton';
import cliTruncate from 'cli-truncate';
export interface CoreDashboardMenuProps {}

export function CoreDashboardMenu(props: CoreDashboardMenuProps) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const savedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const showGroups = useSelector(selectShowGlobalServiceGroupsAsList);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { checkIsMobile } = useIsMobile();
  const isMobile = checkIsMobile();
  const history = useHistory();
  const classes = useStyles({
    isMobile,
  })();
  const ddlClasses = useListStyles({ isMobile })();
  const [selectedServiceGroups, setSelectedServiceGroups] = React.useState<
    Entity<number>[]
  >(savedServiceGroups || []);
  const [
    availableServiceGroups,
    setAvailableServiceGroups,
  ] = useAsyncExtendedState<Entity<number>[]>([]);
  const [availableLoading, setAvailableLoading] = React.useState<
    boolean | undefined
  >(undefined);
  const [show, setShow] = React.useState<boolean>(false);
  React.useEffect(() => {
    setOpen(false);
    setSelectedServiceGroups(savedServiceGroups || []);
  }, [savedServiceGroups]);
  React.useEffect(() => {
    setShow(showGroups);
  }, [showGroups]);
  React.useEffect(() => {
    let active = availableLoading === undefined && open;
    if (active) {
      setAvailableLoading(true);
      initFiltersServiceGroupsData(`(CanRequestAccess eq false)`).then(data => {
        setAvailableServiceGroups(data);
        setAvailableLoading(false);
      });
    }
    return () => {
      active = false;
    };
  }, [availableLoading, open, setAvailableServiceGroups]);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
  };
  const handleSaveItemClick = (item: Entity<number>) => {
    dispatch(actions.saveGlobalServiceGroupFilter([item]));
    setOpen(false);
    setAvailableServiceGroups([]);
    setAvailableLoading(undefined);
    history.push('/dashboards/core/' + item.Id);
  };

  const handleItemClick = (item: Entity<number>) => {
    setSelectedServiceGroups([item]);
    //history.push('/dashboards/core/' + item.Id);
    // dispatch(actions.navigate({
    //     search: '/dashboards/core/' + item.Id,
    //   }));
    dispatch(actions.saveGlobalServiceGroupFilter_Success([item]));
    setOpen(false);
    setAvailableServiceGroups([]);
    setAvailableLoading(undefined);
  };

  const firstServiceGroupName = React.useMemo(() => {
    return selectedServiceGroups.length > 0
      ? selectedServiceGroups[0]?.Name
      : undefined;
  }, [selectedServiceGroups]);
  return show ? (
    <React.Fragment>
      <TopGroupButton
        savedServiceGroups={selectedServiceGroups}
        firstServiceGroupName={firstServiceGroupName}
        startIcon={
          <Icon
            icon="layer-group"
            color={
              savedServiceGroups !== undefined && savedServiceGroups?.length > 0
                ? 'primary'
                : 'black'
            }
          />
        }
        endIcon={<Icon icon={open ? 'chevron-up' : 'chevron-down'} />}
        onClick={handleClick}
      />

      <Popover
        classes={{
          paper: isMobile ? classes.popoverPaper : undefined,
        }}
        open={open}
        marginThreshold={1}
        anchorEl={anchorEl}
        onClose={handleClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{ classes: { root: classes.root } }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'right' : 'center',
        }}
      >
        <Paper classes={{ root: classes.paper }}>
          <IconButton
            variant="ghost"
            aria-label="close"
            shape="square"
            size="xs"
            style={{ position: 'absolute', right: 8, top: 8 }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <Icon icon="times" />
          </IconButton>
          <Box className={classes.mainContentAndScroll}>
            <Box className={classes.mainContent}>
              <Box className={classes.searchAndHeader}>
                <Body bold={true} size="small">
                  {t(translations.Dashboard_ServiceGroupSelection) as string}
                </Body>
              </Box>

              <Box style={{ width: '100%', padding: '4px 4px 0px' }}>
                {availableLoading && (
                  <Box style={{ width: '100%' }}>
                    <LinearProgress variant="query" color="primary" />
                  </Box>
                )}
                <List>
                  {availableServiceGroups.length > 0 &&
                    availableServiceGroups.map(item => (
                      <ListItem
                        key={item.Id}
                        component={RouterLink}
                        to={`/dashboards/core/${item.Id}`}
                        selected={
                          selectedServiceGroups.find(f => f.Id === item.Id) !==
                          undefined
                        }
                        className={ddlClasses.root}
                        //   component={'a'}
                        //   href={buildURL(`${publicUrl}dashboards/core/${item.Id}`, {})}
                        button
                        onClick={() => handleItemClick(item)}
                      >
                        <ListItemIcon>
                          {(item as IServiceGroupDto).ImageName !== null ? (
                            <AssetThumbImage
                              imageName={(item as IServiceGroupDto).ImageName}
                              maxSize={40}
                            />
                          ) : (
                            <Avatar
                              variant="square"
                              className={ddlClasses.avatar}
                            >
                              {'C'}
                            </Avatar>
                          )}
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            root: ddlClasses.listItemText,
                            primary: ddlClasses.primary,
                            secondary: ddlClasses.secondary,
                          }}
                          className={ddlClasses.listItemText}
                        >
                          {cliTruncate(item.Name, 16, { position: 'end' })}
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <Button
                            variant="white"
                            size="small"
                            onClick={() => handleSaveItemClick(item)}
                          >
                            {
                              t(
                                translations.Dashboard_ServiceGroupSwith,
                              ) as string
                            }
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </React.Fragment>
  ) : null;
}
