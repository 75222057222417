import { Button } from 'app/components/BasicButtons/Button';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export interface OpenWorkOrderProps {
  buttonName: React.ReactNode;
  id: number;
  bringBackUp?: boolean;
}
export function OpenWorkOrder(props: OpenWorkOrderProps) {
  const { buttonName, id, bringBackUp } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const openOrder = () => {
    dispatch(
      actions.openSidePanel({
        type: RenderPageType.WorkOrderDetails,
        props: {
          queryParams: {
            id: id.toString(),
            Up: (bringBackUp === true).toString(),
          },
          useSidePanel: true,
        } as WorkOrderDetailsProps,
      }),
    );
  };

  return (
    <Button variant="white" size="small" onClick={() => openOrder()}>
      {buttonName || t(translations.menu_EditWorkOrder)}
    </Button>
  );
}
