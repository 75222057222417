/**
 *
 * Asynchronously loads the component for LocationList Page
 *
 */

import { lazyLoad } from 'utils/loadable';

export const LocationListPage = lazyLoad(
  () => import('./index'),
  module => module.LocationListPage,
);
