/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';
import { BarcodeScanner } from 'react-usb-barcode-scanner';

import reportWebVitals from 'reportWebVitals';

import WebfontLoader from '@dr-kobros/react-webfont-loader';
// Initialize languages
import './locales/i18n';
import { MobileBarcodeScanner } from 'app/components/MobileBarcodeScanner';

const fontsConfig = {
  // custom: {
  //   families: ['Lato:wght@300;400;700;900&display=swap'],
  //   urls: [`${process.env.PUBLIC_URL}/fonts/Lato/lato.css`],
  // },
  google: {
    families: ['Lato:wght@300;400;700;900&display=swap'],
  },
};
const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={null}>
      <Provider store={store}>
        <HelmetProvider>
          <WebfontLoader config={fontsConfig}>
            <App />
          </WebfontLoader>
        </HelmetProvider>
        <BarcodeScanner
          config={{
            intervalBetweenKeyPress: 50,
            scanningEndTimeout: 200,
            debug: process.env.NODE_ENV === 'development',
            ignoreOnInput: false,
            historyLength: 0,
          }}
        />
        <MobileBarcodeScanner />
      </Provider>
    </React.Suspense>
  </React.StrictMode>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
