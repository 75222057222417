import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { layoutSaga } from './saga';
import {
  LayoutState,
  PageTypeProps,
  ProcessingData,
  RenderPageType,
} from './type';

export const initialState: LayoutState = {
  sidePanelState: {
    sidePanelOpen: false,
    pageType: undefined,
    pageProps: undefined,
    expanded: false,
    coverOpen: false,
    coverExpanded: false,
  },
  confirmState: {
    confirmOpen: false,
    approved: false,
    rejected: false,
    showLocalConfirm: false,
    continueToLink: undefined,
  },
  hasAnyNotSavedChanges: false,
  doRefreshTable: false,
  continueState: {
    continueOnApprove: false,
    pageProps: undefined,
    pageType: undefined,
    expanded: undefined,
  },
  hideNavigation: undefined,
  actionTableProcessing: undefined,
  upsnackbarIndex: undefined,
  isMobileView: undefined,
};
export interface IOpenSidePanelPayload {
  type: RenderPageType | undefined;
  props: PageTypeProps | undefined;
  expanded?: boolean;
}

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleSidePanel(state, action: PayloadAction<boolean>) {
      state.sidePanelState.sidePanelOpen = action.payload;
    },
    openSidePanel(state, action: PayloadAction<IOpenSidePanelPayload>) {},
    setTuggleOpenState(
      state,
      action: PayloadAction<{
        expanded: boolean;
        type: RenderPageType | undefined;
        props: PageTypeProps | undefined;
        open: boolean;
      }>,
    ) {
      state.sidePanelState.expanded = action.payload.expanded;
      state.sidePanelState.pageProps = action.payload.props;
      state.sidePanelState.pageType = action.payload.type;
      state.sidePanelState.sidePanelOpen = action.payload.open;
    },
    setPageType(state, action: PayloadAction<RenderPageType | undefined>) {
      state.sidePanelState.pageType = action.payload;
    },
    setPageProps(state, action: PayloadAction<PageTypeProps | undefined>) {
      state.sidePanelState.pageProps = action.payload;
    },
    setExpanded(state, action: PayloadAction<boolean>) {
      state.sidePanelState.expanded = action.payload;
    },
    resetSidePanel(state, action: PayloadAction<boolean | undefined>) {
      state.sidePanelState.sidePanelOpen = action.payload || false;
      state.sidePanelState.pageProps = undefined;
      state.sidePanelState.pageType = undefined;
      state.sidePanelState.expanded = false;
      state.sidePanelState.coverExpanded = undefined;
      state.sidePanelState.coverOpen = undefined;
      state.confirmState = {
        confirmOpen: false,
        approved: false,
        rejected: false,
        showLocalConfirm: false,
        continueToLink: undefined,
      };
      state.hasAnyNotSavedChanges = false;
      state.upsnackbarIndex = undefined;
    },
    setConfirmOpen(state, action: PayloadAction<boolean>) {
      state.confirmState.confirmOpen = action.payload;
    },
    resetConfirmState(state, action: PayloadAction) {
      state.confirmState = {
        confirmOpen: false,
        approved: false,
        rejected: false,
        showLocalConfirm: false,
        continueToLink: undefined,
      };
    },
    setConfirmApproved(state, action: PayloadAction<boolean>) {
      state.confirmState.approved = action.payload;
    },
    setConfirmRejected(state, action: PayloadAction<boolean>) {
      state.confirmState.rejected = action.payload;
    },
    setContinueToLink(state, action: PayloadAction<string>) {
      state.confirmState.continueToLink = action.payload;
    },
    setNotSavedChanges(state, action: PayloadAction<boolean>) {
      state.hasAnyNotSavedChanges = action.payload;
    },
    setRefreshTable(state, action: PayloadAction<boolean>) {
      state.doRefreshTable = action.payload;
    },
    setActionTableProcessing(
      state,
      action: PayloadAction<ProcessingData | undefined>,
    ) {
      state.actionTableProcessing = action.payload;
    },
    setLocalConfirm(state, action: PayloadAction<boolean>) {
      state.confirmState.showLocalConfirm = action.payload;
    },
    setContinueOnApprove(state, action: PayloadAction<boolean>) {
      state.continueState.continueOnApprove = action.payload;
    },
    setHideNavigation(state, action: PayloadAction<boolean | undefined>) {
      state.hideNavigation = action.payload;
    },
    setContinueState(
      state,
      action: PayloadAction<{
        type: RenderPageType | undefined;
        props: PageTypeProps | undefined;
        expanded: boolean | undefined;
        continue: boolean;
      }>,
    ) {
      state.continueState.continueOnApprove = action.payload.continue;
      state.continueState.pageProps = action.payload.props;
      state.continueState.pageType = action.payload.type;
      state.continueState.expanded = action.payload.expanded;
    },
    resetContinueState(state, action: PayloadAction) {
      state.continueState = {
        continueOnApprove: false,
        pageProps: undefined,
        pageType: undefined,
        expanded: undefined,
      };
    },
    setUpSnackBarIndex(state, action: PayloadAction<boolean>) {
      state.upsnackbarIndex = action.payload;
    },
    setIsMobileView(state, action: PayloadAction<boolean>) {
      state.isMobileView = action.payload;
    },
    setIsCoverOpen(state, action: PayloadAction<boolean | undefined>) {
      state.sidePanelState.coverOpen = action.payload;
    },
    setIsCoverExpanded(state, action: PayloadAction<boolean | undefined>) {
      state.sidePanelState.coverExpanded = action.payload;
    },
  },
});

export const { actions: layoutActions } = slice;

export const useLayoutSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: layoutSaga });
  return { actions: slice.actions };
};
