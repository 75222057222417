import * as Handlebar from 'handlebars';

/**
 * Extracts paths used in handlebars ast
 */
export class ExtractPathsVisitor extends Handlebar.Visitor {
  paths: string[] = [];
  MustacheStatement(mustache: hbs.AST.MustacheStatement) {
    // for our purpose the path can be either a direct property path or a handlebars helper function with property as a parameter
    const isHelperPath = mustache.params.length > 0;
    if (isHelperPath) {
      // extract all parameters that are PathExpression only (e.g. ignore LiteralExpression, etc.)
      const paths = mustache.params
        .filter(f => f.type === 'PathExpression')
        .map(f => (f as hbs.AST.PathExpression)?.original);
      this.paths.push(...paths);
    } else {
      this.paths.push((mustache.path as hbs.AST.PathExpression)?.original);
    }
  }
}
