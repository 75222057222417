import { PayloadAction } from '@reduxjs/toolkit';
import { IEquipmentAccServiceDto } from 'api/odata/generated/entities/IAccServiceDto';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { GlobalSettingsType } from '../components/useGlobalSettingsHook';
import { reservationSaga } from './saga';
import {
  createReservation_Success_Action,
  EquipmentsChangeStateParameters,
  IMandatoryEquipmentsDto,
  IReservationDto,
  IReservationEquipmentDto,
  PricingState,
  ReservationDetailsState,
  ReservationEquipmentsChangeResult,
  ReservationGlobalState,
  ReservationInsertModel,
  ReservationQueryStringParameters,
  ReservationSettingsState,
  ReservationState,
  ServiceSettingsState,
  TransferToReservation,
  UserTrainingReservationData,
} from './types';
import { ConvertDtoToModel } from './ConvertDtoToModel';
import { getReservationSettings } from './getReservationSettings';

export const initialState: ReservationState = {
  detailsState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  isEdit: false,
  confirmAssistedReservations: undefined,
  processing: false,
  reservationSettingsState: undefined,
  equipmentsSettingsState: undefined,
  trainingsData: null,
  valueHandler: undefined,
  requestedValueFor: undefined,
  requestedValue: undefined,
  equipmentChangeResultState: undefined,
  isChangeCompleted: undefined,
  transferState: {
    User: null,
    ReservtionId: undefined,
    WaitingTransferApproval: undefined,
    transferProcessing: undefined,
    transferStepCompleted: undefined,
  },
  creditState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
    error: undefined,
  },
  hasCharges: undefined,
  savedOfflineServices: [],
  refeshOfflineService: undefined,
  terminationState: {
    terminatProcessing: undefined,
    terminateCompleted: undefined,
  },
  cloneReservation: undefined,
  reservationDtoState: undefined,
  asyncChangeLoading: undefined,
  submitCredit: undefined,
  refreshWorkOrderLink: undefined,
};

const slice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    /**
     * Resets reservation details to it's initial state
     * @param state
     * @param action
     */
    reset(state, action: PayloadAction) {
      Object.assign(state, initialState);
    },
    initReservation(
      state,
      action: PayloadAction<{
        query: ReservationQueryStringParameters;
        globalSettings: GlobalSettingsType;
        prevState?: ReservationDetailsState;
      }>,
    ) {
      let isChange = action.payload.query.resourceChanged === 'true';
      state.processing = isChange ? false : true;
      if (isChange) {
        state.detailsState = Object.assign(state.detailsState, {
          processing: true,
          completed: undefined,
          hasErrors: undefined,
        });
        state.isChangeCompleted = false;
      } else {
        state.detailsState = {
          data: undefined,
          processing: undefined,
          completed: undefined,
          hasErrors: undefined,
        };
        state.equipmentsSettingsState = undefined;
      }
      state.confirmAssistedReservations = undefined;
      state.reservationSettingsState = undefined;
      state.trainingsData = null;
    },
    initReservation_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        model: IReservationDto;
        isChanged: boolean;
        isEdit: boolean;
        globalSettings: GlobalSettingsType;
        userTrainigData: UserTrainingReservationData | null;
      }>,
    ) {
      state.processing = false;
      state.detailsState.data = ConvertDtoToModel(
        action.payload.model,
        action.payload.globalSettings,
      );
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.reservationSettingsState = getReservationSettings(
        action.payload.model,
      );
      state.isChangeCompleted =
        action.payload.isChanged === true ? true : undefined;
      state.transferState.WaitingTransferApproval =
        action.payload.model.WaitingTransferApproval;
      state.transferState.ReservtionId = action.payload.model.Id;
      state.transferState.User = action.payload.model.TransferredTo;
      state.reservationDtoState = action.payload.model;
      state.isEdit = action.payload.isEdit;
      state.trainingsData = action.payload.userTrainigData;
      if (action.payload.isEdit) {
        state.savedOfflineServices = [];
      }
    },
    initClonedReservation_Success(
      state,
      action: PayloadAction<{
        model: IReservationDto;
        reservation: ReservationDetailsState;
        settings?: ReservationSettingsState;
      }>,
    ) {
      state.processing = false;
      state.detailsState.data = ConvertDtoToModel(action.payload.model); //action.payload.reservation;
      state.detailsState.processing = false;
      state.reservationSettingsState = getReservationSettings(
        action.payload.model,
      );
      state.isEdit = false;
      state.refeshOfflineService = true;
    },
    initReservation_Error(state, action: PayloadAction<any>) {
      state.processing = false;
      state.isChangeCompleted = undefined;
    },
    initValidationData(
      state,
      action: PayloadAction<{
        parameters: EquipmentsChangeStateParameters;
        warnings: string[];
        errors: string[];
      }>,
    ) {
      state.asyncChangeLoading = true;
    },
    initValidationData_Success(
      state,
      action: PayloadAction<ReservationEquipmentsChangeResult>,
    ) {
      state.equipmentChangeResultState = action.payload;
      state.asyncChangeLoading = undefined;
    },
    initValidationData_Error(state, action: PayloadAction) {
      state.equipmentChangeResultState = undefined;
      state.asyncChangeLoading = undefined;
    },
    resetDetailsState(state, action: PayloadAction) {
      state.detailsState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.reservationSettingsState = undefined;
      state.confirmAssistedReservations = undefined;
      state.equipmentsSettingsState = undefined;
      state.trainingsData = null;
      state.transferState = {
        User: null,
        ReservtionId: undefined,
        WaitingTransferApproval: undefined,
        transferProcessing: undefined,
      };
      state.reservationDtoState = undefined;
      state.savedOfflineServices = [];
      state.submitCredit = undefined;
      state.ByApprovalErrors = undefined;
      state.refreshWorkOrderLink = undefined;
    },
    resetOperateState(state, action: PayloadAction) {
      state.detailsState.hasErrors = false;
      state.detailsState.completed = false;
    },
    createReservation(
      state,
      action: PayloadAction<{
        state: ReservationGlobalState;
        calcCredit?: boolean;
        ByApproval?: boolean;
      }>,
    ) {
      if (action.payload.calcCredit === true) {
        state.creditState.processing = true;
        state.creditState.completed = undefined;
        state.creditState.hasErrors = undefined;
      } else {
        state.detailsState.processing = true;
        state.detailsState.completed = false;
        state.detailsState.hasErrors = false;
      }
    },
    createReservation_Success(state, action: createReservation_Success_Action) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.ByApprovalErrors = action.payload.ByApprovalErrors;
      state.detailsState.completed = true;
      if (!action.payload.hasErrors) {
        state.savedOfflineServices = [];
      }
    },
    createReservation_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
      state.cloneReservation = false;
    },
    updateReservation(
      state,
      action: PayloadAction<{
        current: ReservationDetailsState;
        original: ReservationDetailsState;
        currentCustomFormFiles: IFormFileValue[];
        originalCustomFormFiles: IFormFileValue[];
        offlineServices: IOtherServices[];
        settings: ReservationSettingsState | undefined;
        terminate?: boolean;
      }>,
    ) {
      state.detailsState.processing = true;
      state.detailsState.completed = undefined;
      state.detailsState.hasErrors = undefined;
    },
    updateReservation_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        userTrainigData: UserTrainingReservationData | null;
        insertModel: ReservationInsertModel;
      }>,
    ) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.detailsState.completed = true;
      state.confirmAssistedReservations =
        action.payload.userTrainigData !== null;
      state.trainingsData = action.payload.userTrainigData;
      if (!action.payload.hasErrors) {
        state.savedOfflineServices = [];
      }
    },
    updateReservation_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
    },
    extendReservationSettings(
      state,
      action: PayloadAction<Partial<ReservationSettingsState>>,
    ) {},
    updateReservationSettings(
      state,
      action: PayloadAction<Partial<ReservationSettingsState>>,
    ) {
      state.reservationSettingsState = Object.assign(
        {},
        state.reservationSettingsState,
        action.payload,
      );
    },
    extendEquipmentSettingsState(
      state,
      action: PayloadAction<{
        equipments: IReservationEquipmentDto[];
        mandatories: IMandatoryEquipmentsDto[];
        accServices: IEquipmentAccServiceDto[];
        globalSettings: GlobalSettingsType;
        settings?: ServiceSettingsState;
      }>,
    ) {
      state.asyncChangeLoading = true;
    },
    updateEquipmentSettingsState(
      state,
      action: PayloadAction<ServiceSettingsState>,
    ) {
      state.equipmentsSettingsState = action.payload;
      state.asyncChangeLoading = undefined;
    },
    resetAssisteTrainingData(state, action: PayloadAction) {
      state.confirmAssistedReservations = undefined;
      state.trainingsData = null;
    },
    resetEquipmentChangeState(state, action: PayloadAction) {
      state.equipmentChangeResultState = undefined;
    },
    setStartDate(state, action: PayloadAction<Date | string>) {},
    setStartSuccess(state, action: PayloadAction<Date | string>) {
      state.valueHandler = {
        run: true,
        fieldKey: 'StartTime',
        fieldValue: action.payload,
        shouldValidate: true,
      };
    },
    setEndDate(state, action: PayloadAction<Date | string>) {},
    setEndSuccess(state, action: PayloadAction<Date | string>) {
      state.valueHandler = {
        run: true,
        fieldKey: 'EndTime',
        fieldValue: action.payload,
        shouldValidate: true,
      };
    },
    setAnyValue(
      state,
      action: PayloadAction<{
        fieldKey: keyof ReservationDetailsState;
        fieldValue: any;
      }>,
    ) {},
    setAnyValueSuccess(
      state,
      action: PayloadAction<{
        fieldKey: keyof ReservationDetailsState;
        fieldValue: any;
      }>,
    ) {
      state.valueHandler = {
        run: true,
        fieldKey: action.payload.fieldKey,
        fieldValue: action.payload.fieldValue,
        shouldValidate: true,
      };
    },
    resetValueHandler(state, action: PayloadAction) {
      state.valueHandler = undefined;
    },
    getAnyValue(state, action: PayloadAction<keyof ReservationDetailsState>) {
      state.requestedValueFor = action.payload;
    },
    getAnyValueSuccess(state, action: PayloadAction<any>) {
      state.requestedValue = action.payload;
    },
    resetRequestedFor(state, action: PayloadAction) {
      state.requestedValueFor = undefined;
    },
    transferReservation(state, action: PayloadAction<TransferToReservation>) {
      state.transferState.transferProcessing = true;
      state.transferState.transferStepCompleted = undefined;
    },
    transferReservation_Success(
      state,
      action: PayloadAction<TransferToReservation>,
    ) {
      state.transferState.transferProcessing = false;
      state.transferState.User = action.payload.User;
      state.transferState.ReservtionId = action.payload.ReservtionId;
      state.transferState.WaitingTransferApproval = true;
      state.transferState.transferStepCompleted = true;
    },
    transferReservation_Error(state, action: PayloadAction<any>) {
      state.transferState.transferProcessing = false;
      state.transferState.transferStepCompleted = false;
    },
    resetTransferState(state, action: PayloadAction) {
      state.transferState.transferProcessing = undefined;
      state.transferState.User = null;
      state.transferState.ReservtionId = undefined;
      state.transferState.WaitingTransferApproval = undefined;
      state.transferState.transferStepCompleted = undefined;
    },
    cancelTransfer(state, action: PayloadAction<TransferToReservation>) {
      state.transferState.transferProcessing = true;
      state.transferState.transferStepCompleted = undefined;
    },
    cancelTransfer_Success(state, action: PayloadAction) {
      state.transferState.transferProcessing = false;
      state.transferState.User = null;
      state.transferState.WaitingTransferApproval = false;
      state.transferState.ReservtionId = undefined;
      state.transferState.transferStepCompleted = true;
    },
    cancelTransfer_Error(state, action: PayloadAction<any>) {
      state.transferState.transferProcessing = false;
      state.transferState.transferStepCompleted = false;
    },
    getCredit(state, action: PayloadAction<ReservationGlobalState>) {
      state.creditState.processing = true;
      state.creditState.completed = undefined;
      state.creditState.hasErrors = undefined;
      state.submitCredit = undefined;
    },
    getCredit_Success(state, action: PayloadAction<PricingState>) {
      state.creditState.processing = false;
      state.creditState.completed = true;
      state.creditState.data = action.payload;
    },
    getCredit_Error(state, action: PayloadAction<any>) {
      state.creditState.processing = false;
      state.creditState.completed = true;
      state.creditState.data = undefined;
    },
    resetCreditState(state, action: PayloadAction) {
      state.creditState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.submitCredit = undefined;
    },
    setSavedOfflineServices(state, action: PayloadAction<IOtherServices[]>) {
      state.savedOfflineServices = action.payload;
    },
    setRefreshOfflineServices(
      state,
      action: PayloadAction<boolean | undefined>,
    ) {
      state.refeshOfflineService = action.payload;
    },
    terminateReservation(
      state,
      action: PayloadAction<{
        current: ReservationDetailsState;
        original: ReservationDetailsState;
        currentCustomFormFiles: IFormFileValue[];
        originalCustomFormFiles: IFormFileValue[];
        settings: ReservationSettingsState | undefined;
      }>,
    ) {
      state.terminationState.terminatProcessing = true;
      state.terminationState.terminateCompleted = undefined;
    },
    terminateReservation_success(state, action: PayloadAction) {
      state.terminationState.terminatProcessing = false;
      state.terminationState.terminateCompleted = true;
    },
    terminateReservation_error(state, action: PayloadAction) {
      state.terminationState.terminatProcessing = false;
      state.terminationState.terminateCompleted = true;
    },
    resetTerminateState(state, action: PayloadAction) {
      state.terminationState = {
        terminatProcessing: undefined,
        terminateCompleted: undefined,
      };
    },
    setCloneReservation(state, action: PayloadAction<boolean | undefined>) {
      state.cloneReservation = action.payload;
    },
    setAsyncChangeLoading(state, action: PayloadAction<boolean | undefined>) {
      state.asyncChangeLoading = action.payload;
    },
    setSubmitCredit(state, action: PayloadAction<boolean | undefined>) {
      state.submitCredit = action.payload;
    },
    setByApprovalErrors(
      state,
      action: PayloadAction<Array<string> | undefined>,
    ) {
      state.ByApprovalErrors = action.payload;
    },
    setRefreshWorkOrderLink(state, action: PayloadAction<boolean | undefined>) {
      state.refreshWorkOrderLink = action.payload;
    },
  },
});

export const { actions: reservationActions } = slice;

export const useReservationSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: reservationSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useReservationSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
