/**
 *
 * Asynchronously loads the component for LocationTypesPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SubLocationTypesPage = lazyLoad(
  () => import('./index'),
  module => module.SubLocationTypesPage,
);
