/**
 *
 * Link
 *
 */
import * as React from 'react';
import * as H from 'history';
import { IPathAndQuery, toQueryString } from 'utils/url-utils';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export interface LinkProps<T> extends Omit<RouterLinkProps<T>, 'to'> {
  to: t<T>;
}

type routerLinkType<S> = H.LocationDescriptor<S>;
//| ((location: H.Location<S>) => H.LocationDescriptor<S>);

type t<S> = routerLinkType<S> | IPathAndQuery;
/**
 * Link to a resource inside the larger system but external to the React app
 * Renders <a href="path" ...
 * @param param0
 * @returns
 */
export function Link<T>({ to, ...props }: LinkProps<T>) {
  const toto = toLocationDescriptor(to);
  return <RouterLink to={toto} {...props} />;
}

export function toLocationDescriptor<T>(
  tt: t<T>,
): H.LocationDescriptorObject<T> {
  if (isPathAndQuery(tt)) {
    return toLocationDescriptorObject(tt as IPathAndQuery);
  } else {
    return tt as H.LocationDescriptorObject<T>;
  }
}

function isPathAndQuery<S>(value: t<S>) {
  var s = value as IPathAndQuery;
  if (value === null) {
    return false;
  }

  return (
    s.path !== undefined &&
    s.search !== undefined &&
    typeof s.search === 'object'
  );
}

function toLocationDescriptorObject<S>(
  to: IPathAndQuery,
): H.LocationDescriptorObject<S> {
  return {
    pathname: to.path,
    search: toQueryString(to.search).toString(),
  };
}
