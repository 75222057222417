/**
 *
 * ListItemLink
 *
 */
import { ListItem, ListItemProps } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import * as React from 'react';

export interface ListItemLinkProps extends ListItemProps {
  info?: string;
}

export function ListItemLink(props) {
  return !!props.info ? (
    <Tooltip title={<>{props.info}</>}>
      <span>
        <ListItem component="a" {...props} />
      </span>
    </Tooltip>
  ) : (
    <React.Fragment>
      <ListItem component="a" {...props} />
    </React.Fragment>
  );
}
