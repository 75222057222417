import { BaseToggleButtonGroup } from 'app/components/BasicButtons/BaseToggleButtonGroup';
import { useProfileSetting } from 'app/components/BasicTable/useProfileSetting';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { ReservationDetailsProps } from 'app/pages/ReservationDetails/Details';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { EmptySlotAdminClick } from 'enums/EmptySlotAdminClick';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Entity } from 'types/common';

export interface SwitchActionsProps {
  target: SwitchTarget;
  getProps: () => ReservationDetailsProps | WorkOrderDetailsProps;
}
export enum SwitchTarget {
  WorkOrder = 1,
  Reservation = 2,
}

export function SwitchActions(props: SwitchActionsProps) {
  const { target, getProps } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const { updateValue: updateX } = useProfileSetting({
    profileSettingKey: 'EmptySlotAdminClick',
  });
  const openReservation = React.useCallback(() => {
    let params = getProps() as ReservationDetailsProps;
    setTimeout(
      () =>
        dispatch(
          actions.openSidePanel({
            type: RenderPageType.ReservationDetails,
            props: params,
          }),
        ),
      null,
    );
    updateX(EmptySlotAdminClick.ReservationsOnly.toString());
  }, [actions, dispatch, getProps, updateX]);
  const openWorkOrder = React.useCallback(() => {
    let params = getProps() as WorkOrderDetailsProps;
    setTimeout(
      () =>
        dispatch(
          actions.openSidePanel({
            type: RenderPageType.WorkOrderDetails,
            props: params,
          }),
        ),
      null,
    );
    updateX(EmptySlotAdminClick.DownTimeOnly.toString());
  }, [actions, dispatch, getProps, updateX]);
  const options = [
    {
      Id: 2,
      Name: t(translations.SwitchToReservationTitle),
    },
    {
      Id: 1,
      Name: t(translations.SwitchToDowntime),
    },
  ];
  const [selectedOption, setSelectedOption] = React.useState<Entity<number>>(
    options.filter(f => f.Id !== (target as number))[0],
  );
  const handleChange = (item: Entity<number> | null) => {
    let selected = item === null ? options[0] : item;
    if (selected.Id === (target as number)) {
      setSelectedOption(selected);
      if (selected.Id === (SwitchTarget.WorkOrder as number)) {
        openWorkOrder();
      } else {
        openReservation();
      }
    }
  };
  return (
    <React.Fragment>
      <BaseToggleButtonGroup
        size={'small'}
        options={options as Entity<number>[]}
        onChange={handleChange}
        fullWidth={true}
        value={selectedOption}
        orientation={'horizontal'}
        variant={'white'}
      />
      {/* <Button
        fullWidth
        variant={target === SwitchTarget.WorkOrder ? 'white' : 'ghost'}
        key="swithToReservation"
        disabled={target === SwitchTarget.WorkOrder}
        size="small"
        onClick={() => openReservation()}
      >
        {t(translations.SwitchToReservationTitle)}
      </Button>
      <Button
        fullWidth
        variant={target === SwitchTarget.Reservation ? 'ghost' : 'white'}
        key="swithToWorkOrder"
        disabled={target === SwitchTarget.Reservation}
        size="small"
        onClick={() => openWorkOrder()}
      >
        {t(translations.SwitchToDowntime)}
      </Button> */}
    </React.Fragment>
  );
}
