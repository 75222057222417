import * as React from 'react';
import * as DHTMLX from 'dhtmlx-scheduler';
import { getLogger } from 'utils/logLevel';
import 'dhtmlx-scheduler/dhtmlxscheduler_material.css';
import clsx from 'clsx';
import './scheduler.css';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import {
  IChangeViewModePayload,
  IEditEvent,
  INewEvent,
  ISchedulerService,
  IViewState,
  RestrictDuration,
  ViewLengths,
  ViewModes,
  ViewTypes,
} from './slice/types';
import { Timestamp } from 'types/Timestamp';
import { dateUtils } from 'utils/date-utils';
import { assign, isEqual } from 'lodash';
import {
  adjustToTimeSlot,
  getSchedulerViewMode,
  getSchedulerViewType,
  getViewEndDate,
  getViewStartDate,
  MatchUnitSlotToEnd,
  parseViewMode,
} from './utils';
import {
  serializeSchedulerDate,
  serializeSchedulerDateOrUndefined,
} from './utils/formatSchedulerDateOrUndefined';
import { useTranslation } from 'react-i18next';
import { assertExhaustive } from 'utils/assertExhaustive';
import { IEquipmentTimeSlot } from 'api/odata/generated/complexTypes/IEquipmentTimeSlot';
import { SchedulerDatePositionUnion } from 'app/components/BasicTable/Filters/DatesPeriodFilter';
import { MouseEventHandler } from 'hoist-non-react-statics/node_modules/@types/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectExportToPDF,
  selectSchedulerLoadingEvents,
  selectSchedulerReservationBodyTemplate,
  selectSchedulerReservationTooltipTemplate,
  selectSchedulerTempEvents,
  selectSchedulerViewCustomStart,
  selectTimelineGroupBy,
  selectWithReservationsOnly,
} from './slice/selectors';
import { DetectIsMobile } from 'utils/mobileDetect';

import {
  allowedToViewAlert,
  IsReadOnlyUser,
} from 'app/permissions/WorkOrders/workOrdersPermissionUtils';
import {
  selectAppSettings,
  selectAuthenticatedUser,
  selectUseAmPm,
} from 'app/slice/selectors';
import { ISchedulerState } from './types/ISchedulerState';
import { IMarkedTimespan as IMarkedTimeSpan } from './types/IMarkedTimespan';
import { renderEvent } from './components/EventRender';
import { ISchedulerEvent } from './types/ISchedulerEvent';
import { adjustMonthTimelineLength } from './utils/adjustMonthTimelineLength';
import { createTimelineView } from './createTimelineView';
import { createUnitsView } from './createUnitsView';
import { RestrictDurationUnitTypes } from 'enums/RestrictDurationUnitTypes';
import { syncSchedulerEvents } from './utils/syncSchedulerEvents';
import { useSchedulerSlice } from './slice';
import { ValueChangeHandler } from 'types/ValueChangeHandler';
import { ISchedulerParseEvent } from './types/ISchedulerParseEvent';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { AssetPopUpProps } from 'app/pages/AssetPopUp';
import { scheduleServiceContainerClassName } from './renderServiceLabel';
import { NoServices } from './components/NoServices';
import { useLocation } from 'react-router-dom';
import {
  GetNumberOrUndefined,
  GetRawValue,
  getSplitNumberArray,
} from 'app/components/BasicTable/types/FilterParam';
import { routerActions } from 'connected-react-router';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { EmptySlotAdminClick } from 'enums/EmptySlotAdminClick';
import { useAppSettingsSlice } from 'app/slice';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { initOnlineServicesData } from 'app/components/pickers/MultiSelectPickers/OnlineServicesPicker';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { useCallback } from 'app/hooks/useComparedHooks';
import { event_text } from './components/event_text';
import { tooltip_text } from './components/Tooltips';
import { CombinedSchedulerTRow } from '../Filter';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { translations } from 'locales/translations';
import { useDidUpdate } from 'app/hooks/useDidUpdate';
import { TableRowsSkeleton } from 'app/components/BasicTable/components/TableSkeleton';
import { updateView } from './updateView';
import { deleteTempReservations } from './deleteTempReservations';
import { resetEventEdits } from './resetEventEdits';
import {
  getServerListService,
  setServerList,
  updateServerList,
} from './serverListUtils';
import { applyForceFullDayReservations } from './utils/applyForceFullDayReservations';
import { ISchedulerNewEvent } from './types/ISchedulerNewEvent';
import { adjustSchedulerEventTimes } from './utils/adjustSchedulerEventTimes';
import { getEvent } from './utils/getEvent';
import { getServiceIdFromServiceClickEvent } from './getServiceIdFromServiceClickEvent';
import { findParentTarget } from './findParentTarget';
import { transformToSerializableEvent } from './transformToSerializableEvent';
import { PrintSchedulerToPDF } from 'app/components/PrintToPDF/Scheduler';
import { SchedulerPeriod } from './types/SchedulerPeriod';

export interface ViewModeChangeArgs {
  previous?: IViewState;
  current: IViewState;
}
export type ViewModeChangeHandler = (args: IChangeViewModePayload) => void;
export type SchedulerEventCreateHandler = (args: INewEvent) => void;
export type SchedulerEventEditHandler = ValueChangeHandler<
  ISchedulerParseEvent
>;
export interface SchedulerProps {
  onDataUpdated: (s: string, ev: any, id: any) => void;
  events?: ISchedulerParseEvent[];
  nonWorkingHours?: IEquipmentTimeSlot[];
  services?: ISchedulerService[];
  viewType: ViewTypes;
  viewLength: ViewLengths;
  presetPosition: SchedulerDatePositionUnion | null;
  search: string;
  date: Timestamp;
  onViewModeChange?: ViewModeChangeHandler;
  onEdit?: SchedulerEventEditHandler;
  setFilterValue?: (id: string, value: FilterValueType) => void;
  onFilterChange?: (items: IFilterSettings<CombinedSchedulerTRow>[]) => void;
  appliedFilters?: IFilterSettings<CombinedSchedulerTRow>[];
  /**
   * Handles create new reservation.
   * Must be Memoized.
   */
  onCreate?: SchedulerEventCreateHandler;
  hight?: string;
  reservationEditEvents: Array<IEditEvent>;
  defaultDuration?: number;
  customDays?: number;
  customDaysEnabled?: boolean;
  twoDaysEnabled?: boolean;
  handleViewTypeLengthChange?: (
    type: ViewTypes,
    length: ViewLengths,
    value?: Date,
    noChangeLocation?: boolean,
  ) => void;
  noChangeLocation?: boolean;
  zoomEnabled?: boolean;
}

const NEW_EVENT_TEXT = 'New event';
export const scheduler_services_collection_name = 'services';
const log = getLogger('Scheduler');

export default function Scheduler({
  viewLength,
  date,
  events,
  nonWorkingHours,
  services,
  onCreate,
  onEdit,
  setFilterValue,
  onFilterChange,
  appliedFilters,
  reservationEditEvents,
  customDays,
  customDaysEnabled,
  twoDaysEnabled,
  handleViewTypeLengthChange,
  noChangeLocation,
  zoomEnabled = false,
  ...props
}: SchedulerProps) {
  const { t } = useTranslation();
  /** Reference to DHTMLX Scheduler instance */
  const schedulerRef = React.useRef<DHTMLX.SchedulerStatic>();
  const dispatch = useDispatch();
  const { actions } = useSchedulerSlice();
  const location = useLocation();
  const { actions: globalActions } = useAppSettingsSlice();
  /** Reference to Scheduler container DOM element */
  const schedulerContainer = React.useRef<HTMLDivElement>(null);
  const isMobile = DetectIsMobile();
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const viewType = getSchedulerViewType(props.viewType, viewLength, isMobile);
  const viewMode = getSchedulerViewMode(props.viewType, viewLength, isMobile);
  const appSettings = useSelector(selectAppSettings);
  const hasCustomStart = useSelector(selectSchedulerViewCustomStart);
  const compiledSchedulerReservationBodyTemplate = useSelector(
    selectSchedulerReservationBodyTemplate,
  );
  const useAmPm = useSelector(selectUseAmPm);
  const compiledSchedulerReservationTooltipTemplate = useSelector(
    selectSchedulerReservationTooltipTemplate,
  );
  const permissionsError: string = t(
    translations.role_err_Permissions,
  ) as string;
  const { newDate } = useSystemDate();

  const defaultDuration = React.useMemo(() => {
    if (services !== undefined && services.length > 0) {
      const result =
        (services.map(f => f.DefaultDuration).sort((a, b) => a - b)[0] || 1) *
        60;
      return result;
    }
    return 60;
  }, [services]);
  const restrictTimeSlots = React.useMemo(() => {
    if (
      services !== undefined &&
      services.length === 1 &&
      services[0].RestrictReservationToTimeSlots
    ) {
      return services[0].WorkingHours;
    }
    return [];
  }, [services]);
  const minDuration = React.useMemo(() => {
    if (services !== undefined && services.length > 0) {
      const result =
        (services.map(f => f.MinOrderHours).sort((a, b) => a - b)[0] || 0) * 60;
      return result;
    }
    return 0;
  }, [services]);
  const forceFullDayReservations = React.useMemo(
    () => services?.some(service => service.ForceFullDayReservations),
    [services],
  );

  const restrictDuration = React.useMemo(() => {
    if (services !== undefined && services.length > 0) {
      if (
        services.some(
          f =>
            (f.RestrictDurationUnitType ?? 0) > 0 &&
            (f.RestrictDurationUnitsAmount ?? 0) > 0,
        )
      ) {
        return services
          .filter(
            f =>
              (f.RestrictDurationUnitType ?? 0) > 0 &&
              (f.RestrictDurationUnitsAmount ?? 0) > 0,
          )
          .map(f => {
            return {
              durationType: (f.RestrictDurationUnitType ??
                0) as RestrictDurationUnitTypes,
              durationAmount: f.RestrictDurationUnitsAmount ?? 0,
            };
          })[0] as RestrictDuration;
      }
    }
    return undefined;
  }, [services]);

  const tempReservations = useSelector(selectSchedulerTempEvents);

  React.useEffect(() => {
    deleteTempReservations(schedulerRef, tempReservations?.id);
  }, [tempReservations]);

  const handleClick = useCallback(
    (id: string, e: any) => {
      log.debug('handleClick', id, e);
      const event: ISchedulerEvent = schedulerRef?.current?.getEvent(id);
      if (event === undefined) {
        return;
      }
      if (event.type === undefined) {
        return;
      }
      if (IsReadOnlyUser(authenticatedUser)) {
        dispatch(
          globalActions.addNotification({
            key: 'permissionError',
            message: permissionsError,
            variant: 'error',
            closable: true,
            autoHideDuration: 3000,
          }),
        );
        return;
      }
      if (
        event.type === 'reservation' &&
        (event.original as ICalendarReservationDto).Restricted === true
      ) {
        return;
      }
      // check permissions to disable alert edit
      if (event.alert_id !== undefined) {
        if (
          !allowedToViewAlert(authenticatedUser, {
            ReportedBy: '',
            ServiceGroupId: event.original.ServiceGroupId,
            ServiceId: event.original.EquipmentId,
            ServiceTypeId: 1,
          })
        ) {
          return false;
        }
      }

      if (!!event) {
        let transformedEvent = transformToSerializableEvent(event);
        deleteTempReservations(schedulerRef);
        onEdit?.(transformedEvent);
      }
      return false;
    },
    [authenticatedUser, dispatch, globalActions, onEdit, permissionsError],
  );
  const scheduler = schedulerRef.current;
  React.useEffect(() => {
    scheduler?.attachEvent('onClick', handleClick, {});

    // cleanup
    return () => {
      scheduler?.detachEvent('onClick');
    };
  }, [handleClick, scheduler]);

  const handleBeforeDrag = React.useCallback(
    (id: string, mode: 'move' | 'resize' | 'create', e: MouseEventHandler) => {
      log.debug('handleBeforeDrag', id, mode, e);
      switch (mode) {
        case 'create':
          console.log('create call: ' + id);
          return true;
        case 'move':
        case 'resize':
          const event: ISchedulerEvent = getEvent(scheduler, id);
          if (event.readonly === true) {
            return false;
          }
          return true;
        default:
          assertExhaustive(mode);
      }
    },
    [scheduler],
  );
  React.useEffect(() => {
    const eventName = 'onBeforeDrag';
    if (scheduler !== undefined) {
      scheduler.detachEvent(eventName);
      scheduler.attachEvent(eventName, handleBeforeDrag, {});
    }
  }, [handleBeforeDrag, scheduler]);

  /**
   * @see https://docs.dhtmlx.com/scheduler/api__scheduler_onbeforeeventchanged_event.html
   */
  const handleBeforeEventChange = React.useCallback(
    (
      ev: ISchedulerEvent,
      e: MouseEventHandler,
      is_new: boolean,
      original: any,
    ) => {
      log.debug('handleBeforeEventChange', ev, e, is_new, original);
      if (IsReadOnlyUser(authenticatedUser)) {
        dispatch(
          globalActions.addNotification({
            key: 'permissionError',
            message: permissionsError,
            variant: 'error',
            closable: true,
            autoHideDuration: 3000,
          }),
        );
        return false;
      }
      // allow all changes to new events
      if (is_new || (original as any).text === NEW_EVENT_TEXT) {
        if (forceFullDayReservations === true) {
          assign(
            ev,
            applyForceFullDayReservations(
              {
                start: ev.start_date,
                end: ev.end_date,
              },
              forceFullDayReservations,
            ),
          );
          return true;
          // force the selected duration to fixed time slot defined in service as RestrictDurationUnitType - m/h/d,
          // so total duration will be equal RestrictDurationUnitsAmount;
        } else if (restrictDuration !== undefined) {
          const new_end = MatchUnitSlotToEnd(
            dateUtils.formatISO(ev.start_date),
            dateUtils.formatISO(ev.end_date),
            restrictDuration.durationType,
            restrictDuration.durationAmount,
          );
          ev.end_date = new_end;
          return true;
        } else if (restrictTimeSlots.length > 0) {
          let newSlot = adjustToTimeSlot(
            ev.start_date,
            ev.end_date,
            restrictTimeSlots,
            minDuration,
          );
          ev.start_date = newSlot.start;
          ev.end_date = newSlot.end;
        }
        return true;
      }
      // prevent service change - will be implemented later on
      const serviceChanged = !is_new && ev.service_id !== original.service_id;
      if (serviceChanged) {
        return false;
      }

      if (is_new || (ev as any).text === NEW_EVENT_TEXT) {
        // set defaultDuration - not sure if need to do some thing in this case
        // - we have a server side correction related to default / min / max durations.
        // To do: check if need to include this code on event selection
        // comented by Ariel Malov on 05/11/2023
        // // if (props.defaultDuration !== undefined && props.defaultDuration > 0) {
        // //   ev.end_date = dateUtils.addMinutes(
        // //     ev.start_date,
        // //     props.defaultDuration,
        // //   );
        // //   schedulerRef?.current?.updateEvent(ev.id?.toString());
        // //   return true;
        // //   // force the provided duration when user gets to place fixed length duration reservation from the service run
        // // } else
        // moved to the section  if (is_new || (original as any).text === NEW_EVENT_TEXT)
        // the above rows didn't run because a 'if' belove - always true.
        // if (forceFullDayReservations === true) {
        //   assign(
        //     ev,
        //     applyForceFullDayReservations(
        //       {
        //         start: ev.start_date,
        //         end: ev.end_date,
        //       },
        //       forceFullDayReservations,
        //     ),
        //   );
        //   return true;
        //   // force the selected duration to fixed time slot defined in service as RestrictDurationUnitType - m/h/d,
        //   // so total duration will be equal RestrictDurationUnitsAmount;
        // } else if (restrictDuration !== undefined) {
        //   const new_end = MatchUnitSlotToEnd(
        //     dateUtils.formatISO(ev.start_date),
        //     dateUtils.formatISO(ev.end_date),
        //     restrictDuration.durationType,
        //     restrictDuration.durationAmount,
        //   );
        //   ev.end_date = new_end;
        //   return true;
        // }
        // return true;
      }
      return true;
    },
    [
      authenticatedUser,
      dispatch,
      forceFullDayReservations,
      globalActions,
      minDuration,
      permissionsError,
      restrictDuration,
      restrictTimeSlots,
    ],
  );
  React.useEffect(() => {
    const eventName = 'onBeforeEventChanged';
    if (
      scheduler !== undefined &&
      services !== undefined &&
      services.length > 0
    ) {
      scheduler.detachEvent(eventName);
      scheduler.attachEvent(eventName, handleBeforeEventChange, {});
    }
    // cleanup
    return () => {
      scheduler?.detachEvent(eventName);
    };
  }, [handleBeforeEventChange, scheduler, services]);

  const onEmptyClick = 'onEmptyClick';
  const handleEmptyClick = React.useCallback(
    (date: Date, event: MouseEvent) => {
      try {
        log.debug(onEmptyClick, date, event);
        if (scheduler === undefined) {
          log.debug(`${onEmptyClick} - scheduler is undefined. Exiting.`);
          return;
        }
        // check if the click target is a DOM element so that the closest method is available
        const target = event.target;
        if (target instanceof Element) {
          if (target.closest('.dhx_scell_level') !== null) {
            return true;
          }
          // check if the click target is a service label rendered in the timeline section
          const containerClassName = `.${scheduleServiceContainerClassName}`;
          let serviceContainer = target.closest(containerClassName);
          if (serviceContainer !== null) {
            return false;
          }
        }
        if (IsReadOnlyUser(authenticatedUser)) {
          dispatch(
            globalActions.addNotification({
              key: 'permissionError',
              message: permissionsError,
              variant: 'error',
              closable: true,
              autoHideDuration: 3000,
            }),
          );
          return false;
        }
        var newExisted = scheduler
          .getEvents()
          .filter(f => !!f.text && f.text === NEW_EVENT_TEXT);
        if (newExisted.length > 0) {
          return false;
        }
        let action_data = scheduler?.getActionData(event);

        // verify that the empty click was done inside the calendar/timeline area
        // this event is also raised when instrument column is clicked on the timeline in which case the date the date is equal to timeline start date
        const state: ISchedulerState = scheduler?.getState();
        const clickedInsideVisiblePeriod =
          date.getTime() > state.min_date.getTime() &&
          date.getTime() < state.max_date.getTime();
        if (!clickedInsideVisiblePeriod) {
          return false;
        }

        // get the instrument data - relevant for timeline/unit mode
        // in this case the event is created on specific instrument
        const serviceId: number | undefined = action_data.section;
        const service = getServerListService(scheduler, serviceId);

        // get the default duration for the instrument if the event placed directly on timeline/unit view
        const duration =
          props.defaultDuration !== undefined
            ? props.defaultDuration
            : service !== undefined
            ? service.DefaultDuration * 60
            : defaultDuration;
        let period: SchedulerPeriod = {
          start_date: action_data.date,
          end_date: scheduler.date.add(action_data.date, duration, 'minute'),
        };
        if (restrictDuration !== undefined) {
          const new_end = MatchUnitSlotToEnd(
            dateUtils.formatISO(period.start_date),
            dateUtils.formatISO(period.end_date),
            restrictDuration.durationType,
            restrictDuration.durationAmount,
          );
          period.end_date = new_end;
        }
        if (restrictTimeSlots.length > 0) {
          let newSlot = adjustToTimeSlot(
            period.start_date,
            period.end_date,
            restrictTimeSlots,
            minDuration,
          );
          period.start_date = newSlot.start;
          period.end_date = newSlot.end;
        }
        const new_event: ISchedulerNewEvent = {
          ...adjustSchedulerEventTimes(
            period,
            service?.ForceFullDayReservations ?? forceFullDayReservations,
          ),
          service_id: serviceId,
          text: NEW_EVENT_TEXT,
        };

        scheduler?.addEvent(new_event);
        log.debug(onEmptyClick, new_event);
      } catch (error) {
        log.error(onEmptyClick, error);
      }
    },
    [
      authenticatedUser,
      defaultDuration,
      dispatch,
      forceFullDayReservations,
      globalActions,
      minDuration,
      permissionsError,
      props.defaultDuration,
      restrictDuration,
      restrictTimeSlots,
      scheduler,
    ],
  );

  React.useEffect(() => {
    scheduler?.detachEvent(onEmptyClick);
    scheduler?.attachEvent(onEmptyClick, handleEmptyClick, {});
    return () => {
      scheduler?.detachEvent(onEmptyClick);
    };
  }, [handleEmptyClick, scheduler]);

  /**
   * Attach non memoized events
   * @returns scheduler instance
   */
  const initSchedulerEvents = () => {
    const scheduler: DHTMLX.SchedulerStatic | undefined = schedulerRef.current;
    if (scheduler === undefined) {
      return;
    }
    const onDataUpdated = props.onDataUpdated;
    const attachEventSettings = {};

    scheduler.attachEvent(
      'onEventCreated',
      (id: number, e: MouseEventHandler) => {
        log.debug('onEventCreated', id, e);
        return true;
      },
      attachEventSettings,
    );

    scheduler.attachEvent(
      'onEventAdded',
      (id: number, event: ISchedulerEvent) => {
        log.debug('onEventAdded', id, event);
        if (IsReadOnlyUser(authenticatedUser)) {
          dispatch(
            globalActions.addNotification({
              key: 'permissionError',
              message: permissionsError,
              variant: 'error',
              closable: true,
              autoHideDuration: 3000,
            }),
          );
          return false;
        }
        // clear previously created events other than the latest one
        deleteTempReservations(schedulerRef, id);
        resetEventEdits(scheduler, event);
        dispatch(actions.getEmptySlotAdminClick());
        const service = getServerListService(scheduler, event.service_id);
        const serviceId =
          service?.AssemblyId ?? service?.Id ?? event.service_id;

        onCreate?.({
          id: id,
          start_date: dateUtils.formatISO(event.start_date),
          end_date: dateUtils.formatISO(event.end_date),
          service_id: serviceId,
          alert_type: event.alert_type,
          defaultMulti: event.defaultMulti,
        });
      },
      attachEventSettings,
    );

    // onBeforeViewChange is triggered on init, on section change, etc.
    scheduler.attachEvent(
      'onBeforeViewChange',
      function (
        old_mode: ViewModes | undefined,
        old_date: Date | undefined,
        mode: ViewModes,
        date: Date,
      ) {
        log.debug('onBeforeViewChange', { old_mode, old_date, mode, date });
        // silence lame events fired after section change
        if (
          isEqual(
            {
              viewMode: old_mode,
              date: serializeSchedulerDateOrUndefined(old_date),
            },
            {
              viewMode: mode,
              date: serializeSchedulerDate(date),
            },
          )
        ) {
          return true;
        }

        const prev =
          old_mode === undefined ? undefined : parseViewMode(old_mode);
        const prevViewState: IViewState | undefined =
          prev === undefined
            ? undefined
            : {
                preset: props.presetPosition,
                viewType: prev.viewType,
                viewLength: prev.viewLength,
                date: serializeSchedulerDate(date),
                search: props.search,
              };
        const currentViewState: IViewState = {
          preset: null,
          search: props.search,
          ...parseViewMode(mode),
          date: serializeSchedulerDate(date),
        };

        const x = (f?: IViewState) => ({
          viewLength: f?.viewLength,
          date: f?.date,
        });
        if (
          prevViewState === undefined ||
          isEqual(x(currentViewState), x(prevViewState))
        ) {
          currentViewState.preset = props.presetPosition;
        }

        // adjust timeline length
        adjustMonthTimelineLength(mode, date, scheduler);

        return true;
      },
      attachEventSettings,
    );

    // sync view mode state in scheduler -> react state
    // is called on date/period change + on after init
    scheduler.attachEvent(
      'onViewChange',
      function (new_viewMode, new_date, b) {
        log.debug('onViewChange', {
          new_viewMode,
          new_date,
          viewType,
          viewLength,
        });
      },
      attachEventSettings,
    );

    scheduler.attachEvent(
      'onDblClick',
      (id, event) => {
        log.debug('onDblClick', { id, event });
        // just disable double click - edit is triggered on the single click
        return false;
      },
      attachEventSettings,
    );
    scheduler.attachEvent(
      'onBeforeEventCreated',
      event => {
        log.debug('onBeforeEventCreated', event);
        return true;
      },
      attachEventSettings,
    );

    // onEventChanged is fired immediately after an event is changed on the scheduler, for example - by dragging it over
    scheduler.attachEvent(
      'onEventChanged',
      (id, event: ISchedulerEvent) => {
        log.debug('onEventChanged', id, event);
        if (IsReadOnlyUser(authenticatedUser)) {
          dispatch(
            globalActions.addNotification({
              key: 'permissionError',
              message: permissionsError,
              variant: 'error',
              closable: true,
              autoHideDuration: 3000,
            }),
          );
          return false;
        }
        resetEventEdits(scheduler, event);
        // either an existing reservation can be edited or a "new event" can be moved on the timeline while the sidePanel is still open
        const changeNewReservation = event.type === undefined;
        if (!changeNewReservation) {
          // if existing reservation is edited - drop any "new events"
          deleteTempReservations(schedulerRef);
          // and open the edit sidePanel for through onEdit callback
          onEdit?.({
            ...event,
            ...{
              start_date: dateUtils.formatISO(event.start_date),
              end_date: dateUtils.formatISO(event.end_date),
            },
          });
          return true;
        } else {
          // open create reservation sidePanel or adjust time if already opened
          onCreate?.({
            id: id,
            start_date: dateUtils.formatISO(event.start_date),
            end_date: dateUtils.formatISO(event.end_date),
            service_id: event.service_id,
          });
        }

        return false;
      },
      attachEventSettings,
    );

    scheduler.attachEvent(
      'onEventDeleted',
      (id, ev) => {
        log.debug('onEventDeleted', { id, ev });
        if (onDataUpdated) {
          onDataUpdated('delete', ev, id);
        }
      },
      attachEventSettings,
    );
  };
  React.useEffect(() => {
    // wait for both scheduler and the services to be initialized
    // the services will be needed in the openToBookMode for the default reservation duration
    if (!!scheduler && services !== undefined) {
      const params = new URLSearchParams(location.search);
      let openToBook = GetRawValue(params, 'openToBook') === 'true';
      let openToReport = GetRawValue(params, 'openToReport') === 'true';
      let serviceIds = GetRawValue(params, 'eid') ?? undefined;
      let alertType = GetNumberOrUndefined(
        GetRawValue(params, 'aType') ?? undefined,
      );
      let defaultMulti = GetRawValue(params, 'defaultMulti') === 'true';
      if (
        (openToBook === true || openToReport === true) &&
        serviceIds !== undefined &&
        services !== undefined &&
        services.length > 0
      ) {
        initOnlineServicesData(serviceIds, 'reserved').then(result => {
          !!setFilterValue && setFilterValue('service', result);
          dispatch(actions.getServices_Success(result as IServiceFilterDto[]));
          dispatch(
            actions.updateEmptySlotSettings(
              openToReport
                ? EmptySlotAdminClick.DownTimeOnly
                : EmptySlotAdminClick.ReservationsOnly,
            ),
          );
        });
        if (isMobile) {
          dispatch(
            globalActions.addNotification({
              key: 'ClickToReserve',
              message: 'Click to reserve (or click, hold and drag)',
              variant: 'info',
              closable: true,
              autoHideDuration: 5000,
            }),
          );
        } else {
          var event = {
            start_date: newDate(),
            end_date: scheduler.date.add(
              newDate(),
              defaultDuration || 60,
              'minute',
            ),
            service_id: getSplitNumberArray(serviceIds || ''),
            alert_type: alertType,
            defaultMulti,
          };
          scheduler.addEventNow(event);
        }

        const currentSearch = new URLSearchParams();
        [...params.keys()].forEach(key => {
          if (
            key !== 'openToBook' &&
            key !== 'openToReport' &&
            key !== 'aType' &&
            key !== 'defaultMulti'
          ) {
            const val = params.get(key);
            if (val !== null) {
              currentSearch.set(key, val);
            }
          }
        });
        dispatch(
          routerActions.replace({
            pathname: location.pathname,
            search: currentSearch.toString(),
          }),
        );
      }
    }
  }, [
    actions,
    appliedFilters,
    defaultDuration,
    dispatch,
    globalActions,
    isMobile,
    location.pathname,
    location.search,
    newDate,
    onFilterChange,
    scheduler,
    services,
    setFilterValue,
  ]);
  /**
   * Main initialization
   */
  useEffectOnMount(() => {
    schedulerRef.current = DHTMLX.Scheduler.getSchedulerInstance();
    const scheduler = schedulerRef.current;
    if (scheduler !== undefined && schedulerContainer.current !== undefined) {
      scheduler.skin = 'material';
      scheduler.locale.labels.timeline_tab = 'Timeline';
      scheduler.locale.labels.calendar_tab = 'Calendar';
      scheduler.locale.labels.section_custom = 'Section';
      // native header can't be removed by removing div.dhx_cal_navline since scheduler creates it anyway
      // display:none hides native navline
      // scheduler.xy.nav_height = 0 - adjusts the heights
      scheduler.xy.nav_height = 0;

      scheduler.plugins({
        limit: true,
        all_timed: true,
        timeline: true,
        treetimeline: true,
        daytimeline: true,
        tooltip: true,
        units: true,
        multiselect: true,
      });

      const configOptions: Partial<DHTMLX.SchedulerConfigOptions> = {
        // show multi day events inside the scheduler body instead of showing as full day events on top
        all_timed: true,
        multi_day: true,
        details_on_create: false,
        details_on_dblclick: false,
        start_on_monday: tryGetStartOnMonday(),
        // disable internal edit on event create
        edit_on_create: false,
        hour_date: '%g:%i %A',
        mark_now: true,
        now_date: newDate(),
        time_step: 15,
        //auto_end_date: true,
        //event_duration: 120,
        touch: true,
        touch_drag: 500,
        // sets a timeout (in milliseconds) that wraps the updateView and setCurrentView calls (that cause re-drawing of the scheduler)
        // https://docs.dhtmlx.com/scheduler/api__scheduler_delay_render_config.html
        // setting delay_render to be > 0 results in timeline (both tree and matrix) not render e.g. blank view
        delay_render: 0,
        // disable dragging event by it's body element since it's handled in the readonly/move
        drag_event_body: false,
        // scroll by default to 08:00 on calendar
        scroll_hour: 8, // newDate().getHours(), //8,
        // prevents check limit cpu crunch on open end offline hours/alerts
        check_limits: false,
      };

      Object.assign(scheduler.config, configOptions);
      log.info('scheduler.config', scheduler.config);
      // reduce from default 70 to 40 in order to reduce precious horizontal space on mobile
      scheduler.xy.scale_width = isMobile ? 40 : 70;
      // this should be large enough to contain 2 text lines on mobile otherwise one is sufficient
      scheduler.xy.scale_height = isMobile ? 35 : 30;

      scheduler.config.hour_size_px = 44;
      // reduces default min height from 40px to 22px (half an hour height)
      scheduler.xy.min_event_height = scheduler.config.hour_size_px / 2;

      // cast is needed since the scheduler renderEvent is typed incompletely. According to definition it has 2 arguments, but in reality it's got 6 - see renderEvent
      scheduler.renderEvent = renderEvent(scheduler) as (
        container: HTMLElement,
        event: any,
      ) => boolean;

      Object.assign(scheduler.templates, {
        // parse function to match system settings
        parse_date: date => dateUtils.parseISO(date),
        tooltip_text: tooltip_text(
          scheduler,
          compiledSchedulerReservationTooltipTemplate,
        ),
        event_class: function (start: Date, end: Date, event: ISchedulerEvent) {
          const eventType = event.type;
          if (eventType === 'offline') {
            return 'event_offline';
          } else {
            if (event.id === scheduler.getState().select_id) {
              return 'selected';
            }
          }
        },
        // sets the calendar event text content
        event_text: event_text(
          viewType,
          isMobile,
          compiledSchedulerReservationBodyTemplate,
        ),
        // todo: set the timeline event text content - this will require rework of timeline display/or separate template since currently the timeline events won't fit the multiline text
        event_bar_text: event_text(
          viewType,
          isMobile,
          compiledSchedulerReservationBodyTemplate,
        ),
        week_scale_date: function (date) {
          const text = isMobile
            ? // Mon 12 with line break
              dateUtils.format(date, 'EEE') +
              '<br/>' +
              dateUtils.format(date, 'dd')
            : // Mon, 12 Jan
              dateUtils.format(date, 'EEE, d LLL');

          return text;
        },
        unit_scale_text: function (key, label, unit, date) {
          return 'AAAAAAAAAAAA';
        },
        hour_scale: function (date) {
          // no am/pm indicator was found on the date-fns locale
          // removes minutes :00 manually in order to preserve precious horizontal space on mobile
          return useAmPm
            ? isMobile
              ? dateUtils.format(date, 'HH')?.replace(':00', '')
              : dateUtils.format(date, 'hh aaa')
            : isMobile
            ? dateUtils.format(date, 'p')?.replace(':00', '')
            : dateUtils.format(date, 'p');
        },
        // work date class
        week_date_class: function (date, today) {
          return `week_date_${dateUtils.formatISO(date, {
            format: 'basic',
            representation: 'date',
          })}`;
        },
      });

      // attaches constant events
      initSchedulerEvents();

      // serverList is needed to be able to change the "sections" in the lifecycle of the scheduler component
      // changes in the scheduler.sections is ignored by the scheduler
      setServerList(
        scheduler,
        services,
        appSettings,
        schedulerSectionsGroupByField,
      );

      // create all of the views like weekly calendar, daily calendar (unit) etc.
      createTimelineView(scheduler, isMobile, customDays || 2, useAmPm);
      createUnitsView(scheduler);

      // initializes the scheduler
      scheduler.init(
        schedulerContainer.current as HTMLElement,
        getViewStartDate(viewLength, date, hasCustomStart),
        viewMode,
      );
    }
    return () => {
      schedulerRef.current && schedulerRef.current.destructor();
    };

    /**
     * try to get the monday/sunday week start
     * dateUtils.CurrentLocale() might return undefined if it was not initialized properly before this call, e.g. in test
     * @returns
     */
    function tryGetStartOnMonday(): boolean | undefined {
      try {
        return dateUtils.CurrentLocale()?.options?.weekStartsOn === 1;
      } catch (error) {
        return true;
      }
    }
  });

  const handleServiceLabelClick = React.useCallback(
    (event: MouseEvent, serviceId: number) => {
      // open the asset details
      const assetPopupProps: AssetPopUpProps = {
        serviceId: serviceId,
      };
      dispatch(layoutActions.toggleSidePanel(true));
      dispatch(layoutActions.setPageType(RenderPageType.AssetDetails));
      dispatch(layoutActions.setPageProps(assetPopupProps));
      dispatch(layoutActions.setExpanded(false));
    },
    [dispatch],
  );

  /**
   * Handles click inside the scheduler.
   * Clicking on an asset label/image should open asset side panel
   */
  const handleSchedulerClick = React.useCallback(
    (event: MouseEvent) => {
      log.debug('handleSchedulerClick', event);

      const isTimelineColumnCellClick =
        findParentTarget(event, ['.dhx_matrix_scell']) !== null;
      if (isTimelineColumnCellClick) {
        const serviceId = getServiceIdFromServiceClickEvent(event);
        if (serviceId !== undefined) {
          handleServiceLabelClick(event, serviceId);
          // prevent further event propagation if it's already handled
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      }
    },
    [handleServiceLabelClick],
  );
  React.useEffect(() => {
    const dom = schedulerContainer.current;
    dom?.addEventListener('click', handleSchedulerClick, { capture: true });
    return () => {
      dom?.removeEventListener('click', handleSchedulerClick);
    };
  }, [handleSchedulerClick]);

  /**
   * Handles zoom in / out for timeline view only.
   * currently  commented to develop later
   */
  // const handleZoomWhell = React.useCallback(
  //   (event: WheelEvent) => {
  //    if (!zoomEnabled) {
  //      return;
  //    }
  //     let delta = 0;
  //     if (!!(event as any).wheelDelta) {
  //       /* IE/Opera. */
  //       delta = (event as any).wheelDelta / 120;
  //     } else if (event.detail) {
  //       /* Mozilla case. */
  //       // In Mozilla, sign of delta is different than in IE.
  //       // Also, delta is multiple of 3.
  //       delta = -event.detail / 3;
  //     } else if (event.deltaY) {
  //       delta = -event.deltaY / 3;
  //     }
  //     if (!(viewType === 'timeline' && !!schedulerRef.current && !!scheduler))
  //       return;
  //     const target = event.target;

  //     if (!(target instanceof Element)) {
  //       return;
  //     }
  //     let dataContainer = target.closest('.dhx_timeline_data_wrapper');
  //     if (dataContainer === null) {
  //       return;
  //     }
  //     let state = scheduler?.getActionData(event);
  //     if (!state || !state.date) {
  //       return;
  //     }

  //     if (delta) {
  //       const zoomFriction = 5;
  //       let scale = 0;
  //       if (delta < 0) {
  //         scale = 1 - delta / zoomFriction;
  //       } else {
  //         scale = 1 / (1 + delta / zoomFriction);
  //       }
  //       let pointer = getPointer(
  //         {
  //           x: event.clientX,
  //           y: event.clientY,
  //         },
  //         dataContainer,
  //         scheduler,
  //       );
  //       let timeline = scheduler?.matrix[viewMode];
  //       let currentMode = parseViewMode(scheduler?.getState().mode);
  //       let newLength = currentMode.viewLength;
  //       if (false) {
  //         //currentMode.viewLength === 'week'
  //         //let pointerDate = timeline.dateFromPos(pointer.x);

  //         const hour = 24;
  //         const divider = 4;
  //         const minStep = 4;
  //         const maxStep = 8;
  //         const column_width = 36;
  //         //const left = timeline.posFromDate(state.date);
  //         const top = timeline.getSectionTop(state.section);
  //         const position = {
  //           left: pointer.x + (pointer.x - pointer.x * scale) / 2, //Math.ceil(left / 2),
  //           top: top / 2, //pointer.y - (pointer.y / 2) * scale, //Math.ceil(top / 2),
  //         } as LeftTop;
  //         // const position = {
  //         //   date: pointerDate,
  //         //   section: state.section,
  //         // } as DateSection;
  //         // const position = timeline.resolvePosition({
  //         //   top: e.y,
  //         //   left: e.x,
  //         // });
  //         // zoom in
  //         if (delta > 0) {
  //           if (currentStep === 0) {
  //             timeline.x_step = hour / (divider + scale);
  //             timeline.x_size = (divider + scale) * 7;
  //             timeline.x_length = (divider + scale) * 7;
  //             timeline.column_width =
  //               timeline.column_width +
  //               (timeline.column_width - timeline.column_width * scale); //column_width * scale;
  //             setCurrentPosition(position);
  //             setCurrentStep(divider + 1);
  //             setZoomed(true);

  //             // timeline.scrollTo(position.date, position.section);
  //             // scheduler.setCurrentView(position.date);
  //           } else {
  //             timeline.x_step =
  //               currentStep < maxStep
  //                 ? hour / (currentStep + scale)
  //                 : timeline.x_step;
  //             timeline.x_size =
  //               currentStep < maxStep
  //                 ? (currentStep + scale) * 7
  //                 : timeline.x_size;
  //             timeline.x_length =
  //               currentStep < maxStep
  //                 ? (currentStep + scale) * 7
  //                 : timeline.x_length;
  //             timeline.column_width =
  //               currentStep < maxStep
  //                 ? timeline.column_width +
  //                   (timeline.column_width - timeline.column_width * scale)
  //                 : timeline.column_width;
  //             if (currentStep < maxStep) {
  //               setCurrentStep(prev => prev + 1);
  //               setCurrentPosition(position);
  //               setZoomed(true);

  //               // scheduler.setCurrentView(position.date);
  //               // timeline.scrollTo(position.date, position.section);
  //             }
  //           }
  //         }
  //         // zoom out
  //         else {
  //           if (currentStep >= minStep) {
  //             timeline.x_step =
  //               currentStep > minStep
  //                 ? hour / (currentStep - 1)
  //                 : timeline.x_step;
  //             timeline.x_size =
  //               currentStep > minStep ? (currentStep - 1) * 7 : timeline.x_size;
  //             timeline.x_length =
  //               currentStep > minStep
  //                 ? (currentStep - 1) * 7
  //                 : timeline.x_length;
  //             timeline.column_width =
  //               currentStep > minStep
  //                 ? timeline.column_width / 1.5
  //                 : column_width;
  //             if (currentStep > minStep) {
  //               setCurrentStep(prev => prev - 1);
  //               setCurrentPosition(position);
  //               setZoomed(true);
  //             }
  //           }
  //         }
  //         console.log('week_timeline', timeline);
  //       } else {
  //         if (event.deltaY < 0) {
  //           newLength = getPrevViewLength(
  //             currentMode.viewLength,
  //             customDaysEnabled,
  //             twoDaysEnabled,
  //           );
  //         } else {
  //           newLength = getNextViewLength(
  //             currentMode.viewLength,
  //             customDaysEnabled,
  //             twoDaysEnabled,
  //           );
  //         }
  //         if (newLength !== currentMode.viewLength) {
  //           !!handleViewTypeLengthChange &&
  //             handleViewTypeLengthChange(
  //               viewType,
  //               newLength,
  //               getViewStartDate(newLength, state.date, hasCustomStart),
  //               noChangeLocation,
  //             );
  //         }
  //       }
  //       event.preventDefault();
  //     }
  //   },
  //   [
  //     currentStep,
  //     customDaysEnabled,
  //     handleViewTypeLengthChange,
  //     hasCustomStart,
  //     noChangeLocation,
  //     scheduler,
  //     twoDaysEnabled,
  //     viewMode,
  //     viewType,
  //   ],
  //    zoomEnabled,
  // );
  /**
   * Bind to scheduler dom in order to handle click on instrument label/image in the timeline
   */

  // todo: find the source of services array reference change
  // prevent unnecessary useEffect invocations when services array reference changes for whatever reason
  const stringifiedServices = React.useMemo(
    () => (services === undefined ? undefined : JSON.stringify(services)),
    [services],
  );
  const parsedServices = React.useMemo(
    () =>
      stringifiedServices === undefined
        ? undefined
        : JSON.parse(stringifiedServices),
    [stringifiedServices],
  );
  const schedulerSectionsGroupByField = useSelector(selectTimelineGroupBy);
  /**
   * Sync props.services -> scheduler
   */
  React.useEffect(() => {
    if (parsedServices !== undefined) {
      const scheduler = schedulerRef.current;
      if (scheduler !== undefined) {
        // if (sections?.length === 0) {
        //   sections.push({ label: '' });
        // }
        // todo: update grouping on unit->timeline transition
        updateServerList(
          scheduler,
          services,
          appSettings,
          schedulerSectionsGroupByField,
        );

        /**
         * units view does not update the service column on it's own after the updateCollection call
         * unlike the timeline which manages to reflect the changes immediately on it's own without any help
         * calendar view does not need any changes at all since it does not show separate services
         */
        if (viewType === 'unit') {
          updateView(schedulerRef.current);
        }
      }
    }
  }, [
    appSettings,
    parsedServices,
    schedulerSectionsGroupByField,
    services,
    viewType,
  ]);

  /**
   * Sync props.events -> scheduler
   */
  React.useEffect(() => {
    // return early on initial render
    if (events !== undefined) {
      syncSchedulerEvents(schedulerRef?.current, events);
    }
  }, [scheduler, events]);

  /**
   * non working hours (gaps between the working hours) need to be tracked separately due to the lack of getMarkedTimespans similar to getEvents on the scheduler API
   * todo: find a better way to clear/sync non working hours into the scheduler. Currently shown marked timespans might be queryable through the DOM API.
   */
  const nonWorkingHoursRef = React.useRef<Array<IMarkedTimeSpan>>([]);
  React.useEffect(() => {
    // return early on initial render
    if (nonWorkingHours !== undefined) {
      const state: ISchedulerState = schedulerRef?.current?.getState();
      // https://docs.dhtmlx.com/scheduler/api__scheduler_addmarkedtimespan.html
      const getUnits = item => {
        switch (viewType) {
          case 'unit':
          case 'timeline':
            return {
              [viewMode]: item.EquipmentId,
            };
          case 'calendar':
            return undefined;

          default:
            assertExhaustive(viewType);
        }
      };
      const timeSpans = nonWorkingHoursRef.current.reduce<{
        removed: IMarkedTimeSpan[];
        current: IMarkedTimeSpan[];
      }>(
        (prev, item) => {
          const match =
            item.start_date < state.max_date && item.end_date > state.min_date;
          (match ? prev.removed : prev.current).push(item);
          return prev;
        },
        {
          removed: [],
          current: [],
        },
      );
      timeSpans.removed.forEach(item => {
        schedulerRef?.current?.deleteMarkedTimespan(String(item.id));
        return item;
      });

      const highlights = nonWorkingHours
        ?.map(item => {
          const timeSpan = {
            type: 'dhx_time_block',
            css: 'fat_lines_section',
            start_date: dateUtils.parseISO(item.Start),
            end_date: dateUtils.parseISO(item.End),
            sections: getUnits(item),
          };
          var spanId = schedulerRef.current?.addMarkedTimespan(timeSpan);
          if (spanId === undefined) {
            return undefined;
          }
          const r: IMarkedTimeSpan = {
            id: spanId,
            start_date: timeSpan.start_date,
            end_date: timeSpan.end_date,
          };
          return r;
        })
        .filter(item => item !== undefined);
      nonWorkingHoursRef.current = [
        ...timeSpans.current,
        ...(highlights as IMarkedTimeSpan[]),
      ];

      // updateView here is needed since addMarkedTimespan above is not rendered immediately on the scheduler
      updateView(schedulerRef.current, undefined, true);
    }
  }, [nonWorkingHours, viewMode, viewType]);

  /**
   * Sync current view props from props to scheduler instance
   * useDidUpdate is used here to skip first fire since the scheduler is already initialized with the current view props during initial mount, so nothing needs to be done in this case.
   */
  useDidUpdate(() => {
    const scheduler = schedulerRef.current;
    if (scheduler === undefined) {
      return;
    }

    // TODO: remove following block? looks like the viewType parameter is redundant and resetting the calendar/timeline event text templates with subsequent updateView call just wastes the resources
    // TODO: clearly separate of calendar/timeline templates (if it will be possible to untangle the event_text mess)
    scheduler.templates.event_text = event_text(
      viewType,
      isMobile,
      compiledSchedulerReservationBodyTemplate,
    );
    scheduler.templates.event_bar_text = event_text(
      viewType,
      isMobile,
      compiledSchedulerReservationBodyTemplate,
    );
    // is this needed to update view to apply updates in event_text & event_bar_text ?
    updateView(scheduler);

    const d = dateUtils.parseISO(date);
    scheduler.setCurrentView(
      getViewStartDate(viewLength, date, hasCustomStart),
      viewMode,
    );
    // scroll to 08:00 on daily timeline
    if (viewMode === 'day_timeline') {
      scheduler
        .getView()
        ?.scrollTo(dateUtils.addHours(dateUtils.startOfDay(d), 8));
    }
    if (viewMode === 'twodays_timeline') {
      let timeline = scheduler.matrix[viewMode];
      timeline.setRange(
        getViewStartDate(viewLength, date, hasCustomStart),
        getViewEndDate(viewLength, date, undefined, hasCustomStart),
      );
    }
    if (viewMode === 'customdays_timeline') {
      let timeline = scheduler.matrix[viewMode];
      timeline.setRange(
        getViewStartDate(viewLength, date, hasCustomStart),
        getViewEndDate(viewLength, date, customDays, hasCustomStart),
      );
    }
    if (hasCustomStart) {
      const d = dateUtils.parseISO(date);
      if (
        viewMode === 'month_timeline' ||
        viewMode === 'week_timeline' ||
        viewMode === 'quarter_timeline' ||
        viewMode === 'year_timeline' ||
        viewMode === 'week' ||
        viewMode === 'month'
      ) {
        let timeline = scheduler.matrix[viewMode];
        let newStart = getViewStartDate(viewLength, date, hasCustomStart);
        let newEnd = getViewEndDate(
          viewLength,
          date,
          customDays,
          hasCustomStart,
        );

        if (viewMode === 'week_timeline') {
          scheduler.date['week_timeline_start'] = () => newStart;
          scheduler.date['get_week_timeline_end'] = () => newEnd;
        } else if (viewMode === 'week') {
          scheduler.date['week_start'] = () => newStart ?? d;
        } else if (viewMode === 'month') {
          scheduler.date.month_start = () => newStart ?? d;
        } else {
          timeline.setRange(newStart, newEnd);
        }
      }
    }
  }, [
    compiledSchedulerReservationBodyTemplate,
    customDays,
    date,
    hasCustomStart,
    isMobile,
    viewLength,
    viewMode,
    viewType,
  ]);

  const withReservationsOnly = useSelector(selectWithReservationsOnly);
  const loadingEvents = useSelector(selectSchedulerLoadingEvents);
  const showProgress = withReservationsOnly === true && loadingEvents === true;
  const showNoDataPlaceholder = services?.length === 0 && !showProgress;
  const hideCalData = showNoDataPlaceholder || showProgress;
  const print = useSelector(selectExportToPDF);
  const handleClosePrintToPDF = React.useCallback(() => {
    dispatch(actions.resetExportToPDF());
  }, [actions, dispatch]);
  const getTitle = React.useCallback(
    (viewType: ViewTypes): string => {
      switch (viewType) {
        case 'calendar':
          return t(translations.Calendar);
        case 'timeline':
          return t(translations.Timeline);
        default:
          assertExhaustive(viewType);
      }
    },
    [t],
  );
  return (
    <>
      <PrintSchedulerToPDF
        title={''}
        print={print !== undefined ? print : false}
        printTitle={getTitle(props.viewType)}
        scheduler={schedulerRef.current}
        close={handleClosePrintToPDF}
      ></PrintSchedulerToPDF>
      <div
        ref={schedulerContainer}
        style={{ width: '100%', height: props.hight ?? '100%' }}
        id="scheduler_here"
        className={clsx(schedulerSectionsGroupByField, {
          dhx_cal_container: isMobile,
        })}
      >
        {/* 
        native header can't be removed by removing div.dhx_cal_navline since scheduler creates it anyway
        display:none hides native navline
        scheduler.xy.nav_height = 0 - adjusts the heights
      */}
        <div className="dhx_cal_navline" style={{ display: 'none' }}></div>
        <div className="dhx_cal_header"></div>
        {showNoDataPlaceholder && <NoServices />}
        {showProgress && <TableRowsSkeleton isCards={false} isMobile={false} />}
        {/* hide the calendar data without unmounting it since it's content is handled by the dhtmlx scheduler */}
        <div
          className="dhx_cal_data"
          style={{ visibility: hideCalData ? 'hidden' : 'visible' }}
        ></div>
      </div>
    </>
  );
}
