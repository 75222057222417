import { Entity } from 'types/common';
import * as React from 'react';
import { GlobalSettingsType } from '../useGlobalSettingsHook';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { useSelector } from 'react-redux';
import { selectAsyncChangeLoading } from '../../slice/selectors';

export interface EndTimeListenerProps<T> {
  userName: Entity<string> | null;
  equipments: Entity<number>[];
  globalSettings: GlobalSettingsType;
  user: AuthenticatedUser | undefined;
  endTime: Date | string;
  isEdit: boolean;
  changeHandler: (values: T, changeStatus?: boolean | undefined) => void;
  values: T;
}
export function EndTimeListener<T>(props: EndTimeListenerProps<T>) {
  const {
    userName,
    equipments,
    endTime,
    isEdit,
    changeHandler,
    values,
  } = props;
  const [initialized, setInitialized] = React.useState(true);
  const asyncChangeLoading = useSelector(selectAsyncChangeLoading);
  React.useEffect(() => {
    if (!initialized) {
      if (
        equipments.length > 0 &&
        userName != null &&
        !isEdit &&
        !asyncChangeLoading
      ) {
        changeHandler(values);
      }
    }
    setInitialized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime]);

  return null;
}
