import { Button } from 'app/components/BasicButtons/Button';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectExpandedSidePanel,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { selectPublicUrl } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DetectIsMobile } from 'utils/mobileDetect';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { buildURL } from 'utils/url-utils';
import { omit } from 'lodash';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { push } from 'connected-react-router';
import { DeatilsOperations } from './ditails';
import { SaveFormButton } from 'app/pages/UserTrainings/TrainingDetailsPage/Details/Actions/TrainingActions';
import { TFunction } from 'i18next';
import { useAppSettingsSlice } from 'app/slice';
import { INotification } from '../Actions/Actions';
import { Beforeunload } from 'react-beforeunload';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';

export interface DetailsFormProps {
  initialValues?: LocationDetailsProps;
  ModelDetails: any;
  isEdit?: boolean;
  processing?: boolean;
  bindSubmitForm: any;
  onSubmit: (item: any) => void;
  onCancel?: () => void;
}

export interface LocationDetailsProps extends SidePanelContentProps {
  Id: number;
  Title: string;
  AddNew: string;
  ModelDetails: any;
  PageComponent: string;
  component: React.FC<DetailsFormProps>;
  operation: DeatilsOperations;
  submitcall: (
    t: TFunction,
    Id: number,
    operation: DeatilsOperations,
    details: any,
    handleSubmitCallResult: (values: INotification[]) => void,
  ) => void;
}

export function LocationDetails(props: LocationDetailsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const initialValues: LocationDetailsProps = props;
  const hasChanges = useSelector(selectHasNotSavedChanges, shallowEqual);
  const edit = initialValues.operation === DeatilsOperations.edit;
  const busy = false;
  const isMobile = DetectIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const confirmRejected = useSelector(selectConfirmRejected);
  const confirmApproved = useSelector(selectConfirmApproved);
  const requestCompleted = true;
  const publicUrl = useSelector(selectPublicUrl);
  const handleSubmitForm = e => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const handleCancelClick = React.useCallback(() => {
    if (props.useSidePanel) {
      dispatch(layoutActions.resetSidePanel());
    } else {
      dispatch(push('/' + initialValues.PageComponent));
    }
  }, [
    dispatch,
    initialValues.PageComponent,
    layoutActions,
    props.useSidePanel,
  ]);
  const handleClose = () => {
    if (hasChanges) {
      dispatch(layoutActions.setConfirmOpen(true));
    } else {
      handleCancelClick();
    }
  };

  React.useEffect(() => {
    if (confirmApproved) {
      dispatch(layoutActions.resetSidePanel());
    }
    if (confirmRejected) {
      dispatch(layoutActions.resetConfirmState());
    }
  });

  const submitFormRef = React.useRef<any>(null);
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);
  const handleSubmitCallResult = React.useCallback(
    (values: INotification[]) => {
      values.forEach(v =>
        dispatch(
          actions.addNotification({ variant: v.variant, message: v.message }),
        ),
      );
      dispatch(layoutActions.resetSidePanel());
      dispatch(layoutActions.setRefreshTable(true));
    },
    [actions, dispatch, layoutActions],
  );
  const handleSubmit = React.useCallback(
    values => {
      initialValues.submitcall(
        t,
        initialValues.Id,
        initialValues.operation,
        values,
        handleSubmitCallResult,
      );
    },
    [handleSubmitCallResult, initialValues, t],
  );
  const LocationContent = React.useMemo(() => {
    return (
      <initialValues.component
        initialValues={initialValues}
        ModelDetails={initialValues.ModelDetails}
        bindSubmitForm={bindSubmitForm}
        onSubmit={handleSubmit}
      />
    );
  }, [bindSubmitForm, handleSubmit, initialValues]);
  const leftActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <SaveFormButton
            size={isMobile || !sidePanelExpanded ? 'small' : 'medium'}
            startIcon={<Icon icon="save" />}
            onClick={handleSubmitForm}
            disabled={busy}
            processing={!requestCompleted && busy}
            edit={edit}
            text={t(translations.Save)}
          />
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [busy, edit, isMobile, requestCompleted, sidePanelExpanded, t]);

  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <Button
            variant="ghost"
            size={isMobile || !sidePanelExpanded ? 'small' : 'medium'}
            startIcon={<Icon icon="times" />}
            onClick={handleCancelClick}
          >
            {t(translations.Cancel)}
          </Button>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCancelClick, isMobile, sidePanelExpanded, t]);
  return (
    <>
      <PageWrapper
        pageName={edit ? props.Title : props.Title}
        titlePage={
          edit
            ? ` ${t(translations.Update)} ${t(translations.IdSpec)}${
                initialValues.Id
              }`
            : ` ${props.AddNew}`
        }
        closable={true}
        closeSidePanel={handleClose}
        children={LocationContent}
        pageLink={buildURL(
          publicUrl + initialValues.PageComponent,
          omit(props, ['useSidePanel']),
        )}
        leftActions={leftActions}
        rightActions={rightActions}
        useSidePanel={props.useSidePanel}
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </>
  );
}
