/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  userDetails: () => _t(translations.UserDetails),
  changePassword: () => _t(translations.reg_ChangePassword),
  changeUserGroup: () => _t(translations.changeUserGroup),
  logout: () => _t(translations.main_master_LogOutLinkText),
  help: () => _t(translations.Help),
  optOutNotifications: () => _t(translations.OptOutNotifications),
  optOutNotificationsTitle: () => _t(translations.OptOutNotifications_info),
  coreRegistration: () => _t(translations.ServiceGroupRegistration),
  googleCalendar: () => _t(translations.menu_GoogleCalendar),
  outlookCalendar: () => _t(translations.menu_OutlookCalendar),
  notifications: () => _t(translations.Notifications),
};
