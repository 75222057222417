import * as DHTMLX from 'dhtmlx-scheduler';
import { dateUtils } from 'utils/date-utils';

export function adjustMonthTimelineLength(
  mode: any,
  date: any,
  scheduler: DHTMLX.SchedulerStatic,
) {
  if (mode === 'month_timeline') {
    if (date !== undefined) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var d = new Date(year, month, 0);
      scheduler.matrix[mode].x_size = d.getDate();
      scheduler.matrix[mode].x_length = d.getDate();
      //scheduler.date['timeline_start'] = scheduler.date.month_start;
      //scheduler.date.
    }
  } else if (mode === 'quarter_timeline') {
    if (date !== undefined) {
      let qst = dateUtils.startOfQuarter(date);
      let qet = dateUtils.endOfQuarter(date);
      let offsetStart =
        dateUtils.startOfWeek(qst) < qst
          ? dateUtils.startOfWeek(qst).getDay()
          : 0;

      let weeks = Math.round(dateUtils.getWeekDiff(qst, qet));
      scheduler.matrix[mode].x_size = weeks;
      scheduler.matrix[mode].x_length = weeks;
      scheduler.matrix[mode].x_start = offsetStart;
    }
  }
}
