import fileDownload from 'js-file-download';
import * as Papa from 'papaparse';

export type CsvColumnsMapType<T> = { [key in keyof T]: 'string' };

export async function writeCsvFile(
  fields: string[],
  data: string[][],
): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const csv = Papa.unparse({ fields: fields, data: data });
      resolve(csv);
    } catch (error) {
      reject(error);
    }
  });
}
export async function exportCsv({
  data,
  fields,
  fileName = 'file.csv',
}: {
  data: unknown[][];
  fields: string[];
  fileName: string;
}): Promise<void> {
  const stringData = data.map(row =>
    row.map(value => (value as any)?.toString()),
  );
  const csvContent = await writeCsvFile(fields, stringData);
  fileDownload(csvContent, fileName);
}
