import {
  getPeriodTypesEntity,
  PeriodTypes,
  PeriodTypesEntity,
} from 'app/components/DatePeriods';
import { IPresetDatesRange } from './IPresetDatesRange';
import { DatesRange } from './DatesRange';
import { translations } from 'locales/translations';
import { TFunction } from 'i18next';
import { dateUtils } from 'utils/date-utils';

export class PresetDatesRange extends DatesRange implements IPresetDatesRange {
  constructor(t?: IPresetDatesRange) {
    super(t as IPresetDatesRange);
    this.type = (t as IPresetDatesRange)?.type ?? {
      Id: 'CustomDate',
      Name: `${translations.CustomDate}`,
      type: 'CustomDate',
    };
  }
  type: PeriodTypesEntity = {
    Id: 'CustomDate',
    Name: `${translations.CustomDate}`,
  };

  GetPresetDatesRangeDisplayString(t: TFunction): string {
    switch (this.type?.Id) {
      default:
        return t(this.type.Id);
      case 'CustomDate':
        return `${
          this.start === null
            ? t(translations.NA)
            : dateUtils.shortDateTimeFormat(this.start)
        } - ${
          this.end === null
            ? t(translations.NA)
            : dateUtils.shortDateTimeFormat(this.end)
        }`;
    }
  }
  static fromPreset(t: PeriodTypesEntity) {
    return new PresetDatesRange({
      type: t,
      ...DatesRange.fromPreset(t, null, null),
    });
  }
  static fromInitPreset(
    t: PeriodTypesEntity,
    start: Date | null,
    end: Date | null,
  ) {
    return new PresetDatesRange({
      type: t,
      ...DatesRange.fromPreset(t, start, end),
    });
  }
  static fromType(t: PeriodTypes, st: Date | null, et: Date | null) {
    const x = getPeriodTypesEntity(t);
    const y = DatesRange.fromPreset(x as PeriodTypesEntity, st, et);
    const z: IPresetDatesRange = {
      type: x,
      start: y.start,
      end: y.end,
    };
    return new PresetDatesRange(z);
  }

  static Custom(
    start: Date | undefined | null,
    end: Date | undefined | null,
  ): PresetDatesRange | undefined {
    return new PresetDatesRange({
      type: getPeriodTypesEntity('CustomDate'),
      start: start ?? null,
      end: end ?? null,
    });
  }
}
