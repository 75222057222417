/**
 *
 * Asynchronously loads the component for ServiceRequestsKanbanPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ServiceRequestsKanbanPage = lazyLoad(
  () => import('./index'),
  module => module.ServiceRequestsKanbanPage,
);
