import { trimEnd, trimStart } from 'lodash';
import * as H from 'history';
import { URLSearchParamsCI } from 'app/components/BasicTable/types/FilterParam';
import { useSelector } from 'react-redux';
import { selectPublicUrl } from 'app/slice/selectors';
import fileDownload from 'js-file-download';
import axios from 'axios';

const PATH_SEPARATOR = '/';
const ASP_ROOT = '~';
export function isAbsoluteURL(urlString: string): boolean {
  const lowered = urlString.toLowerCase();
  return ['http://', 'https://'].some(schema => lowered.startsWith(schema));
}
function isRooted(path: string): boolean {
  return path.toLowerCase().startsWith(process.env.PUBLIC_URL.toLowerCase());
}
function tryCleanASPRoot(path: string): string {
  return path.startsWith(ASP_ROOT) ? path.replace(ASP_ROOT, '') : path;
}
export function combine(basePath: string, path: string): string {
  var result =
    trimEnd(basePath, PATH_SEPARATOR) +
    PATH_SEPARATOR +
    trimStart(path, PATH_SEPARATOR);
  return result;
}

/**
 * convert relative path to host rooted, e.g. /reservations -> /bkt_webApp/reservations
 * @param path
 * @returns url usable in an href attribute
 */
export function toRootedURL(path: string, query?: Record<string, any>): string {
  const cpath = tryCleanASPRoot(path);
  const alreadyRoot = isAbsoluteURL(cpath) || isRooted(cpath);
  const rootedpath = alreadyRoot
    ? cpath
    : combine(process.env.PUBLIC_URL, cpath);
  const fullPath =
    query === undefined ? rootedpath : `${rootedpath}?${toQueryString(query)}`;
  return fullPath;
}

/**
 * Converts Record values to string and add to URLSearchParams
 * @param p object representation of query string
 * @returns URLSearchParams
 */
export function toQueryString(p: Record<string, any>): URLSearchParams {
  var result = new URLSearchParams();
  Object.entries(p)
    .filter(([key, value]) => value !== undefined)
    .forEach(([key, value]) => {
      result.append(key, value);
    });
  return result;
}

export function buildURL(path: string, params: Record<string, any>): string {
  var result = [toRootedURL(path), '?', toQueryString(params)].join('');
  return result;
}
export function toRelative(root: URL, url: URL) {
  if (root.origin !== url.origin) {
    return undefined;
  }
  if (!url.pathname.toLowerCase().startsWith(root.pathname.toLowerCase())) {
    throw new Error('External path found.');
  }
  const rootPath = appendTrailingSlash(root.pathname);
  const pathStart = url.pathname.substr(rootPath.length - 1);
  const result = pathStart + url.search + url.hash;
  return result;
}
export function appendTrailingSlash(pathname: string) {
  return pathname + (pathname.endsWith('/') ? '' : '/');
}
export function removeLeadingSlash(pathname: string) {
  if (pathname.startsWith('/')) {
    return pathname.substring(1);
  } else {
    return pathname;
  }
}

/**
 * Provides absoluteURL (e.g. https://foo.bar/baz?foo) given the usual path & search parameters that can be shared/copied
 * @param path target pathName
 * @param params search parameters
 * @returns absolute url
 */
export function useAbsolute(path?: string) {
  const publicUrl = useSelector(selectPublicUrl);
  if (publicUrl === undefined) {
    return undefined;
  }
  if (path === undefined) {
    return undefined;
  }
  if (isAbsoluteURL(path)) {
    return path;
  }

  var baseURL = new URL(publicUrl);
  var targetURL = new URL(removeLeadingSlash(path), baseURL);
  return targetURL.toString();
}

/**
 * Converts odata api parameters to export query string
 * @param params odata api parameters
 * @returns
 */
export function getExportParameters(params: Record<string, any>) {
  const result = { ...params, $format: 'application/xlsx' };
  delete result['$count'];
  delete result['$top'];
  delete result['$skip'];
  return result;
}
export function getExportForImportParameters(params: Record<string, any>) {
  const result = { ...params };
  delete result['$count'];
  delete result['$top'];
  delete result['$skip'];
  return result;
}
export function openExportLinkOld(
  path: string,
  params?: Record<string, any>,
): void {
  const url = toRootedURL(path, params);
  window.open(url, '_blank');
}

export async function openExportLink(
  path: string,
  params?: Record<string, any>,
): Promise<void> {
  return new Promise(async resolve => {
    const url = path.replace(process.env.PUBLIC_URL, '');
    try {
      const response = await axios.get(url, {
        responseType: 'blob',
        timeout: undefined,
        params: params,
      });
      const cd = response.headers['content-disposition'];
      const filename: string =
        cd !== undefined ? cd.split('filename=')[1] : 'ExportFile.xlsx';
      fileDownload(response.data, filename);
    } catch {}
    resolve();
  });
}

export const odataUrlPrefix = '/api/odata/v4/';

export type IPathAndQuery = { path: string; search: Record<string, any> };

export function getReturnURL(
  location: H.Location,
  defaultReturnURL: string = '/',
): string {
  try {
    const params = new URLSearchParamsCI(location.search);
    const returnURLKey = 'returnURL';
    const value = params.get(returnURLKey);
    return value ?? defaultReturnURL;
  } catch (error) {
    return defaultReturnURL;
  }
}

/**
 * Pipes content-disposition=attachments download links through client side
 * @param props
 */
export async function downloadFile(props: {
  path: string;
  defaultFileName?: string;
}) {
  const response = await axios.get(props.path, {
    responseType: 'blob',
  });
  const cd = response.headers['content-disposition'];
  const [, filename] = cd?.split('=');
  fileDownload(
    response.data,
    decodeURIComponent(filename ?? props.defaultFileName),
  );
}
