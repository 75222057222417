import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { useLocation } from 'react-router-dom';
import { GetRawValue } from 'app/components/BasicTable/types/FilterParam';

import { AssetsSelectionProps } from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import { PageTopActionsProps } from '../type';

export function AddConsumable(
  props: PageTopActionsProps & { services: number[] },
) {
  const location = useLocation();
  //const authenticatedUser = useSelector(selectAuthenticatedUser);
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const { t } = useTranslation();
  const {
    size,
    text,
    variant,
    icon,
    services,
    disabled,
    compact = true,
  } = props;

  return (
    <TopActionButton
      text={text || (t(translations.AddConsumable) as string)}
      icon={icon || 'plus'}
      startIcon={icon || 'plus'}
      variant={variant || 'ghost'}
      size={size || 'small'}
      disabled={disabled}
      noChangeOnMobile={!compact}
      onClick={() => {
        let selParams = {
          useSidePanel: true,
          actionType: 'OfflineService',
          offTypeIds:
            services.length > 0
              ? services.join(',')
              : GetRawValue(new URLSearchParams(location.search), 'stid'),
        } as AssetsSelectionProps;
        dispatch(
          actions.openSidePanel({
            type: RenderPageType.AssetsSelection,
            props: selParams,
            expanded: false,
          }),
        );
      }}
    />
  );
}
export function AddCoverConsumable(
  props: PageTopActionsProps & {
    services: number[];
    onAddClick: (services: number[]) => void;
  },
) {
  const { t } = useTranslation();
  const {
    size,
    text,
    variant,
    icon,
    services,
    disabled,
    onAddClick,
    compact = true,
  } = props;

  return (
    <TopActionButton
      text={text || (t(translations.AddConsumable) as string)}
      icon={icon || 'plus'}
      startIcon={icon || 'plus'}
      variant={variant || 'ghost'}
      size={size || 'small'}
      disabled={disabled}
      noChangeOnMobile={!compact}
      onClick={() => onAddClick(services)}
    />
  );
}
