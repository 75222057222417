/**
 *
 * Asynchronously loads the component for QuizzesPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const QuizzesPage = lazyLoad(
  () => import('./index'),
  module => module.QuizzesPage,
);
