import Chip from '@material-ui/core/Chip';
import { IconProp } from 'app/AllIcons';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import React from 'react';
import { IPathAndQuery, toRootedURL } from 'utils/url-utils';

export interface RecentItemProps {
  label: React.ReactNode;
  icon?: IconProp;
  link?: string | IPathAndQuery;
  searchTerm?: string;
  onClick?: (searchTerm?: string) => void;
}
export function RecentItem(props: RecentItemProps) {
  const { label, icon, onClick, searchTerm, link } = props;
  const handleClick = React.useCallback(() => {
    !!onClick && onClick(searchTerm);
  }, [onClick, searchTerm]);
  const absolutePath =
    link === undefined
      ? undefined
      : typeof link === 'string'
      ? toRootedURL(link)
      : toRootedURL(
          (link as IPathAndQuery).path,
          (link as IPathAndQuery).search,
        );
  return (
    <Chip
      className={'recentItem'}
      label={label}
      onClick={() => handleClick()}
      component={'a'}
      avatar={<Icon icon={icon || 'magnifying-glass'} />}
      href={absolutePath}
      clickable
    />
  );
}
