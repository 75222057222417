import {
  scheduleImageClassName,
  scheduleServiceContainerClassName,
  scheduleServiceIdAttributeName,
} from './renderServiceLabel';
import { findParentTarget } from './findParentTarget';

/**
 * Locates the service label container click and extracts service id from the click event
 * @param event scheduler click event
 * @returns service id if present
 */
export function getServiceIdFromServiceClickEvent(event: MouseEvent) {
  // check if the click target is a service label rendered in the timeline section
  const containerClassName = `.${scheduleServiceContainerClassName}`;
  const imageClassName = `.${scheduleImageClassName}`;
  var serviceContainer = findParentTarget(event, [
    containerClassName,
    imageClassName,
  ]);
  if (serviceContainer === null) {
    return undefined;
  }

  var serviceIdAttributeValue = serviceContainer.getAttribute(
    scheduleServiceIdAttributeName,
  );
  if (serviceIdAttributeValue === null) {
    return undefined;
  }

  var serviceId = parseInt(serviceIdAttributeValue);
  if (isNaN(serviceId)) {
    return undefined;
  } else {
    return serviceId;
  }
}
