import { useAppSettingsSlice } from 'app/slice';
import {
  selectIsExpanded,
  selectUserProfileSettings,
} from 'app/slice/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'utils/userProfileSettings';
import { useIsMobile } from './useIsMobile';
import { useCustomCompareMemo } from 'use-custom-compare';
interface ExpandedType {
  expanded: boolean;
  expand: (value: boolean) => void;
  expandLocal: (value: boolean) => void;
  toggle: () => void;
}
export function userProfileComparer(
  prev: readonly [UserProfile],
  next: readonly [UserProfile],
) {
  if (!!prev[0] && !!prev[0].savedSettings) {
    if (prev[0].savedSettings.length === next[0].savedSettings.length) {
      let prevExpanded = prev[0].GetSideBarExpanded('SideBarExpanded');
      let nextExpanded = next[0].GetSideBarExpanded('SideBarExpanded');
      if (prevExpanded !== nextExpanded) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  return false;
}
function useExpandedSideBarState(defaultValue?: boolean): ExpandedType {
  const { checkIsMobile } = useIsMobile();
  const isMobile = checkIsMobile();
  const isExpanded = useSelector(selectIsExpanded);
  const userProfileSett = useSelector(selectUserProfileSettings);
  const [expanded, setExpanded] = React.useState<boolean>(
    !!defaultValue ? defaultValue : isExpanded,
  );
  const userProfileSettCompared = useCustomCompareMemo(
    () => {
      return userProfileSett;
    },
    [userProfileSett],
    userProfileComparer,
  );
  const userProfExpanded = React.useMemo<boolean | undefined>(() => {
    return userProfileSettCompared?.GetSideBarExpanded('SideBarExpanded');
  }, [userProfileSettCompared]);
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  React.useEffect(() => {
    if (isMobile) {
      setExpanded(isExpanded);
    } else {
      setExpanded(userProfExpanded ?? true);
    }
  }, [isExpanded, isMobile, userProfExpanded]);

  const expand = React.useCallback(
    (value: boolean) => {
      dispatch(actions.updateExpandedState_Local(value));
      if (!isMobile) {
        dispatch(actions.updateExpandedState(value));
      }
    },
    [actions, dispatch, isMobile],
  );
  const expandLocal = React.useCallback(
    (value: boolean) => {
      dispatch(actions.updateExpandedState_Local(value));
    },
    [actions, dispatch],
  );
  const toggle = React.useCallback(() => {
    dispatch(actions.updateExpandedState_Local(!expanded));
    if (!isMobile) {
      dispatch(actions.updateExpandedState(!expanded));
    }
  }, [actions, dispatch, expanded, isMobile]);

  return { expanded, expand, expandLocal, toggle };
}

export default useExpandedSideBarState;
