/**
 *
 * AssetCatPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { IAssetCatDto } from 'app/pages/CategoriesPage/IAssetCatDto';
import {
  selectGlobalServiceGroupFilter,
  selectIsAuthenticated,
} from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity, Identifiable } from 'types/common';
export type CatUrl = 'base' | 'assetBase' | 'public';
export interface AssetCatPickerProps
  extends Omit<AutocompletePickerProps<IAssetCatDto>, 'loadData'> {
  urlType?: CatUrl;
}

const url = '/api/odata/v4/AssetCat';
const assetBaseUrl = '/api/odata/v4/AssetCat/AssetCatsFilter';
const publicUrl = '/api/odata/v4/AssetCat/PublicAssetCatsFilter';
export const initAssetCatData = (
  initval: string | undefined,
  sourceUrl?: CatUrl,
) => {
  if (initval === undefined) {
    return new Promise<IAssetCatDto[]>((resolve, reject) => {
      resolve([] as IAssetCatDto[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name,GroupId,AssetCatGroupName,ServiceGroups',
      $skip: 0,
      $top: 1,
    };
    let initUrl =
      sourceUrl === 'public'
        ? publicUrl
        : sourceUrl === 'assetBase'
        ? assetBaseUrl
        : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as IAssetCatDto[]);
  }
};

const loadData = (
  predicates: (string | Condition<IAssetCatDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  isAuthenticated?: boolean,
  urlType?: CatUrl,
) => {
  const sourceUrl =
    urlType === 'public' || !isAuthenticated
      ? publicUrl
      : urlType === 'assetBase'
      ? assetBaseUrl
      : url;
  return getAutoCompleteLoadDataFn<IAssetCatDto>({
    url: sourceUrl,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: ['Id', 'Name', 'GroupId', 'AssetCatGroupName', 'ServiceGroups'],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<IAssetCatDto>(
              'ServiceGroups',
              ODataOperators.AnyIn,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });
};

export function AssetCatPicker(props: AssetCatPickerProps) {
  const { predicates, urlType, ...other } = props;
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const isAuth = useSelector(selectIsAuthenticated);
  const innerLoadData = loadData(
    predicates,
    isAuth ? globalServiceGroupFilter || [] : [],
    isAuth,
    urlType,
  );
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={innerLoadData}
      id={props.id || 'AssetCatId'}
      {...other}
    />
  );
}

export const AssetCatByCategoryFilter = (
  value: FilterValueType,
  fieldName: any,
) => {
  if (value !== undefined && value !== null) {
    if (fieldName === 'AssetCatGroupId') {
      return [
        `${new Condition<IAssetCatDto>(
          'GroupId',
          ODataOperators.Equals,
          (value as Identifiable<number>).Id,
        ).toString()}`,
      ];
    }
    if (fieldName === 'ServiceGroupId') {
      return [
        `${new Condition<IAssetCatDto>('ServiceGroups', ODataOperators.AnyIn, [
          (value as Identifiable<number>).Id,
        ]).toString()}`,
      ];
    }
  }
  return [];
};
