import {
  FilledInput as MuiFiledInput,
  FilledInputClassKey,
  //InputBase,
  //InputBaseClassKey,
  InputBaseProps,
  //InputClassKey,
  StandardProps,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

export interface FilledInputProps
  extends StandardProps<InputBaseProps, FilledInputClassKey> {
  className?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  noBorder?: boolean;
  disableUnderline?: boolean;
}
export const FilledInput = React.forwardRef<
  React.HTMLAttributes<HTMLDivElement>,
  FilledInputProps
>(function FilledInput(props, ref) {
  const {
    className,
    fullWidth = false,
    inputComponent = 'input',
    multiline = false,
    type = 'text',
    ...rest
  } = props;
  return (
    <MuiFiledInput
      fullWidth={fullWidth}
      inputComponent={inputComponent}
      className={clsx(className, 'filled')}
      disableUnderline={false}
      multiline={multiline}
      ref={ref}
      type={type}
      {...rest}
    />
  );
});
export default FilledInput;
