import htmlParse from 'html-react-parser';
import { Mention } from '../RichTextEditor/RichEditor';

export const parse = (text: string) => {
  const options = {
    replace: (domNode: any) => {
      if (domNode.attribs?.class === 'MENTION') {
        const attribs = domNode.attribs;
        return (
          <Mention
            element={{
              user: {
                Id: attribs.id,
                Name: attribs.name.split('|').join(' '),
              },
            }}
            children=""
            attributes={{}}
          />
        );
      }
    },
  };
  return htmlParse(text, options);
};
