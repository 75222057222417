/*
 * Asynchronously loads the component for NotAllowedPage
 */

import { lazyLoad } from 'utils/loadable';

export const NotAllowedPage = lazyLoad(
  () => import('./index'),
  module => module.NotAllowed,
);
