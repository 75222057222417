import { PopperProps, Theme, Popper } from '@material-ui/core';
import styled from 'styled-components';
// import { bookitColors } from 'styles/colors/bookitColors';
import { bookitShadows } from 'styles/shadows/bookitShadow';

export const StyledPicker = styled('div')(({ theme }) => ({
  '&.picker-root': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .picker-date-input': {},
    // '& .picker-popper': {
    //   zIndex: 1300,
    //   '& .picker-paper': {
    //     padding: '16px',
    //     maxWidth: '360px',
    //     borderRadius: '12px',
    //     boxShadow: bookitShadows.shadowXL,
    //     // background: bookitColors.grayscale.grayBackground,
    //     '& .picker-header': {
    //       // minHeight: '20px',
    //       '& .picker-header-title': {},
    //     },
    //     '& .picker-content': {
    //       display: 'flex',
    //       flexDirection: 'column',
    //       alignItems: 'center',
    //       gap: '8px',
    //       '& .picker-content-date': {
    //         height: '56px',
    //         width: '100%',
    //         // padding: '12px',
    //       },
    //       '& .picker-buttons': {
    //         display: 'flex',
    //         flexDirection: 'row',
    //         alignItems: 'center',
    //         alignSelf: 'flex-start',
    //         gap: '16px',
    //         // paddingTop: '16px',
    //       },
    //     },
    //   },
    // },
  },
}));

export const PickerPopper = styled(
  ({
    theme,
    children,
    ...other
  }: {
    theme: Theme;
    children: React.ReactNode;
  } & PopperProps) => <Popper {...other}>{children}</Popper>,
)({
  '&.picker-popper': {
    zIndex: 1300,
    '& .picker-paper': {
      padding: '16px',
      maxWidth: '360px',
      borderRadius: '12px',
      boxShadow: bookitShadows.shadowXL,
      // background: bookitColors.grayscale.grayBackground,
      '& .picker-header': {
        // minHeight: '20px',
        '& .picker-header-title': {},
      },
      '& .picker-content': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
        '& .picker-content-date': {
          height: '56px',
          width: '100%',
          // padding: '12px',
        },
        '& .picker-buttons': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'flex-start',
          gap: '16px',
          // paddingTop: '16px',
        },
      },
    },
  },
});

// `
//   z-index: ${props => props.theme.zIndex.modal};
//   height: 100vh;
//   border-left: 1px solid ${bookitColors.grayscale.grayBorders};
//   &.rootopen {
//     width: 100%;
//     transform: unset;
//     &.mobileroot {
//       padding-top: 0px;
//     }
//   }
//   &.rootclose {
//     width: 100vw;
//     &.mobileroot {
//       inset-block: 50px auto;
//       height: calc(100% - 50px);
//       padding-top: 0px;
//     }
//   }
//   @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
//     &.rootclose {
//       width: 100%;
//       min-width: 600px;
//     }
//   }
//   @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
//     &.rootclose {
//       width: 100%;
//       min-width: 370px;
//     }
//   }
//   @media (min-width: ${props => props.theme.breakpoints.values.md}px) {
//     &.rootclose {
//       width: ${props => props.sidePanelWidth}px;
//     }
//   }
//   & .paper {
//     background: ${props => props.theme.palette.common.background};
//     border-radius: 0;
//     max-height: 100vh;
//     height: auto;
//     margin: 0;
//     box-shadow: unset;
//   }
//   @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
//     & .paper {
//       max-width: 100%;
//     }
//   }
// `;
