import { FieldHookConfig, useField } from 'formik';
import { Entity } from 'types/common';
import {
  PriceSheetMethodPicker,
  PriceSheetMethodPickerProps,
} from '../pickers/StaticOptionsPickers/PriceSheetMethodPicker';

export const FormPriceSheetMethodPicker = ({
  onChange,
  ...props
}: FieldHookConfig<Entity<number> | null> &
  Omit<PriceSheetMethodPickerProps, 'onChange'>) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <PriceSheetMethodPicker
        onChange={value => helpers.setValue(value, true)}
        onBlur={field?.onBlur}
        value={field.value}
        variant="filled"
        error={meta?.error !== undefined && meta?.touched}
        helperText={meta.error}
        {...props}
      />
    </>
  );
};
