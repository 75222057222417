import { IViewState } from './Scheduler/slice/types';
import { match } from 'react-router';
import { parseType } from './Scheduler/utils/parseType';
import { dateUtils } from 'utils/date-utils';
import { CalendarPageParams } from './index';
import { presetPeriodToViewLength } from './Scheduler/utils/parseLength';
import {
  getPeriodTypeNameOrUndefined,
  GetRawValue,
  URLSearchParamsCI,
} from 'app/components/BasicTable/types/FilterParam';
import { TimelineGroupByUnionArray } from 'api/odata/generated/enums/TimelineGroupBy';

/** the name of the filter/search/querystring field storing the selected period value */
export const periodFilterFieldName = 'period';
/**
 * the name of the filter/search/querystring field storing the selected period value
 */
export const groupByFilterFieldName = 'groupBy';
/**
 * Parse and sanitize scheduler viewstate provided in the URL
 * @param match current route match
 * @param search route search
 * @returns scheduler view state
 */
export const parseCalendarPageParams = (
  match: match<CalendarPageParams>,
  search: string,
  isMobile: boolean,
  date: Date,
  dashView?: boolean,
): IViewState => {
  const params = new URLSearchParamsCI(search);
  let periodType =
    getPeriodTypeNameOrUndefined(GetRawValue(params, periodFilterFieldName)) ??
    match.params.period;
  const defaultviewType =
    isMobile && !dashView
      ? 'calendar'
      : parseType(match.params.type) ?? 'timeline';
  const defaultviewLength = isMobile
    ? 'day'
    : periodType === undefined
    ? 'week'
    : presetPeriodToViewLength(periodType, defaultviewType);
  const result: IViewState = {
    viewType: defaultviewType,
    search: search,
    preset: null,
    viewLength: defaultviewLength,
    date: dateUtils.formatISO(date),
    groupBy: getTimelineGroupBy(params),
    //...parsePosition(match.params.length, match.params.date),
  };
  return result;
};

const getTimelineGroupBy = function (params: URLSearchParamsCI) {
  const rawValue = GetRawValue(params, groupByFilterFieldName);
  if (rawValue === null || rawValue === undefined) {
    return undefined;
  }
  var result = TimelineGroupByUnionArray.find(
    item => item.toLowerCase() === rawValue.toLowerCase(),
  );
  return result;
};
