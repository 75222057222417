import { dateUtils } from 'utils/date-utils';
import { truncateTime } from './truncateTime';
import { DatePickerValueProps } from '../index';

/**
 * Handles date-only period end date conversions
 * @param props
 * @returns
 */

export const toEndDateDisplayValue = (
  props: DatePickerValueProps & { direction: 'toDisplay' | 'fromDisplay' },
) => {
  if (props.value === null) {
    return null;
  }
  if (props.value === undefined) {
    return null;
  }
  // first truncate the time part if required
  const truncatedDate = truncateTime({
    value: dateUtils.dateOrStringToDate(props.value),
    truncateTime: props.variant === 'date',
  });

  // then either move to one day forward for the underlying value or backward for the display value
  // for example, the actual stored value is '2010-11-12T00:00:00' is displayed as 'November 10th 2010' (note that 10th is inclusive till the midnight)
  const dir = props.direction === 'toDisplay' ? -1 : 1;
  const result = props.endTime
    ? dateUtils.addDays(truncatedDate, dir)
    : truncatedDate;
  return result;
};
