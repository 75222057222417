/**
 *
 * AsyncPicker
 *
 */
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import {
  IRepetitiveOptionsParamsPicker,
  RepetitiveOptionsPicker,
} from '../pickers/StaticOptionsPickers/RepetitiveOptionsPicker';

export const FormRepetitiveOptionsPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> &
  IFieldProps &
  IRepetitiveOptionsParamsPicker) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <RepetitiveOptionsPicker
        name={props.name}
        startDate={props.startDate}
        placeholder={props.placeholder}
        ariaLabel={props.ariaLabel}
        label={label}
        variant="filled"
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        info={props.info}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
      />
    </>
  );
};
