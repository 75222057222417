import React, { ReactNode } from 'react';
import { IAsset } from '../IAsset';
import { AttributeValue } from '../IAssetRow';
import parse from 'html-react-parser';
import { dateUtils } from 'utils/date-utils';

export const DateRenderer = (
  props: AttributeValue<IAsset>,
): React.ReactNode => {
  let date = props.value as Date;
  return <>{dateUtils.shortDateFormat(date)}</>;
};

export const HtmlRenderer = (props: AttributeValue<IAsset>): ReactNode => {
  let html = props.value as string;
  return <>{parse(html)}</>;
};

export function ArrayRenderer<T>(
  callback: (value: T, original: IAsset | undefined) => ReactNode | undefined,
) {
  const mapper = (props: AttributeValue<IAsset>) => {
    if (!!props.value && !!props.value.length) {
      return (
        <ul>
          {props.value.map(v => (
            <li>{callback(v, props.original)}</li>
          ))}
        </ul>
      );
    }
  };
  return mapper;
}
