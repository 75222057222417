import { useFormikContext } from 'formik';
import { Entity } from 'types/common';
import * as React from 'react';
import { GlobalSettingsType } from '../useGlobalSettingsHook';
import { useDispatch } from 'react-redux';
import { newDate } from '../utils';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { ServiceSettingsState } from '../../slice/types';
import { useRepetitiveSlice } from 'app/components/Forms/FormRepetitive/slice';
import { dateUtils } from 'utils/date-utils';
import { CustomDate } from 'types/CustomDate';

export interface StartTimeListenerProps<T> {
  userName: Entity<string> | null;
  equipments: Entity<number>[];
  globalSettings: GlobalSettingsType;
  user: AuthenticatedUser | undefined;
  startTime: Date | string;
  endTime: Date | string;
  initialStart: Date | string;
  initialEnd: Date | string;
  changeHandler: (values: T, changeStatus?: boolean | undefined) => void;
  equipmentSettings: ServiceSettingsState | undefined;
  isEdit: boolean;
  values: T;
}
export function StartTimeListener<T>(props: StartTimeListenerProps<T>) {
  const {
    userName,
    equipments,
    startTime,
    endTime,
    initialStart,
    initialEnd,
    values,
    equipmentSettings,
    changeHandler,
  } = props;
  const [prevStartTime, setPrevStartTime] = React.useState<
    Date | string | undefined
  >(initialStart);
  const [prevEndTime, setPrevEndTime] = React.useState<
    Date | string | undefined
  >(initialEnd);
  const [isStartChanged, setIsStartChanged] = React.useState<
    boolean | undefined
  >(undefined);
  const [isEndChanged, setIsEndChanged] = React.useState<boolean | undefined>(
    undefined,
  );
  const dispatch = useDispatch();
  const { actions: repetitiveActions } = useRepetitiveSlice();
  const [initilized, setInitilized] = React.useState(true);

  const { setFieldTouched, validateForm } = useFormikContext<T>();
  React.useEffect(() => {
    if (!initilized) {
      let start = newDate(startTime);
      let end =
        endTime === null
          ? newDate(
              dateUtils.set(start, {
                hours:
                  start.getHours() +
                  (equipmentSettings?.DefaultReservationDuration || 1),
                minutes: start.getMinutes(),
              }),
            )
          : newDate(endTime);
      //Start Time Changed
      if (!!prevStartTime) {
        let prev = newDate(prevStartTime);
        if (start.getTime() !== prev.getTime()) {
          if (end === null) {
            let dt = newDate(start);
            end = newDate(
              new Date(
                dt.setHours(
                  start.getHours() +
                    (equipmentSettings?.DefaultReservationDuration || 1) +
                    start.getMinutes(),
                ),
              ),
            );
          }
          end = newDate(
            new Date(end.getTime() + start.getTime() - prev.getTime()),
          );
          setTimeout(() => setFieldTouched('EndTime', true, false));

          setTimeout(() => validateForm({ ...values, EndTime: end }));
          setPrevStartTime(start);
          setIsStartChanged(true);
          setIsEndChanged(undefined);
          setPrevEndTime(end);
          dispatch(
            repetitiveActions.setRepetitiveValue({
              fieldKey: 'RecurringEndDate',
              fieldValue: dateUtils.formatISO(
                new CustomDate(dateUtils.addDays(start, 7), 'date'),
              ),
            }),
          );
        } else {
          let prevEnd = newDate(prevEndTime);
          if (end.getTime() !== prevEnd.getTime()) {
            setIsEndChanged(true);
          }
          setPrevEndTime(end);
        }
      }
      if (
        equipments.length > 0 &&
        userName != null &&
        (isStartChanged || isEndChanged)
      ) {
        changeHandler({ ...values, EndTime: end }, false);
        console.log('StartTime Changed');
        setIsStartChanged(undefined);
        setIsEndChanged(undefined);
      }
    }
    setInitilized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  return null;
}
