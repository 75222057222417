import { FormControl, FormLabel, styled } from '@material-ui/core';
import { FieldHookConfig } from 'formik';
import * as React from 'react';
import { Body } from '../Typography';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export interface FormLabelTextProps {
  text?: React.ReactNode;
  id?: string;
}
export function FormLabelText({
  label,
  text,
  html,
  info,
  ...props
}: FieldHookConfig<string> & IFieldProps & FormLabelTextProps) {
  const StyledInfo = styled(InfoIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1),
  }));
  return (
    <FormControl fullWidth>
      {label && (
        <FormLabel>
          <>
            {label} {info && <StyledInfo title={info} />}
          </>
        </FormLabel>
      )}
      {text && (
        <Body bold={true} size="small">
          {text}
        </Body>
      )}
      {html}
    </FormControl>
  );
}
// const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
//   color: theme.palette.common.graySubtitle,
//   fontFamily: `'Lato', sans-serif`,
//   fontStyle: 'normal',
//   fontWeight: 400,
//   fontSize: '12px',
//   lineHeight: '141%',
//   letterSpacing: '0.2px',
//   '.Mui-focused &': {
//     color: theme.palette.common.graySubtitle,
//   },
// }));
