import { httpClient } from 'api/HttpClient';
import { CellRenderer } from 'app/components/BasicTable/BasicTableProps';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import {
  RowActionsMenu,
  RowActionsProps,
} from 'app/components/BasicTable/RowActions';
import { SelectedRowsActionIconButton } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionIconButton';
import { SelectedRowsActionRenderer } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionRenderer';
import { translations } from 'locales/translations';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UnconfigurableTable } from '../../../../components/BasicTable/UnconfigurableTable/UnconfigurableTable';
import { getColumns } from './Columns';
import { IInvoicePayment } from './IInvoicePayment';

export interface PaymentTableProps {
  invoiceId: number;
  isSidePanel: boolean;
  pageActions?: PageActionRenderer[];
  openEditPayment?: (payId: number) => void;
}
const url = '/api/odata/v4/InvoicePayments';
export const PaymentTable = ({
  invoiceId,
  pageActions,
  openEditPayment,
}: PaymentTableProps) => {
  const { t } = useTranslation();
  const columns = useMemo(() => getColumns(t, openEditPayment), [
    openEditPayment,
    t,
  ]);
  const rowActions: CellRenderer<IInvoicePayment>[] = useMemo<
    CellRenderer<IInvoicePayment>[]
  >(
    () => [
      ({ row }) => {
        const items: RowActionsProps[] = [];
        if (!!openEditPayment) {
          items.push({
            onClick: () => {
              openEditPayment(row.original.Id);
            },
            text: t(translations.Edit) as string,
            title: t(translations.EditInvoicePayment) as string,
            icon: 'pencil-alt',
          } as RowActionsProps);
        }

        if (items.length > 0) {
          return (
            <RowActionsMenu
              id={`invoiceRow_RowsActions${row.original.Id}`}
              items={items}
            />
          );
        } else {
          return undefined;
        }
      },
    ],
    [openEditPayment, t],
  );
  const selectedRowActions: SelectedRowsActionRenderer<
    IInvoicePayment
  >[] = useMemo(
    () => [
      (rows, onRefresh) => (
        <SelectedRowsActionIconButton
          title={t(translations.Delete)}
          confirm
          validConfirm
          confirmBody={t(translations.PaymentDeleteConfirm)}
          cancelButtonLabel={t(translations.No)}
          confirmButtonLabel={t(translations.Yes)}
          onRefresh={onRefresh}
          rows={rows}
          action={async () => {
            try {
              await httpClient.post(url + '/DeleteMany', {
                invoiceId,
                ids: rows.map(v => v.Id),
              });
              return {
                SuccessMessages: [t(translations.PaymentsDelete_success)],
                ErrorMessages: [],
                WarningMessages: [],
              };
            } catch {
              return {
                SuccessMessages: [],
                WarningMessages: [],
                ErrorMessages: [t(translations.PaymentsDelete_err)],
              };
            }
          }}
        />
      ),
    ],
    [invoiceId, t],
  );
  return (
    <UnconfigurableTable
      screenName={t(translations.menu_InvoicePayments)}
      columns={columns}
      pageActions={pageActions}
      api={{ path: url, search: { invoiceId } }}
      serviceGroups={[]}
      selectedRowsActions={selectedRowActions}
      useRowSelect
      rowActions={rowActions.length > 0 ? rowActions : undefined}
      searchColumns={[
        'CreatedByDN',
        'CreatedBy',
        'PaymentMethodName',
        'Remarks',
        'Reference',
      ]}
      additionalColumns={['CreatedBy']}
    />
  );
};
