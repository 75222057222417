/**
 *
 * CampusPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { ICampusDto } from 'api/odata/generated/entities/ICampusDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import {
  selectGlobalServiceGroupFilter,
  selectIsAuthenticated,
} from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity, Identifiable } from 'types/common';

export type CampusUrl = 'base' | 'assetBase' | 'public';
export interface CampusPickerProps
  extends Omit<AutocompletePickerProps<ICampusDto>, 'loadData'> {
  urlType?: CampusUrl;
  withoutServiceGroups?: boolean;
}

const url = '/api/odata/v4/Campuses';
const assetCampusUrl = '/api/odata/v4/Campuses/CampusAssetFilter';
const publicCampusUrl = '/api/odata/v4/Campuses/PublicCampusAssetFilter';
export const initCampusData = (
  initval: string | undefined,
  sourceUrl?: CampusUrl,
  withoutServiceGroups?: boolean,
) => {
  if (initval === undefined) {
    return new Promise<ICampusDto[]>((resolve, reject) => {
      resolve([] as ICampusDto[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select:
        withoutServiceGroups === undefined || withoutServiceGroups === false
          ? 'Id,Name,ServiceGroups'
          : 'Id,Name',
      $skip: 0,
      $top: 1,
    };
    let initUrl =
      sourceUrl === 'public'
        ? publicCampusUrl
        : sourceUrl === 'assetBase'
        ? assetCampusUrl
        : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as ICampusDto[]);
  }
};

const loadData = (
  predicates: (string | Condition<ICampusDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  isAuthenticated?: boolean,
  urlType?: CampusUrl,
  withoutServiceGroups?: boolean,
) => {
  const sourceUrl =
    urlType === 'public' || !isAuthenticated
      ? publicCampusUrl
      : urlType === 'assetBase'
      ? assetCampusUrl
      : url;
  return getAutoCompleteLoadDataFn<ICampusDto>({
    url: sourceUrl,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select:
      withoutServiceGroups === undefined || withoutServiceGroups === false
        ? ['Id', 'Name', 'ServiceGroups']
        : ['Id', 'Name'],
    globalServiceGroupFilter:
      (withoutServiceGroups === undefined || withoutServiceGroups === false) &&
      !!globalServiceGroupFilter &&
      globalServiceGroupFilter.length > 0
        ? [
            new Condition<ICampusDto>(
              'ServiceGroups',
              ODataOperators.AnyIn,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });
};

export function CampusPicker(props: CampusPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const isAuth = useSelector(selectIsAuthenticated);
  const innerLoadData = loadData(
    props.predicates,
    isAuth ? globalServiceGroupFilter || [] : [],
    isAuth,
    props.urlType,
    props.withoutServiceGroups,
  );
  return (
    <AutocompletePicker
      size={props.size}
      loadData={innerLoadData}
      id={props.id || 'CampusId'}
      {...props}
    />
  );
}
export const CampusByServiceGroupFilter = (
  value: FilterValueType,
  fieldName: any,
) => {
  if (value !== undefined && value !== null) {
    if (fieldName === 'ServiceGroupId') {
      return [
        `${new Condition<ICampusDto>(
          'ServiceGroups',
          ODataOperators.In,
          (value as Identifiable<number>).Id,
        ).toString()}`,
      ];
    }
  }
  return [];
};
