import { fade, ThemeOptions } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
// import convertLength from 'convert-css-length';
import { grey, green } from '@material-ui/core/colors';
import { bookitColors } from './colors/bookitColors';
import infoColors from './colors/info';
import {
  bodyS,
  boldS,
  bookitTypography,
  buttonM,
  inputLabelXS,
  inputValueS,
  tipsRegular,
} from './typography/bookitTypography';
import {
  MuiButtonOverride,
  MuiIconButtonOverride,
  MuiToggleButtonGroupOverride,
  MuiToggleButtonOverride,
} from './MUIExtend/themeOverrides';
import transitions from '@material-ui/core/styles/transitions';
import {
  COVER_WIDTH,
  DRAWER_MOBILE_WIDTH,
  DRAWER_WIDTH,
} from './constantVariables';
import { box_shadow_s } from './shadows/bookitShadow';

const breakpoints = createBreakpoints({});
// xs: 0,
// sm: 600,
// md: 960,
// lg: 1280,
// xl: 1920
const lato_Light = {
  fontFamily: `'Lato', sans-serif`,
  fontStyle: 'normal',
  fontWeight: 300,
  src: `
    local(''),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Light.woff') format('woff'),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Light.woff2') format('woff2'),
  `,
};
const lato_Regular = {
  fontFamily: `'Lato', sans-serif`,
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local(''),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff') format('woff'),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff2') format('woff2'),
  `,
};
const lato_Bold = {
  fontFamily: `'Lato', sans-serif`,
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local(''),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Bold.woff') format('woff'),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Bold.woff2') format('woff2'),
  `,
};
const lato_Black = {
  fontFamily: `'Lato', sans-serif`,
  fontStyle: 'normal',
  fontWeight: 900,
  src: `
    local(''),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Black.woff') format('woff'),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Black.woff2') format('woff2'),
  `,
};
const lato = {
  fontFamily: `'Lato', sans-serif`,
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local(''),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff') format('woff'),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff2') format('woff2'),
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
//const convert = convertLength('16px');
export type BaseTheme = 'default' | 'animals' | 'bookit' | 'dark' | undefined;
// customizes the mui theme defaults/overrides
// colors: https://material-ui.com/customization/color/#color
export const DefaultThemeSettings: ThemeOptions = {
  typography: {
    fontFamily: `'Lato', sans-serif`,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [lato],
      },
    },
  },
};

export const BookitThemeSettings: ThemeOptions = {
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  palette: {
    primary: {
      dark: bookitColors.primary.dark,
      main: bookitColors.primary.regular,
      light: bookitColors.primary.background,
      textHover: bookitColors.primary.hover,
      bgHover: bookitColors.primary.bgHover,
      contrastText: bookitColors.base.white,
    },

    secondary: {
      dark: '#BFA44A',
      main: '#FFDB63',
      light: '#F4ECCE',
      textHover: '#E0BC40',
      bgHover: '#FFEBA9',
      contrastText: '#000',
    },
    info: {
      dark: infoColors.colorInfoDark,
      main: infoColors.colorInfoRegular,
      light: infoColors.colorInfoBg,
      textHover: infoColors.colorInfoBgHover,
      bgHover: infoColors.colorInfoHover,
      contrastText: bookitColors.base.white,
    },
    accent: {
      dark: bookitColors.secondary.violet.dark,
      main: bookitColors.secondary.violet.regular,
      light: bookitColors.secondary.violet.background,
      bgHover: bookitColors.secondary.violet.bgHover,
      textHover: bookitColors.secondary.violet.hover,
      contrastText: bookitColors.base.white,
    },
    danger: {
      dark: bookitColors.danger.dark,
      main: bookitColors.danger.regular,
      light: bookitColors.danger.background,
      textHover: bookitColors.danger.hover,
      bgHover: bookitColors.danger.bgHover,
      contrastText: bookitColors.base.white,
    },
    success: {
      dark: bookitColors.success.dark,
      main: bookitColors.success.regular,
      light: bookitColors.success.background,
      textHover: bookitColors.success.hover,
      bgHover: bookitColors.success.bgHover,
      contrastText: bookitColors.base.white,
    },
    error: {
      dark: bookitColors.danger.dark,
      main: bookitColors.danger.regular,
      light: bookitColors.danger.background,
      bgHover: bookitColors.danger.bgHover,
      textHover: bookitColors.danger.hover,
      contrastText: bookitColors.base.white,
    },
    default: {
      dark: bookitColors.grayscale.blackComponents,
      main: bookitColors.grayscale.graybgComponents,
      light: bookitColors.grayscale.grayBackground,
      bgHover: bookitColors.base.white,
      textHover: bookitColors.grayscale.graySubtitles,
      contrastText: bookitColors.base.black,
    },
    filters: {
      dark: bookitColors.primary.dark,
      main: bookitColors.grayscale.graySelection,
      textHover: bookitColors.primary.dark,
      bgHover: bookitColors.primary.bgHover,
      light: bookitColors.grayscale.grayComponents,
      contrastText: bookitColors.grayscale.blackRegular,
    },
    common: {
      black: bookitColors.base.black,
      blackRegular: bookitColors.grayscale.blackRegular,
      blackComponent: bookitColors.grayscale.blackComponents,
      graySubtitle: bookitColors.grayscale.graySubtitles,
      grayComponents: bookitColors.grayscale.grayComponents,
      grayComponentsLight: bookitColors.grayscale.grayComponentsLight,
      background: bookitColors.grayscale.grayBackground,
      backgroundHover: bookitColors.grayscale.graybgComponents,
      grayBorders: bookitColors.grayscale.grayBorders,
      grayDividers: bookitColors.grayscale.graySelection,
      white: bookitColors.base.white,
    },
    background: {
      default: bookitColors.grayscale.grayBackground,
      paper: bookitColors.base.white,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    h1: bookitTypography.h1,
    h2: bookitTypography.h2,
    h3: bookitTypography.h3,
    h4: bookitTypography.h4,
    bodyL: bookitTypography.bodyL,
    bodyM: bookitTypography.bodyM,
    bodyS: bookitTypography.bodyS,
    boldL: bookitTypography.boldL,
    boldM: bookitTypography.boldM,
    boldS: bookitTypography.boldS,
    buttonM: bookitTypography.buttonM,
    buttonS: bookitTypography.buttonS,
    captionRegular: bookitTypography.captionRegular,
    captionBold: bookitTypography.captionBold,

    subtitle1: bookitTypography.subtitle1,
    subtitle2: bookitTypography.subtitle2,
    tipsRegular: bookitTypography.tipsRegular,
    tipsBold: bookitTypography.tipsBold,
    inputLabelM: bookitTypography.inputLabelM,
    inputLabelXS: bookitTypography.inputLabelXS,
    inputValueM: bookitTypography.inputValueM,
    inputValueS: bookitTypography.inputValueS,
    uppercase: bookitTypography.uppercase,
  },
  overrides: {
    MuiTypography: {
      root: {
        '&.variantH1': {
          ...bookitTypography.h1,
        },
        '&.variantH2': {
          ...bookitTypography.h2,
        },
        '&.variantH3': {
          ...bookitTypography.h3,
        },
        '&.variantH4': {
          ...bookitTypography.h4,
        },
        '&.variantSubtitle1': {
          ...bookitTypography.subtitle1,
        },
        '&.variantSubtitle2': {
          ...bookitTypography.subtitle2,
        },
        '&.variantBodyl': {
          ...bookitTypography.bodyL,
        },
        '&.variantBodym': {
          ...bookitTypography.bodyM,
        },
        '&.variantBodys': {
          ...bookitTypography.bodyS,
        },
        '&.variantBoldl': {
          ...bookitTypography.boldL,
        },
        '&.variantBoldm': {
          ...bookitTypography.boldM,
        },
        '&.variantBolds': {
          ...bookitTypography.boldS,
        },
        '&.variantCaptionbold': {
          ...bookitTypography.captionBold,
        },
        '&.variantCaptionregular': {
          ...bookitTypography.captionRegular,
        },
        '&.variantUppercase': {
          ...bookitTypography.uppercase,
        },
        '&.variantTipsbold': {
          ...bookitTypography.tipsBold,
        },
        '&.variantTipsregular': {
          ...bookitTypography.tipsRegular,
        },
        '&.variantInputlabelm': {
          ...bookitTypography.inputLabelM,
        },
        '&.variantInputlabelxs': {
          ...bookitTypography.inputLabelXS,
        },
        '&.variantInputvaluem': {
          ...bookitTypography.inputValueM,
        },
        '&.variantInputvalues': {
          ...bookitTypography.inputValueS,
        },
        '&.variantButtonm': {
          ...bookitTypography.buttonM,
        },
        '&.variantButtons': {
          ...bookitTypography.buttonS,
        },
        '&.italic': {
          fontStyle: 'italic',
        },
      },
      colorError: {
        color: bookitColors.danger.components,
      },
      colorPrimary: {
        color: bookitColors.grayscale.blackRegular,
      },
      colorSecondary: { color: bookitColors.grayscale.grayComponents },
      colorTextPrimary: { color: bookitColors.primary.regular },
      colorTextSecondary: { color: bookitColors.success.regular },
    },
    MuiAppBar: {
      colorDefault: { backgroundColor: bookitColors.grayscale.grayBackground },
    },
    MuiLink: {
      root: {
        '&:hover': {
          textDecoration: 'underline',
        },
        '&:focus': {
          textDecoration: 'underline',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [lato_Light, lato_Regular, lato_Bold, lato_Black],
        ':root': {
          '--drawer-width': 240,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiInputBase: {
      root: {
        ...inputValueS,
        color: bookitColors.grayscale.blackRegular,
      },
      adornedStart: {
        paddingLeft: 12,
      },
      adornedEnd: {
        paddingRight: 12,
      },
      input: {
        '.textTable &': {
          minWidth: '80px',
        },
      },
      // marginDense: {
      //   width: '100px',
      // }
    },
    MuiFilledInput: {
      root: {
        border: `1px solid ${bookitColors.grayscale.grayBorders}`,
        backgroundColor: bookitColors.base.white,
        borderRadius: 8,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        '&:hover': {
          backgroundColor: bookitColors.base.white,
          '@media (hover: none)': {
            backgroundColor: 'rgba(255, 255, 255, 0.90)',
          },
        },
        '&$focused': {
          backgroundColor: bookitColors.base.white,
        },
        '&$disabled': {
          backgroundColor: bookitColors.grayscale.grayBackground,
        },
      },
      /* Styles applied to the root element if color secondary. */
      colorSecondary: {
        '&$underline:after': {
          borderBottomColor: bookitColors.danger.regular,
        },
      },
      underline: {
        '&:after': {
          borderBottom: `1px solid ${bookitColors.primary.regular}`,
          left: 0,
          bottom: -1,
          // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
          content: '""',
          position: 'absolute',
          right: 0,
          transform: 'scaleX(0)',
          pointerEvents: 'none', // Transparent to the hover style.
          transition: transitions.create('transform', {
            duration: transitions.duration.shorter,
            easing: transitions.easing.easeOut,
          }),
          marginInline: 8,
        },
        '&$focused:after': {
          transform: 'scaleX(1)',
        },
        '&$error:after': {
          borderBottomColor: bookitColors.danger.regular,
          transform: 'scaleX(1)', // error is always underlined in red
        },
        '&:before': {
          borderBottom: 'none',
          left: 0,
          bottom: -1,
          // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
          content: '"\\00a0"',
          // content: '""',
          position: 'absolute',
          right: 0,
          transition: transitions.create('border-bottom-color', {
            duration: transitions.duration.shorter,
          }),
          pointerEvents: 'none', // Transparent to the hover style.
          marginInline: 8,
        },
        '&:hover:before': {
          borderBottom: 'none',
        },
        '&$disabled:before': {
          borderBottomStyle: 'none',
        },
        '&$focused:before': {
          borderBottom: `1px solid ${bookitColors.primary.regular}`,
          transform: 'scaleX(1)',
        },
        '&$error:before': {
          borderBottom: `1px solid ${bookitColors.danger.regular}`,
          transform: 'scaleX(1)',
        },
      },
      /* Pseudo-class applied to the root element if the component is focused. */
      focused: {},

      /* Pseudo-class applied to the root element if `disabled={true}`. */
      disabled: {},

      /* Styles applied to the root element if `startAdornment` is provided. */
      adornedStart: {
        paddingLeft: 12,
      },

      /* Styles applied to the root element if `endAdornment` is provided. */
      adornedEnd: {
        paddingRight: 12,
      },

      /* Pseudo-class applied to the root element if `error={true}`. */
      error: {},

      /* Styles applied to the `input` element if `margin="dense"`. */
      marginDense: {},

      /* Styles applied to the root element if `multiline={true}`. */
      multiline: {
        padding: '27px 12px 10px',
        '&$marginDense': {
          paddingTop: 23,
          paddingBottom: 6,
        },
      },
      // input: {
      //   height: '20px',
      //   padding: '22px 12px 4px',
      // },
      // inputMarginDense: {
      //   paddingTop: '16px',
      //   paddingBottom: '6px',
      // },
      /* Styles applied to the `input` element. */
      input: {
        height: '20px',
        padding: '26px 12px 8px',
        '&:-webkit-autofill': {
          // WebkitBoxShadow:
          //   theme.palette.type === 'light' ? null : '0 0 0 100px #266798 inset',
          // WebkitTextFillColor: theme.palette.type === 'light' ? null : '#fff',
          // caretColor: theme.palette.type === 'light' ? null : '#fff',
          borderTopLeftRadius: 'inherit',
          borderTopRightRadius: 'inherit',
        },
        '.inputSizeLarge &': {
          paddingTop: 22,
          paddingBottom: 4,
        },
        '.inputSizeLarge.noLabel &': {
          paddingTop: 14,
          paddingBottom: 12,
        },
        '.inputSizeMedium &': {
          paddingTop: 9,
          paddingBottom: 9,
        },
        '.inputSizeSmall &': {
          paddingTop: 5,
          paddingBottom: 5,
        },
      },

      /* Styles applied to the `input` element if `margin="dense"`. */
      inputMarginDense: {
        paddingTop: 22,
        paddingBottom: 4,
      },

      /* Styles applied to the `input` if in `<FormControl hiddenLabel />`. */
      inputHiddenLabel: {
        paddingTop: 17,
        paddingBottom: 17,
        '&$inputMarginDense': {
          paddingTop: 9,
          paddingBottom: 9,
        },
      },

      /* Styles applied to the `input` element if `multiline={true}`. */
      inputMultiline: {
        padding: 0,
      },

      /* Styles applied to the `input` element if `startAdornment` is provided. */
      inputAdornedStart: {
        paddingLeft: 0,
      },

      /* Styles applied to the `input` element if `endAdornment` is provided. */
      inputAdornedEnd: {
        paddingRight: 0,
      },
    },
    MuiFormHelperText: {
      /* Styles applied to the root element. */
      root: {
        display: 'flex',
        paddingLeft: '4px',
        alignItems: 'center',
        gap: 0,
        alignSelf: 'stretch',
        color: bookitColors.grayscale.blackRegular,
        ...tipsRegular,
        textAlign: 'left',
        marginTop: 0,
        margin: 0,
        '&$disabled': {
          color: bookitColors.grayscale.graySelection,
        },
        '&$error': {
          color: bookitColors.danger.regular,
        },
        '&.boxed': {
          gap: 0,
        },
        '&.shiftRight': {
          gap: 28,
        },
        // '&.separate-picker': {
        //   position: 'absolute',
        //   top: 0,
        //   left: 0,
        //   '&.inputSizeLarge': {
        //     transform: 'translate(0px, 54px) scale(1)',
        //   },
        //   '&.inputSizeMedium': {
        //     transform: 'translate(0px, 46px) scale(1)',
        //   },
        //   '&.inputSizeSmall': {
        //     transform: 'translate(0px, 38px) scale(1)',
        //   },
        // },
        // empty helperText results in zero element height. So when field is validated the appearance of the
        // error message in the helperText causes the form element to increase in height and forces a redraw
        // empty char \00a0 causes the FormHelperText to always occupy one line height and prevents reflows
        '&::before': {
          content: '"\\00a0"',
        },
        // marginLeft: '6px',
        // ...tipsRegular,
      },

      /* Pseudo-class applied to the root element if `error={true}`. */
      error: {},

      /* Pseudo-class applied to the root element if `disabled={true}`. */
      disabled: {},

      /* Styles applied to the root element if `margin="dense"`. */
      marginDense: {
        marginTop: 4,
      },

      /* Styles applied to the root element if `variant="filled"` or `variant="outlined"`. */
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },

      /* Pseudo-class applied to the root element if `focused={true}`. */
      focused: {},

      /* Pseudo-class applied to the root element if `filled={true}`. */
      filled: {},

      /* Pseudo-class applied to the root element if `required={true}`. */
      required: {},
    },
    MuiFormGroup: {
      /* Styles applied to the root element. */
      root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: 8,
        '&.boxed': {
          display: 'flex',
          height: 'auto',
          minHeight: '56px',
          //justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          alignSelf: 'stretch',
          padding: '16px',
          borderRadius: '8px',
          border: `1px solid ${bookitColors.grayscale.grayBorders}`,
          background: bookitColors.base.white,
          '&.toggleSizeSmall': {
            minHeight: '48px',
            padding: '12px',
          },
          '&.boxedError': {
            border: `1px solid ${bookitColors.danger.borders}`,
          },
          '&.boxedChecked': {
            border: `1px solid ${bookitColors.primary.borders}`,
            background: bookitColors.primary.background,
            //   '&.boxedSecondary': {
            //     border: `1px solid ${bookitColors.success.borders}`,
            //     background: bookitColors.success.background,
            //   },
            '&.boxedError': {
              border: `1px solid ${bookitColors.danger.borders}`,
              background: bookitColors.danger.background,
            },
          },
        },
        '&.horizontal': {
          gap: '16px',
        },
        '&.vertical': {
          gap: '16px',
          alignItems: 'flex-start',
          width: '100%',
        },
      },
      /* Styles applied to the root element if `row={true}`. */
      row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    MuiFormLabel: {
      root: {
        color: bookitColors.grayscale.graySubtitles,
        ...bodyS,
        '&$focused': {
          color: bookitColors.grayscale.graySubtitles,
        },
      },
      // focused: {
      //   color: bookitColors.grayscale.graySubtitles,
      // },
      asterisk: {
        color: bookitColors.danger.regular,
      },
    },
    MuiInputLabel: {
      root: {
        color: bookitColors.grayscale.grayComponents,
        ...inputValueS,
        display: 'block',
        transformOrigin: 'top left',
        '&.datetime-picker': {
          position: 'relative',
          transform: 'unset',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: '13ch',
          display: 'inline-block',
        },
      },
      /* Pseudo-class applied to the root element if `focused={true}`. */
      focused: {},

      /* Pseudo-class applied to the root element if `disabled={true}`. */
      disabled: {},

      /* Pseudo-class applied to the root element if `error={true}`. */
      error: {},

      /* Pseudo-class applied to the root element if `required={true}`. */
      required: {},

      /* Pseudo-class applied to the asterisk element. */
      asterisk: {},
      /* Styles applied to the root element if the component is a descendant of `FormControl`. */
      formControl: {
        position: 'absolute',
        left: 0,
        top: 0,
        // slight alteration to spec spacing to match visual spec result
        transform: 'translate(0, 24px) scale(1)',
        '&.date-dropdown-picker': {
          transform: 'translate(0px, -20px) scale(1)',
        },
        '&.time-options-picker': {
          transform: 'translate(0px, -26px) scale(1)',
        },
        '&.noLabelInline': {
          transform: 'translate(0px, 0px) scale(1)',
        },
      },
      /* Styles applied to the root element if `margin="dense"`. */
      marginDense: {
        // Compensation for the `Input.inputDense` style.
        transform: 'translate(0, 21px) scale(1)',
      },
      /* Styles applied to the `input` element if `disableAnimation={false}`. */
      animated: {
        transition: transitions.create(['color', 'transform'], {
          duration: transitions.duration.shorter,
          easing: transitions.easing.easeOut,
        }),
      },
      /* Styles applied to the `input` element if `shrink={true}`. */
      shrink: {
        transform: 'translate(0, -2.5px) scale(1.1)',
        //transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        ...inputLabelXS,
      },
      /* Styles applied to the root element if `variant="filled"`. */
      filled: {
        // Chrome's autofill feature gives the input field a yellow background.
        // Since the input field is behind the label in the HTML tree,
        // the input field is drawn last and hides the label with an opaque background color.
        // zIndex: 1 will raise the label above opaque background-colors of input.
        zIndex: 1,
        pointerEvents: 'none',
        height: '16px',
        transform: 'translate(12px, 18px) scale(1)',
        '&.inputSizeLarge': {
          transform: 'translate(12px, 14px) scale(1)',
          '&.separate-picker': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.date-dropdown-picker': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.time-options-picker': {
            transform: 'translate(0px, -26px) scale(1)',
          },
        },
        '&.inputSizeMedium': {
          transform: 'translate(12px, 10px) scale(1)',
          '&.separate-picker': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.date-dropdown-picker': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.time-options-picker': {
            transform: 'translate(0px, -26px) scale(1)',
          },
        },
        '&.inputSizeSmall': {
          transform: 'translate(12px, 6px) scale(1)',
          '&.separate-picker,': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.date-dropdown-picker': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.time-options-picker': {
            transform: 'translate(0px, -26px) scale(1)',
          },
        },
        '&$marginDense': {
          transform: 'translate(12px, 8px) scale(1)',
        },
        '&$shrink': {
          // letterSpacing: '0.2px',
          ...inputLabelXS,
          '&.inputSizeLarge': {
            transform: 'translate(12px, 6px) scale(1)',
          },
          '&.inputSizeMedium': {
            transform: 'translate(12px, -18px) scale(1)',
          },
          '&.inputSizeSmall': {
            transform: 'translate(12px, -18px) scale(1)',
          },
          transform: 'translate(12px, 8px) scale(1)',
          '&$marginDense': {
            transform: 'translate(12px, 4px) scale(0.75)',
          },
        },
        '&.datetime-picker': {
          transform: 'unset',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: '13ch',
          display: 'inline-block',
        },
        // transform: 'translate(12px, 20px) scale(1)',
        // '&$marginDense': {
        //   transform: 'translate(12px, 17px) scale(1)',
        // },
        // '&$shrink': {
        //   transform: 'translate(12px, 10px) scale(0.75)',
        //   '&$marginDense': {
        //     transform: 'translate(12px, 7px) scale(0.75)',
        //   },
        // },
      },
      /* Styles applied to the root element if `variant="outlined"`. */
      outlined: {
        // see comment above on filled.zIndex
        zIndex: 1,
        pointerEvents: 'none',
        transform: 'translate(14px, 20px) scale(1)',
        '&$marginDense': {
          transform: 'translate(14px, 12px) scale(1)',
        },
        '&$shrink': {
          transform: 'translate(14px, -6px) scale(0.75)',
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        cursor: 'pointer',
        '& svg': {
          width: '16px',
          height: '16px',
        },
      },
      positionEnd: {
        '&.date-picker': {
          marginLeft: 0,
        },
      },
      filled: {
        '&$positionStart:not($hiddenLabel)': {
          marginTop: 0,
        },
        width: '18px',
        height: '20px',
        cursor: 'pointer',
        '& svg': {
          height: '16px',
          width: '16px',
        },
      },
      marginDense: {
        '& svg': {
          fontSize: '0.8rem',
          cursor: 'pointer',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        cursor: 'auto',
      },
      fontSizeSmall: {
        fontSize: '0.8rem',
      },
    },
    MuiListItem: {
      root: {
        '&.assetListItemLink': {
          display: 'flex',
          width: '100%',
          padding: '4px 8px',
          alignItems: 'center',
          gap: '12px',

          borderRadius: '4px',
          background: bookitColors.base.white,
          '&:hover, &:focus': {
            backgroundColor: bookitColors.grayscale.grayBackground,
          },
          '&:active': {
            backgroundColor: bookitColors.grayscale.graybgComponents,
          },
        },
      },
    },
    MuiListItemText: {
      root: {
        '.assetListItemLink &': {
          display: 'flex',
          alignItems: 'flex-start',
          gap: '8px',
          flex: '1 0 0',
          margin: 0,
        },
      },
      primary: {
        '.assetListItemLink &': {
          display: 'inline-block',
          maxWidth: '50%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          ...boldS,
        },
      },
      secondary: {
        '.assetListItemLink &': {
          display: 'flex',
          height: '20px',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: '1 0 0',
          color: bookitColors.grayscale.blackRegular,
          ...bodyS,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 20,
        '.assetListItemLink &': {
          display: 'flex',
          width: '32px',
          height: '32px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          flexShrink: 0,
          borderRadius: '4px',
        },
      },
    },
    MuiAvatar: {
      root: {
        '.assetListItemLink &': {
          color: bookitColors.grayscale.grayComponentsLight,
          backgroundColor: bookitColors.grayscale.graySelection,
          width: '32px',
          height: '32px',
          borderRadius: '4px',
        },
      },
    },
    MuiTableContainer: {
      root: {
        overflowX: 'unset',
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
      },
    },
    MuiTable: {
      root: {
        //flex test -----
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // flex: '1 1 auto',
        //---------------
        padding: 0,
        borderRadius: '8px',
        boxShadow: 'inset 0px 0px 1px 1px #e0e5ec',
        [breakpoints.down('md')]: {
          display: 'block',
          overflowX: 'auto',
          height: '100%',
        },
      },
    },
    MuiTableHead: {
      root: {
        //flex test -----
        // display: 'flex',
        // flexFlow: 'row nowrap',
        // alignItems: 'flex-start',
        //width: 'inherit',
        //---------------
        padding: 0,
      },
    },
    MuiTableBody: {
      root: {
        //flex test -----
        // display: 'flex',
        // flexFlow: 'row wrap',
        // width: 'inherit',
        //---------------
        padding: 0,
      },
    },
    MuiTableRow: {
      root: {
        //flex test -----
        // display: 'flex',
        // flex: '1 1 auto',
        // width: 'inherit',
        //---------------
        '& td:first-child ': {
          boxShadow: '0px 1px 0px 0px #e0e5ec',
        },
        '& th:first-child ': {
          boxShadow: '0px 1px 0px 0px #e0e5ec',
        },
      },
    },
    MuiTableCell: {
      root: {
        //flex test -----
        // display: 'flex',
        // flexFlow: 'row nowrap',
        // flexGrow: 1,
        // flexBasis: 0,
        //---------------
        //height: '32px',
        ...bodyS,
        boxShadow: '-1px 1px 0px #e0e5ec',
        borderBottom: 'unset',
        height: '56px',
        color: bookitColors.grayscale.blackRegular,
        [breakpoints.down('md')]: {
          whiteSpace: 'nowrap',
        },
      },
      head: {
        lineHeight: '164%',
        fontWeight: 'bold',
        padding: '4px 12px',
        gap: '10px',
        whiteSpace: 'nowrap',
      },
      body: {
        fontWeight: 'normal',
        lineHeight: '141%',
        padding: '8px 12px',
        gap: '10px',
        '& > span a': {
          color: bookitColors.primary.regular, // accentColors.colorAccentRegular,
        },
        '& > span > button': {
          color: bookitColors.primary.regular, //accentColors.colorAccentRegular,
        },
        '& > a': {
          color: bookitColors.primary.regular, // accentColors.colorAccentRegular,
        },
        '& > button': {
          color: bookitColors.primary.regular, // accentColors.colorAccentRegular,
        },
        // '& > span': {
        //   display: 'inline-block',
        //   whiteSpace: 'break-spaces',
        //   maxWidth: '25ch',
        //   minWidth: '6ch',
        //   //overflow: 'hidden',
        //   //textOverflow: 'ellipsis',
        // },
      },
      sizeSmall: {
        height: '40px',
        padding: '0px 12px',
        gap: '10px',
      },
      footer: {
        lineHeight: '164%',
        fontWeight: 'bold',
        padding: '12px',
      },
    },
    MuiTableSortLabel: {
      root: {
        cursor: 'pointer',
        '&$active': {
          // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
          '&& $icon': {
            color: bookitColors.grayscale.blackRegular,
          },
        },
        '&:focus': {
          '&& $icon': {
            fontSize: 16,
            opacity: 0.5,
          },
        },
      },
      icon: {
        fontSize: '12px',
        cursor: 'pointer',
        color: bookitColors.grayscale.blackRegular,
      },
    },
    MuiTablePagination: {
      root: {
        fontSize: '14px',
        color: bookitColors.grayscale.blackRegular,
      },
      caption: {
        fontSize: '14px', // convert('14px', 'em'),
        fontWeight: 'bold',
        letterSpacing: '0.1px',
        lineHeight: '151%',
      },
      spacer: {
        flex: 'none',
      },
      input: {
        marginRight: 'auto',
        marginLeft: 0,
      },
      selectRoot: {
        fontWeight: 'bold',
      },
      select: {
        '&:focus': {
          border: `1px solid ${bookitColors.primary.hover}`,
          background: bookitColors.primary.background,
          borderRadius: '6px',
          fontWeight: 'bold',
          color: bookitColors.primary.dark,
        },
      },
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        gap: '16px',
        fontWeight: 'bold',
        fontSize: '14px',
        minHeight: '40px',
      },
      menuItem: {
        padding: '10px 16px',
        gap: '12px',
        fontSize: '14px',
        color: bookitColors.grayscale.blackRegular,
      },
    },
    MuiTextField: {
      root: {
        '&.startIcon': {
          '& > label': {
            marginLeft: '24px',
          },
        },
        // '&.textTable': {
        //   minWidth: '80px',
        // },
      },
    },
    MuiChip: {
      root: {
        '&.recentItem': {
          display: 'flex',
          height: '32px',
          padding: '0px 8px',
          alignItems: 'center',
          gap: '12px',
          flexShrink: 0,
          justifyContent: 'flex-start',
          ...bodyS,
          borderRadius: '4px',
          background: bookitColors.base.white,
          '& [class*="MuiChip-avatar"]': {
            margin: 0,
            width: 16,
            height: 16,
            color: bookitColors.grayscale.grayComponents,
          },
          '&$disabled': {
            color: bookitColors.grayscale.grayComponentsLight,
            opacity: 1,
          },
          '&:hover, &:focus': {
            backgroundColor: bookitColors.grayscale.grayBackground,
          },
          '&:active': {
            backgroundColor: bookitColors.grayscale.graybgComponents,
            boxShadow: 'none',
          },
          '& [class*="MuiChip-label"]': {
            flex: '1 0 0',
            padding: 0,
          },
        },
      },
      clickable: {
        '&:focus': {
          backgroundColor: bookitColors.grayscale.grayComponentsLight,
          color: bookitColors.base.black,
        },
        '&:hover': {
          backgroundColor: bookitColors.grayscale.grayComponentsLight,
          color: bookitColors.base.black,
        },
      },
    },
    MuiButton: MuiButtonOverride,
    MuiIconButton: MuiIconButtonOverride,
    MuiToggleButtonGroup: MuiToggleButtonGroupOverride,
    MuiToggleButton: MuiToggleButtonOverride,
    MuiSwitch: {
      //TO DO: set a padding: '0px' after Toggle component will replace a Switch component
      /* Styles applied to the root element. */
      root: {
        display: 'inline-flex',
        width: 36,
        height: 20,
        overflow: 'hidden',
        padding: '0px',
        boxSizing: 'border-box',
        position: 'relative',
        flexShrink: 0,
        zIndex: 0,
        // Reset the stacking context.
        verticalAlign: 'middle',
        // For correct alignment with the text.
        '@media print': {
          colorAdjust: 'exact',
        },
        // '&>[class*="PrivateSwitchBase-root"]': {
        //   padding: 2,
        // },
      },
      /* Styles applied to the internal `SwitchBase` component's `root` class. */
      switchBase: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        // Render above the focus ripple.
        color: bookitColors.grayscale.grayComponents,
        transition: transitions.create(['left', 'transform'], {
          duration: transitions.duration.shortest,
        }),
        padding: '2px',
        border: 0,
        '&$checked': {
          transform: 'translateX(16px)',
        },
        '&$disabled': {
          color: bookitColors.grayscale.grayComponentsLight,
        },
        '&$checked + $track': {
          opacity: 'unset',
        },
        '&$disabled + $track': {
          opacity: 'unset',
        },
      },
      /* Pseudo-class applied to the internal `SwitchBase` component's `checked` class. */
      checked: {},

      /* Pseudo-class applied to the internal SwitchBase component's disabled class. */
      disabled: {},

      /* Styles applied to the internal SwitchBase component's input element. */
      input: {
        left: '-100%',
        width: '300%',
      },
      /* Styles applied to the internal SwitchBase component's root element if `color="primary"`. */
      colorPrimary: {
        '&$checked': {
          color: bookitColors.primary.components,
          '&:hover': {
            //backgroundColor: bookitColors.primary.componentsLight,
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.grayscale.graySelection,
          //backgroundColor: bookitColors.grayscale.graySelection,
        },
        '&$checked + $track': {
          backgroundColor: bookitColors.primary.components,
        },
        '&.Mui-checked.Mui-disabled + $track': {
          backgroundColor: bookitColors.primary.disabled,
        },
        '&$disabled + $track': {
          backgroundColor: bookitColors.grayscale.graySelection,
        },
      },
      /* Styles applied to the internal SwitchBase component's root element if `color="secondary"`. */
      colorSecondary: {
        '&$checked': {
          color: bookitColors.danger.regular,
          '&:hover': {
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.danger.disabled,
        },
        '&$checked + $track': {
          backgroundColor: bookitColors.danger.regular,
        },
        '&.Mui-checked.Mui-disabled + $track': {
          backgroundColor: bookitColors.danger.disabled,
        },
        '&$disabled + $track': {
          backgroundColor: bookitColors.grayscale.graySelection,
          border: `1px solid ${bookitColors.danger.borders}`,
        },
      },
      /* Styles applied to the root element if `size="small"`. */
      sizeSmall: {
        width: 36,
        height: 20,
        padding: 0,
        '& $thumb': {
          width: 16,
          height: 16,
        },
        '& $switchBase': {
          padding: 2,
          '&$checked': {
            transform: 'translateX(16px)',
          },
        },
      },
      /* Styles used to create the thumb passed to the internal `SwitchBase` component `icon` prop. */
      thumb: {
        backgroundColor: bookitColors.base.white,
        borderRadius: '8px',
        height: '16px',
        position: 'relative',
        width: '16px',
        boxShadow: box_shadow_s,
      },
      /* Styles applied to the track element. */
      track: {
        borderRadius: '100px',
        padding: '0px',
        height: '100%',
        width: '100%',
        zIndex: -1,
        transition: 'unset',
        backgroundColor: bookitColors.grayscale.grayComponentsLight,
        opacity: 'unset',
      },
    },
    MuiFormControl: {
      /* Styles applied to the root element. */
      root: {
        display: 'inline-flex',
        flexDirection: 'column',
        position: 'relative',
        // Reset fieldset default style.
        minWidth: 0,
        padding: 0,
        margin: 0,
        border: 0,
        verticalAlign: 'top', // Fix alignment issue on Safari.

        minHeight: '20px',
        // alignItems: 'center',
        gap: '8px',
        flexShrink: 0,
        '&.boxed': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '8px',
        },
        '&.inputSizeMedium.withLabel': {
          marginTop: '16px',
        },
        '&.inputSizeSmall.withLabel': {
          marginTop: '16px',
        },
        '&.separate-picker': {
          // flexDirection: 'row',
          // flexShrink: 1,
          // marginBottom: '18px',
          '& .separate-picker-inputs': {
            display: 'flex',
            flexDirection: 'row',
            flex: '1 1 auto',
            alignSelf: 'stretch',
            alignItems: 'center',
            gap: 8,
          },
          '&.withLabel': {
            marginTop: '16px',
          },
        },
        '&.separate-time-picker': {
          flexShrink: 1,
        },
        '&.separate-date-picker': {
          flexShrink: 1,
        },
        '&.datetime-picker': {
          // flexDirection: 'row',
          // flexShrink: 1,
          // marginBottom: '18px',
          '& .datetime-picker-inputs': {
            display: 'flex',
            flexDirection: 'row',
            flex: '1 1 auto',
            alignSelf: 'stretch',
            alignItems: 'center',
            gap: 8,
          },
          // '&.withLabel': {
          //   marginTop: '16px',
          // },
        },
        '&.datetime-time-picker': {
          flexShrink: 1,
        },
        '&.datetime-date-picker': {
          flexShrink: 1,
        },
        '&.date-dropdown-picker': {
          // flexDirection: 'row',
          // flexShrink: 1,
          // marginBottom: '18px',
          '& .date-dropdown-picker-inputs': {
            display: 'flex',
            flexDirection: 'row',
            flex: '1 1 auto',
            alignSelf: 'stretch',
            alignItems: 'center',
            gap: 8,
          },
          '&.withLabel': {
            marginTop: '16px',
          },
        },
        '&.time-options-picker': {
          marginTop: '20px',
          '&.withLabel': {
            marginTop: '20px',
          },
        },
      },

      /* Styles applied to the root element if `margin="normal"`. */
      marginNormal: {
        marginTop: 16,
        marginBottom: 8,
      },

      /* Styles applied to the root element if `margin="dense"`. */
      marginDense: {
        marginTop: 8,
        marginBottom: 4,
      },

      /* Styles applied to the root element if `fullWidth={true}`. */
      fullWidth: {
        width: '100%',
      },
    },
    MuiFormControlLabel: {
      /* Styles applied to the root element. */
      root: {
        display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',
        // For correct alignment with the text.
        verticalAlign: 'middle',
        WebkitTapHighlightColor: 'transparent',
        marginLeft: 0,
        marginRight: 0,
        gap: 8,
        // used for row presentation of radio/checkbox
        '&$disabled': {
          cursor: 'default',
        },
        '&.boldLabel': {
          '& $label': {
            ...boldS,
            '&$disabled': {
              color: bookitColors.grayscale.graySubtitles,
            },
          },
        },
      },
      /* Styles applied to the root element if `labelPlacement="start"`. */
      labelPlacementStart: {
        flexDirection: 'row-reverse',
        marginLeft: 16,
        // used for row presentation of radio/checkbox
        marginRight: -11,
      },

      /* Styles applied to the root element if `labelPlacement="top"`. */
      labelPlacementTop: {
        flexDirection: 'column-reverse',
        marginLeft: 0,
        marginRight: 2,
      },

      /* Styles applied to the root element if `labelPlacement="bottom"`. */
      labelPlacementBottom: {
        flexDirection: 'column',
        marginLeft: 16,
      },

      /* Pseudo-class applied to the root element if `disabled={true}`. */
      disabled: {},

      /* Styles applied to the label's Typography component. */
      label: {
        ...bodyS,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // maxWidth: '25ch',
        '&$disabled': {
          color: bookitColors.grayscale.graySubtitles,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: bookitColors.grayscale.graySelection,
        // '&[class*="PrivateSwitchBase-root"]': {
        //   padding: 0,
        // },
      },
      colorPrimary: {
        color: bookitColors.primary.borders,
        '&$checked': {
          color: bookitColors.primary.regular,
          '&:hover': {
            backgroundColor: bookitColors.base.white,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.primary.disabled,
        },
      },
      /* Styles applied to the root element if `color="secondary"`. */
      colorSecondary: {
        color: bookitColors.danger.borders,
        '&$checked': {
          color: bookitColors.danger.regular,
          '&:hover': {
            backgroundColor: bookitColors.base.white,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.danger.disabled,
        },
      },
    },
    MuiRadio: {
      /* Styles applied to the root element. */
      root: {
        color: bookitColors.base.white,
      },

      /* Pseudo-class applied to the root element if `checked={true}`. */
      checked: {},

      /* Pseudo-class applied to the root element if `disabled={true}`. */
      disabled: {},

      /* Styles applied to the root element if `color="primary"`. */
      colorPrimary: {
        '&$checked': {
          color: bookitColors.primary.components,
          '&:hover': {
            backgroundColor: bookitColors.primary.regular,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.grayscale.grayBorders,
        },
      },

      /* Styles applied to the root element if `color="secondary"`. */
      colorSecondary: {
        '&$checked': {
          color: bookitColors.danger.components,
          '&:hover': {
            backgroundColor: bookitColors.danger.regular,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.danger.disabled,
        },
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: 0,
      },
      checked: {},
      disabled: {},
      input: {
        cursor: 'inherit',
        position: 'absolute',
        opacity: 0,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        margin: 0,
        padding: 0,
        zIndex: 1,
      },
    },
    MuiBadge: {
      badge: {
        whiteSpace: 'nowrap',
        height: '14px',
        padding: '2px 0px',
        fontSize: '8px',
        minWidth: '14px',
        lineHeight: '168%',
        letterSpacing: '0.8px',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        padding: '0px 8px 0px 8px',
        //textAlign: 'center',
      },
      // stackedCommon: {
      //   display: 'flex',
      //   // alignItems: 'center',
      //   // flexWrap: 'nowrap',
      //   // justifyContent: 'center',
      // },
    },
    MUIDataTableBody: {
      root: {
        border: '1px solid #ccc',
      },
    },
    MUIDataTableHeadCell: {
      root: {
        border: '1px solid #ccc',
      },
      contentWrapper: {
        //justifyContent: 'center',
      },
      data: {
        fontWeight: 'bold',
      },
      fixedHeader: {
        //textAlign: 'center',
        fontWeight: 'bold',
        border: '1px solid #ccc',
      },
      toolButton: {
        whiteSpace: 'nowrap',
      },
    },
    MUIDataTableToolbarSelect: {
      title: {
        paddingRight: '26px',
        paddingLe: '26px',
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: '1.5rem',
      },
    },
    MuiDialog: {
      root: {},
      paper: {
        //width: '100%',
        borderRadius: '12px',
        '.coverRoot &': {
          margin: '0px 4px',
          overflowY: 'hidden',
          borderRadius: '12px 12px 0px 0px',
        },
      },
      paperWidthXs: {
        maxWidth: '480px',
      },
      scrollPaper: {
        '.coverRoot &': {
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        },
      },
      paperWidthFalse: {
        '.coverRoot &': {
          maxWidth: COVER_WIDTH,
        },
      },
      paperScrollPaper: {
        '.coverRoot &': {
          maxHeight: 'calc(100% - 50px)',
          // height: '100vh',
        },
      },
      paperFullWidth: {
        '.coverRoot &': {
          width: '100%',
        },
      },
    },
    MuiBackdrop: {
      root: {
        '&.sidePanelBackdrop': {
          zIndex: 1201,
        },
      },
    },
    MuiAlert: {
      root: {
        padding: '0px 12px',
        '&.showMore': {
          alignItems: 'center',
        },
      },
      action: {
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    },
    MuiMenuItem: {
      root: {
        gap: 12,
      },
      dense: {
        paddingTop: '2px',
        paddingBottom: '2px',
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '8px',
      },
      toolbarLandscape: {
        padding: 16,
      },
      dateLandscape: {
        marginRight: 16,
      },
    },
    MuiPickerDTToolbar: {
      toolbar: {
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-around',
      },
      separator: {
        margin: '0 4px 0 2px',
        cursor: 'default',
        ...buttonM,
      },
    },
    MuiDrawer: {
      root: {
        '&.sideBarDrawer': {
          gap: 8,
          width: DRAWER_WIDTH,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          backgroundColor: bookitColors.grayscale.grayBackground,
          zIndex: 1200,
          '&.mobile': {
            width: DRAWER_MOBILE_WIDTH,
          },
          '&.drawerOpen': {
            width: DRAWER_WIDTH,
            transition: transitions.create('width', {
              easing: transitions.easing.sharp,
              duration: transitions.duration.enteringScreen,
            }),
            '&.mobile': {
              width: DRAWER_MOBILE_WIDTH,
            },
          },
          '&.drawerClose': {
            transition: transitions.create('width', {
              easing: transitions.easing.sharp,
              duration: transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: 0,
            [breakpoints.up('sm')]: {
              width: 7 * 8 + 1,
            },
          },
        },
      },
      paper: {
        '.sideBarDrawer &': {
          background: bookitColors.grayscale.grayBackground,
          position: 'fixed',
          whiteSpace: 'nowrap',
          width: DRAWER_WIDTH,
          transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen,
          }),
        },
        '.sideBarDrawer.mobile &': {
          width: DRAWER_MOBILE_WIDTH,
        },
        '.sideBarDrawer.drawerClose &': {
          overflowX: 'hidden',
          transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen,
          }),
          width: 7 * 8,
          [breakpoints.up('sm')]: {
            width: 7 * 8 + 1,
          },
        },
      },
    },
    // MuiPickerDTTabs: {
    //   tabs: {
    //     color: theme.palette.getContrastText(tabsBackground),
    //     backgroundColor: tabsBackground,
    // },
    // },
    MuiPickersToolbar: {
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        backgroundColor: bookitColors.primary.regular,
        // theme.palette.type === 'light'
        //   ? theme.palette.primary.main
        //   : theme.palette.background.default,
      },
      toolbarLandscape: {
        height: 'auto',
        maxWidth: 150,
        padding: 8,
        justifyContent: 'flex-start',
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        padding: 0,
        minWidth: '32px',
        textTransform: 'none',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: fade(bookitColors.base.white, 0.54),
        ...buttonM,
      },
      toolbarBtnSelected: {
        color: bookitColors.base.white,
        // color: theme.palette.type === 'light'
        // ? theme.palette.primary.contrastText
        // : theme.palette.getContrastText(theme.palette.background.default);,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        flexWrap: 'wrap',
        '.atable &': {
          flexWrap: 'nowrap',
        },
        '& $input': {
          width: 'auto',
          minWidth: 56,
        },
        '.date-dropdown-picker-inputs &': {
          '& $input': {
            width: '100%',
            minWidth: 30,
          },
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        display: 'flex',
        padding: 12,
        flexDirection: 'column',
        alignItems: 'flex-start',
        color: bookitColors.base.white,
        backgroundColor: bookitColors.overlays.overlayBlack,
        flex: '1 0 0',
        ...bookitTypography.bodyS,
        '.colorDark &': {
          color: bookitColors.base.white,
          backgroundColor: bookitColors.overlays.overlayBlack,
        },
        '.colorLight &': {
          color: bookitColors.base.black,
          backgroundColor: bookitColors.base.white,
          boxShadow: box_shadow_s,
        },
        '& .content': {
          maxHeight: 360,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            boxShadow: `inset 0 0 2px ${bookitColors.grayscale.grayBorders}`,
            borderRadius: '6px',
            backgroundColor: bookitColors.grayscale.grayBorders,
            '.colorDark &': {
              backgroundColor: bookitColors.overlays.overlayBlack,
            },
          },
        },
      },
      arrow: {
        color: bookitColors.overlays.overlayBlack,
        '.colorDark &': {
          color: bookitColors.overlays.overlayBlack,
        },
        '.colorLight &': {
          color: bookitColors.base.white,
          '&::before': {
            border: `1px solid ${bookitColors.grayscale.graySelection}`,
          },
        },
      },
    },
  },
};
export const darkTheme: ThemeOptions = {
  palette: {
    background: { default: grey[400], paper: grey[100] },
    primary: { main: green[100] },
    secondary: {
      main: '#4a4a4a',
    },
    text: {
      primary: '#e1dada',
      secondary: 'rgba(255, 255, 255, 0.54)',
      disabled: '#757575',
    },
  },
};
export const animalsThemeSettings: ThemeOptions = {
  palette: {
    primary: {
      main: '#ffdc64',
      dark: '#c9ab33',
      light: '#ffff95',
      contrastText: '#000000',
    },
    secondary: {
      main: '#3247e1',
      dark: '#001fae',
      light: '#7673ff',
      contrastText: '#ffffff',
    },
    error: {
      main: '#E53F58',
      dark: '#AC2F42',
      light: '#F195A3',
      contrastText: '#ffffff',
    },
    background: {
      default: '#F3F3F3',
      paper: '#FFF',
    },
    default: {
      main: '#ffdc64',
      dark: '#c9ab33',
      light: '#ffff95',
      contrastText: '#000000',
    },
  },
  shape: {
    borderRadius: 12,
  },

  typography: {
    fontFamily: ["'Open Sans Hebrew'", 'system-ui'].join(','),
    h1: {
      //styleName: Bkt/Header 1;
      fontSize: '2.5rem',
      fontWeight: 700,
      lineHeight: '3rem',
    },
    h2: {
      //styleName: Bkt/Header 2;
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '2.875rem', //46/16
    },
    h3: {
      //styleName: Bkt/Header 3;
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '2.375rem', //46/16
    },
    h4: {
      //styleName: Bkt/Header 4:
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '2rem',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.375rem',
    },
    subtitle1: {
      fontSize: '1.25rem',
      lineHeight: '2rem',
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: '1.25rem',
      lineHeight: '2rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      fontWeight: 400,
      //color: grey[600],
    },
  },
  overrides: {
    MuiTextField: {},
    MuiAppBar: {},
  },
};

export const CreateTheme = (direction, baseTheme: BaseTheme) =>
  createMuiTheme(getBaseTheme(baseTheme), { direction: direction }); //DefaultThemeSettings

const getBaseTheme = (type: BaseTheme) => {
  switch (type) {
    case 'bookit':
      return BookitThemeSettings;
    case 'animals':
      return animalsThemeSettings;
    case 'dark':
      return darkTheme;
    case 'default':
    default:
      return DefaultThemeSettings;
  }
};
