/**
 *
 * TextField
 *
 */
import * as React from 'react';
import {
  TextField as MatTextField,
  TextFieldProps as MatTextFieldProps,
  Theme,
  withStyles,
} from '@material-ui/core';
import { omit } from 'lodash';
//import styled from 'styled-components/macro';
export interface TextFieldProps
  extends Omit<MatTextFieldProps, 'variant' | 'color' | 'autocomplete'> {
  variant?: 'outlined' | 'filled' | 'standard';
  color?: 'primary' | 'secondary';
  autoComplete?: string;
  mini?: string;
}
export function TextField(props: TextFieldProps) {
  return (
    <>
      <StyledTextField
        variant={props.variant === undefined ? 'filled' : props.variant}
        color={props.color === undefined ? 'primary' : props.color}
        autoComplete={
          props.autoComplete === undefined ? 'false' : props.autoComplete
        }
        {...omit(props, 'DialogProps')}
      />
    </>
  );
}
/* only bottom border should have accent when focused according to figma design */
/* TODO: override default theme instead of this mess */
const StyledTextField = withStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0),
  },
}))(MatTextField);
