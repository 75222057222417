import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from 'react';
import {
  selectAppSettings,
  selectGlobalServiceGroupFilter,
  selectGlobalSettingBool,
  selectglobalSettings,
  selectIsAuthenticated,
  selectPublicUrl,
} from 'app/slice/selectors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AllowedSettings } from 'utils/globalSettings';
import { getEnabledAttributes } from './EnabledAttributes';
import { IAsset } from './IAsset';
import { AttributeValue, IAssetRow } from './IAssetRow';
import { getRows } from './Rows';
import { Grid, Link as MuiLink, MenuItem } from '@material-ui/core';
import { AssetDetailsApi } from 'api/AssetDetailsApi';
import { httpClient } from 'api/HttpClient';
import { translations } from 'locales/translations';
import { Button } from 'app/components/BasicButtons/Button';
import { Link } from 'app/components/ExternalLink';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { buildURL, toQueryString, toRootedURL } from 'utils/url-utils';
import { DropDown } from 'app/components/DropDown';
import { EndUsageConfirm, EndUsageData } from 'app/components/EndUsageConfirm';
import { useAppSettingsSlice } from 'app/slice';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { Box } from 'app/components/basic/Wrappers/Box';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { useTopAssetActionsDropDown } from './components/TopAssetActionsDropDown';
import { AvailabilityTypes } from 'enums/asset';
import { Redirect } from 'react-router-dom';
import { CalendarButton } from 'app/components/CalendarButton';
import { ServiceType } from 'enums/ServiceTypes';
import { PrimaryActionDynamic } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { BasicAlert } from 'app/components/BasicAlert';
import { Body } from 'app/components/Typography';
import { IsAssetFieldEnabled, IsAssetFieldIsPublic } from 'types/AppSettings';
import { AssetAttributes } from 'enums/AssetAttributes';

const isBookable = (type?: number) => {
  const bookable: (number | undefined)[] = [
    AvailabilityTypes.ApprovalRequiredBillable as number,
    AvailabilityTypes.BookableWApproval as number,
    AvailabilityTypes.FreelyBookable as number,
    AvailabilityTypes.FreelyBookableBillable as number,
    AvailabilityTypes.LectureRoom as number,
    AvailabilityTypes.LoanableEquipment as number,
    AvailabilityTypes.TrainingProgram as number,
  ];
  return bookable.includes(type);
};

const maxLeftTopButtonsLength = 2;

const expandable = [
  'Alerts',
  'Tags',
  'Suppliers',
  'Contacts',
  'Experts',
  'Accessories',
  'Articles',
];

const defaultRows = [
  'ImageName',
  'ServiceGroupName',
  'DivisionName',
  'AvailabilityTypeName',
  'Articles',
];

export interface AssetPopUpProps extends CoverProps {
  Id?: number;
  serviceId?: number;
  onClose?: () => void;
  showAllCores?: boolean;
}

export const AssetPopUp = ({
  Id,
  serviceId,
  onClose,
  showAllCores,
  ...props
}: AssetPopUpProps) => {
  const { t } = useTranslation();
  const globalSettings = useSelector(selectglobalSettings);
  const appSettings = useSelector(selectAppSettings);
  const [rows, setRows] = useState<IAssetRow[]>([]);
  const [data, setData] = useState<IAsset | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openEndUsage, setOpenEndUsage] = useState(false);
  const [reset, setReset] = useState(false);
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const publicUrl = useSelector(selectPublicUrl);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [assetCover, setAssetCover] = useState<ReactNode>();
  const selectedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const topServiceGroupsIsHidden = useSelector(state =>
    selectGlobalSettingBool(state, AllowedSettings.HideTopServiceGroupFilter),
  );
  const assetFieldEnabled = useCallback(
    (AssetField: AssetAttributes): boolean => {
      if (isAuthenticated) {
        return IsAssetFieldEnabled(appSettings, AssetField);
      } else {
        return (
          IsAssetFieldEnabled(appSettings, AssetField) &&
          IsAssetFieldIsPublic(appSettings, AssetField)
        );
      }
    },
    [appSettings, isAuthenticated],
  );
  const defaultAttributes = useMemo(() => {
    let result: string[] = [
      'Id',
      'Name',
      'ImageName',
      'ServiceId',
      'ServiceTypeId',
      'NotifyExists',
      'CanEndUsage',
      'UsageId',
      'ServiceGroupName',
      'AvailabilityTypeId',
      'EquipmentRestrictionsOverview',
      'AssemblyId',
      'EquipmentMinOrder',
      'EquipmentMaxOrder',
      'LoanTypeId',
      'DivisionName',
      'Alerts',
      'AlertInfo',
      'AmAdmin',
      'AvailabilityTypeName',
      'AlertsEnabled',
      'UsageEnabled',
      'ReservationEnabled',
      'CanStartUsage',
      'PlanedEventsEnabled',
      'ShowFeed',
      'ServiceGroupId',
      'DivisionId',
      'EndUsageIds',
      'RestrictedReservationsOption',
      'MinTimeAheadPeriodType',
      'MinTimeAheadMinutesSinceMidnight',
      'MinTimeAheadPeriodValue',
      'ReservationMinimumGap',
      'CarryOverTimeSlotTypeName',
      'CarryOverTimeSlotGap',
      'ApprovalRequired',
    ];
    if (assetFieldEnabled(AssetAttributes.ExtraDDL0)) {
      result.push('ExtraDDL0Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL1)) {
      result.push('ExtraDDL1Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL2)) {
      result.push('ExtraDDL2Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL3)) {
      result.push('ExtraDDL3Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL4)) {
      result.push('ExtraDDL4Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL5)) {
      result.push('ExtraDDL5Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL6)) {
      result.push('ExtraDDL6Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL7)) {
      result.push('ExtraDDL7Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL8)) {
      result.push('ExtraDDL8Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL9)) {
      result.push('ExtraDDL9Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL10)) {
      result.push('ExtraDDL10Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL11)) {
      result.push('ExtraDDL11Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL12)) {
      result.push('ExtraDDL12Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL13)) {
      result.push('ExtraDDL13Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL14)) {
      result.push('ExtraDDL14Value');
    }
    if (assetFieldEnabled(AssetAttributes.ExtraDDL15)) {
      result.push('ExtraDDL15Value');
    }
    return result;
  }, [assetFieldEnabled]);
  const redirectToDashOnCoreClick =
    globalSettings.GetBoolean(
      AllowedSettings[AllowedSettings.NoDashboardRedirectOnCoreSelection],
    ) === false;
  const closeAssetCover = () => {
    setAssetCover(undefined);
  };
  const assetProps = useMemo(
    () =>
      isAuthenticated
        ? [
            ...globalSettings
              .GetString(AllowedSettings.AssetPopUpActiveProps)
              .split(','),
            'RestrictedReservationsOption',
          ]
        : (appSettings?.AssetPopUpActiveProps || '').split(','),
    [appSettings?.AssetPopUpActiveProps, globalSettings, isAuthenticated],
  );
  const dict: Record<string, IAssetRow> = useMemo(() => {
    return getRows(t, globalSettings, publicUrl, setAssetCover).reduce(
      (res, current) => {
        res[current.Id] = current;
        return res;
      },
      {},
    );
  }, [t, globalSettings, publicUrl]);

  const mismatchCore = useMemo(() => {
    return showAllCores && isAuthenticated && !topServiceGroupsIsHidden
      ? !!data &&
          !!data.AvailabilityTypeId &&
          data.AvailabilityTypeId !== AvailabilityTypes.ServiceGroup &&
          data.AvailabilityTypeId !== AvailabilityTypes.SuperGroup &&
          !!selectedServiceGroups &&
          selectedServiceGroups.length > 0 &&
          !selectedServiceGroups.some(f => f.Id === data.ServiceGroupId)
      : false;
  }, [
    data,
    isAuthenticated,
    selectedServiceGroups,
    showAllCores,
    topServiceGroupsIsHidden,
  ]);
  const updateGlobalServiceGroup = useCallback(() => {
    if (showAllCores && !!data && !!data.ServiceGroupId) {
      let groups = !!selectedServiceGroups
        ? [
            ...selectedServiceGroups,
            { Id: data.ServiceGroupId, Name: data.ServiceGroupName },
          ]
        : [{ Id: data.ServiceGroupId, Name: data.ServiceGroupName }];
      dispatch(actions.saveGlobalServiceGroupFilter(groups));
    }
  }, [actions, data, dispatch, selectedServiceGroups, showAllCores]);
  useEffect(() => {
    getEnabledAttributes(assetProps, isAuthenticated)
      .then(value =>
        setRows(
          [
            ...defaultRows.map(r => dict[r]),
            ...value.flatMap(v => {
              let val = dict[v.Id];
              if (!!val) {
                val.Name = v.Name ?? val.Name;
                if (val.dependencies && !!val.dependencies.length) {
                  return [val, ...val.dependencies.map(v => dict[v])];
                }
              }
              return val;
            }),
          ].filter(v => !!v),
        ),
      )
      .catch(err => console.error(err));
  }, [dict, assetProps, Id, isAuthenticated]);

  useEffect(() => {
    if (rows.length === 0) return;
    setLoading(true);
    let active = true;
    const cancelToken = httpClient.cancelToken.source();
    let columns = [...rows.map(v => v.PropName ?? v.Id), ...defaultAttributes];
    async function fetchData() {
      let res = await AssetDetailsApi.getAssetById(
        Id,
        serviceId,
        columns,
        expandable,
        cancelToken.token,
        isAuthenticated,
      );
      if (active) {
        setData(res);
      }
      setLoading(false);
    }

    fetchData();

    return () => {
      active = false;
      setLoading(false);
      cancelToken.cancel('cancelled');
    };
  }, [Id, rows, serviceId, reset, isAuthenticated, defaultAttributes]);
  const refresh = () => {
    setReset(f => !f);
  };

  const HandleEndUsage = (usageData: EndUsageData) => {
    if (data?.UsageId)
      AssetDetailsApi.endUsage(data?.UsageId, usageData)
        .then(response => {
          if (response.ErrorMessages.length > 0) {
            response.ErrorMessages.forEach(errmsg =>
              dispatch(
                actions.addNotification({
                  variant: 'error',
                  message: errmsg,
                }),
              ),
            );
          }
          if (response.SuccessMessages.length > 0) {
            response.SuccessMessages.forEach(msg =>
              dispatch(
                actions.addNotification({
                  variant: 'success',
                  message: msg,
                }),
              ),
            );
            refresh();
          }
          if (response.WarningMessages.length > 0) {
            response.WarningMessages.forEach(wrnmsg =>
              dispatch(
                actions.addNotification({
                  variant: 'warning',
                  message: wrnmsg,
                }),
              ),
            );
          }
        })
        .catch(e => console.error(e));
    setOpenEndUsage(false);
  };

  const DefautltCell = ({ value }: AttributeValue<IAsset>) => <>{value}</>;

  const getTopAssetActionsDropDown = useTopAssetActionsDropDown(mismatchCore);
  const rightTopActions = useMemo(() => {
    let actions = [] as ActionRenderer[];
    actions.push(() => (
      <Tooltip title={t(translations.ShowAssetFull)}>
        <span>
          <Link
            href={{
              path: '/Assets/Details.aspx',
              search: {
                id: data?.Id ?? undefined,
                serviceId: data?.ServiceId ?? undefined,
              },
            }}
            target={'_blank'}
          >
            <IconButton
              variant="ghost"
              size="small"
              shape="square"
              aria-label="full details"
            >
              <Icon
                color="default"
                size="1x"
                icon={'external-link-alt'}
                colorExtend="textHover"
              />
            </IconButton>
          </Link>
        </span>
      </Tooltip>
    ));
    if (data?.AmAdmin) {
      actions.push(() => (
        <Tooltip title={t(translations.EditSettings)}>
          <span>
            <Link
              href={editLink(
                data.AvailabilityTypeId || 0,
                data.Id,
                data.ServiceId,
                data.ServiceGroupId,
                data.DivisionId,
              )}
              target={'_blank'}
            >
              <IconButton variant="ghost" size="small" shape="square">
                <Icon
                  color="default"
                  size="1x"
                  icon="pencil-alt"
                  colorExtend="textHover"
                />
              </IconButton>
            </Link>
          </span>
        </Tooltip>
      ));
    }
    return actions;
  }, [
    data?.AmAdmin,
    data?.AvailabilityTypeId,
    data?.DivisionId,
    data?.Id,
    data?.ServiceGroupId,
    data?.ServiceId,
    t,
  ]);

  const topActions = useMemo(() => {
    let actions = [] as ActionRenderer[];
    if (data !== undefined) {
      const bookable = isBookable(data.AvailabilityTypeId);
      const addWorkOrder = () => {
        if (data.AlertsEnabled && isAuthenticated) {
          actions.push(() => (
            <DropDown
              size="small"
              startIcon={<Icon icon="plus" />}
              title={t(translations.CreateAnAssetRelatedEvent)}
              color="primary"
              variant={!bookable ? 'main' : 'white'}
              disabled={mismatchCore}
              addChevron
              menuChildren={() => [
                <MenuItem key={'title for this menu'} disabled>
                  {t(translations.PleaseSelectAnEventType)}
                </MenuItem>,
                ...data.Alerts.map(a => (
                  <MenuItem
                    component={'a'}
                    key={a.Id + a.Name + 'key'}
                    href={toRootedURL(
                      `/workorders/details?${toQueryString({
                        aType: a.Id,
                        assetId: data.Id,
                      })}`,
                    )}
                  >
                    {a.Name}
                  </MenuItem>
                )),
              ]}
              menuProps={{}}
            >
              {t(translations.Event)}
            </DropDown>
          ));
        }
      };
      const addReservation = () => {
        if (!data.AssemblyId && data.ReservationEnabled) {
          actions.push(() => (
            <CalendarButton
              size="small"
              color="primary"
              variant={bookable ? 'main' : 'white'}
              startIcon={<Icon icon="plus" />}
              title={t(translations.PlaceAReservation)}
              equipments={[data.ServiceId]}
              disabled={mismatchCore}
              openToBook={true}
            >
              {t(translations.Reservation)}
            </CalendarButton>
          ));
        }
      };
      const addUsage = () => {
        if (!!data.UsageEnabled) {
          if (!!data.CanStartUsage && !data.AssemblyId) {
            actions.push(() => (
              <Button
                size="small"
                variant="white"
                startIcon={<Icon icon="play" />}
                href={toRootedURL(`/Usage.aspx?id=${data.ServiceId}`)}
                title={t(translations.StartUsage)}
                disabled={mismatchCore}
              >
                {t(translations.Use)}
              </Button>
            ));
          }
          if (!!data.CanEndUsage && !!data.UsageId) {
            actions.push(() => (
              <Button
                size="small"
                variant="white"
                startIcon={<Icon icon="pause" />}
                title={t(translations.StopUsage)}
                onClick={() => setOpenEndUsage(true)}
                disabled={mismatchCore}
              >
                {t(translations.Stop)}
              </Button>
            ));
          }
        }
      };
      const addTakeDown = () => {
        if (
          data.AlertInfo !== undefined &&
          !!data.PlanedEventsEnabled &&
          !!data.AlertInfo.show &&
          isAuthenticated
        ) {
          if (!data.AlertInfo.hasDownActive) {
            if (!!data.AlertInfo.isAdmin && !!data.AlertInfo.assetId) {
              actions.push(() => (
                <DropDown
                  size="small"
                  color="primary"
                  variant="white"
                  disabled={mismatchCore}
                  title={t(translations.HasNoAlertsAdmin_info)}
                  menuChildren={() => [
                    <MenuItem key={'title for this menu'} disabled>
                      {t(translations.PleaseSelectAnEventType)}
                    </MenuItem>,
                    ...data.Alerts.map(a => (
                      <MenuItem
                        component={'a'}
                        key={a.Id + a.Name + 'key'}
                        href={toRootedURL('/workorders/details', {
                          assetId: data.AlertInfo!.assetId,
                          offH: true,
                          down: true,
                          aType: a.Id,
                        })}
                      >
                        {a.Name}
                      </MenuItem>
                    )),
                  ]}
                >
                  <Icon icon="power-off" color="danger" />
                </DropDown>
              ));
            }
          } else if (!!data.AlertInfo.noAlerts || !!data.AlertInfo.hasActive) {
            if (!!data.AlertInfo.isAdmin && !!data.AlertInfo.alertId) {
              actions.push(() => (
                <Button
                  size="small"
                  variant="white"
                  disabled={mismatchCore}
                  title={t(translations.HasActiveDownAlertsAdmin_info)}
                  href={toRootedURL(
                    `/workorders/details?id=${data.AlertInfo?.alertId}&Up=True`,
                  )}
                >
                  <Icon icon="plug" color="success" />
                </Button>
              ));
            }
          }
        }
      };
      const addConsumable = () => {
        if (data.ServiceTypeId === ServiceType.Offline && isAuthenticated) {
          actions.push(() => (
            <Button
              size="small"
              variant="main"
              disabled={mismatchCore}
              startIcon={<Icon icon="plus" />}
              title={t(translations.ReportAConsumable)}
              href={toRootedURL(`/reportConsumables?stid=${data.ServiceId}`)}
            >
              {t(translations.LogConsumables)}
            </Button>
          ));
        }
      };
      if (bookable) {
        addReservation();
        addUsage();
        addWorkOrder();
        addTakeDown();
      } else {
        addWorkOrder();
        addTakeDown();
        addUsage();
        addReservation();
        addConsumable();
      }
      if (isAuthenticated) {
        const assetActionsDropDown = getTopAssetActionsDropDown({
          data,
        });

        actions.push(...assetActionsDropDown.map(item => () => item));
      }
    }
    return actions;
  }, [data, getTopAssetActionsDropDown, isAuthenticated, mismatchCore, t]);
  const RenderRows = () => {
    return !!data ? (
      <Grid container direction="column" spacing={0}>
        {/* <RenderTop /> */}
        {rows
          .filter(f => {
            return (
              f.validToRender === undefined ||
              f.validToRender({
                value: data![f.PropName ?? f.Id],
                original: data,
              })
            );
          })
          .map(r => {
            let cell = r.Cell ?? DefautltCell;
            let cellData = {
              original: data,
              value: data![r.PropName ?? r.Id],
            };
            return (
              cellData.value !== null &&
              cellData.value !== undefined &&
              cellData.value !== '' && (
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  spacing={0}
                  key={'key for ' + r.Name + r.Id}
                  style={{ paddingBottom: '5px', paddingTop: '5px' }}
                >
                  {!r.NoHeader && (
                    <Grid item xs={12}>
                      <span style={{ fontWeight: 'bold', lineHeight: '164%' }}>
                        {r.Header ? r.Header(cellData) : r.Name}
                      </span>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {cell(cellData)}
                  </Grid>
                </Grid>
              )
            );
          })}
        <EndUsageConfirm
          ids={data.EndUsageIds}
          isopen={openEndUsage}
          onClose={() => setOpenEndUsage(false)}
          onConfirm={HandleEndUsage}
        />
      </Grid>
    ) : (
      <></>
    );
  };
  const RenderCoreMismatch = () => {
    return mismatchCore ? (
      <BasicAlert
        content={{
          closable: true,
          type: 'warning',
          children: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <Body size="small">
                {t(translations.MismatchAssetCore_warning) as string}
              </Body>
              <Button size="small" onClick={() => updateGlobalServiceGroup()}>
                {t(translations.SwitchToThisCore) as string}
              </Button>
            </div>
          ),
          variant: 'outlined',
        }}
      />
    ) : (
      <></>
    );
  };
  if (
    data?.AvailabilityTypeId === AvailabilityTypes.ServiceGroup &&
    redirectToDashOnCoreClick &&
    !!selectedServiceGroups &&
    selectedServiceGroups.length === 1
  ) {
    if (onClose) {
      onClose();
    } else {
      dispatch(layoutActions.resetSidePanel());
    }
    return <Redirect to={'/dashboards/core/' + data.ServiceGroupId ?? ''} />;
  }
  return (
    <PageWrapper
      useSidePanel
      closable
      disableExpandToggle
      loading={loading || rows.length === 0}
      pageLink={buildURL(`${publicUrl}Assets/Details.aspx`, { id: data?.Id })}
      pageName={t(translations.Instrument)}
      titlePage={data?.Name}
      titleTooltip={t(translations.ShowAssetFull)}
      children={
        <Box height={'100%'} p={1}>
          {data ? (
            <Fragment>
              <RenderCoreMismatch />
              <RenderRows />
            </Fragment>
          ) : (
            <>{t(translations.err_Equipment_NotFound)}</>
          )}
        </Box>
      }
      closeSidePanel={onClose}
      rightTopActions={rightTopActions}
      leftTopActions={topActions}
      maxLeftTopButtonsLength={maxLeftTopButtonsLength}
      isCover={props.isCover}
      closeCover={props.closeCover || closeAssetCover}
      cover={assetCover}
    />
  );
};
export const editLink = (
  availabilityTypeId: number,
  assetId?: number,
  serviceId?: number,
  serviceGroupId?: number,
  divisionId?: number,
) => {
  switch (availabilityTypeId as AvailabilityTypes) {
    case AvailabilityTypes.NotAvailable:
    case AvailabilityTypes.AvailableNotBookable:
    case AvailabilityTypes.FreelyBookableBillable:
    case AvailabilityTypes.ApprovalRequiredBillable:
    case AvailabilityTypes.FreelyBookable:
    case AvailabilityTypes.BookableWApproval:
    case AvailabilityTypes.TrainingProgram:
    case AvailabilityTypes.LoanableEquipment:
    case AvailabilityTypes.LectureRoom:
      return toRootedURL(
        `/NewResource.aspx?Edit=${serviceId}&back=assetdetails&assetid=${assetId}`,
      );
    case AvailabilityTypes.ServiceRelated:
      return toRootedURL(
        `/OfflineServiceType.aspx?ID=${serviceId}&back=assetdetails&assetid=${assetId}`,
      );
    case AvailabilityTypes.RequestRelated:
      return toRootedURL(
        `/SampleType.aspx?ID=${serviceId}&back=assetdetails&assetid=${assetId}`,
      );
    case AvailabilityTypes.ServiceGroup:
      return toRootedURL(`/newgroup.aspx?edit=${serviceGroupId}`);
    case AvailabilityTypes.SuperGroup:
      return toRootedURL(`/newsupergroup.aspx?edit=${divisionId}`);
    default:
      return '';
  }
};

export const AssetLink = (props: {
  id?: number;
  serviceId?: number;
  children: ReactNode;
  title?: string;
}) => {
  const { t } = useTranslation();
  const openAsset = useOpenAssetPopUp();
  const open = () => {
    openAsset({ Id: props.id, serviceId: props.serviceId });
  };
  return (
    <Tooltip title={props.title ?? t(translations.ShowAssetSidePanel)}>
      <MuiLink style={{ cursor: 'pointer' }} onClick={() => open()}>
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};

export const useOpenAssetPopUp = () => {
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  return useCallback(
    (props: { Id?: number; serviceId?: number }) => {
      dispatch(
        actions.openSidePanel({
          type: RenderPageType.AssetDetails,
          props: { Id: props.Id, serviceId: props.serviceId },
          expanded: false,
        }),
      );
    },
    [actions, dispatch],
  );
};
export const OpenAssetPrimaryAction: PrimaryActionDynamic<{
  Id: number;
}> = (dispatch, actions, row) => {
  dispatch(
    actions.openSidePanel({
      type: RenderPageType.AssetDetails,
      props: { Id: row.original.Id },
      expanded: false,
    }),
  );
};
