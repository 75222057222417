import { Dictionary, keyBy, omit, orderBy, transform } from 'lodash';
import {
  IServiceRequestTableRowModel,
  IServiceRequestTableRowValueModel,
} from './types';
import { fromDtoValue } from './selectors';
import { IServiceRequestRowDto } from 'api/odata/generated/entities/IServiceRequestRowDto';
import { IServiceRequestRowValueDto } from 'api/odata/generated/entities/IServiceRequestRowValueDto';
import { serializeCustomFormRowValue } from 'app/components/CustomForm/CustomFormUtils';
import { ServiceRequestTableColumnsCollection } from './utils/ServiceRequestTableColumn';

export function prepareServiceRequestRowModel(
  rows: Array<IServiceRequestRowDto>,
  columns: ServiceRequestTableColumnsCollection,
) {
  const result: IServiceRequestTableRowModel[] | undefined = orderBy(
    rows,
    f => f.Id,
  )?.map(row => {
    const values = keyBy(row.Values, value => value.ColumnId);
    const rowValues: Dictionary<IServiceRequestTableRowValueModel> = transform(
      columns.columnsObj,
      (result, column, columnId) => {
        result[column.getFieldName()] = fromDtoValue(
          column.ColumnType,
          values[column.Id],
        );
      },
      {},
    );
    const model: IServiceRequestTableRowModel = {
      ...omit(row, 'Values', '@odata.context'),
      ...{
        Values: rowValues,
      },
    };
    return model;
  });
  return result;
}

export function serviceRequestModelToDto(
  model: Partial<IServiceRequestTableRowModel>,
  columns: ServiceRequestTableColumnsCollection,
) {
  const result: Partial<
    Omit<IServiceRequestRowDto, 'Values'> & {
      Values: Array<Partial<IServiceRequestRowValueDto>>;
    }
  > = {
    ...omit(model, 'Values'),
    Values: columns
      .getArray()
      .filter(column => column.CopyPrevValues)
      .map(column => {
        const dto: Partial<IServiceRequestRowValueDto> = {
          ColumnId: column.Id,
        };
        const modelValue = model.Values?.[column.Id];
        if (modelValue !== undefined) {
          Object.assign(dto, serializeCustomFormRowValue(modelValue));
        }
        return dto;
      }),
  };
  return result;
}
