/**
 *
 * UserFilter
 *
 */
import { UserPicker } from 'app/components/pickers/AutocompletePickers/UserPicker';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const UserFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<string> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <UserPicker
      value={props.value}
      onChange={handleOnChange}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      size="small"
      ariaLabel={props.label}
      id={props.id}
      selectArray={[
        'Id',
        'Name',
        'UserGroups',
        'Budgets',
        'IsAdminPanel',
        'IsEquipmentAdmin',
        'IsGroupAdmin',
        'IsLabTech',
        'Services',
      ]}
    />
  );
};
export default UserFilter;
