/**
 *
 * MultiSelectBasicPicker for EmailRecipientTypePicker
 *
 */
import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { Entity } from 'types/common';
import {
  AutocompleteMultiPicker,
  AutocompleteMultiPickerProps,
} from 'app/components/BasicPickers/AutocompleteMultiPicker';
import { ODataFilterBuilder } from 'api/odata/ODataFilter';
import i18next from 'i18next';
import { IEmailRecipientTypesDto } from 'app/pages/BillingActions/BillingActionsPage/IEmailRecipientTypesDto';

export interface ERTBaseURLMultiPickerProps<T>
  extends Omit<AutocompleteMultiPickerProps<T>, 'loadData'> {
  url: string;
  queryfilter?: string | undefined;
}

export function ERTBaseURLMultiPicker<T>(props: ERTBaseURLMultiPickerProps<T>) {
  const { url, queryfilter, ...other } = props;
  const loadData = async (searchTerm: string | null) => {
    const params: { $orderby: string; $filter?: string } = {
      $orderby: 'Name asc',
    };
    const predicates: string[] = [];
    if (queryfilter !== undefined && queryfilter !== '') {
      predicates.push(queryfilter);
    }

    params.$filter = new ODataFilterBuilder<Entity<number>>({
      predicates: predicates,
      stringColumns: ['Name'],
      stringSearch: searchTerm !== null ? searchTerm : undefined,
    }).toString();

    const response = await httpClient.get(url, params);
    return response.value.map(i => {
      return {
        Id: i.Id,
        Name: i18next.t(i.Name) as string,
      } as IEmailRecipientTypesDto;
    });
  };
  return <AutocompleteMultiPicker loadData={loadData} {...other} />;
}
