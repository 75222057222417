import { BooleanRender } from 'app/components/BasicTable/components/BooleanRender';
import { Link } from 'app/components/ExternalLink';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Dispatch, Fragment, ReactNode, SetStateAction } from 'react';
import { Entity } from 'types/common';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import {
  ArrayRenderer,
  DateRenderer,
  HtmlRenderer,
} from './AttributeRenderers';
import { AssetUser } from './IAsset';
import { IAssetRow } from './IAssetRow';
import { ServiceType } from '../../../enums/ServiceTypes';
import { Link as MuiLink } from '@material-ui/core';
import { AssetLink } from '.';
import { DoubleRender } from 'app/components/BasicTable/components/DoubleRenderer';
import { dateUtils } from 'utils/date-utils';
import { enumToEntityArray } from 'utils/enumKeys';
import { MinTimeAheadPeriodTypes } from 'enums/MinTimeAheadPeriodTypes';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { InstrumentRestrictions } from '../InstrumentRestrictionsPage/Details/InstrumentRestrictions';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { RestrictedReservationsOptions } from 'enums/RestrictedReservationsOptions';
import { getAssetImageSrc } from 'app/components/AssetThumbImage/getAssetImageSrc';

export const getRows = (
  t: TFunction,
  setting: GlobalSettings,
  publicUrl?: string,
  setCover?: Dispatch<SetStateAction<ReactNode>>,
): IAssetRow[] => [
  {
    Id: 'ImageName',
    Name: t(translations.ImageName) as string,
    Cell: ({ value }) =>
      !!value && (
        <img
          src={getAssetImageSrc(
            value,
            undefined,
            (setting.GetNullableByKey(AllowedSettings.AssetImageSizeType) ??
              '2') === '1'
              ? '/images/400x300.png'
              : '/images/200x150.png',
          )}
          alt=""
          style={
            (setting.GetNullableByKey(AllowedSettings.AssetImageSizeType) ??
              '2') === '1'
              ? { maxWidth: '400px', maxHeight: '300px' }
              : { maxWidth: '200px', maxHeight: '150px' }
          }
        />
      ),
    NoHeader: true,
    validToRender: ({ value }) => !!value,
  },
  {
    Id: 'ServiceGroupName',
    Name: t(translations.ServiceGroup) as string,
  },
  {
    Id: 'AvailabilityTypeName',
    Name: t(translations.Availability),
    Cell: ({ value, original }) => {
      return (
        <Fragment>
          {original.ApprovalRequired ? (
            <Fragment>
              {`${value} - ${t(translations.ApprovalRequired)}`}
            </Fragment>
          ) : (
            <Fragment>{value}</Fragment>
          )}
        </Fragment>
      );
    },
  },
  {
    Id: 'EquipmentRestrictionsOverview',
    Name: t(translations.RestrictionsOverview) as string,
    validToRender: ({ value, original }) => {
      return (
        !!value &&
        value !== null &&
        value !== '' &&
        original.ServiceTypeId === ServiceType.Online &&
        original.ReservationEnabled &&
        !setting.GetBooleanByKey(AllowedSettings.HideInstrumentRestrictions) &&
        original.AssemblyId === null
      );
    },
    Cell: ({ value }) => {
      return (
        <Fragment>
          <strong>{value}</strong>
        </Fragment>
      );
    },
  },
  {
    Id: 'PrerequisiteEquipments',
    Name: t(translations.PrerequisiteTrainings) as string,
    validToRender: ({ value, original }) =>
      !!value && value.length > 0 && original.ReservationEnabled,
    Cell: ArrayRenderer<Entity<number>>(v => (
      <AssetLink serviceId={v.Id}>{v.Name}</AssetLink>
    )),
  },
  {
    Id: 'ForceTutoring',
    Name: t(translations.TrainingMandatory) as string,
    validToRender: ({ value, original }) =>
      value === true && original.ReservationEnabled,
    Cell: ({ value }) => {
      return (
        <Fragment>
          <strong>{t(translations.ApprovalRequired)}</strong>
        </Fragment>
      );
    },
  },
  {
    Id: 'minorder',
    Name: t(translations.MinimumReservationDurationH) as string,
    validToRender: ({ value, original }) =>
      !!value && value > 0 && original.ReservationEnabled,
    Cell: DoubleRender,
  },
  {
    Id: 'maxorder',
    Name: t(translations.MaximumReservationDurationH) as string,
    validToRender: ({ value, original }) =>
      !!value && value > 0 && original.ReservationEnabled,
    Cell: DoubleRender,
  },
  {
    Id: 'ReservationMinimumGap',
    Name: t(translations.ReservationMinimumGap) as string,
    validToRender: ({ value, original }) =>
      !!value && value > 0 && original.ReservationEnabled,
    Cell: DoubleRender,
  },
  {
    Id: 'CarryOverTimeSlotTypeId',
    Name: t(translations.CarryOverTimeSlotGap) as string,
    validToRender: ({ value, original }) =>
      !!value && value !== null && value > 0 && original.ReservationEnabled,
    Cell: ({ value, original }) => {
      return (
        <>{`${original.CarryOverTimeSlotGap} hours over timeslot ${original.CarryOverTimeSlotTypeName}`}</>
      );
    },
    dependencies: ['CarryOverTimeSlotGap', 'CarryOverTimeSlotTypeName'],
  },
  {
    Id: 'MinimumTimeAhead',
    Name: t(translations.MinimumTimeAhead) as string,
    validToRender: ({ value, original }) => {
      return (
        original.ReservationEnabled &&
        ((original.MinTimeAheadPeriodType !== undefined &&
          !!original.MinTimeAheadPeriodValue) ||
          !!original.MinTimeAheadMinutesSinceMidnight)
      );
    },
    Cell: ({ value, original }) => {
      let results = '';
      if (!!original.MinTimeAheadPeriodValue) {
        results = `${t(
          translations.Before,
        )}: ${original.MinTimeAheadPeriodValue.toFixed(2)}
           ${(t(translations.AdvancedReservation) as string).replace(
             '{0}',
             enumToEntityArray(MinTimeAheadPeriodTypes).filter(
               f => f.Id === original.MinTimeAheadPeriodType,
             )[0].Name,
           )}`;
      } else if (!!original.MinTimeAheadMinutesSinceMidnight) {
        results = `${t(translations.OnTheSameDay)}: ${(t(
          translations.ToBeBookedBy,
        ) as string).replace(
          '{0}',
          dateUtils.dateFnsUtils.format(
            new Date(0, 0, 0, 0, original.MinTimeAheadMinutesSinceMidnight),
            dateUtils.DateIOFormats.fullDateTime24h,
          ),
        )}`;
      }
      if (results !== '') {
        return (
          <Fragment>
            {results}
            <Tooltip
              title={
                !!original.MinTimeAheadPeriodValue
                  ? t(translations.AdvancedReservation_info)
                  : t(translations.ToBeBookedBy_info)
              }
            >
              <span style={{ marginLeft: '1ch' }}>
                <Icon icon="info-circle" />
              </span>
            </Tooltip>
          </Fragment>
        );
      }
    },
  },
  {
    Id: 'RestrictedReservationsOption',
    Name: t(translations.InstrumentRestrictions) as string,
    validToRender: ({ value, original }) => {
      return (
        (value !== null &&
          value !== (RestrictedReservationsOptions.NoLimitations as number) &&
          original.ReservationEnabled &&
          (original.ServiceTypeId ?? 0) === (ServiceType.Online as number) &&
          !setting.GetBooleanByKey(
            AllowedSettings.HideInstrumentRestrictions,
          ) &&
          !original.AssemblyId) ||
        (!!original.PrerequisiteEquipments &&
          original.PrerequisiteEquipments.length > 0)
      );
    },
    //dependencies: ['PrerequisiteEquipments'],
    Cell: ({ value, original }) => {
      return (
        <Tooltip title={t(translations.ShowRestrictionsFull)}>
          <MuiLink
            style={{ cursor: 'pointer' }}
            onClick={() =>
              !!setCover &&
              setCover(() => {
                return (
                  <InstrumentRestrictions
                    serviceId={original.ServiceId ?? -1}
                    serviceGroupId={original.ServiceGroupId}
                    useSidePanel
                    isCover
                    closeCover={() => setCover(undefined)}
                    onClose={() => setCover(undefined)}
                  />
                );
              })
            }
          >
            {t(translations.CheckTrainingStatus)}
          </MuiLink>
        </Tooltip>
      );
    },
  },
  {
    Id: 'DivisionName',
    Name: t(translations.AssetsInstituteBusiness) as string,
  },
  {
    Id: 'Accessories',
    Name: t(translations.Accessories) as string,
    Cell: ArrayRenderer<Entity<number>>(v => <>{v.Name}</>),
  },
  {
    Id: 'AssetCatGroupName',
    Name: t(translations.AssetCatGroupName) as string,
    Cell: ({ value }) => {
      if (!!value && setting.GetBooleanByKey(AllowedSettings.AssetCatEnabled)) {
        return <>{value}</>;
      }
    },
  },
  {
    Id: 'AssetCat',
    Name: t(translations.AssetCategory) as string,
    PropName: 'AssetCatName',
    validToRender: ({ value }) => {
      return (
        !!value && setting.GetBooleanByKey(AllowedSettings.AssetCatEnabled)
      );
    },
    Cell: ({ value, original }) => {
      return (
        <>
          {value}
          <>
            {!!original.AssetCatInternalServiceAvaliable && (
              <>{t(translations.InternalServiceAvaliable)}</>
            )}
          </>
        </>
      );
    },
  },
  {
    Id: 'NickName',
    Name: t(translations.NickName) as string,
  },
  {
    Id: 'OtherNames',
    Name: t(translations.OtherNames) as string,
  },
  {
    Id: 'Brand',
    Name: t(translations.Brand),
  },
  {
    Id: 'Model',
    Name: t(translations.Model) as string,
  },
  {
    Id: 'ModelID',
    Name: t(translations.Model) as string,
    validToRender: ({ value }) => !!value,
    Cell: ({ original, value }) => (
      <Link href={'/Assets/AssetModelDetails/Details.aspx?id' + value}>
        {value}
      </Link>
    ),
  },
  {
    Id: 'ModelNumber',
    Name: t(translations.ModelNumber) as string,
  },
  {
    Id: 'Campus',
    Name: t(translations.Campus) as string,
  },
  {
    Id: 'Building',
    Name: t(translations.Building) as string,
  },
  {
    Id: 'Room',
    Name: t(translations.RoomNumber) as string,
    validToRender: ({ value, original }) =>
      !!value ||
      (!!original.AmAdmin &&
        (!!original.LocationUpdatedAt || !!original.LocationUpdatedByDn)),
    Cell: ({ value, original }) => (
      <>
        {!!value && <>{value}</>}
        {!!original.AmAdmin && (
          <>
            {!!original.LocationUpdatedAt && <>{original.LocationUpdatedAt}</>}
            {!!original.LocationUpdatedByDn && (
              <>{original.LocationUpdatedByDn}</>
            )}
          </>
        )}
      </>
    ),
    dependencies: ['LocationUpdatedAt', 'LocationUpdatedByDn'],
  },
  {
    Id: 'LocationUpdatedAt',
    Name: t(translations.LocationUpdatedAt),
    validToRender: ({ value, original }) =>
      !!original.Room && !!original.AmAdmin,
    Cell: ({ original, value }) => DateRenderer({ original, value }),
  },
  {
    Id: 'LocationUpdatedByDn',
    Name: t(translations.LocationUpdatedBy),
    validToRender: ({ value, original }) =>
      !!original.Room && !!original.AmAdmin && !!value,
    Cell: ({ value }) => <>{value}</>,
  },
  {
    Id: 'LocationsList',
    Name: t(translations.LocationsList) as string,
    PropName: 'LocationName',
  },
  {
    Id: 'SubLocationName',
    Name: t(translations.LocationsList) as string,
    PropName: 'SubLocationName',
  },
  {
    Id: 'Experts',
    Name: t(translations.EquipmentExpert) as string,
    validToRender: ({ value }) => !!value && value.length > 0,
    Cell: ArrayRenderer<AssetUser>(v => (
      <>
        <span>{!!v.DisplayName ? v.Name : v.DisplayName}</span>
        {!!v.Email && <Link href={'mailto:' + v.Email}>{v.Email}</Link>}
        {!!v.OfficeTel && <span>{v.OfficeTel}</span>}
      </>
    )),
  },
  {
    Id: 'Contacts',
    Name: t(translations.ContactsPersonForEquipment) as string,
    validToRender: ({ value }) => !!value && value.length > 0,
    Cell: ArrayRenderer<AssetUser>(v => (
      <>
        <span>{!!v.DisplayName ? v.Name : v.DisplayName}</span>
        {!!v.Email && <Link href={'mailto:' + v.Email}>{v.Email}</Link>}
        {!!v.OfficeTel && <span>{v.OfficeTel}</span>}
      </>
    )),
  },
  {
    Id: 'Suppliers',
    Name: t(translations.ExternalCompany) as string,
    validToRender: ({ value }) => !!value && value.length > 0,
    Cell: ArrayRenderer<Entity<string>>(v => <>{v.Name}</>),
  },
  {
    Id: 'MainSpecRange',
    Name: t(translations.MainSpecificationRange) as string,
  },
  {
    Id: 'ElectricalSpecs',
    Name: t(translations.ElectricalSpecs) as string,
  },
  {
    Id: 'FunctionalCharacteristics',
    Name: t(translations.FunctionalCharacteristics) as string,
  },
  {
    Id: 'Description',
    Name: t(translations.Description) as string,
    Cell: HtmlRenderer,
  },
  {
    Id: 'WebLink',
    Name: t(translations.WebLink) as string,
    Cell: ({ value }) => value && <Link href={value}>{value}</Link>,
  },
  {
    Id: 'Raac',
    Name: t(translations.RoutineActiveAnalysisCapable) as string,
    Cell: BooleanRender,
  },
  {
    Id: 'Tags',
    Name: t(translations.Tags) as string,
    validToRender: ({ value }) => !!value && value.length > 0,
    Cell: ArrayRenderer<Entity<number>>(v => <>{v.Name}</>),
  },
  {
    Id: 'ExtraDate0',
    Name: t(translations.Asset_ExtraDate0) as string,
    Cell: DateRenderer,
  },
  {
    Id: 'ExtraDate1',
    Name: t(translations.Asset_ExtraDate1) as string,
    Cell: DateRenderer,
  },
  {
    Id: 'ExtraDate2',
    Name: t(translations.Asset_ExtraDate2) as string,
    Cell: DateRenderer,
  },
  {
    Id: 'ExtraDate3',
    Name: t(translations.Asset_ExtraDate3) as string,
    Cell: DateRenderer,
  },
  {
    Id: 'ExtraDate4',
    Name: t(translations.Asset_ExtraDate4) as string,
    Cell: DateRenderer,
  },
  {
    Id: 'ExtraDate5',
    Name: t(translations.Asset_ExtraDate5) as string,
    Cell: DateRenderer,
  },
  {
    Id: 'ExtraDate6',
    Name: t(translations.Asset_ExtraDate6) as string,
    Cell: DateRenderer,
  },
  {
    Id: 'ExtraDate7',
    Name: t(translations.Asset_ExtraDate7) as string,
    Cell: DateRenderer,
  },
  {
    Id: 'ExtraDate8',
    Name: t(translations.Asset_ExtraDate8) as string,
    Cell: DateRenderer,
  },
  {
    Id: 'ExtraDate9',
    Name: t(translations.Asset_ExtraDate9) as string,
    Cell: DateRenderer,
  },
  {
    Id: 'ExtraText0',
    Name: t(translations.Asset_ExtraText0) as string,
  },
  {
    Id: 'ExtraText1',
    Name: t(translations.Asset_ExtraText1) as string,
  },
  {
    Id: 'ExtraText2',
    Name: t(translations.Asset_ExtraText2) as string,
  },
  {
    Id: 'ExtraText3',
    Name: t(translations.Asset_ExtraText3) as string,
  },
  {
    Id: 'ExtraText4',
    Name: t(translations.Asset_ExtraText4) as string,
  },
  {
    Id: 'ExtraText5',
    Name: t(translations.Asset_ExtraText5) as string,
  },
  {
    Id: 'ExtraText6',
    Name: t(translations.Asset_ExtraText6) as string,
  },
  {
    Id: 'ExtraText7',
    Name: t(translations.Asset_ExtraText7) as string,
  },
  {
    Id: 'ExtraText8',
    Name: t(translations.Asset_ExtraText8) as string,
  },
  {
    Id: 'ExtraText9',
    Name: t(translations.Asset_ExtraText9) as string,
  },
  {
    Id: 'ExtraText10',
    Name: t(translations.Asset_ExtraText10) as string,
  },
  {
    Id: 'ExtraText11',
    Name: t(translations.Asset_ExtraText11) as string,
  },
  {
    Id: 'ExtraText12',
    Name: t(translations.Asset_ExtraText12) as string,
  },
  {
    Id: 'ExtraText13',
    Name: t(translations.Asset_ExtraText13) as string,
  },
  {
    Id: 'ExtraText14',
    Name: t(translations.Asset_ExtraText14) as string,
  },
  {
    Id: 'ExtraText15',
    Name: t(translations.Asset_ExtraText15) as string,
  },
  {
    Id: 'ExtraInt0',
    Name: t(translations.Asset_ExtraInt0) as string,
  },
  {
    Id: 'ExtraInt1',
    Name: t(translations.Asset_ExtraInt1) as string,
  },
  {
    Id: 'ExtraInt2',
    Name: t(translations.Asset_ExtraInt2) as string,
  },
  {
    Id: 'ExtraInt3',
    Name: t(translations.Asset_ExtraInt3) as string,
  },
  {
    Id: 'ExtraInt4',
    Name: t(translations.Asset_ExtraInt4) as string,
  },
  {
    Id: 'ExtraDec0',
    Name: t(translations.Asset_ExtraDec0) as string,
  },
  {
    Id: 'ExtraDec1',
    Name: t(translations.Asset_ExtraDec1) as string,
  },
  {
    Id: 'ExtraDec2',
    Name: t(translations.Asset_ExtraDec2) as string,
  },
  {
    Id: 'ExtraDec3',
    Name: t(translations.Asset_ExtraDec3) as string,
  },
  {
    Id: 'ExtraDec4',
    Name: t(translations.Asset_ExtraDec4) as string,
  },
  {
    Id: 'ExtraBool0',
    Name: t(translations.Asset_ExtraBool0) as string,
    Cell: BooleanRender,
  },
  {
    Id: 'ExtraBool1',
    Name: t(translations.Asset_ExtraBool1) as string,
    Cell: BooleanRender,
  },
  {
    Id: 'ExtraBool2',
    Name: t(translations.Asset_ExtraBool2) as string,
    Cell: BooleanRender,
  },
  {
    Id: 'ExtraBool3',
    Name: t(translations.Asset_ExtraBool3) as string,
    Cell: BooleanRender,
  },
  {
    Id: 'ExtraBool4',
    Name: t(translations.Asset_ExtraBool4) as string,
    Cell: BooleanRender,
  },
  {
    Id: 'ExtraUrl0',
    Name: t(translations.Asset_ExtraUrl0) as string,
  },
  {
    Id: 'ExtraUrl1',
    Name: t(translations.Asset_ExtraUrl1) as string,
  },
  {
    Id: 'ExtraUrl2',
    Name: t(translations.Asset_ExtraUrl2) as string,
  },
  {
    Id: 'ExtraUrl3',
    Name: t(translations.Asset_ExtraUrl3) as string,
  },
  {
    Id: 'ExtraUrl4',
    Name: t(translations.Asset_ExtraUrl4) as string,
  },
  {
    Id: 'ExtraRichText0',
    Name: t(translations.Asset_ExtraRichText0) as string,
  },
  {
    Id: 'ExtraRichText1',
    Name: t(translations.Asset_ExtraRichText1) as string,
  },
  {
    Id: 'ExtraRichText2',
    Name: t(translations.Asset_ExtraRichText2) as string,
  },
  {
    Id: 'ExtraRichText3',
    Name: t(translations.Asset_ExtraRichText3) as string,
  },
  {
    Id: 'ExtraRichText4',
    Name: t(translations.Asset_ExtraRichText4) as string,
  },
  {
    Id: 'ExtraDDL0',
    Name: t(translations.Asset_ExtraDDL0) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL0Value}</>,
  },
  {
    Id: 'ExtraDDL1',
    Name: t(translations.Asset_ExtraDDL1) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL1Value}</>,
  },
  {
    Id: 'ExtraDDL2',
    Name: t(translations.Asset_ExtraDDL2) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL2Value}</>,
  },
  {
    Id: 'ExtraDDL3',
    Name: t(translations.Asset_ExtraDDL3) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL3Value}</>,
  },
  {
    Id: 'ExtraDDL4',
    Name: t(translations.Asset_ExtraDDL4) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL4Value}</>,
  },
  {
    Id: 'ExtraDDL5',
    Name: t(translations.Asset_ExtraDDL5) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL5Value}</>,
  },
  {
    Id: 'ExtraDDL6',
    Name: t(translations.Asset_ExtraDDL6) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL6Value}</>,
  },
  {
    Id: 'ExtraDDL7',
    Name: t(translations.Asset_ExtraDDL7) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL7Value}</>,
  },
  {
    Id: 'ExtraDDL8',
    Name: t(translations.Asset_ExtraDDL8) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL8Value}</>,
  },
  {
    Id: 'ExtraDDL9',
    Name: t(translations.Asset_ExtraDDL9) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL9Value}</>,
  },
  {
    Id: 'ExtraDDL10',
    Name: t(translations.Asset_ExtraDDL10) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL10Value}</>,
  },
  {
    Id: 'ExtraDDL11',
    Name: t(translations.Asset_ExtraDDL11) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL11Value}</>,
  },
  {
    Id: 'ExtraDDL12',
    Name: t(translations.Asset_ExtraDDL12) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL12Value}</>,
  },
  {
    Id: 'ExtraDDL13',
    Name: t(translations.Asset_ExtraDDL13) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL13Value}</>,
  },
  {
    Id: 'ExtraDDL14',
    Name: t(translations.Asset_ExtraDDL14) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL14Value}</>,
  },
  {
    Id: 'ExtraDDL15',
    Name: t(translations.Asset_ExtraDDL15) as string,
    Cell: ({ value, original }) => !!value && <>{original.ExtraDDL15Value}</>,
  },
  {
    Id: 'BrandCode',
    Name: t(translations.BrandBarcodeNumber) as string,
  },
  {
    Id: 'ServiceContract',
    Name: t(translations.ServiceContract) as string,
  },
  {
    Id: 'POnumber',
    Name: t(translations.PurchaseOrder) as string,
  },
  {
    Id: 'WarrantyEndDate',
    Name: t(translations.WarrantyEndDate) as string,
    Cell: DateRenderer,
  },
  {
    Id: 'LifeDate',
    Name: t(translations.LifeDateView) as string,
    validToRender: ({ value }) => !!value,
    Cell: ({ value, original }) => value && <>{original.Age}</>,
  },
  {
    Id: 'ExportLicense',
    Name: t(translations.ExportLicense) as string,
  },
  {
    Id: 'InternalCode',
    Name: t(translations.InternalEquipmentBarcodeNumber) as string,
  },
  {
    Id: 'AccountingId',
    Name: t(translations.AccountingId) as string,
  },
  {
    Id: 'NotActive',
    Name: t(translations.NotActive) as string,
    Cell: notActive => <BooleanRender value={!notActive.value} />,
  },
  // stub, could be used when full screen asset details will be migrated to react https://prog4biz.atlassian.net/browse/BKT-6902
  // {
  //   Id: 'Articles',
  //   PropName: 'Articles',
  //   Name: t(translations.RelatedPublications),
  //   Cell: ({ value }: { value?: IArticleDto[] }) => {
  //     return <AssetArticles value={value} />;
  //   },
  // },
];
