import { useTheme } from '@material-ui/core';
import * as React from 'react';
import clsx from 'clsx';

import { SidePanelRenderer } from './SidePanelRenderer';
import { PageTypeProps, RenderPageType } from '../../slice/type';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useDispatch } from 'react-redux';
import { useLayoutSlice } from '../../slice';
import { SidePanelPopper, SidePanelRoot } from './wrappers';

export interface SidePanelPopupProps {
  renderPageType?: RenderPageType;
  renderPageProps?: PageTypeProps;
  sidePanelOpen: boolean;
  sidePanelExpanded: boolean;
  sidePanelWidth: number;
}

export const SidePanelPopup = (props: SidePanelPopupProps) => {
  const { checkIsMobile } = useIsMobile();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const isMobile = checkIsMobile();
  const {
    renderPageType,
    renderPageProps,
    sidePanelOpen,
    sidePanelExpanded,
    sidePanelWidth,
  } = props;
  const elementRef = React.useRef<HTMLDivElement>(null);
  const closeSidePanel = React.useCallback(() => {
    dispatch(actions.resetSidePanel());
  }, [actions, dispatch]);
  // manually trigger rerender using a useless usState hook
  // this ensures that the popper is rendered only after the div is rendered and have provided the elementRef value to ancor the popper
  const countState = React.useState(0);
  React.useEffect(() => {
    countState[1](c => c + 1);
    // setCount (rerender) needs to occur after element elementRef.current value is bound
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);
  const theme = useTheme();
  return (
    <SidePanelRoot
      ref={elementRef}
      className={clsx(isMobile ? 'sidePanelMobileRoot' : 'sidePanelRoot', {
        sidePanelRootExpanded: sidePanelExpanded && !isMobile,
        sidePanelRootCollapsed: !sidePanelExpanded && sidePanelOpen,
      })}
    >
      {/* {elementRef.current !== null && ( */}
      <React.Fragment>
        {isMobile ? (
          // <Slide
          //   direction={'down'}
          //   in={sidePanelOpen}
          //   ref={elementRef}
          //   mountOnEnter
          //   unmountOnExit
          //   timeout={{
          //     appear: 100,
          //     enter: 50,
          //     exit: 100,
          //   }}
          // >
          //   <Backdrop
          //     className={'sidePanelBackdrop'}
          //     open={isMobile && sidePanelOpen}
          //     id="backdropPanel"
          //     invisible={!isMobile}
          //     onClick={ev => {
          //       if (ev.currentTarget.id === (ev.target as any).id && isMobile) {
          //         closeSidePanel();
          //       } else {
          //         //ev.preventDefault();
          //       }
          //     }}
          //   >
          <React.Fragment>
            <div
              id="backdropPanel"
              className={clsx('backdrop', { backdropOpen: sidePanelOpen })}
              onClick={ev => {
                if (ev.currentTarget.id === (ev.target as any).id && isMobile) {
                  closeSidePanel();
                } else {
                  //ev.preventDefault();
                }
              }}
            ></div>
            <SidePanelPopper
              theme={theme}
              open={sidePanelOpen || false}
              sidePanelWidth={sidePanelWidth}
              anchorEl={elementRef.current}
              disablePortal
              placement={isMobile ? 'right-start' : 'top-end'}
              transition
              className={clsx({
                rootopen: sidePanelExpanded,
                rootclose: !sidePanelExpanded,
                mobileroot: isMobile,
              })}
              modifiers={{
                keepTogether: { enabled: true },
                preventOverflow: {
                  enabled: true,
                  // altAxis: true,
                  // altBoundary: true,
                  // tether: false,
                  // rootBoundary: 'document',
                  boundariesElement: `${
                    !sidePanelExpanded || isMobile ? 'viewport' : 'window'
                  }`, //scrollParent, window, viewport
                  padding: 0,
                },
                flip: { enabled: false },
              }}
            >
              <div className={'paper'}>
                <React.Fragment>
                  {renderPageType !== undefined &&
                    renderPageProps !== undefined && (
                      <SidePanelRenderer
                        renderPageType={renderPageType}
                        renderPageProps={renderPageProps}
                      />
                    )}
                </React.Fragment>
              </div>
            </SidePanelPopper>
          </React.Fragment>
        ) : (
          //   </Backdrop>
          // </Slide>
          <SidePanelPopper
            open={sidePanelOpen || false}
            theme={theme}
            sidePanelWidth={sidePanelWidth}
            anchorEl={elementRef.current}
            disablePortal
            placement={'left-start'}
            transition
            className={clsx({
              rootopen: sidePanelExpanded,
              rootclose: !sidePanelExpanded,
              mobileroot: isMobile,
            })}
            modifiers={{
              keepTogether: { enabled: true },
              preventOverflow: {
                enabled: true,
                // altAxis: true,
                // altBoundary: true,
                // tether: false,
                // rootBoundary: 'document',
                boundariesElement: 'window', //scrollParent, window, viewport
                padding: 0,
              },
              flip: { enabled: false },
              // offset: {
              //   offset: '0,-10',
              // },
            }}
          >
            <div className={'paper'}>
              <React.Fragment>
                {renderPageType !== undefined && renderPageProps ? (
                  <SidePanelRenderer
                    renderPageType={renderPageType}
                    renderPageProps={renderPageProps}
                  />
                ) : (
                  <div style={{ height: '99%', maxHeight: '99vh' }}></div>
                )}
              </React.Fragment>
            </div>
          </SidePanelPopper>
        )}
      </React.Fragment>
      {/* )} */}
    </SidePanelRoot>
  );
};
