/**
 *
 * ReservationOrDowntimePicker
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Entity } from 'types/common';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { enumToEntityArray } from 'utils/enumKeys';
import { ReservationOrDowntimeEnum } from 'enums/ReservationOrDowntimeEnum';

export interface ReservationOrDowntimePickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'> {}

export function ReservationOrDowntimePicker(
  props: ReservationOrDowntimePickerProps,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const loadData = () => {
    const options = enumToEntityArray(ReservationOrDowntimeEnum, t);
    return Promise.resolve<Entity<number>[]>(options);
  };
  return (
    <AutocompletePicker
      loadData={loadData}
      {...props}
      id={props.id || 'TrainingStateId'}
      placeholder={t(translations.AllValues)}
    />
  );
}
