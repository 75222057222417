import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  BillingActionsDetailsQStringParameters,
  BillingActionsDetailsResponse,
  BillingActionsDetailsState,
  ConvertEntityToModelBillingActionsDetails,
  //ConvertNewBillingActionsDetailsToModel,
} from './types';
import { BillingActionsApi } from '../../../../../../api/BillingActionsApi';
import { billingActionsDetailsActions as actions } from '.';
import { IBillingActionsDto } from '../../../BillingActionsPage/IBillingActionsDto';
import { appSettingsActions } from 'app/slice';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import { translations } from 'locales/translations';
import i18next from 'i18next';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { BillingActionsDetailsProps } from '..';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { AxiosError } from 'axios';

function* doInitCreate(
  action: PayloadAction<BillingActionsDetailsQStringParameters>,
) {
  try {
    const data = yield call(BillingActionsApi.initCreateData, action.payload);
    yield put(actions.initBillingActionsDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initBillingActionsDetails_Error(error));
  }
}

function* doInitUpdate(
  action: PayloadAction<BillingActionsDetailsQStringParameters>,
) {
  try {
    const data = yield call(BillingActionsApi.initUpdateData, action.payload);
    yield put(actions.initUpdateBillingActionsDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initUpdateBillingActionsDetails_Error(Error));
  }
}

function* doCreate(action: PayloadAction<BillingActionsDetailsState>) {
  const httpPayloads: IBillingActionsDto = ConvertEntityToModelBillingActionsDetails(
    action.payload,
    undefined,
  );
  try {
    const result = yield call(
      BillingActionsApi.insertBillingActionsDetails,
      httpPayloads,
    );
    let response: BillingActionsDetailsResponse = result as BillingActionsDetailsResponse;

    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.map(item => {
            return {
              key: 'billingActionsInsert',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'billingActionsInsert',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      if (response.Id) {
        yield put(
          appSettingsActions.addNotification({
            key: 'billingActionsInsert',
            message: response.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id,
              created: true,
              itemName: i18next.t(translations.WorkOrdersPageName),
              detailsType: RenderPageType.AddBillingAction,
              detailsTypeProps: {
                queryParams: {
                  id: '' + response.Id,
                } as BillingActionsDetailsQStringParameters,
                useSidePanel: true,
              } as BillingActionsDetailsProps,
            },
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      } else {
        yield put(
          appSettingsActions.addNotification({
            key: 'billingActionsInsertSuccess',
            message: response.SuccessMessages[0],
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      }
    }

    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.createBillingActionsDetails_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'billingActionsInsert',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createBillingActionsDetails_Error(Error));
  }
}

function* doUpdate(
  action: PayloadAction<{
    current: BillingActionsDetailsState;
    original: BillingActionsDetailsState | undefined;
  }>,
) {
  const httpPayloads: IBillingActionsDto = ConvertEntityToModelBillingActionsDetails(
    action.payload.current,
    action.payload.original,
  );
  try {
    const results = yield call(
      BillingActionsApi.updateBillingActionsDetails,
      httpPayloads,
    );
    let response = results as BillingActionsDetailsResponse;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'billingActionsUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'billingActionsUpdateWarn',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          key: 'billingActionsUpdate',
          message: response.SuccessMessages[0],
          messageType: SnackBarMessageType.openSidepanelDetails,
          messageTypeProps: {
            Id: response.Id,
            created: false,
            itemName: i18next.t(translations.BillingActionsPageName),
            detailsType: RenderPageType.AddBillingAction,
            detailsTypeProps: {
              queryParams: {
                id: '' + response.Id,
              } as BillingActionsDetailsQStringParameters,
              useSidePanel: true,
            } as BillingActionsDetailsProps,
          },
          variant: 'success',
        }),
      );
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateBillingActionsDetails_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'billingActionsUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateBillingActionsDetails_Error(Error));
  }
}

export function* billingActionsDetailsSaga() {
  yield takeLatest(actions.initBillingActionsDetails.type, doInitCreate);
  yield takeLatest(actions.initUpdateBillingActionsDetails.type, doInitUpdate);
  yield takeLatest(actions.createBillingActionsDetails.type, doCreate);
  yield takeLatest(actions.updateBillingActionsDetails.type, doUpdate);
}
