import * as React from 'react';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { useDispatch } from 'react-redux';
import { useAppSettingsSlice } from 'app/slice';

/**
 * Hides the global service group filter on a page.
 * Example of usage on the page componet:
 * ```
 * export const SomePage = withoutGlobalServiceGroupFilter(SomePageContent);
 * ```
 * Usage on loadable:
 * ```
 * export const SomePage = lazyLoad(
 * () => import('./index'),
 * module => withoutGlobalServiceGroupFilter(module.SomePage));
 * ```
 * @param Component Page component
 * @returns page component
 */
export function withoutGlobalServiceGroupFilter<P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> {
  return ({ ...props }: P) => {
    const dispatch = useDispatch();

    const { actions } = useAppSettingsSlice();
    useEffectOnMount(() => {
      dispatch(actions.showGlobalServiceGroupFilter(false));
    });

    return <Component {...(props as P)} />;
  };
}
