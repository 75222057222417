/**
 *
 * FiltersGroupDisplay
 *
 */
import * as React from 'react';
import { FilterDisplay } from '../FilterDisplay';
import { IFilterSettings } from '../IFilterSettings';
import { areSameFilter, filterKey } from '../utils';

export interface FiltersGroupDisplayProps<TRow> {
  appliedFilters: IFilterSettings<TRow>[];
  onChange: (items: IFilterSettings<TRow>[]) => void;
  openFilter: () => void;
}

export function FiltersGroupDisplay<TRow>(
  props: FiltersGroupDisplayProps<TRow>,
) {
  const [items, setItems] = React.useState(
    props.appliedFilters.filter(f => f.value !== null && f.value !== undefined),
  );
  React.useEffect(() => {
    setItems(
      props.appliedFilters.filter(
        f => f.value !== null && f.value !== undefined,
      ),
    );
  }, [props.appliedFilters]);
  const handleDelete = item => {
    props.onChange(
      props.appliedFilters.map(f =>
        !areSameFilter({ f, item }) ? f : { ...f, ...{ value: null } },
      ),
    );
  };
  return (
    <>
      {items
        .filter(f => f.value !== undefined)
        .map((item, index) => (
          <FilterDisplay
            key={`filter-display-${JSON.stringify(filterKey(item))}`}
            filter={item}
            onDelete={handleDelete}
            openFilter={props.openFilter}
          />
        ))}
    </>
  );
}
