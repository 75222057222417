import { IInvoiceBudgetBalanceDto } from 'api/odata/generated/entities/IInvoiceBudgetBalanceDto';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import htmlParse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { toQueryString } from 'utils/url-utils';
import { ExternalLink } from 'app/components/ExternalLink';
import { AlignRight } from '../../components/BasicTable/CellRenderers/AlignRight';
import PercentageRenderer from 'app/components/BasicTable/CellRenderers/PercentageRenderer';

export const useColumns = (endDate: Date) => {
  const { t } = useTranslation();
  const columns: Column<IInvoiceBudgetBalanceDto>[] = [
    {
      accessor: 'ServiceGroup',
      Header: t<string>(translations.ServiceGroup),
    },
    {
      accessor: 'HostBudgetNumber',
      Header: t<string>(translations.HostBudgetNumber),
    },
    {
      accessor: 'UserGroup',
      Header: t<string>(translations.UserGroup),
    },
    //     Institute- tool tip “By Project User Group”
    {
      accessor: 'Institute',
      Header: (
        <Tooltip title={htmlParse(t(translations.ByProjectUserGroup))}>
          <span>{t(translations.Institute)}</span>
        </Tooltip>
      ),
      Cell: ({ row, value }) => (
        <AlignRight>
          <Tooltip title={htmlParse(t(translations.ByProjectUserGroup))}>
            <span>{value}</span>
          </Tooltip>
        </AlignRight>
      ),
    },
    // Department- tool tip “By Project User Group”
    {
      accessor: 'Department',
      Header: (
        <Tooltip title={htmlParse(t(translations.ByProjectUserGroup))}>
          <span>{t(translations.Department)}</span>
        </Tooltip>
      ),
      Cell: ({ row, value }) => (
        <AlignRight>
          <Tooltip title={htmlParse(t(translations.ByProjectUserGroup))}>
            <span>{value}</span>
          </Tooltip>
        </AlignRight>
      ),
    },
    // User Group- tool tip “Project User Group”
    {
      accessor: 'UsergroupIDNO',
      Header: t<string>(translations.IDNo),
    },
    {
      accessor: 'Budget',
      Header: t<string>(translations.BudgetNumber),
      Cell: ({ value, row }) => (
        <ExternalLink
          href={{ path: '/Budget.aspx', search: { id: row.original.BudgetId } }}
        >
          {value}
        </ExternalLink>
      ),
    },
    {
      accessor: 'BudgetAmount',
      Header: t<string>(translations.BudgetAmount),
      Cell: ({ value }) => (
        <AlignRight>
          <span>{value?.toFixed(2)}</span>
        </AlignRight>
      ),
    },
    {
      accessor: 'BudgetAmount',
      Header: t<string>(translations.BudgetAmount),
      Cell: ({ value }) => (
        <AlignRight>
          <span>{value?.toFixed(2)}</span>
        </AlignRight>
      ),
    },
    {
      accessor: 'BudgetHardLimit',
      Header: t<string>(translations.BudgetHardLimit),
      Cell: PercentageRenderer,
    },
    {
      accessor: 'BudgetSoftLimit',
      Header: t<string>(translations.BudgetSoftLimit),
      Cell: PercentageRenderer,
    },
    {
      accessor: 'InvoicedAmount',
      Header: t<string>(translations.TotalInvoiced),
      Cell: ({ value, row }) => (
        <AlignRight>
          <Link
            to={{
              pathname: '/Charges',
              search: toQueryString({
                ugid: row.original.UserGroupId,
                BudgetId: row.original.BudgetId,
                showEstimated: false,
              }).toString(),
            }}
          >
            {value?.toFixed(2)}
          </Link>
        </AlignRight>
      ),
    },
    {
      accessor: 'PaidAmount',
      Header: t<string>(translations.TotlaPaid),
      Cell: ({ value }) => (
        <AlignRight>
          <span>{value?.toFixed(2)}</span>
        </AlignRight>
      ),
    },
    {
      accessor: 'UnpaidBalance',
      Header: (
        <>
          <Tooltip
            title={htmlParse(t(translations.OnlineBudgetStatusBalanceTooltip))}
          >
            <span>{t(translations.UnpaidBalance)}</span>
          </Tooltip>
        </>
      ),
      Cell: ({ row, value }) => (
        <AlignRight>
          <Tooltip
            title={htmlParse(t(translations.OnlineBudgetStatusBalanceTooltip))}
          >
            <span>{value?.toFixed(2)}</span>
          </Tooltip>
        </AlignRight>
      ),
    },
    {
      accessor: 'OverallEstimatedBalance',
      Header: (
        <Tooltip
          title={htmlParse(t(translations.OverallEstimatedBalanceTooltip))}
        >
          <span>{t(translations.OverallEstimatedBalance)}</span>
        </Tooltip>
      ),
      Cell: ({ value }) => (
        <AlignRight>
          <Tooltip title={t(translations.OverallEstimatedBalanceTooltip)}>
            <span>{String(value.toFixed(2))}</span>
          </Tooltip>
        </AlignRight>
      ),
    },
    {
      accessor: 'EstimatedCharges',
      Header: (
        <Tooltip
          title={htmlParse(
            t(translations.OnlineBudgetStatusEstimatedChargesTooltip),
          )}
        >
          <span>{t(translations.EstimatedCharges)}</span>
        </Tooltip>
      ),
      Cell: ({ value, row }) => (
        <AlignRight>
          <Tooltip
            title={htmlParse(
              t(translations.OnlineBudgetStatusEstimatedChargesTooltip),
            )}
          >
            <span>
              <Link
                to={{
                  pathname: '/Charges',
                  search: toQueryString({
                    //ServiceGroupId: row.original.ServiceGroupId,
                    ugid: row.original.UserGroupId,
                    //bid: row.original.BudgetId,
                    BudgetId: row.original.BudgetId,
                    //Estimated: true,
                    showEstimated: true,
                  }).toString(),
                }}
              >
                {value?.toFixed(2)}
              </Link>
            </span>
          </Tooltip>
        </AlignRight>
      ),
    },
  ];
  return columns;
};
