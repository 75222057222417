import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.offlineServiceState || initialState;

export const selectProcessing = createSelector(
  [selectSlice],
  state => state.processing,
);
export const selectInitConsumablesProcessing = createSelector(
  [selectSlice],
  state => state.consumablesProcessing,
);
export const selectService = createSelector(
  [selectSlice],
  state => state.detailsState,
);
export const selectServiceProcessing = createSelector(
  [selectService],
  state => state.processing,
);
export const selectServicenError = createSelector(
  [selectService],
  state => state.error,
);
export const selectServiceHasError = createSelector(
  [selectService],
  state => state.hasErrors,
);
export const selectServiceCompleted = createSelector(
  [selectService],
  state => state.completed,
);
export const selectServiceData = createSelector(
  [selectService],
  state => state.data,
);
export const selectConnectedInitState = createSelector(
  [selectSlice],
  state => state.connectedDataState,
);
export const selectServiceChangeState = createSelector(
  [selectSlice],
  state => state.serviceChangeResultState,
);
export const selectFieldHandler = createSelector(
  [selectSlice],
  state => state.valueHandler,
);
export const selectCreditState = createSelector(
  [selectSlice],
  state => state.creditState,
);
export const selectCredit = createSelector(
  [selectCreditState],
  state => state.data,
);
export const selectCreditProcessing = createSelector(
  [selectCreditState],
  state => state.processing,
);
export const selectCreditCompleted = createSelector(
  [selectCreditState],
  state => state.completed,
);
export const selectHasCharges = createSelector(
  [selectSlice],
  state => state.hasCharges,
);
export const selectConsumablesState = createSelector(
  [selectSlice],
  state => state.consumablesState,
);
export const selectSavedReservationService = createSelector(
  [selectSlice],
  state => state.savedReservationService,
);
export const selectConsumablesProcessing = createSelector(
  [selectConsumablesState],
  state => state.processing,
);
export const selectConsumablesData = createSelector(
  [selectConsumablesState],
  state => state.data,
);
export const selectConsumablesError = createSelector(
  [selectConsumablesState],
  state => state.error,
);
export const selectConsumablesHasErrors = createSelector(
  [selectConsumablesState],
  state => state.hasErrors,
);
export const selectConsumablesCompleted = createSelector(
  [selectConsumablesState],
  state => state.completed,
);
export const selectConsumablesValueHandler = createSelector(
  [selectSlice],
  state => state.consumablesValueHandler,
);
export const selectSelectedServiceType = createSelector(
  [selectSlice],
  state => state.selectedServiceTypes,
);
export const selectEditCreatableService = createSelector(
  [selectSlice],
  state => state.editCreatableService,
);
export const selectBarcodeScanned = createSelector(
  [selectSlice],
  state => state.barcodeScanned,
);
export const selectRefreshConsumableServices = createSelector(
  [selectSlice],
  state => state.refeshOfflineServices,
);
export const selectStockRenewalsState = createSelector(
  [selectSlice],
  state => state.stockRenewalState,
);
export const selectStockRenewalProcessing = createSelector(
  [selectStockRenewalsState],
  state => state.processing,
);
export const selectStockRenewalData = createSelector(
  [selectStockRenewalsState],
  state => state.data,
);
export const selectStockRenewalError = createSelector(
  [selectStockRenewalsState],
  state => state.error,
);
export const selectStockRenewalHasErrors = createSelector(
  [selectStockRenewalsState],
  state => state.hasErrors,
);
export const selectStockRenewalCompleted = createSelector(
  [selectStockRenewalsState],
  state => state.completed,
);
export const selectStockRenewalValueHandler = createSelector(
  [selectSlice],
  state => state.stockRenewalValueHandler,
);
export const selectRenewStockState = createSelector(
  [selectSlice],
  state => state.renewStockState,
);
export const selectRenewStockProcessing = createSelector(
  [selectRenewStockState],
  state => state.processing,
);
export const selectRenewStockData = createSelector(
  [selectRenewStockState],
  state => state.data,
);
export const selectRenewStockError = createSelector(
  [selectRenewStockState],
  state => state.error,
);
export const selectRenewStockHasErrors = createSelector(
  [selectRenewStockState],
  state => state.hasErrors,
);
export const selectRenewStockCompleted = createSelector(
  [selectRenewStockState],
  state => state.completed,
);
export const selectRenewStockValueHandler = createSelector(
  [selectSlice],
  state => state.renewStockValueHandler,
);
export const selectIsEdit = createSelector(
  [selectSlice],
  state => state.isEdit,
);

export const selectInventoryBatchState = createSelector(
  [selectSlice],
  state => state.inventoryBatchState,
);
export const selectInventoryBatchProcessing = createSelector(
  [selectInventoryBatchState],
  state => state.processing,
);
export const selectInventoryBatchData = createSelector(
  [selectInventoryBatchState],
  state => state.data,
);
export const selectInventoryBatchError = createSelector(
  [selectInventoryBatchState],
  state => state.error,
);
export const selectInventoryBatchHasErrors = createSelector(
  [selectInventoryBatchState],
  state => state.hasErrors,
);
export const selectInventoryBatchCompleted = createSelector(
  [selectInventoryBatchState],
  state => state.completed,
);
export const selectInventoryBatchValueHandler = createSelector(
  [selectSlice],
  state => state.inventoryBatchValueHandler,
);
export const selectInventoryBatchIsEdit = createSelector(
  [selectSlice],
  state => state.batchIsEdit,
);
export const selectInventoryBatchEditable = createSelector(
  [selectSlice],
  state => state.inventoryBatchEditable,
);
export const selectCreatableService = createSelector(
  [selectSlice],
  state => state.getCreatableService,
);
