/**
 *
 * MobileBarcodeScanner
 *
 */
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import React from 'react';
import { useDispatch } from 'react-redux';

import { BARCODE_SCANNED } from 'react-usb-barcode-scanner';
export interface MobileBarcodeScannerProps {}

export const MobileBarcodeScanner = React.memo(function MobileBarcodeScanner(
  props: MobileBarcodeScannerProps,
) {
  const dispatch = useDispatch();
  const handleBarcodeScanned = (barcode: string) => {
    dispatch({
      type: BARCODE_SCANNED,
      payload: {
        data: barcode,
      },
    });
  };
  // sets the mobile barcode callback that will be called directly from the mobile app
  useEffectOnMount(() => {
    (window as any).mobileBarcodeScanCallback = handleBarcodeScanned;
    return () => ((window as any).mobileBarcodeScanCallback = undefined);
  });
  return <div></div>;
});
