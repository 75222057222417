/**
 *
 * FilterDisplay
 *
 */
import { Chip, ChipProps, Theme, useTheme } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { IFilterSettings } from '../IFilterSettings';
import { DetectIsMobile } from 'utils/mobileDetect';
import { dateUtils } from 'utils/date-utils';
import { PresetDatesRange } from 'types/PresetDatesRange';
import { Entity } from 'types/common';
import { IsEntity, IsNotEmptyArray } from '../AppliedFilterComponent';
import { Skeleton } from '@material-ui/lab';
import BasicTypography from 'app/components/Typography/BasicTypography';
import styled from 'styled-components';

export interface FilterDisplayProps<TRow> {
  filter: IFilterSettings<TRow>;
  onDelete: (item: IFilterSettings<TRow>) => void;
  openFilter: () => void;
}
export const FilterChip = styled(
  ({
    theme,
    ...other
  }: {
    theme: Theme;
  } & ChipProps) => <Chip {...other} />,
)`
  &.filter-display-root {
    gap: ${props => props.theme.spacing(1)}px;
    max-width: 58ch;
    border-radius: 8px;
    background-color: ${props => props.theme.palette.common.grayDividers};
  }
`;
export function FilterDisplay<TRow>(props: FilterDisplayProps<TRow>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = DetectIsMobile();
  const value = props.filter.value;
  const displayValue =
    typeof value === 'string'
      ? value
      : typeof value === 'number'
      ? value?.toString()
      : value instanceof Date
      ? dateUtils.shortDateFormat(value)
      : value instanceof PresetDatesRange
      ? value?.GetPresetDatesRangeDisplayString(t)
      : IsEntity(value)
      ? (value as Entity<number>).Name
      : IsNotEmptyArray(value)
      ? (value as Array<Entity<number>>).map(v => v.Name).join(', ')
      : props.filter.getDisplay !== undefined
      ? props.filter.getDisplay(value)
      : t(translations.NA);
  const isInversed = props.filter.isInversed ? 'Not ' : '';

  const handleDelete = event => {
    props.onDelete(props.filter);
  };
  return !!props.filter.renderFilterDisplay ? (
    <>{props.filter.renderFilterDisplay(props)}</>
  ) : (
    <>
      <FilterChip
        theme={theme}
        className={'filter-display-root'}
        onClick={props.openFilter}
        label={
          <>
            <BasicTypography
              component="span"
              noWrap
              style={
                isMobile
                  ? {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '10ch',
                    }
                  : undefined
              }
            >
              {props.filter.displayName}
            </BasicTypography>
            :&ensp;
            {displayValue === undefined || displayValue === null ? (
              <Skeleton width={'15ch'} />
            ) : props.filter.displayName.length + displayValue.length > 40 ? (
              <Tooltip
                title={isInversed + displayValue}
                placement="bottom-start"
                arrow
              >
                <span>
                  <BasicTypography
                    component="span"
                    noWrap
                    style={
                      isMobile
                        ? {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '30ch',
                          }
                        : undefined
                    }
                  >
                    {isInversed}
                    {displayValue}
                  </BasicTypography>
                </span>
              </Tooltip>
            ) : (
              <BasicTypography
                component="span"
                noWrap
                style={
                  isMobile
                    ? {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '30ch',
                      }
                    : undefined
                }
              >
                {isInversed}
                {displayValue}
              </BasicTypography>
            )}
          </>
        }
        onDelete={handleDelete}
      />
    </>
  );
}
