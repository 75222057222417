import { Typography, TypographyProps } from '@material-ui/core';
import { BookitTypographyVariant } from '@material-ui/core/styles/createTypography';
import { capitalize } from 'lodash';
import * as React from 'react';
import { typographyComponentMapping } from 'styles/typography/bookitTypography';
import clsx from 'clsx';

export interface BasicTypographyProps extends Omit<TypographyProps, 'variant'> {
  variant?: BookitTypographyVariant;
  component?: React.ElementType;
  italic?: boolean;
  style?: React.CSSProperties;
  className?: string;
}
export const BasicTypography = (props: BasicTypographyProps) => {
  const {
    children,
    variant = 'bodyM',
    italic,
    component,
    color,
    style,
    className,
    ...other
  } = props;
  return (
    <Typography
      component={component || typographyComponentMapping[variant] || 'label'}
      color={color ?? 'primary'}
      className={clsx(`variant${capitalize(variant)}`, className, {
        italic: italic,
      })}
      style={style}
      {...other}
    >
      {children}
    </Typography>
  );
};
export default BasicTypography;
