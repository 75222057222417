import { httpClient } from 'api/HttpClient';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { ODataFilterBuilder } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { Entity } from 'types/common';

export interface RelevantBudgetPickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'> {
  userGroupId: string;
  userName: string;
  serviceId?: number;
}

const url = '/api/odata/v4/BudgetFilter';

export const RelevantBudgetPicker = (props: RelevantBudgetPickerProps) => {
  const loadData = (searchTerm: string | null) => {
    const params: {
      $orderby: string;
      $filter?: string;
      $select?: string;
      userGroupId?: string;
      userName?: string;
      serviceId?: number;
    } = {
      $orderby: 'Name asc',
      $select: 'Id,Name,IsDefault',
    };
    params.userGroupId = props.userGroupId;
    params.userName = props.userName;
    params.serviceId = props.serviceId;
    if (searchTerm !== null) {
      params.$filter = new ODataFilterBuilder<Entity<number>>({
        predicates: [],
        stringColumns: ['Name'],
        stringSearch: searchTerm,
      }).toString();
    }
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  };
  return (
    <AutocompletePicker
      loadData={loadData}
      {...props}
      id={props.id || 'reservationbudgetId'}
    />
  );
};
export const DefaultBudget = (
  userGroupId: string | null,
  userName: string,
  selectedBudget: Entity<number> | null,
  serviceId?: number,
): Promise<Entity<number> | null> => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const params: {
          $orderby: string;
          $filter?: string;
          $select?: string;
          userGroupId?: string;
          userName?: string;
          serviceId?: number;
        } = {
          $orderby: 'Name asc',
          $select: 'Id,Name,IsDefault',
        };
        params.userGroupId = userGroupId ?? undefined;
        params.userName = userName;
        params.serviceId = serviceId;

        let budgets = await httpClient
          .get(url, params)
          .then(response => response.value as Entity<number>[]);
        if (!!budgets && budgets.length > 0) {
          if (selectedBudget !== null) {
            if (budgets.some(f => f.Id === selectedBudget.Id)) {
              resolve(selectedBudget);
            } else {
              if (budgets.length === 1) {
                resolve(budgets[0]);
              } else {
                let defaults = budgets.filter(
                  f => (f as IBudgetFilterDto).IsDefault === true,
                );
                if (!!defaults && defaults.length > 0) {
                  resolve(defaults[0]);
                } else {
                  resolve(null);
                }
              }
            }
          } else {
            if (budgets.length === 1) {
              resolve(budgets[0]);
            } else {
              let defaults = budgets.filter(
                f => (f as IBudgetFilterDto).IsDefault === true,
              );
              if (!!defaults && defaults.length > 0) {
                resolve(defaults[0]);
              } else {
                resolve(null);
              }
            }
          }
        } else {
          resolve(null);
        }
      } catch {
        resolve(null);
      }
    })();
  });
};
