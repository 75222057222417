/**
 *
 * ContentBox
 *
 */
import * as React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  BoxProps,
} from '@material-ui/core';
import { DetectIsMobile } from 'utils/mobileDetect';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';

/**
 * Provides standard spacing for direct children of FrontendLayout
 */
export function FrontendLayoutContentBox({ className, ...props }: BoxProps) {
  const isMobile = DetectIsMobile();
  const classes = useStyles({ isMobile: isMobile });
  return <Box className={clsx(classes.root, className)} {...props}></Box>;
}

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      margin: isMobile ? theme.spacing(2, 2, 0) : theme.spacing(4, 4, 0),
    },
  }),
);
