import { cyan } from '@material-ui/core/colors';

interface InfoColors {
  colorInfoFull: string;
  colorInfoDark: string;
  colorInfoHover: string;
  colorInfoRegular: string;
  colorInfoComponents: string;
  colorInfoComponentsLight: string;
  colorInfoBorders: string;
  colorInfoDisabled: string;
  colorInfoBgHover: string;
  colorInfoBg: string;
  colorInfoBgLight: string;
}
const infoColors: InfoColors = {
  colorInfoFull: cyan[900],
  colorInfoDark: cyan[900],
  colorInfoHover: cyan[800],
  colorInfoRegular: cyan[700],
  colorInfoComponents: cyan[600],
  colorInfoComponentsLight: cyan[500],
  colorInfoBorders: cyan[400],
  colorInfoDisabled: cyan[300],
  colorInfoBgHover: cyan[200],
  colorInfoBg: cyan[100],
  colorInfoBgLight: cyan[50],
};

export default infoColors;
