import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Button } from 'app/components/BasicButtons/Button';
import { downloadFile } from 'utils/url-utils';

interface DownloadAllFilesProps {
  serviceRequestId: number;
}

export function DownloadAllFiles(props: DownloadAllFilesProps) {
  const { t } = useTranslation();
  const url = `/api/odata/v4/ServiceRequests(${props.serviceRequestId})/Files`;
  const [busy, setBusy] = React.useState(false);
  const handleClick = React.useCallback(
    async event => {
      event.preventDefault();
      if (busy) {
        return;
      }
      setBusy(true);
      try {
        await downloadFile({ path: url });
        setBusy(false);
      } catch (error) {
        setBusy(false);
      }

      return false;
    },
    [busy, url],
  );
  return (
    <Button
      component={'a'}
      href={url}
      size="small"
      variant="white"
      onClick={handleClick}
      processing={busy}
      disabled={busy}
    >
      {t(translations.DownloadAllFiles)}
    </Button>
  );
}
