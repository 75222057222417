/**
 *
 * InvoiceRowReferenceLinks
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Tooltip } from '../BasicTooltips/Tooltip';
import { Link } from 'app/components/ExternalLink';
import { ReservationEditButton } from '../ReservationEditButton';
import { OtherServiceEditLinkprops } from 'app/pages/OtherServiceDetails/OtherServiceDetailsPage';
import { GetServiceRequestDetailsPath } from 'app/pages/Samples/RequestSamplesPage/slice/path';

export interface InvoiceRowReferenceLinksProps {
  ReservationId: number | null;
  UsageId: number | null;
  OfflineServiceId: number | null;
  ServiceRequestId: number | null;
  SubscriptionId: number | null;
}

export function InvoiceRowReferenceLinks(props: InvoiceRowReferenceLinksProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  let references: Array<{
    id: number | null;
    link: string;
    title: string;
  }> = [];

  if (props.UsageId)
    references.push({
      id: props.UsageId,
      link: '/UsageDetails.aspx?id=' + props.UsageId,
      title: t(translations.menu_UsageDetails) as string,
    });

  if (props.ServiceRequestId)
    references.push({
      id: props.ServiceRequestId,
      link: GetServiceRequestDetailsPath(props.ServiceRequestId),
      title: t(translations.ServiceRequestDetails) as string,
    });

  if (props.SubscriptionId)
    references.push({
      id: props.SubscriptionId,
      link: '/Invoices/Subscriptions/Edit.aspx?id=' + props.SubscriptionId,
      title: t(translations.SubscriptionDetails) as string,
    });

  let result = references.map((item, i) => {
    return (
      <React.Fragment key={i}>
        <Tooltip title={item.title} arrow>
          <span>
            <Link href={item.link}>{item.id}</Link>
          </span>
        </Tooltip>
        <br />
      </React.Fragment>
    );
  });
  return (
    <>
      {props.ReservationId !== null && (
        <ReservationEditButton
          variant="text"
          reservationId={props.ReservationId}
        />
      )}
      {props.OfflineServiceId !== null && (
        <OtherServiceEditLinkprops id={props.OfflineServiceId} />
      )}
      {result}
    </>
  );
}
