import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gridGap: '4px',
      padding: '0',
      width: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gridGap: '16px',
      padding: '16px 0px',
      //boxShadow: 'inset 0px -1px 0px #E0E5EC',
      width: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0',
      width: 'inherit',
    },
  }),
);
