import {
  CalendarMessagesDetailsQStringParameters,
  CalendarMessagesDetailsState,
  CalendarMessagesState,
  ConvertModelToEntityCalendarMessagesDetails,
} from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { calendarMessagesDetailsSaga } from './saga';
import { ICalendarMessagesDto } from 'app/pages/CalendarMessages/CalendarMessagesPage/ICalendarMessagesDto';

export const initialState: CalendarMessagesState = {
  processing: false,
  createState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  updateState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
};

const slice = createSlice({
  name: 'calendarMessagesDetail',
  initialState,
  reducers: {
    initCalendarMessagesDetails(
      state,
      action: PayloadAction<CalendarMessagesDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initUpdateCalendarMessagesDetails(
      state,
      action: PayloadAction<CalendarMessagesDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initCalendarMessagesDetails_Success(
      state,
      action: PayloadAction<ICalendarMessagesDto>,
    ) {
      state.processing = false;
      state.createState.data = ConvertModelToEntityCalendarMessagesDetails(
        action.payload,
      );
    },
    initUpdateCalendarMessagesDetails_Success(
      state,
      action: PayloadAction<ICalendarMessagesDto>,
    ) {
      state.processing = false;
      state.updateState.data = ConvertModelToEntityCalendarMessagesDetails(
        action.payload,
      );
    },
    initCalendarMessagesDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    initUpdateCalendarMessagesDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    //====================================================================
    resetUpdateCalendarMessagesDetailsState(state, action: PayloadAction) {
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    resetCreateCalendarMessagesDetailsState(state, action: PayloadAction) {
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    //====================================================================
    updateCalendarMessagesDetails(
      state,
      action: PayloadAction<{
        current: CalendarMessagesDetailsState;
        original: CalendarMessagesDetailsState | undefined;
      }>,
    ) {
      state.updateState.processing = true;
      state.updateState.completed = undefined;
      state.updateState.hasErrors = undefined;
    },
    updateCalendarMessagesDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.updateState.processing = false;
      state.updateState.hasErrors = action.payload.hasErrors;
      state.updateState.completed = true;
    },
    updateCalendarMessagesDetails_Error(state, action: PayloadAction<any>) {
      state.updateState.processing = false;
      state.updateState.completed = true;
      state.updateState.hasErrors = true;
    },
    //====================================================================
    createCalendarMessagesDetails(
      state,
      action: PayloadAction<CalendarMessagesDetailsState>,
    ) {
      state.createState.processing = true;
      state.createState.completed = undefined;
      state.createState.hasErrors = undefined;
    },
    createCalendarMessagesDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.createState.processing = false;
      state.createState.hasErrors = action.payload.hasErrors;
      state.createState.completed = true;
    },
    createCalendarMessagesDetails_Error(state, action: PayloadAction<any>) {
      state.createState.processing = false;
      state.createState.completed = true;
      state.createState.hasErrors = true;
    },
  },
});

export const { actions: calendarMessagesDetailsActions } = slice;

export const useCalendarMessagesDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: calendarMessagesDetailsSaga });
  return { actions: slice.actions };
};
