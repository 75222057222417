import * as React from 'react';
import { Box, MenuItem } from '@material-ui/core';
import { dateUtils } from 'utils/date-utils';
import { IFilterSettings } from './IFilterSettings';
import { PresetDatesRange } from 'types/PresetDatesRange';
import { FilterValueType } from './FilterValueType';
//hide for now all the expressions between the field name and its value (mainly “is”) ? We will go back to these once we implement additional expressions and not just one per each filter
//import { operatorToString } from 'api/odata/ODataFilter';
import { ButtonLabel } from 'app/components/Typography';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Entity, EntityType } from 'types/common';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Button } from 'app/components/BasicButtons/Button';
import { isArray } from 'lodash';
import {
  Directions,
  FilterBox,
} from 'app/components/BasicTable/BasicFilter/FilterBox';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Condition } from 'api/odata/ODataFilter';
import { DropDown } from 'app/components/DropDown';
import { bookitColors } from 'styles/colors/bookitColors';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useSelector } from 'react-redux';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { BasicTypography } from 'app/components/Typography/BasicTypography';

export interface AppliedFilterComponentProps<T> {
  item: IFilterSettings<T>;
  onRemove: (item: IFilterSettings<T>) => void;
  onApply: (item: IFilterSettings<T>) => void;
  direction: Directions;
}

export function IsEntity(
  entity: FilterValueType | undefined,
): entity is Entity<number> {
  if (entity === undefined || entity === null) {
    return false;
  }
  if ((entity as Entity<number>).Id !== undefined) {
    return true;
  }
  return false;
}

export function IsEntityType(
  entity: FilterValueType | undefined,
): entity is EntityType {
  if (entity === undefined || entity === null) {
    return false;
  }
  return (entity as EntityType).Id !== undefined;
}

export function IsEntityArray(entity: FilterValueType | undefined) {
  if (entity === undefined || entity === null) {
    return false;
  }
  if (!isArray(entity)) {
    return false;
  }
  if ((entity as Entity<number>[])?.some(f => f.Id !== undefined)) {
    return true;
  }
  return false;
}

export function IsEntityString(
  entity: FilterValueType | undefined,
): entity is Entity<string> {
  if (entity === undefined || entity === null) {
    return false;
  }
  if ((entity as Entity<string>).Id !== undefined) {
    return typeof (entity as Entity<string>).Id == 'string';
  }
  return false;
}

export function IsEmptyArray(entity: FilterValueType | undefined): boolean {
  return Array.isArray(entity) && !entity.length;
}

export function IsNotEmptyArray(
  entity: FilterValueType | undefined,
): entity is Extract<FilterValueType, any[]> {
  return Array.isArray(entity) && !!entity.length;
}

function PureAppliedFilterComponent<TRow>({
  item,
  onRemove,
  onApply,
  direction = 'vertical',
}: AppliedFilterComponentProps<TRow>) {
  const { checkIsMobile, isLandscape } = useIsMobile();
  const isMobile = checkIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const showShortView = React.useMemo(() => {
    return isMobile || (sidePanelOpen && !sidePanelExpanded);
  }, [isMobile, sidePanelExpanded, sidePanelOpen]);
  const { t } = useTranslation();
  const [value, setValue] = React.useState<FilterValueType | undefined>(
    item.value,
  );
  React.useEffect(() => {
    setValue(item.value);
  }, [item.value]);

  const [open, setOpen] = React.useState(item.open);
  const [predict, setPredict] = React.useState<(string | Condition<TRow>)[]>(
    !!item.filterData && item.filterData.length > 0 && !!item.filterPredicates
      ? item.filterData.map(f =>
          !!item.filterPredicates
            ? item.filterPredicates(f.depValue, f.depKey)[0]
            : '',
        )
      : [],
  );
  const onOpen = () => {
    setPredict(() =>
      !!item.filterData && item.filterData.length > 0 && !!item.filterPredicates
        ? item.filterData.map(f =>
            !!item.filterPredicates
              ? item.filterPredicates(f.depValue, f.depKey)[0]
              : '',
          )
        : [],
    );
  };

  const handleChange = value => {
    setValue(value);
    applyItem(value);
  };

  const applyItem = value => {
    setOpen(false);
    onApply({
      ...item,
      ...{ value: value },
      ...{ open: false },
    });
  };

  const handleRemoveClick = () => {
    onRemove(item);
  };
  //const handleClickAway = () => {};

  const labelFunc =
    value instanceof PresetDatesRange
      ? (date: MaterialUiPickersDate, invalidLabel: string) => {
          const res = dateUtils.shortDateTimeFormat(date);
          return res === null ? invalidLabel : res;
        }
      : undefined;
  const canHaveInversed = React.useMemo(() => {
    return !item.customFilter && !item.inversionDisabled;
  }, [item.customFilter, item.inversionDisabled]);
  const toggleInversed = React.useCallback(
    (isInversed: boolean) => {
      if (!!item.isInversed === isInversed) return;
      onApply({ ...item, isInversed: !item.isInversed });
    },
    [item, onApply],
  );
  return (isMobile || showShortView) && !isLandscape ? (
    <React.Fragment>
      <FilterBox
        id={`filter-box-${item.fieldName}-${item.fieldName1 ?? item.id}`}
        direction={direction}
      >
        <div
          style={{
            display: 'flex',
            gap: 16,
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            margin: 1,
          }}
        >
          <BasicTypography
            //htmlFor={item.fieldName as string}
            // ariaId={`${item.fieldName as string}_label`}
            variant={'buttonM'}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '20ch',
              color: open ? bookitColors.primary.regular : 'initial',
              flexShrink: 0,
            }}
          >
            {item.displayName}
          </BasicTypography>
          <Button
            disabled={item.notNullable}
            title={
              item.notNullable ? t(translations.NonRemovableFilter) : undefined
            }
            color="default"
            variant="ghost"
            size="small"
            onClick={handleRemoveClick}
            aria-label="Remove"
          >
            <Icon icon={['fas', 'xmark']} />
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            gap: 16,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
            flexWrap: 'wrap',
            margin: 1,
          }}
        >
          <DropDown
            color="default"
            disabled={!canHaveInversed}
            variant="white"
            size="small"
            id={`invers-drop-${item.fieldName}-${item.fieldName1 ?? item.id}`}
            menuChildren={close => [
              <MenuItem
                button
                key={'is'}
                onClick={() => {
                  toggleInversed(false);
                  close();
                }}
                selected={!item.isInversed}
              >
                {'Is'}
              </MenuItem>,
              <MenuItem
                button
                key={'is_not'}
                onClick={() => {
                  toggleInversed(true);
                  close();
                }}
                selected={!!item.isInversed}
              >
                {'Is not'}
              </MenuItem>,
            ]}
          >
            {item.isInversed ? 'Is not' : 'Is'}
          </DropDown>

          <item.component
            id={item.fieldName as string}
            onChange={handleChange}
            //onClose={handleClickAway}
            value={value}
            fullWidth={!isMobile}
            open={false} //{item.open ?? false} - changed according to BKT-9487 v39.11 HF: Filter menus - drop down open by default
            additionalItem={item.additionalItem}
            labelFunc={labelFunc}
            customData={item.customData}
            label={item.displayName}
            dateTimeClear={item.dateTimeClear}
            predicates={predict}
            onOpen={onOpen}
            urlType={item.urlType}
            withoutServiceGroups={item.withoutServiceGroups}
          />
        </div>
      </FilterBox>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {/* <ClickAwayListener onClickAway={handleClickAway}> */}
      <FilterBox
        id={`filter-box-${item.fieldName}-${item.fieldName1}`}
        direction={direction}
      >
        <ButtonLabel
          //htmlFor={item.fieldName as string}
          // ariaId={`${item.fieldName as string}_label`}
          style={{
            color: open ? bookitColors.primary.regular : 'initial',
            flexShrink: 0,
          }}
        >
          {item.displayName}
        </ButtonLabel>
        <DropDown
          color="default"
          disabled={!canHaveInversed}
          variant="white"
          id={`invers-drop-${item.fieldName}-${item.fieldName1 ?? item.id}`}
          size="small"
          menuChildren={close => [
            <MenuItem
              button
              key={'is'}
              onClick={() => {
                toggleInversed(false);
                close();
              }}
              selected={!item.isInversed}
            >
              {'Is'}
            </MenuItem>,
            <MenuItem
              button
              key={'is_not'}
              onClick={() => {
                toggleInversed(true);
                close();
              }}
              selected={!!item.isInversed}
            >
              {'Is not'}
            </MenuItem>,
          ]}
        >
          {item.isInversed ? 'Is not' : 'Is'}
        </DropDown>
        <Box
          style={{
            //maxWidth: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 8,
          }}
        >
          <item.component
            id={item.fieldName as string}
            onChange={handleChange}
            //onClose={handleClickAway}
            value={value}
            fullWidth={true}
            open={false} //{item.open ?? false} - changed according to BKT-9487 v39.11 HF: Filter menus - drop down open by default
            additionalItem={item.additionalItem}
            labelFunc={labelFunc}
            customData={item.customData}
            label={item.displayName}
            dateTimeClear={item.dateTimeClear}
            predicates={predict}
            onOpen={onOpen}
            urlType={item.urlType}
            withoutServiceGroups={item.withoutServiceGroups}
          />
        </Box>

        <Button
          disabled={item.notNullable}
          title={
            item.notNullable ? t(translations.NonRemovableFilter) : undefined
          }
          color="default"
          variant="ghost"
          size="small"
          onClick={handleRemoveClick}
          aria-label="Remove"
        >
          <Icon icon={['fas', 'xmark']} />
        </Button>
      </FilterBox>
      {/* </ClickAwayListener> */}
    </React.Fragment>
  );
}

export const AppliedFilterComponent = React.memo(
  PureAppliedFilterComponent,
  (prevProps, nextProps) => {
    const x = props =>
      JSON.stringify({
        fieldName: props.item.fieldName,
        fieldName1: props.item.fieldName1,
        value: props.item.value,
        predicates: props.item.predicetes,
        isInversed: props.item.isInversed,
      });
    var result = x(prevProps) === x(nextProps);

    return result;
  },
) as typeof PureAppliedFilterComponent;
