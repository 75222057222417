import { Caption } from 'app/components/Typography';
import { FormikErrors } from 'formik';
import styled from 'styled-components';

export interface ErrorsSummuryProps<T> {
  errors: FormikErrors<T>;
}
const SummuryBox = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export function ErrorsSummury<T>(props: ErrorsSummuryProps<T>) {
  const data = Object.entries(props.errors).map(f => {
    return { name: f[0], msg: f[1] };
  });
  return data.length > 0 ? (
    <SummuryBox>
      {data.map(item => (
        <Caption color="error" key={item.name}>
          {item.msg as string}
        </Caption>
      ))}
    </SummuryBox>
  ) : null;
}
