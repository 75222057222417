/**
 * UserProfileSettings
 */
import { UserProfileSettings } from 'types/UserProfileSettings';
export const USER_PROFILE_KEYS = ['SideBarExpanded'] as const;
export class UserProfile {
  savedSettings: UserProfileSettings[];
  constructor(settings: UserProfileSettings[]) {
    this.savedSettings = settings;
  }
  public GetSettingByKey(key: string): UserProfileSettings | null {
    if (this.savedSettings.length > 0) {
      let sett = this.savedSettings.find((value, index) => value.Key === key);
      return sett === undefined ? null : sett;
    } else {
      return null;
    }
  }
  public GetSettingByKeyOrDefault(key: string): UserProfileSettings {
    let defaultSetting = {
      Key: key,
      Value: '',
      Enabled: false,
      Selected: false,
      UserName: '',
      Id: 0,
      Name: '',
      AdGroup: '',
      DefaultGroups: '',
      IsMultiple: null,
      StaffEnabled: false,
    } as UserProfileSettings;
    if (this.savedSettings.length > 0) {
      let sett = this.savedSettings.find((value, index) => value.Key === key);
      return sett === undefined ? defaultSetting : sett;
    } else {
      return defaultSetting;
    }
  }
  public UpdateSettingByKey(key: string): UserProfileSettings {
    let defaultSetting = {
      Key: key,
      Value: '',
      Enabled: false,
      Selected: false,
      UserName: '',
      Id: 0,
      Name: '',
      AdGroup: '',
      DefaultGroups: '',
      IsMultiple: null,
      StaffEnabled: false,
    } as UserProfileSettings;
    if (this.savedSettings.length > 0) {
      let sett = this.savedSettings.find((value, index) => value.Key === key);
      return sett === undefined ? defaultSetting : sett;
    } else {
      return defaultSetting;
    }
  }
  public GetSettingValueBy(
    comparator: (item: UserProfileSettings) => boolean,
  ): UserProfileSettings | undefined {
    return this.savedSettings.find(comparator);
  }

  public GetSettingValueByKey(key: string): any | null {
    if (this.savedSettings.length > 0) {
      let sett = this.savedSettings.find((value, index) => value.Key === key);
      switch (key) {
        case 'SideBarExpanded': {
          return sett === undefined ? true : sett.Enabled;
        }
      }
    }
    return null;
  }

  public GetSideBarExpanded(key: string): any | null {
    if (this.savedSettings.length > 0) {
      let sett = this.savedSettings.find((value, index) => value.Key === key);
      switch (key) {
        case 'SideBarExpanded': {
          return sett === undefined ? true : sett.Enabled;
        }
      }
    }
    return null;
  }
}
