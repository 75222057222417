/**
 *
 * ReservationDetails
 *
 */
import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  getSingleReservationStatus,
  IReservationEquipmentDto,
  RecurringReservationUpdateOptions,
  ReservationDetailsState,
  ReservationGlobalState,
  ReservationQueryStringParameters,
  ReservationStatus,
  TransferToReservation,
} from './slice/types';
import { useReservationSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import {
  selectAsyncChangeLoading,
  selectByApprovalErrors,
  selectCloneReservations,
  selectConfirmAssistedReservations,
  selectCredit,
  selectCreditCompleted,
  selectCreditProcessing,
  selectEquipmentChangeCompleted,
  selectEquipmentsSettings,
  selectIsCreditSubmited,
  selectIsEdit,
  selectProcessing,
  selectReservation,
  selectReservationCompleted,
  selectReservationHasError,
  selectReservationProcessing,
  selectReservationSettings,
  selectSavedOfflineServices,
  selectTransferProcessing,
  selectTransferReservation,
  selectTransferStepCompleted,
  selectTransferToUser,
  selectTransferWaitingApproval,
  selectUserTrainingData,
} from './slice/selectors';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import { translations } from 'locales/translations';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectContinueState,
  selectContinueToLink,
  selectExpandedSidePanel,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { push } from 'connected-react-router';
import { buildURL } from 'utils/url-utils';
import { Progress } from 'app/components/LoadingIndicator';
import { Beforeunload } from 'react-beforeunload';
import { ReservationForm } from './ReservationForm';
import { selectRepetitiveData } from 'app/components/Forms/FormRepetitive/slice/selectors';
import { Roles } from 'app/slice/types';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import useGlobalSettingsHook from './components/useGlobalSettingsHook';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { ActionButton } from 'app/components/BasicButtons/ActionButton';
import {
  ApproveReservationButton,
  CancelReservationButton,
  CreateTrainingButton,
  SaveForLaterButton,
  SaveFormButton,
  TransferButton,
} from './components/ReservationActions';
import { AssetPopUp } from 'app/pages/AssetPopUp';
import { InstrumentRestrictions } from 'app/pages/InstrumentRestrictionsPage/Details/InstrumentRestrictions';
import { ApproveTrainingRecords } from './components/ApproveTrainingRecords';
import {
  TrainingDetails,
  TrainingDetailsProps,
} from 'app/pages/UserTrainings/TrainingDetailsPage/Details';
import { OtherServiceDetails } from 'app/pages/OtherServiceDetails/Details';
import { ServiceType } from 'enums/ServiceTypes';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { useAppSettingsSlice } from 'app/slice';
import {
  WorkOrderDetails,
  WorkOrderDetailsProps,
} from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { dateUtils } from 'utils/date-utils';
import { WorkOrderQueryStringParameters } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details/slice/types';
import { FormikProps } from 'formik';
import { TransferReservation } from './components/TransferReservation';
import { ReservationModifications } from './components/Modifications';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { FlexRowDiv } from 'app/components/basic/Wrappers/FlexWrappers/flexRow';
import { Body, H3 } from 'app/components/Typography';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import {
  InventoryBatch,
  InventoryBatchProps,
} from 'app/pages/OtherServiceDetails/InventoryBatch';
import {
  SwitchActionsProps,
  SwitchTarget,
} from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/SwitchActions';
import { Entity } from 'types/common';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { tryParseInt } from 'utils/string-utils';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { ErrorServices } from 'app/pages/OtherServiceDetails/Details/slice/types';
import { CalculatePricing } from './components/CalculatePricing';
import { Alert } from '@material-ui/lab';
import { DetectIsMobile } from 'utils/mobileDetect';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';

export interface ReservationDetailsProps extends SidePanelContentProps {
  queryParams: ReservationQueryStringParameters;
  useSwitchButtons?: boolean;
}

export const ReservationDetails = React.memo(function ReservationDetails(
  props: ReservationDetailsProps,
) {
  const { queryParams, useSidePanel, useSwitchButtons } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useReservationSlice();

  const { actions: layoutActions } = useLayoutSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();
  const isMobile = DetectIsMobile();

  /// Refs ///
  const submitFormRef = React.useRef<any>(null);
  const repetitiveFormRef = React.useRef<any>(null);
  const customFormRef = React.useRef<any>(null);
  const innerFormRef = React.useRef<FormikProps<
    ReservationDetailsState
  > | null>(null);

  /// selectors ///
  const processing = useSelector(selectProcessing);
  const reservation = useSelector(selectReservation);
  const reservationId = reservation.data?.Id ?? tryParseInt(queryParams.id);
  const reservationSettings = useSelector(selectReservationSettings);
  const equipmentSettings = useSelector(selectEquipmentsSettings);
  const User = useSelector(selectAuthenticatedUser);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const continueLink = useSelector(selectContinueToLink);
  const publicUrl = useSelector(selectPublicUrl);
  const repetitiveValues = useSelector(selectRepetitiveData);
  const actionCompleted = useSelector(selectReservationCompleted);
  const hasError = useSelector(selectReservationHasError);
  const reservationProcessing = useSelector(selectReservationProcessing);
  const confirmRejected = useSelector(selectConfirmRejected);
  const confirmApproved = useSelector(selectConfirmApproved);
  const continueToOther = useSelector(selectContinueState);
  const confirmAssisted = useSelector(selectConfirmAssistedReservations);
  const assistedTrainingData = useSelector(selectUserTrainingData);
  const equipmentChangeCompleted = useSelector(selectEquipmentChangeCompleted);
  const transferProcessing = useSelector(selectTransferProcessing);
  const transferStepWaiting = useSelector(selectTransferWaitingApproval);
  const transferUser = useSelector(selectTransferToUser);
  const transferredForId = useSelector(selectTransferReservation);
  const transferStepCompleted = useSelector(selectTransferStepCompleted);
  const savedOfflineServices = useSelector(selectSavedOfflineServices);
  const creditProcessing = useSelector(selectCreditProcessing);
  const credit = useSelector(selectCredit);
  const creditCompleted = useSelector(selectCreditCompleted);
  const isCloned = useSelector(selectCloneReservations);
  const edit = useSelector(selectIsEdit);
  const asyncChangeLoading = useSelector(selectAsyncChangeLoading);
  const isCreditSubmited = useSelector(selectIsCreditSubmited);
  const ByApprovalErrors = useSelector(selectByApprovalErrors);
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const showShortView = React.useMemo(() => {
    return isMobile || (useSidePanel && !sidePanelExpanded);
  }, [isMobile, sidePanelExpanded, useSidePanel]);

  //cover
  const [cover, setCover] = React.useState<React.ReactNode>();
  /// Settings ///
  const globalSettings = useGlobalSettingsHook();
  /// Local State ///
  const [busy, setBusy] = React.useState<boolean | undefined>(false);
  //const [edit, setEdit] = React.useState<boolean | undefined>(false);
  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);
  const [showConfirmRecurring, setShowConfirmRecurring] = React.useState<
    boolean | undefined
  >(undefined);
  const [showConfirmAssisted, setShowConfirmAssisted] = React.useState<
    boolean | undefined
  >(undefined);
  const [confirmBeforeTransfer, setConfirmBeforeTransfer] = React.useState<
    boolean | undefined
  >(undefined);
  const [showTransfer, setShowTransfer] = React.useState<boolean | undefined>(
    undefined,
  );
  const [showCalculateCredit, setShowCalculateCredit] = React.useState<
    boolean | undefined
  >(undefined);
  const [confirmCloned, setConfirmCloned] = React.useState<boolean | undefined>(
    undefined,
  );
  const [canceled, setCanceled] = React.useState<boolean | undefined>(
    undefined,
  );
  const [openModifications, setOpenModifications] = React.useState<boolean>(
    false,
  );
  const { newDate } = useSystemDate();
  /// Memo State ///
  const repeat = React.useMemo(() => {
    return edit && reservationSettings?.Repeat;
  }, [edit, reservationSettings]);

  const IsAdmin = React.useMemo(() => {
    return (
      equipmentSettings?.IsAllAdmin ||
      User?.IsAllGroupOrLabTechAdmin(
        reservation?.data?.EquipmentsData.map(
          g => (g as IReservationEquipmentDto).ServiceGroupId,
        ),
        reservation?.data?.EquipmentsData.map(f => {
          return {
            Id: f.Id,
            Name: f.Name,
            ServiceTypeId: ServiceType.Online,
          } as IServiceTypeFilterDto;
        }),
      ) ||
      false
    );
  }, [equipmentSettings?.IsAllAdmin, User, reservation?.data?.EquipmentsData]);
  const isOwner = React.useMemo(() => {
    return reservation?.data?.BookedBy?.Id === User?.Id;
  }, [User?.Id, reservation?.data?.BookedBy]);
  const isUserGroupCoordinator = React.useMemo(() => {
    return (
      User &&
      User.Roles.includes(Roles.UserGroupCoord) &&
      (edit
        ? reservation?.data?.ADGroup?.Id === User.ActiveUserGroup?.Id
        : true)
    );
  }, [User, edit, reservation?.data?.ADGroup?.Id]);
  const trainingSessionEnabled = React.useMemo(() => {
    return (
      globalSettings.trainingSessionEnabled &&
      !equipmentSettings?.HideTrainingSession
    );
  }, [
    equipmentSettings?.HideTrainingSession,
    globalSettings.trainingSessionEnabled,
  ]);
  const trainingSignUp = React.useMemo(() => {
    return (
      trainingSessionEnabled &&
      (reservation?.data?.TrainingSignUp ||
        innerFormRef.current?.values.TrainingSignUp)
      //&&
      // innerFormRef.current?.values.EquipmentsData.length === 1
    );
  }, [reservation?.data?.TrainingSignUp, trainingSessionEnabled]);
  const allowOngoingModifications = React.useMemo(() => {
    return (
      isOwner &&
      reservationSettings?.Ongoing &&
      equipmentSettings?.AllowModification
    );
  }, [
    equipmentSettings?.AllowModification,
    isOwner,
    reservationSettings?.Ongoing,
  ]);
  const editable = React.useMemo(() => {
    return (
      IsAdmin ||
      isUserGroupCoordinator ||
      (isOwner && reservationSettings?.FutureReservation) ||
      (reservationSettings?.Ongoing && allowOngoingModifications)
    );
  }, [
    allowOngoingModifications,
    IsAdmin,
    isUserGroupCoordinator,
    reservationSettings?.FutureReservation,
    reservationSettings?.Ongoing,
    isOwner,
  ]);
  const terminationEnabled = React.useMemo(() => {
    return (
      globalSettings.reservationTerminationEnabled &&
      reservationSettings?.Ongoing &&
      reservation?.data?.Status?.Id !== ReservationStatus.Cancelled &&
      reservation?.data?.Status?.Id !== ReservationStatus.CancelledByAdmin &&
      equipmentSettings?.AllowUsersToShortenReservations &&
      (IsAdmin || isOwner)
    );
  }, [
    IsAdmin,
    equipmentSettings?.AllowUsersToShortenReservations,
    globalSettings.reservationTerminationEnabled,
    isOwner,
    reservation?.data?.Status?.Id,
    reservationSettings?.Ongoing,
  ]);
  const allowSortenReservation = React.useMemo(() => {
    return globalSettings.reservationTerminationEnabled
      ? false
      : equipmentSettings?.AllowUsersToShortenReservations;
  }, [
    equipmentSettings?.AllowUsersToShortenReservations,
    globalSettings.reservationTerminationEnabled,
  ]);
  const timeEnabledForOwner = React.useMemo(() => {
    return (
      reservationSettings?.FutureReservation ||
      (reservationSettings?.Ongoing &&
        (allowSortenReservation ||
          equipmentSettings?.AllowModification ||
          equipmentSettings?.AllowExtendReservation))
    );
  }, [
    equipmentSettings?.AllowExtendReservation,
    equipmentSettings?.AllowModification,
    reservationSettings?.FutureReservation,
    reservationSettings?.Ongoing,
    allowSortenReservation,
  ]);
  const timeEnabled = React.useMemo(() => {
    return (
      (IsAdmin || isUserGroupCoordinator || (isOwner && timeEnabledForOwner)) &&
      !reservationSettings?.TerminateReservation
    );
  }, [
    IsAdmin,
    isOwner,
    isUserGroupCoordinator,
    reservationSettings?.TerminateReservation,
    timeEnabledForOwner,
  ]);
  const canBeCanceled = React.useMemo(() => {
    return (
      edit &&
      editable &&
      reservation?.data?.Status?.Id !== ReservationStatus.Cancelled &&
      reservation?.data?.Status?.Id !== ReservationStatus.CancelledByAdmin
    );
  }, [edit, editable, reservation?.data?.Status?.Id]);
  const canBeApproved = React.useMemo(() => {
    return (
      edit &&
      editable &&
      ((reservation?.data?.Status?.Id === ReservationStatus.Pending &&
        IsAdmin) ||
        (reservation?.data?.Status?.Id === ReservationStatus.PendingCoord &&
          isUserGroupCoordinator))
    );
  }, [
    IsAdmin,
    edit,
    editable,
    isUserGroupCoordinator,
    reservation?.data?.Status?.Id,
  ]);
  const canSaveForLater = React.useMemo(() => {
    return (
      globalSettings.draftReservationsEnabled &&
      equipmentSettings?.DraftSaveForLater &&
      (IsAdmin || isOwner) &&
      reservation?.data?.Status?.Id !== ReservationStatus.Cancelled &&
      reservation?.data?.Status?.Id !== ReservationStatus.CancelledByAdmin
    );
  }, [
    IsAdmin,
    equipmentSettings?.DraftSaveForLater,
    globalSettings.draftReservationsEnabled,
    isOwner,
    reservation?.data?.Status?.Id,
  ]);
  const reservationCanceled = React.useMemo(() => {
    return (
      (edit && reservation?.data?.Status?.Id === ReservationStatus.Cancelled) ||
      reservation?.data?.Status?.Id === ReservationStatus.CancelledByAdmin
    );
  }, [edit, reservation?.data?.Status?.Id]);
  const calculateButtonVisible = React.useMemo(() => {
    return globalSettings.showCalculatePrice &&
      globalSettings.budgetModuleEnabled
      ? equipmentSettings?.BudgetsTurnedOn
      : true;
  }, [
    equipmentSettings?.BudgetsTurnedOn,
    globalSettings.budgetModuleEnabled,
    globalSettings.showCalculatePrice,
  ]);
  const not_restricted = React.useMemo(() => {
    if (edit) {
      let budget =
        reservation?.data?.Budget !== null &&
        reservation?.data?.Budget !== undefined
          ? reservation.data.Budget
          : null;
      let noInstumentRestricted = globalSettings.reservationRestricted
        ? IsAdmin || isUserGroupCoordinator || isOwner
        : globalSettings.userGroupReservationRestricted
        ? reservation?.data?.ADGroup?.Id === User?.ActiveUserGroup?.Id ||
          IsAdmin ||
          isUserGroupCoordinator ||
          isOwner
        : true;
      let confidential =
        ((!!reservation?.data?.BudgetExperiment &&
          reservation?.data?.BudgetExperiment !== null) ||
          globalSettings.confidentialBudgetsEnabled) &&
        !!reservation?.data?.Budget &&
        (budget === null
          ? false
          : (budget as IBudgetFilterDto).Confidential ?? false);
      let noBudgetRestricted = confidential ? IsAdmin || isOwner : true;

      return noInstumentRestricted && noBudgetRestricted;
    }

    return true;
  }, [
    IsAdmin,
    User?.ActiveUserGroup?.Id,
    edit,
    globalSettings.confidentialBudgetsEnabled,
    globalSettings.reservationRestricted,
    globalSettings.userGroupReservationRestricted,
    isOwner,
    isUserGroupCoordinator,
    reservation?.data?.ADGroup?.Id,
    reservation?.data?.Budget,
    reservation?.data?.BudgetExperiment,
  ]);
  /// get initial state of the equipmentSettings
  // const equipmentSettings = React.useMemo(() => {
  //   if (!!reservation.data) {
  //     let sett = mapSettingsFromServices(
  //       reservation.data.EquipmentsData as IReservationEquipmentDto[],
  //       {} as ServiceSettingsState,
  //       globalSettings,
  //     );
  //     return Object.assign(sett, {
  //       CustomForms: reservationSettings?.CustomForms,
  //     });
  //   } else {
  //     return {} as ServiceSettingsState;
  //   }
  // }, [globalSettings, reservation.data, reservationSettings?.CustomForms]);
  /// mount effect - init ///
  // useEffectOnMount(() => {
  //   setRequestCompleted(undefined);
  //   dispatch(
  //     actions.initReservation({
  //       query: queryParams,
  //       globalSettings: globalSettings,
  //     }),
  //   );
  //   return () => {};
  // });
  const workOrderSwithProps = React.useMemo(() => {
    if (!edit) {
      let eqids = queryParams.selectedIds;
      let start = queryParams.Start;
      let end = queryParams.singleClick ? undefined : queryParams.End;
      let defaultMulti = globalSettings.multipleInstrumentsEnabled;
      if (reservation && reservation.data) {
        start = dateUtils.formatQueryStringDate(
          dateUtils.dateOrStringToDate(reservation.data.StartTime),
        );
        end = queryParams.singleClick
          ? undefined
          : dateUtils.formatQueryStringDate(
              dateUtils.dateOrStringToDate(reservation.data.EndTime),
            );
        defaultMulti =
          globalSettings.multipleInstrumentsEnabled &&
          reservation.data.EquipmentsData.length > 0;
        eqids = reservation.data.EquipmentsData.map(f => f.Id).join(',');
      }

      let p = {
        queryParams: {
          eqid: eqids,
          down: 'true',
          offH: 'true',
          eStart: start,
          offStart: start,
          offEnd: end,
          defaultMulti: defaultMulti.toString(),
          source: queryParams.source,
          id: undefined,
          aType: undefined,
          title: undefined,
          sympt: undefined,
          aStatus: undefined,
          aStart: undefined,
          desc: undefined,
          assetId: undefined,
          reason: undefined,
          sid: undefined,
        } as WorkOrderQueryStringParameters,
        useSidePanel: true,
        useSwithButtons: useSwitchButtons,
      } as WorkOrderDetailsProps;
      return p;
    }
    return undefined;
  }, [
    edit,
    globalSettings.multipleInstrumentsEnabled,
    queryParams.End,
    queryParams.Start,
    queryParams.selectedIds,
    queryParams.singleClick,
    queryParams.source,
    reservation,
    useSwitchButtons,
  ]);
  const allowEditMultipleEquipments = React.useMemo(() => {
    return (
      globalSettings.multipleInstrumentsEnabled &&
      !trainingSignUp &&
      globalSettings.allowAddRemoveOnEditReservation
    );
  }, [
    globalSettings.allowAddRemoveOnEditReservation,
    globalSettings.multipleInstrumentsEnabled,
    trainingSignUp,
  ]);
  React.useEffect(() => {
    let active = firstLoad;
    // if (!reservation || !reservation.data) {

    // }
    if (globalSettings.loadCompleted && active) {
      setFirstLoad(false);
      dispatch(
        actions.initReservation({
          query: queryParams,
          globalSettings: globalSettings,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [actions, dispatch, firstLoad, globalSettings, queryParams, reservation]);
  React.useEffect(() => {
    if (edit) {
      if (
        savedOfflineServices.length > 0 &&
        savedOfflineServices.some(f => f.Id < 1)
      ) {
        dispatch(layoutActions.setNotSavedChanges(true));
      }
    }
  }, [dispatch, edit, layoutActions, savedOfflineServices]);
  /// functions, callbacks ///
  const closeCover = () => {
    setCover(undefined);
  };
  const handleClose = () => {
    if (hasChanges) {
      dispatch(layoutActions.setConfirmOpen(true));
    } else {
      handleCloselClick();
    }
  };
  const handleCloselClick = React.useCallback(() => {
    dispatch(actions.resetDetailsState());
    if (useSidePanel) {
      dispatch(layoutActions.resetSidePanel());
    } else {
      dispatch(push('/reservations'));
    }
  }, [actions, dispatch, layoutActions, useSidePanel]);

  const handleSaveClick = React.useCallback(
    (e: any) => {
      if (repeat) {
        setShowConfirmRecurring(true);
      } else {
        handleSubmitForm(e);
      }
    },
    [repeat],
  );

  const cancelReservation = React.useCallback(() => {
    let status = getSingleReservationStatus(
      ReservationStatus.Cancelled as number,
    );
    dispatch(actions.setAnyValue({ fieldKey: 'Status', fieldValue: status }));
    setCanceled(true);
  }, [actions, dispatch]);
  const approveReservation = React.useCallback(() => {
    let status = getSingleReservationStatus(
      ReservationStatus.Approved as number,
    );
    dispatch(actions.setAnyValue({ fieldKey: 'Status', fieldValue: status }));
  }, [actions, dispatch]);
  const draftReservation = React.useCallback(() => {
    let status = getSingleReservationStatus(ReservationStatus.Draft as number);
    dispatch(actions.extendReservationSettings({ SaveForLater: true }));
    dispatch(actions.setAnyValue({ fieldKey: 'Status', fieldValue: status }));
  }, [actions, dispatch]);
  const handleChangeStatus = React.useCallback(
    (event: any, status: ReservationStatus) => {
      if (status === ReservationStatus.Cancelled) {
        cancelReservation();
      } else if (status === ReservationStatus.Approved) {
        approveReservation();
      } else if (status === ReservationStatus.Draft) {
        draftReservation();
      }
      setTimeout(() => handleSaveClick(event), 10);
    },
    [approveReservation, cancelReservation, draftReservation, handleSaveClick],
  );
  const handleSubmitForm = (e: any) => {
    if (customFormRef.current) {
      customFormRef.current(e);
      setTimeout(() => {
        if (repetitiveFormRef.current) {
          repetitiveFormRef.current(e);
        }
        if (submitFormRef.current) {
          submitFormRef.current(e);
        }
      }, 50);
    } else {
      if (repetitiveFormRef.current) {
        repetitiveFormRef.current(e);
      }
      if (submitFormRef.current) {
        submitFormRef.current(e);
      }
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (
      values: ReservationDetailsState,
      customFormFiles: IFormFileValue[],
      originalFormFiles: IFormFileValue[],
    ) => {
      setBusy(true);
      if (isCreditSubmited) {
        setShowCalculateCredit(true);
        let value = {
          details: values,
          settings: reservationSettings,
          customFormFiles: [],
          RepetitiveOptions: repetitiveValues,
          offlineServices: savedOfflineServices,
        } as ReservationGlobalState;
        dispatch(actions.createReservation({ state: value, calcCredit: true }));
      } else {
        if (edit) {
          dispatch(
            actions.updateReservation({
              current: values,
              original: reservation.data || values,
              currentCustomFormFiles: customFormFiles,
              originalCustomFormFiles: originalFormFiles,
              offlineServices: savedOfflineServices,
              settings: reservationSettings,
            }),
          );
        } else {
          let value = {
            details: {
              ...values,
              SampleRunId: reservation.data?.SampleRunId,
            },
            settings: reservationSettings,
            customFormFiles: customFormFiles,
            RepetitiveOptions: repetitiveValues,
            offlineServices: savedOfflineServices,
          } as ReservationGlobalState;
          //console.log('data', value);
          dispatch(
            actions.createReservation({ state: value, calcCredit: undefined }),
          );
        }
      }
    },
    [
      actions,
      dispatch,
      edit,
      isCreditSubmited,
      repetitiveValues,
      reservation.data,
      reservationSettings,
      savedOfflineServices,
    ],
  );
  const [offlineServicesErrors, setOfflineServicesErrors] = React.useState<
    ErrorServices[]
  >([]);
  const handleSetError = React.useCallback(
    (error: ErrorServices) => {
      if (error.error !== undefined) {
        setOfflineServicesErrors([
          ...offlineServicesErrors.filter(f => f.id !== error.id),
          error,
        ]);
      } else {
        setOfflineServicesErrors([
          ...offlineServicesErrors.filter(f => f.id !== error.id),
        ]);
      }
    },
    [offlineServicesErrors],
  );
  const handleTerminate = React.useCallback(() => {
    dispatch(
      actions.setAnyValue({
        fieldKey: 'EndTime',
        fieldValue: newDate(),
      }),
    );
    dispatch(
      actions.setAnyValue({
        fieldKey: 'Remarks',
        fieldValue: t(translations.AutoEndReservation_remarks) as string,
      }),
    );
    setTimeout(() => {
      dispatch(
        actions.updateReservation({
          current:
            innerFormRef?.current?.values ||
            reservation.data ||
            ({} as ReservationDetailsState),
          original: reservation.data || ({} as ReservationDetailsState),
          currentCustomFormFiles: [],
          originalCustomFormFiles: [],
          offlineServices: savedOfflineServices,
          settings: reservationSettings,
          terminate: true,
        }),
      );
    }, 10);
  }, [
    actions,
    dispatch,
    newDate,
    reservation.data,
    reservationSettings,
    savedOfflineServices,
    t,
  ]);
  //------ Confirm Repetitive Update/Delete handlers -------//
  const handleUpdateAllRecurrent = (e: any) => {
    dispatch(
      actions.updateReservationSettings({
        RecurringUpdateOption: RecurringReservationUpdateOptions.All,
      }),
    );
    handleSubmitForm(e);
    setShowConfirmRecurring(false);
    setCanceled(undefined);
  };
  const handleUpdateFollowing = (e: any) => {
    dispatch(
      actions.updateReservationSettings({
        RecurringUpdateOption: RecurringReservationUpdateOptions.Following,
      }),
    );
    handleSubmitForm(e);
    setShowConfirmRecurring(false);
    setCanceled(undefined);
  };
  const handleUpdateJustOne = (e: any) => {
    dispatch(
      actions.updateReservationSettings({
        RecurringUpdateOption:
          RecurringReservationUpdateOptions.CurrentReservation,
      }),
    );
    handleSubmitForm(e);
    setShowConfirmRecurring(false);
    setCanceled(undefined);
  };
  const assistedApproveOnse = () => {
    setShowConfirmAssisted(false);
    dispatch(actions.resetAssisteTrainingData());
    dispatch(layoutActions.setRefreshTable(true));
    if (!isCloned) {
      handleCloselClick();
    }
  };
  const handleByApprovalConfirm = React.useCallback(
    (e: any) => {
      dispatch(
        actions.updateReservationSettings({
          UserRequestedByApproval: true,
        }),
      );
      dispatch(actions.setByApprovalErrors());
      handleSubmitForm(e);
    },
    [actions, dispatch],
  );
  const editAssisted = React.useCallback(
    (p: TrainingDetailsProps, reset: boolean) => {
      setCover(
        <TrainingDetails
          id={p.id}
          date={p.date}
          equId={p.equId}
          equIds={p.equIds}
          fatherId={p.fatherId}
          rid={p.rid}
          user={p.user}
          useSidePanel={true}
          isCover={true}
          closeCover={() => {
            closeCover();
            if (reset) {
              dispatch(actions.resetAssisteTrainingData());
            }

            dispatch(layoutActions.setRefreshTable(true));
          }}
          onClose={() => {
            closeCover();
            if (reset) {
              dispatch(actions.resetAssisteTrainingData());
            }

            dispatch(layoutActions.setRefreshTable(true));
          }}
        />,
      );
      setShowConfirmAssisted(false);
    },
    [actions, dispatch, layoutActions],
  );
  const handleCancelTransfer = () => {
    if (transferUser !== null) {
      dispatch(
        actions.cancelTransfer({
          User: transferUser,
          ReservtionId: transferredForId || reservation?.data?.Id || 0,
        }),
      );
    }
  };
  const handleEquipmentClick = React.useCallback(
    (equipment: Entity<number>) => {
      useSidePanel
        ? setCover(
            <AssetPopUp
              closeCover={closeCover}
              isCover
              serviceId={equipment.Id}
              onClose={closeCover}
            />,
          )
        : dispatch(
            layoutActions.openSidePanel({
              type: RenderPageType.AssetDetails,
              props: { Id: undefined, serviceId: equipment.Id },
              expanded: false,
            }),
          );
    },
    [dispatch, layoutActions, useSidePanel],
  );
  const handleRestrictionsClick = React.useCallback(
    (equipment: Entity<number>) => {
      useSidePanel
        ? setCover(
            <InstrumentRestrictions
              serviceId={equipment.Id}
              serviceGroupId={
                (equipment as IReservationEquipmentDto).ServiceGroupId
              }
              useSidePanel
              isCover
              closeCover={closeCover}
              onClose={closeCover}
            />,
          )
        : dispatch(
            layoutActions.openSidePanel({
              type: RenderPageType.RestrictionDetails,
              props: {
                serviceId: equipment.Id,
                showTable: IsAdmin,
                useSidePanel: useSidePanel,
                isCover: false,
                serviceGroupId: (equipment as IReservationEquipmentDto)
                  .ServiceGroupId,
              },
              expanded: false,
            }),
          );
    },
    [IsAdmin, dispatch, layoutActions, useSidePanel],
  );
  const handleOfflineServiceClick = React.useCallback(
    (service: IOtherServices) => {
      if (edit) {
        useSidePanel
          ? setCover(
              <OtherServiceDetails
                useSidePanel={true}
                isCover={true}
                closeCover={() => {
                  closeCover();
                  // dispatch(actions.setRefreshOfflineServices(true));
                }}
                onClose={() => {
                  closeCover();
                  // dispatch(actions.setRefreshOfflineServices(true));
                }}
                initialService={service}
                saveCreatable={true}
                queryParams={{
                  id: '' + service.Id ?? -1,
                }}
              />,
            )
          : dispatch(
              layoutActions.openSidePanel({
                type: RenderPageType.OtherServiceDetails,
                props: {
                  useSidePanel: true,
                  initialService: service,
                  saveCreatable: true,
                  queryParams: {
                    id: '' + service.Id ?? -1,
                  },
                },
              }),
            );
      }
    },
    [dispatch, edit, layoutActions, useSidePanel],
  );
  const handleWorkTypeClick = React.useCallback(
    (
      event: IWorkOrderTypeDto,
      equipmentIds: number[],
      reservationId: number,
    ) => {
      useSidePanel
        ? setCover(
            <WorkOrderDetails
              useSidePanel={true}
              isCover={true}
              closeCover={() => {
                closeCover();
                dispatch(actions.setRefreshWorkOrderLink(true));
              }}
              onClose={() => {
                closeCover();
                dispatch(actions.setRefreshWorkOrderLink(true));
              }}
              queryParams={{
                id: undefined,
                aType: event.Id.toString(),
                eqid: equipmentIds.join(','),
                reservationId: reservationId.toString(),
              }}
            />,
          )
        : dispatch(
            layoutActions.openSidePanel({
              type: RenderPageType.WorkOrderDetails,
              props: {
                useSidePanel: true,
                queryParams: {
                  id: undefined,
                  aType: event.Id.toString(),
                  eqid: equipmentIds.join(','),
                  reservationId: reservationId.toString(),
                } as WorkOrderQueryStringParameters,
              } as WorkOrderDetailsProps,
            }),
          );
    },
    [actions, dispatch, layoutActions, useSidePanel],
  );
  const handleAddBatchClick = React.useCallback(
    (batch: IInventoryBatchDto | null, serviceTypeId: number) => {
      useSidePanel
        ? setCover(
            <InventoryBatch
              useSidePanel={true}
              isCover={true}
              closeCover={() => {
                closeCover();
              }}
              onClose={() => {
                closeCover();
              }}
              initialBatch={batch ?? undefined}
              saveCreatable={true}
              queryParams={{
                id: '' + (batch?.Id ?? -1),
                stid: '' + serviceTypeId,
              }}
            />,
          )
        : dispatch(
            layoutActions.openSidePanel({
              type: RenderPageType.InventoryBatch,
              props: {
                useSidePanel: true,
                initialBatch: batch ?? undefined,
                queryParams: {
                  id: '' + (batch?.Id ?? -1),
                  stid: '' + serviceTypeId,
                },
              } as InventoryBatchProps,
            }),
          );
    },
    [dispatch, layoutActions, useSidePanel],
  );
  const handleCalculateCredit = React.useCallback(
    (e: any) => {
      dispatch(actions.setSubmitCredit(true));
      handleSubmitForm(e);
    },
    [actions, dispatch],
  );
  // const handleCalculateClose = React.useCallback(() => {
  //   setShowCalculateCredit(false);
  //   dispatch(actions.resetCreditState());
  // }, [actions, dispatch]);
  //const [setSubmitting] = useSubmittingHandler<ReservationDetailsState>();
  /// Use Effects ///
  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      if (reservationId !== undefined && !isCloned) {
        //setEdit(true);
      } else {
        //setEdit(false);
      }
      if (actionCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          if (confirmAssisted && assistedTrainingData !== null) {
            setShowConfirmAssisted(true);
          } else {
            setShowConfirmAssisted(false);
            if (!isCloned) {
              handleCloselClick();
            }
          }
        }
        if (terminationEnabled) {
          dispatch(actions.resetTerminateState());
        }
      } else if (actionCompleted === false) {
        setRequestCompleted(undefined);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    actionCompleted,
    actions,
    assistedTrainingData,
    confirmAssisted,
    dispatch,
    handleCloselClick,
    hasError,
    isCloned,
    processing,
    reservationId,
    terminationEnabled,
  ]);
  React.useEffect(() => {
    let active = creditProcessing === false;
    if (active) {
      if (creditCompleted === true && !showCalculateCredit) {
        dispatch(actions.resetCreditState());
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    creditCompleted,
    creditProcessing,
    dispatch,
    showCalculateCredit,
  ]);
  React.useEffect(() => {
    if (confirmApproved) {
      if (confirmBeforeTransfer) {
        dispatch(layoutActions.resetConfirmState());
        setShowTransfer(true);
      } else {
        dispatch(actions.resetDetailsState());
        if (continueToOther.continueOnApprove) {
          dispatch(layoutActions.resetSidePanel());
          setTimeout(() => {
            dispatch(
              layoutActions.openSidePanel({
                type: continueToOther.pageType,
                props: continueToOther.pageProps,
                expanded: continueToOther.expanded,
              }),
            );
            dispatch(layoutActions.resetContinueState());
          }, 100);
        } else if (continueLink !== undefined) {
          dispatch(appSettingsActions.navigate(continueLink));
          dispatch(layoutActions.resetSidePanel());
        } else {
          dispatch(layoutActions.resetSidePanel());
        }
        //dispatch(actions.setChangedState(false));
      }
    }
    if (confirmRejected) {
      dispatch(layoutActions.resetConfirmState());
      if (confirmBeforeTransfer) {
        setConfirmBeforeTransfer(false);
      }
    }

    return () => undefined;
  }, [
    actions,
    appSettingsActions,
    confirmApproved,
    confirmBeforeTransfer,
    confirmRejected,
    continueLink,
    continueToOther,
    dispatch,
    edit,
    layoutActions,
  ]);
  React.useEffect(() => {
    if (transferProcessing === false && transferStepCompleted === true) {
      setShowTransfer(false);
    }
  }, [actions, dispatch, transferProcessing, transferStepCompleted]);

  /// actions ///
  const leftActions = React.useMemo(() => {
    let mactions = [] as ActionRenderer[];
    if (!edit || (edit && (IsAdmin || isUserGroupCoordinator || isOwner))) {
      mactions.push(() => (
        <React.Fragment>
          <SaveFormButton
            size="small"
            startIcon={<Icon icon="save" />}
            onClick={e => {
              dispatch(actions.setCloneReservation(false));
              dispatch(actions.resetOperateState());
              handleSaveClick(e);
            }}
            disabled={
              busy ||
              equipmentChangeCompleted === false ||
              transferStepWaiting === true ||
              (edit && reservationCanceled && !IsAdmin)
            }
            processing={!requestCompleted && busy}
            text={t(translations.Save)}
          />
        </React.Fragment>
      ));
    }
    if (edit) {
      if (!!equipmentSettings && !!reservationSettings) {
        if (canBeCanceled) {
          mactions.push(() => (
            <React.Fragment>
              <CancelReservationButton
                size="small"
                startIcon={<Icon icon="trash" />}
                onClick={e =>
                  handleChangeStatus(e, ReservationStatus.Cancelled)
                }
                disabled={
                  busy ||
                  transferStepWaiting === true ||
                  equipmentChangeCompleted === false
                }
                variant="white"
                processing={!requestCompleted && busy}
                text={
                  showShortView
                    ? t(translations.Cancel)
                    : t(translations.CancelReservation)
                }
              />
            </React.Fragment>
          ));
        }
        if (canBeApproved) {
          mactions.push(() => (
            <React.Fragment>
              <ApproveReservationButton
                size="small"
                startIcon={<Icon icon="check" />}
                onClick={e => handleChangeStatus(e, ReservationStatus.Approved)}
                disabled={
                  busy ||
                  transferStepWaiting === true ||
                  equipmentChangeCompleted === false
                }
                variant="white"
                processing={!requestCompleted && busy}
                text={
                  showShortView
                    ? t(translations.Approve)
                    : t(translations.ApproveReservation)
                }
              />
            </React.Fragment>
          ));
        }
        if (
          (equipmentSettings.AllowedToTransfer ||
            equipmentSettings.AllowedToTransferAll) &&
          !transferStepWaiting &&
          !repeat &&
          canBeCanceled
        ) {
          mactions.push(() => (
            <React.Fragment>
              <TransferButton
                size="small"
                onClick={e => {
                  if (hasChanges) {
                    dispatch(layoutActions.setConfirmOpen(true));
                    setConfirmBeforeTransfer(true);
                  } else {
                    setShowTransfer(true);
                  }
                }}
                disabled={busy}
                variant="white"
                title={t(translations.TransferReservation)}
                processing={transferProcessing}
                text={t(translations.Transfer)}
              />
            </React.Fragment>
          ));
        }
      }
      // if (terminationEnabled) {
      //   actions.push(() => (
      //     <React.Fragment>
      //       <TerminateReservation
      //         onTerminate={handleTerminate}
      //         isLoaanDesc={equipmentSettings?.IsLoanDesc || false}
      //         reservationId={reservation.data?.Id || 0}
      //         serviceId={reservationSettings?.BaseEquipment?.Id || 0}
      //         title={t(translations.TerminateReservationInfo)}
      //         text={
      //           isMobile
      //             ? t(translations.TerminateReservationMobile)
      //             : t(translations.TerminateReservation)
      //         }
      //       />
      //     </React.Fragment>
      //   ));
      // }
    }
    if (
      IsAdmin &&
      globalSettings.assistedReservationsEnabled &&
      globalSettings.tutoringModulesEnabled &&
      equipmentSettings?.IsEquipmentTutoring &&
      edit &&
      assistedTrainingData !== null &&
      assistedTrainingData.Equipments.some(f => f.TrainingRecordId === null)
    ) {
      mactions.push(() => (
        <React.Fragment>
          <CreateTrainingButton
            size="small"
            startIcon={<Icon icon="dumbbell" />}
            onClick={e =>
              editAssisted(
                {
                  id: undefined,
                  equId: undefined,
                  equIds: assistedTrainingData.Equipments.map(
                    f => f.EquipmentId,
                  ).join(','),
                  fatherId:
                    assistedTrainingData.FatherId !== null
                      ? assistedTrainingData.FatherId.toString()
                      : undefined,
                  rid:
                    assistedTrainingData.FatherId !== null &&
                    assistedTrainingData.FatherId !== undefined
                      ? undefined
                      : assistedTrainingData.ReservationId !== null
                      ? assistedTrainingData.ReservationId.toString()
                      : undefined,
                  date: dateUtils.formatISO(
                    dateUtils.dateOrStringToDate(
                      assistedTrainingData.StartTime,
                    ),
                  ),
                  user: assistedTrainingData.UserName,
                  useSidePanel: true,
                } as TrainingDetailsProps,
                false,
              )
            }
            disabled={busy}
            variant="white"
            text={t(translations.CreateTraining)}
          />
        </React.Fragment>
      ));
    }
    if (canSaveForLater) {
      mactions.push(() => (
        <React.Fragment>
          <SaveForLaterButton
            size="small"
            startIcon={<Icon icon="floppy-disk-circle-arrow-right" />}
            onClick={e => handleChangeStatus(e, ReservationStatus.Draft)}
            disabled={
              busy ||
              transferStepWaiting === true ||
              equipmentChangeCompleted === false
            }
            variant="white"
            processing={!requestCompleted && busy}
            text={t(translations.SaveForLater)}
          />
        </React.Fragment>
      ));
    }
    if (calculateButtonVisible) {
      mactions.push(() => (
        <Button
          id={'CalculateCreditPopupButtonId'}
          variant="white"
          size="small"
          startIcon={<Icon icon="money-bill" />}
          onClick={e => handleCalculateCredit(e)}
          title={
            offlineServicesErrors.length > 0
              ? (t(translations.OfflineServicesError_info) as string)
              : (t(translations.CalculatePricing_info) as string)
          }
          disabled={
            creditProcessing ||
            equipmentChangeCompleted === false ||
            offlineServicesErrors.length > 0
          }
          processing={creditProcessing}
        >
          {t(translations.CalculatePricing)}
        </Button>
      ));
    }
    if (edit && (IsAdmin || isUserGroupCoordinator || isOwner)) {
      mactions.push(() => (
        <Button
          variant="white"
          size="small"
          startIcon={<Icon icon="square" />}
          aria-label="history"
          onClick={() => setOpenModifications(true)}
        >
          {t(translations.ModificationsHistory)}
        </Button>
      ));
    }
    if (IsAdmin || isOwner) {
      mactions.push(() => (
        <Button
          variant="white"
          size="small"
          startIcon={<Icon icon="clone" />}
          aria-label="clone"
          disabled={
            busy ||
            equipmentChangeCompleted === false ||
            transferStepWaiting === true ||
            (edit && reservationCanceled && !IsAdmin)
          }
          title={t(translations.res_SaveAndClone_info)}
          onClick={e => {
            dispatch(actions.resetOperateState());
            if (edit) {
              if (hasChanges) {
                setConfirmCloned(hasChanges);
              } else {
                dispatch(actions.setCloneReservation(true));
                handleSaveClick(e);
              }
            } else {
              dispatch(actions.setCloneReservation(true));
              handleSaveClick(e);
            }
          }}
        >
          {edit ? t(translations.SaveAndClone) : t(translations.CreateAndClone)}
        </Button>
      ));
    }
    return mactions;
  }, [
    IsAdmin,
    actions,
    assistedTrainingData,
    busy,
    calculateButtonVisible,
    canBeApproved,
    canBeCanceled,
    canSaveForLater,
    creditProcessing,
    dispatch,
    edit,
    editAssisted,
    equipmentChangeCompleted,
    equipmentSettings,
    globalSettings.assistedReservationsEnabled,
    globalSettings.tutoringModulesEnabled,
    handleCalculateCredit,
    handleChangeStatus,
    handleSaveClick,
    hasChanges,
    isOwner,
    isUserGroupCoordinator,
    layoutActions,
    offlineServicesErrors.length,
    repeat,
    requestCompleted,
    reservationCanceled,
    reservationSettings,
    showShortView,
    t,
    transferProcessing,
    transferStepWaiting,
  ]);

  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <IconButton
            variant="ghost"
            size="small"
            title={t(translations.Close)}
            onClick={handleCloselClick}
          >
            <Icon icon="times" />
          </IconButton>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCloselClick, t]);
  // const rightTopActions = React.useMemo(() => {
  //   let rtactions = [] as ActionRenderer[];
  //   if (edit && (IsAdmin || isUserGroupCoordinator || isOwner)) {
  //     rtactions.push(() => (
  //       <TopActionButton
  //         variant="ghost"
  //         size="small"
  //         shape="square"
  //         startIcon="history"
  //         icon="history"
  //         aria-label="history"
  //         onClick={() => setOpenModifications(true)}
  //         noChangeOnMobile
  //         text={t(translations.ModificationsHistory)}
  //       />
  //     ));
  //   }
  //   if (IsAdmin || isOwner) {
  //     rtactions.push(() => (
  //       <TopActionButton
  //         variant="ghost"
  //         size="small"
  //         shape="square"
  //         startIcon="clone"
  //         icon="clone"
  //         aria-label="clone"
  //         disabled={
  //           busy ||
  //           equipmentChangeCompleted === false ||
  //           transferStepWaiting === true ||
  //           (edit && reservationCanceled && !IsAdmin)
  //         }
  //         title={t(translations.res_SaveAndClone_info)}
  //         onClick={e => {
  //           dispatch(actions.resetOperateState());
  //           if (edit) {
  //             if (hasChanges) {
  //               setConfirmCloned(hasChanges);
  //             } else {
  //               dispatch(actions.setCloneReservation(true));
  //               handleSaveClick(e);
  //             }
  //           } else {
  //             dispatch(actions.setCloneReservation(true));
  //             handleSaveClick(e);
  //           }
  //         }}
  //         noChangeOnMobile
  //         text={
  //           edit ? t(translations.SaveAndClone) : t(translations.CreateAndClone)
  //         }
  //       />
  //     ));
  //   }
  //   if (edit) {
  //     if (!!equipmentSettings && !!reservationSettings) {
  //       if (canBeCanceled) {
  //         rtactions.push(() => (
  //           <TopActionButton
  //             variant="ghost"
  //             size="small"
  //             shape="square"
  //             startIcon="ban"
  //             icon="ban"
  //             aria-label="cancel reservation"
  //             onClick={e => handleChangeStatus(e, ReservationStatus.Cancelled)}
  //             disabled={
  //               busy ||
  //               transferStepWaiting === true ||
  //               equipmentChangeCompleted === false
  //             }
  //             noChangeOnMobile
  //             text={t(translations.Cancel)}
  //           />
  //         ));
  //       }
  //       if (canBeApproved) {
  //         rtactions.push(() => (
  //           <TopActionButton
  //             variant="ghost"
  //             size="small"
  //             shape="square"
  //             startIcon="circle-check"
  //             icon="circle-check"
  //             aria-label="approve reservation"
  //             onClick={e => handleChangeStatus(e, ReservationStatus.Approved)}
  //             disabled={
  //               busy ||
  //               transferStepWaiting === true ||
  //               equipmentChangeCompleted === false
  //             }
  //             noChangeOnMobile
  //             text={t(translations.Approve)}
  //           />
  //         ));
  //       }
  //       if (
  //         (equipmentSettings.AllowedToTransfer ||
  //           equipmentSettings.AllowedToTransferAll) &&
  //         !transferStepWaiting &&
  //         !repeat &&
  //         canBeCanceled
  //       ) {
  //         rtactions.push(() => (
  //           <TopActionButton
  //             variant="ghost"
  //             size="small"
  //             shape="square"
  //             startIcon="exchange"
  //             icon="exchange"
  //             aria-label={t(translations.TransferReservation) as string}
  //             onClick={e => {
  //               if (hasChanges) {
  //                 dispatch(layoutActions.setConfirmOpen(true));
  //                 setConfirmBeforeTransfer(true);
  //               } else {
  //                 setShowTransfer(true);
  //               }
  //             }}
  //             disabled={busy}
  //             noChangeOnMobile
  //             text={t(translations.Transfer)}
  //           />
  //         ));
  //       }
  //       if (
  //         IsAdmin &&
  //         globalSettings.assistedReservationsEnabled &&
  //         globalSettings.tutoringModulesEnabled &&
  //         equipmentSettings?.IsEquipmentTutoring &&
  //         edit &&
  //         assistedTrainingData !== null &&
  //         assistedTrainingData.Equipments.some(f => f.TrainingRecordId === null)
  //       ) {
  //         rtactions.push(() => (
  //           <TopActionButton
  //             variant="ghost"
  //             size="small"
  //             shape="square"
  //             startIcon="dumbbell"
  //             icon="dumbbell"
  //             aria-label="create training"
  //             onClick={e =>
  //               editAssisted(
  //                 {
  //                   id: undefined,
  //                   equId: undefined,
  //                   equIds: assistedTrainingData.Equipments.map(
  //                     f => f.EquipmentId,
  //                   ).join(','),
  //                   fatherId:
  //                     assistedTrainingData.FatherId !== null
  //                       ? assistedTrainingData.FatherId.toString()
  //                       : undefined,
  //                   rid:
  //                     assistedTrainingData.FatherId !== null
  //                       ? undefined
  //                       : assistedTrainingData.ReservationId !== null
  //                       ? assistedTrainingData.ReservationId.toString()
  //                       : undefined,
  //                   date: dateUtils.formatISO(
  //                     dateUtils.dateOrStringToDate(
  //                       assistedTrainingData.StartTime,
  //                     ),
  //                   ),
  //                   user: assistedTrainingData.UserName,
  //                   useSidePanel: true,
  //                 } as TrainingDetailsProps,
  //                 false,
  //               )
  //             }
  //             disabled={busy}
  //             noChangeOnMobile
  //             text={t(translations.CreateTraining)}
  //           />
  //         ));
  //       }
  //     }
  //   }
  //   if (canSaveForLater) {
  //     rtactions.push(() => (
  //       <TopActionButton
  //         variant="ghost"
  //         size="small"
  //         shape="square"
  //         startIcon="floppy-disk-circle-arrow-right"
  //         icon="floppy-disk-circle-arrow-right"
  //         aria-label={t(translations.SaveForLater) as string}
  //         onClick={e => handleChangeStatus(e, ReservationStatus.Draft)}
  //         disabled={
  //           busy ||
  //           transferStepWaiting === true ||
  //           equipmentChangeCompleted === false
  //         }
  //         noChangeOnMobile
  //         text={t(translations.SaveForLater)}
  //       />
  //     ));
  //   }
  //   if (calculateButtonVisible) {
  //     rtactions.push(() => (
  //       <TopActionButton
  //         id={'CalculateCreditPopupButtonId'}
  //         variant="ghost"
  //         size="small"
  //         shape="square"
  //         startIcon="money-bill"
  //         icon="money-bill"
  //         title={t(translations.CalculatePricing_info) as string}
  //         aria-label={t(translations.CalculatePricing) as string}
  //         onClick={e => handleCalculateCredit(e)}
  //         disabled={creditProcessing || equipmentChangeCompleted === false}
  //         noChangeOnMobile
  //         text={t(translations.CalculatePricing)}
  //       />
  //     ));
  //   }
  //   return rtactions;
  // }, [
  //   IsAdmin,
  //   actions,
  //   assistedTrainingData,
  //   busy,
  //   calculateButtonVisible,
  //   canBeApproved,
  //   canBeCanceled,
  //   canSaveForLater,
  //   creditProcessing,
  //   dispatch,
  //   edit,
  //   editAssisted,
  //   equipmentChangeCompleted,
  //   equipmentSettings,
  //   globalSettings.assistedReservationsEnabled,
  //   globalSettings.tutoringModulesEnabled,
  //   handleCalculateCredit,
  //   handleChangeStatus,
  //   handleSaveClick,
  //   hasChanges,
  //   isOwner,
  //   isUserGroupCoordinator,
  //   layoutActions,
  //   repeat,
  //   reservationCanceled,
  //   reservationSettings,
  //   t,
  //   transferStepWaiting,
  // ]);
  const pageTitle = reservation?.data
    ? edit
      ? ` ${
          isCloned
            ? t(translations.CloneReservation)
            : t(translations.Reservation)
        } #${reservationId || 'N/A'}`
      : reservation.data.TrainingSignUp
      ? t(translations.TrainingSignUp)
      : ` ${
          isCloned
            ? t(translations.CloneReservation)
            : t(translations.AddReservation)
        }`
    : undefined;
  const pageName = edit
    ? t(translations.menu_ReservationDetails)
    : t(translations.menu_NewReservation);
  const useSwitchActions =
    !edit && IsAdmin && !reservation.data?.TransferFromId && useSwitchButtons;
  const switchState =
    !!workOrderSwithProps && !edit && useSwitchButtons
      ? ({
          target: SwitchTarget.WorkOrder,
          getProps: () => workOrderSwithProps,
        } as SwitchActionsProps)
      : undefined;
  const ByApprovalAvailable =
    ByApprovalErrors !== undefined && ByApprovalErrors.length > 0;
  /// render ///
  return (
    <React.Fragment>
      <PageWrapper
        pageName={pageName}
        titlePage={pageTitle}
        loading={processing}
        useSidePanel={useSidePanel}
        closable={true}
        closeSidePanel={handleClose}
        //leftTopActions={topActions}
        useSwitchActions={useSwitchActions}
        switchState={switchState}
        topProcessing={
          equipmentChangeCompleted === false || asyncChangeLoading === true
        }
        leftActions={not_restricted ? leftActions : []}
        leftActionsMaxLength={edit ? 2 : 1}
        pageLink={buildURL(
          publicUrl + 'reservations/details',
          props.queryParams,
        )}
        rightActions={rightActions}
        // rightTopActions={not_restricted ? rightTopActions : undefined}
        //rightTopActionsAsMenu
        confirm={
          showConfirmRecurring ? (
            <DialogConfirm
              isOpen={showConfirmRecurring}
              title={
                canceled
                  ? t(translations.CancelMultipleReservations)
                  : t(translations.UpdateMultipleReservations)
              }
              processing={reservationProcessing}
              body={t(translations.WeeklyReservationConfirmationMessage)}
              confirmButtonLabel={t(translations.JustThisOne)}
              onConfirm={handleUpdateJustOne}
              maxWidth="md"
              onCancel={() => {
                setShowConfirmRecurring(false);
              }}
              actions={[
                () => (
                  <ActionButton
                    text={
                      canceled
                        ? t(translations.CancelFollowing)
                        : t(translations.UpdateFollowing)
                    }
                    variant="main"
                    onClick={handleUpdateFollowing}
                  />
                ),
                () => (
                  <ActionButton
                    text={
                      canceled
                        ? t(translations.CancelAll)
                        : t(translations.UpdateAll)
                    }
                    variant="main"
                    onClick={handleUpdateAllRecurrent}
                  />
                ),
              ]}
            />
          ) : confirmCloned ? (
            <DialogConfirm
              isOpen={confirmCloned}
              title={t(translations.CloneReservation)}
              body={t(translations.CloneReservationConfirm)}
              confirmButtonLabel={t(translations.Proceed)}
              onConfirm={e => {
                dispatch(actions.setCloneReservation(true));
                handleSaveClick(e);
                setConfirmCloned(false);
              }}
              maxWidth="md"
              onCancel={() => {
                setConfirmCloned(false);
              }}
            />
          ) : requestCompleted &&
            confirmAssisted &&
            assistedTrainingData !== null ? (
            <ApproveTrainingRecords
              show={showConfirmAssisted || false}
              data={assistedTrainingData}
              handleApproveOnce={assistedApproveOnse}
              handleEditAssisted={editAssisted}
            />
          ) : showTransfer ? (
            <TransferReservation
              show={showTransfer}
              transferAllowedToGroup={
                equipmentSettings?.AllowedToTransfer || false
              }
              services={(reservation?.data
                ?.EquipmentsData as IReservationEquipmentDto[]).map(f => {
                return {
                  Id: f.Id,
                  Name: f.Name,
                  ServiceTypeId: 1,
                  ServiceGroupId: f.ServiceGroupId,
                  HideProject: f.HideProjects,
                } as IServiceTypeFilterDto;
              })}
              adGroup={reservation?.data?.ADGroup?.Id}
              trnsferSubmitted={transferProcessing}
              handleTransfer={value => {
                let transfer = {
                  User: value,
                  ReservtionId: reservation?.data?.Id ?? 0,
                } as TransferToReservation;
                dispatch(actions.transferReservation(transfer));
              }}
              user={User}
              onClose={() => setShowTransfer(false)}
            />
          ) : showCalculateCredit ? (
            <CalculatePricing
              open={showCalculateCredit}
              title={t(translations.CalculatePricing)}
              onClose={() => setShowCalculateCredit(false)}
              edit={edit}
              credit={credit}
              creditCompleted={creditCompleted}
              creditProcessing={creditProcessing}
            />
          ) : !!openModifications ? (
            <ReservationModifications
              open={openModifications}
              setOpen={value => setOpenModifications(value)}
              resId={reservation?.data?.Id ?? 0}
            />
          ) : ByApprovalAvailable ? (
            <DialogConfirm
              isOpen={ByApprovalAvailable}
              title={t(translations.ByApprovalConfirmationModalTitle)}
              processing={reservationProcessing}
              body={
                <>
                  {ByApprovalErrors.map((error, index) => (
                    <Alert key={index} color="warning">
                      {error}
                    </Alert>
                  ))}
                  <Body>{t(translations.ReservationConfirmByApproval)}</Body>
                </>
              }
              confirmButtonLabel={t(translations.Confirm)}
              onConfirm={handleByApprovalConfirm}
              maxWidth="md"
              onCancel={() => {
                dispatch(actions.setByApprovalErrors(undefined));
              }}
            />
          ) : (
            <></>
          )
        }
        cover={cover}
        closeCover={closeCover}
      >
        {reservation?.data !== undefined && !processing ? (
          not_restricted ? (
            <ReservationForm
              initialValues={
                reservation?.data || ({} as ReservationDetailsState)
              }
              bindSubmitForm={bindSubmitForm}
              onSubmit={handleSubmit}
              isEdit={edit || false}
              repetitiveFormRef={repetitiveFormRef}
              formRef={innerFormRef}
              isAdmin={IsAdmin}
              user={User}
              isUserGroupCoordinator={isUserGroupCoordinator}
              trainingSignUp={trainingSignUp}
              trainingSessionEnabled={trainingSessionEnabled}
              allowEditMultipleEquipments={allowEditMultipleEquipments}
              useSidePanel={useSidePanel}
              onEquipmentClicked={handleEquipmentClick}
              onRestrictionsClick={handleRestrictionsClick}
              onOfflineServiceClick={handleOfflineServiceClick}
              onAddBatchClick={handleAddBatchClick}
              onStatusChange={handleChangeStatus}
              onWorkTypeClick={handleWorkTypeClick}
              globalSettings={globalSettings}
              customFormRef={customFormRef}
              editable={editable}
              timeEnabled={timeEnabled}
              cancelTransfer={handleCancelTransfer}
              waitingTransferApproval={transferStepWaiting}
              transferToUser={transferUser}
              transferForId={transferredForId}
              terminationEnabled={terminationEnabled}
              handleTerminate={handleTerminate}
              offlineServicesErrors={offlineServicesErrors}
              setError={handleSetError}
              assistedTrainingData={assistedTrainingData}
              allowOngoingModifications={allowOngoingModifications}
            />
          ) : (
            <FlexRowDiv style={{ alignSelf: 'center', justifySelf: 'center' }}>
              <Box
                style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
              >
                <H3>{t(translations.RestrictedNotAllowed) as string}</H3>
                <Button
                  variant="ghost"
                  size="medium"
                  startIcon={<Icon icon="times" />}
                  onClick={handleCloselClick}
                >
                  {t(translations.Continue)}
                </Button>
              </Box>
            </FlexRowDiv>
          )
        ) : (
          <React.Fragment>
            {' '}
            <Box component="div">
              <Progress inProgress={processing} size={80} />
            </Box>
          </React.Fragment>
        )}
      </PageWrapper>
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </React.Fragment>
  );
});
