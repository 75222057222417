/**
 *
 * Comments
 *
 */
import * as React from 'react';
import { CommentsApi } from 'api/CommentsApi';
import {
  CommentsContainer,
  EmailRelated,
} from 'app/components/CommentsContainer';
import {
  IComment,
  NewComment,
} from 'app/components/CommentsContainer/IComment';
import { useAsyncGetWthEffect } from 'app/hooks/useAsyncGetOnMount';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAbsolute } from 'utils/url-utils';

export interface CommentsProps
  extends Omit<EmailRelated, 'referenceName' | 'linkUrl'> {
  /**
   * Type of the comments, e.g. Invoice, UserTraining, etc.
   */
  commentType: CommentReferenceTypes;
  /**
   * Controls permissions related functionality like internal comments available to admins only
   */
  isAdmin: boolean;
  /**
   * Entity identifier to attach comments to
   */
  referenceId: number;
  /**
   * Optional link that will be shown inside of comments
   */
  link?: string;
  /**
   * default initial value for added comment
   */
  defaultComment?: Partial<IComment>;
  /**
   * set to true if user can not add comments
   */
  cannotComment?: boolean;

  /**
   * Event fired after a comment has been added successfully
   */
  onCommentAdded?: (comment: NewComment) => void;

  onEditComment?: (comment: IComment, newComment: NewComment) => void;
  onDeleteComment?: (comment: IComment) => void;
}

/**
 * Reusable comments component.
 * For example:
 * <Comments
 *    commentType={CommentReferenceTypes.Invoice}
 *    referenceId={referenceId}
 *    isAdmin={isAdmin}
 *    link={link}
 *    />
 * @param param
 * @returns
 */
export const Comments = ({
  commentType,
  referenceId,
  isAdmin,
  link,
  defaultComment,
  cannotComment,
  pageName,
  emailType,
  ...props
}: CommentsProps) => {
  const { t } = useTranslation();
  const [changed, setChanged] = React.useState(false);

  const comments = useAsyncGetWthEffect<IComment[]>(
    () => CommentsApi.GetComments(commentType, referenceId),
    [],
    [changed],
  );

  // const classes = useStyles();

  return (
    <CommentsContainer
      Comments={comments}
      onSubmit={c => {
        CommentsApi.SendComment(commentType, referenceId, c)
          .then(() => props.onCommentAdded?.(c))
          .finally(() => setChanged(prev => !prev));
      }}
      isAdmin={isAdmin}
      withMentions
      pageName={pageName ?? t(translations.Invoice)}
      referenceName={referenceId.toString()}
      linkUrl={useAbsolute(link)}
      emailType={emailType ?? 'Invoice'}
      defaultComment={defaultComment}
      cannotComment={cannotComment}
      {...props}
    />
  );
};
//   <Box component="div" className={classes.base}>
// </Box>
// const useStyles = makeStyles((theme: Theme) => ({
//   base: {
//     paddingInline: theme.spacing(2),
//   },
// }));
