import { flow, partialRight } from 'lodash';
import { Period } from 'types/Period';
import { applyForceFullDayReservations } from './applyForceFullDayReservations';
import { SchedulerPeriod } from '../types/SchedulerPeriod';

/**
 * Intended to adjust new event start/end according to ForceFullDayReservations equipment setting
 * @param period
 * @param forceFullDayReservations
 * @returns
 */
export function adjustSchedulerEventTimes(
  period: SchedulerPeriod,
  forceFullDayReservations: boolean | undefined,
): SchedulerPeriod {
  const p = (period: SchedulerPeriod): Period => ({
    start: period.start_date,
    end: period.end_date,
  });
  const pp = (period: Period): SchedulerPeriod => ({
    start_date: period.start,
    end_date: period.end,
  });

  const result = flow(
    p,
    partialRight(applyForceFullDayReservations, forceFullDayReservations),
    pp,
  )(period);
  return result;
}
