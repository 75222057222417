/**
 *
 * ExtraDDLPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IExtraDDLDto } from 'api/odata/generated/entities/IExtraDDLDto';
import { Condition } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import * as React from 'react';
export type CatUrl = 'base' | 'assetBase' | 'public';
export interface ExtraDDLPickerProps
  extends Omit<AutocompletePickerProps<IExtraDDLDto>, 'loadData'> {
  urlType?: any;
}

const url = '/api/odata/v4/ExtraDDL';
export const initExtraDDLData = (
  initval: string | undefined,
  sourceUrl?: string,
) => {
  if (initval === undefined) {
    return new Promise<IExtraDDLDto[]>((resolve, reject) => {
      resolve([] as IExtraDDLDto[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name,Active,ExtraDdlId',
      $skip: 0,
      $top: 1,
    };
    let initUrl = `${url}?key=${sourceUrl ?? ''}`;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as IExtraDDLDto[]);
  }
};

const loadData = (
  predicates: (string | Condition<IExtraDDLDto>)[] | undefined,
  urlType?: string,
) => {
  const sourceUrl = `${url}?key=${urlType ?? ''}`;
  return getAutoCompleteLoadDataFn<IExtraDDLDto>({
    url: sourceUrl,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: ['Id', 'Name', 'Active', 'ExtraDdlId'],
    globalServiceGroupFilter: [],
  });
};

export function ExtraDDLPicker(props: ExtraDDLPickerProps) {
  const { predicates, urlType, ...other } = props;

  const innerLoadData = loadData(predicates, urlType);
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={innerLoadData}
      id={props.id || `ExtraDDL_${urlType}Id`}
      {...other}
    />
  );
}
