import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import { IServiceRequestTicketDto } from 'api/odata/generated/entities/IServiceRequestTicketDto';
import { IUserBaseDto } from 'api/odata/generated/entities/IUserBaseDto';
import { PrioritiesUnion } from 'api/odata/generated/enums/Priorities';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface IServiceRequestTicketEdit {
  AssignedTo?: IUserBaseDto;
  Status?: IServiceRequestStatusDto;
  Priority?: PrioritiesUnion;
  ParentTicketId?: number | null;
  SamplesCount?: number | null;
}

export type ServiceRequestSubTicketSchema = Pick<
  IServiceRequestTicketDto,
  'AssignedTo' | 'Priority' | 'Status' | 'SamplesCount'
>;
export const getInitialValues = (
  item: IServiceRequestTicketDto,
  t: TFunction,
) => ({
  AssignedTo: item.AssignedTo,
  Status: item.Status,
  Priority: item.Priority,
  ParentTicketId: item.ParentTicketId,
  SamplesCount: item.ParentTicketId === null ? undefined : item.SamplesCount,
});

export const getTicketSchema = (
  t: TFunction,
  item: IServiceRequestTicketDto,
  samplesAutomationEnabled: boolean,
): Yup.SchemaOf<IServiceRequestTicketEdit> => {
  const base = {
    AssignedTo: Yup.mixed().optional(),
    Priority: Yup.mixed().required(),
    Status: Yup.mixed().required(),
    ParentTicketId: Yup.number().optional().nullable(),
  };
  const isSubTicket =
    item.ParentTicketId !== null &&
    item.ParentTicketId !== undefined &&
    !isNaN(item.ParentTicketId);
  if (!isSubTicket) {
    return Yup.object({
      ...base,
      SamplesCount: Yup.number().optional(),
    });
  } else {
    const parentSamplesCount = item.ParentTicket?.SamplesCount ?? 0;
    const samplesRequired = samplesAutomationEnabled && parentSamplesCount > 0;
    const max = parentSamplesCount + (item.SamplesCount ?? 0);
    return Yup.object({
      ...base,
      SamplesCount: samplesRequired
        ? Yup.number().required().min(0).max(max)
        : Yup.number().optional(),
    });
  }
};
