import { EmailApi, IEmail } from 'api/EmailApi';
import { extractErrorMessages } from 'api/utils';
import { useAppSettingsSlice } from 'app/slice';
import { translations } from 'locales/translations';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IResponseType } from 'types/ResponseType';
import { useResponseModel } from './useResponseModel';

export const useSendMail = (setBusy?: (busy: boolean) => void) => {
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const { t } = useTranslation();
  const response = useResponseModel();

  return useCallback(
    (email: IEmail) => {
      try {
        setBusy?.(true);
        EmailApi.SendEmail(email)
          .then(v => {
            const value = v as IResponseType;
            response(value);
          })
          .catch(e => {
            //console.log(e);
            const error = extractErrorMessages(e);
            dispatch(
              actions.addNotification({
                variant: 'error',
                message: !!error
                  ? error[0]
                  : (t(translations.err_SendEmailToUserFailed) as string),
              }),
            );
            setBusy?.(false);
          })
          .finally(() => setBusy?.(false));
      } catch (e) {
        const error = extractErrorMessages(e);
        dispatch(
          actions.addNotification({
            variant: 'error',
            message: !!error
              ? error[0]
              : (t(translations.err_SendEmailToUserFailed) as string),
          }),
        );
        setBusy?.(false);
      }
    },
    [actions, dispatch, response, setBusy, t],
  );
};
