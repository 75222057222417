import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { savedViewActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { translations } from 'locales/translations';
import i18next from 'i18next';
import {
  ConvertModelToEntity,
  SavedViewDetailsState,
  SavedViewResponse,
} from './types';
import { savedViewsApi as api, savedViewsApi } from 'api/savedViewsApi';
import { ISavedViewDto } from 'api/odata/generated/entities/ISavedViewDto';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { SavedViewDetailsProps } from '../Details';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { AxiosError } from 'axios';
import { Entity } from 'types/common';
import { initAssetsData } from 'app/components/pickers/MultiSelectPickers/AssetsPicker';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';

const loadNew = async (eid?: string): Promise<SavedViewDetailsState> => {
  if (!!eid && eid !== '') {
    return initAssetsData(eid, true).then(data => {
      return {
        Id: -1,
        Name: '',
        Assets: data,
        Shared: false,
        Multiple: false,
        // SharedTo: null,
        // SharedUsers: [] as Entity<string>[],
        // SharedCores: [] as Entity<number>[],
      } as SavedViewDetailsState;
    });
  } else {
    return new Promise<SavedViewDetailsState>(resolve => {
      resolve({
        Id: -1,
        Name: '',
        Assets: [] as Entity<number>[],
        Shared: false,
        Multiple: false,
        // SharedTo: null,
        // SharedUsers: [] as Entity<string>[],
        // SharedCores: [] as Entity<number>[],
      } as SavedViewDetailsState);
    });
  }
};

function* doInitNewDetails(action: PayloadAction<string | undefined>) {
  try {
    const data = yield call(loadNew, action.payload);
    yield put(actions.initNewDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initNewDetails_Error(Error));
  }
}
function* doInitDetails(action: PayloadAction<number>) {
  try {
    const data = yield call(api.initUpdateData, action.payload);
    console.log(data);
    yield put(actions.initDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initDetails_Error(Error));
  }
}
function* doCreate(action: PayloadAction<SavedViewDetailsState>) {
  const httpPayload: ISavedViewDto = ConvertModelToEntity(
    action.payload,
    undefined,
  );
  try {
    let responses: SavedViewResponse[] = [];
    const result = yield call(api.createSavedView, httpPayload);
    responses.push(result);

    let responseErrors = responses
      .filter(err => err.ErrorMessages.length > 0)
      .flatMap(f => f.ErrorMessages);

    let responseWarnings = responses
      .filter(err => err.WarningMessages.length > 0)
      .flatMap(f => f.WarningMessages);
    let responseSuccess = responses
      .filter(err => err.SuccessMessages.length > 0)
      .flatMap(f => f.SuccessMessages);
    if (responseErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseErrors.map(item => {
            return {
              key: 'savedViewInsert',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (responseWarnings.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseWarnings.map(item => {
            return {
              key: 'savedViewInsert',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (responseSuccess.length > 0) {
      let res = responses[0];
      yield put(
        appSettingsActions.addNotification({
          key: 'savedViewInsert',
          message: responseSuccess[0],
          messageType: SnackBarMessageType.openSidepanelDetails,
          messageTypeProps: {
            Id: res.Id,
            created: true,
            itemName: i18next.t(translations.MyInstrumentList),
            detailsType: RenderPageType.SavedViewDetails,
            detailsTypeProps: {
              id: '' + res.Id,
              useSidePanel: true,
            } as SavedViewDetailsProps,
          },
          variant: 'success',
        }),
      );
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors =
      responses.filter(f => f.ErrorMessages.length > 0).length > 0;
    yield put(
      actions.createView_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'savedViewInsert',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createView_Error(Error));
  }
}
function* doUpdate(
  action: PayloadAction<{
    current: SavedViewDetailsState;
    original: SavedViewDetailsState | undefined;
  }>,
) {
  const httpPayload: ISavedViewDto = ConvertModelToEntity(
    action.payload.current,
    action.payload.original,
  );
  // const currentSelected = yield select(selectMyListSelected);
  // const userProfileSettings = yield select(selectUserProfileSettings);
  // let listViewSelected = currentSelected;
  // if (!listViewSelected) {
  //   let proffSettings = userProfileSettings.GetSettingValueBy(
  //     f => f.Key.toLowerCase() === 'myListSelected'.toLowerCase(),
  //   );
  //   listViewSelected = !!proffSettings
  //     ? {
  //         listId:
  //           proffSettings.Name === null
  //             ? undefined
  //             : parseInt(proffSettings.Name),
  //         ids: proffSettings.Value ?? undefined,
  //         assetIds: proffSettings.DefaultGroups ?? undefined,
  //       }
  //     : undefined;
  // }
  try {
    const results = yield call(api.updateSavedView, httpPayload);
    let response = results as SavedViewResponse;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'savedViewUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'savedViewUpdateWarn',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          key: 'savedViewUpdate',
          message: response.SuccessMessages[0],
          messageType: SnackBarMessageType.openSidepanelDetails,
          messageTypeProps: {
            Id: response.Id,
            created: false,
            itemName: i18next.t(translations.MyInstrumentList),
            detailsType: RenderPageType.SavedViewDetails,
            detailsTypeProps: {
              id: '' + response.Id,
              useSidePanel: true,
            } as SavedViewDetailsProps,
          },
          variant: 'success',
        }),
      );
      // if (!!listViewSelected && !!listViewSelected.listId) {
      //   if (listViewSelected.listId === action.payload.original?.Id) {
      //     yield call(doSelectView, {
      //       payload: httpPayload,
      //       type: actions.selectMyList.type,
      //     });
      //   }
      // }
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateView_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'savedViewUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateView_Error(Error));
  }
}
function* doUpdateViewAssets(
  action: PayloadAction<{ assets: Entity<number>[]; view: ISavedViewDto }>,
) {
  const newAssets: Entity<number>[] = [];
  // const currentSelected = yield select(selectMyListSelected);
  // const userProfileSettings = yield select(selectUserProfileSettings);
  // let listViewSelected = currentSelected;
  // if (!listViewSelected) {
  //   let proffSettings = userProfileSettings.GetSettingValueBy(
  //     f => f.Key.toLowerCase() === 'myListSelected'.toLowerCase(),
  //   );
  //   listViewSelected = !!proffSettings
  //     ? {
  //         listId:
  //           proffSettings.Name === null
  //             ? undefined
  //             : parseInt(proffSettings.Name),
  //         ids: proffSettings.Value ?? undefined,
  //         assetIds: proffSettings.DefaultGroups ?? undefined,
  //       }
  //     : undefined;
  // }
  action.payload.assets.forEach(el => {
    let a = action.payload.view.Assets.find(a => a.Id === el.Id);
    if (a === undefined) {
      newAssets.push(el);
    }
  });
  let groupId = (newAssets[0] as IAssetDto).ServiceGroupId;
  const shared =
    action.payload.view.Shared === true
      ? !newAssets.some(f => (f as IAssetDto).ServiceGroupId !== groupId)
      : false;
  const httpPayload: ISavedViewDto = Object.assign({}, action.payload.view, {
    Assets: newAssets.concat(action.payload.view.Assets),
    Shared: shared,
  });

  try {
    const results = yield call(api.updateSavedView, httpPayload);
    let response = results as SavedViewResponse;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'savedViewUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'savedViewUpdateWarn',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          key: 'savedViewUpdate',
          message: response.SuccessMessages[0],
          variant: 'success',
        }),
      );
      // if (!!listViewSelected && !!listViewSelected.listId) {
      //   if (listViewSelected.listId === action.payload.view.Id) {
      //     yield call(doSelectView, {
      //       payload: httpPayload,
      //       type: actions.selectMyList.type,
      //     });
      //   }
      // }
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateViewAssets_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'savedViewUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateViewAssets_Error(Error));
  }
}
function* doSelectSavedView(action: PayloadAction<number | undefined>) {
  try {
    if (action.payload === undefined) {
      yield put(actions.selectSavedView_Success());
      return;
    }

    const x: ISavedViewDto = yield call(
      savedViewsApi.initUpdateData,
      action.payload,
    );
    // check if the saved view belongs to current service groups selection
    const serviceGroups: Array<Entity<number>> | undefined = yield select(
      selectGlobalServiceGroupFilter,
    );
    const serviceGroupIds = serviceGroups?.map(f => f.Id);
    const belongsToServiceGroupsSelection =
      serviceGroups === undefined ||
      serviceGroups?.length === 0 ||
      x.Assets.every(
        asset => serviceGroupIds?.includes(asset.ServiceGroupId ?? 0) === true,
      );
    if (belongsToServiceGroupsSelection) {
      yield put(actions.selectSavedView_Success(x));
    } else {
      yield put(actions.selectSavedView_Success());
    }
  } catch (error) {
    yield put(actions.selectSavedView_Success());
  }
}
function* doPatch(action: PayloadAction<Partial<ISavedViewDto>>) {
  try {
    yield call(savedViewsApi.patch, action.payload);
    yield put(actions.patch_success(action.payload));
  } catch (error) {
    //noop
    console.log(error);
  }
}

export function* savedViewSaga() {
  yield takeLatest(actions.initNewDetails.type, doInitNewDetails);
  yield takeLatest(actions.initDetails.type, doInitDetails);
  yield takeLatest(actions.createView.type, doCreate);
  yield takeLatest(actions.updateView.type, doUpdate);
  yield takeLatest(actions.updateViewAssets.type, doUpdateViewAssets);
  yield takeLatest(actions.selectSavedView.type, doSelectSavedView);
  yield takeLatest(actions.patch.type, doPatch);
}
