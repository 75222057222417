/* @flow */

import useIsomorphicLayoutEffect from 'app/hooks/useIsomorphicLayoutEffect';
import * as React from 'react';
import {
  DateStartWith,
  DateTimeMask,
  formatingDate,
  formatingTime,
  getMaskedDateValue,
  getMaskedTimeValue,
} from './types';

export interface DateChangeProps {
  value: string;
  onChange: (text: string) => void;
  onSelectionChange: () => boolean;
  variant: 'date' | 'time';
  maskFormats: DateTimeMask;
  inputMask: string;
  ampm?: boolean;
  // format: (str: string) => string;
  mask?: boolean;
  maskIfEmpty?: boolean;
  // replace?: string => string,
  // append?: string => string,
  // accept?: RegExp,
  selectionChanged?: boolean;
}

type RenderProps = {
  value: string;
  onChange: (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    increment?: 'increase' | 'decrease',
  ) => void;
};

export type ResolverProps = {
  props: DateChangeProps;
  children: (props: RenderProps) => React.ReactNode;
};

export const useDateTimeChange = (props: DateChangeProps): RenderProps => {
  const [, refresh] = React.useReducer(c => c + 1, 0);
  const valueRef = React.useRef<any | null>(null);

  const dateTimeMask = props.maskFormats;
  const dateStartOf: DateStartWith = React.useMemo(() => {
    return dateTimeMask.dateStartFrom;
  }, [dateTimeMask.dateStartFrom]);
  // const { replace, append } = props;
  // const { variant } = props;
  // const userValue = replace
  //   ? replace(props.format(props.value))
  //   : props.format(props.value);
  const userValue = props.value;
  // state of delete button see comments below about inputType support
  const isDeleleteButtonDownRef = React.useRef(false);
  const wasZeroBefore = React.useRef(false);
  const selectionStateChanged = React.useRef(false);
  const onChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    increment?: 'increase' | 'decrease',
  ) => {
    if (process.env.NODE_ENV !== 'production') {
      if (evt.target.type === 'number') {
        console.error(
          'Rifm does not support input type=number, use type=tel instead.',
        );
        return;
      }
      if (evt.target.type === 'date') {
        console.error('Rifm does not support input type=date.');
        return;
      }
    }

    const eventValue = evt.target.value;
    const target = evt.target as HTMLInputElement;
    const oneLetterHour =
      props.variant === 'time' &&
      props.ampm &&
      dateTimeMask.hour.length === 1 &&
      (eventValue === '' || eventValue.split(':')[0].length === 1);
    const hasSingleChar =
      eventValue.length === 1 && (target.selectionStart ?? 0) <= 1;
    const maskedValue = hasSingleChar
      ? props.variant === 'time'
        ? getMaskedTimeValue(props.maskFormats, props.ampm, eventValue)
        : getMaskedDateValue(props.maskFormats, eventValue)
      : undefined;
    selectionStateChanged.current = props.onSelectionChange();
    valueRef.current = [
      hasSingleChar ? maskedValue : eventValue, // eventValue
      target, // input
      // eventValue.length > userValue.length, // isSizeIncreaseOperation
      // eventValue.length < userValue.length, // isSizeDecraseOperation
      increment, //'increase' | 'decrease' by keyboard
      props.variant === 'time' &&
        (target.selectionStart ?? 0) <= (oneLetterHour ? 1 : 2), //isHourChanged
      props.variant === 'time' &&
        (target.selectionStart ?? 0) > (oneLetterHour ? 1 : 2) &&
        (target.selectionEnd ?? 0) <= (oneLetterHour ? 4 : 5), //isMinutesChanged
      props.variant === 'time' &&
        props.ampm &&
        (target.selectionStart ?? 0) >= 5, //isAmPmChanged
      dateStartOf === 'day'
        ? (target.selectionStart ?? 0) <= 2
        : dateStartOf === 'month'
        ? (target.selectionStart ?? 0) > 2 && (target.selectionEnd ?? 0) <= 5
        : (target.selectionStart ?? 0) >= 8, //isDayChanged
      dateStartOf === 'day'
        ? (target.selectionStart ?? 0) > 2 && (target.selectionEnd ?? 0) <= 5
        : dateStartOf === 'month'
        ? (target.selectionStart ?? 0) <= 2
        : (target.selectionStart ?? 0) > 4 && (target.selectionEnd ?? 0) < 7, //isMonthChanged

      dateStartOf === 'day' || dateStartOf === 'month'
        ? (target.selectionStart ?? 0) > 5
        : (target.selectionStart ?? 0) <= 4, //isYearChanged
      isDeleleteButtonDownRef.current, // isDeleleteButtonDown
      props.variant === 'time' ? false : userValue === eventValue, // isNoOperation
      selectionStateChanged.current,
    ];
    // if (process.env.NODE_ENV !== 'production') {
    //   const formattedEventValue = props.format(eventValue);
    //   if (
    //     eventValue !== formattedEventValue &&
    //     eventValue.toLowerCase() === formattedEventValue.toLowerCase()
    //   ) {
    //     console.warn(
    //       'Case enforcement does not work with format. Please use replace={value => value.toLowerCase()} instead',
    //     );
    //   }
    // }

    // The main trick is to update underlying input with non formatted value (= eventValue)
    // that allows us to calculate right cursor position after formatting (see getCursorPosition)
    // then we format new value and call props.onChange with masked/formatted value
    // and finally we are able to set cursor position into right place
    refresh();
  };
  // React prints warn on server in non production mode about useLayoutEffect usage
  // in both cases it's noop
  useIsomorphicLayoutEffect(() => {
    if (valueRef.current == null) return;

    let [
      eventValue,
      input,
      increment,
      isHourChanged,
      isMinutesChanged,
      isAmPmChanged,
      isDayChanged,
      isMonthChanged,
      isYearChanged,
      isDeleleteButtonDown,
      // No operation means that value itself hasn't been changed, BTW cursor, selection etc can be changed
      isNoOperation,
      selectionChanged,
    ] = valueRef.current;
    valueRef.current = null;
    let formattedValue = eventValue;
    let moveSelectionForward = true;
    if (eventValue === '' || eventValue === undefined) {
      formattedValue = props.maskIfEmpty
        ? props.variant === 'time'
          ? getMaskedTimeValue(dateTimeMask, props.ampm)
          : getMaskedDateValue(dateTimeMask)
        : '';
    } else {
      if (!isNoOperation) {
        if (props.variant === 'time') {
          if (isHourChanged || isMinutesChanged || isAmPmChanged) {
            let finalTime = formatingTime(
              eventValue,
              isHourChanged ? 'hour' : isMinutesChanged ? 'minute' : 'meridiem',
              dateTimeMask,
              userValue,
              increment,
              props.ampm,
            );
            formattedValue = finalTime.value;
            moveSelectionForward = !finalTime.stayOnSelection;
          }
        } else {
          if (isDayChanged || isMonthChanged || isYearChanged) {
            let finalValue = formatingDate(
              eventValue,
              isDayChanged ? 'day' : isMonthChanged ? 'month' : 'year',
              dateTimeMask,
              userValue,
              increment,
              wasZeroBefore.current,
              selectionChanged,
            );
            formattedValue = finalValue.value;
            moveSelectionForward = !finalValue.stayOnSelection;
            wasZeroBefore.current = finalValue.zeroPress;
          }
        }
      }
    }
    if (isDeleleteButtonDown) {
    }
    if (userValue === formattedValue || isNoOperation) {
      // if nothing changed for formatted value, just refresh so userValue will be used at render
      refresh();
    } else {
      props.onChange(formattedValue);
    }
    // const replacedValue = replace ? replace(formattedValue) : formattedValue;
    // const replacedValue = formattedValue;
    // if (userValue === formattedValue || isNoOperation) {
    //   // if nothing changed for formatted value, just refresh so userValue will be used at render
    //   refresh();
    // } else {
    //   props.onChange(formattedValue);
    // }

    return () => {
      if (
        (eventValue === '' || eventValue === undefined) &&
        props.maskIfEmpty
      ) {
        if (props.variant === 'time') {
          if (props.ampm) {
            let oneLetterHour = dateTimeMask.hour.length === 1;
            input.setSelectionRange(0, oneLetterHour ? 1 : 2);
          } else {
            input.setSelectionRange(0, 2);
          }
        } else {
          if (dateStartOf === 'year') {
            input.setSelectionRange(0, 4);
          } else {
            input.setSelectionRange(0, 2);
          }
        }
      } else {
        if (props.variant === 'time') {
          let oneLetterHour =
            props.ampm &&
            dateTimeMask.hour.length === 1 &&
            formattedValue.split(':')[0].length === 1;
          if (isHourChanged) {
            if (moveSelectionForward) {
              input.setSelectionRange(
                oneLetterHour ? 2 : 3,
                oneLetterHour ? 4 : 5,
              );
            } else {
              input.setSelectionRange(0, oneLetterHour ? 1 : 2);
            }
          }
          if (isMinutesChanged) {
            if (props.ampm) {
              if (moveSelectionForward) {
                input.setSelectionRange(
                  oneLetterHour ? 5 : 6,
                  oneLetterHour ? 7 : 8,
                );
              } else {
                input.setSelectionRange(
                  oneLetterHour ? 2 : 3,
                  oneLetterHour ? 4 : 5,
                );
              }
            } else {
              input.setSelectionRange(3, 5);
            }
          }
          if (isAmPmChanged) {
            input.setSelectionRange(
              oneLetterHour ? 5 : 6,
              oneLetterHour ? 7 : 8,
            );
          }
        } else {
          if (isDayChanged) {
            if (moveSelectionForward) {
              if (dateStartOf === 'year') {
                input.setSelectionRange(8, 10);
              } else if (dateStartOf === 'month') {
                input.setSelectionRange(6, 10);
              } else {
                input.setSelectionRange(3, 5);
              }
            } else {
              if (dateStartOf === 'year') {
                input.setSelectionRange(8, 10);
              } else if (dateStartOf === 'month') {
                input.setSelectionRange(3, 5);
              } else {
                input.setSelectionRange(0, 2);
              }
            }
          }
          if (isMonthChanged) {
            if (moveSelectionForward) {
              if (dateStartOf === 'year') {
                input.setSelectionRange(8, 10);
              } else if (dateStartOf === 'month') {
                input.setSelectionRange(3, 5);
              } else {
                input.setSelectionRange(6, 10);
              }
            } else {
              if (dateStartOf === 'year') {
                input.setSelectionRange(3, 5);
              } else if (dateStartOf === 'month') {
                input.setSelectionRange(0, 2);
              } else {
                input.setSelectionRange(3, 5);
              }
            }
          }
          if (isYearChanged) {
            if (moveSelectionForward) {
              if (dateStartOf === 'year') {
                input.setSelectionRange(5, 7);
              } else if (dateStartOf === 'month') {
                input.setSelectionRange(6, 10);
              } else {
                input.setSelectionRange(6, 10);
              }
            } else {
              if (dateStartOf === 'year') {
                input.setSelectionRange(0, 4);
              } else if (dateStartOf === 'month') {
                input.setSelectionRange(6, 10);
              } else {
                input.setSelectionRange(6, 10);
              }
            }
          }
        }
      }
      selectionStateChanged.current = false;
      //   let start = getCursorPosition(formattedValue);
      //   // Visually improves working with masked values,
      //   // like cursor jumping over refused symbols
      //   // as an example date mask: was "5|1-24-3" then user pressed "6"
      //   // it becomes "56-|12-43" with this code, and "56|-12-43" without
      //   if (
      //     props.mask != null &&
      //     (isSizeIncreaseOperation ||
      //       isSizeDecraseOperation ||
      //       (isDeleleteButtonDown && !deleteWasNoOp))
      //   ) {
      //     while (formattedValue[start] && clean(formattedValue[start]) === '') {
      //       start += 1;
      //     }
      //   }
      //   input.selectionStart = input.selectionEnd =
      //     start + (deleteWasNoOp ? 1 + charsToSkipAfterDelete : 0);
    };
  });

  React.useEffect(() => {
    // until https://developer.mozilla.org/en-US/docs/Web/API/InputEvent/inputType will be supported
    // by all major browsers (now supported by: +chrome, +safari, ?edge, !firefox)
    // there is no way I found to distinguish in onChange
    // backspace or delete was called in some situations
    // firefox track https://bugzilla.mozilla.org/show_bug.cgi?id=1447239
    const handleKeyDown = (evt: KeyboardEvent) => {
      if (evt.code === 'Delete') {
        isDeleleteButtonDownRef.current = true;
      }
    };

    const handleKeyUp = (evt: KeyboardEvent) => {
      if (evt.code === 'Delete') {
        isDeleleteButtonDownRef.current = false;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return {
    value: valueRef.current != null ? valueRef.current[0] : userValue,
    onChange,
  };
};

export const DateTimeResolver = (props: ResolverProps) => {
  const renderProps = useDateTimeChange(props.props);

  return props.children(renderProps);
};
