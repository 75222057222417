import {
  FormControlProps as MuiFormControlProps,
  FormControl as MuiFormControl,
} from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
export interface FormControlProps extends MuiFormControlProps {
  boxed?: boolean;
}
const FormControl = (props: FormControlProps) => {
  const { boxed, className, ...other } = props;
  return (
    <MuiFormControl className={clsx(className, { boxed: boxed })} {...other} />
  );
};

export default FormControl;
