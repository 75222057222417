import { PayloadAction } from '@reduxjs/toolkit';
import { IUserTrainingDto } from 'api/odata/generated/entities/IUserTrainingDto';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { trainingSaga } from './saga';
import {
  ConvertEntityToModel,
  TrainingDetailsState,
  TrainingReservationsState,
  TrainingsResponse,
  TrainingState,
} from './types';

export const initialState: TrainingState = {
  createState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  updateState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  reservationsUpdateState: {
    showApproveReservations: false,
    showRejectReservations: false,
    ApproveRejectRecords: [],
    processing: false,
    completed: undefined,
  },
  processing: false,
};

const slice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    initCreateTraining(
      state,
      action: PayloadAction<{
        equId?: string | null;
        equIds?: string | null;
        fatherId?: string | null;
        rid?: string | null;
        date?: string | null;
        user?: string | null;
      }>,
    ) {
      state.processing = true;
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initCreateTraining_Success(
      state,
      action: PayloadAction<TrainingDetailsState>,
    ) {
      state.processing = false;
      state.createState.data = {
        Id: null,
        Active: action.payload.Active,
        AllowBookingFromTrainingDate: true,
        Equipments: action.payload.Equipments,
        RequiresAdminApproval: false,
        Reservations: action.payload.Reservations,
        UnhideServiceGroup: false,
        Users: action.payload.Users,
        TrainingDate: action.payload.TrainingDate,
        ExpiredDate: null,
        MustOrderEveryDays: undefined,
        MustUseEveryDays: undefined,
        Remarks: undefined,
        Trainer: action.payload.Trainer,
        TrainingLevel: undefined,
        FatherId: action.payload.FatherId ?? null,
        ServiceGroupId: null,
        TrainingProgram: undefined,
      };
    },
    initCreateTraining_Error(state, action: PayloadAction<any>) {
      state.processing = false;
      state.createState.data = {
        Id: null,
        Active: true,
        AllowBookingFromTrainingDate: true,
        Equipments: [],
        RequiresAdminApproval: false,
        Reservations: undefined,
        UnhideServiceGroup: false,
        Users: [],
        TrainingDate: new Date(),
        ExpiredDate: null,
        MustOrderEveryDays: undefined,
        MustUseEveryDays: undefined,
        Remarks: undefined,
        Trainer: undefined,
        TrainingLevel: undefined,
        FatherId: null,
        ServiceGroupId: null,
        TrainingProgram: undefined,
      };
    },
    createTraining(state, action: PayloadAction<TrainingDetailsState>) {
      state.createState.processing = true;
      state.createState.completed = undefined;
      state.createState.hasErrors = undefined;
    },
    createTraining_Success(state, action: PayloadAction<TrainingsResponse>) {
      state.createState.processing = false;
      state.reservationsUpdateState.ApproveRejectRecords =
        action.payload.ApproveRejectRecords;
      state.reservationsUpdateState.showApproveReservations =
        action.payload.ShowApproveReservationsMessage;
      state.reservationsUpdateState.showRejectReservations =
        action.payload.ShowReviseToPendingMessage;
      state.createState.hasErrors = action.payload.ErrorMessages.length > 0;
      state.createState.completed = true;
    },
    createTraining_Error(state, action: PayloadAction<any>) {
      state.createState.processing = false;
      state.createState.completed = true;
    },
    resetCreateTrainingState(state, action: PayloadAction) {
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    resetReservationsState(state, action: PayloadAction) {
      state.reservationsUpdateState = {
        showApproveReservations: false,
        showRejectReservations: false,
        ApproveRejectRecords: [],
        processing: false,
        completed: undefined,
      };
    },
    resetUpdateTrainingState(state, action: PayloadAction) {
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initUpdateTraining(
      state,
      action: PayloadAction<{
        Id: number;
      }>,
    ) {
      state.processing = true;
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initUpdateTraining_Success(state, action: PayloadAction<IUserTrainingDto>) {
      state.processing = false;
      state.updateState.data = ConvertEntityToModel(action.payload);
    },
    initUpdateTraining_Error(state, action: PayloadAction<any>) {
      state.processing = false;
      state.updateState.data = undefined;
    },
    updateTraining(
      state,
      action: PayloadAction<{
        current: TrainingDetailsState;
        original: TrainingDetailsState | undefined;
      }>,
    ) {
      state.updateState.processing = true;
      state.updateState.completed = undefined;
      state.updateState.hasErrors = undefined;
    },
    updateTraining_Success(state, action: PayloadAction<TrainingsResponse>) {
      state.updateState.processing = false;
      state.reservationsUpdateState.ApproveRejectRecords =
        action.payload.ApproveRejectRecords;
      state.reservationsUpdateState.showApproveReservations =
        action.payload.ShowApproveReservationsMessage;
      state.reservationsUpdateState.showRejectReservations =
        action.payload.ShowReviseToPendingMessage;
      state.updateState.hasErrors = action.payload.ErrorMessages.length > 0;
      state.updateState.completed = true;
    },
    updateTraining_Error(state, action: PayloadAction<any>) {
      state.updateState.processing = false;
      state.updateState.completed = true;
    },
    setReservationsUpdateState(
      state,
      action: PayloadAction<TrainingReservationsState>,
    ) {
      state.reservationsUpdateState.ApproveRejectRecords =
        action.payload.ApproveRejectRecords;
      state.reservationsUpdateState.showApproveReservations =
        action.payload.showApproveReservations;
      state.reservationsUpdateState.showRejectReservations =
        action.payload.showRejectReservations;
    },
    approveRejectReservations(
      state,
      action: PayloadAction<{
        trainings: number[];
        approve: boolean;
      }>,
    ) {
      state.reservationsUpdateState.processing = true;
      state.reservationsUpdateState.completed = false;
    },
    approveRejectReservations_success(state, action: PayloadAction) {
      state.reservationsUpdateState.processing = false;
      state.reservationsUpdateState.completed = true;
    },
    approveRejectReservations_error(state, action: PayloadAction<any>) {
      state.reservationsUpdateState.processing = false;
      state.reservationsUpdateState.completed = true;
    },
    activateDeactivateTraining(
      state,
      action: PayloadAction<{ Id?: number; activate?: boolean }>,
    ) {
      state.updateState.processing = true;
      state.updateState.completed = false;
    },
    activateDeactivateTraining_success(
      state,
      action: PayloadAction<boolean | undefined>,
    ) {
      state.updateState.processing = false;
      state.updateState.completed = true;
      if (state.updateState.data) {
        state.updateState.data.Active = action.payload;
      }
    },
    activateDeactivateTraining_error(state, action: PayloadAction<any>) {
      state.updateState.processing = false;
      state.updateState.completed = true;
    },
  },
});

export const { actions: trainingActions } = slice;

export const useTrainingSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: trainingSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useTrainingSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
