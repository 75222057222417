import { BaseTextFieldProps, InputAdornment } from '@material-ui/core';
import { PureDateInputProps } from '@material-ui/pickers/_shared/PureDateInput';
import React from 'react';
import { isNullOrUndefined } from 'utils/typeUtils';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import BaseTextField from '../BasicInputs/BaseTextField';
import { DatePopupVariants } from './utils/types';

export interface DateInputProps
  extends PureDateInputProps,
    Pick<
      BaseTextFieldProps,
      | 'autoFocus'
      | 'fullWidth'
      | 'helperText'
      | 'placeholder'
      | 'required'
      | 'disabled'
      | 'name'
      | 'id'
      | 'label'
      | 'margin'
    > {
  info?: string;
  ariaLabel?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  togglePicker: () => void;
  variant?: DatePopupVariants;
}

export function DateInput(props: DateInputProps) {
  const {
    inputValue,
    openPicker,
    InputProps,
    TextFieldComponent,
    inputProps,
    onBlur,
    onFocus,
    validationError,
    inputVariant,
    togglePicker,
    info,
    ariaLabel,
    startIcon,
    endIcon,
    variant,
    ...other
  } = props;
  const TextComponent = !!TextFieldComponent
    ? TextFieldComponent
    : BaseTextField;
  const innerInputProps = React.useMemo(() => {
    return Object.assign({}, InputProps, { readOnly: true });
  }, [InputProps]);
  return (
    <TextComponent
      //   InputProps={innerInputProps}
      inputProps={{
        ...inputProps,
        'aria-label': props.label
          ? undefined
          : `${ariaLabel ? ariaLabel : 'date input'}`,
        style: { minWidth: 80 },
      }}
      error={Boolean(validationError)}
      InputProps={{
        ...innerInputProps,
        disableUnderline: props.inputVariant === 'standard',
        // startAdornment: (
        //   <InputAdornment position="start">
        //     <Icon icon="calendar-alt" color="default" colorExtend="textHover" />
        //   </InputAdornment>
        // ),
        endAdornment: (
          <>
            {(info && <InfoIcon title={info} error={props.error} />) ||
              (endIcon && (
                <InputAdornment position="end">{endIcon}</InputAdornment>
              ))}
            <InputAdornment position="end">
              <IconButton
                onClick={e => togglePicker()}
                disabled={props.disabled}
                size="xs"
                variant="ghost"
              >
                <Icon
                  icon={variant === 'time' ? 'clock' : 'calendar-alt'}
                  color="default"
                  colorExtend="textHover"
                />
              </IconButton>
            </InputAdornment>
          </>
        ),
        style: { minWidth: 80 },
      }}
      InputLabelProps={{
        shrink:
          props.inputVariant === 'filled'
            ? !isNullOrUndefined(inputValue)
              ? true
              : false
            : undefined,
      }}
      helperText={validationError || props.helperText}
      onClick={ev => {
        const target = ev.target as HTMLInputElement;
        if (!!target && target.nodeName === 'INPUT') openPicker();
      }}
      value={inputValue}
      variant={inputVariant}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          openPicker();
        }
      }}
      onBlur={onBlur}
      onFocus={onFocus}
      {...other}
    />
  );
}
