/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { barcodeScannerReducer } from 'react-usb-barcode-scanner';
import { InjectedReducersType } from 'utils/types/injector-typings';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export const createReducer = history => (
  injectedReducers: InjectedReducersType = {},
) => {
  // Create a function that takes history as an argument and returns the root reducer.
  // Add router reducer into root reducer by passing history to connectRouter.
  // Note: The key MUST be router.

  return combineReducers({
    router: connectRouter(history),
    barcodeScanner: barcodeScannerReducer,
    ...injectedReducers,
  });
};
