import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.savedView || initialState;

export const selectSavedView = createSelector([selectSlice], state => state);

export const selectSavedViewProcessing = createSelector(
  [selectSavedView],
  state => state.processing,
);
export const selectCreateSavedViewProcessing = createSelector(
  [selectSavedView],
  state => state.createState.processing,
);
export const selectUpdateSavedViewProcessing = createSelector(
  [selectSavedView],
  state => state.updateState.processing,
);
export const selectCreateSavedView = createSelector(
  [selectSavedView],
  state => state.createState.data,
);
export const selectUpdateSavedView = createSelector(
  [selectSavedView],
  state => state.updateState.data,
);
export const selectUpdateUserName = createSelector(
  [selectSavedView],
  state => state.savedViewUserName,
);
export const selectCreateSavedViewCompleted = createSelector(
  [selectSavedView],
  state => state.createState.completed,
);
export const selectUpdateSavedViewCompleted = createSelector(
  [selectSavedView],
  state => state.updateState.completed,
);
export const selectCreateSavedViewHasError = createSelector(
  [selectSavedView],
  state => state.createState.hasErrors,
);
export const selectUpdateSavedViewHasError = createSelector(
  [selectSavedView],
  state => state.updateState.hasErrors,
);
export const selectSelectedSavedView = createSelector(
  [selectSavedView],
  state => state.selectedSavedView,
);
