import { isEqual } from 'lodash';
import { Descendant } from 'slate';
import { jsx } from 'slate-hyperscript';
import { ParagraphElement } from 'types/Slate';
import { removeHtml } from 'utils/string-utils';
import { isEmptyOrWhitespace } from 'utils/typeUtils';

const ELEMENT_TAGS = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
  MENTION: el => ({
    type: 'mention',
    user: {
      Id: el.getAttribute('id'),
      Name: el.getAttribute('name').split('|').join(' '),
    },
  }),
  TABLE: () => ({ type: 'table' }),
  TBODY: () => ({ type: 'tbody' }),
  THEAD: () => ({ type: 'thead' }),
  TR: () => ({ type: 'table-row' }),
  TD: () => ({ type: 'table-cell' }),
  TH: () => ({ type: 'table-cell-header' }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
  DATA: () => ({ plain: true }),
};

export const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === 'BR') {
    return '\n';
  }

  const { nodeName } = el;
  let parent = el;

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0];
  }
  let children = Array.from(parent.childNodes).map(deserialize).flat();

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx('element', attrs, children);
  }

  if (ELEMENT_TAGS[el.className]) {
    const attrs = ELEMENT_TAGS[el.className](el);
    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    try {
      const attrs = TEXT_TAGS[nodeName](el);
      return children.map(child => jsx('text', attrs, child));
    } catch (error) {
      //console.log(error); //If have <strong> element, error: The <text> hyperscript tag can only contain text content as children.
      return children;
    }
  }

  return children;
};

const deserializeHtml = node => {
  if (node) {
    return deserialize(node);
  } else return [];
};

export const deserializeHtmlString = (
  htmlString: string | undefined | null,
) => {
  let currentSring = htmlString || '';
  if (isEmptyOrWhitespace(currentSring)) {
    currentSring = '<p></p>';
  } else {
    if (currentSring.indexOf('<') === -1) {
      currentSring = '<p>' + currentSring + '</p>';
    }
  }
  const parsed = new DOMParser().parseFromString(currentSring, 'text/html');
  return deserializeHtml(parsed.body);
};

const elementHtmlString = props => {
  const { attributes, children, element } = props;

  switch (element.type) {
    default:
      return '<p ' + attributes + '>' + children + '</p>';
    case 'quote':
      return '<blockquote' + attributes + '>' + children + '</blockquote>';
    case 'code':
      return (
        '<pre>' +
        '<code ' +
        attributes +
        '>' +
        children +
        '</code> ' +
        '</pre> '
      );
    case 'bulleted-list':
      return '<ul ' + attributes + '>' + children + '</ul>';
    case 'heading-one':
      return '<h1 ' + attributes + '>' + children + '</h1>';
    case 'heading-two':
      return '<h2 ' + attributes + '>' + children + '</h2>';
    case 'heading-three':
      return '<h3 ' + attributes + '>' + children + '</h3>';
    case 'heading-four':
      return '<h4 ' + attributes + '>' + children + '</h4>';
    case 'heading-five':
      return '<h5 ' + attributes + '>' + children + '</h5>';
    case 'heading-six':
      return '<h6 ' + attributes + '>' + children + '</h6>';
    case 'list-item':
      return '<li ' + attributes + '>' + children + '</li>';
    case 'numbered-list':
      return '<ol ' + attributes + '>' + children + '</ol>';
    case 'link':
      return (
        '<a href=' + element.url + ' ' + attributes + '>' + children + '</a>'
      );
    case 'mention':
      return (
        '<span class="MENTION" id=' +
        element.user.Id +
        ' name=' +
        element.user.Name.split(' ').join('|') +
        ' ' +
        attributes +
        '>' +
        children +
        '</span>'
      );
    case 'thead':
      return '<thead ' + attributes + '>' + children + '</thead>';
    case 'tbody':
      return '<tbody ' + attributes + '>' + children + '</tbody>';
    case 'table':
      return (
        '<table style="height: 70px; width: 99.3288%; border-collapse: collapse; border: solid 1px; font-size: 13px; margin: center;" ' +
        attributes +
        '>' +
        children +
        '</table>'
      );
    case 'table-row':
      return (
        '<tr style="width: 18px; height: 15px; vertical-align: bottom; text-align: center; border: solid 1px;" ' +
        attributes +
        '>' +
        children +
        '</tr>'
      );
    case 'table-cell':
      return (
        '<td style="border: solid 1px #CCCCCF;" ' +
        attributes +
        '>' +
        children +
        '</td>'
      );
    case 'table-cell-header':
      return '<th ' + attributes + '>' + children + '</th>';
    case 'image':
      return '<img ' + attributes + ' crossorigin="anonymous" />';
    //case 'image':
    //return <ImageElement {...props} />;
  }
};

const leafHtmlString = ({ attributes, children, leaf }) => {
  if (children === '\n') {
    return '<br />';
  }

  if (leaf.bold) {
    children = '<strong>' + children + '</strong>';
  }

  if (leaf.code) {
    children = '<code>' + children + '</code>';
  }

  if (leaf.italic) {
    children = '<em>' + children + '</em>';
  }

  if (leaf.underline) {
    children = '<u>' + children + '</u>';
  }

  if (leaf.strikethrough) {
    children = '<del>' + children + '</del>';
  }
  if (leaf.plain) {
    children = '<data>' + children + '</data>';
  }
  if (attributes !== '') {
    return '<span ' + attributes + '>' + children + '</span>';
  } else {
    if (children) return children;
    else return '';
  }
};

function serializeItemHtmlString(item) {
  let children = [];
  if (item.children !== undefined) {
    children = item.children.map(item => {
      if (item.type) {
        return serializeItemHtmlString(item);
      } else {
        return leafHtmlString({
          attributes: '',
          children: item.text,
          leaf: item,
        });
      }
    });
  }
  return elementHtmlString({
    attributes: '',
    children: children.join(''),
    element: item,
  });
}

/**
 * This one serves as an empty placeholder since Slate does not accept undefined. When an empty array is passed it throws one of the following errors:
 * * Cannot get the start point in the node at path [] because it has no start text node.
 * * Cannot resolve a Slate point from DOM point: [object HTMLDivElement],0
 */
const EMPTY_SLATE_NODE: ParagraphElement[] = [
  {
    type: 'paragraph',
    children: [{ text: '', type: 'text' }],
  },
];

export function serializeHtmlString(
  data: Descendant[] | undefined,
): string | undefined {
  const emptyValue = undefined;
  // data won't be undefined since Slate does not work with it, but nevertheless
  if (data === undefined || data === null) {
    return emptyValue;
  }
  // empty array -> empty value
  if (data.length === 0) {
    return emptyValue;
  }
  // default empty slate value -> empty value
  if (isEqual(data, EMPTY_SLATE_NODE)) {
    return emptyValue;
  }
  const elements = data.map(item => {
    return serializeItemHtmlString(item);
  });
  const result = elements.join('');
  // double check that serialized html does have something typed in and is not just a bunch of html entities
  if (isEmptyOrWhitespace(removeHtml(result))) {
    return emptyValue;
  } else {
    return result;
  }
}
