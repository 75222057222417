import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLeading, takeLatest } from 'redux-saga/effects';
import { labelPrintActions as actions } from '.';
import { appSettingsActions } from 'app/slice';

import { selectLabelPrint } from './selectors';
import {
  IPrintAssetLabelArgs,
  LabelPrinter,
  LabelPrintState,
  OpenPrintDialogPayload,
} from './types';
import { httpClient } from 'api/HttpClient';
import { Dymo, getPrinterInterface, TPrint, Zebra } from '../Printers';
import { selectAppSettings, selectglobalSettings } from 'app/slice/selectors';
import { getQrUrl } from '../Printers/getQrUrl';
import fileDownload from 'js-file-download';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { ILabelTypeDto } from 'api/odata/generated/entities/ILabelTypeDto';
import { AppSettings } from 'types/AppSettings';
import { AllowedSettings } from 'utils/globalSettings';
import i18next from 'i18next';
import { translations } from 'locales/translations';

function* doGetPrinters(action: PayloadAction) {
  let printers: Array<LabelPrinter> = [];
  const systemSettings = yield select(selectglobalSettings);
  if (systemSettings.GetBooleanByKey(AllowedSettings.DymoPrinterSupported)) {
    try {
      const dymo_printers = yield call(Dymo.getPrinters);
      printers = [...printers, ...dymo_printers];
    } catch (error: unknown) {
      yield put(
        appSettingsActions.addNotification({
          message: String(error),
          variant: 'error',
        }),
      );
      yield put(actions.getPrinters_Error(Error));
    }
  }
  if (systemSettings.GetBooleanByKey(AllowedSettings.ZebraPrinterSupported)) {
    try {
      const zebra_printers = yield call(Zebra.getPrinters);
      printers = [...printers, ...zebra_printers];
    } catch (error: unknown) {
      yield put(
        appSettingsActions.addNotification({
          message: String(error),
          variant: 'error',
        }),
      );
      yield put(actions.getPrinters_Error(Error));
    }
  }

  const serializablePayload = JSON.parse(JSON.stringify(printers));
  yield put(actions.getPrinters_Success(serializablePayload));
}
function* doGetLabelTypes(action: PayloadAction<OpenPrintDialogPayload>) {
  try {
    var filter = new Condition<ILabelTypeDto>(
      'Type',
      ODataOperators.Equals,
      action.payload.type,
    );

    const labelTypes = yield call(httpClient.get, '/api/odata/v4/labeltypes', {
      $filter: filter.toString(),
    });
    yield put(actions.getLabelTypes_Success(labelTypes.value));
  } catch (error) {
    yield put(actions.getLabelTypes_Error(error));
  }
}
async function loadObjects(action: PayloadAction<OpenPrintDialogPayload>) {
  switch (action.payload.type) {
    case 'Asset':
      const assetResponse = await httpClient.get(`/api/odata/v4/AssetDetails`, {
        $filter: `Id in (${action.payload.ids.join(',')})`,
      });
      return assetResponse.value;
    /* const assets = await Promise.allSettled(
          action.payload.ids.map(assetId =>
            httpClient.get(
              `/api/odata/v4/AssetDetails(${assetId})?serviceId=0`,
            ),
          ),
        );
        return assets; */
    case 'Room':
      const roomResponse = await httpClient.get(
        `/api/odata/v4/LocationsBuilding`,
        {
          $filter: `Id in (${action.payload.ids.join(',')})`,
        },
      );
      return roomResponse.value;
    case 'LocationList':
      const locationlistsResponse = await httpClient.get(
        `/api/odata/v4/LocationList`,
        {
          $filter: `Id in (${action.payload.ids.join(',')})`,
        },
      );
      return locationlistsResponse.value;
    case 'SubLocationList':
      const sublocationlistsResponse = await httpClient.get(
        `/api/odata/v4/SubLocationList`,
        {
          $filter: `Id in (${action.payload.ids.join(',')})`,
        },
      );
      return sublocationlistsResponse.value;
    case 'SamplePlate':
      const Ids = action.payload.ids.map(id => "'" + id + "'");
      const samplePlatesResponse = await httpClient.get(
        `/api/odata/v4/SamplePlates`,
        {
          $filter: `Id in (${Ids.join(',')})`,
        },
      );
      return samplePlatesResponse.value;
    case 'Consumable':
      const consumableTypesResponse = await httpClient.get(
        `/api/odata/v4/AssetDetails`,
        {
          $filter: `Id in (${action.payload.ids.join(',')})`,
        },
      );
      return consumableTypesResponse.value;
    default:
      throw new Error('loadObjects NotImplemented');
  }
}
function* doGetObjects(action: PayloadAction<OpenPrintDialogPayload>) {
  try {
    const responses = yield call(loadObjects, action);
    const assetDetails = responses.map(response => response);
    yield put(actions.getAssetDetails_Success(assetDetails));
  } catch (error) {
    yield put(actions.getAssetDetails_Failure(error));
  }
}
function* doOpen(action: PayloadAction<OpenPrintDialogPayload>) {
  const state: LabelPrintState = yield select(selectLabelPrint);
  if (state.printers.value === undefined) {
    yield put(actions.getPrinters());
  }
  if (state.data.value === undefined) {
    yield put(actions.getAssetDetails(action.payload));
  }
  if (state.labelTypes.value === undefined) {
    yield put(actions.getLabelTypes(action.payload));
  }
}

function* doPrint(action: PayloadAction<IPrintAssetLabelArgs>) {
  if (!action.payload.printer) {
    yield put(actions.getAssetDetails_Failure(`Printer not selected.`));
    return;
  }

  const systemSettings = yield select(selectglobalSettings);

  switch (action.payload.printer.Type) {
    case 'Dymo':
      if (
        !systemSettings.GetBooleanByKey(AllowedSettings.DymoPrinterSupported)
      ) {
        const msg = i18next.t(translations.DymoPrinterIsNotSupported);
        yield put(
          appSettingsActions.addNotification({
            variant: 'error',
            message: msg,
          }),
        );
        yield put(actions.print_Error(msg));
        return;
      }
      break;
    case 'Zebra':
      if (
        !systemSettings.GetBooleanByKey(AllowedSettings.ZebraPrinterSupported)
      ) {
        const msg = i18next.t(translations.ZebraPrinterIsNotSupported);
        yield put(
          appSettingsActions.addNotification({
            variant: 'error',
            message: msg,
          }),
        );
        yield put(actions.print_Error(msg));
        return;
      }
      break;
  }

  const printerInterface = getPrinterInterface(action.payload.printer);
  if (printerInterface === undefined) {
    yield put(
      actions.getAssetDetails_Failure(
        `Printer ${action.payload.printer.Type} not supported.`,
      ),
    );
    return;
  }
  const settings: AppSettings = yield select(selectAppSettings);
  const state: LabelPrintState = yield select(selectLabelPrint);
  const type = state.type;
  const AssetBarcodeUrlTemplate =
    type === 'Asset'
      ? settings.AssetBarcodeUrlTemplate
      : type === 'Room'
      ? settings.RoomBarcodeUrlTemplate
      : type === 'LocationList'
      ? settings.LocationBarcodeUrlTemplate
      : type === 'SubLocationList'
      ? settings.SubLocationBarcodeUrlTemplate
      : type === 'SamplePlate'
      ? settings.SamplePlateBarcodeUrlTemplate
      : type === 'Consumable'
      ? settings.ConsumableTypeBarcodeUrlTemplate
      : null;
  try {
    if (type === undefined) {
      throw new Error('Label type is undefined');
    }
    if (AssetBarcodeUrlTemplate === null) {
      throw new Error(`Unsupported label type "${type}"`);
    }
    const data = action.payload.assetDetails.map(assetDetails => ({
      ...assetDetails,
      ...{ QR: getQrUrl(assetDetails, type, AssetBarcodeUrlTemplate) },
    }));
    const printAction: TPrint = printerInterface[action.payload.action];
    const result = yield call(
      printAction,
      action.payload.printer,
      action.payload.template,
      data,
      {
        AssetBarcodeUrlTemplate: settings.AssetBarcodeUrlTemplate,
        RoomBarcodeUrlTemplate: settings.RoomBarcodeUrlTemplate,
        LocationBarcodeUrlTemplate: settings.LocationBarcodeUrlTemplate,
        SubLocationBarcodeUrlTemplate: settings.SubLocationBarcodeUrlTemplate,
        SamplePlateBarcodeUrlTemplate: settings.SamplePlateBarcodeUrlTemplate,
        ConsumableTypeBarcodeUrlTemplate:
          settings.ConsumableTypeBarcodeUrlTemplate,
      },
    );
    switch (action.payload.action) {
      case 'print':
        break;
      case 'download':
      case 'preview':
        for (const item of result) {
          yield call(fileDownload, item, 'import-result.xlsx');
        }
    }
    yield put(actions.print_Success(null));
  } catch (error) {
    yield put(actions.print_Error(error));
  }
}

export function* labelPrintSaga() {
  yield takeLeading(actions.getPrinters.type, doGetPrinters);
  yield takeLeading(actions.getLabelTypes.type, doGetLabelTypes);
  yield takeLatest(actions.getAssetDetails.type, doGetObjects);
  yield takeLeading(actions.open.type, doOpen);
  yield takeLeading(actions.print.type, doPrint);
  //yield takeLeading(actions.preview.type, doPrint);
  //yield takeLeading(actions.download.type, doPrint);
}
