import { httpClient } from 'api/HttpClient';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { ITrainingLevelFilterDto } from 'api/odata/generated/entities/ITrainingLevelFilterDto';
import {
  Condition,
  ODataOperators,
  quoteODataValue,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity } from 'types/common';

export interface TrainingLevelPickerProps
  extends Omit<AutocompletePickerProps<ITrainingLevelFilterDto>, 'loadData'> {}

const url = '/api/odata/v4/TrainingLevelFilter';
export const initTrainingLevelData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (
  predicates: (string | Condition<ITrainingLevelFilterDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
) =>
  getAutoCompleteLoadDataFn<ITrainingLevelFilterDto>({
    url: url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'ServiceGroupId',
      'ExpiredDays',
      'MustOrderEveryDays',
      'MustUseEveryDays',
      'LevelEquipments',
      'UPGroups',
      'UPEquipments',
    ],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<ITrainingLevelFilterDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
    isOptionalServiceGroup: true,
  });
export const loadTrainingLevels = loadData;
export function TrainingLevelPicker(props: TrainingLevelPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(
    props.predicates,
    globalServiceGroupFilter || [],
  );
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'TrainingLevelPicker'}
      loadData={innerLoadData}
      {...props}
    />
  );
}
export function TrainingLevelFilter(
  equipments: IServiceFilterDto[],
  trainingLevelPermissionsEnabled,
  userPermissionsEnabled,
): (string | Condition<ITrainingLevelFilterDto>)[] {
  const predicates: (string | Condition<ITrainingLevelFilterDto>)[] = [];
  if (!!equipments && equipments.length > 0) {
    if (trainingLevelPermissionsEnabled) {
      predicates.push(
        equipments
          .map(eq => {
            if (eq.UseServiceGroupTrainingLevels) {
              return `(ServiceGroupId eq null or ServiceGroupId eq ${quoteODataValue(
                eq.ServiceGroupId,
              )})`;
            } else {
              return `LevelEquipments/any(g: g eq ${quoteODataValue(eq.Id)})`;
            }
          })
          .join(' or '),
      );
    } else if (userPermissionsEnabled) {
      // noop - clients could not select training level that was not part of user or group permissions on selected equipment they needed on uchicago
      // should be properly resolved in https://prog4biz.atlassian.net/browse/BKT-8190
    }
    if (predicates.length > 0) {
      return predicates;
    }
    return [];
  } else {
    return [];
  }
}
