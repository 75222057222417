/**
 *
 * GenerateInvoicesAndSendToSap
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import BasicTypography from 'app/components/Typography/BasicTypography';

import { IInvoiceBudgetBalanceDto } from 'api/odata/generated/entities/IInvoiceBudgetBalanceDto';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { Button } from 'app/components/BasicButtons/Button';
import { ButtonConfirm } from 'app/components/ButtonConfirm';
import { useOnlineBudgetsStatusSlice } from '../slice';
import { selectIGenerateInvoicesAndSendToSapState } from '../slice/selectors';

export interface GenerateInvoicesAndSendToSapProps {
  selected: IInvoiceBudgetBalanceDto[];
  endDate: Date;
  disabled?: boolean;
  disabledTitle?: string;
}

export function GenerateInvoicesAndSendToSap({
  disabled,
  disabledTitle,
  ...props
}: GenerateInvoicesAndSendToSapProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const state = useSelector(selectIGenerateInvoicesAndSendToSapState);
  const { actions } = useOnlineBudgetsStatusSlice();
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    dispatch(
      actions.GenerateInvoicesAndChargeOnlineBudgets({
        endDate: props.endDate,
        selected: props.selected,
        chargeOnlineBudgets: true,
      }),
    );
  };
  const handleDialogClose = () => {
    //todo
  };
  const title = disabled ? disabledTitle : t(translations.PayDescription);

  return (
    <>
      <Tooltip title={title}>
        <span>
          <ButtonConfirm
            disabled={disabled === true || props.selected?.length === 0}
            onClick={handleButtonClick}
            processing={state.processing === true}
          >
            {t(translations.GenerateInvoicesAndSendToSap)}
          </ButtonConfirm>
        </span>
      </Tooltip>
      <Dialog open={state.dialogOpen ?? false} onClose={handleDialogClose}>
        {state.progress !== undefined && (
          <LinearProgress variant="determinate" value={state.progress ?? 0} />
        )}
        <DialogTitle>{t(translations.GenerateInvoices)}</DialogTitle>
        <DialogContent>
          {state.currentRecord !== undefined && (
            <BasicTypography>{`Processing ${state.currentRecord?.ServiceGroup} ${state.currentRecord?.Budget}`}</BasicTypography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={state.processing === true}
            onClick={() =>
              dispatch(actions.GenerateSelectedInvoices_SetDialogOpen(false))
            }
          >
            {t(translations.Close)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
