/**
 *
 * DaysByMonthPicker
 *
 */
import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import React from 'react';

import { Entity } from 'types/common';
import { dateUtils } from 'utils/date-utils';
import { GetOptionsFilterFunction } from 'utils/enumKeys';

export interface DaysByMonthPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {
  month?: number | null;
  date?: Date | string | null;
}
export const daysEntityArray = Array.from({ length: 31 }, (_, i) => {
  const x = i + 1;
  return {
    Id: x,
    Name: x.toString(),
  } as Entity<number>;
});

export const defaultDayValue =
  daysEntityArray.find(f => f.Id === dateUtils.getDate(new Date())) ??
  ({
    Id: dateUtils.getDate(new Date()),
    Name: dateUtils.getDate(new Date()).toString(),
  } as Entity<number>);
export const getDayEntityFromDate = (date?: Date | string | null) => {
  if (date === null || date === undefined) {
    return daysEntityArray[0];
  } else {
    let toDate = dateUtils.dateOrStringToDate(date);
    return (
      daysEntityArray.find(f => f.Id === toDate.getDate()) ?? daysEntityArray[0]
    );
  }
};

export const initDaysData = (
  initval: number | string | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve(
        daysEntityArray.filter(item => {
          return item.Id.toString() === initval.toString();
        }),
      );
    });
  }
};

export function DaysByMonthPicker(props: DaysByMonthPickerProps) {
  const getOptions = React.useMemo(() => {
    let maxDay = 31;
    if (props.month !== null && props.month !== undefined) {
      let monthDate = dateUtils.setMonth(new Date(), props.month);
      maxDay = dateUtils.lastDayOfMonth(monthDate).getDate();
    } else if (props.date !== null && props.date !== undefined) {
      let monthDate = dateUtils.dateOrStringToDate(props.date);
      maxDay = dateUtils.lastDayOfMonth(monthDate).getDate();
    }
    let items = daysEntityArray.filter(f => f.Id <= maxDay);
    return GetOptionsFilterFunction(items);
  }, [props.date, props.month]);
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      id={props.id || 'daysByMonthId'}
      value={props.value}
      autoHighlight={true}
      autoSelect={true}
      selectOnFocus={true}
      clearOnEscape
      {...props}
    />
  );
}
