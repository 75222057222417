import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { DropDown } from 'app/components/DropDown';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { TFunctionKeys } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import {
  getViewLength,
  getViewType,
  viewLengthOptions,
  ViewLengths,
  viewTypeOptions,
  ViewTypes,
} from '../../slice/types';

export interface ViewTypeLengthDropDownProps {
  viewType: ViewTypes;
  viewLength: ViewLengths;
  onTypeLengthChange: (
    type: ViewTypes,
    length: ViewLengths,
    value?: Date,
  ) => void;
  size?: 'small' | 'large' | 'medium';
  /**
   * Limit display to a subset of possible options. E.g. options={['day', 'week']} to show only day/week options without the month
   */
  lengthOptions?: Array<ViewLengths>;
  /**
   * Optional show only icons on mobile
   */
  compact?: boolean;
  disabled?: boolean;
  haideCalendarViewType?: boolean;
  twoDaysEnabled?: boolean;
  customDaysEnabled?: boolean;
  customDays?: number;
  customTimelineDays?: number;
  customCalendarDays?: number;
}

export function ViewTypeLengthDropDown(props: ViewTypeLengthDropDownProps) {
  const {
    viewType,
    viewLength,
    onTypeLengthChange,
    lengthOptions = ['day', 'week', 'month'],
    compact,
    size = 'medium',
    disabled,
    haideCalendarViewType,
    customDays,
    customTimelineDays,
    customCalendarDays,
  } = props;
  const { t } = useTranslation();
  const { newDate } = useSystemDate();
  const titletext = React.useMemo(() => {
    if (compact) {
      return t(getViewLength(viewLength)?.shortName as TFunctionKeys).replace(
        '{0}',
        !!customDays ? customDays.toString() : '',
      );
    } else {
      return `${t(getViewType(viewType)?.Name as TFunctionKeys)} by ${t(
        getViewLength(viewLength)?.Name as TFunctionKeys,
      ).replace('{0}', !!customDays ? customDays.toString() : '')}`;
    }
  }, [compact, customDays, t, viewLength, viewType]);
  const itemText = React.useCallback(
    (type: Entity<ViewTypes>, length: Entity<ViewLengths>) => {
      if (type.Id === 'calendar') {
        if (length.Id === 'customPeriod') {
          if (!!customCalendarDays && customCalendarDays > 0) {
            return t(length.Name as TFunctionKeys).replace(
              '{0}',
              customCalendarDays.toString(),
            );
          } else {
            return 'Custom';
          }
        } else {
          return t(length.Name as TFunctionKeys);
        }
      } else {
        if (length.Id === 'customdays') {
          return t(length.Name as TFunctionKeys).replace(
            '{0}',
            !!customDays ? customDays.toString() : '',
          );
        } else if (length.Id === 'customPeriod') {
          if (!!customTimelineDays && customTimelineDays > 0) {
            return t(length.Name as TFunctionKeys).replace(
              '{0}',
              customTimelineDays.toString(),
            );
          } else {
            return 'Custom';
          }
        } else {
          return t(length.Name as TFunctionKeys);
        }
      }
    },
    [customCalendarDays, customDays, customTimelineDays, t],
  );
  const handleChange = (
    type: Entity<ViewTypes> | null,
    length: Entity<ViewLengths> | null,
  ) => {
    if (type === null || length === null) {
      return;
    }
    onTypeLengthChange(
      type.Id,
      length.Id,
      length.Id === 'customdays' ? newDate() : undefined,
    );
  };

  return (
    <DropDown
      variant="gray"
      size={size}
      disabled={disabled}
      menuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      startIcon={
        <Icon icon={viewType === 'calendar' ? 'calendar' : 'list-timeline'} />
      }
      addChevron
      menuChildren={onClose => [
        ...viewTypeOptions
          .filter(
            f =>
              !haideCalendarViewType ||
              (haideCalendarViewType === true && f.Id === 'timeline'),
          )
          .map((vt, index) => {
            return (
              <List
                style={{ minWidth: 240 }}
                disablePadding
                key={vt.Id}
                subheader={
                  <ListSubheader>
                    <Body
                      style={{ textTransform: 'uppercase' }}
                      color="secondary"
                      size="small"
                    >
                      {t(vt.Name as TFunctionKeys)}
                    </Body>
                  </ListSubheader>
                }
              >
                {viewLengthOptions
                  .filter(f => {
                    if (compact) {
                      return vt.Id === 'timeline'
                        ? f.Id !== 'month' &&
                            f.Id !== 'customdays' &&
                            lengthOptions.includes(f.Id)
                        : f.Id !== 'twodays' &&
                            f.Id !== 'customdays' &&
                            f.Id !== 'quarter' &&
                            f.Id !== 'year' &&
                            lengthOptions.includes(f.Id);
                    } else {
                      return vt.Id === 'timeline'
                        ? lengthOptions.includes(f.Id)
                        : f.Id !== 'customdays' &&
                            f.Id !== 'twodays' &&
                            f.Id !== 'quarter' &&
                            f.Id !== 'year' &&
                            lengthOptions.includes(f.Id);
                    }
                  })
                  .map(vl => {
                    return (
                      <ListItem
                        key={vl.Id}
                        button
                        selected={vt.Id === viewType && vl.Id === viewLength}
                        onClick={() => {
                          handleChange(vt, vl);
                          onClose();
                        }}
                      >
                        <ListItemIcon>
                          <Icon
                            color="primary"
                            colorExtend="main"
                            icon={
                              vl.Icon ??
                              vt.Icon ??
                              (vt.Id === 'calendar'
                                ? 'calendar'
                                : 'list-timeline')
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={itemText(vt, vl)} />
                      </ListItem>
                    );
                  })}
              </List>
            );
          }),
      ]}
    >
      {titletext}
    </DropDown>
  );
}
