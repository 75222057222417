import { IBudgetExperimentsFilterDto } from 'api/odata/generated/entities/IBudgetExperimentsFilterDto';
import { FieldHookConfig, useField } from 'formik';
import { BudgetExperimentsPicker } from '../pickers/AutocompletePickers/BudgetExperimentsPicker';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export const FormBudgetExperimentsPicker = ({
  label,
  ...props
}: FieldHookConfig<IBudgetExperimentsFilterDto | null> &
  IFieldProps & {
    username: string | undefined;
  }) => {
  const [field, meta, helpers] = useField<IBudgetExperimentsFilterDto | null>(
    props,
  );
  return (
    <BudgetExperimentsPicker
      predicates={props.predicates}
      name={props.name}
      placeholder={props.placeholder}
      label={label}
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      onBlur={field?.onBlur}
      value={field.value ?? undefined}
      error={meta?.error !== undefined}
      variant="filled"
      disabled={props.disabled}
      helperText={meta.error}
      fullWidth={props.fullWidth}
      info={props.info}
      username={props.username}
    />
  );
};
