import { ReservationStatusMultiPicker } from 'app/components/pickers/AutocompletePickers/ResrevationStatusMultiPicker';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const ResrvationStatusMultiFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number>[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <ReservationStatusMultiPicker
      onPickerOpen={props.onOpen}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      onChange={handleOnChange}
      value={props.value as Entity<number>[]}
      ariaLabel={props.label}
      size="small"
      id={props.id}
    />
  );
};
export default ResrvationStatusMultiFilter;
