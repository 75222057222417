/**
 *
 * AssetQuickSearch
 *
 */
import * as React from 'react';
import {
  ClickAwayListener,
  createStyles,
  Fade,
  List,
  makeStyles,
  Paper,
  Popper,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssetQuickSearchSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAssetQuickSearch,
  selectOpen,
  selectSearchTerm,
} from './slice/selectors';
import { RecentAssetsList } from './RecentAssetsList';
import { RecentSearchTermsList } from './RecentSearchTermsList';
import { toRootedURL } from 'utils/url-utils';
import { TextControlField } from '../BasicInputs/TextControlField';
import { useRef } from 'react';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useEffect } from 'react';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import { Body } from '../Typography';
import useGlobalDOMEvents from 'app/hooks/useGlobalDomEvents';
import { bookitColors } from 'styles/colors/bookitColors';
import { box_shadow_s } from 'styles/shadows/bookitShadow';
import { BasicToggle } from '../BasicToggle';
import { AssetSearchResultsByType } from './AssetSearchResultsByType';
import {
  QuickSearchAllInstrumentsSection,
  QuickSearchMainTitleSection,
} from './RecentWrappers/wrappers';
import { Button } from '../BasicButtons/Button';
import { AssetsSelectionProps } from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import {
  selectGlobalServiceGroupFilter,
  selectShowAllCores,
} from 'app/slice/selectors';
import { useAppSettingsSlice } from 'app/slice';
import clsx from 'clsx';

export interface AssetQuickSearchProps {}

export const AssetQuickSearch = React.memo(function AssetQuickSearch(
  props: AssetQuickSearchProps,
) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { actions } = useAssetQuickSearchSlice();
  const { actions: appSettingsAction } = useAppSettingsSlice();
  const open = useSelector(selectOpen);
  const isMobile = DetectIsMobile();
  const searchAllCores = useSelector(selectShowAllCores);
  const searchTerm = useSelector(selectSearchTerm);
  const selectedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const handleInputChange = e => {
    dispatch(actions.searchAllAssets(e.target.value));
  };
  const state = useSelector(selectAssetQuickSearch);
  const elementRef = useRef<HTMLDivElement>(null);

  const handleClick = event => {
    dispatch(actions.setOpen(true));
  };

  const handleSearchAllCores = (value: boolean) => {
    dispatch(appSettingsAction.setShowAllCores(value));
    if (!!searchTerm && searchTerm !== '') {
      dispatch(actions.searchAllAssets(searchTerm));
    } else {
      dispatch(actions.getRecentSearchTerms());
      dispatch(actions.getRecentAssets());
    }
  };

  const handleClose = () => {
    if (open) {
      dispatch(actions.setOpen(false));
    }
  };
  const handleHideAssetQuickSearchClick = () => {
    dispatch(actions.showSearchInput(false));
  };
  const handleOpenAssets = (serviceTypeId?: number, searchTerm?: string) => {
    let selParams = {
      useSidePanel: true,
      actionType: 'AssetSearch',
      serviceTypeId: serviceTypeId,
      searchTerm: searchTerm,
      //offTypeIds: services,
    } as AssetsSelectionProps;
    dispatch(
      layoutActions.openSidePanel({
        type: RenderPageType.AssetsSelection,
        props: selParams,
        expanded: true,
      }),
    );
  };
  // manually trigger rerender using a useless usState hook
  // this ensures that the popper is rendered only after the div is rendered and have provided the elementRef value to ancor the popper
  const countState = React.useState(0);
  useEffect(() => {
    countState[1](c => c + 1);
    // setCount (rerender) needs to occur after element elementRef.current value is bound
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);
  useGlobalDOMEvents({
    keyup(ev) {
      if ((ev as KeyboardEvent).key === 'Escape') {
        dispatch(actions.setOpen(false));
      }
    },
  });

  return (
    <ClickAwayListener onClickAway={() => handleClose()}>
      <form action={toRootedURL('/assets/')} method="get">
        <div className={classes.search} ref={elementRef}>
          {isMobile && (
            <IconButton
              onClick={() => handleHideAssetQuickSearchClick()}
              title={t(translations.Close)}
              variant="ghost"
              shape="square"
              size="small"
            >
              <Icon icon="times" />
            </IconButton>
          )}
          <TextControlField
            name="q"
            placeholder={t(translations.SearchAnAssets)}
            onChange={e => handleInputChange(e)}
            onFocus={e => handleClick(e)}
            autoFocus={false}
            size="small"
            value={state.searchTerm || ''}
            classes={{
              root: classes.inputRoot,
            }}
            autoComplete="off"
            aria-label="search"
            inputProps={{ 'aria-label': 'search' }}
            endIcon={<FontAwesomeIcon icon="search" />}
            fullWidth
          />
          {elementRef.current !== null && (
            <Popper
              open={open}
              anchorEl={elementRef.current}
              className={clsx(classes.popper, { isMobile: isMobile })}
              placement={'bottom-start'}
              transition
              disablePortal={!isMobile}
              modifiers={{
                keepTogether: { enabled: true },
                flip: { enabled: false },
                preventOverflow: {
                  enabled: false,
                  boundariesElement: 'viewport',
                },
                offset: {
                  offset: '-15,0',
                },
              }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={333}>
                  <Paper
                    className={clsx(classes.paper, { isMobile: isMobile })}
                  >
                    <QuickSearchMainTitleSection>
                      <Body size="large" bold style={{ flex: '1 0 0' }}>
                        {isMobile
                          ? t(translations.AssetsSearch)
                          : t(translations.InstrumentAssetSearch)}
                      </Body>
                      <BasicToggle
                        label={t(translations.SearchAllCores)}
                        checked={searchAllCores}
                        onChange={(value, event) => handleSearchAllCores(value)}
                      />
                    </QuickSearchMainTitleSection>
                    {state.searchTerm !== undefined &&
                    state.searchTerm?.length > 0 ? (
                      <List
                        component="div"
                        disablePadding
                        style={{ width: '100%' }}
                        dense={true}
                      >
                        <AssetSearchResultsByType
                          onAllAssetsClick={(serviceType, searchTerm) =>
                            handleOpenAssets(serviceType, searchTerm)
                          }
                          selectedServiceGroups={selectedServiceGroups}
                          isMobile={isMobile}
                        />
                      </List>
                    ) : (
                      <List
                        dense={true}
                        disablePadding
                        style={{ overflowY: 'auto', width: '100%' }}
                      >
                        <RecentSearchTermsList
                          onClick={searchTerm =>
                            handleOpenAssets(undefined, searchTerm)
                          }
                        />
                        {/* <ListItemLink href={toRootedURL('/assets/')}>
                            <ListItemIcon>
                              <FontAwesomeIcon icon="search" />
                            </ListItemIcon>
                            <ListItemText>
                              <Body bold={true}>
                                {t(translations.AdvancedAssetSearch)}
                              </Body>
                            </ListItemText>
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() => handleClose()}
                                title={t(translations.Close)}
                                variant="ghost"
                                shape="square"
                                aria-label="close"
                                size="small"
                              >
                                <Icon icon="times" />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItemLink> */}
                        {/* <Divider component="li" /> */}
                        <RecentAssetsList
                          isMobile={isMobile}
                          selectedServiceGroups={selectedServiceGroups}
                        />
                        <QuickSearchAllInstrumentsSection>
                          <Button
                            variant="text"
                            onClick={() => handleOpenAssets()}
                          >
                            {t(translations.SeeAllInstruments)}
                          </Button>
                        </QuickSearchAllInstrumentsSection>
                      </List>
                    )}
                  </Paper>
                </Fade>
              )}
            </Popper>
          )}
        </div>
      </form>
    </ClickAwayListener>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // root css
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      //  backgroundColor: alpha(theme.palette.common.white, 0.15),
      // '&:hover': {
      //   backgroundColor: alpha(theme.palette.common.white, 0.25),
      // },
      marginLeft: 0,
      [theme.breakpoints.up('sm')]: {
        width: 355,
      },
      [theme.breakpoints.up('lg')]: {
        width: 463,
      },
      paddingTop: 6,
      paddingBottom: 6,
      gap: 8,
      // [theme.breakpoints.up('sm')]: {
      //   marginLeft: theme.spacing(1),
      //   width: 'auto',
      // },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      flexGrow: 1,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '30ch',
        '&:focus': {
          width: '50ch',
        },
      },
    },
    popper: {
      // +1 from the appBar (zIndex: theme.zIndex.drawer + 1)
      zIndex: theme.zIndex.drawer + 2,
      '&.isMobile': {
        zIndex: theme.zIndex.modal + 2,
        height: 'calc(100% - 92px)',
      },

      [theme.breakpoints.up('lg')]: {
        width: 700,
      },
      [theme.breakpoints.down('lg')]: {
        width: 600,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    paper: {
      display: 'flex',
      width: '672px',
      padding: '16px 12px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '24px',

      borderRadius: '12px',
      border: `1px solid ${bookitColors.grayscale.graySelection}`,
      background: bookitColors.base.white,
      /* Shadow/box-shadow-s */
      boxShadow: box_shadow_s,
      '&.isMobile': {
        width: '100%',
        height: '100%',
      },
    },
    paperTitleSection: {},
  }),
);
