import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    item: {
      border: 'solid 1px ' + theme.palette.divider,
      borderRadius: '5px',
      gap: '10%',
      padding: '10px 0',
      display: 'flex',
      justifyContent: 'space-around',
    },
    container: {
      gap: theme.spacing(2),
    },
  }),
);
