/**
 *
 * FormRestrictedUserGroupPicker
 *
 */
import { IUserGroupDto } from 'api/odata/generated/entities/IUserGroupDto';
import { FieldHookConfig, useField } from 'formik';
import { RestrictedUserGroupPicker } from '../pickers/AutocompletePickers/UserGroupPicker';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export const FormRestrictedUserGroupPicker = ({
  label,
  ...props
}: FieldHookConfig<IUserGroupDto | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<IUserGroupDto | null>(props);
  return (
    <RestrictedUserGroupPicker
      name={props.name}
      placeholder={props.placeholder}
      label={label}
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      onBlur={field?.onBlur}
      value={field.value}
      error={meta?.error !== undefined}
      variant="filled"
      helperText={meta.error}
      fullWidth={props.fullWidth}
      info={props.info}
      predicates={props.predicates}
      disabled={props.disabled}
    />
  );
};
