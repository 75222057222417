/**
 *
 * auto generated interface for Bookit.Data.ServiceRequestMilestoneStatuses
 *
 */

export enum ServiceRequestMilestoneStatuses {
  NotStarted = 0,
  Started = 1,
  Completed = 2,
}
export type ServiceRequestMilestoneStatusesUnion = keyof typeof ServiceRequestMilestoneStatuses;
export const ServiceRequestMilestoneStatusesUnionArray: Array<ServiceRequestMilestoneStatusesUnion> = [
  'NotStarted',
  'Started',
  'Completed',
];
