import { Timestamp } from '../../../../../types/Timestamp';
import { dateUtils } from 'utils/date-utils';

export const serializeSchedulerDateOrUndefined = (
  d: Date | null | undefined,
): Timestamp | undefined => {
  if (d === null || d === undefined) {
    return undefined;
  }
  return serializeSchedulerDate(d);
};
export const serializeSchedulerDate = (d: Date): Timestamp => {
  try {
    return dateUtils.formatISO(d);
  } catch (error) {
    throw error;
  }
};
