import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
// import { coreSelectionSaga } from './saga';
import { CoreSelectionState } from './types';

export const initialState: CoreSelectionState = {
  filters: undefined,
  loading: undefined,
};

const slice = createSlice({
  name: 'coreSelection',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<Record<string, any> | undefined>) {
      state.filters = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    reset(state, action: PayloadAction) {
      state.filters = undefined;
      state.loading = undefined;
    },
  },
});

export const { actions: coreSelectionActions } = slice;

export const useCoreSelectionSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  // useInjectSaga({ key: slice.name, saga: coreSelectionSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCoreSelectionSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
