/**
 *
 * RequestSamplesPage
 *
 */
import * as React from 'react';
import { Redirect, RouteComponentProps, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestSamplesSlice } from './slice';
import {
  selectIsServiceRequestAdmin,
  selectRequestDetails,
  selectRequestDetailsData,
  selectServiceRequestCustomFormColumns,
  selectServiceRequestCustomFormFields,
  selectServiceRequestFormData,
  selectServiceRequestSidePanel,
  selectSubmitting,
} from './slice/selectors';
import { useTranslation } from 'react-i18next';
import { SamplesTable } from '../components/SamplesTable';
import { translations } from 'locales/translations';
import { ServiceRequestCustomForm } from './components/ServiceRequestCustomForm';
import { PrintToPDF } from 'app/components/PrintToPDF';
import { PrintPDFSection } from 'app/components/PrintToPDF/PrintPDFSection';
import { usePrintPDFSlice } from 'app/components/PrintToPDF/slice';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { GetServiceRequestDetailsPath } from './slice/path';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { push } from 'connected-react-router';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { ServiceRequestSubmissionDetails } from '../components/ServiceRequestSubmissionDetails';
import { SubmitServiceRequestButton } from './components/SubmitServiceRequestButton';
import { CancelServiceRequestButton } from './components/CancelServiceRequestButton';
import { getServiceRequestTitle } from './getServiceRequestTitle';
import { getPrintPDFSections } from 'app/components/PrintToPDF/getPrintPDFSections';
import { ServiceRequestMilestonesSection } from '../components/ServiceRequestMilestonesSection';
import { AddonTypesUnion } from 'api/odata/generated/enums/AddonTypes';
import { assertExhaustive } from 'utils/assertExhaustive';
import { ServiceRequestMilestoneDetailsProps } from '../components/ServiceRequestMilestoneDetails';
import { ServiceRequestMilestoneDetails } from '../components/ServiceRequestMilestoneDetails';
import {
  ServiceRequestMilestoneChargeDetails,
  ServiceRequestMilestoneChargeDetailsProps,
} from '../components/ServiceRequestMilestoneChargeDetails';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { buildURL } from 'utils/url-utils';
import { CloneServiceRequestButton } from './components/CloneServiceRequestButton';
import {
  selectAppSettings,
  selectForbiddenFileExtensions,
  selectKnownModule,
  selectUploadFileSizeLimit,
  selectUserProfileSettings,
} from 'app/slice/selectors';
import { getNotificationsLogPageLocation } from 'app/pages/EmailNotificationsPage/getNotificationsLogPageLocation';
import { Box } from '@material-ui/core';
import { ServiceRequestRowsTable } from '../components/ServiceRequestRowsTable';
import { QuoteSentToUserAlert } from './components/QuoteSentToUserAlert';
import { SendQuoteButtonButton } from './components/SendQuoteButton';
import { bookitColors } from 'styles/colors/bookitColors';
import { StyledFormFieldsContainer } from 'app/components/Forms/FormsLayout';
import { KnownModules } from 'types/KnownModules';
import { ServiceRequestRowComments as ServiceRequestComments } from '../components/ServiceRequestRowsTable/components/ServiceRequestComments';
import { getRequestDetailsSchema } from './utils/validationSchema';
import { Formik } from 'formik';
import { IServiceRequestDetailsFormModel } from './slice/types';
import { InternalServiceRequestStatus } from 'api/odata/generated/enums/InternalServiceRequestStatus';
import { DownloadAllFiles } from './components/DownloadAllFiles';
import { Body, H4 } from 'app/components/Typography';

export interface RequestSamplesPageProps
  extends RouteComponentProps<{ id: string }> {}
export interface ServiceRequestDetailsProps extends SidePanelContentProps {
  id: number;
}

export function RequestSamplesPage(props: RequestSamplesPageProps) {
  const serviceRequestId = parseInt(props.match.params.id);
  if (isNaN(serviceRequestId)) {
    return null;
  }
  return <ServiceRequestDetails id={serviceRequestId} useSidePanel={false} />;
}
export function RequestDetailsPage() {
  const location = useLocation();
  const id = React.useMemo(
    () => new URLSearchParams(location.search).get('id') ?? '',
    [location.search],
  );
  const serviceRequestId = parseInt(id);
  if (isNaN(serviceRequestId)) {
    return <NotFoundPage />;
  }
  return <Redirect to={'/requests/' + serviceRequestId} />;
}
export function ServiceRequestDetails({
  id,
  useSidePanel,
}: ServiceRequestDetailsProps) {
  const requestDetails = useSelector(selectRequestDetails);
  const requestDetailsData = useSelector(selectRequestDetailsData);
  const serviceRequestFormData = useSelector(selectServiceRequestFormData);
  const userProfileSettings = useSelector(selectUserProfileSettings);
  const appSettings = useSelector(selectAppSettings);
  const samplesAutomationEnabled = appSettings?.Modules.includes(
    KnownModules.SamplesAutomation,
  );
  const submittingRequest = useSelector(selectSubmitting);
  /**
   * custom form table section enabled/disabled
   */
  const SampleManagementTablesEnabled = useSelector(state =>
    selectKnownModule(state, KnownModules.SampleManagementTables),
  );
  const { actions } = useRequestSamplesSlice();
  const { actions: printActions } = usePrintPDFSlice();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //todo
  const { isAdmin, isOwner, isEditable } = useSelector(
    selectIsServiceRequestAdmin,
  );
  const sidePanel = useSelector(selectServiceRequestSidePanel);

  React.useEffect(() => {
    dispatch(actions.loadRequestDetails(id));
  }, [actions, dispatch, id]);

  const [print, setPrint] = React.useState(false);
  React.useEffect(() => {
    if (!print) {
      const printSections = getPrintPDFSections(
        t,
        userProfileSettings.GetSettingByKey('/RequestSamples.pdf')?.Value ?? '',
      );
      dispatch(printActions.setUseSections(true));
      dispatch(printActions.updateSections(printSections));
    }
  }, [dispatch, print, printActions, t, userProfileSettings]);

  const customFormFields = useSelector(selectServiceRequestCustomFormFields);
  const customFormColumns = useSelector(selectServiceRequestCustomFormColumns);
  const handlePrintToPDF = React.useCallback(() => {
    setPrint(true);
  }, []);

  const ForbiddenFileExtensions = useSelector(selectForbiddenFileExtensions);
  const UploadFileSizeLimit = useSelector(selectUploadFileSizeLimit);
  const schema = React.useMemo(
    () =>
      getRequestDetailsSchema({
        columns: customFormColumns,
        fields: customFormFields,
        isAdmin,
        SampleManagementTablesEnabled,
        ForbiddenFileExtensions,
        UploadFileSizeLimit,
      }),
    [
      ForbiddenFileExtensions,
      SampleManagementTablesEnabled,
      UploadFileSizeLimit,
      customFormColumns,
      customFormFields,
      isAdmin,
    ],
  );
  const [hasRowsErrors, setHasRowsErrors] = React.useState<boolean | undefined>(
    undefined,
  );

  // const submitAction = React.useMemo(() => {
  //   const x = () => (
  //     <SubmitServiceRequestButton
  //       serviceRequest={requestDetails?.value}
  //       editable={isEditable}
  //       getErrors={(errors: FormikErrors<IRequestDetailsModel>) =>
  //         setErrors(errors)
  //       }
  //     />
  //   );
  //   return x;
  // }, [isEditable, requestDetails?.value]);
  // const cancelAction = React.useMemo(
  //   () => () => (
  //     <CancelServiceRequestButton
  //       serviceRequestId={requestDetailsData?.Id}
  //       isAdmin={isAdmin}
  //     />
  //   ),
  //   [isAdmin, requestDetailsData?.Id],
  // );
  // const sendQuoteAction = React.useMemo(() => {
  //   const serviceRequest = requestDetailsData;
  //   if (serviceRequest === undefined) {
  //     return undefined;
  //   } else if (
  //     !canSendQuote({ isAdmin: isAdmin, serviceRequest: serviceRequest })
  //   ) {
  //     return undefined;
  //   } else {
  //     return () => (
  //       <SendQuoteButtonButton
  //         isAdmin={isAdmin}
  //         serviceRequest={serviceRequest}
  //       />
  //     );
  //   }
  // }, [isAdmin, requestDetailsData]);

  const rightTopActions: ActionRenderer[] = React.useMemo(
    () => [
      () => (
        <TopActionButton
          variant="white"
          size="small"
          shape="square"
          startIcon="print"
          icon="print"
          aria-label="history"
          onClick={handlePrintToPDF}
          noChangeOnMobile
          text={t(translations.PrintPdf)}
          title={
            requestDetailsData?.Status.InternalStatusId === 'Draft'
              ? t(translations.PleaseSubmitRequestFirstInOrderToPrint)
              : t(translations.PrintPdf)
          }
          disabled={requestDetailsData?.Status.InternalStatusId === 'Draft'}
        />
      ),
      () => (
        <TopActionButton
          variant="ghost"
          size="small"
          shape="square"
          startIcon="history"
          icon="history"
          aria-label="history"
          href={buildURL(`/ServiceRequestAuditTrailHistory.aspx`, {
            requestId: requestDetailsData?.Id,
          })}
          target="_blank"
          noChangeOnMobile
          text={t(translations.ModificationsHistory)}
          title={t(translations.ModificationsHistory)}
        />
      ),
      () =>
        requestDetailsData?.Id !== undefined && (
          <TopActionButton
            variant="ghost"
            size="small"
            shape="square"
            startIcon="history"
            icon="history"
            aria-label="history"
            href={getNotificationsLogPageLocation({
              NotificationTypeId: 'Request',
              ReferenceId: requestDetailsData?.Id,
            })}
            noChangeOnMobile
            text={t(translations.menu_EmailNotifications)}
            title={t(translations.menu_EmailNotifications)}
          />
        ),
      () => <CloneServiceRequestButton item={requestDetailsData} />,
    ],
    [handlePrintToPDF, requestDetailsData, t],
  );
  const leftActions = React.useMemo(() => {
    let actions = [] as ActionRenderer[];
    if (!!requestDetailsData) {
      if (isEditable) {
        actions.push(() => (
          <React.Fragment>
            <SubmitServiceRequestButton
              serviceRequest={requestDetailsData}
              getRowsErrors={(hasErrors: boolean) =>
                setHasRowsErrors(hasErrors)
              }
              disabled={
                requestDetails.status === 'pending' || submittingRequest
              }
            />
          </React.Fragment>
        ));
      }
      if (isAdmin) {
        actions.push(() => (
          <React.Fragment>
            <CancelServiceRequestButton
              serviceRequestId={requestDetailsData?.Id}
            />
          </React.Fragment>
        ));
      }
      if (
        requestDetailsData.Service.SendQuoteToUser === true &&
        isAdmin === true &&
        ['InProcess', 'Draft'].includes(
          requestDetailsData.Status.InternalStatusId,
        )
      ) {
        actions.push(() => (
          <React.Fragment>
            <SendQuoteButtonButton serviceRequest={requestDetailsData} />
          </React.Fragment>
        ));
      }
      if (
        isAdmin &&
        requestDetailsData !== undefined &&
        requestDetailsData?.HasFiles === true
      ) {
        actions.push(() => (
          <DownloadAllFiles serviceRequestId={requestDetailsData.Id} />
        ));
      }
    }
    return [...actions, ...rightTopActions];
  }, [
    isAdmin,
    isEditable,
    requestDetails.status,
    requestDetailsData,
    rightTopActions,
    submittingRequest,
  ]);
  const handleClosePrintToPDF = React.useCallback(() => {
    setPrint(false);
  }, []);

  const handleCloseClick = React.useCallback(() => {
    if (useSidePanel) {
      dispatch(layoutActions.resetSidePanel());
      dispatch(layoutActions.setRefreshTable(true));
    } else {
      dispatch(push('/ServiceRequests'));
    }
    //dispatch(actions.resetDetailsState());
  }, [dispatch, useSidePanel]);

  const handleSubmit = React.useCallback(
    async (values: IServiceRequestDetailsFormModel) => {
      if (requestDetailsData === undefined) {
        return;
      }
      dispatch(actions.submitStep({ ...requestDetailsData, ...values }));
    },
    [actions, dispatch, requestDetailsData],
  );

  const closeCover = () => dispatch(actions.setCover());
  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <Button
            variant="ghost"
            size="small"
            startIcon={<Icon icon="times" />}
            onClick={handleCloseClick}
          >
            {t(translations.Close)}
          </Button>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCloseClick, t]);

  if (requestDetails.status !== 'pending') {
    if (requestDetails.error !== undefined) {
      const statusCode = (requestDetails.error as AxiosError)?.response?.status;
      switch (statusCode) {
        case StatusCodes.NOT_FOUND:
          return <NotFoundPage />;
      }
    }
  }
  const getTitle = (addonType?: AddonTypesUnion) => {
    if (addonType === undefined) {
      return undefined;
    }
    switch (addonType) {
      case 'Milestones':
        return t(translations.Milestones);
      case 'Addons':
      case 'UserAddons':
        return t(translations.AddonCharges);
      case 'NA':
        return undefined;

      default:
        assertExhaustive(addonType);
    }
  };
  const sectionStyles = {
    background: bookitColors.base.white,
    border: `1px solid ${bookitColors.grayscale.grayBorders}`,
    borderRadius: 12,
    padding: '20px 24px 24px 24px',
    width: '100%',
  };

  if (requestDetailsData === undefined) {
    // nothing to show if the request details are empty but it's neither processing nor errored but the data for whatever reason is still not there
    return null;
  }
  const title = getServiceRequestTitle(requestDetailsData);
  const loading = requestDetails.status === 'pending';
  const cover =
    useSidePanel === false ? undefined : sidePanel?.type ===
      RenderPageType.ServiceRequestMilestone ? (
      <ServiceRequestMilestoneDetails
        {...(sidePanel.props as ServiceRequestMilestoneDetailsProps)}
      />
    ) : sidePanel?.type === RenderPageType.ServiceRequestMilestoneCharge ? (
      <ServiceRequestMilestoneChargeDetails
        {...(sidePanel.props as ServiceRequestMilestoneChargeDetailsProps)}
      />
    ) : undefined;

  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={schema}
        enableReinitialize
        initialValues={serviceRequestFormData}
        onSubmit={handleSubmit}
      >
        {formik => (
          <PageWrapper
            topProcessing={
              requestDetails.status === 'pending' || submittingRequest
            }
            loading={loading}
            pageName={t(translations.ServiceRequest)}
            titlePage={title}
            pageLink={GetServiceRequestDetailsPath(requestDetailsData?.Id)}
            closable
            disableExpandToggle
            hideTitleBar={false}
            useSidePanel={useSidePanel}
            rightTopActions={rightTopActions}
            leftActions={leftActions}
            leftActionsMaxLength={2}
            rightActions={rightActions}
            cover={cover}
            closeCover={closeCover}
          >
            <PrintToPDF
              title={''}
              print={print}
              printTitle={title}
              useSections={true}
              close={handleClosePrintToPDF}
              saveMarkedSections={true}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '32px 0px',
                  gap: '32px',
                }}
              >
                {print ? (
                  <Box style={{ paddingLeft: '32px' }}>
                    <Body bold={true} color="secondary">
                      {t(translations.ServiceRequest)}
                    </Body>
                    <H4>{title}</H4>
                  </Box>
                ) : (
                  <></>
                )}
                {isEditable && (
                  <QuoteSentToUserAlert
                    ServiceRequestId={requestDetailsData.Id}
                    ServiceRequestInternalStatus={
                      requestDetailsData.Status.InternalStatusId
                    }
                  />
                )}

                {/* <ErrorsSummuryAlert errors={formik.errors} /> */}
                {requestDetailsData !== undefined && (
                  <PrintPDFSection print={print} id="SubmissionsDetails">
                    <ServiceRequestSubmissionDetails
                      requestDetails={requestDetailsData}
                      isOwner={isOwner}
                      isAdmin={isAdmin}
                      sectionStyles={sectionStyles}
                    />
                  </PrintPDFSection>
                )}

                {customFormFields.length > 0 && (
                  <PrintPDFSection print={print} id="RequestForm_Table">
                    <StyledFormFieldsContainer>
                      <ServiceRequestCustomForm
                        serviceRequestId={requestDetailsData?.Id}
                        customForm={requestDetailsData?.Service.CustomForm}
                        isAdmin={isAdmin}
                        sectionStyles={sectionStyles}
                        disabled={!isEditable}
                        {...formik}
                      />
                    </StyledFormFieldsContainer>
                  </PrintPDFSection>
                )}

                {requestDetailsData?.Id !== undefined &&
                  customFormColumns?.getArray()?.length > 0 &&
                  (requestDetailsData.Service?.CustomForm
                    ?.VisibleToAdminOnly !== true ||
                    isAdmin === true) && (
                    <PrintPDFSection print={print} id="RequestRows_Table">
                      <StyledFormFieldsContainer>
                        <ServiceRequestRowsTable
                          {...formik}
                          serviceRequestId={requestDetailsData.Id}
                          serviceRequestTypeId={requestDetailsData.Service.Id}
                          formFields={customFormColumns}
                          isEditable={isEditable}
                          processing={false}
                          isAdmin={isAdmin}
                          hasErrors={hasRowsErrors}
                          printing={{
                            id: '3',
                            onLoading: (id: string, loading: boolean) => {
                              dispatch(
                                printActions.setLoading({
                                  id: id,
                                  loading: loading,
                                }),
                              );
                            },
                            printing: print,
                          }}
                        />
                      </StyledFormFieldsContainer>
                    </PrintPDFSection>
                  )}

                {samplesAutomationEnabled && (
                  <StyledFormFieldsContainer>
                    <SamplesTable
                      isAdmin={isAdmin}
                      isEditable={isEditable}
                      serviceRequestId={requestDetailsData?.Id}
                      // processing skeleton is shown independently above
                      processing={false}
                      printing={{
                        id: '1',
                        onLoading: (id: string, loading: boolean) => {
                          dispatch(
                            printActions.setLoading({
                              id: id,
                              loading: loading,
                            }),
                          );
                        },
                        printing: print,
                      }}
                      sectionStyles={tableSectionStyles}
                    />
                  </StyledFormFieldsContainer>
                )}

                {requestDetailsData !== undefined && (
                  <PrintPDFSection print={print} id="Milestones_Services">
                    <StyledFormFieldsContainer>
                      <ServiceRequestMilestonesSection
                        ServiceRequestId={requestDetailsData?.Id}
                        AddonType={
                          requestDetailsData.Service.MilestonesAddonsType
                        }
                        isAdmin={isAdmin}
                        InternalStatus={
                          requestDetailsData.Status.InternalStatusId
                        }
                        pageName={getTitle(
                          requestDetailsData.Service.MilestonesAddonsType,
                        )}
                        tableContainerStyles={tableSectionStyles}
                        printing={{
                          id: '2',
                          onLoading: (id: string, loading: boolean) => {
                            dispatch(
                              printActions.setLoading({
                                id: id,
                                loading: loading,
                              }),
                            );
                          },
                          printing: print,
                        }}
                      />
                    </StyledFormFieldsContainer>
                  </PrintPDFSection>
                )}
                {requestDetailsData?.Id !== undefined && (
                  <PrintPDFSection print={print} id="Comments">
                    <StyledFormFieldsContainer>
                      <Box style={sectionStyles}>
                        <ServiceRequestComments
                          commentType={CommentReferenceTypes.ServiceRequest}
                          referenceId={requestDetailsData?.Id}
                          serviceRequestId={requestDetailsData?.Id}
                          canUserEdit={
                            !requestDetailsData.CommentOnEditOnly ||
                            [
                              InternalServiceRequestStatus[
                                InternalServiceRequestStatus.Draft
                              ],
                              InternalServiceRequestStatus[
                                InternalServiceRequestStatus.ReturnedToUser
                              ],
                            ].includes(
                              requestDetailsData.Status.InternalStatusId,
                            )
                          }
                        />
                      </Box>
                    </StyledFormFieldsContainer>
                  </PrintPDFSection>
                )}
              </Box>
            </PrintToPDF>
          </PageWrapper>
        )}
      </Formik>
    </>
  );
}
export const tableSectionStyles = {
  background: bookitColors.base.white,
  border: `1px solid ${bookitColors.grayscale.grayBorders}`,
  borderRadius: 12,
  paddingBottom: 24,
};
