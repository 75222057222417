/**
 *
 * Asynchronously loads the component for TrainingSessionsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const TrainingSessionsPage = lazyLoad(
  () => import('./index'),
  module => module.TrainingSessionsPage,
);
