import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.coreSelection || initialState;

export const selectCoreSelection = createSelector(
  [selectSlice],
  state => state,
);

export const selectCoreSelectionLoading = createSelector(
  [selectCoreSelection],
  state => state.loading,
);

export const selectCoreSelectionFilters = createSelector(
  [selectCoreSelection],
  state => state.filters,
);
