import * as React from 'react';
import { Entity } from 'types/common';
import {
  FormAutocompleteMulti,
  FormAutocompleteMultiProps,
} from './FormAutocompleteMulti';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export interface FormAutocompleteMultiOptionsProps<T>
  extends Omit<FormAutocompleteMultiProps<T>, 'loadData'>,
    IFieldProps {
  getOptions: (searchTerm: string | null) => Entity<T>[];
  disabled?: boolean;
  id?: string;
}

export function FormAutocompleteMultiOptions<T>({
  getOptions,
  ...props
}: FormAutocompleteMultiOptionsProps<T>) {
  const loadData = async (searchTerm: string | null): Promise<Entity<T>[]> =>
    new Promise<Entity<T>[]>(resolve => {
      const opts = getOptions(searchTerm);
      resolve(opts);
    });
  return (
    <FormAutocompleteMulti loadData={loadData} variant="filled" {...props} />
  );
}
