import { useTranslation } from 'react-i18next';
import {
  getAlertStart,
  WorkOrderDetailsState,
  WorkOrderReadonlyState,
} from '../slice/types';
import * as React from 'react';
import { translations } from 'locales/translations';
import { FormAssetsPicker } from 'app/components/Forms/FormAssetsPicker';
import { FormWorkOrderTypePicker } from 'app/components/Forms/FormWorkOrderTypePicker';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { FormWorkOrderTypeStatusPicker } from 'app/components/Forms/FormWorkOrderTypeStatusPicker';
import { FormRemindBeforePicker } from 'app/components/Forms/FormRemindBeforePicker';
import { FormSymptomCodesPicker } from 'app/components/Forms/FormSymptomCodesPicker';
import { FormRepairCodesPicker } from 'app/components/Forms/FormRepairCodesPicker';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { Formik, FormikProps } from 'formik';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRightSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import {
  FieldHandler,
  FormListener,
} from 'app/components/Forms/FormRender/FormRenderer';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import {
  IAssetDto,
  mapAssetsToServiceFilterDto,
} from 'api/odata/generated/entities/IAssetDto';
import { FormSwitch } from 'app/components/Forms/Switch';
import { FormReasonTypePicker } from 'app/components/Forms/FormReasonTypePicker';
import { ServiceType } from 'enums/ServiceTypes';
import { FormAdminUsersPicker } from 'app/components/Forms/FormAdminUserPicker';
import { getWorkOrdersShema } from './validationSchema';
import { AssetsFilterForWorkOrder } from 'app/components/pickers/MultiSelectPickers/AssetsPicker';
import { dateUtils, WeekStartsOn } from 'utils/date-utils';
import { initReasonTypeData } from 'app/components/pickers/AutocompletePickers/ReasonTypePicker';
import { useDispatch, useSelector } from 'react-redux';
import { selectSystemFirstDayOfWeek } from 'app/slice/selectors';
import { GetAutoCreateTitle } from 'types/IRepetitiveDto';
import { getSingleReminder } from 'app/components/pickers/StaticOptionsPickers/RemindBeforePicker';
import { Entity } from 'types/common';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { RepetitiveControl } from 'app/components/Forms/FormRepetitive/RepetitiveControl';
import { isNullOrUndefined } from 'utils/typeUtils';
import { CreationDetails } from '../CreationDetails';
import { FormUnlimitedDowntime } from './FormUnlimitedDowntime';
import { CustomFormControl } from '../../../../../components/CustomForm/CusomFormControl';
import { CustomFormTypeEnum } from 'enums/CustomFormType';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { FormCustomFormPicker } from 'app/components/Forms/FormCustomFormPicker';
import { AlertFormFilter } from 'app/components/pickers/AutocompletePickers/CustomFormPicker';
import { WorkOrderComments } from './WorkOrderComments';
import { isEmpty } from 'lodash';
import { RepetitiveFor } from 'app/components/Forms/FormRepetitive/slice/types';

import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { WorkOrderDetailsProps } from '..';
import { useWorkOrderSlice } from '../slice';

import {
  selectDowntimeOverlaps,
  selectFieldHandler,
  selectLinksProcessing,
  selectOfflineEventEnd,
  selectOfflineEventStart,
  selectSavedLinkedOrders,
} from '../slice/selectors';
import produce from 'immer';
import {
  AdminServicesFilter,
  initAssigneeAdminUsers,
} from 'app/components/pickers/AutocompletePickers/UserPicker';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { AddCalibrationWorkOrder } from './WorkOrderLinks/AddCalibrationWorkOrder';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { LinkedList, WorkOrderLinks } from './WorkOrderLinks/WorkOrderLinks';
import { useAsyncGetWthEffect } from 'app/hooks/useAsyncGetOnMount';
import { WorkOrderApi } from 'api/WorkOrderApi';
import { FormBookitDateTimePicker } from 'app/components/Forms/FormBookitDateTimePicker';
import { IndexNode } from 'app/components/Forms/FormsLayout/StyledForm';
import { Body, Caption } from 'app/components/Typography';
import { CalendarButton } from 'app/components/CalendarButton';
import { Button } from 'app/components/BasicButtons/Button';

export interface WorkOrderFormProps {
  onSubmit: (
    item: WorkOrderDetailsState,
    subFormFiles: IFormFileValue[],
    staffFormFiles: IFormFileValue[],
  ) => void;
  initialValues: WorkOrderDetailsState;
  processing?: boolean;
  bindSubmitForm: any;
  isEdit: boolean;
  repetitiveFormRef: React.MutableRefObject<any>;
  workOrderTypeFormRef: React.MutableRefObject<any>;
  workOrderStaffFormRef: React.MutableRefObject<any>;
  readonlyDetails?: WorkOrderReadonlyState;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  handleTypeChange: (type: IWorkOrderTypeDto | undefined) => void;
  canView?: boolean;
  commentsFormRef: React.MutableRefObject<any>;
  onAssetClicked?: (asset: Entity<number>) => void;
  onLinkedClick?: (linked: Entity<number>) => void;
  expanded: boolean;
  formRef: React.MutableRefObject<FormikProps<WorkOrderDetailsState> | null>;
  setWorkOrderOfflineStartChangedValue: React.Dispatch<
    React.SetStateAction<Date | string | null | undefined>
  >;
  setWorkOrderOfflineEndChangedValue: React.Dispatch<
    React.SetStateAction<Date | string | null | undefined>
  >;
  useSidePanel?: boolean;
  pageLink?: string;
  showShortView?: boolean;
  isCover?: boolean;
  bringUp?: boolean;
}

export const WorkOrderForm = React.memo(function WorkOrderForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isEdit,
  isAdmin,
  user,
  handleTypeChange,
  repetitiveFormRef,
  workOrderTypeFormRef,
  workOrderStaffFormRef,
  readonlyDetails,
  canView,
  commentsFormRef,
  setWorkOrderOfflineStartChangedValue,
  setWorkOrderOfflineEndChangedValue,
  onAssetClicked,
  expanded,
  formRef,
  onLinkedClick,
  useSidePanel,
  pageLink,
  showShortView,
  isCover,
  bringUp,
}: WorkOrderFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const { actions: workOrderActions } = useWorkOrderSlice();
  // const [addToLinked, setAddToLinked] = React.useState<Entity<number> | null>(
  //   null,
  // );
  const readonly = !isAdmin && isEdit;
  const firstDayOfWeek = useSelector(selectSystemFirstDayOfWeek);
  const fieldHandler = useSelector(selectFieldHandler);
  const savedLinkedOrders = useSelector(selectSavedLinkedOrders);
  const linksProcessing = useSelector(selectLinksProcessing);
  const overlaps = useSelector(selectDowntimeOverlaps);
  // const linkedCalibrationsChanged = useSelector(selectCalibrationsChanged);
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value, originalSubFormFiles, originalStaffFormFiles);
    }
  };
  const resetFieldHandler = () => {
    dispatch(workOrderActions.resetValueHandler());
  };
  const [unlimitedDowntime, setUnlimitedDowntime] = React.useState<boolean>(
    isEdit &&
      initialValues.OfflineEventEnd !== null &&
      initialValues.OfflineEventEnd !== undefined &&
      dateUtils
        .dateOrStringToDate(initialValues.OfflineEventEnd)
        .getFullYear() === 9999,
  );
  const [typeChanged, setTypeChanged] = React.useState<boolean>(false);
  const [downTimeChanged, setDownTimeChanged] = React.useState<boolean>(false);
  const [allIsValid, setAllIsValid] = React.useState<boolean>(true);
  const [remindBeforeChanged, setRemindBeforeChanged] = React.useState<boolean>(
    false,
  );
  const [originalSubFormFiles, setOriginalSubFormFiles] = React.useState<
    IFormFileValue[]
  >([]);
  const [originalStaffFormFiles, setOriginalStaffFormFiles] = React.useState<
    IFormFileValue[]
  >([]);
  const linkedWorkOrders = useAsyncGetWthEffect<LinkedList[]>(
    //WorkOrderApi.getLinkedToCalibration(initialValues.Id),
    async (): Promise<LinkedList[]> => {
      if (isEdit) {
        if (linksProcessing === true) {
          return new Promise<LinkedList[]>(resolve => {
            resolve(linkedWorkOrders);
          });
        } else {
          return WorkOrderApi.getLinkedToCalibration(initialValues.Id).then(
            res => {
              return res.Links as LinkedList[];
            },
          );
        }
      } else {
        return new Promise<LinkedList[]>(resolve => {
          resolve(
            savedLinkedOrders.map(f => {
              return {
                Id: f.Id,
                Name: `#${f.Id}: ${f.AssetName} - ${f.Name}`,
                ParentId: -1,
                Linked: [],
              };
            }),
          );
        });
      }
    },
    [],
    [savedLinkedOrders, linksProcessing],
  );
  const { newDate } = useSystemDate();
  const updateFormValues = (
    original: IFormValueDto[],
    coming: IFormValueDto[],
    alertId: number | null,
    typeId: number | null,
  ) => {
    const result = coming.map(v => {
      let orn = original.find(o => o.FormFieldId === v.FormFieldId);
      if (!!orn) {
        return Object.assign({}, orn, {
          Value: v.Value,
          AlertId: alertId,
          AlertTypeId: typeId,
        });
      } else {
        return Object.assign(v, {
          AlertId: alertId,
          AlertTypeId: typeId,
        });
      }
    });
    //console.log('form values:', result);
    return result;
  };
  const workOrderSchema = React.useMemo(() => {
    return getWorkOrdersShema(t);
  }, [t]);
  const handleFormChange = React.useCallback(
    (values: WorkOrderDetailsState, isValid, dirty, setValue) => {
      if (typeChanged) {
        let type = values.WorkOrderType as IWorkOrderTypeDto;
        handleTypeChange(type);
        if (!!type) {
          if (type.DefaultSubject !== null) {
            setValue('AlertTitle', type.DefaultSubject, false);
          }
          if (initialValues.ToDown) {
            setValue('Downtime', true, false);
            setValue('OfflineEventStart', newDate(), true);
            (async () => {
              try {
                let id = type.DowntimeReasonTypeId ?? 3; // - Unexpected Malfunction
                const reason = await initReasonTypeData(id.toString());
                if (!!reason && reason.length > 0) {
                  setValue('ReasonType', reason[0], false);
                }
              } catch {}
            })();
          } else if (type.DowntimeDuration !== null) {
            setValue('Downtime', true, false);
            if (values.EventStart !== null) {
              let endTime = dateUtils.addHours(
                dateUtils.dateOrStringToDate(values.EventStart),
                type.DowntimeDuration,
              );
              setValue('OfflineEventStart', values.EventStart, false);
              setValue('OfflineEventEnd', endTime, false);
              if (type.DowntimeReasonTypeId != null) {
                (async () => {
                  try {
                    let id = type.DowntimeReasonTypeId ?? 0;
                    const reason = await initReasonTypeData(id.toString());
                    if (!!reason && reason.length > 0) {
                      setValue('ReasonType', reason[0], false);
                    }
                  } catch {}
                })();
              }
            }
          } else {
            setValue('Downtime', false, false);
          }
          setValue(
            'AutoCreateTitle',
            GetAutoCreateTitle(
              type,
              values.EventStart,
              firstDayOfWeek as WeekStartsOn,
            ),
            false,
          );
          if (type.DefaultRemaindBefore !== null) {
            let remb = getSingleReminder(type.DefaultRemaindBefore);
            if (!!remb) {
              setValue('RemindBefore', remb, false);
              setValue('AlertStart', getAlertStart(remb.Id, values.EventStart));
            }
          }
          let assignee: Entity<string>[] = [];
          if (type.AssignedUsers.length > 0) {
            assignee.concat(type.AssignedUsers);
          }
          if (type.AssignedToCreator && !!user) {
            assignee.push({
              Id: user.Id,
              Name: user.FormatedName || user.Id,
            });
          }
          (async () => {
            try {
              let assets = values.Assets as IAssetDto[]; // - Unexpected Malfunction
              const users = await initAssigneeAdminUsers(
                type,
                mapAssetsToServiceFilterDto(assets),
              );
              assignee.concat(users);
              if (assignee.length > 0) {
                setValue('AssignUsers', assignee, false);
              }
            } catch {}
          })();
        }
        setTypeChanged(false);
      }
      if (downTimeChanged) {
        if (values.Downtime) {
          setValue('OfflineEventStart', newDate(), true);
        }
        setDownTimeChanged(false);
      }
      if (remindBeforeChanged) {
        setValue(
          'AlertStart',
          getAlertStart(values.RemindBefore?.Id ?? 0, values.EventStart),
        );
        setRemindBeforeChanged(false);
      }
    },
    [
      downTimeChanged,
      firstDayOfWeek,
      handleTypeChange,
      initialValues.ToDown,
      newDate,
      remindBeforeChanged,
      typeChanged,
      user,
    ],
  );
  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm();
    }
  }, [formRef]);
  const validateForms = React.useCallback((isValid: boolean) => {
    setAllIsValid(isValid);
  }, []);
  const changeLinkedOrders = React.useCallback(
    (
      orders: IWorkOrderDetailsDto[],
      operation: 'add' | 'remove' | 'update',
    ) => {
      if (operation === 'add') {
        let addedOrders = orders.filter(
          f => !savedLinkedOrders.some(s => s.Id === f.Id),
        );
        dispatch(
          workOrderActions.setSavedLinkedOrders([
            ...savedLinkedOrders,
            ...addedOrders,
          ]),
        );
      }
      if (operation === 'remove') {
        let removedOrders = savedLinkedOrders.filter(f => {
          return !orders.some(a => a.Id === f.Id);
        });
        dispatch(workOrderActions.setSavedLinkedOrders(removedOrders));
      }
      if (operation === 'update') {
        dispatch(
          workOrderActions.setSavedLinkedOrders(
            savedLinkedOrders.map(f => {
              let order = orders.find(s => s.Id === f.Id);
              if (!!order && order !== null) {
                return Object.assign({}, f, order);
              } else {
                return f;
              }
            }),
          ),
        );
      }
    },
    [dispatch, savedLinkedOrders, workOrderActions],
  );
  const removeCalibrationOrder = React.useCallback(
    (linked: LinkedList) => {
      dispatch(
        workOrderActions.removeLinkedCalibration({
          id: linked.ParentId,
          link: linked.Id,
        }),
      );
    },
    [dispatch, workOrderActions],
  );
  const addCalibrationOrder = React.useCallback(
    (linked: Entity<number>) => {
      dispatch(
        workOrderActions.addLinkedCalibration({
          id: initialValues.Id,
          link: linked.Id,
        }),
      );
    },
    [dispatch, initialValues.Id, workOrderActions],
  );
  const handleOfflineEventStartChange = (v: any) => {
    dispatch(
      workOrderActions.setOfflineEventStart(
        dateUtils.tryParse(v) === null
          ? undefined
          : dateUtils.formatQueryStringDate(v),
      ),
    );
  };
  const handleOfflineEventEndChange = (v: any) => {
    dispatch(
      workOrderActions.setOfflineEventEnd(
        // dateUtils.tryFormatIso(dateUtils.tryParse(v)) ?? undefined,
        dateUtils.tryParse(v) === null
          ? undefined
          : dateUtils.formatQueryStringDate(v),
      ),
    );
  };
  const OfflineEventStart = useSelector(selectOfflineEventStart);
  const OfflineEventEnd = useSelector(selectOfflineEventEnd);
  const warningMessages: IndexNode[] = React.useMemo(() => {
    let nodes: IndexNode[] = [];
    if (!!overlaps && overlaps.length > 0) {
      nodes.push({
        index: 'overlapsLinks',
        node: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Body size="small" bold={true}>
              {t(translations.InstrumentHasOverlappingDowntimeEvents) as string}
            </Body>
            <Caption>
              {t(translations.ShowAllDowntimeEventsOn) as string}
            </Caption>
            <div
              style={{
                display: 'flex',
                alignSelf: 'stretch',
                gap: 8,
              }}
            >
              <Button
                size={'small'}
                href={`/workorders?aid=${overlaps
                  .filter(f => f.AssetId !== -1)
                  .map(f => f.AssetId)
                  .join(',')}&downTime=2`}
                aria-label="work orders"
                target="_blank"
              >
                {t(translations.menu_WorkOrders) as string}
              </Button>
              <CalendarButton
                size="small"
                variant="white"
                equipments={overlaps
                  .filter(f => f.ServiceId !== -1)
                  .map(f => f.ServiceId)}
              >
                {t(translations.Calendar) as string}
              </CalendarButton>
            </div>

            {/* <Link
              href={`/schedule/calendar/?eid=${overlaps
                .filter(f => f.ServiceId !== -1)
                .map(f => f.ServiceId)
                .join(',')}`}
              aria-label="calendar"
              target="_blank"
            >
              {t(translations.Calendar) as string}
            </Link> */}
          </div>
        ),
        variant: 'outlined',
      });
    }
    return nodes;
  }, [overlaps, t]);
  // React.useEffect(() => {
  //   if (linkedCalibrationsChanged === true) {
  //     dispatch(workOrderActions.setCalibrationChanged(undefined));
  //   }
  // }, [dispatch, linkedCalibrationsChanged, workOrderActions]);
  React.useEffect(() => {
    if (
      isEdit &&
      !!initialValues.OfflineEventStart &&
      initialValues.OfflineEventStart !== null
    ) {
      dispatch(workOrderActions.checkDowntimeOverlaps(initialValues.Id));
    }
  }, [
    bringUp,
    dispatch,
    initialValues.Id,
    initialValues.OfflineEventStart,
    isEdit,
    workOrderActions,
  ]);
  React.useEffect(() => {
    formRef.current?.setValues(
      produce(v => {
        if (OfflineEventStart !== undefined) {
          v.OfflineEventStart = dateUtils.parseISOOrNull(OfflineEventStart);
        }
        if (OfflineEventEnd !== undefined) {
          v.OfflineEventEnd = dateUtils.parseISOOrNull(OfflineEventEnd);
        }
      }),
    );
  }, [OfflineEventStart, OfflineEventEnd, formRef]);

  return (
    <>
      <Formik
        validationSchema={workOrderSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        isInitialValid={false}
        validateOnChange={true}
        innerRef={formRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          if (allIsValid) {
            formikHelpers.validateForm(values).then(responseErrors => {
              if (!isEmpty(responseErrors)) {
                formikHelpers.setSubmitting(false);
                console.debug('errors', responseErrors);
              } else {
                console.debug('submitting', values);
                formikHelpers.setSubmitting(true);
                handleSubmit(values);
              }
            });
          } else {
            formikHelpers.setSubmitting(false);
          }
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            console.log('Work order errors', formik.errors);
          }
          return (
            <>
              <StyledForm onSubmit={formik.handleSubmit} isCover={isCover}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['WorkOrderType', 'Downtime', 'RemindBefore']}
                />
                <FieldHandler
                  {...fieldHandler}
                  resetFieldState={resetFieldHandler}
                />
                <FormLeftSection
                  isCover={isCover}
                  info={
                    (formik.values.WorkOrderType as IWorkOrderTypeDto)
                      .NotEditable === true && formik.values.IsClosed === true
                      ? (t(translations.LockedClosedRecord) as string)
                      : undefined
                  }
                  warningNodes={warningMessages}
                >
                  <FormFieldsSection
                    titleSection={t(translations.EventDetails) as string}
                  >
                    <FormRow isCover={isCover} fullRow={true}>
                      <FormAssetsPicker
                        predicates={AssetsFilterForWorkOrder(
                          (formik.values.WorkOrderType as IWorkOrderTypeDto) ??
                            undefined,
                        )}
                        name="Assets"
                        label={t(translations.Asset) as string}
                        placeholder={t(translations.PleaseSelectAsset)}
                        disabled={
                          formik.isSubmitting ||
                          isEdit ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        onChipClick={onAssetClicked}
                        //onChange={() => setAssetChanged(true)}
                        fullWidth
                      />
                    </FormRow>

                    <FormRow isCover={isCover}>
                      <FormTextField
                        name="AlertTitle"
                        label={t(translations.AlertName)}
                        placeholder={t(translations.AlertName)}
                        disabled={
                          formik.isSubmitting ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        autoComplete="on"
                        id="AlertTitleId"
                        fullWidth
                      />
                    </FormRow>
                    <FormRow isCover={isCover} fullRow={true}>
                      <FormAdminUsersPicker
                        name="AssignUsers"
                        label={t(translations.AssignedUsers)}
                        placeholder={t(translations.PleaseSelectAnAssigneeUser)}
                        predicates={AdminServicesFilter(
                          mapAssetsToServiceFilterDto(
                            formik.values.Assets as IAssetDto[],
                          ),
                        )}
                        multiple={true}
                        disabled={
                          formik.isSubmitting ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        fullWidth
                      />
                    </FormRow>
                    <FormRow
                      isCover={isCover}
                      hide={
                        initialValues.WorkOrderType !== null &&
                        initialValues.ToDown === false
                      }
                    >
                      <FormWorkOrderTypePicker
                        name="WorkOrderType"
                        disableClearable
                        label={t(translations.AlertType)}
                        placeholder={t(translations.PleaseSelectAnAlertType)}
                        disabled={
                          formik.isSubmitting ||
                          isEdit ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        onChange={() => setTypeChanged(true)}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow isCover={isCover} hide={!isEdit}>
                      <FormWorkOrderTypeStatusPicker
                        name="WorkOrderTypeStatus"
                        typeId={formik.values.WorkOrderType?.Id}
                        label={t(translations.EventTypeStatus)}
                        placeholder={t(
                          translations.PleaseSelectAnAlertTypeStatus,
                        )}
                        info="Just for test"
                        disabled={
                          formik.isSubmitting ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        //onChange={() => setStatusChanged(true)}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow
                      isCover={isCover}
                      hide={
                        formik.values.WorkOrderType === null ||
                        (formik.values.WorkOrderType as IWorkOrderTypeDto)
                          .ReportSymptoms !== true
                      }
                    >
                      <FormSymptomCodesPicker
                        name="SymptomCodes"
                        AssetCats={
                          formik.values.Assets.length > 0
                            ? formik.values.Assets.filter(
                                f =>
                                  !isNullOrUndefined(
                                    (f as IAssetDto).AssetCatId,
                                  ),
                              ).map(
                                asset => (asset as IAssetDto).AssetCatId ?? 0,
                              )
                            : undefined
                        }
                        label={t(translations.SymptomCodes)}
                        placeholder={t(
                          translations.PleaseSelectAnAlertSymptomCode,
                        )}
                        disabled={
                          formik.isSubmitting ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        fullWidth
                      />
                    </FormRow>
                    <FormRow
                      isCover={isCover}
                      hide={
                        !(
                          isEdit &&
                          formik.values.WorkOrderType !== null &&
                          (formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .RepairCodesEnabled
                        )
                      }
                    >
                      <FormRepairCodesPicker
                        name="RepairCodes"
                        AssetCatIds={
                          formik.values.Assets.length > 0
                            ? formik.values.Assets.filter(
                                f =>
                                  !isNullOrUndefined(
                                    (f as IAssetDto).AssetCatId,
                                  ),
                              ).map(
                                asset => (asset as IAssetDto).AssetCatId ?? 0,
                              )
                            : undefined
                        }
                        label={t(translations.RepairCodes)}
                        placeholder={t(
                          translations.PleaseSelectAnAlertRepairCode,
                        )}
                        disabled={
                          formik.isSubmitting ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        fullWidth
                      />
                    </FormRow>
                    <FormRow isCover={isCover}>
                      <FormBookitDateTimePicker
                        name="EventStart"
                        // label={t(translations.EventDueDate)}
                        label={t(translations.EventDueDateTimeLabel)}
                        labelInline={true}
                        disabled={
                          formik.isSubmitting ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        fullWidth
                      />
                    </FormRow>
                    <FormRow isCover={isCover}>
                      <FormRemindBeforePicker
                        name="RemindBefore"
                        label={t(translations.RemindMeBefore)}
                        info={t(translations.RemindMeBefore_Description)}
                        disabled={
                          formik.isSubmitting ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        onChange={() => setRemindBeforeChanged(true)}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow
                      isCover={isCover}
                      hide={!(formik.values.RemindBefore?.Id === 6)}
                    >
                      <FormBookitDateTimePicker
                        name="AlertStart"
                        // label={t(translations.AlertStart)}
                        label={t(translations.AlertStartTimeLabel)}
                        labelInline={true}
                        disabled={
                          formik.isSubmitting ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        fullWidth
                      />
                    </FormRow>
                  </FormFieldsSection>
                  {formik.values.Assets.length > 0 &&
                    (formik.values.Assets as IAssetDto[])[0].ServiceTypeId ===
                      ServiceType.Online &&
                    ((isEdit &&
                      (isAdmin || initialValues.OfflineEventStart !== null)) ||
                      (!isEdit && isAdmin)) && (
                      <FormFieldsSection
                        withTopSeparator={formik.values.Downtime}
                        withBottomSeparator={
                          formik.values.Downtime && !expanded
                        }
                      >
                        <FormRow isCover={isCover} fullRow>
                          <FormSwitch
                            boldLebel
                            name="Downtime"
                            label={t(translations.Downtime)}
                            disabled={
                              formik.isSubmitting ||
                              readonly ||
                              ((formik.values
                                .WorkOrderType as IWorkOrderTypeDto)
                                .NotEditable === true &&
                                formik.values.IsClosed === true)
                            }
                            onChange={() => setDownTimeChanged(true)}
                          />
                        </FormRow>
                        <FormRow
                          isCover={isCover}
                          hide={!formik.values.Downtime}
                          fullRow
                        >
                          <FormReasonTypePicker
                            name="ReasonType"
                            label={t(translations.Reason)}
                            placeholder={t(
                              translations.PleaseSelectAReasonType,
                            )}
                            disabled={
                              formik.isSubmitting ||
                              readonly ||
                              ((formik.values
                                .WorkOrderType as IWorkOrderTypeDto)
                                .NotEditable === true &&
                                formik.values.IsClosed === true)
                            }
                            fullWidth
                          />
                        </FormRow>
                        <FormRow
                          isCover={isCover}
                          hide={!formik.values.Downtime}
                        >
                          <FormBookitDateTimePicker
                            name="OfflineEventStart"
                            id="offlineStartId"
                            // label={t(translations.OfflineEventStart)}
                            label={t(translations.OfflineEventStartLabel)}
                            disabled={
                              formik.isSubmitting ||
                              readonly ||
                              ((formik.values
                                .WorkOrderType as IWorkOrderTypeDto)
                                .NotEditable === true &&
                                formik.values.IsClosed === true)
                            }
                            onChange={handleOfflineEventStartChange}
                            fullWidth
                          />
                        </FormRow>
                        <FormRow
                          isCover={isCover}
                          hide={!formik.values.Downtime || unlimitedDowntime}
                        >
                          <FormBookitDateTimePicker
                            name="OfflineEventEnd"
                            id="offlineEndId"
                            // label={t(translations.OfflineEventEnd)}
                            label={t(translations.OfflineEventEndLabel)}
                            disabled={
                              formik.isSubmitting ||
                              readonly ||
                              ((formik.values
                                .WorkOrderType as IWorkOrderTypeDto)
                                .NotEditable === true &&
                                formik.values.IsClosed === true)
                            }
                            onChange={handleOfflineEventEndChange}
                            maskIfEmpty={false}
                            fullWidth
                          />
                        </FormRow>
                        <FormRow
                          isCover={isCover}
                          hide={!formik.values.Downtime || !unlimitedDowntime}
                        >
                          <FormUnlimitedDowntime
                            onClick={() => {
                              formik.setFieldValue(
                                'OfflineEventEnd',
                                dateUtils.formatISO(newDate()),
                              );
                              setUnlimitedDowntime(false);
                            }}
                          />
                        </FormRow>
                        <FormRow
                          isCover={isCover}
                          hide={!formik.values.Downtime}
                          fullRow
                        >
                          <FormRichTextField
                            name="OfflineRemarks"
                            placeholder={t(
                              translations.OfflineRemarksPlaceholder,
                            )}
                            disabled={
                              formik.isSubmitting ||
                              readonly ||
                              ((formik.values
                                .WorkOrderType as IWorkOrderTypeDto)
                                .NotEditable === true &&
                                formik.values.IsClosed === true)
                            }
                            fullWidth
                          />
                        </FormRow>
                        {/* <FormRow isCover={isCover} hide={!formik.values.Downtime || !isEdit}>
                            <FormDowntimeLinks
                              OfflineStart={
                                formik.values.OfflineEventStart === null ||
                                formik.values.OfflineEventStart === undefined
                                  ? undefined
                                  : dateUtils.dateOrStringToDate(
                                      formik.values.OfflineEventStart,
                                    )
                              }
                              OfflineEnd={
                                formik.values.OfflineEventEnd === null ||
                                formik.values.OfflineEventEnd === undefined
                                  ? undefined
                                  : dateUtils.dateOrStringToDate(
                                      formik.values.OfflineEventEnd,
                                    )
                              }
                              ServiceId={
                                (formik.values.Assets[0] as IAssetDto)
                                  .ServiceId ?? undefined
                              }
                              isAdmin={isAdmin}
                            />
                          </FormRow> */}
                      </FormFieldsSection>
                    )}
                  {formik.values.WorkOrderType !== null &&
                    (formik.values.WorkOrderType as IWorkOrderTypeDto)
                      .CustomFormId !== null && (
                      <CustomFormControl
                        CustomFormId={
                          (formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .CustomFormId ?? 0
                        }
                        isAdmin={isAdmin}
                        values={formik.values.SubmissionFormValues}
                        FormType={CustomFormTypeEnum.AlertType}
                        submitFormRef={workOrderTypeFormRef}
                        formIsValid={validateForms}
                        formName={t(translations.SubmissionFormDetails)}
                        readonly={formik.isSubmitting || readonly}
                        // withSeparator={true}
                        saveFormValues={(
                          values: IFormValueDto[],
                          files: IFormFileValue[],
                          originalFiles: IFormFileValue[],
                        ) => {
                          formik.setFieldValue(
                            'SubmissionFormValues',
                            updateFormValues(
                              formik.values.SubmissionFormValues,
                              values,
                              formik.values.Id > 0 ? formik.values.Id : null,
                              formik.values.WorkOrderType?.Id || null,
                            ),
                            false,
                          );
                          formik.setFieldValue(
                            'SubmissionFormFiles',
                            files,
                            false,
                          );
                          setOriginalSubFormFiles(originalFiles);
                        }}
                      />
                    )}
                </FormLeftSection>
                <FormRightSection isCover={isCover}>
                  {!isEdit && (
                    <RepetitiveControl
                      // withSeparator={showShortView}
                      startDate={dateUtils.dateOrStringToDate(
                        formik.values.EventStart,
                      )}
                      submitFormRef={repetitiveFormRef}
                      title={t(translations.RecurringEvents) as string}
                      type={RepetitiveFor.WorkOrder}
                    />
                  )}
                  <FormFieldsSection
                    titleSection={t(translations.Description) as string}
                    // withSeparator={showShortView}
                  >
                    <FormRow isCover={isCover}>
                      <FormRichTextField
                        name="Description"
                        placeholder={t(translations.Description)}
                        disabled={
                          formik.isSubmitting ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        fullWidth
                      />
                    </FormRow>
                  </FormFieldsSection>

                  <FormFieldsSection>
                    <WorkOrderComments
                      commentsFormRef={commentsFormRef}
                      isEdit={isEdit}
                      user={user}
                      order={formik.values}
                      isAdmin={isAdmin}
                      disabled={
                        (readonly &&
                          !(user?.Id === formik.values.ReportedBy)) ||
                        ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                          .NotEditable === true &&
                          formik.values.IsClosed === true)
                      }
                    />
                  </FormFieldsSection>

                  {isEdit && formik.values.HasAletForm && (
                    <React.Fragment>
                      <FormFieldsSection
                        // withSeparator={true}
                        titleSection={t(translations.StaffFilledForm)}
                      >
                        <FormRow isCover={isCover}>
                          <FormCustomFormPicker
                            formType={CustomFormTypeEnum.Alert}
                            name="StuffCustomForm"
                            placeholder={t(translations.PleaseSelectCustomForm)}
                            disabled={
                              formik.isSubmitting ||
                              readonly ||
                              ((formik.values
                                .WorkOrderType as IWorkOrderTypeDto)
                                .NotEditable === true &&
                                formik.values.IsClosed === true)
                            }
                            filter={AlertFormFilter(
                              formik.values.WorkOrderType?.Id || 0,
                              (formik.values.Assets[0] as IAssetDto)
                                .AssetCatId || 0,
                            )}
                            fullWidth
                          />
                        </FormRow>
                      </FormFieldsSection>
                      {formik.values.StuffCustomForm !== null &&
                        formik.values.StuffCustomForm.Id !== 0 && (
                          <CustomFormControl
                            CustomFormId={formik.values.StuffCustomForm.Id ?? 0}
                            readonly={readonly}
                            isAdmin={isAdmin}
                            values={formik.values.StaffFormValues}
                            FormType={CustomFormTypeEnum.Alert}
                            submitFormRef={workOrderStaffFormRef}
                            formIsValid={validateForms}
                            saveFormValues={(
                              values: IFormValueDto[],
                              files: IFormFileValue[],
                              originalFiles: IFormFileValue[],
                            ) => {
                              formik.setFieldValue(
                                'StaffFormValues',
                                updateFormValues(
                                  formik.values.StaffFormValues,
                                  values,
                                  formik.values.Id > 0
                                    ? formik.values.Id
                                    : null,
                                  null,
                                ),
                                false,
                              );
                              formik.setFieldValue(
                                'StaffFormFiles',
                                files,
                                false,
                              );
                              setOriginalStaffFormFiles(originalFiles);
                            }}
                          />
                        )}
                    </React.Fragment>
                  )}

                  {readonlyDetails !== undefined && isEdit && expanded && (
                    <FormFieldsSection withBottomSeparator withTopSeparator>
                      <CreationDetails details={readonlyDetails} />
                    </FormFieldsSection>
                  )}
                  {(isEdit
                    ? !!linkedWorkOrders && linkedWorkOrders.length > 0
                    : savedLinkedOrders.length > 0) && (
                    <WorkOrderLinks
                      links={
                        isEdit
                          ? linkedWorkOrders
                          : savedLinkedOrders.map(f => {
                              return {
                                Id: f.Id,
                                Name: `${f.AssetName} - ${f.Name}`,
                                ParentId: -1,
                                Linked: [],
                              };
                            })
                      }
                      onHoverClick={item => {
                        isEdit
                          ? removeCalibrationOrder(item)
                          : changeLinkedOrders(
                              [
                                (item as Entity<
                                  number
                                >) as IWorkOrderDetailsDto,
                              ],
                              'remove',
                            );
                      }}
                      onItemClcik={item => {
                        isEdit
                          ? dispatch(
                              actions.openSidePanel({
                                type: RenderPageType.WorkOrderDetails,
                                props: {
                                  queryParams: { id: '' + item.Id },
                                  useSidePanel: true,
                                  continueTo: {
                                    pageType: useSidePanel
                                      ? RenderPageType.WorkOrderDetails
                                      : undefined,
                                    pageProps: useSidePanel
                                      ? {
                                          queryParams: {
                                            id: '' + formik.values.Id,
                                          },
                                          useSidePanel: true,
                                        }
                                      : undefined,
                                    continueToLink: useSidePanel
                                      ? undefined
                                      : pageLink,
                                  },
                                } as WorkOrderDetailsProps,
                              }),
                            )
                          : !!onLinkedClick && onLinkedClick(item);
                      }}
                      showProcessing={linksProcessing}
                    />
                  )}
                  {isAdmin &&
                    formik.values.WorkOrderType !== null &&
                    formik.values.Assets.length > 0 && (
                      <FormRow isCover={isCover} fullRow={true}>
                        <AddCalibrationWorkOrder
                          showProcessing={linksProcessing}
                          workOrederIds={
                            isEdit
                              ? [
                                  ...linkedWorkOrders.map(f => f.Id),
                                  ...[formik.values.Id],
                                ]
                              : savedLinkedOrders.map(f => f.Id)
                          }
                          saveLinkedOrders={value => {
                            if (isEdit) {
                              addCalibrationOrder({
                                Id: value.Id,
                                Name: value.Name,
                              });
                            } else {
                              changeLinkedOrders([value], 'add');
                            }
                          }}
                        />
                      </FormRow>
                    )}
                  {/* {readonlyDetails !== undefined &&
                      readonlyDetails.LinkedAlerts.length > 0 &&
                      isEdit && (
                        <>
                          {expanded ? (
                            <FormFieldsSection
                              titleSection={t(translations.LinkedTo)}
                            >
                              <>
                                {readonlyDetails.LinkedAlerts.map((l, i) => {
                                  return (
                                    <HoverLinkedItem
                                      linked={l}
                                      key={l.Id + i}
                                      useHoverButton={true}
                                      onHoverClick={item =>
                                        dispatch(
                                          workOrderActions.removeFromLinked(
                                            item.Id,
                                          ),
                                        )
                                      }
                                    >
                                      <Button
                                        variant="text"
                                        size="small"
                                        onClick={() =>
                                          dispatch(
                                            actions.openSidePanel({
                                              type:
                                                RenderPageType.WorkOrderDetails,
                                              props: {
                                                queryParams: { id: '' + l.Id },
                                                useSidePanel: true,
                                              } as WorkOrderDetailsProps,
                                            }),
                                          )
                                        }
                                      >
                                        #{l.Id} - {l.Name}
                                      </Button>
                                    </HoverLinkedItem>
                                  );
                                })}
                              </>
                            </FormFieldsSection>
                          ) : (
                            <FormFieldsSection>
                              <Button
                                variant="text"
                                size="small"
                                onClick={() =>
                                  dispatch(actions.setExpanded(true))
                                }
                              >
                                {t(translations.LinkedWorkOrders).replace(
                                  '{0}',
                                  readonlyDetails.LinkedAlerts.length.toString(),
                                )}
                              </Button>
                            </FormFieldsSection>
                          )}
                        </>
                      )}
                    {initialValues.RecurringAssetId &&
                      isEdit &&
                      isAdmin &&
                      expanded && (
                        <span style={{ display: 'flex' }}>
                          <BaseURLPicker
                            url="/api/odata/v4/workorders/GetAllowedWorkOrders"
                            queryfilter={new Condition(
                              'Id',
                              ODataOperators.Excludes,
                              readonlyDetails?.LinkedAlerts ?? [],
                            ).toString()}
                            onChange={v => setAddToLinked(v)}
                            value={addToLinked}
                          />
                          <Button
                            disabled={!addToLinked}
                            onClick={() =>
                              dispatch(
                                workOrderActions.addToLinked({
                                  id: addToLinked!.Id,
                                  link: initialValues.RecurringAssetId!,
                                }),
                              )
                            }
                          >
                            Add to linked
                          </Button>
                        </span>
                      )} */}
                </FormRightSection>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    </>
  );
});
