/**
 *
 * Description
 *
 */
import * as React from 'react';
import { Tooltip } from '../BasicTooltips/Tooltip';
import { assertExhaustive } from 'utils/assertExhaustive';
import htmlParse from 'html-react-parser';
import styled from 'styled-components';

/**
 * Predefined (column) widths
 */
type TextLength = 'short' | 'medium' | 'long' | 'auto';

const DEFAULT_WIDTH = 'medium';

export interface DescriptionProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'children' | 'title'> {
  value?: string | null;
  //className?: string;
  title?: string | null | React.ReactNode;
  /**
   * predefined width - default = medium
   */
  width?: TextLength;
  /**
   * Makes a tooltip interactive, i.e. will not close when the user
   * hovers over the tooltip before the `leaveDelay` is expired.
   */
  interactive?: boolean;
  /**
   * The number of milliseconds to wait before hiding the tooltip.
   * This prop won't impact the leave touch delay (`leaveTouchDelay`).
   */
  leaveDelay?: number;
  /**
   * The number of milliseconds after the user stops touching an element before hiding the tooltip.
   */
  leaveTouchDelay?: number;
}
/**
 * Renders a long text Cell - truncates long text with a popup
 * @param param0 value to render
 * @returns
 */
export const Description = ({
  value,
  title,
  width = DEFAULT_WIDTH,
  interactive,
  leaveDelay,
  leaveTouchDelay,
}: DescriptionProps) => {
  // cleanup html tags for inline display and trim white spaces

  const textValue = tryCleanValue(value);

  if (value === '' || value === null || value === undefined) {
    return <></>;
  }

  // use the title if provided, otherwise tooltip will show full text value
  const titleValue = title ?? value;

  // parse html title value for html values e.g. remarks, email body, etc.
  const parsedTitle =
    typeof titleValue === 'string' ? htmlParse(titleValue) : titleValue;

  const maxChars = toCharsWidth(width);
  const foo =
    maxChars !== undefined &&
    maxChars > 0 &&
    typeof textValue === 'string' &&
    textValue.length > maxChars;
  if (foo) {
    return (
      <Tooltip
        data-html="true"
        title={parsedTitle}
        arrow
        interactive={interactive}
        leaveDelay={leaveDelay}
        leaveTouchDelay={leaveTouchDelay}
      >
        <TruncatedDescriptionText value={textValue} width={width} />
      </Tooltip>
    );
  } else {
    return <>{String(textValue)}</>;
  }
};

/***
 * Renders truncated text without tooltip
 */
export const TruncatedDescriptionText = React.forwardRef<
  HTMLSpanElement,
  DescriptionProps
>(function TruncatedDescriptionText(
  { value, width = DEFAULT_WIDTH, title, ...props }: DescriptionProps,
  ref,
) {
  if (value === '' || value === null || value === undefined) {
    return null;
  }
  return (
    <Styled ref={ref} {...props} width={toCssWidth(width)}>
      {value}
    </Styled>
  );
});
const Styled = styled('span')<{ width: string }>`
  display: inline-block;
  white-space: nowrap;
  max-width: ${props => props.width};
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * Converts preset width to value that can be used to set css width
 * @param lengthType preset width
 * @returns
 */
const toCharsWidth = (lengthType?: TextLength) => {
  const length = lengthType ?? DEFAULT_WIDTH;
  switch (length) {
    case 'short':
      return 15;
    case 'medium':
      return 30;
    case 'long':
      return 70;
    case 'auto':
      return undefined;
    default:
      assertExhaustive(length);
  }
};

/**
 * Converts preset width to value that can be used to set css width
 * @param lengthType preset width
 * @returns
 */
const toCssWidth = (lengthType?: TextLength) => {
  const length = lengthType ?? DEFAULT_WIDTH;
  switch (length) {
    case 'short':
    case 'medium':
    case 'long':
      return `${toCharsWidth(length)}ch`;

    case 'auto':
      return 'auto';
    default:
      assertExhaustive(length);
  }
};

function tryCleanValue(value: string | null | undefined) {
  if (value === null || value === undefined) {
    return undefined;
  }

  try {
    return value?.replace(/<[^>]+>/g, '')?.trim();
  } catch (error) {
    return value;
  }
}
