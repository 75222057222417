import { GlobalSettingsType } from '../components/useGlobalSettingsHook';
import { IReservationEquipmentDto } from './types';

/**
 * This function determines if the budgets are enabled and should be visible for particular online services selection
 * @param globalSettings global settings
 * @param equipments related online services
 * @returns
 */

export function getBudgetVisible(
  globalSettings: GlobalSettingsType,
  equipments: Array<
    Pick<
      IReservationEquipmentDto,
      'HideProjects' | 'BudgetsTurnedOn' | 'Billable'
    >
  >,
): boolean {
  return (
    globalSettings.budgetModuleEnabled &&
    !equipments.every(f => f.HideProjects) &&
    equipments.every(f => f.BudgetsTurnedOn === true) &&
    // Billable is only shown on the instrument details when the PriceSheetPricing is on. Otherwise the instruments will with Billable = false, which should be ignored since they are in fact billable
    (globalSettings.priceSheetPricing === true ||
      equipments.some(f => f.Billable === true))
  );
}
