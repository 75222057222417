import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, { isOpen: boolean }>(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
      padding: 0,
      marginTop: 40,
      '& .Mui-selected': {
        backgroundColor: theme.palette.primary.bgHover,
        '& > .menuItemContent': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.background.paper,
          '& svg': {
            color: theme.palette.background.paper,
          },
        },
      },
      '& .Mui-selected:hover': {
        backgroundColor: theme.palette.primary.bgHover,
        '& > .menuItemContent': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.background.paper,
          '& svg': {
            color: theme.palette.background.paper,
          },
        },
      },
      '& > .menuItem': {
        padding: 0,
        '& > .menuItemContent': {
          display: 'flex',
          width: '100%',
          position: 'relative',
          boxSizing: 'border-box',
          textAlign: 'left',
          alignItems: 'center',
          justifyContent: 'flex-start',
          textDecoration: 'none',
          padding: '6px 8px 6px 26px',
          fontWeight: '900', //theme.typography.fontWeightBold,
          color: theme.palette.common.graySubtitle,
          textTransform: 'uppercase',
          backgroundColor: theme.palette.background.default,
          gap: 16,
          marginBottom: '8px',
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '& > .menuItemIcon': {
              color: theme.palette.common.white,
            },
          },
          '& *': {
            fontWeight: '900', //theme.typography.fontWeightBold,
            whiteSpace: props => (props.isOpen ? 'initial' : 'inherit'),
          },
          '& > .menuItemIcon': {
            color: theme.palette.common.grayComponents,
            minWidth: props => (props.isOpen ? 'initial' : '48px'),
            '& > svg': {
              width: '19px',
            },
          },
          '& > .menuItemText': {
            margin: 0,
            '& > *': {
              fontSize: '13px',
            },
          },
          '& > .menuItemTextInset': {
            paddingLeft: 0,
          },
        },
      },
      '& > .hoverItem': {
        marginBottom: '8px',
        '& .Mui-selected': {
          backgroundColor: theme.palette.primary.bgHover,
          '& > .menuItemHoverContent': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.paper,
            '& svg': {
              color: theme.palette.background.paper,
            },
          },
        },
        '& .Mui-selected:hover': {
          backgroundColor: theme.palette.primary.bgHover,
          '& > .menuItemHoverContent': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.paper,
            '& svg': {
              color: theme.palette.background.paper,
            },
          },
        },
        '& > .menuItem': {
          padding: 0,
          '& > .menuItemHoverContent': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '900', //theme.typography.fontWeightBold,
            color: theme.palette.common.graySubtitle,
            textTransform: 'uppercase',
            paddingLeft: 16,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              '& > .menuItemIcon': {
                color: theme.palette.common.white,
              },
            },
            '&:focus': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              '& > .menuItemIcon': {
                color: theme.palette.common.white,
              },
            },
            '& *': {
              fontWeight: '900', //theme.typography.fontWeightBold,
              whiteSpace: props => (props.isOpen ? 'initial' : 'inherit'),
            },
            '& > .menuItemIcon': {
              color: theme.palette.common.grayComponents,
              minWidth: props => (props.isOpen ? '32px' : '48px'),
              '& > svg': {
                width: '1.5em',
              },
            },
            '& > .menuItemText': {
              '& > *': {
                fontSize: '13px',
              },
            },
            '& > .menuItemTextInset': {
              paddingLeft: 0,
            },
          },
        },
      },
      '& .childRoot': {
        padding: '12px 0px 12px 0',
        backgroundColor: theme.palette.primary.bgHover,
        '& .Mui-selected': {
          '& > .menuItemContent': {
            backgroundColor: theme.palette.primary.bgHover,
            color: theme.palette.primary.main,
          },
        },
        '& .Mui-selected:hover': {
          '& > .menuItemContent': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.bgHover,
          },
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.bgHover,
          color: theme.palette.primary.main,
        },
        '&>.active': {
          '& > .menuItemContent': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.bgHover,
          },
        },
        '& > .menuItem': {
          padding: 0,
          paddingLeft: '26px',
          '& > .menuItemContent': {
            display: 'flex',
            width: '100%',
            position: 'relative',
            boxSizing: 'border-box',
            textAlign: 'left',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textDecoration: 'none',
            color: theme.palette.common.blackRegular,
            '&.active': {
              color: theme.palette.primary.main,
            },
            '& > .menuItemTextInset': {
              paddingLeft: props => (props.isOpen ? '0px' : '40px'),
              paddingBlock: '6px',
              margin: 0,
            },
            '& *': {
              fontWeight: '900', //theme.typography.fontWeightBold,
              fontSize: '13px',
            },
            '&:hover': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
    selected: {},
    sideBarHeader: {
      minHeight: '48px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: props => (props.isOpen ? '0px 16px' : '0px 8px'),
      gap: 16,
    },
    sideBarFooter: {
      minHeight: '48px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 16px',
      fontSize: '12px',
      gap: 16,
    },
  }),
);
