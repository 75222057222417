import { IAuthenticatedUser } from 'types/AuthenticatedUser';

/**
 * Gets the default filters for the calendar/timeline
 * @param user
 * @returns
 */
export const getDefaultFilter: (
  user: IAuthenticatedUser,
) => URLSearchParams = user => {
  const result = new URLSearchParams();
  return result;
};
