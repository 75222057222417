import { useProfileSetting } from 'app/components/BasicTable/useProfileSetting';
import {
  selectGlobalServiceGroupFilter,
  selectIsAuthenticated,
  selectLoading,
  selectGlobalSettingBool,
  selectShowGlobalServiceGroupFilter,
  selectAuthenticatedUser,
  selectAppSettings,
} from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { AllowedSettings } from 'utils/globalSettings';
import { useLocationChange } from './useLocationChange';

export const useRequiredRedirect = () => {
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const selectedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const globalCoreVisible = useSelector(selectShowGlobalServiceGroupFilter);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const loading = useSelector(selectLoading);
  const topServiceGroupsIsHidden = useSelector(state =>
    selectGlobalSettingBool(state, AllowedSettings.HideTopServiceGroupFilter),
  );
  const appSettings = useSelector(selectAppSettings);
  const termsOfUseAgreementEnabled = IsModuleEnabled(
    appSettings,
    KnownModules.TermsOfUseAgreement,
  );
  const multipleAdGroupsModuleEnabled = IsModuleEnabled(
    appSettings,
    KnownModules.MultipleAdGroups,
  );
  const { savedEnabled: isFirstLogin } = useProfileSetting({
    profileSettingKey: 'IsFirstLoginUser',
  });
  const { savedEnabled: OptOutCoreSelection } = useProfileSetting({
    profileSettingKey: 'CoreSelectionOptOut',
  });
  const { savedEnabled: isFirstActiveUserGroupSelected } = useProfileSetting({
    profileSettingKey: 'IsFirstActiveUserGroupSelected',
  });
  const skipCoreSelectionScreen = useSelector(state =>
    selectGlobalSettingBool(state, AllowedSettings.SkipCoreSelectionScreen),
  );
  const usageKioskRoleEnabled = useSelector(state =>
    selectGlobalSettingBool(state, AllowedSettings.UsageKioskRoleEnabled),
  );
  const roomUserRoleEnabled = useSelector(state =>
    selectGlobalSettingBool(state, AllowedSettings.RoomUserRoleEnabled),
  );
  const [
    redirectToTermsOfUseAgreement,
    setRedirectToTermsOfUseAgreement,
  ] = React.useState<boolean>(false);
  const [
    redirectToActiveUserGroup,
    setRedirectToActiveUserGroup,
  ] = React.useState<boolean>(false);
  const [redirectToCoreSelection, setRedirectToCoreSelection] = React.useState<
    boolean
  >(false);
  const [redirectToUsageKiosk, setRedirectToUsageKiosk] = React.useState<
    boolean
  >(false);
  const [redirectToUsageRoom, setRedirectToUsageRoom] = React.useState<boolean>(
    false,
  );
  const [
    redirectToConsumableKiosk,
    setRedirectToConsumableKiosk,
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!loading && isAuthenticated) {
      if (
        !topServiceGroupsIsHidden &&
        !skipCoreSelectionScreen &&
        globalCoreVisible
      ) {
        if (
          !selectedServiceGroups ||
          selectedServiceGroups.length < 1 ||
          (!OptOutCoreSelection && isFirstLogin)
        ) {
          if (window.location.pathname.indexOf('/coreselection') === -1) {
            setRedirectToCoreSelection(true);
          } else {
            setRedirectToCoreSelection(false);
          }
        } else {
          setRedirectToCoreSelection(false);
        }
      } else {
        setRedirectToCoreSelection(false);
      }
    } else {
      setRedirectToCoreSelection(false);
    }
    return () => {
      setRedirectToCoreSelection(false);
    };
  }, [
    OptOutCoreSelection,
    globalCoreVisible,
    isAuthenticated,
    isFirstLogin,
    loading,
    selectedServiceGroups,
    skipCoreSelectionScreen,
    topServiceGroupsIsHidden,
  ]);

  useLocationChange((current: Location, previus: Location) => {
    if (!previus || current.pathname !== previus.pathname) {
      if (!loading && isAuthenticated) {
        if (
          termsOfUseAgreementEnabled &&
          authenticatedUser !== undefined &&
          authenticatedUser?.HasSignedTermsOfUse === true
        ) {
          setRedirectToTermsOfUseAgreement(false);
        } else {
          setRedirectToTermsOfUseAgreement(true);
        }

        if (
          (isFirstActiveUserGroupSelected === false ||
            isFirstActiveUserGroupSelected === undefined) &&
          multipleAdGroupsModuleEnabled &&
          (authenticatedUser?.UserGroups?.length || 1) > 1
        ) {
          setRedirectToActiveUserGroup(true);
        } else {
          setRedirectToActiveUserGroup(false);
        }
        if (
          usageKioskRoleEnabled &&
          authenticatedUser?.Roles.includes(Roles.KioskAdmin)
        ) {
          setRedirectToUsageKiosk(true);
        } else {
          setRedirectToUsageKiosk(false);
        }
        if (
          roomUserRoleEnabled &&
          authenticatedUser?.Roles.includes(Roles.RoomOnly)
        ) {
          setRedirectToUsageRoom(true);
        } else {
          setRedirectToUsageRoom(false);
        }
        if (authenticatedUser?.Roles.includes(Roles.ConsumableKiosk)) {
          setRedirectToConsumableKiosk(true);
        } else {
          setRedirectToConsumableKiosk(false);
        }
        if (
          !topServiceGroupsIsHidden &&
          !skipCoreSelectionScreen &&
          globalCoreVisible
        ) {
          if (
            !selectedServiceGroups ||
            selectedServiceGroups.length < 1 ||
            (!OptOutCoreSelection && isFirstLogin)
          ) {
            if (
              current.pathname.indexOf('/coreselection') === -1 &&
              !redirectToUsageKiosk &&
              !redirectToUsageRoom &&
              !redirectToConsumableKiosk
            ) {
              setRedirectToCoreSelection(true);
            } else {
              setRedirectToCoreSelection(false);
            }
          } else {
            setRedirectToCoreSelection(false);
          }
        }
      }
    }
  });
  return {
    redirectToCoreSelection,
    redirectToTermsOfUseAgreement,
    redirectToActiveUserGroup,
    redirectToUsageKiosk,
    redirectToUsageRoom,
    redirectToConsumableKiosk,
  };
};
