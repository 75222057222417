/**
 *
 * Asynchronously loads the component for RequestSamplesPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RequestSamplesPage = lazyLoad(
  () => import('./index'),
  module => module.RequestSamplesPage,
);

export const RequestDetailsPage = lazyLoad(
  () => import('./index'),
  module => module.RequestDetailsPage,
);
