/**
 *
 * AssetCatFilter
 *
 */
import { AssetCatPicker } from 'app/components/pickers/AutocompletePickers/AssetCatPicker';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const AssetCatFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <>
      <AssetCatPicker
        ariaLabel={props.label}
        id={props.id}
        value={props.value}
        onChange={handleOnChange}
        isOpen={props.open}
        placeholder={t(translations.AllValues)}
        predicates={props.predicates}
        onPickerOpen={props.onOpen}
        size="small"
        urlType={props.urlType}
      />
    </>
  );
};
export default AssetCatFilter;
