/**
 *
 * AssetListItem
 *
 */
import {
  Avatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { toQueryString, toRootedURL } from 'utils/url-utils';
import { AssetThumbImage } from 'app/components/AssetThumbImage';
import { AssetQuickSearchDetails } from '../slice/types';
import { ListItemLink } from 'app/components/ListItemLink';
import { useSelector } from 'react-redux';
import { selectGlobalSetting, selectglobalSettings } from 'app/slice/selectors';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import { GetSchedulerPagePath } from 'app/pages/CalendarPage/GetCalendarPagePath';
import cliTruncate from 'cli-truncate';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Body, Tips } from 'app/components/Typography';
import React from 'react';
import { ActionIconButton } from 'app/components/BasicButtons/ActionIconButton';
import { tryParseBool } from 'utils/string-utils';
import { Entity } from 'types/common';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';

export interface AssetListItemProps {
  item: AssetQuickSearchDetails;
  useAssetPopup?: boolean;
  isMobile?: boolean;
  selectedServiceGroups?: Entity<number>[];
}

const DefaultQuickAssetSearchAction = [
  'AssetDetails',
  'Calendar',
  'Timeline',
] as const;

const getAssetDetailsLink = (item: AssetQuickSearchDetails) =>
  toRootedURL(`/assets/details.aspx?id=${item.Id}`);
const getCalendarLink = (
  item: AssetQuickSearchDetails,
  EnableLegacyScheduler: boolean,
) => {
  if (EnableLegacyScheduler) {
    return toRootedURL('/Weekly.aspx', { id: `os${item.ServiceId}` });
  }

  return toRootedURL(
    `${GetSchedulerPagePath('calendar')}?${toQueryString({
      eid: item.ServiceId,
      openToBook: true,
    })}`,
  );
};

const getTimelineLink = (
  item: AssetQuickSearchDetails,
  EnableLegacyScheduler: boolean,
) => {
  if (EnableLegacyScheduler) {
    return toRootedURL('/Timeline.aspx', { services: item.ServiceId });
  }
  return toRootedURL(
    `${GetSchedulerPagePath('timeline')}?eid=${item.ServiceId}&openToBook=true`,
  );
};

function getSettingValue(
  g: GlobalSettings,
  selectedServiceGroups?: Entity<number>[],
): (item: AssetQuickSearchDetails) => string {
  const s = g.GetString(AllowedSettings.DefaultQuickAssetSearchAction);
  const t = g.GetBooleanByKey(AllowedSettings.EnableLegacyScheduler);
  var validKey =
    DefaultQuickAssetSearchAction.find(f => f === s) || 'AssetDetails';
  return (item: AssetQuickSearchDetails) => {
    if (
      !item.BookableAsset ||
      (item.BookableAsset &&
        !selectedServiceGroups?.some(f => f.Id === item.ServiceGroupId))
    ) {
      return getAssetDetailsLink(item);
    }
    switch (validKey) {
      case 'AssetDetails':
        return getAssetDetailsLink(item);
      case 'Calendar':
        return getCalendarLink(item, t);
      case 'Timeline':
        return getTimelineLink(item, t);
    }
  };
}

export function AssetListItem({
  item,
  useAssetPopup,
  isMobile,
  selectedServiceGroups,
}: AssetListItemProps) {
  const { t } = useTranslation();
  const truncateName = isMobile
    ? item.Name?.length > 25
    : item.Name?.length > 50;
  const app = useSelector(selectglobalSettings);
  const defaultLink = getSettingValue(app);
  const EnableLegacyScheduler = useSelector(
    state =>
      tryParseBool(
        selectGlobalSetting(state, AllowedSettings.EnableLegacyScheduler),
      ) === true,
  );
  const showCalendarLinks = React.useMemo(() => {
    return (
      (selectedServiceGroups === undefined && item.BookableAsset) ||
      (!!selectedServiceGroups &&
        selectedServiceGroups.some(f => f.Id === item.ServiceGroupId))
    );
  }, [item.BookableAsset, item.ServiceGroupId, selectedServiceGroups]);
  return (
    <ListItemLink
      key={item.Id}
      disableGutters
      button
      component="a"
      target={'_blank'}
      href={defaultLink(item)}
      className={'assetListItemLink'}
      disableRipple
      info={t(translations.ShowFullDetails) as string}
    >
      <ListItemIcon>
        {item.Image !== null ? (
          <AssetThumbImage imageName={item.Image} maxSize={32} />
        ) : (
          <Avatar variant="square">{'A'}</Avatar>
        )}
      </ListItemIcon>
      <ListItemText
        primary={
          isMobile ? (
            <>{cliTruncate(item.Name, 25, { position: 'end' })}</>
          ) : (
            <>
              {truncateName ? (
                <Tooltip title={item.Name}>
                  <span>{item.Name?.substr(0, 50) + '...'}</span>
                </Tooltip>
              ) : (
                <>{item.Name}</>
              )}
            </>
          )
        }
        secondary={
          isMobile ? (
            cliTruncate(item.AvailabilityTypeName, 15, { position: 'middle' })
          ) : item.ApprovalRequired ? (
            <div
              style={{ display: 'inline-flex', gap: 8, alignItems: 'center' }}
            >
              <Body>{item.AvailabilityTypeName}</Body>
              <Tips bold>{t(translations.ApprovalRequired) as string}</Tips>
            </div>
          ) : (
            item.AvailabilityTypeName
          )
        }
      />
      <ListItemSecondaryAction>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {item.BookableAsset && showCalendarLinks && (
            <ActionIconButton
              variant="ghost"
              icon="calendar-alt"
              size="xs"
              title={t(translations.Schedule)}
              href={getCalendarLink(item, EnableLegacyScheduler)}
              shape="square"
            />
          )}
          {/* {item.BookableAsset && showCalendarLinks && (
            <ActionIconButton
              variant="ghost"
              icon="stream"
              size="xs"
              title={t(translations.Timeline)}
              href={getTimelineLink(item, EnableLegacyScheduler)}
              shape="square"
            />
          )} */}
          {/* <ActionIconButton
            variant="ghost"
            icon="file-alt"
            size="xs"
            title={t(translations.AssetDetails)}
            href={getAssetDetailsLink(item)}
            shape="square"
          /> */}
        </div>
      </ListItemSecondaryAction>
    </ListItemLink>
  );
}
