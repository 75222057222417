/**
 *
 * FormTrainingLevelPicker
 *
 */
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { TrainingLevelPicker } from '../pickers/AutocompletePickers/TrainingLevelPicker';
import { ITrainingLevelFilterDto } from 'api/odata/generated/entities/ITrainingLevelFilterDto';

export const FormTrainingLevelPicker = ({
  label,
  ...props
}: FieldHookConfig<ITrainingLevelFilterDto | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<ITrainingLevelFilterDto | null>(
    props,
  );
  return (
    <>
      <TrainingLevelPicker
        name={props.name}
        placeholder={props.placeholder}
        predicates={props.predicates}
        label={label}
        variant="filled"
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        info={props.info}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      />
    </>
  );
};
