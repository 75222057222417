import { Theme } from '@material-ui/core/styles';
import { MenuItem, MenuItemProps } from '@material-ui/core';
import styled from 'styled-components';

// export const StyledMenuItem = withStyles({
//   root: {
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//   },
// })(MenuItem);
export const StyledMenuItem = styled(
  ({
    button,
    theme,
    children,
    ...other
  }: {
    button: true | false;
    theme: Theme;
    children: React.ReactNode;
  } & MenuItemProps) => (
    <MenuItem disableRipple {...other}>
      {children}
    </MenuItem>
  ),
)`
  &:hover {
    background-color: 'transparent';
  }
  &.hidden-if-empty:empty {
    display: none;
  }
`;
