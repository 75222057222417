import styled from 'styled-components';
import { Theme, AppBar, AppBarProps, MenuProps, Menu } from '@material-ui/core';

export const StyledTopBar = styled(
  ({
    theme,
    drawerWidth,
    sidePanelWidth,
    children,
    ...other
  }: {
    theme: Theme;
    drawerWidth: number;
    sidePanelWidth: number;
    children: React.ReactNode;
  } & AppBarProps) => <AppBar {...other}>{children}</AppBar>,
)`
  z-index: ${props => props.theme.zIndex.drawer + 1};
  transition: ${props =>
    props.theme.transitions.create(['width', 'margin'], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.leavingScreen,
    })};
  box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%),
    0px 0px 2px 0px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 12%);
  width: calc(100% - ${props => props.theme.spacing(7) + 1}px);
  &.appBarShift {
    margin-left: ${props => props.drawerWidth}px;
    width: calc(100% - ${props => props.drawerWidth}px);
    transition: ${props =>
      props.theme.transitions.create(['width', 'margin'], {
        easing: props.theme.transitions.easing.sharp,
        duration: props.theme.transitions.duration.enteringScreen,
      })};
  }
  &.sidePanelShift {
    margin-right: ${props => props.sidePanelWidth}px;
    width: calc(
      100% - ${props => props.theme.spacing(7) + 1 + props.sidePanelWidth}px
    );
    &.appBarShift {
      width: calc(
        100% - ${props => props.drawerWidth + props.sidePanelWidth}px
      );
    }
  }
`;
export const ProfileSection = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: ${props => props.theme.spacing(2)}px;
  & span > a {
    color: ${props => props.theme.palette.common.graySubtitle};
    & > svg {
      font-size: 20px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    gap: 12px;
  }
  & .mainMenu {
    display: flex;
    align-items: flex-start;
    padding: 0px;
    &.userMenu {
      flex-direction: column;
      flex: none;
      order: 4;
      flex-grow: 0;
    }
    &.menuIcon {
      flex-direction: row;
      flex: none;
      flex-grow: 0;
    }
    &.menuCores {
      flex-direction: row;
      flex: none;
      flex-grow: 0;
      order: 1;
      align-self: center;
    }
    &.menuSearch {
      flex-direction: column;
      flex: none;
      flex-grow: 0;
      order: 0;
      align-self: stretch;
    }
  }
`;
export const StyledUserMenu = styled(
  ({
    theme,
    children,
    ...other
  }: {
    theme: Theme;
    children: React.ReactNode;
  } & MenuProps) => <Menu {...other}>{children}</Menu>,
)`
  & li {
    & a {
      margin-left: ${props => props.theme.spacing(2)}px;
      color: ${props => props.theme.palette.text.primary};
    }
  }
`;
