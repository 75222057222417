/**
 *
 * Asynchronously loads the component for SampleModificationsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SampleModificationsPage = lazyLoad(
  () => import('./index'),
  module => module.SampleModificationsPage,
);
