import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { DetailRowRenderer } from 'types/DetailRow';
import { dateUtils } from 'utils/date-utils';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import { IInvoice } from './IInvoice';

export const getRows = (
  t: TFunction,
  settings: GlobalSettings,
  openChangeStatus?: () => void,
  openChangeInvoiceTemplate?: () => void,
) => {
  const currency = settings.GetString(AllowedSettings.ISOCurrencySymbol);
  const res: DetailRowRenderer<IInvoice>[] = [
    {
      Header: t(translations.InvoiceId),
      accessor: 'Id',
    },
    {
      accessor: 'Amount',
      Header: t(translations.Amount),
      Cell: ({ value }) => <>{currency + (value ?? 0).toFixed(2)}</>,
    },
    {
      accessor: 'Balance',
      Header: t(translations.InvoiceBalance),
      Cell: ({ value }) => <>{currency + (value ?? 0).toFixed(2)}</>,
    },
    {
      accessor: 'ServiceGroups',
      Header: t(translations.ServiceGroup),
      Cell: ({ value }) => (
        <Tooltip
          title={
            <ul>
              {value.map(v => (
                <li key={v.Id}>{v.Name}</li>
              ))}
            </ul>
          }
          arrow
        >
          <span>
            {value
              .slice(0, 3)
              .map(v => v.Name)
              .join(',')}
            {value.length > 3 && <span>, {value.length - 3} more</span>}
          </span>
        </Tooltip>
      ),
    },
    {
      accessor: 'UserGroup',
      Header: t(translations.UserGroup),
      Cell: attrib => (
        <>{!!attrib.original.UserGroupId ? attrib.value : t(translations.NA)}</>
      ),
    },
    {
      accessor: 'BudgetNumber',
      Header: t(translations.BudgetNumber),
      Cell: attrib => (
        <>{!!attrib.original.BudgetId ? attrib.value : t(translations.NA)}</>
      ),
    },
    {
      accessor: 'StatusName',
      Header: () => (
        <>
          {' '}
          {t(translations.Status)}{' '}
          {!!openChangeStatus ? (
            <Icon icon="pencil" onClick={openChangeStatus} />
          ) : undefined}
        </>
      ),
    },
    {
      accessor: 'CreatedBy',
      Header: t(translations.CreatedBy),
    },
    {
      accessor: 'CreatedAt',
      Header: t(translations.CreatedAt),
      Cell: ({ value }) => <>{dateUtils.shortDateTimeFormat(value)}</>,
    },
    {
      accessor: 'InvoiceTemplateName',
      Header: () => (
        <>
          {' '}
          {t(translations.InvoiceTemplate)}{' '}
          {!!openChangeInvoiceTemplate ? (
            <Icon icon="pencil" onClick={openChangeInvoiceTemplate} />
          ) : undefined}
        </>
      ),
      Cell: attrib => (
        <>
          {!!attrib.original.InvoiceTemplateId
            ? attrib.value
            : `Invoice #${attrib.original.Id} is missing a template`}
        </>
      ),
    },
    {
      accessor: 'Start',
      Header: t(translations.Period),
      Cell: ({ original }) => (
        <>
          {dateUtils.shortDateFormat(original.Start)} -{' '}
          {dateUtils.shortDateFormat(
            dateUtils.addDays(dateUtils.dateOrStringToDate(original.End), -1),
          )}
        </>
      ),
    },
    {
      accessor: 'LastSentToBilling',
      Header: t(translations.LastSentToBilling),
      Cell: ({ value }) => (
        <>
          {!!value ? dateUtils.shortDateTimeFormat(value) : t(translations.NA)}
        </>
      ),
    },
  ];
  return res;
};
