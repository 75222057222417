/**
 *
 * auto generated interface for Default.EmailType
 *
 */

export enum EmailType {
  Invoice = 0,
  Request = 1,
  Reservation = 2,
  User = 3,
  PaymentLetter = 4,
  Budget = 5,
  BudgetComment = 6,
  OfflineService = 7,
  Eln = 8,
  Alert = 9,
  DataStore = 10,
  SAP = 11,
  Equipment = 12,
  Asset = 13,
  TrainingSession = 14,
  ServiceGroup = 15,
  BudgetExpirement = 16,
  InstrumentNotifications = 17,
  WorkflowBooking = 18,
  Cage = 19,
  InquiryAlert = 20,
  UserGroup = 21,
  LoanDesk = 22,
  Training = 23,
  RequestComment = 24,
  Downtime = 25,
  ExternalBillingError = 26,
  GenerateInvoice = 27,
  InstituteQuarter = 28,
  RequestMilestones = 29,
  Custom = 30,
  Subscription = 31,
}
export type EmailTypeUnion = keyof typeof EmailType;
export const EmailTypeUnionArray: Array<EmailTypeUnion> = [
  'Invoice',
  'Request',
  'Reservation',
  'User',
  'PaymentLetter',
  'Budget',
  'BudgetComment',
  'OfflineService',
  'Eln',
  'Alert',
  'DataStore',
  'SAP',
  'Equipment',
  'Asset',
  'TrainingSession',
  'ServiceGroup',
  'BudgetExpirement',
  'InstrumentNotifications',
  'WorkflowBooking',
  'Cage',
  'InquiryAlert',
  'UserGroup',
  'LoanDesk',
  'Training',
  'RequestComment',
  'Downtime',
  'ExternalBillingError',
  'GenerateInvoice',
  'InstituteQuarter',
  'RequestMilestones',
  'Custom',
  'Subscription',
];
