/**
 *
 * Asynchronously loads the component for AnimalsListPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AnimalsListPage = lazyLoad(
  () => import('./index'),
  module => module.AnimalsListPage,
);
