import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = props =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        //padding: '0px 0px 0px 1px',
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.grayComponentsLight}`,
        boxSizing: 'border-box',
        borderRadius: 8,
      },
      header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        //padding: '1px 0px',
        width: '100%',
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.common.grayComponentsLight}`,
        boxSizing: 'border-box',
        borderRadius: '8px 8px 0px 0px',
      },
      headerControls: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: 0,
        width: '100%',
        flexWrap: 'wrap',
      },
      button: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        border: 'none',
        borderRadius: 0,
        boxShadow: 'inset -1px 0px 0px #E0E5EC',
        color: theme.palette.common.blackRegular,
      },
      text: {
        paddingLeft: theme.spacing(1),
        width: '100%',
        minHeight: 32,
        maxHeight: 300,
        overflowY: 'auto',
      },
    }),
  );
