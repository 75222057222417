/**
 *
 * Asynchronously loads the component for CreateNewCasePage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CreateNewCasePage = lazyLoad(
  () => import('./index'),
  module => module.CreateNewCasePage,
);
