/**
 *
 * Popper
 *
 */
import * as React from 'react';
import {
  ClickAwayListener,
  createStyles,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Popper as MuiPopper } from '@material-ui/core';
import { Box } from '../basic/Wrappers/Box';
import { PopperTitle } from './PopperTitle';
import clsx from 'clsx';
import { useIsMobile } from 'app/hooks/useIsMobile';

export interface PopperProps {
  id?: string;
  title: string;
  open: boolean;
  anchorEl?: EventTarget & HTMLDivElement;
  children: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  closeTitle?: string;
  clickAway?: boolean;
  clickAwayFunc?: (e: any) => void;
  minWidth?: string | number;
  maxWidth?: string | number;
}
/**
 * A Popper with consistent title + close interface
 */
export const Popper = React.forwardRef<PopperProps, any>(function Popper(
  props,
  ref,
) {
  const {
    id,
    title,
    open,
    anchorEl,
    children,
    onClose,
    className,
    closeTitle,
    clickAway,
    clickAwayFunc,
    minWidth,
    maxWidth,
    ...other
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const { checkIsMobile, isLandscape } = useIsMobile();
  const isMobile = checkIsMobile();
  const handleClickAway = e => {
    if (clickAway) {
      if (e.target.nodeName === 'BODY' && e.type === 'click') {
        return;
      }
      if (!!clickAwayFunc) {
        clickAwayFunc(e);
      } else {
        onClose(e);
      }
    }
  };
  const classes = useStyles({
    isMobile: isMobile,
    minWidth: minWidth,
    maxWidth: maxWidth,
  })(props);

  return (
    <MuiPopper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      ref={ref}
      container={document.fullscreenElement ?? document.body}
      // placement={isMobile ? 'bottom' : 'bottom-start'}
      className={clsx(classes.root, className, {
        [classes.rootMobile]: isMobile && !isLandscape,
        [classes.rootMobileHorizontal]: isMobile && isLandscape,
      })}
      {...other}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper className={classes.poperPaper} elevation={1} ref={ref}>
          <PopperTitle
            title={props.title}
            closeButtonTitle={props.closeTitle}
            onClose={props.onClose}
          />
          <Box className={classes.poperRoot}>{props.children}</Box>
        </Paper>
      </ClickAwayListener>
    </MuiPopper>
  );
});

const useStyles = props =>
  makeStyles<Theme, PopperProps>((theme: Theme) =>
    createStyles({
      root: {
        // https://github.com/mui/material-ui/issues/18905
        zIndex: theme.zIndex.modal,
      },
      rootMobile: {
        width: `100vw`,
        height: 'calc(100% - 32px)',
        // long content of the popper overflows popper box on mobile
        overflow: 'auto',
        marginTop: theme.spacing(4),
      },
      rootMobileHorizontal: {
        width: '100%',
        height: '100%',
        // long content of the popper overflows popper box on mobile
        overflow: 'auto',
        //marginTop: theme.spacing(4),
      },
      poperPaper: {
        height: '100%',
        minWidth: props.minWidth
          ? props.minWidth
          : props.isMobile
          ? '100%'
          : '500px',
        maxWidth: props.maxWidth
          ? props.maxWidth
          : props.isMobile
          ? '100%'
          : '700px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 24,
        gap: 16,
        boxShadow: '0px 2px 18px rgba(8, 24, 41, 0.16)',
        maxHeight: '100vh',
        overflow: 'hidden',
      },
      poperTitle: {},
      poperRoot: {
        width: '100%',
      },
      selectTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 8px',
        justifyContent: 'space-between',
        width: '100%',
        '&>label': {
          width: '100%',
          textAlign: 'center',
        },
      },
      selectContent: {
        marginTop: theme.spacing(2),
        boxShadow: 'inset 0px 1px 0px #E0E5EC',
      },
    }),
  );
