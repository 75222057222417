import { PayloadAction } from '@reduxjs/toolkit';
import { IBillingActionsDto } from 'app/pages/BillingActions/BillingActionsPage/IBillingActionsDto';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { billingActionsDetailsSaga } from './saga';
import {
  //BillingActionsDetailsGlobalState,
  BillingActionsDetailsState,
  BillingActionsDetailsQStringParameters,
  BillingActionsState,
  ConvertModelToEntityBillingActionsDetails,
} from './types';

export const initialState: BillingActionsState = {
  processing: false,
  createState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  updateState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
};

const slice = createSlice({
  name: 'billingActionsDetail',
  initialState,
  reducers: {
    initBillingActionsDetails(
      state,
      action: PayloadAction<BillingActionsDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initUpdateBillingActionsDetails(
      state,
      action: PayloadAction<BillingActionsDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initBillingActionsDetails_Success(
      state,
      action: PayloadAction<IBillingActionsDto>,
    ) {
      state.processing = false;
      state.createState.data = ConvertModelToEntityBillingActionsDetails(
        action.payload,
      );
    },
    initUpdateBillingActionsDetails_Success(
      state,
      action: PayloadAction<IBillingActionsDto>,
    ) {
      state.processing = false;
      state.updateState.data = ConvertModelToEntityBillingActionsDetails(
        action.payload,
      );
    },
    initBillingActionsDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    initUpdateBillingActionsDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    //====================================================================
    resetUpdateBillingActionsDetailsState(state, action: PayloadAction) {
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    resetCreateBillingActionsDetailsState(state, action: PayloadAction) {
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    //====================================================================
    updateBillingActionsDetails(
      state,
      action: PayloadAction<{
        current: BillingActionsDetailsState;
        original: BillingActionsDetailsState | undefined;
      }>,
    ) {
      state.updateState.processing = true;
      state.updateState.completed = undefined;
      state.updateState.hasErrors = undefined;
    },
    updateBillingActionsDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.updateState.processing = false;
      state.updateState.hasErrors = action.payload.hasErrors;
      state.updateState.completed = true;
    },
    updateBillingActionsDetails_Error(state, action: PayloadAction<any>) {
      state.updateState.processing = false;
      state.updateState.completed = true;
      state.updateState.hasErrors = true;
    },
    //====================================================================
    createBillingActionsDetails(
      state,
      action: PayloadAction<BillingActionsDetailsState>,
    ) {
      state.createState.processing = true;
      state.createState.completed = undefined;
      state.createState.hasErrors = undefined;
    },
    createBillingActionsDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.createState.processing = false;
      state.createState.hasErrors = action.payload.hasErrors;
      state.createState.completed = true;
    },
    createBillingActionsDetails_Error(state, action: PayloadAction<any>) {
      state.createState.processing = false;
      state.createState.completed = true;
      state.createState.hasErrors = true;
    },
  },
});

export const { actions: billingActionsDetailsActions } = slice;

export const useBillingActionsDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: billingActionsDetailsSaga });
  return { actions: slice.actions };
};
