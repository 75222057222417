import * as Excel from 'exceljs';
import fileDownload from 'js-file-download';

export interface ColumnTemplate {
  key: string;
  name: string;
  customData?: (v: any) => string;
}

export class ExportToExcel {
  book: Excel.Workbook;
  sheet: Excel.Worksheet;
  constructor(sheetName: string) {
    this.book = new Excel.Workbook();
    this.sheet = this.book.addWorksheet(sheetName);
  }
  public AddWorkBook(): Excel.Workbook {
    return new Excel.Workbook();
  }
  public AddWorkSheet(
    workBook: Excel.Workbook,
    sheetName: string,
  ): Excel.Worksheet {
    return workBook.addWorksheet(sheetName);
  }
  public SetWorkSheetHeader(columns: string[], workSheet?: Excel.Worksheet) {
    const sheet: Excel.Worksheet =
      workSheet !== undefined ? workSheet : this.sheet;
    sheet.columns = columns.map(e => ({
      header: e,
      key: e,
      width: 30,
    }));
  }
  public InitWorksheetHeader(n: number, workSheet?: Excel.Worksheet) {
    const sheet: Excel.Worksheet =
      workSheet !== undefined ? workSheet : this.sheet;
    var columns: { key: string; width: number }[] = [];
    for (let i = 0; i < n; i++) {
      columns.push({
        key: i.toString(),
        width: 30,
      });
    }
    sheet.columns = columns;
  }
  public AddWorkSheetHeader(columns: string[], workSheet?: Excel.Worksheet) {
    const sheet: Excel.Worksheet =
      workSheet !== undefined ? workSheet : this.sheet;
    sheet.addRow(columns);
  }
  public AddWorkSheetRow(workSheet?: Excel.Worksheet) {
    const sheet: Excel.Worksheet =
      workSheet !== undefined ? workSheet : this.sheet;
    sheet.addRow('');
  }
  public AddWorkSheetData<T>(
    data: T[],
    columns?: ColumnTemplate[],
    workSheet?: Excel.Worksheet,
  ) {
    const sheet: Excel.Worksheet =
      workSheet !== undefined ? workSheet : this.sheet;
    if (data.length > 0) {
      // Add header
      if (columns !== undefined) {
        const columnNames = this.GetObjectNames(data[0], columns);
        this.AddWorkSheetHeader(columnNames, workSheet);
      }
      // Add rows
      data.forEach(e => {
        sheet.addRow(this.GetObjectValues(e, columns));
      });
    }
  }
  public SetWorkSheetData<T>(
    data: T[],
    columns?: ColumnTemplate[],
    workSheet?: Excel.Worksheet,
  ) {
    if (data.length > 0) {
      // Add header
      const columnNames = this.GetObjectNames(data[0], columns);
      this.SetWorkSheetHeader(columnNames, workSheet);
      // Add rows
      this.AddWorkSheetData<T>(data, columns, workSheet);
    }
  }
  public async ExportToExcel(
    fileName: string,
    workBook?: Excel.Workbook,
  ): Promise<any> {
    const book: Excel.Workbook = workBook !== undefined ? workBook : this.book;
    try {
      return await book.xlsx
        .writeBuffer()
        .then(buffer => {
          fileDownload(buffer, fileName + '.xlsx');
        })
        .catch(err => {
          throw err;
        });
    } catch (err) {
      throw err;
    }
  }
  public GetObjectNames(obj: {}, columns?: ColumnTemplate[]): string[] {
    var result: string[];
    if (columns !== undefined) {
      result = columns.map(e => e.name);
    } else {
      result = Object.keys(obj).map(function (key) {
        return key;
      });
    }
    return result;
  }
  public GetObjectValues(obj: {}, columns?: ColumnTemplate[]) {
    var result: {}[];
    if (columns !== undefined) {
      result = columns.map(function (column) {
        if (column.customData !== undefined) {
          return column.customData(obj[column.key]);
        } else {
          return obj[column.key];
        }
      });
    } else {
      result = Object.keys(obj).map(function (key) {
        return obj[key];
      });
    }
    return result;
  }
}
