import { Entity } from 'types/common';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { selectConnectedSettings } from '../slice/selectors';

import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';

export interface UserNameListenerProps<T> {
  userName: Entity<string> | null;
  userGroup: Entity<string> | null;
  services: Entity<number>[];
  user: AuthenticatedUser | undefined;
  globalSettings: GlobalSettingsType;
  isEdit: boolean;
  startTime: Date | string;
  endTime?: Date | string;
  setUserGroup: (value: Entity<string> | null) => void;
  setBudget: (value: Entity<number> | null) => void;
  values?: T;
  forceDisabled?: boolean;
}
export function UserNameListener<T>(props: UserNameListenerProps<T>) {
  const {
    userName,
    services,
    isEdit,
    userGroup,
    // startTime,
    // endTime,
    globalSettings,
    //user,
    setUserGroup,
    forceDisabled,
  } = props;
  const serviceSettings = useSelector(selectConnectedSettings);
  const ReservationUserGroupGroupByBudgetUserGroup = React.useMemo(() => {
    return serviceSettings.budgetVisible
      ? globalSettings.reservationUserGroupGroupByBudgetUserGroup
      : false;
  }, [
    globalSettings.reservationUserGroupGroupByBudgetUserGroup,
    serviceSettings.budgetVisible,
  ]);
  // const fetchBudget = async () => {
  //   try {
  //     const budgets = await loadBudgets(
  //       restrictedBudgetFilter(
  //         serviceSettings.budgetLimitedByUsers || false,
  //         serviceSettings.isBudgetsWithoutUserGroup || false,
  //         serviceSettings.isServiceAdmin
  //           ? serviceSettings.ReservationUserGroupGroupByBudgetUserGroup
  //             ? undefined
  //             : userGroup?.Id
  //           : user?.ActiveUserGroup?.Id,
  //         undefined,
  //         services as IServiceTypeFilterDto[],
  //         dateUtils.toDate(startTime),
  //         !!endTime ? dateUtils.toDate(endTime) : undefined,
  //         serviceSettings.isServiceAdmin
  //           ? userName === null
  //             ? undefined
  //             : userName.Id
  //           : user?.Id,
  //         globalSettings.defaultBudgetEnabled,
  //         globalSettings.userDefaultBudgetEnabled,
  //         globalSettings.hideNonDefaultBudgets,
  //       ),
  //     )(null);
  //     return budgets.value;
  //   } catch {
  //     return [];
  //   }
  // };
  const [initilized, setInitilized] = React.useState(!forceDisabled);
  React.useEffect(() => {
    if (!initilized) {
      if (services.length > 0 && !isEdit) {
        //Budget Change
        //   if (serviceSettings.budgetVisible && !isEdit) {
        //     if (userName !== null) {
        //       (async () => {
        //         const budgets = await fetchBudget();
        //         if (budgets.length > 0) {
        //           if (
        //             globalSettings.defaultBudgetEnabled ||
        //             budgets.length === 1
        //           ) {
        //             setBudget(budgets[0]);
        //             if (
        //               serviceSettings.ReservationUserGroupGroupByBudgetUserGroup
        //             ) {
        //               setUserGroup({
        //                 Id: budgets[0].UserGroupId,
        //                 Name: budgets[0].UserGroupName,
        //               } as Entity<string>);
        //             }
        //           } else {
        //             setBudget(null);
        //           }
        //         } else {
        //           setBudget(null);
        //         }
        //       })();
        //     } else {
        //       setBudget(null);
        //     }
        //   } else {
        //     if (userName !== null && userGroup === null && !isEdit) {
        //       setUserGroup({
        //         Id: (userName as IUserFilterDto).UserGroupId,
        //         Name: (userName as IUserFilterDto).UserGroupName,
        //       } as Entity<string>);
        //     }
        //   }
        if (userName !== null) {
          if (
            !ReservationUserGroupGroupByBudgetUserGroup &&
            userGroup === null
          ) {
            setUserGroup({
              Id: (userName as IUserFilterDto).UserGroupId,
              Name: (userName as IUserFilterDto).UserGroupName,
            } as Entity<string>);
          } else {
            // setUserGroup({
            //   Id: (userName as IUserFilterDto).UserGroupId,
            //   Name: (userName as IUserFilterDto).UserGroupName,
            // } as Entity<string>);
            if (!serviceSettings.ReservationUserGroupGroupByBudgetUserGroup) {
              setUserGroup({
                Id: (userName as IUserFilterDto).UserGroupId,
                Name: (userName as IUserFilterDto).UserGroupName,
              } as Entity<string>);
            }
          }
        }
      }
    }
    setInitilized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  return null;
}
