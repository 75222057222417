import { RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';

const selectRouterSlice = (state: RootState) => {
  return state.router;
};
export const selectRouterLocation = createSelector(
  [selectRouterSlice],
  state => state?.location,
);
export const selectRouterAction = createSelector(
  [selectRouterSlice],
  state => state?.action,
);
export const selectRouterSearch = createSelector(
  [selectRouterLocation],
  state => state?.search,
);
export const selectRouterSearchParams = createSelector(
  [selectRouterSearch],
  state => new URLSearchParams(state),
);
export const selectRouterPathname = createSelector(
  [selectRouterLocation],
  state => state?.pathname,
);
