/**
 *
 * CustomFormField
 *
 */
import React from 'react';
import { CustomFormFieldProps } from 'app/components/CustomForm/CustomFormFields';
import { FormParagraph } from 'app/components/Forms/FormParagraph';
import parse from 'html-react-parser';
export function CustomFormParagraph({
  formField,
  ...props
}: CustomFormFieldProps) {
  return (
    <FormParagraph
      {...props}
      label={formField.Label}
      key={formField.Id}
      fullWidth
      html={
        formField.Description !== null ? parse(formField.Description) : <></>
      }
    />
  );
}
