import {
  IPriceSheetPeriodDto,
  PricePeriodQueryParams,
  PricePeriodsResponse,
} from 'app/pages/PriceSheetPeriodDetails/Details/slice/types';
import { httpClient } from './HttpClient';

export const PriceSheetApi = {
  initPeriodDetails: (payload: PricePeriodQueryParams) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/PriceSheetPeriod/InitPeriods`;
    return httpClient.post(url, data);
  },
  insertPricePeriod: (period: IPriceSheetPeriodDto) => {
    const url = '/api/odata/v4/PriceSheetPeriod';
    return httpClient.post(url, period);
  },
  updatePricePeriod: (period: IPriceSheetPeriodDto, rowId: number) => {
    const url = '/api/odata/v4/PriceSheetPeriod';
    return httpClient.put<any, PricePeriodsResponse>(
      url + '(' + rowId + ')',
      period,
    );
  },
  deletePricePeriod: (Id: number) => {
    const url = `/api/odata/v4/PriceSheetPeriod/${Id}`;
    return httpClient.deleteCustom<PricePeriodsResponse>(url);
  },
};
