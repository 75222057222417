/**
 *
 * PublicAssetsLayout
 *
 */
import { useIsMobile } from 'app/hooks/useIsMobile';
import * as React from 'react';

import { useStyles } from './styles';
export interface PublicAssetsLayoutProps {
  children: React.ReactNode;
}

export function PublicAssetsLayout(props: PublicAssetsLayoutProps) {
  const { children } = props;
  const { checkIsMobile } = useIsMobile();
  const isMobile = checkIsMobile();
  const classes = useStyles({ isMobile: isMobile })();
  return <div className={classes.root}>{children}</div>;
}
