import { CustomFormControl } from 'app/components/CustomForm/CusomFormControl';
import * as React from 'react';
import { CustomFormTypeEnum } from 'enums/CustomFormType';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { ICustomFormDto } from 'api/odata/generated/entities/ICustomFormDto';
import { useDispatch, useSelector } from 'react-redux';
import { useReservationSlice } from '../slice';
import { selectAsyncChangeLoading } from '../slice/selectors';

export interface ReservationCustomFormProps {
  multiple?: boolean;
  customForms: ICustomFormDto[];
  readonly?: boolean;
  isAdmin?: boolean;
  customFormRef: React.MutableRefObject<any>;
  formIsValid?: (isValid: boolean) => void;
  formValues: IFormValueDto[];
  disabled?: boolean;
  validationEnabled?: boolean;
  saveFormValues: (
    values: IFormValueDto[],
    files: IFormFileValue[],
    originalFiles: IFormFileValue[],
  ) => void;
}

export function ReservationCustomForm(props: ReservationCustomFormProps) {
  const {
    multiple,
    customForms,
    readonly,
    isAdmin,
    customFormRef,
    formIsValid,
    formValues,
    saveFormValues,
    disabled,
    validationEnabled = true,
  } = props;
  const dispatch = useDispatch();
  const { actions } = useReservationSlice();
  const mandatoryForms = React.useMemo(() => {
    return customForms.filter(f => f.Mandatory === true);
  }, [customForms]);
  const optionalForms = React.useMemo(() => {
    return customForms.filter(f => !f.Mandatory);
  }, [customForms]);
  const isAsyncChangeLoading = useSelector(selectAsyncChangeLoading);
  const selectedForms = React.useMemo(() => {
    return customForms.filter(f =>
      formValues.some(c => c.CustomFormId === f.Id),
    );
  }, [customForms, formValues]);
  const formValuesForms = React.useMemo(() => {
    return formValues.length > 0
      ? [
          ...new Set(
            formValues.map(f => f.CustomFormId || 0).filter(f => f > 0),
          ),
        ]
      : [];
  }, [formValues]);
  const onLoadCompleted = () => {
    if (isAsyncChangeLoading) {
      dispatch(actions.setAsyncChangeLoading(false));
    }
  };
  const onLoadStarted = () => {
    dispatch(actions.setAsyncChangeLoading(true));
  };
  const formId = React.useMemo(() => {
    return selectedForms.length > 0
      ? selectedForms[0].Id
      : formValuesForms.length > 0
      ? formValuesForms[0]
      : mandatoryForms.length > 0
      ? mandatoryForms[0].Id
      : optionalForms.length > 0
      ? optionalForms[0].Id
      : 0;
  }, [formValuesForms, mandatoryForms, optionalForms, selectedForms]);
  return customForms.length > 0 || formId > 0 ? (
    <React.Fragment>
      {multiple ? (
        <CustomFormControl
          CustomFormId={formId}
          readonly={readonly}
          isAdmin={isAdmin}
          values={formValues}
          FormType={CustomFormTypeEnum.Reservation}
          submitFormRef={customFormRef}
          formIsValid={formIsValid}
          saveFormValues={saveFormValues}
          disabled={disabled}
          onLoadCompleted={onLoadCompleted}
          onLoadStarted={onLoadStarted}
          validationEnabled={validationEnabled}
        />
      ) : (
        <CustomFormControl
          CustomFormId={formId}
          readonly={readonly}
          isAdmin={isAdmin}
          values={formValues}
          FormType={CustomFormTypeEnum.Reservation}
          submitFormRef={customFormRef}
          formIsValid={formIsValid}
          saveFormValues={saveFormValues}
          disabled={disabled}
          onLoadCompleted={onLoadCompleted}
          onLoadStarted={onLoadStarted}
          validationEnabled={validationEnabled}
        />
      )}
    </React.Fragment>
  ) : (
    <React.Fragment>{''}</React.Fragment>
  );
}
