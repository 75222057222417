import { TFunction } from 'i18next';
import { Entity } from 'types/common';
import { useTranslation } from 'react-i18next';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';

export enum RUN_TYPES {
  StandardRun = 'RUN_TYPEStandardRun',
  MassSpec = 'RUN_TYPEMassSpec',
  Labeling = 'RUN_TYPELabeling',
  Fractioning = 'RUN_TYPEFractioning',
}
export type RunTypesUnion = keyof typeof RUN_TYPES;
export function getRunTypeOptions(t: TFunction) {
  return Object.entries(RUN_TYPES).map(([key, value], index) => ({
    Id: key as RunTypesUnion,
    Name: t(value),
  }));
}
export function getRunTypeValue(t: TFunction, runtype?: RunTypesUnion) {
  if (runtype === undefined) {
    return undefined;
  }
  return getRunTypeOptions(t).find(item => item.Id === runtype)?.Name;
}

export interface RunTypesSelectOptions
  extends Omit<AutocompletePickerProps<Entity<RunTypesUnion>>, 'loadData'> {}

export const getOptions = (t: TFunction) => {
  return Object.entries(RUN_TYPES).map(([key, value], index) => ({
    Id: key as RunTypesUnion,
    Name: t(value),
  }));
};
export function RunTypePicker(props: RunTypesSelectOptions) {
  const { t } = useTranslation();
  const loadData = () => {
    const options = getOptions(t);
    return Promise.resolve<Entity<RunTypesUnion>[]>(options);
  };
  return <AutocompletePicker loadData={loadData} {...props} />;
}
