import {
  FormGroupProps as MuiFormGroupProps,
  FormGroup as MuiFormGroup,
} from '@material-ui/core';
import * as React from 'react';
import clsx from 'clsx';

export interface FormGroupProps extends MuiFormGroupProps {
  withInfo?: boolean;
  boxed?: boolean;
  boxedChecked?: boolean;
  boxedError?: boolean;
}
const FormGroup = (props: FormGroupProps) => {
  const {
    withInfo,
    boxed,
    boxedChecked,
    boxedError,
    className,
    ...other
  } = props;
  return (
    <MuiFormGroup
      className={clsx(className, {
        withInfo: withInfo,
        boxed: boxed,
        boxedChecked: boxedChecked,
        boxedError: boxedError,
      })}
      {...other}
    />
  );
};

export default FormGroup;
