import { IRow } from 'app/components/BasicTable/IRow';
import { toRootedURL } from 'utils/url-utils';
import { ISampleRun } from '../../SamplesListPage/slice/types';
import { openExportLink } from 'utils/url-utils';

export const ExportRunDetailsSamples = <TRow extends IRow>(
  id: number,
  exportConfigurableColumns: Array<keyof TRow> | undefined,
): Promise<void> => {
  if (exportConfigurableColumns !== undefined) {
    try {
      const params = {
        $format: 'application/xlsx',
        $select: exportConfigurableColumns.join(','),
      };
      const samples_url = `/api/odata/v4/sampleruns(${id})/Samples`;
      const exportUrl = toRootedURL(samples_url, params);
      return openExportLink(exportUrl);
    } catch (error) {
      console.error('doRunDetailsExport failed', error);
      return new Promise<void>((resolve, reject) => {
        resolve();
      });
    }
  } else
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
};
export const ExportForImportRunDetailsSamples = <TRow extends IRow>(
  id: number,
  rows: ISampleRun[],
  exportConfigurableColumns: Array<keyof TRow> | undefined,
): Promise<void> => {
  if (rows?.length === 0)
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  if (exportConfigurableColumns !== undefined) {
    const params = {
      $format: 'application/xlsx',
      $filter: `Id in (${rows.map(f => f.Id).join(',')})`,
      $select: exportConfigurableColumns.join(','),
    };
    const samples_url = `/api/odata/v4/sampleruns(${id})/Samples`;
    const exportUrl = toRootedURL(samples_url, params);
    return openExportLink(exportUrl);
  } else
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
};
export const DownloadTemplateRunDetailsSamples = <TRow extends IRow>(
  id: number,
  exportConfigurableColumns: Array<keyof TRow> | undefined,
): Promise<void> | undefined => {
  if (exportConfigurableColumns !== undefined) {
    try {
      const params = {
        $format: 'application/xlsx',
        $top: 0,
        $select: exportConfigurableColumns.join(','),
      };
      const samples_url = `/api/odata/v4/sampleruns(${id})/Samples`;
      const exportUrl = toRootedURL(samples_url, params);
      return openExportLink(exportUrl);
    } catch (error) {
      console.error('doRunDetailsExport failed', error);
    }
  }
};
