/**
 *
 * Asynchronously loads the component for Expenses Page
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Expenses = lazyLoad(
  () => import('./index'),
  module => module.ExpensesPage,
);
