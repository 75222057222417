import { dateUtils } from 'utils/date-utils';
import { DatePickerValueProps } from '../index';

/**
 * Optionally truncates time part to be used in date-only picker
 * @param props
 * @returns
 */
export function truncateTime(
  props: Pick<DatePickerValueProps, 'truncateTime'> & { value: Date },
) {
  return props.truncateTime ? dateUtils.truncateTime(props.value) : props.value;
}
