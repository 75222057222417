import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { bookitColors } from 'styles/colors/bookitColors';

export const useStyles = props =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          boxShadow: `inset 0 0 2px ${theme.palette.common.grayBorders}`,
          borderRadius: '6px',
          backgroundColor: theme.palette.common.grayBorders,
        },
      },
      paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 0,
        gap: theme.spacing(2),
        background: theme.palette.common.white,
        boxShadow: 'unset', //'0px 4px 20px rgba(39, 51, 65, 0.2)',
        borderRadius: theme.spacing(1),
        width: props.isMobile ? 'auto' : '360px',
        maxHeight: props.isMobile ? '100%' : '400px',
      },
      popoverPaper: {
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100% - 32px)',
        maxWidth: 'calc(100% - 0px)',
      },
      mainContentAndScroll: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: 0,
        gap: theme.spacing(2.5),
        //borderRadius: theme.spacing(1),
        width: '100%',
      },
      mainContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(1),
        gap: theme.spacing(1),
        width: '100%',
      },
      searchAndHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(1),
        width: '100%',
        padding: '4px 8px',
      },
      controlButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: theme.spacing(2),
        width: '100%',
      },
      coresAndServices: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: theme.spacing(2, 1, 0),
        gap: theme.spacing(6),
        width: '100%',
      },
      coreCheks: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: 0,
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        // paddingBottom: '36px',
        width: '100%',
      },
      check: {
        flex: '1 1 49%',
        display: 'flex',
        gap: theme.spacing(2),
      },
      scrollPaddings: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(1, 0),
        gap: theme.spacing(1.25),
        width: '13px',
      },
      scroll: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '7px 3px 100px',
        gap: theme.spacing(1.25),
        background: theme.palette.common.white,
        boxShadow: 'inset 1px 0px 0px #E3E8EF',
        borderRadius: theme.spacing(0, 1, 1, 0),
      },
    }),
  );
export const useListStyles = props =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        fontSize: '14px',
        gap: 8,
      },
      listItemText: {
        [theme.breakpoints.up('sm')]: {
          display: 'flex',
        },
        [theme.breakpoints.down('sm')]: {
          overflow: 'hidden',
        },
      },
      primary: {
        [theme.breakpoints.up('sm')]: {
          flexGrow: 1,
        },
        display: 'inline-block',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      avatar: {
        color: theme.palette.getContrastText(
          bookitColors.grayscale.grayBorders,
        ),
        backgroundColor: theme.palette.common.grayDividers,
      },
      secondary: {},
    }),
  );
