/**
 *
 * DateTimeLabel
 *
 */
import * as React from 'react';
import { dateUtils } from 'utils/date-utils';

export interface DateTimeLabelProps {
  value: Date | string | null;
}

export function DateTimeLabel(props: DateTimeLabelProps) {
  return <>{dateUtils.shortDateTimeFormat(props.value)}</>;
}
