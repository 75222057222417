import { makeStyles, Theme } from '@material-ui/core';
import Box, { BoxProps } from '@material-ui/core/Box';
import { createStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { selectSnackBarIndex } from '../../slice/selectors';
import clsx from 'clsx';

export interface SnackWrapperProps extends BoxProps {
  children: React.ReactNode;
}

export function SnackWrapper(props: SnackWrapperProps) {
  const upSnackbarIndex = useSelector(selectSnackBarIndex);
  const classes = useStyles();
  return (
    <Box className={clsx({ [classes.zindex301]: upSnackbarIndex })}>
      {props.children}
    </Box>
  );
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    zindex301: {
      '& > div': {
        zIndex: 1301,
      },
    },
  }),
);
