import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { IFormFieldOptionDto } from 'api/odata/generated/entities/IFormFieldOptionDto';
import { DisplayTypes } from 'api/odata/generated/enums/DisplayTypes';
import { UserSelectionTypeUnion } from 'api/odata/generated/enums/UserSelectionType';
import { createOptions } from 'app/components/CustomForm/CustomFormFields/CustomFormDropDown';
import { Dictionary, keyBy } from 'lodash';
import { assertExhaustive } from 'utils/assertExhaustive';
import {
  ColumnTypesArray,
  CustomFormColumnTypes,
  IServiceRequestTableColumnModel,
} from '../types';

export class ServiceRequestTableColumn
  implements IServiceRequestTableColumnModel {
  AssignParent(result: Dictionary<ServiceRequestTableColumn>): void {
    const parentFieldName = this.getParentFieldName();
    if (parentFieldName !== null) {
      const parent = result[parentFieldName];
      if (parent !== undefined) {
        this.Parent = parent;
        (parent.Children = parent.Children ?? []).push(this);
      }
    }
  }

  IsColumn: true = true;
  Options?: IFormFieldOptionDto[] | undefined;
  ColumnType: CustomFormColumnTypes;
  isRequired: boolean;
  Id: number;
  Label: string;
  Index: number;
  Required: 'Optional' | 'User' | 'Admin' | 'Conditional';
  ParentId: number | null;
  SizeLimit: number;
  Description: string | null;
  DisplayType: DisplayTypes;
  IsMultiple: boolean;
  CopyPrevValues: boolean;
  CustomRoles: string | null;
  UserSelectionType: UserSelectionTypeUnion | null;
  FixedRoles: string | null;

  Parent: ServiceRequestTableColumn | null = null;
  Children: Array<ServiceRequestTableColumn> | null = null;

  constructor(columnDto: IFormFieldDto, isAdmin?: boolean) {
    this.Id = columnDto.Id;
    // see null check above in filter
    this.ColumnType = (columnDto.ColumnType as CustomFormColumnTypes)!;
    this.Description = columnDto.Description;
    this.DisplayType = columnDto.DisplayType;
    this.Index = columnDto.Index;
    this.IsColumn = true;
    this.IsMultiple = columnDto.IsMultiple;
    this.Label = columnDto.Label;
    this.ParentId = columnDto.ParentId;
    this.Required = columnDto.Required;
    this.SizeLimit = columnDto.SizeLimit;
    this.Options =
      columnDto.ColumnType === 'DropDownList'
        ? createOptions(columnDto)
        : undefined;
    this.isRequired = this.isColumnRequired({
      Required: columnDto.Required,
      isAdmin: isAdmin ?? false,
    });
    this.CopyPrevValues = columnDto.CopyPrevValues;
    this.CustomRoles = columnDto.CustomRoles;
    this.UserSelectionType = columnDto.UserSelectionType;
    this.FixedRoles = columnDto.FixedRoles;
  }

  static getFieldName(columnId: number) {
    return `column_${String(columnId)}`;
  }
  getFieldName() {
    return ServiceRequestTableColumn.getFieldName(this.Id);
  }
  getParentFieldName() {
    if (this.ParentId === null) {
      return null;
    }
    return ServiceRequestTableColumn.getFieldName(this.ParentId);
  }

  private isColumnRequired = (
    props: Pick<IFormFieldDto, 'Required'> & { isAdmin: boolean },
  ) => {
    switch (props.Required) {
      case 'Optional': //optional
        return false;
      case 'User': //required for user (all users)
        return true;
      case 'Admin': //required for admin
        return props.isAdmin;
      case 'Conditional':
        // not supported on columns/table
        return false;
      default:
        assertExhaustive(props.Required);
    }
  };
}

export class ServiceRequestTableColumnsCollection {
  columnsObj: Dictionary<ServiceRequestTableColumn>;
  constructor(columnsData, isAdmin: boolean, VisibleToAdminOnly?: boolean) {
    if (VisibleToAdminOnly === true && isAdmin !== true) {
      this.columnsObj = {};
      return;
    }
    const columns =
      columnsData
        ?.filter(f => f.Active === true)
        ?.filter(
          column =>
            column.IsColumn === true &&
            column.ColumnType !== null &&
            (ColumnTypesArray as string[]).includes(column.ColumnType),
        )
        ?.map(columnDto => new ServiceRequestTableColumn(columnDto, isAdmin)) ??
      [];
    const result = keyBy(columns, column => column.getFieldName());
    columns.forEach(column => column.AssignParent(result));
    this.columnsObj = result;
  }

  getArray() {
    return Object.values(this.columnsObj);
  }
  get(columnId: number) {
    return this.columnsObj[ServiceRequestTableColumn.getFieldName(columnId)];
  }
}
