import { capitalize } from 'lodash';
import { BookitDatePicker, BookitDatePickerProps } from '.';
import * as React from 'react';
import { dateUtils } from 'utils/date-utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CustomDate } from 'types/CustomDate';
import FormControl from '../Forms/FormControls/FormControl';
import FormHelperText from '../Forms/FormControls/FormHelperText';
import clsx from 'clsx';
import { InputLabel } from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { useUtils } from '@material-ui/pickers';
import { validateError } from '../DatePicker/utils/pickerDateValidate';
import { v4 as uuid } from 'uuid';

export interface BookitDateTimePickerProps extends BookitDatePickerProps {
  labelInline?: boolean;
}

export function BookitDateTimePicker({
  value,
  endTime,
  onChange,
  truncateTime,
  label,
  helperText,
  error,
  size,
  labelInline = false,
  dateLabel,
  timeLabel,
  maskIfEmpty,
  id = 'datetime_picker',
  ...props
}: BookitDateTimePickerProps) {
  const utils = useUtils();
  const inputLabelId = label && id ? `${id}-label` : undefined;
  const helperTextId = id ? `${id}-helper-text` : undefined;
  const dateId = !!id ? `${id}-date` : uuid();
  const timeId = !!id ? `${id}-time` : uuid();
  const dateName = !!props.name ? `${props.name}-date` : uuid();
  const timeName = !!props.name ? `${props.name}-time` : uuid();
  const sizeClass = `inputSize${capitalize(size ?? 'large')}`;
  const dateLabelInline = labelInline
    ? dateLabel ?? `${label} Date`
    : undefined;
  const timeLabelInline = labelInline
    ? timeLabel ?? `${label} Time`
    : undefined;
  const dateTimeValue = React.useMemo(() => {
    return value;
  }, [value]);
  const [dateValue, setDateValue] = React.useState<
    Date | string | null | undefined
  >(() => {
    if (dateTimeValue === null || dateTimeValue === undefined) {
      return null;
    } else {
      let parsedValue = dateUtils.dateOrStringToDate(dateTimeValue);
      return parsedValue === null ? null : dateUtils.truncateTime(parsedValue);
    }
  });
  const [timeValue, setTimeValue] = React.useState<
    Date | string | null | undefined
  >(() => {
    if (dateTimeValue === null || dateTimeValue === undefined) {
      return null;
    } else {
      return dateUtils.dateOrStringToDate(dateTimeValue);
    }
  });
  React.useEffect(() => {
    setTimeValue(() => {
      if (dateTimeValue === null || dateTimeValue === undefined) {
        return null;
      } else {
        return dateUtils.dateOrStringToDate(dateTimeValue);
      }
    });
    setDateValue(() => {
      if (dateTimeValue === null || dateTimeValue === undefined) {
        return null;
      } else {
        let parsedValue = dateUtils.dateOrStringToDate(dateTimeValue);
        return parsedValue === null
          ? null
          : dateUtils.truncateTime(parsedValue);
      }
    });
  }, [dateTimeValue]);

  const handleChange = React.useCallback(
    (date: MaterialUiPickersDate, inpValue?: string | null): void => {
      let error = validateError(
        date,
        {
          maxDate: props.maxDate,
          minDate: props.minDate,
          disablePast: props.disablePast,
          disableFuture: props.disableFuture,
          maxDateMessage: props.maxDateMessage,
          minDateMessage: props.minDateMessage,
          invalidDateMessage: props.invalidDateMessage,
          strictCompareDates: props.strictCompareDates,
        },
        utils,
      );
      if (!!error) {
        setDateError(error);
      } else {
        if (!(date?.toString() === 'Invalid Date')) {
          if (props.required && (date === null || date === undefined)) {
            setDateError(`${label} is a required field`);
            if (!!props.onError) {
              props.onError(`${label} is a required field`, date);
            }
          } else {
            onChange(
              date === null || date === undefined
                ? null
                : new CustomDate(date, 'complete'),
              inpValue,
            );
          }
        }
      }
      // onChange(
      //   date === null || date === undefined
      //     ? null
      //     : new CustomDate(date, 'complete'),
      //   inpValue,
      // );
    },
    [label, onChange, props, utils],
  );
  const handleDateChange = React.useCallback(
    (displayValue: MaterialUiPickersDate): void => {
      const finalValue =
        displayValue === null
          ? null
          : displayValue === undefined
          ? null
          : displayValue;
      setDateValue(finalValue);
      if (finalValue !== null) {
        if (timeValue === null || timeValue === undefined) {
          setTimeValue(dateUtils.startOfDay(finalValue));
          if (props.saveOnSelect) {
            handleChange(dateUtils.startOfDay(finalValue));
          }
        } else {
          let time = dateUtils.dateOrStringToDate(timeValue);
          let val = dateUtils.set(dateUtils.truncateTime(finalValue), {
            hours: time === null ? 0 : time.getHours(),
            minutes: time === null ? 0 : time.getMinutes(),
            seconds: time === null ? 0 : time.getSeconds(),
          });
          handleChange(val);
        }
      } else {
        if (props.saveOnSelect) {
          setTimeValue(null);
          handleChange(null);
        } else {
          if (timeValue === null || timeValue === undefined) {
            handleChange(null);
          }
        }
      }
    },
    [handleChange, props.saveOnSelect, timeValue],
  );
  const handleTimeChange = React.useCallback(
    (displayValue: MaterialUiPickersDate): void => {
      const finalValue =
        displayValue === null
          ? null
          : displayValue === undefined
          ? null
          : displayValue;
      setTimeValue(finalValue);
      if (finalValue !== null) {
        if (dateValue !== null && dateValue !== undefined) {
          let val = dateUtils.set(dateUtils.truncateTime(dateValue), {
            hours: finalValue.getHours(),
            minutes: finalValue.getMinutes(),
            seconds: finalValue.getSeconds(),
          });
          handleChange(val);
        }
      } else {
        if (dateValue === null || dateValue === undefined) {
          handleChange(null);
        }
      }
    },
    [dateValue, handleChange],
  );
  const [dateError, setDateError] = React.useState<React.ReactNode | undefined>(
    undefined,
  );
  const [timeError, setTimeError] = React.useState<React.ReactNode | undefined>(
    undefined,
  );
  const validationError = React.useMemo(() => {
    return dateError || timeError || undefined;
  }, [dateError, timeError]);
  const handleOnError = React.useCallback(
    (error: React.ReactNode, value: MaterialUiPickersDate | ParsableDate) => {
      if (!!props.onError) {
        props.onError(validationError, value);
      }
    },
    [props, validationError],
  );
  const onDateError = React.useCallback(
    (error: React.ReactNode, value: MaterialUiPickersDate | ParsableDate) => {
      setDateError(error);
      if (!!props.onError) {
        handleOnError(error, value);
      }
    },
    [handleOnError, props.onError],
  );
  const onTimeError = React.useCallback(
    (error: React.ReactNode, value: MaterialUiPickersDate | ParsableDate) => {
      setTimeError(error);
      if (!!props.onError) {
        handleOnError(error, value);
      }
    },
    [handleOnError, props.onError],
  );

  return (
    <FormControl
      className={clsx('datetime-picker', sizeClass, {
        withLabel: !!label && labelInline,
      })}
      disabled={props.disabled}
      error={error || Boolean(validationError)}
      fullWidth={props.fullWidth}
      required={props.required}
      variant={props.inputVariant || 'filled'}
      size={'medium'}
    >
      <div className="datetime-picker-inputs">
        {label && !labelInline && (
          <InputLabel
            htmlFor={id}
            id={inputLabelId}
            className={clsx('datetime-picker', sizeClass, {
              noLabelInline: labelInline === false,
            })}
            required={props.required}
            error={error || Boolean(validationError)}
            disabled={props.disabled}
          >
            {label}
          </InputLabel>
        )}
        <BookitDatePicker
          inputVariant={props.inputVariant || 'filled'}
          size={size}
          margin={props.margin}
          disableFuture={props.disableFuture}
          disablePast={props.disablePast}
          label={dateLabelInline}
          ariaLabel={label as string}
          value={dateTimeValue}
          placeholder={props.datePlaceholder}
          required={props.required}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          id={props.dateId || dateId}
          name={props.dateName || dateName}
          clearable={props.clearable}
          onChange={handleDateChange}
          minDate={props.minDate}
          maxDate={props.maxDate}
          strictCompareDates={props.strictCompareDates}
          onError={onDateError}
          onBlur={props.onBlur}
          className={'datetime-date-picker'}
          variant={'date'}
          saveOnSelect={props.saveOnSelect}
          maskIfEmpty={maskIfEmpty}
        />
        <BookitDatePicker
          inputVariant={props.inputVariant || 'filled'}
          variant={'time'}
          size={size}
          margin={props.margin}
          minutesStep={props.minutesStep}
          id={props.timeId || timeId}
          name={props.timeName || timeName}
          clearable={props.clearable}
          onChange={handleTimeChange}
          onBlur={props.onBlur}
          onError={onTimeError}
          label={timeLabelInline}
          ariaLabel={label as string}
          value={timeValue}
          placeholder={props.timePlaceholder}
          strictCompareDates={props.strictCompareDates}
          required={props.required}
          disabled={props.disabled}
          // autoFocus={props.autoFocus}
          minDate={props.minDate}
          maxDate={props.maxDate}
          className={'datetime-time-picker'}
          saveOnSelect={props.saveOnSelect}
          maskIfEmpty={maskIfEmpty}
        />
      </div>
      {(!!helperText || !!validationError) && (
        <FormHelperText
          id={helperTextId}
          className={clsx('datetime-picker', sizeClass)}
          disabled={props.disabled}
          error={error || Boolean(validationError)}
          required={props.required}
        >
          {helperText || validationError}
        </FormHelperText>
      )}
    </FormControl>
  );
}
