/**
 *
 * Popover
 *
 */
import * as React from 'react';
import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Popover as MuiPopover } from '@material-ui/core';
import { DetectIsMobile } from 'utils/mobileDetect';
import { Box } from 'app/components/basic/Wrappers/Box';
import clsx from 'clsx';
import { PopperTitle } from '../Popper/PopperTitle';

export interface PopperProps {
  id?: string;
  title: React.ReactNode;
  open: boolean;
  anchorEl?: EventTarget & HTMLDivElement;
  children: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLButtonElement | Document>;
  className?: string;
  closeTitle?: string;
}

export function Popover(props: PopperProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const isMobile = DetectIsMobile();
  const classes = useStyles({ isMobile: isMobile });

  return (
    <MuiPopover
      id={props.id}
      open={props.open}
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      container={document.fullscreenElement ?? document.body}
      className={clsx(classes.root, props.className, {
        [classes.rootMobile]: isMobile,
      })}
    >
      <Paper className={classes.selectPaper} elevation={1}>
        <PopperTitle
          title={props.title}
          closeButtonTitle={props.closeTitle}
          onClose={props.onClose}
        />
        <Box className={classes.selectRoot}>{props.children}</Box>
      </Paper>
    </MuiPopover>
  );
}

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      // https://github.com/mui/material-ui/issues/18905
      zIndex: 1300,
    },
    rootMobile: {
      width: `100vw`,
      height: 'calc(100% - 90px)',
      // long content of the Popover overflows Popover box on mobile
      overflow: 'auto',
    },
    selectPaper: {
      height: '100%',
    },
    selectRoot: {
      padding: theme.spacing(2),
    },
    selectTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 8px',
      justifyContent: 'space-between',
      width: '100%',
      '&>label': {
        width: '100%',
        textAlign: 'center',
      },
    },
    selectContent: {
      marginTop: theme.spacing(2),
      boxShadow: 'inset 0px 1px 0px #E0E5EC',
    },
  }),
);
