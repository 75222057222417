/**
 *
 * Asynchronously loads the component for SubscriptionTypesPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SubscriptionTypesPage = lazyLoad(
  () => import('./index'),
  module => module.SubscriptionTypesPage,
);
