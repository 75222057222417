/**
 *
 * Asynchronously loads the component for PricePeriods Page
 *
 */
import { lazyLoad } from 'utils/loadable';

export const PricePeriodsPagePage = lazyLoad(
  () => import('./index'),
  module => module.PricePeriods,
);
