import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';
import { publicAssetActions as actions } from '.';
import { BrowseByUnitItemDto } from './types';
import { routerActions } from 'connected-react-router';
import { toQueryString } from 'utils/url-utils';
import { selectRouterSearchParams } from 'app/slice/router-selectors';

function* dosetSelectedItem(
  action: PayloadAction<{
    item: BrowseByUnitItemDto | undefined;
    search: string;
    init?: boolean;
  }>,
) {
  const path = '/Assets/Public';
  const currentSearch = new URLSearchParams(action.payload.search);

  if (!action.payload.init) {
    yield put(
      routerActions.push({ pathname: path, search: currentSearch.toString() }),
    );
  }
  yield put(actions.setSelectedItem_Success(action.payload));
}
function* dosetSelectedItems(
  action: PayloadAction<{
    items: BrowseByUnitItemDto[];
    search: string;
    init?: boolean;
  }>,
) {
  const path = '/Assets/Public';
  const currentSearch = new URLSearchParams(action.payload.search);
  //const newSearch = getSearchFromItems(action.payload.items, currentSearch);
  if (!action.payload.init) {
    yield put(
      routerActions.push({ pathname: path, search: currentSearch.toString() }),
    );
  }

  yield put(actions.setSelectedItems_Success(action.payload));
}
function* dosetAssetPublicFilters(
  action: PayloadAction<Record<string, any> | undefined>,
) {
  const currentSearch = yield select(selectRouterSearchParams);

  const path = '/Assets/Public';
  const newSearch = !!action.payload
    ? toQueryString(action.payload)
    : currentSearch;
  for (const key of newSearch.keys()) {
    if ((currentSearch as URLSearchParams).has(key)) {
      const filterValue = newSearch.get(key);
      if (filterValue !== null) {
        let currValue = currentSearch.get(key);
        if (filterValue !== currValue) {
          currentSearch.set(key, filterValue);
        }
      } else {
        currentSearch.delete(key);
      }
    } else {
      const filterValue = newSearch.get(key);
      if (filterValue !== null) {
        currentSearch.set(key, filterValue);
      }
    }
  }
  yield put(
    routerActions.push({
      pathname: path,
      search: currentSearch.toString(),
    }),
  );
  yield put(actions.setAssetPublicFilters_success(action.payload));
}
export function* publicAssetSaga() {
  yield takeLatest(actions.setSelectedItem.type, dosetSelectedItem);
  yield takeLatest(actions.setSelectedItems.type, dosetSelectedItems);
  yield takeLatest(actions.setAssetPublicFilters.type, dosetAssetPublicFilters);
}
