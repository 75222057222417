import * as React from 'react';
import { selectAppSettings, selectglobalSettings } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { ChargeOnlineBudgetsButton } from './ChargeOnlineBudgetsButton';
import { GenerateInvoicesButton } from './GenerateInvoicesButton';
import { PayButton } from './PayButton';
import { GenerateInvoicesAndSendToSap } from './GenerateInvoicesAndSendToSap';
import { dateUtils } from 'utils/date-utils';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ReactActions } from 'app/components/ReactActions';
import { AllowedSettings } from 'utils/globalSettings';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { PageActionRenderer2 } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { IInvoiceBudgetBalanceDto } from 'api/odata/generated/entities/IInvoiceBudgetBalanceDto';

export interface usePageActionsProps {
  endDate: Date | null;
  isMobile: boolean;
}
export const usePageActions = ({ endDate, isMobile }: usePageActionsProps) => {
  const { t } = useTranslation();
  const appSettings = useSelector(selectAppSettings);
  const globalSettings = useSelector(selectglobalSettings);
  const { newDate } = useSystemDate();
  const disableActionButtons =
    endDate === null ||
    dateUtils.truncateTime(endDate) > dateUtils.truncateTime(newDate())
      ? true
      : undefined;
  const disabledTitle = disableActionButtons
    ? t(translations.EndDateMustBeSetToTodayOrEarlier)
    : undefined;
  const pageActions = React.useMemo(() => {
    if (endDate == null) {
      return [];
    }
    const accumulateInvoices = globalSettings.GetBoolean(
      AllowedSettings[AllowedSettings.AccumulateNewChargesToExistingInvoice],
    );
    // TODO: provide properly typed actions: PageActionRenderer
    const actions: PageActionRenderer2<IInvoiceBudgetBalanceDto>[] = [];

    if (accumulateInvoices) {
      actions.unshift((dataCount, onRefresh, appliedFilters, selectedRows) => (
        <GenerateInvoicesButton
          disabled={disableActionButtons}
          disabledTitle={disabledTitle}
          endDate={endDate}
          selected={selectedRows ?? []}
        />
      ));
    }
    if (IsModuleEnabled(appSettings, KnownModules.WS_Billing)) {
      actions.unshift(() => (
        <ChargeOnlineBudgetsButton
          disabled={disableActionButtons}
          disabledTitle={disabledTitle}
        />
      ));
      actions.unshift((dataCount, onRefresh, appliedFilters, selectedRows) => (
        <GenerateInvoicesAndSendToSap
          endDate={endDate}
          selected={selectedRows ?? []}
          disabled={disableActionButtons}
          disabledTitle={disabledTitle}
        />
      ));
    }
    if (accumulateInvoices) {
      actions.push((dataCount, onRefresh, appliedFilters, selectedRows) => (
        <PayButton
          endDate={endDate}
          selected={selectedRows ?? []}
          disabled={disableActionButtons}
          disabledTitle={disabledTitle}
        />
      ));
    }
    if (isMobile) {
      return [
        (dataCount, onRefresh, appliedFilters, selectedRows) => (
          <ReactActions
            id="foobar"
            items={actions.map((item, index) => (
              <span key={index}>
                {item(dataCount, onRefresh, appliedFilters, selectedRows)}
              </span>
            ))}
          />
        ),
      ];
    }
    return actions;
  }, [
    appSettings,
    disableActionButtons,
    disabledTitle,
    endDate,
    globalSettings,
    isMobile,
  ]);
  return pageActions;
};
