import * as React from 'react';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import FormControl from '../Forms/FormControls/FormControl';
import FormControlLabel from '../Forms/FormControls/FormControlLabel';
import FormHelperText from '../Forms/FormControls/FormHelperText';
import FormGroup from '../Forms/FormControls/FormGroup';
import { Toggle, ToggleProps } from '../BasicInputs/Toggle';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { capitalize } from 'lodash';

export interface BasicToggleProps
  extends Omit<ToggleProps, 'onChange' | 'danger'> {
  label?: string;
  error?: string;
  helperText?: string;
  info?: React.ReactNode;
  fullWidth?: boolean;
  name?: string;
  boxed?: boolean;
  boldLabel?: boolean;
  maxLabelWidth?: string | number;
  onChange?: (
    value: boolean,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

export function BasicToggle(props: BasicToggleProps) {
  const {
    label,
    error,
    helperText,
    info,
    name,
    onChange,
    boxed,
    fullWidth,
    boldLabel,
    maxLabelWidth = 25,
    size,
    ...other
  } = props;
  const [isChecked, setIsChecked] = React.useState<boolean>(
    props.checked ?? false,
  );
  const sizeClass = `toggleSize${capitalize(size ?? 'medium')}`;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked as boolean;
    if (onChange !== undefined) {
      onChange(value, event);
    }
    setIsChecked(value);
  };
  const withInfoLabel = React.useMemo(() => {
    return !!label && label.length > maxLabelWidth;
  }, [label, maxLabelWidth]);
  const Label = withInfoLabel ? (
    <Tooltip title={<>{label}</>}>
      <span>{label}</span>
    </Tooltip>
  ) : (
    <React.Fragment>{label}</React.Fragment>
  );
  const baseLabelStyle: React.CSSProperties = React.useMemo(() => {
    return withInfoLabel
      ? { maxWidth: `${maxLabelWidth}ch`, gap: 12 }
      : { gap: 12 };
  }, [maxLabelWidth, withInfoLabel]);
  return (
    <FormControl
      error={error !== undefined}
      fullWidth={fullWidth}
      boxed={boxed}
      className={sizeClass}
    >
      <FormGroup
        row={true}
        withInfo={info !== undefined}
        boxed={boxed}
        boxedChecked={isChecked}
        boxedError={error !== undefined}
        className={sizeClass}
      >
        <FormControlLabel
          style={baseLabelStyle}
          label={Label}
          withInfo={info !== undefined}
          boxed={boxed}
          boxedChecked={isChecked}
          boxedError={error !== undefined}
          boldLabel={boldLabel}
          className={sizeClass}
          control={
            <Toggle
              name={name}
              color={props.color || 'primary'}
              danger={error !== undefined}
              onChange={handleChange}
              checked={isChecked}
              className={sizeClass}
              {...other}
            />
          }
        />
        {info && <InfoIcon title={info} />}
      </FormGroup>
      {((!!error && error !== '') || (!!helperText && helperText !== '')) && (
        <FormHelperText
          error={error !== undefined}
          boxed={boxed}
          className={sizeClass}
        >
          {error || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
