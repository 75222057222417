import { PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from 'api/HttpClient';
import { call, put, takeLatest } from 'redux-saga/effects';
import { InvoiceDetailActions as actions } from '.';

export function* doGetInvoice(action: PayloadAction<number>) {
  yield put(actions.setLoading(true));
  try {
    const invoice = yield call(
      httpClient.get,
      '/api/odata/v4/InvoiceDetails(' + action.payload + ')',
      {
        $expand: 'ServiceGroups',
      },
    );
    yield put(actions.setInvoice(invoice));
    yield put(actions.setLoading(false));
  } catch {
    yield put(actions.notFound());
  }
}

export function* InvoiceDetailsSaga() {
  yield takeLatest(actions.getInvoice.type, doGetInvoice);
}
