import {
  FormHelperTextProps as MuiFormHelperTextProps,
  FormHelperText as MuiFormHelperText,
} from '@material-ui/core';
import clsx from 'clsx';

export interface FormHelperTextProps extends MuiFormHelperTextProps {
  boxed?: boolean;
  shiftRight?: boolean;
}
const FormHelperText = (props: FormHelperTextProps) => {
  const { shiftRight, boxed, ...other } = props;
  return (
    <MuiFormHelperText
      {...other}
      className={clsx(props.className, {
        boxed: boxed,
        shiftRight: shiftRight,
      })}
    />
  );
};

export default FormHelperText;
