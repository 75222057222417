import { ToolbarProps as MuiToolbarProps, useTheme } from '@material-ui/core';
import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { AlertContentType, BasicAlert } from 'app/components/BasicAlert';
import useExpandedSideBarState from 'app/hooks/useExpandedSideBarState';
import { useIsMobile } from 'app/hooks/useIsMobile';
import clsx from 'clsx';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ICanRaiseBasicTableRefreshEvent } from '../../ControlledTable/ControlledTableProps';
import { RefreshIcon } from './RefreshIcon';
import {
  TableToolbarHeader,
  TableToolbarHeaderProps,
} from './TableToolbarHeader';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { StyledToolbar } from './styled';

export interface TableToolbarProps
  extends React.PropsWithChildren<MuiToolbarProps>,
    Omit<TableToolbarHeaderProps, 'isMobile'>,
    ICanRaiseBasicTableRefreshEvent {
  alertContent?: AlertContentType;
  showAlert?: boolean;
  titleIcon?: React.ReactNode;
  showMenu?: boolean;
  loading?: boolean;
  compact?: boolean;
  /**
   * Make a title section render content as flex column (in case when need to show additional description under Title)
   */
  titleAsColumn?: boolean;
  customExpandMenu?: () => void;
  showGlobalSearchIcon?: boolean;
  toggleGlobalFilter?: () => void;
}

export const TableToolbar = React.memo(function TableToolbar({
  screenName,
  screenNameVariant = 'H1',
  alertContent,
  showAlert,
  titleIcon,
  showMenu = true,
  variant = 'regular',
  onRefresh,
  compact,
  customExpandMenu,
  customScreenName,
  showGlobalSearchIcon,
  toggleGlobalFilter,
  titleAsColumn,
  ...props
}: TableToolbarProps) {
  const { t } = useTranslation();
  const { checkIsMobile } = useIsMobile();
  const isMobile = checkIsMobile();
  const { toggle } = useExpandedSideBarState();

  const handleSidebarOpen = () => {
    if (!!customExpandMenu) {
      customExpandMenu();
    } else {
      toggle();
    }
  };
  const handleRefresh = () => {
    const event = { reloadConfigurableColumns: true };
    onRefresh?.(event);
  };
  const theme = useTheme();
  return (
    <StyledToolbar
      theme={theme}
      disableGutters
      variant={variant}
      className={clsx('toolbar', { shortview: isMobile || compact === true })}
    >
      {isMobile && showMenu && (
        <IconButton
          edge="start"
          variant="textGray"
          aria-label="toggle menu"
          onClick={handleSidebarOpen}
        >
          <MenuIcon />
        </IconButton>
      )}
      <div
        className={clsx('titleSection', {
          titleAndIconMobile: isMobile || compact,
          titleAsColumn: titleAsColumn,
        })}
      >
        <div className={'title'}>
          <TableToolbarHeader
            variant={variant}
            isMobile={isMobile}
            screenName={screenName}
            customScreenName={customScreenName}
            compact={compact}
            screenNameVariant={screenNameVariant}
          />

          {onRefresh !== undefined && (
            <RefreshIcon
              onRefresh={handleRefresh}
              loading={props.loading}
              title={t(translations.RefreshContent)}
            />
          )}
        </div>
        <div>{titleIcon}</div>
      </div>
      {!isMobile && !compact && showAlert === true && alertContent && (
        <div className={'messageContainer'}>
          <BasicAlert content={alertContent} />
        </div>
      )}

      <div className={'actionsContainer'}>
        {
          <React.Fragment>
            {showGlobalSearchIcon && (
              <IconButton
                variant="ghost"
                shape="square"
                size="small"
                aria-label="toggle menu"
                onClick={() => !!toggleGlobalFilter && toggleGlobalFilter()}
              >
                <Icon icon="magnifying-glass" />
              </IconButton>
            )}
            {props.children}
          </React.Fragment>
        }
      </div>
    </StyledToolbar>
  );
});
