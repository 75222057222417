/**
 *
 * DateLabel
 *
 */
import * as React from 'react';
import { dateUtils } from 'utils/date-utils';

export interface DateLabelProps {
  value: Date | string | null;
}

export function DateLabel(props: DateLabelProps) {
  return <>{dateUtils.shortDateFormat(props.value)}</>;
}
