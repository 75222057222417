import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { translations } from 'locales/translations';
import { AuthenticatedUser, IIsAdminOfParams } from 'types/AuthenticatedUser';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import {
  FieldHandler,
  FormListener,
  SubmittingHandler,
} from 'app/components/Forms/FormRender/FormRenderer';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { ServiceTypeSchema } from 'app/components/Forms/Schemas';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import {
  OtherServiceDetailsState,
  ServiceChangeStateParameters,
} from '../slice/types';
import { useOfflineServiceStateSlice } from '../slice';
import {
  selectConnectedInitState,
  selectFieldHandler,
  selectInventoryBatchEditable,
  selectServiceChangeState,
  selectServiceHasError,
} from '../slice/selectors';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { ServiceType } from 'enums/ServiceTypes';
import { FormOfflineServiceTypePicker } from 'app/components/Forms/FormOfflineServiceTypePicker';
import { OfflineServiceRelatedFilter } from 'app/components/pickers/AutocompletePickers/OfflineServiceTypePicker';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import {
  selectConnectedFiltersData,
  selectConnectedSettings,
} from 'app/components/Forms/FormConnectedFilters/slice/selectors';
import { useConnectedFiltersSlice } from 'app/components/Forms/FormConnectedFilters/slice';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { ConnectedFiltersControl } from 'app/components/Forms/FormConnectedFilters/ConnectedFiltersControl';
import { FormNumberField } from 'app/components/Forms/FormNumberField';
import { dateUtils } from 'utils/date-utils';
import { FormInventoryBatchPicker } from 'app/components/Forms/FormInventoryBatchPicker';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { Button } from 'app/components/BasicButtons/Button';
import { BatchesByServiceTypeFilter } from 'app/components/pickers/AutocompletePickers/InventoryBatchesPicker';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
export interface OfflineFormProps {
  onSubmit: (item: OtherServiceDetailsState) => void;
  initialValues: OtherServiceDetailsState;
  processing?: boolean;
  bindSubmitForm: any;
  isEdit: boolean;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  innerFormRef: React.RefObject<FormikProps<OtherServiceDetailsState>>;
  connectedFiltersFormRef: React.MutableRefObject<any>;
  globalSettings: GlobalSettingsType;
  editCreatable?: boolean;
  onBatchClick: (
    batch: IInventoryBatchDto | null,
    serviceTypeId: number,
  ) => void;
}
export const OfflineForm = React.memo(function OfflineForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isEdit,
  isAdmin,
  user,
  connectedFiltersFormRef,
  globalSettings,
  innerFormRef,
  editCreatable,
  onBatchClick,
}: OfflineFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();
  const { actions: connectedFiltersActions } = useConnectedFiltersSlice();
  //const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const fieldHandler = useSelector(selectFieldHandler);
  const hasError = useSelector(selectServiceHasError);
  const serviceSettings = useSelector(selectConnectedSettings);
  const connectedData = useSelector(selectConnectedFiltersData);
  const connectedInit = useSelector(selectConnectedInitState);
  const serviceChangeState = useSelector(selectServiceChangeState);
  const batchRequested = useSelector(selectInventoryBatchEditable);
  const readonly = !isAdmin && isEdit;
  const [submitting, setSubmitting] = React.useState<boolean | undefined>(
    undefined,
  );
  const resetSubmitting = () => {
    setSubmitting(true);
  };
  const handleSubmit = (values: OtherServiceDetailsState) => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(values);
    }
  };
  const resetFieldHandler = () => {
    dispatch(actions.resetValueHandler());
  };

  /// Local State ///
  const [serviceChanged, setServiceChanged] = React.useState(false);
  const [quantityChanged, setQuantityChanged] = React.useState(false);
  const [batchChanged, setBatchChanged] = React.useState(false);
  const [allIsValid, setAllIsValid] = React.useState<boolean>(true);
  const [serviceTypeSelected, setServiceTypeSelected] = useAsyncExtendedState<
    IOfflineServiceFilterDto | undefined
  >(initialValues.ServiceType ?? undefined);
  const minStep = React.useMemo(() => {
    return !!serviceTypeSelected
      ? serviceTypeSelected.IntQuantityOnly === true
        ? 1
        : 0.01
      : 1;
  }, [serviceTypeSelected]);
  const step = React.useMemo(() => {
    return !!serviceTypeSelected
      ? serviceTypeSelected.IntQuantityOnly === true
        ? 1
        : 0.01
      : 1;
  }, [serviceTypeSelected]);
  // const defaultQuantity = React.useMemo(() => {
  //   return initialValues.Quantity ?? serviceTypeSelected?.DefaultQuantity;
  // }, [initialValues.Quantity, serviceTypeSelected]);
  const maxStep = 9999;
  const inputProps = {
    step: step,
    min: minStep,
    max: maxStep,
    'aria-label': 'Counter',
  };
  const IsAdminOfService = React.useMemo(() => {
    return user?.IsAdminOf({
      ServiceGroupId: serviceTypeSelected?.ServiceGroupId,
      ServiceId: serviceTypeSelected?.Id,
      ServiceTypeId: ServiceType.Offline,
    } as IIsAdminOfParams);
  }, [serviceTypeSelected, user]);

  const handleFormChange = React.useCallback(
    (
      values: OtherServiceDetailsState,
      isValid,
      dirty,
      setValue,
      setTouched,
      validateField,
      setError,
      validate,
    ) => {
      if (serviceChanged) {
        dispatch(
          connectedFiltersActions.extendSettingsState({
            services:
              values.ServiceType === null
                ? []
                : [
                    {
                      Id: values.ServiceType.Id,
                      Name: values.ServiceType.Name,
                      ServiceGroupId: values.ServiceType.ServiceGroupId,
                      ServiceTypeId: ServiceType.Offline,
                      Active: true,
                      BudgetsTurnedOn: values.ServiceType.BudgetsTurnedOn,
                      HideProject: values.ServiceType.HideProjects,
                    } as IServiceTypeFilterDto,
                  ],
            globalSettings: globalSettings,
            settings: serviceSettings,
            isEdit: isEdit,
            data: connectedData,
          }),
        );
        if (
          values.ServiceType !== null &&
          !isEdit &&
          connectedData?.User !== null
        ) {
          const parameters = {
            ServiceTypes: [values.ServiceType.Id],
            ReservationId: values.ReservationId || null,
            UsageId: values.UsageId || null,
            BookedBy: connectedData?.User?.Id || null,
            ADGroup: connectedData?.UserGroup?.Id || null,
            Start: dateUtils.formatISO(
              dateUtils.dateOrStringToDate(
                connectedData?.StartTime || new Date(),
              ),
            ),
            BudgetId: connectedData?.Budget?.Id || null,
            FundingTypeId: connectedData?.FundingType?.Id || 0,
          } as ServiceChangeStateParameters;
          dispatch(actions.initServiceChangeStateData(parameters));
        }
        setServiceTypeSelected(values.ServiceType ?? undefined);
        if (
          values.ServiceType?.DefaultQuantity !== null &&
          values.ServiceType?.DefaultQuantity !== undefined
        ) {
          dispatch(
            actions.setAnyValue({
              fieldKey: 'Quantity',
              fieldValue: values.ServiceType.DefaultQuantity,
            }),
          );
        }
        setServiceChanged(false);
      }
      if (quantityChanged) {
        setQuantityChanged(false);
      }
      if (batchChanged) {
        setBatchChanged(false);
      }
    },
    [
      serviceChanged,
      quantityChanged,
      batchChanged,
      dispatch,
      connectedFiltersActions,
      globalSettings,
      serviceSettings,
      isEdit,
      connectedData,
      setServiceTypeSelected,
      actions,
    ],
  );
  const validateForms = React.useCallback((isValid: boolean) => {
    setAllIsValid(isValid);
  }, []);
  /// refs
  // const formRef = React.useRef<FormikProps<ReservationDetailsState> | null>(
  //   null,
  // );
  /// useEffects
  React.useEffect(() => {
    if (innerFormRef.current) {
      innerFormRef.current.validateForm();
    }
  }, [innerFormRef]);

  React.useEffect(() => {
    if (batchRequested !== undefined) {
      dispatch(
        actions.setAnyValue({
          fieldKey: 'InventoryBatch',
          fieldValue: batchRequested,
        }),
      );
      dispatch(actions.setInventoryBatchEditable(undefined));
    }
  }, [actions, batchRequested, dispatch]);

  React.useEffect(() => {
    if (!!serviceChangeState && !editCreatable) {
      console.log('service settings: ', serviceSettings);
      if (!!serviceChangeState) {
        if (serviceSettings.budgetVisible && !isEdit) {
          dispatch(
            connectedFiltersActions.setConnectedValue({
              fieldKey: 'Budget',
              fieldValue: serviceChangeState.DefaultBudget,
            }),
          );
          if (serviceSettings.ReservationUserGroupGroupByBudgetUserGroup) {
            if (serviceChangeState.DefaultBudget !== null) {
              dispatch(
                connectedFiltersActions.setConnectedValue({
                  fieldKey: 'UserGroup',
                  fieldValue: {
                    Id: serviceChangeState.DefaultBudget.UserGroupId,
                    Name: serviceChangeState.DefaultBudget.UserGroupName,
                  },
                }),
              );
            }
          } else {
            if (
              connectedData?.UserGroup === null ||
              connectedData?.UserGroup === undefined
            ) {
              dispatch(
                connectedFiltersActions.setConnectedValue({
                  fieldKey: 'UserGroup',
                  fieldValue: {
                    Id:
                      (connectedData?.User as IUserFilterDto).UserGroupId ??
                      user?.ActiveUserGroup?.Id,
                    Name:
                      (connectedData?.User as IUserFilterDto).UserGroupName ??
                      user?.ActiveUserGroup?.Name,
                  },
                }),
              );
            }
          }
          if (
            serviceSettings.budgetExpirementVisible &&
            serviceChangeState.DefaultExperiment !== null
          ) {
            dispatch(
              connectedFiltersActions.setConnectedValue({
                fieldKey: 'BudgetExperiment',
                fieldValue: serviceChangeState.DefaultExperiment,
              }),
            );
          }
        } else {
          if (serviceSettings.isUserGroupCoordinator) {
            dispatch(
              connectedFiltersActions.setConnectedValue({
                fieldKey: 'UserGroup',
                fieldValue: {
                  Id: user?.ActiveUserGroup?.Id,
                  Name: user?.ActiveUserGroup?.Name,
                },
              }),
            );
          }
        }
      }
      dispatch(actions.resetServiceChangeState());
    }
  }, [
    actions,
    connectedData?.User,
    connectedData?.UserGroup,
    connectedFiltersActions,
    dispatch,
    editCreatable,
    isEdit,
    serviceChangeState,
    serviceSettings,
    user?.ActiveUserGroup?.Id,
    user?.ActiveUserGroup?.Name,
  ]);

  const computedSchema = React.useMemo(() => {
    const otherServiceSchema: Yup.SchemaOf<OtherServiceDetailsState> = Yup.object(
      {
        Id: Yup.number().notRequired().default(-1),
        ServiceType: ServiceTypeSchema.nullable().required(
          t(translations.err_OfflineServiceTypeRequired),
        ),
        DiscountFactor: Yup.number().nullable().notRequired().optional(),
        Remarks: Yup.string()
          .nullable()
          .label(t(translations.Remarks) as string),
        Quantity: Yup.number()
          .label(t(translations.Quantity) as string)
          .required(t(translations.err_Quantity_required))
          .nullable()
          .default(null)
          .max(maxStep)
          .min(minStep)
          .test(
            'empty',
            t(translations.err_Quantity_required) as string,
            (value, context) => {
              let currVal = (context as any).from[0].value.Quantity;
              if (
                currVal === undefined ||
                currVal === null ||
                isNaN(currVal) ||
                currVal === 0
              ) {
                return false;
              }
              return true;
            },
          )
          .test(
            'integerOnly',
            t(translations.err_IntQuantityOnly) as string,
            (value, context) => {
              let currVal = (context as any).from[0].value.Quantity;
              if (
                !!serviceTypeSelected &&
                serviceTypeSelected.IntQuantityOnly &&
                currVal % 1 !== 0
              ) {
                return false;
              }
              return true;
            },
          ),
        ReservationId: Yup.number().nullable().notRequired().optional(),
        UsageId: Yup.number().nullable().notRequired().optional(),
        SyncReservation: Yup.boolean().nullable().notRequired().optional(),
        SyncUsage: Yup.boolean().nullable().notRequired().optional(),
        InventoryBatch: (Yup.mixed() as Yup.SchemaOf<IInventoryBatchDto>)
          .nullable()
          .label(t(translations.Batch) as string),
        // ServiceDate: Yup.date()
        // .default(dateUtils.dateOrStringToDate(new Date()))
        // .label(t(translations.StartTime) as string)
        // .required(t(translations._errStartTimeRequired) as string),
        // Budget: Yup.mixed()
        // .label(t(translations.BudgetNumber) as string)
        // .nullable(true),
        // BudgetExperiment: Yup.mixed()
        // .label(t(translations.BudgetExperiment) as string)
        // .nullable(true),
        // BookedBy: Yup.mixed()
        //   .label(t(translations.User) as string)
        //   .required(() => t(translations.err_UserFieldRequired) as string),
        // UserGroup: Yup.mixed()
        //   .label(t(translations.UserGroup) as string)
        //   .nullable(true),
        // InstituteProject: Yup.mixed()
        //   .label(t(translations.InstituteProject) as string)
        //   .nullable(true),
        // ExternalCustomer: Yup.mixed()
        //   .label(t(translations.ExternalCustomer) as string)
        //   .nullable(true),
        // FundingType: Yup.mixed().label(t(translations.FundingType) as string),
        // PurchaseOrder: Yup.string()
        //   .label(t(translations.PurchaseOrder) as string)
        //   .nullable(true),
        // Reference: Yup.string()
        //   .label(t(translations.Reference) as string)
        //   .nullable(true),
      },
    );
    if (!!serviceTypeSelected && serviceTypeSelected.TrackInventory) {
      if (serviceTypeSelected?.NotLessThanZero) {
        // if (serviceTypeSelected.Inventory === 0) {
        //   otherServiceSchema.fields.Quantity.withMutation(schema =>
        //     schema
        //       .label(t(translations.Quantity) as string)
        //       .required(t(translations.err_Quantity_required))
        //       .default(null)
        //       .nullable()
        //       .max(maxStep)
        //       .min(minStep)
        //       .test(
        //         'empty',
        //         t(translations.err_Quantity_required) as string,
        //         (value, context) => {
        //           let currVal = context.from[0].value.Quantity;
        //           if (
        //             currVal === undefined ||
        //             currVal === null ||
        //             isNaN(currVal) ||
        //             currVal === 0
        //           ) {
        //             return false;
        //           }
        //           return true;
        //         },
        //       )
        //       .test(
        //         'minQuantWithZero',
        //         t(
        //           translations.NotAllowNegativeInventory_currentEqualsZero_err,
        //         ) as string,
        //         (value, context) => {
        //           let currVal = context.from[0].value.Quantity;
        //           let current = currVal as number;
        //           if (current < 1) {
        //             return false;
        //           }
        //           return true;
        //         },
        //       ),
        //   );
        // } else {
        //   otherServiceSchema.fields.Quantity.withMutation(schema =>
        //     schema
        //       .label(t(translations.Quantity) as string)
        //       .required(t(translations.err_Quantity_required))
        //       .max(maxStep)
        //       .min(minStep)
        //       .test(
        //         'empty',
        //         t(translations.err_Quantity_required) as string,
        //         (value, context) => {
        //           let currVal = context.from[0].value.Quantity;
        //           if (
        //             currVal === undefined ||
        //             currVal === null ||
        //             isNaN(currVal) ||
        //             currVal === 0
        //           ) {
        //             return false;
        //           }
        //           return true;
        //         },
        //       )
        //       .test(
        //         'minQuant',
        //         (t(
        //           translations.NotAllowNegativeInventory_currentMoreThanZero_err,
        //         ) as string).replace(
        //           '{0}',
        //           serviceTypeSelected.Inventory.toString(),
        //         ),
        //         (value, context) => {
        //           let currVal = context.from[0].value.Quantity;
        //           let current = currVal as number;
        //           if (serviceTypeSelected.Inventory - current < 0) {
        //             return false;
        //           }
        //           return true;
        //         },
        //       ),
        //   );
        // }
      }
      if (serviceTypeSelected?.InventoryBatchesEnabled) {
        otherServiceSchema.fields.InventoryBatch.withMutation(schema =>
          schema
            .label(t(translations.Batch) as string)
            .nullable()
            .required(t(translations.err_InventoryBatch_required) as string),
        );
      }
    }

    // if (budgetVisible === true) {
    //   otherServiceSchema.fields.Budget.withMutation(schema =>
    //     schema.required(t(translations.BudgetIsRequired) as string),
    //   );
    // }
    return otherServiceSchema;
  }, [minStep, serviceTypeSelected, t]);
  return (
    <>
      <Formik
        validationSchema={computedSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        innerRef={innerFormRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          if (allIsValid) {
            formikHelpers.validateForm(values).then(responseErrors => {
              if (!isEmpty(responseErrors)) {
                formikHelpers.setSubmitting(false);
                console.debug('errors', responseErrors);
              }
              // else if (errorMessages.length > 0) {
              //   console.debug('errors', errorMessages);
              //   formikHelpers.setSubmitting(false);
              // }
              else {
                console.debug('submitting', values);
                formikHelpers.setSubmitting(true);
                handleSubmit(values);
              }
            });
          } else {
            formikHelpers.setSubmitting(false);
          }
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            console.log('Reservation errors', formik.errors);
          }
          // if (newStatus && !statusUpdated) {
          //   formik.setFieldValue('Status', newStatus, true);
          //   statusUpdated = true;
          // }
          console.log('Reservation submitting', formik.isSubmitting);
          return (
            <React.Fragment>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['ServiceType']}
                />
                <FieldHandler
                  {...fieldHandler}
                  resetFieldState={resetFieldHandler}
                />
                <SubmittingHandler
                  value={hasError === true && submitting === undefined}
                  resetSubmitting={resetSubmitting}
                />
                <FormLeftSection
                // warningNodes={warningMessages}
                // infoNodes={infoMessages}
                // errors={errorMessages}
                // successNodes={successMessages}
                >
                  <FormFieldsSection
                    titleSection={t(translations.Details) as string}
                  >
                    <FormRow fullRow={true}>
                      <FormOfflineServiceTypePicker
                        fullWidth
                        id="ServiceTypeID"
                        name="ServiceType"
                        predicates={OfflineServiceRelatedFilter(
                          [],
                          undefined,
                          connectedData?.Budget?.Id,
                          connectedData?.Budget === null ||
                            connectedData?.Budget === undefined
                            ? undefined
                            : (connectedData?.Budget as IBudgetFilterDto)
                                .ServiceGroupServicesAllowed,
                        )}
                        urlType="base"
                        label={t(translations.Consumable)}
                        placeholder={t(translations.PleaseSelectServices)}
                        disabled={
                          formik.isSubmitting ||
                          isEdit ||
                          editCreatable === true
                        }
                        onChange={val => {
                          setServiceChanged(true);
                        }}
                      />
                    </FormRow>
                    <ConnectedFiltersControl
                      initialState={connectedInit}
                      submitFormRef={connectedFiltersFormRef}
                      isEdit={isEdit}
                      serviceTypeEnum={ServiceType.Offline}
                      serviceTypes={
                        !serviceTypeSelected
                          ? []
                          : [
                              {
                                Id: serviceTypeSelected.Id,
                                Name: serviceTypeSelected.Name,
                                Active: true,
                                BudgetsTurnedOn:
                                  serviceTypeSelected.BudgetsTurnedOn,
                                HideProject: serviceTypeSelected.HideProjects,
                                ServiceGroupId:
                                  serviceTypeSelected.ServiceGroupId,
                                ServiceTypeId:
                                  serviceTypeSelected.ServiceTypeId,
                              } as IServiceTypeFilterDto,
                            ]
                      }
                      globalSettings={globalSettings}
                      formIsValid={validateForms}
                      startTimeLabel={
                        t(translations.LogDateTimeLabel) as string
                      }
                      startLabelInline={true}
                      forceDisabled={editCreatable}
                    />
                    {!!serviceTypeSelected &&
                      serviceTypeSelected?.InventoryBatchesEnabled &&
                      IsAdminOfService &&
                      !isEdit && (
                        <React.Fragment>
                          <FormRow>
                            <FormInventoryBatchPicker
                              name="InventoryBatch"
                              id="batchId"
                              fullWidth
                              label={t(translations.InventoryBatches)}
                              disabled={formik.isSubmitting || isEdit}
                              predicates={BatchesByServiceTypeFilter(
                                serviceTypeSelected.Id,
                                true,
                              )}
                              onChange={val => {
                                setBatchChanged(true);
                              }}
                            />
                          </FormRow>
                          {formik.values.ServiceType !== null &&
                            formik.values.ServiceType !== undefined &&
                            IsAdminOfService && (
                              <React.Fragment>
                                {formik.values.InventoryBatch !== null &&
                                  formik.values.InventoryBatch !==
                                    undefined && (
                                    <Button
                                      variant="text"
                                      size="small"
                                      onClick={e =>
                                        onBatchClick(
                                          formik.values.InventoryBatch ?? null,
                                          formik.values.ServiceType?.Id ?? 0,
                                        )
                                      }
                                    >
                                      {t(translations.EditSelectedBatch)}
                                    </Button>
                                  )}
                                <Button
                                  variant="text"
                                  size="small"
                                  onClick={e =>
                                    onBatchClick(
                                      null,
                                      formik.values.ServiceType?.Id ?? 0,
                                    )
                                  }
                                >
                                  {t(translations.AddNewBatch)}
                                </Button>
                              </React.Fragment>
                            )}
                        </React.Fragment>
                      )}
                    <FormRow fullRow>
                      <FormNumberField
                        fullWidth
                        id="QuantityID"
                        name="Quantity"
                        noWarnOnNuN={true}
                        inputMode="decimal"
                        placeholder={t(translations.Quantity)}
                        label={t(translations.Quantity)}
                        disabled={formik.isSubmitting}
                        onChange={event => {
                          const precision =
                            formik.values.ServiceType?.IntQuantityOnly === false
                              ? 2
                              : 0;
                          const value = +(+event.target.value).toFixed(
                            precision,
                          );
                          console.log('qyt', value);
                          // if (value < step) {
                          //   event.preventDefault();
                          //   event.stopPropagation();
                          //   formik.setFieldError(
                          //     'Quantity',
                          //     (t(
                          //       translations.err_LowQuantity,
                          //     ) as string).replace('{0}', minStep.toString()),
                          //   );
                          // } else if (value > 0 && value > maxStep) {
                          //   event.preventDefault();
                          //   event.stopPropagation();
                          //   formik.setFieldError(
                          //     'Quantity',
                          //     (t(
                          //       translations.err_HighQuantity,
                          //     ) as string).replace('{0}', maxStep.toString()),
                          //   );
                          // }
                        }}
                        inputProps={inputProps}
                      />
                    </FormRow>
                    <FormRow fullRow={true}>
                      <FormRichTextField
                        name="Remarks"
                        placeholder={t(translations.Remarks)}
                        disabled={formik.isSubmitting || readonly}
                        fullWidth={true}
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormLeftSection>
              </StyledForm>
            </React.Fragment>
          );
        }}
      </Formik>
    </>
  );
});
