import { FormValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { FieldHookConfig, useFormikContext } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { FormFieldsSection, FormRow } from '../FormsLayout';
import {
  FormListenerProps,
  IFieldProps,
  IFormFieldSetting,
} from './IFormFieldSetting';
export type IFieldRender = (item: JSX.Element) => React.ReactNode;
export type IFieldSectionRender = () => React.ReactNode;
export interface FieldsRendererProps<T> {
  fields: FormFieldProps<any>[];
  row?: T;
}
export interface FormFieldProps<T> extends IFormFieldSetting {
  component: React.FC<FieldHookConfig<T> & IFieldProps & any>;
  order: number;
}
export interface IFieldRendererProps<T> {
  fieldRow: React.FC<FormFieldProps<FormValueType>>;
  row?: T;
}
export interface IFieldSection<T> {
  title?: string;
  fields: (FormFieldProps<any> | JSX.Element)[];
  order: number;
  avoidRenderSection?: boolean;
  item: T;
}

export function FormikField<T>(props: FormFieldProps<T>) {
  //const { component } = props;
  // const Field = React.memo(function Field(props: FormFieldProps<T>) => {
  //   return React.createElement(component, props);
  // });
  return (
    <FormRow fullRow={props.fullRow} hide={props.hide} minRow={props.minRow}>
      <props.component {...props} />
    </FormRow>
  );
}
export function FormikSection<T>(props: IFieldSection<T>) {
  const { title, fields, order, avoidRenderSection } = props;

  return fields.length > 0 ? (
    <React.Fragment>
      {avoidRenderSection ? (
        <React.Fragment>
          {fields.map((field, index) => (
            <React.Fragment key={`${order}-${index}`}>
              {'component' in field ? (
                <FormikField {...field} key={`${order}-${index}`} />
              ) : (
                field
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      ) : (
        <FormFieldsSection titleSection={title}>
          {fields.map((field, index) => (
            <React.Fragment key={`${order}-${index}`}>
              {'component' in field ? (
                <FormikField {...field} key={`${order}-${index}`} />
              ) : (
                field
              )}
            </React.Fragment>
          ))}
        </FormFieldsSection>
      )}
    </React.Fragment>
  ) : (
    <></>
  );
}
export function FormListener<T>({
  onFormChange,
  fields,
}: FormListenerProps<T>) {
  const {
    values,
    setFieldValue,
    isValid,
    dirty,
    setFieldTouched,
    validateField,
    setFieldError,
    validate,
  } = useFormikContext<T>();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  React.useEffect(() => {
    onFormChange(
      values,
      isValid,
      dirty,
      setFieldValue,
      setFieldTouched,
      validateField,
      setFieldError,
      validate,
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...fields.map(field => values[field]),
    isValid,
    dirty,
  ]);

  React.useEffect(() => {
    dispatch(actions.setNotSavedChanges(dirty));
  }, [actions, dirty, dispatch]);

  return null;
}
export interface FieldChangeHandlerProps<T> {
  key?: keyof T;
  value: any;
  shouldValidate?: boolean;
}
export interface FieldHandlerProps<T> {
  run?: boolean;
  fieldKey?: keyof T;
  fieldValue?: any;
  shouldValidate?: boolean;
  resetFieldState?: () => void;
  additionalFunc?: (value: any) => void;
}
export function useFieldChangeHandler<T>() {
  const { setFieldValue } = useFormikContext<T>();
  const changeField = React.useCallback(
    (key, value, shouldValidate) => {
      if (key !== undefined) {
        setFieldValue(key.toString(), value, shouldValidate);
      }
    },
    [setFieldValue],
  );
  return [changeField];
}
export function SubmittingHandler<T>({
  value,
  resetSubmitting,
}: {
  value: boolean;
  resetSubmitting: () => void;
}) {
  const { setSubmitting } = useFormikContext<T>();
  React.useEffect(() => {
    if (value) {
      setSubmitting(!value);
      resetSubmitting();
    }

    return () => {};
  }, [resetSubmitting, setSubmitting, value]);
  return null;
}
export function FieldHandler<T>({
  run,
  fieldKey,
  fieldValue,
  shouldValidate,
  resetFieldState,
  additionalFunc,
}: FieldHandlerProps<T>) {
  const [changeField] = useFieldChangeHandler<T>();
  //const [doChange, setDoChange] = React.useState<boolean | undefined>(run);
  React.useEffect(() => {
    if (run && fieldKey !== undefined) {
      changeField(fieldKey, fieldValue, shouldValidate);
      if (!!additionalFunc) additionalFunc({ [fieldKey]: fieldValue });
      if (!!resetFieldState) resetFieldState();
      //setDoChange(undefined);
    }
    return () => {};
  }, [
    changeField,
    run,
    fieldKey,
    shouldValidate,
    fieldValue,
    resetFieldState,
    additionalFunc,
  ]);
  return null;
}
export interface SetValueProps<T> {
  setValue?: (value: any) => void;
  resetValueKey?: () => void;
  fieldKey?: keyof T;
}
export function FieldValue<T>({
  setValue,
  resetValueKey,
  fieldKey,
}: SetValueProps<T>) {
  const { getFieldMeta } = useFormikContext<T>();

  React.useEffect(() => {
    if (fieldKey !== undefined) {
      const meta = getFieldMeta(fieldKey.toString());
      if (!!setValue) setValue(meta.value);
      if (!!resetValueKey) resetValueKey();
    }
    return () => {};
  }, [fieldKey, getFieldMeta, setValue, resetValueKey]);
  return null;
}
