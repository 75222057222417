import { LocationDetailsProps } from 'app/pages/LocationsPage/Details';
import { AssetPopUpProps } from 'app/pages/AssetPopUp';
import { QuizDetailsProps } from 'app/pages/QuizDetailsPage/QuizDetails';
import { StartQuizProps } from 'app/pages/QuizPage/StartQuiz';
import { TrainingDetailsProps } from 'app/pages/UserTrainings/TrainingDetailsPage/Details';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { PaymentProps } from 'app/pages/AddPaymentPage';
import { ChargeProps } from 'app/pages/AddChargePage';
import { InvoiceDetailsSidePanelProps } from 'app/pages/InvoiceDetails/InvoiceDetailsSidePanel';
import { BillingActionsDetailsProps } from 'app/pages/BillingActions/BillingActionsDetailsPage/Details';
import { SavedViewDetailsProps } from 'app/pages/SavedViewsPage/SavedViewPage/Details';
import { InstrumentRestrictionsProps } from 'app/pages/InstrumentRestrictionsPage/Details/InstrumentRestrictions';
import { ReservationDetailsProps } from 'app/pages/ReservationDetails/Details';
import { InvoiceTemplatePopUpProps } from 'app/pages/InvoiceTemplates/InvoiceTemplatePopUp';
import { OtherServiceDetailsProps } from 'app/pages/OtherServiceDetails/Details';
import { PricePeriodDetailsProps } from 'app/pages/PriceSheetPeriodDetails/Details';
import { CoreSelectionProps } from 'app/pages/CoreSelectionPage/CoreSelection';
import { AssetsSelectionProps } from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import { UserAttributesDetailsProps } from 'app/pages/UserAttributes/UserAttributesDetailsPage/Details';
import { ServiceRequestDetailsProps } from 'app/pages/Samples/RequestSamplesPage';
import { CalendarMessagesDetailsProps } from 'app/pages/CalendarMessages/CalendarMessagesDetails/Details';
import { ReportConsumablesProps } from 'app/pages/OtherServiceDetails/ReportConsumables';
import { ServiceRequestTicketDetailsProps } from 'app/pages/ServiceRequestTicketDetailsPage/ServiceRequestTicketDetails';
import { ServiceRequestMilestoneDetailsProps } from 'app/pages/Samples/components/ServiceRequestMilestoneDetails';
import { ServiceRequestMilestoneChargeDetailsProps } from 'app/pages/Samples/components/ServiceRequestMilestoneChargeDetails';
import { StockRenewalProps } from 'app/pages/OtherServiceDetails/StockRenewal';
import { RenewStockProps } from 'app/pages/OtherServiceDetails/RenewStock';
import { InventoryBatchProps } from 'app/pages/OtherServiceDetails/InventoryBatch';
import { InvoiceBatchesCommentsProps } from 'app/pages/InvoiceBatches/InvoiceBatchesComments';

export interface LayoutState {
  sidePanelState: SidePanelState;
  confirmState: ConfirmState;
  continueState: ContinueState;
  hasAnyNotSavedChanges: boolean;
  doRefreshTable: boolean;
  hideNavigation?: boolean;
  actionTableProcessing?: ProcessingData;
  upsnackbarIndex?: boolean;
  isMobileView?: boolean;
}
export interface SidePanelState {
  sidePanelOpen: boolean;
  pageType?: RenderPageType;
  pageProps?: PageTypeProps;
  expanded: boolean;
  coverOpen?: boolean;
  coverExpanded?: boolean;
}
export interface ConfirmState {
  confirmOpen: boolean;
  approved: boolean;
  rejected: boolean;
  showLocalConfirm: boolean;
  continueToLink?: string;
}
export interface ContinueState {
  pageType?: RenderPageType;
  pageProps?: PageTypeProps;
  expanded?: boolean;
  continueOnApprove: boolean;
}
export interface ProcessingData {
  name: string;
  processing: boolean;
}
export enum RenderPageType {
  TrainingDetails,
  QuizCreate,
  QuizzDetails,
  LocationDetails,
  AssetDetails,
  WorkOrderDetails,
  AddPayment,
  AddCharge,
  InvoiceDetails,
  AddBillingAction,
  SavedViewDetails,
  LabelType,
  LabelTypeEdit,
  OtherServiceDetails,
  RestrictionDetails,
  InvoiceTemplatePopUp,
  ReservationDetails,
  PricePeriodsDetails,
  Article,
  CoreSelection,
  AssetsSelection,
  ArticleNew,
  ArticleEdit,
  UserAttributesDetails,
  ServiceRequestDetails,
  CalendarMessagesDetails,
  ReportConsumables,
  ServiceRequestTicketDetails,
  ServiceRequestMilestone,
  ServiceRequestMilestoneCharge,
  StockRenewal,
  RenewStock,
  InventoryBatch,
  InvoiceBatchesComments,
}
export interface SidePanelContentProps {
  useSidePanel: boolean;
}
export type PageTypeProps =
  | TrainingDetailsProps
  | StartQuizProps
  | QuizDetailsProps
  | LocationDetailsProps
  | AssetPopUpProps
  | WorkOrderDetailsProps
  | PaymentProps
  | ChargeProps
  | InvoiceDetailsSidePanelProps
  | BillingActionsDetailsProps
  | SavedViewDetailsProps
  | InvoiceTemplatePopUpProps
  | OtherServiceDetailsProps
  | InstrumentRestrictionsProps
  | ReservationDetailsProps
  | PricePeriodDetailsProps
  | CoreSelectionProps
  | AssetsSelectionProps
  | UserAttributesDetailsProps
  | ServiceRequestDetailsProps
  | ServiceRequestTicketDetailsProps
  | ReportConsumablesProps
  | CalendarMessagesDetailsProps
  | ServiceRequestMilestoneDetailsProps
  | ServiceRequestMilestoneChargeDetailsProps
  | StockRenewalProps
  | RenewStockProps
  | InventoryBatchProps
  | InvoiceBatchesCommentsProps;
