/**
 *
 * NewArticleDetailsForm
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import * as Yup from 'yup';
import { selectArticles } from '../slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { Formik } from 'formik';
import {
  FormFieldsSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormAssetsPicker } from 'app/components/Forms/FormAssetsPicker';
import { useArticlesSlice } from '../slice';
import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { Button } from 'app/components/BasicButtons/Button';
import { Box } from 'app/components/basic/Wrappers/Box';
import { useTranslation } from 'react-i18next';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { InputLabel } from 'app/components/Typography';
import { ValueLabel } from 'app/components/ValueLabel';
import {
  EntityNumberSchema,
  UserEntitySchema,
} from 'app/components/Forms/Schemas';
import { FormUserPicker } from 'app/components/Forms/FormUserPicker';
import { FormAssetPicker } from 'app/components/Forms/FormAssetPicker';
import { FormListener } from 'app/components/Forms/FormRender/FormRenderer';
import {
  assetSchema,
  assetsSchema,
  GetInstrumentFilter,
  IArticleNewEditForm,
} from '../utils';
import { ArticleStatus } from 'enums/Article';
import { FormCustomPicker } from 'app/components/Forms/FormCustomPicker';
import { Entity } from 'types/common';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { useAllowedToChangeStatus } from '../Hooks/useAllowedToChangeStatus';
import { Roles } from 'app/slice/types';
import { selectAuthenticatedUser } from 'app/slice/selectors';

export interface NewArticleDetailsFormProps extends CoverProps {
  Journal?: string | null;
  PageNumber?: number | null;
}

export function NewArticleDetailsForm(props: NewArticleDetailsFormProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const { actions: layoutActions } = useLayoutSlice();
  const [oldServiceGroups, setOldServiceGroups] = React.useState<IAssetDto[]>(
    [],
  );
  const [serviceGroups, setServiceGroups] = React.useState<IAssetDto[]>([]);
  const state = useSelector(selectArticles);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const admin = authenticatedUser?.Roles.includes(Roles.GroupAdministrators);
  const [canEditStatus, setCanEditStatus] = React.useState<boolean>(false);
  const allowedToChangeStatus = useAllowedToChangeStatus();
  const schema: Yup.SchemaOf<IArticleNewEditForm> = Yup.object().shape(
    {
      Title: Yup.string().required().label(t(translations.Title)).trim(),
      Authors: Yup.string().required().label(t(translations.Authors)).trim(),
      Abstract: Yup.string().required().label(t(translations.Abstract)).trim(),
      Doi: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.Doi))
        .trim(),
      ISSN: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.ISSN))
        .trim(),
      ORCIDs: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.ORCID))
        .trim(),
      Year: Yup.number().required().label(t(translations.Year)).min(1),
      RelatedUser: UserEntitySchema.nullable().notRequired(),
      /*ServiceGroupAssets: assetsSchema.when(['InstrumentAssets'], {
        is: v => (v === undefined ? true : v.length === 0),
        then: assetsSchema
          .required()
          .label(t(translations.ServiceGroup))
          .min(1)
        otherwise: assetsSchema.notRequired(),
      }),*/
      ServiceGroupAsset: assetSchema.when(['InstrumentAssets'], {
        is: v => (v === undefined ? true : v.length === 0),
        then: assetSchema
          .required()
          .label(t(translations.ServiceGroup))
          .nullable(),
        otherwise: assetSchema.nullable().notRequired(),
      }),
      /*InstrumentAssets: assetsSchema.when(['ServiceGroupAssets'], {
        is: v => (v === undefined ? true : v.length === 0),
        then: assetsSchema.required().label(t(translations.Instruments)).min(1),
        otherwise: assetsSchema.notRequired(),
      }),*/
      InstrumentAssets: assetsSchema.when(['ServiceGroupAsset'], {
        is: v => v === null,
        then: assetsSchema.required().label(t(translations.Instruments)).min(1),
        otherwise: assetsSchema.notRequired(),
      }),
      Status: EntityNumberSchema.required().label(
        t(translations.ArticleStatus),
      ),
      Journal: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.Journal))
        .trim(),
      PageNumber: Yup.number(),
    },
    [['ServiceGroupAsset', 'InstrumentAssets']],
  );
  const initialValues: IArticleNewEditForm = React.useMemo(
    () => ({
      Title: '',
      Authors: '',
      Abstract: '',
      Doi: null,
      ISSN: null,
      ORCIDs: null,
      Year: 0,
      RelatedUser: null,
      InstrumentAssets: [],
      //ServiceGroupAssets: [],
      ServiceGroupAsset: null,
      Status: admin
        ? { Id: 1, Name: t('Article' + ArticleStatus[1]) }
        : { Id: 0, Name: t('Article' + ArticleStatus[0]) },
    }),
    [admin, t],
  );
  const instrumentFilter = React.useCallback(() => {
    return GetInstrumentFilter(serviceGroups);
  }, [serviceGroups]);
  const dispatch = useDispatch();
  const { actions } = useArticlesSlice();
  const handleFormChange = React.useCallback(
    (
      values: IArticleNewEditForm,
      isValid,
      dirty,
      setValue,
      setTouched,
      validateField,
      setError,
      validate,
    ) => {
      if (
        serviceGroups.length !== oldServiceGroups.length ||
        serviceGroups[0] !== oldServiceGroups[0]
      ) {
        setOldServiceGroups(serviceGroups);
        setValue('InstrumentAssets', []);
        return;
      }
      if (
        values.ServiceGroupAsset === null &&
        values.InstrumentAssets.length > 0
      ) {
        setOldServiceGroups(serviceGroups);
        setValue('ServiceGroupAsset', {
          Id: values.InstrumentAssets[0].ServiceGroupAssetId,
          Name: values.InstrumentAssets[0].ServiceGroupName,
          ServiceGroupId: values.InstrumentAssets[0].ServiceGroupId,
          ServiceGroupName: values.InstrumentAssets[0].ServiceGroupName,
        });
      }
      setCanEditStatus(allowedToChangeStatus(serviceGroups[0]));
    },
    [allowedToChangeStatus, oldServiceGroups, serviceGroups],
  );
  const handleSubmit = React.useCallback(
    (values: IArticleNewEditForm) => {
      if (
        //values.ServiceGroupAssets.length === 0 &&
        (values.ServiceGroupAsset === null &&
          values.InstrumentAssets.length === 0) ||
        values.Status === null
      ) {
        return;
      }
      const payload: IArticleDto = {
        Id: 0,
        Title: values.Title.trim(),
        Authors: values.Authors.trim().split(','),
        Abstract: values.Abstract.trim(),
        Year: values.Year,
        ExternalId: null,
        ExternalIdSource: 'Manual Entry',
        Doi:
          values.Doi !== undefined && values.Doi !== null
            ? values.Doi.trim()
            : null,
        ISSN:
          values.ISSN !== undefined && values.ISSN !== null
            ? values.ISSN.trim()
            : null,
        Journal: values.Journal !== undefined ? values.Journal : null,
        PageNumber: values.PageNumber !== undefined ? values.PageNumber : null,
        ORCIDs:
          values.ORCIDs !== undefined && values.ORCIDs !== null
            ? values.ORCIDs.trim().split(',')
            : [],
        ArticleContributionLevelId: null,
        FirstInstrumentAssetName: null,
        FirstServiceGroupAssetName: null,
        ...{
          //Assets: values.ServiceGroupAssets.concat(values.InstrumentAssets),
          Assets:
            values.ServiceGroupAsset !== null
              ? values.InstrumentAssets.concat([values.ServiceGroupAsset])
              : values.InstrumentAssets,
        },
        Status: values.Status.Id,
        EnteredBy: null,
        UserDisplayName: null,
        EnteredAt: null,
        RelatedUser:
          values.RelatedUser !== null ? values.RelatedUser?.Id : null,
        RelatedUserDisplayName: null,
        UserGroupId: null,
        UserGroupName: null,
        DepartmentId: null,
        DepartmentName: null,
        InstituteId: null,
        InstituteName: null,
        InstituteTypeId: null,
        InstituteTypeName: null,
        CreatedByMe: false,
      };
      dispatch(actions.saveArticleAssociation(payload));
    },
    [actions, dispatch],
  );
  const processing = state.editArticleAssociationBusy;
  const statusPickerData: Entity<number>[] = React.useMemo(() => {
    const data = [
      {
        Id: ArticleStatus.AwaitingApproval,
        Name: t('Article' + ArticleStatus[ArticleStatus.AwaitingApproval]),
      },
      {
        Id: ArticleStatus.Approved,
        Name: t('Article' + ArticleStatus[ArticleStatus.Approved]),
      },
    ] as Entity<number>[];
    return data;
  }, [t]);

  return (
    <PageWrapper
      useSidePanel
      closable
      disableExpandToggle
      pageName={t(translations.AddPublication)}
      titlePage={t(translations.AddPublication)}
      titleTooltip={t(translations.AddPublication)}
      closeSidePanel={() => {
        dispatch(layoutActions.setNotSavedChanges(false));
        dispatch(actions.hideDetails());
      }}
      topProcessing={processing}
    >
      <Box height={'100%'} p={1}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validateOnMount={true}
          validateOnBlur={false}
        >
          {formik => (
            <StyledForm onSubmit={formik.handleSubmit}>
              <FormFieldsSection>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['ServiceGroupAsset', 'InstrumentAssets']}
                />
                <FormRow>
                  <FormTextField
                    name="Title"
                    id="TitleId"
                    label={t(translations.Title)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="Authors"
                    id="AuthorsId"
                    label={t(translations.Authors)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="Abstract"
                    id="AbstractId"
                    label={t(translations.Abstract)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <InputLabel size="xs">{t(translations.Source)}</InputLabel>
                  <ValueLabel value="Manual Entry" />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="Doi"
                    id="DoiId"
                    label={t(translations.Doi)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="ISSN"
                    id="ISSNId"
                    label={t(translations.ISSN)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="ORCIDs"
                    id="ORCIDsId"
                    label={t(translations.ORCID)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="Year"
                    id="YearId"
                    label={t(translations.Year)}
                    type="number"
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="Journal"
                    id="JournalId"
                    label={t(translations.Journal)}
                    multiline={true}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTextField
                    name="PageNumber"
                    id="PageNumberId"
                    label={t(translations.ArticlePageNumber)}
                    type="number"
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormCustomPicker
                    name="Status"
                    label={t(translations.ArticleStatus)}
                    placeholder={t(translations.ArticleStatus)}
                    disabled={formik.isSubmitting || !canEditStatus}
                    fullWidth
                    data={statusPickerData}
                  />
                </FormRow>
                <FormRow>
                  <FormUserPicker
                    name="RelatedUser"
                    label={t(translations.RelatedUser)}
                    placeholder={t(translations.PleaseSelectAUser)}
                    disabled={formik.isSubmitting || !canEditStatus}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormAssetPicker
                    admin={false}
                    showOnlyActiveAssets={true}
                    name="ServiceGroupAsset"
                    restrictByAvType="serviceGroup"
                    label={t(translations.ServiceGroup)}
                    placeholder={t(translations.PleaseSelectAsset)}
                    disabled={processing}
                    fullWidth
                    onChange={(value: any) => {
                      setOldServiceGroups(serviceGroups);
                      setServiceGroups([value]);
                    }}
                  />
                </FormRow>
                <FormRow>
                  <FormAssetsPicker
                    admin={false}
                    filter={instrumentFilter()}
                    showOnlyActiveAssets={true}
                    name="InstrumentAssets"
                    restrictByAvType="bookableAndnotbookable"
                    label={t(translations.Instruments)}
                    placeholder={t(translations.PleaseSelectAsset)}
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <Button processing={processing} type="submit">
                    {t(translations.Submit)}
                  </Button>
                </FormRow>
              </FormFieldsSection>
            </StyledForm>
          )}
        </Formik>
      </Box>
    </PageWrapper>
  );
}
