/**
 *
 * Asynchronously loads the component for CoresDirectoryPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CoresDirectoryPage = lazyLoad(
  () => import('./index'),
  module => module.CoresDirectoryPage,
);
