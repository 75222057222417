import {
  CalendarButton,
  CalendarButtonProps,
} from 'app/components/CalendarButton';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';

export interface OpenCalendarProps<TRow>
  extends Omit<
    CalendarButtonProps,
    | 'variant'
    | 'size'
    | 'equipments'
    | 'openToBook'
    | 'viewType'
    | 'target'
    | 'children'
  > {
  rows: TRow[];
  getEquipmentIds: (rows: TRow[]) => number[];
  bookMultiple?: boolean;
  primary?: boolean;
}

export function OpenCalendar<TRow>({
  rows,
  getEquipmentIds,
  bookMultiple,
  primary,
  ...props
}: OpenCalendarProps<TRow>) {
  const { t } = useTranslation();
  return (
    <CalendarButton
      variant={primary ? 'main' : 'white'}
      size="small"
      equipments={getEquipmentIds(rows)}
      openToBook={true}
      viewType="calendar"
      target="_blank"
      defaultMulti={bookMultiple}
      {...props}
    >
      {t(translations.Reservation)}
    </CalendarButton>
  );
}
