import * as React from 'react';
import { useAppSettingsSlice } from 'app/slice';
import {
  selectAuthenticatedUser,
  selectUserProfileSettings,
} from 'app/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';

export interface IUseProfileProps {
  profileSettingKey: string;
}
export const useProfileSetting = (
  props: IUseProfileProps,
): {
  savedValue: string | undefined;
  updateValue: (value: string) => void;
  savedEnabled: boolean | undefined;
  updateEnabled: (enabled: boolean) => void;
} => {
  const { actions } = useAppSettingsSlice();
  const dispatch = useDispatch();
  const userProfileSettings = useSelector(selectUserProfileSettings);
  const key = props.profileSettingKey.toLowerCase();
  const userProfileSetting = userProfileSettings.GetSettingValueBy(
    f => f.Key.toLowerCase() === key,
  );

  const savedValue = userProfileSetting?.Value ?? undefined;
  const savedEnabled = userProfileSetting?.Enabled ?? undefined;
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const updateValue = React.useCallback(
    (value: string) => {
      if (authenticatedUser === undefined) {
        return;
      }
      dispatch(
        actions.saveUserProfileSetting({
          Key: props.profileSettingKey,
          Value: value,
          UserName: authenticatedUser?.Id,
          Enabled: true,
        }),
      );
    },
    [actions, authenticatedUser, dispatch, props.profileSettingKey],
  );
  const updateEnabled = React.useCallback(
    (value: boolean) => {
      if (authenticatedUser === undefined) {
        return;
      }
      if (value === savedEnabled) {
        return;
      }
      dispatch(
        actions.saveUserProfileSetting({
          Key: props.profileSettingKey,
          Value: savedValue || null,
          UserName: authenticatedUser?.Id,
          Enabled: value,
        }),
      );
    },
    [
      actions,
      authenticatedUser,
      dispatch,
      props.profileSettingKey,
      savedEnabled,
      savedValue,
    ],
  );
  return { savedValue, updateValue, savedEnabled, updateEnabled };
};
