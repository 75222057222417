import { Box, Divider, Grid, Popover } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Body } from 'app/components/Typography';
import { InvoiceIdMultiPicker } from 'app/components/pickers/AutocompletePickers/InvoiceIdMultiPicker';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { translations } from 'locales/translations';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { buildURL, openExportLink } from 'utils/url-utils';

export interface PreviewButtonProps {}

export function PreviewButton() {
  //PROPS
  const { t } = useTranslation();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'previewInvoiceSelection',
  });

  //STATES
  const [invoiceIds, setInvoiceIds] = useAsyncExtendedState<
    Entity<number>[] | undefined
  >(undefined);

  //FUNCTIONS
  const handleInvoiceIdChange = useCallback(
    (value: Entity<number>[] | undefined) => {
      setInvoiceIds(value);
    },
    [setInvoiceIds],
  );

  const handlePreview = () => {
    if (!!invoiceIds) {
      const ids: string = invoiceIds.map(item => item.Id).join(',');
      var params = {
        ids: ids,
      };
      //BillingController->InvoiceExportPDFs - [Route("api/Billing/Invoices/InvoiceExportPDFs")]
      const url = buildURL('api/Billing/Invoices/InvoiceExportPDFs', params);
      openExportLink(url);
      popupState.close();
      setInvoiceIds([]);
    }
  };

  //RENDER
  return (
    <Box component="div">
      <TopActionButton
        text={t(translations.Preview) as string}
        title={t(translations.Preview) as string}
        icon="link"
        startIcon="save"
        size="small"
        variant="main"
        /* endIcon="chevron-down" */
        css={{ marginLeft: '8px' }}
        disabled={false}
        noChangeOnMobile={true}
        {...bindTrigger(popupState)}
      />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableAutoFocus
        PaperProps={{ style: { width: '360px' } }}
      >
        <Box component="div" style={{ padding: 16 }}>
          <Grid container spacing={2} wrap="nowrap" direction="column">
            <Grid item key="title-content">
              <Box display="row" justifyContent="space-between">
                <Body size="small" bold={true}>
                  {t(translations.Invoice)}
                </Body>
                <IconButton
                  variant="ghost"
                  aria-label="close"
                  size="xs"
                  style={{ position: 'absolute', right: 8, top: 8 }}
                  onClick={() => {
                    popupState.close();
                    setInvoiceIds([]);
                  }}
                >
                  <Icon icon="times" />
                </IconButton>
              </Box>
            </Grid>
            <Divider variant="fullWidth" />
            <Grid item key="previewInvoice-picker">
              <InvoiceIdMultiPicker
                id="previewInvoiceID"
                isOpen={false}
                placeholder={t(translations.AllValues)}
                value={invoiceIds}
                onChange={value => {
                  handleInvoiceIdChange(value);
                }}
              />
            </Grid>
            <Divider variant="fullWidth" />
            <Grid item key="preview-button">
              <Button
                variant="main"
                size="small"
                disabled={
                  invoiceIds === null ||
                  invoiceIds === undefined ||
                  invoiceIds.length === 0
                }
                onClick={() => handlePreview()}
              >
                {t(translations.Preview)}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
}
