import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { DialogAlertState } from './types';

export const initialState: DialogAlertState = {
  open: false,
};

const slice = createSlice({
  name: 'dialogAlert',
  initialState,
  reducers: {
    showAlert(state, action: PayloadAction<string>) {
      state.open = true;
      state.content = action.payload;
    },
    hideAlert(state, action: PayloadAction) {
      state.open = false;
      state.content = undefined;
    },
  },
});

export const { actions: dialogAlertActions } = slice;

export const useDialogAlertSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAlertSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
