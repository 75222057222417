import {
  selectAuthenticatedUser,
  selectglobalSettings,
} from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { ScreensId } from 'enums/ConfigurableTypes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import { IBasePageProps, IListPageProps } from '../IBasePageProps';
import { IEmailNotificationsRow } from './IEmailNotificationsRow';
import * as React from 'react';
import { translations } from 'locales/translations';
import { UserProfileLink } from 'app/components/BasicTable/components/UserProfileLink';
import { DateTimeLabel } from 'app/components/DateTimeLabel';
import { DescriptionCell } from 'app/components/BasicTable/components/DescriptionCell';
import { BasicTable, IBasicTableState } from 'app/components/BasicTable';
import { GetSelectedActions } from './SelectedRowActions/GetSelectedRowActions';
import { SelectedRowsActionRenderer } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionRenderer';
import { withSavedHistoryT } from '../../components/BasicTable/withSavedHistory';
import { withGlobalServiceGroupFilter } from 'app/components/BasicTable/withGlobalServiceGroupFilter';
import { GetFilters as getFilters } from './Filter';
import { AllowedSettings } from 'utils/globalSettings';
import { AlertContentType } from 'app/components/BasicAlert';
import AssetRenderer from 'app/components/BasicTable/CellRenderers/AssetRenderer';

export const NOTIFICATIONS_LOG_PAGE_PATH = '/emailnotifications';
type RowType = IEmailNotificationsRow;
export interface EmailNotificationsPageProps
  extends IBasePageProps,
    IListPageProps<RowType> {}

function EmailNotifications(props: EmailNotificationsPageProps) {
  const { t } = useTranslation();
  const user = useSelector(selectAuthenticatedUser);
  const settings = useSelector(selectglobalSettings);
  const scopeOfMonth = settings.GetString(
    AllowedSettings.DeleteEmailNotficationsMonth,
  );
  const topMessage = {
    type: 'info',
    message: t(translations.EmailNotficationsScreenWarning).replace(
      '{0}',
      scopeOfMonth,
    ),
    closable: true,
  } as AlertContentType;
  const isMainAdmin =
    user !== undefined && user.Roles.includes(Roles.Administrators);
  const columns: Column<RowType>[] = React.useMemo(
    () => [
      {
        Header: t(translations.Timestamp) as string,
        accessor: 'Timestamp',
        Cell: ({ value }) => <DateTimeLabel value={value as Date} />,
      },
      {
        Header: t(translations.Recipient) as string,
        accessor: 'RecipientName',
        Cell: ({ value, row }) =>
          row.original.Recipient !== null &&
          row.original.Recipient !== undefined ? (
            <>
              <UserProfileLink
                UserId={`${row.original.Recipient}`}
                DisplayName={`${row.original.RecipientName}`}
              />
            </>
          ) : (
            <>{t(translations.NA)}</>
          ),
      },
      {
        Header: t(translations.EmailTo) as string,
        accessor: 'EmailTo',
      },
      {
        Header: t(translations.EmailCc) as string,
        accessor: 'EmailCc',
      },
      {
        Header: t(translations.EmailBcc) as string,
        accessor: 'EmailBcc',
      },
      {
        Header: t(translations.CreatedBy) as string,
        accessor: 'CreatedByName',
      },
      {
        Header: t(translations.Subject) as string,
        accessor: 'Subject',
      },
      {
        Header: t(translations.EmailMessage) as string,
        accessor: 'Message',
        Cell: DescriptionCell({ width: 'medium' }),
      },
      {
        Header: t(translations.Asset) as string,
        accessor: 'AssetName',
        Cell: AssetRenderer,
      },
      {
        Header: t(translations.Attachment) as string,
        accessor: 'HasAttachment',
      },
    ],
    [t],
  );
  // const k = keys<RowType>())
  const initialState: IBasicTableState<RowType> = React.useMemo(
    () => ({
      sortBy: [{ id: 'Timestamp', desc: true }],
    }),
    [],
  );
  const selectedRowActions: SelectedRowsActionRenderer<
    IEmailNotificationsRow
  >[] = isMainAdmin ? GetSelectedActions({}) : [];

  const url = '/api/odata/v4/emailnotifications';
  return (
    <>
      <TTable
        screenId={ScreensId.Email}
        api={url}
        columns={columns}
        initialState={initialState}
        selectedRowsActions={selectedRowActions}
        useRowSelect={isMainAdmin}
        additionalColumns={[
          'Id',
          'Recipient',
          'CreatedBy',
          'NotificationTypeId',
          'AssetId',
          'ServiceTypeId',
          'EquipmentId',
          'OfflineServiceTypeId',
          'InvoiceId',
          'ReferenceId',
          //'SubReferenceId',
          'ServiceGroupId',
        ]}
        searchColumns={[
          'AssetName',
          'Recipient',
          'RecipientName',
          'CreatedBy',
          'CreatedByName',
          'Subject',
          'Message',
          'EmailTo',
          'EmailBcc',
          'EmailCc',
        ]}
        screenName={t(translations.menu_EmailNotifications)}
        serviceGroups={props.serviceGroups}
        topAlertMessage={topMessage}
      />
    </>
  );
}
const TTable = withSavedHistoryT<RowType>(
  BasicTable,
  '~/EmailNotifications',
  getFilters,
);

export const EmailNotificationsPage = withGlobalServiceGroupFilter(
  EmailNotifications,
  false,
);
