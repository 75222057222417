import { getAssetImageThumbSrc } from 'app/components/AssetThumbImage/getAssetImageSrc';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { selectImageExist } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import { CellProps, Renderer } from 'react-table';

type RecordType = Record<string, any>;
const ImageRenderer: Renderer<CellProps<RecordType>> = ({ value, row }) => {
  const imageExist = useSelector(state => selectImageExist(state, value));
  return (
    <>
      <Tooltip title={row.original.Name} arrow>
        <span>
          <img
            src={getAssetImageThumbSrc(value, 'thumbSmall', imageExist)}
            alt=""
          />
        </span>
      </Tooltip>
    </>
  );
};
export default ImageRenderer;
