import * as DHTMLX from 'dhtmlx-scheduler';
import { dateUtils } from 'utils/date-utils';
import { ISchedulerState } from './types/ISchedulerState';
import { ISchedulerEvent } from './types/ISchedulerEvent';

/**
 * Resets currently shown events to their original positions.
 * @param scheduler scheduler instance
 * @param event created/updated event
 */
export function resetEventEdits(
  scheduler: DHTMLX.SchedulerStatic,
  event: ISchedulerEvent,
) {
  const state: ISchedulerState = scheduler.getState();
  const x = scheduler
    .getEvents(state.min_date, state.max_date)
    .filter((f: ISchedulerEvent) => f.id !== event.id)
    .filter((f: ISchedulerEvent) => f.original?.StartTime !== undefined)
    .filter((f: ISchedulerEvent) => f.original?.EndTime !== undefined)
    .filter((f: ISchedulerEvent) => {
      const dd =
        dateUtils.formatISO(f.end_date) !== f.original.EndTime ||
        dateUtils.formatISO(f.start_date) !== f.original.StartTime;
      return dd;
    });

  x.forEach((f: ISchedulerEvent) => {
    f.start_date = dateUtils.parseISO(f.original.StartTime);
    f.end_date = dateUtils.parseISO(f.original.EndTime);
    scheduler.updateEvent(String(f.id));
  });
}
