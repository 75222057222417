/**
 *
 * ServiceTypeEnumPicker
 *
 */

import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import { selectAppSettings } from 'app/slice/selectors';
import { ServiceType } from 'enums/ServiceTypes';
import { useSelector } from 'react-redux';
import { AppSettings, IsModuleEnabled } from 'types/AppSettings';
import { Entity } from 'types/common';
import { KnownModules } from 'types/KnownModules';
import { enumToEntityArray } from 'utils/enumKeys';

export interface ServiceTypeEnumPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {}

export const getSingleServiceTypeEnum = (val: number): Entity<number> => {
  return enumToEntityArray(ServiceType, undefined, 'ServiceType_').filter(
    item => {
      return item.Id === val;
    },
  )[0];
};

export const initServiceTypeEnumData = (
  initval: string | undefined,
): Promise<Entity<number>[]> => {
  const val = parseInt(initval ?? '');
  if (initval === undefined || isNaN(val)) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([getSingleServiceTypeEnum(val)]);
    });
  }
};
export const GetAvailableServiceTypes = (
  items: Entity<number>[],
  appSettings: AppSettings | null,
) => {
  return items.filter(f => {
    if (f.Id === ServiceType.Offline) {
      return IsModuleEnabled(appSettings, KnownModules.OfflineServices);
    }
    if (f.Id === ServiceType.Sample) {
      return IsModuleEnabled(appSettings, KnownModules.ServiceRequests);
    }
    if (f.Id === ServiceType.Workflow) {
      return IsModuleEnabled(appSettings, KnownModules.Workflows);
    }
    if (f.Id === ServiceType.Online) {
      return IsModuleEnabled(appSettings, KnownModules.Instruments);
    }
    return false;
  });
};
export function ServiceTypeEnumPicker(props: ServiceTypeEnumPickerProps) {
  const appSettings = useSelector(selectAppSettings);
  const getOptions = (searchTerm: string | null) => {
    let items = GetAvailableServiceTypes(
      enumToEntityArray(ServiceType, undefined, 'ServiceType_'),
      appSettings,
    );

    if (searchTerm !== null && searchTerm !== '') {
      return items.filter(
        f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
      );
    }
    return items;
  };

  return (
    <BaseOptionsPicker
      id={'servicetypeenum'}
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      value={props.value}
      {...props}
    />
  );
}
