import { Skeleton } from '@material-ui/lab';
import React from 'react';
import styled from 'styled-components';

const SceletonBox = styled('div')`
  display: flex;
  padding-inline: 16px;
`;
export const SkeletonIfLoading = ({
  loading,
  children,
}: React.PropsWithChildren<{ loading: boolean }>) => {
  if (loading) {
    return (
      <SceletonBox>
        <Skeleton>{children}</Skeleton>
      </SceletonBox>
    );
  }
  return <>{children}</>;
};
