import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { FormRow } from 'app/components/Forms/FormsLayout';
import { UserPicker } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { IRestrictedUserPermissionResult } from 'api/odata/generated/entities/IRestrictedUserPermissionResult';
import { Entity } from 'types/common';

import { translations } from 'locales/translations';
import { FormLabelText } from 'app/components/Forms/FormLabelText';
import { Box, makeStyles, Theme, createStyles } from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { Skeleton } from '@material-ui/lab';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { BasicAlert } from 'app/components/BasicAlert';
import { SimpleList } from 'app/components/BasicList/StyledList';
import { Icon } from 'app/components/BasicIcons/FontAwesome';

export interface UserOwnPermissionsProps {
  user: Entity<string> | null;
  isAdmin?: boolean;
  onUserChange: (value: Entity<string> | null) => void;
  permissions: IRestrictedUserPermissionResult[];
  permLoading: boolean;
  restrictedOption: boolean | null;
  EquipmentName: string;
  serviceGroupId: number;
  serviceId: number;
}

export const UserOwnPermissions = React.memo(function UserOwnPermissions(
  props: UserOwnPermissionsProps,
) {
  const {
    user,
    isAdmin,
    onUserChange,
    permissions,
    permLoading,
    restrictedOption,
    EquipmentName,
    serviceGroupId,
    serviceId,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const handleUserChange = React.useCallback(
    (value: Entity<string> | null) => {
      onUserChange(value);
    },
    [onUserChange],
  );
  const userIsAdmin = React.useMemo(() => {
    if (user === null) {
      return false;
    } else {
      return (
        (user as IUserFilterDto)?.ServiceGroupIds?.some(
          f => f === serviceGroupId,
        ) ||
        (user as IUserFilterDto)?.Services?.some(f => f.Id === serviceId) ||
        (user as IUserFilterDto)?.IsAdminPanel
      );
    }
  }, [serviceGroupId, serviceId, user]);
  return (
    <React.Fragment>
      {isAdmin && (
        <FormRow>
          <UserPicker
            label={t(translations.ShowRestrictionsFor)}
            placeholder={t(translations.PleaseSelectAUser)}
            id="specificPermissions"
            name="userPermissions"
            disableClearable={false}
            clearOnBlur={false}
            variant="filled"
            fullWidth={true}
            value={user as IUserFilterDto}
            onChange={value => handleUserChange(value)}
            selectArray={[
              'Id',
              'Name',
              'UserGroups',
              'Budgets',
              'IsAdminPanel',
              'IsEquipmentAdmin',
              'IsGroupAdmin',
              'IsLabTech',
              'Services',
              'ServiceGroupIds',
            ]}
          />
        </FormRow>
      )}

      {user !== null && (
        <FormRow>
          <FormLabelText
            name="permissions"
            html={
              <Box className={classes.permRoot}>
                {permLoading ? (
                  <Box key="loading" className={classes.permRoot}>
                    <Skeleton key={'first'} width="100%" variant="text" />
                    <Skeleton key={'last'} width="100%" variant="text" />
                  </Box>
                ) : restrictedOption === null && !userIsAdmin ? (
                  <BasicAlert
                    variant="standard"
                    content={{
                      type: 'warning',
                      message: (t(
                        translations.res_err_Permissions_StaffOnly,
                      ) as string).replace('{0}', EquipmentName),
                      closable: false,
                    }}
                  />
                ) : permissions.length > 0 ? (
                  <Box>
                    {permissions.filter(x => x.Valid === true).length > 0 && (
                      <React.Fragment>
                        <Body size="small" bold={true}>
                          {'Allowed on:'}
                        </Body>
                        <SimpleList
                          key={'allowedon'}
                          className={classes.cardContentRows}
                        >
                          {permissions
                            .filter(x => x.Valid === true)
                            .map(item => (
                              <li
                                key={`allowed-${item.TimeSlotTypeId}`}
                                className={classes.contentRow}
                              >
                                <React.Fragment>
                                  <Icon
                                    icon="circle"
                                    fontSize="6px"
                                    color="primary"
                                  />
                                  <Box
                                    component="span"
                                    className={classes.rowOverRule}
                                  >
                                    <Body
                                      size="small"
                                      key={`body-${item.TimeSlotTypeId}`}
                                    >{`${item.PermissionName} - ${item.TimeSlotTypeName}`}</Body>
                                  </Box>
                                </React.Fragment>
                              </li>
                            ))}
                        </SimpleList>
                      </React.Fragment>
                    )}
                    {permissions.filter(x => x.Valid === false).length > 0 && (
                      <React.Fragment>
                        <Body size="small" bold={true}>
                          {'Not allowed on:'}
                        </Body>
                        <SimpleList
                          key={'notallowedon'}
                          className={classes.cardContentRows}
                        >
                          {permissions
                            .filter(x => x.Valid === false)
                            .map((item, index) => (
                              <li
                                key={`notallowed-${item.TimeSlotTypeName}`}
                                className={classes.contentRow}
                              >
                                <React.Fragment>
                                  <Icon
                                    icon="circle"
                                    fontSize="6px"
                                    color="primary"
                                  />
                                  <Box
                                    component="span"
                                    className={classes.rowOverRule}
                                  >
                                    <Body
                                      size="small"
                                      key={`body-${item.TimeSlotTypeName}`}
                                    >{`${item.TimeSlotTypeName}`}</Body>
                                  </Box>
                                </React.Fragment>
                              </li>
                            ))}
                        </SimpleList>
                      </React.Fragment>
                    )}
                  </Box>
                ) : (
                  <Box key="notFound" className={classes.permRoot}>
                    {user !== null ? (
                      <Body size="small" bold={true}>
                        {t(translations.NoAdditionalRestrictionsFound)}
                      </Body>
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
              </Box>
            }
          />
        </FormRow>
      )}
    </React.Fragment>
  );
});
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    permRoot: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: '12px',
      width: '100%',
      gap: 8,
    },
    cardTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 20,
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 8,
    },
    titleOverRule: {
      display: 'inline-block',
      whiteSpace: 'pre',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '30ch',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '25ch',
      },
    },
    cardContentRows: {
      padding: 0,
      margin: 0,
    },
    contentRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 0,
      gap: 8,
    },
    rowOverRule: {
      display: 'inline-block',
      whiteSpace: 'pre',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '29ch',
    },
    rowTitle: {
      color: theme.palette.common.graySubtitle,
      paddingRight: 8,
      display: 'inline',
    },
    rowText: {
      color: theme.palette.common.blackRegular,
      display: 'inline',
      '& > *': {
        display: 'inherit',
      },
    },
    cardActions: {
      justifyContent: 'space-between',
      padding: '0px',
      gap: 8,
      width: '100%',
    },
    cardActionsButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
      justifyContent: 'flex-start',
      width: '100%',
    },
  }),
);
