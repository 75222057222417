import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.printPDF || initialState;

export const selectPrintPDF = createSelector([selectSlice], state => state);
export const selectPrintPDFPrinting = createSelector(
  [selectPrintPDF],
  state => state.printing,
);
export const selectPrintPDFSections = createSelector([selectPrintPDF], state =>
  state.useSections ? state.Sections : [],
);
export const selectPrintPDFisLoading = createSelector(
  [selectPrintPDF],
  state => state.isLoading,
);
