import { IViewState, ViewTypes } from './Scheduler/slice/types';
import { generatePath } from 'react-router';
import { calendarPageFullPath } from './index';
import { useSelector } from 'react-redux';
import { selectGlobalDbSetting_EnableLegacyScheduler } from 'app/slice/globalDbSettingsSelectors';
import { toQueryString } from 'utils/url-utils';
import { dateUtils } from 'utils/date-utils';

/**
 * Generates path of the scheduler (calendar/timeline) page
 * @param params
 * @returns
 */
export const GetCalendarPagePath = (params: IViewState) =>
  GetSchedulerPagePath(params.viewType);

export const GetSchedulerPagePath = (viewType: ViewTypes) =>
  generatePath(calendarPageFullPath, {
    type: viewType,
  });

export const useCalendarLink = (props?: {
  equipments?: Array<number | undefined>;
  start?: string | Date;
  openToBook?: boolean;
  viewType: ViewTypes;
  defaultMulti?: boolean;
}): string => {
  const EnableLegacyScheduler = useSelector(
    selectGlobalDbSetting_EnableLegacyScheduler,
  );
  if (EnableLegacyScheduler) {
    switch (props?.viewType) {
      case 'calendar':
        const params = {
          id: props?.equipments
            ?.filter(f => f !== undefined)
            ?.map(r => `os${r}`)
            ?.join(','),
          Start:
            props?.start === undefined
              ? undefined
              : dateUtils.formatQueryStringDate(
                  dateUtils.parseISO(props?.start),
                ),
          openToBook: props?.openToBook,
        };

        return `/Weekly.aspx?${toQueryString(params)}`;
      case 'timeline':
        return `/Timeline.aspx?${toQueryString({
          services: props?.equipments?.filter(f => f !== undefined)?.join(','),
          Start:
            props?.start === undefined
              ? undefined
              : dateUtils.formatQueryStringDate(
                  dateUtils.parseISO(props?.start),
                ),
          openToBook: props?.openToBook,
        })}`;
    }
  }
  return `/schedule/${props?.viewType.toLowerCase()}?${toQueryString({
    eid: props?.equipments
      ?.filter(f => f !== undefined)
      ?.map(r => `${r}`)
      ?.join(','),
    Start:
      props?.start === undefined
        ? undefined
        : dateUtils.formatQueryStringDate(dateUtils.parseISO(props?.start)),
    openToBook: props?.openToBook,
    defaultMulti: props?.defaultMulti,
  })}`;
};
