import BasicTypography from 'app/components/Typography/BasicTypography';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { uniqueId } from 'lodash';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import { Button } from 'app/components/BasicButtons/Button';
import { Box } from 'app/components/basic/Wrappers/Box';

export interface ImportProps {
  onDownloadTemplateButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  processing: boolean;
  title?: string;
  accept: '.xlsx' | '.xls' | '.csv';
  disabled?: boolean;
  hideImportButton?: boolean;
  hideMessage?: boolean;
}
export interface ImportComponentProps extends ImportProps {
  handleFileChange: (file: File) => void;
}
export function ImportComponent(props: ImportComponentProps) {
  const { t } = useTranslation();
  const ref = React.useRef<HTMLInputElement>(null);
  const handleFileChange = event => {
    const file = ref.current?.files?.[0];
    if (file !== undefined) {
      props.handleFileChange(file);
    }
  };

  const [fileId] = React.useState(uniqueId('import-file-'));
  const [buttonId] = React.useState(uniqueId('import-file-'));
  return (
    <Box>
      {props.onDownloadTemplateButtonClick !== undefined && (
        <BasicTypography>
          {t(translations.ImportRecordsDescription)}
          <InfoIcon title={parse(t(translations.ImportRecordsSteps))} />
        </BasicTypography>
      )}
      {props.onDownloadTemplateButtonClick !== undefined && (
        <BasicTypography>
          <label htmlFor={buttonId}>
            {t(translations.ToImportNewRecords)}:
          </label>
          &emsp;
          <Button id={buttonId} onClick={props.onDownloadTemplateButtonClick}>
            {t(translations.DownloadTemplate)}
          </Button>
        </BasicTypography>
      )}
      <BasicTypography>
        {props.hideMessage === undefined ||
          (props.hideMessage === false && (
            <label htmlFor={fileId}>
              {t(translations.UploadTheUpdatedFile)}:
            </label>
          ))}
        &emsp;
        <Box>
          <input
            id={fileId}
            type="file"
            name="file"
            ref={ref}
            accept={props.accept}
            onChange={handleFileChange}
          />
        </Box>
      </BasicTypography>
    </Box>
  );
}
