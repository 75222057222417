import * as React from 'react';
import { Button } from 'app/components/BasicButtons/Button';
import { FilterIconButton } from 'app/components/BasicButtons/FilterIconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { DetectIsMobile } from 'utils/mobileDetect';
import { bookitColors } from 'styles/colors/bookitColors';

export interface FiltersButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  appliedFiltersLength?: number;
  allFiltersLength?: number;
  innerRef?: React.RefObject<HTMLButtonElement>;
}

export function FiltersButton({
  onClick,
  appliedFiltersLength,
  allFiltersLength,
  ...props
}: FiltersButtonProps) {
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  if (isMobile) {
    return (
      <FilterIconButton
        filterLength={allFiltersLength ?? 0}
        handleClick={onClick}
      />
    );
  }
  return (
    <Button
      aria-label={t(translations.Filters)}
      variant="gray"
      role="button"
      size="small"
      innerRef={props.innerRef}
      onClick={onClick}
      startIcon={<Icon icon="filter" aria-label="Filter" role="button" />}
      endIcon={
        !!appliedFiltersLength && (
          <span className="fa-layers fa-fw fa-lg">
            <Icon icon={['fas', 'circle']} color="primary" colorExtend="main" />
            <span
              className="fa-layers-text fa-inverse"
              style={{
                fontSize: '9px',
                color: bookitColors.primary.bgLight,
              }}
            >
              {appliedFiltersLength}
            </span>
          </span>
        )
      }
    >
      {t(translations.Filters)}
    </Button>
  );
}
