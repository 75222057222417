import * as React from 'react';
import { IFilterSettings } from './BasicFilter/IFilterSettings';
import { filterKey } from './BasicFilter/utils';
import { EntityType } from 'types/common';

/**
 * Memoes the array of filter to be used in the fetch data functions. Tracks the current state of the filters by the filter key (fieldName,fieldName1/etc) + filter value without the display name in case of Entity<T> value
 * @param props
 * @returns
 */
export const useMemoFilters = <TRow>(props: {
  appliedFilters?: IFilterSettings<TRow>[];
}) => {
  const nonEmpty = props.appliedFilters?.filter(
    f => f.value !== null && f.value !== undefined,
  );
  const singleValueToId = value => {
    return (value as EntityType)?.Id !== undefined
      ? (value as EntityType)?.Id
      : value;
  };

  const singleValue = value => {
    return Array.isArray(value)
      ? value.map(singleValueToId)
      : singleValueToId(value);
  };
  const mapped = nonEmpty?.map(f => ({
    key: filterKey(f),
    value: singleValue(f.value),
    operator: f.operator,
    isInversed: f.isInversed,
  }));

  const customFilter = React.useMemo(() => {
    const result = nonEmpty;
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(mapped)]);
  return customFilter;
};
