import DateRenderer from 'app/components/BasicTable/CellRenderers/DateRenderer';
import { parse } from 'app/components/CommentsContainer/commentParser';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';
import { IPayHistory } from './IPayHistory';

export const getColumns = (t: TFunction) => {
  const res: Column<IPayHistory>[] = [
    {
      accessor: 'InvoicePaymentId',
      Header: t(translations.HashSymbol) as string,
    },
    {
      accessor: 'FieldName',
      Header: t(translations.Field) as string,
    },
    {
      accessor: 'OldValue',
      Header: t(translations.OldValue) as string,
      Cell: ({ value }) => {
        if (!value) return <></>;
        return <span style={{ display: 'flex' }}> {parse(value)}</span>;
      },
    },
    {
      accessor: 'NewValue',
      Header: t(translations.NewValue) as string,
      Cell: ({ value }) => {
        if (!value) return <></>;
        return <span style={{ display: 'flex' }}> {parse(value)}</span>;
      },
    },
    {
      accessor: 'DisplayName',
      Header: t(translations.User) as string,
    },
    {
      accessor: 'UpdatedAt',
      Header: t(translations.Date) as string,
      Cell: DateRenderer,
    },
  ];
  return res;
};
