import { Check, CheckProps } from '../BasicInputs/Check';
import * as React from 'react';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import FormControl from '../Forms/FormControls/FormControl';
import FormControlLabel from '../Forms/FormControls/FormControlLabel';
import FormHelperText from '../Forms/FormControls/FormHelperText';
import clsx from 'clsx';
import FormGroup from '../Forms/FormControls/FormGroup';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';

export interface BasicCheckBoxProps
  extends Omit<CheckProps, 'onChange' | 'danger'> {
  label?: string;
  error?: string;
  helperText?: string;
  info?: React.ReactNode;
  fullWidth?: boolean;
  name?: string;
  boxed?: boolean;
  maxLabelWidth?: string | number;
  onChange?: (
    value: boolean,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

export function BasicCheckBox(props: BasicCheckBoxProps) {
  // const StyledInfo = styled(InfoIcon)(({ theme }) => ({
  //   marginLeft: theme.spacing(1),
  // }));
  const {
    label,
    error,
    helperText,
    info,
    name,
    onChange,
    boxed,
    fullWidth,
    maxLabelWidth = 25,
    ...other
  } = props;
  // const LabelWithInfo = () => {
  //   return (
  //     <React.Fragment>
  //       {label}
  //       {info && <StyledInfo title={info} />}
  //     </React.Fragment>
  //   );
  // };
  const [isChecked, setIsChecked] = React.useState<boolean>(
    props.checked ?? false,
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked as boolean;
    if (onChange !== undefined) {
      onChange(value, event);
    }
    setIsChecked(value);
  };
  const withInfoLabel = React.useMemo(() => {
    return !!label && label.length > maxLabelWidth;
  }, [label, maxLabelWidth]);
  const Label = withInfoLabel ? (
    <Tooltip title={<>{label}</>}>
      <span>{label}</span>
    </Tooltip>
  ) : (
    <React.Fragment>{label}</React.Fragment>
  );
  const baseLabelStyle: React.CSSProperties = React.useMemo(() => {
    return withInfoLabel
      ? { maxWidth: `${maxLabelWidth}ch`, gap: 12 }
      : { gap: 12 };
  }, [maxLabelWidth, withInfoLabel]);
  return (
    <FormControl
      error={error !== undefined}
      fullWidth={fullWidth}
      className={clsx({
        boxed: boxed,
      })}
    >
      <FormGroup
        row={true}
        className={clsx({
          withInfo: info !== undefined,
          boxed: boxed,
          boxedChecked: isChecked,
          boxedError: error !== undefined,
        })}
      >
        <FormControlLabel
          label={Label}
          style={baseLabelStyle}
          className={clsx({
            withInfo: info !== undefined,
            boxed: boxed,
            boxedChecked: isChecked,
            boxedError: error !== undefined,
          })}
          control={
            <Check
              name={name}
              color={props.color || 'primary'}
              withPadding={false}
              danger={error !== undefined}
              onChange={handleChange}
              checked={isChecked}
              {...other}
            />
          }
        />
        {info && <InfoIcon title={info} />}
      </FormGroup>
      {((!!error && error !== '') || (!!helperText && helperText !== '')) && (
        <FormHelperText
          error={error !== undefined}
          className={clsx({
            boxed: boxed,
          })}
        >
          {error || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
