/**
 *
 * Asynchronously loads the component for ConfigurableColumnExportImport Page
 *
 */
import { lazyLoad } from 'utils/loadable';

export const ConfigurableColumnExportImportPage = lazyLoad(
  () => import('./index'),
  module => module.ConfigurableColumnExportImportPage,
);
