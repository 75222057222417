import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
  styled,
} from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import * as React from 'react';

import cliTruncate from 'cli-truncate';
import { uploadStyle } from './styles';
import { useSelector } from 'react-redux';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { FileView } from './FileView';
import { buttonM } from 'styles/typography/bookitTypography';

export interface FileInputProps extends InputProps {
  className?: string;
  onFileChange?: (file: FileList | null) => void;
  label?: string;
  multiple?: boolean;
  uploadLabel?: string;
  showPreview?: boolean;
  removeable?: boolean;
  onRemove?: (value: string) => void;
  acceptedFiles?: string;
  isRequired?: string;
  helperText?: string;
  errorMesage?: string;
  info?: string;
  referenceId?: number;
  file?: IFormFileValue;
}

const StyledFormHelperText = styled(FormHelperText)(props => ({
  // empty helperText results in zero element height. So when field is validated the appearance of the
  // error message in the helperText causes the form element to increase in height and forces a redraw
  // empty char \00a0 causes the FormHelperText to always occupy one line height and prevents reflows
  '&::before': {
    content: '"\\00a0"',
  },
}));
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.common.blackRegular,
  ...buttonM,
  '&[class*="Mui-focused"]': {
    color: theme.palette.common.blackRegular,
  },
}));
export function FileInput(props: FileInputProps) {
  const {
    className,
    onFileChange,
    onRemove,
    id,
    multiple,
    uploadLabel,
    helperText,
    errorMesage,
    onChange,
    label,
    file,
  } = props;
  const classes = uploadStyle();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  //const [file, setFile] = React.useState<string | undefined>(undefined);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    } else {
      const files = event.target.files;
      if (onFileChange) {
        onFileChange(files);
      }
    }
    // if (event.target.files?.length) {
    //   setFileName(event.target.files[event.target.files?.length - 1].name);
    // }
  };
  const removeFile = (fileName: string) => {
    if (onRemove) {
      onRemove(fileName);
    }
    //setFileName(undefined);
  };
  return (
    <FormControl
      required={props.required}
      error={props.error}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      variant="filled"
    >
      {label && (
        <StyledFormLabel
          htmlFor={id || 'standart-uploader'}
          style={{ marginBottom: '8px' }}
        >
          {label}
        </StyledFormLabel>
      )}
      <Button
        component="label"
        style={{ display: `${file !== undefined ? 'none' : 'flex'}` }}
        classes={{
          root: classes.buttonRoot,
          label: classes.buttonLabel,
          endIcon: classes.endIcon,
        }}
        endIcon={
          multiple && file !== undefined ? (
            <IconButton
              variant="ghost"
              shape="square"
              onClick={() => removeFile(file.DisplayValue || '')}
              children={<Icon icon="times" />}
              size="small"
            />
          ) : props.info ? (
            <InfoIcon title={props.info} />
          ) : undefined
        }
      >
        <span>{uploadLabel ? uploadLabel : 'Upload File'}</span>
        <Divider
          orientation="vertical"
          variant="fullWidth"
          classes={{ root: classes.dividerRoot }}
        />
        {multiple && file ? (
          <Body size="small">
            {cliTruncate(file.DisplayValue || '', sidePanelExpanded ? 35 : 22, {
              position: 'middle',
            })}
          </Body>
        ) : (
          <Body size="small">{'Please select a file...'}</Body>
        )}
        <Input
          id={id || 'standart-uploader'}
          className={className}
          value={props.value}
          name={props.name}
          onChange={handleChange}
          type="file"
          style={{ display: 'none' }}
          inputProps={{ multiple: multiple }}
          aria-describedby="upload-input"
        />
      </Button>
      {!multiple && file !== undefined && (
        <FileView
          file={file}
          onRemove={() => removeFile(file.DisplayValue || ' ')}
        />
      )}
      <StyledFormHelperText
        id="upload-input"
        required={props.required}
        error={props.error}
        disabled={props.disabled}
      >
        {errorMesage ? (
          <span>{errorMesage}</span>
        ) : helperText ? (
          <span>{helperText}</span>
        ) : undefined}
      </StyledFormHelperText>
    </FormControl>
  );
}
