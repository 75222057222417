import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import * as React from 'react';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import clsx from 'clsx';

export interface InfoIconProps {
  title: NonNullable<React.ReactNode>;
  className?: string;
  error?: boolean;
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      zIndex: 1,
      '&.tableInfo': {
        position: 'absolute',
        top: '15%',
        left: '0px',
        '&>svg': {
          width: 13,
          height: 13,
        },
      },
    },
  }),
);
// const StyledTooltip = styled(Tooltip)`
//   cursor: pointer;
//   z-index: 1;
//   &.tableInfo {
//     position: absolute;
//     top: 20%;
//     left: 0px;
//   }
// `;
export const InfoIcon = (props: InfoIconProps) => {
  const { title, className, error } = props;
  const classes = styles();
  return (
    <Tooltip arrow title={title} className={clsx(classes.root, className)}>
      <span>
        <Icon
          icon="info-circle"
          color={error ? 'danger' : 'default'}
          colorExtend="textHover"
          fontSize="13px"
          width="13px"
        />
      </span>
    </Tooltip>
  );
};
