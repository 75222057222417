/**
 *
 * UserGroupPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IUserGroupDto } from 'api/odata/generated/entities/IUserGroupDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import * as React from 'react';
import { Entity } from 'types/common';

export interface UserGroupPickerProps
  extends Omit<AutocompletePickerProps<IUserGroupDto>, 'loadData'> {}

const url = '/api/odata/v4/UserGroupFilter';
const restrictedUrl = '/api/odata/v4/UserGroupFilter/Restricted';
export const initUserGroupData = (initval: string) => {
  const params: {
    $orderby: string;
    $filter?: string;
    $select: string;
    $top: number;
    $skip: number;
  } = {
    $orderby: 'Name asc',
    $filter: new Condition<Entity<string>>(
      'Id',
      ODataOperators.Equals,
      initval,
    ).toString(),
    $select: 'Id,Name',
    $skip: 0,
    $top: 1,
  };
  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<string>[]);
};

export function UserGroupPicker(props: UserGroupPickerProps) {
  const loadData = getAutoCompleteLoadDataFn<IUserGroupDto>({
    url,
    predicates: props.predicates,
    select: [
      'Id',
      'Name',
      'DepartmentId',
      'DepartmentName',
      'InstituteId',
      'InstituteName',
      'InstituteTypeId',
      'InstituteTypeName',
      'AffiliatedGroups',
      'IsUserOwneGroup',
    ],
  });
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      id={props.id || 'usergroupId'}
      size={props.size}
      loadData={loadData}
      {...props}
    />
  );
}
export function RestrictedUserGroupPicker(props: UserGroupPickerProps) {
  const loadData = getAutoCompleteLoadDataFn<IUserGroupDto>({
    url: restrictedUrl,
    predicates: props.predicates,
    select: [
      'Id',
      'Name',
      'DepartmentId',
      'DepartmentName',
      'InstituteId',
      'InstituteName',
      'InstituteTypeId',
      'InstituteTypeName',
      'AffiliatedGroups',
      'IsUserOwneGroup',
    ],
  });
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      id={props.id || 'usergroupId'}
      size={props.size}
      loadData={loadData}
      {...props}
    />
  );
}
export function RestrictedFilter(
  departmentId?: number,
  instituteId?: number,
  instituteTypeId?: number,
  restricted?: boolean,
): (string | Condition<IUserGroupDto>)[] {
  const predicates: (string | Condition<IUserGroupDto>)[] = [];
  if (!!departmentId) {
    predicates.push(
      new Condition<IUserGroupDto>(
        'DepartmentId',
        ODataOperators.Equals,
        departmentId,
      ),
    );
  }
  if (!!instituteId) {
    predicates.push(
      new Condition<IUserGroupDto>(
        'InstituteId',
        ODataOperators.Equals,
        instituteId,
      ),
    );
  }
  if (!!instituteTypeId) {
    predicates.push(
      new Condition<IUserGroupDto>(
        'InstituteTypeId',
        ODataOperators.Equals,
        instituteTypeId,
      ),
    );
  }
  if (restricted) {
    predicates.push(
      new Condition<IUserGroupDto>(
        'IsUserOwneGroup',
        ODataOperators.Equals,
        true,
      ),
    );
  }
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}
