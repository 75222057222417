/**
 *
 * AsyncPicker
 *
 */
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { LocationPicker } from '../pickers/AutocompletePickers/LocationPicker';

export const FormLocationPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> & {
  label?: string;
  fullWidth: boolean;
  autoFocus?: boolean;
  name: string;
  placeholder: string;
  disabled?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
}) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <LocationPicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={value => helpers.setValue(value, true)}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        variant={props.variant}
      />
    </>
  );
};
