import * as React from 'react';
import { ButtonsVariant } from '../BasicButtons/Button';
import { HoverActions } from './HoverActions';
import { ClickableActions } from './ClickableActions';
import { IconProp } from 'app/AllIcons';

export interface ReactActionsProps {
  id: string;
  items: Array<React.ReactNode>;
  size?: 'large' | 'medium' | 'small' | 'xs';
  iconVariant?: ButtonsVariant;
  hoverMenu?: boolean;
  icon?: IconProp;
  /**
   * Keeps menu open after clicking the menu item.
   */
  keepOpen?: boolean;
  /**
   * Disables ripple on all menu items. Useful for menu items containing more advanced components like drop down.
   */
  disableRipple?: boolean;
}
export function ReactActions(props: ReactActionsProps) {
  const { hoverMenu, items } = props;
  // filter items that will render something
  const nonEmptyItems = React.useMemo(() => {
    return items.filter(
      f =>
        f !== null && f !== false && f !== undefined && f !== true && f !== '',
    );
  }, [items]);
  // if there's nothing to show in the menu then there's no point to show the menu itself
  if (nonEmptyItems.length === 0) {
    return <></>;
  }
  return (
    <React.Fragment>
      {hoverMenu ? (
        <HoverActions {...props} items={nonEmptyItems} />
      ) : (
        <ClickableActions {...props} items={nonEmptyItems} />
      )}
    </React.Fragment>
  );
}
