import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ExternalLink } from 'app/components/ExternalLink';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cliTruncate from 'cli-truncate';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useSelector } from 'react-redux';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { H4 } from 'app/components/Typography';
import { OpenMenuButton } from '../../TopBar/components/openMenuButton';
import { useAbsolute } from 'utils/url-utils';
import styled from 'styled-components';

export interface TitlePageProps {
  // title of the current screen
  title: string;
  // link to open the details page as its own url on a new browser tab
  pageLink?: string;
  useSidePanel?: boolean;
  buttonsLenth: number;
  expandPanel?: () => void;
  tooltip?: string;
  isCover?: boolean;
}
const TitleSection = styled('div')`
  display: flex;
  gap: 4px;
`;
const ShortTitle = styled('span')`
  cursor: pointer;
`;
export function TitlePage(props: TitlePageProps) {
  const { t } = useTranslation();
  const {
    title,
    pageLink,
    useSidePanel,
    buttonsLenth,
    expandPanel,
    tooltip,
    isCover,
  } = props;
  const [showCopy, setShowCopy] = React.useState<boolean>(false);
  const [copied, setCopied] = React.useState<boolean>(false);
  const isMobile = DetectIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const showShortView = React.useMemo(() => {
    return isMobile || (useSidePanel && !sidePanelExpanded) || isCover;
  }, [isCover, isMobile, sidePanelExpanded, useSidePanel]);
  const truncatedTitle = React.useMemo(() => {
    return showCopy
      ? cliTruncate(
          title,
          buttonsLenth === 1
            ? isCover
              ? 22
              : 24
            : buttonsLenth === 2
            ? isCover
              ? 18
              : 20
            : isCover
            ? 14
            : 16,
          { position: 'middle' },
        )
      : cliTruncate(
          title,
          buttonsLenth === 1
            ? isCover
              ? 28
              : 30
            : buttonsLenth === 2
            ? isCover
              ? 22
              : 24
            : isCover
            ? 18
            : 20,
          { position: 'end' },
        );
  }, [buttonsLenth, isCover, showCopy, title]);
  const absolutePageLink = useAbsolute(pageLink);
  return pageLink ? (
    <TitleSection
      onMouseEnter={() => setShowCopy(true)}
      onMouseLeave={() => setShowCopy(false)}
    >
      {isMobile && !useSidePanel && <OpenMenuButton iconSize="small" />}
      {expandPanel && (
        <IconButton
          variant="ghost"
          aria-label="minimize"
          size="small"
          shape="square"
          onClick={expandPanel}
        >
          <Icon
            icon={['fas', 'arrow-left']}
            color="default"
            colorExtend="textHover"
          />
        </IconButton>
      )}

      <ExternalLink href={pageLink}>
        <H4>
          {showShortView ? (
            <Tooltip title={tooltip ?? title}>
              {<ShortTitle>{truncatedTitle}</ShortTitle>}
            </Tooltip>
          ) : (
            title
          )}
        </H4>
      </ExternalLink>
      {showCopy && absolutePageLink !== undefined && (
        <IconButton
          variant="ghost"
          size="small"
          shape="square"
          style={{
            position: 'relative',
            display: 'inline',
          }}
          onClick={() => {
            navigator.clipboard.writeText(absolutePageLink);
            setCopied(true);
          }}
        >
          <Tooltip
            title={
              copied
                ? (t(translations.URLCopied) as string)
                : (t(translations.CopyURL) as string)
            }
            arrow
          >
            <Icon icon="chain" />
          </Tooltip>
        </IconButton>
      )}
    </TitleSection>
  ) : (
    <H4>
      {showShortView ? (
        <Tooltip title={tooltip ?? title}>
          {<span>{truncatedTitle}</span>}
        </Tooltip>
      ) : (
        title
      )}
    </H4>
  );
}
