import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { usePromise } from 'app/hooks/usePromise';
import { AddCoverConsumable } from 'app/pages/Actions/PageTopActions/AddConsumable';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import {
  selectBarcodeScanned,
  selectSelectedServiceType,
} from '../slice/selectors';
import * as React from 'react';
import { useOfflineServiceStateSlice } from '../slice';
import { loadOfflineServiceData } from 'app/components/pickers/AutocompletePickers/OfflineServiceTypePicker';

export interface AddServiceFromSelectionProps {
  onAddClick: (services: number[]) => void;
  getServiceTypes?: (
    searchTerm: string | null,
  ) => Promise<IOfflineServiceFilterDto[]>;
  addServices: (serviceType: IOfflineServiceFilterDto[]) => void;
  removeServices: (services: IOtherServices[]) => void;
  addAndRemoveServices: (
    serviceType: IOfflineServiceFilterDto[],
    services: IOtherServices[],
  ) => void;
  selected: IOtherServices[];
  disabled?: boolean;
}
export function AddServiceFromSelection(props: AddServiceFromSelectionProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();
  const {
    getServiceTypes,
    addServices,
    selected,
    disabled,
    onAddClick,
    removeServices,
    addAndRemoveServices,
  } = props;
  const newSelectedServices = useSelector(selectSelectedServiceType);
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const [updated, setUpdated] = React.useState<boolean | undefined>(undefined);
  const isBarcodeScanned = useSelector(selectBarcodeScanned);
  const fetchServiceTypes = async (): Promise<IOfflineServiceFilterDto[]> => {
    return new Promise(async (resolve, reject) => {
      if (!!getServiceTypes) {
        try {
          const data = await getServiceTypes(null);
          resolve(
            data.filter(f => !selected.some(s => s.ServiceTypeID === f.Id)),
          );
        } catch {
          resolve([]);
        }
      } else {
        try {
          const data = await loadOfflineServiceData(
            undefined,
            'base',
            globalServiceGroupFilter || [],
            undefined,
            'InventoryBatches',
          )(null, undefined);
          resolve(
            data.value.filter(
              f => !selected.some(s => s.ServiceTypeID === f.Id),
            ),
          );
        } catch (error) {
          resolve([]);
        }
      }
    });
  };
  const [serviceTypes, setServiceTypes] = useAsyncExtendedState<
    IOfflineServiceFilterDto[]
  >([]);
  const [fetchServiceTypeState, fetchServiceTypePromise] = usePromise(
    fetchServiceTypes,
  );
  React.useEffect(() => {
    if (
      newSelectedServices.length > 0 &&
      !updated &&
      fetchServiceTypeState.status !== 'pending'
    ) {
      setServiceTypes(fetchServiceTypePromise());
      setUpdated(true);
    }
  }, [
    actions,
    dispatch,
    fetchServiceTypePromise,
    fetchServiceTypeState.status,
    newSelectedServices,
    newSelectedServices.length,
    selected,
    serviceTypes,
    setServiceTypes,
    updated,
  ]);
  React.useEffect(() => {
    let added = newSelectedServices.filter(
      f => !selected.some(s => s.ServiceTypeID === f),
    );
    let removed = isBarcodeScanned
      ? []
      : selected.filter(
          f => !newSelectedServices.some(c => c === f.ServiceTypeID),
        );
    if (
      (added.length > 0 || removed.length > 0) &&
      updated &&
      fetchServiceTypeState.status === 'resolved' &&
      serviceTypes.length > 0
    ) {
      setUpdated(undefined);
      dispatch(actions.resetSelectedServiceType());
      dispatch(actions.setBarcodeScanned(undefined));

      if (added.length > 0 && removed.length > 0) {
        addAndRemoveServices(
          serviceTypes.filter(f => added.includes(f.Id)),
          removed,
        );
      } else {
        if (added.length > 0) {
          addServices(serviceTypes.filter(f => added.includes(f.Id)));
        }
        if (removed.length > 0) {
          removeServices(removed);
        }
      }

      setServiceTypes([]);
    }
  }, [
    actions,
    addAndRemoveServices,
    addServices,
    dispatch,
    fetchServiceTypeState.status,
    isBarcodeScanned,
    newSelectedServices,
    removeServices,
    selected,
    serviceTypes,
    setServiceTypes,
    updated,
  ]);
  return (
    <AddCoverConsumable
      compact={false}
      text={t(translations.AddConsumable) as string}
      size="small"
      variant="white"
      services={selected.map(f => f.ServiceTypeID)}
      disabled={disabled}
      onAddClick={onAddClick}
    />
  );
}
