/**
 *
 * ServiceRequestTicketDetails
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import { IServiceRequestTicketDto } from 'api/odata/generated/entities/IServiceRequestTicketDto';

import { useDispatch, useSelector } from 'react-redux';
import {
  IServiceRequestTicketEdit,
  getInitialValues,
  getTicketSchema,
} from 'app/pages/ServiceRequestTicketDetailsPage/ServiceRequestTicketDetails/schema';
import { useServiceRequestsKanbanSlice } from 'app/pages/ServiceRequestsKanbanPage/slice';

import { Form } from '../Form';
import { Dto } from 'app/pages/ServiceRequestsKanbanPage/slice/types';
import { selectServiceRequestsKanbanProcessingInsert } from 'app/pages/ServiceRequestsKanbanPage/slice/selectors';
import { selectKnownModule } from 'app/slice/selectors';
import { KnownModules } from 'types/KnownModules';
import { omit } from 'lodash';

export interface AddSubTicketProps {
  parentTicket: IServiceRequestTicketDto;
  useSidePanel: boolean;
}
export function AddSubTicket({ parentTicket, ...props }: AddSubTicketProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useServiceRequestsKanbanSlice();
  const processing = useSelector(selectServiceRequestsKanbanProcessingInsert);
  const samplesAutomationEnabled = useSelector(state =>
    selectKnownModule(state, KnownModules.SamplesAutomation),
  );

  const schema = React.useMemo(
    () =>
      getTicketSchema(
        t,
        {
          Id: 0,
          AssignedTo: parentTicket.AssignedTo,
          AssignedTo_Id: parentTicket.AssignedTo_Id,
          Index: parentTicket.Index + 1,
          Priority: parentTicket.Priority,
          SamplesCount: null,
          ServiceRequest: parentTicket.ServiceRequest,
          ServiceRequestId: parentTicket.ServiceRequestId,
          ServiceRequestStatusId: parentTicket.ServiceRequestStatusId,
          Status: parentTicket.Status,
          SubSamplesCount: parentTicket.SubSamplesCount,
          TotalSamplesCount: parentTicket.TotalSamplesCount,
          ParentTicketId: parentTicket.Id,
          ParentTicket: parentTicket,
        },
        samplesAutomationEnabled,
      ),
    [parentTicket, samplesAutomationEnabled, t],
  );
  const initialValues = React.useMemo(
    () =>
      getInitialValues(
        {
          ParentTicketId: parentTicket.Id,
          AssignedTo: parentTicket.AssignedTo,
          Status: parentTicket.Status,
          Priority: parentTicket.Priority,
        } as Dto,
        t,
      ),
    [parentTicket, t],
  );
  const handleSubmit = React.useCallback(
    (values: IServiceRequestTicketEdit) => {
      dispatch(
        actions.insert({
          ...values,
          ParentTicket: parentTicket,
          // insert after the parent ticket
          Index: parentTicket.Index + 1,
          ServiceRequestId: parentTicket.ServiceRequestId,
        }),
      );
    },
    [actions, dispatch, parentTicket],
  );
  const item: Partial<Dto> = {
    ...omit(parentTicket, 'Id', 'SamplesCount'),
    ParentTicketId: parentTicket.Id,
    SamplesCount: parentTicket.SamplesCount,
  };

  const handleCancel = () => dispatch(actions.showAddSubTicket());

  const title = t(translations.AddSubServiceRequestTicket);

  return (
    <PageWrapper
      topProcessing={processing}
      titlePage={title}
      closable
      disableExpandToggle
      hideTitleBar={false}
      useSidePanel={props.useSidePanel}
      closeCover={handleCancel}
      isCover={true}
    >
      <>
        <Form
          schema={schema}
          item={item}
          processing={processing}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </>
    </PageWrapper>
  );
}
