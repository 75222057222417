/**
 *
 * Alert
 *
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { messages } from './messages';
import { useDialogAlertSlice } from './slice';
import { selectDialogAlert } from './slice/selectors';
import { useDispatch, useSelector } from 'react-redux';

export const DialogAlert = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useDialogAlertSlice();
  const { open, content } = useSelector(selectDialogAlert);
  const handleClose = () => {
    dispatch(actions.hideAlert());
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t(messages.alert())}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t(messages.ok())}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
