/**
 *
 * CustomFormField
 *
 */
import React from 'react';
import { CustomFormFieldProps } from 'app/components/CustomForm/CustomFormFields';
import { FormTextField } from 'app/components/Forms/FormTextField';

export function CustomFormEmail({
  formField,
  readonly,
  disabled,
  ...props
}: CustomFormFieldProps) {
  return (
    <FormTextField
      {...props}
      disabled={disabled || readonly}
      label={formField.Label}
      key={formField.Id}
      helperText={formField.Description ?? undefined}
      fullWidth
    />
  );
}
