/**
 *
 * InvoiceIdMultiPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { ODataFilterBuilder } from 'api/odata/ODataFilter';
import {
  AutocompleteMultiPicker,
  AutocompleteMultiPickerProps,
} from 'app/components/BasicPickers/AutocompleteMultiPicker';
import * as React from 'react';
import { Entity } from 'types/common';

export interface InvoiceIdProps
  extends Omit<AutocompleteMultiPickerProps<number>, 'loadData'> {}

const url = '/api/odata/v4/InvoiceIdFilter';
export const initInvoiceIdData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const ids = initval.split('|').map(v => parseInt(v));
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Id asc',
      $filter: '(Id in (' + ids.join(',') + '))',
      $select: 'Id,Name',
      $skip: 0,
      $top: ids.length,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (searchTerm: string | null): Promise<Entity<number>[]> => {
  const params: { $orderby: string; $filter?: string } = {
    $orderby: 'Id asc',
  };
  if (searchTerm !== null) {
    params.$filter = new ODataFilterBuilder<Entity<number>>({
      predicates: [],
      stringColumns: ['Name'],
      stringSearch: searchTerm,
    }).toString();
  }

  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<number>[]);
};
export function InvoiceIdMultiPicker(props: InvoiceIdProps) {
  return (
    <AutocompleteMultiPicker
      mini={!!props.mini}
      size={props.size}
      id={props.id || 'invoiceId'}
      loadData={loadData}
      ariaLabel="Invoice Id Filter"
      {...props}
    />
  );
}
