import { PageTypeProps, RenderPageType } from '../../slice/type';
import * as React from 'react';
import {
  TrainingDetails,
  TrainingDetailsProps,
} from 'app/pages/UserTrainings/TrainingDetailsPage/Details';
import { StartQuiz, StartQuizProps } from 'app/pages/QuizPage/StartQuiz';
import {
  QuizDetails,
  QuizDetailsProps,
} from 'app/pages/QuizDetailsPage/QuizDetails';
import { AssetPopUp, AssetPopUpProps } from 'app/pages/AssetPopUp';
import {
  LocationDetails,
  LocationDetailsProps,
} from 'app/pages/LocationsPage/Details';
import {
  WorkOrderDetails,
  WorkOrderDetailsProps,
} from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { AddPayment, PaymentProps } from 'app/pages/AddPaymentPage';
import { AddCharge, ChargeProps } from 'app/pages/AddChargePage';
import {
  InvoiceDetailsSidePanel,
  InvoiceDetailsSidePanelProps,
} from 'app/pages/InvoiceDetails/InvoiceDetailsSidePanel';
import {
  BillingActionsDetails,
  BillingActionsDetailsProps,
} from 'app/pages/BillingActions/BillingActionsDetailsPage/Details';
import {
  SavedViewDetails,
  SavedViewDetailsProps,
} from 'app/pages/SavedViewsPage/SavedViewPage/Details';
import { LabelTypePage } from 'app/pages/LabelTypesPage/LabelTypeEdit/Loadable';
import { LabelTypeEditProps } from 'app/pages/LabelTypesPage/LabelTypeEdit';
import {
  InstrumentRestrictions,
  InstrumentRestrictionsProps,
} from 'app/pages/InstrumentRestrictionsPage/Details/InstrumentRestrictions';
import {
  ReservationDetails,
  ReservationDetailsProps,
} from 'app/pages/ReservationDetails/Details';
import {
  InvoiceTemplatePopUp,
  InvoiceTemplatePopUpProps,
} from 'app/pages/InvoiceTemplates/InvoiceTemplatePopUp';
import {
  OtherServiceDetails,
  OtherServiceDetailsProps,
} from 'app/pages/OtherServiceDetails/Details';
import {
  PricePeriodDetails,
  PricePeriodDetailsProps,
} from 'app/pages/PriceSheetPeriodDetails/Details';
import {
  ArticleDetails,
  ArticleDetailsProps,
} from 'app/pages/ArticlesPage/ArticleDetails';
import {
  CoreSelection,
  CoreSelectionProps,
} from 'app/pages/CoreSelectionPage/CoreSelection';
import {
  AssetsSelection,
  AssetsSelectionProps,
} from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import {
  NewArticleDetailsForm,
  NewArticleDetailsFormProps,
} from 'app/pages/ArticlesPage/NewArticleDetailsForm';
import {
  EditArticleDetailsForm,
  EditArticleDetailsFormProps,
} from 'app/pages/ArticlesPage/EditArticleDetailsForm';
import {
  UserAttributesDetails,
  UserAttributesDetailsProps,
} from 'app/pages/UserAttributes/UserAttributesDetailsPage/Details';
import {
  ServiceRequestDetails,
  ServiceRequestDetailsProps,
} from 'app/pages/Samples/RequestSamplesPage';
import {
  ServiceRequestTicketDetails,
  ServiceRequestTicketDetailsProps,
} from 'app/pages/ServiceRequestTicketDetailsPage/ServiceRequestTicketDetails';
import {
  ReportConsumables,
  ReportConsumablesProps,
} from 'app/pages/OtherServiceDetails/ReportConsumables';
import {
  CalendarMessagesDetails,
  CalendarMessagesDetailsProps,
} from 'app/pages/CalendarMessages/CalendarMessagesDetails/Details';
import {
  ServiceRequestMilestoneChargeDetails,
  ServiceRequestMilestoneChargeDetailsProps,
} from 'app/pages/Samples/components/ServiceRequestMilestoneChargeDetails';
import {
  ServiceRequestMilestoneDetails,
  ServiceRequestMilestoneDetailsProps,
} from 'app/pages/Samples/components/ServiceRequestMilestoneDetails';
import {
  StockRenewal,
  StockRenewalProps,
} from 'app/pages/OtherServiceDetails/StockRenewal';
import {
  RenewStock,
  RenewStockProps,
} from 'app/pages/OtherServiceDetails/RenewStock';
import {
  InventoryBatch,
  InventoryBatchProps,
} from 'app/pages/OtherServiceDetails/InventoryBatch';
import {
  InvoiceBatchesComments,
  InvoiceBatchesCommentsProps,
} from 'app/pages/InvoiceBatches/InvoiceBatchesComments';

export interface SidePanelRendererProps {
  renderPageType: RenderPageType;
  renderPageProps: PageTypeProps;
}

export function SidePanelRenderer(props: SidePanelRendererProps) {
  const { renderPageType, renderPageProps } = props;

  switch (renderPageType) {
    case RenderPageType.TrainingDetails:
      return <TrainingDetails {...(renderPageProps as TrainingDetailsProps)} />;
    case RenderPageType.QuizCreate:
      return <StartQuiz {...(renderPageProps as StartQuizProps)} />;
    case RenderPageType.QuizzDetails:
      return <QuizDetails {...(renderPageProps as QuizDetailsProps)} />;
    case RenderPageType.LocationDetails:
      return <LocationDetails {...(renderPageProps as LocationDetailsProps)} />;
    case RenderPageType.AssetDetails:
      return <AssetPopUp {...(renderPageProps as AssetPopUpProps)} />;
    case RenderPageType.WorkOrderDetails:
      return (
        <WorkOrderDetails {...(renderPageProps as WorkOrderDetailsProps)} />
      );
    case RenderPageType.AddCharge:
      return <AddCharge {...(renderPageProps as ChargeProps)} />;
    case RenderPageType.AddPayment:
      return <AddPayment {...(renderPageProps as PaymentProps)} />;
    case RenderPageType.InvoiceDetails:
      return (
        <InvoiceDetailsSidePanel
          {...(renderPageProps as InvoiceDetailsSidePanelProps)}
        />
      );
    case RenderPageType.AddBillingAction:
      return (
        <BillingActionsDetails
          {...(renderPageProps as BillingActionsDetailsProps)}
        />
      );
    case RenderPageType.SavedViewDetails:
      return (
        <SavedViewDetails {...(renderPageProps as SavedViewDetailsProps)} />
      );
    case RenderPageType.LabelType:
      return <LabelTypePage {...(renderPageProps as LabelTypeEditProps)} />;
    case RenderPageType.OtherServiceDetails:
      return (
        <OtherServiceDetails
          {...(renderPageProps as OtherServiceDetailsProps)}
        />
      );
    case RenderPageType.RestrictionDetails:
      return (
        <InstrumentRestrictions
          {...(renderPageProps as InstrumentRestrictionsProps)}
        />
      );
    case RenderPageType.ReservationDetails:
      return (
        <ReservationDetails {...(renderPageProps as ReservationDetailsProps)} />
      );
    case RenderPageType.PricePeriodsDetails:
      return (
        <PricePeriodDetails {...(renderPageProps as PricePeriodDetailsProps)} />
      );
    case RenderPageType.Article:
      return <ArticleDetails {...(renderPageProps as ArticleDetailsProps)} />;
    case RenderPageType.ArticleNew:
      return (
        <NewArticleDetailsForm
          {...(renderPageProps as NewArticleDetailsFormProps)}
        />
      );
    case RenderPageType.ArticleEdit:
      return (
        <EditArticleDetailsForm
          {...(renderPageProps as EditArticleDetailsFormProps)}
        />
      );
    case RenderPageType.InvoiceTemplatePopUp:
      return (
        <InvoiceTemplatePopUp
          {...(renderPageProps as InvoiceTemplatePopUpProps)}
        />
      );
    case RenderPageType.CoreSelection:
      return <CoreSelection {...(renderPageProps as CoreSelectionProps)} />;
    case RenderPageType.AssetsSelection:
      return <AssetsSelection {...(renderPageProps as AssetsSelectionProps)} />;
    case RenderPageType.UserAttributesDetails:
      return (
        <UserAttributesDetails
          {...(renderPageProps as UserAttributesDetailsProps)}
        />
      );
    case RenderPageType.ServiceRequestDetails:
      return (
        <ServiceRequestDetails
          {...(renderPageProps as ServiceRequestDetailsProps)}
        />
      );
    case RenderPageType.ServiceRequestTicketDetails:
      return (
        <ServiceRequestTicketDetails
          {...(renderPageProps as ServiceRequestTicketDetailsProps)}
        />
      );
    case RenderPageType.CalendarMessagesDetails:
      return (
        <CalendarMessagesDetails
          {...(renderPageProps as CalendarMessagesDetailsProps)}
        />
      );
    case RenderPageType.ReportConsumables:
      return (
        <ReportConsumables {...(renderPageProps as ReportConsumablesProps)} />
      );
    case RenderPageType.ServiceRequestMilestone:
      return (
        <ServiceRequestMilestoneDetails
          {...(renderPageProps as ServiceRequestMilestoneDetailsProps)}
        />
      );
    case RenderPageType.ServiceRequestMilestoneCharge:
      return (
        <ServiceRequestMilestoneChargeDetails
          {...(renderPageProps as ServiceRequestMilestoneChargeDetailsProps)}
        />
      );
    case RenderPageType.StockRenewal:
      return <StockRenewal {...(renderPageProps as StockRenewalProps)} />;
    case RenderPageType.RenewStock:
      return <RenewStock {...(renderPageProps as RenewStockProps)} />;
    case RenderPageType.InventoryBatch:
      return <InventoryBatch {...(renderPageProps as InventoryBatchProps)} />;
    case RenderPageType.InvoiceBatchesComments:
      return (
        <InvoiceBatchesComments
          {...(renderPageProps as InvoiceBatchesCommentsProps)}
        />
      );
    default:
      return <React.Fragment> </React.Fragment>;
  }
}
