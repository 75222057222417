import { translations } from 'locales/translations';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';

import { CampusPickerFilter } from 'app/components/BasicTable/Filters/CampusPickerFilter';
import { BuildingPickerFilter } from 'app/components/BasicTable/Filters/BuildingPickerFilter';
import { RoomPickerFilter } from 'app/components/BasicTable/Filters/RoomPickerFilter';

import { TagsFilter } from 'app/components/BasicTable/Filters/TagsFilter';
import { LoanableFilter } from 'app/components/BasicTable/Filters/LoanableFilter';
import { StatusFilter } from 'app/components/BasicTable/Filters/StatusFilter';
import { LockersFilter } from 'app/components/BasicTable/Filters/LockersFilter';
import { AssetCatGroupFilter } from 'app/components/BasicTable/Filters/AssetCatGroupFilter';
import { AssetCatFilter } from 'app/components/BasicTable/Filters/AssetCatFilter';
import {
  arrayToQueryString,
  assetRangeSerializer,
  getAssetRange,
  GetBooleanEntity,
  GetBooleanOrUndefined,
  GetDateOrUndefined,
  getNumberEntityArray,
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  getStringEntityArray,
  GetStringOrUndefined,
  initExPickerValue,
  initMultiExPickerValue,
  initMultiPickerValue,
  initPickerValue,
  URLSearchParamsCI,
} from 'app/components/BasicTable/types/FilterParam';
import { initCampusData } from 'app/components/pickers/AutocompletePickers/CampusPicker';
import { initBuildingData } from 'app/components/pickers/AutocompletePickers/BuildingPicker';
import { initRoomData } from 'app/components/pickers/AutocompletePickers/RoomPicker';
import { initLockersData } from 'app/components/pickers/AutocompletePickers/LockersPicker';
import { initTagsData } from 'app/components/pickers/AutocompletePickers/TagsPicker';
import { initAssetCatData } from 'app/components/pickers/AutocompletePickers/AssetCatPicker';
import { initAssetCatGroupData } from 'app/components/pickers/AutocompletePickers/AssetCatGroupPicker';
import { initLoanableData } from 'app/components/pickers/AutocompletePickers/LoanablePicker';
import { IsAssetFieldEnabled, IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { initStatusData } from 'app/components/pickers/AutocompletePickers/StatusPicker';
import BooleanPickerFilter from 'app/components/BasicTable/Filters/BooleanPickerFilter';
import { CustomDate } from 'types/CustomDate';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import {
  DatePickerFilter,
  TextFieldFilter,
} from 'app/components/BasicTable/Filters';
import { initAssetMultiData } from 'app/components/pickers/AutocompletePickers/AssetMultiPicker';
import { AssetMultiFilter } from 'app/components/BasicTable/Filters/AssetMultiFilter';
import { AssetTypesFilter } from 'app/components/BasicTable/Filters/AssemblyTypesFilter';
import { initAssemblyTypeData } from 'app/components/pickers/StaticOptionsPickers/AssemblyTypePicker';
import { AreasFilter } from 'app/components/BasicTable/Filters/AreasFilter';
import { initAreasData } from 'app/components/pickers/AutocompletePickers/AreassPicker';
import { initLocationData } from 'app/components/pickers/AutocompletePickers/LocationPicker';
import { LocationPickerFilter } from 'app/components/BasicTable/Filters/LocationPickerFilter';
import { SubLocationFilter } from 'app/components/BasicTable/Filters/SubLocationListFilter';
import { initSubLocationListData } from 'app/components/pickers/AutocompletePickers/SubLocationListPicker';
import {
  initInstrumentCatData,
  InstrumentCatFilter,
} from 'app/components/BasicTable/Filters/InstrumentCatFilter';
import { OnlineServiceTypeFilter } from 'app/components/BasicTable/Filters/OnlineServiceTypeFilter';
import { initOnlineServiceTypeData } from 'app/components/pickers/StaticOptionsPickers/OnlineServiceTypePicker';
import { initUserMultiData } from 'app/components/pickers/AutocompletePickers/UserMultiPicker';
import UserAssetContactMultiFilter from 'app/components/BasicTable/Filters/UserAssetContactMultiFilter';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import { initMyAssetAvailabilityTypeMultiData } from 'app/components/pickers/AutocompletePickers/MyAssetAvailabilityTypeMultiPicker';
import {
  assetRangeCustomFilter,
  assetRangeDisplay,
  AssetRangeFilter,
  initAssetRangeData,
} from 'app/components/BasicTable/Filters/AssetRangeFilter';
import { AssetAttributes } from 'enums/AssetAttributes';
import { IMyAssetsRow } from '../IMyAssetsRow';
import { AvailabilityTypeAvailableMultiFilter } from 'app/components/BasicTable/Filters/MyAssetAvailabilityTypeMultiPickerFilter';
import ServiceTypeEnumFilter from 'app/components/BasicTable/Filters/ServiceTypeEnumFilter';
import { initServiceTypeEnumData } from 'app/components/pickers/StaticOptionsPickers/ServiceTypeEnumPicker';
import ExtraDDLFilter from 'app/components/BasicTable/Filters/ExtraDDLFilter';
import { initExtraDDLData } from 'app/components/pickers/AutocompletePickers/ExtraddlPicker';

export const GetFilters: GetPageFilters<IMyAssetsRow> = (
  t,
  appSettings,
  user,
  settings,
) => search => {
  const myAssetsDefaultFilters: string[] = [
    ...(settings as GlobalSettings)
      .GetString(AllowedSettings.AdvancedAssetSearchFilterAttributes)
      .split(','),
  ];

  const params = new URLSearchParamsCI(search);
  var result: IFilterSettings<IMyAssetsRow>[] = [
    {
      fieldName: 'Id',
      queryStringSerializer: arrayToQueryString('aid'),
      displayName: t(translations.AssetName), //(displayed as Resource Name)
      operator: ODataOperators.Includes,
      value: getNumberEntityArray(params, 'aid'),
      initData: initMultiPickerValue(initAssetMultiData),
      component: AssetMultiFilter,
      urlKey: 'aid',
    },
    {
      fieldName: 'CampusId',
      displayName: t(translations.Campus),
      queryStringSerializer: 'CampusId',
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'CampusId')),
      ),
      initData: initPickerValue(initCampusData),
      component: CampusPickerFilter,
    },
    {
      fieldName: 'LocationId',
      queryStringSerializer: 'LocationId',
      displayName: t(translations.LocationsList),
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'LocationId')),
      ),
      initData: initPickerValue(initLockersData),
      component: LockersFilter,
    },
    {
      fieldName: 'AssetRecordDate',
      queryStringSerializer: 'AssetRecordDate',
      displayName: t(translations.CreatedSince),
      operator: ODataOperators.GreaterThanEqual,
      defaultValue: new CustomDate(),
      value: GetDateOrUndefined(GetRawValue(params, 'AssetRecordDate'), 'date'),
      component: DatePickerFilter,
    },
    {
      fieldName: 'Active',
      queryStringSerializer: 'active',
      displayName: t(translations.ActiveAssets),
      operator: ODataOperators.Equals,
      value: GetBooleanEntity(
        GetBooleanOrUndefined(GetRawValue(params, 'active')),
        t,
      ),
      component: BooleanPickerFilter,
    },
    {
      fieldName: 'InstrumentTypeId',
      queryStringSerializer: 'typeId',
      displayName: t(translations.InstrumentType),
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'typeId')),
      ),
      initData: initPickerValue(initAssemblyTypeData),
      component: AssetTypesFilter,
    },
    {
      fieldName: 'OperationTypeID',
      queryStringSerializer: 'OperationTypeID',
      displayName: t(translations.OperationType),
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'OperationTypeID')),
      ),
      customFilter: (value, setting) => {
        return `${new Condition(
          'AutoMode',
          ODataOperators.Equals,
          1,
        ).toString()} and ${new Condition(
          setting.fieldName,
          ODataOperators.Equals,
          value,
        ).toString()}`;
      },
      initData: initPickerValue(initOnlineServiceTypeData),
      component: OnlineServiceTypeFilter,
    },
    {
      fieldName: 'RoomAreaId',
      queryStringSerializer: 'areaId',
      displayName: t(translations.Areas),
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'areaId')),
      ),
      initData: initPickerValue(initAreasData),
      component: AreasFilter,
    },
    {
      fieldName: 'LocationId',
      queryStringSerializer: 'locationid',
      displayName: t(translations.LocationList),
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'locationid')),
      ),
      initData: initPickerValue(initLocationData),
      component: LocationPickerFilter,
    },
    {
      fieldName: 'SubLocationId',
      queryStringSerializer: 'sublocationid',
      displayName: t(translations.SubLocationsList),
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'sublocationid')),
      ),
      initData: initPickerValue(initSubLocationListData),
      component: SubLocationFilter,
    },
    {
      fieldName: 'InstrumentCatId',
      displayName: t(translations.InstrumentCat),
      queryStringSerializer: 'instrumentCatId',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'instrumentCatId')),
      ),
      operator: ODataOperators.Equals,
      component: InstrumentCatFilter,
      initData: initPickerValue(initInstrumentCatData),
    },
    {
      fieldName: 'StatusId',
      queryStringSerializer: 'StatusId',
      displayName: t(translations.Status),
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'StatusId')),
      ),
      initData: initPickerValue(initStatusData),
      component: StatusFilter,
    },
    {
      fieldName: 'ApprovalRequired',
      queryStringSerializer: 'approval',
      displayName: t(translations.ApprovalRequired),
      operator: ODataOperators.Equals,
      value: GetBooleanEntity(
        GetBooleanOrUndefined(GetRawValue(params, 'approval')),
        t,
      ),
      component: BooleanPickerFilter,
    },
  ];
  if (IsModuleEnabled(appSettings, KnownModules.LocationTracking)) {
    result.push({
      fieldName: 'LocationUpdatedAt',
      queryStringSerializer: 'locationNotUpdatedSince',
      displayName: t(translations.LocationNotUpdatedSince),
      operator: ODataOperators.LessThan,
      defaultValue: new CustomDate(),
      value: GetDateOrUndefined(
        GetRawValue(params, 'locationNotUpdatedSince'),
        'date',
      ),
      component: DatePickerFilter,
    });
  }
  if (
    IsModuleEnabled(appSettings, KnownModules.LoanDesk) &&
    myAssetsDefaultFilters.includes('Loanable')
  ) {
    result.push({
      fieldName: 'LoanTypeId',
      queryStringSerializer: 'LoanTypeId',
      displayName: t(translations.Loanable),
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'loantypeId')),
      ),
      initData: initPickerValue(initLoanableData),
      component: LoanableFilter,
    });
  }
  if (myAssetsDefaultFilters.includes('Tags')) {
    result.push({
      fieldName: 'TagsText',
      queryStringSerializer: arrayToQueryString('TagsText'),
      displayName: t(translations.Tags),
      operator: ODataOperators.ContainsM,
      value: getNumberEntityArray(params, 'TagsText'),
      initData: initMultiPickerValue(initTagsData),
      component: TagsFilter,
      urlKey: 'TagsText',
    });
  }
  if (myAssetsDefaultFilters.includes('AssetCatGroup')) {
    result.push({
      fieldName: 'AssetCatGroupId',
      queryStringSerializer: 'AssetCatGroupId',
      displayName: t(translations.AssetCatGroupName),
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'AssetCatGroupId')),
      ),
      initData: initPickerValue(initAssetCatGroupData),
      component: AssetCatGroupFilter,
      urlType: 'assetBase',
    });
  }
  if (myAssetsDefaultFilters.includes('AssetCat')) {
    result.push({
      fieldName: 'AssetCatId',
      queryStringSerializer: 'AssetCatId',
      displayName: t(translations.AssetCatName),
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'AssetCatId')),
      ),
      initData: initPickerValue(initAssetCatData),
      component: AssetCatFilter,
      urlType: 'assetBase',
    });
  }
  if (myAssetsDefaultFilters.includes('Building')) {
    result.push({
      fieldName: 'BuildingId',
      displayName: t(translations.Building),
      queryStringSerializer: 'BuildingId',
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'BuildingId')),
      ),
      initData: initPickerValue(initBuildingData),
      component: BuildingPickerFilter,
      urlType: 'assetBase',
    });
  }
  if (myAssetsDefaultFilters.includes('Room')) {
    result.push({
      fieldName: 'RoomId',
      queryStringSerializer: 'RoomId',
      displayName: t(translations.Room),
      operator: ODataOperators.Equals,
      value: GetPartialEntity(
        GetStringOrUndefined(GetRawValue(params, 'RoomId')),
      ),
      initData: initPickerValue(initRoomData),
      component: RoomPickerFilter,
    });
  }
  //if (myAssetsDefaultFilters.includes('Availability')) {
  result.push({
    fieldName: 'AvailabilityTypeId',
    queryStringSerializer: arrayToQueryString('AvailabilityTypeId'),
    displayName: t(translations.AvailabilityType),
    operator: ODataOperators.Includes,
    value: getNumberEntityArray(params, 'AvailabilityTypeId'),
    initData: initMultiPickerValue(initMyAssetAvailabilityTypeMultiData),
    component: AvailabilityTypeAvailableMultiFilter,
    urlKey: 'AvailabilityTypeId',
  });
  result.push({
    fieldName: 'ServiceTypeId',
    queryStringSerializer: 'stid',
    displayName: t(translations.ResourceServiceType),
    operator: ODataOperators.Equals,
    value: GetPartialEntity(GetNumberOrUndefined(GetRawValue(params, 'stid'))),
    initData: initPickerValue(initServiceTypeEnumData),
    component: ServiceTypeEnumFilter,
    urlKey: 'stid',
  });

  //}
  if (myAssetsDefaultFilters.includes('ContactsPersonForEquipment')) {
    result.push({
      fieldName: 'Contacts',
      displayName: t(translations.ContactsPersonForEquipment),
      operator: ODataOperators.AnyIn,
      queryStringSerializer: arrayToQueryString('contacts'),
      value: getStringEntityArray(params, 'contacts'),
      component: UserAssetContactMultiFilter,
      initData: initMultiExPickerValue(initUserMultiData),
      urlKey: 'contacts',
    });
  }
  if (myAssetsDefaultFilters.includes('Experts')) {
    result.push({
      fieldName: 'Experts',
      displayName: t(translations.EquipmentExperts),
      operator: ODataOperators.AnyIn,
      queryStringSerializer: arrayToQueryString('experts'),
      value: getStringEntityArray(params, 'experts'),
      component: UserAssetContactMultiFilter,
      initData: initMultiExPickerValue(initUserMultiData),
      urlKey: 'experts',
    });
  }
  if (myAssetsDefaultFilters.includes('OtherNames')) {
    result.push({
      fieldName: 'OtherNames',
      displayName: t(translations.OtherNames),
      operator: ODataOperators.Contains,
      queryStringSerializer: 'otherNames',
      value: GetStringOrUndefined(GetRawValue(params, 'otherNames')),
      component: TextFieldFilter,
    });
  }
  if (myAssetsDefaultFilters.includes('InternalCode')) {
    result.push({
      fieldName: 'InternalCode',
      displayName: t(translations.InternalEquipmentBarcodeNumber),
      operator: ODataOperators.Contains,
      queryStringSerializer: 'internalCode',
      value: GetStringOrUndefined(GetRawValue(params, 'internalCode')),
      component: TextFieldFilter,
    });
  }
  if (myAssetsDefaultFilters.includes('ShowAssemblyParts')) {
    result.push({
      fieldName: 'HasAssemblyParts',
      displayName: t(translations.ShowAssemblyParts),
      operator: ODataOperators.Equals,
      queryStringSerializer: 'showAssemblyParts',
      value: GetBooleanEntity(
        GetBooleanOrUndefined(GetRawValue(params, 'showAssemblyParts')),
        t,
      ),
      component: BooleanPickerFilter,
    });
  }
  if (myAssetsDefaultFilters.includes('IsUsageOpen')) {
    result.push({
      fieldName: 'HasActiveUsage',
      displayName: t(translations.UsageStatus),
      operator: ODataOperators.Equals,
      queryStringSerializer: 'isUsageOpen',
      value: GetBooleanEntity(
        GetBooleanOrUndefined(GetRawValue(params, 'isUsageOpen')),
        t,
      ),
      component: BooleanPickerFilter,
    });
  }
  if (IsAssetFieldEnabled(appSettings, AssetAttributes.Range)) {
    result.push({
      fieldName: 'Ranges',
      queryStringSerializer: assetRangeSerializer('RangeWeight'),
      value: getAssetRange(params, 'RangeWeight'),
      displayName: t(translations.RangeWeight),
      operator: ODataOperators.In,
      component: AssetRangeFilter,
      initData: initAssetRangeData,
      customData: 1,
      customFilter: assetRangeCustomFilter,
      getDisplay: assetRangeDisplay,
      urlKey: 'RangeWeight',
    });
  }
  if (IsAssetFieldEnabled(appSettings, AssetAttributes.Range)) {
    result.push({
      fieldName: 'Ranges',
      queryStringSerializer: assetRangeSerializer('RangekN'),
      value: getAssetRange(params, 'RangekN'),
      displayName: t(translations.RangekN),
      operator: ODataOperators.In,
      component: AssetRangeFilter,
      initData: initAssetRangeData,
      customData: 2,
      customFilter: assetRangeCustomFilter,
      getDisplay: assetRangeDisplay,
      urlKey: 'RangekN',
    });
  }
  if (IsAssetFieldEnabled(appSettings, AssetAttributes.Range)) {
    result.push({
      fieldName: 'Ranges',
      queryStringSerializer: assetRangeSerializer('RangeTemperature'),
      value: getAssetRange(params, 'RangeTemperature'),
      displayName: t(translations.RangeTemperature),
      operator: ODataOperators.In,
      component: AssetRangeFilter,
      initData: initAssetRangeData,
      customData: 3,
      customFilter: assetRangeCustomFilter,
      getDisplay: assetRangeDisplay,
      urlKey: 'RangeTemperature',
    });
  }
  if (IsAssetFieldEnabled(appSettings, AssetAttributes.Range)) {
    result.push({
      fieldName: 'Ranges',
      queryStringSerializer: assetRangeSerializer('RangeArea'),
      value: getAssetRange(params, 'RangeArea'),
      displayName: t(translations.RangeArea),
      operator: ODataOperators.In,
      component: AssetRangeFilter,
      initData: initAssetRangeData,
      customData: 4,
      customFilter: assetRangeCustomFilter,
      getDisplay: assetRangeDisplay,
      urlKey: 'RangeArea',
    });
  }
  if (IsAssetFieldEnabled(appSettings, AssetAttributes.Range)) {
    result.push({
      fieldName: 'Ranges',
      queryStringSerializer: assetRangeSerializer('RangeVolume'),
      value: getAssetRange(params, 'RangeVolume'),
      displayName: t(translations.RangeVolume),
      operator: ODataOperators.In,
      component: AssetRangeFilter,
      initData: initAssetRangeData,
      customData: 5,
      customFilter: assetRangeCustomFilter,
      getDisplay: assetRangeDisplay,
      urlKey: 'RangeVolume',
    });
  }
  if (IsAssetFieldEnabled(appSettings, AssetAttributes.Range)) {
    result.push({
      fieldName: 'Ranges',
      queryStringSerializer: assetRangeSerializer('RangeAmount'),
      value: getAssetRange(params, 'RangeAmount'),
      displayName: t(translations.RangeAmount),
      operator: ODataOperators.In,
      component: AssetRangeFilter,
      initData: initAssetRangeData,
      customData: 6,
      customFilter: assetRangeCustomFilter,
      getDisplay: assetRangeDisplay,
      urlKey: 'RangeAmount',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL0) &&
    myAssetsDefaultFilters.includes('ExtraDDL0')
  ) {
    result.push({
      fieldName: 'ExtraDDL0',
      displayName: t(translations.Asset_ExtraDDL0),
      queryStringSerializer: 'extddl0',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl0')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL0'),
      urlType: 'ExtraDDL0',
      urlKey: 'extddl0',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL1) &&
    myAssetsDefaultFilters.includes('ExtraDDL1')
  ) {
    result.push({
      fieldName: 'ExtraDDL1',
      displayName: t(translations.Asset_ExtraDDL1),
      queryStringSerializer: 'extddl1',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl1')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL1'),
      urlType: 'ExtraDDL1',
      urlKey: 'extddl1',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL2) &&
    myAssetsDefaultFilters.includes('ExtraDDL2')
  ) {
    result.push({
      fieldName: 'ExtraDDL2',
      displayName: t(translations.Asset_ExtraDDL2),
      queryStringSerializer: 'extddl2',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl2')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL2'),
      urlType: 'ExtraDDL2',
      urlKey: 'extddl2',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL3) &&
    myAssetsDefaultFilters.includes('ExtraDDL3')
  ) {
    result.push({
      fieldName: 'ExtraDDL3',
      displayName: t(translations.Asset_ExtraDDL3),
      queryStringSerializer: 'extddl3',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl3')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL3'),
      urlType: 'ExtraDDL3',
      urlKey: 'extddl3',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL4) &&
    myAssetsDefaultFilters.includes('ExtraDDL4')
  ) {
    result.push({
      fieldName: 'ExtraDDL4',
      displayName: t(translations.Asset_ExtraDDL4),
      queryStringSerializer: 'extddl4',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl4')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL4'),
      urlType: 'ExtraDDL4',
      urlKey: 'extddl4',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL5) &&
    myAssetsDefaultFilters.includes('ExtraDDL5')
  ) {
    result.push({
      fieldName: 'ExtraDDL5',
      displayName: t(translations.Asset_ExtraDDL5),
      queryStringSerializer: 'extddl5',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl5')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL5'),
      urlType: 'ExtraDDL5',
      urlKey: 'extddl5',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL6) &&
    myAssetsDefaultFilters.includes('ExtraDDL6')
  ) {
    result.push({
      fieldName: 'ExtraDDL6',
      displayName: t(translations.Asset_ExtraDDL6),
      queryStringSerializer: 'extddl6',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl6')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL6'),
      urlType: 'ExtraDDL6',
      urlKey: 'extddl6',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL7) &&
    myAssetsDefaultFilters.includes('ExtraDDL7')
  ) {
    result.push({
      fieldName: 'ExtraDDL7',
      displayName: t(translations.Asset_ExtraDDL7),
      queryStringSerializer: 'extddl7',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl7')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL7'),
      urlType: 'ExtraDDL7',
      urlKey: 'extddl7',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL8) &&
    myAssetsDefaultFilters.includes('ExtraDDL8')
  ) {
    result.push({
      fieldName: 'ExtraDDL8',
      displayName: t(translations.Asset_ExtraDDL8),
      queryStringSerializer: 'extddl8',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl8')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL8'),
      urlType: 'ExtraDDL8',
      urlKey: 'extddl8',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL9) &&
    myAssetsDefaultFilters.includes('ExtraDDL9')
  ) {
    result.push({
      fieldName: 'ExtraDDL9',
      displayName: t(translations.Asset_ExtraDDL9),
      queryStringSerializer: 'extddl9',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl9')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL9'),
      urlType: 'ExtraDDL9',
      urlKey: 'extddl9',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL10) &&
    myAssetsDefaultFilters.includes('ExtraDDL10')
  ) {
    result.push({
      fieldName: 'ExtraDDL10',
      displayName: t(translations.Asset_ExtraDDL10),
      queryStringSerializer: 'extddl10',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl10')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL10'),
      urlType: 'ExtraDDL10',
      urlKey: 'extddl10',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL11) &&
    myAssetsDefaultFilters.includes('ExtraDDL11')
  ) {
    result.push({
      fieldName: 'ExtraDDL11',
      displayName: t(translations.Asset_ExtraDDL11),
      queryStringSerializer: 'extddl11',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl11')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL11'),
      urlType: 'ExtraDDL11',
      urlKey: 'extddl11',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL12) &&
    myAssetsDefaultFilters.includes('ExtraDDL12')
  ) {
    result.push({
      fieldName: 'ExtraDDL12',
      displayName: t(translations.Asset_ExtraDDL12),
      queryStringSerializer: 'extddl12',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl12')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL12'),
      urlType: 'ExtraDDL12',
      urlKey: 'extddl12',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL13) &&
    myAssetsDefaultFilters.includes('ExtraDDL13')
  ) {
    result.push({
      fieldName: 'ExtraDDL13',
      displayName: t(translations.Asset_ExtraDDL13),
      queryStringSerializer: 'extddl13',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl13')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL13'),
      urlType: 'ExtraDDL13',
      urlKey: 'extddl13',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL14) &&
    myAssetsDefaultFilters.includes('ExtraDDL14')
  ) {
    result.push({
      fieldName: 'ExtraDDL14',
      displayName: t(translations.Asset_ExtraDDL14),
      queryStringSerializer: 'extddl14',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl14')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL14'),
      urlType: 'ExtraDDL14',
      urlKey: 'extddl14',
    });
  }
  if (
    IsAssetFieldEnabled(appSettings, AssetAttributes.ExtraDDL15) &&
    myAssetsDefaultFilters.includes('ExtraDDL15')
  ) {
    result.push({
      fieldName: 'ExtraDDL5',
      displayName: t(translations.Asset_ExtraDDL15),
      queryStringSerializer: 'extddl15',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'extddl15')),
      ),
      operator: ODataOperators.Equals,
      component: ExtraDDLFilter,
      initData: initExPickerValue(initExtraDDLData, undefined, 'ExtraDDL15'),
      urlType: 'ExtraDDL15',
      urlKey: 'extddl15',
    });
  }
  return result;
};
