import * as React from 'react';
import {
  UseSortByState,
  UsePaginationState,
  SortingRule,
  TableState,
} from 'react-table';
import { httpClient } from 'api/HttpClient';
import { CancelToken } from 'axios';
import { BasicTableProps } from 'app/components/BasicTable/BasicTableProps';
import { IRow } from 'app/components/BasicTable/IRow';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import {
  getExportParameters,
  buildURL,
  openExportLink,
  IPathAndQuery,
} from 'utils/url-utils';
import { Entity } from 'types/common';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useDispatch, useSelector } from 'react-redux';
//import { Progress } from '../LoadingIndicator';
import { buildParams } from 'api/odata/ODataParamsBuilder';
import { ControlledTable } from 'app/components/BasicTable/ControlledTable';
import { useMemoFilters } from 'app/components/BasicTable/useMemoFilters';
import { selectDoRefreshTable } from 'app/Layout/FrontendLayout/slice/selectors';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { BasicTableRefreshEventHandler } from '../ControlledTable/ControlledTableProps';
export type { IRow };
export type { BasicTableProps };

type IPartialTableState<TRow extends IRow> = Partial<
  UseSortByState<TRow> & UsePaginationState<TRow>
>;

const REQUEST_CANCELLED_MESSAGE = 'Request cancelled (race condition)';
/**
 * Interface to the (initial) table state
 */
export interface IBasicTableState<TRow extends IRow>
  extends IPartialTableState<TRow> {}

const defaultRowIdAccessor = <TRow extends IRow>(row: TRow): string =>
  row.Id?.toString();
/**
 * Server side driven Table component that will be reused in most of the pages
 * @param param0
 * @returns
 */
export function UnconfigurableTable<TRow extends IRow>({
  columns,
  initialState,
  api,
  additionalColumns,
  getRowId = defaultRowIdAccessor,
  searchColumns,
  useExport = true,
  onExportForImport,
  rowActions,
  pageActions,
  screenName,
  selectedRowsActions,
  filterOpen = false,
  serviceGroups,
  customParams,
  useImportLink,
  importLink,
  needRefresh,
  ServiceGroupsOptional,
  topAlertMessage,
  printing,
  ...props
}: Omit<BasicTableProps<TRow>, 'screenId'>) {
  const isMobile = DetectIsMobile();
  // loading/processing indication
  const [loading, setLoading] = React.useState(false);
  // data received from the server and displayd on the table
  const [data, setData] = React.useState<TRow[]>([]);
  // reference to the show/hide filters button
  // data length - total number of the records
  const [dataLength, setDataLength] = React.useState(0);
  // page count derrived from total number of the records
  // this is passed to the react-table hook
  const [controlledPageCount, setControlledPageCount] = React.useState(0);
  const { actions: layoutActions } = useLayoutSlice();
  const dispatch = useDispatch();
  const doRefresh = useSelector(selectDoRefreshTable);

  const [tableState, setTableState] = React.useState<Partial<TableState<TRow>>>(
    {
      pageIndex: initialState?.pageIndex ?? 0,
      pageSize:
        printing !== undefined && printing.printing === true
          ? 10000
          : initialState?.pageSize ?? 10,

      sortBy: initialState?.sortBy,
    },
  );
  const handleChangeState = React.useCallback(state => {
    setTableState(state);
  }, []);

  const configurableColumns = React.useMemo(() => columns, [columns]);

  const columnAccessors = React.useMemo(
    () =>
      configurableColumns
        ?.filter(c => typeof c.accessor === 'string')
        ?.filter(c => c.accessor !== undefined)
        ?.map(c => c.accessor as keyof TRow),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configurableColumns],
  );

  // memo cleaned up filters without the Name which is loaded independently of the filter value and only affects appearance of the filter
  const customFilter = useMemoFilters({ appliedFilters: props.appliedFilters });
  // serialize the applied filters to be compatible with shallow comparison of the useEffect readonly array
  const serializedCustomFilter = JSON.stringify(customFilter);
  const handleFilterChange = React.useCallback(
    items => {
      if (props.onFilterChange !== undefined) {
        props.onFilterChange(items);
      }
    },
    [props],
  );

  /**
   * Fetch data method that retrieves the data according to the current table state
   */
  const fetchData = React.useCallback(
    async (
      state: {
        api: string | IPathAndQuery;
        pageSize: number;
        pageIndex: number;
        sortBy?: SortingRule<TRow>[] | null;
        globalFilterValue?: string;
        customFilter: IFilterSettings<TRow>[];
        serviceGroups?: Entity<number>[];
        isOptionalServiceGroup: boolean;
        columns: Array<keyof TRow>;
      },
      cancelToken: CancelToken,
    ) => {
      setLoading(true);
      try {
        // todo: add support for object notation accessors like 'foo.bar'
        // todo: add support for accessor of type Function(originalRow, rowIndex) => any
        const oDataParams = buildParams<TRow>(
          {
            ...state,
            select: state.columns,
            additionalColumns: additionalColumns,
            serviceGroups: state.serviceGroups ?? [],
          },
          searchColumns,
          customParams,
        );
        var { url, params } = getUrlAndParams(state.api, oDataParams);
        const response = await httpClient.get(url, params, cancelToken);

        setData(response.value);
        setDataLength(response['@odata.count']);
        setControlledPageCount(
          Math.ceil(response['@odata.count'] / state.pageSize),
        );
        setLoading(false);
      } catch (error) {
        // todo: show error message

        if (
          error instanceof Error &&
          error.message === REQUEST_CANCELLED_MESSAGE
        ) {
          // noop - request was explicitly cancelled after a new one was issues to prevent race conditions between multiple requests
        } else {
          console.warn(error);
        }
      }
      // finally {
      //   setLoading(false);
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const pageIndex = tableState.pageIndex!;
  const pageSize = tableState.pageSize!;
  const sortBy = tableState.sortBy!;
  const globalFilter = tableState.globalFilter;

  // connect table state changes to server data fetch side effect
  React.useEffect(() => {
    const cancelTokenSource = httpClient.cancelToken.source();
    if (columnAccessors === undefined) {
      return;
    }
    fetchData(
      {
        api,
        pageIndex,
        pageSize,
        sortBy,
        globalFilterValue: globalFilter as string,
        customFilter: customFilter ?? [],
        serviceGroups: serviceGroups,
        isOptionalServiceGroup: ServiceGroupsOptional || false,
        columns: columnAccessors,
      },
      cancelTokenSource.token,
    );
    return () => {
      cancelTokenSource.cancel(REQUEST_CANCELLED_MESSAGE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchData,
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    serializedCustomFilter,
    serviceGroups,
    ServiceGroupsOptional,
    columnAccessors,
    api,
  ]);

  const handleExportClick = (): Promise<void> => {
    const { $top, $skip, ...tableParams } = buildParams({
      pageIndex,
      pageSize,
      sortBy,
      globalFilterValue: globalFilter as string,
      customFilter: customFilter ?? [],
      select: columnAccessors,
      additionalColumns: additionalColumns,
      serviceGroups: serviceGroups ?? [],
      isOptionalServiceGroup: ServiceGroupsOptional || false,
    });
    var oDataParams = getExportParameters(tableParams);
    var { url, params } = getUrlAndParams(api, oDataParams);
    const exporturl = buildURL(url, params);
    return openExportLink(exporturl);
  };

  const [allRowsSelected, setAllRowsSelected] = React.useState<boolean>();
  const toggleAllRowsSelected = React.useCallback((value: boolean) => {
    setAllRowsSelected(value);
  }, []);

  /**
   * Reloads the table and unselects all rows
   */
  const handleRefresh: BasicTableRefreshEventHandler = React.useCallback(
    event => {
      if (columnAccessors === undefined) {
        return;
      }
      if (toggleAllRowsSelected !== undefined) {
        toggleAllRowsSelected(false);
      }
      fetchData(
        {
          api,
          pageIndex,
          pageSize,
          sortBy,
          globalFilterValue: globalFilter as string,
          customFilter: customFilter ?? [],
          serviceGroups: serviceGroups,
          isOptionalServiceGroup: ServiceGroupsOptional || false,
          columns: columnAccessors,
        },
        httpClient.cancelToken.source().token,
      );
    },
    [
      columnAccessors,
      toggleAllRowsSelected,
      fetchData,
      api,
      pageIndex,
      pageSize,
      sortBy,
      globalFilter,
      customFilter,
      serviceGroups,
      ServiceGroupsOptional,
    ],
  );

  const refreshFromState = React.useCallback(() => {
    dispatch(layoutActions.setRefreshTable(false));
    handleRefresh();
  }, [dispatch, layoutActions, handleRefresh]);

  React.useEffect(() => {
    if (needRefresh !== undefined && needRefresh) {
      handleRefresh();
    }
    if (doRefresh === true) {
      refreshFromState();
    }
  }, [doRefresh, needRefresh, handleRefresh, refreshFromState]);
  const handleSelectedRowsChange = React.useCallback(
    (rows: TRow[]) => {
      if (props.onSelectedRowsChange !== undefined) {
        props.onSelectedRowsChange(rows);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onSelectedRowsChange],
  );
  return (
    <ControlledTable
      api="foo"
      columns={configurableColumns ?? []}
      compact={isMobile}
      data={data}
      loading={loading}
      dataLength={dataLength}
      onChangeState={handleChangeState}
      onExport={handleExportClick}
      pageCount={controlledPageCount}
      screenName={screenName}
      serviceGroups={serviceGroups}
      ServiceGroupsOptional={ServiceGroupsOptional}
      additionalColumns={additionalColumns}
      appliedFilters={props.appliedFilters}
      availableFilters={props.availableFilters}
      customParams={customParams}
      filterOpen={filterOpen}
      getRowId={getRowId}
      importLink={importLink}
      initialPageSize={pageSize}
      initialState={initialState}
      onDownloadTemplate={props.onDownloadTemplate}
      onExportForImport={onExportForImport}
      onFilterChange={handleFilterChange}
      pageActions={pageActions}
      rowActions={rowActions}
      searchColumns={searchColumns}
      selectedRowsActions={selectedRowsActions}
      onSelectedRowsChange={handleSelectedRowsChange}
      subHeader={props.subHeader}
      topAlertMessage={topAlertMessage}
      useExport={useExport}
      onRefresh={handleRefresh}
      useGlobalFilter={true}
      useImportLink={useImportLink}
      useRowSelect={props.useRowSelect}
      allRowsSelected={allRowsSelected}
      imageSrcKey={props.imageSrcKey}
      titleKey={props.titleKey ?? columnAccessors[0]}
      printing={printing}
    />
  );
}
function getUrlAndParams(
  api: string | IPathAndQuery,
  oDataParams: {
    $skip?: number;
    $top?: number;
    $count?: boolean;
    $select?: string;
    $orderby?: string | undefined;
    $filter?: string | undefined;
    $format?: string;
  },
) {
  var url = typeof api === 'string' ? api : (api as IPathAndQuery).path;
  var search = (api as IPathAndQuery).search;
  const params = { ...oDataParams, ...search };
  return { url, params };
}
