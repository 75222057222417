/**
 *
 * RepetitiveOptionsPicker
 *
 */

import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
// import { useSystemDate } from 'app/hooks/useSystemDate';
import { selectSystemFirstDayOfWeek } from 'app/slice/selectors';
import { GetRepetitiveOptions } from 'enums/RepetitiveOptions';
import { useSelector } from 'react-redux';
import { Entity } from 'types/common';
import { dateUtils } from 'utils/date-utils';
export interface IRepetitiveOptionsParamsPicker {
  startDate: Date | null;
}
export interface RepetitiveOptionsPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'>,
    IRepetitiveOptionsParamsPicker {}

// export const initRepetitiveOptionsData = (
//   initval: number | string | undefined,
// ): Promise<Entity<number>[]> => {
//   if (initval === undefined) {
//     return new Promise<Entity<number>[]>((resolve, reject) => {
//       resolve([] as Entity<number>[]);
//     });
//   } else {
//     return new Promise<Entity<number>[]>((resolve, reject) => {
//       resolve(
//         enumToEntityArray(RepetitiveOptions).filter(item => {
//           return item.Id.toString() === initval;
//         }),
//       );
//     });
//   }
// };
// const getOptions = (searchTerm: string | null) => {
//   let items = enumToEntityArray(RepetitiveOptions);

//   if (searchTerm !== null && searchTerm !== '') {
//     return items.filter(
//       f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
//     );
//   }
//   return items;
// };
export function withParamsFilter(
  startDate: Date | null,
  firstDayOfWeek: number,
) {
  let date = startDate || new Date();
  let items = GetRepetitiveOptions(date, firstDayOfWeek);

  return (searchTerm: string | null) => {
    if (searchTerm !== null && searchTerm !== '') {
      return items.filter(
        f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
      );
    }
    return items;
  };
}
export function RepetitiveOptionsPicker(props: RepetitiveOptionsPickerProps) {
  const firstDayOfWeek = useSelector(selectSystemFirstDayOfWeek);
  // const { getOffsetDate } = useSystemDate();
  let getOptions = withParamsFilter(
    props.startDate === null
      ? null
      : dateUtils.dateOrStringToDate(props.startDate),
    firstDayOfWeek || 0,
  );
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      id={props.id || 'RepetitiveOptionsId'}
      value={props.value}
      style={{ marginRight: '4px' }}
      {...props}
    />
  );
}
