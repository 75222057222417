import styled from 'styled-components';

export const TableHeaderCellLabel = styled('span')`
  white-space: break-spaces;
  word-break: normal;
`;

// export const TableCell = withStyles({
//   root: {
//     height: '32px',
//   },
//   head: {},
//   body: {
//     padding: '10px 12px',
//   },
// })(MuiTableCell);
