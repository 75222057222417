import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import {
  ALL_PERIOD_TYPES,
  PeriodTypes,
  PeriodTypesEntity,
} from 'app/components/DatePeriods';
import i18next from 'i18next';
import * as React from 'react';

const DATE_PERIODS = (periods?: Array<PeriodTypes>) => {
  const all = ALL_PERIOD_TYPES.map((value, index) => {
    return { Id: value, Name: value };
  });
  if (periods === undefined) {
    return all;
  } else {
    return all.filter(f => periods.includes(f.Name));
  }
};

export interface DatePeriodsPickerProps
  extends Omit<BaseOptionsPickerProps<PeriodTypesEntity>, 'getOptions'> {
  periods?: Array<PeriodTypes>;
  twoDaysDisabled?: boolean;
}
export function getPeriods(periods?: Array<PeriodTypes>) {
  return DATE_PERIODS(periods);
}
const getOptions = (
  searchTerm: string | null,
  periods?: Array<PeriodTypes>,
) => {
  let items = DATE_PERIODS(periods).map(item => {
    return {
      Id: item.Id,
      Name: i18next.t(item.Name),
      type: item.Name as PeriodTypes,
    };
  });

  if (searchTerm !== null && searchTerm !== '') {
    return items.filter(
      f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
    );
  }
  return items;
};
export function DatePeriodsPicker(props: DatePeriodsPickerProps) {
  const getOptionsCallback = React.useCallback(
    (searchTerm: string | null) => {
      return getOptions(searchTerm, props.periods);
    },
    [props.periods],
  );
  return (
    <>
      <BaseOptionsPicker
        mini={props.mini ? true : undefined}
        size={props.size}
        getOptions={getOptionsCallback}
        value={props.value}
        style={{ marginRight: '4px' }}
        id={props.id || 'dateperiodsId'}
        {...props}
      />
    </>
  );
}
