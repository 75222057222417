import { FieldHookConfig, useField } from 'formik';
import { ChangeEvent } from 'react';
import {
  ChipCheckBoxGroup,
  ChipCheckBoxGroupProps,
} from '../BasicChip/ChipChekBoxGroup';
import {
  ChipLabelGroup,
  ChipLabelGroupProps,
} from '../BasicChip/ChipLabelGroup';
import { CheckEntity, ChipEntity } from '../CheckBoxGroup';

//export type FormCheckBoxGroupProps = FieldHookConfig<CheckEntity[]> & CheckBoxGroupProps;

export const FormChipCheckBoxGroup = ({
  label,
  ...props
}: FieldHookConfig<CheckEntity[]> &
  ChipCheckBoxGroupProps & { setOnChanged: () => void }) => {
  const [field, meta, helpers] = useField<CheckEntity[]>(props);
  const handleChange = (
    value: CheckEntity,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const val = value as CheckEntity;
    helpers.setValue(
      field.value.map(f => {
        if (f.Id === val.Id) {
          return { ...f, checked: val.checked };
        }
        return f;
      }),
      true,
    );
    field.onChange(event);
    if (props.onChange !== undefined) {
      props.onChange(value, event);
    }
    props.setOnChanged();
  };
  return (
    <ChipCheckBoxGroup
      label={label}
      value={field.value}
      error={meta?.error !== undefined}
      helperText={meta.error}
      info={props.info}
      onChange={handleChange}
      disabled={props.disabled}
      required={props.required}
      isRow={true}
      margin="none"
      size={props.size}
      {...props}
    />
  );
};
export const FormChipLabelGroup = ({
  label,
  ...props
}: FieldHookConfig<ChipEntity[]> &
  ChipLabelGroupProps & { onChipClick?: (value: ChipEntity) => void }) => {
  const [field, meta] = useField<ChipEntity[]>(props);
  const handleChipClick = (value: ChipEntity) => {
    !!props.onChipClick && props.onChipClick(value);
  };
  //   const handleChange = (value, event) => {
  //     const val = value as CheckEntity;
  //     helpers.setValue(
  //       field.value.map(f => {
  //         if (f.Id === val.Id) {
  //           f.checked = val.checked;
  //         }
  //         return f;
  //       }),
  //       true,
  //     );
  //     if (props.onChange !== undefined) {
  //       props.onChange(value, event);
  //     }
  //     field.onChange(event);
  // };
  return (
    <ChipLabelGroup
      label={label}
      value={field.value}
      error={meta?.error !== undefined}
      helperText={meta.error}
      info={props.info}
      onChipClick={handleChipClick}
      disabled={props.disabled}
      required={props.required}
      isRow={true}
      margin="none"
      {...props}
    />
  );
};
