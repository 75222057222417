/**
 *
 * AsyncPicker
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { SamplePlateTypePicker } from '../pickers/AutocompletePickers/SamplePlateTypePicker';

export const FormSamplePlateTypePicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> & {
  label?: string;
  fullWidth: boolean;
  autoFocus?: boolean;
  name: string;
  placeholder: string;
  disabled?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
}) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <SamplePlateTypePicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={value => helpers.setValue(value, true)}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        variant={props.variant}
      />
    </>
  );
};
