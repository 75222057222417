import { Box } from 'app/components/basic/Wrappers/Box';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { InputLabel, InputValue } from 'app/components/Typography';

export function ReadonlyField(props: {
  title: string;
  value: string | undefined | null;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flex: '1 0 0',
          alignSelf: 'stretch',
        }}
      >
        <InputLabel size="xs" color="secondary">
          {props.title}
        </InputLabel>
        <InputValue size="small">
          {props.value ?? t(translations.NA)}
        </InputValue>
      </Box>
    </>
  );
}
