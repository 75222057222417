import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: '48px',
      height: '48px',
      backgroundColor: '#DBF9ED',
      color: '#10BD79',
    },
    withPointer: {
      cursor: 'pointer',
    },
  }),
);
