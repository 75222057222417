/**
 *
 * WokFlowBooking
 *
 */
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { WorkflowBookingPicker } from 'app/components/pickers/AutocompletePickers/WorkflowBookingPicker';
import { IWorkflowBookingDto } from 'api/odata/generated/entities/IWorkflowBookingDto';
import { IFieldProps } from '../FormRender/IFormFieldSetting';
import BaseTextField from 'app/components/BasicInputs/BaseTextField';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { TextFieldProps } from 'app/components/TextField';
import React from 'react';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';

export const FormWorkFlowBooking = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> &
  IFieldProps &
  Omit<TextFieldProps, 'helperText'> & { useWorkFlowPicker?: boolean }) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  const { t } = useTranslation();
  return props.useWorkFlowPicker ? (
    <WorkflowBookingPicker
      name={props.name}
      label={label}
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      placeholder={props.placeholder}
      onBlur={field?.onBlur}
      value={field.value as IWorkflowBookingDto}
      error={meta?.error !== undefined}
      variant="filled"
      helperText={meta.error}
      fullWidth={props.fullWidth}
      info={props.info}
      disabled={props.disabled}
    />
  ) : (
    <BaseTextField
      name={props.name}
      label={label ?? t(translations[props.name])}
      fullWidth={props.fullWidth}
      variant="filled"
      onChange={e => {
        let val = e.currentTarget.value;
        let entityVal =
          val === undefined || val === ''
            ? null
            : ({ Id: -1, Name: val } as Entity<number>);
        helpers.setValue(entityVal, true);
        if (props.onChange) {
          props.onChange(entityVal);
        }
      }}
      onBlur={field?.onBlur}
      value={field?.value?.Name ?? ''}
      error={meta?.error !== undefined}
      helperText={meta.error || props.helperText}
      id={props.id || 'workflowtextfieldId'}
      inputProps={{
        ...props.inputProps,
        'aria-label': `${label ? undefined : 'text input'}`,
      }}
      color="primary"
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <React.Fragment>
            {props.info ? <InfoIcon title={props.info} /> : null}
            {props.InputProps?.endAdornment}
          </React.Fragment>
        ),
      }}
    />
  );
};
