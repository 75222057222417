import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectContinueState,
  selectContinueToLink,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { useAppSettingsSlice } from 'app/slice';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { push } from 'connected-react-router';
import { FormikProps } from 'formik';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Beforeunload } from 'react-beforeunload';
import { useOfflineServiceStateSlice } from '../Details/slice';
import {
  selectStockRenewalCompleted,
  selectStockRenewalData,
  selectStockRenewalHasErrors,
  selectStockRenewalProcessing,
} from '../Details/slice/selectors';
import {
  RenewStockState,
  StockRenewalParams,
  StockRenewalState,
} from '../Details/slice/types';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { ServiceType } from 'enums/ServiceTypes';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { Box } from 'app/components/basic/Wrappers/Box';
import { Progress } from 'app/components/LoadingIndicator';
import { buildURL } from 'utils/url-utils';
import { AssetsSelection } from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { Roles, ScanTarget } from 'app/slice/types';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IMyAssetsRow } from 'app/pages/MyAssetsPage/IMyAssetsRow';
import { StockRenewalForm } from './StockRenewalForm';
import { RenewStock } from '../RenewStock';
import { InventoryBatch } from '../InventoryBatch';

export interface StockRenewalProps extends CoverProps, SidePanelContentProps {
  queryParams: StockRenewalParams;
  initialServices: IOtherServices[];
  onClose?: () => void;
}
export const StockRenewal = React.memo(function StockRenewal(
  props: StockRenewalProps,
) {
  const { useSidePanel, queryParams, initialServices, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();
  const processing = useSelector(selectStockRenewalProcessing);
  const actionCompleted = useSelector(selectStockRenewalCompleted);
  const User = useSelector(selectAuthenticatedUser);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const confirmApproved = useSelector(selectConfirmApproved);
  const confirmRejected = useSelector(selectConfirmRejected);
  const continueToOther = useSelector(selectContinueState);
  const continueLink = useSelector(selectContinueToLink);
  const hasError = useSelector(selectStockRenewalHasErrors);
  const stockRenewalServicesData = useSelector(selectStockRenewalData);
  const publicUrl = useSelector(selectPublicUrl);
  //   const { checkIsMobile } = useIsMobile();
  //   const mobile = checkIsMobile();
  const globalSettings = useGlobalSettingsHook();
  //Local State
  const [cover, setCover] = React.useState<React.ReactNode>();
  const [busy, setBusy] = React.useState<boolean | undefined>(false);
  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  // const [submitAllowed, setSubmitAllowed] = React.useState<boolean | undefined>(
  //   false,
  // );
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);

  //Refs
  const submitFormRef = React.useRef<any>(null);
  const innerFormRef = React.useRef<FormikProps<StockRenewalState>>(null);

  const isReadOnlyUser = React.useMemo(() => {
    return (
      User !== undefined &&
      (User.Roles.includes(Roles.CalendarOnly) ||
        User.Roles.includes(Roles.Readonly) ||
        User.Roles.includes(Roles.RoomOnly))
    );
  }, [User]);

  //Methods
  const closeCover = () => {
    setCover(undefined);
  };
  const handleClose = () => {
    if (hasChanges) {
      dispatch(layoutActions.setConfirmOpen(true));
    } else {
      handleCloselClick();
    }
  };
  const handleCloselClick = React.useCallback(() => {
    if (!!onClose) {
      onClose();
    } else {
      if (useSidePanel) {
        dispatch(layoutActions.resetSidePanel());
      } else {
        dispatch(push('/otherServices'));
      }
    }
    dispatch(actions.resetStockRenewalState());
  }, [actions, dispatch, layoutActions, onClose, useSidePanel]);
  const handleSaveClick = React.useCallback((e: any) => {
    handleSubmitForm(e);
  }, []);

  const handleSubmitForm = (e: any) => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (values: StockRenewalState) => {
      setBusy(true);
      dispatch(actions.createStockRenewals(values));
    },
    [actions, dispatch],
  );
  //effects
  useEffectOnMount(() => {
    dispatch(
      appSettingsActions.setBarcodeScannigTarget(ScanTarget.Consumables),
    );
    return () => {
      dispatch(appSettingsActions.setBarcodeScannigTarget(ScanTarget.All));
    };
  });
  React.useEffect(() => {
    let active = firstLoad;
    if (globalSettings.loadCompleted && active) {
      setFirstLoad(false);
      dispatch(
        actions.initStockRenewal({
          query: queryParams,
          services: initialServices,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    dispatch,
    firstLoad,
    globalSettings,
    initialServices,
    queryParams,
  ]);
  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      if (actionCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          handleCloselClick();
        }
      } else if (actionCompleted === false) {
        setRequestCompleted(undefined);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [actionCompleted, handleCloselClick, hasError, processing]);
  React.useEffect(() => {
    if (confirmApproved) {
      dispatch(actions.resetStockRenewalState());
      if (continueToOther.continueOnApprove) {
        dispatch(layoutActions.resetSidePanel());
        setTimeout(() => {
          dispatch(
            layoutActions.openSidePanel({
              type: continueToOther.pageType,
              props: continueToOther.pageProps,
              expanded: continueToOther.expanded,
            }),
          );
          dispatch(layoutActions.resetContinueState());
        }, 200);
      } else if (continueLink !== undefined) {
        dispatch(appSettingsActions.navigate(continueLink));
        dispatch(layoutActions.resetSidePanel());
      } else {
        dispatch(layoutActions.resetSidePanel());
      }
      //dispatch(actions.setChangedState(false));
    }
    if (confirmRejected) {
      dispatch(layoutActions.resetConfirmState());
    }

    return () => undefined;
  }, [
    actions,
    appSettingsActions,
    confirmApproved,
    confirmRejected,
    continueLink,
    continueToOther,
    dispatch,
    layoutActions,
  ]);
  // React.useEffect(() => {
  //   setSubmitAllowed(
  //     !!stockRenewalServicesData &&
  //       stockRenewalServicesData.Services.length > 0,
  //   );
  // }, [setSubmitAllowed, stockRenewalServicesData]);
  //memo
  const IsAdmin = React.useMemo(() => {
    return !!innerFormRef.current &&
      innerFormRef.current.values.Services.length > 0
      ? User?.IsAllGroupOrLabTechAdmin(
          innerFormRef.current.values.Services.map(g => g.ServiceGroupId || 0),
          innerFormRef.current.values.Services.map(f => {
            return {
              Id: f.ServiceTypeId,
              Name: f.ServiceTypeName,
              ServiceTypeId: ServiceType.Offline,
            } as IServiceTypeFilterDto;
          }),
        )
      : false;
  }, [User]);
  const rightActions = React.useMemo(() => {
    let actions: ActionRenderer[] = [];
    actions.push(() => (
      <Button
        size="small"
        startIcon={<Icon icon="save" />}
        onClick={handleSaveClick}
        disabled={busy} // || !submitAllowed
        processing={!requestCompleted && busy}
      >
        {t(translations.Save)}
      </Button>
    ));
    actions.push(() => (
      <Button
        variant="ghost"
        size="small"
        startIcon={<Icon icon="times" />}
        onClick={handleCloselClick}
      >
        {t(translations.Close)}
      </Button>
    ));
    return actions;
  }, [busy, handleCloselClick, handleSaveClick, requestCompleted, t]);
  const assetsFilter = React.useMemo(() => {
    const userGroups = User?.AdminServiceGroups.map(f => f.Id);
    let filter: (string | Condition<IMyAssetsRow>)[] | undefined = [];
    filter.push(
      new Condition<IMyAssetsRow>(
        'TrackInventory',
        ODataOperators.Equals,
        true,
      ),
    );
    filter.push(
      new Condition<IMyAssetsRow>(
        'ServiceGroupId',
        ODataOperators.Includes,
        userGroups || [],
      ),
    );
    return filter.length > 0 ? filter : undefined;
  }, [User?.AdminServiceGroups]);
  return (
    <React.Fragment>
      <PageWrapper
        pageName={t(translations.StockRenewals)}
        titlePage={t(translations.StockRenewals)}
        loading={processing}
        useSidePanel={useSidePanel}
        closable={useSidePanel || props.isCover}
        disableExpandToggle={true}
        closeSidePanel={props.isCover ? onClose : handleClose}
        // leftTopActions={topButtons}
        //rightTopActions={topButtons}
        rightTopActionsAsMenu={true}
        //leftTopActions={topActions}
        //topProcessing={equipmentChangeCompleted === false}
        leftActions={isReadOnlyUser ? [] : rightActions}
        //rightActions={rightActions}
        children={
          stockRenewalServicesData !== undefined &&
          !processing &&
          globalSettings.loadCompleted ? (
            <StockRenewalForm
              initialValues={
                stockRenewalServicesData || ({} as RenewStockState)
              }
              bindSubmitForm={bindSubmitForm}
              onSubmit={handleSubmit}
              isAdmin={IsAdmin || false}
              user={User}
              globalSettings={globalSettings}
              innerFormRef={innerFormRef}
              editCreatable={true}
              onRenewStockClick={service =>
                setCover(
                  <RenewStock
                    useSidePanel={true}
                    isCover={true}
                    closeCover={() => {
                      closeCover();
                    }}
                    onClose={() => {
                      closeCover();
                    }}
                    initialService={service}
                    editCreatable={true}
                    queryParams={{
                      id: '' + service.Id ?? -1,
                    }}
                  />,
                )
              }
              onAddBatchClick={(batch, serviceType) => {
                setCover(
                  <InventoryBatch
                    useSidePanel={true}
                    isCover={true}
                    closeCover={() => {
                      closeCover();
                    }}
                    onClose={() => {
                      closeCover();
                    }}
                    initialBatch={batch ?? undefined}
                    saveCreatable={true}
                    queryParams={{
                      id: '' + (batch?.Id ?? -1),
                      stid: '' + serviceType,
                    }}
                  />,
                );
              }}
              onAddServiceClick={services => {
                setCover(
                  <AssetsSelection
                    useSidePanel={true}
                    actionType="OfflineService"
                    offTypeIds={services}
                    customPredicates={assetsFilter}
                    isCover={true}
                    closeCover={() => {
                      closeCover();
                    }}
                    onClose={() => {
                      closeCover();
                    }}
                  />,
                );
              }}
            />
          ) : (
            <React.Fragment>
              {' '}
              <Box component="div">
                <Progress inProgress={processing || false} size={80} />
              </Box>
            </React.Fragment>
          )
        }
        pageLink={buildURL(publicUrl + 'stockRenewals', queryParams)}
        isCover={props.isCover}
        cover={cover}
        closeCover={props.closeCover || closeCover}
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </React.Fragment>
  );
});
