/**
 *
 * SelectedRowsActionIconButton
 *
 */
import * as React from 'react';
import { useAppSettingsSlice } from 'app/slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionIconButton } from '../../../BasicButtons/ActionIconButton';
import { DialogConfirm } from '../../../DialogConfirm';
import { ActionButton } from 'app/components/BasicButtons/ActionButton';
import { DialogTypeVariants } from 'app/components/DialogComponents/DialogTypes';
import { IResponseType, IResponseTypeWithLink } from 'types/ResponseType';
import { Notification } from 'types/Notification';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import { IconProp } from 'app/AllIcons';
import { ICanRaiseBasicTableRefreshEvent } from '../../ControlledTable/ControlledTableProps';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';

export interface IRowsActionComponent<TRow> {
  rows: TRow[];
}
export interface SelectedRowsActionIconButtonProps<TRow>
  extends IRowsActionComponent<TRow>,
    ICanRaiseBasicTableRefreshEvent {
  icon?: IconProp;
  title: string;
  subtitle?: React.ReactNode;
  text?: string;
  action: () => Promise<any>;
  snackBarMessageType?: SnackBarMessageType;
  confirm?: boolean;
  update?: boolean;
  confirmTitle?: string | React.ReactNode;
  confirmBody?: React.ReactNode;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  variantButton?: 'button' | 'icon';
  updateDialogVariant?: DialogTypeVariants;
  validConfirm?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  onHandleClose?: () => void;
  disabled?: boolean;
  toggleAllRowsSelected?: (value?: boolean) => void;
}

export function SelectedRowsActionIconButton<TRow>({
  rows,
  title,
  action,
  snackBarMessageType,
  onRefresh,
  confirm,
  confirmTitle,
  confirmBody,
  confirmButtonLabel,
  cancelButtonLabel,
  variantButton,
  icon,
  text,
  updateDialogVariant,
  subtitle,
  validConfirm,
  maxWidth,
  onHandleClose,
  disabled,
  toggleAllRowsSelected,
  ...rest
}: SelectedRowsActionIconButtonProps<TRow>) {
  const { t } = useTranslation();
  const [busy, setBusy] = useState(false);
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const [showConfirm, setShowConfirm] = React.useState(false);

  const handleAction = async () => {
    setBusy(true);
    try {
      const response = await action();
      setShowConfirm(false);
      if (onHandleClose !== undefined) {
        onHandleClose();
      }
      if (response?.Id !== undefined && response.link !== undefined) {
        let resp = response as IResponseTypeWithLink;
        const key = response.link;
        var Notification = {
          key: key,
          persist: true,
          variant:
            resp.ErrorMessages.length > 0
              ? 'error'
              : resp.WarningMessages.length > 0
              ? 'warning'
              : resp.SuccessMessages.length > 0
              ? 'success'
              : 'default',
          message:
            resp.ErrorMessages.length > 0
              ? resp.ErrorMessages[0]
              : resp.WarningMessages.length > 0
              ? resp.WarningMessages[0]
              : resp.SuccessMessages.length > 0
              ? resp.SuccessMessages[0]
              : '',
          messageType: SnackBarMessageType.showLinkMessage,
          messageTypeProps: {
            Id: 0,
            created: true,
            itemName:
              resp.ErrorMessages.length > 0
                ? resp.ErrorMessages[0]
                : resp.WarningMessages.length > 0
                ? resp.WarningMessages[0]
                : resp.SuccessMessages.length > 0
                ? resp.SuccessMessages[0]
                : '',
            linkUrl: resp.link,
            linkText: resp.Id?.toString(),
            detailsType: undefined,
            detailsTypeProps: undefined,
          },
        } as Notification;
        if (key !== undefined) {
          Notification = Object.assign(Notification, { key: key });
        }
        dispatch(actions.addNotification(Notification));
      } else {
        let respm = response as IResponseType;
        if (respm.ErrorMessages.length > 0) {
          respm.ErrorMessages.forEach(errmsg =>
            dispatch(
              actions.addNotification({
                variant: 'error',
                message: errmsg,
                messageType: snackBarMessageType,
              }),
            ),
          );
        }
        if (respm.SuccessMessages.length > 0) {
          respm.SuccessMessages.forEach(sccmsg =>
            dispatch(
              actions.addNotification({
                variant: 'success',
                message: sccmsg,
                messageType: snackBarMessageType,
              }),
            ),
          );
        }
        if (respm.WarningMessages.length > 0) {
          respm.WarningMessages.forEach(wrnmsg =>
            dispatch(
              actions.addNotification({
                variant: 'warning',
                message: wrnmsg,
                messageType: snackBarMessageType,
              }),
            ),
          );
        }
      }
      setBusy(false);
      toggleAllRowsSelected?.(false);
      onRefresh?.();
    } catch (error) {
      setShowConfirm(false);
      if (onHandleClose !== undefined) {
        onHandleClose();
      }

      dispatch(
        actions.addNotification({
          variant: 'error',
          message:
            typeof error === 'string' ? error : t(translations.AnErrorOccurred),
        }),
      );

      setBusy(false);
    }
  };
  const handleClick = () => {
    if (confirm) {
      setShowConfirm(() => true);
    } else {
      handleAction();
    }
  };
  const handleConfirmClick = () => {
    handleAction();
  };
  const handleCloseClick = () => {
    setShowConfirm(false);
    if (onHandleClose !== undefined) {
      onHandleClose();
    }
  };

  return (
    <React.Fragment>
      {showConfirm && (
        <DialogConfirm
          maxWidth={maxWidth !== undefined ? maxWidth : 'xs'}
          title={confirmTitle || 'Confirm'}
          subtitle={subtitle}
          body={
            confirmBody || <BasicTypography>{'Are you sure?'}</BasicTypography>
          }
          confirmButtonLabel={confirmButtonLabel || 'Ok'}
          cancelButtonLabel={cancelButtonLabel || 'Cancel'}
          onConfirm={handleConfirmClick}
          onCancel={handleCloseClick}
          isOpen={showConfirm}
          processing={busy}
          disabled={busy || !validConfirm}
        />
      )}
      {variantButton === 'icon' && icon ? (
        <ActionIconButton
          disabled={busy || disabled}
          onClick={handleClick}
          title={title}
          icon={icon}
          {...rest}
        />
      ) : (
        <ActionButton
          processing={busy}
          disabled={busy || disabled}
          onClick={handleClick}
          title={title}
          text={text || title}
          starticon={icon}
          {...rest}
        />
      )}
    </React.Fragment>
  );
}
