import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { articlesActions as actions } from '.';
import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { ArticleDetailsProps } from 'app/pages/ArticlesPage/ArticleDetails';
import { httpClient } from 'api/HttpClient';
import { IExternalArticlesSearchResponse } from 'api/odata/generated/entities/IExternalArticlesSearchResponse';
import { IExternalArticleSearchPayload } from './types';
import { selectArticlesExternalSearchPageData } from 'app/pages/ArticlesPage/slice/selectors';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { appSettingsActions } from 'app/slice';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { ArticleStatus } from 'enums/Article';

function* doShowDetails(action: PayloadAction<IArticleDto>) {
  const props: ArticleDetailsProps = {
    useSidePanel: true,
    value: action.payload,
  };
  try {
    const url = '/api/odata/v4/articles';
    let params = {};
    let articleDetails;
    if (action.payload.Id > 0) {
      params = { key: action.payload.Id };
      const data: IODataQueryResponse<IArticleDto> = yield call(
        httpClient.get,
        url,
        params,
      );
      articleDetails = data;
    } else {
      if (action.payload.ExternalId !== null) {
        const filter = new ODataFilterBuilder<IArticleDto>({
          predicates: [
            new Condition<IArticleDto>(
              'ExternalId',
              ODataOperators.Equals,
              action.payload.ExternalId,
            ),
            new Condition<IArticleDto>(
              'ExternalIdSource',
              ODataOperators.Equals,
              action.payload.ExternalIdSource,
            ),
          ],
          stringColumns: [],
        });
        params = { $filter: filter.toString(), $top: 1 };
        const data: IODataQueryResponse<IArticleDto> = yield call(
          httpClient.get,
          url,
          params,
        );
        articleDetails = data.value[0];
      }
    }

    yield put(actions.showDetails_Success(articleDetails));
  } catch (error) {}

  yield put(
    layoutActions.openSidePanel({
      type: RenderPageType.Article,
      props: props,
    }),
  );
}
function* doHideDetails(action: PayloadAction) {
  yield put(layoutActions.toggleSidePanel(false));
}

function* doExternalSearch(
  action: PayloadAction<IExternalArticleSearchPayload>,
) {
  try {
    // skip fetching already loaded items
    const page = yield select(selectArticlesExternalSearchPageData);
    if (page !== undefined) {
      yield put(actions.externalSearch_Skip());
      return;
    }
    if (action.payload.searchForm.Source === undefined) {
      return;
    }
    const url = `/api/odata/v4/Articles/ExternalSearch(source='${action.payload.searchForm.Source}')`;
    const params = new URLSearchParams(
      action.payload.searchForm as Record<string, string>,
    );
    params.set(
      '$skip',
      String(action.payload.pageIndex * action.payload.pageSize),
    );
    params.set('$top', String(action.payload.pageSize));
    const results: IExternalArticlesSearchResponse = yield call(
      httpClient.get,
      url,
      params,
    );
    yield put(
      actions.externalSearch_Success({
        count: results.Count,
        items: results.Items,
        pageIndex: action.payload.pageIndex,
      }),
    );
  } catch (error) {
    if ((error as AxiosError).isAxiosError) {
      var errorCode = (error as AxiosError).response?.status;
      if (errorCode === StatusCodes.TOO_MANY_REQUESTS) {
        yield put(
          appSettingsActions.addNotification({
            variant: 'error',
            message: i18next.t(translations.TooManyRequests),
          }),
        );
      }
    }
    yield put(actions.externalSearch_Error('Error searching external source'));
  }
}
function* doSaveArticleAssociation(action: PayloadAction<IArticleDto>) {
  try {
    const isNew = (action.payload.Id ?? 0) === 0;
    const url = isNew
      ? '/api/odata/v4/articles'
      : `/api/odata/v4/articles(${action.payload.Id})`;
    const fn = isNew ? httpClient.post : httpClient.put;
    const result = yield call(fn, url, action.payload);
    yield put(layoutActions.resetSidePanel());
    yield put(layoutActions.setRefreshTable(true));
    if (result.Queryable[0].Status === ArticleStatus.AwaitingApproval) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'success',
          message: i18next.t(translations.PublicationAwaitingApproval),
        }),
      );
    } else {
      yield put(
        appSettingsActions.addNotification({
          variant: 'success',
          message: i18next.t(translations.Success),
        }),
      );
    }
    yield put(actions.saveArticleAssociation_Success(result));
  } catch (error) {
    yield put(
      appSettingsActions.addNotification({
        variant: 'error',
        message: i18next.t(translations.AnErrorOccurred),
      }),
    );
    yield put(actions.saveArticleAssociation_Error(action.payload));
  }
}

export function* articlesSaga() {
  yield takeLatest(actions.showDetails.type, doShowDetails);
  yield takeLatest(actions.hideDetails.type, doHideDetails);
  yield takeLatest(actions.externalSearch.type, doExternalSearch);
  yield takeLatest(actions.chnagePage.type, doExternalSearch);

  yield takeLatest(
    actions.saveArticleAssociation.type,
    doSaveArticleAssociation,
  );
}
