import { httpClient } from './HttpClient';

var restUrl = '/api/odata/v4/RestrictedUserPermissions';

export const UserPermissionsApi = {
  getUserRestrictions: (payload: { serviceId: number; username: string }) => {
    return httpClient.post(restUrl + '/UserPermissions', payload);
  },
  getUserSlotPermissions: (payload: {
    serviceId: number;
    username: string;
  }) => {
    return httpClient.post(restUrl + '/UserSlotPermissions', payload);
  },
};
