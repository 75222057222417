/**
 *
 * Copyright
 *
 */
import { useTheme } from '@material-ui/core';
import {
  selectAppTimeZone,
  selectLastCodeUpdate,
  selectWebVersion,
} from 'app/slice/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { dateUtils } from 'utils/date-utils';
import { Link } from '../ExternalLink';
import BasicTypography from '../Typography/BasicTypography';
import { messages } from './messages';
import { CopyrightSection } from './styled';

export interface CopyrightProps {
  disableVersionOnMobile?: boolean;
}

export function Copyright(props: CopyrightProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const webVersion = useSelector(selectWebVersion);
  const lastUpdate = useSelector(selectLastCodeUpdate);
  const appTimeZone = useSelector(selectAppTimeZone);
  const appOffset = appTimeZone?.TimezoneOffsetMinutes ?? 0;
  const getOffset = () => {
    const currentDateOffset = new Date().getTimezoneOffset();
    const appNow = dateUtils.addMinutes(
      new Date(),
      appOffset + currentDateOffset,
    );
    return appNow;
  };
  const [appNow, setAppNow] = React.useState<Date>(getOffset());
  React.useEffect(() => {
    const interval = setInterval(() => {
      // const currentDateOffset = new Date().getTimezoneOffset();
      // const appNow = dateUtils.addMinutes(
      //   new Date(),
      //   appOffset + currentDateOffset,
      // );
      setAppNow(getOffset());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  });
  const theme = useTheme();
  return (
    <CopyrightSection theme={theme} className={'copyrightSection'}>
      <BasicTypography variant="tipsBold" color="secondary" align="left">
        <Link href="http://bookit-lab.com" target="_blank">
          {t(messages.bookitlabCopyrightNotice())}
        </Link>
        <>{` `}</>
        <span>{t(messages.softwareBy())}</span>
        <>{` `}</>
        <Link href="http://prog4biz.com/" target="_blank">
          {t(messages.prog4biz())}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </BasicTypography>
      {!props.disableVersionOnMobile && (
        <BasicTypography variant="tipsBold" color="secondary" align="right">
          <span>
            {`v${webVersion}; ` || ''}{' '}
            {` ${t(messages.systemTime())}: ${dateUtils.longDateTimeFormat(
              appNow,
            )};`}{' '}
            {lastUpdate &&
              `${t(messages.lastCodeUpdate())}: ${dateUtils.longDateTimeFormat(
                lastUpdate,
              )};`}
          </span>
        </BasicTypography>
      )}
      <span>
        <BasicTypography variant="tipsBold" align="right">
          <Link href="/TermsOfUseAgreement.aspx" target="_blank">
            {t(messages.termOfUse())}
          </Link>
        </BasicTypography>
      </span>
    </CopyrightSection>
  );
}
