import { Box } from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { ReservationEditButton } from 'app/components/ReservationEditButton';
import { selectglobalSettings } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateUtils } from 'utils/date-utils';
import { AllowedSettings } from 'utils/globalSettings';
import { isNullOrUndefined } from 'utils/typeUtils';
import { WorkOrderReadonlyState } from '../slice/types';
import { useStyles } from './styles';

export interface CreationDetailsProps {
  details: WorkOrderReadonlyState;
}

export function CreationDetails(props: CreationDetailsProps) {
  const { details } = props;
  const globalSettings = useSelector(selectglobalSettings);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Body bold={true}>{t(translations.WorkOrderAdvancedDetails)}</Body>
        <Box className={classes.content}>
          <Box className={classes.row}>
            <Body size="small">{t(translations.ReportedBy)}</Body>
            <Body size="small" bold={true}>
              {details.ReportedByDisplayName}
            </Body>
          </Box>
          <Box className={classes.row}>
            <Body size="small">{t(translations.ReportedDate)}</Body>
            <Body size="small" bold={true}>
              {dateUtils.longDateTimeFormat(details.CreateDate)}
            </Body>
          </Box>
          <Box className={classes.row}>
            <Body size="small">{t(translations.LastUpdated)}</Body>
            <Body size="small" bold={true}>
              {dateUtils.longDateTimeFormat(details.LastUpdated) ?? 'N/A'}
            </Body>
          </Box>
          <Box className={classes.row}>
            <Body size="small">{t(translations.AlertType)}</Body>
            <Body size="small" bold={true}>
              {details.AlertType ?? 'N/A'}
            </Body>
          </Box>
          <Box className={classes.row}>
            <Body size="small">{t(translations.EventTypeStatus)}</Body>
            <Body size="small" bold={true}>
              {details.StatusName ?? 'N/A'}
            </Body>
          </Box>
          <Box className={classes.row}>
            <Body size="small">{t(translations.ClosedAt)}</Body>
            <Body size="small" bold={true}>
              {dateUtils.longDateTimeFormat(details.DoneDate) ||
                t(translations.WorkStillInProgress)}
            </Body>
          </Box>
          <Box className={classes.row}>
            <Body size="small">{t(translations.ClosedBy)}</Body>
            <Body size="small" bold={true}>
              {details.DoneBy ?? 'N/A'}
            </Body>
          </Box>
          {details.Reservation !== null && (
            <Box className={classes.row}>
              <Body size="small">{t(translations.Reservation)}</Body>
              <ReservationEditButton
                reservationId={details.Reservation}
                variant="text"
              >
                {`# ${details.Reservation}`}
              </ReservationEditButton>
            </Box>
          )}
          {globalSettings.GetBooleanByKey(AllowedSettings.AssetCatEnabled) &&
            !isNullOrUndefined(details.AssetCatName) && (
              <Box className={classes.row}>
                <Body size="small">{t(translations.AssetCatName)}</Body>
                <Body size="small" bold={true}>{`${details.AssetCatName} - ${
                  details.InternalServiceAvaliable
                    ? t(translations.InternalServiceAvaliable)
                    : ''
                }`}</Body>
              </Box>
            )}
        </Box>
      </Box>
    </React.Fragment>
  );
}
