import * as React from 'react';
import { Body } from 'app/components/Typography';
import { FormFieldsBox } from '../wrappers';
import { Divider, useTheme } from '@material-ui/core';

export interface FormFieldsSectionProps {
  children: React.ReactNode;
  titleSection?: string | React.ReactNode;
  titleLink?: React.ReactNode;
  styleSection?: React.CSSProperties;
  withTopSeparator?: boolean;
  withBottomSeparator?: boolean;
}
export const FormFieldsSection = (props: FormFieldsSectionProps) => {
  const {
    children,
    titleSection,
    titleLink,
    styleSection,
    withTopSeparator,
    withBottomSeparator,
  } = props;
  const theme = useTheme();
  return (
    <FormFieldsBox style={styleSection} theme={theme}>
      {withTopSeparator && (
        <Divider
          style={{ width: '100%', alignSelf: 'center', marginBottom: 12 }}
        />
      )}
      {titleSection && (
        <div className={'sectiontitle'}>
          <Body bold={true}>{titleSection}</Body>
          {titleLink || <></>}
        </div>
      )}

      <div className={`fields`}>{children}</div>
      {withBottomSeparator && (
        <Divider
          style={{ width: '100%', alignSelf: 'center', marginTop: 12 }}
        />
      )}
    </FormFieldsBox>
  );
};
