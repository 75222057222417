import { httpClient } from 'api/HttpClient';
import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { InternalServiceRequestRowStatus } from 'api/odata/generated/enums/InternalServiceRequestRowStatus';
import { DialogConfirm } from 'app/components/DialogConfirm';

import {
  IServiceRequestTableRowModel,
  IServiceRequestTableRowValueModel,
} from 'app/pages/Samples/RequestSamplesPage/slice/types';
import { ImportComponent } from 'app/pages/Samples/RunDetailsPage/ImportComponents/ImportComponent';
import { appSettingsActions } from 'app/slice';
import { useFormikContext } from 'formik';
import { StatusCodes } from 'http-status-codes';
import { translations } from 'locales/translations';
import { chain, Dictionary } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { openExportLink } from 'utils/url-utils';
import {
  IServiceRequestRelated,
  IServiceRequestTableFormComponent,
} from '../..';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { selectServiceRequestCustomFormColumns } from 'app/pages/Samples/RequestSamplesPage/slice/selectors';
import { ServiceRequestTableColumnsCollection } from 'app/pages/Samples/RequestSamplesPage/slice/utils/ServiceRequestTableColumn';

export interface ServiceRequestRowsImportProps
  extends IServiceRequestRelated,
    IServiceRequestTableFormComponent {}
export function ServiceRequestRowsImport(props: ServiceRequestRowsImportProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formik = useFormikContext<any>();
  const columns = useSelector(selectServiceRequestCustomFormColumns);
  const { serviceRequestId } = props;
  const handleFileChange = async (file: File) => {
    const url = `/api/Requests/${serviceRequestId}/tabledata`;
    try {
      const response = await httpClient.uploadFile(url, file, {
        responseType: 'json',
      });
      switch (response.status) {
        case StatusCodes.OK:
          dispatch(
            appSettingsActions.addNotification({
              variant: 'success',
              message: t(translations.ImportSuccessMessage),
            }),
          );
          const model = convertImportResponse(
            response.data,

            columns,
            serviceRequestId,
          );
          model.forEach(item => {
            const index = formik.values.Rows.findIndex(
              row => row.Id === item.Id,
            );
            if (index === -1) {
              props.arrayHelpers.push(item);
            } else {
              props.arrayHelpers.replace(index, item);
            }
          });
          setOpen(false);
          break;
        default:
          dispatch(
            appSettingsActions.addNotification({
              variant: 'error',
              message:
                response.data.ExceptionMessage ??
                t(translations.ImportErrorMessage),
            }),
          );
          break;
      }
    } catch (error) {
      dispatch(
        appSettingsActions.addNotification({
          variant: 'error',
          message: t(translations.ImportErrorMessage),
        }),
      );
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button
        startIcon={<Icon icon="file-arrow-up" />}
        variant="white"
        size="small"
        onClick={() => setOpen(true)}
      >
        {t(translations.Import)}
      </Button>
      <DialogConfirm
        isOpen={open}
        title={t(translations.UpdateColumnValueAcrossMultipleRows)}
        body={
          <React.Fragment>
            <DownloadImportTemplate
              url={`/api/requests/${serviceRequestId}/importTemplate`}
            >
              {t(translations.ImportTemplate)}
            </DownloadImportTemplate>
            <hr />
            <ImportComponent
              processing={false}
              accept=".csv"
              handleFileChange={handleFileChange}
              hideMessage={false}
            />
          </React.Fragment>
        }
        cancelButtonLabel={t(translations.Close)}
        onCancel={handleClose}
        onClose={handleClose}
      />
    </div>
  );
}

interface DownloadFileButtonProps extends ButtonProps {
  url: string;
}
function DownloadImportTemplate({
  url,
  ...props
}: React.PropsWithChildren<DownloadFileButtonProps>) {
  const handleClick = async () => {
    try {
      await openExportLink(url);
    } catch (error) {
      console.error(error);
    }
  };
  return <Button onClick={handleClick} size="small" {...props}></Button>;
}
export function ExportRows({
  url,
  ...props
}: React.PropsWithChildren<DownloadFileButtonProps>) {
  const handleClick = async () => {
    try {
      await openExportLink(url);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Button
      variant="white"
      onClick={handleClick}
      size="small"
      startIcon={<Icon icon="print" size="sm" />}
      {...props}
    ></Button>
  );
}
/**
 * Response example:
 *  [
  {
    Id: 0,
    CommentsCount: 0,
    FilesCount: 0,
    Status: { Id: 0, InternalStatusId: 0, Name: 'New' },
    ReservationId: null,
    '1001': null,
    '1002': { id: 'a1', text: 'a1', isValid: true, SourceValueId: null },
    '1011': null,
    '1003': null,
    '1004': '1',
    '1005': null,
    '1006': null,
    '1007': null,
    '1008': null,
    '1010': '1',
  }
  ...
]
 * @param importResponse 
 * @param arrayHelpers 
 * @param values 
 * @param serviceRequestId 
 * @returns 
 */
function convertImportResponse(
  importResponse: Array<
    {
      Id: number;
      Status: any;
      ReservationId: any;
      CommentsCount: any;
      FilesCount: any;
    } & Record<string, any>
  >,
  columns: ServiceRequestTableColumnsCollection | null,
  serviceRequestId: number,
): Array<IServiceRequestTableRowModel> {
  const result = importResponse.map(item => {
    const values =
      columns === null
        ? {}
        : (chain(columns.columnsObj)
            .mapValues(column => {
              const value = item[column.Id.toString()];
              if (value === null) {
                return null;
              } else if (typeof value === 'string') {
                return value;
              } else if (
                typeof value === 'object' &&
                typeof value.id === 'string' &&
                typeof value.text === 'string'
              ) {
                return {
                  Id: value.id,
                  Name: value.text,
                };
              } else {
                return null;
              }
            })
            .value() as Dictionary<IServiceRequestTableRowValueModel>);
    const model: IServiceRequestTableRowModel = {
      Id: item.Id,
      Status: {
        Id: item.Status.Id,
        Name: item.Status.Name,
        InternalStatus:
          InternalServiceRequestRowStatus[item.Status.InternalStatusId],
      },
      Values: values,
      Charged: false,
      CommentsCount: item.CommentsCount,
      Completed: false,
      InsertDate: '',
      ReservationId: null,
      Serial: 0,
      ServiceRequestId: serviceRequestId,
      SourceLinkId: null,
      SourceRowId: null,
      StatusId: item.Status.Id,
      UpdateDate: '',
    };
    return model;
  });

  return result;
}
