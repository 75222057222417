import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.training || initialState;

export const selectTraining = createSelector([selectSlice], state => state);

export const selectInitTrainingProcessing = createSelector(
  [selectTraining],
  state => state.processing,
);
export const selectCreateTrainingProcessing = createSelector(
  [selectTraining],
  state => state.createState.processing,
);
export const selectUpdateTrainingProcessing = createSelector(
  [selectTraining],
  state => state.updateState.processing,
);
export const selectCreateTraining = createSelector(
  [selectTraining],
  state => state.createState.data,
);
export const selectUpdateTraining = createSelector(
  [selectTraining],
  state => state.updateState.data,
);
export const selectCreateTrainingCompleted = createSelector(
  [selectTraining],
  state => state.createState.completed,
);
export const selectUpdateTrainingCompleted = createSelector(
  [selectTraining],
  state => state.updateState.completed,
);
export const selectCreateTrainingHasError = createSelector(
  [selectTraining],
  state => state.createState.hasErrors,
);
export const selectUpdateTrainingHasError = createSelector(
  [selectTraining],
  state => state.updateState.hasErrors,
);
export const selectShowApproveReservatiions = createSelector(
  [selectTraining],
  state => state.reservationsUpdateState.showApproveReservations,
);
export const selectShowRejectReservatiions = createSelector(
  [selectTraining],
  state => state.reservationsUpdateState.showRejectReservations,
);
export const selectReservatiionsData = createSelector(
  [selectTraining],
  state => state.reservationsUpdateState.ApproveRejectRecords,
);
export const selectReservatiionsProcessing = createSelector(
  [selectTraining],
  state => state.reservationsUpdateState.processing,
);
export const selectReservatiionsUpdateCompleted = createSelector(
  [selectTraining],
  state => state.reservationsUpdateState.completed,
);
