import { Theme } from '@material-ui/core';
import { PropTypes } from '@material-ui/core';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

export type TypeColor =
  | Exclude<PropTypes.Color, 'inherit'>
  | 'danger'
  | 'accent'
  | 'filters'
  | 'success'
  | 'black'
  | 'info'
  | 'warning';
export type TypeColorOptions =
  | 'dark'
  | 'main'
  | 'light'
  | 'textHover'
  | 'bgHover'
  | 'contrastText';
export const getColor = (
  theme: Theme,
  color: TypeColor | undefined = 'primary',
  options: TypeColorOptions | undefined = 'main',
): string | undefined => {
  switch (color) {
    case 'black':
      return getBlackColorOption(options, theme);
    case 'accent':
    case 'danger':
    case 'default':
    case 'filters':
    case 'info':
    case 'primary':
    case 'secondary':
    case 'success':
    case 'warning':
      return getColorOption(options, theme.palette[color]);
    default:
      return getColorOption(options, theme.palette.primary);
  }
};
function getBlackColorOption(options: string, theme: Theme) {
  switch (options) {
    case 'main':
      return theme.palette.common.graySubtitle;
    case 'dark':
      return theme.palette.common.blackRegular;
    case 'light':
      return theme.palette.common.grayComponentsLight;
    case 'textHover':
      return theme.palette.common.blackComponent;
    case 'bgHover':
      return theme.palette.common.backgroundHover;
    case 'contrastText':
      return theme.palette.common.white;
    default:
      return theme.palette.common.graySubtitle;
  }
}
function getColorOption(options: string | undefined, color: PaletteColor) {
  switch (options) {
    case 'main':
      return color.main;
    case 'dark':
      return color.dark;
    case 'light':
      return color.light;
    case 'textHover':
      return color.textHover;
    case 'bgHover':
      return color.bgHover;
    case 'contrastText':
      return color.contrastText;
    default:
      return color.main;
  }
}
