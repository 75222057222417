import { CellProps, Renderer } from 'react-table';
import { UserProfileLink } from '../../components/UserProfileLink';

const UserRenderer: Renderer<CellProps<Record<string, any>>> = ({
  value,
  row,
}) => {
  if (value === null || value === undefined) {
    return <></>;
  }
  return <UserProfileLink UserId={row.original.UserId} DisplayName={value} />;
};
export default UserRenderer;
