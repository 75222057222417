import { dateUtils } from 'utils/date-utils';
import { pick } from 'lodash';
import { Period } from 'types/Period';

/**
 * Adjusts start/end times of a reservation to match the ForceFullDayReservations requirements:
 * * starts = now if reservation is placed for today, start of the day otherwise
 * * ends = midnight of the next day
 * @param period
 * @returns adjusted start/end times
 */
export function applyForceFullDayReservations(
  period: Period,
  forceFullDayReservations: boolean | undefined,
): Period {
  if (forceFullDayReservations !== true) {
    return pick(period, 'start', 'end');
  }

  const now = new Date();
  const startsToday =
    dateUtils.truncateTime(now).getTime() ===
    dateUtils.truncateTime(period.start).getTime();

  const startTime = startsToday ? now : dateUtils.truncateTime(period.start);

  const truncatedEndTime = dateUtils.truncateTime(period.end);
  const adjustEndTime = period.end.getTime() !== truncatedEndTime.getTime();
  const endTime = adjustEndTime
    ? dateUtils.addDays(dateUtils.truncateTime(period.end), 1)
    : period.end;
  const result = {
    start: startTime,
    end: endTime,
  };
  return result;
}
