import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { ArticleStatus } from 'enums/Article';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectArticleDetailsForm } from '../slice/selectors';

export type AllowedToChangeStatusFunc = (asset: IAssetDto) => boolean;
export const useAllowedToChangeStatus = (): AllowedToChangeStatusFunc => {
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const admin = authenticatedUser?.Roles.includes(Roles.GroupAdministrators);
  const allowedToChangeStatus = React.useCallback(
    asset => {
      if (admin && asset !== undefined && asset !== null) {
        return asset.ServiceGroupId !== null &&
          asset.ServiceGroupId !== undefined &&
          (authenticatedUser?.HasAdminGroupOnlyPermissions(
            asset.ServiceGroupId,
          ) ||
            authenticatedUser?.HasLabTechGroupPermissions(asset.ServiceGroupId))
          ? true
          : false;
      } else {
        return false;
      }
    },
    [admin, authenticatedUser],
  );
  return allowedToChangeStatus;
};

export const useCanEdit = (): [boolean, string] => {
  const { t } = useTranslation();
  const articleDetailsForm = useSelector(selectArticleDetailsForm);
  const allowedToChangeStatus = useAllowedToChangeStatus();
  if (allowedToChangeStatus(articleDetailsForm?.ServiceGroupAssets[0])) {
    return [true, ''];
  } else {
    // simple user rights
    if (articleDetailsForm?.CreatedByMe) {
      if (articleDetailsForm?.Status === ArticleStatus.Approved) {
        return [false, t(translations.PublicationRecordApproved_info)];
      } else {
        return [true, ''];
      }
    } else {
      return [false, ''];
    }
  }
};
