import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { UserTrainingReservationData } from '../slice/types';
import { translations } from 'locales/translations';
import { ActionButton } from 'app/components/BasicButtons/ActionButton';
import { DialogActionsRenderer } from 'app/components/BasicDialog/DialogActionsRender';
import { useDispatch } from 'react-redux';
import { TrainingDetailsProps } from 'app/pages/UserTrainings/TrainingDetailsPage/Details';
import { dateUtils } from 'utils/date-utils';
import { useHistory } from 'react-router-dom';
import { ITrainingReservations } from 'app/pages/ReservationHistoryPage/ITrainingReservations';
import { ReservationsApi } from 'api/ReservationsApi';
import { useAppSettingsSlice } from 'app/slice';
import { AxiosError } from 'axios';
import i18next from 'i18next';

export interface ApproveTrainingRecordsProps {
  show: boolean;
  data: UserTrainingReservationData;
  handleApproveOnce: () => void;
  handleEditAssisted: (p: TrainingDetailsProps, reset: boolean) => void;
}
export function ApproveTrainingRecords(props: ApproveTrainingRecordsProps) {
  const { show, data, handleApproveOnce, handleEditAssisted } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions: appSetActions } = useAppSettingsSlice();
  const { t } = useTranslation();
  const existingTrainings = React.useMemo(() => {
    return data.Equipments.filter(f => f.RequiresAdminApproval === true);
  }, [data.Equipments]);
  const servicesWithoutTraining = React.useMemo(() => {
    return data.Equipments.filter(f => f.TrainingRecordId === null);
  }, [data.Equipments]);

  //BKT-9144 Customer feedback UiO: Assisted: Toggle “DO NOT SHOW POP UP FOR APPROVING THE USER FOR GOOD/ CREATING TRAINING RECORD” to ON does not hide the pop up.
  //setting - DO NOT SHOW POP UP FOR APPROVING THE USER FOR GOOD/ CREATING TRAINING RECORD
  const showPopupAccordingToSetting = React.useMemo(() => {
    return !data.Equipments.every(f => f.NotShowPopup === true);
  }, [data.Equipments]);

  const showPopup = React.useMemo(() => {
    return (
      (!data.Equipments.every(f => f.NotShowPopup === true) &&
        existingTrainings.length > 0) ||
      servicesWithoutTraining.length > 0
    );
  }, [
    data.Equipments,
    existingTrainings.length,
    servicesWithoutTraining.length,
  ]);

  const handleEditClick = React.useCallback(() => {
    if (existingTrainings.length === 1) {
      handleEditAssisted(
        {
          id: (existingTrainings[0].TrainingRecordId ?? 0).toString(),
          equId: undefined,
          equIds: undefined,
          fatherId: undefined,
          rid: undefined,
          date: undefined,
          user: undefined,
          useSidePanel: true,
        } as TrainingDetailsProps,
        true,
      );
    } else {
      history.push(
        `/History?un=${data.UserName}&st=${dateUtils.formatQueryStringDate(
          dateUtils.dateOrStringToDate(data.StartTime),
        )}&et=${dateUtils.formatQueryStringDate(
          dateUtils.dateOrStringToDate(data.EndTime),
        )}`,
      );
    }
  }, [
    data.EndTime,
    data.StartTime,
    data.UserName,
    existingTrainings,
    handleEditAssisted,
    history,
  ]);
  const handleNweCreateClick = React.useCallback(() => {
    handleEditAssisted(
      {
        id: undefined,
        equId: undefined,
        equIds: data.Equipments.map(f => f.EquipmentId).join(','),
        fatherId: data.FatherId !== null ? data.FatherId.toString() : undefined,
        rid:
          data.FatherId !== null
            ? undefined
            : data.ReservationId !== null
            ? data.ReservationId.toString()
            : undefined,
        date: dateUtils.formatISO(dateUtils.dateOrStringToDate(data.StartTime)),
        user: data.UserName,
        useSidePanel: true,
      } as TrainingDetailsProps,
      true,
    );
  }, [
    handleEditAssisted,
    data.Equipments,
    data.FatherId,
    data.ReservationId,
    data.StartTime,
    data.UserName,
  ]);
  const handleCreateTrainings = React.useCallback(() => {
    const postData = servicesWithoutTraining.map(item => {
      return {
        BookedBy: data.UserName,
        ReservationId: data.ReservationId,
        EquipmentId: item.EquipmentId,
        StartTime: data.StartTime,
        TrainingLevelId: item.TrainingRecordId,
      };
    }) as ITrainingReservations[];
    (async () => {
      try {
        let trdata = await ReservationsApi.createTraining(postData);
        if (trdata.length > 0) {
          let success = t(translations.TrainingsCreatedSuccessfully) as string;
          dispatch(
            appSetActions.addNotification({
              variant: 'success',
              message: success.replace('{0}', trdata.length),
              key: 'training-created',
            }),
          );
        } else {
          let info = t(translations.TrainingsAlreadyExist) as string;
          dispatch(
            appSetActions.addNotification({
              variant: 'warning',
              message: info,
              key: 'training-warning',
            }),
          );
        }
        handleApproveOnce();
      } catch (error) {
        const message =
          (error as AxiosError)?.response?.data?.error?.innererror?.message ??
          ((error as AxiosError)?.response?.status === 403
            ? i18next.t(translations.Forbidden)
            : undefined) ??
          i18next.t(translations.errormessage);
        dispatch(
          appSetActions.addNotification({
            variant: 'error',
            message: message,
            key: 'training-error',
          }),
        );
      }
    })();
  }, [
    appSetActions,
    data.ReservationId,
    data.StartTime,
    data.UserName,
    dispatch,
    handleApproveOnce,
    servicesWithoutTraining,
    t,
  ]);
  const actions: DialogActionsRenderer[] = React.useMemo(() => {
    let buttons: DialogActionsRenderer[] = [];
    buttons.push(() => (
      <ActionButton
        key="approveOnce"
        text={t(translations.ApproveOnce)}
        variant="main"
        onClick={() => handleApproveOnce()}
      />
    ));
    if (existingTrainings.length > 0) {
      buttons.push(() => (
        <ActionButton
          key="editTraining"
          text={(t(translations.menu_EditUserTraining) as string).replace(
            '{0}',
            '' + existingTrainings[0].TrainingRecordId ?? 0,
          )}
          variant="white"
          onClick={() => handleEditClick()}
        />
      ));
    }
    buttons.push(() => (
      <ActionButton
        key="createNewTraining"
        text={t(translations.CreateDetailedTraining)}
        variant="main"
        onClick={() => handleNweCreateClick()}
      />
    ));
    if (servicesWithoutTraining.length > 0) {
      buttons.push(() => (
        <ActionButton
          key="createTraining"
          text={t(translations.ApproveForGood)}
          variant="main"
          onClick={() => handleCreateTrainings()}
        />
      ));
    }
    return buttons;
  }, [
    existingTrainings,
    handleApproveOnce,
    handleCreateTrainings,
    handleEditClick,
    handleNweCreateClick,
    servicesWithoutTraining.length,
    t,
  ]);
  return (
    <DialogConfirm
      isOpen={show && showPopupAccordingToSetting && showPopup}
      title={t(translations.AssistedReservationApprovalOptions)}
      body={
        existingTrainings.length > 0
          ? t(translations.This_user_already_has_a_valid_training)
          : t(translations.CreateNewTrainingRecord_YesNo)
      }
      maxWidth="md"
      actions={actions}
    />
  );
}
