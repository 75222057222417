import { Column, SortingRule } from 'react-table';
import { Entity } from 'types/common';
import { IRow } from '..';

export function SortByComparer<TRow>(
  prev: readonly [SortingRule<TRow>[] | undefined],
  next: readonly [SortingRule<TRow>[] | undefined],
) {
  if (prev[0] === undefined && next[0] === undefined) {
    return true;
  } else if (prev[0] === undefined && next[0] !== undefined) {
    return false;
  } else if (next[0] === undefined && prev[0] !== undefined) {
    return false;
  } else {
    if (prev[0]?.length === next[0]?.length) {
      if (prev[0]?.length === 0 && next[0]?.length === 0) {
        return true;
      } else {
        if (
          prev[0]?.some(
            f => !next[0]?.some(n => n.id === f.id && n.desc === f.desc),
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }
}
export function ServiceGroupsComparer(
  prev: readonly [Entity<number>[]],
  next: readonly [Entity<number>[]],
) {
  if (!!prev[0]) {
    if (prev[0].length === next[0].length) {
      if (prev[0].length === 0 && next[0].length === 0) {
        return true;
      } else {
        if (prev[0].some(f => !next[0].some(n => n.Id === f.Id))) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }
  return false;
}
export function columnsComparer<TRow extends IRow>(
  prev: readonly [Column<TRow>[]],
  next: readonly [Column<TRow>[]],
) {
  if (!!prev[0]) {
    if (prev[0].length === next[0].length) {
      if (prev[0].length === 0 && next[0].length === 0) {
        return true;
      } else {
        if (prev[0].some(f => !next[0].some(n => n.accessor === f.accessor))) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }
  return false;
}
export function ColumnAccesorsComparer<TRow>(
  prev: readonly [(keyof TRow)[] | undefined],
  next: readonly [(keyof TRow)[] | undefined],
) {
  if (prev[0] === undefined && next[0] === undefined) {
    return true;
  } else if (prev[0] === undefined && next[0] !== undefined) {
    return false;
  } else if (next[0] === undefined && prev[0] !== undefined) {
    return false;
  } else {
    if (prev[0]?.length === next[0]?.length) {
      if (prev[0]?.length === 0 && next[0]?.length === 0) {
        return true;
      } else {
        if (prev[0]?.some(f => !next[0]?.some(n => n === f))) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }
}
