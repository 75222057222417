import { PayloadAction } from '@reduxjs/toolkit';
import { IAssetDetailsDto } from 'api/odata/generated/entities/IAssetDetailsDto';
import { ILabelTypeDto } from 'api/odata/generated/entities/ILabelTypeDto';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { labelPrintSaga } from './saga';
import {
  LabelPrintState,
  LabelPrinter,
  IPrintAssetLabelArgs,
  AsyncState,
  OpenPrintDialogPayload,
} from './types';

export const initialState: LabelPrintState = {
  ids: [],
  labelTypes: {},
  printers: {},
  printState: {},
  data: {},
  open: false,
};

function init<T>(state: AsyncState<T>) {
  state.status = 'pending';
  state.error = undefined;
  state.value = undefined;
}
function resolve<T>(state: AsyncState<T>, value: T) {
  state.status = 'resolved';
  state.error = undefined;
  state.value = value;
}
function reject<T>(state: AsyncState<T>, error: unknown) {
  state.status = 'rejected';
  state.value = undefined;
  state.error = String(error);
}

const slice = createSlice({
  name: 'labelPrint',
  initialState,
  reducers: {
    getPrinters(state, action: PayloadAction) {
      init(state.printers);
    },
    getPrinters_Success(state, action: PayloadAction<Array<LabelPrinter>>) {
      resolve(state.printers, action.payload);
    },
    getPrinters_Error(state, action: PayloadAction<any>) {
      reject(state.printers, action.payload);
    },

    getLabelTypes(state, action: PayloadAction<OpenPrintDialogPayload>) {
      init(state.labelTypes);
    },
    getLabelTypes_Success(state, action: PayloadAction<Array<ILabelTypeDto>>) {
      resolve(state.labelTypes, action.payload);
    },
    getLabelTypes_Error(state, action: PayloadAction<any>) {
      reject(state.labelTypes, action.payload);
    },

    getAssetDetails(state, action: PayloadAction<OpenPrintDialogPayload>) {
      init(state.data);
    },
    getAssetDetails_Success(
      state,
      action: PayloadAction<Array<IAssetDetailsDto>>,
    ) {
      resolve(state.data, action.payload);
    },
    getAssetDetails_Failure(state, action: PayloadAction<any>) {
      reject(state.data, action.payload);
    },

    open(state, action: PayloadAction<OpenPrintDialogPayload>) {
      state.open = true;
      state.ids = action.payload.ids;
      state.type = action.payload.type;
      state.data = {};
    },
    closeAssetLabelPrintDialog(state, action: PayloadAction) {
      state.open = false;
      state.ids = [];
      state.data = {};
      state.printState = {};
    },

    print(state, action: PayloadAction<IPrintAssetLabelArgs>) {
      init(state.printState);
      state.selectedPrinter = action.payload.printer ?? null;
      state.selectedTemplate = action.payload.template ?? null;
    },
    print_Success(state, action: PayloadAction<any>) {
      state.data = {};
      state.open = false;
      resolve(state.printState, null);
    },
    print_Error(state, action: PayloadAction<any>) {
      reject(state.printState, action.payload);
    },

    download(state, action: PayloadAction<IPrintAssetLabelArgs>) {
      init(state.printState);
      state.selectedPrinter = action.payload.printer ?? null;
      state.selectedTemplate = action.payload.template ?? null;
    },
    download_Success(state, action: PayloadAction<any>) {
      state.data = {};
      state.open = false;
      resolve(state.printState, null);
    },
    download_Error(state, action: PayloadAction<any>) {
      reject(state.printState, action.payload);
    },

    preview(state, action: PayloadAction<IPrintAssetLabelArgs>) {
      init(state.printState);
      state.selectedPrinter = action.payload.printer ?? null;
      state.selectedTemplate = action.payload.template ?? null;
    },
    preview_Success(state, action: PayloadAction<any>) {
      state.data = {};
      state.open = false;
      resolve(state.printState, null);
    },
    preview_Error(state, action: PayloadAction<any>) {
      reject(state.printState, action.payload);
    },
  },
});

export const { actions: labelPrintActions } = slice;

export const useLabelPrintSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: labelPrintSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useLabelPrintSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
