import { Box, ListItem, ListItemText, MenuItem } from '@material-ui/core';
import { ButtonLabel } from 'app/components/Typography';
import { useIsMobile } from 'app/hooks/useIsMobile';
import {
  selectAuthenticatedUser,
  selectGlobalServiceGroupFilter,
} from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FilterBox } from '../FilterBox';
import { savedViewsApi as api } from 'api/savedViewsApi';
import { ISavedViewDto } from 'api/odata/generated/entities/ISavedViewDto';
import { Skeleton } from '@material-ui/lab';
import { Body } from 'app/components/Typography';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Uppercase } from 'app/components/Typography';
import { Button } from 'app/components/BasicButtons/Button';
import { SavedViewDetailsProps } from 'app/pages/SavedViewsPage/SavedViewPage/Details';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import {
  savedViewActions,
  useSavedViewSlice,
} from 'app/pages/SavedViewsPage/SavedViewPage/slice';
import { Select } from 'app/components/BasicInputs/Select';
import { useLocation } from 'react-router-dom';
import { routerActions } from 'connected-react-router';
import { IFilterSettings } from '../IFilterSettings';
import { Entity } from 'types/common';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { usePromise } from 'app/hooks/usePromise';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import { URLSearchParamsCI } from '../../types/FilterParam';
import { tryParseInt } from 'utils/string-utils';

export interface SavedListsDropDownProps<T> {
  serviceItem: IFilterSettings<T>;
  onRemove: (item: IFilterSettings<T>) => void;
  onApply: (item: IFilterSettings<T>) => void;
  addFilter: (value: IFilterSettings<T>) => void;
  hasInVisibleFilters: boolean;
  serializedKey: string;
  listViewbyAsset?: boolean;
}
export const SAVEDVIEW_SEARCH_KEY = 'savedview';
function SavedLists<TRow>({
  serviceItem,
  onRemove,
  onApply,
  addFilter,
  hasInVisibleFilters,
  serializedKey,
  listViewbyAsset,
}: SavedListsDropDownProps<TRow>) {
  const { checkIsMobile } = useIsMobile();
  const isMobile = checkIsMobile();
  const { t } = useTranslation();
  const user = useSelector(selectAuthenticatedUser);
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const savedActions = useSavedViewSlice();
  const isAdmin = React.useMemo(() => {
    return (
      user !== undefined &&
      (user.Roles.indexOf(Roles.Administrators) >= 0 ||
        user.Roles.indexOf(Roles.GroupAdministrators) >= 0 ||
        user.Roles.indexOf(Roles.LabTech) >= 0 ||
        user.Roles.indexOf(Roles.EquipmentAdministrator) >= 0)
    );
  }, [user]);
  const selectedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const location = useLocation();
  const fetchView = async (
    user: AuthenticatedUser | undefined,
    serviceGroups: Entity<number>[] | undefined,
    isAdmin: boolean,
  ): Promise<ISavedViewDto[]> => {
    return api
      .getMySavedViews({
        user: user,
        serviceGroups: selectedServiceGroups,
        isAdmin: isAdmin,
        onlyMyView: false,
      })
      .then(data => {
        return data;
      });
  };
  const containsText = (text, searchText) =>
    text.toLowerCase().includes(searchText.toLowerCase());

  const [myViews, setMyViews] = useAsyncExtendedState<ISavedViewDto[]>([]);
  const [fetchViewState, fetchViewPromise] = usePromise(fetchView);
  const [input, setInnput] = React.useState<string>('');
  const selectedView = React.useMemo(() => {
    const search = new URLSearchParamsCI(location.search);
    const savedViewId = tryParseInt(search.get(SAVEDVIEW_SEARCH_KEY));
    const selectedView = myViews.find(savedView => {
      return savedView.Id === savedViewId;
    });
    return selectedView;
  }, [location.search, myViews]);
  const displayedOptions = React.useMemo(
    () =>
      myViews
        .filter(option => containsText(option.Name, input))
        .sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0)),
    [input, myViews],
  );
  const getViewDislpayName = (renderedView: ISavedViewDto): string => {
    let groupId = renderedView.Assets[0].ServiceGroupId;
    if (renderedView.Assets.some(item => item.ServiceGroupId !== groupId)) {
      return renderedView.Name;
    } else {
      return `${renderedView.Name} (${renderedView.Assets[0].ServiceGroupName})`;
    }
  };
  const selectedChangeLocation = React.useCallback(
    (view: ISavedViewDto | undefined) => {
      // store selected values for other uses
      dispatch(savedViewActions.selectSavedView_Success(view));

      //const originalSearch = new URLSearchParamsCI(location.search);
      const currentSearch = new URLSearchParams(location.search);
      if (view === undefined) {
        currentSearch.delete(SAVEDVIEW_SEARCH_KEY);
      } else {
        currentSearch.set(SAVEDVIEW_SEARCH_KEY, String(view?.Id));
      }
      if (!!view && view.Assets.length > 0) {
        currentSearch.set(
          serializedKey,
          view.Assets.map(f => {
            if (listViewbyAsset) {
              return f.Id;
            } else {
              return f.ServiceId !== null && f.ServiceId !== undefined
                ? f.ServiceId
                : undefined;
            }
          })
            .filter(f => f !== undefined)
            .join('|'),
        );
        //addFilter(serviceItem);
        if (hasInVisibleFilters) {
          onApply({
            ...serviceItem,
            ...{
              value: view.Assets.map(asset => {
                return {
                  Id: listViewbyAsset ? asset.Id : asset.ServiceId || 0,
                  Name: asset.Name,
                  Color: asset.Color,
                } as Entity<number>;
              }),
            },
            ...{ open: false },
          });
        } else {
          addFilter({
            ...serviceItem,
            ...{
              value: view.Assets.map(asset => {
                return {
                  Id: listViewbyAsset ? asset.Id : asset.ServiceId || 0,
                  Name: asset.Name,
                  Color: asset.Color,
                } as Entity<number>;
              }),
            },
            ...{ open: false },
          });
        }
        dispatch(
          routerActions.replace({
            pathname: location.pathname,
            search: currentSearch.toString(),
          }),
        );
      } else {
        currentSearch.set(serializedKey, '');
        if (hasInVisibleFilters) {
          onApply({
            ...serviceItem,
            ...{
              value: undefined,
            },
            ...{ open: false },
          });
        }
        //onRemove(serviceItem);
        dispatch(
          routerActions.replace({
            pathname: location.pathname,
            search: currentSearch.toString(),
          }),
        );
      }
    },
    [
      addFilter,
      dispatch,
      hasInVisibleFilters,
      listViewbyAsset,
      location.pathname,
      location.search,
      onApply,
      serializedKey,
      serviceItem,
    ],
  );
  React.useEffect(() => {
    if (
      !fetchViewState.status ||
      (fetchViewState.status !== 'pending' &&
        fetchViewState.status !== 'resolved')
    ) {
      setMyViews(fetchViewPromise(user, selectedServiceGroups, isAdmin));
    }

    return () => {};
  }, [
    fetchViewPromise,
    fetchViewState,
    isAdmin,
    selectedServiceGroups,
    setMyViews,
    user,
  ]);
  const selectedViewChange = React.useCallback(
    e => {
      let selected = e.target.value;
      if (selected === 'search') {
        //e.preventDefault();
        //e.stopPropagation();
      } else {
        if (selected !== 'action') {
          let view = myViews.filter(f => f.Id === selected)[0];
          dispatch(savedActions.actions.selectMyList(view));
          //setSelectedView(() => view);
          selectedChangeLocation(view);
        }
      }
    },
    [myViews, dispatch, savedActions.actions, selectedChangeLocation],
  );
  const removeSelected = React.useCallback(() => {
    dispatch(savedActions.actions.selectMyList(undefined));
    //setSelectedView(undefined);
    selectedChangeLocation(undefined);
  }, [dispatch, savedActions.actions, selectedChangeLocation]);
  const openDetailsList = React.useCallback(() => {
    let params = {
      id: undefined,
      useSidePanel: true,
    } as SavedViewDetailsProps;
    dispatch(
      actions.openSidePanel({
        type: RenderPageType.SavedViewDetails,
        props: params,
      }),
    );
  }, [actions, dispatch]);
  return (
    <FilterBox id={`filter-box-saved-lists`} direction={'vertical'}>
      <ButtonLabel
        // ariaId={`saved-list-label`}
        style={
          isMobile
            ? {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '10ch',
              }
            : undefined
        }
        //color={open ? 'primary' : 'initial'}
      >
        {t(translations.InstrumentList)}
      </ButtonLabel>

      <Box style={{ maxWidth: 400, minWidth: 300 }}>
        {fetchViewState.status === 'resolved' ? (
          <Select
            placeholder={t(translations.MyInstrumentList) as string}
            value={selectedView?.Id || ''}
            onChange={selectedViewChange}
            id="saved-list"
            fullWidth={true}
            //SelectProps={{ MenuProps: { autoFocus: false } }}
            renderValue={selected => {
              return (
                <Body size="small">
                  {displayedOptions.filter(
                    f => f.Id === (selected as number),
                  )[0].Name || ''}
                </Body>
              );
            }}
          >
            <MenuItem key={'myListsTitle'} disabled value="">
              <Uppercase>{t(translations.PleaseSelectView)}</Uppercase>
            </MenuItem>
            <ListItem key="lists-search" value="search">
              <TextControlField
                placeholder={t(translations.SearchFilter)}
                startIcon={
                  <Icon icon="search" color="filters" colorExtend="light" />
                }
                value={input || ''}
                size="small"
                aria-label={t(translations.SearchFilter)}
                inputProps={{ 'aria-label': t(translations.SearchFilter) }}
                onChange={e => setInnput(e.target.value)}
                autoFocus
                fullWidth
              />
            </ListItem>
            <MenuItem key={'myViewsTitle'} disabled>
              <Uppercase>{t(translations.MySavedLists)}</Uppercase>
            </MenuItem>
            {displayedOptions
              .filter(f => f.UserName === user?.Id)
              .map(a => (
                <MenuItem key={a.Id} value={a.Id}>
                  <ListItemText>
                    <Body size="small">{getViewDislpayName(a)}</Body>
                  </ListItemText>
                </MenuItem>
              ))}
            {displayedOptions.filter(
              f => f.Shared === true && f.UserName !== user?.Id,
            ).length > 0 && (
              <MenuItem key={'sharedViewsTitle'} disabled>
                <Uppercase>{t(translations.SharedLists)}</Uppercase>
              </MenuItem>
            )}

            {displayedOptions.filter(
              f => f.Shared === true && f.UserName !== user?.Id,
            ).length > 0 &&
              displayedOptions
                .filter(f => f.Shared === true && f.UserName !== user?.Id)
                .map(a => (
                  <MenuItem key={a.Id} value={a.Id}>
                    <ListItemText>
                      <Body size="small">{getViewDislpayName(a)}</Body>
                    </ListItemText>
                  </MenuItem>
                ))}
            {displayedOptions.filter(f => f.UserName === user?.Id).length >
            0 ? (
              <ListItem key="manage-lists" value="action">
                <Button
                  variant="white"
                  size="small"
                  fullWidth={true}
                  aria-label="manage views"
                  startIcon={<Icon icon="cog" />}
                  to={'/myLists'}
                >
                  {t(translations.ManageLists)}
                </Button>
              </ListItem>
            ) : (
              <ListItem key="manage-lists" value="action">
                <Button
                  variant="white"
                  size="small"
                  fullWidth={true}
                  aria-label="create view"
                  startIcon={<Icon icon="plus" />}
                  onClick={() => openDetailsList()}
                >
                  {t(translations.AddSavedView)}
                </Button>
              </ListItem>
            )}
          </Select>
        ) : (
          <Skeleton width="300" />
        )}
      </Box>

      <Button
        color="default"
        variant="ghost"
        title="Clear selection"
        size="small"
        onClick={() => {
          removeSelected();
        }}
        aria-label="Remove"
      >
        <Icon icon={['fas', 'xmark']} />
      </Button>
    </FilterBox>
  );
}

export const SavedListsDropDown = React.memo(
  SavedLists,
  (prevProps, nextProps) => {
    const x = props =>
      JSON.stringify({
        value: props.serviceItem?.value,
      });
    var result = x(prevProps) === x(nextProps);

    return result;
  },
) as typeof SavedLists;
