import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { OnlineServiceMultiPicker } from 'app/components/pickers/MultiSelectPickers/OnlineServicesPicker';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const ServiceMultiFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number>[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <OnlineServiceMultiPicker
      onChange={handleOnChange}
      value={props.value}
      isOpen={false}
      placeholder={t(translations.AllValues)}
      additionalItem={props.additionalItem as IServiceFilterDto}
      ariaLabel={props.label}
      size="small"
      id={props.id}
    />
  );
};
export const ServiceAdminFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number>[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <OnlineServiceMultiPicker
      onChange={handleOnChange}
      value={props.value}
      isOpen={false}
      placeholder={t(translations.AllValues)}
      additionalItem={props.additionalItem as IServiceFilterDto}
      ariaLabel={props.label}
      urlType="admin"
      id={props.id}
    />
  );
};
export const ServiceReservationFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number>[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <OnlineServiceMultiPicker
      onChange={handleOnChange}
      value={props.value}
      isOpen={false}
      placeholder={t(translations.AllValues)}
      additionalItem={props.additionalItem as IServiceFilterDto}
      ariaLabel={props.label}
      urlType="reserved"
      id={props.id}
    />
  );
};
