import {
  Button as MatButton,
  ButtonProps as MatButtonProps,
  CircularProgress,
} from '@material-ui/core/index';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { capitalize } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { IPathAndQuery, toRootedURL } from 'utils/url-utils';
import clsx from 'clsx';
export type ButtonsVariant =
  | 'main'
  | 'gray'
  | 'accent'
  | 'white'
  | 'ghost'
  | 'ghostColor'
  | 'danger'
  | 'text'
  | 'textGray';
export interface ButtonProps extends Omit<MatButtonProps, 'variant' | 'href'> {
  processing?: boolean;
  /**
   * Variants overview:
   * main - Regular in figma — main color button, used for CTA (call-to-action) buttons
   * white - Secondary White in figma — White button with borders. Used as a button for secondary actions across all application.
   * ghost - Ghost Gray in figma — button that in the Resting state looks like a simple text , but it contains internal paddings and color background on hover and focus states
   * ghostColor - Ghost Color in figma — button that in the Resting state looks like a link/text button , but it contains internal paddings and color background on hover and focus states
   * accent - Accent in figma — Alternative button for the CTA actions, used on the screens that already contain several panels and too many primary buttons. However, this style is now barely used and is candidate to deprecation.
   * gray - Secondary Gray in figma — Gray button style that before used in filters.  This style is now barely used and is candidate to deprecation.
   * danger - Primary Danger in figma — Red filled primary button that is used in the confirmation popups and screens only
   * warning - Primary Alert in figma — Alert filled button that is used to warn users on the section when we need to pay attention about some behavior in the component so user should click on it to fix some issue.
   *           Currntly is used to warn about conflict in the Configurable columns, so user should resolve it.
   * text - Text Button in figma - Have similar behavior to Anchor Link.
   * textGray - Text Button in figma - The same as text varian only styled as grey text link.
   */
  variant?: ButtonsVariant;
  href?: string | IPathAndQuery;
  component?: React.ElementType;
  to?: string;
  target?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      disabled,
      processing,
      size = 'medium',
      variant = 'main',
      href,
      component,
      title,
      to,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const history = useHistory();
    const absolutePath =
      href === undefined
        ? undefined
        : typeof href === 'string'
        ? toRootedURL(href)
        : toRootedURL(
            (href as IPathAndQuery).path,
            (href as IPathAndQuery).search,
          );
    const handleClick = (e: any) => {
      if (props.onClick) {
        props.onClick(e);
      }
      if (to) {
        history.push(to);
      }
    };
    return title ? (
      <>
        {/*<WithTitle title={title}>*/}
        <Tooltip title={title}>
          <span>
            <MatButton
              disableRipple
              disableFocusRipple
              disableTouchRipple
              variant={
                variant === 'text' || variant === 'textGray'
                  ? 'text'
                  : 'contained'
              }
              size={size}
              disabled={(processing || disabled) ?? false}
              ref={ref}
              component={component ?? !!absolutePath ? 'a' : 'button'}
              onClick={event => {
                handleClick(event);
              }}
              className={clsx(
                props.className,
                `variant${capitalize(variant)}${
                  props.startIcon !== undefined ? ' hasStartIcon' : ''
                }${props.endIcon !== undefined ? ' hasEndIcon' : ''}`,
              )}
              href={absolutePath}
              {...props}
            >
              <span className={'buttonLabel'}>{children}</span>

              {processing && <CircularProgress size={16} />}
            </MatButton>
          </span>
        </Tooltip>
        {/*</WithTitle>*/}
      </>
    ) : (
      <MatButton
        disableRipple
        disableFocusRipple
        disableTouchRipple
        variant={
          variant === 'text' || variant === 'textGray' ? 'text' : 'contained'
        }
        size={size}
        disabled={(processing || disabled) ?? false}
        ref={ref}
        component={component ?? !!absolutePath ? 'a' : 'button'}
        onClick={event => {
          handleClick(event);
        }}
        className={clsx(
          props.className,
          `variant${capitalize(variant)}${
            props.startIcon !== undefined ? ' hasStartIcon' : ''
          }${props.endIcon !== undefined ? ' hasEndIcon' : ''}`,
        )}
        href={absolutePath}
        {...props}
      >
        <span className={'buttonLabel'}>{children}</span>

        {processing && <CircularProgress size={20} />}
      </MatButton>
    );
  },
);
