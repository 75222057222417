import { PeriodTypesEntity } from 'app/components/DatePeriods';
import { assertExhaustive } from 'utils/assertExhaustive';
import { dateUtils } from 'utils/date-utils';
import { IDatesRange } from './IDatesRange';

function createPeriod(
  now: Date,
  startFn: (d: Date) => Date,
  endFn: (d: Date) => Date,
  addFn: (d: Date, n: number) => Date,
  offset: number,
): DatesRange {
  const d = addFn(now, offset);
  return new DatesRange({ start: startFn(d), end: endFn(d) });
}
export class DatesRange implements IDatesRange {
  public start: Date | null = null;
  public end: Date | null = null;
  constructor(t?: IDatesRange) {
    this.start = t?.start ?? null;
    this.end = t?.end ?? null;
  }
  public static fromPreset(
    type: PeriodTypesEntity,
    st: Date | null,
    et: Date | null,
  ): { start: Date | null; end: Date | null } {
    const now = new Date();
    switch (type.Id) {
      case 'CustomDate':
        return { start: st, end: et };
      case 'ThisMonth':
        return createPeriod(
          now,
          dateUtils.startOfMonth,
          dateUtils.endOfMonth,
          dateUtils.addMonths,
          0,
        );
      case 'LastMonth':
        return createPeriod(
          now,
          dateUtils.startOfMonth,
          dateUtils.endOfMonth,
          dateUtils.addMonths,
          -1,
        );
      case 'NextMonth':
        return createPeriod(
          now,
          dateUtils.startOfMonth,
          dateUtils.endOfMonth,
          dateUtils.addMonths,
          1,
        );
      case 'ThisQuarter':
        return createPeriod(
          now,
          dateUtils.startOfQuarter,
          dateUtils.endOfQuarter,
          dateUtils.addQuarters,
          0,
        );
      case 'LastQuarter':
        return createPeriod(
          now,
          dateUtils.startOfQuarter,
          dateUtils.endOfQuarter,
          dateUtils.addQuarters,
          -1,
        );
      case 'NextQuarter':
        return createPeriod(
          now,
          dateUtils.startOfQuarter,
          dateUtils.endOfQuarter,
          dateUtils.addQuarters,
          1,
        );
      case 'ThisWeek':
        return createPeriod(
          now,
          dateUtils.startOfWeek,
          dateUtils.endOfWeek,
          dateUtils.addWeeks,
          0,
        );
      case 'LastWeek':
        return createPeriod(
          now,
          dateUtils.startOfWeek,
          dateUtils.endOfWeek,
          dateUtils.addWeeks,
          -1,
        );
      case 'NextWeek':
        return createPeriod(
          now,
          dateUtils.startOfWeek,
          dateUtils.endOfWeek,
          dateUtils.addWeeks,
          1,
        );
      case 'ThisYear':
        return createPeriod(
          now,
          dateUtils.startOfYear,
          dateUtils.endOfYear,
          dateUtils.addYears,
          0,
        );
      case 'LastYear':
        return createPeriod(
          now,
          dateUtils.startOfYear,
          dateUtils.endOfYear,
          dateUtils.addYears,
          -1,
        );
      case 'NextYear':
        return createPeriod(
          now,
          dateUtils.startOfYear,
          dateUtils.endOfYear,
          dateUtils.addYears,
          1,
        );
      case 'Today':
        return createPeriod(
          now,
          dateUtils.startOfDay,
          dateUtils.endOfDay,
          dateUtils.addDays,
          0,
        );
      case 'TodayTomorrow':
        return createPeriod(
          now,
          dateUtils.startOfDay,
          dateUtils.endOfTomorrow,
          dateUtils.addDays,
          0,
        );
      case 'Tomorrow':
        return createPeriod(
          now,
          dateUtils.startOfDay,
          dateUtils.endOfDay,
          dateUtils.addDays,
          1,
        );
      case 'Yesterday':
        return createPeriod(
          now,
          dateUtils.startOfDay,
          dateUtils.endOfDay,
          dateUtils.addDays,
          -1,
        );
      default:
        assertExhaustive(type.Id);
    }
  }
}
