import { BaseTextFieldProps } from '@material-ui/core';
import { BaseDatePickerProps, BaseTimePickerProps } from '@material-ui/pickers';
import { DateValidationProps } from '@material-ui/pickers/_helpers/text-field-helper';
import { BaseKeyboardPickerProps } from '@material-ui/pickers/_shared/hooks/useKeyboardPickerState';
import { dateUtils } from 'utils/date-utils';
import { KeyboardRifmInput } from './KeyboardRifmInput';
import * as React from 'react';
import { IUtils } from '@date-io/core/IUtils';
import { validateError } from '../DatePicker/utils/pickerDateValidate';
import { KeyboardDateInputProps } from '@material-ui/pickers/_shared/KeyboardDateInput';
import { DatePopupVariants, getDisplayDate } from './utils/types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export interface KeyboardInputProps
  extends DateValidationProps,
    Pick<BaseTimePickerProps, 'minutesStep' | 'ampm'>,
    Pick<
      BaseDatePickerProps,
      | 'disableFuture'
      | 'disablePast'
      | 'minDate'
      | 'maxDate'
      | 'strictCompareDates'
    >,
    Omit<
      KeyboardDateInputProps,
      | 'maskChar'
      | 'mask'
      | 'refuse'
      | 'KeyboardButtonProps'
      | 'rifmFormatter'
      | 'onChange'
      | 'format'
    >,
    Pick<
      BaseKeyboardPickerProps,
      | 'onChange'
      | 'format'
      | 'value'
      | 'invalidLabel'
      | 'emptyLabel'
      | 'labelFunc'
      | 'onError'
      | 'onChange'
    >,
    Pick<
      BaseTextFieldProps,
      | 'autoFocus'
      | 'fullWidth'
      | 'helperText'
      | 'placeholder'
      | 'required'
      | 'disabled'
      | 'name'
      | 'id'
      | 'label'
      | 'margin'
    > {
  info?: string;
  ariaLabel?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  togglePicker: () => void;
  variant: Omit<DatePopupVariants, 'separate'>;
  isPickerOpen?: boolean;
  onChange: (
    date: MaterialUiPickersDate | null,
    value?: string | null,
    withCompletion?: boolean,
  ) => void;
  maskIfEmpty?: boolean;
  clearable?: boolean;
}
export function parseInputString(
  value: string,
  utils: IUtils<any>,
  format: string,
) {
  try {
    return utils.parse(value, format);
  } catch {
    return null;
  }
}
export function KeyboardInput({
  format = dateUtils.DateIOFormats.keyboardDate,
  inputValue,
  onChange,
  value,
  onError,
  maxDate,
  minDate,
  disablePast,
  disableFuture,
  maxDateMessage,
  minDateMessage,
  invalidDateMessage,
  strictCompareDates,
  variant,
  size,
  maskIfEmpty,
  clearable,
  ...props
}: KeyboardInputProps) {
  const utils = dateUtils.dateFnsUtils;
  const displayDate = getDisplayDate(
    value,
    format,
    utils,
    value === null || value === '',
    {
      invalidLabel: props.invalidLabel,
      emptyLabel: props.emptyLabel,
      labelFunc: props.labelFunc,
    },
  );
  const [innerInputValue, setInnerInputValue] = React.useState(displayDate);
  // const dateValue = inputValue
  //   ? parseInputString(inputValue, utils, format)
  //   : value;
  const changedValueRef = React.useRef(innerInputValue);
  React.useEffect(() => {
    if (value === null || utils.isValid(value)) {
      setInnerInputValue(displayDate);
    }
  }, [displayDate, setInnerInputValue, utils, value]);
  React.useEffect(() => {
    let val = innerInputValue;
    if (!!val && val !== '') {
      let date = val === null ? null : utils.parse(val, format);

      if (!(date?.toString() === 'Invalid Date')) {
        if (value !== null || utils.isValid(value)) {
          let dateVal = dateUtils.getParsableDate(value);
          if (dateVal !== null && date !== null) {
            if (variant === 'time') {
              date = dateUtils.dateFnsUtils.mergeDateAndTime(dateVal, date);
            } else {
              date = dateUtils.dateFnsUtils.mergeDateAndTime(date, dateVal);
            }
          }
        }
      }

      let error = validateError(
        date,
        {
          maxDate: maxDate,
          minDate: minDate,
          disablePast: disablePast,
          disableFuture: disableFuture,
          maxDateMessage: maxDateMessage,
          minDateMessage: minDateMessage,
          invalidDateMessage: invalidDateMessage,
          strictCompareDates: strictCompareDates,
        },
        utils,
      );
      if (!!error) {
        setValidationError(error);
        if (!!onError) {
          onError(error, null);
        }
      } else {
        setValidationError('');
        if (!!onError) {
          onError('', null);
        }
      }
    } else {
      setValidationError('');
      if (!!onError) {
        onError('', null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format, innerInputValue, maxDate, minDate]);
  const [innerValidationError, setValidationError] = React.useState<
    React.ReactNode | undefined
  >(undefined);
  const errorMessage = React.useMemo(() => {
    return innerValidationError;
  }, [innerValidationError]);
  // const handleKeyboardChange = React.useCallback(
  //   (date: MaterialUiPickersDate) => {
  //     onChange(date, date === null ? null : utils.format(date, format));
  //   },
  //   [format, onChange, utils],
  // );

  // const inputProps = React.useMemo(
  //   () => ({
  //     ...innerInputProps, // reuse validation and open/close logic
  //     format: format,
  //     inputValue: inputValue || innerInputValue,
  //     onChange: (value: string | null) => {
  //       setInnerInputValue(value || '');
  //       const date =
  //         value === null ? null : utils.parse(value, wrapperProps.format);

  //       onChange(date, value);
  //     },
  //   }),
  //   [
  //     innerInputProps,
  //     innerInputValue,
  //     inputValue,
  //     onChange,
  //     utils,
  //     wrapperProps.format,
  //   ],
  // );
  return (
    <KeyboardRifmInput
      inputValue={innerInputValue || inputValue}
      onChange={(textValue: string | null, withCompletion?: boolean) => {
        setInnerInputValue(textValue ?? '');
        changedValueRef.current = textValue ?? '';

        let date = textValue === null ? null : utils.parse(textValue, format);

        if (!(date?.toString() === 'Invalid Date')) {
          if (value !== null || utils.isValid(value)) {
            let dateVal = dateUtils.getParsableDate(value);
            if (dateVal !== null && date !== null) {
              if (variant === 'time') {
                date = dateUtils.dateFnsUtils.mergeDateAndTime(dateVal, date);
              } else {
                date = dateUtils.dateFnsUtils.mergeDateAndTime(date, dateVal);
              }
            }
          }
        }
        let error = validateError(
          date,
          {
            maxDate: maxDate,
            minDate: minDate,
            disablePast: disablePast,
            disableFuture: disableFuture,
            maxDateMessage: maxDateMessage,
            minDateMessage: minDateMessage,
            invalidDateMessage: invalidDateMessage,
            strictCompareDates: strictCompareDates,
          },
          utils,
        );
        if (!!error) {
          setValidationError(error);
          if (!!onError) {
            onError(error, date);
          }
        } else {
          setValidationError('');
          if (!!onError) {
            onError('', date);
          }
          // if (!(date?.toString() === 'Invalid Date')) {
          //   setTimeout(() => {
          //     if (textValue === changedValueRef.current) {
          //       onChange(date, textValue);
          //     }
          //   }, 1500);
          // }
          if (!(date?.toString() === 'Invalid Date')) {
            onChange(date, textValue, withCompletion);
          }
        }
      }}
      isPickerOpen={props.isPickerOpen}
      info={props.info}
      ariaLabel={props.ariaLabel}
      format={format}
      validationError={errorMessage || props.validationError}
      helperText={errorMessage || props.helperText}
      error={Boolean(errorMessage)}
      InputProps={props.InputProps}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      inputVariant={props.inputVariant}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      ampm={props.ampm}
      variant={variant}
      size={size}
      maskIfEmpty={maskIfEmpty}
      clearable={clearable}
      {...props}
    />
  );
}
