import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { InventoryBatchState } from '../Details/slice/types';
import React from 'react';
import * as Yup from 'yup';
import { translations } from 'locales/translations';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';

import {
  selectInventoryBatchHasErrors,
  selectInventoryBatchValueHandler,
} from '../Details/slice/selectors';
import { useOfflineServiceStateSlice } from '../Details/slice';
import { isEmpty } from 'lodash';
import {
  FieldHandler,
  FormListener,
  SubmittingHandler,
} from 'app/components/Forms/FormRender/FormRenderer';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { InventoryBatchRelatedFilter } from 'app/components/pickers/AutocompletePickers/OfflineServiceTypePicker';
import { ServiceTypeSchema } from 'app/components/Forms/Schemas';
import { FormOfflineServiceTypePicker } from 'app/components/Forms/FormOfflineServiceTypePicker';
import { FormNumberField } from 'app/components/Forms/FormNumberField';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { FormBookitDatePicker } from 'app/components/Forms/FormBookitDatePicker';

export interface InventoryBatchFormProps {
  onSubmit: (item: InventoryBatchState) => void;
  initialValues: InventoryBatchState;
  processing?: boolean;
  bindSubmitForm: any;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  innerFormRef: React.RefObject<FormikProps<InventoryBatchState>>;
  globalSettings: GlobalSettingsType;
  isEdit: boolean;
}

export const InventoryBatchForm = React.memo(function InventoryBatchForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isAdmin,
  user,
  globalSettings,
  innerFormRef,
  isEdit,
}: InventoryBatchFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();

  const fieldHandler = useSelector(selectInventoryBatchValueHandler);
  const hasError = useSelector(selectInventoryBatchHasErrors);
  const { newDate } = useSystemDate();
  // const readonly = !isAdmin;
  const [submitting, setSubmitting] = React.useState<boolean | undefined>(
    undefined,
  );
  const resetSubmitting = () => {
    setSubmitting(true);
  };
  const handleSubmit = (values: InventoryBatchState) => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(values);
    }
  };
  const resetFieldHandler = () => {
    dispatch(actions.resetInventoryBatchValueHandler());
  };

  /// Local State ///
  const [serviceChanged, setServiceChanged] = React.useState(false);
  const [batchChanged, setBatchChanged] = React.useState(false);

  const [serviceTypeSelected, setServiceTypeSelected] = useAsyncExtendedState<
    IOfflineServiceFilterDto | undefined
  >(initialValues.ServiceType ?? undefined);
  const minStep = React.useMemo(() => {
    return !!serviceTypeSelected
      ? serviceTypeSelected.NotLessThanZero === true
        ? 0
        : -9999
      : -9999;
  }, [serviceTypeSelected]);
  const maxStep = 9999;
  const inputProps = {
    step: 1,
    min: minStep,
    max: maxStep,
    'aria-label': 'Inventory',
  };

  // const IsAdmin = React.useCallback(
  //   (service: IOfflineServiceFilterDto) => {
  //     return user?.IsAllGroupOrLabTechAdmin(
  //       [service.ServiceGroupId ?? 0],
  //       [
  //         {
  //           Id: service.Id,
  //           Name: service.Name,
  //           ServiceTypeId: ServiceType.Offline,
  //         } as IServiceTypeFilterDto,
  //       ],
  //     );
  //   },
  //   [user],
  // );

  const handleFormChange = React.useCallback(
    (values: InventoryBatchState) => {
      if (serviceChanged) {
        setServiceTypeSelected(values.ServiceType ?? undefined);
        setServiceChanged(false);
      }
      if (batchChanged) {
        setBatchChanged(false);
      }
    },
    [batchChanged, serviceChanged, setServiceTypeSelected],
  );

  // useEffects
  React.useEffect(() => {
    if (innerFormRef.current) {
      innerFormRef.current.validateForm();
    }
  }, [innerFormRef]);

  const computedSchema = React.useMemo(() => {
    const inventoryBatchSchema: Yup.SchemaOf<InventoryBatchState> = Yup.object({
      Id: Yup.number().notRequired().default(-1),
      Name: Yup.string()
        .label(t(translations.BatchName) as string)
        .required(t(translations.err_BatchName_required) as string),
      ServiceType: ServiceTypeSchema.nullable().required(
        t(translations.err_OfflineServiceTypeRequired),
      ),
      ExpirationDate: Yup.date()
        .label(t(translations.BatchExpirationDate) as string)
        .nullable()
        .default(null),
      PurchaseDate: Yup.date()
        .default(newDate())
        .label(t(translations.BatchPurchaseDate) as string)
        .required(t(translations.err_BatchPurchaseDate_required) as string),
      Inventory: Yup.number()
        .label(t(translations.BatchInventory) as string)
        .required(t(translations.err_BatchInventory_required) as string)
        .max(maxStep)
        .min(minStep)
        .default(0)
        .test(
          'empty',
          t(translations.err_BatchInventory_required) as string,
          (value, context) => {
            let currVal = (context as any).from[0].value.Inventory;
            if (currVal === undefined || currVal === null || isNaN(currVal)) {
              return false;
            }
            return true;
          },
        ),
      Lot: Yup.string()
        .nullable()
        .label(t(translations.BatchLot) as string),
      PurchasePricePerUnit: Yup.number()
        .label(t(translations.BatchPurchasePricePerUnit) as string)
        .nullable(true)
        .default(null),
    });

    if (!!serviceTypeSelected && serviceTypeSelected.TrackInventory) {
      if (serviceTypeSelected?.NotLessThanZero) {
        inventoryBatchSchema.fields.Inventory.withMutation(schema =>
          schema
            .label(t(translations.BatchInventory) as string)
            .required(t(translations.err_BatchInventory_required))
            .max(maxStep)
            .min(minStep)
            .default(0)
            .test(
              'empty',
              t(translations.err_BatchInventory_required) as string,
              (value, context) => {
                let currVal = context.from[0].value.Inventory;
                if (
                  currVal === undefined ||
                  currVal === null ||
                  isNaN(currVal)
                ) {
                  return false;
                }
                return true;
              },
            )
            .test(
              'noLessThanZero',
              t(translations.NotAllowNegativeInventory_err) as string,
              (value, context) => {
                let currVal = context.from[0].value.Inventory;
                if (
                  currVal === undefined ||
                  currVal === null ||
                  isNaN(currVal) ||
                  currVal >= 0
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            ),
        );
      }
    }
    return inventoryBatchSchema;
  }, [minStep, newDate, serviceTypeSelected, t]);
  return (
    <>
      <Formik
        validationSchema={computedSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        innerRef={innerFormRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          formikHelpers.validateForm(values).then(responseErrors => {
            if (!isEmpty(responseErrors)) {
              formikHelpers.setSubmitting(false);
              console.debug('errors', responseErrors);
            } else {
              console.debug('submitting', values);
              formikHelpers.setSubmitting(true);
              handleSubmit(values);
            }
          });
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            console.log('Consumables errors', formik.errors);
          }
          return (
            <React.Fragment>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['ServiceType']}
                />
                <FieldHandler
                  {...fieldHandler}
                  resetFieldState={resetFieldHandler}
                />
                <SubmittingHandler
                  value={hasError === true && submitting === undefined}
                  resetSubmitting={resetSubmitting}
                />
                <FormLeftSection
                // warningNodes={warningMessages}
                // infoNodes={infoMessages}
                // errors={errorMessages}
                // successNodes={successMessages}
                >
                  <FormFieldsSection
                    titleSection={t(translations.BatchInfo) as string}
                  >
                    <FormRow fullRow={true}>
                      <FormOfflineServiceTypePicker
                        fullWidth
                        id="ServiceTypeID"
                        name="ServiceType"
                        predicates={InventoryBatchRelatedFilter()}
                        urlType="base"
                        label={t(translations.Consumable)}
                        placeholder={t(translations.PleaseSelectServices)}
                        disabled={formik.isSubmitting || isEdit}
                        onChange={val => {
                          setServiceChanged(true);
                        }}
                      />
                    </FormRow>
                    <FormRow fullRow={true}>
                      <FormTextField
                        name="Name"
                        label={t(translations.BatchName)}
                        placeholder={t(translations.BatchName)}
                        disabled={formik.isSubmitting}
                        autoComplete="on"
                        id="BatchNameId"
                        fullWidth
                      />
                    </FormRow>
                    <FormRow fullRow>
                      <FormBookitDatePicker
                        fullWidth
                        id="PurchaseDateID"
                        name="PurchaseDate"
                        clearable={false}
                        label={t(translations.BatchPurchaseDate) as string}
                        disabled={formik.isSubmitting}
                      />
                    </FormRow>
                    <FormRow fullRow>
                      <FormBookitDatePicker
                        fullWidth
                        id="ExpirationDateID"
                        name="ExpirationDate"
                        clearable={true}
                        label={t(translations.BatchExpirationDate) as string}
                        disabled={formik.isSubmitting}
                      />
                    </FormRow>
                    <FormRow fullRow>
                      <FormNumberField
                        fullWidth
                        id="InventoryID"
                        name="Inventory"
                        inputMode="decimal"
                        info={
                          t(
                            translations.Inventory_ServiceType_Description,
                          ) as string
                        }
                        placeholder={t(translations.BatchInventory)}
                        label={t(translations.BatchInventory)}
                        disabled={true}
                        onChange={event => {
                          const value = +(+event.target.value).toFixed(0);
                          console.log('qyt', value);
                        }}
                        inputProps={inputProps}
                      />
                    </FormRow>
                    <FormRow fullRow>
                      <FormNumberField
                        fullWidth
                        id="InventoryID"
                        name="PurchasePricePerUnit"
                        inputMode="decimal"
                        placeholder={t(translations.BatchPurchasePricePerUnit)}
                        label={t(translations.BatchPurchasePricePerUnit)}
                        disabled={formik.isSubmitting}
                        onChange={event => {
                          const value = +(+event.target.value).toFixed(2);
                          console.log('qyt', value);
                        }}
                        inputProps={{
                          step: 1,
                          min: 0,
                          'aria-label': 'PurchasePricePerUnit',
                        }}
                      />
                    </FormRow>
                    <FormRow fullRow={true}>
                      <FormRichTextField
                        name="Lot"
                        placeholder={t(translations.BatchLot)}
                        disabled={formik.isSubmitting}
                        fullWidth={true}
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormLeftSection>
              </StyledForm>
            </React.Fragment>
          );
        }}
      </Formik>
    </>
  );
});
