import { IRow } from 'app/components/BasicTable/IRow';
import { useConfigurableColumns } from 'app/components/BasicTable/useConfigurableColumns';
import React from 'react';
import { Column } from 'react-table';

const GetTargetColumns = <TRow extends IRow>(
  configurableColumns: Column<TRow>[] | undefined,
  target: Column<TRow>[] | undefined,
): Column<TRow>[] | undefined => {
  if (configurableColumns !== undefined && target !== undefined) {
    const res = configurableColumns.map(c => {
      const r = target.find(t => t.accessor === c.accessor);
      return r !== undefined ? r : c;
    });
    return res;
  } else return undefined;
};
const ExcludeColumns = <TRow extends IRow>(
  configurableColumns: Array<keyof TRow> | undefined,
  columnsToRemove: string[] | undefined,
): Array<keyof TRow> | undefined => {
  if (configurableColumns !== undefined) {
    if (columnsToRemove !== undefined) {
      const res = configurableColumns.filter(
        c => columnsToRemove.find(r => r === c) === undefined,
      );
      return res;
    } else {
      return configurableColumns;
    }
  } else {
    return undefined;
  }
};
export const useConfigurableColumnsAdvanced = <TRow extends IRow>(
  screenId: number,
  columns: Array<Column<TRow>>,
  excludeFroExport: string[],
) => {
  const {
    configurableColumns,
    configurableColumnsExport: exportConfigurableColumns,
    handleConfigurableColumns,
    configCompleted,
  } = useConfigurableColumns<TRow>({
    screenId: screenId,
    columns: columns,
    // setGlobalMessage: setGlobalMessage,
  });
  const getColumnAccessors = React.useCallback(
    (columns: Column<TRow>[] | undefined) =>
      columns
        ?.filter(c => typeof c.accessor === 'string')
        ?.filter(c => c.accessor !== undefined)
        ?.map(c => c.accessor as keyof TRow),
    [],
  );

  const getDisplayColumns = React.useCallback(
    ({
      screenId,
      configurableColumns,
      configCompleted,
      columns,
    }): Column<TRow>[] | undefined => {
      if (screenId === undefined) {
        return columns;
      } else if (configCompleted) {
        return configurableColumns;
      } else {
        return undefined;
      }
    },
    [],
  );
  const columnAccessors = React.useMemo(() => {
    const result = getColumnAccessors(
      getDisplayColumns({
        screenId,
        configurableColumns,
        configCompleted,
        columns,
      }),
    );
    return result;
  }, [
    getColumnAccessors,
    getDisplayColumns,
    screenId,
    configurableColumns,
    configCompleted,
    columns,
  ]);
  const targetColumns = React.useMemo(() => {
    return GetTargetColumns(configurableColumns, columns);
  }, [columns, configurableColumns]);
  const exportColumns = React.useMemo(() => {
    return ExcludeColumns(exportConfigurableColumns, excludeFroExport);
  }, [excludeFroExport, exportConfigurableColumns]);
  return {
    configurableColumns: targetColumns,
    columnAccessors,
    exportConfigurableColumns: exportColumns,
    handleConfigurableColumns,
    configCompleted,
  };
};
