import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProps } from 'formik';
import {
  getFieldsVisibilities,
  SerializeValue,
} from 'app/components/CustomForm/CustomFormUtils';
import { useRequestSamplesSlice } from '../../slice';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box } from '@material-ui/core';
import { getLogger } from 'utils/logLevel';
import { IServiceRequestDetailsFormModel } from '../../slice/types';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { selectServiceRequestCustomFormFields } from '../../slice/selectors';
import { FormFieldsSection, FormRow } from 'app/components/Forms/FormsLayout';
import { CustomFormField } from 'app/components/CustomForm/CustomFormFields';
import { sortBy } from 'lodash';
import { ICustomFormDto } from 'api/odata/generated/entities/ICustomFormDto';

export interface ServiceRequestCustomFormProps
  extends FormikProps<IServiceRequestDetailsFormModel> {
  serviceRequestId?: number;
  customForm: ICustomFormDto;
  isAdmin: boolean;
  disabled: boolean;
  sectionStyles?: React.CSSProperties;
}
const log = getLogger('ServiceRequestCustomForm');
export const ServiceRequestCustomForm = React.memo(
  function ServiceRequestCustomForm({
    isAdmin,
    disabled,
    sectionStyles,
    ...props
  }: ServiceRequestCustomFormProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { actions } = useRequestSamplesSlice();
    const formFields = useSelector(selectServiceRequestCustomFormFields);

    const handleChange = React.useCallback(
      (value, formField: IFormFieldDto) => {
        const serviceRequestId = props.serviceRequestId;
        if (serviceRequestId === undefined) {
          log.info('handleSubmit - serviceRequestId is is empty');
          return;
        }

        dispatch(
          actions.saveFormValues({
            RequestId: serviceRequestId,
            FormFieldId: formField.Id,
            Value:
              formField.Type === 'File'
                ? value
                : SerializeValue(formField, value?.target?.value ?? value),
            DisplayValue: null,
            Type: formField.Type,
          }),
        );
      },
      [actions, dispatch, props.serviceRequestId],
    );

    const isVisibleField = (
      field: IFormFieldDto,
      isAdmin: boolean,
      values: Record<string, any> | undefined,
      visibleOnCompletion?: () => boolean,
    ): boolean => {
      let visibilities = getFieldsVisibilities(
        formFields,
        isAdmin,
        values,
        visibleOnCompletion,
      );
      if (visibilities) {
        return visibilities[field.Id];
      }
      return true;
    };
    const sortedActiveFormFields = React.useMemo(
      () =>
        sortBy(
          formFields.filter(f => f.Active === true),
          f => f.Index,
        ),
      [formFields],
    );

    return props.values.FormValues === undefined ? (
      <Box style={{ width: '100%' }}>
        <LinearProgress variant="query" color="primary" />
      </Box>
    ) : (
      <FormFieldsSection
        titleSection={props.customForm.Name}
        styleSection={sectionStyles}
      >
        {sortedActiveFormFields.map((formField, index) => {
          // paragraphs & form sections deserve to start from the new line in 2 column layout (full screen)
          const fullRow =
            formField.Type !== null &&
            ['Paragraph', 'FormSection'].includes(formField.Type);
          const name = `FormValues.${String(formField.Id)}`;
          return (
            <FormRow
              key={formField.Id}
              hide={
                isVisibleField(
                  formField,
                  isAdmin || false,
                  props.values.FormValues,
                  undefined,
                ) === false
              }
              fullRow={fullRow}
            >
              <CustomFormField
                name={name}
                id={name}
                key={index}
                formField={formField}
                autoFocus={index === 0}
                readonly={disabled}
                disabled={disabled || props.isSubmitting}
                onChange={value => handleChange(value, formField)}
              />
            </FormRow>
          );
        })}
      </FormFieldsSection>
    );
  },
);
