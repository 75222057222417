import { ToggleButtonGroup, ToggleButtonGroupProps } from '@material-ui/lab';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EntityType } from 'types/common';
import clsx from 'clsx';
import { FormHelperText } from '@material-ui/core';
import { BaseOptionsPicker } from 'app/components/BasicPickers/BaseOptionsPicker';
import { translations } from 'locales/translations';
import { IsEntityType } from 'app/components/BasicTable/BasicFilter/AppliedFilterComponent';
import { IconProp } from 'app/AllIcons';
import { ButtonsVariant } from '../Button';
import { BaseToggleButton } from '../BaseToggleButton';
import { capitalize } from 'lodash';

export interface ToggleEntityType extends EntityType {
  icon?: IconProp;
  title?: string;
  disabled?: boolean;
  startIcon?: IconProp;
  endIcon?: IconProp;
}
export interface BaseToggleButtonGroupProps<T extends ToggleEntityType>
  extends Omit<ToggleButtonGroupProps, 'onChange'> {
  variant?: ButtonsVariant;
  options: Array<T>;
  otherOptions?: Array<T>;
  value: T | null;
  error?: boolean;
  helperText?: string;
  label?: string;
  onChange: (value: T | null) => void;
  fullWidth?: boolean;
  compact?: boolean;
  separated?: boolean;
  name?: string;
  disabled?: boolean;
}

function BaseToggleButtonGroupInner<T extends ToggleEntityType>(
  props: BaseToggleButtonGroupProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const {
    variant = 'ghost',
    size = 'medium',
    options,
    otherOptions,
    value,
    orientation = 'horizontal',
    error,
    helperText,
    onChange,
    fullWidth,
    compact,
    separated = true,
    exclusive,
    name,
    label,
    disabled,
    ...other
  } = props;
  const { t } = useTranslation();
  const [toggleValue, setToggleValue] = React.useState(value);
  React.useEffect(() => {
    setToggleValue(value);
  }, [value]);
  const handleOtherChange = React.useCallback(
    (newValue: T | null) => {
      if (newValue?.Id !== toggleValue?.Id) {
        setToggleValue(newValue);
        onChange(newValue);
      }
    },
    [onChange, toggleValue?.Id],
  );

  const handleToggleValue = React.useCallback(
    (event, newViewType: T) => {
      if (IsEntityType(event)) {
        handleOtherChange(event as T);
      } else {
        if (newViewType.Id !== toggleValue?.Id) {
          setToggleValue(newViewType);
          onChange(newViewType);
        }
      }
    },
    [handleOtherChange, onChange, toggleValue?.Id],
  );

  // const isMobile = DetectIsMobile();
  // const settings = React.useMemo(
  //   () => ({
  //     size: size,
  //     variant: variant,
  //     orientation: orientation,
  //     separated: separated,
  //   }),
  //   [orientation, separated, size, variant],
  // );
  // const classes = toggleButtonGroupStyle(settings);
  return (
    <ToggleButtonGroup
      ref={ref}
      value={toggleValue}
      exclusive={exclusive || true}
      onChange={handleToggleValue}
      aria-label={label || 'group buttons'}
      className={clsx(`variant${capitalize(variant)} size${capitalize(size)}`, {
        errorBorder: error,
        fullWidth: fullWidth,
        separated: separated,
      })}
      // classes={{
      //   root: classes.root,
      //   groupedHorizontal: classes.groupedHorizontal,
      //   groupedVertical: classes.groupedVertical,
      // }}
      size={size}
      orientation={orientation}
      {...other}
    >
      {options.map((option, index) => (
        <BaseToggleButton
          size={size}
          key={`option_${index}_${option.Id}`}
          value={option}
          title={option.title}
          selected={option.Id === value?.Id}
          fullWidth={fullWidth}
          disabled={disabled || option.disabled}
          className={clsx(
            `variant${capitalize(variant)} size${capitalize(size)}`,
            {
              fullWidthButton: fullWidth,
              separated: separated,
              hasStartIcon: option.startIcon !== undefined,
              hasEndIcon: option.endIcon !== undefined,
              hasMainIcon: option.icon !== undefined,
              fullWidth: fullWidth,
              withTitle: option.title !== undefined,
            },
          )}
          mainIcon={option.icon}
          startIcon={option.startIcon}
          endIcon={option.endIcon}
          variant={variant}
        >
          {(compact !== true || option.icon === undefined) && (
            <span>{option.Name}</span>
          )}
        </BaseToggleButton>
      ))}
      {otherOptions !== undefined && otherOptions.length > 0 && (
        <BaseOptionsPicker
          disabled={disabled}
          variant="filled"
          getOptions={() => otherOptions || []}
          onChange={handleOtherChange}
          placeholder={t(translations.Other)}
          value={value}
          size={size === 'large' ? 'medium' : size}
        />
      )}
      {helperText !== undefined && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </ToggleButtonGroup>
  );
}
export const BaseToggleButtonGroup = React.forwardRef(
  BaseToggleButtonGroupInner,
);
