/**
 *
 * EnumPicker
 *
 */
import * as React from 'react';
import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import { Entity } from 'types/common';
import { useTranslation } from 'react-i18next';
import { GetOptionsFilterFunction } from 'utils/enumKeys';

export interface EnumPickerProps<TKey extends string>
  extends Omit<
    BaseOptionsPickerProps<Entity<TKey>>,
    'getOptions' | 'value' | 'onChange'
  > {
  EnumNamesArray: Array<TKey>;
  value: TKey | null;
  onChange: (value: TKey | null) => void;
  nameRender?: (value: TKey) => string;
}

export function EnumPicker<TKey extends string>({
  value,
  onChange,
  nameRender,
  ...props
}: EnumPickerProps<TKey>) {
  const { t } = useTranslation();
  const options = React.useMemo(
    () =>
      props.EnumNamesArray.map(key => ({
        Id: key,
        Name: !!nameRender ? nameRender(key) : t(key) ?? key,
      })),
    [nameRender, props.EnumNamesArray, t],
  );

  const getOptions = React.useMemo(() => GetOptionsFilterFunction(options), [
    options,
  ]);
  const selectedOption = React.useMemo(
    () => options.find(f => f.Id === value) ?? null,
    [options, value],
  );
  const handleChange = (value: Entity<TKey> | null) => {
    onChange?.(value?.Id ?? null);
  };

  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      value={selectedOption}
      onChange={handleChange}
      style={{ marginRight: '4px' }}
      {...props}
    />
  );
}

/**
 * Provides a picker with the predefined options based on the array of the enum names
 * @param array Array of the Union type enum names
 * @returns
 */
export function withEnumOptions<TUnion extends string>(array: Array<TUnion>) {
  return (Component: React.ComponentType<EnumPickerProps<TUnion>>) => {
    return (props: Omit<EnumPickerProps<TUnion>, 'EnumNamesArray'>) => (
      <Component {...props} EnumNamesArray={array} />
    );
  };
}
