import { Button } from 'app/components/BasicButtons/Button';
import { useTranslation } from 'react-i18next';
import { toQueryString } from 'utils/url-utils';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { translations } from 'locales/translations';
import { useDispatch } from 'react-redux';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
} from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import {
  AssetsFilterForWorkOrder,
  AssetsPicker,
} from 'app/components/pickers/MultiSelectPickers/AssetsPicker/index';
import { initWOTypeDataParams } from 'app/components/pickers/AutocompletePickers/WorkOrderTypePicker';
import { WorkOrderQueryStringParameters } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details/slice/types';
import { GetRawValue } from 'app/components/BasicTable/types/FilterParam';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { DetectIsMobile } from 'utils/mobileDetect';
import { Skeleton } from '@material-ui/lab';

export interface AddWorkOrderProps<TRow> {
  rows: TRow[];
  getAssets: (rows: TRow[]) => Promise<IAssetDto[]>;
  downtime?: boolean;
  buttonName?: string;
  primary?: boolean;
}
const WorkButton = props => {
  return (
    <Button
      variant={props.primary ? 'main' : 'white'}
      size="small"
      title={props.title}
      startIcon={<Icon icon="plus" />}
      endIcon={<Icon icon="chevron-down" />}
      onClick={props.handleClick}
      aria-controls={props.id}
      aria-haspopup="true"
    >
      {props.buttonName}
    </Button>
  );
};
export function AddWorkOrder<TRow>({
  rows,
  getAssets,
  downtime,
  buttonName,
  primary,
}: AddWorkOrderProps<TRow>) {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const isMobile = DetectIsMobile();
  const history = useHistory();
  const [assets, setAssets] = React.useState<IAssetDto[] | undefined>([]);
  const [workTypes, setWorkTypes] = React.useState<
    IWorkOrderTypeDto[] | undefined
  >(undefined);
  const [workTypeLoading, setWorkTypeLoading] = React.useState<
    boolean | undefined
  >(undefined);
  const [assetLoading, setAssetLoading] = React.useState<boolean | undefined>(
    undefined,
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleAssetChange = React.useCallback((value: IAssetDto[] | []) => {
    setAssets(value);
    setWorkTypeLoading(undefined);
    // setWorkTypeLoading(true);
    // initWOTypeDataParams(value, undefined, downtime).then(types => {
    //   setWorkTypes(types);
    //   setWorkTypeLoading(false);
    // });
  }, []);
  const getParamFromUrl = (name: string): string | undefined => {
    return GetRawValue(new URLSearchParams(location.search), name) ?? undefined;
  };
  const handleWorkTypeClick = (event: IWorkOrderTypeDto) => {
    let params = {
      queryParams: {
        id: undefined,
        aType: event.Id.toString(),
        down: (downtime === true && event.AssetAlertType).toString(),
        source: getParamFromUrl('source'),
        Up: getParamFromUrl('Up'),
        title: getParamFromUrl('title'),
        eStart: getParamFromUrl('eStart'),
        sympt: getParamFromUrl('sympt'),
        aStatus: getParamFromUrl('aStatus'),
        aStart: getParamFromUrl('aStart'),
        desc: getParamFromUrl('desc'),
        assetId: !!assets ? assets.map(a => a.Id).join(',') : undefined,
        offH: (downtime === true && event.AssetAlertType).toString(),
        offStart: getParamFromUrl('offStart'),
        offEnd: getParamFromUrl('offStart'),
        reason: getParamFromUrl('reason'),
        eqid: getParamFromUrl('eqid'),
        sid: getParamFromUrl('sid'),
      } as WorkOrderQueryStringParameters,
      useSidePanel: true,
    } as WorkOrderDetailsProps;
    isMobile
      ? history.push(['/workorders/details?', toQueryString(params)].join(''))
      : dispatch(
          actions.openSidePanel({
            type: RenderPageType.WorkOrderDetails,
            props: params,
          }),
        );
    setOpen(false);
  };
  React.useEffect(() => {
    let active =
      open && !assetLoading && (assets === undefined || assets?.length < 1);
    if (active) {
      setAssetLoading(true);
      getAssets(rows).then(ass => {
        setAssets(() => ass);
        setAssetLoading(false);
      });
    }
    return () => {
      active = false;
    };
  }, [assetLoading, assets, getAssets, open, rows]);
  React.useEffect(() => {
    let active =
      open && !!assets && assets.length > 0 && workTypeLoading === undefined;
    if (active) {
      setWorkTypeLoading(true);
      initWOTypeDataParams(assets, undefined, downtime).then(types => {
        setWorkTypes(types);
        setWorkTypeLoading(false);
      });
    }
    return () => {
      active = false;
    };
  }, [assets, downtime, open, workTypeLoading]);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
  };
  return (
    <div>
      <WorkButton
        handleClick={handleClick}
        id={`workOrderCreate-${downtime ? 'downtime' : 'new'}`}
        title={t(translations.CreateAnAssetRelatedEvent) as string}
        buttonName={buttonName || (t(translations.AddEvent) as string)}
        primary={primary}
      />
      <Popover
        onClose={handleClick}
        id={`workOrderCreate-${downtime ? 'downtime' : 'new'}`}
        open={open}
        marginThreshold={1}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        PaperProps={{ style: { width: '350px' } }}
      >
        <div style={{ padding: 16 }}>
          <Grid container spacing={2} wrap="nowrap" direction="column">
            <Grid item>
              <Box display="row" justifyContent="space-between">
                <Body size="small" bold={true}>
                  {t(translations.CreateAnAssetRelatedEvent)}
                </Body>
                <IconButton
                  variant="ghost"
                  aria-label="close"
                  size="xs"
                  style={{ position: 'absolute', right: 8, top: 8 }}
                  onClick={() => {
                    setOpen(false);
                    setAssets([]);
                    setWorkTypes([]);
                  }}
                >
                  <Icon icon="times" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item>
              <React.Fragment>
                <AssetsPicker
                  predicates={AssetsFilterForWorkOrder(undefined)}
                  key="assetKey"
                  onChange={value => {
                    handleAssetChange(value);
                  }}
                  placeholder={t(translations.QuickWorkOrderPleaseSelectAssets)}
                  fullWidth={true}
                  name="assets"
                  variant="filled"
                  id="asset-id"
                  restrictByAvType="serviceRelated"
                  label={t(translations.Asset)}
                  value={assets || undefined}
                />
              </React.Fragment>
            </Grid>
            {assets && assets.length > 0 && (
              <React.Fragment>
                <Grid item>
                  <Divider variant="fullWidth" />
                </Grid>
                <Grid item>
                  <MenuList
                    variant="menu"
                    dense
                    style={{ maxHeight: `${42 * 10}px` }}
                  >
                    <MenuItem key="selectEventType">
                      <Body size="small" bold={true}>
                        {t(translations.PleaseSelectAnEventType)}
                      </Body>
                      <Divider />
                    </MenuItem>
                    {workTypeLoading ? (
                      <MenuItem key="skeleton-1">
                        <Box display="flex" width="100%" flexDirection="column">
                          <Skeleton width="100%" variant="text" />
                          <Skeleton width="100%" variant="text" />
                        </Box>
                      </MenuItem>
                    ) : workTypes && workTypes.length > 0 ? (
                      workTypes.map(wt => (
                        <MenuItem
                          key={wt.Id}
                          onClick={() => {
                            handleWorkTypeClick(wt);
                            setAssets([]);
                            setWorkTypes([]);
                          }}
                        >
                          <ListItemText>
                            <Body size="small">{wt.Name}</Body>
                          </ListItemText>
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem key="notFoundType">
                        <ListItemText>
                          {t(translations.NoAlertTypesFoundForSelectedAssets)}
                        </ListItemText>
                      </MenuItem>
                    )}
                  </MenuList>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </div>
      </Popover>
    </div>
  );
}
