import { Entity } from 'types/common';
import { httpClient } from './HttpClient';
import { EmailTypeUnion } from './odata/generated/enums/EmailType';
const url = '/api/odata/v4/email';
export const EmailApi = {
  SendEmail: ({ Files, Bcc, Cc, To, ...data }: IEmail) => {
    const form = new FormData();
    Object.keys(data).forEach(k => {
      form.append(k, data[k]);
    });
    form.append('Bcc', Bcc.map(v => v.Id) as any);
    form.append('Cc', Cc.map(v => v.Id) as any);
    form.append('To', To.map(v => v.Id) as any);

    for (let i = 0; !!Files && i < Files?.length; i++) {
      let item = Files?.item(i);
      if (!!item) {
        form.append('file' + i, item);
      }
    }
    return httpClient.post(url, form);
  },
};

export interface IEmail {
  Id?: number;
  Type: EmailTypeUnion;
  To: Entity<string>[];
  ExtraTo?: string;
  Cc: Entity<string>[];
  ExtraCc?: string;
  Bcc: Entity<string>[];
  ExtraBcc?: string;
  Subject: string;
  Body?: string;
  Files?: FileList;
  SaveToLog: boolean;
  SaveAsTemlate?: boolean;
  ServiceGroup?: number;
}
