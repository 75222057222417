import React from 'react';
import { Row } from 'react-table';
import { IRow } from '../../IRow';
import { DetectIsMobile } from 'utils/mobileDetect';
import {
  getAssetImageThumbSrc,
  ImageSize,
} from 'app/components/AssetThumbImage/getAssetImageSrc';
import {
  BasicCardWithInfo,
  CardPosition,
  CardSize,
} from 'app/components/BasicCards';
import { PrimaryAction } from './PrimaryAction';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser, selectImageExist } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';

export interface TableCardProps<TRow extends IRow> {
  row: Row<TRow>;
  imageSrcKey?: keyof TRow;
  titleKey: keyof TRow;
  primaryAction?: PrimaryAction<TRow>;
  someSelected: boolean;
  viewCheckBox?: boolean;
  useSelect: boolean;
  hasAdditionalActions: boolean;
  hasInlineRowActions: boolean;
  toggleAllRowsSelected?: (value?: boolean) => void;
  isRowSelectable: (row: Row<TRow>) => boolean;
  active: boolean;
  cardSize?: CardSize;
  showTitleCell?: boolean;
  preventInnerClicks?: boolean;
  compact?: boolean;
  contentEditable?: boolean;
  cardPosition?: CardPosition;
  loading?: boolean;
}

export function TableCard<Trow extends IRow>({
  row,
  imageSrcKey,
  titleKey,
  primaryAction,
  viewCheckBox = true,
  useSelect,
  hasAdditionalActions,
  hasInlineRowActions,
  toggleAllRowsSelected,
  isRowSelectable,
  someSelected,
  active,
  cardSize = 'small',
  showTitleCell,
  compact,
  contentEditable,
  cardPosition,
  loading,
  ...props
}: TableCardProps<Trow>) {
  const isMobile = DetectIsMobile() || compact === true;
  const user = useSelector(selectAuthenticatedUser);
  const { t } = useTranslation();
  const useButtons = React.useMemo(() => {
    return (
      hasInlineRowActions &&
      row.cells.filter(c => c.column.id.startsWith('InlineActionColumn'))
        .length > 0
    );
  }, [hasInlineRowActions, row.cells]);

  const sizeOfMobileCard = React.useMemo(() => {
    return isMobile ? (cardSize === undefined ? 'small' : cardSize) : undefined;
  }, [cardSize, isMobile]);
  const position = React.useMemo(() => {
    return isMobile
      ? 'horizontal'
      : cardPosition === undefined
      ? 'vertical'
      : cardPosition;
  }, [cardPosition, isMobile]);
  const img = React.useMemo(() => {
    return row.original[imageSrcKey ?? ''];
  }, [imageSrcKey, row.original]);
  const hasImg = React.useMemo(() => {
    return (img ?? null) !== null && typeof img === 'string';
  }, [img]);
  const imageExist = useSelector(state =>
    selectImageExist(state, hasImg ? img : null),
  );
  const imageSize = React.useMemo<ImageSize>(() => {
    if (position === 'horizontal') {
      if (!!sizeOfMobileCard) {
        return sizeOfMobileCard === 'medium' ? 'thumbLarge' : 'thumb';
      } else {
        return cardSize === 'medium' ? 'horizontalLarge' : 'horizontal';
      }
    } else {
      return cardSize === 'medium' ? 'verticalLarge' : 'vertical';
    }
  }, [cardSize, position, sizeOfMobileCard]);
  const imgSrc = React.useMemo(() => {
    return getAssetImageThumbSrc(img, imageSize, imageExist);
  }, [imageExist, imageSize, img]);
  const [showSelect, setShowSelect] = React.useState(
    isMobile ? viewCheckBox === true : someSelected || viewCheckBox === true,
  );

  React.useEffect(() => {
    setShowSelect(
      isMobile ? viewCheckBox === true : someSelected || viewCheckBox === true,
    );
  }, [isMobile, someSelected, viewCheckBox]);
  const title = React.useMemo(() => {
    return row.cells.find(c => c.column.id === titleKey);
  }, [row.cells, titleKey]);
  const select = React.useMemo(() => {
    return row.cells.find(c => c.column.id === 'selection');
  }, [row.cells]);
  const isDisabled = React.useMemo(() => {
    return !isRowSelectable(row);
  }, [isRowSelectable, row]);
  const cells = React.useMemo(() => {
    return row.cells
      .filter(
        c =>
          c.column.id !== titleKey &&
          c.column.id !== imageSrcKey &&
          c.column.id !== 'selection',
      )
      .filter(f => f.value !== null && f.value !== undefined && f.value !== '')
      .slice(0, 3);
  }, [imageSrcKey, row.cells, titleKey]);

  return (
    <BasicCardWithInfo
      title={showTitleCell ? title?.render('Cell') : title?.value}
      info={
        isDisabled
          ? undefined
          : useSelect
          ? t(translations.CardMouseOver_info)
          : undefined
      }
      backStyle="white"
      imageSrc={imgSrc}
      disabled={isDisabled}
      status={active ? 'active' : undefined}
      selected={active}
      selectVisible={
        showSelect
        //isMobile ? useSelect && !isDisabled && viewCheckBox : showSelect
      }
      checkBox={
        <React.Fragment>{!!select && select.render('Cell')}</React.Fragment>
      }
      showBullet={true}
      cardSize={cardSize}
      mobileSize={sizeOfMobileCard}
      position={position}
      primaryAction={() => {
        if (!!primaryAction) {
          primaryAction(row, toggleAllRowsSelected, user);
        } else if (useSelect && !isDisabled) {
          row.toggleRowSelected();
        }
      }}
      useSelect={useSelect} //&& viewCheckBox
      useButtons={useButtons}
      useImage={imageSrcKey !== undefined}
      topActions={
        hasAdditionalActions
          ? row.cells
              .filter(c => c.column.id.startsWith('ActionColumn'))
              .map(c => {
                return {
                  key: c.column.id,
                  action: c.render('Cell'),
                };
              })
          : undefined
      }
      hasTopActions={hasAdditionalActions}
      cardContentRows={
        cells.length > 0
          ? cells
              .filter(
                f =>
                  !(
                    f.column.id.startsWith('ActionColumn') ||
                    f.column.id.startsWith('InlineActionColumn')
                  ),
              )
              .map(c => {
                return {
                  key: c.column.id,
                  label: c.render('Header'),
                  text: c.render('Cell'),
                };
              })
          : undefined
      }
      buttons={
        hasInlineRowActions
          ? row.cells
              .filter(c => c.column.id.startsWith('InlineActionColumn'))
              .map(c => {
                return {
                  key: c.column.id,
                  button: c.render('Cell'),
                };
              })
          : undefined
      }
      preventInnerClicks={props.preventInnerClicks}
      contentEditable={contentEditable}
      loading={loading}
    />
  );
}
