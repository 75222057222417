import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';

export interface InventoryBatchPickerProps
  extends Omit<AutocompletePickerProps<IInventoryBatchDto>, 'loadData'> {}
const url = '/api/odata/v4/InventoryBatches';
export const generateInventoryBatchName = (
  dto: IInventoryBatchDto,
): IInventoryBatchDto => {
  let name = `Batch: #${dto?.Id} - ${dto.Name} - ${dto?.Inventory}`;
  return Object.assign({}, dto, { Name: name });
};
export const convertInventoryBatchName = (
  Id: number,
  Name: string,
  Inventory: number,
): string => {
  let name = `Batch: #${Id} - ${Name} - ${Inventory}`;
  return name;
};
const loadBaseData = (
  predicates: (string | Condition<IInventoryBatchDto>)[] | undefined,
  select?: (keyof IInventoryBatchDto)[],
) =>
  getAutoCompleteLoadDataFn<IInventoryBatchDto>({
    url,
    predicates: predicates,
    //transformModel: generateInventoryBatchName,
    // TODO: narrow down used properties list
    select:
      !!select && select.length > 0
        ? select
        : [
            'Id',
            'Name',
            'OfflineServiceTypeId',
            'OfflineServiceTypeName',
            'Inventory',
            'Lot',
            'CreatedAt',
            'CreatedBy',
            'ExpirationDate',
            'PurchaseDate',
            'PurchasePricePerUnit',
            'InventoryUsed',
            'StokRenewed',
          ],
    globalServiceGroupFilter: undefined,
  });

export function InventoryBatchPicker(props: InventoryBatchPickerProps) {
  const innerLoadData = loadBaseData(props.predicates);
  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      additionalItem={props.additionalItem}
      id={props.id || 'inventBatchId'}
      getOptionLabelName={option =>
        convertInventoryBatchName(
          (option as IInventoryBatchDto).Id,
          (option as IInventoryBatchDto).Name,
          (option as IInventoryBatchDto).Inventory,
        )
      }
      renderOptionName={option =>
        convertInventoryBatchName(
          (option as IInventoryBatchDto).Id,
          (option as IInventoryBatchDto).Name,
          (option as IInventoryBatchDto).Inventory,
        )
      }
      {...props}
    />
  );
}
export const loadInventoryBatchesData = loadBaseData;
export function BatchesByServiceTypeFilter(
  serviceTypeId: number,
  withStockRenewed?: boolean,
): (string | Condition<IInventoryBatchDto>)[] {
  const predicates: (string | Condition<IInventoryBatchDto>)[] = [];
  predicates.push(
    new Condition<IInventoryBatchDto>(
      'OfflineServiceTypeId',
      ODataOperators.Equals,
      serviceTypeId,
    ),
  );
  if (!!withStockRenewed) {
    predicates.push(
      new Condition<IInventoryBatchDto>(
        'StokRenewed',
        ODataOperators.Equals,
        withStockRenewed,
      ),
    );
  }
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}
