import { AxiosError } from 'axios';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { Identifiable } from 'types/common';
import { httpClient } from './HttpClient';

export interface IDeleteSamplesResult {
  successCount: number;
  errors: Error[];
}
export async function deleteSamples(
  samples: Identifiable<number>[],
): Promise<IDeleteSamplesResult> {
  let successCount = 0;
  const errors: Error[] = [];
  // todo: convert to transaction/batch
  for (const sample of samples) {
    const url = `/api/odata/v4/samples(${sample.Id})`;
    try {
      await httpClient.delete(url);
      successCount++;
    } catch (error) {
      const message =
        (error as AxiosError)?.response?.data?.error?.innererror?.message ??
        i18next.t(translations.errormessage);
      errors.push(new Error(message));
    }
  }

  return {
    successCount: successCount,
    errors: errors,
  };
}
