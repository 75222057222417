import * as React from 'react';
import {
  Box,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuList,
} from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { Body } from 'app/components/Typography';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { useAsyncGetWthEffect } from 'app/hooks/useAsyncGetOnMount';
import {
  BatchesByServiceTypeFilter,
  convertInventoryBatchName,
  loadInventoryBatchesData,
} from 'app/components/pickers/AutocompletePickers/InventoryBatchesPicker';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Button } from 'app/components/BasicButtons/Button';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { GetResponseMessages, IResponseType } from 'types/ResponseType';
import { OtherServicesApi } from 'api/OtherServicesApi';
import { useDispatch } from 'react-redux';
import { useAppSettingsSlice } from 'app/slice';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';

export interface InventoryBatchListProps {
  serviceTypeId: number;
  isAdmin: boolean;
  addNewBatchClick: () => void;
  anchorEl: any;
  onClose: () => void;
  onBatchClick?: (batch: IInventoryBatchDto) => void;
  selectedBatch?: IInventoryBatchDto;
  batches: IInventoryBatchDto[];
  onRenewStockClick?: (batchId?: number) => void;
  batchDeletable?: boolean;
  showRenewStockButtons?: boolean;
  createdRenewal?: IOtherServices;
}

export const InventoryBatchList = React.memo(function InventoryBatchList(
  props: InventoryBatchListProps,
) {
  const {
    isAdmin,
    serviceTypeId,
    addNewBatchClick,
    anchorEl,
    onClose,
    onBatchClick,
    selectedBatch,
    batches,
    onRenewStockClick,
    batchDeletable,
    showRenewStockButtons,
    createdRenewal,
  } = props;
  const [input, setInnput] = React.useState<string>('');
  const { t } = useTranslation();
  const [removedBatch, setRemovedBatch] = React.useState<
    IInventoryBatchDto | undefined
  >(undefined);
  const [removeBatchOpen, setRemoveBatchOpen] = React.useState(false);
  const dispatch = useDispatch();

  const { actions: appSettingsActions } = useAppSettingsSlice();
  const onRemoveBatchClick = (batch: IInventoryBatchDto) => {
    setRemoveBatchOpen(true);
    setRemovedBatch(batch);
  };
  const handleRemoveBatch = (batch: IInventoryBatchDto) => {
    new Promise<IResponseType>((resolve, reject) => {
      return OtherServicesApi.deleteInventoryBatch(batch)
        .then(response => {
          const messages: IResponseType = GetResponseMessages(
            response,
            t(translations.Success) as string,
          );
          setRemoveBatchOpen(false);
          if (messages.ErrorMessages.length > 0) {
            dispatch(
              appSettingsActions.addNotification({
                variant: 'success',
                message: messages.ErrorMessages[0],
              }),
            );
          }
          if (messages.SuccessMessages.length > 0) {
            dispatch(
              appSettingsActions.addNotification({
                variant: 'success',
                message: messages.SuccessMessages[0],
              }),
            );
          }
          resolve(messages);
        })
        .catch(error => {
          console.log('status error', error);
          let err = t(translations.Failed) as string;
          reject(err);
        })
        .finally(() => {
          setRemoveBatchOpen(false);
          setRemovedBatch(undefined);
        });
    });
  };
  const availableBatches = useAsyncGetWthEffect<IInventoryBatchDto[]>(
    async () =>
      loadInventoryBatchesData(
        BatchesByServiceTypeFilter(
          serviceTypeId,
          showRenewStockButtons ? undefined : true,
        ),
      )(input, undefined).then(res => res.value),
    batches,
    [input, serviceTypeId, selectedBatch, removedBatch, createdRenewal],
  );

  return (
    <React.Fragment>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl !== null}
        onClose={onClose}
        //   PaperProps={{ style: { height: '400px' } }}
      >
        <ListItem key="lists-title">
          <Body size="small" bold={true}>
            {t(translations.InventoryBatches)}
          </Body>
        </ListItem>
        <ListItem key="lists-search" value="search">
          <TextControlField
            placeholder={t(translations.SearchFilter)}
            size="small"
            startIcon={
              <Icon icon="search" color="filters" colorExtend="light" />
            }
            value={input || ''}
            aria-label={t(translations.SearchFilter)}
            inputProps={{ 'aria-label': t(translations.SearchFilter) }}
            onChange={e => setInnput(e.target.value)}
            autoFocus
            fullWidth
          />
        </ListItem>
        <MenuList
          variant="menu"
          key="lists-menu-items"
          style={{ maxHeight: `${42 * 5}px`, overflowY: 'auto' }}
        >
          {!!availableBatches && availableBatches.length > 0 ? (
            availableBatches.map(a => (
              <ListItem
                button
                key={a.Id}
                selected={a.Id === selectedBatch?.Id}
                onClick={e => !!onBatchClick && onBatchClick(a)}
              >
                <ListItemText>
                  <Body size="small" style={{ cursor: 'pointer' }}>
                    {convertInventoryBatchName(a.Id, a.Name, a.Inventory)}
                  </Body>
                </ListItemText>
                {a.Id !== selectedBatch?.Id && batchDeletable && (
                  <ListItemSecondaryAction>
                    <IconButton
                      variant="text"
                      aria-label="delete"
                      onClick={() => onRemoveBatchClick(a)}
                    >
                      <Icon icon="trash" color="black" colorExtend="light" />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
                {showRenewStockButtons && (
                  <ListItemSecondaryAction>
                    <IconButton
                      variant="text"
                      aria-label="delete"
                      onClick={() =>
                        onRenewStockClick && onRenewStockClick(a.Id)
                      }
                    >
                      <Tooltip
                        arrow
                        title={t(translations.RenewStock) as string}
                        style={{ cursor: 'pointer', zIndex: 1 }}
                      >
                        <span>
                          <Icon
                            icon="barcode"
                            color="black"
                            colorExtend="light"
                          />
                        </span>
                      </Tooltip>
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Body size="small" bold={true}>
                {t(translations.InventoryBatch_notFound)}
              </Body>
            </ListItem>
          )}
        </MenuList>
        {isAdmin && (
          <ListItem key="manage-lists" value="action">
            <Box style={{ display: 'flex', gap: 8 }}>
              <Button
                variant="white"
                size="small"
                fullWidth={true}
                aria-label="create batch"
                startIcon={<Icon icon="plus" />}
                onClick={() => addNewBatchClick()}
              >
                {t(translations.AddNewBatch)}
              </Button>
              {showRenewStockButtons && !!onRenewStockClick && (
                <Button
                  variant="white"
                  size="small"
                  fullWidth={true}
                  aria-label="create batch"
                  startIcon={<Icon icon="barcode" />}
                  onClick={() => onRenewStockClick()}
                >
                  {t(translations.RenewStock)}
                </Button>
              )}
            </Box>
          </ListItem>
        )}
      </Menu>
      <DialogConfirm
        isOpen={removeBatchOpen}
        title={t(translations.DeleteBatchInventory_title)}
        //processing={props.processing}
        body={t(translations.DeleteBatchInventory_message)}
        confirmButtonLabel={t(translations.Delete)}
        cancelButtonLabel={t(translations.Cancel)}
        onConfirm={e => !!removedBatch && handleRemoveBatch(removedBatch)}
        onCancel={() => {
          setRemoveBatchOpen(false);
          setRemovedBatch(undefined);
        }}
        maxWidth="md"
      />
    </React.Fragment>
  );
});
