import { IconProp } from 'app/AllIcons';
import * as React from 'react';
import { DetectIsMobile } from 'utils/mobileDetect';
import { IPathAndQuery } from 'utils/url-utils';
import { ActionButton } from '../ActionButton';
import { ActionIconButton } from '../ActionIconButton';
import { ButtonsVariant } from '../Button';

export interface TopActionButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  startIcon?: IconProp;
  endIcon?: IconProp;
  icon: IconProp;
  text: string;
  title?: string;
  variant?: ButtonsVariant;
  size?: 'large' | 'medium' | 'small' | 'xs';
  shape?: 'circle' | 'square';
  customIcon?: React.ReactNode;
  href?: string | IPathAndQuery;
  to?: string;
  noChangeOnMobile?: boolean;
  target?: string;
  id?: string;
}

export function TopActionButton(props: TopActionButtonProps) {
  const {
    onClick,
    disabled,
    startIcon,
    endIcon,
    icon,
    text,
    title,
    variant,
    size,
    shape,
    customIcon,
    noChangeOnMobile,
    id,
    ...other
  } = props;
  const isMobile = DetectIsMobile();
  const action =
    isMobile && !noChangeOnMobile ? (
      <ActionIconButton
        icon={icon}
        title={title || ''}
        onClick={onClick}
        disabled={disabled}
        variant={variant || 'ghost'}
        size={size || 'small'}
        shape={shape || 'square'}
        customIcon={customIcon}
        id={id}
        {...other}
      />
    ) : (
      <ActionButton
        onClick={onClick}
        starticon={startIcon}
        endicon={endIcon}
        title={title}
        text={text}
        disabled={disabled}
        variant={variant || 'main'}
        size={size === 'xs' ? 'small' : size}
        id={id}
        {...other}
      />
    );
  return action;
}
