import BasicTypography from 'app/components/Typography/BasicTypography';
import { ITrainingSessionSlotDto } from 'api/odata/generated/entities/ITrainingSessionSlotDto';
import { TrainingState, TrainingStateUnion } from 'enums/TrainingState';
import HTMLReactParser from 'html-react-parser';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { assertExhaustive } from 'utils/assertExhaustive';
import { formatSchedulerDateTime } from '../../../utils/formatSchedulerTime';
import { SchedulerEventTooltipProps } from '../SchedulerEventTooltipProps';
import { TooltipLine } from '../TooltipLine';

export function TrainingSessionTooltip(props: SchedulerEventTooltipProps) {
  const dto = props.event.original as ITrainingSessionSlotDto;
  const statusTextLocalizationKey = statusTextKey(dto.TrainingState);
  const footerTextLocalizationKey = statusClickTextKey(dto.TrainingState);
  const available = (dto.Attendees ?? 0) < (dto.MaxAttendees ?? 0);
  return (
    <>
      <TooltipLine
        label={i18next.t(translations.Start)}
        value={formatSchedulerDateTime(props.event.start_date)}
      />
      <TooltipLine
        label={i18next.t(translations.End)}
        value={formatSchedulerDateTime(props.event.end_date)}
      />
      <TooltipLine
        label={i18next.t(translations.Service)}
        value={props.event.service_name}
      />
      <BasicTypography>
        {i18next.t(translations.TrainingSession)}
      </BasicTypography>
      {statusTextLocalizationKey !== undefined && available && (
        <BasicTypography>
          {i18next.t(statusTextLocalizationKey)}
        </BasicTypography>
      )}
      <TooltipLine
        label={i18next.t(translations.Trainer)}
        value={dto.TrainerName}
      />
      <TooltipLine
        label={i18next.t(translations.Campus)}
        value={dto.CampusName}
      />
      <TooltipLine
        label={i18next.t(translations.Building)}
        value={dto.BuildingName}
      />
      <TooltipLine label={i18next.t(translations.Room)} value={dto.RoomName} />
      <TooltipLine
        label={i18next.t(translations.TrainingSignedUp)}
        value={dto.Attendees}
      />
      <TooltipLine
        label={i18next.t(translations.MinAttendees)}
        value={dto.MinAttendees}
      />
      <TooltipLine
        label={i18next.t(translations.MaxAttendees)}
        value={dto.MaxAttendees}
      />
      <TooltipLine
        label={i18next.t(translations.Remarks)}
        value={dto.Remarks === null ? null : HTMLReactParser(dto.Remarks)}
      />
      {footerTextLocalizationKey !== undefined && (
        <BasicTypography>
          {i18next.t(footerTextLocalizationKey)}
        </BasicTypography>
      )}
    </>
  );
}

const statusTextKey = (stateid: number) => {
  const state = TrainingState[stateid] as TrainingStateUnion;
  try {
    switch (state) {
      case 'SignedUp':
        return translations.TrainingSessions_Calendar_SignedUp;
      case 'UnderMinimum':
        return translations.TrainingSessions_Calendar_UnderMinimum;
      case 'Available':
        return translations.TrainingSessions_Calendar_Available;
      case 'Maximum':
        return translations.TrainingSessions_Calendar_Maximum;
      default:
        assertExhaustive(state);
    }
  } catch (error) {
    return undefined;
  }
};

const statusClickTextKey = (stateid: number) => {
  const state = TrainingState[stateid] as TrainingStateUnion;
  try {
    switch (state) {
      case 'SignedUp':
        return translations.TrainingSessions_Calendar_ClickToView;
      case 'UnderMinimum':
        return translations.TrainingSessions_Calendar_ClickToSignUp;
      case 'Available':
        return translations.TrainingSessions_Calendar_ClickToSignUp;
      case 'Maximum':
        return translations.TrainingSessions_Calendar_ClickToSignUp;
      default:
        assertExhaustive(state);
    }
  } catch (error) {
    return undefined;
  }
};
