import * as React from 'react';
import { Button } from 'app/components/BasicButtons/Button';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOnlineBudgetsStatusSlice } from '../slice';
import * as Yup from 'yup';
import { selectPayState } from '../slice/selectors';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { PaymentMethodEntitySchema } from 'app/components/Forms/Schemas';
import { Form, Formik } from 'formik';
import { FormPaymentMethodPicker } from 'app/components/Forms/FormPaymentMethodPicker';
import { FormRow } from 'app/components/Forms/FormsLayout';
import { Entity } from 'types/common';
import { IInvoiceBudgetBalanceDto } from 'api/odata/generated/entities/IInvoiceBudgetBalanceDto';
import { Box } from 'app/components/basic/Wrappers/Box';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { sum } from 'lodash';
import { Alert } from '@material-ui/lab';

export interface PayButtonProps {
  endDate: Date;
  selected: IInvoiceBudgetBalanceDto[];
  disabled?: boolean;
  disabledTitle?: string;
}
export const PayButton = ({
  selected,
  disabled,
  disabledTitle,
  ...props
}: PayButtonProps) => {
  const { t } = useTranslation();
  const state = useSelector(selectPayState);
  const dispatch = useDispatch();
  const { actions } = useOnlineBudgetsStatusSlice();

  const handlePayButtonClick = () => {
    if (selected.length > 0) {
      dispatch(actions.pay_Open(selected));
    }
  };
  const handleDialogClose = () => {
    if (state.status === 'pending') {
      return;
    }
    dispatch(actions.pay_Close());
  };
  const totalUpaidBalance = sum(state.selected?.map(row => row.UnpaidBalance));

  const confirmationMessage = `${state.selected?.length} records will be processed.`;

  const handleSubmit = (values: IValues, formikHelpers) => {
    const paymentMethod = values?.PaymentMethod?.Id;
    if (paymentMethod !== undefined && state.selected !== undefined) {
      dispatch(
        actions.pay({
          EndDate: props.endDate,
          PaymentMethod: paymentMethod,
          Rows: state.selected,
        }),
      );
    }
  };
  const initialValues: IValues = {
    PaymentMethod: null,
  };
  const title = disabled ? disabledTitle : t(translations.PayDescription);
  return (
    <>
      <Tooltip title={title}>
        <span>
          <Button
            disabled={disabled === true || selected.length === 0}
            onClick={handlePayButtonClick}
            processing={state.status === 'pending'}
          >
            {t(translations.Pay)}
          </Button>
        </span>
      </Tooltip>

      <Dialog open={state.dialogOpen ?? false} onClose={handleDialogClose}>
        {state.status === 'pending' && (
          <LinearProgress variant="determinate" value={state.progress ?? 1} />
        )}
        <Formik<IValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {formik => (
            <Form>
              <DialogTitle>{t(translations.ChargeBalanceOnline)}</DialogTitle>
              <DialogContent>
                <BasicTypography>{confirmationMessage}</BasicTypography>

                <Box>
                  <FormRow fullRow>
                    {[
                      <React.Fragment key="PaymentMethod">
                        <FormPaymentMethodPicker
                          fullWidth
                          name="PaymentMethod"
                          placeholder={t(translations.PaymentMethod)}
                          label={t(translations.PaymentMethod)}
                          disabled={state.status !== undefined}
                        />
                      </React.Fragment>,
                    ]}
                  </FormRow>
                  <FormRow fullRow>
                    {[
                      <BasicTypography>
                        {t(translations.TotalPayments)}:{' '}
                        {totalUpaidBalance.toFixed(2)}
                      </BasicTypography>,
                    ]}
                  </FormRow>
                </Box>
                {state.messages?.map((responseType, index) => (
                  <>
                    {responseType.SuccessMessages.map(message => (
                      <Alert key={`success_${index}`} color="success">
                        {message}
                      </Alert>
                    ))}
                    {responseType.ErrorMessages.map(message => (
                      <Alert key={`error_${index}`} color="error">
                        {message}
                      </Alert>
                    ))}
                    {responseType.WarningMessages.map(message => (
                      <Alert key={`warning_${index}`} color="warning">
                        {message}
                      </Alert>
                    ))}
                  </>
                ))}
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  processing={state.status === 'pending'}
                  disabled={state.status !== undefined}
                >
                  {t(translations.PaySelected)}
                </Button>
                {state.status === 'resolved' ? (
                  <Button
                    type="reset"
                    onClick={() => dispatch(actions.pay_Close())}
                  >
                    {t(translations.Close)}
                  </Button>
                ) : (
                  <Button
                    type="reset"
                    onClick={() => dispatch(actions.pay_Close())}
                    disabled={state.status === 'pending'}
                  >
                    {t(translations.Cancel)}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
interface IValues {
  PaymentMethod?: Entity<number> | null;
}
const validationSchema: Yup.SchemaOf<IValues> = Yup.object({
  PaymentMethod: PaymentMethodEntitySchema.required(),
});
