import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme => ({
  /* Styles applied to the root element. */
  root: {
    position: 'relative',
    height: 32,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.grayBorders}`,
    gap: 4,
    borderRadius: 8,
    '&.inputSizeSmall': {
      gap: 0,
      paddingRight: 8,
    },
    'label + &': {
      marginTop: '21px',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.80)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'rgba(255, 255, 255, 0.90)',
      },
    },
    '&$focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.90)',
    },
    '&$disabled': {
      backgroundColor: theme.palette.common.backgroundHover,
    },
  },
  /* Styles applied to the root element if the component is a descendant of `FormControl`. */
  formControl: {
    'label + &': {
      marginTop: '21px',
    },
  },
  /* Styles applied to the root element if the component is focused. */
  focused: {
    border: `1px solid ${theme.palette.primary.textHover}`,
  },
  /* Styles applied to the root element if `disabled={true}`. */
  disabled: {
    backgroundColor: theme.palette.common.backgroundHover,
  },
  /* Styles applied to the root element if color secondary. */
  colorSecondary: {
    '&$underline:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  /* Styles applied to the root element if `disableUnderline={false}`. */
  underline: {
    // '&:after': {
    //   borderBottom: `2px solid ${theme.palette.primary.main}`,
    //   left: 0,
    //   bottom: 0,
    //   // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
    //   content: '""',
    //   position: 'absolute',
    //   right: 0,
    //   transform: 'scaleX(0)',
    //   transition: theme.transitions.create('transform', {
    //     duration: theme.transitions.duration.shorter,
    //     easing: theme.transitions.easing.easeOut,
    //   }),
    //   pointerEvents: 'none', // Transparent to the hover style.
    // },
    // '&$focused:after': {
    //   transform: 'scaleX(1)',
    // },
    // '&$error:after': {
    //   borderBottomColor: theme.palette.error.main,
    //   transform: 'scaleX(1)', // error is always underlined in red
    // },
    // '&:before': {
    //   borderBottom: `1px solid ${theme.palette.primary.textHover}`,
    //   left: 0,
    //   bottom: 0,
    //   // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
    //   content: '"\\00a0"',
    //   position: 'absolute',
    //   right: 0,
    //   transition: theme.transitions.create('border-bottom-color', {
    //     duration: theme.transitions.duration.shorter,
    //   }),
    //   pointerEvents: 'none', // Transparent to the hover style.
    // },
    // '&:hover:not($disabled):before': {
    //   borderBottom: `2px solid ${theme.palette.text.primary}`,
    //   // Reset on touch devices, it doesn't add specificity
    //   '@media (hover: none)': {
    //     borderBottom: `1px solid ${theme.palette.primary.textHover}`,
    //   },
    // },
    // '&$disabled:before': {
    //   borderBottomStyle: 'dotted',
    // },
  },
  /* Pseudo-class applied to the root element if `error={true}`. */
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  /* Styles applied to the `input` element if `margin="dense"`. */
  marginDense: {
    borderRadius: 8,
    height: 24,
  },
  /* Styles applied to the root element if `multiline={true}`. */
  multiline: {
    padding: '6px 12px',
    '&$marginDense': {
      paddingTop: 1,
      paddingBottom: 1,
    },
  },
  /* Styles applied to the root element if `fullWidth={true}`. */
  fullWidth: {},
  /* Styles applied to the `input` element. */
  input: {
    minWidth: theme.spacing(6),
    padding: '6px 12px',
    borderRadius: 8,
    '&:-webkit-autofill': {
      WebkitBoxShadow:
        theme.palette.type === 'light' ? null : '0 0 0 100px #266798 inset',
      WebkitTextFillColor: theme.palette.type === 'light' ? null : '#fff',
      caretColor: theme.palette.type === 'light' ? null : '#fff',
    },
    '&&.inputSizeSmall &': {
      padding: '6px 8px',
    },
  },
  /* Styles applied to the `input` element if `margin="dense"`. */
  inputMarginDense: {
    paddingTop: 1,
    paddingBottom: 1,
  },
  /* Styles applied to the `input` element if `multiline={true}`. */
  inputMultiline: {
    padding: 0,
  },
  /* Styles applied to the `input` element if `type="search"`. */
  inputTypeSearch: {},
  // inputAdornedEnd: {
  //   padding: '6px 8px',
  // },
}));
