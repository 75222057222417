import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { Body } from 'app/components/Typography';
import { ReservationStatus } from 'app/pages/ReservationDetails/Details/slice/types';
import HTMLReactParser from 'html-react-parser';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { ReservationStatusIcon } from '../../EventRender/ReservationStatusIcon';
import { SchedulerEventTooltipProps } from '../SchedulerEventTooltipProps';

export function ReservationTooltip(props: SchedulerEventTooltipProps) {
  const dto = props.event.original as ICalendarReservationDto;
  const statusTextLocalizationKey = statusTextKey(dto);
  const reservationHtml = !!props.reservationTooltipTemplate
    ? props.reservationTooltipTemplate(props.event.original)
    : undefined;
  return !!dto && !!dto.Restricted && dto.Restricted === true ? (
    <>{''}</>
  ) : (
    <>
      {!!!!dto && !!dto.StatusId && (
        <div
          style={{
            display: 'flex',
            gap: 8,
          }}
        >
          <ReservationStatusIcon size="xl" event={props.event} />
          {statusTextLocalizationKey !== undefined && (
            <Body size="small" bold={true}>
              {i18next.t(statusTextLocalizationKey)}
            </Body>
          )}
        </div>
      )}
      {reservationHtml !== undefined && (
        <div>{HTMLReactParser(reservationHtml)}</div>
      )}
    </>
  );
}
const statusTextKey = (dto?: ICalendarReservationDto) => {
  const statusId = dto?.StatusId;
  if (statusId === undefined) {
    return undefined;
  }
  if (statusId === null) {
    return undefined;
  }
  const state = statusId as ReservationStatus;
  if (
    state === ReservationStatus.Pending ||
    state === ReservationStatus.PendingCoord
  ) {
    return translations.ReservationPendingApproval;
  } else if (state === ReservationStatus.Draft) {
    return translations.DraftReservationStatus;
  } else if (state === ReservationStatus.Approved && dto?.Tutoring === true) {
    return translations.ApprovedByStaff;
  } else {
    return undefined;
  }
};
