import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { reservationActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { ReservationsApi as api } from 'api/ReservationsApi';
import {
  EquipmentsChangeStateParameters,
  IMandatoryEquipmentsDto,
  IReservationEquipmentDto,
  ReservationDetailsResponseModel,
  ReservationDetailsState,
  ReservationEquipmentsChangeResult,
  ReservationGlobalState,
  ReservationInsertModel,
  ReservationQueryStringParameters,
  ReservationSettingsState,
  ReservationUpdateModel,
  ServiceSettingsState,
  TransferToReservation,
} from './types';
import { mapSettingsFromServices } from './mapSettingsFromServices';
import { ConvertModelToUpdateEntity } from './ConvertModelToUpdateEntity';
import { ConvertModelToInsertEntity } from './ConvertModelToInsertEntity';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { selectAuthenticatedUser, selectOffsetDate } from 'app/slice/selectors';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import i18next from 'i18next';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { translations } from 'locales/translations';
import { ReservationDetailsProps } from '..';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { AxiosError } from 'axios';
import { IResponseType } from 'types/ResponseType';
import { FileTypes } from 'api/odata/generated/enums/FileTypes';
import { CustomFormTypeEnum } from 'enums/CustomFormType';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { FileStoreApi } from 'api/fileStoreApi';
import { getFilesModel, UpdateFilesModel } from 'utils/fileStoreHelper';
import { GlobalSettingsType } from '../components/useGlobalSettingsHook';
import { Entity } from 'types/common';
import { IEquipmentAccServiceDto } from 'api/odata/generated/entities/IAccServiceDto';
import { dateUtils } from 'utils/date-utils';
import {
  selectCloneReservations,
  selectReservationDtoState,
} from './selectors';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { OtherServicesApi } from 'api/OtherServicesApi';
import { ConsumableServicesResponse } from 'app/pages/OtherServiceDetails/Details/slice/types';
import { tryParseInt } from 'utils/string-utils';
import { repetitiveActions } from 'app/components/Forms/FormRepetitive/slice';

function* doReservationInint(
  action: PayloadAction<{
    query: ReservationQueryStringParameters;
    globalSettings: GlobalSettingsType;
    prevState?: ReservationDetailsState;
  }>,
) {
  try {
    const user: AuthenticatedUser | undefined = yield select(
      selectAuthenticatedUser,
    );
    const result = yield call(api.initReservation, action.payload.query);
    console.debug('reseration result: ', result);
    let isChange = action.payload.query.resourceChanged === 'true';
    let response = result as ReservationDetailsResponseModel;
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.SuccessMessages.map(item => {
            return {
              key: 'initReservationSucc',
              message: item,
              variant: 'success',
            };
          }),
        ),
      );
    }
    let equipments =
      response.model.EquipmentsData.length > 0
        ? response.model.EquipmentsData
        : !!response.model.BaseEquipment
        ? [response.model.BaseEquipment]
        : [];
    if (equipments.length > 0) {
      yield put(
        actions.extendEquipmentSettingsState({
          equipments: equipments,
          mandatories: response.model.MandatoriesEquipments,
          accServices: response.model.AccServices,
          globalSettings: action.payload.globalSettings,
          settings: {} as ServiceSettingsState,
        }),
      );
    }
    if (!isChange) {
      if (action.payload.query.Start !== undefined) {
        response.model.StartTime = dateUtils.formatQueryStringDate(
          dateUtils.parseISO(action.payload.query.Start),
        );
      }
      if (action.payload.query.End !== undefined) {
        response.model.EndTime = dateUtils.formatQueryStringDate(
          dateUtils.parseISO(action.payload.query.End),
        );
      }
      // copy query options to the model
      if (action.payload.query.id === undefined) {
        // set reservation's sample run id to requested value so that it will be later sent to the server during reservation insert
        const sampleRunId = tryParseInt(action.payload.query.sampleRunId);
        if (sampleRunId !== undefined) {
          response.model.SampleRunId = sampleRunId;
        }
      }
    }

    yield put(
      actions.initReservation_Success({
        hasErrors: response.ErrorMessages.length > 0,
        model: response.model,
        isChanged: isChange,
        isEdit: response.model.Id > 0,
        globalSettings: action.payload.globalSettings,
        userTrainigData: response.userTrainingsApprovalData,
      }),
    );
    if (equipments.length > 0 && !(response.model.Id > 0)) {
      let allEquipments = [
        ...response.model.EquipmentsData,
        ...((response.model.MandatoriesEquipments as Entity<
          number
        >[]) as IReservationEquipmentDto[]),
      ];
      const parameters: EquipmentsChangeStateParameters = {
        AccServices:
          response.model.AccServices.length > 0
            ? response.model.AccServices.map(f => f.Id)
            : [],
        Equipments: allEquipments.map(f => f.Id),
        ReservationId: (response.model.Id ?? 0) > 0 ? response.model.Id : null,
        Start:
          // preserve requested start time
          dateUtils.tryFormatIso(action.payload.query.Start) ??
          // use the server value if time was not requested
          dateUtils.formatISO(
            dateUtils.dateOrStringToDate(response.model.StartTime),
            { representation: 'complete' },
          ),
        End:
          // preserve requested end time
          dateUtils.tryFormatIso(action.payload.query.End) ??
          // use the server value if time was not requested
          dateUtils.formatISO(
            dateUtils.dateOrStringToDate(response.model.EndTime),
            { representation: 'complete' },
          ),
        BookedBy: response.model.BookedBy?.Id || user?.Id || null,
        Status: response.model.StatusId,
        TrainingSignUp: response.model.TrainingSignUp,
        Tutoring: response.model.Tutoring,
        ADGroup:
          response.model.ADGroup?.Id || user?.ActiveUserGroup?.Id || null,
        FundingTypeId: response.model.FundingTypeId || 0,
        CheckStatus: false,
        TrainingSessionId: response.model.TrainingSession?.Id || null,
        Remarks: response.model.Remarks || null,
        SampleRunId: action.payload.query.srrid,
        BudgetId:
          response.model.Budget?.Id ||
          (isChange &&
            !!action.payload.prevState &&
            !!action.payload.prevState.Budget)
            ? action.payload?.prevState?.Budget?.Id ?? null
            : null,
        CheckSession: false,
        FullDayReservation: response.model.FullDayReservation,
      } as EquipmentsChangeStateParameters;
      yield put(
        actions.initValidationData({
          parameters: parameters,
          warnings: response.WarningMessages,
          errors: response.ErrorMessages,
        }),
      );
    }
  } catch (error: unknown) {
    yield put(actions.initReservation_Error(error));
  }
}
function* doCreate(
  action: PayloadAction<{
    state: ReservationGlobalState;
    calcCredit?: boolean;
  }>,
) {
  const user: AuthenticatedUser | undefined = yield select(
    selectAuthenticatedUser,
  );
  const newDate = yield select(state => selectOffsetDate(state, new Date()));
  const isCloned = yield select(selectCloneReservations);
  const httpPayloads: ReservationInsertModel = ConvertModelToInsertEntity(
    action.payload.state,
    user,
  );
  if (action.payload.calcCredit === true) {
    httpPayloads.Remarks = 'CalculateCredit';
  }
  const customFormFiles = action.payload.state.customFormFiles;
  try {
    const result = yield call(api.insertReservation, httpPayloads);
    let response = result as ReservationDetailsResponseModel;
    let responseErrors = response.ErrorMessages;
    let responseWarnings = response.WarningMessages;
    let responseSuccess = response.SuccessMessages;
    if (responseErrors.length === 0 && !action.payload.calcCredit) {
      if (customFormFiles.length > 0) {
        let data = getFilesModel(
          [],
          customFormFiles,
          FileTypes.ReservationFile,
          response.Id || -1,
          CustomFormTypeEnum.Reservation,
          httpPayloads.RecurringUpdateOption,
          null,
          null,
          null,
        );
        const fileRes = yield call(FileStoreApi.saveFormFiles, data);
        let fileResponse = fileRes as IResponseType;
        if (fileResponse.ErrorMessages.length > 0) {
          responseErrors = [...responseErrors, ...fileResponse.ErrorMessages];
        }
      }
    }
    if (responseErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseErrors
            .filter((value, index, self) => self.indexOf(value) === index)
            .map(item => {
              return {
                key: 'reservationInsertError',
                message: item,
                variant: 'error',
              };
            }),
        ),
      );
      if (isCloned) {
        yield put(actions.setCloneReservation(false));
      }
      if (action.payload.calcCredit) {
        yield put(actions.getCredit_Success({ credit: 0, balance: null }));
      }
    } else {
      if (action.payload.calcCredit) {
        yield put(
          actions.getCredit_Success({
            credit: response.credit ?? 0,
            balance: response.balance,
          }),
        );
      } else {
        if (responseSuccess.length > 0) {
          yield put(
            appSettingsActions.addNotification({
              message: responseSuccess[0],
              variant: 'success',
            }),
          );
          yield put(
            appSettingsActions.addNotification({
              key: 'reservationInsertSuccess',
              message: responseSuccess[0],
              messageType: SnackBarMessageType.openSidepanelDetails,
              messageTypeProps: {
                Id: response.Id ?? undefined,
                created: true,
                itemName: i18next.t(translations.ReservationDetails),
                detailsType: RenderPageType.ReservationDetails,
                detailsTypeProps: {
                  useSidePanel: true,
                  queryParams: {
                    id: '' + response.Id ?? -1,
                  },
                } as ReservationDetailsProps,
              },
              variant: 'success',
            }),
          );
        }
        if (responseWarnings.length > 0) {
          yield put(
            appSettingsActions.addNotifications(
              responseWarnings.map(item => {
                return {
                  key: 'reservationInsertWarning',
                  message: item,
                  variant: 'warning',
                };
              }),
            ),
          );
        }
        if (response.warnOnTrainingSession) {
          let linkUrl = `/trainingsessions?eqid=${response.FirstEquipmentId}&nextonly=true`;
          let linkText = i18next.t(translations.ClickHere) as string;
          yield put(
            appSettingsActions.addNotification({
              key: 'warnOnTrainingSession',
              persist: true,
              message: '',
              messageType: SnackBarMessageType.showLinkMessage,
              messageTypeProps: {
                Id: 0,
                created: true,
                itemName: i18next.t(translations.UpcomingTrainingSessions),
                linkUrl: linkUrl,
                linkText: linkText,
                afterLinkText: i18next.t(
                  translations.res_futureTrainingSession_LinkTitle,
                ),
                detailsType: undefined,
                detailsTypeProps: undefined,
              },
              variant: 'warning',
            }),
          );
        }
        yield put(
          repetitiveActions.resetState(
            dateUtils.formatISO(dateUtils.dateOrStringToDate(newDate)),
          ),
        );
        yield put(layoutActions.setRefreshTable(true));
      }
    }
    if (!action.payload.calcCredit) {
      let hasErrors = responseErrors.length > 0;
      if (!isCloned) {
        yield put(layoutActions.setNotSavedChanges(false));
      }

      yield put(
        actions.createReservation_Success({
          hasErrors: hasErrors,
          reservationIds: response.ReservationIds,
          insertModel: response.insertedModel,
          ByApprovalErrors: response.ByApprovalErrors,
        }),
      );
    }
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'reservationInsert',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createReservation_Error(Error));
  }
}
function* doUpdate(
  action: PayloadAction<{
    current: ReservationDetailsState;
    original: ReservationDetailsState;
    currentCustomFormFiles: IFormFileValue[];
    originalCustomFormFiles: IFormFileValue[];
    offlineServices: IOtherServices[];
    settings: ReservationSettingsState | undefined;
    terminate?: boolean;
  }>,
) {
  const user: AuthenticatedUser | undefined = yield select(
    selectAuthenticatedUser,
  );
  const isCloned = yield select(selectCloneReservations);
  const reservationDto = yield select(selectReservationDtoState);
  const reccurentUpdateOption =
    action.payload.settings?.RecurringUpdateOption || 1;
  const httpPayloads: ReservationUpdateModel = ConvertModelToUpdateEntity(
    action.payload.original,
    action.payload.current,
    action.payload.settings,
    action.payload.offlineServices,
    user,
    action.payload.terminate,
  );
  let filesData: UpdateFilesModel[] = [];
  if (
    action.payload.originalCustomFormFiles.length > 0 ||
    action.payload.currentCustomFormFiles.length > 0
  ) {
    filesData = getFilesModel(
      action.payload.originalCustomFormFiles,
      action.payload.currentCustomFormFiles,
      FileTypes.ReservationFile,
      action.payload.current.Id,
      CustomFormTypeEnum.Reservation,
      reccurentUpdateOption,
      null,
      null,
      null,
    );
  }
  try {
    const results = yield call(api.updateReservation, httpPayloads);
    let response = results as ReservationDetailsResponseModel;
    let respErrors = response.ErrorMessages;
    if (respErrors.length === 0) {
      if (filesData.length > 0) {
        let fileRes = yield call(FileStoreApi.updateFormFiles, filesData);
        let fileResponses = fileRes as IResponseType;
        if (fileResponses.ErrorMessages.length > 0) {
          respErrors = [...respErrors, ...fileResponses.ErrorMessages];
        }
      }
    }
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'reservationUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
      if (isCloned) {
        yield put(actions.setCloneReservation(false));
      }
      if (action.payload.terminate) {
        yield put(actions.terminateReservation_error());
      }
    } else {
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: 'reservationUpdateSuccess',
            message:
              action.payload.terminate === true
                ? i18next.t(translations.Succes_ReservationTerminated)
                : response.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: false,
              itemName: i18next.t(translations.ReservationDetails),
              detailsType: RenderPageType.ReservationDetails,
              itemEndName: action.payload.terminate
                ? ' end time updated'
                : undefined,
              detailsTypeProps: {
                useSidePanel: true,
                queryParams: {
                  id: '' + response.Id,
                },
              } as ReservationDetailsProps,
            },
            variant: 'success',
          }),
        );
        if (action.payload.terminate === true) {
          yield call(api.sendTerminateNotification, response.ReservationIds);
          yield put(actions.terminateReservation_success());
        }
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: 'reservationUpdateWarn',
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      if (response.userTrainingsApprovalData === null) {
        yield put(layoutActions.setRefreshTable(true));
      }
      if (
        action.payload.offlineServices.length > 0 &&
        action.payload.offlineServices.some(f => f.Id < 1)
      ) {
        const offRes = yield call(
          OtherServicesApi.insertConsumableServices,
          action.payload.offlineServices.filter(f => f.Id < 1),
        );
        let offResponse = offRes as ConsumableServicesResponse;
        let offErrors = offResponse.ErrorMessages;
        let offWarnings = offResponse.WarningMessages;
        if (offErrors.length > 0) {
          yield put(
            appSettingsActions.addNotifications(
              offErrors.map(item => {
                return {
                  key: 'serviceInsertError',
                  message: item,
                  variant: 'error',
                };
              }),
            ),
          );
        } else {
          if (offWarnings.length > 0) {
            yield put(
              appSettingsActions.addNotifications(
                offWarnings.map(item => {
                  return {
                    key: 'serviceInsertWarning',
                    message: item,
                    variant: 'warning',
                  };
                }),
              ),
            );
          }
        }
      }
      yield put(layoutActions.setNotSavedChanges(false));
    }
    let hasErrors = response.ErrorMessages.length > 0;

    if (isCloned) {
      yield put(
        actions.initClonedReservation_Success({
          model: reservationDto,
          reservation: action.payload.current,
          settings: action.payload.settings,
        }),
      );
    }
    yield put(
      actions.updateReservation_Success({
        hasErrors: hasErrors,
        userTrainigData: response.userTrainingsApprovalData,
        insertModel: response.insertedModel,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'reservationUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateReservation_Error(Error));
    if (action.payload.terminate) {
      yield put(actions.terminateReservation_error());
    }
  }
}
function* doUpdateSettigs(action: PayloadAction<any>) {
  yield put(actions.updateReservationSettings(action.payload));
}
function* doUpdateEquipmentSettigs(
  action: PayloadAction<{
    equipments: IReservationEquipmentDto[];
    mandatories: IMandatoryEquipmentsDto[];
    accServices: IEquipmentAccServiceDto[];
    globalSettings: GlobalSettingsType;
    settings?: ServiceSettingsState;
  }>,
) {
  let allEquipments = [
    ...action.payload.equipments,
    ...((action.payload.mandatories as Entity<
      number
    >[]) as IReservationEquipmentDto[]),
  ];
  let newSettings = mapSettingsFromServices(
    allEquipments,
    action.payload.accServices,
    action.payload.globalSettings,
    action.payload.settings,
  );
  yield put(actions.updateEquipmentSettingsState(newSettings));
}
function* doLoadValidationData(
  action: PayloadAction<{
    parameters: EquipmentsChangeStateParameters;
    warnings: string[];
    errors: string[];
  }>,
) {
  try {
    let res = yield call(
      api.getEquipmentsChangeState,
      action.payload.parameters,
    );
    let response = res as ReservationEquipmentsChangeResult;
    if (!!response?.CustomForms) {
      if (response?.CustomForms?.IsValid) {
        yield put(
          actions.extendReservationSettings({
            CustomForms: response.CustomForms.CustomForms || [],
          }),
        );
      }
    }
    if (!!response.StatusChange && response.StatusChange !== null) {
      yield put(
        actions.extendReservationSettings({
          IsForceTutoring: response.StatusChange.IsForceTutoring,
        }),
      );
    }
    response.WarningMessages = [
      ...response.WarningMessages,
      ...action.payload.warnings,
    ];
    response.ErrorMessages = [
      ...response.ErrorMessages,
      ...action.payload.errors,
    ];
    yield put(actions.initValidationData_Success(response));
  } catch (error: unknown) {
    yield put(actions.initValidationData_Error());
  }
}
function* doSetStart(action: PayloadAction<Date | string>) {
  yield put(
    actions.setStartSuccess(
      dateUtils.formatQueryStringDate(dateUtils.parseISO(action.payload)),
    ),
  );
}
function* doSetEnd(action: PayloadAction<Date | string>) {
  yield put(
    actions.setEndSuccess(
      dateUtils.formatQueryStringDate(dateUtils.parseISO(action.payload)),
    ),
  );
}
function* doSetAny(
  action: PayloadAction<{
    fieldKey: keyof ReservationDetailsState;
    fieldValue: any;
  }>,
) {
  yield put(actions.setAnyValueSuccess(action.payload));
}
function* doTransferReservation(action: PayloadAction<TransferToReservation>) {
  try {
    let res = yield call(api.transferReservation, action.payload);
    let response = res as IResponseType;
    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.map(item => {
            return {
              key: 'transferError',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
      yield put(actions.transferReservation_Error(response.ErrorMessages[0]));
    } else {
      yield put(
        appSettingsActions.addNotification({
          key: 'transferSuccess',
          message: response.SuccessMessages[0],
          variant: 'success',
        }),
      );
      yield* doSetAny({
        payload: {
          fieldKey: 'WaitingTransferApproval',
          fieldValue: true,
        },
        type: action.type,
      });
      yield* doSetAny({
        payload: {
          fieldKey: 'TransferredTo',
          fieldValue: action.payload.User,
        },
        type: action.type,
      });
      yield put(layoutActions.setNotSavedChanges(false));
      yield put(actions.transferReservation_Success(action.payload));
      yield call(api.sendTransferNotification, action.payload);
    }
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'transferError',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.transferReservation_Error(Error));
  }
}
function* doCancelTransferReservation(
  action: PayloadAction<TransferToReservation>,
) {
  try {
    let res = yield call(
      api.cancelTransferReservation,
      action.payload.ReservtionId,
    );
    let response = res as IResponseType;
    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.map(item => {
            return {
              key: 'transferError',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
      yield put(actions.cancelTransfer_Error(response.ErrorMessages[0]));
    } else {
      yield put(
        appSettingsActions.addNotification({
          key: 'transferSuccess',
          message: response.SuccessMessages[0],
          variant: 'success',
        }),
      );
      yield* doSetAny({
        payload: {
          fieldKey: 'WaitingTransferApproval',
          fieldValue: false,
        },
        type: action.type,
      });
      yield* doSetAny({
        payload: {
          fieldKey: 'TransferredTo',
          fieldValue: null,
        },
        type: action.type,
      });
      yield put(layoutActions.setNotSavedChanges(false));
      yield put(actions.cancelTransfer_Success());
      yield call(api.sendCancelTransferNotification, action.payload);
    }
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'transferError',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.cancelTransfer_Error(Error));
  }
}
function* doTerminateReservation(
  action: PayloadAction<{
    current: ReservationDetailsState;
    original: ReservationDetailsState;
    currentCustomFormFiles: IFormFileValue[];
    originalCustomFormFiles: IFormFileValue[];
    settings: ReservationSettingsState | undefined;
  }>,
) {
  yield* doUpdate({
    payload: {
      current: action.payload.current,
      original: action.payload.original,
      currentCustomFormFiles: action.payload.currentCustomFormFiles,
      originalCustomFormFiles: action.payload.originalCustomFormFiles,
      offlineServices: [],
      settings: action.payload.settings,
      terminate: true,
    },
    type: action.type,
  });
}
export function* reservationSaga() {
  yield takeLeading(actions.initReservation.type, doReservationInint);
  yield takeLatest(actions.createReservation.type, doCreate);
  yield takeLatest(actions.updateReservation.type, doUpdate);
  yield takeLatest(actions.extendReservationSettings.type, doUpdateSettigs);
  yield takeLatest(
    actions.extendEquipmentSettingsState.type,
    doUpdateEquipmentSettigs,
  );
  yield takeLatest(actions.initValidationData.type, doLoadValidationData);
  yield takeLatest(actions.setStartDate.type, doSetStart);
  yield takeLatest(actions.setEndDate.type, doSetEnd);
  yield takeLatest(actions.setAnyValue.type, doSetAny);
  yield takeLatest(actions.transferReservation.type, doTransferReservation);
  yield takeLatest(actions.cancelTransfer.type, doCancelTransferReservation);
  yield takeLatest(actions.terminateReservation.type, doTerminateReservation);
}
