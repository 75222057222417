import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import React from 'react';

export interface ReservationFormButtonProps extends ButtonProps {
  text: string;
  onClick: (e: any) => void;
}

export function SaveFormButton({
  text,
  onClick,
  ...props
}: ReservationFormButtonProps) {
  return (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
}
export function CancelReservationButton({
  text,
  onClick,
  ...props
}: ReservationFormButtonProps) {
  return (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
}
export function ApproveReservationButton({
  text,
  onClick,
  ...props
}: ReservationFormButtonProps) {
  return (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
}
export function SaveForLaterButton({
  text,
  onClick,
  ...props
}: ReservationFormButtonProps) {
  return (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
}
export function TransferButton({
  text,
  onClick,
  ...props
}: ReservationFormButtonProps) {
  return (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
}
export function TerminateReservationButton({
  text,
  onClick,
  ...props
}: ReservationFormButtonProps) {
  return (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
}
export function ModificationButton({
  text,
  onClick,
  ...props
}: ReservationFormButtonProps) {
  return (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
}
export function CreateTrainingButton({
  text,
  onClick,
  ...props
}: ReservationFormButtonProps) {
  return (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
}
