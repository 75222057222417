import { Entity } from 'types/common';

export enum messageType {
  Announcment,
  reservationStarted,
  usageReservation,
  loadDesk,
  removableAnnouncment,
  calendar,
}

export interface StartedReservation {
  Id: number;
  Asset: Entity<number>;
  AllowModification: boolean;
  AllowExtendReservation: boolean;
  AllowUsersToShortenReservations: boolean;
  Tutoring: boolean;
  AllUsagesEnded: boolean;
}

export interface LoanDesk {
  Id: number;
  AssetDetails: Entity<number>;
}

export interface UsageReservation extends Entity<number> {}

export interface CalendarMessageProps {
  Id: number;
  ServiceGroupId: number | null;
  EquipmentId: number | null;
  Title: string | null;
  Body: string | null;
  StartDate: Date | null;
  EndDate: Date | null;
}

type Messageprops =
  | StartedReservation
  | UsageReservation
  | LoanDesk
  | string
  | CalendarMessageProps;

export interface Message {
  type: messageType;
  props?: Messageprops;
  key: string;
}

export interface MessagesState {
  messages: Message[];
  processing?: boolean;
}
