import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { repetitiveActions as actions } from '.';
import { IRepetitiveDto } from 'types/IRepetitiveDto';

function* doSaveState(action: PayloadAction<IRepetitiveDto>) {
  yield put(actions.saveState_success(action.payload));
}
function* doSetRepetitiveValue(
  action: PayloadAction<{
    fieldKey: keyof IRepetitiveDto;
    fieldValue: any;
  }>,
) {
  yield put(actions.setRepetitiveValueSuccess(action.payload));
}
export function* repetitiveSaga() {
  yield takeLatest(actions.saveState.type, doSaveState);
  yield takeLatest(actions.setRepetitiveValue.type, doSetRepetitiveValue);
}
