/**
 *
 * PrivateAnimalRoute
 *
 */
import { selectInternalIdLogin } from 'app/pages/Animals/InternalIdLoginPage/slice/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';
import { useInternalIdLoginSlice } from '../InternalIdLoginPage/slice';

export interface PrivateAnimalRouteProps extends RouteProps {}

export function PrivateAnimalRoute(props: PrivateAnimalRouteProps) {
  const login = useSelector(selectInternalIdLogin);
  const location = useLocation();
  const isAuthenticated = login.identity?.Id !== undefined;
  const isInitialized = login.initialized;
  const dispatch = useDispatch();
  const { actions } = useInternalIdLoginSlice();
  React.useEffect(() => {
    if (!isInitialized) {
      dispatch(actions.init({ isLargeAnimals: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!isInitialized) {
    return <>Inintializing...</>;
  }
  if (isAuthenticated) {
    return <Route {...props} />;
  } else {
    return (
      <Route {...props}>
        <Redirect
          to={`/internalidlogin?return=${encodeURIComponent(
            location.pathname,
          )}`}
        />
      </Route>
    );
  }
}
