/**
 *
 * AssetThumbImage
 *
 */
import * as React from 'react';
import { Avatar, createStyles, makeStyles, Theme } from '@material-ui/core';
import { getAssetImageThumbSrc } from './getAssetImageSrc';
import { useSelector } from 'react-redux';
import { selectImageExist } from 'app/slice/selectors';
import { bookitColors } from 'styles/colors/bookitColors';
// asset thumbs have max size of 64px
const MAX_SIZE_DEFAULT = 64;

export interface AssetThumbImageProps {
  maxSize?: number;
  imageName: string | null;
}

export const defaultImgSrc =
  process.env.PUBLIC_URL + `/images/DefaultImage.png`;

export function AssetThumbImage(props: AssetThumbImageProps) {
  const [hasImgeBroken, setHasImgeBroken] = React.useState<boolean | undefined>(
    undefined,
  );
  const handleImgError = event => {
    setHasImgeBroken(true);
  };
  const imageExist = useSelector(state =>
    selectImageExist(state, props.imageName),
  );
  const classes = useStyles(props);
  const src = imageExist
    ? getAssetImageThumbSrc(props.imageName, 'thumb')
    : null;
  return (
    <div className={classes.root}>
      {src === null || hasImgeBroken ? (
        <Avatar variant="square" className={classes.avatar}>
          {'A'}
        </Avatar>
      ) : (
        <img
          onError={handleImgError}
          src={src}
          alt="asset"
          className={classes.image}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles<Theme, AssetThumbImageProps>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: props =>
        Math.min(props.maxSize ?? MAX_SIZE_DEFAULT, MAX_SIZE_DEFAULT),
      maxHeight: props =>
        Math.min(props.maxSize ?? MAX_SIZE_DEFAULT, MAX_SIZE_DEFAULT),
    },
    image: {
      maxWidth: props =>
        Math.min(props.maxSize ?? MAX_SIZE_DEFAULT, MAX_SIZE_DEFAULT),
      maxHeight: props =>
        Math.min(props.maxSize ?? MAX_SIZE_DEFAULT, MAX_SIZE_DEFAULT),
      borderRadius: '4px',
    },
    avatar: {
      color: theme.palette.getContrastText(bookitColors.grayscale.grayBorders),
      backgroundColor: theme.palette.common.grayDividers,
      width: '32px',
      height: '32px',
      borderRadius: '4px',
    },
  }),
);
