import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { intersection } from 'lodash';
import { Identifiable } from 'types/common';

export const initFilterData = async <TRow>(
  filters: IFilterSettings<TRow>[],
  serviceGroups?: Identifiable<number>[],
): Promise<IFilterSettings<TRow>[]> => {
  var result = Promise.all(
    filters.map(async item => {
      if (item.initData === undefined) {
        return item;
      }
      if (item.value === undefined) {
        return item;
      }
      if (item.value === null) {
        return item;
      }
      if (typeof item.value === 'string') {
        return item;
      }
      if (
        Array.isArray(item.value) &&
        item.value.some(f => f.Name !== undefined)
      ) {
        return item;
      }
      try {
        const data = await item.initData(item.value, serviceGroups);
        if (data !== undefined) {
          return {
            ...item,
            ...{
              value: filterByServiceGroups(
                data,
                serviceGroups?.map(f => f.Id) ?? [],
              ),
            },
          };
        } else {
          return item;
        }
      } catch {
        return item;
      }
    }),
  );
  return result;
};

function filterByServiceGroups(item, serviceGroups: number[]) {
  if (serviceGroups.length === 0) {
    return item;
  }
  if (item.ServiceGroupId !== undefined) {
    if (serviceGroups.includes(item.ServiceGroupId)) {
      return item;
    } else {
      return null;
    }
  } else if (item.ServiceGroups !== undefined) {
    const a = item.ServiceGroups.map(f => f.Id);
    if (item.ServiceGroups.length === 0) {
      return item;
    }
    const intersectsWithServiceGroups =
      intersection(a, serviceGroups).length > 0;
    if (intersectsWithServiceGroups) {
      return item;
    } else {
      return null;
    }
  } else if (Array.isArray(item)) {
    return item
      .map(c => filterByServiceGroups(c, serviceGroups))
      .filter(f => f != null);
  }
  return item;
}
