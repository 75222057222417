import { getSinglePriceSheetMethod } from 'app/components/pickers/StaticOptionsPickers/PriceSheetMethodPicker';
import { AsyncDataState, Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { dateUtils } from 'utils/date-utils';
import { toEntity } from 'utils/entity-utils';

/* --- STATE --- */
export interface PricePeriodState {
  detailsState: AsyncDataState<PricePeriodsDetailsState>;
  processing: boolean;
}

export interface IPriceSheetPeriodDto {
  Id: number;
  ServiceGroupId: number | null;
  ServiceGroupName: string | null;
  StartDate: Date | string;
  EndDate: Date | string | null;
  Method: number | null;
  PriceSheetLink: string | null;
}

export interface PricePeriodQueryParams {
  id?: string;
  groupId?: string;
  start?: string;
  end?: string;
  method?: string;
}
export interface PricePeriodsDetailsState {
  Id: number;
  ServiceGroup: Entity<number> | null;
  StartDate: Date | string;
  EndDate: Date | string | null;
  Method: Entity<number> | null;
}
export interface PricePeriodsResponse extends IResponseType {
  Id: number | null;
  data: IPriceSheetPeriodDto | null;
  IsValid: boolean;
}
export const ConvertDtoToModel = (
  entity: IPriceSheetPeriodDto,
): PricePeriodsDetailsState => {
  let model = {
    Id: entity.Id,
    ServiceGroup:
      entity.ServiceGroupId !== null
        ? toEntity(entity.ServiceGroupId, entity.ServiceGroupName)
        : null,
    StartDate: dateUtils.formatQueryStringDate(
      dateUtils.parseISO(entity.StartDate),
    ),
    EndDate:
      entity.EndDate === null
        ? null
        : dateUtils.formatQueryStringDate(dateUtils.parseISO(entity.EndDate)),
    Method:
      entity.Method !== null
        ? getSinglePriceSheetMethod(entity.Method ?? 0)
        : null,
  } as PricePeriodsDetailsState;
  return model;
};

export const ConvertModelToInsertEntity = (model: PricePeriodsDetailsState) => {
  let data = {
    Id: model.Id,
    ServiceGroupId: model.ServiceGroup?.Id || null,
    ServiceGroupName: model.ServiceGroup?.Name,
    StartDate: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(model.StartDate),
    ),
    EndDate:
      model.EndDate === null
        ? null
        : dateUtils.formatISO(dateUtils.dateOrStringToDate(model.EndDate)),
    Method: model.Method?.Id,
  } as IPriceSheetPeriodDto;
  return data;
};
export const ConvertModelToUpdateEntity = (
  model: PricePeriodsDetailsState,
  original: PricePeriodsDetailsState,
) => {
  let data = {
    Id: original.Id,
    ServiceGroupId: model.ServiceGroup?.Id || null,
    ServiceGroupName: model.ServiceGroup?.Name,
    StartDate: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(model.StartDate),
    ),
    EndDate:
      model.EndDate === null
        ? null
        : dateUtils.formatISO(dateUtils.dateOrStringToDate(model.EndDate)),
    Method: model.Method?.Id,
  } as IPriceSheetPeriodDto;
  return data;
};
export const getDefaultPricePeriod = () => {
  return {
    Id: 0,
    ServiceGroupId: null,
    ServiceGroupName: null,
    StartDate: dateUtils.formatISO(new Date()),
    EndDate: null,
    Method: null,
  } as IPriceSheetPeriodDto;
};
