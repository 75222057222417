import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { AddWorkOrder } from './AddWorkOrder';

export interface DownWorkOrderProps<TRow> {
  rows: TRow[];
  getAssets: (rows: TRow[]) => Promise<IAssetDto[]>;
  buttonName?: string;
}

export function DownWorkOrder<TRow>({
  rows,
  getAssets,
  buttonName,
}: DownWorkOrderProps<TRow>) {
  return (
    <AddWorkOrder
      rows={rows}
      getAssets={getAssets}
      buttonName={buttonName}
      downtime={true}
    />
  );
}
