import { H3 } from 'app/components/Typography';
import { H4 } from 'app/components/Typography';
import { Box } from 'app/components/basic/Wrappers/Box';
import { FlexRowDiv } from 'app/components/basic/Wrappers/FlexWrappers/flexRow';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';

export interface NoTableDataProps {
  id?: string;
}
export function NoTableData(props: NoTableDataProps) {
  const { t } = useTranslation();
  return (
    <FlexRowDiv id={props.id}>
      <Box textAlign="center" style={{ paddingBlock: '48px' }}>
        <H3>{t(translations.dash_EmptyWidgetTitle) as string}</H3>
        <H4>{t(translations.dash_EmptyWidgetBody) as string}</H4>
      </Box>
    </FlexRowDiv>
  );
}
