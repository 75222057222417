import * as DHTMLX from 'dhtmlx-scheduler';
import { ISchedulerEvent } from '../types/ISchedulerEvent';

/**
 * Retrieves scheduler event by it's id
 * @param scheduler
 * @param id
 * @returns
 */
export function getEvent(
  scheduler: DHTMLX.SchedulerStatic | undefined,
  id: string,
): ISchedulerEvent {
  return scheduler?.getEvent(id);
}
