import { IInvoiceBudgetBalanceDto } from 'api/odata/generated/entities/IInvoiceBudgetBalanceDto';
import { ODataOperators } from 'api/odata/ODataFilter';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { translations } from 'locales/translations';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import {
  GetBooleanEntity,
  GetBooleanOrUndefined,
  getNumberEntityArray,
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  GetStringOrUndefined,
  initPickerValue,
  URLSearchParamsCI,
  arrayToQueryString,
  initMultiExPickerValue,
} from 'app/components/BasicTable/types/FilterParam';
import { initProjectData } from 'app/components/pickers/AutocompletePickers/ProjectPicker';
import BooleanPickerFilter from 'app/components/BasicTable/Filters/BooleanPickerFilter';
import { initInstituteData } from 'app/components/pickers/AutocompletePickers/InstitutePicker';
import InstituteFilter from 'app/components/BasicTable/Filters/InstituteFilter';
import InstituteTypeFilter from 'app/components/BasicTable/Filters/InstituteTypeFilter';
import { initInstituteTypeData } from 'app/components/pickers/AutocompletePickers/InstituteTypePicker';
import DepartmentFilter from 'app/components/BasicTable/Filters/DepartmentFilter';
import { initDepartmentData } from 'app/components/pickers/AutocompletePickers/DepartmentPicker';
import { initUserGroupData } from 'app/components/pickers/AutocompletePickers/UserGroupPicker';
import UserGroupFilter from 'app/components/BasicTable/Filters/UserGroupFIlter';
import { initServiceGroupsMultiData } from 'app/components/pickers/MultiSelectPickers/ServiceGroupsPicker';
import { ServiceGroupsFilter } from 'app/components/BasicTable/Filters/ServiceGroupsFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { BudgetByUserGroupFilter } from 'app/components/pickers/AutocompletePickers/BudgetPicker';
import { ProjectFilter } from 'app/components/BasicTable/Filters/ProjectFilter';

export const GetFilters: GetPageFilters<IInvoiceBudgetBalanceDto> = (
  t,
  appSettings,
  user,
  settings,
) => search => {
  const params = new URLSearchParamsCI(search);
  const result: IFilterSettings<IInvoiceBudgetBalanceDto>[] = [];
  // Project
  if (IsModuleEnabled(appSettings, KnownModules.Budgets)) {
    result.push({
      fieldName: 'BudgetId',
      displayName: t(translations.BudgetNumber),
      operator: ODataOperators.Equals,
      // value: getDefaultFilterValue('bid', params),
      queryStringSerializer: 'bid',
      initData: initPickerValue(initProjectData),
      value: GetPartialEntity(GetNumberOrUndefined(GetRawValue(params, 'bid'))),
      component: ProjectFilter,
      dependencyProp: ['UserGroupId'],
      filterPredicates: BudgetByUserGroupFilter,
    });
  }
  // Online/ offline projects: (online only/ offline only/ All= default)
  result.push({
    fieldName: 'BudgetOnline',
    displayName: t(translations.BudgetIsOnline),
    operator: ODataOperators.Equals,
    queryStringSerializer: 'BudgetIsOnline',
    value: GetBooleanEntity(
      GetBooleanOrUndefined(GetRawValue(params, 'BudgetIsOnline')),
      t,
    ),
    component: BooleanPickerFilter,
  });
  // Has Balance/ Estimated charges (yes/no/ all, default= all) - is this generating a performance issue (same question as above)
  result.push({
    fieldName: 'HasBalanceOrEstimatedCharges',
    displayName: t(translations.HasBalanceOrEstimatedCharges),
    operator: ODataOperators.Equals,
    queryStringSerializer: 'HasBalanceOrEstimatedCharges',
    value: GetBooleanEntity(
      GetBooleanOrUndefined(
        GetRawValue(params, 'HasBalanceOrEstimatedCharges'),
      ),
      t,
    ),
    component: BooleanPickerFilter,
  });
  // User group- as per the project user group.
  result.push({
    fieldName: 'UserGroupId',
    displayName: t(translations.UserGroup),
    operator: ODataOperators.Equals,
    value: GetPartialEntity(GetStringOrUndefined(GetRawValue(params, 'adg'))),
    component: UserGroupFilter,
    queryStringSerializer: 'adg',
    initData: initPickerValue(initUserGroupData),
  });
  // Department- as per the project user group.
  result.push({
    id: 'DepartmentId',
    fieldName: 'DepartmentId',
    queryStringSerializer: 'DepartmentId',
    displayName: t(translations.Department),
    operator: ODataOperators.Equals,
    value: GetPartialEntity(
      GetNumberOrUndefined(GetRawValue(params, 'DepartmentId')),
    ),
    initData: initPickerValue(initDepartmentData),
    component: DepartmentFilter,
  });
  // Institute- as per the project user group.
  result.push({
    fieldName: 'InstituteId', // connected to Company
    displayName: t(translations.Company),
    operator: ODataOperators.Equals,
    queryStringSerializer: 'InstituteId',
    value: GetPartialEntity(
      GetNumberOrUndefined(GetRawValue(params, 'InstituteId')),
    ),
    initData: initPickerValue(initInstituteData),
    component: InstituteFilter,
  });
  // Institute Type -as per the project user group.

  result.push({
    fieldName: 'InstituteTypeId', // Company Type
    displayName: t(translations.InstituteType),
    operator: ODataOperators.Equals,
    queryStringSerializer: 'InstituteTypeId',
    value: GetPartialEntity(
      GetNumberOrUndefined(GetRawValue(params, 'InstituteTypeId')),
    ),
    initData: initPickerValue(initInstituteTypeData),
    component: InstituteTypeFilter,
  });
  // Only Active (yes/ no, default= yes)
  result.push({
    fieldName: 'BudgetActive',
    displayName: t(translations.Active),
    operator: ODataOperators.Equals,
    queryStringSerializer: 'Active',
    value: GetBooleanEntity(
      GetBooleanOrUndefined(GetRawValue(params, 'Active')),
      t,
    ),
    component: BooleanPickerFilter,
  });
  result.push({
    fieldName: 'ServiceGroupId',
    displayName: t(translations.ServiceGroups),
    operator: ODataOperators.Includes,
    queryStringSerializer: arrayToQueryString('ServiceGroupId'),
    value: getNumberEntityArray(params, 'ServiceGroupId'),
    initData: initMultiExPickerValue(initServiceGroupsMultiData),
    component: ServiceGroupsFilter,
    urlKey: 'ServiceGroupId',
  });
  return result;
};
