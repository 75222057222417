// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Entity, Identifiable } from 'types/common';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { INamedEntity } from 'types/common';
import { IAssetAvailabilityDto } from './IAssetAvailabilityDto';
import { IServiceFilterDto } from './IServiceFilterDto';
import { IServiceTypeFilterDto } from './IServiceTypeFilterDto';

/**
 *
 * auto generated interface for bkt_webApp.Api.OData.V4.Dto.AssetDto
 *
 */
export interface IAssetDto extends Entity<number> {
  Id: number;
  Name: string;
  Active?: boolean;
  Availability?: IAssetAvailabilityDto;
  ServiceId?: number | null;
  ServiceTypeId?: number | null;
  ServiceGroupId?: number | null;
  ServiceGroupName?: string | null;
  Image?: string;
  AssetCatId?: number | null;
  PrerequisiteFor?: number | null;
  BookableAsset?: boolean;
  ServiceGroupAssetId?: number | null;
  Color?: string;
}
export const mapAssetsToServiceFilterDto = (
  assets: IAssetDto[],
): IServiceFilterDto[] => {
  return assets.map(a => {
    return {
      Id: a.ServiceId,
      Name: a.Name,
      ServiceGroupId: a.ServiceGroupId,
      ServiceTypeId: a.ServiceTypeId,
    } as IServiceFilterDto;
  });
};
export const mapAssetsToServiceTypeFilterDto = (
  assets: IAssetDto[],
): IServiceTypeFilterDto[] => {
  return assets.map(a => {
    return {
      Id: a.ServiceId ?? 0,
      Name: a.Name,
      ServiceGroupId: a.ServiceGroupId ?? 0,
      ServiceTypeId: a.ServiceTypeId ?? 1,
    } as IServiceTypeFilterDto;
  });
};
