import { Menu, MenuProps } from '@material-ui/core';
import { ReactNode, useState } from 'react';
import { Button, ButtonProps } from '../BasicButtons/Button';
import { Icon } from '../BasicIcons/FontAwesome';
import { Tooltip } from '../BasicTooltips/Tooltip';

export interface DropDownProps extends ButtonProps {
  menuChildren: (onClose: () => void) => ReactNode | ReactNode[];
  addChevron?: boolean;
  menuProps?: Omit<MenuProps, 'children' | 'anchorEl' | 'open'>;
  id?: string;
  onOpen?: () => void;
  onHandleClose?: () => void;
  disablePortal?: boolean;
}

export const DropDown = ({
  onClick,
  onMouseDown,
  children,
  menuChildren,
  menuProps,
  addChevron,
  id,
  title,
  onOpen,
  onHandleClose,
  disablePortal,
  ...other
}: DropDownProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { marginThreshold, anchorOrigin, onClose, ...rest } = menuProps ?? {};
  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
    setOpen(!open);
    if (!!onOpen) {
      onOpen();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    if (onHandleClose) {
      onHandleClose();
    }
  };
  return (
    <>
      {title ? (
        <Tooltip title={title}>
          <span>
            <Button
              aria-controls={id || 'custom-menu-button'}
              onClick={e => {
                handleOpen(e);
                onClick?.(e);
              }}
              onMouseDown={e => {
                handleOpen(e);
                onMouseDown?.(e);
              }}
              aria-haspopup="true"
              endIcon={
                addChevron ? (
                  <Icon icon={open ? 'chevron-up' : 'chevron-down'} />
                ) : undefined
              }
              {...other}
            >
              {children}
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button
          aria-controls={id || 'custom-menu-button'}
          onClick={e => {
            handleOpen(e);
            onClick?.(e);
          }}
          onMouseDown={e => {
            handleOpen(e);
            onMouseDown?.(e);
          }}
          aria-haspopup="true"
          endIcon={
            addChevron ? (
              <Icon icon={open ? 'chevron-up' : 'chevron-down'} />
            ) : undefined
          }
          {...other}
        >
          {children}
        </Button>
      )}
      <Menu
        id={id || 'custom-menu-button'}
        marginThreshold={marginThreshold ?? 1}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        MenuListProps={{ dense: true }}
        open={Boolean(anchorEl)}
        container={document.fullscreenElement ?? document.body}
        disablePortal={disablePortal}
        onClose={(e, r) => {
          handleClose();
          onClose && onClose(e, r);
        }}
        anchorOrigin={
          anchorOrigin ?? {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        {...rest}
      >
        {open && menuChildren(handleClose)}
      </Menu>
    </>
  );
};
