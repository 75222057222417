import * as React from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { useIsRequired } from './ValidationSchemaProvider';
import { FileInputProps } from '../BasicInputs/FileUpload/FileInput';
import { IFormFileValue } from '../CustomForm/CustomFormUtils';
import { FileUpload } from '../BasicInputs/FileUpload/FileUpload';

export const FormInputFile = ({
  id,
  label,
  onChange,
  value,
  name,
  title,
  ...props
}: FieldHookConfig<IFormFileValue[]> & IFieldProps & FileInputProps) => {
  const [field, meta, helper] = useField<IFormFileValue[]>({
    name,
    ...props,
  });
  const handleChange = (files: IFormFileValue[]) => {
    helper.setValue(files);
    //const files = event.target.files;
    //console.log('value', event.target);
    console.debug('files', files);
    onChange?.(files);
    // if (onChange !== undefined) {
    //   onChange(files, event);
    // }
    // field.onChange(event);
  };
  const { submitCount } = useFormikContext();
  const isRequired = useIsRequired(name);
  const hasError = meta?.error !== undefined;
  const showError = hasError || submitCount > 0;
  return (
    <FileUpload
      fileInputProps={{
        id: id,
        label: label,
        name: name,
        onBlur: field?.onBlur,
        error: showError,
        required: isRequired && hasError,
        errorMesage: meta?.error,
        ...props,
      }}
      values={field.value}
      multiple={props.multiple}
      showPreview={props.showPreview}
      saveFiles={handleChange}
    />
  );
};
