import { httpClient } from 'api/HttpClient';
import { EntityType, INamedEntity } from 'types/common';
import {
  Condition,
  escapeODataValue,
  ODataFilterBuilder,
} from 'api/odata/ODataFilter';
import { translations } from 'locales/translations';
import { TFunction } from 'i18next';
import { uniq } from 'lodash';

/**
 * Sets maximum limit of rows to fetch from the server for autocomplete picker.
 * Fetching all at once significantly slows down UI on slow connections.
 */
const MAX_TOP = 200;

interface loadDataParams<TEntity extends EntityType> {
  url: string;
  idPropertyName?: keyof TEntity;
  DisplaynamePropertyName?: keyof TEntity;
  predicates?: (string | Condition<TEntity>)[];
  select?: Array<keyof TEntity>;
  globalServiceGroupFilter?: Condition<TEntity>[];
  expand?: string;
  isOptionalServiceGroup?: boolean;
  transformModel?: (item: TEntity) => TEntity;
  useSearchOrPredicate?: boolean;
  queryString?: Record<string, any>;
  maxTop?: number;
}
export interface autocompleteLoadDataResult<TEntity extends EntityType> {
  value: TEntity[];
  more: boolean;
  limit?: number;
}
export const getAutoCompleteLoadDataFn = <TEntity extends EntityType>({
  url,
  idPropertyName = 'Id',
  DisplaynamePropertyName = 'Name',
  select = [idPropertyName, DisplaynamePropertyName],
  predicates: basePredicates = [],
  globalServiceGroupFilter = [],
  isOptionalServiceGroup = undefined,
  expand = undefined,
  transformModel = undefined,
  useSearchOrPredicate = undefined,
  queryString,
  maxTop,
}: loadDataParams<TEntity>) => async (
  searchTerm: string | null,
  dynamicPredicates?: (string | Condition<TEntity>)[],
) => {
  const params = {
    ...queryString,
    ...{
      $orderby: `${String(DisplaynamePropertyName)} asc`,
      $select: uniq(select).join(','),
      $top: (maxTop ?? MAX_TOP) + 1,
      $filter:
        useSearchOrPredicate && searchTerm !== null
          ? `(${String(DisplaynamePropertyName)} eq ${
              "'" + escapeODataValue(searchTerm) + "'"
            })`
          : new ODataFilterBuilder<TEntity>({
              predicates: dynamicPredicates
                ? [...(dynamicPredicates ?? [])]
                : basePredicates,
              stringColumns: [DisplaynamePropertyName],
              stringSearch: searchTerm ?? undefined,
              globalServiceGroupFilter: globalServiceGroupFilter,
              isOptionalServiceGroup: isOptionalServiceGroup,
            }).toString(),
      $expand: expand,
    },
  };

  const response = await httpClient.get(url, params);
  const value = !!transformModel
    ? (response.value as TEntity[]).map(f => transformModel(f))
    : (response.value as TEntity[]);

  return {
    value: value.slice(0, maxTop ?? MAX_TOP),
    more: value.length > (maxTop ?? MAX_TOP),
    limit: maxTop ?? MAX_TOP,
  };
};
export const extractDataFromResponse = <T extends EntityType>(
  response: T[] | autocompleteLoadDataResult<T>,
) => {
  if (Array.isArray(response)) {
    return { value: response, more: false };
  } else {
    return response as autocompleteLoadDataResult<T>;
  }
};

export const loadDataAndPrepareOptions = <T extends EntityType>({
  t,
  response,
  additionalItem,
}: {
  t: TFunction;
  response: autocompleteLoadDataResult<T>;
  additionalItem?: T | null;
}) => {
  const value = response.value;
  if (additionalItem !== null && additionalItem !== undefined) {
    value.unshift(additionalItem);
  }
  const nonSelectableOptions: NonSelectableOption[] = response.more
    ? [
        {
          disabled: true,
          Name: t(translations.ShowingFirstXRecords, {
            maxRecordsLimit: MAX_TOP,
          }),
        },
      ]
    : [];
  return [...value, ...nonSelectableOptions];
};

export interface IDisableable {
  disabled: boolean;
}
export interface IRemoveable {
  noremoved: boolean;
}
export interface IInfoOption {
  titleInfo?: string;
}
export interface IColored {
  Color?: string;
}

export type NonSelectableOption = INamedEntity & IDisableable;
export type NonRemoveableOption = INamedEntity & IRemoveable;
export type InfoOption = INamedEntity & IInfoOption;
export type ColoredOption = INamedEntity &
  NonSelectableOption &
  NonRemoveableOption &
  InfoOption &
  IColored;
export type OptionType<T> =
  | T
  | NonSelectableOption
  | NonRemoveableOption
  | InfoOption
  | ColoredOption;
