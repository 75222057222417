/**
 *
 * Asynchronously loads the component for CategoryGroupsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CategoryGroupsPage = lazyLoad(
  () => import('./index'),
  module => module.CategoryGroupsPage,
);
