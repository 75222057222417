import { Box, makeStyles, Theme } from '@material-ui/core';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../BasicIcons/FontAwesome';
import { Body } from '../Typography';

export interface ScannerViewProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '144px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
    gap: 8,
    background: theme.palette.common.white,
    border: '1px solid #026AA2',
    boxShadow: '0px 0px 0px 4px #E2F6FF',
    borderRadius: 8,
  },
  iconScann: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: 8.75,
  },
  selectRoot: {
    padding: theme.spacing(2),
  },
  selectTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 8px',
    justifyContent: 'space-between',
    width: '100%',
    '&>label': {
      width: '100%',
      textAlign: 'center',
    },
  },
}));
export function ScannerView(props: ScannerViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <Box className={classes.iconScann}>
        <Icon icon="barcode" size="3x" />
      </Box>
      <Body color="secondary">
        {t(translations.BarcodeScannViewDescription)}
      </Body>
    </Box>
  );
}
