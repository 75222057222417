/**
 *
 * Asynchronously loads the component for InternalIdLoginPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const InternalIdLoginPage = lazyLoad(
  () => import('./index'),
  module => module.InternalIdLoginPage,
);
