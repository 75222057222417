/**
 *
 * PriorityPicker
 *
 */

import {
  PrioritiesUnion,
  PrioritiesUnionArray,
} from 'api/odata/generated/enums/Priorities';
import {
  EnumPicker,
  withEnumOptions,
} from 'app/components/BasicPickers/EnumPicker';

export const PriorityPicker = withEnumOptions<PrioritiesUnion>(
  PrioritiesUnionArray,
)(EnumPicker);
