/**
 *
 * LoanablePicker
 *
 */
import { AutocompletePickerProps } from 'app/components/BasicPickers/AutocompletePicker';
import { BaseOptionsPicker } from 'app/components/BasicPickers/BaseOptionsPicker';
import * as React from 'react';
import { Entity } from 'types/common';

export const LoanTypeOptions = [
  {
    Id: 1,
    Name: 'Yes',
  },
  {
    Id: 2,
    Name: 'No',
  },
];

export interface LoanablePickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'> {}

export const getSingleLoanableType = (val: number): Entity<number> => {
  return LoanTypeOptions.filter(item => {
    return item.Id === val;
  })[0];
};

export const initLoanableData = (
  initval: number | string | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve(
        LoanTypeOptions.filter(item => {
          return item.Id.toString() === initval;
        }),
      );
    });
  }
};

const getOptions = (searchTerm: string | null) => {
  let items = LoanTypeOptions;
  if (searchTerm !== null && searchTerm !== '') {
    return items.filter(
      f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
    );
  }
  return items;
};

export function LoanablePicker(props: LoanablePickerProps) {
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      value={props.value}
      {...props}
    />
  );
}
