import { PrintPDFItem } from '../slice/types';
import { PopupPrintToPDFDialog } from './Dialog';
import { PopupPrintToPDFComponent } from './PopupPrintToPDFComponent';

export interface PrintToPDFPopupProps {
  title: string;
  show: boolean;
  processing?: boolean;
  preparing?: boolean;
  disabled?: boolean;
  items: PrintPDFItem[];
  fullWidth?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}
export function PrintToPDFPopup({
  title,
  show,
  items,
  processing,
  preparing,
  disabled,
  fullWidth,
  onClose,
  onSubmit,
}: PrintToPDFPopupProps) {
  return (
    <>
      <PopupPrintToPDFDialog
        open={show}
        padding={'32px'}
        fullWidth={fullWidth === undefined ? true : fullWidth}
        content={
          <PopupPrintToPDFComponent
            title={title}
            items={items}
            processing={processing}
            preparing={preparing}
            disabled={disabled}
            close={onClose}
            onSubmit={onSubmit}
          />
        }
      />
    </>
  );
}
