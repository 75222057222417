import * as React from 'react';
import { LinearProgress } from '@material-ui/core';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Body } from 'app/components/Typography';
import { TitlePage } from './TitlePage';
import { Skeleton } from '@material-ui/lab';
import { ReactActions } from 'app/components/ReactActions';
import { ActionRenderer } from '../PageActions/ActionRender';
import {
  SwitchActions,
  SwitchActionsProps,
} from '../PageActions/SwitchActions';
import { SkeletonIfLoading } from './SkeletonIfLoading';
import { useTheme } from '@material-ui/core';
import styled from 'styled-components';
import clsx from 'clsx';

export interface HeadProps {
  closable: boolean | undefined;
  useSidePanel: boolean;
  closePanel: () => void;
  pageName: React.ReactNode;
  titlePage: string | undefined;
  pageLink: string | undefined;
  titleTooltip: string | undefined;
  isMobile: boolean;
  disableExpandToggle: boolean | undefined;
  expandPanel: () => void;
  subTitlePage?: React.ReactNode;
  leftTopActions?: ActionRenderer[];
  rightTopActions?: ActionRenderer[];
  onSubmit?: () => void;
  switchState?: SwitchActionsProps;
  useSwithActions?: boolean;
  showProcessing?: boolean;
  loading: boolean;
  isCover?: boolean;
}
const TitleRoot = styled('div')(({ theme }) => ({
  '&.page-wrapper-title-root': {
    padding: theme.spacing(4, 4, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    '.topIcons': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      alignSelf: 'end',
      padding: 0,
      gap: theme.spacing(1.25),
      '.topRightIcons': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        position: 'absolute',
        padding: 0,
        gap: '12px',
        top: theme.spacing(2),
      },
    },
    '.titleContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      padding: theme.spacing(0, 0, 0),
      width: '100%',
      gap: theme.spacing(4.5),
      '.titleSubtitle': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 0,
      },
      '.rightControls': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: 0,
        gap: theme.spacing(1.5),
      },
    },
  },
}));
export function Head(props: HeadProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    closable,
    useSidePanel,
    closePanel,
    pageName,
    titlePage,
    pageLink,
    titleTooltip,
    isMobile,
    disableExpandToggle,
    expandPanel,
    subTitlePage,
    leftTopActions,
    rightTopActions,
    onSubmit,
    showProcessing,
    loading,
    useSwithActions,
    switchState,
    isCover,
  } = props;
  return (
    <SkeletonIfLoading loading={loading}>
      <TitleRoot
        theme={theme}
        className={clsx('page-wrapper-title-root', { cover: isCover })}
      >
        <div className={'topIcons'}>
          <div className={'topRightIcons'}>
            {closable && useSidePanel ? (
              <React.Fragment>
                <IconButton
                  variant="ghost"
                  aria-label="close"
                  size="small"
                  shape="square"
                  title={t(translations.CloseSidePanel)}
                  onClick={closePanel}
                >
                  <Icon
                    icon="times"
                    size="lg"
                    color="default"
                    colorExtend="textHover"
                  />
                </IconButton>
              </React.Fragment>
            ) : null}
          </div>
        </div>
        <div className={'titleContainer'}>
          <div className={'titleSubtitle'}>
            {pageName && (
              <Body bold={true} color="secondary">
                {pageName}
              </Body>
            )}
            {titlePage ? (
              <TitlePage
                title={titlePage}
                pageLink={pageLink}
                useSidePanel={useSidePanel}
                buttonsLenth={0}
                tooltip={titleTooltip}
                expandPanel={
                  !isMobile && useSidePanel && !disableExpandToggle
                    ? expandPanel
                    : undefined
                }
              />
            ) : (
              <Skeleton variant="text" width="20ch" />
            )}
            {subTitlePage && <Body size="small">{subTitlePage}</Body>}
          </div>
          <div className={'rightControls'}>
            <>
              {!!leftTopActions &&
                leftTopActions?.length > 0 &&
                leftTopActions?.map((item, index) => (
                  <span key={index}>{item(onSubmit)}</span>
                ))}
              {!!rightTopActions && rightTopActions?.length > 0 && (
                <React.Fragment>
                  <span key="firstAct">{rightTopActions[0](onSubmit)}</span>
                  <ReactActions
                    id={`sidepanel_top_actions`}
                    size="small"
                    items={rightTopActions
                      .filter((ot, ind) => ind > 0)
                      .map((item, index) => (
                        <span key={index}>{item(onSubmit)}</span>
                      ))}
                  ></ReactActions>
                </React.Fragment>
              )}
              {useSwithActions && switchState && (
                <React.Fragment>
                  <SwitchActions
                    target={switchState.target}
                    getProps={switchState.getProps}
                  />
                </React.Fragment>
              )}
            </>
          </div>
        </div>
        {showProcessing && (
          <div style={{ width: '100%' }}>
            <LinearProgress variant="query" color="primary" />
          </div>
        )}
      </TitleRoot>
    </SkeletonIfLoading>
  );
}
