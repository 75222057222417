import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.connectedFilters || initialState;

export const selectConnectedFilters = createSelector(
  [selectSlice],
  state => state,
);

export const selectConnectedFiltersProcessing = createSelector(
  [selectConnectedFilters],
  state => state.processing,
);

export const selectConnectedFiltersError = createSelector(
  [selectConnectedFilters],
  state => state.error,
);

export const selectConnectedFiltersData = createSelector(
  [selectConnectedFilters],
  state => state.data,
);
export const selectConnectedFieldHandler = createSelector(
  [selectConnectedFilters],
  state => state.connectedValueHandler,
);
export const selectConnectedSettings = createSelector(
  [selectConnectedFilters],
  state => state.connectedSettings,
);
