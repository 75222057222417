import { InputBaseComponentProps } from '@material-ui/core';
import { httpClient } from 'api/HttpClient';

import {
  AutocompleteMultiPickerEx,
  AutocompleteMultiPickerExProps,
} from 'app/components/BasicPickers/AutocompleteMultiPickerEx';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import * as React from 'react';
import { Entity } from 'types/common';

export interface MyAssetAvailabilityTypeMultiPickerProps
  extends Omit<AutocompleteMultiPickerExProps<Entity<number>>, 'loadData'> {
  inputProps?: InputBaseComponentProps;
  urlType?: 'allServices' | 'onlineServices';
}

const url = '/api/odata/v4/AvailabilityTypesFilter/GetMyAsset';
const onlineUrl = '/api/odata/v4/AvailabilityTypesFilter/GetOnlineMyAsset';
export const initMyAssetAvailabilityTypeMultiData = (
  initval: string | undefined,
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const ids = initval.split('|').map(v => parseInt(v));
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id in (' + ids.join(',') + '))',
      $select: 'Id,Name',
      $skip: 0,
      $top: ids.length,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
export const initMyAssetAvailabilityTypeOnlineMultiData = (
  initval: string | undefined,
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const ids = initval.split('|').map(v => parseInt(v));
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id in (' + ids.join(',') + '))',
      $select: 'Id,Name',
      $skip: 0,
      $top: ids.length,
    };
    return httpClient
      .get(onlineUrl, params)
      .then(response => response.value as Entity<number>[]);
  }
};
export function MyAssetAvailabilityTypeMultiPicker(
  props: MyAssetAvailabilityTypeMultiPickerProps,
) {
  const loadData = getAutoCompleteLoadDataFn<Entity<number>>({
    url:
      !!props.urlType && props.urlType === 'onlineServices' ? onlineUrl : url,
    predicates: props.predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: ['Id', 'Name'],
  });

  return (
    <AutocompleteMultiPickerEx
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={loadData}
      ariaLabel="Availability Type search"
      id={props.id || 'AvailabilityId'}
      {...props}
    />
  );
}
