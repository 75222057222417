import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectPrintPDFSections } from './slice/selectors';
import { ServiceRequestSectionTypes } from './ServiceRequestSectionTypes';

export interface PrintPDFSectionProps {
  print: boolean;
  id: ServiceRequestSectionTypes;
}
export function PrintPDFSection(
  props: React.PropsWithChildren<PrintPDFSectionProps>,
) {
  const printPDFSections = useSelector(selectPrintPDFSections);
  const componentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (componentRef.current !== null) {
      if (props.print) {
        const checked = printPDFSections?.some(
          f => f.id === props.id && f.checked === true,
        );
        if (checked) {
          componentRef.current.style.display = 'block';
        } else {
          componentRef.current.style.display = 'none';
        }
      } else {
        componentRef.current.style.display = 'initial';
      }
    }
  }, [printPDFSections, props.id, props.print]);
  return (
    <>
      <div ref={componentRef}>{props.children}</div>
    </>
  );
}
