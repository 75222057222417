import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { getLogger } from 'loglevel';
import ReactDOMServer from 'react-dom/server';
import { ISchedulerEvent } from '../../types/ISchedulerEvent';
import { EventTimelineContent } from '../EventRender/EventTimelineContent';

const log = getLogger('Scheduler - event_text');
export function event_text(
  viewType: 'calendar' | 'timeline' | 'unit',
  isMobile: boolean,
  SchedulerReservationBodyTemplate?: Handlebars.TemplateDelegate<
    ICalendarReservationDto
  >,
): (start: Date, end: Date, event: ISchedulerEvent) => string {
  return function (start: Date, end: Date, event: ISchedulerEvent) {
    try {
      switch (viewType) {
        case 'timeline':
          const body =
            event.type === 'reservation'
              ? SchedulerReservationBodyTemplate?.(
                  event.original as ICalendarReservationDto,
                ) ?? ''
              : undefined;
          const result = timeline_event_text({
            viewType,
            isMobile,
            body,
            event,
          });
          return result;
        case 'calendar':
        case 'unit':
          try {
            switch (event.type) {
              case 'reservation':
                const reservation = event.original as ICalendarReservationDto;
                const r = SchedulerReservationBodyTemplate?.(reservation) ?? '';
                return r;
              default:
                return '';
            }
          } catch (error) {
            log.error('error in event_text', event, error);
            return '';
          }
        default:
          return '';
      }
    } catch (error) {
      log.error('error in event_text', event, error);
      return '';
    }
  };
}

export function timeline_event_text(props: {
  viewType: 'calendar' | 'timeline' | 'unit';
  isMobile;
  body?: string;
  event: ISchedulerEvent;
}): string {
  try {
    return ReactDOMServer.renderToStaticMarkup(
      <EventTimelineContent
        event={props.event}
        body={props.body}
        viewType={props.viewType}
        isMobile={props.isMobile}
      />,
    );
  } catch (error) {
    log.error('error in event_text', props.event, error);
    return '';
  }
}
