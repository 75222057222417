/**
 *
 * Asynchronously loads the component for CalendarMessages Page
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CalendarMessages = lazyLoad(
  () => import('./index'),
  module => module.CalendarMessagesPage,
);
