import { BillingActionsDetailsQStringParameters } from 'app/pages/BillingActions/BillingActionsDetailsPage/Details/slice/types';
import { httpClient } from './HttpClient';
import { IBillingActionsDto } from '../app/pages/BillingActions/BillingActionsPage/IBillingActionsDto';
import { IResponseType } from '../types/ResponseType';
import { IBillingDto } from 'app/pages/InvoiceBillingPage/IBillingDto';
import { IInvoiceBatchesDto } from 'app/pages/InvoiceBatches/IInvoiceBatchesDto';
import { IBillingActionInvoice } from 'app/pages/InvoiceBillingPage/SelectedRowActions/BillingActionsPopup/BillingActionsPopupTable/IBillingActionInvoice';

export const BillingActionsApi = {
  initCreateData: (payload: BillingActionsDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/BillingActions/InitBillingActions';
    return httpClient.post(url, data);
  },
  initUpdateData: (payload: BillingActionsDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/BillingActions/InitBillingActions';
    return httpClient.post(url, data);
  },
  insertBillingActionsDetails: (payload: IBillingActionsDto) => {
    const url = '/api/odata/v4/BillingActions'; //POST action in Controller
    return httpClient.post(url, payload);
  },
  updateBillingActionsDetails: (payload: IBillingActionsDto) => {
    const url = `/api/odata/v4/BillingActions(${payload.Id})`; //PUT action in Controller
    return httpClient.put(url, payload);
  },
  deleteBillingActionsDetails: (
    rows: IBillingActionsDto[],
  ): Promise<IResponseType> => {
    const billingActionIds: number[] = rows.map(item => item.Id);
    const data = { billingActionIds };
    const url = '/api/odata/v4/BillingActions/DeleteBillingActions';
    return httpClient.post(url, data);
  },
  getBillingActions: (screen: number) => {
    const url = `/api/odata/v4/BillingActions(${screen})`;
    return httpClient.get(url, {
      $expand: ['EmailAttachmentTypes', 'EmailRecipientTypes'].join(','),
    });
  },
  getBillingActionById: (screen: number, billingActionId: number) => {
    const url = `/api/odata/v4/BillingActions(${screen})`;
    const params: {
      $filter?: string;
      $expand?: string;
    } = {
      $filter: '(Id eq ' + billingActionId + ')',
      $expand: ['EmailAttachmentTypes', 'EmailRecipientTypes'].join(','),
    };
    return httpClient.get(url, params);
  },
  getBillingActionInvoices: (
    batchInvoiceIds: IBillingDto[] | IInvoiceBatchesDto[],
    screen: number,
  ) => {
    const ids: string = batchInvoiceIds.map(item => item.Id).join(',');
    const data = { ids, screen };
    const url = '/api/odata/v4/BillingActions/GetBillingActionInvoices';
    return httpClient.post(url, data);
  },
  processEmailAction: (
    billingActionsParams: IBillingActionsDto,
    rows: IBillingDto[] | IInvoiceBatchesDto[],
  ) => {
    const billingActionId: number = billingActionsParams.Id;
    const invoiceIds: string = rows.map(item => item.Id).join(',');
    const data = { billingActionId, invoiceIds };
    const url = '/api/odata/v4/BillingActions/ProcessEmailAction';
    return httpClient.post(url, data);
  },
  ProcessSftpUploadAction: (
    billingActionsParams: IBillingActionsDto,
    rows: IBillingDto[] | IInvoiceBatchesDto[],
  ) => {
    const billingActionId: number = billingActionsParams.Id;
    const invoiceIds: string = rows.map(item => item.Id).join(',');
    const data = { billingActionId, invoiceIds };
    const url = '/api/odata/v4/BillingActions/ProcessSftpUploadAction';
    return httpClient.post(url, data);
  },
  processStatusAction: (
    billingActionsParams: IBillingActionsDto,
    rows: IBillingActionInvoice[],
  ) => {
    const billingActionId: number = billingActionsParams.Id;
    const invoiceIds: string = rows.map(item => item.Id).join(',');
    const data = { billingActionId, invoiceIds };
    const url = '/api/odata/v4/BillingActions/ProcessStatusAction';
    return httpClient.post(url, data);
  },
  getRecipientsEmailAddresses: (
    rows: IBillingDto[] | IInvoiceBatchesDto[],
    billingActionsRow: IBillingActionsDto,
  ) => {
    const invoiceIds: number[] = rows.map(item => item.Id);
    const emailTypes: number[] = billingActionsRow.EmailRecipientTypes.map(
      item => item.Id,
    );
    const screen: number = billingActionsRow.ShowOn;
    const specEmailAddresses: string | null =
      billingActionsRow.SpecificEmailAddresses;
    const data = { invoiceIds, emailTypes, screen, specEmailAddresses };
    const url = '/api/odata/v4/BillingActions/GetRecipientsEmailAddresses';
    return httpClient.post(url, data);
  },
};
