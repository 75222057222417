import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { FilterValueType } from './BasicFilter/FilterValueType';

/**
 * Returns current value of the filter based on filter's id
 * @param allFilters all filters
 * @param id filter id
 * @returns filter value
 */
export const getFilterValue = function <T>(
  allFilters: Array<IFilterSettings<T>> | undefined,
  id: string,
): FilterValueType | undefined {
  if (allFilters === undefined) {
    return undefined;
  }
  var filter = allFilters.find(f => f.id === id);
  if (filter === undefined) {
    return undefined;
  }
  const value = filter.value;
  return value ?? undefined;
};
