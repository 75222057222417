import { ILabelTypeDto } from 'api/odata/generated/entities/ILabelTypeDto';
import { LabelPrinter } from '../slice/types';
import { Dymo as DymoPrinter } from './Dymo';
import { zebra as ZebraPrinter } from './Zebra';

export type TGetPrinter = () => Promise<Array<LabelPrinter>>;

export type TPrint<TResult = any> = (
  printer: LabelPrinter,
  labelType: ILabelTypeDto,
  data: Array<any>,
  options: {
    AssetBarcodeUrlTemplate: string;
    RoomBarcodeUrlTemplate: string;
    LocationBarcodeUrlTemplate: string;
    SubLocationBarcodeUrlTemplate: string;
    SamplePlateBarcodeUrlTemplate: string;
    ConsumableTypeBarcodeUrlTemplate: string;
  },
) => Promise<TResult>;
export interface IFileResult {
  fileName: string;
  content: any;
}
export type ILabelPrinter = {
  getPrinters: TGetPrinter;
  print: TPrint;
  preview: TPrint<IFileResult[]>;
  download: TPrint<IFileResult[]>;
};

export const Dymo = DymoPrinter;
export const Zebra = ZebraPrinter;
export const getPrinterInterface = (printer: LabelPrinter) => {
  switch (printer.Type) {
    case 'Dymo':
      return Dymo;
    case 'Zebra':
      return Zebra;
  }
};
