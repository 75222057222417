/**
 *
 * LoginLayout
 *
 */
import { Copyright } from 'app/components/Copyright';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  AppBar,
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
} from '@material-ui/core';
import { BookitlabLogo } from 'app/components/BookitlabLogo';
import { FlexColumnDiv } from '../../components/basic/Wrappers/FlexWrappers/flexColumn';
import { StyledUserMenu } from '../FrontendLayout/components/TopBar/styled';
import { toRootedURL } from 'utils/url-utils';
import { translations } from 'locales/translations';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { DetectIsMobile } from 'utils/mobileDetect';

export interface LoginLayoutProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    //marginTop: theme.spacing(3),
    flexGrow: 1,
  },
  mainWrapper: {
    height: '80vh',
  },
  footer: {},
  paper: {
    //marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '40%',
    '& p,h1': {
      padding: theme.spacing(1),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function LoginLayout({ children }: LoginLayoutProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar color="default" elevation={0} position="relative">
        <Toolbar>
          <Box onClick={event => handleClick(event)}>
            <BookitlabLogo />
          </Box>
          <LogoMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            theme={theme}
          />
        </Toolbar>
      </AppBar>
      <Container component="main" className={classes.main}>
        <FlexColumnDiv className={classes.mainWrapper}>
          <Box className={classes.paper}>{children}</Box>
          {/* <Paper variant='outlined' ></Paper> */}
        </FlexColumnDiv>
      </Container>
      <Container component="footer" className={classes.footer}>
        <Copyright />
      </Container>
    </div>
  );
}

export interface LogoMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  theme: Theme;
}
const LogoMenu = React.memo(function LogoMenuFunc(props: LogoMenuProps) {
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  return (
    <>
      <StyledUserMenu
        theme={props.theme}
        marginThreshold={1}
        id="user-profile-menu"
        anchorEl={props.anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(props.anchorEl)}
        PaperProps={
          isMobile
            ? {
                style: {
                  width: '100%',
                  height: '100%',
                  maxHeight: 'calc(100% - 32px)',
                  maxWidth: 'calc(100% - 0px)',
                },
              }
            : undefined
        }
        onClose={() => props.handleClose()}
      >
        <MenuItem
          onClick={() => props.handleClose()}
          component={'a'}
          href={toRootedURL('/account/signout')}
        >
          <ListItemIcon>
            <Icon icon="sign-out-alt" color="default" colorExtend="textHover" />
          </ListItemIcon>
          <ListItemText>
            {t(translations.main_master_LogOutLinkText)}
          </ListItemText>
        </MenuItem>
      </StyledUserMenu>
    </>
  );
});
