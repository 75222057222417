import { Dialog } from '@material-ui/core';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsCoverExpanded } from '../../slice/selectors';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { useLayoutSlice } from '../../slice';

export interface CoverWrapperProps {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
}

export const CoverWrapper = ({
  children,
  open,
  onClose,
}: CoverWrapperProps) => {
  const isMobile = DetectIsMobile();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const coverExpanded = useSelector(selectIsCoverExpanded);
  useEffectOnMount(() => {
    dispatch(actions.setIsCoverOpen(open));
  });
  return (
    <div style={{ height: '100%' }} onClick={e => e.stopPropagation()}>
      <Dialog
        fullWidth={coverExpanded || isMobile}
        maxWidth={false}
        className={clsx('coverRoot', {
          coverShortRoot: !coverExpanded || isMobile,
        })}
        hideBackdrop={true}
        scroll="paper"
        disableAutoFocus
        open={open}
        onClose={onClose}
      >
        {children}
      </Dialog>
    </div>
  );
};
