/**
 *
 * Asynchronously loads the component for RunsListPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RunsListPage = lazyLoad(
  () => import('./index'),
  module => module.RunsListPage,
);
