/**
 *
 * DialogForm
 *
 */
import * as React from 'react';
import {
  FormFieldsSection,
  FormLeftSection,
} from 'app/components/Forms/FormsLayout';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Form, Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import { BasicDialogContent } from 'app/components/BasicDialog/BasicDialogContent';
import { BasicDialogTitle } from 'app/components/BasicDialog/BasicDialogTitle';
import { Button } from 'app/components/BasicButtons/Button';
import { Dialog, DialogActions, DialogProps } from '@material-ui/core';
import { FormRowProps } from '../Forms/FormsLayout/FormRow';

export interface DialogFormProps<T extends FormikValues>
  extends Pick<
      FormikConfig<T>,
      'onSubmit' | 'initialValues' | 'validationSchema'
    >,
    Pick<DialogProps, 'open'> {
  /**
   * overrides Dialog's onClose to simplify the function signature since the event target & close reason is not in use currently
   * @returns
   */
  onClose: () => void;
  title: string;
  subTitle?: string;
  /**
   * Children have to be of type <FormRow .../> due to layout constraints
   */
  children:
    | Array<React.ReactElement<FormRowProps>>
    | React.ReactElement<FormRowProps>;
}

/**
 * Dialog with a form inside the DialogContent. Useful in cases where a confirmation is needed alongside another input from the user.
 * For example, quote rejection that requires a confirmation dialog with a reason for rejection, or an entity is added/updated through a dialog UI.
 * Props largely composed from a minimum viable props set from Formik & Dialog.
 * Children are expected to 
 * @example
 * <DialogForm
      validationSchema={Yup.object({Name:Yup.string().required()}}
      initialValues={{}}
      title={t(translations.SomeTitle)}
    >
      <FormRow fullRow>
        <FormRichTextField
          name="Name"
          label={t(translations.label)}
          placeholder={t(translations.placeholder)}
        />
      </FormRow>
    </DialogForm>
 * @param props
 * @returns
 */
export function DialogForm<T extends FormikValues>(props: DialogFormProps<T>) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Formik
        validationSchema={props.validationSchema}
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
      >
        {formik => <FormContent {...props} {...formik} />}
      </Formik>
    </Dialog>
  );
}
function FormContent<T extends FormikValues>(
  props: DialogFormProps<T> & FormikProps<T>,
) {
  const { t } = useTranslation();

  return (
    <Form onSubmit={props.handleSubmit}>
      <BasicDialogTitle subtitle={props.subTitle}>
        {props.title}
      </BasicDialogTitle>
      <BasicDialogContent>
        <FormLeftSection>
          <FormFieldsSection>{props.children}</FormFieldsSection>
        </FormLeftSection>
      </BasicDialogContent>
      <DialogActions>
        <Button variant="white" size="small" onClick={props.onClose}>
          {t(translations.Cancel)}
        </Button>
        <Button
          size="small"
          type="submit"
          variant="main"
          disabled={!props.isValid}
        >
          {t(translations.Submit)}
        </Button>
      </DialogActions>
    </Form>
  );
}
