/**
 *
 * EditableCell
 *
 */
import * as React from 'react';
import { Row } from 'react-table';
import { IRow } from 'app/components/BasicTable';
import { FieldProps } from 'formik';

export interface EditableCellProps<TValue, TColumn, TRow extends IRow> {
  value: TValue;
  row: Row<TRow>;
  column: TColumn;
  updateMyData: any; // This is a custom function that we supplied to our table instance
}

export interface BaseInputProps<TValue, TElement = Element> {
  onChange: (value: TValue) => void;
  name?: string;
  value: TValue;
  onBlur: React.FocusEventHandler<TElement>;
  error?: boolean;
  helperText?: string;
}

export function withPPP<TValue>(
  Component: React.FunctionComponent<BaseInputProps<TValue>>,
) {
  return ({
    field,
    form,
    meta,
    ...props
  }: React.PropsWithChildren<BaseInputProps<TValue> & FieldProps<TValue>>) => {
    const {
      onChange: formikOnChange,
      multiple,
      checked,
      ...fieldProps
    } = field;
    const { onChange: componentOnChange, ...compProps } = props;
    const handleChange = value => {
      componentOnChange?.(value);
      formikOnChange?.({
        target: {
          name: field.name,
          value: value,
        },
      });
    };
    return <Component onChange={handleChange} {...fieldProps} {...compProps} />;
  };
}

export interface EditorProps<TValue> {
  value: TValue;
  onChange: (value: TValue) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
  id?: string;
  label?: string;
  name: string;
  error?: boolean;
  helperText?: string;
  parentValue?: any;
  info?: string;
  autoFocus?: boolean;
}

export function EditableCell<T>(
  WrappedComponent: React.FunctionComponent<EditorProps<T>>,
) {
  return function Comp({
    value: initialValue,
    row,
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    ...props
  }: EditableCellProps<any, { id: any }, any>) {
    const [value, setValue] = React.useState(initialValue);
    const onChange = event => {
      const value = event.target.value;
      setValue(value);
      const payload = { Id: row.original.Id };
      payload[id] = value;
      updateMyData(payload);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = React.useCallback(() => {
      if (value !== initialValue) {
        const payload = { Id: row.original.Id };
        payload[id] = value;
        // updateMyData(payload);
      }
    }, [id, initialValue, row.original.Id, value]);
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <>
        <WrappedComponent
          {...{ value, onChange, onBlur, name: id }}
          error={false}
        />
      </>
    );
  };
}
