import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.pricePeriod || initialState;

export const selectPricePeriod = createSelector([selectSlice], state => state);

export const selectProcessing = createSelector(
  [selectPricePeriod],
  state => state.processing,
);
export const selectDetails = createSelector(
  [selectPricePeriod],
  state => state.detailsState,
);
export const selectDetailsProcessing = createSelector(
  [selectDetails],
  state => state.processing,
);
export const selectDetailsError = createSelector(
  [selectDetails],
  state => state.error,
);
export const selectDetailsHasError = createSelector(
  [selectDetails],
  state => state.hasErrors,
);
export const selectDetailsCompleted = createSelector(
  [selectDetails],
  state => state.completed,
);
export const selectDetailsData = createSelector(
  [selectDetails],
  state => state.data,
);
