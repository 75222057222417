import * as React from 'react';
import { useProfileSetting } from 'app/components/BasicTable/useProfileSetting';
import { IBasePageProps } from '../pages/IBasePageProps';
import { getLogger } from 'utils/logLevel';

/**
 * Enables wrapped page to be saved as the Last Visited Page. This is used to redirect users after login to the last visited page
 * @param Component Page component
 * @returns
 */

export function withSaveAsLastVisitedPage<TProps extends IBasePageProps>(
  Component: React.FC<TProps>,
) {
  const log = getLogger('withSaveAsLastVisitedPage');
  return (props: TProps) => {
    const LAST_SAVED_PAGE_PROFILE_KEY = 'LastVisitedPage';
    // reconstruct back the location in the aspnet compatible relative path e.g. ~/schedule/calendar?id=1#foobar
    const location = [
      '~',
      props.location.pathname,
      props.location.search,
      props.location.hash,
    ].join('');

    // warning: updateY might mutate after the "savedValue" change inside the useProfileSetting
    // this might lead to useEffect firing 2 times for each location change -
    // first time when location changes, and then once again on update function change
    const { updateValue: updateY } = useProfileSetting({
      profileSettingKey: LAST_SAVED_PAGE_PROFILE_KEY,
    });

    // update the profile setting on each location change on current page
    React.useEffect(() => {
      updateY(location);
      log.info('LastVisitedPage', location);
    }, [location, updateY]);

    // wrapped component render
    return <Component {...props} />;
  };
}
