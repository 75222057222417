import { makeStyles, Theme } from '@material-ui/core/styles';
export const autocompleteExtendStyles = makeStyles((theme: Theme) => ({
  extendRoot: {
    '&.Mui-focused $infoWidthPlus': {
      right: '48px',
    },
    '@media (pointer: fine)': {
      '&:hover $infoWidthPlus': {
        right: '48px',
      },
    },
  },
  infoIcon: {
    padding: '4px',
    top: 'calc(50% - 14px)',
    position: 'absolute',
    cursor: 'auto',
    zIndex: 4,
    '& > svg': {
      width: '12px',
      height: '12px',
    },
  },
  infoWidthPlus: {
    right: '26px',
  },
  infoWidthMinus: {
    right: '26px',
  },
}));
/* These styles was taken from original place https://github.com/mui-org/material-ui/blob/master/packages/material-ui-lab/src/Autocomplete/Autocomplete.js
  and partially redefined for for our components */
export const autocompleteStyles = makeStyles((theme: Theme) => ({
  /* Styles applied to the root element. */
  root: {
    '&$focused $clearIndicatorDirty': {
      visibility: 'visible',
      //display: 'inline-flex',
    },
    /* Avoid double tap issue on iOS */
    '@media (pointer: fine)': {
      '&:hover $clearIndicatorDirty': {
        visibility: 'visible',
        //display: 'inline-flex',
      },
      // '&:hover $infoWidthPlus': {
      //   right: '54px',
      // },
    },
    // '&$focused $infoWidthPlus': {
    //   right: '54px',
    // },
    '&>[class*="MuiFormControl-root"]': {
      '&>[class*="multiSelect"]': {
        maxWidth: '400px',
        // paddingTop: '6px',
        // paddingBottom: '6px',
        '&>[class*="shortView"]': {
          maxWidth: 360,
        },
        '&.hasLabel': {
          '& > $tag:first-child': {
            marginTop: '6px',
            //marginBottom: '6px',
          },
        },
        [theme.breakpoints.up('sm')]: {
          maxWidth: '100%',
          '&>[class*="shortView"]': {
            maxWidth: '100%',
          },
        },
      },
      '&>[class*="MuiInputLabel-filled"]': {
        transform: 'translate(12px, 16px) scale(1)',
        // long placeholder/label might break in 2+ lines - show only single line here
        // setting up width to minimum assuming there might be clear as well as other adornments just in case
        // 12px is the left translate value, the other sum equals to max paddingRight of filled inputRoot
        width: 'calc(100% - 12px - (52px + 4px + 9px))',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingTop: 0,
        paddingBottom: 0,
        '&.adornedStart-label': {
          transform: 'translate(41px, 16px) scale(0.85)',
        },
        '&[class*="MuiInputLabel-shrink"]': {
          letterSpacing: '0.2px',
          transform: 'translate(12px, 8px) scale(1)',
          // '&$marginDense': {
          //   transform: 'translate(12px, 4px) scale(0.75)',
          // },
          '&.adornedStart-label': {
            transform: 'translate(41px, 27px) scale(0.85)',
          },
        },
        '&[class*="multiSelect"][class*="MuiInputLabel-shrink"]': {
          letterSpacing: '0.2px',
          transform: 'translate(12px, 4px) scale(1)',
          // '&$marginDense': {
          //   transform: 'translate(12px, 4px) scale(0.75)',
          // },
          '&.adornedStart-label': {
            transform: 'translate(41px, 27px) scale(0.85)',
          },
        },
      },
    },
    '&>[class*="MuiInputLabel-shrink"]': {
      transform: 'translate(0, -2.5px) scale(1.1)',
    },
  },
  /* Styles applied to the root element if `fullWidth={true}`. */
  fullWidth: {
    width: '100%',
    '&>[class*="MuiFormControl-root"]': {
      '&>[class*="multiSelect"]': {
        maxWidth: '100%',
      },
      '&>[class*="shortView"]': {
        maxWidth: 360,
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
        '&>[class*="shortView"]': {
          maxWidth: '100%',
        },
      },
    },
  },
  /* Pseudo-class applied to the root element if focused. */
  focused: {},
  /* Styles applied to the tag elements, e.g. the chips. */
  tag: {
    margin: 0,
    // maxWidth: 400, //'calc(100% - 56px)',
    // //width: 'inherit',
    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: 300,
    // },
  },
  /* Styles applied to the tag elements, e.g. the chips if `size="small"`. */
  tagSizeSmall: {
    margin: 0,
    maxWidth: 'calc(100% - 4px)',
  },
  /* Styles applied when the popup icon is rendered. */
  hasPopupIcon: {},
  /* Styles applied when the clear icon is rendered. */
  hasClearIcon: {
    '& > * > * > [class*="infoWidthPlus"]': {
      right: '48px',
    },
  },
  /* Styles applied to the Input element. */
  // inputRoot: {
  //   backgroundColor: theme.palette.common.white,
  //   border: `1px solid ${theme.palette.common.grayBorders}`,
  //   borderRadius: 6,
  //   cursor: 'auto',
  //   flexWrap: 'wrap',
  //   height: 32,
  //   '$hasPopupIcon &, $hasClearIcon &': {
  //     paddingRight: 26 + 4,
  //   },
  //   '$hasPopupIcon$hasClearIcon &': {
  //     paddingRight: 24 + 4,
  //   },
  //   gap: 4,
  //   // 'label + &': {
  //   //   marginTop: '21px',
  //   // },
  //   // 'label + &[class*="MuiFilledInput-root"]': {
  //   //   marginTop: '0',
  //   // },
  //   '& $input': {
  //     width: 'auto',
  //     minWidth: 30,
  //     padding: '7px 12px',
  //   },
  //   '&:hover': {
  //     backgroundColor: 'unset', //'rgba(255, 255, 255, 0.80)',
  //     // Reset on touch devices, it doesn't add specificity
  //     '@media (hover: none)': {
  //       backgroundColor: 'unset', //'rgba(255, 255, 255, 0.90)',
  //     },
  //   },
  //   '&[class*="Mui-focused"]': {
  //     backgroundColor: 'rgba(255, 255, 255, 0.90)',
  //     border: `1px solid ${theme.palette.primary.main}`,
  //   },
  //   '&[class*="Mui-disabled"]': {
  //     backgroundColor: theme.palette.common.backgroundHover,
  //   },
  //   '&[class*="MuiInput-root"]': {
  //     paddingBottom: 1,
  //     '& $input': {
  //       padding: 4,
  //     },
  //     '& $input:first-child': {
  //       padding: '6px 0',
  //     },
  //   },
  //   '&[class*="marginDense"]': {
  //     borderRadius: 4,
  //     '& $input': {
  //       padding: '4px 4px 5px',
  //     },
  //     '& $input:first-child': {
  //       padding: '1px 0px 1px 6px',
  //     },
  //   },
  //   '&[class*="MuiOutlinedInput-root"]': {
  //     padding: 9,
  //     '$hasPopupIcon &, $hasClearIcon &': {
  //       paddingRight: 26 + 4 + 9,
  //     },
  //     '$hasPopupIcon$hasClearIcon &': {
  //       paddingRight: 52 + 4 + 9,
  //     },
  //     '& $input': {
  //       padding: '9.5px 4px',
  //     },
  //     '& $input:first-child': {
  //       paddingLeft: 6,
  //     },
  //     '& $endAdornment': {
  //       right: 9,
  //     },
  //   },
  //   '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
  //     padding: 6,
  //     '& $input': {
  //       padding: '4.5px 4px',
  //     },
  //   },
  //   '&[class*="MuiFilledInput-root"]': {
  //     paddingTop: 16,
  //     paddingLeft: 8,
  //     '$hasPopupIcon &, $hasClearIcon &': {
  //       paddingRight: 26 + 4 + 9,
  //     },
  //     '$hasPopupIcon$hasClearIcon &': {
  //       paddingRight: 52 + 4 + 9,
  //     },
  //     '& $input': {
  //       padding: '6px 4px 4px',
  //     },
  //     '& $endAdornment': {
  //       right: 4,
  //     },
  //     '&[class*="MuiFilledInput-adornedStart"]': {
  //       //paddingLeft: '36px',
  //       '&>.adornedStart': {
  //         cursor: 'auto',
  //         position: 'absolute',
  //         left: 12,
  //         top: 'calc(50% - 3px)',
  //       },
  //     },
  //   },
  //   '&[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"]': {
  //     paddingTop: 14,
  //     paddingBottom: 0,
  //     '& $input': {
  //       padding: '4.5px 4px',
  //     },
  //   },
  //   '&[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"][class*="miniDense"]': {
  //     paddingTop: 2,
  //     paddingBottom: 2,
  //     //borderRadius: '6px',
  //     border: `1px solid ${theme.palette.primary.main}`,
  //     '& $input': {
  //       padding: '3.5px 3px',
  //     },
  //   },
  // },
  inputRoot: {
    flexWrap: 'wrap',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.grayBorders}`,
    borderRadius: 6,
    cursor: 'auto',
    minHeight: 32,
    '$hasPopupIcon &, $hasClearIcon &': {
      paddingRight: 56, //26 + 4,
    },
    '$hasPopupIcon$hasClearIcon &': {
      paddingRight: 56, //24 + 4,
    },
    gap: 4,
    '& $input': {
      width: 'auto',
      minWidth: 30,
      //padding: '6px 12px',
    },
    '&[class*="MuiInput-root"]': {
      paddingBottom: 0,
      '& $input': {
        padding: 4,
      },
      '& $input:first-child': {
        padding: '6px 12px',
      },
    },
    '&[class*="MuiInput-root"][class*="MuiInput-marginDense"]': {
      // '& $input': {
      //   padding: '4px 4px 5px',
      // },
      // '& $input:first-child': {
      //   padding: '4px 4px 5px', //'3px 0 6px',
      // },
      borderRadius: 6,
      '& $input': {
        padding: '4px 4px 5px',
      },
      '& $input:first-child': {
        padding: '1px 0px 1px 7px',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.80)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'rgba(255, 255, 255, 0.90)',
      },
    },
    '&[class*="Mui-focused"]': {
      backgroundColor: 'rgba(255, 255, 255, 0.90)',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&[class*="Mui-disabled"]': {
      backgroundColor: theme.palette.common.backgroundHover,
    },
    '&[class*="MuiOutlinedInput-root"]': {
      padding: 9,
      '$hasPopupIcon &, $hasClearIcon &': {
        paddingRight: 26 + 4 + 9,
      },
      '$hasPopupIcon$hasClearIcon &': {
        paddingRight: 52 + 4 + 9,
      },
      '& $input': {
        padding: '9.5px 4px',
      },
      '& $input:first-child': {
        paddingLeft: 6,
      },
      '& $endAdornment': {
        right: 9,
      },
    },
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: 6,
      '& $input': {
        padding: '4.5px 4px',
      },
    },
    '&[class*="MuiFilledInput-root"]': {
      paddingTop: 16,
      paddingLeft: 8,
      minHeight: 48,
      height: 'auto',
      '$hasPopupIcon &, $hasClearIcon &': {
        paddingRight: 26 + 4 + 9,
      },
      '$hasPopupIcon$hasClearIcon &': {
        paddingRight: 52 + 4 + 9,
      },
      '& $input': {
        padding: '6px 4px 4px',
      },
      '& $endAdornment': {
        right: 4,
      },
      '&[class*="MuiFilledInput-adornedStart"]': {
        //paddingLeft: '36px',
        '&>.adornedStart': {
          cursor: 'auto',
          position: 'absolute',
          left: 12,
          top: 'calc(50% - 3px)',
        },
      },
    },
    '&[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"]': {
      paddingTop: 14,
      paddingBottom: 0,
      '& $input': {
        padding: '4.5px 4px',
      },
    },
  },
  /* Styles applied to the input element. */
  input: {
    flexGrow: 1,
    textOverflow: 'ellipsis',
    opacity: 0,
    padding: '6px 12px',
  },
  /* Styles applied to the input element if tag focused. */
  inputFocused: {
    opacity: 1,
  },
  /* Styles applied to the endAdornment element. */
  endAdornment: {
    // We use a position absolute to support wrapping tags.
    position: 'absolute',
    right: 8,
    top: 'calc(50% - 11px)', // Center vertically
  },
  /* Styles applied to the clear indicator. */
  clearIndicator: {
    marginRight: 2,
    padding: 4,
    '& > * > svg': {
      width: 12,
      height: 12,
      cursor: 'pointer',
    },
    visibility: 'hidden',
    //display: 'none',
  },
  /* Styles applied to the clear indicator if the input is dirty. */
  clearIndicatorDirty: { visibility: 'visible', display: 'inline-flex' },
  /* Styles applied to the popup indicator. */
  popupIndicator: {
    padding: 4,
    marginRight: 0,
    '& > * > svg': {
      width: 12,
      height: 12,
    },
  },
  /* Styles applied to the popup indicator if the popup is open. */
  popupIndicatorOpen: {
    transform: 'rotate(180deg)',
  },
  /* Styles applied to the popper element. */
  popper: {
    zIndex: theme.zIndex.modal,
  },
  /* Styles applied to the popper element if `disablePortal={true}`. */
  popperDisablePortal: {
    position: 'absolute',
  },
  /* Styles applied to the `Paper` component. */
  paper: {
    ...theme.typography.bodyM,
    overflow: 'hidden',
    margin: '4px 0',
  },
  /* Styles applied to the `listbox` component. */
  listbox: {
    listStyle: 'none',
    margin: 0,
    padding: '8px 0',
    maxHeight: '40vh',
    overflow: 'auto',
  },
  /* Styles applied to the loading wrapper. */
  loading: {
    color: theme.palette.text.disabled,
    padding: '14px 16px',
  },
  /* Styles applied to the no option wrapper. */
  noOptions: {
    color: theme.palette.text.secondary,
    padding: '14px 16px',
  },
  /* Styles applied to the option elements. */
  option: {
    minHeight: 48,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    paddingTop: 6,
    boxSizing: 'border-box',
    outline: '0',
    WebkitTapHighlightColor: 'transparent',
    paddingBottom: 6,
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up('sm')]: {
      minHeight: 'auto',
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.action.selected,
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.selected,
    },
    '&[aria-disabled="true"]': {
      opacity: theme.palette.action.disabledOpacity,
      pointerEvents: 'none',
    },
  },
  /* Styles applied to the group's label elements. */
  groupLabel: {
    backgroundColor: theme.palette.background.paper,
    top: -8,
  },
  /* Styles applied to the group's ul elements. */
  groupUl: {
    padding: 0,
    '& $option': {
      paddingLeft: 24,
    },
  },
}));
