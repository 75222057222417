/**
 *
 * ExternalLink
 *
 */
import * as React from 'react';
import {
  Link as MuiLink,
  LinkBaseProps as MuiLinkProps,
  Theme,
} from '@material-ui/core';
import { IPathAndQuery, toRootedURL } from 'utils/url-utils';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import { useDispatch } from 'react-redux';
import { useAppSettingsSlice } from 'app/slice';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { makeStyles } from '@material-ui/styles';
import { Tooltip } from '../BasicTooltips/Tooltip';

export interface ExternalLinkProps extends Omit<MuiLinkProps, 'href'> {
  href: string | IPathAndQuery;
  /**
   * Show external URL icon with copy url functionality
   */
  showIcon?: boolean;
}

/**
 * External link - opens in a new tab/window
 * This should be used for all external links and should be clear that it leads to a resource outside of the system
 * @param props
 * @returns
 */
export const ExternalLink = React.forwardRef(function ExternalLink(
  props: ExternalLinkProps,
  ref,
) {
  return <LinkBehavior ref={ref} {...props} target="_blank" />;
});

/**
 * Link to a resource inside the larger system but external to the React app
 * Renders <a href="path" ...
 * @param param0
 * @returns
 */
export function Link(props: ExternalLinkProps) {
  return <LinkBehavior {...props} />;
}

/**
 * Link that can accept forwarded ref prop. Usefull in the "override component" in the material ui
 * Usage example: <ListItem component={LinkBehavior} href={'/Reports/OperatingRoomProceduresSummary.aspx'} ...
 */
export const LinkBehavior = React.forwardRef<any, ExternalLinkProps>(
  function LinkBehavior(props, ref) {
    const { href, ...rest } = props;
    const classes = useStyles();
    const absolutePath =
      typeof href === 'string'
        ? toRootedURL(href)
        : toRootedURL(
            (href as IPathAndQuery).path,
            (href as IPathAndQuery).search,
          );
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { actions } = useAppSettingsSlice();
    const handleIconClick = () => {
      clipboardWrite(absolutePath).then(() =>
        dispatch(
          actions.addNotification({
            variant: 'success',
            message: `${t(translations.URLCopied)} - ${absolutePath}`,
          }),
        ),
      );
    };
    if (props.showIcon === true) {
      return (
        <span className={classes.externalLinkRoot}>
          <MuiLink
            className={classes.link}
            ref={ref}
            href={absolutePath}
            {...rest}
          />
          <Tooltip title={t(translations.CopyURL)}>
            <IconButton
              className={classes.externalLinkIcon}
              title={t(translations.CopyURL)}
              size="xs"
              variant="ghost"
              onClick={handleIconClick}
            >
              <Icon size="1x" icon="external-link" />
            </IconButton>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <MuiLink
          className={classes.link}
          ref={ref}
          href={absolutePath}
          {...rest}
        />
      );
    }
  },
);
const useStyles = makeStyles((theme: Theme) => ({
  externalLinkRoot: {
    whiteSpace: 'nowrap',
  },
  link: {},
  externalLinkIcon: {
    display: 'inline',
  },
}));

const permissionName = 'clipboard-write' as PermissionName;
const clipboardWriteEnabled = async () => {
  const result = await navigator.permissions.query({ name: permissionName });
  return result.state === 'granted' || result.state === 'prompt';
};
const clipboardWrite = async (text: string) => {
  const enabled = await clipboardWriteEnabled();
  if (enabled) {
    const copyResult = await navigator.clipboard.writeText(text);
    return copyResult;
  }
};
