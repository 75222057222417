import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { LoginTypes, Roles } from 'app/slice/types';
import { ServiceType } from 'enums/ServiceTypes';
import { intersection } from 'lodash';
import { enumToArray, enumToDescriptedArray } from 'utils/enumKeys';
import { Entity } from './common';

export interface IAuthenticatedUser extends Entity<string> {
  Language?: string;
  CultureName?: string;
  Roles: number[];
  LoginType: LoginTypes;
  UserGroups: Entity<string>[];
  ActiveUserGroup?: Entity<string>;
  DisplayName?: string;
  AdminEquipments?: AuthServiceDto[];
  AdminServiceGroups?: AuthServiceGroupDto[];
  InstrumentEventsGroups?: AuthServiceGroupDto[];
  CreatableGroups?: Entity<number>[];
  HasRegisterableServiceGroups?: boolean;
  FormatedName?: string;
  HasNotifications?: boolean;
  HasOptOutCoreSelection: boolean;
  CalendarType: number;
  RequireUserGroupCoordApproval: boolean;
  HasSignedTermsOfUse: boolean;
  IsActiveDirectory: boolean;
}
export interface AuthServiceDto extends Entity<number> {
  Id: number;
  Name: string;
  ServiceTypeId: number;
  ServiceGroupId: number;
  IsLabTech: boolean;
}
export interface AuthServiceGroupDto extends Entity<number> {
  Id: number;
  Name: string;
  IsLabTech: boolean;
}
export interface IIsAdminOfParams {
  ServiceTypeId: ServiceType;
  ServiceId: number;
  ServiceGroupId: number;
}
export interface IIsAdminOrOwnerOfParams extends IIsAdminOfParams {
  UserId: string | null;
}

export class AuthenticatedUser implements IAuthenticatedUser {
  // Entity<string> members
  Id: string;
  Name: string;
  // IAuthenticatedUser members
  Language?: string;
  CultureName?: string;
  Roles: number[];
  LoginType: LoginTypes;
  UserGroups: Entity<string>[];
  ActiveUserGroup?: Entity<string>;
  DisplayName?: string;
  AdminEquipments: AuthServiceDto[];
  AdminServiceGroups: AuthServiceGroupDto[];
  InstrumentEventsGroups: AuthServiceGroupDto[];
  CreatableGroups: Entity<number>[];
  HasRegisterableServiceGroups?: boolean;
  FormatedName?: string;
  HasNotifications?: boolean;
  HasOptOutCoreSelection: boolean;
  CalendarType: number;
  RequireUserGroupCoordApproval: boolean;
  HasSignedTermsOfUse: boolean;
  IsActiveDirectory: boolean;

  //constructor
  constructor(data: IAuthenticatedUser) {
    this.Id = data.Id;
    this.Name = data.Name;
    this.Language = data.Language;
    this.CultureName = data.CultureName;
    this.Roles = data.Roles;
    this.LoginType = data.LoginType;
    this.UserGroups = data.UserGroups;
    this.ActiveUserGroup = data.ActiveUserGroup;
    this.DisplayName = data.DisplayName;
    this.AdminEquipments = data.AdminEquipments ?? [];
    this.AdminServiceGroups = data.AdminServiceGroups ?? [];
    this.InstrumentEventsGroups = data.InstrumentEventsGroups ?? [];
    this.CreatableGroups = data.CreatableGroups ?? [];
    this.HasRegisterableServiceGroups = data.HasRegisterableServiceGroups;
    this.FormatedName = data.FormatedName;
    this.HasNotifications = data.HasNotifications;
    this.HasOptOutCoreSelection = data.HasOptOutCoreSelection;
    this.CalendarType = data.CalendarType;
    this.RequireUserGroupCoordApproval = data.RequireUserGroupCoordApproval;
    this.HasSignedTermsOfUse = data.HasSignedTermsOfUse;
    this.IsActiveDirectory = data.IsActiveDirectory;
  }
  /**
   * Generic permissions check on a partial DTO:
   * either it is a user created object
   * @param row Dto partial object
   * @returns true if current user has permissions
   */
  public IsAdminOf(row: IIsAdminOfParams) {
    return (
      this.HasAdminGroupPermissions(row?.ServiceGroupId) ||
      this.HasLabTechGroupPermissions(row?.ServiceGroupId) ||
      this.HasAdminServicePermissions(row.ServiceTypeId, row.ServiceId) ||
      this.HasLabTechServicePermissions(row.ServiceTypeId, row.ServiceId)
    );
  }
  /**
   * Determines if current user is an Admin or Owner of an object
   * @param row some partial DTO
   * @returns
   */
  public IsAdminOrOwnerOf(row: IIsAdminOrOwnerOfParams) {
    const result = this.IsAdminOf(row) || row.UserId === this.Id;
    return result;
  }

  public HasAdminGroupPermissions(serviceGroupId?: number): boolean {
    if (serviceGroupId === undefined) {
      return this.Roles.includes(Roles.Administrators);
    }
    if (this.Roles.includes(Roles.Administrators)) {
      return true;
    }
    if (this.Roles.includes(Roles.GroupAdministrators)) {
      return this.AdminServiceGroups.some(
        f => f.Id === serviceGroupId && f.IsLabTech === false,
      );
    }
    return false;
  }
  public HasAdminServicePermissions(
    serviceTypeId: number,
    serviceId?: number,
  ): boolean {
    if (serviceId === undefined) {
      return this.Roles.includes(Roles.Administrators);
    }
    if (this.Roles.includes(Roles.Administrators)) {
      return true;
    }
    if (
      this.Roles.includes(Roles.GroupAdministrators) ||
      this.Roles.includes(Roles.EquipmentAdministrator)
    ) {
      return this.AdminEquipments.some(
        item =>
          item.Id === serviceId &&
          item.ServiceTypeId === serviceTypeId &&
          item.IsLabTech === false,
      );
    }
    return false;
  }
  public HasCreateAdminGroupPermissions(serviceGroupId?: number): boolean {
    if (serviceGroupId === undefined) {
      return this.Roles.includes(Roles.Administrators);
    }
    if (this.Roles.includes(Roles.Administrators)) {
      return true;
    }
    if (this.Roles.includes(Roles.CreateNewAssetsAdmin)) {
      return this.CreatableGroups.map(item => item.Id).includes(serviceGroupId);
    }
    return false;
  }
  public HasAdminGroupOnlyPermissions(serviceGroupId?: number): boolean {
    if (serviceGroupId === undefined) {
      return false;
    }
    if (this.Roles.includes(Roles.GroupAdministrators)) {
      return this.AdminServiceGroups.some(
        f => f.Id === serviceGroupId && f.IsLabTech === false,
      );
    }
    return false;
  }
  public HasAdminServiceOnlyPermissions(
    serviceTypeId: number,
    serviceId?: number,
  ): boolean {
    if (serviceId === undefined) {
      return false;
    }
    if (
      this.Roles.includes(Roles.GroupAdministrators) ||
      this.Roles.includes(Roles.EquipmentAdministrator)
    ) {
      return this.AdminEquipments.some(
        item =>
          item.Id === serviceId &&
          item.ServiceTypeId === serviceTypeId &&
          item.IsLabTech === false,
      );
    } else {
      return false;
    }
  }
  public HasLabTechGroupPermissions(serviceGroupId?: number): boolean {
    if (serviceGroupId === undefined) {
      return false;
    }
    if (!this.Roles.includes(Roles.LabTech)) {
      return false;
    } else {
      return this.AdminServiceGroups.some(
        f => f.Id === serviceGroupId && f.IsLabTech === true,
      );
    }
  }
  public HasLabTechServicePermissions(
    serviceTypeId: number,
    serviceId?: number,
  ): boolean {
    if (serviceId === undefined) {
      return false;
    }
    if (!this.Roles.includes(Roles.LabTech)) {
      return false;
    } else {
      return this.AdminEquipments.some(
        item =>
          item.Id === serviceId &&
          item.ServiceTypeId === serviceTypeId &&
          item.IsLabTech === true,
      );
    }
  }
  public HasInstrumentEventsGroupPermissions(serviceGroupId?: number): boolean {
    if (serviceGroupId === undefined) {
      return false;
    }
    if (!this.Roles.includes(Roles.InventoryAdmin)) {
      return false;
    } else {
      return this.InstrumentEventsGroups.some(f => f.Id === serviceGroupId);
    }
  }
  public IsAllAdminGroupPermissions(serviceGroups?: number[]): boolean {
    if (serviceGroups === undefined || serviceGroups.length < 1) {
      return this.Roles.includes(Roles.Administrators);
    }
    if (this.Roles.includes(Roles.Administrators)) {
      return true;
    }
    if (this.Roles.includes(Roles.GroupAdministrators)) {
      return serviceGroups.every(f =>
        this.AdminServiceGroups.some(g => g.Id === f && g.IsLabTech === false),
      );
    }
    return false;
  }
  public IsAllServicesAdmin(services?: IServiceTypeFilterDto[]): boolean {
    if (services === undefined || services.length < 1) {
      return this.Roles.includes(Roles.Administrators);
    }
    if (this.Roles.includes(Roles.Administrators)) {
      return true;
    }
    if (
      this.Roles.includes(Roles.GroupAdministrators) ||
      this.Roles.includes(Roles.EquipmentAdministrator)
    ) {
      return services.every(f =>
        this.AdminEquipments.some(
          s =>
            s.Id === f.Id &&
            s.ServiceTypeId === f.ServiceTypeId &&
            s.IsLabTech === false,
        ),
      );
    }
    return false;
  }
  public HasSomeAdminGroupPermissions(serviceGroups?: number[]): boolean {
    if (serviceGroups === undefined || serviceGroups.length < 1) {
      return this.Roles.includes(Roles.Administrators);
    }
    if (this.Roles.includes(Roles.Administrators)) {
      return true;
    }
    if (this.Roles.includes(Roles.GroupAdministrators)) {
      return serviceGroups.some(f =>
        this.AdminServiceGroups.some(g => g.Id === f && g.IsLabTech === false),
      );
    }
    return false;
  }
  public HasSomeAdminServicesPermissions(
    services?: IServiceTypeFilterDto[],
  ): boolean {
    if (services === undefined || services.length < 1) {
      return this.Roles.includes(Roles.Administrators);
    }
    if (this.Roles.includes(Roles.Administrators)) {
      return true;
    }
    if (
      this.Roles.includes(Roles.GroupAdministrators) ||
      this.Roles.includes(Roles.EquipmentAdministrator)
    ) {
      return services.some(f =>
        this.AdminEquipments.some(
          s =>
            s.Id === f.Id &&
            s.ServiceTypeId === f.ServiceTypeId &&
            s.IsLabTech === false,
        ),
      );
    }
    return false;
  }
  public HasSomeLabTechGroupPermissions(serviceGroups?: number[]): boolean {
    if (serviceGroups === undefined || serviceGroups.length < 1) {
      return false;
    }
    if (this.Roles.includes(Roles.LabTech)) {
      return serviceGroups.some(f =>
        this.AdminServiceGroups.some(g => g.Id === f && g.IsLabTech === true),
      );
    }
    return false;
  }
  public HasSomeLabTechServicesPermissions(
    services?: IServiceTypeFilterDto[],
  ): boolean {
    if (services === undefined || services.length < 1) {
      return false;
    }
    if (this.Roles.includes(Roles.LabTech)) {
      return services.some(f =>
        this.AdminEquipments.some(
          s =>
            s.Id === f.Id &&
            s.ServiceTypeId === f.ServiceTypeId &&
            s.IsLabTech === true,
        ),
      );
    }
    return false;
  }
  public IsAllLabTechGroupPermissions(serviceGroups?: number[]): boolean {
    if (serviceGroups === undefined || serviceGroups.length < 1) {
      return false;
    }
    if (this.Roles.includes(Roles.LabTech)) {
      return serviceGroups.every(f =>
        this.AdminServiceGroups.some(g => g.Id === f && g.IsLabTech === true),
      );
    }
    return false;
  }
  public IsAllServicesLabTech(services?: IServiceTypeFilterDto[]): boolean {
    if (services === undefined || services.length < 1) {
      return false;
    }
    if (this.Roles.includes(Roles.LabTech)) {
      return services.every(f =>
        this.AdminEquipments.some(
          s =>
            s.Id === f.Id &&
            s.ServiceTypeId === f.ServiceTypeId &&
            s.IsLabTech === true,
        ),
      );
    }
    return false;
  }
  public IsAllGroupOrLabTechAdmin(
    serviceGroups?: number[],
    services?: IServiceTypeFilterDto[],
  ): boolean {
    return (
      this.IsAllAdminGroupPermissions(serviceGroups) ||
      this.IsAllServicesAdmin(services) ||
      this.IsAllLabTechGroupPermissions(serviceGroups) ||
      this.IsAllServicesLabTech(services)
    );
  }
  public IsAllInstrumentEventsGroupPermissions(
    serviceGroups?: number[],
  ): boolean {
    if (serviceGroups === undefined || serviceGroups.length < 1) {
      return false;
    }

    if (this.Roles.includes(Roles.InventoryAdmin)) {
      return serviceGroups.every(f =>
        this.InstrumentEventsGroups.some(g => g.Id === f),
      );
    }
    return false;
  }
}

export function isInRole(
  user: IAuthenticatedUser | undefined,
  role: string | Roles,
): boolean {
  if (user && user.Roles.length > 0) {
    let baseRoles = enumToArray(Roles);
    let odjRoles = enumToDescriptedArray(Roles);
    if (intersection(user.Roles, baseRoles).length > 0) {
      if (typeof role === 'string') {
        return (
          odjRoles.filter(
            item =>
              item.description.toLowerCase() === (role as string).toLowerCase(),
          ).length > 0
        );
      } else {
        return user.Roles.includes(role as Roles);
      }
    }
  }
  return false;
}

// export function isServiseGroupAdmin(
//   user: IAuthenticatedUser | undefined,
//   ServiceGroups: Identifiable<number>[] | undefined,
// ): boolean {
//   if (
//     user &&
//     ServiceGroups &&
//     ServiceGroups.length > 0 &&
//     user.Roles.indexOf(Roles.GroupAdministrators) >= 0
//   ) {
//     const sg = user.AdminServiceGroups;
//     if (sg) {
//       for (var i = 0; i < sg.length; i++) {
//         const Id = sg[i].Id;
//         if (ServiceGroups.some(item => item.Id === Id)) {
//           return true;
//         }
//       }
//       return false;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }
// export function isAllServiseGroupAdmin(
//   user: IAuthenticatedUser | undefined,
//   ServiceGroups: Entity<number>[] | undefined,
// ): boolean {
//   if (
//     user &&
//     ServiceGroups &&
//     ServiceGroups.length > 0 &&
//     user.Roles.indexOf(Roles.GroupAdministrators) >= 0
//   ) {
//     const sg = user.AdminServiceGroups;
//     if (sg) {
//       if (ServiceGroups.every(item => sg.some(g => g.Id === item.Id))) {
//         return true;
//       }
//       return false;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }
// export function isServiceGroupOrLabTechAdmin(
//   user: IAuthenticatedUser | undefined,
//   Services: number[] | undefined,
//   ServiceGroups: number[] | undefined,
// ): boolean {
//   let result = false;
//   if (user !== undefined) {
//     if (
//       ServiceGroups !== undefined &&
//       user.Roles.includes(Roles.GroupAdministrators)
//     ) {
//       const sg = user.AdminServiceGroups;
//       if (
//         sg !== undefined &&
//         ServiceGroups.every(item => sg.some(g => g.Id === item))
//       ) {
//         result = true;
//       }
//     } else if (user.Roles.includes(Roles.LabTech)) {
//       const sg = user.AdminServiceGroups;
//       const eqs = user.AdminEquipments;
//       let isLabAdmin = false;
//       let isEqAdmin = false;
//       if (ServiceGroups !== undefined) {
//         if (
//           sg !== undefined &&
//           ServiceGroups.every(item => sg.some(g => g.Id === item))
//         ) {
//           isLabAdmin = true;
//         }
//       }
//       if (Services !== undefined) {
//         if (
//           eqs !== undefined &&
//           Services.every(item => eqs.some(eq => eq === item))
//         ) {
//           isEqAdmin = true;
//         }
//       }
//       result = isLabAdmin || isEqAdmin;
//     } else if (user.Roles.includes(Roles.EquipmentAdministrator)) {
//       if (Services !== undefined) {
//         const eqs = user.AdminEquipments;
//         if (
//           eqs !== undefined &&
//           Services.every(item => eqs.some(eq => eq === item))
//         ) {
//           result = true;
//         }
//       }
//     }
//   }
//   return result;
// }
// export function isOnlineServicesAdmin(
//   user: IAuthenticatedUser | undefined,
//   Services: Entity<number>[] | undefined,
// ): boolean {
//   if (
//     user &&
//     Services &&
//     Services.length > 0 &&
//     (user.Roles.indexOf(Roles.Administrators) >= 0 ||
//       user.Roles.indexOf(Roles.GroupAdministrators) >= 0 ||
//       user.Roles.indexOf(Roles.LabTech) >= 0 ||
//       user.Roles.indexOf(Roles.EquipmentAdministrator) >= 0)
//   ) {
//     const eqs: IServiceTypeFilterDto[] | undefined = user.AllAdminEquipments;
//     if (!!eqs && eqs.length > 0) {
//       let result = eqs
//         .filter(f => f.ServiceTypeId === 1)
//         .filter(f => Services.some(item => item.Id === f.Id));
//       return result.length > 0;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }
// export function isServiceAdmin(
//   user: IAuthenticatedUser | undefined,
//   Service: number | undefined,
//   ServiceTypeId: ServiceType,
// ): boolean {
//   if (
//     user &&
//     Service &&
//     (user.Roles.indexOf(Roles.Administrators) >= 0 ||
//       user.Roles.indexOf(Roles.GroupAdministrators) >= 0 ||
//       user.Roles.indexOf(Roles.LabTech) >= 0 ||
//       user.Roles.indexOf(Roles.EquipmentAdministrator) >= 0)
//   ) {
//     if (
//       user.AllAdminEquipments?.some(
//         item => item.Id === Service && item.ServiceTypeId === ServiceTypeId,
//       )
//     ) {
//       return true;
//     }
//     return false;
//   } else {
//     return false;
//   }
// }
// export function isAllServiceAdmin(
//   user: IAuthenticatedUser | undefined,
//   Services: IServiceTypeFilterDto[],
// ): boolean {
//   if (
//     user &&
//     Services.length > 0 &&
//     (user.Roles.indexOf(Roles.Administrators) >= 0 ||
//       user.Roles.indexOf(Roles.GroupAdministrators) >= 0 ||
//       user.Roles.indexOf(Roles.LabTech) >= 0 ||
//       user.Roles.indexOf(Roles.EquipmentAdministrator) >= 0)
//   ) {
//     if (
//       user.AllAdminEquipments?.some(item =>
//         Services.some(
//           f => f.Id === item.Id && f.ServiceTypeId === item.ServiceTypeId,
//         ),
//       )
//     ) {
//       return true;
//     }
//     return false;
//   } else {
//     return false;
//   }
// }
