export const box_shadow_xs: React.CSSProperties['boxShadow'] =
  '0px 1px 2px 0px rgba(16, 24, 40, 0.10), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)';
export const box_shadow_s: React.CSSProperties['boxShadow'] =
  '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)';
export const box_shadow_m: React.CSSProperties['boxShadow'] =
  '0px 4px 4px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)';
export const box_shadow_l: React.CSSProperties['boxShadow'] =
  '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)';
export const box_shadow_xl: React.CSSProperties['boxShadow'] =
  '0px 24px 48px -12px rgba(16, 24, 40, 0.18)';
export const box_shadow_border_top: React.CSSProperties['boxShadow'] =
  '0px 1px 0px 0px #E3E8EF inset';

export const bookitShadows = {
  shadowXS: box_shadow_xs,
  shadowS: box_shadow_s,
  shadowM: box_shadow_m,
  shadowL: box_shadow_l,
  shadowXL: box_shadow_xl,
  shadowBorderTop: box_shadow_border_top,
};
