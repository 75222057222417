import { AsyncDataState } from 'types/common';
import { IRepetitiveDto } from 'types/IRepetitiveDto';
import { FieldHandlerProps } from '../../FormRender/FormRenderer';

/* --- STATE --- */
export interface RepetitiveState extends AsyncDataState<IRepetitiveDto> {
  repetitiveValueHandler?: FieldHandlerProps<IRepetitiveDto>;
}
export enum RepetitiveFor {
  WorkOrder = 1,
  Reservations = 2,
}
