// This module is copied from
// node_modules\date-fns\locale\he\_lib\formatLong\index.js
// and modified to solve the date separator problem for "HE".
// Here the date separator "."  is replaced with "/" for short date case.

var _index = _interopRequireDefault(
  require('date-fns/locale/_lib/buildFormatLongFn/index.js'),
);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var dateFormats = {
  full: 'EEEE, d בMMMM y',
  long: 'd בMMMM y',
  medium: 'd בMMM y',
  short: 'd/M/y',
};
var timeFormats = {
  full: 'H:mm:ss zzzz',
  long: 'H:mm:ss z',
  medium: 'H:mm:ss',
  short: 'H:mm',
};
var dateTimeFormats = {
  full: "{{date}} 'בשעה' {{time}}",
  long: "{{date}} 'בשעה' {{time}}",
  medium: '{{date}}, {{time}}',
  short: '{{date}}, {{time}}',
};
var formatLong = {
  date: (0, _index.default)({
    formats: dateFormats,
    defaultWidth: 'full',
  }),
  time: (0, _index.default)({
    formats: timeFormats,
    defaultWidth: 'full',
  }),
  dateTime: (0, _index.default)({
    formats: dateTimeFormats,
    defaultWidth: 'full',
  }),
};
/*var _default = formatLong;
exports.default = _default;
module.exports = exports.default;*/
export const formatLongHE = formatLong;
