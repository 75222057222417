/**
 *
 * RedirectWithSearch
 *
 */
import * as React from 'react';
import { Redirect, RedirectProps, useLocation } from 'react-router';

export interface RedirectWithSearchProps extends RedirectProps {
  preserveSearch?: boolean;
}

export function RedirectWithSearch({
  preserveSearch = true,

  to,
  ...props
}: RedirectWithSearchProps) {
  const location = useLocation();
  to.search = location.search;
  return <Redirect to={to} {...props} />;
}
