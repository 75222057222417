import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSamplesListSlice } from '../SamplesListPage/slice';
import { ImportSamplePlateForm } from './ImportComponents/PopupImport';
import { ISampleCreateBase } from '../SamplePlatesPage/slice/types';
import { Button } from 'app/components/BasicButtons/Button';
import {
  selectImportingRunDetails,
  selectImportingRunDetailsFinished,
} from '../SamplesListPage/slice/selectors';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

export interface ImportButtonProps {
  onDownloadTemplateButtonClick: React.MouseEventHandler<HTMLButtonElement>;
}

export function ImportButton({
  onDownloadTemplateButtonClick,
}: ImportButtonProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useSamplesListSlice();
  const [open, setOpen] = React.useState(false);
  const importStarted = useSelector(selectImportingRunDetails);
  const importFinished = useSelector(selectImportingRunDetailsFinished);

  const onImportButtonClick = () => {
    dispatch(actions.setShowImportDialog(false));
    setOpen(true);
  };
  const handleConfirm = (item: ISampleCreateBase | null, file: any) => {
    if (file !== undefined) {
      dispatch(actions.setShowImportDialog(true));
      dispatch(actions.readImportFile({ file: file, plate: item }));
    }
  };

  return (
    <>
      <Button onClick={onImportButtonClick}>
        {t(translations.ImportTargetSamples)}
      </Button>
      {open && importFinished === false && (
        <ImportSamplePlateForm
          onSubmit={handleConfirm}
          processing={importStarted}
          accept=".csv"
          onClose={() => setOpen(false)}
          initialValues={{
            IsRack: false,
            ServiceGroup: undefined,
            SamplePlateType: undefined,
            Active: true,
          }}
        />
      )}
    </>
  );
}
