import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { FieldHookConfig, useField } from 'formik';
import {
  InventoryBatchPicker,
  InventoryBatchPickerProps,
} from '../pickers/AutocompletePickers/InventoryBatchesPicker';

export const FormInventoryBatchPicker = (
  props: FieldHookConfig<IInventoryBatchDto | null> &
    Omit<InventoryBatchPickerProps, 'onChange'>,
) => {
  const [field, meta, helpers] = useField<IInventoryBatchDto | null>(props);
  const handleChange = val => {
    props.onChange?.(val);
    helpers.setValue(val, true);
  };
  return (
    <InventoryBatchPicker
      predicates={props.predicates}
      name={props.name}
      placeholder={props.placeholder}
      disabled={props.disabled}
      label={props.label}
      onChange={handleChange}
      onBlur={field?.onBlur}
      value={field.value}
      error={meta?.error !== undefined}
      helperText={meta.error}
      fullWidth={props.fullWidth}
      variant="filled"
    />
  );
};
