import { Entity } from './common';
import { CustomDate } from './CustomDate';

export interface IConnectedFiltersDto {
  StartTime: Date | string;
  EndTime?: Date | string;
  User?: Entity<string> | null;
  UserGroup?: Entity<string> | null;
  Budget?: Entity<number> | null;
  BudgetExperiment?: Entity<number> | null;
  InstituteProject?: Entity<number> | null;
  FundingType?: Entity<number> | null;
  ExternalCustomer?: Entity<number> | null;
  PurchaseOrder?: string | null;
  Reference?: string | null;
}
export const ConnectedFiltersInit = (
  StartTime?: Date | string,
  EndTime?: Date | string,
  User?: Entity<string> | null,
  UserGroup?: Entity<string> | null,
  Budget?: Entity<number> | null,
  BudgetExperiment?: Entity<number> | null,
  InstituteProject?: Entity<number> | null,
  FundingType?: Entity<number> | null,
  ExternalCustomer?: Entity<number> | null,
  PurchaseOrder?: string | null,
  Reference?: string | null,
): IConnectedFiltersDto => {
  return {
    StartTime: StartTime || new CustomDate(new Date()).toISOString(),
    EndTime: EndTime,
    User: User,
    UserGroup: UserGroup,
    Budget: Budget,
    BudgetExperiment: BudgetExperiment,
    InstituteProject: InstituteProject,
    ExternalCustomer: ExternalCustomer,
    FundingType: FundingType,
    PurchaseOrder: PurchaseOrder,
    Reference: Reference,
  };
};
