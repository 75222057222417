import * as React from 'react';
import { Body } from 'app/components/Typography';
import styled from 'styled-components';

export interface FormSectionTitleProps {
  title: React.ReactNode;
  link?: React.ReactNode;
}
const SectionTitle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 4px;
  gap: 16px;
`;
export function FormSectionTitle(props: FormSectionTitleProps) {
  return (
    <SectionTitle>
      <Body bold={true}>{props.title}</Body>
      {props.link !== undefined && props.link}
    </SectionTitle>
  );
}
