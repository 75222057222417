/**
 * Defines what the comment is referencing.
 * Keep synchronized with enum in backend when changing.
 */
export enum CommentReferenceTypes {
  Alerts = 1,
  Invoice = 2,
  UserTraining = 3,
  Budget = 4,
  ServiceRequest = 5,
  ServiceRequestTicket = 6,
  ServiceRequestRow = 7,
  batches = 8,
}
