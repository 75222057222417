import { CommentsApi } from 'api/CommentsApi';
import {
  ConvertNewToComment,
  IComment,
  NewComment,
} from 'app/components/CommentsContainer/IComment';
import { selectPublicUrl } from 'app/slice/selectors';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { WorkOrderDetailsState } from '../slice/types';
import { useAsyncGetWthEffect } from 'app/hooks/useAsyncGetOnMount';
import { CommentsContainer } from 'app/components/CommentsContainer';
import { translations } from 'locales/translations';
import { buildURL } from 'utils/url-utils';
import { Portal } from '@material-ui/core';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { selectCreatedOrders, selectSaveNewComments } from '../slice/selectors';
import { useWorkOrderSlice } from '../slice';

export interface WorkOrderCommentsProps {
  isAdmin?: boolean;
  order: WorkOrderDetailsState;
  disabled?: boolean;
  commentsFormRef: React.MutableRefObject<any>;
  isEdit?: boolean;
  user?: AuthenticatedUser;
  sectionTitle?: React.ReactNode;
}

export const WorkOrderComments = (props: WorkOrderCommentsProps) => {
  const {
    order,
    isAdmin,
    disabled,
    commentsFormRef,
    isEdit,
    user,
    sectionTitle,
  } = props;
  const publicUrl = useSelector(selectPublicUrl);
  const { t } = useTranslation();
  const { newDate } = useSystemDate();
  const dispatch = useDispatch();
  const { actions: workOrderActions } = useWorkOrderSlice();
  const doSaveComments = useSelector(selectSaveNewComments);
  const createdOrders = useSelector(selectCreatedOrders);
  const [changed, setchanged] = React.useState(false);
  const [newSavedComments, setNewSavedComments] = React.useState<NewComment[]>(
    [],
  );
  const [newComments, setNewComments] = React.useState<IComment[]>([]);
  const comments = useAsyncGetWthEffect<IComment[]>(
    () => CommentsApi.GetComments(CommentReferenceTypes.Alerts, order.Id),
    [],
    [changed],
  );
  const saveComments = async (
    comments: NewComment[],
    referenceIds: number[],
  ): Promise<any> => {
    var result = await Promise.all(
      comments.map(async item => {
        referenceIds.map(refId => {
          try {
            return CommentsApi.SendComment(
              CommentReferenceTypes.Alerts,
              refId,
              item,
            );
          } catch {
            return item;
          }
        });
      }),
    );
    return result;
  };
  const bindSubmitComment = React.useCallback(
    submitForm => {
      commentsFormRef.current = submitForm;
    },
    [commentsFormRef],
  );
  React.useEffect(() => {
    if (doSaveComments === true && createdOrders.length > 0) {
      saveComments(newSavedComments, createdOrders);
      dispatch(workOrderActions.setSaveNewComments(undefined));
      dispatch(workOrderActions.setOrderNumbers([]));
    }
  }, [
    createdOrders,
    dispatch,
    doSaveComments,
    newSavedComments,
    workOrderActions,
  ]);

  const portalRef = React.useRef(null);
  return (
    <React.Fragment>
      <div ref={portalRef} style={{ width: '100%' }} />
      <Portal container={portalRef.current}>
        <CommentsContainer
          disabled={disabled}
          Comments={isEdit ? comments : newComments}
          sectionTitle={sectionTitle}
          bindSubmitComment={bindSubmitComment}
          onSubmit={c => {
            if (!disabled) {
              if (isEdit) {
                CommentsApi.SendComment(
                  CommentReferenceTypes.Alerts,
                  order.Id,
                  c,
                ).finally(() => setchanged(prev => !prev));
              } else {
                setNewSavedComments(() => [...newSavedComments, c]);
                setNewComments(() => [
                  ...newComments,
                  ConvertNewToComment(c, newComments.length + 1, newDate(), {
                    Id: user?.Id || '',
                    Name: user?.Name || '',
                  }),
                ]);
              }
            }
          }}
          isAdmin={isAdmin}
          withMentions
          pageName={t(translations.menu_EditWorkOrder)}
          referenceName={order.Id.toString()}
          linkUrl={buildURL(`${publicUrl}workorders/details/${order.Id}`, {})}
          emailType={'Alert'}
        />
      </Portal>
    </React.Fragment>
  );
};
