/**
 *
 * RadioGroup
 *
 */
import {
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@material-ui/core';
import * as React from 'react';
import { Entity } from 'types/common';
import { Check } from '../BasicInputs/Check';
import FormControlLabel from '../Forms/FormControls/FormControlLabel';

export interface CheckEntity extends Entity<number> {
  checked: boolean;
  disabled?: boolean;
}
export interface ChipEntity extends Entity<number> {
  info?: string;
  disabled?: boolean;
}
export interface CheckBoxGroupProps {
  options: CheckEntity[];
  value?: CheckEntity[];
  className?: string;
  onChange?: (
    value: CheckEntity,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  id: string;
  //formik
  name?: string;
  error?: boolean;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  info?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  margin?: 'none' | 'dense' | 'normal';
  isRow?: boolean;
}

export function CheckBoxGroup({
  name,
  id,
  value,
  options,
  onChange,
  label,
  required,
  helperText,
  disabled,
  fullWidth,
  margin,
  error,
  size,
  isRow,
  ...props
}: CheckBoxGroupProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = {
      Id: parseInt(event.target.id),
      Name: event.target.name,
      checked: event.target.checked,
    } as CheckEntity;
    if (onChange !== undefined) {
      onChange(value, event);
    }
  };
  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      name={name}
      margin={margin}
      id={id}
      fullWidth={fullWidth}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup row={isRow}>
        {options.map((item, index) => (
          <FormControlLabel
            value={item}
            key={item.Id}
            disabled={disabled || item.disabled}
            labelPlacement="top"
            control={
              <Check
                key={item.Id}
                id={item.Id.toString()}
                checked={item.checked}
                name={item.Name}
                onChange={handleChange}
                size={size}
                color="primary"
              />
            }
            label={item.Name}
          />
        ))}
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
    //  <MuiRadioGroup
    //    key={id}
    //    aria-label={id}
    //    name={name}
    //    value={value || ''}
    //    onChange={handleChange}
    //  >
    //    {options.map((option, index) => (
    //      <span key={option.Id}>
    //        <FormControlLabel
    //          key={option.Id}
    //          value={option.Id}
    //          control={<Radio key={option.Id} />}
    //          label={option.Name}
    //          disabled={props.disabled}
    //        />
    //        {(option as any).Info && (
    //          <Tooltip key={option.Id + index} title={(option as any).Info}>
    //            <span>
    //              <Icon
    //                key={option.Id}
    //                icon="info-circle"
    //                color="default"
    //                colorExtend="textHover"
    //              />
    //            </span>
    //          </Tooltip>
    //        )}
    //      </span>
    //    ))}
    //  </MuiRadioGroup>
  );
}
