import { httpClient } from 'api/HttpClient';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { Entity } from 'types/common';

export interface ServiceUserGroupPickerProps
  extends Omit<AutocompletePickerProps<Entity<string>>, 'loadData'> {}

const url = '/api/odata/v4/ServiceUserGroupFilter';
export const initServiceUserGroupData = (initval: string) => {
  const params: {
    $orderby: string;
    $filter?: string;
    $select: string;
    $top: number;
    $skip: number;
  } = {
    $orderby: 'Name asc',
    $filter: new Condition<Entity<string>>(
      'Id',
      ODataOperators.Equals,
      initval,
    ).toString(),
    $select: 'Id,Name',
    $skip: 0,
    $top: 1,
  };
  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<string>[]);
};
const loadData = (
  searchTerm: string | null,
  predicates?: (string | Condition<Entity<string>>)[],
) => {
  const params: { $orderby: string; $filter?: string } = {
    $orderby: 'Name asc',
  };
  if (searchTerm !== null || (predicates && predicates.length > 0)) {
    params.$filter = new ODataFilterBuilder<Entity<string>>({
      predicates: predicates ?? [],
      stringColumns: ['Name'],
      stringSearch: searchTerm ?? '',
    }).toString();
  }

  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<string>[]);
};
export function ServiceUserGroupPicker(props: ServiceUserGroupPickerProps) {
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      id={props.id || 'serviceusergroupId'}
      size={props.size}
      loadData={loadData}
      {...props}
    />
  );
}
