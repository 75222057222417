import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.calendarMessagesDetail || initialState;

export const selectCalendarMessagesDetails = createSelector(
  [selectSlice],
  state => state,
);
//====================================================================
export const selectCreateCalendarMessagesDetails = createSelector(
  [selectCalendarMessagesDetails],
  state => state.createState.data,
);

export const selectCreateCalendarMessagesDetailsCompleted = createSelector(
  [selectCalendarMessagesDetails],
  state => state.createState.completed,
);

export const selectCreateCalendarMessagesDetailsHasError = createSelector(
  [selectCalendarMessagesDetails],
  state => state.createState.hasErrors,
);
//====================================================================
export const selectUpdateCalendarMessagesDetails = createSelector(
  [selectCalendarMessagesDetails],
  state => state.updateState.data,
);

export const selectUpdateCalendarMessagesDetailsCompleted = createSelector(
  [selectCalendarMessagesDetails],
  state => state.updateState.completed,
);
export const selectUpdateCalendarMessagesDetailsHasError = createSelector(
  [selectCalendarMessagesDetails],
  state => state.updateState.hasErrors,
);
//====================================================================
export const selectCalendarMessagesDetailsProcessing = createSelector(
  [selectCalendarMessagesDetails],
  state => state.processing,
);
