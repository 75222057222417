/**
 *
 * CalendarHeader
 *
 */
import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ViewLengths, ViewTypes } from '../../slice/types';
import { Timestamp } from 'types/Timestamp';
import { dateUtils } from 'utils/date-utils';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Button } from 'app/components/BasicButtons/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useSchedulerSlice } from '../../slice';
import { useLocation } from 'react-router';
import { ValueChangeHandler } from 'types/ValueChangeHandler';
import { getLogger } from 'utils/logLevel';
import { FiltersComponent } from 'app/pages/CalendarPage/FiltersComponent';
import { IWithSavedHistoryComponentProps } from 'app/components/BasicTable/withSavedHistory';
import { MultiToggle } from '../MultiToggle';
import {
  selectMultipleModeState,
  selectSchedulerViewCustomStart,
  selectSchedulerViewType,
} from '../../slice/selectors';
import { Box } from 'app/components/basic/Wrappers/Box';
import { FiltersGroupDisplay } from 'app/components/BasicTable/BasicFilter/FiltersGroupDisplay';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { TableToolbar } from 'app/components/BasicTable/BasicFilter/TableToolbar';
import { FilterIconButton } from 'app/components/BasicButtons/FilterIconButton';
import { assertExhaustive } from 'utils/assertExhaustive';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { ReactActions } from 'app/components/ReactActions';
import {
  selectAuthenticatedUser,
  selectGlobalSetting,
  selectIsFullScreen,
  selectKnownModule,
} from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { FullScreenSchedulerButton } from '../FullScreenSchedulerButton';
import { CalendarActionRenderer } from '../CalendarToolbar/CalendarActionRenderer';
import { AddReservation } from 'app/pages/Actions/PageTopActions/AddReservation';
import { ViewTypeLengthDropDown } from '../ViewTypeLengthDropDown';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { getPeriodFromViewLength, viewStateFromFilter } from '../../utils';
import { CombinedSchedulerTRow } from 'app/pages/CalendarPage/Filter';
import { CalendarDatePicker, CalendarDateValue } from '../CalendarDatePicker';
import { IsReadOnlyUser } from 'app/permissions/WorkOrders/workOrdersPermissionUtils';
import { SchedulerDatePositionUnion } from 'app/components/BasicTable/Filters/DatesPeriodFilter';
import { tryParseBool } from 'utils/string-utils';
import { KnownModules } from 'types/KnownModules';
import { AllowedSettings } from 'utils/globalSettings';
import { TimelineGroupByUnion } from 'api/odata/generated/enums/TimelineGroupBy';
import { TimelineGroupByPicker } from '../TimelineGroupByPicker';
import { ExportToPDF } from '../ExportToPDF';

export interface CalendarHeaderProps
  extends IWithSavedHistoryComponentProps<CombinedSchedulerTRow> {
  viewType: ViewTypes;
  viewLength: ViewLengths;
  date: Timestamp;
  onMultipleModeChange: ValueChangeHandler<boolean>;
  //range: Range<Timestamp | undefined>;
  filtersButtonAnchorEl: HTMLElement | null;
  filterRef?: React.RefObject<HTMLButtonElement>;
  onFilterOpen: () => void;
  filterOpen: boolean;
  onFilterClose: () => void;
  withoutHeader?: boolean;
  noChangeLocation?: boolean;
  noTypeChange?: boolean;
  haideCalendarViewType?: boolean;
  pageActions?: CalendarActionRenderer[];
  setFilterValue?: (id: string, value: FilterValueType) => void;
  showPeriodPicker?: boolean;
  customDays?: number;
  customDaysEnabled?: boolean;
  twoDaysEnabled?: boolean;
  handleSetPeriodFilter: (length: ViewLengths, value?: Date) => void;
  handleViewTypeLengthChange: (
    type: ViewTypes,
    length: ViewLengths,
    value?: Date,
    noChangeLocation?: boolean,
  ) => void;
  updateSearchPeriod: (
    preset: SchedulerDatePositionUnion | null,
    search?: string,
  ) => string | undefined;
  onRefresh?: () => void;
  loading: boolean;
}

export function CalendarHeader({
  customDays,
  handleSetPeriodFilter,
  handleViewTypeLengthChange,
  updateSearchPeriod,
  showPeriodPicker = true,
  customDaysEnabled = false,
  twoDaysEnabled = false,
  ...props
}: CalendarHeaderProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const log = React.useMemo(() => getLogger('CalendarHeader'), []);
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  const classes = useStyles({ isMobile: isMobile });
  const dispatch = useDispatch();
  const location = useLocation();
  const { actions } = useSchedulerSlice();
  const multipleMode = useSelector(selectMultipleModeState);
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const isFullScreen = useSelector(selectIsFullScreen);
  const hasCustomStart = useSelector(selectSchedulerViewCustomStart);
  const { newDate } = useSystemDate();
  useEffectOnMount(() => {
    dispatch(actions.setNotUseLocation(props.noChangeLocation));
  });
  const handleCalendarDateChange: ValueChangeHandler<CalendarDateValue> = value => {
    dispatch(
      actions.changeViewMode({
        current: {
          viewType: props.viewType,
          viewLength: value.viewLength,
          date: dateUtils.formatISO(dateUtils.dateOrStringToDate(value.date)),
          preset: null,
          search: location.search,
          customDays: customDaysEnabled ? customDays : undefined,
          customStart: value.customStart,
        },
        withNoChangeLocation: props.noChangeLocation,
      }),
    );
  };
  const moveToDate = React.useCallback(
    (value: Date, viewLength?: ViewLengths) => {
      let presetValue = getPeriodFromViewLength(
        viewLength ?? props.viewLength,
        value ?? dateUtils.parseISO(props.date),
        customDays,
      );
      handleSetPeriodFilter(viewLength ?? props.viewLength, value);
      dispatch(
        actions.changeViewMode({
          current: {
            viewType: props.viewType,
            viewLength: viewLength ?? props.viewLength,
            date: dateUtils.formatISO(value),
            preset: viewStateFromFilter(presetValue.type)?.preset ?? null,
            search:
              updateSearchPeriod(
                viewStateFromFilter(presetValue.type)?.preset ?? null,
                location.search,
              ) ?? location.search,
            customDays: customDays,
            customStart: hasCustomStart,
          },
          withNoChangeLocation: props.noChangeLocation,
        }),
      );
    },
    [
      actions,
      customDays,
      dispatch,
      handleSetPeriodFilter,
      hasCustomStart,
      location.search,
      props.date,
      props.noChangeLocation,
      props.viewLength,
      props.viewType,
      updateSearchPeriod,
    ],
  );
  const move = React.useCallback(
    (a: 'next' | 'prev') => {
      const diff = a === 'next' ? 1 : a === 'prev' ? -1 : undefined;
      if (diff === undefined) {
        return;
      }
      const customDiff =
        props.viewLength === 'day'
          ? diff
          : props.viewLength === 'twodays'
          ? a === 'next'
            ? 2
            : -2
          : props.viewLength === 'customdays'
          ? a === 'next'
            ? customDays ?? 2
            : -(customDays ?? 2)
          : props.viewLength === 'week'
          ? diff
          : props.viewLength === 'month'
          ? diff
          : props.viewLength === 'quarter'
          ? diff
          : props.viewLength === 'year'
          ? diff
          : undefined;

      const addDateFn =
        props.viewLength === 'day'
          ? dateUtils.addDays
          : props.viewLength === 'twodays'
          ? dateUtils.addDays
          : props.viewLength === 'customdays'
          ? dateUtils.addDays
          : props.viewLength === 'week'
          ? dateUtils.addWeeks
          : props.viewLength === 'month'
          ? dateUtils.addMonths
          : props.viewLength === 'quarter'
          ? dateUtils.addQuarters
          : props.viewLength === 'year'
          ? dateUtils.addYears
          : undefined;
      if (addDateFn === undefined) {
        return undefined;
      }
      const newDate = addDateFn(
        dateUtils.parseISO(props.date),
        customDiff || diff,
      );
      return moveToDate(newDate);
    },
    [customDays, moveToDate, props.date, props.viewLength],
  );
  const handleMultipleModeChange: ValueChangeHandler<boolean> = value => {
    props.onMultipleModeChange(value);
  };
  const handlePreviousPeriodClick = () => {
    move('prev');
  };

  const handleNextPeriodClick = () => {
    move('next');
  };

  const handleTodayClick = value => {
    handleSetPeriodFilter('day');
    dispatch(
      actions.changeViewMode({
        current: {
          viewType: props.viewType,
          viewLength: 'day',
          date: dateUtils.formatISO(newDate()),
          preset: 'Today',
          search:
            updateSearchPeriod('Today', location.search) ?? location.search,
          customDays: undefined,
          customStart: undefined,
        },
        withNoChangeLocation: props.noChangeLocation,
        forceEvents: true,
      }),
    );
  };
  const handleFilterChange = (
    filters: IFilterSettings<CombinedSchedulerTRow>[],
  ) => {
    props.onFilterChange?.(filters);
  };

  const getTitle = (viewType: ViewTypes) => {
    switch (viewType) {
      case 'calendar':
        return t(translations.Calendar);
      case 'timeline':
        return t(translations.Timeline);
      default:
        assertExhaustive(viewType);
    }
  };

  const lengthOptions = React.useMemo(() => {
    let lengths: Array<ViewLengths> = ['day'];
    if (twoDaysEnabled) {
      lengths.push('twodays');
    }
    if (customDaysEnabled) {
      lengths.push('customdays');
    }
    lengths.push('week');
    lengths.push('month');
    if (!isMobile) {
      lengths.push('quarter');
      lengths.push('year');
    }
    return lengths;
  }, [customDaysEnabled, isMobile, twoDaysEnabled]);

  const viewType = useSelector(selectSchedulerViewType);
  const AssetCatEnabled = useSelector(
    state =>
      tryParseBool(
        selectGlobalSetting(state, AllowedSettings.AssetCatEnabled),
      ) === true,
  );
  const EquipmentAssembliesEnabled = useSelector(
    state =>
      selectKnownModule(state, KnownModules.EquipmentAssemblies) === true,
  );

  const TimelineGroupByEnumsArray = React.useMemo(() => {
    const result: TimelineGroupByUnion[] = [];
    if (EquipmentAssembliesEnabled) {
      result.push('GroupByAssemblyPart');
    }
    if (AssetCatEnabled) {
      result.push('GroupByAssetCat');
    }
    if (result.length === 0) {
      return undefined;
    } else {
      result.unshift('NoTimelineGrouped');
    }
    return result;
  }, [AssetCatEnabled, EquipmentAssembliesEnabled]);

  // page level actions shown behind kebab menu
  const secondaryPageActions: CalendarActionRenderer[] = React.useMemo(() => {
    const calendarActions: CalendarActionRenderer[] = [];
    // const hasCalendarAndOtherRoles =
    //   authenticatedUser?.Roles?.includes(Roles.CalendarOnly) &&
    //   authenticatedUser?.Roles?.length > 1;
    if (TimelineGroupByEnumsArray !== undefined && viewType === 'timeline') {
      calendarActions.push(() => (
        <TimelineGroupByPicker EnumNamesArray={TimelineGroupByEnumsArray} />
      ));
    }

    if (!authenticatedUser?.Roles?.includes(Roles.CalendarOnly)) {
      calendarActions.push(() => <FullScreenSchedulerButton />);
    }
    if (!isMobile) {
      calendarActions.push(() => (
        <ExportToPDF
          onClick={() => {
            dispatch(actions.exportToPDF());
          }}
        />
      ));
    }

    return calendarActions.concat(props.pageActions ?? []);
  }, [
    TimelineGroupByEnumsArray,
    actions,
    authenticatedUser?.Roles,
    dispatch,
    isMobile,
    props.pageActions,
    viewType,
  ]);
  const primaryPageActions: CalendarActionRenderer[] = React.useMemo(() => {
    const actions: CalendarActionRenderer[] = [];
    if (isMobile && props.availableFilters !== undefined) {
      actions.push(() => (
        <FilterIconButton
          filterLength={props.appliedFilters?.length ?? 0}
          handleClick={props.onFilterOpen}
          innerRef={props.filterRef}
        />
      ));
    }
    if (!isFullScreen && isMobile && !IsReadOnlyUser(authenticatedUser)) {
      actions.push(() => (
        <AddReservation text={t(translations.AddReservation)} variant="main" />
      ));
    }

    return actions;
  }, [
    authenticatedUser,
    isFullScreen,
    isMobile,
    props.appliedFilters?.length,
    props.availableFilters,
    props.filterRef,
    props.onFilterOpen,
    t,
  ]);
  const handleRefresh = () => {
    props.onRefresh?.();
  };
  return (
    <>
      {!props.withoutHeader && (
        <TableToolbar
          screenName={getTitle(props.viewType)}
          variant={isFullScreen ? 'dense' : 'regular'}
          onRefresh={handleRefresh}
          loading={props.loading}
        >
          {primaryPageActions.length > 0 &&
            primaryPageActions.map((item, index) => (
              <span key={index}>{item()}</span>
            ))}
          {authenticatedUser?.Roles?.includes(Roles.CalendarOnly) &&
            !isFullScreen && <FullScreenSchedulerButton />}
          {secondaryPageActions.length > 0 &&
            !(
              authenticatedUser?.Roles?.includes(Roles.CalendarOnly) &&
              isFullScreen
            ) && (
              <ReactActions
                id="CalendarHeaderReactActions"
                keepOpen /** keepOpen is used here to prevent the menu from closing when selecting the timeline grouping option */
                disableRipple /** the ripple over the "timeline group by" dropdown is too confusing  */
                items={secondaryPageActions.map((item, index) => (
                  <React.Fragment key={index}>{item()}</React.Fragment>
                ))}
              />
            )}
        </TableToolbar>
      )}
      <Box className={classes.root}>
        <Box className={classes.toolbar}>
          {/* hide the menu in fullscreen mode since it's not functional (autocompletes do not show up) */}
          {!isFullScreen && props.availableFilters !== undefined && (
            <>
              {isMobile ? (
                <FiltersComponent
                  appliedFilters={props.appliedFilters}
                  availableFilters={props.availableFilters}
                  onFilterChange={handleFilterChange}
                  key="timeline-filter"
                  fullWidth
                  filterOpen={props.filterOpen}
                  filterRef={props.filterRef}
                  onFilterOpen={props.onFilterOpen}
                  onFilterClose={props.onFilterClose}
                  getDefaultFilters={props.getDefaultFilters}
                  filtersButtonAnchorEl={props.filtersButtonAnchorEl}
                  savedListSettings={{
                    enableSavedLists: true,
                    savedListRelatedPickerName: 'Id',
                    savedListSerializedKey: 'eid',
                  }}
                />
              ) : (
                <Box className={classes.otherControls}>
                  <FiltersComponent
                    appliedFilters={props.appliedFilters}
                    availableFilters={props.availableFilters}
                    onFilterChange={handleFilterChange}
                    key="timeline-filter"
                    fullWidth
                    filterOpen={props.filterOpen}
                    filterRef={props.filterRef}
                    onFilterOpen={props.onFilterOpen}
                    onFilterClose={props.onFilterClose}
                    getDefaultFilters={props.getDefaultFilters}
                    filtersButtonAnchorEl={props.filtersButtonAnchorEl}
                    savedListSettings={{
                      enableSavedLists: true,
                      savedListRelatedPickerName: 'Id',
                      savedListSerializedKey: 'eid',
                    }}
                  />
                </Box>
              )}
            </>
          )}
          <Box className={classes.periodControls}>
            <IconButton
              variant="ghost"
              shape="square"
              size={'small'}
              title={translations.Previous}
              onClick={handlePreviousPeriodClick}
            >
              <Icon icon="chevron-left" />
            </IconButton>
            <CalendarDatePicker
              viewLength={props.viewLength}
              date={props.date}
              open={false}
              size="small"
              onChange={handleCalendarDateChange}
              compact={isMobile}
              customDays={customDays}
              customStart={hasCustomStart}
            />
            {props.viewType === 'calendar' && !isMobile && (
              <MultiToggle
                value={multipleMode}
                onchange={handleMultipleModeChange}
              />
            )}
            <IconButton
              title={t(translations.Today)}
              onClick={handleTodayClick}
              variant="ghost"
              shape="square"
              size={'small'}
            >
              <Icon icon="home" />
            </IconButton>
            <IconButton
              title={t(translations.Next)}
              onClick={handleNextPeriodClick}
              variant="ghost"
              size={'small'}
              shape="square"
            >
              <Icon icon="chevron-right" />
            </IconButton>
            {/* todo: implement settings */}
            {false && (
              <IconButton
                title={t(translations.Settings)}
                variant="ghost"
                shape="square"
                size={'small'}
              >
                <Icon icon="cog" />
              </IconButton>
            )}
            {/* todo: implement "save selection" */}
            {false && (
              <Button
                variant="ghost"
                size={'small'}
                title={t(translations.SaveSelection)}
              >
                {t(translations.SaveSelection)}
              </Button>
            )}
          </Box>
          {showPeriodPicker && (
            <Box className={classes.viewTypeControls}>
              <ViewTypeLengthDropDown
                viewType={props.viewType}
                viewLength={props.viewLength}
                compact={isMobile}
                size="small"
                haideCalendarViewType={props.haideCalendarViewType}
                onTypeLengthChange={(type, length, value) =>
                  handleViewTypeLengthChange(
                    type,
                    length,
                    value,
                    props.noChangeLocation,
                  )
                }
                customDays={customDays}
                customDaysEnabled={customDaysEnabled}
                twoDaysEnabled={twoDaysEnabled}
                lengthOptions={lengthOptions}
              />
            </Box>
          )}
        </Box>
        {!isMobile &&
          props.availableFilters !== undefined &&
          (props.appliedFilters ?? []).length > 0 && (
            <Box component="div" className={classes.filterDisplayRoot}>
              <FiltersGroupDisplay
                appliedFilters={props.appliedFilters ?? []}
                onChange={handleFilterChange}
                openFilter={props.onFilterOpen}
              />
            </Box>
          )}
      </Box>
    </>
  );
}

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: 8,
      gap: 8,
    },
    column: {
      display: 'flex',
      width: 'auto', //props => timelineColumnWidth(props.isMobile),
      alignItems: 'center',
    },
    toolbar: {
      flex: 1,
      display: 'flex',
      padding: props => (props.isMobile ? '8px 0px' : '0px'),
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        gap: 0,
        // flexDirection: 'column',
        // alignItems: 'flex-start',
      },
    },
    left: {
      // flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    right: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    periodControls: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      gap: props => (props.isMobile ? theme.spacing(0.5) : theme.spacing(1)),
    },
    viewTypeControls: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    otherControls: {},
    search: {
      width: 150,
    },
    filterDisplayRoot: {
      gap: 8,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  }),
);
