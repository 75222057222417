import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { CheckEntity } from 'app/components/CheckBoxGroup';
import { Entity, Identifiable } from 'types/common';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import * as Yup from 'yup';

export const EntityNumberSchema: Yup.SchemaOf<Entity<number>> = Yup.object({
  Id: Yup.number().required(),
  Name: Yup.string().ensure(),
});
export const EntityStringSchema: Yup.SchemaOf<Entity<string>> = Yup.object({
  Id: Yup.string().required(),
  Name: Yup.string().ensure(),
});
export const IdentifiableNumberSchema: Yup.SchemaOf<Identifiable<
  number
>> = Yup.object({
  Id: Yup.number().required(),
});
export const IdentifiableStringSchema: Yup.SchemaOf<Identifiable<
  string
>> = Yup.object({
  Id: Yup.string().required(),
});
export const CheckEntityShema: Yup.SchemaOf<CheckEntity> = Yup.object({
  Id: Yup.number().defined(),
  Name: Yup.string().ensure(),
  checked: Yup.boolean().default(false),
  disabled: Yup.boolean().default(undefined),
});
const inventoryBatchesSchema = Yup.array().of(
  Yup.mixed() as Yup.SchemaOf<IInventoryBatchDto>,
);
export const OfflineServiceTypeShema: Yup.SchemaOf<IOfflineServiceFilterDto> = Yup.object(
  {
    Id: Yup.number().defined(),
    Name: Yup.string().ensure(),
    ServiceGroupId: Yup.number().defined(),
    ServiceTypeId: Yup.number().defined().default(2),
    Active: Yup.boolean().default(true),
    HideProjects: Yup.boolean().default(false),
    IntQuantityOnly: Yup.boolean().default(false),
    UnitTypeID: Yup.number().nullable().default(null),
    UnitTypeName: Yup.string().ensure(),
    AllowToUser: Yup.boolean().default(false),
    Inventory: Yup.number().defined().default(1),
    DefaultQuantity: Yup.number().nullable().notRequired().default(null),
    ReservationOverlappingInventory: Yup.number()
      .nullable()
      .notRequired()
      .default(null),
    IsAdmin: Yup.boolean().default(false),
    AllowedBudgets: Yup.array().of(Yup.number().required()),
    StaffOnly: Yup.boolean().default(false),
    BudgetsTurnedOn: Yup.boolean().default(false),
    NotLessThanZero: Yup.boolean().default(false),
    TrackInventory: Yup.boolean().default(false),
    InventoryBatchesEnabled: Yup.boolean().default(false),
    InventoryBatches: inventoryBatchesSchema,
  },
);
export const ServiceGroupEntitySchema = EntityNumberSchema;
export const BudgetEntitySechema = EntityNumberSchema;
export const BudgetExperimentEntitySechema = EntityNumberSchema;
export const InstituteProjectEntitySechema = EntityNumberSchema;
export const PaymentMethodEntitySchema = EntityNumberSchema;
export const UserEntitySchema = EntityStringSchema;
export const OnlineServiceEntitySchema = EntityNumberSchema;
export const OfflineServiceTypeEntitySchema = EntityNumberSchema;
export const FeeTypeEntitySchema = EntityNumberSchema;
export const UserGroupEntitySchema = EntityStringSchema;
export const ServiceTypeSchema = EntityNumberSchema;
