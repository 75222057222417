import { SuperGroupPicker } from 'app/components/pickers/AutocompletePickers/SuperGroupPicker';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const SuperGroupFilter = (props: IFilterComponentProps) => {
  const handleChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <SuperGroupPicker
      onChange={handleChange}
      value={props.value}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      ariaLabel={props.label}
      id={props.id}
      size="small"
      predicates={props.predicates}
      onPickerOpen={props.onOpen}
    />
  );
};
