import { EditorProps } from '../../EditableCell';
import { tryParseFloat } from 'utils/string-utils';
import { TextField as BasicTextField } from 'app/components/TextField';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import { InputAdornment } from '@material-ui/core';
import { Icon } from 'app/components/BasicIcons/FontAwesome';

/* Project Name */
// 'Number':

export function NumberField({
  value,
  onChange,
  parentValue,
  min,
  max,
  step,
  helperText,
  info,
  error,
  autoFocus,
  ...props
}: EditorProps<number | null> & MinMaxProps) {
  return (
    // <StyledInput
    //   type="number"
    //   value={value || ''}
    //   onChange={e => onChange(tryParseFloat(e.target.value) ?? null)}
    //   {...props}
    // />
    <BasicTextField
      className={'textTable'}
      InputProps={{
        disableUnderline: true,
        endAdornment: ((!!info && info !== '') || error) && (
          <InputAdornment position="end">
            {!!info && info !== '' && <InfoIcon title={info} error={error} />}
            {error && (
              <Icon
                icon="info-circle"
                color="danger"
                colorExtend="textHover"
                fontSize="13px"
                width="13px"
              />
            )}
          </InputAdornment>
        ),
      }}
      variant="standard"
      error={error}
      fullWidth
      autoFocus={autoFocus}
      inputProps={{
        type: 'number',
        min: min,
        max: max,
        step: step,
      }}
      value={value || ''}
      onChange={e => onChange(tryParseFloat(e.target.value) ?? null)}
      onBlur={props.onBlur}
      id={props.id}
      label={props.label}
      name={props.name}
    />
  );
}

interface MinMaxProps {
  min?: number;
  max?: number;
  step?: number;
}

export function withMinMax(Component, withProps: MinMaxProps) {
  return (props: EditorProps<number>) => (
    <Component {...withProps} {...props} />
  );
}
