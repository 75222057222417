/**
 *
 * BooleanPicker
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { AutocompletePickerProps } from '../BasicPickers/AutocompletePicker';
import { Entity } from 'types/common';
import { TFunction } from 'i18next';
import { BaseOptionsPicker } from '../BasicPickers/BaseOptionsPicker';

export interface BooleanPickerProps
  extends Omit<AutocompletePickerProps<Entity<boolean>>, 'loadData'> {
  trueName?: string;
  falseName?: string;
}
export const GetBooleanFilterOptions = (
  t: TFunction,
  trueName?: string,
  falseName?: string,
) => {
  return [
    {
      Id: true,
      Name: !!trueName ? trueName : t(translations.Yes),
    },
    { Id: false, Name: !!falseName ? falseName : t(translations.No) },
  ];
};
export function BooleanPicker(props: BooleanPickerProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const getOptions = () =>
    GetBooleanFilterOptions(t, props.trueName, props.falseName);
  return (
    <>
      <BaseOptionsPicker getOptions={getOptions} {...props} />
    </>
  );
}
