import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { Icon, IconProps } from 'app/components/BasicIcons/FontAwesome';
import {
  ReservationStatus,
  ReservationStatusUnion,
} from 'app/pages/ReservationDetails/Details/slice/types';
import { assertExhaustive } from 'utils/assertExhaustive';
import { ISchedulerEvent } from '../../types/ISchedulerEvent';
// import { getReservationIconName } from './getReservationIconName';

export interface ReservationStatusContentProps extends Omit<IconProps, 'icon'> {
  event: ISchedulerEvent;
}
export function ReservationStatusIcon({
  event,
  ...props
}: ReservationStatusContentProps) {
  const reservation = event.original as ICalendarReservationDto;
  const statusId = reservation.StatusId;
  if (statusId === undefined) {
    return null;
  }
  if (statusId === null) {
    return null;
  }
  const reservationStatus = ReservationStatus[
    statusId
  ] as ReservationStatusUnion;
  switch (reservationStatus) {
    case 'Pending':
    case 'PendingCoord':
      return <Icon icon={['far', 'comment-question']} {...props} />;
    case 'Draft':
      return <Icon icon={['far', 'circle-exclamation']} {...props} />;
    case 'Approved':
      if (reservation.Tutoring) {
        return <Icon icon={['far', 'circle-check']} {...props} />;
      }
      return null;
    case 'Cancelled':
    case 'AllStatus':
    case 'CancelledByAdmin':
    case 'Done':
    case 'NotDone':
    case 'DoneWOBooking':
    case 'ApprovedOnce':
    case 'Tentative':
      return null;
    default:
      assertExhaustive(reservationStatus);
  }
  // const iconName =  getReservationIconName(
  //   ReservationStatus[statusid] as ReservationStatusUnion,
  //   reservation.Tutoring,
  // );

  // if (iconName === undefined) {
  //   return null;
  // }

  // return <Icon icon={['far', iconName]} {...props} />;
}
