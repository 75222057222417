import {
  SendEmailOnOffline,
  WorkOrderQueryStringParameters,
} from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details/slice/types';
import { Identifiable } from 'types/common';
import { CustomDate } from 'types/CustomDate';
import { httpClient } from './HttpClient';
import { IWorkOrderDetailsDto } from './odata/generated/entities/IWorkOrderDetailsDto';
import { Condition, ODataOperators } from './odata/ODataFilter';

export const WorkOrderApi = {
  initCreateData: (payload: WorkOrderQueryStringParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/workorders/InitWorkOrder`;
    return httpClient.post(url, data);
  },
  initUpdateData: (payload: WorkOrderQueryStringParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/workorders/InitWorkOrder`;
    return httpClient.post(url, data);
  },
  insertWorkOrder: (payload: IWorkOrderDetailsDto) => {
    const url = `/api/odata/v4/workorders`;
    return httpClient.post(url, payload);
  },
  updateWorkOrder: (payload: IWorkOrderDetailsDto) => {
    const url = `/api/odata/v4/workorders(${payload.Id})`;
    return httpClient.put(url, payload);
  },
  sendEmailOnOffline: (payload: SendEmailOnOffline) => {
    const data = {
      offData: payload,
    };
    console.debug('offlinedata', payload);
    const url = `/api/odata/v4/workorders/SendEmailOnResOffline`;
    return httpClient.post(url, data);
  },
  getNewWorkOrderStatus: (payload: number) => {
    const url = '/api/odata/v4/WorkOrderStatus';
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Order asc',
      $filter: `(WorkOrderTypeId eq  ${payload} and New eq true)`,
      $skip: 0,
      $top: 1,
    };
    return httpClient.get(url, params).then(response => response.value);
  },
  deleteOrders: (payload: { Id: number; all: boolean | null }) => {
    const url = '/api/odata/v4/WorkOrders/DeleteWorkOrder';
    return httpClient.post(url, payload);
  },
  DowntimeOrders: (payload: number[]) => {
    const filters = (assetIds: number[]) => {
      const predicates: string | Condition<IWorkOrderDetailsDto>[] = [];
      let foo: string | undefined = undefined;
      if (assetIds.length > 0) {
        let ids = assetIds.map(item => {
          return { Id: item };
        }) as Identifiable<number>[];
        let nowDate = new CustomDate(new Date(), 'complete');
        predicates.push(new Condition('AssetId', ODataOperators.Includes, ids));
        predicates.push(
          new Condition('OfflineEventStart', ODataOperators.LessThan, nowDate),
        );
        predicates.push(
          new Condition('OfflineEventEnd', ODataOperators.GreaterThan, nowDate),
        );
        predicates.push(
          new Condition<IWorkOrderDetailsDto>(
            'DoneDate',
            ODataOperators.Equals,
            null,
          ),
        );
        predicates.push(
          new Condition<IWorkOrderDetailsDto>(
            'WorkingHoursId',
            ODataOperators.NotEquals,
            null,
          ),
        );
        predicates.push(
          new Condition<IWorkOrderDetailsDto>(
            'IsClosed',
            ODataOperators.Equals,
            false,
          ),
        );
        foo = predicates.map(f => f.toString()).join(' and ');
      }
      return foo;
    };
    const url = '/api/odata/v4/WorkOrders';
    const params: {
      $orderby: string;
      $filter?: string;
    } = {
      $orderby: 'Id asc',
      $filter: filters(payload),
    };
    return httpClient.get(url, params).then(response => response.value);
  },
  removefromLinks: (id: number) => {
    const url = '/api/odata/v4/workorders/RemoveFromLinks';
    const params = { linkedId: id };
    return httpClient.post(url, params).then(v => v.value);
  },
  addToLinked: (data: { id: number; link: number }) => {
    const params = { params: { Id: data.id, Link: data.link } };
    const url = '/api/odata/v4/workorders/AddToLinked';
    return httpClient.post(url, params);
  },
  removeLinkedCalibration: (data: { id: number; link: number }) => {
    const url = '/api/odata/v4/workorders/RemoveLinkedCalibr';
    const params = { params: { Id: data.id, Link: data.link } };
    return httpClient.post(url, params);
  },
  addLinkedToCalibration: (data: { id: number; link: number }) => {
    const params = { params: { Id: data.id, Link: data.link } };
    const url = '/api/odata/v4/workorders/AddLinkedCalibr';
    return httpClient.post(url, params);
  },
  getLinkedToCalibration: (id: number) => {
    const params = { key: id };
    const url = '/api/odata/v4/workorders/InitLinkedCalibrations';
    return httpClient.post(url, params);
  },
  getOverlappingDowntimes: (id: number) => {
    const params = { id: id };
    const url = '/api/odata/v4/workorders/GetOverlappingDowntimes';
    return httpClient.post(url, params);
  },
};
