import * as DHTMLX from 'dhtmlx-scheduler';
import { scheduler_services_collection_name } from './serverListUtils';
import { scheduleServiceTemplate } from './renderServiceLabel';

export function createUnitsView(scheduler: DHTMLX.SchedulerStatic) {
  const sharedSettings = {
    property: 'service_id',
    y_property: 'service_id',
    list: scheduler.serverList(scheduler_services_collection_name),
  };
  const unitsName = 'day_unit';
  scheduler.createUnitsView({
    ...sharedSettings,
    name: unitsName,
    days: 1,
    xy: {
      scale_height: 70,
    },
  });
  // sets the render template for unit header
  // https://docs.dhtmlx.com/scheduler/api__scheduler_%7Bunitsname%7D_scale_text_template.html
  scheduler.templates[`${unitsName}_scale_text`] = function (
    _key,
    _label,
    unit,
    _date,
  ) {
    return scheduleServiceTemplate(unit);
  };
}
