import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.workOrder || initialState;

export const selectWorkOrder = createSelector([selectSlice], state => state);

export const selectWorkOrderProcessing = createSelector(
  [selectWorkOrder],
  state => state.processing,
);
export const selectCreateWorkOrderProcessing = createSelector(
  [selectWorkOrder],
  state => state.createState.processing,
);
export const selectUpdateWorkOrderProcessing = createSelector(
  [selectWorkOrder],
  state => state.updateState.processing,
);

export const selectCreateWorkOrder = createSelector(
  [selectWorkOrder],
  state => state.createState.data,
);
export const selectUpdateWorkOrder = createSelector(
  [selectWorkOrder],
  state => state.updateState.data,
);
export const selectCreateWorkOrderCompleted = createSelector(
  [selectWorkOrder],
  state => state.createState.completed,
);
export const selectUpdateWorkOrderCompleted = createSelector(
  [selectWorkOrder],
  state => state.updateState.completed,
);
export const selectCreateWorkOrderHasError = createSelector(
  [selectWorkOrder],
  state => state.createState.hasErrors,
);
export const selectUpdateWorkOrderHasError = createSelector(
  [selectWorkOrder],
  state => state.updateState.hasErrors,
);
export const selectSendEmailOnResOffline = createSelector(
  [selectWorkOrder],
  state => state.sendEmailOnOffline,
);
export const selectReservationOffline = createSelector(
  [selectWorkOrder],
  state => state.reservationOffline,
);
export const selectFieldHandler = createSelector(
  [selectWorkOrder],
  state => state.valueHandler,
);
export const selectReservationOfflineData = createSelector(
  [selectReservationOffline],
  state => state.data,
);
export const selectReservationOfflineProcessing = createSelector(
  [selectReservationOffline],
  state => state.processing,
);
export const selectReservationOfflineCompleted = createSelector(
  [selectReservationOffline],
  state => state.completed,
);
export const selectReservationOfflineHasErrors = createSelector(
  [selectReservationOffline],
  state => state.hasErrors,
);
export const selectReadonlyWorkOrder = createSelector(
  [selectWorkOrder],
  state => state.readonlyState,
);
export const selectUpdateAllRecurrent = createSelector(
  [selectWorkOrder],
  state => state.updateAllRecurrent,
);
export const selectOfflineEventStart = createSelector(
  [selectWorkOrder],
  state => state.OfflineEventStart,
);
export const selectOfflineEventEnd = createSelector(
  [selectWorkOrder],
  state => state.OfflineEventEnd,
);
export const selectSaveNewComments = createSelector(
  [selectWorkOrder],
  state => state.doSaveNewComments,
);
export const selectCreatedOrders = createSelector(
  [selectWorkOrder],
  state => state.createdOrders,
);
export const selectSavedLinkedOrders = createSelector(
  [selectWorkOrder],
  state => state.savedLinkedOrders,
);

export const selectCalibrationsChanged = createSelector(
  [selectWorkOrder],
  state => state.calibrationsChanged,
);
export const selectLinksProcessing = createSelector(
  [selectWorkOrder],
  state => state.linksProcessing,
);
export const selectDowntimeOverlaps = createSelector(
  [selectWorkOrder],
  state => state.downtimeOverlaps,
);
