/**
 *
 * TopBar
 *
 */
import { AppBarProps, useTheme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { TopToolbar } from './TopToolbar';
import { StyledTopBar } from './styled';
import { SIDE_PANEL_WIDTH } from 'styles/constantVariables';
export interface StyleTopBarProps {
  isOpen: boolean;
  drawerWidth: number;
  sidePanelCollapsed?: boolean;
}
export interface TopBarProps extends AppBarProps {
  isOpen: boolean;
  drawerWidth: number;
  sidePanelCollapsed?: boolean;
}

export function TopBar(props: TopBarProps) {
  const { children, isOpen, drawerWidth, sidePanelCollapsed, ...rest } = props;
  const theme = useTheme();
  return (
    <>
      <StyledTopBar
        theme={theme}
        drawerWidth={drawerWidth}
        sidePanelWidth={SIDE_PANEL_WIDTH}
        color="default"
        position="absolute"
        variant="elevation"
        elevation={1}
        className={clsx('appBar', {
          appBarShift: isOpen,
          sidePanelShift: sidePanelCollapsed,
        })}
        {...rest}
      >
        <TopToolbar />
      </StyledTopBar>
    </>
  );
}
