/**
 *
 * WorkflowBookingPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IWorkflowBookingDto } from 'api/odata/generated/entities/IWorkflowBookingDto';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import * as React from 'react';
import { Entity } from 'types/common';

export interface WorkflowBookingPickerProps
  extends Omit<AutocompletePickerProps<IWorkflowBookingDto>, 'loadData'> {}

const url = '/api/odata/v4/WorkflowBooking';
export const initWorkflowBookingData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name,UserName',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
export function WorkflowBookingPicker(props: WorkflowBookingPickerProps) {
  const loadData = getAutoCompleteLoadDataFn<IWorkflowBookingDto>({
    url,
    predicates: props.predicates,
    select: ['Id', 'Name', 'UserName'],
  });
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      id={props.id || 'usergroupId'}
      size={props.size}
      loadData={loadData}
      {...props}
    />
  );
}
