import * as React from 'react';
import { FormHelperText } from '@material-ui/core';
import { Box } from 'app/components/basic/Wrappers/Box';
import { FormEmailAttachmentTypePicker } from 'app/components/Forms/FormEmailAttachmentTypePicker';
import { FormFundingTypePicker } from 'app/components/Forms/FormFundingTypePicker';
import { FormInvoiceStatusPicker } from 'app/components/Forms/FormInvoiceStatusPicker';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { FormScreenToShowOnPicker } from 'app/components/Forms/FormScreenToShowOnPicker';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRightSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { Formik } from 'formik';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { BillingActionsDetailsState } from '../slice/types';
import { FormSwitch } from 'app/components/Forms/Switch';
import { FormPaymentMethodEnumPicker } from '../../../../../components/Forms/FormPaymentMethodEnumPicker';
import { FormEmailRecipientTypesPicker } from 'app/components/Forms/FormEmailRecipientTypesPicker';
import { FormListener } from 'app/components/Forms/FormRender/FormRenderer';
import { selectAppSettings } from 'app/slice/selectors';
import { BudgetFields } from 'enums/BudgetFields';
import { useSelector } from 'react-redux';
import { IsBudgetFieldEnabled, IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import i18next from 'i18next';
import { EntityNumberSchema } from 'app/components/Forms/Schemas';
import * as Yup from 'yup';

export interface BillingActionsFormProps {
  initialValues: BillingActionsDetailsState;
  onSubmit: (item: BillingActionsDetailsState) => void;
  isEdit: boolean;
  isAdmin: boolean;
  bindSubmitForm: any;
}

export const BillingActionsForm = React.memo(function BillingActionsForm({
  initialValues,
  onSubmit,
  isEdit,
  isAdmin,
  bindSubmitForm,
}: BillingActionsFormProps): JSX.Element {
  //SETTINGS
  const { t } = useTranslation();
  const appSettings = useSelector(selectAppSettings);
  const isSftpEnabled = IsModuleEnabled(
    appSettings ?? null,
    KnownModules.SAP_SFTP,
  ) as boolean;
  const budgetFieldFundingTypeEnabled:
    | boolean
    | undefined = (IsBudgetFieldEnabled(
    appSettings ?? null,
    BudgetFields.FundingType,
  ) ||
    IsModuleEnabled(appSettings ?? null, KnownModules.CostObjects)) as boolean;

  //STATES
  const [IsSendEmailChanged, setIsSendEmailChanged] = React.useState<boolean>(
    false,
  );
  const [IsSftpUploadChanged, setIsSftpUploadChanged] = React.useState<boolean>(
    false,
  );
  //FUNCTIONS
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };

  const handleFormChange = React.useCallback(
    (values: BillingActionsDetailsState, isValid, dirty, setValue) => {
      if (IsSendEmailChanged) {
        setValue('EmailRecipientTypes', [], false);
        setValue('EmailAttachmentTypes', [], false);
        setValue('SpecificEmailAddresses', '', false);
        setValue('Subject', '', false);
        setValue('Body', '', false);
        setIsSendEmailChanged(false);
      }
      if (IsSftpUploadChanged) {
        setValue('EmailRecipientTypes', [], false);
        setValue('EmailAttachmentTypes', [], false);
        setValue('SpecificEmailAddresses', '', false);
        setValue('Subject', '', false);
        setValue('Body', '', false);
        setIsSftpUploadChanged(false);
      }
    },
    [IsSendEmailChanged, IsSftpUploadChanged],
  );

  //VALIDATION SCHEMA for BillingActionsDetails Form
  const isMultipleEmails = {
    name: 'isMultipleEmails',
    // eslint-disable-next-line no-template-curly-in-string
    message: '${path} not all are a valid email',
    test: async value => {
      if (!value) return true;
      let values = value.split(',');
      //let values = value.replace(/\s/g, '').split(',');
      let res = await Promise.all(
        values.map(
          async v => (await Yup.string().email().isValid(v)) && v !== '',
        ),
      );
      return res.every(v => !!v);
    },
  };

  const billingActionsDetailsSchema: Yup.SchemaOf<BillingActionsDetailsState> = Yup.object(
    {
      Id: Yup.number().notRequired().default(-1),
      OperationName: Yup.string()
        .nullable()
        .default('')
        .required(i18next.t(translations.err_OperationNameRequired))
        .label(i18next.t(translations.OperationName)),
      ShowOn: Yup.mixed()
        .label(i18next.t(translations.ShowOn))
        .required(i18next.t(translations.err_ShowOn)),
      FundingType: Yup.mixed()
        .label(i18next.t(translations.FundingType))
        .when(['YupFundingType'], {
          is: () => budgetFieldFundingTypeEnabled === true,
          then: schema =>
            schema.required(i18next.t(translations.FundingTypeIsRequired)),
          otherwise: schema => schema.notRequired(),
        }),
      ChangeStatusTo: Yup.mixed().label(i18next.t(translations.ChangeStatusTo)),
      GeneratePayment: Yup.mixed().label(
        i18next.t(translations.GeneratePayment),
      ),
      IsSendEmail: Yup.boolean()
        .default(false)
        .label(i18next.t(translations.IsSendEmail)),
      IsSftpUpload: Yup.boolean()
        .default(false)
        .label(i18next.t(translations.IsSftpUpload)),
      EmailRecipientTypes: Yup.array()
        .of(EntityNumberSchema)
        .label(i18next.t(translations.EmailRecipientTypes)),
      SpecificEmailAddresses: Yup.string()
        .nullable()
        .test(isMultipleEmails)
        .default('')
        .label(i18next.t(translations.SpecificEmailAddresses))
        .when(['EmailRecipientTypes', 'IsSendEmail'], {
          is: (EmailRecipientTypes, IsSendEmail) =>
            EmailRecipientTypes.length <= 0 && IsSendEmail === true,
          then: schema => schema.required(i18next.t(translations.Required)),
          otherwise: schema => schema.notRequired(),
        }),
      Body: Yup.string()
        .nullable()
        .notRequired()
        .label(i18next.t(translations.Body)),
      Subject: Yup.string()
        .nullable()
        //.required(i18next.t(translations.EmailSubject_RequiredField))
        .label(i18next.t(translations.Subject)),
      EmailAttachmentTypes: Yup.array()
        .of(EntityNumberSchema)
        .when(['IsSftpUpload'], {
          is: IsSftpUpload => IsSftpUpload === true,
          then: schema => schema.required(i18next.t(translations.Required)),
          otherwise: schema => schema.notRequired(),
        })
        .label(i18next.t(translations.EmailAttachmentTypes)),
    },
  );

  //RENDER
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={billingActionsDetailsSchema}
        validateOnMount={true}
        validateOnBlur={false}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          console.debug('Submitting: ', values);
          formikHelpers.setSubmitting(true);
          handleSubmit(values);
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            //console.log('Billing Actions Details Errors: ', formik.errors);
          }
          return (
            <>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['IsSendEmail']}
                />
                <FormLeftSection>
                  <FormFieldsSection
                    titleSection={
                      t(translations.BillingActionDetails) as string
                    }
                  >
                    <FormRow>
                      <FormTextField
                        id="OperationNameID"
                        name="OperationName"
                        label={t(translations.OperationName)}
                        placeholder={t(translations.OperationName)}
                        disabled={formik.isSubmitting}
                        info={t(translations.err_OperationNameUnique)}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow>
                      <FormScreenToShowOnPicker
                        id="ShowOnID"
                        name="ShowOn"
                        label={t(translations.ShowOn)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow hide={!budgetFieldFundingTypeEnabled}>
                      <FormFundingTypePicker
                        id="FundingTypeID"
                        name="FundingType"
                        label={t(translations.FundingType)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow>
                      <FormInvoiceStatusPicker
                        id="ChangeStatusToID"
                        name="ChangeStatusTo"
                        label={t(translations.ChangeStatusTo)}
                        placeholder={t(translations.AllInvoiceStatuses)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow>
                      <FormPaymentMethodEnumPicker
                        id="GeneratePaymentID"
                        name="GeneratePayment"
                        label={t(translations.GeneratePayment)}
                        placeholder={t(translations.AllValues)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormLeftSection>
                <FormRightSection>
                  <FormFieldsSection>
                    <FormRow fullRow>
                      <FormSwitch
                        id="IsSendEmailID"
                        boldLebel
                        name="IsSendEmail"
                        label={t(translations.IsSendEmail)}
                        disabled={formik.isSubmitting}
                        onChange={() => setIsSendEmailChanged(true)}
                      />
                    </FormRow>
                    {isSftpEnabled && (
                      <FormRow fullRow>
                        <FormSwitch
                          id="IsSftpUpload"
                          boldLebel
                          name="IsSftpUpload"
                          label={t(translations.IsSftpUpload)}
                          disabled={formik.isSubmitting}
                          onChange={() => setIsSftpUploadChanged(true)}
                        />
                      </FormRow>
                    )}
                    <FormRow hide={!formik.values.IsSendEmail}>
                      <FormEmailRecipientTypesPicker
                        id="EmailRecipientTypesID"
                        name="EmailRecipientTypes"
                        label={t(translations.EmailRecipientTypes)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow hide={!formik.values.IsSendEmail}>
                      {/* to make it visible only if specific email is choosen */}
                      <FormTextField
                        id="SpecificEmailAddressesID"
                        name="SpecificEmailAddresses"
                        fullWidth
                        label={t(translations.SpecificEmailAddresses)}
                        disabled={formik.isSubmitting}
                        info={t(
                          translations.EnterEmailsSeparatedByCommaWithoutSpaces,
                        )}
                      />
                      {!!formik.errors.Id && (
                        <Box>
                          <FormHelperText error>
                            {formik.errors.Id}
                          </FormHelperText>
                        </Box>
                      )}
                    </FormRow>
                    <FormRow
                      hide={
                        !formik.values.IsSendEmail &&
                        !formik.values.IsSftpUpload
                      }
                    >
                      <FormEmailAttachmentTypePicker
                        id="EmailAttachmentTypesID"
                        name="EmailAttachmentTypes"
                        label={t(translations.EmailAttachmentTypes)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow hide={!formik.values.IsSendEmail}>
                      <FormTextField
                        id="SubjectID"
                        name="Subject"
                        fullWidth
                        label={t(translations.Subject)}
                        disabled={formik.isSubmitting}
                        info={
                          'You can use the following variables:' +
                          ' {0} - Invoice Id,' +
                          ' {1} - Invoice Start Date,' +
                          ' {2} - Invoice End Date'
                        }
                      />
                    </FormRow>
                    <FormRow hide={!formik.values.IsSendEmail}>
                      <FormRichTextField
                        id="BodyID"
                        fullWidth
                        name="Body"
                        label={t(translations.Body)}
                        disabled={formik.isSubmitting}
                        info={
                          'Please mind using email supported HTML. The following attributes are supported:' +
                          ' {0} - User E-mail Address Display Name,' +
                          ' {1} - Invoice Start Date,' +
                          ' {2} - Invoice End Date,' +
                          ' {3} - Invoice Details Link,' +
                          ' {4} - User Group Name'
                        }
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormRightSection>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    </>
  );
});
