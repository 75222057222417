import { Box, useTheme } from '@material-ui/core';
import { httpClient } from 'api/HttpClient';
import { AssetQuickSearchDetails } from 'app/components/AssetQuickSearch/slice/types';
import { getAssetImageThumbSrc } from 'app/components/AssetThumbImage/getAssetImageSrc';
import { Body } from 'app/components/Typography';
import { BasicCard } from 'app/components/BasicCards';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { usePromise } from 'app/hooks/usePromise';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { DetectIsMobile } from 'utils/mobileDetect';
import { AssetActionType } from './AssetsSelection';
import * as React from 'react';
import { IsAssetImageExist } from 'types/AppSettings';
import { useSelector } from 'react-redux';
import { selectAppSettings } from 'app/slice/selectors';
import styled from 'styled-components';
import clsx from 'clsx';

export interface RecentAssetsProps {
  actionType: AssetActionType;
  primaryAction?: (row: AssetQuickSearchDetails) => void;
  useSidePanel?: boolean;
  expanded?: boolean;
}
const RecentContent = styled('div')(({ theme }) => ({
  '&.recentRoot': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 16,
    '& .recentTitle': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 8,
    },
    '& .recentContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '32px',
      '&.recentShort': {
        flexDirection: 'column',
        alignItems: 'start',
        gap: '16px',
        '& .recentItem': {
          flex: '1 1 100%',
          maxWidth: '100%',
          '& .nameOverRule': {
            maxWidth: '25ch',
          },
        },
      },
      '& .recentItem': {
        flex: '1 1 10%',
        borderRadius: 8,
        width: 0,
        minWidth: '256px',
        maxWidth: '360px',
        '& .nameOverRule': {
          display: 'inline-block',
          whiteSpace: 'pre',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '30ch',
          // [theme.breakpoints.down('sm')]: {
          //   maxWidth: '25ch',
          // },
        },
      },
    },
  },
}));
export function RecentAssets(props: RecentAssetsProps) {
  const { actionType, primaryAction } = props;
  const isMobile = DetectIsMobile();
  const appSettings = useSelector(selectAppSettings);
  const theme = useTheme();
  const { t } = useTranslation();
  const fetchRecentAssets = async (
    type: AssetActionType,
  ): Promise<AssetQuickSearchDetails[]> => {
    let url =
      actionType === 'WorkOrder'
        ? '/api/assets/recentReported?top=5'
        : actionType === 'StartUsage'
        ? '/api/assets/recentUsed?top=5'
        : '/api/assets/recentBook?top=5';
    return new Promise(async resolve => {
      try {
        const data = await httpClient.get(url);
        let result = data as AssetQuickSearchDetails[];
        if (data.length > 0) {
          resolve(result);
        } else {
          resolve([] as AssetQuickSearchDetails[]);
        }
      } catch {
        resolve([] as AssetQuickSearchDetails[]);
      }
    });
  };
  const [recentAssets, setRecentAssets] = useAsyncExtendedState<
    AssetQuickSearchDetails[]
  >([]);
  const [fetchRecentAssetsState, fetchRecentAssetsPromise] = usePromise(
    fetchRecentAssets,
  );
  useEffectOnMount(() => {
    setRecentAssets(fetchRecentAssetsPromise(actionType));
  });
  return recentAssets.length > 0 &&
    fetchRecentAssetsState.status === 'resolved' ? (
    <RecentContent theme={theme} className={'recentRoot'}>
      <div className={'recentTitle'}>
        <Icon icon="history" />
        <Body size="small" bold={true}>
          {t(translations.RecentAssets)}
        </Body>
      </div>
      <div className={clsx('recentContainer', { recentShort: isMobile })}>
        {recentAssets.map(item => {
          return (
            <div key={item.Id} className={'recentItem'}>
              <BasicCard
                key={item.ServiceId}
                position="horizontal"
                cardSize="small"
                mobileSize="small"
                title={undefined}
                cardContent={
                  <Box className={'nameOverRule'}>
                    <Body bold={true}>
                      <React.Fragment>{item.Name}</React.Fragment>
                    </Body>
                  </Box>
                }
                imageSrc={getAssetImageThumbSrc(
                  item.Image || null,
                  'thumb',
                  IsAssetImageExist(appSettings, item.Image || null, 'thumb'),
                )}
                useImage={true}
                maxContentHeight={20}
                useButtons={false}
                primaryAction={() => !!primaryAction && primaryAction(item)}
                backStyle="white"
              />
            </div>
          );
        })}
      </div>
    </RecentContent>
  ) : (
    <></>
  );
}
