/**
 *
 * ButtonLink
 *
 */
import React from 'react';
import { Link, styled } from '@material-ui/core';

export interface ButtonLinkProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> &
    React.MouseEventHandler<HTMLSpanElement> &
    React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  id?: string;
  href?: string;
  color?:
    | 'inherit'
    | 'initial'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
}

/**
 * Button styles as a Link
 * @param param0
 * @returns
 */
export default function ButtonLink(props: ButtonLinkProps) {
  return (
    <StyledLink
      color={props.color}
      href={props.href !== undefined ? props.href : '#'}
      id={props.id}
      {...props}
    />
  );
}
const StyledLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
}));
