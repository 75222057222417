import { IRepetitiveDto, RepetitiveInit } from 'types/IRepetitiveDto';
import { RepetitiveForm } from '.';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useRepetitiveSlice } from './slice';
import { Portal } from '@material-ui/core';
import { useRef } from 'react';
import { Skeleton } from '@material-ui/lab';
import { dateUtils } from 'utils/date-utils';
import { MutableRefObject } from 'react';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { RepetitiveFor } from './slice/types';
import { useSystemDate } from 'app/hooks/useSystemDate';
export interface RepetitiveControlProps {
  startDate?: Date | null;
  saveRepetitive?: (values: IRepetitiveDto) => void;
  submitFormRef: MutableRefObject<any>;
  title?: string;
  type: RepetitiveFor;
  disabled?: boolean;
  formIsValid?: (isValid: boolean) => void;
  withTopSeparator?: boolean;
  withBottomSeparator?: boolean;
  //submitForm: (e) => void;
}

export function RepetitiveControl(props: RepetitiveControlProps) {
  const {
    saveRepetitive,
    submitFormRef,
    startDate,
    title,
    type,
    disabled,
    formIsValid,
    withTopSeparator,
    withBottomSeparator,
  } = props;
  //const submitFormRef = React.useRef<any>(null);
  const dispatch = useDispatch();
  const { actions } = useRepetitiveSlice();
  const [initData, setInitData] = React.useState<IRepetitiveDto | undefined>(
    undefined,
  );
  const { newDate, getOffsetDate } = useSystemDate();
  //  React.useMemo(() => {
  //   return RepetitiveInit(
  //     startDate ? dateUtils.dateOrStringToDate(startDate) : new Date(),
  //     dateUtils.addDays(
  //       startDate ? dateUtils.dateOrStringToDate(startDate) : new Date(),
  //       7,
  //     ),
  //   );
  // }, [startDate]);
  useEffectOnMount(() => {
    setInitData(() =>
      RepetitiveInit(startDate ? getOffsetDate(startDate) : newDate()),
    );
    // if (startDate !== undefined && startDate !== null) {
    //   dispatch(actions.setStartDate(dateUtils.dateOrStringToDate(startDate)));
    // } else {
    //   dispatch(actions.setStartDate(new Date()));
    // }
    return () => {};
  });
  //   React.useEffect(() => {
  //     let active = doSubmit;
  //     if (active) {
  //       if (submitFormRef.current) {
  //         submitFormRef.current.Submit();
  //         dispatch(actions.setSubmit(false));
  //       }
  //     }
  //     return () => {
  //       active = false;
  //     };
  //   }, [actions, dispatch, doSubmit]);

  //   const handleSubmitForm = e => {
  //     if (submitFormRef.current) {
  //       submitFormRef.current(e);
  //     }
  //   };
  const portalRef = useRef(null);
  const bindSubmitForm = React.useCallback(
    submitForm => {
      submitFormRef.current = submitForm;
    },
    [submitFormRef],
  );
  const handleSubmit = React.useCallback(
    (values: IRepetitiveDto) => {
      if (saveRepetitive) {
        saveRepetitive(values);
      } else {
        var d = {
          StartDate:
            values.StartDate === undefined
              ? undefined
              : dateUtils.formatISO(
                  dateUtils.dateOrStringToDate(values.StartDate),
                ),
          RecurringEvents: values.RecurringEvents,
          RepeatsOnDays: values.RepeatsOnDays,
          RecurringEndDate:
            values.RecurringEndDate === undefined
              ? undefined
              : dateUtils.formatISO(
                  dateUtils.dateOrStringToDate(values.RecurringEndDate),
                ),
          RepeatsEveryDay: values.RepeatsEveryDay,
          RepeatsEveryPeriod: values.RepeatsEveryPeriod,
          RepeatsMonthlyBy: values.RepeatsMonthlyBy,
        } as IRepetitiveDto;
        dispatch(actions.saveState(d));
      }
    },
    [actions, dispatch, saveRepetitive],
  );
  return initData ? (
    <React.Fragment>
      <div ref={portalRef} style={{ width: '100%' }} />
      <Portal container={portalRef.current}>
        <RepetitiveForm
          onSubmit={handleSubmit}
          disabled={disabled}
          formIsValid={formIsValid}
          RepetitiveData={
            initData ?? undefined
            //   RepetitiveInit(
            //     dateUtils.dateOrStringToDate(startDate || new Date()),
            //     null,
            //   )
          }
          bindSubmitForm={bindSubmitForm}
          title={title}
          type={type}
          withBottomSeparator={withBottomSeparator}
          withTopSeparator={withTopSeparator}
        />
      </Portal>
      {/* <SaveFormButton onClick={handleSubmitForm} edit={false} text="Rep Save" /> */}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Skeleton variant="rect" />
    </React.Fragment>
  );
}
