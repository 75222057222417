/**
 *
 * Popper
 *
 */
import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetectIsMobile } from 'utils/mobileDetect';
import { Box } from 'app/components/basic/Wrappers/Box';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { translations } from 'locales/translations';
import { Body } from '../Typography';

export interface PopperTitleProps {
  title: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  closeButtonTitle?: string;
}

export function PopperTitle({ title, onClose, ...props }: PopperTitleProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const isMobile = DetectIsMobile();
  const classes = useStyles({ isMobile: isMobile });
  const closeTitle = props.closeButtonTitle ?? t(translations.Close);
  return (
    <Box className={classes.poperTitle}>
      <IconButton
        shape="square"
        size="xs"
        variant="ghost"
        aria-label="Close"
        onClick={onClose}
        style={{ position: 'absolute', right: 8, top: 8 }}
      >
        <Tooltip title={closeTitle}>
          <span>
            <Icon icon="times" color="default" colorExtend="textHover" />
          </span>
        </Tooltip>
      </IconButton>

      <Box className={classes.poperTditle}>
        <Body size="small" bold={true}>
          {title}
        </Body>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    poperTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 8px',
      justifyContent: 'space-between',
      width: '100%',
      '&>label': {
        width: '100%',
        textAlign: 'center',
      },
    },
  }),
);
