import {
  BaseTextFieldProps,
  capitalize,
  Divider,
  List,
  ListItem,
  useTheme,
} from '@material-ui/core';
import { BaseTimePickerProps, useUtils } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FormControl from 'app/components/Forms/FormControls/FormControl';
import FormHelperText from 'app/components/Forms/FormControls/FormHelperText';
import { InputLabel } from '@material-ui/core';
import * as React from 'react';

import clsx from 'clsx';
import { Entity } from 'types/common';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { dateUtils } from 'utils/date-utils';
import { validateError } from 'app/components/DatePicker/utils/pickerDateValidate';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { DateDropDownValidationProps } from '../DateDropDown';
import {
  getHoursEntityFromDate,
  hoursEntityArray12,
  hoursEntityArray24,
} from 'app/components/pickers/StaticOptionsPickers/HoursPicker';
import {
  getMinutesEntityFromDate,
  minutesEntityArray,
} from 'app/components/pickers/StaticOptionsPickers/MinutesPicker';
import {
  AmPmEntityArray,
  getAmPmEntityFromDate,
} from 'app/components/pickers/StaticOptionsPickers/AmPmPicker';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export interface TimeOptionPickerProps
  extends DateDropDownValidationProps,
    BaseTimePickerProps,
    Pick<
      BaseTextFieldProps,
      | 'fullWidth'
      | 'helperText'
      | 'required'
      | 'disabled'
      | 'name'
      | 'id'
      | 'label'
      | 'error'
      | 'size'
    > {
  value?: Date | string | null;
  onChange: (
    date: MaterialUiPickersDate | null,
    withCompletion?: boolean,
  ) => void;
  inputVariant?: 'filled' | 'standard' | 'outlined';
  info?: string;
  className?: string;
  hoursDisabled?: boolean;
  minutesDisabled?: boolean;
  onError?: (
    error: React.ReactNode,
    value: MaterialUiPickersDate | ParsableDate,
  ) => void;
}

export function TimeOptionPicker(props: TimeOptionPickerProps) {
  const {
    value,
    onChange,
    inputVariant,
    id,
    fullWidth,
    helperText,
    disabled,
    label,
    error,
    size,
    ampm,
    minutesStep,
    strictCompareDates = true,
    onError,
  } = props;
  const { newDate } = useSystemDate();
  const utils = useUtils();
  const theme = useTheme();
  const inputLabelId = label && id ? `${id}-label` : undefined;
  const helperTextId = id ? `${id}-helper-text` : undefined;
  const sizeClass = `inputSize${capitalize(size ?? 'large')}`;
  const dateTimeValue = React.useMemo(() => {
    return value;
  }, [value]);
  const [dateSelected, setDateSelected] = React.useState<
    Date | string | null | undefined
  >(value);
  const [hoursSelected, setHoursSelected] = React.useState(
    getHoursEntityFromDate(ampm ?? false, dateTimeValue),
  );
  const [minutesSelected, setMinutesSelected] = React.useState(
    getMinutesEntityFromDate(dateTimeValue),
  );
  const [ampmSelected, setAmpmSelected] = React.useState(
    getAmPmEntityFromDate(dateTimeValue),
  );
  //   const [isHourOpen, setIsHourOpen] = React.useState<boolean>(false);
  //   const [isMinuteOpen, setIsMinuteOpen] = React.useState<boolean>(false);
  //   const [isAmPmOpen, setIsAmPmOpen] = React.useState<boolean>(false);
  //   const someOpen = React.useMemo(() => {
  //     return isHourOpen || isMinuteOpen || isAmPmOpen;
  //   }, [isHourOpen, isMinuteOpen, isAmPmOpen]);
  const [innerError, setInnerError] = React.useState<
    React.ReactNode | undefined
  >(undefined);

  const hoursItems = React.useMemo(() => {
    return ampm ? hoursEntityArray12 : hoursEntityArray24;
  }, [ampm]);
  const minutesItems = React.useMemo(() => {
    return !!minutesStep && minutesStep > 0 && minutesStep <= 30
      ? minutesEntityArray.filter(f => f.Id % minutesStep === 0)
      : minutesEntityArray;
  }, [minutesStep]);
  const ampmItems = React.useMemo(() => {
    return AmPmEntityArray;
  }, []);
  const hourRef = React.useRef<HTMLDivElement>(null);
  const minuteRef = React.useRef<HTMLDivElement>(null);
  const hourScroll = (selectedId: number, useAmPm?: boolean) => {
    if (!!hourRef.current) {
      let top = (useAmPm ? selectedId - 1 : selectedId) * 50;
      hourRef.current.scrollTo(0, top);
    }
  };
  const minuteScroll = (selectedId: number, step?: number) => {
    if (!!minuteRef.current) {
      let top = selectedId * 50;
      if (step !== undefined) {
        top = top / step;
      }
      minuteRef.current.scrollTo(0, top);
    }
  };
  React.useEffect(() => {
    setHoursSelected(() =>
      getHoursEntityFromDate(ampm ?? false, dateTimeValue),
    );
    setMinutesSelected(() => getMinutesEntityFromDate(dateTimeValue));
    setAmpmSelected(() => getAmPmEntityFromDate(dateTimeValue));
  }, [ampm, dateTimeValue]);

  React.useEffect(() => {
    if (!!hourRef.current) {
      hourScroll(hoursSelected.Id, ampm);
    }
    if (!!minuteRef.current) {
      minuteScroll(minutesSelected.Id, minutesStep);
    }
  }, [ampm, hoursSelected.Id, minutesSelected.Id, minutesStep]);
  React.useEffect(() => {
    if (dateSelected !== null && dateSelected !== undefined) {
      let time = dateUtils.set(dateUtils.dateOrStringToDate(dateSelected), {
        hours: hoursSelected.Id,
        minutes: minutesSelected.Id,
      });
      let validateDate = dateUtils.convertToMeridiem(
        time,
        ampmSelected.Id === 'am' ? 'am' : 'pm',
        ampm ?? false,
      );
      let error = validateError(
        validateDate,
        {
          maxDate: props.maxDate,
          minDate: props.minDate,
          disablePast: props.disablePast,
          disableFuture: props.disableFuture,
          maxDateMessage: props.maxDateMessage,
          minDateMessage: props.minDateMessage,
          invalidDateMessage: props.invalidDateMessage,
          strictCompareDates: strictCompareDates,
        },
        utils,
      );
      if (!!error) {
        setInnerError(error);
        if (onError) {
          onError(error, validateDate);
        }
      } else {
        setInnerError('');
        if (onError) {
          onError('', validateDate);
        }
      }
    } else {
      setInnerError('');
      if (onError) {
        onError('', null);
      }
    }
  }, [
    ampm,
    ampmSelected.Id,
    dateSelected,
    hoursSelected.Id,
    minutesSelected.Id,
    onError,
    props.disableFuture,
    props.disablePast,
    props.invalidDateMessage,
    props.maxDate,
    props.maxDateMessage,
    props.minDate,
    props.minDateMessage,
    strictCompareDates,
    utils,
  ]);
  const handleChange = React.useCallback(
    (
      hour: number,
      minute: number,
      ampmValue: 'am' | 'pm',
      withCompletion?: boolean,
    ) => {
      if (dateSelected !== null && dateSelected !== undefined) {
        let time = dateUtils.set(dateUtils.dateOrStringToDate(dateSelected), {
          hours: hour,
          minutes: minute,
        });
        let finalDate = dateUtils.convertToMeridiem(
          time,
          ampmValue,
          ampm ?? false,
        );
        let hasError = validateError(
          finalDate,
          {
            maxDate: props.maxDate,
            minDate: props.minDate,
            disablePast: props.disablePast,
            disableFuture: props.disableFuture,
            maxDateMessage: props.maxDateMessage,
            minDateMessage: props.minDateMessage,
            invalidDateMessage: props.invalidDateMessage,
            strictCompareDates: strictCompareDates,
          },
          utils,
        );
        if (!hasError || hasError === '') {
          onChange(finalDate, withCompletion);
        }
      } else {
        let time = dateUtils.set(newDate(), { hours: hour, minutes: minute });
        let finalDate = dateUtils.convertToMeridiem(
          time,
          ampmValue,
          ampm ?? false,
        );
        setDateSelected(finalDate);
        let hasError = validateError(
          finalDate,
          {
            maxDate: props.maxDate,
            minDate: props.minDate,
            disablePast: props.disablePast,
            disableFuture: props.disableFuture,
            maxDateMessage: props.maxDateMessage,
            minDateMessage: props.minDateMessage,
            invalidDateMessage: props.invalidDateMessage,
            strictCompareDates: strictCompareDates,
          },
          utils,
        );
        if (!hasError || hasError === '') {
          onChange(finalDate, withCompletion);
        }
      }
    },
    [
      ampm,
      dateSelected,
      newDate,
      onChange,
      props.disableFuture,
      props.disablePast,
      props.invalidDateMessage,
      props.maxDate,
      props.maxDateMessage,
      props.minDate,
      props.minDateMessage,
      strictCompareDates,
      utils,
    ],
  );
  const handleHoursChange = React.useCallback(
    (val: Entity<number>) => {
      setHoursSelected(val);
      handleChange(
        val.Id,
        minutesSelected.Id,
        ampmSelected.Id === 'am' ? 'am' : 'pm',
      );
    },
    [ampmSelected.Id, handleChange, minutesSelected.Id],
  );
  const handleMinutesChange = React.useCallback(
    (val: Entity<number>) => {
      setMinutesSelected(val);
      handleChange(
        hoursSelected.Id,
        val.Id,
        ampmSelected.Id === 'am' ? 'am' : 'pm',
      );
    },
    [ampmSelected.Id, handleChange, hoursSelected.Id],
  );
  const handleAmPmChange = React.useCallback(
    (val: Entity<string>) => {
      setAmpmSelected(val);
      handleChange(
        hoursSelected.Id,
        minutesSelected.Id,
        val.Id === 'am' ? 'am' : 'pm',
      );
    },
    [handleChange, hoursSelected.Id, minutesSelected.Id],
  );

  return (
    <React.Fragment>
      <FormControl
        className={clsx('time-options-picker', sizeClass, {
          withLabel: !!label,
        })}
        disabled={disabled}
        error={error || Boolean(innerError)}
        fullWidth={fullWidth}
        required={props.required}
        variant={inputVariant || 'standard'}
        size={'medium'}
        // onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
        //   const target = event.target as any; // as HTMLInputElement;

        //   if (event.key === 'Enter') {
        //     if (!!target && target.nodeName === 'INPUT') {
        //       if (!someOpen && !innerError) {
        //         handleChange(
        //           hoursSelected.Id,
        //           minutesSelected.Id,
        //           ampmSelected.Id === 'am' ? 'am' : 'pm',
        //           true,
        //         );
        //       }
        //     }
        //   }
        // }}
      >
        {label && (
          <InputLabel
            htmlFor={id}
            id={inputLabelId}
            className={clsx('time-options-picker', sizeClass)}
            required={props.required}
            error={error || Boolean(innerError)}
            disabled={props.disabled}
          >
            {label}
          </InputLabel>
        )}
        <PickerOptions theme={theme} className={'time-options-picker-root'}>
          <div className={'time-options-picker-wrap'}>
            <div className={'time-options-picker-section'}>
              <List
                disablePadding
                ref={hourRef}
                // dense={true}
                component={'div'}
                className={clsx(
                  'time-options-picker-list',
                  'time-options-hours',
                )}
                // name={name}
              >
                {hoursItems.map(hour => (
                  <ListItem
                    button
                    alignItems="center"
                    key={hour.Id}
                    className={clsx('time-options-picker-list-item', {
                      selected: hoursSelected.Id === hour.Id,
                    })}
                    //    children={children}
                    onClick={event => {
                      handleHoursChange(hour);
                    }}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        handleHoursChange(hour);
                      }
                    }}
                    selected={hoursSelected.Id === hour.Id}
                  >
                    {hour.Name}
                  </ListItem>
                ))}
              </List>
              <Divider orientation="vertical" />
              <List
                disablePadding
                ref={minuteRef}
                component={'div'}
                // dense={true}
                className={clsx(
                  'time-options-picker-list',
                  'time-options-minutes',
                )}
                // name={name}
              >
                {minutesItems.map(minute => (
                  <ListItem
                    button
                    alignItems="center"
                    key={minute.Id}
                    className={clsx('time-options-picker-list-item', {
                      selected: minutesSelected.Id === minute.Id,
                    })}
                    onClick={event => {
                      handleMinutesChange(minute);
                    }}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        handleMinutesChange(minute);
                      }
                    }}
                    selected={minutesSelected.Id === minute.Id}
                  >
                    {minute.Name}
                  </ListItem>
                ))}
              </List>
              {ampm && (
                <React.Fragment>
                  <Divider orientation="vertical" />
                  <List
                    disablePadding
                    component={'div'}
                    //   dense={true}
                    className={clsx(
                      'time-options-picker-list',
                      'time-options-ampm',
                    )}
                    // name={name}
                  >
                    {ampmItems.map(ap => (
                      <ListItem
                        button
                        alignItems="center"
                        key={ap.Id}
                        className={clsx('time-options-picker-list-item', {
                          selected: ampmSelected.Id === ap.Id,
                        })}
                        onClick={event => {
                          handleAmPmChange(ap);
                        }}
                        onKeyDown={event => {
                          if (event.key === 'Enter') {
                            handleAmPmChange(ap);
                          }
                        }}
                        selected={ampmSelected.Id === ap.Id}
                      >
                        {ap.Name}
                      </ListItem>
                    ))}
                  </List>
                </React.Fragment>
              )}
            </div>
          </div>
        </PickerOptions>
        {(!!helperText || Boolean(innerError)) && (
          <FormHelperText
            id={helperTextId}
            className={clsx('time-options-picker', sizeClass)}
            disabled={disabled}
            error={error || Boolean(innerError)}
            required={props.required}
          >
            {helperText || innerError}
          </FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
}
const PickerOptions = styled('div')(({ theme }) => ({
  '&.time-options-picker-root': {
    display: 'grid',
    gridAutoColumns: 'max-content auto max-content',
    gridAutoRows: 'max-content auto max-content',
    '& .time-options-picker-wrap': {
      display: 'flex',
      flexDirection: 'column',
      gridColumn: 1,
      gridRow: 1,
      '& .time-options-picker-section': {
        overflow: 'hidden',
        maxHeight: '334px',
        margin: '0px auto',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        gap: '8px',
        padding: '8px 0px',
        '& .time-options-picker-list': {
          listStyle: 'none',
          margin: '0px',
          position: 'relative',
          maxHeight: '232px',
          width: '56px',
          padding: '0px',
          overflow: 'hidden',
          '&.time-options-hours, &.time-options-minutes': {
            '&::after': {
              display: 'block',
              content: '""',
              height: 'calc((100% - 40px) - 6px)',
            },
            '@media (prefers-reduced-motion: no-preference)': {
              scrollBehavior: 'auto',
            },
            '&:hover': {
              overflowY: 'auto',
            },
            '&::-webkit-scrollbar': {
              width: '4px',
              boxShadow: `inset 0px 0px 1px 1px ${bookitColors.grayscale.grayBorders}`,
              borderRadius: '2px',
            },
            '&::-webkit-scrollbar-thumb': {
              boxShadow: `inset 0 0 2px ${bookitColors.grayscale.grayBorders}`,
              borderRadius: '6px',
              backgroundColor: theme.palette.common.grayBorders,
            },
          },
          '& .time-options-picker-list-item': {
            display: 'flex',
            minHeight: '48px',
            whiteSpace: 'nowrap',
            padding: '8px',
            margin: '2px 4px',
            width: '48px',
            borderRadius: '8px',
            justifyContent: 'center',
            fontFamily: `'Lato', sans-serif`,
            fontSize: '16px',
            fontStyle: 'normal',
            lineHeight: '20px' /* 125% */,
            fontWeight: 600,
            letterSpacing: '0.2px',
            '&.selected': {
              backgroundColor: bookitColors.primary.components,
              color: bookitColors.base.white,
              '&:hover': {
                backgroundColor: bookitColors.primary.regular,
                color: bookitColors.base.white,
              },
            },
            '&:hover': {
              backgroundColor: bookitColors.primary.bgHover,
            },
          },
        },
        '& .time-options-minutes': {
          // borderLeft: `1px solid ${bookitColors.grayscale.grayBorders}`,
          // marginLeft: '12px',
        },
        '& .time-options-ampm': {
          // borderLeft: `1px solid ${bookitColors.grayscale.grayBorders}`,
          // marginLeft: '12px',
        },
        '& .time-options-hours': {
          // marginRight: '12px',
        },
      },
    },
  },
}));
