import { Accessor, Column, Renderer, CellProps, Row } from 'react-table';
import { IServiceRequestRowValueDto } from 'api/odata/generated/entities/IServiceRequestRowValueDto';
import {
  getServiceRequestRowFieldName,
  ServiceRequestRowEditableCell,
} from '../ServiceRequestRowEditableCell';
import { IServiceRequestTableRowModel } from '../../RequestSamplesPage/slice/types';
import { getCellComponent } from '../../EditableTable/Fields/getCellComponent';
import { getReadonlyCell } from '../../EditableTable/Fields/getReadonlyCell';
import { ColumnType } from './getTableColumns';
import {
  ServiceRequestTableColumn,
  ServiceRequestTableColumnsCollection,
} from '../../RequestSamplesPage/slice/utils/ServiceRequestTableColumn';

export function getCustomFormTableColumns(props: {
  columns: ServiceRequestTableColumnsCollection;
  isEditable: boolean;
}): Column<IServiceRequestTableRowModel>[] {
  return props.columns.getArray().map((column, index) => {
    const accessor: Accessor<IServiceRequestTableRowModel> = originalRow => {
      const result = originalRow.Values[column.getFieldName()];
      return result;
    };
    const cell = getCell({
      column: column,
      isEditable: props.isEditable,
      isFocused: index === 0,
    });

    const result: ColumnType = {
      id: column.getFieldName(),
      Header: column.Label,
      accessor: accessor,
      Cell: cell,
      enableHide: true,
      // TODO: add min/max/width based on the column type
      // minWidth:
      // maxWidth:
      // width:
      name: (row: Row<IServiceRequestTableRowModel>) =>
        getServiceRequestRowFieldName(row.index, column.getFieldName()),
      column: column,
    };
    return result;
  });
}

function getCell(props: {
  isEditable: boolean;
  column: ServiceRequestTableColumn;
  isFocused?: boolean;
}):
  | Renderer<
      CellProps<IServiceRequestTableRowModel, IServiceRequestRowValueDto>
    >
  | undefined {
  if (props.isEditable) {
    const fieldComponent = getCellComponent(props.column);
    if (fieldComponent !== null) {
      return ServiceRequestRowEditableCell(fieldComponent, {
        column: props.column,
        focused: props.isFocused,
      });
    }
  }
  return getReadonlyCell({ columnType: props.column.ColumnType });
}
