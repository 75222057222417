import { toRootedURL } from 'utils/url-utils';
export type ImageSize =
  | 'full'
  | 'thumb'
  | 'thumbSmall'
  | 'thumbLarge'
  | 'horizontal'
  | 'horizontalLarge'
  | 'vertical'
  | 'verticalLarge';

export function getAssetImageThumbSrc(
  imageName: string | null,
  imageSize?: ImageSize,
  imageExist?: boolean,
  defaultImage?: string,
): string | undefined {
  if (imageName === null || imageExist === false) {
    return toRootedURL(defaultImage ?? getDefaultImage(imageSize));
  }
  switch (imageSize) {
    case 'full':
      return toRootedURL(`/uploads/assets/${imageName}`);
    case 'horizontal':
      return toRootedURL(`/uploads/assets/cardhorizontal/${imageName}`);
    case 'horizontalLarge':
      return toRootedURL(`/uploads/assets/cardhorizontallarge/${imageName}`);
    case 'vertical':
      return toRootedURL(`/uploads/assets/cardvertical/${imageName}`);
    case 'verticalLarge':
      return toRootedURL(`/uploads/assets/cardverticallarge/${imageName}`);
    case 'thumbSmall':
      return toRootedURL(`/uploads/assets/thumbsSmall/${imageName}`);
    case 'thumbLarge':
      return toRootedURL(`/uploads/assets/thumbsLarge/${imageName}`);
    case 'thumb':
    default:
      return toRootedURL(`/uploads/assets/thumbs/${imageName}`);
  }
}
export function getDefaultImage(size?: ImageSize) {
  switch (size) {
    case 'full':
      return `/images/200x150.png`;
    case 'thumb':
      return `/images/64x64.png`;
    case 'thumbSmall':
      return `/images/64x38.png`;
    case 'thumbLarge':
      return `/images/80x80.png`;
    case 'vertical':
      return `/images/278x174.png`;
    case 'verticalLarge':
      return `/images/457x240.png`;
    case 'horizontal':
      return `/images/180x201.png`;
    case 'horizontalLarge':
      return `/images/207x201.png`;
    case undefined:
    default:
      return `/images/64x64.png`;
  }
}
export function getAssetImageSrc(
  imageName: string | null,
  imageExist?: boolean,
  defaultImage?: string,
): string | undefined {
  if (imageName === null || imageExist === false) {
    return toRootedURL(defaultImage ?? `/images/200x150.png`);
  }
  return toRootedURL(`/uploads/assets/${imageName}`);
}
