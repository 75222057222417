/**
 *
 * RecentAssetsList
 *
 */
import { LinearProgress, ListItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectRecentAssets } from '../slice/selectors';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { AssetListItem } from '../AssetListItem';
import {
  QuickSearchSection,
  QuickSearchRecentItems,
  QuickSearchSectionTitle,
} from '../RecentWrappers/wrappers';
import { Body, Uppercase } from 'app/components/Typography';
import React from 'react';
import { Entity } from 'types/common';

export interface RecentAssetsListProps {
  useAssetPopup?: boolean;
  isMobile?: boolean;
  selectedServiceGroups?: Entity<number>[];
}

export function RecentAssetsList(props: RecentAssetsListProps) {
  const { t } = useTranslation();
  const data = useSelector(selectRecentAssets);
  const { useAssetPopup, isMobile, selectedServiceGroups } = props;

  return (
    <ListItem disableGutters component="div" style={{ width: '100%' }}>
      <QuickSearchSection>
        <QuickSearchSectionTitle>
          <Uppercase color="secondary">
            {t(translations.RecentAssets)}
          </Uppercase>
        </QuickSearchSectionTitle>
        <QuickSearchRecentItems>
          {data.status === 'pending' ? (
            <div style={{ width: '100%' }}>
              <LinearProgress variant="query" color="primary" />
            </div>
          ) : data.status === 'rejected' ||
            (data.status === 'resolved' && data.value?.length === 0) ? (
            <div style={{ width: '100%' }}>
              <Body>{'No recent data'}</Body>
            </div>
          ) : (
            <React.Fragment>
              {data.value?.map((item, index) => (
                <AssetListItem
                  key={index}
                  item={item}
                  useAssetPopup={useAssetPopup}
                  isMobile={isMobile}
                  selectedServiceGroups={selectedServiceGroups}
                />
              ))}
            </React.Fragment>
          )}
        </QuickSearchRecentItems>
      </QuickSearchSection>
    </ListItem>
  );
}
