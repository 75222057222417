import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import * as Yup from 'yup';
import { BudgetEntitySechema, ServiceGroupEntitySchema } from '..';

/**
 *
 * @param t localization TFunction
 * @returns base validation schema for paymet or charge
 */
export function InvoiceChildRowBaseSchema(t: TFunction) {
  return Yup.object({
    Id: Yup.number().notRequired(),
    InvoiceId: Yup.number().notRequired(),
    Budget: BudgetEntitySechema.nullable().required(
      t(translations.PleaseSelectABudgetNumber),
    ),
    ServiceGroup: ServiceGroupEntitySchema.required(
      t(translations.PleaseSelectServiceGroup),
    ).nullable(),
    Date: Yup.date().required(t(translations.PleaseSelectADate)),
  });
}
