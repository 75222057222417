import * as React from 'react';
import { FieldHookConfig, useField } from 'formik';
import { TextFieldProps } from '../TextField';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import BaseTextField from '../BasicInputs/BaseTextField';

export const FormTextField = ({
  label,
  onChange,
  onBlur,
  value,
  name,
  error,
  helperText,
  ...props
}: FieldHookConfig<string> &
  Omit<TextFieldProps, 'helperText'> &
  IFieldProps) => {
  const [field, meta] = useField<string>({ name, ...props });
  const handleChange = e => {
    if (onChange !== undefined) {
      onChange(e);
    }
    field.onChange(e);
  };
  const { t } = useTranslation();
  return (
    <BaseTextField
      name={name}
      label={label ?? t(translations[name])}
      fullWidth={props.fullWidth}
      variant="filled"
      onChange={handleChange}
      onBlur={field?.onBlur}
      value={field.value ?? ''}
      error={meta?.error !== undefined}
      helperText={meta.error || helperText}
      id={props.id || 'textfieldId'}
      inputProps={{
        ...props.inputProps,
        'aria-label': `${label ? undefined : 'text input'}`,
      }}
      color="primary"
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <React.Fragment>
            {props.info ? <InfoIcon title={props.info} /> : null}
            {props.InputProps?.endAdornment}
          </React.Fragment>
        ),
      }}
      {...props}
    />
  );
};
