import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const uploadStyle = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    buttonRoot: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1.25),
      padding: '6px 8px',
      boxShadow: 'unset',
      lineHeight: 'normal',
      height: '48px',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.grayBorders}`,
      '&:hover': {
        backgroundColor: theme.palette.common.grayDividers,
        border: `1px solid ${theme.palette.common.grayBorders}`,
      },
      '&$disabled': {
        backgroundColor: theme.palette.common.grayDividers,
        color: theme.palette.common.grayComponents,
        border: `1px solid ${theme.palette.common.grayBorders}`,
      },
      '&>[class*="MuiButton-label"]': {
        gap: theme.spacing(1),
        pointerEvents: 'none',
      },
    },
    buttonLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',
      margin: 0,
      height: '24px',
      justifyContent: 'flex-start',
    },
    startIcon: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '0px',
      flex: 'none',
      order: 0,
      flexGrow: 0,
      margin: 0,
      // margin: props =>
      //   props.size === 'large'
      //     ? '0px 12px 0px 0px'
      //     : props.size === 'medium'
      //     ? '0px 8px 0px 0px'
      //     : props.size === 'small'
      //     ? '0px 8px'
      //     : props.size === 'xs'
      //     ? '0px 8px 0px 0px'
      //     : '0px 12px 0px 0px',
      '&$iconSizeSmall': {
        marginLeft: 'unset',
      },
    },
    endIcon: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',
      flex: 'none',
      order: 2,
      flexGrow: 0,
      marginLeft: 'auto',
      marginRight: 0,
    },
    disabled: {
      backgroundColor: theme.palette.common.grayDividers,
      color: theme.palette.common.grayComponents,
      border: `1px solid ${theme.palette.common.grayBorders}`,
    },
    dividerRoot: {
      backgroundColor: theme.palette.common.grayComponents,
    },
    previewContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      gap: theme.spacing(1),
      height: '48px',
      padding: '0px 10px 0px 0px',
    },
    previewIconAndText: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 0,
      gap: theme.spacing(1.5),
      width: '100%',
    },
    previewIcon: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '12px',
      gap: theme.spacing(1.25),
      cursor: 'pointer',
      backgroundColor: theme.palette.common.white,
      borderRadius: '8px',
      color: theme.palette.common.grayComponents,
      '& svg': {
        width: '24px',
        height: '24px',
      },
      '& img': {
        height: '24px',
      },
    },
    previewText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0,
      width: '100%',
    },
    previewTextSize: {
      color: theme.palette.common.grayComponents,
    },
    previewCloseButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 2,
      gap: theme.spacing(1.25),
    },
    previewCloseButtonIcon: {
      color: theme.palette.common.grayComponents,
      width: '16px',
      height: '16px',
      fontSize: '16px',
    },
  }),
);
