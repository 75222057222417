/**
 *
 * MultiSelectBasicPicker
 *
 */
import * as React from 'react';
import {
  AutocompleteMultiPicker,
  AutocompleteMultiPickerProps,
} from 'app/components/BasicPickers/AutocompleteMultiPicker';
import { Entity } from 'types/common';

export interface BaseOptionsMultiPickerProps<T>
  extends Omit<AutocompleteMultiPickerProps<T>, 'loadData'> {
  getOptions: (searchTerm: string | null) => Entity<T>[];
}

export function BaseOptionsMultiPicker<T>(
  props: BaseOptionsMultiPickerProps<T>,
) {
  const { getOptions, ...other } = props;
  const loadData = async (searchTerm: string | null): Promise<any> =>
    new Promise<any>(resolve => {
      const opts = getOptions(searchTerm);
      resolve(opts);
    });
  return <AutocompleteMultiPicker loadData={loadData} {...other} />;
}
