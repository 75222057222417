/**
 *
 * InstituteTypePicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { ODataFilterBuilder } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { Entity } from 'types/common';

export interface InstituteTypePickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'> {}

const url = '/api/odata/v4/InstituteTypeFilter';
export const initInstituteTypeData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (searchTerm: string | null) => {
  const params: { $orderby: string; $filter?: string } = {
    $orderby: 'Name asc',
  };
  if (searchTerm !== null) {
    params.$filter = new ODataFilterBuilder<Entity<number>>({
      predicates: [],
      stringColumns: ['Name'],
      stringSearch: searchTerm,
    }).toString();
  }

  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<number>[]);
};
export function InstituteTypePicker(props: InstituteTypePickerProps) {
  return (
    <AutocompletePicker
      size={props.size}
      loadData={loadData}
      id={props.id || 'institutetypeId'}
      {...props}
    />
  );
}
