import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { DetectIsMobile } from 'utils/mobileDetect';
import { Row } from 'react-table';
import { IRow } from '../..';

export interface ICustomToolbar<TRow extends IRow> extends Record<string, any> {
  rowsCount: number;
  rows: Row<TRow>[];
}
export interface CustomToolbarProps {
  Component: React.ReactNode;
  compact?: boolean;
}

function CustomToolbarFunc(props: CustomToolbarProps) {
  const { Component, compact } = props;
  const isMobile = DetectIsMobile() || compact === true;
  const classes = useStyles({ isMobile })();
  return <Box className={classes.customRoot}>{Component}</Box>;
}
const useStyles = ({ isMobile }: { isMobile: boolean }) =>
  makeStyles((theme: Theme) =>
    createStyles({
      customRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: isMobile ? '8px 8px 16px' : '8px 32px',
      },
      tabsContant: {
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
      },
      buttonsContant: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        //flexWrap: isMobile ? 'wrap' : 'nowrap',
        gap: isMobile ? 0 : 16,
      },
    }),
  );
export const CustomToolbar = React.memo(CustomToolbarFunc);
