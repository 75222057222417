import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useCommentStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentSection: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: theme.spacing(2, 0),
      gap: '16px',
      alignSelf: 'stretch',
      borderBottom: `1px solid ${theme.palette.common.grayDividers}`,
      width: '100%',
    },
    commentTextFiles: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: '1 0 0',
      gap: '16px',
    },
    commentText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
      alignSelf: 'stretch',
    },
    nameAndTime: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '4px',
      alignSelf: 'stretch',
    },
    nameTitleButtons: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      alignSelf: 'stretch',
    },
    nameTitle: {
      flex: '1 0 0',
    },
  }),
);
export const useContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentsContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: '8px',
    },
    sectionTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: '0px',
      gap: '16px',
      alignSelf: 'stretch',
    },
    controlBox: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '16px',
      alignItems: 'flex-start',
      gap: '24px',
      alignSelf: 'stretch',
    },
    addCommentBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      alignSelf: 'stretch',
    },
  }),
);
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      //padding: theme.spacing(0, 4),
      maxWidth: '100%',
    },
    leftSide: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flexGrow: 1,
      padding: 0,
      width: '100%',
    },
    rightSide: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flexGrow: 0,
      padding: 0,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
      width: '100%',
    },
    fields: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
      width: '100%',
      gap: '16px',
      //paddingBottom: '36px',
    },
    // fieldsSpacing: {
    //   width: 'calc(100% + 24px)',
    //   margin: '-12px',
    //   '& > $row': {
    //     padding: theme.spacing(0.75, 0, 0.75, 0),
    //   },
    // },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: 0,
      width: '100%',
      gap: '16px',
    },
    sectionTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(0, 0, 2.5),
    },
    alertSpacing: {
      width: 'calc(100% + 24px)',
      margin: '12px -12px 12px -12px',
    },
    item: {
      flexBasis: '100%',
      maxWidth: '100%',
    },
    itemCheck: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      flexDirection: 'row',
    },
  }),
);
