import * as React from 'react';
import { EditorProps } from '../EditableCell';
import { IServiceRequestTableColumnModel } from '../../RequestSamplesPage/slice/types';
import { DateField } from './DateField';
import { NumberField, withMinMax } from './NumberField';
import { TextField } from './TextField';
import { SRSelect, withOptions } from './DropDownList';
import { IFormFieldOptionDto } from 'api/odata/generated/entities/IFormFieldOptionDto';
import { withAsyncOptions } from './DropDownList/IWithAsyncOptions';

export function getCellComponent(
  props: IServiceRequestTableColumnModel,
): React.FC<EditorProps<any> & any> | null {
  switch (props.ColumnType) {
    case 'ShortText':
      return TextField;
    /* Drop Down List */
    case 'DropDownList':
      return withOptions<
        string,
        IFormFieldOptionDto,
        EditorProps<IFormFieldOptionDto | Array<IFormFieldOptionDto> | null>
      >(SRSelect, props.Options ?? []);
    case 'UserSelection':
      return withAsyncOptions(SRSelect, {
        CustomRoles: props.CustomRoles,
        FixedRoles: props.FixedRoles,
        UserSelectionType: props.UserSelectionType,
        multiple: props.IsMultiple,
      });
    //return CustomFormDropDown
    /* User Selection */
    // case 'UserSelection':
    // return UserPickerField;
    /* Project Name */
    case 'Number':
      return withMinMax(NumberField, {
        min: -props.SizeLimit,
        max: props.SizeLimit,
        step: 0.01,
      });
    /* Positive Integer */
    case 'PositiveInteger':
      return withMinMax(NumberField, {
        min: 0,
        max: props.SizeLimit,
        step: 1,
      });
    /* Positive Decimal */
    case 'PositiveDecimal':
      return withMinMax(NumberField, {
        min: 0,
        max: props.SizeLimit,
        step: 0.01,
      });
    /* Date */
    case 'Date':
      return DateField;
    /* Email */
    case 'Email':
      return TextField;
    /* Serial */
    case 'Serial':
      return null;
    default:
      return null;
  }
}
