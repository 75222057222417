import { RadioProps as MuiRadioProps, styled } from '@material-ui/core';
import { Radio as MuiRadio } from '@material-ui/core';
import * as React from 'react';
import { bookitColors } from 'styles/colors/bookitColors';
export interface RadioProps extends MuiRadioProps {
  danger?: boolean;
  label?: string;
}

export default function Radio(props: RadioProps) {
  const { color, danger, label, size = 'medium', ...other } = props;
  const RadioIconUnchecked = styled('span')(({ theme, ...other }) => ({
    borderRadius: '1000px',
    width: 20,
    height: 20,
    backgroundColor: bookitColors.base.white,
    border:
      danger || color === 'secondary'
        ? `1px solid ${bookitColors.danger.borders}`
        : `1px solid ${bookitColors.grayscale.grayBorders}`,
    'input:hover ~ &': {
      border:
        danger || color === 'secondary'
          ? `1px solid ${bookitColors.danger.borders}`
          : props.disabled
          ? `1px solid ${bookitColors.grayscale.grayBorders}`
          : `1px solid ${bookitColors.primary.borders}`,
    },
    'input:disabled ~ &': {
      //boxShadow: 'none',
      background:
        danger || color === 'secondary'
          ? bookitColors.danger.bgHover
          : bookitColors.grayscale.graybgComponents,
    },
  }));
  const RadioIconChecked = styled(RadioIconUnchecked)(({ theme }) => ({
    backgroundColor:
      danger || color === 'secondary'
        ? bookitColors.danger.components
        : bookitColors.primary.components,
    border: 'none',
    '&:before': {
      display: 'flex',
      alignItems: 'center',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 16%,transparent 25%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor:
        danger || color === 'secondary'
          ? bookitColors.danger.regular
          : bookitColors.primary.regular,
      border: 'none',
    },
    'input:disabled ~ &': {
      background:
        danger || color === 'secondary'
          ? bookitColors.danger.disabled
          : bookitColors.grayscale.grayBorders,
    },
  }));
  return (
    <MuiRadio
      key={props.key}
      icon={<RadioIconUnchecked />}
      checkedIcon={<RadioIconChecked />}
      color={danger ? 'secondary' : color === 'default' ? 'primary' : color}
      size={size}
      inputProps={{ 'aria-label': !!label ? label : 'decorative radio' }}
      {...other}
    />
  );
}
