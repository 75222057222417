import { LabelTypes } from '../slice/types';
import Handlebars from 'handlebars';

/**
 * Produces a URL used to print QR barcodes
 * @param item data record e.g. assetDetails, LocationListDto, etc.
 * @param type The type of the URL Template: Asset/LocationList/etc.
 * @param urlTemplate The URL template as configured in system settings
 * @returns
 */
export function getQrUrl(item: any, type: LabelTypes, urlTemplate: string) {
  if (
    type === 'Asset' &&
    (item as IGetQrURLAssetDetails).InternalCode !== undefined
  ) {
    return getAssetQrUrl(item, urlTemplate);
  } else {
    return getQrUrlH(item, urlTemplate);
  }
}

interface IGetQrURLAssetDetails {
  InternalCode: string | null;
}

function getAssetQrUrl(
  assetDetails: IGetQrURLAssetDetails,
  AssetBarcodeUrlTemplate,
) {
  if (assetDetails.InternalCode === null) {
    throw new Error('Asset QR: Internal code is empty');
  }
  return AssetBarcodeUrlTemplate.replace(
    /:internalCode/g,
    assetDetails.InternalCode,
  );
}

/**
 * Produces a URL through Handlebars engine
 * @param item data record
 * @param urlTemplate url template string
 * @returns URL for QR label print
 */
function getQrUrlH(item: any, urlTemplate) {
  const template = Handlebars.compile(urlTemplate);
  const url = template(item);
  return url;
}
