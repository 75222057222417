import { Button } from 'app/components/BasicButtons/Button';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toQueryString } from 'utils/url-utils';

export interface OpenWorkOrdersProps<TRow> {
  rows: TRow[];
  getAssetIds: (rows: TRow[]) => number[];
}

export function OpenWorkOrders<TRow>({
  rows,
  getAssetIds,
}: OpenWorkOrdersProps<TRow>) {
  const { t } = useTranslation();
  const alertslink = React.useMemo(() => {
    return `/workorders?${toQueryString({
      aid: getAssetIds(rows).join('|'),
    })}`;
  }, [getAssetIds, rows]);

  return (
    <Button variant="white" size="small" href={alertslink}>
      {t(translations.menu_WorkOrders)}
    </Button>
  );
}
