import DateRenderer from 'app/components/BasicTable/CellRenderers/DateRenderer';
import DecimalRenderer from 'app/components/BasicTable/CellRenderers/DecimalRenderer';
import ServiceIdRenderer from 'app/components/BasicTable/CellRenderers/EquipmentIdRenderer';
import ImageRenderer from 'app/components/BasicTable/CellRenderers/ImageRenderer';
import OperationTypeIdRenderer from 'app/components/BasicTable/CellRenderers/OperationTypeIdRenderer';
import { BooleanRender } from 'app/components/BasicTable/components/BooleanRender';
import { DescriptionCell } from 'app/components/BasicTable/components/DescriptionCell';
import { ColumnWithDep } from 'app/components/BasicTable/types/ColumnWithDeps';
import { DateTimeLabel } from 'app/components/DateTimeLabel';
import { ExternalLink, Link } from 'app/components/ExternalLink';
import { Roles } from 'app/slice/types';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import * as React from 'react';
import { bookitColors } from 'styles/colors/bookitColors';
import { AppSettings, IsModuleEnabled } from 'types/AppSettings';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { KnownModules } from 'types/KnownModules';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import { tryParseBool } from 'utils/string-utils';
import { AssetLink } from '../AssetPopUp';
import { IMyAssetsRow } from './IMyAssetsRow';

export function getColumns(
  t: TFunction,
  user: AuthenticatedUser | undefined,
  appSettings: AppSettings | null,
  settings: GlobalSettings | null,
) {
  const suppliersLabel = tryParseBool(
    settings?.GetString(AllowedSettings.RequireSupplierExternalId),
  )
    ? t(translations.ExternalCompany)
    : t(translations.SupplierExternalId);
  const res: ColumnWithDep<IMyAssetsRow>[] = [
    {
      Header: t(translations.HashSymbol) as string,
      accessor: 'Id',
      Cell: ServiceIdRenderer,
    },
    {
      Header: t(translations.Active) as string,
      accessor: 'Active',
      Cell: ({ value }) => <BooleanRender value={value} />,
    },
    {
      Header: t(translations.Image) as string,
      accessor: 'ImageName',
      Cell: ImageRenderer,
      notShowTitle: true,
    },
    {
      Header: t(translations.Asset) as string,
      accessor: 'Name',
      Cell: ({ value, row }) => {
        return <AssetLink id={row.original.Id}>{value}</AssetLink>;
      },
    },
    {
      Header: t(translations.Created) as string,
      accessor: 'AssetRecordDate',
      Cell: ({ value }) => <DateTimeLabel value={value as Date} />,
    },
    {
      Header: t(translations.LifeDate) as string,
      accessor: 'LifeDate',
      Cell: ({ value }) => <DateTimeLabel value={value as Date} />,
    },
    {
      Header: t(translations.Status) as string,
      accessor: 'Status',
      Cell: ({ value, row }) => {
        let color = 'black';
        let backgroundColor = 'white';
        switch (row.original.StatusId) {
          case 1:
            color = bookitColors.success.dark;
            backgroundColor = bookitColors.success.background;
            break;
          case 2:
            color = bookitColors.warning.dark;
            backgroundColor = bookitColors.warning.background;
            break;
          case 3:
            color = bookitColors.danger.dark;
            backgroundColor = bookitColors.danger.background;
            break;
          default:
            color = 'black';
            backgroundColor = 'white';
        }
        return (
          <>
            <span
              style={{
                color: `${color}`,
                backgroundColor: `${backgroundColor}`,
                borderRadius: 8,
                padding: 6,
              }}
            >
              {value}
            </span>
          </>
        );
      },
      deps: ['StatusId'],
    },
    {
      Header: t(translations.InstrumentCat) as string,
      accessor: 'InstrumentCatName',
    },
    {
      Header: t(translations.OperationType) as string,
      accessor: 'OperationTypeID',
      Cell: OperationTypeIdRenderer,
    },
    {
      Header: t(translations.Availability) as string,
      accessor: 'AvailabilityTypeName',
    },
    {
      Header: t(translations.AssetCatName) as string,
      accessor: 'AssetCatName',
    },
    {
      Header: t(translations.AssetCatGroupName) as string,
      accessor: 'AssetCatGroupName',
    },
    {
      Header: t(translations.AssetType2Counter) as string,
      accessor: 'Type2Counter',
    },
    {
      Header: t(translations.AccountingId) as string,
      accessor: 'AccountingId',
    },
    {
      Header: t(translations.Overdue) as string,
      accessor: 'Overdue',
    },
    {
      Header: t(translations.DueToday) as string,
      accessor: 'DueToday',
    },
    {
      Header: t(translations.ShortDescriptionOfInstrument) as string,
      accessor: 'ShortDescription',
    },
    {
      Header: t(translations.Description) as string,
      accessor: 'Description',
      Cell: DescriptionCell({ width: 'medium' }),
    },
    {
      Header: t(translations.Campus) as string,
      accessor: 'Campus',
    },
    {
      Header: t(translations.Building) as string,
      accessor: 'Building',
      Cell: ({ value, row }) => {
        if (row.original.AreaMapId === null) {
          return <>{value}</>;
        } else {
          return (
            <>
              <Link
                href={`/Maps/Areas/Details.aspx?id=${row.original.AreaMapId}`}
              >
                {value}
              </Link>
            </>
          );
        }
      },
      deps: ['AreaMapId'],
    },
    {
      Header: t(translations.Room) as string,
      accessor: 'Room',
      Cell: ({ value, row }) => {
        if (row.original.RoomMapId === null) {
          return <>{value}</>;
        } else {
          return (
            <>
              <Link href={`/Maps/Room.aspx?id=${row.original.RoomId}`}>
                {value}
              </Link>
            </>
          );
        }
      },
      deps: ['RoomId', 'RoomMapId'],
    },
    {
      Header: t(translations.CoreName) as string,
      accessor: 'ServiceGroupName',
      Cell: ({ value, row }) => {
        if (row.original.ServiceGroupAssetId === null) {
          return <>{value}</>;
        } else {
          return (
            <>
              <ExternalLink
                href={{
                  path: 'Assets/Details.aspx',
                  search: { id: row.original.ServiceGroupAssetId },
                }}
              >
                {value}
              </ExternalLink>
            </>
          );
        }
      },
      deps: ['ServiceGroupAssetId'],
    },
    {
      Header: t(translations.Division) as string,
      accessor: 'DivisionName',
      Cell: ({ value, row }) => {
        if (row.original.DivisionAssetId === null) {
          return <>{value}</>;
        } else {
          return (
            <>
              <AssetLink id={row.original.DivisionAssetId}>{value}</AssetLink>
            </>
          );
        }
      },
      deps: ['DivisionAssetId'],
    },
    {
      Header: t(translations.OtherNames) as string,
      accessor: 'OtherNames',
    },
    {
      Header: t(translations.Brand) as string,
      accessor: 'Brand',
    },
    {
      Header: t(translations.Raac) as string,
      accessor: 'Raac',
      Cell: ({ value }) => <BooleanRender value={value} />,
    },
    {
      Header: t(translations.ApprovalRequired) as string,
      accessor: 'ApprovalRequired',
      Cell: ({ value }) => <BooleanRender value={value} />,
    },
    {
      Header: t(translations.MainSpecRange) as string,
      accessor: 'MainSpecRange',
    },
    {
      Header: t(translations.WebLink) as string,
      accessor: 'WebLink',
    },
    {
      Header: t(translations.BrandCode) as string,
      accessor: 'BrandCode',
    },
    {
      Header: t(translations.InternalCode) as string,
      accessor: 'InternalCode',
    },
    {
      Header: t(translations.Rank) as string,
      accessor: 'Rank',
    },
    {
      Header: t(translations.ElectricalSpecs) as string,
      accessor: 'ElectricalSpecs',
    },
    {
      Header: t(translations.FunctionalCharacteristics) as string,
      accessor: 'FunctionalCharacteristics',
    },
    {
      Header: t(translations.Inventory) as string,
      accessor: 'inventory',
    },
    {
      Header: t(translations.Asset_ExtraDDL0) as string,
      accessor: 'ExtraDDL0Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL1) as string,
      accessor: 'ExtraDDL1Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL2) as string,
      accessor: 'ExtraDDL2Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL3) as string,
      accessor: 'ExtraDDL3Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL4) as string,
      accessor: 'ExtraDDL4Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL5) as string,
      accessor: 'ExtraDDL5Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL6) as string,
      accessor: 'ExtraDDL6Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL7) as string,
      accessor: 'ExtraDDL7Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL8) as string,
      accessor: 'ExtraDDL8Value',
      Cell: ({ value, original }) => !!value && <>{original.ExtraDDL8Value}</>,
    },
    {
      Header: t(translations.Asset_ExtraDDL9) as string,
      accessor: 'ExtraDDL9Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL10) as string,
      accessor: 'ExtraDDL10Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL11) as string,
      accessor: 'ExtraDDL11Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL12) as string,
      accessor: 'ExtraDDL12Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL13) as string,
      accessor: 'ExtraDDL13Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL14) as string,
      accessor: 'ExtraDDL14Value',
    },
    {
      Header: t(translations.Asset_ExtraDDL15) as string,
      accessor: 'ExtraDDL15Value',
    },
    {
      Header: t(translations.Asset_ExtraText0) as string,
      accessor: 'ExtraText0',
    },
    {
      Header: t(translations.Asset_ExtraText1) as string,
      accessor: 'ExtraText1',
    },
    {
      Header: t(translations.Asset_ExtraText2) as string,
      accessor: 'ExtraText2',
    },
    {
      Header: t(translations.Asset_ExtraText3) as string,
      accessor: 'ExtraText3',
    },
    {
      Header: t(translations.Asset_ExtraText4) as string,
      accessor: 'ExtraText4',
    },
    {
      Header: t(translations.Asset_ExtraText5) as string,
      accessor: 'ExtraText5',
    },
    {
      Header: t(translations.Asset_ExtraText6) as string,
      accessor: 'ExtraText6',
    },
    {
      Header: t(translations.Asset_ExtraText7) as string,
      accessor: 'ExtraText7',
    },
    {
      Header: t(translations.Asset_ExtraText8) as string,
      accessor: 'ExtraText8',
    },
    {
      Header: t(translations.Asset_ExtraText9) as string,
      accessor: 'ExtraText9',
    },
    {
      Header: t(translations.Asset_ExtraText10) as string,
      accessor: 'ExtraText10',
    },
    {
      Header: t(translations.Asset_ExtraText11) as string,
      accessor: 'ExtraText11',
    },
    {
      Header: t(translations.Asset_ExtraText12) as string,
      accessor: 'ExtraText12',
    },
    {
      Header: t(translations.Asset_ExtraText13) as string,
      accessor: 'ExtraText13',
    },
    {
      Header: t(translations.Asset_ExtraText14) as string,
      accessor: 'ExtraText14',
    },
    {
      Header: t(translations.Asset_ExtraText15) as string,
      accessor: 'ExtraText15',
    },
    {
      accessor: 'ExtraDate0',
      Header: t(translations.Asset_ExtraDate0) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'ExtraDate1',
      Header: t(translations.Asset_ExtraDate1) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'ExtraDate2',
      Header: t(translations.Asset_ExtraDate2) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'ExtraDate3',
      Header: t(translations.Asset_ExtraDate3) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'ExtraDate4',
      Header: t(translations.Asset_ExtraDate4) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'ExtraDate5',
      Header: t(translations.Asset_ExtraDate5) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'ExtraDate6',
      Header: t(translations.Asset_ExtraDate6) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'ExtraDate7',
      Header: t(translations.Asset_ExtraDate7) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'ExtraDate8',
      Header: t(translations.Asset_ExtraDate8) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'ExtraDate9',
      Header: t(translations.Asset_ExtraDate9) as string,
      Cell: DateRenderer,
    },
    /*{
          Header: t(translations.Asset_ExtraInt0) as string,
          accessor: 'ExtraInt0',
        },
        {
          Header: t(translations.Asset_ExtraInt1) as string,
          accessor: 'ExtraInt1',
        },
        {
          Header: t(translations.Asset_ExtraInt2) as string,
          accessor: 'ExtraInt2',
        },
        {
          Header: t(translations.Asset_ExtraInt3) as string,
          accessor: 'ExtraInt3',
        },
        {
          Header: t(translations.Asset_ExtraInt4) as string,
          accessor: 'ExtraInt4',
        },
        {
          Header: t(translations.Asset_ExtraDec0) as string,
          accessor: 'ExtraDec0',
        },
        {
          Header: t(translations.Asset_ExtraDec1) as string,
          accessor: 'ExtraDec1',
        },
        {
          Header: t(translations.Asset_ExtraDec2) as string,
          accessor: 'ExtraDec2',
        },
        {
          Header: t(translations.Asset_ExtraDec3) as string,
          accessor: 'ExtraDec3',
        },
        {
          Header: t(translations.Asset_ExtraDec4) as string,
          accessor: 'ExtraDec4',
        },
        {
          Header: t(translations.Asset_ExtraBool0) as string,
          accessor: 'ExtraBool0',
        },
        {
          Header: t(translations.Asset_ExtraBool1) as string,
          accessor: 'ExtraBool1',
        },
        {
          Header: t(translations.Asset_ExtraBool2) as string,
          accessor: 'ExtraBool2',
        },
        {
          Header: t(translations.Asset_ExtraBool3) as string,
          accessor: 'ExtraBool3',
        },
        {
          Header: t(translations.Asset_ExtraBool4) as string,
          accessor: 'ExtraBool4',
        },*/
    {
      Header: t(translations.Asset_ExtraUrl0) as string,
      accessor: 'ExtraUrl0',
    },
    {
      Header: t(translations.Asset_ExtraUrl1) as string,
      accessor: 'ExtraUrl1',
    },
    /*{
          Header: t(translations.Asset_ExtraUrl2) as string,
          accessor: 'ExtraUrl2',
        },
        {
          Header: t(translations.Asset_ExtraUrl3) as string,
          accessor: 'ExtraUrl3',
        },
        {
          Header: t(translations.Asset_ExtraUrl4) as string,
          accessor: 'ExtraUrl4',
        },
        {
          Header: t(translations.Asset_ExtraRichText0) as string,
          accessor: 'ExtraRichText0',
        },
        {
          Header: t(translations.Asset_ExtraRichText1) as string,
          accessor: 'ExtraRichText1',
        },
        {
          Header: t(translations.Asset_ExtraRichText2) as string,
          accessor: 'ExtraRichText2',
        },
        {
          Header: t(translations.Asset_ExtraRichText3) as string,
          accessor: 'ExtraRichText3',
        },
        {
          Header: t(translations.Asset_ExtraRichText4) as string,
          accessor: 'ExtraRichText4',
        },*/
    {
      Header: t(translations.NickName) as string,
      accessor: 'NickName',
    },
    {
      Header: t(translations.PurchaseOrder) as string,
      accessor: 'POnumber',
    },
    {
      Header: t(translations.ServiceContract) as string,
      accessor: 'ServiceContract',
    },
    {
      Header: t(translations.GasSource) as string,
      accessor: 'GasSource',
    },
    {
      Header: t(translations.ModelNumber) as string,
      accessor: 'ModelNumber',
    },
    {
      Header: t(translations.Accessories) as string,
      accessor: 'AccessoriesText',
    },
    {
      Header: t(translations.SGA_Public) as string,
      accessor: 'Public',
      Cell: ({ value }) => <BooleanRender value={value} />,
    },
    {
      Header: t(translations.LocationName) as string,
      accessor: 'LocationName',
    },
    {
      Header: t(translations.SubLocationName) as string,
      accessor: 'SubLocationName',
    },
    {
      Header: t(translations.UnderOriginalWarranty) as string,
      accessor: 'CanViewWarrantyEndDate',
      Cell: ({ value, row }) => {
        if (
          settings?.GetBooleanByKey(
            AllowedSettings.WarrantyEndDateVisibleAllUsers,
          )
        ) {
          return <BooleanRender value={value} />;
        } else {
          let canview =
            user !== undefined &&
            (user.Roles.includes(Roles.Administrators) ||
              user.Roles.includes(Roles.InstrumentEventsAdmin) ||
              (user.Roles.includes(Roles.GroupAdministrators) &&
                user.HasAdminGroupPermissions(
                  row.original.ServiceGroupId ?? undefined,
                )));
          return <BooleanRender value={canview} />;
        }
      },
    },
    {
      Header: t(translations.ExportLicense) as string,
      accessor: 'ExportLicense',
      Cell: ({ value }) => <BooleanRender value={value} />,
    },
    {
      Header: t(translations.Tags) as string,
      accessor: 'TagsText',
    },
    {
      Header: t(translations.EquipmentExperts) as string,
      accessor: 'ExpertsText',
    },
    {
      Header: t(translations.ContactsPersonForEquipment) as string,
      accessor: 'ContactsText',
    },
    {
      Header: suppliersLabel,
      accessor: 'SuppliersText',
    },
    {
      Header: t(translations.AssemblyParent) as string,
      accessor: 'AssemblyParent',
    },
    {
      Header: t(translations.AssemblyChilds) as string,
      accessor: 'AssemblyChilds',
    },
    {
      Header: t(translations.BookAppVersion) as string,
      accessor: 'BookAppVersion',
    },
    {
      Header: t(translations.BookAppVersionUpdateDate) as string,
      accessor: 'BookAppVersionUpdateDate',
      Cell: ({ value }) => <DateTimeLabel value={value as Date} />,
    },
    {
      Header: t(translations.LateTime) as string,
      accessor: 'LateTime',
      Cell: DecimalRenderer,
    },
    {
      Header: t(translations.EarlyLeaveTime) as string,
      accessor: 'EarlyLeaveTime',
      Cell: DecimalRenderer,
    },
    {
      Header: t(translations.EarlyStartAllowedMinutes) as string,
      accessor: 'EarlyStartAllowed',
      Cell: DecimalRenderer,
    },
    {
      Header: t(translations.Model) as string,
      accessor: 'Model',
    },
  ];

  if (IsModuleEnabled(appSettings, KnownModules.EquipmentAssemblies)) {
    res.push({
      Header: t(translations.InstrumentType) as string,
      accessor: 'InstrumentType',
    });
  }
  if (IsModuleEnabled(appSettings, KnownModules.LocationTracking)) {
    res.push({
      Header: t(translations.LocationUpdatedAt) as string,
      accessor: 'LocationUpdatedAt',
      Cell: ({ value }) => <DateTimeLabel value={value as Date} />,
    });
  }

  return res;
}
