import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { toLowerCaseSafely } from 'utils/typeUtils';
import { IAuthenticatedUser, isInRole } from './AuthenticatedUser';

export interface SiteMapSettings {
  userNodes: SiteMapNode[];
  adminNodes: SiteMapNode[];
  rootNode: SiteMapNode;
  adminRootNode: SiteMapNode;
}

export interface SiteMapNode {
  Roles?: string[];
  HasChildNodes: boolean;
  Key?: string;
  ResourceKey?: string;
  Title: string;
  Description?: string;
  RootNode: SiteMapNode | null;
  Url: string;
  ChildNodes: SiteMapNode[];
  icon?: string;
  reactUrl: string;
  HideReact: boolean;
  isAdminNode: boolean;
  ParentNode: SiteMapNode | null;
  Hide: boolean;
  LinkWithChildren?: string | null;
}
export const NoAuthNodes = (t: TFunction): SiteMapNode[] => {
  return [
    {
      Roles: [],
      HasChildNodes: false,
      Key: undefined,
      ResourceKey: undefined,
      Title: t(translations.reg_NewUserRegistration) as string,
      Description: undefined,
      RootNode: null,
      Url: '/RegisterAccount',
      ChildNodes: [],
      icon: undefined,
      reactUrl: '/RegisterAccount',
      HideReact: false,
      isAdminNode: false,
      ParentNode: null,
      Hide: false,
    },
  ] as SiteMapNode[];
};

export class SiteMapProvider {
  settings: SiteMapSettings | null;
  constructor(sett: SiteMapSettings | null) {
    this.settings = sett;
  }
  public getAdminNodes(): SiteMapNode[] {
    if (
      !!this.settings &&
      this.settings !== null &&
      !!this.settings.adminNodes
    ) {
      //console.log('adminNodes', this.settings.adminNodes);
      return this.settings.adminNodes;
    } else {
      //console.log('empty admin nodes');
      return [] as SiteMapNode[];
    }
  }
  public getUserNodes(): SiteMapNode[] {
    if (
      !!this.settings &&
      this.settings !== null &&
      !!this.settings.userNodes
    ) {
      //console.log('userNodes', this.settings.userNodes);
      return this.settings.userNodes;
    } else {
      //console.log('empty user nodes');
      return [] as SiteMapNode[];
    }
  }
  public getCurrentMainNode(pathname: string): SiteMapNode | undefined {
    let node: SiteMapNode[] | undefined = undefined;
    let searchItem = pathname;
    if (!!this.settings && this.settings != null) {
      node = this.settings.adminNodes
        .filter(f => f.Hide === false)
        .concat(this.settings.userNodes.filter(f => f.Hide === false))
        .filter((item, index) => {
          if (item.ChildNodes.length > 0) {
            let irurl = toLowerCaseSafely(item.reactUrl);

            return (
              item.ChildNodes.filter(chitem => {
                let rurl = toLowerCaseSafely(chitem.reactUrl);
                if (rurl !== null) {
                  let rpath = rurl.split('?')[0] ?? rurl;
                  return searchItem.toLowerCase().indexOf(rpath) > -1;
                } else {
                  return chitem.Url.toLowerCase().indexOf(searchItem) > -1;
                }
              }).length > 0 ||
              (irurl !== null
                ? searchItem
                    .toLowerCase()
                    .indexOf(irurl.split('?')[0] ?? irurl) > -1
                : item.Url.toLowerCase().indexOf(searchItem) > -1) === true
            );
          } else {
            let irurl = toLowerCaseSafely(item.reactUrl);
            let prurl =
              item.ParentNode !== null
                ? toLowerCaseSafely(item.ParentNode.reactUrl)
                : null;
            let purl = item.ParentNode !== null ? item.ParentNode.Url : null;
            return (
              (irurl !== null
                ? searchItem
                    .toLowerCase()
                    .indexOf(irurl.split('?')[0] ?? irurl) > -1
                : item.Url.toLowerCase().indexOf(searchItem) > -1) === true ||
              (item.ParentNode !== null && prurl !== null
                ? searchItem
                    .toLowerCase()
                    .indexOf(prurl.split('?')[0] ?? prurl) > -1
                : purl !== null
                ? purl.toLowerCase().indexOf(searchItem) > -1
                : false)
            );
          }
        });
    }
    // There may be multiple results.
    // This happens when the react page is selected.
    // It is necessary to choose the result whose reactUrl length is longer.
    if (node !== undefined && node.length > 0) {
      let res: SiteMapNode | undefined = node[0];
      if (node.length > 1) {
        var rurl = '';
        node.forEach(item => {
          item.ChildNodes.forEach(chitem => {
            let rurl1 = toLowerCaseSafely(chitem.reactUrl);
            if (rurl1 !== null) {
              let rpath = rurl1.split('?')[0] ?? rurl1;
              if (searchItem.toLowerCase().indexOf(rpath) > -1) {
                if (rpath.length > rurl.length) {
                  res = item;
                  rurl = rpath;
                }
              }
            }
          });
        });
      }
      //console.log('getCurrentNode', node);
      return res;
    } else {
      return undefined;
    }
  }
  public currentMainNodeIsAdmin(pathname: string): boolean | undefined {
    let isAdmin = this.getCurrentMainNode(pathname)?.isAdminNode;
    //console.log('isAdmin', isAdmin);
    return isAdmin;
  }
  public getCurrentNodes(pathname: string): SiteMapNode[] {
    let isAdmin = this.currentMainNodeIsAdmin(pathname);
    if (isAdmin) {
      return this.getAdminNodes().filter(f => f.Hide === false);
    } else {
      return this.getUserNodes().filter(f => f.Hide === false);
    }
  }
  public findNode(pathname: string): SiteMapNode | undefined {
    let node: SiteMapNode | undefined = undefined;
    if (!!this.settings && this.settings != null) {
      let parentNodes = this.settings.userNodes.concat(
        this.settings.adminNodes,
      );
      let childNodes = this.settings.userNodes
        .concat(this.settings.adminNodes)
        .map(f => f.ChildNodes)
        .flat();
      const currentPathNameLowered = pathname.toLowerCase();
      // first try exact match node
      node = childNodes.find(element => {
        const elementURL = element.reactUrl ?? element.Url;
        const [elementPathName] = elementURL.split('?');
        return currentPathNameLowered === elementPathName?.toLowerCase();
      });
      // then if no exact match was found - try the indexOf for whatever reason it was done originally
      if (!node) {
        childNodes.find(element => {
          if (element !== undefined) {
            const reactUrl = toLowerCaseSafely(element.reactUrl);

            if (reactUrl !== null) {
              let rpath = reactUrl.split('?')[0] ?? reactUrl;
              //return pathname.toLowerCase() === rpath;
              return pathname.toLowerCase().indexOf(rpath) > -1;
            } else {
              return (
                element.Url.toLowerCase().indexOf(pathname.toLowerCase()) > -1
              );
            }
          }
          return undefined;
        });
      }
      if (!node) {
        node = parentNodes.find(element => {
          if (element !== undefined) {
            const reactUrl = toLowerCaseSafely(element.reactUrl);
            if (reactUrl !== null) {
              let rpath = reactUrl.split('?')[0] ?? reactUrl;
              //return pathname.toLowerCase() === rpath;
              return pathname.toLowerCase().indexOf(rpath) > -1;
            } else {
              return (
                element.Url.toLowerCase().indexOf(pathname.toLowerCase()) > -1
              );
            }
          }
          return undefined;
        });
      }
    }
    return node;
  }
  public updatePath(pathname: string | undefined, newSearch: string) {
    if (!!this.settings && this.settings != null && pathname) {
      this.getCurrentNodes(pathname)
        .map(f => f.ChildNodes)
        .flat()
        .forEach(element => {
          if (element !== undefined) {
            const reactUrl = element.reactUrl;
            if (reactUrl !== undefined && reactUrl !== null) {
              const elementPath = reactUrl.split('?')[0] ?? reactUrl;
              if (elementPath === pathname) {
                element.reactUrl = `${pathname}?${newSearch}`;
                element.Url = `${pathname}?${newSearch}`;
              }
            }
          }
        });
    }
  }
  public accesibleByRole(pathname: string, user?: IAuthenticatedUser): boolean {
    if (user) {
      let currentNode = this.findNode(pathname);
      if (currentNode) {
        if (currentNode.Roles && currentNode.Roles.length > 0) {
          return (
            currentNode.Roles.filter(item => isInRole(user, item)).length > 0
          );
        } else if (
          currentNode.ParentNode !== null &&
          currentNode.ParentNode.Roles &&
          currentNode.ParentNode.Roles.length > 0
        ) {
          return (
            currentNode.ParentNode.Roles.filter(item => isInRole(user, item))
              .length > 0
          );
        }
      }
    }
    return false;
  }
}
