import { Box } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { FormLabelText } from 'app/components/Forms/FormLabelText';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
export interface FormUnlimitedDowntimeProps {
  onClick: () => void;
}
export function FormUnlimitedDowntime(props: FormUnlimitedDowntimeProps) {
  const { t } = useTranslation();
  return (
    <FormLabelText
      name="FormUnlimitedDowntime"
      label={t(translations.UnlimitedDowntime)}
      text={
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          paddingLeft="4px"
        >
          <Icon icon={['fad', 'infinity']} size="2x" />
          <Button variant="main" size="small" onClick={props.onClick}>
            {t(translations.EndDowntime)}
          </Button>
        </Box>
      }
    />
  );
}
