/**
 *
 * InfoIcon
 *
 */
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Icon } from '../BasicIcons/FontAwesome';
import { Tooltip, TooltipsProps } from '../BasicTooltips/Tooltip';

export interface InfoIconProps extends Pick<TooltipsProps, 'title'> {}

export const InfoIcon = React.memo(function BookitlabLoginLogo(
  props: InfoIconProps,
) {
  const classes = useStyles();
  return (
    <Tooltip title={props.title}>
      <span>
        <Icon icon="info-circle" className={classes.root} />
      </span>
    </Tooltip>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: '1ch',
      // root css
    },
  }),
);
