import DateRenderer from 'app/components/BasicTable/CellRenderers/DateRenderer';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';
import { IRowHistory } from './IRowHistory';

export const getColumns = (t: TFunction) => {
  const res: Column<IRowHistory>[] = [
    {
      accessor: 'InvoiceRowId',
      Header: t(translations.HashSymbol) as string,
    },
    {
      accessor: 'FieldName',
      Header: t(translations.Field) as string,
    },
    {
      accessor: 'OldValue',
      Header: t(translations.OldValue) as string,
    },
    {
      accessor: 'NewValue',
      Header: t(translations.NewValue) as string,
    },
    {
      accessor: 'DisplayName',
      Header: t(translations.User) as string,
    },
    {
      accessor: 'UpdatedAt',
      Header: t(translations.Date) as string,
      Cell: DateRenderer,
    },
  ];
  return res;
};
