import {
  BaseURLPicker,
  BaseURLPickerProps,
} from 'app/components/BasicPickers/BaseURLPicker';
import { Entity } from 'types/common';

const url = '/api/odata/v4/InvoiceTemplateFilter';
export interface InvoiceTemplatePickerProps
  extends Omit<BaseURLPickerProps<Entity<number>>, 'url'> {}

export const InvoiceTemplatePicker = (props: InvoiceTemplatePickerProps) => {
  return (
    <BaseURLPicker url={url} {...props} id={props.id || 'InvoiceTemplateId'} />
  );
};
