/**
 *
 * Asynchronously loads the component for AnimalExtendedBillingReportPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AnimalExtendedBillingReportPage = lazyLoad(
  () => import('./index'),
  module => module.AnimalExtendedBillingReportPage,
);
