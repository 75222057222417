import { useDispatch, useSelector } from 'react-redux';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { selectServiceRequestDetailsFieldState } from '../../RequestSamplesPage/slice/selectors';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';

export interface IUseServiceRequestStatusUpdateProps {
  serviceRequestStatusId: number;
}
export function useServiceRequestStatusUpdate(
  props: IUseServiceRequestStatusUpdateProps,
) {
  const { actions } = useRequestSamplesSlice();
  const dispatch = useDispatch();
  const changeStatus = (value: {
    status: IServiceRequestStatusDto;
    reason?: string;
  }) => {
    value !== null &&
      dispatch(
        actions.updateStatus({
          Id: props.serviceRequestStatusId,
          Status: value.status,
          Reason: value.reason,
        }),
      );
  };
  const fieldState = useSelector(state =>
    selectServiceRequestDetailsFieldState(state, 'Status'),
  );
  return { changeStatus, fieldState };
}
