import { PayloadAction } from '@reduxjs/toolkit';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { Entity } from 'types/common';
import { Timestamp } from 'types/Timestamp';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { workOrderSaga } from './saga';
import {
  ConvertEntityToModel,
  DowntimeOverlaps,
  ReservationsOfflineData,
  WorkOrderDetailsState,
  WorkOrderGlobalState,
  WorkOrderQueryStringParameters,
  WorkOrderReadonlyState,
  WorkOrderState,
} from './types';

export const initialState: WorkOrderState = {
  processing: false,
  createState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  updateState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  reservationOffline: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  sendEmailOnOffline: false,
  readonlyState: undefined,
  updateAllRecurrent: false,
  valueHandler: undefined,
  doSaveNewComments: undefined,
  createdOrders: [],
  savedLinkedOrders: [],
  calibrationsChanged: undefined,
  linksProcessing: undefined,
  downtimeOverlaps: undefined,
};

const slice = createSlice({
  name: 'workOrder',
  initialState,
  reducers: {
    initWorkOrder(
      state,
      action: PayloadAction<WorkOrderQueryStringParameters>,
    ) {
      state.processing = true;
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.downtimeOverlaps = undefined;
    },
    initWorkOrder_Success(state, action: PayloadAction<IWorkOrderDetailsDto>) {
      state.processing = false;
      state.createState.data = ConvertEntityToModel(
        action.payload,
        action.payload.ToDown,
      );
    },
    initWorkOrder_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    initUpdateWorkOrder(
      state,
      action: PayloadAction<WorkOrderQueryStringParameters>,
    ) {
      state.processing = true;
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initUpdateWorkOrder_Success(
      state,
      action: PayloadAction<IWorkOrderDetailsDto>,
    ) {
      state.processing = false;
      state.updateState.data = ConvertEntityToModel(action.payload, undefined);
      state.readonlyState = action.payload as WorkOrderReadonlyState;
    },
    initUpdateWorkOrder_Error(state, action: PayloadAction<any>) {
      state.processing = false;
      // state.updateState.data = undefined;
      // state.readonlyState = undefined;
    },
    createWorkOrder(state, action: PayloadAction<WorkOrderGlobalState>) {
      state.createState.processing = true;
      state.createState.completed = undefined;
      state.createState.hasErrors = undefined;
    },
    createWorkOrder_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        sendEmailOnOffline: boolean;
        reservationOffline: ReservationsOfflineData[];
      }>,
    ) {
      state.createState.processing = false;
      state.createState.hasErrors = action.payload.hasErrors;
      state.createState.completed = true;

      state.reservationOffline.data = action.payload.reservationOffline;
      state.sendEmailOnOffline = action.payload.sendEmailOnOffline;
    },
    createWorkOrder_Error(state, action: PayloadAction<any>) {
      state.createState.processing = false;
      state.createState.completed = true;
      state.createState.hasErrors = true;
    },
    updateWorkOrder(
      state,
      action: PayloadAction<{
        current: WorkOrderDetailsState;
        original: WorkOrderDetailsState | undefined;
        originalSubFormFiles: IFormFileValue[];
        originalStaffFormFiles: IFormFileValue[];
      }>,
    ) {
      state.updateState.processing = true;
      state.updateState.completed = undefined;
      state.updateState.hasErrors = undefined;
    },
    updateWorkOrder_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        sendEmailOnOffline: boolean;
        reservationOffline: ReservationsOfflineData[];
      }>,
    ) {
      state.updateState.processing = false;
      state.updateState.hasErrors = action.payload.hasErrors;
      state.updateState.completed = true;
      state.reservationOffline.data = action.payload.reservationOffline;
      state.sendEmailOnOffline = action.payload.sendEmailOnOffline;
    },
    updateWorkOrder_Error(state, action: PayloadAction<any>) {
      state.updateState.processing = false;
      state.updateState.completed = true;
      state.updateState.hasErrors = true;
    },
    resetCreateWorkOrderState(state, action: PayloadAction) {
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.OfflineEventStart = undefined;
      state.OfflineEventEnd = undefined;
      state.savedLinkedOrders = [];
      state.downtimeOverlaps = undefined;
    },
    resetUpdateWorkOrderState(state, action: PayloadAction) {
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.readonlyState = undefined;
      state.savedLinkedOrders = [];
      state.downtimeOverlaps = undefined;
    },
    resetReservationsOfflineState(state, action: PayloadAction) {
      state.sendEmailOnOffline = false;
      state.reservationOffline = {
        data: undefined,
        completed: undefined,
        hasErrors: undefined,
        processing: false,
      };
      state.OfflineEventStart = undefined;
      state.OfflineEventEnd = undefined;
    },
    sendReservationsOfflineEmail(
      state,
      action: PayloadAction<{
        cancel: boolean;
        deleted: boolean;
        insert: boolean;
        doNotNotifyUsers: boolean;
        allAffectedUsers: boolean;
        reservationOffline: ReservationsOfflineData[];
      }>,
    ) {
      state.reservationOffline.processing = true;
      state.reservationOffline.completed = undefined;
      state.reservationOffline.hasErrors = undefined;
    },
    sendReservationsOfflineEmail_Success(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.reservationOffline.processing = false;
      state.reservationOffline.hasErrors = action.payload;
      state.reservationOffline.completed = true;
    },
    sendReservationsOfflineEmail_Error(state, action: PayloadAction<any>) {
      state.reservationOffline.processing = false;
      state.reservationOffline.completed = true;
      state.reservationOffline.hasErrors = true;
    },
    setUpdateAllRecurrent(state, action: PayloadAction<boolean>) {
      state.updateAllRecurrent = action.payload;
    },
    removeFromLinked(state, action: PayloadAction<number>) {
      state.processing = true;
    },
    removeFromLinked_success(state, action: PayloadAction<number>) {
      state.processing = false;
      if (state.readonlyState === undefined) return;
      state.readonlyState.LinkedAlerts = state.readonlyState.LinkedAlerts.filter(
        f => f.Id !== action.payload,
      );
    },
    addToLinked(state, action: PayloadAction<{ id: number; link: number }>) {
      state.processing = true;
    },
    addToLink_success(state, action: PayloadAction<Entity<number>>) {
      state.processing = false;
      if (state.readonlyState === undefined) return;
      state.readonlyState.LinkedAlerts.push(action.payload);
    },
    setAnyValue(
      state,
      action: PayloadAction<{
        fieldKey: keyof WorkOrderDetailsState;
        fieldValue: any;
      }>,
    ) {},
    setAnyValueSuccess(
      state,
      action: PayloadAction<{
        fieldKey: keyof WorkOrderDetailsState;
        fieldValue: any;
      }>,
    ) {
      state.valueHandler = {
        run: true,
        fieldKey: action.payload.fieldKey,
        fieldValue: action.payload.fieldValue,
        shouldValidate: true,
      };
    },
    resetValueHandler(state, action: PayloadAction) {
      state.valueHandler = undefined;
    },
    /**
     * Sets the offline event start
     * @param state
     * @param action
     */
    setOfflineEventStart(state, action: PayloadAction<Timestamp | undefined>) {
      state.OfflineEventStart = action.payload;
    },
    /**
     * Sets the offline event end
     * @param state
     * @param action
     */
    setOfflineEventEnd(state, action: PayloadAction<Timestamp | undefined>) {
      state.OfflineEventEnd = action.payload;
    },
    setSaveNewComments(state, action: PayloadAction<boolean | undefined>) {
      state.doSaveNewComments = action.payload;
    },
    setOrderNumbers(state, action: PayloadAction<number[]>) {},
    setOrderNumbers_success(state, action: PayloadAction<number[]>) {
      state.createdOrders = action.payload;
    },
    setSavedLinkedOrders(
      state,
      action: PayloadAction<Array<IWorkOrderDetailsDto>>,
    ) {
      state.savedLinkedOrders = action.payload;
    },
    resetSavedLinkedOrders(state, action: PayloadAction) {
      state.savedLinkedOrders = [];
    },
    removeLinkedCalibration(
      state,
      action: PayloadAction<{ id: number; link: number }>,
    ) {
      state.linksProcessing = true;
    },
    removeLinkedCalibration_success(state, action: PayloadAction) {
      state.linksProcessing = false;
      state.calibrationsChanged = true;
    },
    addLinkedCalibration(
      state,
      action: PayloadAction<{ id: number; link: number }>,
    ) {
      state.linksProcessing = true;
    },
    addLinkedCalibration_success(state, action: PayloadAction) {
      state.linksProcessing = false;
      state.calibrationsChanged = true;
    },
    setCalibrationChanged(state, action: PayloadAction<boolean | undefined>) {
      state.calibrationsChanged = action.payload;
    },
    checkDowntimeOverlaps(state, action: PayloadAction<number>) {},
    checkDowntimeOverlaps_Success(
      state,
      action: PayloadAction<DowntimeOverlaps[] | undefined>,
    ) {
      state.downtimeOverlaps = action.payload;
    },
  },
});

export const { actions: workOrderActions } = slice;

export const useWorkOrderSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: workOrderSaga });
  return { actions: slice.actions };
};
