import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { PurchaseOrderOptions } from 'api/odata/generated/enums/PurchaseOrderOptions';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { getBudgetVisible } from 'app/pages/ReservationDetails/Details/slice/getBudgetVisible';
import { FundingTypes } from 'app/pages/ReservationDetails/Details/slice/types';
import { Roles } from 'app/slice/types';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { AsyncDataState } from 'types/common';
import { IConnectedFiltersDto } from 'types/IConnectedFiltersDto';
import { FieldHandlerProps } from '../../FormRender/FormRenderer';

/* --- STATE --- */
export interface ConnectedFiltersState
  extends AsyncDataState<IConnectedFiltersDto> {
  connectedValueHandler?: FieldHandlerProps<IConnectedFiltersDto>;
  connectedSettings: ConnectedFiltersSettings;
}
export interface ConnectedFiltersSettings {
  startTimeVisible?: boolean;
  endTimeVisible?: boolean;
  budgetVisible?: boolean;
  userVisible?: boolean;
  userGroupVisible?: boolean;
  fundingTypeVisible?: boolean;
  budgetExpirementVisible?: boolean;
  instProjVisible?: boolean;
  extCustomerVisible?: boolean;
  purchaseOrderVisible?: boolean;
  referenceVisible?: boolean;
  onlyOwnUserGroupsAllowed?: boolean;
  ReservationUserGroupGroupByBudgetUserGroup?: boolean;
  budgetLimitedByUsers?: boolean;
  isBudgetsWithoutUserGroup?: boolean;
  hideProject?: boolean;
  isServiceAdmin?: boolean;
  isUserGroupCoordinator?: boolean;
  chargesViewAllowed?: boolean;
  hasConflictProjects?: boolean;
}
export function mapSettings(
  services: IServiceTypeFilterDto[],
  globalSettings: GlobalSettingsType,
  settings: ConnectedFiltersSettings,
  isEditMode: boolean,
  user: AuthenticatedUser | undefined,
  data?: IConnectedFiltersDto,
): ConnectedFiltersSettings {
  let budgetEnabled = getBudgetVisible(
    globalSettings,
    services.map(service => ({
      Billable: service.Billable,
      BudgetsTurnedOn: service.BudgetsTurnedOn,
      HideProjects: service.HideProject,
    })),
  );

  let resUGEnabled =
    services.length > 0
      ? globalSettings.reservationUserGroupGroupByBudgetUserGroup &&
        budgetEnabled
      : globalSettings.reservationUserGroupGroupByBudgetUserGroup;
  let isServiceAdmin = user?.IsAllGroupOrLabTechAdmin(
    services.map(f => f.ServiceGroupId ?? 0),
    services,
  );
  let isRoleAdmin =
    user?.Roles.includes(Roles.Administrators) ||
    user?.Roles.includes(Roles.GroupAdministrators) ||
    user?.Roles.includes(Roles.LabTech);
  let isUserGroupCoordinator =
    user &&
    user.Roles.includes(Roles.UserGroupCoord) &&
    (isEditMode ? !!data && data.UserGroup?.Id === user.ActiveUserGroup : true);
  let btExpEnabled =
    globalSettings.budgetExperimentModuleEnabled && budgetEnabled;
  // && !!data
  //   ? data.Budget !== null
  //     ? !((data.Budget as IBudgetFilterDto).HideExperiments === true)
  //     : false
  //   : false;
  let chargesViewAllowed =
    (user && user.Roles.includes(Roles.FinAdmin)) ||
    isServiceAdmin ||
    (user &&
      user.Roles.includes(Roles.InvoicesReader) &&
      user.ActiveUserGroup?.Id === data?.UserGroup?.Id);
  const result = Object.assign({}, settings, {
    hideProject:
      services.length > 0 ? services.every(f => f.HideProject) : undefined,
    isUserGroupCoordinator: isUserGroupCoordinator,
    hasConflictProjects:
      services.some(f => f.HideProject === true) &&
      services.some(f => f.HideProject === false),
    budgetVisible: budgetEnabled,
    fundingTypeVisible:
      globalSettings.fundingTypeBudgetEnabled &&
      // funding type is not needed unless the PurchaseOrder is also enabled
      globalSettings.purchaseOrderBudgetEnabled,
    ReservationUserGroupGroupByBudgetUserGroup: resUGEnabled,
    userGroupVisible:
      (isServiceAdmin || isRoleAdmin) &&
      !isEditMode &&
      !isUserGroupCoordinator &&
      !resUGEnabled,
    userVisible:
      isServiceAdmin || isUserGroupCoordinator || (!isEditMode && isRoleAdmin),
    budgetExpirementVisible: btExpEnabled,
    instProjVisible: globalSettings.instituteProjectsEnabled,
    // looks like the external customers are being deprecated
    extCustomerVisible: false,
    purchaseOrderVisible:
      globalSettings.purchaseOrderBudgetEnabled &&
      ((data?.Budget as IBudgetFilterDto)?.PurchaseOrderOptionId ??
        PurchaseOrderOptions.Hidden) !== PurchaseOrderOptions.Hidden,
    referenceVisible:
      globalSettings.showReferenceFieldInReservationDetails && !!data
        ? data.FundingType?.Id === FundingTypes.External
        : false,
    onlyOwnUserGroupsAllowed: globalSettings.onlyOwnUserGroupsAllowed,
    budgetLimitedByUsers: globalSettings.budgetLimitedByUsers,
    isBudgetsWithoutUserGroup: globalSettings.isBudgetsWithoutUserGroup,
    isServiceAdmin: isServiceAdmin,
    endTimeVisible: false,
    startTimeVisible: true,
    chargesViewAllowed:
      globalSettings.allowUsersToViewTheirCharges || chargesViewAllowed,
  });
  return result;
}
