import * as React from 'react';
import { ISchedulerEvent } from '../../types/ISchedulerEvent';
import { EventContent, EventContentProps } from './EventContent';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { ReservationStatusIcon } from './ReservationStatusIcon';
export interface ReservationEventContentProps extends EventContentProps {
  event: ISchedulerEvent;
}
export function ReservationEventConent(props: ReservationEventContentProps) {
  const x = props.event.original as ICalendarReservationDto;

  return (
    <EventContent
      containerWidth={props.containerWidth}
      backgroundColor={x?.EquipmentColor || undefined}
      body={<ReservationEventBody event={props.event} body={props.body} />}
      height={props.height}
      //todo: add ediability conditaionally based on premissions/etc.
      readonly={props.readonly}
      offsetTopHeight={props.offsetTopHeight}
      notClickable={props.notClickable}
    />
  );
}
export interface ReservationEventBodyProps {
  event: ISchedulerEvent;
  body: React.ReactNode;
}
export function ReservationEventBody(props: ReservationEventBodyProps) {
  const dto = props.event.original as ICalendarReservationDto;
  return !!dto && !!dto.Restricted && dto.Restricted === true ? (
    <span>{''}</span>
  ) : (
    <span
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        columnGap: 8,
        rowGap: 4,
      }}
    >
      {!!dto && !!dto.StatusId && <ReservationStatusIcon event={props.event} />}
      {props.body}
    </span>
  );
}
