import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { SideBar } from 'app/Layout/FrontendLayout/components/SideBar';
import clsx from 'clsx';

export interface SideBarDrawerProps {
  hideNavigation: boolean | undefined;
  isMobile: boolean;
  open: boolean;
  handleDrawerClose: (event: any, reason: any) => void;
}
export const SideBarDrawer = React.memo(function SideBarDrawer(
  props: SideBarDrawerProps,
) {
  if (props.hideNavigation) {
    return null;
  }
  const { isMobile, open, handleDrawerClose } = props;
  return (
    <Drawer
      tabIndex={0}
      variant={isMobile ? 'temporary' : 'permanent'}
      open={open}
      onClose={handleDrawerClose}
      className={clsx('sideBarDrawer', {
        mobile: isMobile,
        drawerOpen: open,
        drawerClose: !open,
      })}
    >
      <SideBar isOpen={open} />
    </Drawer>
  );
});
