import { httpClient } from './HttpClient';

export const CustomFormApi = {
  getAll: (key: number) => {
    const url = `/api/odata/v4/CustomForm(${key})`;
    return httpClient.get(url);
  },
  getSingle: (key: number, customFormId: number) => {
    const url = `/api/odata/v4/CustomForm(${key})`;
    const params: {
      $orderby: string;
      $filter?: string;
      //$select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Id asc',
      $filter: `(Id eq ${customFormId})`,
      //$select: 'Id,Name,FormFields,ServiceGroups,Active,Type',
      $skip: 0,
      $top: 1,
    };
    return httpClient.get(url, params);
  },
};
