import * as React from 'react';
import { useSelector } from 'react-redux';
import { DetectIsMobile } from 'utils/mobileDetect';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { RightSection } from '../wrappers';

export interface FormRightSectionProps {
  children: React.ReactNode;
  isCover?: boolean;
}
export const FormRightSection = (props: FormRightSectionProps) => {
  const { children, isCover } = props;
  const isMobile = DetectIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const showShortView =
    isMobile || (sidePanelOpen && !sidePanelExpanded) || isCover;
  const theme = useTheme();
  return (
    <RightSection
      className={clsx('rightsection', { shortView: showShortView })}
      theme={theme}
    >
      {children}
    </RightSection>
  );
};
