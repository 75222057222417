import Box from '@material-ui/core/Box';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { Link } from 'app/components/ExternalLink';
import {
  selectAuthenticatedUser,
  selectglobalSettings,
} from 'app/slice/selectors';
import { Roles } from 'app/slice/types';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AllowedSettings } from 'utils/globalSettings';

export interface UserProfileLinkProps {
  UserId: string | null;
  DisplayName?: string | null;
  twoRows?: boolean;
  isAdmin?: boolean;
}

export const UserProfileLink = React.forwardRef<any, UserProfileLinkProps>(
  function UserProfileLink(props, ref) {
    const { t } = useTranslation();
    const { UserId, DisplayName, isAdmin, twoRows = true, ...rest } = props;
    const authenticatedUser = useSelector(selectAuthenticatedUser);
    const settings = useSelector(selectglobalSettings);
    const isServiceAdmin: boolean = React.useMemo(
      () =>
        authenticatedUser !== undefined &&
        ((authenticatedUser.Roles.indexOf(Roles.Administrators) >= 0 ||
          isAdmin) ??
          false),
      [authenticatedUser, isAdmin],
    );
    const format = settings.GetString(AllowedSettings.UserFormatResult);
    const url = `/userprofile.aspx?id=${UserId}`;
    if ((DisplayName ?? null) == null) {
      return <></>;
    }

    const text =
      format === 'DisplayNameUserName' && DisplayName !== undefined ? (
        <Box marginLeft={1} component="span">
          {DisplayName}
          {UserId === 'undefined' ? (
            <></>
          ) : (
            <>
              {twoRows && <br />}
              <Box component="span">{' (' + UserId + ') '}</Box>
            </>
          )}
        </Box>
      ) : (
        <Box marginLeft={1} component="span">
          {DisplayName || UserId}
        </Box>
      );
    return isServiceAdmin && UserId !== 'undefined' ? (
      <Tooltip {...rest} title={t(translations.UserProfile) as string} arrow>
        <span>
          <Link ref={ref} href={url} target={'_blank'} {...rest}>
            <Icon icon="user" />
            {text}
          </Link>
        </span>
      </Tooltip>
    ) : (
      <React.Fragment>{text}</React.Fragment>
    );
  },
);
