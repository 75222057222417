import { ODataOperators } from 'api/odata/ODataFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import BooleanPickerFilter from 'app/components/BasicTable/Filters/BooleanPickerFilter';
import { ServiceGroupsFilter } from 'app/components/BasicTable/Filters/ServiceGroupsFilter';
import { SuperGroupFilter } from 'app/components/BasicTable/Filters/SuperGroupFilter';
import {
  arrayToQueryString,
  GetBooleanEntity,
  GetBooleanOrUndefined,
  getNumberEntityArray,
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  initMultiExPickerValue,
  initPickerValue,
} from 'app/components/BasicTable/types/FilterParam';
import { initSuperGroupData } from 'app/components/pickers/AutocompletePickers/SuperGroupPicker';
import { initServiceGroupsMultiData } from 'app/components/pickers/MultiSelectPickers/ServiceGroupsPicker';
import { translations } from 'locales/translations';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';

export const GetFilters: GetPageFilters<ICoresDirectoryRow> = (
  t,
  appSettings,
  user,
  settings,
) => search => {
  const params = new URLSearchParams(search);
  const res: IFilterSettings<ICoresDirectoryRow>[] = [
    {
      fieldName: 'Id',
      displayName: t(translations.ServiceGroup),
      operator: ODataOperators.Includes,
      queryStringSerializer: arrayToQueryString('sgids'),
      value: getNumberEntityArray(params, 'sgids'),
      initData: initMultiExPickerValue(initServiceGroupsMultiData),
      component: ServiceGroupsFilter,
      urlKey: 'sgids',
    },
    {
      fieldName: 'DivisionId',
      displayName: t(translations.Division),
      operator: ODataOperators.Equals,
      queryStringSerializer: 'divisionId',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'divisionId')),
      ),
      component: SuperGroupFilter,
      initData: initPickerValue(initSuperGroupData),
    },
  ];
  if (!!user && user.AdminServiceGroups && user.AdminServiceGroups.length > 0) {
    res.push({
      fieldName: 'OwnedByMe',
      displayName: t(translations.OnlyMyCores),
      queryStringSerializer: 'ownedbyme',
      operator: ODataOperators.Equals,
      value: GetBooleanEntity(
        GetBooleanOrUndefined(GetRawValue(params, 'ownedbyme')),
        t,
      ),
      component: BooleanPickerFilter,
    });
  }
  return res;
};
