/**
 *
 * ServiceRequestTicketDetails
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import { IServiceRequestTicketDto } from 'api/odata/generated/entities/IServiceRequestTicketDto';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { getInitialValues, getTicketSchema } from './schema';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { IServiceRequestTicketEdit } from 'app/pages/ServiceRequestTicketDetailsPage/ServiceRequestTicketDetails/schema';
import { useServiceRequestsKanbanSlice } from 'app/pages/ServiceRequestsKanbanPage/slice';
import { PartialExceptId } from 'utils/typeUtils';
import { Form } from './components/Form';
import {
  selectEdit,
  selectServiceRequestsKanbanProcessingUpdate,
  selectShowAddSubTicket,
} from 'app/pages/ServiceRequestsKanbanPage/slice/selectors';
import { AddSubTicket } from './components/AddSubTicket';
import { Comments } from 'app/components/Comments';
import {
  selectAuthenticatedUser,
  selectKnownModule,
} from 'app/slice/selectors';
import { ServiceType } from 'enums/ServiceTypes';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { KnownModules } from 'types/KnownModules';
import { getServiceRequestTicketTitle } from 'app/pages/ServiceRequestsKanbanPage/components/getServiceRequestTicketTitle';
import { ParentTicketDetailsButton } from 'app/pages/ServiceRequestsKanbanPage/components/ParentTicketDetailsButton';

export interface ServiceRequestTicketDetailsProps {
  item: IServiceRequestTicketDto;
  useSidePanel: boolean;
}
export function ServiceRequestTicketDetails({
  item,
  ...props
}: ServiceRequestTicketDetailsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useServiceRequestsKanbanSlice();
  const showAddSubTicket = useSelector(selectShowAddSubTicket);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const samplesAutomationEnabled = useSelector(state =>
    selectKnownModule(state, KnownModules.SamplesAutomation),
  );
  const processing = useSelector(selectServiceRequestsKanbanProcessingUpdate);
  const editState = useSelector(selectEdit);
  const editItem = editState.value;
  const enableAddSubTicket =
    !samplesAutomationEnabled || (editItem?.SamplesCount ?? 0) > 0;

  const handleAddSubTicketClick = React.useCallback(() => {
    dispatch(actions.showAddSubTicket(editItem));
  }, [actions, dispatch, editItem]);
  const rightTopActions: ActionRenderer[] = React.useMemo(() => {
    const result: ActionRenderer[] = [];
    if (editItem?.ParentTicketId === null) {
      result.push(() => (
        <TopActionButton
          icon="plus"
          startIcon="plus"
          onClick={handleAddSubTicketClick}
          disabled={!enableAddSubTicket}
          title={t(translations.AddSubServiceRequestTicket)}
          text={t(translations.AddSubServiceRequestTicket)}
        />
      ));
    } else {
      result.push(() => <ParentTicketDetailsButton item={editItem} />);
    }
    return result;
  }, [editItem, handleAddSubTicketClick, enableAddSubTicket, t]);

  const schema = React.useMemo(
    () =>
      editItem === undefined
        ? undefined
        : getTicketSchema(t, editItem, samplesAutomationEnabled),
    [editItem, samplesAutomationEnabled, t],
  );
  const initialValues = React.useMemo(
    () => getInitialValues(editItem as IServiceRequestTicketDto, t),
    [editItem, t],
  );
  const handleSubmit = React.useCallback(
    (values: IServiceRequestTicketEdit) => {
      const updated: Partial<IServiceRequestTicketDto> = {
        ...editItem,
        ...{
          AssignedTo: values.AssignedTo,
          Status: values.Status,
          Priority: values.Priority,
          SamplesCount: values.SamplesCount,
        },
      };
      if (
        (updated as PartialExceptId<IServiceRequestTicketDto>).Id === undefined
      ) {
        return;
      }
      dispatch(
        actions.update(updated as PartialExceptId<IServiceRequestTicketDto>),
      );
    },
    [actions, dispatch, editItem],
  );

  const handleCancel = () => dispatch(layoutActions.resetSidePanel());
  const cover = React.useMemo(() => {
    return (
      showAddSubTicket &&
      editItem !== undefined && (
        <AddSubTicket parentTicket={editItem} useSidePanel={false} />
      )
    );
  }, [editItem, showAddSubTicket]);
  const isAdmin = React.useMemo(
    () =>
      (editItem !== undefined &&
        authenticatedUser?.IsAdminOf({
          ServiceGroupId: editItem.ServiceRequest.Service.ServiceGroup.Id,
          ServiceId: editItem.ServiceRequest.Service.Id,
          ServiceTypeId: ServiceType.Sample,
        })) ??
      false,
    [authenticatedUser, editItem],
  );
  const title =
    editItem === undefined ? undefined : getServiceRequestTicketTitle(editItem);
  return (
    <PageWrapper
      topProcessing={processing}
      //pageName={t(translations.ServiceRequestTicketDetails)}
      titlePage={title}
      //pageLink={GetServiceRequestTicketDetailsPagePath(item.Id)}
      loading={editState.status === 'pending'}
      closable
      disableExpandToggle
      hideTitleBar={false}
      cover={cover}
      useSidePanel={props.useSidePanel}
      rightTopActions={rightTopActions}
    >
      {schema !== undefined && editItem !== undefined && (
        <>
          <Form
            schema={schema}
            item={editItem}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            processing={processing}
          />

          {editItem.ParentTicketId === null && (
            <Comments
              defaultComment={{
                IsInternal: isAdmin,
              }}
              commentType={CommentReferenceTypes.ServiceRequestTicket}
              pageName={t(translations.ServiceRequestTickets)}
              emailType="RequestComment"
              isAdmin={isAdmin}
              referenceId={editItem.Id}
            />
          )}
        </>
      )}
    </PageWrapper>
  );
}
