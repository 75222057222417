/**
 *
 * ActionIconButton
 *
 */
import * as React from 'react';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { IPathAndQuery } from 'utils/url-utils';
import { IconProp } from 'app/AllIcons';
import { ButtonsVariant } from '../Button';

export interface ActionIconButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  icon: IconProp;
  title: string;
  variant?: ButtonsVariant;
  size?: 'large' | 'medium' | 'small' | 'xs';
  autoFocus?: boolean;
  shape?: 'circle' | 'square';
  customIcon?: React.ReactNode;
  to?: string;
  href?: string | IPathAndQuery;
  id?: string;
}

export function ActionIconButton({
  disabled,
  onClick,
  title,
  icon,
  variant,
  size,
  autoFocus,
  shape,
  customIcon,
  to,
  href,
  id,
  ...other
}: ActionIconButtonProps) {
  return (
    <Tooltip title={title} arrow>
      <span>
        <IconButton
          variant={variant || 'ghost'}
          shape={shape || 'square'}
          disabled={disabled}
          onClick={onClick}
          size={size}
          to={to}
          href={href}
          autoFocus={autoFocus}
          aria-label={title}
          id={id}
          {...other}
        >
          {customIcon ? customIcon : <Icon icon={icon} />}
        </IconButton>
      </span>
    </Tooltip>
  );
}
