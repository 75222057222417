import * as React from 'react';
import { IWithSavedHistoryComponentProps } from 'app/components/BasicTable/withSavedHistory';
import { FiltersButton } from 'app/components/BasicTable/BasicFilter/FiltersButton';
import { FilterContainer } from 'app/components/BasicTable/FilterContainer';
import { DetectIsMobile } from 'utils/mobileDetect';
import { BasicFilterGroup } from 'app/components/BasicTable/BasicFilter';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { ButtonProps } from 'app/components/BasicButtons/Button';
import { CombinedSchedulerTRow } from './Filter';

export interface FiltersComponentProps
  extends IWithSavedHistoryComponentProps<CombinedSchedulerTRow>,
    Pick<ButtonProps, 'fullWidth'> {
  filtersButtonAnchorEl: HTMLElement | null;
  onFilterOpen: React.MouseEventHandler<HTMLButtonElement>;
  filterOpen: boolean;
  onFilterClose: () => void;
  filterRef?: React.RefObject<HTMLButtonElement>;
}
export function FiltersComponent({
  filterOpen,
  onFilterClose,
  onFilterOpen,
  filtersButtonAnchorEl,

  ...props
}: FiltersComponentProps) {
  const isMobile = DetectIsMobile();

  const handleFilterChange = React.useCallback(
    (items: IFilterSettings<CombinedSchedulerTRow>[]) => {
      props.onFilterChange?.(items);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      {!isMobile &&
        props.availableFilters &&
        props.availableFilters.length > 0 && (
          <FiltersButton
            onClick={onFilterOpen}
            allFiltersLength={props.availableFilters?.length}
            appliedFiltersLength={props.appliedFilters?.length}
            innerRef={props.filterRef}
          />
        )}

      <FilterContainer
        open={filterOpen}
        onClose={onFilterClose}
        onChange={handleFilterChange}
        anchorEl={filtersButtonAnchorEl}
        appliedFilters={props.appliedFilters ?? []}
      >
        <BasicFilterGroup
          availableFilters={props.availableFilters || []}
          onChange={handleFilterChange}
          appliedFilters={props.appliedFilters || []}
          onClose={onFilterClose}
          autoOpenAddFilter={true}
          showFooter={!isMobile}
          savedListSettings={props.savedListSettings}
          getDefaultFilters={props.getDefaultFilters}
        />
      </FilterContainer>
    </>
  );
}
