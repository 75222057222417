import { ISavedViewDto } from 'api/odata/generated/entities/ISavedViewDto';
import { AsyncDataState, Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';

/* --- STATE --- */
export interface SavedViewState {
  createState: AsyncDataState<SavedViewDetailsState>;
  updateState: AsyncDataState<SavedViewDetailsState>;
  processing: boolean;
  selectedSavedView?: ISavedViewDto;
  savedViewUserName?: string;
}
export interface SavedViewSelected {
  listId: number | undefined;
  ids: string | undefined;
  assetIds: string | undefined;
  multiple: boolean;
}
export interface SavedViewResponse extends IResponseType {
  Id?: number;
}
export interface SavedViewDetailsState {
  Id: number;
  Name: string;
  Assets: Entity<number>[];
  Shared?: boolean;
  Multiple?: boolean;
  // SharedTo: Entity<number> | null;
  // SharedUsers: Entity<string>[];
  // SharedCores: Entity<number>[];
}
export function ConvertEntityToModel(
  entity: ISavedViewDto,
): SavedViewDetailsState | undefined {
  if (!entity) {
    return undefined;
  } else {
    return {
      Id: entity.Id,
      Name: entity.Name,
      Assets: entity.Assets,
      Shared: entity.Shared,
      Multiple: entity.Multiple,
      // SharedTo: getSingleSharedTo(entity.SharedTo),
      // SharedUsers: entity.SharedUsers,
      // SharedCores: entity.SharedCores,
    } as SavedViewDetailsState;
  }
}
export function ConvertModelToEntity(
  model: SavedViewDetailsState,
  original: SavedViewDetailsState | undefined,
): ISavedViewDto {
  let entity = {
    Id: original?.Id === undefined ? model.Id : original.Id,
    Name: model.Name,
    Shared: model.Shared,
    Multiple: model.Multiple,
    Assets: model.Assets,
    // SharedTo: model.SharedTo?.Id ?? 0,
    // SharedUsers: model.SharedUsers as IUserFilterDto[],
    // SharedCores: model.SharedCores as IServiceGroupDto[],
  } as ISavedViewDto;
  return entity;
}
