import { List } from '@material-ui/core';
import { IconProp } from 'app/AllIcons';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { verticalScrolling } from 'styles/CssVariables/styledVariables';
import { SiteMapNode } from 'types/SiteMapSettings';
import { isNullOrUndefined } from 'utils/typeUtils';
import { useStyles } from '../styles';
import { createMenuItemRefs } from './HoverMenu';
import SidbarMenuItem, { SidebarMenuItemProps } from './MenuItem';

export interface SideBarMenuItemProps {
  menuItems: SiteMapNode[];
  currentIndex?: number;
  currentNode?: SiteMapNode | undefined;
  isOpen: boolean;
  loading: boolean;
}
const NavWrapper = styled('div')`
  height: calc(100% - 50px);
  overflow-y: auto;
  ${verticalScrolling}
`;
const replaceIcon = (icon: string): IconProp => {
  if (icon.indexOf(' fa-') !== -1) {
    let tmpIcon = icon.substring(icon.indexOf(' fa-') + 4, icon.length);
    return tmpIcon as IconProp;
  }
  return icon as IconProp;
};
const convertUrl = (url: string): string => {
  if (url !== null) {
    if (url.indexOf(process.env.PUBLIC_URL) !== -1) {
      return url.replace(process.env.PUBLIC_URL, '');
    }
    return url;
  }
  return url;
};
export function SideBarMenu(props: SideBarMenuItemProps) {
  const classes = useStyles({ isOpen: props.isOpen });
  const location = useLocation();
  const { menuItems, currentIndex } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(currentIndex || -1);
  const [mapItems, setMapItems] = React.useState<SidebarMenuItemProps[]>([]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  React.useEffect(() => {
    setMapItems(
      menuItems
        .filter(f => f.Hide === false)
        .map((item, index) => {
          const link: string | null | undefined =
            item.ChildNodes.filter(
              ch => ch.HideReact === false && ch.Hide === false,
            ).length > 0
              ? item.LinkWithChildren ?? null
              : item.reactUrl ?? convertUrl(item.Url);
          return {
            name: item.Title,
            link: link,
            Icon: isNullOrUndefined(item.icon) ? undefined : (
              <Icon icon={replaceIcon(item.icon)} size="lg" />
            ),
            items:
              item.ChildNodes.filter(
                ch => ch.HideReact === false && ch.Hide === false,
              ).length > 0
                ? item.ChildNodes.filter(citem => {
                    // filter to exclude '/usertraininghistory' url from sidebar
                    return citem.HideReact === false && citem.Hide === false;
                  }).map((chItem, chIndex) => {
                    return {
                      name: chItem.Title,
                      link: chItem.reactUrl ?? convertUrl(chItem.Url),
                      Icon: isNullOrUndefined(chItem.icon) ? undefined : (
                        <Icon icon={replaceIcon(chItem.icon)} />
                      ),
                      items: [] as SidebarMenuItemProps[],
                      external: isNullOrUndefined(chItem.reactUrl),
                      index: chIndex,
                      selected:
                        location.pathname
                          .toLowerCase()
                          .indexOf(
                            (chItem.reactUrl ?? chItem.Url).toLowerCase(),
                          ) !== -1,
                    };
                  })
                : ([] as SidebarMenuItemProps[]),
            external:
              link?.includes('aspx') ||
              (isNullOrUndefined(item.reactUrl) &&
                item.ChildNodes.filter(
                  ch => ch.HideReact === false && ch.Hide === false,
                ).length === 0),
            index: index,
            selected:
              props.currentNode === undefined
                ? false
                : selectedIndex === -1
                ? props.currentNode.Key === item.Key
                : selectedIndex === index,
          };
        }),
    );
  }, [location.pathname, menuItems, props.currentNode, selectedIndex]);
  const menuItemRefs = React.useRef(
    createMenuItemRefs(menuItems, currentIndex ?? 0),
  );
  return (
    <NavWrapper>
      <List
        component="nav"
        className={classes.root}
        role="menubar"
        disablePadding
      >
        {mapItems.map((item, index) => (
          <SidbarMenuItem
            {...item}
            key={index}
            selected={
              selectedIndex === -1 ? item.selected : selectedIndex === index
            }
            ref={element => (menuItemRefs.current[index] = element)}
            indexChanged={handleListItemClick}
          />
        ))}
      </List>
    </NavWrapper>
  );
}
