import { httpClient } from 'api/HttpClient';
import { IPayment } from 'app/pages/AddPaymentPage/IPayment';
//import { IResponseType } from 'types/ResponseType';
const url = '/api/odata/v4/Payment';
export interface IPaymentPayload extends IPayment {
  ShouldCloseInvoice?: boolean;
}
export const PaymentApi = {
  AddPayment: (payment: IPaymentPayload) => {
    const data = {
      InvoiceId: payment.InvoiceId,
      MethodId: payment.Method?.Id,
      Amount: payment.Amount,
      Date: payment.Date,
      Remarks: payment.Remarks,
      Reference: payment.Reference,
      BudgetId: payment.Budget?.Id,
      ServiceGroupId: payment.ServiceGroup?.Id,
      ShouldCloseInvoice: payment.ShouldCloseInvoice,
    };
    return httpClient.post<any>(url, data);
  },
  UpdatePayment: (paymentId: number, payment: IPaymentPayload) => {
    const data = {
      InvoiceId: payment.InvoiceId,
      MethodId: payment.Method?.Id,
      Amount: payment.Amount,
      Date: payment.Date,
      Remarks: payment.Remarks,
      Reference: payment.Reference,
      BudgetId: payment.Budget?.Id,
      ServiceGroupId: payment.ServiceGroup?.Id,
      ShouldCloseInvoice: payment.ShouldCloseInvoice,
    };
    return httpClient.put(url + '(' + paymentId + ')', data);
  },
};
