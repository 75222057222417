import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    boxcontainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    selectFileBox: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '24px',
    },
    infoBox: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '24px',
      padding: '10PX',
      backgroundColor: '#f0f9ff',
    },
  }),
);
