import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { CalendarButton } from 'app/components/CalendarButton';
import { MenuItemLinkProps } from 'app/components/Menu/MenuItemLink';
import { selectAppSettings, selectglobalSettings } from 'app/slice/selectors';
import { AvailabilityTypes } from 'enums/asset';
import { ServiceType } from 'enums/ServiceTypes';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { toRootedURL } from 'utils/url-utils';
import { IAsset } from '../IAsset';

export interface TopAssetActionsDropDownProps {
  data: IAsset;
}

const ButtonOrLink = (props: MenuItemLinkProps) => {
  const { icon, target, text, disabled, ...rest } = props;
  return (
    <Button
      startIcon={!icon ? undefined : <Icon icon={icon} />}
      variant="white"
      component={'a'}
      disabled={disabled}
      target={!!target ? '_blank' : '_self'}
      {...rest}
    >
      {text}
    </Button>
  );
};
export const useTopAssetActionsDropDown = (isMismatchCore?: boolean) => {
  const { t } = useTranslation();
  const appSettings = useSelector(selectAppSettings);
  const globalSettings = useSelector(selectglobalSettings);
  const getMenuItems = React.useCallback(
    ({ data }: TopAssetActionsDropDownProps) => {
      const NotificationIsAvailable = [
        AvailabilityTypes.FreelyBookableBillable,
        AvailabilityTypes.ApprovalRequiredBillable,
        AvailabilityTypes.FreelyBookable,
        AvailabilityTypes.BookableWApproval,
        AvailabilityTypes.TrainingProgram,
        AvailabilityTypes.LoanableEquipment,
        AvailabilityTypes.LectureRoom,
      ].includes(data?.AvailabilityTypeId as AvailabilityTypes);
      let items: React.ReactNode[] = [];
      if (
        data.AmAdmin &&
        [
          AvailabilityTypes.NotAvailable,
          AvailabilityTypes.AvailableNotBookable,
          AvailabilityTypes.FreelyBookableBillable,
          AvailabilityTypes.ApprovalRequiredBillable,
          AvailabilityTypes.FreelyBookable,
          AvailabilityTypes.BookableWApproval,
          AvailabilityTypes.TrainingProgram,
          AvailabilityTypes.LoanableEquipment,
          AvailabilityTypes.LectureRoom,
          AvailabilityTypes.ServiceRelated,
          AvailabilityTypes.RequestRelated,
          AvailabilityTypes.ServiceGroup,
          AvailabilityTypes.SuperGroup,
        ].includes(data.AvailabilityTypeId as AvailabilityTypes)
      ) {
        items.push(
          <ButtonOrLink
            key="edit-service"
            href={(() => {
              switch (data.AvailabilityTypeId as AvailabilityTypes) {
                case AvailabilityTypes.NotAvailable:
                case AvailabilityTypes.AvailableNotBookable:
                case AvailabilityTypes.FreelyBookableBillable:
                case AvailabilityTypes.ApprovalRequiredBillable:
                case AvailabilityTypes.FreelyBookable:
                case AvailabilityTypes.BookableWApproval:
                case AvailabilityTypes.TrainingProgram:
                case AvailabilityTypes.LoanableEquipment:
                case AvailabilityTypes.LectureRoom:
                  return toRootedURL(
                    `/NewResource.aspx?Edit=${data.ServiceId}&back=assetdetails&assetid=${data.Id}`,
                  );
                case AvailabilityTypes.ServiceRelated:
                  return toRootedURL(
                    `/OfflineServiceType.aspx?ID=${data.ServiceId}&back=assetdetails&assetid=${data.Id}`,
                  );
                case AvailabilityTypes.RequestRelated:
                  return toRootedURL(
                    `/SampleType.aspx?ID=${data.ServiceId}&back=assetdetails&assetid=${data.Id}`,
                  );
                case AvailabilityTypes.ServiceGroup:
                  return toRootedURL(
                    `/newgroup.aspx?edit=${data.ServiceGroupId}`,
                  );
                case AvailabilityTypes.SuperGroup:
                  return toRootedURL(
                    `/newsupergroup.aspx?edit=${data.DivisionId}`,
                  );
                default:
                  return '';
              }
            })()}
            title={t(translations.EditSettings)}
            text={t(translations.Settings)}
            icon="pencil-alt"
            target={'_blank'}
          />,
        );
      }
      items.push(
        <ButtonOrLink
          key="asset-details"
          href={toRootedURL('/Assets/Details.aspx?id=' + data.Id)}
          title={t(translations.AssetDetails)}
          text={t(translations.AssetDetails)}
          icon="file"
        />,
      );
      if (data.ReservationEnabled) {
        items.push(
          <ButtonOrLink
            key="new-session"
            href={toRootedURL(
              '/TrainingSession/AddNew.aspx?eqid=' + data.ServiceId,
            )}
            title={t(translations.menu_AddNewTrainingSessions)}
            disabled={isMismatchCore}
            icon="plus"
            text={t(translations.NewTrainingSession)}
          />,
        );
      }
      if (
        data.ReservationEnabled &&
        data.ServiceTypeId === ServiceType.Online
      ) {
        items.push(
          <CalendarButton
            key="calendar-open"
            equipments={[data.ServiceId]}
            openToBook={true}
            disabled={isMismatchCore}
            title={
              data.Allowed_ByTraining === false
                ? t(translations.UntrainedToolTip)
                : 'Calendar'
            }
            startIcon={<Icon icon="calendar-alt" />}
            variant="white"
            component={'a'}
          >
            {t(translations.Calendar)}
          </CalendarButton>,
        );
      }
      if (data.ReservationEnabled) {
        items.push(
          <CalendarButton
            key="timeline-open"
            equipments={[data.ServiceId]}
            openToBook={true}
            viewType="timeline"
            disabled={isMismatchCore}
            title={t(translations.Timeline)}
            variant="white"
            component={'a'}
            startIcon={<Icon icon="stream" />}
          >
            {t(translations.Timeline)}
          </CalendarButton>,
        );
        items.push(
          <ButtonOrLink
            key="res-history"
            href={toRootedURL('/History.aspx?eid=' + data.ServiceId)}
            title={t(translations.ReservationHistory)}
            icon="history"
            text={t(translations.ReservationHistory)}
            disabled={isMismatchCore}
          />,
        );
      }
      if (data.UsageEnabled) {
        items.push(
          <ButtonOrLink
            key="usage-history"
            href={toRootedURL('/usagehistory.aspx?sid=' + data.ServiceId)}
            title={t(translations.UsageHistory)}
            icon="ruler-triangle"
            text={t(translations.UsageHistory)}
            disabled={isMismatchCore}
          />,
        );
      }
      if (data.ServiceTypeId === ServiceType.Offline && data.AllowToUser) {
        items.push(
          <ButtonOrLink
            key="offline-services"
            href={toRootedURL('/otherservices?sid=' + data.ServiceId)}
            title={t(translations.OfflineService)}
            icon="th-list"
            text={t(translations.OfflineService)}
            disabled={isMismatchCore}
          />,
        );
      }
      if (
        data.ServiceTypeId === ServiceType.Online &&
        IsModuleEnabled(appSettings, KnownModules.Quizzes)
      ) {
        items.push(
          <ButtonOrLink
            key="quizzes"
            href={toRootedURL('/quizzes?eid=' + data.ServiceId)}
            title={t(translations.TrainingQuiz)}
            icon="plus"
            text={t(translations.TrainingQuiz)}
            disabled={isMismatchCore}
          />,
        );
      }
      if (data.AlertsEnabled) {
        items.push(
          <ButtonOrLink
            key="workorders"
            href={toRootedURL(`/workorders?aid=${data.Id}`)}
            title={t(translations.Alerts)}
            icon="comment-alt-dots"
            text={t(translations.AlertsIcon)}
            disabled={isMismatchCore}
          />,
        );
      }
      if (data.PlanedEventsEnabled) {
        items.push(
          <ButtonOrLink
            key="incident-track"
            href={toRootedURL(
              '/Alerts/IncidentAssetsTracking.aspx?assetIds=' + data.Id,
            )}
            title={t(translations.ScheduledEvents)}
            icon="clipboard-list-check"
            text={t(translations.ScheduledEvents)}
            disabled={isMismatchCore}
          />,
        );
      }
      if (data.ServiceTypeId === ServiceType.Offline && data.AllowToUser) {
        items.push(
          <ButtonOrLink
            key="request-otherservice"
            href={toRootedURL('/OfflineServices.aspx?sid=' + data.ServiceId)}
            title={t(translations.RequestAnOtherService)}
            icon="history"
            text={t(translations.RequestAnOtherService)}
            disabled={isMismatchCore}
          />,
        );
      }
      if (
        data.ServiceTypeId === ServiceType.Sample &&
        data.CanBeCreatedFromLinkOnly === false
      ) {
        items.push(
          <ButtonOrLink
            key="workflow-step"
            href={toRootedURL('/WorkflowStep.aspx?ostid=' + data.ServiceId)}
            title={t(translations.CreateNewSampleRequest)}
            icon="bolt"
            text={t(translations.CreateNewSampleRequest)}
            disabled={isMismatchCore}
          />,
        );
        items.push(
          <ButtonOrLink
            key="service-request"
            href={toRootedURL('/ServiceRequests.aspx?sid=' + data.ServiceId)}
            title={t(translations.SampleRequests)}
            icon="history"
            text={t(translations.SampleRequests)}
            disabled={isMismatchCore}
          />,
        );
      }
      if (
        data.ServiceTypeId === ServiceType.Online &&
        data.ReservationEnabled &&
        (IsModuleEnabled(appSettings, KnownModules.ResCancelNotifications) ||
          IsModuleEnabled(appSettings, KnownModules.ReservationNotification)) &&
        NotificationIsAvailable
      ) {
        items.push(
          <ButtonOrLink
            key="notifications"
            href={toRootedURL(
              `/UserNotifications.aspx?exist=${
                data.NotifyExists ? 'yes' : 'no'
              }&EquipId=${data.ServiceId}&prev=home`,
            )}
            title={t(
              data.NotifyExists
                ? translations.NotifyToolTip
                : translations.NoNotifyToolTip,
            )}
            icon="bell"
            text={t(
              data.NotifyExists
                ? translations.EditRemainders
                : translations.AddRemainders,
            )}
          />,
        );
      }
      if (
        data.ShowFeed &&
        globalSettings.GetBoolean('CalendarPublicIntegrationFeed')
      ) {
        items.push(
          <ButtonOrLink
            key="calendar-feed"
            title={t(translations.CalendarPublicIntegrationFeed_Icon)}
            href={toRootedURL(
              '/api/ICalController/import?EquipmentName=' + data.Name,
            )}
            icon="calendar-plus"
            text={t(translations.OutlookGoogle)}
          />,
        );
      }
      return items;
    },
    [appSettings, globalSettings, isMismatchCore, t],
  );
  return getMenuItems;
};
