/**
 *
 * Asynchronously loads the component for ArticlesExternalSearchPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ArticlesExternalSearchPagePath = '/articles/search';
export const ArticlesExternalSearchPage = lazyLoad(
  () => import('./index'),
  module => module.ArticlesExternalSearchPage,
);
