import { BasicTable } from 'app/components/BasicTable';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { filterHandler } from 'app/components/BasicTable/UnconfigurableTable/FilterHandler';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';
import { GetFilters } from './getFilters';
import * as React from 'react';
import { getColumns } from './Columns';
import { useTranslation } from 'react-i18next';
import { CellRenderer } from 'app/components/BasicTable/BasicTableProps';
import { IBasicTableState } from 'app/components/BasicTable/UnconfigurableTable/UnconfigurableTable';
import { translations } from 'locales/translations';
import { IdType, Row } from 'react-table';
import { ScreensId } from 'enums/ConfigurableTypes';
import { SelectedRowsActionExtRenderer } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionRenderer';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGlobalServiceGroupFilter,
  selectGlobalSettingBool,
} from 'app/slice/selectors';
import { BookitChipWithInfo } from 'app/components/BasicChip';
import { useAppSettingsSlice } from 'app/slice';
import { Entity } from 'types/common';
import { Button } from 'app/components/BasicButtons/Button';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { TabsActionRenderer } from 'app/components/BasicTable/components/TabsToolbar/TabsActionRender';
import { Badge, Tab } from '@material-ui/core';
import { getDefaultFilter } from './getDefaultFilters';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { selectHideNavigation } from 'app/Layout/FrontendLayout/slice/selectors';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { useHistory, useLocation } from 'react-router-dom';
import { ServiceGroupRegistrationStatus } from 'enums/ServiceGroupRegistrationStatus';
import { toRootedURL } from 'utils/url-utils';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { URLSearchParamsCI } from 'app/components/BasicTable/types/FilterParam';
import { routerActions } from 'connected-react-router';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { DetectIsMobile } from 'utils/mobileDetect';
import { AllowedSettings } from 'utils/globalSettings';
import { Check } from 'app/components/BasicInputs/Check';
import { bookitColors } from 'styles/colors/bookitColors';
import FormControlLabel from 'app/components/Forms/FormControls/FormControlLabel';
import { GetDetailsRowActions } from '../RowActions';
// import { RowsCountPicker } from 'app/components/Dashboard/TableViewEdit/RowsCountPicker';

export interface CoreSelectionTableProps {
  isSidePanel?: boolean;
  authenticatedUser?: AuthenticatedUser;
  closeSidePanel: () => void;
  setFilters?: (
    appliedFilters: IFilterSettings<ICoresDirectoryRow>[] | undefined,
  ) => void;
  updtFirstLogin: (val: boolean) => void;
  updtOptOut: (val: boolean) => void;
  //primaryAction?: (row: Row<ICoresDirectoryRow>) => void;
  isRowSelectable: (row: Row<ICoresDirectoryRow>) => boolean;
  redirectToDash: boolean;
  isFirstLogin?: boolean;
  optOutCoreSelection?: boolean;
}

export function CoreSelectionTable(props: CoreSelectionTableProps) {
  const {
    isSidePanel,
    setFilters,
    authenticatedUser,
    closeSidePanel,
    updtFirstLogin,
    updtOptOut,
    //primaryAction,
    isRowSelectable,
    redirectToDash,
    optOutCoreSelection,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: serviceGroupActions } = useAppSettingsSlice();
  const hideNavigation = useSelector(selectHideNavigation);
  const history = useHistory();
  const location = useLocation();
  const isMobile = DetectIsMobile();
  //const publicUrl = useSelector(selectPublicUrl);
  const savedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const [onlyMyCores, setOnlyMyCores] = React.useState(false);
  const [tabNumber, setTubNumber] = React.useState(0);
  const [multiCoresSelectionMode, setMultiCoresselectionMode] = React.useState<
    boolean
  >(false);
  const skipCoreSelectionScreen = useSelector(state =>
    selectGlobalSettingBool(state, AllowedSettings.SkipCoreSelectionScreen),
  );
  const [selectedRowIds, setSelectedRowIds] = React.useState<
    Record<IdType<number>, boolean>
  >({});
  const [loacalServiceGroups, setLoacalServiceGroups] = useAsyncExtendedState<
    Entity<number>[]
  >([]);
  useEffectOnMount(() => {
    dispatch(serviceGroupActions.loadGlobalServiceGroupFilter());
  });
  React.useEffect(() => {
    let obj = {};
    if (!!savedServiceGroups && savedServiceGroups.length > 0) {
      for (const key of savedServiceGroups) {
        obj[key.Id] = true;
      }
      setMultiCoresselectionMode(savedServiceGroups.length > 1);
      setLoacalServiceGroups(savedServiceGroups);
    }
    setSelectedRowIds(obj);
  }, [savedServiceGroups, setLoacalServiceGroups]);

  const showMyCoresOptions = React.useMemo(() => {
    return (
      !!authenticatedUser &&
      !!authenticatedUser.AdminServiceGroups &&
      authenticatedUser.AdminServiceGroups.length > 0
    );
  }, [authenticatedUser]);
  const handleRemoveGroup = React.useCallback(
    (value: Entity<number>) => {
      setLoacalServiceGroups(oldGroups => {
        let newGroups = oldGroups.filter(group => group.Id !== value.Id);
        return newGroups;
      });
      // if (loacalServiceGroups) {
      //   let newGroups = loacalServiceGroups.filter(
      //     group => group.Id !== value.Id,
      //   );

      //   //dispatch(actions.saveGlobalServiceGroupFilter(newGroups || []));
      // }
    },
    [setLoacalServiceGroups],
  );
  const handleRowsServiceGroupsChange = React.useCallback(
    (rows: Row<ICoresDirectoryRow>[], selected: boolean) => {
      let selectable = rows.map(f => {
        return {
          Id: f.original.Id,
          Name: f.original.Name,
        } as Entity<number>;
      });
      if (selected) {
        setLoacalServiceGroups([
          ...loacalServiceGroups,
          ...selectable.filter(
            f => !loacalServiceGroups.some(s => s.Id === f.Id),
          ),
        ]);
      } else {
        setLoacalServiceGroups([
          ...loacalServiceGroups.filter(
            f => !selectable.some(s => s.Id === f.Id),
          ),
        ]);
      }

      //dispatch(actions.saveGlobalServiceGroupFilter(selectable || []));
    },
    [loacalServiceGroups, setLoacalServiceGroups],
  );
  const handleServiceGroupsChange = React.useCallback(
    (value: Entity<number>[] | []) => {
      dispatch(actions.saveGlobalServiceGroupFilter(value || []));
    },
    [actions, dispatch],
  );

  const handleSingleGroupChange = React.useCallback(
    (value: Entity<number>, added: boolean) => {
      if (added) {
        if (loacalServiceGroups.length + 1 > 1) {
          setMultiCoresselectionMode(true);
        }
        setLoacalServiceGroups(oldGroups => [...oldGroups, value]);
        // dispatch(
        //   actions.saveGlobalServiceGroupFilter(
        //     !!savedServiceGroups ? [...savedServiceGroups, value] : [value],
        //   ),
        // );
      } else {
        if (loacalServiceGroups.length - 1 < 2) {
          setMultiCoresselectionMode(false);
        }
        handleRemoveGroup(value);
      }
    },
    [handleRemoveGroup, loacalServiceGroups.length, setLoacalServiceGroups],
  );
  const onSelectChange = React.useCallback(
    (row: ICoresDirectoryRow, checked: boolean) => {
      handleSingleGroupChange(
        {
          Id: row.Id,
          Name: row.Name,
        } as Entity<number>,
        checked,
      );
    },
    [handleSingleGroupChange],
  );
  const primaryAction = React.useCallback(
    (row: Row<ICoresDirectoryRow>) => {
      //dispatch(layoutActions.setUpSnackBarIndex(true));
      let canSwitch = isRowSelectable(row);
      if (!multiCoresSelectionMode) {
        if (canSwitch) {
          // handleRowsServiceGroupsChange([row]);
          if (hideNavigation) {
            handleServiceGroupsChange([
              {
                Id: row.original.Id,
                Name: row.original.Name,
              } as Entity<number>,
            ]);
            updtFirstLogin(false);
            dispatch(layoutActions.setHideNavigation(false));
            history.push('/dashboards/core/' + row.original.Id);
          } else if (isSidePanel) {
            handleServiceGroupsChange([
              {
                Id: row.original.Id,
                Name: row.original.Name,
              } as Entity<number>,
            ]);
            if (redirectToDash) {
              dispatch(layoutActions.resetSidePanel());
              history.push('/dashboards/core/' + row.original.Id);
            } else {
              dispatch(layoutActions.resetSidePanel());
            }
          } else {
            handleServiceGroupsChange([
              {
                Id: row.original.Id,
                Name: row.original.Name,
              } as Entity<number>,
            ]);
            if (redirectToDash) {
              history.push('/dashboards/core/' + row.original.Id);
            }
          }
        } else {
          if (row.original.CanRequestAccess) {
            if (hideNavigation) {
              updtFirstLogin(false);
              setTimeout(() => {
                window.location.href = toRootedURL(
                  `/ServiceGroupRegister.aspx?sgid=${row.original.Id}`,
                );
                dispatch(layoutActions.setHideNavigation(false));
              }, 20);
            } else if (isSidePanel) {
              window.location.href = toRootedURL(
                `/ServiceGroupRegister.aspx?sgid=${row.original.Id}`,
              );
              dispatch(layoutActions.resetSidePanel());
            } else {
              window.location.href = toRootedURL(
                `/ServiceGroupRegister.aspx?sgid=${row.original.Id}`,
              );
            }
          } else {
            dispatch(
              actions.addNotification({
                key: 'coreSelection_error',
                message: (t(
                  translations.OnSelectNotRegisteredCore_error,
                ) as string).replace('{0}', row.original.Name),
                variant: 'error',
                autoHideDuration: 10000,
              }),
            );
          }
        }
      } else {
        if (canSwitch) {
          handleSingleGroupChange(
            {
              Id: row.original.Id,
              Name: row.original.Name,
            } as Entity<number>,
            !row.isSelected,
          );
          row.toggleRowSelected(!row.isSelected);
        } else {
          if (row.original.CanRequestAccess) {
            if (hideNavigation) {
              updtFirstLogin(false);
              dispatch(layoutActions.setHideNavigation(false));
              setTimeout(() => {
                window.location.href = toRootedURL(
                  `/ServiceGroupRegister.aspx?sgid=${row.original.Id}`,
                );
              }, 20);
            } else if (isSidePanel) {
              window.location.href = toRootedURL(
                `/ServiceGroupRegister.aspx?sgid=${row.original.Id}`,
              );
              dispatch(layoutActions.resetSidePanel());
            } else {
              window.location.href = toRootedURL(
                `/ServiceGroupRegister.aspx?sgid=${row.original.Id}`,
              );
            }
          } else {
            dispatch(
              actions.addNotification({
                key: 'coreSelection_error',
                message: (t(
                  translations.OnSelectNotRegisteredCore_error,
                ) as string).replace('{0}', row.original.Name),
                variant: 'error',
                autoHideDuration: 10000,
              }),
            );
          }
        }
      }
    },
    [
      actions,
      dispatch,
      handleServiceGroupsChange,
      handleSingleGroupChange,
      hideNavigation,
      history,
      isRowSelectable,
      isSidePanel,
      layoutActions,
      multiCoresSelectionMode,
      redirectToDash,
      t,
      updtFirstLogin,
    ],
  );
  const primaryMultipleAction = React.useCallback(() => {
    handleServiceGroupsChange(loacalServiceGroups);
    if (hideNavigation) {
      updtFirstLogin(false);
      dispatch(layoutActions.setHideNavigation(false));
      history.push('/systemdash');
    } else if (isSidePanel) {
      if (redirectToDash) {
        history.push('/systemdash');
      }
      updtFirstLogin(false);
      dispatch(layoutActions.resetSidePanel());
    } else {
      // if (redirectToDash) {
      //   history.push('/systemdash');
      // }
      updtFirstLogin(false);
      history.push('/systemdash');
    }
  }, [
    dispatch,
    handleServiceGroupsChange,
    hideNavigation,
    history,
    isSidePanel,
    layoutActions,
    loacalServiceGroups,
    redirectToDash,
    updtFirstLogin,
  ]);

  const TTable = React.useMemo(
    () =>
      filterHandler<ICoresDirectoryRow, {}>(BasicTable, GetFilters, {
        isSidePanel: isSidePanel,
        setFilters: setFilters,
        reservedQueryStringParameterNames: ['ownedbyme'],
        getDefaultFilter: getDefaultFilter(onlyMyCores),
      }),
    [isSidePanel, onlyMyCores, setFilters],
  );
  const columns = React.useMemo(() => {
    return getColumns(t, isRowSelectable);
  }, [isRowSelectable, t]);
  const rowActions: CellRenderer<ICoresDirectoryRow>[] = React.useMemo<
    CellRenderer<ICoresDirectoryRow>[]
  >(() => {
    let actions: CellRenderer<ICoresDirectoryRow>[] = [];

    actions.push(({ row }) => {
      // let canSwitch = isRowSelectable(row);
      let canRequestAccess = row.original.CanRequestAccess;
      if (canRequestAccess) {
        return (
          <Button
            size="small"
            variant="ghostColor"
            onClick={() => {
              primaryAction(row);
            }}
          >
            {t(translations.RequestAccess)}
          </Button>
        );
      } else {
        return (
          <Badge>
            {t(
              translations[
                ServiceGroupRegistrationStatus[
                  row.original.ApprovalStatusId ?? 0
                ]
              ],
            )}
          </Badge>
        );
      }
    });

    return actions;
  }, [primaryAction, t]);
  const selectedRowActions: SelectedRowsActionExtRenderer<
    ICoresDirectoryRow
  >[] = React.useMemo(() => {
    let actions: SelectedRowsActionExtRenderer<ICoresDirectoryRow>[] =
      loacalServiceGroups.length > 0
        ? loacalServiceGroups.map(
            sg => (
              _selectedRows,
              _rows,
              _onRefresh,
              _toggleAll,
              toggleSingle,
            ) => (
              <BookitChipWithInfo
                info={
                  _rows.filter(f => f.id === sg.Id.toString()).length > 0
                    ? undefined
                    : t(translations.DeleteSelectedCard_info)
                }
                onDelete={() => {
                  handleRemoveGroup(sg);
                  _rows
                    .filter(f => f.id === sg.Id.toString())
                    .forEach(f => {
                      f.toggleRowSelected(false);
                    });
                  // toggleSingle(sg.Id.toString(), false);
                }}
                disabled={
                  !(_rows.filter(f => f.id === sg.Id.toString()).length > 0)
                }
                variant="default"
                customsize={isMobile ? 'small' : 'medium'}
                label={sg.Name}
                customwidth={isMobile ? 100 : 180}
                custombgcolor={bookitColors.primary.disabled}
                customcolor={bookitColors.grayscale.blackComponents}
                customiconcolor={bookitColors.grayscale.blackComponents}
                shape="circle"
                key={`selected_${sg.Id}`}
              />
            ),
          )
        : [];
    return actions;
  }, [handleRemoveGroup, isMobile, loacalServiceGroups, t]);
  const tabLeftActions: TabsActionRenderer<
    ICoresDirectoryRow
  >[] = React.useMemo(() => {
    return [
      (count, _rows) => (
        <Tab
          key={0}
          value={0}
          label={`All Cores (${count})`}
          onClick={() => {
            setOnlyMyCores(false);
            setTubNumber(0);
            if (!isSidePanel && location.search.indexOf('ownedbyme') !== -1) {
              const originalSearch = new URLSearchParamsCI(location.search);
              originalSearch.delete('ownedbyme');
              dispatch(
                routerActions.replace({
                  pathname: location.pathname,
                  search: originalSearch.toString(),
                }),
              );
            }
          }}
        />
      ),
      (_count, rows) => (
        <Tab
          key={1}
          value={1}
          label={`My Cores (${
            rows.filter(f => f.original.OwnedByMe === true).length
          })`}
          onClick={() => {
            setOnlyMyCores(true);
            setTubNumber(1);
          }}
        />
      ),
    ];
  }, [dispatch, isSidePanel, location.pathname, location.search]);
  const tabRightActions: TabsActionRenderer<
    ICoresDirectoryRow
  >[] = React.useMemo(() => {
    return hideNavigation
      ? [
          (_count, _rows) => (
            <FormControlLabel
              label={t(translations.DontShowThisAgain) as string}
              control={
                <Check
                  name="optOutCoreSelect"
                  color="primary"
                  id="optOutCoreSelect"
                  onChange={(_ev, checked) => {
                    updtOptOut(checked);
                    if (checked) {
                      dispatch(
                        actions.addNotification({
                          key: 'coreSelection_optOut',
                          message: t(
                            translations.CoreSelectionOptOut_info,
                          ) as string,
                          variant: 'info',
                          autoHideDuration: 3000,
                          closable: true,
                        }),
                      );
                    }
                  }}
                  checked={optOutCoreSelection}
                />
              }
            />
          ),
        ]
      : [];
  }, [actions, dispatch, hideNavigation, optOutCoreSelection, t, updtOptOut]);
  const useFloatingActions = React.useMemo(() => {
    return multiCoresSelectionMode
      ? loacalServiceGroups.length > 0 || skipCoreSelectionScreen
      : loacalServiceGroups.length > 1;
  }, [
    loacalServiceGroups.length,
    multiCoresSelectionMode,
    skipCoreSelectionScreen,
  ]);
  const url = '/api/odata/v4/servicegroups';
  const initialState: IBasicTableState<ICoresDirectoryRow> = React.useMemo(
    () => ({
      sortBy: [{ id: 'Name', desc: false }],
      selectedRowIds: selectedRowIds,
      pageIndex: 0,
      pageSize: 200,
    }),
    [selectedRowIds],
  );
  const pageActions: PageActionRenderer[] = React.useMemo(() => {
    return isSidePanel
      ? [
          () => (
            <IconButton
              variant="ghost"
              aria-label="close"
              size="small"
              shape="square"
              disabled={
                !(loacalServiceGroups.length > 0 || skipCoreSelectionScreen)
              }
              onClick={() => {
                handleServiceGroupsChange(loacalServiceGroups);
                if (hideNavigation) {
                  updtFirstLogin(false);
                  history.push('/systemdash');
                } else if (isSidePanel) {
                  if (redirectToDash) {
                    history.push('/systemdash');
                  }
                  closeSidePanel();
                } else {
                  history.push('/systemdash');
                  updtFirstLogin(false);
                }
              }}
              title={
                !(loacalServiceGroups.length > 0 || skipCoreSelectionScreen)
                  ? t(translations.SelectDeffServiceGroups)
                  : t(translations.CloseSidePanel)
              }
            >
              <Icon
                icon="times"
                size="lg"
                color="default"
                colorExtend="textHover"
              />
            </IconButton>
          ),
        ]
      : [];
  }, [
    closeSidePanel,
    handleServiceGroupsChange,
    hideNavigation,
    history,
    isSidePanel,
    loacalServiceGroups,
    redirectToDash,
    skipCoreSelectionScreen,
    t,
    updtFirstLogin,
  ]);
  const detailsRowActions: CellRenderer<
    ICoresDirectoryRow
  >[] = GetDetailsRowActions(t, isRowSelectable);
  return (
    <React.Fragment>
      <TTable
        screenId={ScreensId.CoresDirectory}
        api={url}
        useConfigurable={!hideNavigation}
        useExport={!hideNavigation}
        columns={columns}
        screenName={t(translations.menu_CoreSelection)}
        serviceGroups={[]}
        inlineRowActions={rowActions.length > 0 ? rowActions : undefined}
        rowActions={detailsRowActions}
        //selectedRowsActions={selectedRowActions}
        initialState={initialState}
        isRowSelectable={isRowSelectable}
        isContentEditable={row => {
          return (
            row.original.CanRequestAccess &&
            (row.original.ApprovalStatusId === null ||
              row.original.ApprovalStatusId === undefined) &&
            row.original.ApprovalStatusId !==
              ServiceGroupRegistrationStatus.Pending &&
            row.original.ApprovalStatusId !==
              ServiceGroupRegistrationStatus.Rejected
          );
        }}
        hideMenuOnMobile={isSidePanel}
        // viewCardsCheck={multiCoresSelectionMode || isMobile}
        viewCardsCheck={true}
        onSelectedChange={onSelectChange}
        // onSelectedRowsChange={rows => {
        //   handleServiceGroupsChange(
        //     rows.map(f => {
        //       return {
        //         Id: f.Id,
        //         Name: f.Name,
        //       } as Entity<number>;
        //     }),
        //   );
        // }}
        imageSrcKey="ImageName"
        titleKey="Name"
        allowCards={true}
        primaryAction={(row, _toggleAllRowsSelected) => {
          primaryAction(row);
        }}
        useCardsByDefault={true}
        floatingTabSettings={{
          useFloatingActions: useFloatingActions,
          selectedFloatingActions: selectedRowActions,
          buttonFloatingActions: [
            _rows => (
              <Button
                size={isMobile ? 'small' : 'medium'}
                onClick={() => primaryMultipleAction()}
              >
                {isMobile ? 'Continue' : 'Continue with Selected'}
              </Button>
            ),
          ],
          useSidePanel: isSidePanel,
        }}
        pageActions={pageActions}
        additionalColumns={[
          'Id',
          'AssetId',
          'CanRequestAccess',
          'UserRegistrationEnabled',
          'ApprovalStatusId',
          'Private',
          'DivisionId',
          'DivisionName',
          'OwnedByMe',
        ]}
        useRowSelect={true}
        preventInnerClicks
        searchColumns={['Name', 'Description']}
        tabSectionSettings={{
          useTabsSection: true,
          leftTabs: showMyCoresOptions ? tabLeftActions : undefined,
          selectedTabValue: tabNumber,
          defaultMultiMode: multiCoresSelectionMode === true,
          getMultiMode: value => setMultiCoresselectionMode(value),
          showClear: !multiCoresSelectionMode && skipCoreSelectionScreen,
          onAllRowsSelected: handleRowsServiceGroupsChange,
          rightTabActions: tabRightActions,
        }}
      />
    </React.Fragment>
  );
}
