/**
 *
 * CustomFormField
 *
 */
import React from 'react';
import { CustomFormFieldProps } from 'app/components/CustomForm/CustomFormFields';
import { FormBookitDatePicker } from 'app/components/Forms/FormBookitDatePicker';

export function CustomFormDate({
  formField,
  readonly,
  disabled,
  ...props
}: CustomFormFieldProps) {
  //const title = `${formField.QuizScore} ${t(translations.Points) as string}`;
  return (
    <FormBookitDatePicker
      {...props}
      disabled={readonly || disabled}
      label={formField.Label}
      key={formField.Id}
      fullWidth
      helperText={formField.Description ?? undefined}
    />
  );
}
