/**
 *
 * BasicDialog
 *
 */
import {
  createStyles,
  Dialog,
  DialogProps,
  makeStyles,
  Theme,
} from '@material-ui/core';
import * as React from 'react';
import { BasicDialogActions } from './BasicDialogActions';
import { BasicDialogContentWithTitle } from './BasicDialogContentWithTitle';
import { DialogActionsRenderer } from './DialogActionsRender';

export interface BasicDialogProps extends Omit<DialogProps, 'open' | 'title'> {
  id: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  body: React.ReactNode;
  processing?: boolean;
  isopen: boolean;
  leftActions: DialogActionsRenderer[];
  rightActions: DialogActionsRenderer[];
  onClose?: () => void;
  onCancel?: () => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  disabled?: boolean;
}

export function BasicDialog({
  id,
  title,
  body,
  isopen,
  leftActions,
  rightActions,
  maxWidth,
  subtitle,
  processing,
  disabled,
  ...props
}: BasicDialogProps) {
  const [open, setOpen] = React.useState(isopen);
  React.useEffect(() => {
    setOpen(isopen);
  }, [isopen]);
  const classes = dialogStyles();
  // const handleClose = () => {
  //   setOpen(false);
  //   if (onClose) {
  //     onClose();
  //   }
  // };
  // const defaultActions: DialogActionsRenderer[] = [
  //   () => (
  //     <ActionButton
  //       text="Cancel"
  //       variant="main"
  //       title="Cancel"
  //       onClick={handleClose}
  //     />
  //   ),
  // ];
  // actions.forEach(element => {
  //   defaultActions.push(element);
  // });

  return (
    <Dialog
      maxWidth={maxWidth || 'xs'}
      open={open}
      classes={{ paperWidthXs: classes.paperWidthXs }}
      aria-labelledby={id}
      {...props}
    >
      {/* <BasicDialogTitle id={id} subtitle={subtitle} onClose={onClose}>
        {title}
      </BasicDialogTitle>
      <BasicDialogContent dividers>{body}</BasicDialogContent> */}
      <BasicDialogContentWithTitle
        subtitle={subtitle}
        onClose={props.onClose}
        titles={title}
        processing={processing}
        disabled={disabled}
      >
        {body}
      </BasicDialogContentWithTitle>
      <BasicDialogActions
        leftActions={leftActions.map(element => element)}
        rightActions={rightActions.map(element => element)}
      />
    </Dialog>
  );
}
const dialogStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    paperWidthXs: {
      width: '360px',
    },
  }),
);
