import { BudgetFields } from 'enums/BudgetFields';
import { AssetAttributes } from 'enums/AssetAttributes';
import { KnownModules } from './KnownModules';
import { Entity } from './common';
import { ImageSize } from 'app/components/AssetThumbImage/getAssetImageSrc';
import { dateUtils } from 'utils/date-utils';

export interface AppSettings {
  Globalization: CultureInfoModel;
  Modules: KnownModules[];
  UserInternalIdLength: number | null;
  InternalUserId2FieldLength: number | null;
  WebVersion: string;
  LastCodeUpdate: Date;
  UserGroupsProviderType: number;
  AssetFieldSettings: AssetSetting[];
  PublicBaseUrl: string;
  UploadFileSizeLimit: number;
  MinimizedBookitlabLogo: boolean;
  ForbiddenFileExtensions: string[];
  BudgetFieldSettings: {
    BudgetField: BudgetFields;
    Disabled: Boolean;
  }[];
  TimeZone: ITimeZone;
  AssetBarcodeUrlTemplate: string;
  AssetBarcodeRelativeUrlTemplate: string;
  RoomBarcodeUrlTemplate: string;
  RoomBarcodeRelativeUrlTemplate: string;
  LocationBarcodeUrlTemplate: string;
  LocationBarcodeRelativeUrlTemplate: string;
  SubLocationBarcodeUrlTemplate: string;
  SubLocationBarcodeRelativeUrlTemplate: string;
  SamplePlateBarcodeUrlTemplate: string;
  SamplePlateBarcodeRelativeUrlTemplate: string;
  ConsumableTypeBarcodeUrlTemplate: string;
  ConsumableTypeBarcodeRelativeUrlTemplate: string;
  RequiredUserProps: string[];
  HideUserProps: string[];
  DefaultRegistrationGroup: Entity<string> | null;
  UserRegistersToSpecificCores: number;
  TopCustomerLogoLink: string;
  TopCustomerLogo: string;
  FavIconImg: string;
  FavIconTitle: string;
  DTUOrbitWebServiceEnabled: boolean;
  PubMedWebServiceEnabled: boolean;
  EmailFromAddress: string;
  ShowRawErrors: boolean;
  ExistingImages: string[];
  Help_Login: string | null;
  LoginScreenCompanyLogoLink: string;
  LoginScreenCompanyLogo: string;
  LoginScreenCompanyLogoHeight: string;
  ShowForgotPassword: boolean;
  AdvancedAssetPublicSearchFilterAttributes: string;
  BrowseByUnitBy: number;
  AssetPopUpActiveProps: string;
}

export interface ITimeZone {
  Id: string;
  StandardName: string;
  TimezoneOffsetMinutes: number;
  DisplayName: string;
}

export function IsBudgetFieldEnabled(
  setting: AppSettings | null,
  BudgetField: BudgetFields,
): boolean {
  if (!!setting && setting.BudgetFieldSettings) {
    var result = setting.BudgetFieldSettings.find(bf => {
      return bf.BudgetField === BudgetField;
    });
    return result !== undefined ? result.Disabled === false : true;
  }
  return false;
}
export interface CultureInfoModel {
  Locale: string;
  FirstDayOfWeek: number;
  UseAmPm: boolean;
}

export interface AppSettingsErrorType {
  message: string;
}
export function IsModuleEnabled(
  setting: AppSettings | null,
  module: KnownModules,
): boolean {
  if (!!setting && setting.Modules) {
    return setting?.Modules.includes(module);
  }
  return false;
}
export function IsAssetFieldEnabled(
  setting: AppSettings | null,
  AssetField: AssetAttributes,
): boolean {
  if (!!setting && setting.AssetFieldSettings) {
    var result = setting.AssetFieldSettings.find(s => {
      return s.AssetField === AssetField && s.Disabled === false;
    });
    return result !== undefined && result !== null;
  }
  return false;
}
export function IsAssetFieldIsPublic(
  setting: AppSettings | null,
  AssetField: AssetAttributes,
): boolean {
  if (!!setting && setting.AssetFieldSettings) {
    var result = setting.AssetFieldSettings.find(s => {
      return s.AssetField === AssetField && s.IsPublic === true;
    });
    return result !== undefined && result !== null;
  }
  return false;
}
export function IsAssetImageExist(
  settings: AppSettings | null,
  imageName: string | null,
  imageSize?: ImageSize,
) {
  if (
    imageName === null ||
    settings === null ||
    !settings.ExistingImages ||
    (!!settings.ExistingImages && settings.ExistingImages.length < 1)
  ) {
    return false;
  }
  let result = false;
  result =
    !!settings.ExistingImages &&
    settings.ExistingImages.length > 0 &&
    settings.ExistingImages.some(f => f === imageName);
  // switch (imageSize) {
  //   case 'full':
  //     result =

  //     break;
  //   case 'thumb':
  //     result =
  //       !!settings.ExistingImages.ThumbImages &&
  //       settings.ExistingImages.ThumbImages.length > 0 &&
  //       settings.ExistingImages.ThumbImages.some(f => f === imageName);
  //     break;
  //   case 'vertical':
  //     result =
  //       !!settings.ExistingImages.CardVerticalImages &&
  //       settings.ExistingImages.CardVerticalImages.length > 0 &&
  //       settings.ExistingImages.CardVerticalImages.some(f => f === imageName);
  //     break;
  //   case 'horizontal':
  //     result =
  //       !!settings.ExistingImages.CardHorizontalImages &&
  //       settings.ExistingImages.CardHorizontalImages.length > 0 &&
  //       settings.ExistingImages.CardHorizontalImages.some(f => f === imageName);
  //     break;
  //   default:
  //     result = false;
  // }
  return result;
}
export function getOffsetDate(
  settings: AppSettings | null,
  date: Date | string | undefined | null,
) {
  const systemOffset = settings?.TimeZone?.TimezoneOffsetMinutes ?? 0;
  let correctDate =
    date === null || date === undefined
      ? dateUtils.dateOrStringToDate(new Date())
      : dateUtils.dateOrStringToDate(date);
  const currentDateOffset = correctDate.getTimezoneOffset();
  const dateOrNow = dateUtils.addMinutes(
    correctDate,
    systemOffset + currentDateOffset,
  );
  return dateOrNow;
}
export interface AssetSetting {
  AssetField: AssetAttributes;
  Disabled: boolean;
  IsPublic: boolean;
}
