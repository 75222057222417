export enum AvailabilityTypes {
  /// <summary>
  /// no connection to bookitlab service
  /// </summary>
  NotAvailable = 0,

  /// <summary>
  /// no connection to bookitlab service
  /// </summary>
  AvailableNotBookable = 1,

  /// <summary>
  /// connected to online service
  /// </summary>
  FreelyBookable = 2,

  /// <summary>
  /// connected to online service which requires tutoring
  /// </summary>
  BookableWApproval = 3,

  /// <summary>
  /// connected to offlineservice
  /// </summary>
  ServiceRelated = 4,
  /// <summary>
  /// Connected to a service group
  /// </summary>
  ServiceGroup = 5,

  /// <summary>
  /// connected to a division(super group)
  /// </summary>
  SuperGroup = 6,

  /// <summary>
  /// connected to a division(super group)
  /// </summary>
  FreelyBookableBillable = 7,

  /// <summary>
  /// connected to a division(super group)
  /// </summary>
  ApprovalRequiredBillable = 8,

  /// <summary>
  /// connected to requests
  /// </summary>
  RequestRelated = 9,

  TrainingProgram = 10,
  LoanableEquipment = 11,
  LectureRoom = 12,
}
