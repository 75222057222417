import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.repetitive || initialState;

export const selectRepetitive = createSelector([selectSlice], state => state);

export const selectRepetitiveProcessing = createSelector(
  [selectRepetitive],
  state => state.processing,
);

export const selectRepetitiveError = createSelector(
  [selectRepetitive],
  state => state.error,
);

export const selectRepetitiveData = createSelector(
  [selectRepetitive],
  state => state.data,
);
export const selectRepetitiveFieldHandler = createSelector(
  [selectRepetitive],
  state => state.repetitiveValueHandler,
);
