import { useLocation } from 'react-router-dom';
import { usePrevious } from './usePrevious';
import { useEffect } from 'react';
//import { Location } from '@types/history';

export const useLocationChange = action => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  useEffect(() => {
    action(location, prevLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};
