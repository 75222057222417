import { httpClient } from './HttpClient';
import { UpdateFilesModel } from 'utils/fileStoreHelper';

export const FileStoreApi = {
  saveFormFiles: (payload: UpdateFilesModel[]) => {
    const url = `/api/odata/v4/files/InsertFormFile`;
    const form = new FormData();
    form.append('TypeId', payload[0].typeId as any);
    form.append('ReferenceId', payload[0].referenceId as any);
    form.append('FormTypeId', payload[0].formType as any);
    form.append('UpdateOption', payload[0].updateOption as any);
    form.append('AlertTypeId', payload[0].alertTypeId as any);
    let added = payload.filter(f => f.operation === 'added')[0];
    if (!!added && added.files.length > 0) {
      added.files.forEach((file, ind) => {
        form.append(
          `FormField_${file.Id}_${ind}`,
          file.PostedFile ?? file.Id.toString(),
          file.PostedFile === null ? undefined : file.PostedFile.name,
        );
      });
    }
    return httpClient.post(url, form);
  },
  updateFormFiles: (payload: UpdateFilesModel[]) => {
    const url = `/api/odata/v4/files/UpdateFormFile`;
    const form = new FormData();
    form.append('TypeId', payload[0].typeId as any);
    form.append('ReferenceId', payload[0].referenceId as any);
    form.append('FormTypeId', payload[0].formType as any);
    form.append('UpdateOption', payload[0].updateOption as any);
    form.append('AlertTypeId', payload[0].alertTypeId as any);
    let deleted = payload.filter(f => f.operation === 'removed')[0];
    let added = payload.filter(f => f.operation === 'added')[0];
    if (!!deleted) {
      deleted.files.forEach((f, ind) => {
        form.append(`deleted_${ind}`, `FormField_${f.Id}_${f.Value}`);
      });
    }
    if (!!added && added.files.length > 0) {
      added.files.forEach((file, ind) => {
        form.append(
          `FormField_${file.Id}_${ind}`,
          file.PostedFile ?? file.Id.toString(),
          file.PostedFile === null ? undefined : file.PostedFile.name,
        );
      });
    }
    return httpClient.post(url, form);
  },
};
