import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { FieldHookConfig, useField } from 'formik';
import { BudgetPicker } from '../pickers/AutocompletePickers/BudgetPicker';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export const FormBudgetPicker = ({
  label,
  ...props
}: FieldHookConfig<IBudgetFilterDto | null> &
  IFieldProps & { useSearchOrPredicate?: boolean }) => {
  const [field, meta, helpers] = useField<IBudgetFilterDto | null>(props);
  return (
    <BudgetPicker
      name={props.name}
      placeholder={props.placeholder}
      disabled={props.disabled}
      label={label}
      onChange={value => {
        props.onChange?.(value);
        helpers.setValue(value, true);
      }}
      onBlur={field?.onBlur}
      value={field.value}
      error={meta?.error !== undefined}
      helperText={meta.error}
      fullWidth={props.fullWidth}
      variant="filled"
      predicates={props.predicates}
      useSearchOrPredicate={props.useSearchOrPredicate}
      info={props.info}
    />
  );
};
