/**
 *
 * TextFieldFilter
 *
 */
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { debounce } from 'lodash';

export const TextFieldFilter = (props: IFilterComponentProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = React.useState<string>(props.value);
  const onChange = React.useCallback(value => props.onChange(value), [props]);
  const debouncedOnChange = React.useMemo(() => debounce(onChange, 250), [
    onChange,
  ]);
  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = event => {
    setValue(event.target.value);
    debouncedOnChange(event.target.value);
  };

  const { t } = useTranslation();
  return (
    <TextControlField
      value={value || ''}
      type="text"
      onChange={handleChange}
      size="small"
      autoFocus={true}
      placeholder={t(translations.AllValues)}
      inputProps={{ 'aria-label': props.label }}
      id={props.id}
    />
  );
};
export default TextFieldFilter;
