import { BARCODE_SCANNED, BARCODE_SCANNING } from 'react-usb-barcode-scanner';

export const barcodeScannerMiddleware = store => next => action => {
  // processing barcode need to be here
  if (action.type === BARCODE_SCANNED) {
    console.debug(action);
  }
  if (action.type === BARCODE_SCANNING) {
  }
  return next(action);
};
