import { CalendarMessagesDetailsQStringParameters } from 'app/pages/CalendarMessages/CalendarMessagesDetails/Details/slice/types';
import { ICalendarMessagesDto } from 'app/pages/CalendarMessages/CalendarMessagesPage/ICalendarMessagesDto';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';

export const CalendarMessagesApi = {
  initCreateData: (payload: CalendarMessagesDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/CalendarMessages/InitCalendarMessages';
    return httpClient.post(url, data);
  },
  initUpdateData: (payload: CalendarMessagesDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    console.log(data);
    const url = '/api/odata/v4/CalendarMessages/InitCalendarMessages';
    return httpClient.post(url, data);
  },
  insertCalendarMessagesDetails: (payload: ICalendarMessagesDto) => {
    const url = '/api/odata/v4/CalendarMessages'; //POST action in Controller
    return httpClient.post(url, payload);
  },
  updateCalendarMessagesDetails: (payload: ICalendarMessagesDto) => {
    const url = `/api/odata/v4/CalendarMessages(${payload.Id})`; //PUT action in Controller
    return httpClient.put(url, payload);
  },
  deleteCalendarMessagesDetails: (
    rows: ICalendarMessagesDto[],
  ): Promise<IResponseType> => {
    const calendarMessagesIds: number[] = rows.map(item => item.Id);
    const data = { calendarMessagesIds };
    const url = '/api/odata/v4/CalendarMessages/DeleteCalendarMessages';
    return httpClient.post(url, data);
  },
};
