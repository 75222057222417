/**
 *
 * HoursPicker
 *
 */
import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import React from 'react';

import { Entity } from 'types/common';
import { dateUtils } from 'utils/date-utils';
import { GetOptionsFilterFunction } from 'utils/enumKeys';

export interface HoursPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {
  useAmPm?: boolean;
}
export const hoursEntityArray12 = Array.from({ length: 12 }, (_, i) => {
  const x = i + 1;
  return {
    Id: x,
    Name: x.toString(),
  } as Entity<number>;
});
export const hoursEntityArray24 = Array.from({ length: 24 }, (_, i) => {
  const x = i;
  return {
    Id: x,
    Name: x.toString(),
  } as Entity<number>;
});

export const defaultHourValue12 =
  hoursEntityArray12.find(
    f => f.Id === dateUtils.getMeridiemHours(new Date()),
  ) ??
  ({
    Id: dateUtils.getMeridiemHours(new Date()),
    Name: dateUtils.getMeridiemHours(new Date()).toString(),
  } as Entity<number>);
export const defaultHourValue24 =
  hoursEntityArray24.find(f => f.Id === dateUtils.getHours(new Date())) ??
  ({
    Id: dateUtils.getHours(new Date()),
    Name: dateUtils.getHours(new Date()).toString(),
  } as Entity<number>);
export const getHoursEntityFromDate = (
  ampm: boolean,
  date?: Date | string | null,
) => {
  if (date === null || date === undefined) {
    return ampm ? defaultHourValue12 : defaultHourValue24;
  } else {
    let toDate = dateUtils.dateOrStringToDate(date);
    return ampm
      ? hoursEntityArray12.find(
          f => f.Id === dateUtils.getMeridiemHours(toDate),
        ) ?? defaultHourValue12
      : hoursEntityArray24.find(f => f.Id === toDate.getHours()) ??
          defaultHourValue24;
  }
};
export const initHoursData = (
  initval: number | string | undefined,
  ampm?: boolean,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve(
        ampm
          ? hoursEntityArray12.filter(item => {
              return item.Id.toString() === initval.toString();
            })
          : hoursEntityArray24.filter(item => {
              return item.Id.toString() === initval.toString();
            }),
      );
    });
  }
};

export function HoursPicker(props: HoursPickerProps) {
  const getOptions = React.useMemo(() => {
    let items = props.useAmPm ? hoursEntityArray12 : hoursEntityArray24;
    return GetOptionsFilterFunction(items);
  }, [props.useAmPm]);
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      id={props.id || 'hoursId'}
      value={props.value}
      autoHighlight={true}
      autoSelect={true}
      selectOnFocus={true}
      clearOnEscape
      {...props}
    />
  );
}
