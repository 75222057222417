import { httpClient } from 'api/HttpClient';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { Entity } from 'types/common';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { useSelector } from 'react-redux';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { IRequestTypeFilterDto } from 'api/odata/generated/entities/IRequestTypeFilterDto';

export interface ActiveSampleTypesPickerProps
  extends Omit<AutocompletePickerProps<IRequestTypeFilterDto>, 'loadData'> {}

const url = '/api/odata/v4/ActiveSampleTypesFilter';

export const initActiveSampleTypesData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};

const loadData = (
  predicates: (string | Condition<IRequestTypeFilterDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
) =>
  getAutoCompleteLoadDataFn<IRequestTypeFilterDto>({
    url: url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'ServiceGroupId',
      'HideProjects',
      'ProjectIsRequired',
    ],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<IRequestTypeFilterDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
    isOptionalServiceGroup: true,
  });

export const loadActiveSampleTypes = loadData;

export function ActiveSampleTypesPicker(props: ActiveSampleTypesPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(
    props.predicates,
    globalServiceGroupFilter || [],
  );
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'ActiveSampleTypesId'}
      loadData={innerLoadData}
      {...props}
    />
  );
}
