/**
 *
 * Asynchronously loads the component for WorkOrders Page
 *
 */
import { lazyLoad } from 'utils/loadable';

export const WorkOrdersPage = lazyLoad(
  () => import('./index'),
  module => module.WorkOrders,
);
