import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { CellProps, Renderer } from 'react-table';
import { dateUtils } from 'utils/date-utils';

export const DateRenderer: Renderer<CellProps<Record<string, any>>> = ({
  value,
}) => {
  const { t } = useTranslation();
  return value === null || value === undefined ? (
    <>{t(translations.NA)}</>
  ) : (
    <>{dateUtils.shortDateFormat(value)}</>
  );
};
export default DateRenderer;
