import * as React from 'react';
import { usePromise } from 'app/hooks/usePromise';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { debounce } from 'lodash';

export interface useAsyncOptionsProps<TOption> {
  fetchOptions: (search: string) => Promise<Array<TOption>>;
}

export interface IState<TOption> {
  async: boolean;
  options: TOption[];
  open: boolean;
  search: string | null;
}

export function useAsyncOptions<TOption>(
  options: useAsyncOptionsProps<TOption>,
) {
  const [state, , extendState] = useAsyncExtendedState<IState<TOption>>({
    search: null,
    async: !Array.isArray(options),
    options: Array.isArray(options) ? options : [],
    open: false,
  });

  const optionsPromise = React.useCallback(
    async search => {
      if (Array.isArray(options)) {
        return { options: options };
      } else {
        const response = await options.fetchOptions(search);
        return { options: response };
      }
    },
    [options],
  );
  const [promiseState, fetch] = usePromise(optionsPromise);

  const debounced = React.useMemo(() => {
    //debounced?.cancel();
    return debounce(
      e => {
        console.debug('foo zz');
        extendState(fetch(e.target.value));
      },
      250,
      {
        trailing: true,
        maxWait: 2500,
      },
    );
  }, [extendState, fetch]);

  const handleInputChange = e => {
    console.debug('handleInputChange', e);
    if (e === null) {
      return;
    }
    extendState({ search: e.target.value });
    promiseState.reset();
    debounced(e);
  };

  const handleOpen = () => {
    extendState({ open: true });
    extendState(fetch(null));
  };

  return {
    onOpen: handleOpen,
    onInputChange: handleInputChange,
    options: state.options,
    loading: promiseState.status !== 'resolved',
  };
}
