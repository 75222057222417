/**
 *
 * ServiceRequestsKanbanPage
 *
 */
import * as React from 'react';
import {
  Box,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useTranslation } from 'react-i18next';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import { ServiceRequestStatusMultiPicker } from 'app/components/pickers/AutocompletePickers/ServiceRequestStatusMultiPicker';
import { ServiceRequestCard } from './components/ServiceRequestCard';
import { TitlePage } from 'app/Layout/FrontendLayout/components/PageWrapper/components/TitlePage';
import { IBasePageProps } from '../IBasePageProps';
import { useFilters } from 'app/components/BasicTable/withSavedHistory';
import { GetFilters, StatusFilterId } from './Filter';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { IServiceRequestTicketDto } from 'api/odata/generated/entities/IServiceRequestTicketDto';
import { useDispatch, useSelector } from 'react-redux';
import { useServiceRequestsKanbanSlice } from './slice';
import { selectServiceRequestsKanbanData } from './slice/selectors';
import { Kanban, KanbanChangeEvent } from './components/Kanban';
import { FrontendLayoutContentBox } from 'app/Layout/FrontendLayout/components/ContentBox';
import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';

export interface ServiceRequestsKanbanPageProps extends IBasePageProps {}

export type ServiceRequestKanbanItemType = IServiceRequestTicketDto;

interface IState {
  statuses?: IServiceRequestStatusDto[];
  selectedStatuses: IServiceRequestStatusDto[];
  tickets: Array<ServiceRequestKanbanItemType>;
}
export const ServiceRequestsKanbanPagePath = '/serviceRequests/kanban';
export interface ServiceRequestsKanbanProps extends IState {
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}
export function ServiceRequestsKanban(props: ServiceRequestsKanbanProps) {
  return (
    <div>
      <DragDropContext onDragEnd={props.onDragEnd}>
        <Grid container spacing={2}>
          <Grid item xs={6}></Grid>
        </Grid>
      </DragDropContext>
    </div>
  );
}

const SERVICE_REQUEST_KANBAN_IGNORED_STATUSES: Array<InternalServiceRequestStatusesUnion> = [
  'Draft',
  'Pending',
  'ReturnedToUser',
  'Cancelled',
  'Completed',
];
export function ServiceRequestsKanbanPage(
  props: ServiceRequestsKanbanPageProps,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const isMobile = DetectIsMobile();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles({ isMobile: isMobile });
  const dispatch = useDispatch();
  const { actions } = useServiceRequestsKanbanSlice();
  // const [state, , extendState] = useAsyncExtendedState<IState>({
  //   selectedStatuses: [],
  //   tickets: [],
  // });

  useEffectOnMount(() => {
    //todo
  });

  const { appliedFilters, setFilterValue } = useFilters(
    ServiceRequestsKanbanPagePath,
    GetFilters,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  );

  const handleSelectedStatusesChange = (value: FilterValueType) => {
    setFilterValue(StatusFilterId, value as FilterValueType);
  };

  const selectedStatuses = (appliedFilters?.find(f => f.id === StatusFilterId)
    ?.value as IServiceRequestStatusDto[] | undefined)?.filter(
    status =>
      !SERVICE_REQUEST_KANBAN_IGNORED_STATUSES.includes(
        status.InternalStatusId,
      ),
  );

  React.useEffect(() => {
    const selectedStatuses = appliedFilters?.find(f => f.id === StatusFilterId)
      ?.value as IServiceRequestStatusDto[] | undefined;
    dispatch(actions.setSelectedStatuses(selectedStatuses));
  }, [actions, appliedFilters, dispatch]);

  const kanban = useSelector(selectServiceRequestsKanbanData);
  const handleChange = React.useCallback(
    (value: KanbanChangeEvent) => {
      dispatch(actions.reorder(value));
    },
    [actions, dispatch],
  );
  const handleMore = (sectionId: number) => {
    dispatch(actions.getMoreItems(sectionId));
  };

  return (
    <div>
      <FrontendLayoutContentBox>
        <TitlePage title="Kanban" buttonsLenth={0} />

        <StyledServiceRequestStatusMultiPicker
          value={selectedStatuses}
          fullWidth
          onChange={handleSelectedStatusesChange}
          exclude={SERVICE_REQUEST_KANBAN_IGNORED_STATUSES}
        />

        {kanban !== undefined && (
          <Kanban
            data={kanban}
            renderItem={(item, section, dragHandleProps) => (
              <ServiceRequestCard
                item={item}
                dragHandleProps={dragHandleProps}
              />
            )}
            renderTitle={section => (
              <Box>
                <Chip label={section.section.Name} />
              </Box>
            )}
            onChange={handleChange}
            onLoadMore={handleMore}
          />
        )}
      </FrontendLayoutContentBox>
    </div>
  );
}

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      // root css
    },
  }),
);

const StyledServiceRequestStatusMultiPicker = styled(
  ServiceRequestStatusMultiPicker,
)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
