import { useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  FormLeftSection,
  FormRightSection,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import React from 'react';
import { TopButtonsContainer } from '../styled';

export interface FormSkeletonProps {
  fullPage: boolean;
}
const thingsCount = 15;
const getRandomWidth = () => {
  return (Math.random() * 40 + 50).toFixed(0).toString() + '%';
};
export const ButtonsSkeleton = () => {
  const theme = useTheme();
  return (
    <TopButtonsContainer theme={theme}>
      <div className={'leftButtons'}>
        <Skeleton width="64px" height="32px" />
        <Skeleton width="64px" height="32px" />
      </div>
      <div className={'rightButtons'}>
        <Skeleton width="64px" height="32px" />
      </div>
    </TopButtonsContainer>
  );
};
export const FormSkeleton = (props: FormSkeletonProps) => {
  return (
    <StyledFormFieldsContainer
      sectionStyle={{
        overflow: 'hidden',
        height: '95%',
        paddingInline: '16px',
      }}
    >
      <FormLeftSection>
        {[...Array(thingsCount).keys()].map(i => (
          <Skeleton
            key={i}
            width={getRandomWidth()}
            component={Math.random() > 0.5 ? 'h1' : 'p'}
          />
        ))}
      </FormLeftSection>
      <FormRightSection>
        {props.fullPage &&
          [...Array(thingsCount).keys()].map(i => (
            <Skeleton
              key={i}
              width={getRandomWidth()}
              component={Math.random() > 0.5 ? 'h1' : 'p'}
            />
          ))}
      </FormRightSection>
    </StyledFormFieldsContainer>
  );
};
