/**
 *
 * UserAttributesActions
 *
 */

import { Button, ButtonProps } from 'app/components/BasicButtons/Button';

export interface SaveFormButtonProps extends ButtonProps {
  edit: boolean;
  text: string;
  onClick: (e: any) => void;
}

export function SaveFormButton({
  edit,
  text,
  onClick,
  ...props
}: SaveFormButtonProps) {
  return (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
}
