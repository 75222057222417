/**
 *
 * Asynchronously loads the component for UsageHistory Page
 *
 */
import { lazyLoad } from 'utils/loadable';

export const UsageHistory = lazyLoad(
  () => import('./index'),
  module => module.UsageHistoryPage,
);
