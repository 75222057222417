import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { ITrainingSessionSlotDto } from 'api/odata/generated/entities/ITrainingSessionSlotDto';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import HTMLReactParser from 'html-react-parser';
import * as React from 'react';
import { assertExhaustive } from 'utils/assertExhaustive';
import { dateUtils } from 'utils/date-utils';
import { ISchedulerEvent } from '../../types/ISchedulerEvent';
import { EventType } from '../../slice/types';
import {
  formatSchedulerDateTime,
  formatSchedulerTime,
} from '../../utils/formatSchedulerTime';
import { ReservationStatusIcon } from './ReservationStatusIcon';
import { TrainingSessionStatus } from './TrainingSessionStatus';

/**
 * Event Timeline Content props
 */
export interface EventTimelineContentProps {
  event: ISchedulerEvent;
  viewType: 'calendar' | 'timeline' | 'unit';
  body?: string;
  isMobile: boolean;
}

/**
 * Event timeline content element
 * @param props props
 * @returns Event timeline content
 */
export function EventTimelineContent(props: EventTimelineContentProps) {
  // get a render function for each event type type
  const RenderFC = RenderEvent(
    props.viewType,
    props.isMobile,
    props.event.type,
    props.body,
  );
  return (
    <>
      <RenderFC {...props} />
    </>
  );
}

function RenderEvent(
  viewType: 'calendar' | 'timeline' | 'unit',
  isMobile: boolean,
  eventType?: EventType,
  body?: string,
): React.FC<EventTimelineContentProps> {
  if (eventType === undefined) {
    return ReservationEventConent;
  }
  switch (eventType) {
    case 'trainingsession':
      return TrainingSessionEventConent;
    case 'reservation':
      return ReservationEventConent;
    case 'offline':
      return OfflineEventConent;
    default:
      assertExhaustive(eventType);
  }
}
function OfflineEventConent(props: EventTimelineContentProps) {
  const offline = props.event.original as ICalendarReservationDto;

  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span
        style={{
          color: '#4B5565',
          fontWeight: 'bold',
        }}
      >
        {formatSchedulerDateTime(props.event.start_date)}
        {' - '}
        {props.event.end_date === null ||
        props.event.end_date === undefined ||
        props.event.end_date.getTime() >
          dateUtils.addYears(props.event.start_date, 5).getTime() ? (
          <Icon icon={['fad', 'infinity']} size="xl" />
        ) : (
          formatSchedulerDateTime(props.event.end_date)
        )}
      </span>
      &ensp;
      {offline.Remarks != null && offline.Remarks !== undefined && (
        <span
          style={{
            color: '#4B5565',
          }}
        >
          {HTMLReactParser(offline.Remarks)}
        </span>
      )}
    </span>
  );
}
function TrainingSessionEventConent(props: EventTimelineContentProps) {
  const training = props.event.original as ITrainingSessionSlotDto;

  return (
    <span
      style={{
        display: 'inline-flex',
        alignItems: 'flex-start',
        justifyContent: 'start',
        flexWrap: 'wrap', //props.viewType === 'calendar' ? 'wrap' : 'nowrap',
        gap: props.viewType === 'calendar' ? 8 : 4,
      }}
    >
      <TrainingSessionStatus className="dhx_training_status" {...props} />
      <p>
        {formatSchedulerTime(props.event.start_date)}
        {' - '}
        {formatSchedulerTime(props.event.end_date)}
      </p>
      <Icon icon="dumbbell" />
      {training.TrainerName != null && <p>{training.TrainerName}</p>}
    </span>
  );
}
function ReservationEventConent(props: EventTimelineContentProps) {
  const reservation = props.event.original as ICalendarReservationDto;
  //   const timelineTempl =
  //     "<span>{{formatDate StartTime 'h:mm a'}} - {{formatDate EndTime 'h:mm a'}}</span>";
  //   let compileTempl = compileHandlebarsTemplate<ICalendarReservationDto>(
  //     timelineTempl,
  //   );
  //   let resTempl = compileTempl?.(reservation);
  const bodyContent = !!props.body ? (
    HTMLReactParser(props.body)
  ) : (
    <span>
      {formatSchedulerTime(props.event.start_date)}
      {' - '}
      {formatSchedulerTime(props.event.end_date)}
    </span>
  );
  return !!reservation && reservation.Restricted ? (
    <span>{''}</span>
  ) : (
    <span
      style={{
        display: 'inline-flex',
        alignItems: 'flex-start',
        justifyContent: 'start',
        flexWrap: 'wrap', //props.viewType === 'calendar' ? 'wrap' : 'nowrap',
        gap: props.viewType === 'calendar' ? 8 : 4,
      }}
    >
      {!!reservation && !!reservation.StatusId && (
        <ReservationStatusIcon event={props.event} />
      )}
      {bodyContent}
      {/* &ensp;
      <span>
        {formatSchedulerTime(props.event.start_date)}
        {' - '}
        {formatSchedulerTime(props.event.end_date)}
      </span> */}
    </span>
  );
}
