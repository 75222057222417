import * as React from 'react';
import { Form } from 'formik';
import { CSSProperties } from '@material-ui/styles';
import { StyledFormFieldsContainer } from '../StyledFormFieldsContainer';
export interface IndexNode {
  node: React.ReactNode;
  index: number | string;
  variant?: 'standard' | 'filled' | 'outlined';
  showMore?: boolean;
}
export interface StyledFormProps {
  onSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  children: React.ReactNode;
  avoidStyles?: boolean;
  id?: string;
  sectionStyle?: CSSProperties;
  isCover?: boolean;
}
export const StyledForm = (props: StyledFormProps) => {
  const {
    id,
    onSubmit,
    avoidStyles,
    children,
    sectionStyle,
    isCover,
    ...other
  } = props;
  return (
    <Form onSubmit={onSubmit} id={id}>
      <StyledFormFieldsContainer
        avoidStyles={avoidStyles}
        sectionStyle={sectionStyle}
        isCover={isCover}
        {...other}
      >
        {children}
      </StyledFormFieldsContainer>
    </Form>
  );
};
