/**
 *
 * Asynchronously loads the component for CoreSelectionPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CoreSelectionPage = lazyLoad(
  () => import('./index'),
  module => module.CoreSelectionPage,
);
