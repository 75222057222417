import { filterHandler } from 'app/components/BasicTable/UnconfigurableTable/FilterHandler';
import { translations } from 'locales/translations';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UnconfigurableTable } from '../../../../components/BasicTable/UnconfigurableTable/UnconfigurableTable';
import { getColumns } from './columns';
import { GetFilters } from './Filter';
import { IPayHistory } from './IPayHistory';

export interface InvoicePayHistoryProps {
  invoiceId: number;
}
export const PayHistory = ({ invoiceId }: InvoicePayHistoryProps) => {
  const { t } = useTranslation();
  const columns = useMemo(() => getColumns(t), [t]);
  const url = '/api/odata/v4/InvoicePayHistory';
  return (
    <TTable
      api={{ path: url, search: { invoiceId } }}
      screenName={t(translations.History)}
      columns={columns}
      serviceGroups={[]}
      additionalColumns={['Id']}
      searchColumns={[
        'FieldName',
        'OldValue',
        'NewValue',
        'DisplayName',
        'cast(InvoicePaymentId,Edm.String)',
      ]}
    />
  );
};

const TTable = filterHandler<IPayHistory, {}>(UnconfigurableTable, GetFilters, {
  isSidePanel: true,
});
