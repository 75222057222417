import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { Dialog, DialogActions } from '@material-ui/core';
import { Timestamp } from 'types/Timestamp';
import { ViewLengths } from '../../slice/types';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { getPeriodDisplayLabel } from '../../utils';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { DetectIsMobile } from 'utils/mobileDetect';

/**
 * Internal componnent state interface
 */
interface ICalendarDatePickerState extends CalendarDateValue {
  open: boolean;
}
/**
 * Period value including the period length and the date
 */
export interface CalendarDateValue {
  date: Timestamp;
  viewLength: ViewLengths;
  customDays?: number;
  customStart?: boolean;
}
export interface CalendarDateProps extends ICalendarDatePickerState {
  onChange: (value: CalendarDateValue) => void;
  size?: 'small' | 'large' | 'medium';
  compact?: boolean;
  customDays?: number;
  customStart?: boolean;
}
// const makeJSDateObject = (date: Date | string): Date => {
//   return dateUtils.parseISO(date);
// };

export function CalendarDatePicker(props: CalendarDateProps) {
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  //const firstDayOfWeek = useSelector(selectSystemFirstDayOfWeek);
  // set initial state
  const [state, , extendState] = useAsyncExtendedState<
    ICalendarDatePickerState
  >({
    date: props.date,
    open: props.open,
    viewLength: props.viewLength,
    customDays: props.customDays,
    customStart: props.customStart,
  });
  // handle date change based on the period length
  const handleDateChange = date => {
    switch (state.viewLength) {
      case 'day':
      case 'twodays':
        extendState({ date: date, customStart: true });
        break;
      case 'customdays':
        extendState({
          date: date,
          customDays: props.customDays,
          customStart: true,
        });
        break;
      case 'week':
      case 'month':
      case 'quarter':
      case 'year':
        extendState({ date: date, customStart: true });
        break;

      default:
        extendState({ date: date, customStart: true });
      //assertExhaustive(state.viewLength);
    }
  };

  const handleAccept = () => {
    // close the dialog
    extendState({ open: false });
    // raise change event
    props.onChange({
      date: state.date,
      viewLength: props.viewLength,
      customStart: state.customStart,
    });
  };
  const handleReject = React.useCallback(() => {
    extendState({
      // close dialog
      open: false,
      // reset internal state
      viewLength: props.viewLength,
      date: props.date,
      customStart: undefined,
    });
  }, [extendState, props.date, props.viewLength]);
  return (
    <>
      <Dialog open={state.open} fullWidth={isMobile} fullScreen={isMobile}>
        <DatePicker
          variant="static"
          open={state.open}
          views={['year', 'month', 'date']}
          value={state.date}
          openTo="date"
          //   disableToolbar
          onOpen={() => extendState({ open: true })}
          onClose={() => extendState({ open: false })}
          onChange={handleDateChange}
          //   renderDay={renderDay}
        />

        <DialogActions>
          <Button variant="ghostColor" onClick={handleReject}>
            {t(translations.Cancel)}
          </Button>
          <Button variant="ghostColor" onClick={handleAccept}>
            {t(translations.OK)}
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        style={isMobile ? { padding: '4px 6px' } : undefined}
        variant="ghostColor"
        size={props.size || 'medium'}
        endIcon={<Icon icon="chevron-down" />}
        onClick={() => extendState({ open: true })}
      >
        {getPeriodDisplayLabel(
          props.viewLength,
          props.date,
          props.compact ?? false,
          props.customDays,
          state.customStart,
        )}
      </Button>
    </>
  );
}
