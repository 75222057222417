import { css } from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export const verticalScrolling = css`
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 2px ${bookitColors.grayscale.grayBorders};
    border-radius: 6px;
    background-color: ${bookitColors.grayscale.grayBorders};
  }
`;
