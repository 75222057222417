import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { useLocation } from 'react-router-dom';
import {
  GetRawValue,
  splitFilterValue,
} from 'app/components/BasicTable/types/FilterParam';

import { AssetsSelectionProps } from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import { DetectIsMobile } from 'utils/mobileDetect';
import { PageTopActionsProps } from '../type';
import { tryParseInt } from 'utils/string-utils';
import { ReservationCreateButton } from 'app/components/ReservationCreateButton';
import { selectGlobalDbSetting_EnableLegacyScheduler } from 'app/slice/globalDbSettingsSelectors';

export function AddReservation({
  size,
  text,
  variant,
  icon,
  compact = true,
  ...props
}: PageTopActionsProps) {
  const location = useLocation();
  //const authenticatedUser = useSelector(selectAuthenticatedUser);
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const isMobile = DetectIsMobile();
  const { t } = useTranslation();

  const EnableLegacyScheduler = useSelector(
    selectGlobalDbSetting_EnableLegacyScheduler,
  );

  const serviceIdsRawValue = GetRawValue(
    new URLSearchParams(location.search),
    'selectedIds',
  );
  const serviceIds =
    serviceIdsRawValue === undefined || serviceIdsRawValue === null
      ? []
      : (splitFilterValue(serviceIdsRawValue)
          .map(tryParseInt)
          .filter(f => f !== undefined) as number[]);

  if (EnableLegacyScheduler && serviceIds.length > 0) {
    return <ReservationCreateButton instruments={serviceIds} />;
  }
  return (
    <TopActionButton
      text={text || (t(translations.AddReservation) as string)}
      icon={icon || 'plus'}
      startIcon={icon || 'plus'}
      variant={isMobile && compact ? 'ghost' : variant || 'ghost'}
      size={size || 'small'}
      noChangeOnMobile={!compact}
      onClick={() => {
        let selParams = {
          useSidePanel: true,
          actionType: 'Reservation',
          serviceIds: GetRawValue(
            new URLSearchParams(location.search),
            'selectedIds',
          ),
        } as AssetsSelectionProps;
        dispatch(
          actions.openSidePanel({
            type: RenderPageType.AssetsSelection,
            props: selParams,
            expanded: !isMobile,
          }),
        );
      }}
      {...props}
    />
  );
}
