/**
 *
 * ServiceFilter
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { OnlineServiceSinglePicker } from 'app/components/pickers/MultiSelectPickers/OnlineServicesPicker';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';

export const ServiceFilter = (props: IFilterComponentProps) => {
  const handleChange = (value: Entity<number>[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  // OnlineServiceSinglePicker operates on arrays, while ServiceFilter is a single selection picker
  const arrayValue =
    (props.value as Entity<number> | undefined) === undefined
      ? undefined
      : Array.isArray(props.value) // check if the value already is an array before wrapping it yet in another array to avoid nested arrays [[{...}]]
      ? props.value
      : [props.value];

  return (
    <OnlineServiceSinglePicker
      onChange={handleChange}
      value={arrayValue}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      additionalItem={props.additionalItem as IServiceFilterDto}
      ariaLabel={props.label}
      size="small"
      id={props.id}
    />
  );
};
export default ServiceFilter;
