import * as React from 'react';
import { useAppSettingsSlice } from 'app/slice';
import {
  selectGlobalServiceGroupFilter,
  selectglobalSettings,
} from 'app/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnMount } from './useEffectOnMount';
import { AllowedSettings } from 'utils/globalSettings';

export const useGlobalServiceGroupsFilter = (
  applyGlobalServiceGroupsFilter: boolean,
) => {
  const dispatch = useDispatch();
  const selectedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const gloabalSettings = useSelector(selectglobalSettings);
  const { actions } = useAppSettingsSlice();
  useEffectOnMount(() => {
    dispatch(
      actions.showGlobalServiceGroupFilter(
        !gloabalSettings.GetBooleanByKey(
          AllowedSettings.HideTopServiceGroupFilter,
        ) && applyGlobalServiceGroupsFilter,
      ),
    );
  });
  const serviceGroups = React.useMemo(() => {
    return applyGlobalServiceGroupsFilter ? selectedServiceGroups || [] : [];
  }, [applyGlobalServiceGroupsFilter, selectedServiceGroups]);
  return { serviceGroups };
};
